import { InputText } from 'primereact/inputtext';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ViewportList } from 'react-viewport-list';
import ElectricalItem from '../../../../../data/model/ElectricalItem';
import ProtocolItem from '../../../../../data/model/ProtocolItem';
import ElectricalSubItem from '../../../../../data/model/ElectricalSubItem';
import {
  ElectricalShock,
  ElectricalShockOption,
  User,
} from '../../../../../models';
import { ProgressStatus } from '../../../../../models';
import {
  getArchivedDosesToDisplay,
  getFormattedDate,
  globals,
  removeTypename,
  upgradeVersion,
} from '../../../../_global/common/Utils';
import { Col, Row } from 'react-bootstrap';
import ProtocolHeader from '../../ProtocolHeader';
import { UserType } from '../../../../../models';
import ElectricalDoseUI from './ElectricalDoseUI';
import AccordionItem from '../../../../components/Accordion/AccordionItem';
import ConfirmModal from '../../../../components/Modal/ConfirmModal';
import {
  createElectrical,
  deleteElectricalItem,
} from '../../../../../data/functions/ElectricalDB';
import { ElectricalJSON } from '../../../../../data/functions/ElectricalDB';
import { ResponseType } from '../../../../../data/AmplifyDB';
import {
  ArchiveItem,
  reloadModelItem,
} from '../../../../../data/functions/ModelDB';
import ArchivedDosesSideout from '../../../../components/SideOut/doseSideOut/ArchivedDosesSideout';
import { Alert, Snackbar } from '@mui/material';

/* 12-31-24 Gagan: Created electrical Archive Page component */
const ElectricalProtocolArchivePage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const user: User = useSelector((state: any) => state?.user);

  if (
    !location.state?.value ||
    !location.state?.department ||
    !location.state?.database
  ) {
    navigate('/archive');
  }

  const {
    value,
    sourceData,
    subData,
    archiveData,
    department,
    database,
    activeItem,
  } = location.state;

  const [parmElectrical, setParmElectrical] = useState<ElectricalItem>(value);

  const [activeParmElectrical, setActiveParmElectrical] =
    useState<ElectricalItem>(activeItem);

  const [allSubElectricalItems, setAllSubElectricalItems] = useState<
    ElectricalSubItem[]
  >(parmElectrical.subItems);
  const [expanded, setExpanded] = useState<string[]>([]);
  const [modifiedBy, setModifiedBy] = useState<User | null>(null);

  const [isRestoring, setIsRestoring] = useState(false);

  // Check if the most recent version of the vital is a draft
  const isDraft = useMemo(
    () => activeParmElectrical.status === ProgressStatus.DRAFT,
    [activeParmElectrical]
  );

  const [snackbar, setSnackbar] = useState<any>({
    open: false,
    message: '',
    severity: 'success',
  });

  const allProtocols = useMemo(() => {
    let prots = allSubElectricalItems
      .filter((m) => !m.parentCPR)
      .map((m) => m.parentProtocol);
    // Remove all the duplicates
    return prots
      .filter((v, i, a) => a.findIndex((t) => t.uid === v.uid) === i)
      .sort((a, b) => {
        if (a.index === b.index) return a.name.localeCompare(b.name);
        return a.index - b.index;
      });
  }, [allSubElectricalItems]);

  const [modal, setModal] = useState({
    isVisible: false,
    title: '',
    primaryDescription: '',
    primaryBtnName: '',
    secondaryBtnName: '',
    handleClose: () => {},
    handleSubmit: () => {},
  });

  const reloadItem = async () => {
    const response = await reloadModelItem(
      ElectricalShock,
      activeParmElectrical
    );
    if (response.type === ResponseType.Success) {
      const item = new ElectricalItem(response.data.item as ElectricalShock);
      const activeItem = response.data.activeItem as
        | ElectricalShock
        | null
        | undefined;
      item.activeItem = activeItem ? new ElectricalItem(activeItem) : null;
      item.subItems = activeParmElectrical.subItems;
      item.parents = activeParmElectrical.parents;
      setActiveParmElectrical(item);
    }
  };

  /**
   * Check if the draft exists for the active item
   */
  useEffect(() => {
    reloadItem();
  }, []);

  /* Hotkey detection */
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.ctrlKey && event.shiftKey && event.key === 'O') {
        console.log('ARCHIVE ELECTRICAL', parmElectrical);
        console.log('ACTIVE ELECTRICAL', activeParmElectrical);
      }
    };

    // Add event listener
    document.addEventListener('keydown', handleKeyDown);

    // Remove event listener on cleanup
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [parmElectrical, activeParmElectrical]);

  useEffect(() => {
    if (value) {
      setParmElectrical(value);
      setAllSubElectricalItems(value.subItems);
    }
  }, [value]);

  useEffect(() => {
    let id = parmElectrical.model?.modifiedBy;
    if (id == null) id = parmElectrical.model?.createdBy;
    if (id) {
      let user = database.users.find((u: User) => u.id === id);
      setModifiedBy(user ? user : null);
      if (user) {
        parmElectrical.modifiedBy = user;
      }
    } else {
      setModifiedBy(null);
    }
  }, [database, parmElectrical]);

  /**
   * Back button has been pressed
   */
  const handleBack = useCallback(() => {
    navigate('/archive/list-electricals-details', {
      state: {
        data: sourceData,
        subData: subData,
        department: department,
        database: database,
        archiveData: archiveData,
        activeItem: activeItem,
        tab: 'Electrical',
      },
    });
  }, [
    navigate,
    sourceData,
    subData,
    department,
    database,
    archiveData,
    activeItem,
  ]);

  const ListRender = ({ data }: any) => {
    const [showArchiveSideout, setShowArchiveSideout] = useState(false);
    const [selectedDose, setSelectedDose] = useState<ElectricalSubItem | null>(
      null
    );
    const [selectedArchives, setSelectedArchives] = useState<
      ElectricalSubItem[]
    >([]);

    // Process all data once at the beginning
    const { displayItems, archivedItemsMap } = useMemo(() => {
      return getArchivedDosesToDisplay(data);
    }, [data]);

    const handleViewHistory = (
      item: ElectricalSubItem,
      e: React.MouseEvent
    ) => {
      e.stopPropagation(); // Prevent event bubbling if inside clickable containers
      const archivedItems =
        archivedItemsMap.get(item.uid) ||
        archivedItemsMap.get(item.activeID || '') ||
        [];

      setSelectedDose(item);
      setSelectedArchives(archivedItems as ElectricalSubItem[]);
      setShowArchiveSideout(true);
    };

    return (
      <>
        <ViewportList items={displayItems as ElectricalSubItem[]}>
          {(dose: ElectricalSubItem, index: number) => {
            const archivedItems =
              dose.status === ProgressStatus.ARCHIVE
                ? archivedItemsMap.get(dose.activeID || '')
                : archivedItemsMap.get(dose.uid);

            const archivedCount = archivedItems?.length || 0;

            return (
              <div key={index}>
                <ElectricalDoseUI
                  dose={dose}
                  doseIndex={dose.version}
                  showProtocol={false}
                  isEditMode={false}
                  archiveMode={true}
                />
                {archivedCount > 0 && (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      margin: 0,
                      marginTop: '0px',
                      cursor: 'pointer',
                    }}
                    onClick={(e) => handleViewHistory(dose, e)}
                  >
                    <p className="sidebarText-hover">
                      {archivedCount} archived
                      {archivedCount === 1 ? ' shock >' : ' shocks >'}
                    </p>
                  </div>
                )}
              </div>
            );
          }}
        </ViewportList>

        {selectedDose && (
          <ArchivedDosesSideout
            visible={showArchiveSideout}
            currentDose={selectedDose}
            archivedDoses={selectedArchives}
            type={'electrical'}
            setVisible={setShowArchiveSideout}
          />
        )}
      </>
    );
  };

  const ProtocolItemUI = ({ protocol }: any) => {
    const doses = allSubElectricalItems.filter(
      (item: ElectricalSubItem) => item.parentProtocol.uid === protocol.uid
    );
    doses
      .filter(
        (item: ElectricalSubItem) => item.status !== ProgressStatus.DEACTIVATED
      )
      .sort((a: ElectricalSubItem, b: ElectricalSubItem) => {
        if (a.index === b.index) return a.rangeLow - b.rangeLow;
        return a.index - b.index;
      });

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          padding: '10px',
          marginTop: '.25rem',
          marginBottom: '.25rem',
        }}
        onClick={(e) => e.stopPropagation()} // Add this to prevent event bubbling
      >
        <AccordionItem
          title={protocol.name}
          titleStyle={{ fontWeight: '400' }}
          rightTitle={`${doses.length} doses`}
          rightTitleStyle={{ fontWeight: '700' }}
          expanded={expanded.includes(protocol.uid)}
          onChange={() => {
            if (expanded.includes(protocol.uid)) {
              setExpanded(expanded.filter((item) => item !== protocol.uid));
            } else {
              setExpanded([...expanded, protocol.uid]);
            }
          }}
        >
          <div
            style={{ flex: 1, padding: '0 10px 10px 10px' }}
            onClick={(e) => e.stopPropagation()} // Add this to prevent event bubbling
          >
            <ListRender data={doses} />
          </div>
        </AccordionItem>
      </div>
    );
  };

  /** e68bc8f5-c88b-4b5f-9bcc-153ec603c9a5
   * Find the protocols from the electrical options
   * @param options The options to search for
   * @returns The protocols that were found
   */
  const findProtocols = useCallback(
    (options: ElectricalShockOption[]): ProtocolItem[] => {
      let protocols: ProtocolItem[] = [];
      for (let i = 0; i < options.length; i++) {
        let po = options[i];
        let protocol = database.protocols.find((p: ProtocolItem) => {
          let id =
            p.status === ProgressStatus.ACTIVE ||
            (p.status === ProgressStatus.DRAFT && p.activeID == null)
              ? p.uid
              : p.activeID;
          return id === po.protocolID;
        });
        if (protocol) protocols.push(protocol);
      }
      return protocols;
    },
    [database]
  );

  const handleRestore = useCallback(async () => {
    setIsRestoring(true);
    setModal({ ...modal, isVisible: false });
    try {
      const oldActiveItem = isDraft
        ? activeParmElectrical.activeItem
        : activeParmElectrical;
      if (isDraft) {
        const response = await deleteElectricalItem(
          activeParmElectrical,
          false
        );
        if (response.type === ResponseType.Failure) {
          setSnackbar({
            open: true,
            message:
              'Failed to delete already existing draft of ' +
              oldActiveItem?.name,
            severity: 'error',
          });
          // Exit the function
          console.error('Failed to delete electrical', response.data);
          return;
        }
      }

      const protocolList = findProtocols(parmElectrical.options);
      const updatedEquipment: ElectricalJSON = {
        title: parmElectrical.name,
        rangeLow: !isNaN(Number(parmElectrical.rangeLow))
          ? Number(parmElectrical.rangeLow)
          : 0,
        rangeHigh: !isNaN(Number(parmElectrical.rangeHigh))
          ? Number(parmElectrical.rangeHigh)
          : globals.MAX_VALUE,
        departmentID: department.id,
        options: removeTypename(
          parmElectrical.options.map((item: ElectricalShockOption) => {
            return {
              ...item,
              ranges: removeTypename(item.ranges),
            };
          })
        ),
        taggedProtocols: protocolList,

        instruction: parmElectrical.instruction,
        note: parmElectrical.note,
        warning: parmElectrical.warning,
        createdBy: parmElectrical.model.createdBy
          ? parmElectrical.model.createdBy
          : user.id,
        modifiedBy: user.id,

        status: ProgressStatus.DRAFT,
        activeID: oldActiveItem?.uid,
        version: upgradeVersion(oldActiveItem?.version || 'v1.0.0'),
      };

      const results = await createElectrical(
        updatedEquipment,
        oldActiveItem as ElectricalItem
      );
      if (results.type === ResponseType.Success) {
        const newDraftElectrical = results.data as ElectricalItem;
        newDraftElectrical.activeItem = oldActiveItem;

        setSnackbar({
          open: true,
          message:
            'Successfully Restored ' +
            newDraftElectrical.name +
            ' with a new DRAFT version ' +
            newDraftElectrical.version,
          severity: 'success',
        });

        // Update sourceData with new draft
        const newSourceData = sourceData.map((item: ArchiveItem) =>
          item.activeItem?.uid === activeParmElectrical.uid
            ? { ...item, activeItem: newDraftElectrical }
            : item
        );

        if (globals.debug)
          console.log('Successfully restored Electrical', results.data);

        setModal({
          isVisible: true,
          title: 'Successfully Restored Electrical',
          primaryDescription: `Successfully restored ${newDraftElectrical.name} to version ${newDraftElectrical.version}. Would you like to navigate to the electrical?`,
          primaryBtnName: 'Dismiss',
          secondaryBtnName: 'Navigate',
          handleClose: () => {
            setModal({ ...modal, isVisible: false });
            navigate('/archive/list-electricals-details', {
              state: {
                data: newSourceData,
                subData: subData,
                department: department,
                database: database,
                archiveData: archiveData,
                activeItem: newDraftElectrical,
                tab: 'Electrical',
              },
            });
          },
          handleSubmit: () => {
            const state = {
              selectedProtocol: null,
              value: newDraftElectrical,
              subValue: null,
              type: 'Electrical',
              editType: 'edit',
              editMode: false,
              page: 'listElectricalPage',
            };
            navigate(`/protocol/edit/edit-protocol`, { state });
          },
        });
      } else if (results.type === ResponseType.Failure) {
        setSnackbar({
          open: true,
          message: 'Failed to restore Electrical.',
          severity: 'error',
        });
      }
    } catch (error) {
      setIsRestoring(false);
      console.error('Restore error:', error);
    } finally {
      setIsRestoring(false);
    }
  }, [
    navigate,
    sourceData,
    department,
    database,
    archiveData,
    parmElectrical,
    user,
    isDraft,
    activeParmElectrical,
    findProtocols,
    subData,
  ]);

  const handleRestorePressed = async () => {
    if (isDraft) {
      setModal({
        isVisible: true,
        title: 'Override Existing Draft?',
        primaryDescription: `There is already a draft of ${activeParmElectrical.name} (${activeParmElectrical.version}) which is not yet published. Are you sure you want to override the existing draft and restore ${parmElectrical.name} of version ${parmElectrical.version}?`,
        primaryBtnName: 'Cancel',
        secondaryBtnName: 'Restore',
        handleClose: () => {
          setModal({ ...modal, isVisible: false });
        },
        handleSubmit: handleRestore,
      });
    } else {
      setModal({
        isVisible: true,
        title: 'Restore Electrical?',
        primaryDescription: `Are you sure you want to restore ${parmElectrical.name} of version ${parmElectrical.version}? This will create a new draft version of the electrical.`,
        primaryBtnName: 'Cancel',
        secondaryBtnName: 'Restore',
        handleClose: () => {
          setModal({ ...modal, isVisible: false });
        },
        handleSubmit: handleRestore,
      });
    }
  };

  return (
    <div className="screen-container">
      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>

      <ConfirmModal
        isVisible={modal.isVisible}
        title={modal.title}
        primaryDescription={modal.primaryDescription}
        primaryBtnName={modal.primaryBtnName}
        secondaryBtnName={modal.secondaryBtnName}
        handleClose={modal.handleClose}
        handleSubmit={modal.handleSubmit}
      />

      <ProtocolHeader
        name={parmElectrical.name}
        status={parmElectrical.status}
        isBackButton={true}
        page={parmElectrical.name + ' Archive'}
        type={'protocol'}
        isCopyDescription={user.type === UserType.ADMIN}
        descriptionTitle={user.type === UserType.ADMIN ? 'ID: ' : ''}
        description={user.type === UserType.ADMIN ? parmElectrical.uid : ''}
        handleCancel={handleBack}
        isRestoreButton={true}
        isRestoreActive={!isRestoring && parmElectrical.status !== 'ACTIVE'}
        bypassActiveStatusCheck={true}
        handleRestore={handleRestorePressed}
      />

      <div className="ketamineContent">
        <div className="KetamineGeneral">
          <h5 className="ketmine-header-text">General Information</h5>
          <div className="input-container roundBorder">
            <div
              style={{ display: 'flex', marginTop: '5px' }}
              className="ketamine-general-label"
            >
              <div style={{ marginRight: '10px' }}>Modified By:</div>
              <div style={{ fontWeight: '500' }}>
                {modifiedBy
                  ? modifiedBy.firstName + ' ' + modifiedBy.lastName
                  : 'Hinckley Medical'}
              </div>
            </div>
            {parmElectrical.model?.updatedAt && (
              <div
                style={{ display: 'flex' }}
                className="ketamine-general-label"
              >
                <div style={{ marginRight: '10px' }}>Last Updated:</div>
                <div style={{ fontWeight: '500' }}>
                  {getFormattedDate(parmElectrical.model.updatedAt, true)}
                </div>
              </div>
            )}

            <div style={{ display: 'flex' }} className="ketamine-general-label">
              <div style={{ marginRight: '10px' }}>Version:</div>
              <div style={{ fontWeight: '500' }}>{parmElectrical.version}</div>
            </div>
          </div>
          <label htmlFor="Name" className="ketamine-general-label">
            Name
          </label>
          <div className="input-container">
            <InputText
              type="text"
              className="form-control-general"
              id="name"
              name="name"
              data-testid="name"
              value={parmElectrical.name}
              disabled={true}
            />
            <div className="input-border"></div>
          </div>
          <Row>
            <Col sm={6}>
              <label htmlFor="rangeLow" className="ketamine-general-label">
                Range Low (kg)
              </label>
              <div className="input-container">
                <InputText
                  type="text"
                  className="form-control-general"
                  id="name"
                  name="rangeLow"
                  data-testid="rangeLow"
                  value={
                    parmElectrical.rangeLow &&
                    Number(parmElectrical.rangeLow) !== 0
                      ? parmElectrical.rangeLow + ' kg'
                      : ''
                  }
                  placeholder="Min"
                  disabled
                />
                <div className="input-border"></div>
              </div>
            </Col>
            <Col sm={6}>
              <label htmlFor="rangeHigh" className="ketamine-general-label">
                Range High (kg)
              </label>
              <div className="input-container">
                <InputText
                  type="text"
                  className="form-control-general"
                  id="rangeHigh"
                  name="rangeHigh"
                  data-testid="rangeHigh"
                  disabled
                  value={
                    parmElectrical.rangeHigh &&
                    Number(parmElectrical.rangeHigh) !== globals.MAX_VALUE
                      ? parmElectrical.rangeHigh + ' kg'
                      : ''
                  }
                  placeholder="Max"
                />
                <div className="input-border"></div>
              </div>
            </Col>
          </Row>
          <label htmlFor="Warning" className="ketamine-general-label">
            Warning
          </label>
          <div className="input-container">
            <InputText
              type="text"
              className="form-control-general"
              id="warning"
              name="warning"
              required={true}
              data-testid="warning"
              value={parmElectrical.model.warning}
              disabled
            />
            <div className="input-border"></div>
          </div>
          <label htmlFor="Instruction" className="ketamine-general-label">
            Instruction
          </label>
          <div className="input-container">
            <InputText
              type="text"
              className="form-control-general"
              id="instruction"
              name="instruction"
              data-testid="instruction"
              value={parmElectrical.model.instruction}
              disabled
            />
            <div className="input-border"></div>
          </div>
          <label htmlFor="Note" className="ketamine-general-label">
            Note
          </label>
          <div className="input-container">
            <InputText
              type="text"
              className="form-control-general"
              id="note"
              name="note"
              data-testid="note"
              value={parmElectrical.model.note}
              disabled
            />
            <div className="input-border"></div>
          </div>
        </div>
        <div className="KetamineGeneral">
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: '10px',
            }}
          >
            <h5 className="ketmine-header-text">Electrical Shocks</h5>
          </div>
          <>
            {allProtocols.map((protocol: ProtocolItem, index: number) => {
              return <ProtocolItemUI key={index} protocol={protocol} />;
            })}
          </>
        </div>
      </div>
    </div>
  );
};

export default ElectricalProtocolArchivePage;
