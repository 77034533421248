import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Io5 from 'react-icons/io5';
import {
  BatchQuery,
  DatabaseResponse,
  loadDatabase,
  ResponseType,
} from '../../../data/AmplifyDB';
import ProtocolHeader from '../protocol/ProtocolHeader';
import Status from '../../components/ProgressStatus/ProgressStatus';
import { ProgressStatus } from '../../../API';
import {
  FaBoltLightning,
  FaFolderOpen,
  FaHeartPulse,
  FaSyringe,
} from 'react-icons/fa6';
import {
  fetchAllMedicationDosesForMedication,
  fetchMedicationsByStatusAPI,
} from '../../../data/functions/MedicationDB';
import {
  fetchAllInfusionDosesForInfusion,
  fetchInfusionsByStatusAPI,
} from '../../../data/functions/InfusionDB';
import {
  fetchAllElectricalDosesForElectrical,
  fetchElectricalsByStatusAPI,
} from '../../../data/functions/ElectricalDB';
import { fetchEquipmentByStatusAPI } from '../../../data/functions/EquipmentDB';
import { fetchVitalsByStatusAPI } from '../../../data/functions/VitalDB';
import { fetchCheckListsByStatusAPI } from '../../../data/functions/CheckListDB';
import Loading from '../../components/Loading/Loading';
import MedicationItem from '../../../data/model/MedicationItem';
import VitalItem from '../../../data/model/VitalItem';
import EquipmentItem from '../../../data/model/EquipmentItem';
import ElectricalItem from '../../../data/model/ElectricalItem';
import InfusionItem from '../../../data/model/InfusionItem';
import FormItem from '../../../data/model/FormItem';
import {
  globals,
  updateArchiveItemsCreatedAt,
} from '../../_global/common/Utils';
import { ArchiveItem } from '../../../data/functions/ModelDB';
import {
  fetchAllDosesShocksForCPRAssist,
  fetchArchivedCPRAssists,
} from '../../../data/functions/CprDB';
import CPRItem from '../../../data/model/CPRItem';
import { fetchCategoriesByStatusAPI } from '../../../data/functions/CategoryDB';
import CategoryItem from '../../../data/model/CategoryItem';
import { fetchProtocolsByStatusAPI } from '../../../data/functions/ProtocolDB';
import ConfirmModal from '../../components/Modal/ConfirmModal';
import { handleGetDepartment } from '../../../store/actions';
import DepartmentItem from '../../../data/model/DepartmentItem';

interface RootState {
  protocol: any;
}

// Defining the type for the data that is stored in local storage
interface ListItem {
  name: string;
  count: number;
}

const STORAGE_KEY = 'archivePageData';

// For reusability and reducing the lines of code, the following is defined
const ITEM_CONFIG = {
  folders: {
    name: 'Folders',
    model: CategoryItem,
    fetch: fetchCategoriesByStatusAPI,
    fetchSubData: null,
  },
  protocols: {
    name: 'Protocols',
    model: null,
    fetch: fetchProtocolsByStatusAPI,
    fetchSubData: null,
    needsProcessing: true,
  },
  medications: {
    name: 'Medications',
    model: MedicationItem,
    fetch: fetchMedicationsByStatusAPI,
    fetchSubData: fetchAllMedicationDosesForMedication,
    needsProcessing: true, // This is used to update the createdAt field
  },
  infusions: {
    name: 'Infusions',
    model: InfusionItem,
    fetch: fetchInfusionsByStatusAPI,
    fetchSubData: fetchAllInfusionDosesForInfusion,
    needsProcessing: true, // This is used to update the createdAt field
  },
  electricals: {
    name: 'Electricals',
    model: ElectricalItem,
    fetch: fetchElectricalsByStatusAPI,
    fetchSubData: fetchAllElectricalDosesForElectrical,
    needsProcessing: true, // This is used to update the createdAt field
  },
  equipment: {
    name: 'Equipment',
    model: EquipmentItem,
    fetch: fetchEquipmentByStatusAPI,
    fetchSubData: null,
  },
  vitals: {
    name: 'Vitals',
    model: VitalItem,
    fetch: fetchVitalsByStatusAPI,
    fetchSubData: null,
  },
  checklists: {
    name: 'Checklists',
    model: FormItem,
    fetch: fetchCheckListsByStatusAPI,
    fetchSubData: null,
  },
  cprassist: {
    name: 'CPR Assist',
    model: FormItem,
    fetch: fetchArchivedCPRAssists,
    fetchSubData: null,
    needsProcessing: true,
  },
};

const iconMap = {
  Folders: <FaFolderOpen />,
  Protocols: <Io5.IoDocuments />,
  Medications: <FaSyringe />,
  Infusions: <Io5.IoWater />,
  Electricals: <FaBoltLightning />,
  Equipment: <Io5.IoMedkit />,
  Vitals: <Io5.IoHeart />,
  Checklists: <Io5.IoDocumentText />,
  'CPR Assist': <FaHeartPulse />,
};

const ArchivePage = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<string>('');
  const [list, setList] = useState<ListItem[]>([]);
  const [alert, setAlert] = useState({
    isVisible: false,
    title: '',
    description: '',
    primaryBtnName: '',
    secondaryBtnName: '',
    secondaryDescription: '',
    handleSubmit: () => {},
  });

  const [database, setDatabase] = useState<DatabaseResponse | null>(
    useSelector((state: RootState) => state.protocol.departmentItem)
  );
  const department = database?.department;

  const dispatch = useDispatch<any>();

  /**
   * 12-19-24 Hazlett:
   *   - Added a check to see if the database was loaded within the last minute
   *   - If it was, then we don't need to load the database again
   *   - This is to prevent the database from being loaded multiple times
   *   - This is a temporary fix until we have a better solution
   */
  const reloadDatabase = async () => {
    /* 1-10-24 Hazlett:  Update the current data to the database change and keep the current state */
    if (globals.debug) console.log('loading database');

    let load = true;
    let lastLoad = localStorage.getItem('lastLoad-ArchivePage');
    if (lastLoad != null) {
      let loadTimestamp = new Date(lastLoad);
      let now = new Date();
      let diff = now.getTime() - loadTimestamp.getTime();
      if (diff < 1000 * 60 * 1) load = false; //1 minute
    }

    if (load && database != null) {
      loadDatabase(database, undefined, {
        wait: false,
        queryForUsers: false,
        allSubDepsMapParam: database.allSubDepsMap,
        subDepsMapParam: database.subDepsMap,
        supplementalBatchSize: 3,
        lazyCallback: (db: DatabaseResponse, isComplete: boolean) => {
          if (isComplete) {
            setDatabase(db);
            dispatch(handleGetDepartment(db));
            localStorage.setItem(
              'lastLoad-ArchivePage',
              new Date().toISOString()
            );
          }
        },
      });
    }
  };

  useEffect(() => {
    setTimeout(() => {
      reloadDatabase();
    }, 500);
  }, []);

  // This is the function that fetches the data for the item
  // In here, the data is fetched only for one particular category like medications or infusions or electricals etc. instead of all the categories.
  const fetchDataForItem = useCallback(
    async (itemType: string) => {
      // Get the ITEM_CONFIG for the itemType
      const key = itemType
        .toLowerCase()
        .replace(' ', '') as keyof typeof ITEM_CONFIG;
      const config = ITEM_CONFIG[key];
      if (!config) {
        console.error('No config found for itemType', itemType);
        return [];
      }

      // Fetch the data for the itemType by calling their respective fetch function
      const response = await config.fetch(
        department as DepartmentItem,
        database as DatabaseResponse,
        ProgressStatus.ARCHIVE
      );

      if (globals.debug) console.log(itemType, 'response', response);

      // Storing the data upon success
      const filteredData =
        !Array.isArray(response) && response.type === ResponseType.Success
          ? response.data
          : [];

      // If the itemType(MedicationItem, InfusionItem, ElectricalItem) needs processing i.e. the createdAt field needs to be updated, then update the data and return it
      // Else, return the data as is
      // Note: updateArchiveItemsCreatedAt is done to update the createdAt field of the items so that they can be mapped to their respective doses/shocks respectively later.
      return 'needsProcessing' in config && config.needsProcessing
        ? updateArchiveItemsCreatedAt(filteredData)
        : filteredData;
    },
    [department, database]
  );

  // This is the function that updates the item data in the cache upon being clicked
  const updateItemData = useCallback(
    async (item: ListItem) => {
      setIsLoading('Loading ' + item.name + ' Archive...');
      try {
        // Fetch the new data for the item
        const newData = await fetchDataForItem(item.name);
        if (globals.debug) console.log('NEW DATA', newData);

        // Get the current data from the cache
        const currentData = JSON.parse(
          localStorage.getItem(STORAGE_KEY) || '[]'
        );

        // Update with the new item data
        const updatedList = currentData.map((i: any) =>
          i.name.toLowerCase() === item.name.toLowerCase()
            ? { ...item, count: newData.length }
            : i
        );

        // Store the updated data back into local storage
        localStorage.setItem(STORAGE_KEY, JSON.stringify(updatedList));

        // Update the list of items
        setList(updatedList);

        if (
          (item.name.toLowerCase() === 'medications' ||
            item.name.toLowerCase() === 'infusions' ||
            item.name.toLowerCase() === 'electricals') &&
          newData.length > 0
        ) {
          // Get the ITEM_CONFIG for the item
          const config =
            ITEM_CONFIG[item.name.toLowerCase() as keyof typeof ITEM_CONFIG];

          if (!config?.fetchSubData) {
            return { data: newData, subData: [] };
          }

          // Querying doses and shocks for all the items in the database
          const dataset =
            item.name.toLowerCase() === 'medications'
              ? (database as DatabaseResponse).medications
              : item.name.toLowerCase() === 'infusions'
                ? (database as DatabaseResponse).infusions
                : (database as DatabaseResponse).electrical;

          // Using Promise.all to wait for all async operations to complete
          const nestedSubData = await Promise.all(
            dataset.map(
              async (
                selectedItem: MedicationItem | InfusionItem | ElectricalItem
              ) => {
                const subDosesList = await config?.fetchSubData(
                  database as DatabaseResponse,
                  department as DepartmentItem,
                  selectedItem as MedicationItem & InfusionItem & ElectricalItem
                );

                const subDoses = subDosesList.data;

                // Catch any errors upon failure
                if (subDosesList.type === ResponseType.Failure) {
                  console.error('Failed to fetch medication doses', subDoses);
                  return null;
                }

                return subDoses;
              }
            )
          );

          if (globals.debug) console.log('nestedSubData', nestedSubData);

          // Flatten the nested arrays and remove any null values
          const updatedSubData = nestedSubData
            .filter((array) => array !== null)
            .flat();

          if (globals.debug) console.log('updatedSubData', updatedSubData);

          return {
            data: newData,
            subData: updatedSubData,
          };
        } else if (
          item.name.toLowerCase() === 'cpr assist' &&
          newData.length > 0
        ) {
          const cprActItem = newData[0].activeItem as CPRItem;
          const cprActiveItem =
            cprActItem.status === 'DRAFT' ? cprActItem.activeItem : cprActItem;

          // Fetch all the Active and Archived Defib Shocks and Epi Doses linked to the Active CPR Assist Item
          const nestedSubData = await fetchAllDosesShocksForCPRAssist(
            database as DatabaseResponse,
            department as DepartmentItem,
            cprActiveItem as CPRItem
          );

          const [defibSubDoses, epiSubDoses] = nestedSubData.data;

          // Catch any errors upon failure
          if (nestedSubData.type === ResponseType.Failure) {
            console.error('Failed to fetch medication doses', nestedSubData);
            return null;
          }

          return {
            data: newData,
            subData: [
              updateArchiveItemsCreatedAt(defibSubDoses),
              updateArchiveItemsCreatedAt(epiSubDoses),
            ],
          };
        }

        return {
          data: newData,
          subData: [],
        };
      } catch (error) {
        console.error(`Error updating ${item.name}:`, error);
      } finally {
        setIsLoading('');
      }
    },
    [fetchDataForItem, database, department]
  );

  // This is the function that fetches the data for all the categories and stores it in local storage.
  // Each promise takes care of one category.
  // Categories are Medications, Infusions, Electricals, Equipment, Vitals, Checklists, Protocols.
  const fetchFreshData = useCallback(async () => {
    let promisesFunctions: (() => Promise<{ name: string; count: number }>)[] =
      [];
    Object.keys(ITEM_CONFIG).forEach((itemType) => {
      promisesFunctions.push(() =>
        fetchDataForItem(itemType).then((data) => ({
          name: ITEM_CONFIG[itemType as keyof typeof ITEM_CONFIG].name,
          count: data.length,
        }))
      );
    });

    const newList: { name: string; count: number }[] = await BatchQuery(
      promisesFunctions,
      5
    );

    // Store the fetched data in local storage
    localStorage.setItem(STORAGE_KEY, JSON.stringify(newList));

    // Update the list of items
    setList(newList);
  }, [fetchDataForItem]);

  // This is the function that loads the department data from the cache
  const loadDepartmentData = useCallback(async () => {
    setIsLoading('Loading ' + department?.name + ' Archive...');
    try {
      // Check and fetch the data from local storage
      const cachedData = localStorage.getItem(STORAGE_KEY);

      // If the data is in local storage, parse it and load it into the list
      if (cachedData) {
        const localList = JSON.parse(cachedData);

        // Check if each of those data is empty
        const isDataEmpty = localList.every(
          (item: ListItem) => item.count === 0
        );

        // Get the names of each item in ITEM_CONFIG
        const names = Object.values(ITEM_CONFIG).map((item) => item.name);
        // Check if any of the key is missing in the localList
        const isKeyMissing = !names.every((item: string) =>
          localList.some((listItem: ListItem) => listItem.name === item)
        );

        if (isKeyMissing) {
          // If all of the data is empty, fetch the fresh data from the database
          await fetchFreshData();
        } else {
          // If all of the data is not empty, load it into the list
          setList(localList as ListItem[]);
        }
        return;
      }
      // If the data is not in local storage, fetch it from the database
      await fetchFreshData();
    } catch (error) {
      console.error('Error loading department data:', error);
    } finally {
      setIsLoading('');
    }
  }, [fetchFreshData]);

  useEffect(() => {
    loadDepartmentData();
  }, [department, loadDepartmentData]);

  // This is the function that handles the click on an archive item
  // It reloads its respective data into local storage with latest data from the database before navigating to the archive item page
  const handleArchiveItemClick = async (item: ListItem) => {
    setIsLoading('Loading ' + item.name + ' Archive...');
    if (globals.debug) console.log('SELECTED ITEM', item);
    const result = await updateItemData(item);
    if (globals.debug) console.log('RESULT', result);
    const updatedData = result?.data;
    const updatedSubData = result?.subData;
    setIsLoading('');

    if (updatedData.length === 0) {
      setAlert({
        isVisible: true,
        title: 'No Archives Found',
        description:
          'There are no ' +
          item.name +
          ' archives found for ' +
          department?.name +
          '.',
        primaryBtnName: 'Okay',
        secondaryBtnName: 'Okay',
        secondaryDescription: '',
        handleSubmit: () => {
          setAlert({ ...alert, isVisible: false });
        },
      });
      return;
    }

    const itemName = item.name.toLocaleLowerCase().replace(' ', '-');
    navigate(`/archive/list-${itemName}`, {
      state: {
        department: department,
        data: updatedData,
        subData: updatedSubData,
        database: database,
      },
    });
  };

  return (
    <div className="screen-container">
      <ProtocolHeader
        homeScreen={true}
        page={`${department?.name}'s Archive`}
        type="protocol"
      />

      <ConfirmModal
        isVisible={alert.isVisible}
        title={alert.title}
        handleClose={() => {
          setAlert({ ...alert, isVisible: false });
        }}
        handleSubmit={alert.handleSubmit}
        isDeleteBtn={false}
        isSingleBtn={alert.primaryBtnName === 'Okay' ? true : false}
        primaryBtnName={alert.primaryBtnName}
        secondaryBtnName={alert.secondaryBtnName}
        primaryDescription={alert.description}
        secondaryDescription={alert.secondaryDescription}
      />

      <div className="grid-container">
        {list.map((item, index) => (
          <div
            key={index}
            className="grid-item cursor-pointer"
            onClick={() => handleArchiveItemClick(item)}
          >
            <div className="item-name">{item.name}</div>
            {iconMap[item.name as keyof typeof iconMap]}
            <div className="item-count">{item.count} items</div>
            <Status status={ProgressStatus.ARCHIVE} />
          </div>
        ))}
      </div>

      {/* This is the loading spinner that is used to display a loading message to the user */}
      {isLoading && (
        <Loading
          type="bubbles"
          remove={globals.debug ? () => setIsLoading('') : null}
          message={isLoading}
        />
      )}
    </div>
  );
};

export default ArchivePage;
