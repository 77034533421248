// Note: EditDB for Equipment
// Date: 01/11/2024
// Author: Guruprasad Venkatraman
//...........................................................................
import { DataStore } from '@aws-amplify/datastore';
import {
  DatabaseResponse,
  executeQuery,
  executeSingleQuery,
  mapModelItems,
  Response,
  ResponseType,
} from '../AmplifyDB';
import EquipmentItem from '../model/EquipmentItem';
import { FormOption, ProgressStatus } from '../../API';
import DepartmentItem from '../model/DepartmentItem';
import {
  findItemByID,
  generateID,
  compareVersions,
  getActiveID,
  globals,
  removeTypename,
} from '../../ui/_global/common/Utils';
import ProtocolItem from '../model/ProtocolItem';
import { validatePointerID } from './ProtocolDB';
import {
  equipmentByDepartmentID,
  getEquipment,
  listEquipment,
  protocolsByDepartmentID,
} from '../../graphql/queries';
import DraftChangeItem, { DraftChangeType } from '../model/DraftChangeItem';
import { Department, Equipment, EquipmentOption, User } from '../../models';
import { checkIdUpdateDraftChange, Draft } from '../AmplifyVersion';
import { DraftChangeJSON, updateDraftChangeItem } from './ReviewalDB';
import {
  ArchiveItem,
  findUsersForModelItems,
  setAllArchiveItemsToNewActiveID,
  setAllReferencesToNewID,
} from './ModelDB';
import { updateEquipment, updateProtocol } from '../../graphql/mutations';

export type EquipmentDB = {
  name: string;
  title: string;
  note: string;
  warning: string;
  instruction: string;
  optionItems: EquipmentOption[];
  departmentID: string;
  status: ProgressStatus | keyof typeof ProgressStatus;
  activeID: string | null | undefined;
  overrideID?: string | null | undefined;
  version: string | null | undefined;
  modifiedBy?: string;
  createdBy: string;

  taggedProtocols?: ProtocolItem[];
};

/**
 * This function will check if the Equipment is a draft version and delete it
 * @param equipmentItem The Equipment to check
 * @returns Success if ready to create a new Equipment or Failure if there is a draft version
 */
export const checkUpgradeDraftVersion = async (
  id: string,
  isActive: boolean
): Promise<Response> => {
  try {
    let results: Equipment[];
    if (isActive) {
      results = await DataStore.query(Equipment, (e) =>
        e.and((e) => [e.status.eq('DRAFT'), e.activeID.eq(id)])
      );
    } else {
      results = await DataStore.query(Equipment, (e) =>
        e.and((e) => [e.status.eq('DRAFT'), e.id.eq(id)])
      );
    }

    /* There is no current draft version */
    if (results == null || results.length === 0) {
      return {
        type: ResponseType.Success,
        data: undefined,
      };
    }
    if (results.length > 1) {
      return {
        type: ResponseType.Failure,
        data: 'There are multiple draft versions',
      };
    }

    let dbEquip = results[0];
    if (dbEquip.status === ProgressStatus.DRAFT) {
      let result = await DataStore.delete(Equipment, dbEquip.id);
      if (result == null) {
        return {
          type: ResponseType.Failure,
          data: 'The equipment did not delete correctly',
        };
      }
    }

    return {
      type: ResponseType.Success,
      data: dbEquip,
    };
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

/**
 * Create a new equipment in the database and choose the version
 * @param equipment EquipmentDB JSON format
 * @returns The successful equipmentItem or the error
 */
export const createEquipment = async (
  equipment: EquipmentDB | EquipmentItem,
  previousItem?: EquipmentItem
): Promise<Response> => {
  try {
    let json: EquipmentDB;
    if (
      equipment instanceof EquipmentItem ||
      (equipment as any).TAG === 'EquipmentItem'
    ) {
      let equipmentItem = equipment as EquipmentItem;
      json = {
        name: equipmentItem.name,
        status: equipmentItem.status,
        activeID: equipmentItem.activeID,
        overrideID: equipmentItem.overrideID,
        title: equipmentItem.title,
        note: equipmentItem.note,
        instruction: equipmentItem.instruction,
        warning: equipmentItem.warning,
        departmentID: equipmentItem.departmentID,
        version:
          equipmentItem.model?.version != null
            ? equipmentItem.model.version
            : 'v1.0.0',
        optionItems:
          equipmentItem.model.optionItems != null
            ? equipmentItem.model.optionItems
            : [],
        modifiedBy: equipmentItem.modifiedBy
          ? equipmentItem.modifiedBy.id
          : equipmentItem.model.modifiedBy || undefined,
        createdBy: equipmentItem.model.createdBy
          ? equipmentItem.model.createdBy
          : '',
      };
    } else json = equipment as EquipmentDB;

    let e: Equipment;

    /* Use Case 3: Updating a current DRAFT version */
    if (
      previousItem &&
      previousItem.status.includes('DRAFT') &&
      json.status.includes('DRAFT')
    ) {
      let dbEquip = await DataStore.query(Equipment, previousItem.uid);
      if (dbEquip == null) {
        return {
          type: ResponseType.Failure,
          data: 'The DRAFT Equipment does not exist could not update',
        };
      }

      e = await DataStore.save(
        Equipment.copyOf(dbEquip, (updated) => {
          updated.name = json.name;
          updated.title = json.title;
          updated.instruction = json.instruction;
          updated.note = json.note;
          updated.warning = json.warning;
          updated.optionItems = removeTypename(json.optionItems);
          updated.modifiedBy = json.modifiedBy;
        })
      );
    } else {
      /* Use Case 1, 2, & 4: Creating a DRAFT the first time */
      e = await DataStore.save(
        new Equipment({
          name: json.name,
          departmentID: json.departmentID,
          status: json.status,
          activeID: json.activeID,
          overrideID: json.overrideID,
          version: json.version,
          title: json.title,
          instruction: json.instruction,
          note: json.note,
          warning: json.warning,
          optionItems: removeTypename(json.optionItems),
          createdBy: json.createdBy,
          modifiedBy: json.modifiedBy,
        })
      );
    }

    /* Confirm that all items have an ID */
    json.optionItems = json.optionItems.map(
      (item: EquipmentOption, index: number) => {
        let newItem: EquipmentOption = {
          ...item,
          id: item.id ? item.id : generateID(),
          index: item.index == null ? index : item.index,
        };
        return newItem;
      }
    );

    if (globals.debug)
      console.log('EQUIPMENT json.optionItems', json.optionItems);

    let equipItem = new EquipmentItem(e);

    /* Validate the protocols point to the medication --TODO need to update this for if there is an ACTIVE ID */
    if (json.taggedProtocols != null) {
      for (let i = 0; i < json.taggedProtocols.length; i++) {
        let protocol = json.taggedProtocols[i];

        /* ACTIVE - pass ID, DRAFT and activeID is null (HAS NEVER BEEN PUBLISHED) - pass UID, DRAFT with previous publish - pass activeID */
        let equipID: string | null = getActiveID(equipItem);
        if (equipID == null) {
          return {
            type: ResponseType.Failure,
            data:
              'The equipment does not have a proper version ID ' +
              equipItem.uid +
              ' ' +
              equipItem.activeID +
              ' ' +
              equipItem.status,
          };
        }
        let result = await validatePointerID(
          protocol,
          equipID,
          json.modifiedBy,
          'Equipment'
        );
        if (result.type === ResponseType.Failure) {
          return {
            type: ResponseType.Failure,
            data: result.data,
          };
        }
      }
    }
    return {
      type: ResponseType.Success,
      data: equipItem,
    };
  } catch (e) {
    if (globals.debug) console.log('ERROR CREATING EQUIPMENT:', e, equipment);

    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

export const fetchEquipment = async (
  dep: DepartmentItem,
  db?: DatabaseResponse,
  useDataStore: boolean = true,
  waitForUsers: boolean = false
): Promise<Response> => {
  try {
    let depIDs = [dep.id];
    if (dep.parentDep) depIDs.push(dep.parentDep.id);
    if (dep.parentDep?.parentDep) depIDs.push(dep.parentDep.parentDep.id);

    let equipmentList: Equipment[];
    if (useDataStore) {
      equipmentList = await DataStore.query(Equipment, (m) =>
        m.and((m) => [
          m.or((m) => depIDs.map((id) => m.departmentID.eq(id))),
          m.and((m) => [m.status.ne('ARCHIVE'), m.status.ne('DELETED')]),
        ])
      );
    } else {
      equipmentList = await executeQuery(listEquipment, {
        filter: {
          and: [
            { or: depIDs.map((id) => ({ departmentID: { eq: id } })) },
            { status: { ne: 'ARCHIVE' } },
            { status: { ne: 'DELETED' } },
          ],
        },
      });
    }
    // let promises: Promise<User | null>[] = [];
    let equipment: EquipmentItem[] = [];
    for (let i = 0; i < equipmentList.length; i++) {
      let equip = new EquipmentItem(equipmentList[i]);
      // promises.push(equip.findUser());
      /* Take out the active version if there is one */
      mapModelItems(equip, equipment, equip.status, dep);
    }
    // if (waitForUsers) await Promise.all(promises);
    if (waitForUsers) await findUsersForModelItems(equipment);

    equipment.sort((a, b) => a.getName().localeCompare(b.getName()));
    return {
      type: ResponseType.Success,
      data: equipment,
    };
  } catch (error) {
    console.error('Error fetching equipment:', error);
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

/**
 * This function will publish the equipment to the database
 *    1. Create a new ARCHEIVED equipment based on the current ACTIVE equipment
 *    2. Update the ACTIVE equipment with the new information
 *    3. Delete the DRAFT equipment
 * @param draftEquipmentItem The equipment to publish
 */

export const publishEquipment = async (
  draftEquipmentItem: EquipmentItem,
  draftChangeItem?: DraftChangeItem
): Promise<Response> => {
  try {
    /* Base Case 1 -- check if the equipment is configured correctly as a draft version */
    if (draftEquipmentItem.status !== ProgressStatus.DRAFT) {
      return {
        type: ResponseType.Failure,
        data: 'The equipment is not a draft version',
      };
    }

    let dbEquip = await DataStore.query(Equipment, draftEquipmentItem.uid);
    if (dbEquip == null) {
      return {
        type: ResponseType.Failure,
        data: 'The equipment does not exist',
      };
    }

    let activeEquipment: Equipment;

    /* Use case 1: Creating the FIRST active version */
    if (draftEquipmentItem.activeID == null) {
      /* Update the draft equipment to be active */
      activeEquipment = await DataStore.save(
        Equipment.copyOf(dbEquip, (updated) => {
          updated.status = ProgressStatus.ACTIVE;
        })
      );
    } else {
      /* Use case 2: Upgrading a active version */
      /* Step 1. Fetch the active equipment item */
      let id: string = draftEquipmentItem.activeID;
      let curEquipment = await DataStore.query(Equipment, id);

      /* Base Case 3 -- check if the active equipment exists */
      if (curEquipment == null) {
        return {
          type: ResponseType.Failure,
          data: 'The active equipment does not exist',
        };
      }

      let archive = new EquipmentItem(curEquipment);
      archive.status = ProgressStatus.ARCHIVE;
      archive.activeID = curEquipment.id;

      // 2. Create a new ARCHEIVED equipment based on the current ACTIVE equipment
      let archiveResult: Response = await createEquipment(archive);
      if (archiveResult.type === ResponseType.Failure) return archiveResult;
      archive = archiveResult.data as EquipmentItem;

      // 2. Update the ACTIVE equipment with the new information
      activeEquipment = await DataStore.save(
        Equipment.copyOf(curEquipment, (updated) => {
          updated.name = draftEquipmentItem.name;
          updated.optionItems = removeTypename(
            draftEquipmentItem.model.optionItems
          );
          updated.title = draftEquipmentItem.title;
          updated.note = draftEquipmentItem.note;
          updated.warning = draftEquipmentItem.warning;
          updated.instruction = draftEquipmentItem.instruction;

          updated.createdBy = draftEquipmentItem.model.createdBy;
          updated.modifiedBy = draftEquipmentItem.model.modifiedBy;

          updated.status = ProgressStatus.ACTIVE;
          updated.activeID = null;
          updated.version = draftEquipmentItem.version;
        })
      );

      // 3. Delete the DRAFT equipment
      let draftEquipment = await DataStore.delete(
        Equipment,
        draftEquipmentItem.uid
      );
      if (draftEquipment == null) {
        return {
          type: ResponseType.Failure,
          data: 'The draft equipment does not exist',
        };
      }

      // 4. Query if there any closed draft changes with the actvie model item
      checkIdUpdateDraftChange(activeEquipment.id, archive.uid);

      // 5. If there is a draftChangeItem then update the changeID to the new active category and the previousID to the archeived category
      if (draftChangeItem) {
        console.log('Draft Change Item', draftChangeItem);
        let new_dc: DraftChangeJSON = {
          previousDraftChange: draftChangeItem,
          changeItem: activeEquipment.id,
          changeType: draftChangeItem.changeType,
          previousItem: archive.uid,
          isClosed: true,
        };

        console.log('Preivous Draft Change Item', draftChangeItem);
        updateDraftChangeItem(new_dc).then((result) => {
          console.log('Modified Draft Change Item', result);
          if (result == null) {
            return {
              type: ResponseType.Failure,
              data: 'The draft change item did not update correctly',
            };
          }
        });
      }
    }

    let equipItem = new EquipmentItem(activeEquipment);
    return {
      type: ResponseType.Success,
      data: equipItem,
    };
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

/**
 * This function will delete an equipment item from the database
 * @param equipmentItem The equipment item to delete
 * @param isSoft Whether to soft delete the equipment item
 * @returns The deleted equipment item or an error
 * @description
 * IsSoft = True
 *    1. Fetch the item from the database to verify it exists
 *    2. Create a new equipment item with the same data in the DELETED status
 *    3. Delete the ACTIVE item from the database
 *    4. Set all the archive items activeID to the new equipment item
 *    5. Check if there any draft changes with the active equipment and update the changeID to the new equipment item
 *    6. Check if for Protocol references and update the reference to the new equipment item
 * IsSoft = False
 *    1. Delete the item from the database
 */
export const deleteEquipmentItem = async (
  equipmentItem: EquipmentItem,
  isSoft: boolean
): Promise<Response> => {
  try {
    let id: string = equipmentItem.uid;
    if (isSoft) {
      let equipment = await DataStore.query(Equipment, id);
      if (equipment == null) {
        return {
          type: ResponseType.Failure,
          data: 'The equipment does not exist',
        };
      }

      equipmentItem.status = ProgressStatus.DELETED;
      let response = await createEquipment(equipmentItem);
      if (response.type === ResponseType.Failure) {
        return response;
      }
      let newDeletedEquipment = response.data as EquipmentItem;
      let deletedItem = await DataStore.delete(Equipment, id).catch((error) => {
        console.error('Error in deleteEquipmentItem: ', error);
        return null;
      });
      if (deletedItem == null) {
        return {
          type: ResponseType.Failure,
          data: 'The equipment did not delete correctly',
        };
      }

      /* Update all the archive items to the new active item */
      setAllArchiveItemsToNewActiveID(
        equipmentItem,
        newDeletedEquipment,
        updateEquipment,
        equipmentByDepartmentID,
        {
          departmentID: equipmentItem.departmentID,
        }
      )
        .then((result: Response) => {
          if (result.type === ResponseType.Failure) {
            console.error(
              'Error in setAllArchiveItemsToNewActiveID',
              result.data
            );
            return;
          }
          if (globals.debug)
            console.log('setAllArchiveItemsToNewActiveID', result.data);
        })
        .catch((e) => {
          console.error('Error in setAllArchiveItemsToNewActiveID', e);
          return {
            type: ResponseType.Failure,
            data: e,
          };
        });

      /* Check to see if there was a DraftChange referencing this item and update it to the new ID */
      checkIdUpdateDraftChange(equipmentItem.id, newDeletedEquipment.uid);

      /* Check to see if there was a Protocol referencing this item and update it to the new ID */
      setAllReferencesToNewID(
        equipmentItem,
        newDeletedEquipment,
        updateProtocol,
        protocolsByDepartmentID,
        'equipmentIDs',
        {
          departmentID: equipmentItem.departmentID,
          isArray: true,
        }
      );
    } else {
      let equipment = await DataStore.delete(Equipment, id);
      if (equipment == null) {
        return {
          type: ResponseType.Failure,
          data: 'The equipment does not exist',
        };
      }
    }
    return {
      type: ResponseType.Success,
      data: equipmentItem,
    };
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

export const isEquipmentDraftCreated = async (
  department: DepartmentItem
): Promise<Response> => {
  try {
    let drafts = await DataStore.query(Equipment, (e) =>
      e.and((e) => [e.status.eq('DRAFT'), e.departmentID.eq(department.id)])
    );
    return {
      type: ResponseType.Success,
      data: drafts.length !== 0,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

/**
 * This function will get all the equipment drafts from the database
 * @param db The database to get the equipment drafts from
 * @returns Success if the drafts were found with an array of the updates or Failure if there was an error
 */
export const getEquipmentDrafts = async (
  db: DatabaseResponse
): Promise<Response> => {
  try {
    let updates: any[] = [];
    let modelUpdates = await DataStore.query(Equipment, (c) =>
      c.and((c) => [c.status.eq('DRAFT'), c.departmentID.eq(db.department.id)])
    );

    for (let i = 0; i < modelUpdates.length; i++) {
      let model = new EquipmentItem(modelUpdates[i]);
      let activeItem = db.equipment.find(
        (c) =>
          c.uid === model.activeID ||
          (c.activeItem && c.activeItem.uid === model.activeID)
      );
      if (activeItem) {
        model.activeItem =
          activeItem.uid === model.activeID
            ? activeItem
            : activeItem.activeItem;
      }

      updates.push({
        model: model,
        title: 'Equipment ' + model.name,
        message: getChangeDescription(model),
        changeType: DraftChangeType.EQUIPMENT,
      });
    }

    return {
      type: ResponseType.Success,
      data: updates,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const convertEquipmentChangeToDraft = (
  dc: DraftChangeItem
): Draft | null => {
  try {
    if (dc.changeItem == null) {
      return null;
    }
    let update: Draft = {
      draftChangeItem: dc,
      model: dc.changeItem,
      title: 'Equipment ' + dc.changeItem.name,
      message: getChangeDescription(dc.changeItem as EquipmentItem),
      changeType: DraftChangeType.EQUIPMENT,
    };

    return update;
  } catch (error) {
    return null;
  }
};

function getChangeDescription(draftItem: EquipmentItem): string {
  if (draftItem.activeItem == null)
    return `Created Equipment: ${draftItem.name}`;
  return `Updated Equipment: ${draftItem.name}`;
}

export const removeCurrentEquipmentDrafts = async (
  db: DatabaseResponse
): Promise<Response> => {
  try {
    let updates: any[] = [];
    let draftEquipments = await DataStore.query(Equipment, (e) =>
      e.and((e) => [e.status.eq('DRAFT'), e.departmentID.eq(db.department.id)])
    );
    for (let i = 0; i < draftEquipments.length; i++) {
      let equip: Equipment = draftEquipments[i];
      await DataStore.delete(equip);
      updates.push({
        model: equip,
        message: `Removed Equipment: ${equip.name}`,
      });
    }

    return {
      type: ResponseType.Success,
      data: updates,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

/* GraphQL API Queries */
export const getEquipmentByID = async (
  db: DatabaseResponse,
  id: string
): Promise<EquipmentItem | null> => {
  return new Promise(async (resolve, reject) => {
    try {
      /* Fetch the category from the database */
      const dbEquip = findItemByID(id, db.equipment);
      if (dbEquip != null) return resolve(dbEquip as EquipmentItem);
      else {
        executeSingleQuery(getEquipment, { id: id }, 1500)
          .then((equipment: Equipment | null | undefined) => {
            if (equipment == null) {
              resolve(null);
            } else {
              let equip = new EquipmentItem(equipment);
              /* TODO MAP THE SUB ITEMS AND TO PROTOCOLS */
              resolve(equip);
            }
          })
          .catch((error) => {
            reject(error);
          });
      }
    } catch (error: any) {
      reject(error);
    }
  });
};

/**
 * Fetch the equipment by the status
 * @param dep The department to fetch the equipment from
 * @param status The status of the equipment to fetch
 * @param waitForUsers Whether to wait for the users to be fetched
 * @returns The equipment that were fetched
 */
export const fetchEquipmentByStatusAPI = async (
  dep: DepartmentItem,
  db: DatabaseResponse,
  status: ProgressStatus = ProgressStatus.ACTIVE,
  waitForUsers: boolean = false,
  includeErrors: boolean = false
): Promise<Response> => {
  try {
    /* Fetch the protocols for this category and then sort by index */
    let depIDs = dep.getAllParentDepIDs();
    let start = new Date();
    let promises: any[] = [];
    for (let i = 0; i < depIDs.length; i++) {
      promises.push(
        executeQuery(equipmentByDepartmentID, {
          departmentID: depIDs[i],
          filter: {
            and: [
              {
                status: {
                  eq: status,
                },
              },
              {
                _deleted: {
                  ne: true,
                },
              },
            ],
          },
        })
      );
    }
    let equipmentResults: any = await Promise.all(promises);
    let equipmentList: EquipmentItem[] = [];
    for (let i = 0; i < equipmentResults.length; i++) {
      if (equipmentResults[i] != null)
        equipmentList.push(
          ...equipmentResults[i].map((e: Equipment) => new EquipmentItem(e))
        );
    }
    if (globals.debug)
      console.log(
        'Equipment fetched in',
        new Date().getTime() - start.getTime(),
        'ms',
        equipmentList
      );

    let archiveItems: ArchiveItem[] = [];
    for (let i = 0; i < equipmentList.length; i++) {
      const eq = equipmentList[i];
      if (eq.activeID == null) {
        if (includeErrors === false) continue;
        console.log('Equipment has no activeID', eq.name);
        let archiveItem = archiveItems.find((item) => item.id === 'ERROR');
        if (archiveItem) {
          let items = archiveItem.items;
          items.push(eq);
          archiveItem.items = items;
        } else {
          archiveItems.push({
            id: 'ERROR',
            activeItem: eq,
            items: [eq],
          });
        }
      } else {
        /* 1-2-25 Gagan: Needed to check uid and activeID if an item is in DRAFT mode currently */
        const activeEquipment = db.equipment.find(
          (c) => c.uid === eq.activeID || c.activeID === eq.activeID
        );
        if (activeEquipment == null) {
          if (includeErrors === false) continue;
          console.log('Equipment has no activeID', eq.name);
        }
        let archiveItem = archiveItems.find((item) => item.id === eq.activeID);
        if (archiveItem) {
          let items = archiveItem.items;
          items.push(eq);
          items.sort((a: EquipmentItem, b: EquipmentItem) => {
            if (a.version === b.version) return a.name.localeCompare(b.name);
            return compareVersions(
              a.version || 'v1.0.0',
              b.version || 'v1.0.0'
            ) as number;
          });
          archiveItem.items = items;
        } else {
          archiveItems.push({
            id: eq.activeID || 'ERROR',
            activeItem: activeEquipment as EquipmentItem,
            items: [eq],
          });
        }
      }
    }

    return {
      type: ResponseType.Success,
      data: archiveItems,
    };
  } catch (error) {
    console.error('Error fetching protocols:', error);
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const fetchAllEquipmentForProtocol = async (
  db: DatabaseResponse,
  dep: DepartmentItem,
  protocol: ProtocolItem
): Promise<Response> => {
  try {
    let protocolID = getActiveID(protocol);
    if (protocolID == null) {
      return {
        type: ResponseType.Failure,
        data: 'The protocol does not have a proper version ID',
      };
    }

    let equipmentIDs: string[] = protocol.equipmentIDs ?? [];
    if (equipmentIDs.length === 0) {
      return {
        type: ResponseType.Success,
        data: [],
      };
    }

    let equipmentList = await executeQuery(equipmentByDepartmentID, {
      departmentID: dep.id,
      filter: {
        and: [
          {
            _deleted: {
              ne: true,
            },
          },
          {
            status: {
              ne: 'DEACTIVATED',
            },
          },
          {
            status: {
              ne: 'DRAFT',
            },
          },
        ],
      },
    });

    // Filter out items from equipmentList that have uid or activeID that is not in the equipmentIDs array
    equipmentList = equipmentList.filter(
      (item: any) =>
        equipmentIDs.includes(item.id) || equipmentIDs.includes(item.activeID)
    );

    equipmentList = equipmentList.map((item: any) => new EquipmentItem(item));

    let archiveItems: ArchiveItem[] = [];
    for (let i = 0; i < equipmentList.length; i++) {
      const med = equipmentList[i];

      // When med is in ACTIVE or DELETED
      if (
        med.activeID == null &&
        (med.status === 'ACTIVE' || med.status === 'DELETED')
      ) {
        // Find for an existing archive item with the same uid
        let archiveItem = archiveItems.find((item) => item.id === med.uid);
        if (archiveItem) {
          archiveItem.activeItem = med;
        } else {
          // If not found, create a new archive item
          archiveItems.push({
            id: med.uid,
            activeItem: med,
            items: [],
          });
        }
      }
      // When med is in ARCHIVE or DELETED
      else if (med.activeID != null && med.status === 'ARCHIVE') {
        // Find for an existing archive item with the same activeID
        let archiveItem = archiveItems.find((item) => item.id === med.activeID);
        if (archiveItem) {
          // If found, add this med to the items array
          let items = archiveItem.items;
          items.push(med);
          // Sort the items array by version
          items.sort((a: EquipmentItem, b: EquipmentItem) => {
            if (a.version === b.version) return a.name.localeCompare(b.name);
            return compareVersions(
              a.version || 'v1.0.0',
              b.version || 'v1.0.0'
            ) as number;
          });
          archiveItem.items = items;
        } else {
          // If not found, create a new archive item
          archiveItems.push({
            id: med.activeID || 'ERROR',
            activeItem: med,
            items: [med],
          });
        }
      }
    }

    // Filter out any activeItems that are in the archiveItems with activeItem.status !== 'ACTIVE' or 'DELETED'
    archiveItems = archiveItems.filter(
      (item) =>
        item.activeItem.status === 'ACTIVE' ||
        item.activeItem.status === 'DELETED'
    );

    return {
      type: ResponseType.Success,
      data: archiveItems,
    };
  } catch (error) {
    console.error('Error fetching equipmentList:', error);
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};
