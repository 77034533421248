import { useMemo, useState } from 'react';
import DepartmentItem from '../../../data/model/DepartmentItem';
import { HiPlus } from 'react-icons/hi';
import { Sidebar } from 'primereact/sidebar';
import { InputText } from 'primereact/inputtext';
import { Button } from 'react-bootstrap';
import { DatabaseResponse } from '../../../data/AmplifyDB';
import React from 'react';
import { getDepartmentsFromState } from '../../_global/common/Utils';
import { useSelector } from 'react-redux';
import CategoryItem from '../../../data/model/CategoryItem';
import { AiOutlineClose } from 'react-icons/ai';
import { handleCopy } from '../../_global/common/Utils';
import { BiCopy, BiSolidCopy } from 'react-icons/bi';
import { User } from '../../../models';
import { FaChevronDown, FaChevronRight } from 'react-icons/fa';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import SelectDepartmentsModal from './SelectDepartmentsModal';

interface FolderArchiveModalProps {
  isVisible: boolean;
  handleClose: () => void;
  folder: CategoryItem;
  onRestore: (folder: CategoryItem) => void;
  activeID?: string | undefined | null;
}

const FolderArchiveModal: React.FC<FolderArchiveModalProps> = (
  props: FolderArchiveModalProps
) => {
  const { isVisible, handleClose, folder, onRestore, activeID } = props;

  const [isCopied, setIsCopied] = useState<string | undefined | null>(
    undefined
  );
  const user: User = useSelector((state: any) => state?.user);
  const [isRestrictive] = useState<boolean>(
    folder?.model?.isRestrictive ?? false
  );

  const [database, setDatabase] = useState<DatabaseResponse>(
    useSelector((state: any) => state?.protocol?.departmentItem)
  );
  const department: DepartmentItem = useMemo(() => {
    return database.department;
  }, [database]);
  const [owner, setOwner] = useState<DepartmentItem | null | undefined>(
    department
  );
  const reducerState = useSelector((state: any) => state.department);
  const { subDeps, allSubDeps, userDepartments } = useMemo(() => {
    return getDepartmentsFromState(department, reducerState);
  }, [department, reducerState]);
  const nameRef = React.useRef<HTMLInputElement>(null);

  const pairedDeps = useMemo<DepartmentItem[]>(() => {
    const ids = folder?.model?.pairedDepIDs ?? [];
    return allSubDeps.filter((dep) => ids.includes(dep.id));
  }, [folder, allSubDeps]);

  const [isSelectingDepartments, setIsSelectingDepartments] = useState(false);

  const customHeader = (
    <div
      className="buttonContainer contentTitleLarge hoverText"
      onClick={handleClose}
    >
      <span className="">
        <HiPlus className="header-icon" style={{ marginLeft: '5px' }} /> Add{' '}
      </span>
    </div>
  );

  const availableDepartments = useMemo(() => {
    if (owner == null) return allSubDeps;
    const ownerAllSubDeps = getDepartmentsFromState(
      owner,
      reducerState
    ).allSubDeps;
    let list = ownerAllSubDeps ?? [];
    if (folder) {
      list = list.filter((dep) => {
        if (folder.pairedDepIDs) {
          return folder.pairedDepIDs.includes(dep.id);
        } else return false;
      });
    }
    return list;
  }, [owner, folder, allSubDeps, reducerState]);

  const handleRestore = () => {
    if (onRestore) onRestore(folder);
  };

  return (
    <Sidebar
      visible={isVisible}
      position="right"
      onHide={handleClose}
      header={customHeader}
      style={{ width: '30%', minWidth: '400px' }}
      className={isVisible ? 'protocolSidebar sidebarWidth' : 'sidebar-hidden'}
    >
      <div className="sidebarContainer">
        {owner && (
          <SelectDepartmentsModal
            title="Restricted Departments"
            description="This folder is restricted to the following departments:"
            availableDepartments={availableDepartments}
            department={owner}
            values={pairedDeps}
            isVisible={isSelectingDepartments}
            handleClose={() => setIsSelectingDepartments(false)}
            handleSubmit={() => {}}
            includeOwner={true}
            isReadOnly={true}
          />
        )}

        <div
          className="buttonContainer contentTitleLarge"
          onClick={handleClose}
        >
          Archive Folder
        </div>
        <div className="contentText">
          Archive this folder of version {folder?.version}
        </div>

        <div>
          <label
            htmlFor="name"
            className={`notification-css-title`}
            style={{ fontSize: '16px' }}
          >
            Name
          </label>
          <div className="input-container">
            <InputText
              ref={nameRef}
              type="text"
              className=" notification-model"
              id="name"
              name="name"
              disabled={true}
              value={folder?.name}
              style={{
                fontSize: '16px',
                cursor: 'auto',
                padding: '20px 20px',
              }}
            />
            <div className="input-border"></div>
          </div>

          {department.isMultiDep && (
            <>
              <Accordion
                style={{
                  borderRadius: '5px',
                  boxShadow: 'none',
                  alignContent: 'center',
                  justifyContent: 'center',

                  fontSize: '16px',
                  marginTop: '20px',
                  backgroundColor: 'transparent',
                  border: '1px solid #ccc',
                }}
                onChange={(e, expanded) => {}}
              >
                <AccordionSummary
                  expandIcon={<FaChevronDown />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  Advanced Settings
                </AccordionSummary>
                <AccordionDetails>
                  <label htmlFor="" className={`notification-css-title`}>
                    Folder Owner
                  </label>
                  <span
                    className="contentText greyText"
                    style={{ fontSize: '13px', marginLeft: '6px' }}
                  >
                    This is the department that will own the folder.
                  </span>
                  <div className="input-container">
                    <InputText
                      type="text"
                      className="form-control-general"
                      id="owner"
                      name="owner"
                      data-testid="owner"
                      value={owner?.name}
                      onChange={(e: any) => {}}
                      disabled={true}
                    />
                    <div className="input-border"></div>
                  </div>

                  {availableDepartments.length === 0 && (
                    <div
                      style={{
                        marginTop: '20px',
                      }}
                    >
                      <label htmlFor="" className={`notification-css-title`}>
                        Folder Subscription Note:
                      </label>
                      <span
                        className="contentText greyText"
                        style={{ fontSize: '13px', marginLeft: '6px' }}
                      >
                        This folder cannot add any other departments because the
                        folder is restricted to {owner?.name} only. Update the
                        folder's access to allow other departments to subscribe
                        to it.
                      </span>
                    </div>
                  )}

                  {availableDepartments.length > 0 && owner && (
                    <>
                      <label htmlFor="" className={`notification-css-title`}>
                        Folder Type
                      </label>
                      <div className="input-container">
                        <InputText
                          type="text"
                          className="form-control-general"
                          id="folderType"
                          name="folderType"
                          data-testid="folderType"
                          value={isRestrictive ? 'Restrictive' : 'Inclusive'}
                          onChange={(e: any) => {}}
                          disabled={true}
                        />
                        <div className="input-border"></div>
                      </div>
                      {isRestrictive ? (
                        <span className={`notification-css`}>
                          Restrictive: Restricts this folder to only departments
                          that are paired to it. Automatically adds the folder
                          owner.
                        </span>
                      ) : (
                        <span className={`notification-css`}>
                          Inclusive: Allows the folder to propagate to all{' '}
                          {availableDepartments.length + ' '}
                          department(s) under the owner.
                        </span>
                      )}
                      {isRestrictive && (
                        <div
                          className={`hoverable-button-with-text`}
                          onClick={() => setIsSelectingDepartments(true)}
                        >
                          <label className="hoverable-button-text">
                            {pairedDeps.length + 1} Departments Selected
                          </label>
                          <FaChevronRight
                            style={{ marginLeft: '5px' }}
                            size="14px"
                          />
                        </div>
                      )}
                    </>
                  )}

                  {user.type === 'ADMIN' && activeID && (
                    <>
                      <label
                        htmlFor="Name"
                        className="ketamine-general-label"
                        style={{
                          marginTop: '2rem',
                          marginLeft: '0px',
                          fontSize: '16px',
                        }}
                      >
                        Developer Controls
                      </label>
                      <p
                        className="contentText greyText"
                        style={{ fontSize: '13px', marginLeft: '6px' }}
                      >
                        These are controls that should ONLY be used by
                        developers.
                      </p>
                      <label htmlFor="Name" className="ketamine-general-label">
                        Active ID:{' '}
                        <span>
                          {isCopied && isCopied === activeID ? (
                            <BiSolidCopy
                              color={'#00534C'}
                              size="1rem"
                              className="copy_icon"
                            />
                          ) : (
                            <BiCopy
                              size="1rem"
                              className="copy_icon"
                              onClick={(e) =>
                                handleCopy(activeID || '', e, setIsCopied)
                              }
                            />
                          )}
                        </span>
                      </label>
                      <div className="input-container">
                        <InputText
                          type="text"
                          className="form-control-general"
                          id="activeID"
                          name="activeID"
                          data-testid="activeID"
                          value={activeID ? activeID : ''}
                          onChange={(e: any) => {}}
                          disabled={true}
                        />
                        <div className="input-border"></div>
                      </div>
                    </>
                  )}
                </AccordionDetails>
              </Accordion>
            </>
          )}
        </div>

        <div className="sidebarButtons">
          <Button
            className="secondary-button btn-rightMargin"
            data-testid="cancelBtn"
            onClick={handleClose}
          >
            <span>
              <AiOutlineClose className="icon-normal" />
            </span>{' '}
            Cancel
          </Button>

          <Button
            className="red-background-button btn-rightMargin "
            data-testid="deleteBtn"
            onClick={() => {
              handleRestore();
            }}
            disabled={folder?.status === 'ACTIVE'}
          >
            {'Restore'}
          </Button>
        </div>
      </div>
    </Sidebar>
  );
};

export default FolderArchiveModal;
