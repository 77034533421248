import React, { useCallback, useMemo } from 'react';
import { FaChevronRight, FaLock } from 'react-icons/fa6';
import {
  getDepartmentsFromState,
  getFormattedDate,
} from '../../../_global/common/Utils';
import { ProgressStatus, User } from '../../../../models';
import ProtocolHeader from '../../protocol/ProtocolHeader';
import { useLocation, useNavigate } from 'react-router-dom';
import { ArchiveItem } from '../../../../data/functions/ModelDB';
import Status from '../../../components/ProgressStatus/ProgressStatus';
import ProtocolItem from '../../../../data/model/ProtocolItem';
import { useSelector } from 'react-redux';
import DataList, { Header } from '../../database/lists/DataList';

const DEFAULT_USERNAME = 'Hinckley Medical';

const ListArchiveProtocols = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;

  // Validate location state
  if (!state?.data || !state?.department || !state?.database) {
    navigate('/archive');
  }

  const { data: protocol, department, database } = state;

  const protocolList = useMemo(() => {
    return protocol.sort((a: ArchiveItem, b: ArchiveItem) => {
      let aDate = new Date(a.activeItem.modifiedAt || Date.now());
      let bDate = new Date(b.activeItem.modifiedAt || Date.now());
      return bDate.getTime() - aDate.getTime();
    });
  }, [protocol]);

  const reducerState = useSelector((state: any) => state.department);
  const { allSubDeps } = useMemo(() => {
    return getDepartmentsFromState(department, reducerState);
  }, [department, reducerState]);

  const handleItemClick = useCallback(
    (prot: ArchiveItem) => {
      navigate(`/archive/list-protocols-details`, {
        state: {
          data: state.data,
          department: department,
          database: database,
          archiveData: [
            ...prot.items,
            prot.activeItem.status === 'DRAFT'
              ? prot.activeItem.activeItem
              : prot.activeItem,
          ],
          activeItem: prot.activeItem,
        },
      });
    },
    [navigate, state.data, department, database]
  );

  const handleBack = useCallback(() => {
    sessionStorage.removeItem('DataList-' + location.pathname);
    navigate('/archive');
  }, [navigate, location.pathname]);

  const getActiveForm = (item: ArchiveItem): ProtocolItem => {
    return (item.activeItem as ProtocolItem).status === ProgressStatus.DRAFT
      ? ((item.activeItem as ProtocolItem).activeItem as ProtocolItem)
      : (item.activeItem as ProtocolItem);
  };

  const headers: Header<ArchiveItem>[] = useMemo(
    () => [
      {
        key: 'name',
        name: 'Name',
        sortable: true,
        flex: 2,
        render: (item: ArchiveItem) => {
          const activeForm = getActiveForm(item);
          const isOwner = activeForm?.departmentID === department.id;

          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {!(isOwner && activeForm?.status !== 'DEACTIVATED') && (
                <div style={{ marginRight: '8px' }}>
                  <FaLock className="table-icon" color="#A3A3A3" />
                </div>
              )}
              {activeForm?.name}
              {activeForm?.status !== 'ACTIVE' && (
                <Status
                  status={activeForm?.status as ProgressStatus}
                  style={{ marginLeft: '8px' }}
                />
              )}
            </div>
          );
        },
      },
      {
        key: 'subs',
        name: 'Subs',
        sortable: true,
        flex: 0.75,
        render: (item: ArchiveItem) => {
          const activeForm = getActiveForm(item);
          const availableSubs = (allSubDeps?.length ?? 0) + 1;
          const itemSubs = !activeForm?.model?.isRestrictive
            ? availableSubs
            : (activeForm?.model?.pairedDepIDs?.length ?? 0);
          return `${itemSubs}/${availableSubs}`;
        },
      },
      {
        key: 'access',
        name: 'Access',
        sortable: true,
        flex: 0.75,
        render: (item: ArchiveItem) => {
          const activeForm = getActiveForm(item);
          const status: 'Private' | 'Public' | 'Protected' = !activeForm?.model
            ?.isPublic
            ? 'Private'
            : activeForm?.model?.keychainID
              ? 'Protected'
              : 'Public';
          return <Status status={status} />;
        },
      },
      {
        key: 'modified_date',
        name: 'Archive Date',
        sortable: true,
        flex: 1,
        render: (item: ArchiveItem) => {
          const activeForm = getActiveForm(item);
          return getFormattedDate(
            new Date(activeForm?.modifiedAt || Date.now()),
            true
          );
        },
      },
      {
        key: 'modified_by',
        name: 'Archived By',
        sortable: true,
        flex: 1,
        render: (item: ArchiveItem) => {
          const activeForm = getActiveForm(item);
          const userId =
            activeForm?.model?.modifiedBy || activeForm?.model?.createdBy;
          const user = userId
            ? database.users.find((user: User) => user.id === userId)
            : null;
          return user ? `${user.firstName} ${user.lastName}` : DEFAULT_USERNAME;
        },
      },
      {
        key: 'archived_items',
        name: 'Archived Items',
        sortable: true,
        flex: 1,
        render: (item: ArchiveItem) => (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
              paddingRight: '15px',
            }}
          >
            <span>{item.items.length}</span>
            <FaChevronRight className="icon-normal" style={{ margin: '4px' }} />
          </div>
        ),
      },
    ],
    [department.id, database.users, allSubDeps]
  );

  const handleSortItem = useCallback(
    (key: string, direction: string, a: ArchiveItem, b: ArchiveItem) => {
      const getActiveForm = (item: ArchiveItem): ProtocolItem => {
        return (item.activeItem as ProtocolItem).status === ProgressStatus.DRAFT
          ? ((item.activeItem as ProtocolItem).activeItem as ProtocolItem)
          : (item.activeItem as ProtocolItem);
      };

      const activeFormA = getActiveForm(a);
      const activeFormB = getActiveForm(b);

      if (key === 'name') {
        const aName = activeFormA?.name || '';
        const bName = activeFormB?.name || '';
        return direction === 'asc'
          ? aName.localeCompare(bName)
          : bName.localeCompare(aName);
      } else if (key === 'modified_date') {
        const aDate = new Date(activeFormA?.modifiedAt || Date.now());
        const bDate = new Date(activeFormB?.modifiedAt || Date.now());
        return direction === 'asc'
          ? aDate.getTime() - bDate.getTime()
          : bDate.getTime() - aDate.getTime();
      } else if (key === 'archived_items') {
        return direction === 'asc'
          ? a.items.length - b.items.length
          : b.items.length - a.items.length;
      } else if (key === 'subs') {
        const aCount = !activeFormA?.model?.isRestrictive
          ? (allSubDeps?.length ?? 0) + 1
          : (activeFormA?.model?.pairedDepIDs?.length ?? 0);
        const bCount = !activeFormB?.model?.isRestrictive
          ? (allSubDeps?.length ?? 0) + 1
          : (activeFormB?.model?.pairedDepIDs?.length ?? 0);
        return direction === 'asc' ? aCount - bCount : bCount - aCount;
      } else if (key === 'access') {
        const aStatus = activeFormA?.model?.isPublic
          ? 'Public'
          : activeFormA?.model?.keychainID
            ? 'Protected'
            : 'Private';
        const bStatus = activeFormB?.model?.isPublic
          ? 'Public'
          : activeFormB?.model?.keychainID
            ? 'Protected'
            : 'Private';
        return direction === 'asc'
          ? aStatus.localeCompare(bStatus)
          : bStatus.localeCompare(aStatus);
      } else if (key === 'modified_by') {
        const aUserId =
          activeFormA?.model?.modifiedBy || activeFormA?.model?.createdBy;
        const bUserId =
          activeFormB?.model?.modifiedBy || activeFormB?.model?.createdBy;

        const aUser = aUserId
          ? database.users.find((user: User) => user.id === aUserId)
          : null;
        const bUser = bUserId
          ? database.users.find((user: User) => user.id === bUserId)
          : null;

        const aName = aUser
          ? `${aUser.firstName} ${aUser.lastName}`
          : DEFAULT_USERNAME;
        const bName = bUser
          ? `${bUser.firstName} ${bUser.lastName}`
          : DEFAULT_USERNAME;

        return direction === 'asc'
          ? aName.toLowerCase().localeCompare(bName.toLowerCase())
          : bName.toLowerCase().localeCompare(aName.toLowerCase());
      }
      return 0;
    },
    [allSubDeps, database.users]
  );

  const searchFilter = useCallback((item: ArchiveItem, searchQuery: string) => {
    return item.activeItem?.name
      ?.toLowerCase()
      .includes(searchQuery.toLowerCase());
  }, []);

  return (
    <div className="screen-container">
      <ProtocolHeader
        isBackButton={true}
        handleCancel={handleBack}
        name={`Protocols: ${protocolList.length} Archive${protocolList.length === 1 ? '' : 's'}`}
        page={`${department.name} Archive`}
        type="protocol"
      />
      <DataList<ArchiveItem, never>
        items={protocolList}
        headers={headers}
        onItemClick={handleItemClick}
        searchPlaceholder="Search Protocols..."
        sortItem={handleSortItem}
        searchFilter={searchFilter}
      />
    </div>
  );
};

export default ListArchiveProtocols;
