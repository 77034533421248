import React, { useCallback, useMemo } from 'react';
import { FaChevronRight, FaLock } from 'react-icons/fa6';
import {
  getDepartmentsFromState,
  getFormattedDate,
} from '../../../_global/common/Utils';
import { ProgressStatus, User } from '../../../../models';
import ProtocolHeader from '../../protocol/ProtocolHeader';
import { useLocation, useNavigate } from 'react-router-dom';
import { ArchiveItem } from '../../../../data/functions/ModelDB';
import Status from '../../../components/ProgressStatus/ProgressStatus';
import DataList, { Header } from '../../database/lists/DataList';
import CategoryItem from '../../../../data/model/CategoryItem';
import { useSelector } from 'react-redux';

const DEFAULT_USERNAME = 'Hinckley Medical';

const ListArchiveFolders = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;

  // Validate location state
  if (!state?.data || !state?.department || !state?.database) {
    navigate('/archive');
  }

  const { data: category, department, database } = state;

  const categoryList = useMemo(() => {
    return category.sort((a: ArchiveItem, b: ArchiveItem) => {
      let aDate = new Date(a.activeItem?.modifiedAt || Date.now());
      let bDate = new Date(b.activeItem?.modifiedAt || Date.now());
      return bDate.getTime() - aDate.getTime();
    });
  }, [category]);

  const reducerState = useSelector((state: any) => state.department);
  const { allSubDeps } = useMemo(() => {
    return getDepartmentsFromState(department, reducerState);
  }, [department, reducerState]);

  const handleItemClick = useCallback(
    (folder: ArchiveItem) => {
      navigate(`/archive/list-folders-details`, {
        state: {
          data: state.data,
          department: department,
          database: database,
          archiveData: [
            ...folder.items,
            folder.activeItem.status === 'DRAFT'
              ? folder.activeItem.activeItem
              : folder.activeItem,
          ],
          activeItem: folder.activeItem,
        },
      });
    },
    [navigate, state.data, department, database]
  );

  const handleBack = useCallback(() => {
    sessionStorage.removeItem('DataList-' + location.pathname);
    navigate('/archive');
  }, [navigate, location.pathname]);

  const getActiveForm = (item: ArchiveItem): CategoryItem => {
    return (item.activeItem as CategoryItem).status === ProgressStatus.DRAFT
      ? ((item.activeItem as CategoryItem).activeItem as CategoryItem)
      : (item.activeItem as CategoryItem);
  };

  const headers: Header<ArchiveItem>[] = useMemo(
    () => [
      {
        key: 'name',
        name: 'Name',
        sortable: true,
        flex: 2,
        render: (item: ArchiveItem) => {
          const activeForm = getActiveForm(item);
          const isOwner = activeForm?.departmentID === department.id;
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {!(isOwner && activeForm?.status !== 'DEACTIVATED') && (
                <div style={{ marginRight: '8px' }}>
                  <FaLock className="table-icon" color="#A3A3A3" />
                </div>
              )}
              {activeForm?.name}
            </div>
          );
        },
      },
      {
        key: 'subs',
        name: 'Subs',
        sortable: true,
        flex: 0.5,
        render: (item: ArchiveItem) => {
          const activeForm = getActiveForm(item);
          const availableSubs = (allSubDeps?.length ?? 0) + 1;
          const itemSubs = !activeForm.isRestrictive
            ? availableSubs
            : (activeForm.pairedDepIDs?.length ?? 0);
          return `${itemSubs}/${availableSubs}`;
        },
      },
      {
        key: 'access',
        name: 'Access',
        sortable: true,
        flex: 0.5,
        render: (item: ArchiveItem) => {
          const activeForm = getActiveForm(item);
          const status: 'Private' | 'Public' | 'Protected' = !activeForm.model
            .isPublic
            ? 'Private'
            : activeForm.model.keychainID
              ? 'Protected'
              : 'Public';
          return (
            <span style={{ flex: 6, textAlign: 'center' }}>
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Status status={status} />
              </div>
            </span>
          );
        },
      },
      {
        key: 'modified_date',
        name: 'Archive Date',
        sortable: true,
        flex: 1,
        render: (item: ArchiveItem) => {
          const activeForm = getActiveForm(item);
          return getFormattedDate(
            new Date(activeForm?.modifiedAt || Date.now()),
            true
          );
        },
      },
      {
        key: 'modified_by',
        name: 'Archived By',
        sortable: true,
        flex: 1,
        render: (item: ArchiveItem) => {
          const activeForm = getActiveForm(item);
          const id =
            activeForm?.model?.modifiedBy || activeForm?.model?.createdBy;
          const user = id
            ? database.users.find((user: User) => user.id === id)
            : null;
          return user ? `${user.firstName} ${user.lastName}` : DEFAULT_USERNAME;
        },
      },
      {
        key: 'archived_items',
        name: 'Archived Items',
        sortable: true,
        flex: 1,
        render: (item: ArchiveItem) => (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
              paddingRight: '15px',
            }}
          >
            <span>{item.items.length}</span>
            <FaChevronRight className="icon-normal" style={{ margin: '4px' }} />
          </div>
        ),
      },
    ],
    [department.id, database.users, allSubDeps]
  );

  const handleSortItem = useCallback(
    (key: string, direction: string, a: ArchiveItem, b: ArchiveItem) => {
      if (key === 'name') {
        const aName =
          a.activeItem?.status === ProgressStatus.DRAFT
            ? a.activeItem?.activeItem?.name || ''
            : a.activeItem?.name || '';
        const bName =
          b.activeItem?.status === ProgressStatus.DRAFT
            ? b.activeItem?.activeItem?.name || ''
            : b.activeItem?.name || '';
        return direction === 'asc'
          ? aName.localeCompare(bName)
          : bName.localeCompare(aName);
      } else if (key === 'modified_date') {
        const aDate = new Date(a.activeItem?.modifiedAt || Date.now());
        const bDate = new Date(b.activeItem?.modifiedAt || Date.now());
        return direction === 'asc'
          ? aDate.getTime() - bDate.getTime()
          : bDate.getTime() - aDate.getTime();
      } else if (key === 'archived_items') {
        return direction === 'asc'
          ? a.items.length - b.items.length
          : b.items.length - a.items.length;
      } else if (key === 'index') {
        return direction === 'asc'
          ? (a.activeItem as CategoryItem).index -
              (b.activeItem as CategoryItem).index
          : (b.activeItem as CategoryItem).index -
              (a.activeItem as CategoryItem).index;
      } else if (key === 'modified_by') {
        const aActiveItem = getActiveForm(a);
        const bActiveItem = getActiveForm(b);
        const aUserID =
          aActiveItem?.model?.modifiedBy || aActiveItem?.model?.createdBy;
        const bUserID =
          bActiveItem?.model?.modifiedBy || bActiveItem?.model?.createdBy;
        const aUser = aUserID
          ? database.users.find((user: User) => user.id === aUserID)
          : null;
        const bUser = bUserID
          ? database.users.find((user: User) => user.id === bUserID)
          : null;
        return direction === 'asc'
          ? aUser?.firstName?.localeCompare(bUser?.firstName) ||
              aUser?.lastName?.localeCompare(bUser?.lastName) ||
              aUserID?.localeCompare(bUserID)
          : bUser?.firstName?.localeCompare(aUser?.firstName) ||
              bUser?.lastName?.localeCompare(aUser?.lastName) ||
              bUserID?.localeCompare(aUserID);
      }
    },
    [database.users]
  );

  const searchFilter = useCallback((item: ArchiveItem, searchQuery: string) => {
    return item.activeItem?.name
      ?.toLowerCase()
      .includes(searchQuery.toLowerCase());
  }, []);

  return (
    <div className="screen-container">
      <ProtocolHeader
        isBackButton={true}
        handleCancel={handleBack}
        name={`Folders: ${categoryList.length} Archive${categoryList.length === 1 ? '' : 's'}`}
        page={`${department.name} Archive`}
        type="protocol"
      />
      <DataList<ArchiveItem, never>
        items={categoryList}
        headers={headers}
        onItemClick={handleItemClick}
        searchPlaceholder="Search Folders..."
        sortItem={handleSortItem}
        searchFilter={searchFilter}
      />
    </div>
  );
};

export default ListArchiveFolders;
