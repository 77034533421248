import { useCallback, useEffect, useMemo, useState } from 'react';
import ProtocolItem from '../../../../data/model/ProtocolItem';
import WorkbookItem from '../../../../data/model/WorkbookItem';
import Dropzone from 'react-dropzone';
import { RiDownload2Line, RiUpload2Line } from 'react-icons/ri';
import Loading from '../../../components/Loading/Loading';
import SearchBar from '../../../components/Search/SearchBar';
import DepartmentItem from '../../../../data/model/DepartmentItem';
import { ViewportList } from 'react-viewport-list';
import {
  CalculateByteSting,
  getFormattedDate,
  getFormattedDateTime,
  globals,
  upgradeVersion,
} from '../../../_global/common/Utils';
import { debounce, set } from 'lodash';
import { Storage } from 'aws-amplify';
import { Button, Col, Row } from 'react-bootstrap';
import { InputText } from 'primereact/inputtext';
import CustomPdfUpload from '../../../components/CustomPdfUpload';
import { fetchWorkbooks } from '../../../../data/functions/WorkbookDB';

interface UploadProtocolPDFProps {
  protocol: ProtocolItem;
  department: DepartmentItem;
  fileValue?: File;
  onUpload: (file: File) => void;
  onWorkbookSelected: (workbookURL: string) => void;
}

const UploadProtocolPDF = (props: UploadProtocolPDFProps) => {
  const { fileValue, protocol, department, onUpload, onWorkbookSelected } =
    props;

  const [searchQuery, setSearchQuery] = useState('');
  const [isPdfLoading, setIsPdfLoading] = useState(false);
  const [selectedWorkbook, setSelectedWorkbook] = useState<WorkbookItem | null>(
    null
  );
  const [workbookURL, setWorkbookURL] = useState<string>('');
  const [errorText, setErrorText] = useState('');
  const [workbookList, setWorkbookList] = useState<WorkbookItem[]>([]);
  const [list, setList] = useState<WorkbookItem[]>([]);
  const [file, setFile] = useState<File | undefined>(undefined);
  const objURL = useMemo(() => (file ? URL.createObjectURL(file) : ''), [file]);

  const [startPage, setStartPage] = useState('');
  const [startPageError, setStartPageError] = useState('');
  const [endPage, setEndPage] = useState('');
  const [endPageError, setEndPageError] = useState('');

  useEffect(() => {
    onWorkbookSelected(workbookURL);
  }, [workbookURL]);

  useEffect(() => {
    if (fileValue == null && selectedWorkbook != null)
      setSelectedWorkbook(null);
    setFile(fileValue);
  }, [fileValue]);

  useEffect(() => {
    setList(workbookList);
  }, [workbookList]);

  const handleSearch = (searchTerm: string) => {
    const filteredList = workbookList.filter((item) =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setList(filteredList);
  };

  useEffect(() => {
    if (selectedWorkbook !== null) {
      const fetchURL = async () => {
        return await Storage.get(selectedWorkbook.workbookUrl, {
          level: 'public',
        });
      };
      fetchURL().then((url) => {
        setWorkbookURL(url);
      });
    }
  }, [selectedWorkbook]);

  useEffect(() => {
    const getDetails = async () => {
      const result = await fetchWorkbooks(department);
      setWorkbookList(result.data);
    };
    getDetails();
  }, [department]);

  const handleDrop = (file: File) => {
    onUpload(file);
  };

  const valid = () => {
    const startPageNum = Number(startPage);
    const endPageNum = Number(endPage);

    if (
      isNaN(startPageNum) ||
      startPageNum < 1 ||
      isNaN(endPageNum) ||
      (endPageNum < 1 && endPageNum !== 0)
    ) {
      setErrorText(
        'Start Page and End Page must be valid numbers greater than 0.'
      );
      return false;
    }

    if (endPageNum !== 0) {
      if (startPageNum > endPageNum) {
        setErrorText('Start Page must be less than or equal to End Page.');
        return false;
      }
    }
    return true;
  };

  const handleStartPageChange = (e: any) => {
    const value = e.target.value;
    setErrorText('');
    if (/^\d*$/.test(value)) {
      setStartPage(value);
      setStartPageError('');
    } else {
      setStartPageError('Please enter a valid number');
    }
  };

  const handleEndPageChange = (e: any) => {
    const value = e.target.value;
    setErrorText('');
    if (/^\d*$/.test(value)) {
      setEndPage(value);
      setEndPageError('');
    } else {
      setEndPageError('Please enter a valid number');
    }
  };

  const handleParsingPDF = async () => {
    if (!selectedWorkbook) return;
    let id = selectedWorkbook?.workbookUrl;
    if (globals.debug) console.log('id', id);
    if (!id) return;
    setIsPdfLoading(true);
    if (!valid()) {
      setIsPdfLoading(false);
      return;
    }
    const url =
      process.env.REACT_APP_API_GATEWAY_URL ??
      (function () {
        throw new Error('API URL is not defined');
      })();
    const bucketName =
      process.env.REACT_APP_BUCKET_NAME ??
      (function () {
        throw new Error('BUCKET NAME is not defined');
      })();
    const apiUrl = url;

    const requestBody = {
      bucket: bucketName,
      key: `public/${id}`,
      startPage,
      endPage: endPage ? endPage : startPage,
      customName: `workBook-${startPage}-${endPage}`,
      depID: department.id,
    };

    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      if (globals.debug) console.log('result', result);
      if (result.statusCode !== 200) {
        const errorMessages = JSON.parse(result.body);
        if (errorMessages.error === 'sequence index out of range') {
          setErrorText('Invalid Page range');
        } else {
          setErrorText(
            'Error: Unknown error occurred while extracting pages. Please try again later.'
          );
          console.error('Error:', errorMessages);
        }
      }

      const parsedBody = JSON.parse(result.body);
      if (globals.debug) console.log('parsedBody', parsedBody);
      const processedPdfUrl = parsedBody.processedPdfUrl;
      if (!processedPdfUrl) {
        setErrorText('Unknown error occured');
      } else {
        const key = processedPdfUrl.split('.com/')[1];
        const keywWithoutPublic = key.split('public/')[1];
        const extractedWorkbook = await Storage.get(keywWithoutPublic, {
          level: 'public',
          contentType: 'application/pdf',
          download: true,
        });
        if (extractedWorkbook == null || extractedWorkbook.Body == null) {
          throw new Error('Error extracting workbook');
        }

        const file = new File([extractedWorkbook.Body], selectedWorkbook.name, {
          type: extractedWorkbook.Body.type,
        });
        setErrorText('');
        onUpload(file);
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsPdfLoading(false);
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        height: '100%',
        width: '100%',
        flex: 1,
      }}
    >
      {isPdfLoading && <Loading type="bubbles" message="Extracting pages..." />}
      {file == null ? (
        <div
          style={{
            display: 'flex',
            flex: 1,
            width: '100%',
          }}
        >
          {selectedWorkbook == null ? (
            <div style={{ display: 'flex', flex: 1 }}>
              <CustomPdfUpload
                onClear={() => setFile(undefined)}
                pdfUploaded={(file: File) => handleDrop(file)}
                pdfvalue={file}
                containerStyle={{
                  flex: 1,
                  padding: '5px',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              />
              {workbookList.length > 0 && (
                <>
                  <div
                    style={{
                      flex: 1,
                      padding: '20px',
                      display: 'flex',
                      marginTop: '5px',
                      flexDirection: 'column',
                      border: '1px solid #e0e0e0',
                      borderRadius: '5px',
                    }}
                    className="ketamine-general-label"
                  >
                    <h5 className="ketmine-header-text">
                      Select From Protocol Set
                    </h5>
                    <SearchBar
                      containerStyle={{ width: '100%' }}
                      value={searchQuery}
                      onChange={(searchTerm: string, e) => {
                        setSearchQuery(searchTerm);
                        handleSearch(searchTerm);
                      }}
                      placeholder={'Search Protocol Sets...'}
                    />
                    <div
                      className="contentBorder protocolCalculationPad primaryListScroll"
                      style={{ marginBottom: '10px' }}
                    >
                      <ViewportList items={list}>
                        {(item: WorkbookItem, index: number) => (
                          <div
                            className=" cursorPointer hoverItem"
                            style={{
                              alignItems: 'center',
                              display: 'flex',
                              borderBottom:
                                index !== list.length - 1
                                  ? '1px solid #e0e0e0'
                                  : 'none',
                            }}
                            onClick={() => {
                              setSelectedWorkbook(item);
                            }}
                          >
                            <span
                              className={'a1Content'}
                              style={{ width: '100%' }}
                            >
                              <div
                                className="notification-css-grey"
                                style={{ fontSize: '14px' }}
                              >
                                {item.model.updatedAt &&
                                  getFormattedDate(item.model.updatedAt, false)}
                              </div>

                              <div
                                style={{
                                  fontSize: '16px',
                                  marginBottom: '8px',
                                }}
                                className="contentTitle a1SubITems"
                              >
                                {item.name}
                              </div>
                            </span>
                          </div>
                        )}
                      </ViewportList>
                    </div>
                  </div>
                </>
              )}
            </div>
          ) : (
            <div style={{ display: 'flex', flex: 1 }}>
              <div
                style={{
                  display: 'flex',
                  flex: 2,
                  flexDirection: 'column',
                }}
              >
                <embed
                  src={workbookURL}
                  title="PDF Viewer"
                  width={'100%'}
                  height="100%"
                />
              </div>
              {/* <ParseFromWorkbook /> */}
              <div style={{ flex: 1 }}>
                {selectedWorkbook == null ? (
                  <></>
                ) : (
                  <div
                    style={{
                      flex: 1,
                      padding: '20px',
                      display: 'flex',
                      marginTop: '5px',
                      flexDirection: 'column',
                      border: '1px solid #e0e0e0',
                      borderRadius: '5px',
                      height: '100%',
                    }}
                    className="ketamine-general-label"
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                      }}
                    >
                      <h5 className="ketmine-header-text">
                        Protocol Set Information
                      </h5>
                    </div>
                    {errorText && (
                      <span
                        style={{
                          color: 'red',
                          marginBottom: '10px',
                        }}
                      >
                        Error: {errorText}
                      </span>
                    )}
                    <div
                      style={{
                        padding: '10px',
                        border: '1px solid #e0e0e0',
                        borderRadius: '5px',
                        marginBottom: '10px',
                      }}
                    >
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={{ marginRight: '10px' }}>Workbook:</div>
                        <div
                          style={{
                            fontWeight: '500',
                            paddingBottom: '10px',
                          }}
                        >
                          {selectedWorkbook.name}
                        </div>
                      </div>
                      {selectedWorkbook.modifiedBy && (
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                          <div style={{ marginRight: '10px' }}>
                            Last Modified By:
                          </div>
                          <div
                            style={{
                              fontWeight: '500',
                              paddingBottom: '10px',
                            }}
                          >
                            {selectedWorkbook.modifiedBy.firstName +
                              ' ' +
                              selectedWorkbook.modifiedBy.lastName}
                          </div>
                        </div>
                      )}
                      {selectedWorkbook.model.updatedAt && (
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                          <div style={{ marginRight: '10px' }}>
                            Modified At:
                          </div>
                          <div
                            style={{
                              fontWeight: '500',
                              paddingBottom: '10px',
                            }}
                          >
                            {getFormattedDate(
                              selectedWorkbook.model.updatedAt,
                              false
                            )}
                          </div>
                        </div>
                      )}
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={{ marginRight: '10px' }}>File Size:</div>
                        <div
                          style={{
                            fontWeight: '500',
                            paddingBottom: '10px',
                          }}
                        >
                          {CalculateByteSting(selectedWorkbook.fileSize)}
                        </div>
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={{ marginRight: '10px' }}>Version:</div>
                        <div
                          style={{
                            fontWeight: '500',
                            paddingBottom: '10px',
                          }}
                        >
                          {selectedWorkbook.version}
                        </div>
                      </div>
                    </div>
                    <div>
                      <h5 className="ketmine-header-text">
                        Parse Protocol Set
                      </h5>
                      <div
                        className="contentText"
                        style={{ fontWeight: '500' }}
                      >
                        {`To extract pages from the protocol set either enter the page number to extract one page or a range to extract multiple pages.`}
                      </div>
                      <Row>
                        <Col sm={6}>
                          <label
                            htmlFor="startPage"
                            className="ketamine-general-label"
                          >
                            Start Page <span className="required-field">*</span>
                          </label>
                          <div className="input-container">
                            <InputText
                              type="text"
                              className="form-control-general"
                              id="name"
                              name="startPage"
                              data-testid="startPage"
                              value={startPage}
                              placeholder="Starting Page Number"
                              onChange={handleStartPageChange}
                              onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                  handleParsingPDF();
                                }
                              }}
                            />
                            {startPageError && (
                              <div
                                style={{
                                  color: 'red',
                                  alignSelf: 'flex-start',
                                  marginLeft: '10px',
                                }}
                              >
                                {startPageError}
                              </div>
                            )}
                            <div className="input-border"></div>
                          </div>
                        </Col>
                        <Col sm={6}>
                          <label
                            htmlFor="endPage"
                            className="ketamine-general-label"
                          >
                            End Page
                          </label>
                          <div className="input-container">
                            <InputText
                              type="text"
                              className="form-control-general"
                              id="name"
                              name="endPage"
                              data-testid="endPage"
                              value={endPage}
                              placeholder="End Page Number"
                              onChange={handleEndPageChange}
                              onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                  handleParsingPDF();
                                }
                              }}
                            />
                            {endPageError && (
                              <div
                                style={{
                                  color: 'red',
                                  alignSelf: 'flex-start',
                                  marginLeft: '10px',
                                }}
                              >
                                {endPageError}
                              </div>
                            )}
                            <div className="input-border"></div>
                          </div>
                        </Col>
                      </Row>
                      <div
                        style={{
                          display: 'flex',
                          marginTop: '10px',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <Button
                          className="primary-button"
                          style={{
                            width: '100%',
                            borderRadius: '5px',
                          }}
                          onClick={() => {
                            handleParsingPDF();
                          }}
                        >
                          Parse Pages
                        </Button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      ) : (
        <div style={{ display: 'flex', flex: 1, width: '100%' }}>
          <div
            style={{
              display: 'flex',
              flex: 2,
              flexDirection: 'column',
            }}
          >
            <embed
              src={objURL}
              type="application/pdf"
              style={{ width: '100%', height: '100%' }}
            />
          </div>
          <div
            style={{
              flex: 1,
              padding: '20px',
              display: 'flex',
              marginTop: '5px',
              flexDirection: 'column',
              border: '1px solid #e0e0e0',
              borderRadius: '5px',
            }}
            className="ketamine-general-label"
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}
            >
              <h5 className="ketmine-header-text">PDF Preview</h5>
            </div>
            <div
              style={{
                padding: '10px',
                border: '1px solid #e0e0e0',
                borderRadius: '5px',
                marginBottom: '10px',
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ marginRight: '10px' }}>Input Style:</div>
                <div style={{ fontWeight: '500', paddingBottom: '10px' }}>
                  {selectedWorkbook
                    ? 'Protocol Set: ' + selectedWorkbook.name
                    : 'Drag and Drop'}
                </div>
              </div>
              {selectedWorkbook && (
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div style={{ marginRight: '10px' }}>
                    {`Page${endPage ? 's' : ''}:`}
                  </div>
                  <div style={{ fontWeight: '500', paddingBottom: '10px' }}>
                    {`${startPage}${endPage ? ' - ' + endPage : ''} (${endPage ? Number(endPage) - Number(startPage) + 1 + ' pages' : '1 page'})`}
                  </div>
                </div>
              )}
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ marginRight: '10px' }}>Protocol:</div>
                <div style={{ fontWeight: '500', paddingBottom: '10px' }}>
                  {protocol.name}
                </div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ marginRight: '10px' }}>New Version:</div>
                <div style={{ fontWeight: '500', paddingBottom: '10px' }}>
                  {upgradeVersion(protocol.pdfVersion)}
                </div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ marginRight: '10px' }}>File Size:</div>
                <div style={{ fontWeight: '500', paddingBottom: '10px' }}>
                  {CalculateByteSting(file.size)}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UploadProtocolPDF;
