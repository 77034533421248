import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import DepartmentItem from '../../../../data/model/DepartmentItem';
import { DatabaseResponse, ResponseType } from '../../../../data/AmplifyDB';
import ProtocolHeader from '../../protocol/ProtocolHeader';
import {
  getDepartmentsFromState,
  getFormattedDate,
} from '../../../_global/common/Utils';
import { Ambulance, User } from '../../../../models';
import ConfirmModal from '../../../components/Modal/ConfirmModal';
import { useDispatch } from 'react-redux';
import { handleGetDepartment } from '../../../../store/actions';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import {
  deleteAmbulance,
  fetchAmbulances,
} from '../../../../data/functions/AmbulanceDB';
import { ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material';
import OwnerImage from '../../../components/OwnerImage/OwnerImage';
import DataList, { Header } from './DataList';
import HMCheckbox from '../../../components/general/HMCheckbox';

function findDepartmentOwner(
  department: DepartmentItem,
  allSubDeps: DepartmentItem[],
  item: Ambulance
): DepartmentItem | null {
  if (allSubDeps == null && department.parentDep == null) {
    return null;
  } else if (department.id === item.departmentID) {
    return department;
  } else if (department.parentDep?.id === item.departmentID) {
    return department.parentDep;
  } else if (department.parentDep?.parentDep?.id === item.departmentID) {
    return department.parentDep?.parentDep;
  }

  return allSubDeps?.find((dep) => dep.id === item.departmentID) || null;
}
/* 09-27-23 Arul: Created Component for Protocol Screen*/
const ListAmbulances = (props: any) => {
  const isLoggedIn = useSelector((state: any) => state.isLoggedIn);
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const dispatch = useDispatch();
  const user: User = useSelector((state: any) => state.user);

  const [database, setDatabase] = useState<DatabaseResponse>(
    useSelector((state: any) => state?.protocol?.departmentItem)
  );
  const department = database.department;
  const [ambulances, setAmbulances] = useState<Ambulance[]>(
    database.ambulances
  );
  const [viewing, setViewing] = useState<string | null>('all');
  const reducerState = useSelector((state: any) => state.department);
  const { subDeps, allSubDeps, userDepartments } = useMemo(() => {
    return getDepartmentsFromState(department, reducerState);
  }, [department, reducerState]);

  const allDepartmentOwners = useMemo(() => {
    let deps: DepartmentItem[] = [];
    for (let i = 0; i < ambulances.length; i++) {
      let owner = findDepartmentOwner(department, allSubDeps, ambulances[i]);
      if (owner && !deps.find((dep) => dep.id === owner?.id)) deps.push(owner);
    }
    setViewing('all');
    return deps.sort((a, b) => a.name.localeCompare(b.name));
  }, [ambulances, department]);
  const scrollPosition = useRef(0);

  console.log('OWNER', allDepartmentOwners);

  const [searchQuery, setSearchQuery] = useState(
    state?.search?.searchQuery ?? ''
  );
  const [list, setList] = useState<any[]>([]);
  const [filters, setFilters] = useState<DepartmentItem[]>([]);
  const [selectedItems, setSelectedItems] = useState<Ambulance[]>([]);
  const paginationModel = { page: 0, pageSize: 50 };
  const [isDelete, setIsDelete] = useState(false);
  const [allCheckedBtn, setAllCheckedBtn] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success' as 'success' | 'error',
  });

  const reloadDatabase = async () => {
    const response = await fetchAmbulances(department);
    if (response.type === ResponseType.Success) {
      setAmbulances(response.data);
      handleFilterChange(response.data);
      dispatch<any>(
        handleGetDepartment({
          ...database,
          ambulances: response.data,
        })
      );
    } else {
      console.error('Failed to fetch ambulances', response.data);
    }
  };

  useEffect(() => {
    reloadDatabase();
    setAllCheckedBtn(false);
  }, []);

  const handleFilterChange = (pList: any[]) => {
    let filteredList = [...(pList ? pList : ambulances)];
    if (searchQuery !== '') {
      filteredList = filteredList.filter((item) => {
        return item.name.toLowerCase().includes(searchQuery.toLowerCase());
      });
    }
    //Group by the parent first then sort by index
    filteredList = filteredList.sort((a: Ambulance, b: Ambulance) => {
      return a.name.localeCompare(b.name);
    });
    if (filters.length > 0) {
      filteredList = filteredList.filter((item) => {
        return filters.some((filter) => filter.id === item.departmentID);
      });
    }
    setList(filteredList);
  };

  useEffect(() => {
    handleFilterChange(ambulances);
  }, [searchQuery, ambulances, filters]);

  /* 09-29-23 Arul: handle function to Navigate protocol detail page*/
  const handleItemClick = (amb: Ambulance) => {
    const state = {
      selectedProtocol: null,
      value: amb,
      subValue: null,
      type: 'Ambulance',
      editType: 'edit',
      editMode: false,
      page: 'listAmbulancePage',
    };
    navigate(`/database/edit/ambulance`, { state });
  };

  const handleCreateItem = () => {
    const state = {
      selectedProtocol: null,
      value: null,
      subValue: null,
      type: 'Ambulances',
      editType: 'new',
      editMode: false,
      page: 'listAmbulancePage',
    };
    navigate(`/database/new/ambulance`, { state });
  };

  const handleBack = () => {
    sessionStorage.removeItem('DataList-' + location.pathname);
    navigate(`/database`, { state: department });
  };

  const headers: Header<Ambulance>[] = useMemo(
    () => [
      {
        key: 'input',
        name: '',
        sortable: false,
        flex: 0.25,
        render: (item: Ambulance) => {
          return (
            <div
              style={{ display: 'flex', alignItems: 'center', height: '100%' }}
              onClick={(e: any) => {
                e.stopPropagation();
              }}
            >
              <HMCheckbox
                checked={selectedItems.some(
                  (selected) => selected.id === item.id
                )}
                onChange={() => handleSelectionChange(item)}
              />
            </div>
          );
        },
      },
      {
        key: 'name',
        name: 'Name',
        sortable: true,
        flex: 2,
      },
      {
        key: 'modified_date',
        name: 'Modified Date',
        sortable: true,
        flex: 1,
        render: (item: Ambulance) =>
          getFormattedDate(
            item.updatedAt ? new Date(item.updatedAt) : new Date(),
            true
          ),
      },
      {
        key: 'owner',
        name: 'Owner',
        sortable: true,
        flex: 1,
        render: (item: Ambulance) => {
          const departmentOwner = findDepartmentOwner(
            department,
            allSubDeps,
            item
          );
          return departmentOwner ? (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <OwnerImage
                owner={departmentOwner}
                size={32}
                style={{ padding: 0, margin: 0 }}
              />
            </div>
          ) : null;
        },
      },
    ],
    [department, allSubDeps, selectedItems]
  );

  const handleSortItem = (
    key: string,
    direction: string,
    a: Ambulance,
    b: Ambulance
  ) => {
    if (key === 'name') {
      return direction === 'asc'
        ? a.name.localeCompare(b.name)
        : b.name.localeCompare(a.name);
    } else if (key === 'modified_date') {
      const aDate = new Date(a.updatedAt ?? new Date());
      const bDate = new Date(b.updatedAt ?? new Date());
      return direction === 'asc'
        ? aDate.getTime() - bDate.getTime()
        : bDate.getTime() - aDate.getTime();
    } else if (key === 'owner') {
      const aOwner = findDepartmentOwner(department, allSubDeps, a)?.name ?? '';
      const bOwner = findDepartmentOwner(department, allSubDeps, b)?.name ?? '';
      return direction === 'asc'
        ? aOwner.localeCompare(bOwner)
        : bOwner.localeCompare(aOwner);
    }
    return 0;
  };

  const handleSelectAll = (
    e: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    if (checked) {
      setSelectedItems([
        ...ambulances.filter((item) => department.id === item.departmentID),
      ]);
    } else {
      setSelectedItems([]);
    }
  };

  const handleSelectionChange = (ambulance: Ambulance) => {
    if (selectedItems.find((item) => item.id === ambulance.id)) {
      setSelectedItems((prevItems: Ambulance[]) => {
        const updatedItems = prevItems.filter(
          (item: Ambulance) => item.id !== ambulance.id
        );

        if (updatedItems.length === 0) {
          setAllCheckedBtn(false);
        }
        return updatedItems;
      });
    } else {
      let items = [...selectedItems, ambulance];
      let available = ambulances.filter(
        (item) => department.id === item.departmentID
      );
      setSelectedItems(items);
      if (items.length === available.length) {
        setAllCheckedBtn(true);
      } else if (allCheckedBtn) {
        setAllCheckedBtn(false);
      }
    }
  };

  const deleteNextMedication = async () => {
    try {
      // If there are no items left, exit the function
      if (selectedItems.length === 0) {
        setIsDelete(false);
        return;
      }

      // Get the current medication to delete (the first item in the array)
      const currentMedication = selectedItems[0];

      // Delete the medication
      let response = await deleteAmbulance(currentMedication);
      if (response.type === ResponseType.Success) {
        setSnackbar({
          ...snackbar,
          message: `Successfully deleted ambulance: ${currentMedication.name}`,
          severity: 'success',
        });
        setSnackbar({ ...snackbar, open: true });

        // Remove the successfully deleted medication from the selectedItems array
        setSelectedItems((prevItems: Ambulance[]) => {
          const updatedItems = prevItems.filter(
            (item: Ambulance) => item.id !== currentMedication.id
          );
          // If no items are left after deletion, close the modal
          if (updatedItems.length === 0) {
            setIsDelete(false);
          }
          return updatedItems;
        });
      } else {
        setSnackbar({
          ...snackbar,
          message: 'Failed to delete ambulance',
          severity: 'error',
        });
        setSnackbar({ ...snackbar, open: true });
      }

      // Reload the database and check if there are any items left
      reloadDatabase();
    } catch (error) {
      setSnackbar({
        ...snackbar,
        message: 'Failed to delete ambulance',
        severity: 'error',
      });
      setSnackbar({ ...snackbar, open: true });
    }
  };

  const handleConfirmDeleteMedications = async () => {
    if (allCheckedBtn) {
      try {
        // Initialize counters for success and failure
        let successCount = 0;
        let failureCount = 0;

        for (const parmMedication of selectedItems) {
          let response = await deleteAmbulance(parmMedication);
          if (response.type === ResponseType.Success) {
            successCount++;
          } else {
            failureCount++;
          }
        }

        // Set snackbar message based on the results
        if (successCount > 0) {
          setSnackbar({
            ...snackbar,
            message: `Successfully deleted ${successCount} ambulance${
              successCount > 1 ? 's' : ''
            }.`,
            severity: 'success',
          });
        }
        if (failureCount > 0) {
          setSnackbar({
            ...snackbar,
            message: `Failed to delete ${failureCount} ambulance${
              failureCount > 1 ? 's' : ''
            }.`,
            severity: 'error',
          });
        }

        // Clear selectedItems after all deletions
        setSelectedItems([]);
        reloadDatabase();
      } catch (error) {
        setSnackbar({
          ...snackbar,
          message: 'Failed to delete ambulance.',
          severity: 'error',
        });
        setSnackbar({ ...snackbar, open: true });
      }
      setIsDelete(false); // Close modal after all deletions
    } else {
      deleteNextMedication(); // Call the refactored function
    }
  };

  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const getRowClassName = useCallback(
    (params: Ambulance) => {
      const isSelected = selectedItems.some((item) => item.id === params.id);
      if (isSelected) return 'selectedRow';
      // Default return for other cases
      return '';
    },
    [selectedItems]
  );

  const customFilterComponent = useMemo(
    () =>
      allDepartmentOwners.length > 1 ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            margin: '4px 0px',
          }}
        >
          <ToggleButtonGroup
            value={viewing}
            exclusive
            onChange={(event, newAlignment) => setViewing(newAlignment)}
            aria-label="owner"
            sx={{
              maxHeight: '50px',
            }}
          >
            <Tooltip title={'View All Medications'} placement="bottom" arrow>
              <ToggleButton
                value="all"
                aria-label="all"
                autoCapitalize="none"
                sx={{
                  padding: '0px 8px', // Adjust padding as needed for the "All" button
                  minWidth: 'auto', // Ensures the width is only as wide as the content
                }}
                onChange={() => {
                  setViewing('all');
                  setFilters([]);
                }}
              >
                All
              </ToggleButton>
            </Tooltip>
            {allDepartmentOwners.map((owner) => (
              <ToggleButton
                key={owner.id}
                value={owner.id}
                onClick={() => {
                  setViewing(owner.id);
                  setFilters([owner]);
                }}
              >
                <Tooltip title={owner.name} placement="bottom" arrow>
                  <img
                    className="no-select owner-logo"
                    src={owner.logoVerifiedUrl ?? ''}
                    alt="Agency Logo"
                    style={{ width: 40, height: 40 }}
                  />
                </Tooltip>
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </div>
      ) : null,
    [allDepartmentOwners, viewing]
  );

  return (
    <div className="screen-container">
      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
      {isDelete && (
        <ConfirmModal
          isVisible={isDelete}
          title={'Delete Ambulance?'}
          handleClose={() => {
            setIsDelete(false);
          }}
          handleSubmit={handleConfirmDeleteMedications}
          isDeleteBtn={true}
          primaryBtnName="Cancel"
          secondaryBtnName="Delete"
          primaryDescription={
            allCheckedBtn
              ? `Are you sure you would like to delete ${selectedItems.length} ambulance?`
              : `Are you sure you would like to delete ${selectedItems[0].name}?`
          }
          isSelectAllBtn={selectedItems.length > 1}
          handleCheckAll={(check: any) => {
            setAllCheckedBtn(check);
          }}
        />
      )}

      <ProtocolHeader
        // homeScreen={true}
        isBackButton={true}
        handleCancel={handleBack}
        name={
          selectedItems.length === 0
            ? 'Ambulances: ' + list.length + ' items'
            : 'Ambulances: ' +
              selectedItems.length +
              ' / ' +
              list.length +
              ' items'
        }
        page={department.name}
        rightSideBtn={'edit'}
        isEditButton={false}
        isCreateButton={true}
        isCreateActive={true}
        handleCreate={handleCreateItem}
        handleEdit={() => {}}
        isDeleteButton={selectedItems.length > 0}
        isDeleteDisabled={selectedItems.length === 0}
        handleDelete={() => setIsDelete(true)}
        type={'protocol'}
      />

      <DataList<Ambulance, never>
        items={list}
        headers={headers}
        onItemClick={handleItemClick}
        onSelectionChange={setSelectedItems}
        searchPlaceholder="Search Ambulances..."
        customFilterComponent={customFilterComponent}
        sortItem={handleSortItem}
        getRowClassName={getRowClassName}
        handleSelectAll={handleSelectAll}
        selectedItems={selectedItems}
        searchFilter={(item, searchQuery) => {
          return (
            item.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            (user.type === 'ADMIN' &&
              item.id.toLowerCase().includes(searchQuery.toLowerCase()))
          );
        }}
      />
    </div>
  );
};

export default ListAmbulances;
