import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  checkSubDeps,
  DatabaseResponse,
  loadDatabase,
  Response,
  ResponseType,
} from '../../../../data/AmplifyDB';
import ProtocolHeader from '../../protocol/ProtocolHeader';
import {
  getDepartmentsFromState,
  getFormattedDate,
} from '../../../_global/common/Utils';
import { FaChevronRight } from 'react-icons/fa6';
import { User, UserStatus } from '../../../../models';
import { useDispatch } from 'react-redux';
import ConfirmModal from '../../../components/Modal/ConfirmModal';
import DepartmentItem from '../../../../data/model/DepartmentItem';
import {
  handleCacheUsers,
  handleGetDepartment,
} from '../../../../store/actions';
import Loading from '../../../components/Loading/Loading';
import DataList, { Header } from './DataList';
import { fetchUsers } from '../../../../data/functions/UserDB';
import ReactLoading from 'react-loading';
import CopyToClipboard from '../../../components/CopyToClipboard';
import { deleteDepartmentItem } from '../../../../data/functions/DepartmentDB';

const ListAgencies = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const [database, setDatabase] = useState<DatabaseResponse>(
    useSelector((state: any) => state?.protocol?.departmentItem)
  );
  const department = useMemo(() => database.department, [database]);
  const reducerState = useSelector((state: any) => state.department);
  const user: User = useSelector((state: any) => state.user);

  const { subDeps, allSubDeps } = useMemo(() => {
    return getDepartmentsFromState(department, reducerState);
  }, [department, reducerState]);

  const [agencies, setAgencies] = useState<DepartmentItem[]>(
    allSubDeps?.sort((a, b) => a.name.localeCompare(b.name)) ?? []
  );
  const [selectedItems, setSelectedItems] = useState<DepartmentItem[]>([]);
  const [isDelete, setIsDelete] = useState(false);
  const [loading, setLoading] = useState<string | null>(null);
  const [switchDepartment, setSwitchDepartment] =
    useState<DepartmentItem | null>(null);
  const [allCheckedBtn, setAllCheckedBtn] = useState(false);

  const cacheUsers: User[] | null | undefined = useSelector(
    (state: any) => state.cacheUsers
  );

  const reloadDatabase = async () => {
    let subDepsMap = new Map();
    let allSubDepsMap = new Map();
    checkSubDeps(
      department,
      subDepsMap,
      allSubDepsMap,
      user,
      dispatch,
      undefined,
      true,
      false
    )
      .then(({ subDeps, allSubDeps }) => {
        setAgencies(
          allSubDeps?.sort((a, b) => a.name.localeCompare(b.name)) ?? []
        );
      })
      .catch((error) => {
        console.error('Error reloading database: ', error);
      });
  };

  const fetchAllUsers = async () => {
    const users = await fetchUsers(
      department,
      false,
      UserStatus.ACTIVE,
      undefined,
      allSubDeps
    );
    dispatch<any>(handleCacheUsers(users.data));
  };

  useEffect(() => {
    setAgencies(allSubDeps?.sort((a, b) => a.name.localeCompare(b.name)) ?? []);
  }, [allSubDeps]);

  useEffect(() => {
    setTimeout(() => {
      reloadDatabase();
    }, 500);
    setAllCheckedBtn(false);
  }, []);

  /**
   * 02-04-25 Hazlett:
   *   - Added a check to see if the cacheUsers is null or empty
   *   - If it is, then fetch all users
   */
  useEffect(() => {
    if (cacheUsers == null || cacheUsers.length === 0) fetchAllUsers();
  }, []);

  const handleBack = () => {
    sessionStorage.removeItem('DataList-' + location.pathname);
    navigate(`/database`, { state: department });
  };

  const handleItemClick = (agency: DepartmentItem) => {
    setSwitchDepartment(agency);
  };

  const switchToDepartment = async (dep: DepartmentItem | null) => {
    if (!dep) return;
    try {
      await dispatch<any>(handleCacheUsers([]));
      localStorage.removeItem('userStats');
      sessionStorage.clear();
      setLoading('Switching to ' + dep.name + '...');
      const response: Response = await loadDatabase(dep, dispatch, {
        wait: true,
        waitForUsers: true,
        forceResync: true,
        subDepsMapParam: database.subDepsMap,
        allSubDepsMapParam: database.allSubDepsMap,
      });
      dep.calculateAdminLevel(user);
      dep.isSearchedPairedDeps = true;
      if (response.type === ResponseType.Success) {
        localStorage.setItem(
          'loggedInDepartment',
          JSON.stringify({
            id: dep.id,
            parentDepID: dep.parentDepID,
          })
        );
        document.title = `${dep.name} | OneDose`;

        await dispatch<any>(handleGetDepartment(response.data));
        navigate('/protocol');
        setLoading(null);
      } else {
        console.log('Error switching department: ', response.data);
        alert('Error switching department: ' + response.data);
        setLoading(null);
      }
    } catch (error) {
      console.log('Error switching department: ', error);
      setLoading(null);
    }
  };

  const handleConfirmDeleteAgencies = async () => {
    try {
      if (selectedItems.length === 0) {
        setIsDelete(false);
        return;
      }
      const currentAgency = selectedItems[0];
      let response = await deleteDepartmentItem(currentAgency);
      if (response.type === ResponseType.Success) {
        setSelectedItems((prevItems: DepartmentItem[]) => {
          const updatedItems = prevItems.filter(
            (item: DepartmentItem) => item.id !== currentAgency.id
          );
          if (updatedItems.length === 0) {
            setIsDelete(false);
          }
          return updatedItems;
        });
      }
      reloadDatabase();
    } catch (error) {
      console.error('Error deleting agency:', error);
    }
  };

  const headers: Header<DepartmentItem>[] = useMemo(
    () => [
      {
        key: 'input',
        name: '',
        sortable: false,
        flex: 0.1,
        style: { marginRight: '24px' },
      },
      {
        key: 'name',
        name: 'Name',
        sortable: true,
        flex: 2,
        render: (item) => (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span>{item.name}</span>
            {user.type === 'ADMIN' && <CopyToClipboard text={item.id} />}
          </div>
        ),
      },
      {
        key: 'modified_date',
        name: 'Modified Date',
        sortable: true,
        flex: 1,
        render: (item) =>
          getFormattedDate(
            item.model?.updatedAt
              ? new Date(item.model?.updatedAt)
              : item.model?.createdAt
                ? new Date(item.model?.createdAt)
                : new Date(),
            true
          ),
      },
      {
        key: 'deptCode',
        name: 'Code',
        sortable: true,
        flex: 1,
        render: (item) => {
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span>{item.departmentCode}</span>
              <CopyToClipboard text={item.departmentCode} />
            </div>
          );
        },
      },
      {
        key: 'userCount',
        name: 'Users',
        sortable: true,
        flex: 1,
        render: (item) => {
          const count = (cacheUsers ?? []).filter(
            (user: User) =>
              user.departmentID === item.id ||
              user.pairedDepIDs?.includes(item.id)
          ).length;

          return cacheUsers != null ? (
            count
          ) : (
            <ReactLoading
              type="spokes"
              color="#00534C"
              height={16}
              width={16}
            />
          );
        },
      },
      {
        key: 'subAgencies',
        name: 'Sub-Agencies',
        sortable: true,
        flex: 1,
        render: (item) => {
          const count =
            allSubDeps?.filter((dep) => dep.parentDepID === item.id).length ||
            0;
          return (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                flex: 1,
                marginLeft: '0.5rem',
              }}
            >
              <span>{count}</span>
              <FaChevronRight
                className="icon-normal"
                style={{ margin: '4px' }}
              />
            </div>
          );
        },
      },
    ],
    [allSubDeps, cacheUsers]
  );

  const handleSortItem = (
    key: string,
    direction: string,
    a: DepartmentItem,
    b: DepartmentItem
  ) => {
    if (key === 'userCount') {
      let aCount = (cacheUsers ?? []).filter(
        (user: User) =>
          user.departmentID === a.id || user.pairedDepIDs?.includes(a.id)
      ).length;
      let bCount = (cacheUsers ?? []).filter(
        (user: User) =>
          user.departmentID === b.id || user.pairedDepIDs?.includes(b.id)
      ).length;
      return direction === 'asc' ? aCount - bCount : bCount - aCount;
    } else if (key === 'modified_date') {
      return direction === 'asc'
        ? a.modifiedAt.getTime() - b.modifiedAt.getTime()
        : b.modifiedAt.getTime() - a.modifiedAt.getTime();
    } else if (key === 'subAgencies') {
      const aCount =
        allSubDeps?.filter((dep) => dep.parentDepID === a.id).length || 0;
      const bCount =
        allSubDeps?.filter((dep) => dep.parentDepID === b.id).length || 0;
      return direction === 'asc' ? aCount - bCount : bCount - aCount;
    }
    let aValue = a[key as keyof DepartmentItem];
    let bValue = b[key as keyof DepartmentItem];
    if (typeof aValue === 'string' && typeof bValue === 'string') {
      return direction === 'asc'
        ? aValue.localeCompare(bValue)
        : bValue.localeCompare(aValue);
    }
    return 0;
  };

  const handleSelectAll = (
    e: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    if (checked) setSelectedItems([...agencies]);
    else setSelectedItems([]);
  };

  return (
    <div className="screen-container">
      {loading && <Loading type="bubbles" message={loading} />}

      <ConfirmModal
        isVisible={isDelete}
        title={
          selectedItems.length > 1
            ? `Delete ${selectedItems.length} Agencies?`
            : `Delete ${selectedItems[0]?.name}?`
        }
        handleClose={() => setIsDelete(false)}
        handleSubmit={handleConfirmDeleteAgencies}
        isDeleteBtn={true}
        primaryBtnName="Cancel"
        secondaryBtnName="Delete"
        primaryDescription={
          allCheckedBtn
            ? `Are you sure you would like to delete ${selectedItems.length} agencies?`
            : `Are you sure you would like to delete ${selectedItems[0]?.name}?`
        }
        isSelectAllBtn={selectedItems.length > 1}
        handleCheckAll={(check: boolean) => setAllCheckedBtn(check)}
      />

      <ConfirmModal
        isVisible={switchDepartment != null}
        title={`Switch to ${switchDepartment?.name}?`}
        handleClose={() => setSwitchDepartment(null)}
        handleSubmit={() => switchToDepartment(switchDepartment)}
        primaryBtnName="Cancel"
        secondaryBtnName="Switch"
        primaryDescription={`This will switch your current agency to ${switchDepartment?.name} and you will be able to see all the data for this agency.`}
      />

      <ProtocolHeader
        isBackButton={true}
        handleCancel={handleBack}
        name={
          selectedItems.length === 0
            ? `Agencies: ${agencies.length} items`
            : `Agencies: ${selectedItems.length} / ${agencies.length} items`
        }
        descriptionTitle={
          user.type === 'ADMIN' && selectedItems.length === 1
            ? 'ID: '
            : undefined
        }
        description={
          user.type === 'ADMIN' && selectedItems.length === 1
            ? selectedItems[0].id
            : undefined
        }
        isCopyDescription={user.type === 'ADMIN' && selectedItems.length === 1}
        page={department.name}
        rightSideBtn={'edit'}
        isEditButton={false}
        isDeleteButton={selectedItems.length > 0}
        isDeleteDisabled={selectedItems.length === 0}
        handleDelete={() => setIsDelete(true)}
        type={'protocol'}
      />

      <DataList<DepartmentItem, never>
        items={agencies}
        headers={headers}
        onItemClick={handleItemClick}
        onSelectionChange={setSelectedItems}
        sortItem={handleSortItem}
        searchPlaceholder="Search Agencies..."
        selectedItems={selectedItems}
        handleSelectAll={handleSelectAll}
        searchFilter={(item, searchQuery) => {
          return (
            item.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            item.uniquePublicURL
              .toLowerCase()
              .includes(searchQuery.toLowerCase()) ||
            item.departmentCode
              .toLowerCase()
              .includes(searchQuery.toLowerCase()) ||
            (user.type === 'ADMIN' &&
              item.id.toLowerCase().includes(searchQuery.toLowerCase()))
          );
        }}
      />
    </div>
  );
};

export default ListAgencies;
