import React, { useCallback, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ProtocolHeader from '../../protocol/ProtocolHeader';
import { getFormattedDate } from '../../../_global/common/Utils';
import { FaChevronRight, FaLock } from 'react-icons/fa6';
import ElectricalItem from '../../../../data/model/ElectricalItem';
import { ProgressStatus, User } from '../../../../models';
import { ArchiveItem } from '../../../../data/functions/ModelDB';
import ElectricalSubItem from '../../../../data/model/ElectricalSubItem';
import DataList, { Header } from '../../database/lists/DataList';
import GeneralSelection from '../../../components/Selection/GeneralSelection';

const DEFAULT_USERNAME = 'Hinckley Medical';

const ListArchiveElectrical = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;

  // Validate location state
  if (!state?.data || !state?.department || !state?.database) {
    navigate('/archive');
  }

  const {
    data: electrical,
    subData: electricalShocks,
    department,
    database,
    tab,
  } = state;
  const [selectedTab, setSelectedTab] = useState(tab || 'Electrical');

  // Filter out any items in electricalShocks that have items.length === 0
  const filteredElectricalShocks = useMemo(
    () => electricalShocks.filter((item: ArchiveItem) => item.items.length > 0),
    [electricalShocks]
  );

  const electricalList = useMemo(() => {
    return electrical.sort((a: ArchiveItem, b: ArchiveItem) => {
      let aDate = new Date(a.activeItem?.modifiedAt || Date.now());
      let bDate = new Date(b.activeItem?.modifiedAt || Date.now());
      return bDate.getTime() - aDate.getTime();
    });
  }, [electrical]);

  const doseList = useMemo(() => {
    return filteredElectricalShocks.sort((a: ArchiveItem, b: ArchiveItem) => {
      let aDate = new Date(a.activeItem?.modifiedAt || Date.now());
      let bDate = new Date(b.activeItem?.modifiedAt || Date.now());
      return bDate.getTime() - aDate.getTime();
    });
  }, [filteredElectricalShocks]);

  const handleItemClick = useCallback(
    (elec: ArchiveItem) => {
      navigate(`/archive/list-electricals-details`, {
        state: {
          data: state.data,
          subData: state.subData,
          department: department,
          database: database,
          archiveData: [
            ...elec.items,
            elec.activeItem.status === 'DRAFT'
              ? elec.activeItem.activeItem
              : elec.activeItem,
          ],
          activeItem: elec.activeItem,
          tab: selectedTab,
        },
      });
    },
    [navigate, state.data, department, database, state.subData, selectedTab]
  );

  const handleBack = useCallback(() => {
    sessionStorage.removeItem('DataList-' + location.pathname);
    navigate('/archive');
  }, [navigate, location.pathname]);

  const getActiveForm = (item: ArchiveItem): ElectricalItem => {
    return item.activeItem.status === ProgressStatus.DRAFT
      ? (item.activeItem.activeItem as ElectricalItem)
      : (item.activeItem as ElectricalItem);
  };

  const electricalHeaders: Header<ArchiveItem>[] = useMemo(
    () => [
      {
        key: 'name',
        name: 'Name',
        sortable: true,
        flex: 2,
        render: (item: ArchiveItem) => {
          const activeForm = getActiveForm(item);
          const isOwner = activeForm?.departmentID === department.id;

          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {!(isOwner && activeForm?.status !== 'DEACTIVATED') && (
                <div style={{ marginRight: '8px' }}>
                  <FaLock className="table-icon" color="#A3A3A3" />
                </div>
              )}
              {activeForm?.name}
            </div>
          );
        },
      },
      {
        key: 'modified_date',
        name: 'Modified Date',
        sortable: true,
        flex: 1,
        render: (item: ArchiveItem) => {
          const activeForm = getActiveForm(item);
          return getFormattedDate(
            new Date(activeForm?.modifiedAt || Date.now()),
            true
          );
        },
      },
      {
        key: 'modified_by',
        name: 'Modified By',
        sortable: true,
        flex: 1,
        render: (item: ArchiveItem) => {
          const activeForm = getActiveForm(item);
          const userId =
            activeForm?.model?.modifiedBy || activeForm?.model?.createdBy;
          const user = userId
            ? database.users.find((user: User) => user.id === userId)
            : null;
          return user ? `${user.firstName} ${user.lastName}` : DEFAULT_USERNAME;
        },
      },
      {
        key: 'archived_items',
        name: 'Archived Items',
        sortable: true,
        flex: 1,
        render: (item: ArchiveItem) => (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
              paddingRight: '15px',
            }}
          >
            <span>{item.items.length}</span>
            <FaChevronRight className="icon-normal" style={{ margin: '4px' }} />
          </div>
        ),
      },
    ],
    [department.id, database.users]
  );

  const getShockActiveForm = (item: ArchiveItem): ElectricalSubItem => {
    return item.activeItem.status === ProgressStatus.DRAFT
      ? (item.activeItem.activeItem as ElectricalSubItem)
      : (item.activeItem as ElectricalSubItem);
  };

  const shockHeaders: Header<ArchiveItem>[] = useMemo(
    () => [
      {
        key: 'name',
        name: 'Electrical Name',
        sortable: true,
        flex: 2,
        render: (item: ArchiveItem) => {
          const activeForm = getShockActiveForm(item);
          const isOwner = activeForm?.departmentID === department.id;

          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {!(isOwner && activeForm?.status !== 'DEACTIVATED') && (
                <div style={{ marginRight: '8px' }}>
                  <FaLock className="table-icon" color="#A3A3A3" />
                </div>
              )}
              {activeForm?.name}
            </div>
          );
        },
      },
      {
        key: 'protocol_name',
        name: 'Protocol Name',
        sortable: true,
        flex: 2,
        render: (item: ArchiveItem) => {
          const activeForm = getShockActiveForm(item);
          return activeForm.parentCPR
            ? 'CPR Assist'
            : activeForm.parentProtocol?.name || '';
        },
      },

      {
        key: 'full_basis',
        name: 'Full Basis',
        sortable: false,
        flex: 1,
        render: (item: ArchiveItem) => {
          const activeForm = getShockActiveForm(item);
          return activeForm.fullBasis;
        },
      },
      {
        key: 'modified_date',
        name: 'Modified Date',
        sortable: true,
        flex: 1,
        render: (item: ArchiveItem) => {
          const activeForm = getShockActiveForm(item);
          return getFormattedDate(
            new Date(activeForm?.model?.updatedAt || Date.now()),
            true
          );
        },
      },
      {
        key: 'modified_by',
        name: 'Modified By',
        sortable: true,
        flex: 1,
        render: (item: ArchiveItem) => {
          const activeForm = getShockActiveForm(item);
          const userId =
            activeForm?.model?.modifiedBy || activeForm?.model?.createdBy;
          const user = userId
            ? database.users.find((user: User) => user.id === userId)
            : null;
          return user ? `${user.firstName} ${user.lastName}` : DEFAULT_USERNAME;
        },
      },
      {
        key: 'archived_items',
        name: 'Archived Items',
        sortable: true,
        flex: 1,
        render: (item: ArchiveItem) => (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
              paddingRight: '15px',
            }}
          >
            <span>{item.items.length}</span>
            <FaChevronRight className="icon-normal" style={{ margin: '4px' }} />
          </div>
        ),
      },
    ],
    [department.id, database.users]
  );

  const handleSortItem = useCallback(
    (key: string, direction: string, a: ArchiveItem, b: ArchiveItem) => {
      const getActiveForm = (item: ArchiveItem) => {
        return item.activeItem.status === ProgressStatus.DRAFT
          ? item.activeItem.activeItem
          : item.activeItem;
      };

      if (key === 'name') {
        const aName = getActiveForm(a)?.name || '';
        const bName = getActiveForm(b)?.name || '';
        return direction === 'asc'
          ? aName.localeCompare(bName)
          : bName.localeCompare(aName);
      } else if (key === 'modified_date') {
        const aDate = new Date(getActiveForm(a)?.modifiedAt || Date.now());
        const bDate = new Date(getActiveForm(b)?.modifiedAt || Date.now());
        return direction === 'asc'
          ? aDate.getTime() - bDate.getTime()
          : bDate.getTime() - aDate.getTime();
      } else if (key === 'archived_items') {
        return direction === 'asc'
          ? a.items.length - b.items.length
          : b.items.length - a.items.length;
      } else if (key === 'modified_by') {
        const aForm = getActiveForm(a);
        const bForm = getActiveForm(b);
        const aUserId = aForm?.model?.modifiedBy || aForm?.model?.createdBy;
        const bUserId = bForm?.model?.modifiedBy || bForm?.model?.createdBy;

        const aUser = aUserId
          ? database.users.find((user: User) => user.id === aUserId)
          : null;
        const bUser = bUserId
          ? database.users.find((user: User) => user.id === bUserId)
          : null;

        const aName = aUser
          ? `${aUser.firstName} ${aUser.lastName}`
          : DEFAULT_USERNAME;
        const bName = bUser
          ? `${bUser.firstName} ${bUser.lastName}`
          : DEFAULT_USERNAME;

        return direction === 'asc'
          ? aName.toLowerCase().localeCompare(bName.toLowerCase())
          : bName.toLowerCase().localeCompare(aName.toLowerCase());
      } else if (key === 'protocol_name') {
        const aForm = getShockActiveForm(a);
        const bForm = getShockActiveForm(b);
        const aProtocolName = aForm.parentCPR
          ? 'CPR Assist'
          : aForm.parentProtocol?.name || '';
        const bProtocolName = bForm.parentCPR
          ? 'CPR Assist'
          : bForm.parentProtocol?.name || '';
        return direction === 'asc'
          ? aProtocolName.localeCompare(bProtocolName)
          : bProtocolName.localeCompare(aProtocolName);
      }
      return 0;
    },
    [database.users]
  );

  const currentItems = useMemo(() => {
    return selectedTab === 'Electrical' ? electricalList : doseList;
  }, [selectedTab, electricalList, doseList]);
  const currentHeaders = useMemo(() => {
    return selectedTab === 'Electrical' ? electricalHeaders : shockHeaders;
  }, [selectedTab, electricalHeaders, shockHeaders]);

  const searchFilter = useCallback(
    (item: ArchiveItem, searchQuery: string) => {
      const activeForm =
        selectedTab === 'Electrical'
          ? getActiveForm(item)
          : getShockActiveForm(item);
      if (selectedTab === 'Electrical') {
        return activeForm?.name
          ?.toLowerCase()
          .includes(searchQuery.toLowerCase());
      } else {
        return (
          activeForm?.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
          (activeForm as ElectricalSubItem)?.parentProtocol?.name
            ?.toLowerCase()
            .includes(searchQuery.toLowerCase()) ||
          ((activeForm as ElectricalSubItem)?.parentCPR ? 'CPR Assist' : '')
            .toLowerCase()
            .includes(searchQuery.toLowerCase())
        );
      }
    },
    [selectedTab]
  );

  const customTabComponent = useMemo(() => {
    return (
      <div style={{ display: 'flex', alignItems: 'flex-start', width: '100%' }}>
        <GeneralSelection
          items={['Electrical', 'Shocks']}
          selectedIndex={selectedTab === 'Electrical' ? 0 : 1}
          onPress={(item, index) => {
            setSelectedTab(item);
          }}
          labelField={(option) => option}
          valueField={(option) => option}
          containerStyle={{
            marginTop: '2px',
          }}
        />
      </div>
    );
  }, [selectedTab]);

  return (
    <div className="screen-container">
      <ProtocolHeader
        isBackButton={true}
        handleCancel={handleBack}
        name={`${selectedTab}: ${currentItems.length} Archive${currentItems.length === 1 ? '' : 's'}`}
        page={`${department.name} Archive`}
        type="protocol"
      />

      <DataList<ArchiveItem, never>
        items={currentItems}
        headers={currentHeaders}
        onItemClick={handleItemClick}
        searchPlaceholder={`Search ${selectedTab}...`}
        sortItem={handleSortItem}
        searchFilter={searchFilter}
        customFilterComponent={customTabComponent}
        // maxHeight="57vh"
      />
    </div>
  );
};

export default ListArchiveElectrical;
