import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import DepartmentItem from '../../../../data/model/DepartmentItem';
import {
  DatabaseResponse,
  Response,
  ResponseType,
} from '../../../../data/AmplifyDB';
import ProtocolHeader from '../../protocol/ProtocolHeader';
import {
  findDepartmentOwner,
  getFormattedDate,
  hasAdminUserAccess,
} from '../../../_global/common/Utils';
import { FaChevronRight, FaLock } from 'react-icons/fa6';
import ContactItem from '../../../../data/model/ContactItem';
import ConfirmModal from '../../../components/Modal/ConfirmModal';
import { ProgressStatus } from '../../../../models';
import {
  deleteContact,
  fetchContacts,
  subscribeContactFromDepartment,
} from '../../../../data/functions/ContactDB';
import { handleGetDepartment } from '../../../../store/actions';
import { useDispatch } from 'react-redux';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import HMCheckbox from '../../../components/general/HMCheckbox';
import HMSwitch from '../../../components/general/HMSwitch';
import AddContactSideout from '../../../components/SideOut/AddContactSideout';
import OwnerImage from '../../../components/OwnerImage/OwnerImage';
import DataList, { Header } from './DataList';
import { Tooltip } from '@mui/material';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';

/* 09-27-23 Arul: Created Component for Protocol Screen*/
const ListContacts = (props: any) => {
  const isLoggedIn = useSelector((state: any) => state.isLoggedIn);
  const navigate = useNavigate();
  const location = useLocation();
  const reducerState = useSelector((state: any) => state.department);
  const { state } = location;

  const [database, setDatabase] = useState<DatabaseResponse>(
    useSelector((state: any) => state?.protocol?.departmentItem)
  );
  const user = useSelector((state: any) => state?.user);
  const department: DepartmentItem = database.department;
  const [contacts, setContacts] = useState<ContactItem[]>(database.contacts);
  const scrollPosition = useRef(0);

  const [searchQuery, setSearchQuery] = useState(
    state?.search?.searchQuery ?? ''
  );
  const [filters, setFilters] = useState<any[]>(state?.search?.filters ?? []);
  const [categoriesFilter, setCategoriesFilter] = useState<any[]>(
    state?.search?.categoriesFilter ?? []
  );
  const [unsubscribeList, setUnsubscribeList] = useState<ContactItem[]>([]);

  const [list, setList] = useState<any[]>([]);
  const [selectedItems, setSelectedItems] = useState<ContactItem[]>([]);
  const paginationModel = { page: 0, pageSize: 50 };
  const [isDelete, setIsDelete] = useState(false);
  const [allCheckedBtn, setAllCheckedBtn] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>(
    'success'
  );
  const [mainCheckbox, setMainCheckbox] = useState(false);
  const adminAccess = useMemo(
    () => hasAdminUserAccess(department, reducerState, user),
    [department, user]
  );
  const adminLevel = useMemo(() => {
    return department?.adminLevel ?? 4;
  }, [department]);
  const [isAddContactModalOpen, setIsAddContactModalOpen] = useState(false);
  const dispatch = useDispatch();

  const [viewing, setViewing] = useState<string | null>('all');

  const allDepartmentOwners = useMemo(() => {
    let deps: DepartmentItem[] = [];
    for (let i = 0; i < contacts.length; i++) {
      let owner = findDepartmentOwner(department, reducerState, contacts[i]);
      if (owner && !deps.find((dep) => dep.id === owner?.id)) deps.push(owner);
    }
    setViewing('all');
    setFilters([]);
    return deps.sort((a, b) => a.name.localeCompare(b.name));
  }, [contacts, department, reducerState]);

  const reloadDatabase = async () => {
    const resp = await fetchContacts(database.department);
    if (resp.type === ResponseType.Success) {
      setContacts(resp.data);
      handleFilterChange(resp.data);
      dispatch<any>(
        handleGetDepartment({
          ...database,
          contacts: resp.data,
        })
      );
    } else {
      console.error('ERROR LOADING DATABASE', resp.data);
    }
  };

  useEffect(() => {
    reloadDatabase();
    setAllCheckedBtn(false);
  }, []);

  /* 09-28-23 Arul: handle function for filter search*/
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
  };

  const handleFilterChange = (pList: any[]) => {
    let filteredList = [...(pList ? pList : contacts)];
    if (searchQuery !== '' || filters.length > 0) {
      filteredList = filteredList.filter((item) => {
        return item.name.toLowerCase().includes(searchQuery.toLowerCase());
      });
      if (filters.length > 0) {
        filteredList = filteredList.filter((item) => {
          //Check if the Filters (Department ID)
          return filters.some((filter) => {
            return item.departmentID === filter.id;
          });
        });
      }
    }
    //Group by the parent first then sort by index
    filteredList = filteredList.sort((a: ContactItem, b: ContactItem) => {
      return a.name.localeCompare(b.name);
    });
    setList(filteredList);
  };

  useEffect(() => {
    handleFilterChange(contacts);
  }, [searchQuery, filters, categoriesFilter, contacts]);

  /* 09-29-23 Arul: handle function to Navigate protocol detail page*/
  const handleItemClick = (check: ContactItem) => {
    const state = {
      selectedProtocol: null,
      value: check,
      subValue: null,
      type: 'Contact',
      editType: 'edit',
      editMode: false,
      page: 'listContactPage',
    };
    navigate(`/database/edit/contact`, { state });
    // const state = { selectedProtocol: protocol, editMode: false };
    // navigate(`/${protocol.nickname}/protocol-detail`, { state });
  };

  const handleCreateItem = () => {
    const state = {
      selectedProtocol: null,
      value: null,
      subValue: null,
      type: 'Contacts',
      editType: 'new',
      editMode: false,
      page: 'listContactPage',
    };
    navigate(`/database/new/contact`, { state });
    // const state = { selectedProtocol: protocol, editMode: false };
    // navigate(`/${protocol.nickname}/protocol-detail`, { state });
  };

  const handleSave = () => {
    handleSubscribeContacts(unsubscribeList, false)
      .then((completed) => {
        if (completed) {
          setSnackbarMessage(
            'Successfully updated ' + department.name + ' contacts'
          );
          setSnackbarSeverity('success');
          setSnackbarOpen(true);
        } else {
          setSnackbarMessage(
            'Failed to update ' + department.name + ' contacts'
          );
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
        }
      })
      .finally(() => {
        setUnsubscribeList([]);
        reloadDatabase();
      });
  };

  const handleChangeContacts = (newContacts: ContactItem[]) => {
    const addContacts = newContacts.filter(
      (item) => !contacts.find((contact) => contact.uid === item.uid)
    );
    const removeContacts = contacts.filter(
      (item) => !newContacts.find((contact) => contact.uid === item.uid)
    );
    Promise.all([
      handleSubscribeContacts(addContacts, true),
      handleSubscribeContacts(removeContacts, false),
    ])
      .then((completed: boolean[]) => {
        if (completed.every((completed) => completed)) {
          setSnackbarMessage(
            'Successfully updated ' + department.name + ' contacts'
          );
          setSnackbarSeverity('success');
          setSnackbarOpen(true);
        } else {
          setSnackbarMessage(
            'Failed to update ' + department.name + ' contacts'
          );
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
        }
      })
      .finally(() => {
        setUnsubscribeList([]);
        reloadDatabase();
      });
  };

  const handleSubscribeContacts = (
    contacts: ContactItem[],
    isSubscribe: boolean
  ): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      let promises: Promise<Response>[] = [];
      for (const contact of contacts) {
        promises.push(
          subscribeContactFromDepartment(contact, department, isSubscribe)
        );
      }
      Promise.all(promises).then((responses) => {
        if (
          responses.every((response) => response.type === ResponseType.Success)
        ) {
          return resolve(true);
        } else {
          return reject(false);
        }
      });
    });
  };

  const handleBack = () => {
    sessionStorage.removeItem('DataList-' + location.pathname);
    navigate(`/database`, { state: department });
  };

  const headers: Header<ContactItem>[] = useMemo(
    () => [
      {
        key: 'input',
        name: '',
        sortable: false,
        flex: 0.25,
        render: (item: ContactItem) => {
          const adminAccess = hasAdminUserAccess(
            department,
            reducerState,
            user
          );
          const isOwner = item.departmentID === department.id;
          const isSwitchChecked = !unsubscribeList.find(
            (newItem) => newItem.uid === item.uid
          )
            ? item.status !== ProgressStatus.DEACTIVATED
            : item.status === ProgressStatus.DEACTIVATED;

          return (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                flex: 1,
                alignItems: 'center',
                height: '100%',
              }}
              onClick={(e) => e.stopPropagation()}
            >
              {adminAccess && (
                <>
                  {isOwner && item.status !== ProgressStatus.DEACTIVATED ? (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        height: '100%',
                        width: '100%',
                        flexDirection: 'row',
                      }}
                    >
                      <HMCheckbox
                        checked={selectedItems.some(
                          (selected) => selected.uid === item.uid
                        )}
                        onChange={() => handleSelectionChange(item)}
                      />
                    </div>
                  ) : (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        height: '100%',
                        width: '100%',
                        flexDirection: 'row',
                        marginLeft: '-12px',
                      }}
                    >
                      <HMSwitch
                        checked={isSwitchChecked}
                        onChange={() => {
                          let find = unsubscribeList.find(
                            (newItem) => newItem.uid === item.uid
                          );
                          if (find)
                            setUnsubscribeList(
                              unsubscribeList.filter(
                                (newItem) => newItem.uid !== item.uid
                              )
                            );
                          else setUnsubscribeList([...unsubscribeList, item]);
                        }}
                      />
                      <div
                        style={{
                          marginLeft: '1px',
                          border: '1px solid #E0E0E0',
                          height: '50%',
                          width: '1px',
                        }}
                      />
                    </div>
                  )}
                </>
              )}
            </div>
          );
        },
      },
      {
        key: 'name',
        name: 'Name',
        sortable: true,
        flex: 2,
        render: (item: ContactItem) => {
          const isOwner = item.departmentID === department.id;
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {!(isOwner && item.status !== 'DEACTIVATED') && (
                <FaLock
                  className="table-icon"
                  style={{ marginRight: '8px' }}
                  color="#A3A3A3"
                />
              )}
              {item.name}
            </div>
          );
        },
      },
      {
        key: 'title',
        name: 'Title',
        sortable: true,
        flex: 1,
        render: (item: ContactItem) => item.title,
      },
      {
        key: 'number',
        name: 'Number',
        sortable: true,
        flex: 1,
        render: (item: ContactItem) => item.number,
      },
      {
        key: 'modified_date',
        name: 'Modified Date',
        sortable: true,
        flex: 1,
        render: (item: ContactItem) =>
          getFormattedDate(
            item.dbContact.updatedAt ? item.dbContact.updatedAt : new Date(),
            true
          ),
      },
      {
        key: 'owner',
        name: 'Owner',
        sortable: true,
        flex: 1,
        render: (item: ContactItem) => {
          const departmentOwner = findDepartmentOwner(
            department,
            reducerState,
            item
          );
          return departmentOwner ? (
            <OwnerImage
              owner={departmentOwner}
              size={32}
              style={{ padding: 0, margin: 0 }}
            />
          ) : null;
        },
      },
      {
        key: 'note',
        name: 'Note',
        sortable: true,
        flex: 1,
        render: (item: ContactItem) => (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <span
              style={{
                flex: 1,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                marginRight: '8px',
              }}
            >
              {item.note}
            </span>
            <FaChevronRight
              className="icon-normal"
              style={{ margin: '4px', flexShrink: 0 }}
            />
          </div>
        ),
      },
    ],
    [department, reducerState, user, unsubscribeList, selectedItems]
  );

  const handleSortItem = (
    key: string,
    direction: string,
    a: ContactItem,
    b: ContactItem
  ) => {
    if (key === 'name') {
      return direction === 'asc'
        ? a.name.localeCompare(b.name)
        : b.name.localeCompare(a.name);
    } else if (key === 'title') {
      return direction === 'asc'
        ? (a.title || '').localeCompare(b.title || '')
        : (b.title || '').localeCompare(a.title || '');
    } else if (key === 'number') {
      return direction === 'asc'
        ? (a.number || '').localeCompare(b.number || '')
        : (b.number || '').localeCompare(a.number || '');
    } else if (key === 'modified_date') {
      const aDate = new Date(a.dbContact?.updatedAt ?? new Date());
      const bDate = new Date(b.dbContact?.updatedAt ?? new Date());
      return direction === 'asc'
        ? aDate.getTime() - bDate.getTime()
        : bDate.getTime() - aDate.getTime();
    } else if (key === 'owner') {
      const aOwner =
        findDepartmentOwner(department, reducerState, a)?.name ?? '';
      const bOwner =
        findDepartmentOwner(department, reducerState, b)?.name ?? '';
      return direction === 'asc'
        ? aOwner.localeCompare(bOwner)
        : bOwner.localeCompare(aOwner);
    } else if (key === 'note') {
      return direction === 'asc'
        ? (a.note || '').localeCompare(b.note || '')
        : (b.note || '').localeCompare(a.note || '');
    }
    return 0;
  };

  const handleSelectAll = (
    e: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    if (checked) {
      setSelectedItems([
        ...contacts.filter(
          (item) =>
            item.status !== 'DEACTIVATED' && department.id === item.departmentID
        ),
      ]);
      setMainCheckbox(true);
    } else {
      setSelectedItems([]);
      setMainCheckbox(false);
    }
  };

  const handleSelectionChange = (contact: ContactItem) => {
    if (selectedItems.find((item) => item.uid === contact.uid)) {
      setSelectedItems((prevItems: ContactItem[]) => {
        const updatedItems = prevItems.filter(
          (item: ContactItem) => item.uid !== contact.uid
        );

        if (updatedItems.length === 0) {
          setMainCheckbox(false);
        }
        return updatedItems;
      });
    } else {
      let items = [...selectedItems, contact];
      let available = list.filter(
        (item) =>
          item.status !== 'DEACTIVATED' && department.id === item.departmentID
      );
      setSelectedItems(items);
      if (items.length === available.length) {
        setMainCheckbox(true);
      } else if (mainCheckbox) {
        setMainCheckbox(false);
      }
    }
  };
  const deleteNextMedication = async () => {
    try {
      // If there are no items left, exit the function
      if (selectedItems.length === 0) {
        setIsDelete(false);
        return;
      }

      // Get the current medication to delete (the first item in the array)
      const currentMedication = selectedItems[0];

      // Delete the medication
      let response = await deleteContact(currentMedication.dbContact);
      if (response.type === ResponseType.Success) {
        setSnackbarMessage(
          `Successfully deleted contact: ${currentMedication.name}`
        );
        setSnackbarSeverity('success');
        setSnackbarOpen(true);

        // Remove the successfully deleted medication from the selectedItems array
        setSelectedItems((prevItems: ContactItem[]) => {
          const updatedItems = prevItems.filter(
            (item: ContactItem) => item.uid !== currentMedication.uid
          );
          // If no items are left after deletion, close the modal
          if (updatedItems.length === 0) {
            setIsDelete(false);
          }
          return updatedItems;
        });
      } else {
        setSnackbarMessage('Failed to delete contact');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }

      // Reload the database and check if there are any items left
      reloadDatabase();
    } catch (error) {
      setSnackbarMessage('Failed to delete contact');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleConfirmDeleteMedications = async () => {
    if (allCheckedBtn) {
      try {
        // Initialize counters for success and failure
        let successCount = 0;
        let failureCount = 0;

        for (const parmMedication of selectedItems) {
          let response = await deleteContact(parmMedication.dbContact);
          if (response.type === ResponseType.Success) {
            successCount++;
          } else {
            failureCount++;
          }
        }

        // Set snackbar message based on the results
        if (successCount > 0) {
          setSnackbarMessage(`Successfully deleted ${successCount} contacts.`);
          setSnackbarSeverity('success');
        }
        if (failureCount > 0) {
          setSnackbarMessage(`Failed to delete ${failureCount} contacts.`);
          setSnackbarSeverity('error');
        }

        setSnackbarOpen(true); // Open snackbar after all deletions
        // Clear selectedItems after all deletions
        setSelectedItems([]);
        reloadDatabase();
      } catch (error) {
        setSnackbarMessage('Failed to delete contact');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
      setIsDelete(false); // Close modal after all deletions
    } else {
      deleteNextMedication(); // Call the refactored function
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const getRowClassName = useCallback(
    (params: ContactItem) => {
      const rowItem = list.find((item) => item.uid === params.uid);
      const adminAccess = hasAdminUserAccess(
        department,
        reducerState,
        user,
        rowItem
      );
      // Apply background color when adminAccess is false or the item status is not DEACTIVATED.
      if (!(adminAccess && rowItem?.status !== 'DEACTIVATED')) {
        return 'inactive';
      }

      // If the row is selected, give it the selected class.
      const isSelected = selectedItems.some((item) => item.uid === params.id);
      if (isSelected) return 'selectedRow';

      // Default return for other cases
      return '';
    },
    [department, list, user, reducerState, selectedItems]
  );

  const isSaveActive = useMemo(() => {
    if (unsubscribeList.length > 0) return true;
    return false;
  }, [unsubscribeList]);

  const customFilterComponent = useMemo(
    () =>
      allDepartmentOwners.length > 1 ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            margin: '4px 0px',
          }}
        >
          <ToggleButtonGroup
            value={viewing}
            exclusive
            onChange={(event, newAlignment) => setViewing(newAlignment)}
            aria-label="owner"
            sx={{
              maxHeight: '50px',
            }}
          >
            <Tooltip title={'View All Medications'} placement="bottom" arrow>
              <ToggleButton
                value="all"
                aria-label="all"
                autoCapitalize="none"
                sx={{
                  padding: '0px 8px', // Adjust padding as needed for the "All" button
                  minWidth: 'auto', // Ensures the width is only as wide as the content
                }}
                onChange={() => {
                  setViewing('all');
                  setFilters([]);
                }}
              >
                All
              </ToggleButton>
            </Tooltip>
            {allDepartmentOwners.map((owner) => (
              <ToggleButton
                key={owner.id}
                value={owner.id}
                onClick={() => {
                  setViewing(owner.id);
                  setFilters([owner]);
                }}
              >
                <Tooltip title={owner.name} placement="bottom" arrow>
                  <img
                    className="no-select owner-logo"
                    src={owner.logoVerifiedUrl ?? ''}
                    alt="Agency Logo"
                    style={{ width: 40, height: 40 }}
                  />
                </Tooltip>
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </div>
      ) : null,
    [allDepartmentOwners, viewing]
  );

  return (
    <div className="screen-container">
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>

      {isDelete && (
        <ConfirmModal
          isVisible={isDelete}
          title={'Delete Contacts?'}
          handleClose={() => {
            setIsDelete(false);
          }}
          handleSubmit={handleConfirmDeleteMedications}
          isDeleteBtn={true}
          primaryBtnName="Cancel"
          secondaryBtnName="Delete"
          primaryDescription={
            allCheckedBtn
              ? `Are you sure you would like to delete ${selectedItems.length} contacts?`
              : `Are you sure you would like to delete ${selectedItems[0].name}?`
          }
          isSelectAllBtn={selectedItems.length > 1}
          handleCheckAll={(check: any) => {
            setAllCheckedBtn(check);
          }}
        />
      )}

      <AddContactSideout
        isVisible={isAddContactModalOpen}
        handleClose={() => setIsAddContactModalOpen(false)}
        handleAddContacts={(newContacts: ContactItem[]) => {
          // setContacts(newContacts);
          setIsAddContactModalOpen(false);
          handleChangeContacts(newContacts);
        }}
        contacts={contacts}
        department={department}
      />

      <ProtocolHeader
        // homeScreen={true}
        isBackButton={true}
        handleCancel={handleBack}
        name={
          selectedItems.length === 0
            ? 'Contacts: ' + list.length + ' items'
            : 'Contacts: ' +
              selectedItems.length +
              ' / ' +
              list.length +
              ' items'
        }
        page={department.name}
        rightSideBtn={'edit'}
        isEditButton={false}
        isSaveButton={isSaveActive}
        isSaveActive={true}
        handleSave={handleSave}
        handleCancelEdit={() => {
          setUnsubscribeList([]);
        }}
        isCreateButton={adminLevel > 2 && adminAccess}
        isCreateActive={true}
        isAddButton={adminAccess && department.parentDep != null}
        handleAdd={() => setIsAddContactModalOpen(true)}
        handleCreate={handleCreateItem}
        handleEdit={() => {}}
        isDeleteButton={selectedItems.length > 0}
        isDeleteDisabled={selectedItems.length === 0}
        handleDelete={() => setIsDelete(true)}
        type={'protocol'}
      />

      <DataList<ContactItem, never>
        items={list}
        headers={headers}
        onItemClick={handleItemClick}
        onSelectionChange={setSelectedItems}
        searchPlaceholder="Search Contacts..."
        sortItem={handleSortItem}
        getRowClassName={getRowClassName}
        handleSelectAll={handleSelectAll}
        selectedItems={selectedItems}
        customFilterComponent={customFilterComponent}
        searchFilter={(item, searchQuery) => {
          return (
            item.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            item.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
            item.number.toLowerCase().includes(searchQuery.toLowerCase()) ||
            (user.type === 'ADMIN' &&
              item.uid.toLowerCase().includes(searchQuery.toLowerCase()))
          );
        }}
      />
    </div>
  );
};

export default ListContacts;
