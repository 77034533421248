import { InputText } from 'primereact/inputtext';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ViewportList } from 'react-viewport-list';
import EquipmentItem from '../../../../../data/model/EquipmentItem';
import ProtocolItem from '../../../../../data/model/ProtocolItem';
import { Equipment, ProgressStatus, User } from '../../../../../models';
import { EquipmentOption } from '../../../../../models';
import EquipmentDose from './EquipmentDose';
import {
  getFormattedDate,
  globals,
  removeTypename,
  upgradeVersion,
} from '../../../../_global/common/Utils';
import ProtocolHeader from '../../ProtocolHeader';
import { UserType } from '../../../../../models';
import { BiCopy, BiSolidCopy } from 'react-icons/bi';
import ConfirmModal from '../../../../components/Modal/ConfirmModal';
import {
  createEquipment,
  deleteEquipmentItem,
  EquipmentDB,
} from '../../../../../data/functions/EquipmentDB';
import { ResponseType } from '../../../../../data/AmplifyDB';
import {
  ArchiveItem,
  reloadModelItem,
} from '../../../../../data/functions/ModelDB';
import { Alert, Snackbar } from '@mui/material';
import CopyToClipboard from '../../../../components/CopyToClipboard';

/* 12-31-24 Gagan: Created equipment Archive Page component */
const EquipmentProtocolArchivePage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const user: User = useSelector((state: any) => state?.user);

  if (
    !location.state?.value ||
    !location.state?.department ||
    !location.state?.database
  ) {
    navigate('/archive');
  }

  const { value, sourceData, archiveData, department, database, activeItem } =
    location.state;

  const [parmEquipment, setParmEquipment] = useState<EquipmentItem>(value);
  const [pairedProtocols, setPairedProtocols] = useState<ProtocolItem[]>(
    value?.parents || []
  );

  const [activeParmEquipment, setActiveParmEquipment] =
    useState<EquipmentItem>(activeItem);

  const [modifiedBy, setModifiedBy] = useState<User | null>(null);
  const [isRestoring, setIsRestoring] = useState(false);

  // Check if the most recent version of the vital is a draft
  const isDraft = useMemo(
    () => activeParmEquipment.status === ProgressStatus.DRAFT,
    [activeParmEquipment]
  );

  const [snackbar, setSnackbar] = useState<any>({
    open: false,
    message: '',
    severity: 'success',
  });

  const [modal, setModal] = useState({
    isVisible: false,
    title: '',
    primaryDescription: '',
    primaryBtnName: '',
    secondaryBtnName: '',
    handleClose: () => {},
    handleSubmit: () => {},
  });

  const reloadItem = async () => {
    const response = await reloadModelItem(Equipment, activeParmEquipment);
    if (response.type === ResponseType.Success) {
      const item = new EquipmentItem(response.data.item);
      const activeItem = response.data.activeItem as
        | Equipment
        | null
        | undefined;
      item.activeItem = activeItem ? new EquipmentItem(activeItem) : null;
      setActiveParmEquipment(item);
    }
  };

  /**
   * Check if the draft exists for the active item
   */
  useEffect(() => {
    reloadItem();
  }, []);

  /* Hotkey detection */
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.ctrlKey && event.shiftKey && event.key === 'O') {
        console.log('ARCHIVE EQUIPMENT', parmEquipment);
        console.log('ACTIVE ITEM', activeParmEquipment);
      }
    };

    // Add event listener
    document.addEventListener('keydown', handleKeyDown);

    // Remove event listener on cleanup
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [parmEquipment, activeParmEquipment]);

  useEffect(() => {
    if (value) {
      setParmEquipment(value);
    }
  }, [value]);

  useEffect(() => {
    if (!parmEquipment) return;

    const loadModifiedBy = () => {
      const id =
        parmEquipment.model?.modifiedBy || parmEquipment.model?.createdBy;
      if (id) {
        const user = database.users.find((u: User) => u.id === id);
        setModifiedBy(user || null);
        if (user) parmEquipment.modifiedBy = user;
      }
    };

    const loadPairedProtocols = () => {
      const equipmentID =
        parmEquipment.status.includes('DRAFT') && parmEquipment.activeID
          ? parmEquipment.activeID
          : parmEquipment.uid;

      const protocols = database.protocols.filter((protocol: ProtocolItem) =>
        protocol.model.equipmentIDs?.includes(equipmentID)
      );
      setPairedProtocols(protocols);
    };

    loadModifiedBy();
    loadPairedProtocols();
  }, [database, parmEquipment]);

  const handleBack = useCallback(() => {
    navigate('/archive/list-equipment-details', {
      state: {
        data: sourceData,
        department: department,
        database: database,
        archiveData: archiveData,
        activeItem: activeItem,
      },
    });
  }, [navigate, sourceData, department, database, archiveData, activeItem]);

  const handleRestore = useCallback(async () => {
    setIsRestoring(true);
    setModal({ ...modal, isVisible: false });
    try {
      const oldActiveItem = isDraft
        ? activeParmEquipment.activeItem
        : activeParmEquipment;
      if (isDraft) {
        const response = await deleteEquipmentItem(activeParmEquipment, false);
        if (response.type === ResponseType.Failure) {
          setSnackbar({
            open: true,
            message:
              'Failed to delete already existing draft of ' +
              oldActiveItem?.name,
            severity: 'error',
          });
          // Exit the function
          console.error('Failed to delete equipment', response.data);
          return;
        }
      }

      const updatedEquipment: EquipmentDB = {
        name: parmEquipment.name,
        departmentID: department.id,
        title: parmEquipment.title,
        warning: parmEquipment.warning,
        instruction: parmEquipment.instruction,
        note: parmEquipment.note,
        optionItems: removeTypename(parmEquipment.options),
        status: ProgressStatus.DRAFT,
        taggedProtocols: pairedProtocols,
        activeID: oldActiveItem?.uid,
        version: upgradeVersion(oldActiveItem?.version || 'v1.0.0'),
        modifiedBy: user.id,
        createdBy:
          parmEquipment.model && parmEquipment.model.createdBy
            ? parmEquipment.model.createdBy
            : user.id,
      };

      const results = await createEquipment(
        updatedEquipment,
        oldActiveItem as EquipmentItem
      );
      if (results.type === ResponseType.Success) {
        const newDraftEquipment = results.data as EquipmentItem;
        newDraftEquipment.activeItem = oldActiveItem;

        setSnackbar({
          open: true,
          message:
            'Successfully Restored ' +
            newDraftEquipment.name +
            ' with a new DRAFT version ' +
            newDraftEquipment.version,
          severity: 'success',
        });

        // Update sourceData with new draft
        const newSourceData = sourceData.map((item: ArchiveItem) =>
          item.activeItem?.uid === activeParmEquipment.uid
            ? { ...item, activeItem: newDraftEquipment }
            : item
        );

        if (globals.debug)
          console.log('Successfully restored Equipment', results.data);
        // Handle successful restoration
        // Delay navigation to allow snackbar to be shown
        setModal({
          isVisible: true,
          title: 'Successfully Restored Equipment',
          primaryDescription: `Successfully restored ${newDraftEquipment.name} to version ${newDraftEquipment.version}. Would you like to navigate to the equipment?`,
          primaryBtnName: 'Dismiss',
          secondaryBtnName: 'Navigate',
          handleClose: () => {
            setModal({ ...modal, isVisible: false });
            navigate('/archive/list-equipment-details', {
              state: {
                data: newSourceData,
                department: department,
                database: database,
                archiveData: archiveData,
                activeItem: newDraftEquipment,
              },
            });
          },
          handleSubmit: () => {
            const state = {
              selectedProtocol: null,
              value: newDraftEquipment,
              subValue: null,
              type: 'Equipment',
              editType: 'edit',
              editMode: false,
              page: 'listEquipmentPage',
            };
            navigate(`/protocol/edit/edit-protocol`, { state });
          },
        });
      } else if (results.type === ResponseType.Failure) {
        setSnackbar({
          open: true,
          message: 'Failed to restore Equipment.',
          severity: 'error',
        });
      }
    } catch (error) {
      setIsRestoring(false);
      console.error('Restore error:', error);
    } finally {
      setIsRestoring(false);
    }
  }, [
    navigate,
    sourceData,
    department,
    database,
    archiveData,
    activeParmEquipment,
    parmEquipment,
    user,
    isDraft,
    pairedProtocols,
  ]);

  const handleRestorePressed = async () => {
    if (isDraft) {
      setModal({
        isVisible: true,
        title: 'Override Existing Draft?',
        primaryDescription: `There is already a draft of ${activeParmEquipment.name} (${activeParmEquipment.version}) which is not yet published. Are you sure you want to override the existing draft and restore ${parmEquipment.name} of version ${parmEquipment.version}?`,
        primaryBtnName: 'Cancel',
        secondaryBtnName: 'Restore',
        handleClose: () => {
          setModal({ ...modal, isVisible: false });
        },
        handleSubmit: handleRestore,
      });
    } else {
      setModal({
        isVisible: true,
        title: 'Restore Equipment?',
        primaryDescription: `Are you sure you want to restore ${parmEquipment.name} of version ${parmEquipment.version}? This will create a new draft version of the equipment.`,
        primaryBtnName: 'Cancel',
        secondaryBtnName: 'Restore',
        handleClose: () => {
          setModal({ ...modal, isVisible: false });
        },
        handleSubmit: handleRestore,
      });
    }
  };

  return (
    <div className="screen-container">
      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>

      <ConfirmModal
        isVisible={modal.isVisible}
        title={modal.title}
        primaryDescription={modal.primaryDescription}
        primaryBtnName={modal.primaryBtnName}
        secondaryBtnName={modal.secondaryBtnName}
        handleClose={modal.handleClose}
        handleSubmit={modal.handleSubmit}
      />

      <ProtocolHeader
        name={parmEquipment.name}
        status={parmEquipment ? parmEquipment.status : undefined}
        isBackButton={true}
        page={parmEquipment.name + ' Archive'}
        type={'protocol'}
        isCopyDescription={user.type === UserType.ADMIN}
        descriptionTitle={user.type === UserType.ADMIN ? 'ID:' : ''}
        description={user.type === UserType.ADMIN ? parmEquipment?.uid : ''}
        handleCancel={handleBack}
        isRestoreButton={true}
        isRestoreActive={!isRestoring && parmEquipment.status !== 'ACTIVE'}
        bypassActiveStatusCheck={true}
        handleRestore={handleRestorePressed}
      />
      <div className="ketamineContent">
        <div className="KetamineGeneral">
          <h5 className="ketmine-header-text" data-testid="equipment-header">
            Required Information
          </h5>
          {parmEquipment && (
            <div className="input-container roundBorder">
              <div
                style={{ display: 'flex', marginTop: '5px' }}
                className="ketamine-general-label"
              >
                <div style={{ marginRight: '10px' }}>Modified By:</div>
                <div style={{ fontWeight: '500' }}>
                  {modifiedBy
                    ? modifiedBy.firstName + ' ' + modifiedBy.lastName
                    : 'Hinckley Medical'}
                </div>
              </div>
              {parmEquipment.model?.updatedAt && (
                <div
                  style={{ display: 'flex' }}
                  className="ketamine-general-label"
                >
                  <div style={{ marginRight: '10px' }}>Last Updated:</div>
                  <div style={{ fontWeight: '500' }}>
                    {getFormattedDate(parmEquipment.model.updatedAt, true)}
                  </div>
                </div>
              )}
              <div
                style={{ display: 'flex' }}
                className="ketamine-general-label"
              >
                <div style={{ marginRight: '10px' }}>Version:</div>
                <div style={{ fontWeight: '500' }}>{parmEquipment.version}</div>
              </div>
              {pairedProtocols && (
                <div
                  style={{ display: 'flex' }}
                  className="ketamine-general-label"
                >
                  <div style={{ marginRight: '10px' }}>Paired Protocols:</div>
                  <div style={{ fontWeight: '500' }}>
                    {pairedProtocols.length}
                  </div>
                </div>
              )}
              {user.type === 'ADMIN' && parmEquipment.activeID && (
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div
                    style={{ display: 'flex' }}
                    className="ketamine-general-label"
                  >
                    <div style={{ marginRight: '10px' }}>Active ID:</div>
                    <div style={{ fontWeight: '500' }}>
                      {parmEquipment.activeID}
                    </div>

                    <CopyToClipboard text={parmEquipment.activeID ?? ''} />
                  </div>
                </div>
              )}
              {user.type === 'ADMIN' && (
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div
                    style={{ display: 'flex' }}
                    className="ketamine-general-label"
                  >
                    <div style={{ marginRight: '10px' }}>Owner ID:</div>
                    <div style={{ fontWeight: '500' }}>
                      {parmEquipment.departmentID}
                    </div>

                    <CopyToClipboard text={parmEquipment.departmentID ?? ''} />
                  </div>
                </div>
              )}
            </div>
          )}
          <label htmlFor="Name" className="ketamine-general-label">
            Name
          </label>
          <div className="input-container">
            <InputText
              type="text"
              className="form-control-general"
              id="name"
              name="name"
              data-testid="name"
              value={parmEquipment.name}
              disabled
            />
            <div className="input-border"></div>
          </div>
          <label htmlFor="Title" className="ketamine-general-label">
            Title
          </label>
          <div className="input-container">
            <InputText
              type="text"
              className="form-control-general"
              id="title"
              name="title"
              data-testid="title"
              value={parmEquipment.title}
              disabled
            />
            <div className="input-border"></div>
          </div>
          <label htmlFor="Warning" className="ketamine-general-label">
            Warning
          </label>
          <div className="input-container">
            <InputText
              type="text"
              className="form-control-general"
              id="warning"
              name="warning"
              data-testid="warning"
              value={parmEquipment.warning}
              disabled
            />
            <div className="input-border"></div>
          </div>
          <label htmlFor="Instruction" className="ketamine-general-label">
            Instruction
          </label>
          <div className="input-container">
            <InputText
              type="text"
              className="form-control-general"
              id="instruction"
              name="instruction"
              data-testid="instruction"
              value={parmEquipment.instruction}
              disabled
            />
            <div className="input-border"></div>
          </div>
          <label htmlFor="Note" className="ketamine-general-label">
            Note
          </label>
          <div className="input-container">
            <InputText
              type="text"
              className="form-control-general"
              id="note"
              name="note"
              data-testid="note"
              value={parmEquipment.note}
              disabled
            />
            <div className="input-border"></div>
          </div>
        </div>
        <div className="KetamineGeneral">
          <h5 className="ketmine-header-text">Equipment Options</h5>
          <ViewportList items={parmEquipment.options}>
            {(item: EquipmentOption, index: number) => (
              <div key={index} style={{ cursor: 'pointer' }}>
                <EquipmentDose dose={item} doseIndex={index} disabled />
              </div>
            )}
          </ViewportList>
        </div>
      </div>
    </div>
  );
};

export default EquipmentProtocolArchivePage;
