import { timeStamp } from 'console';
import { DraftChange, ProgressStatus } from '../../API';
import { User } from '../../models';
import PatientWeight from '../../ui/_global/common/PatientWeight';
import {
  getProgressStatus,
  globals,
  isObjectEqual,
} from '../../ui/_global/common/Utils';
import { Response, ResponseType } from '../AmplifyDB';
import { fetchUser } from '../functions/UserDB';
import DepartmentItem from './DepartmentItem';
import DraftChangeItem from './DraftChangeItem';
import ModelInterface from './ModelInterface';

export const getMinString = (value: any): string => {
  if (value === 0 || value == null || value === '' || value === '0') {
    return 'Min';
  }
  return String(value);
};

export const getMaxString = (value: any): string => {
  if (
    value === globals.MAX_VALUE ||
    value === globals.MAX_VALUE_STR ||
    value == null ||
    value === ''
  ) {
    return 'Max';
  }
  return String(value);
};

class ModelItem<T> implements ModelInterface {
  TAG = 'ModelItem';
  id: string;
  uid: string;
  departmentID: string;
  name: string;
  model: T;
  anyModel: any;
  version: string;
  isPublic: boolean;
  status: ProgressStatus;
  activeID: string | null | undefined;
  overrideID: string | null | undefined;
  pairedDepIDs: string[] | null | undefined;
  activeItem: ModelItem<T> | null | undefined;
  overrideItem: ModelItem<T> | null | undefined;
  modifiedBy: User | null | undefined;
  keychainID: string | null | undefined;
  pairedDeps: DepartmentItem[] | undefined | null;
  createdAt: Date | null | undefined;
  updatedAt: Date | null | undefined;
  modifiedAt: Date;
  createdByID: string;
  modifiedByID: string | null | undefined;
  draftChange: DraftChangeItem | null | undefined;

  constructor(
    model: any,
    name: string,
    departmentID: string,
    status?: any | null,
    version?: string | null
  ) {
    this.id = model.id;
    this.uid = model.id;
    this.name = name;
    this.model = model;
    this.anyModel = model;
    this.departmentID = departmentID;
    this.activeID = model.activeID;
    this.overrideID = model.overrideID;
    this.status = getProgressStatus(status ? status : model.status);
    this.activeItem = null;
    this.modifiedBy = null;
    this.version = version ? version : model.version ? model.version : 'v1.0.0';
    this.isPublic = model.isPublic ? model.isPublic : false;
    this.keychainID = model.keychainID;
    this.pairedDepIDs = model.pairedDepIDs;
    this.updatedAt = model.updatedAt ? new Date(model.updatedAt) : null;
    this.createdAt = model.createdAt ? new Date(model.createdAt) : null;

    /* Grab the last modified date */
    this.modifiedAt = this.updatedAt
      ? this.updatedAt
      : this.createdAt
        ? this.createdAt
        : new Date();

    this.createdByID = model.createdBy;
    this.modifiedByID = model.modifiedBy;
  }

  async findUser(useDataStore = true): Promise<User | null> {
    return new Promise<User | null>((resolve, reject) => {
      try {
        let id: string = (this.model as any).modifiedBy
          ? (this.model as any).modifiedBy
          : (this.model as any).createdBy;
        fetchUser(id, useDataStore).then((respone: Response) => {
          if (respone.type === ResponseType.Success) {
            this.modifiedBy = respone.data as User;
            resolve(respone.data as User);
          } else {
            this.modifiedBy = null;
            resolve(null);
          }
        });
      } catch (error) {
        console.error('Error finding user', error);
        reject(error);
      }
    });
  }

  getUid(): string {
    return this.uid;
  }
  getName(): string {
    return this.name;
  }
  getAmount(weight: PatientWeight): string {
    throw new Error('Method at parent should not be called.');
  }
  getModel() {
    return this.model;
  }
  equals(obj: any): boolean {
    if (obj == null) return false;
    return isObjectEqual(this, obj);
  }
  toString(): string {
    return `ModelItem -> {
            uid=${this.uid},
            name=${this.name},
        }`;
  }
}

export default ModelItem;
