import CategoryItem from './CategoryItem';
import MedicationItem from './MedicationItem';
import EquipmentItem from './EquipmentItem';
import ElectricalItem from './ElectricalItem';
import MedicationSubItem from './MedicationSubItem';
import ElectricalSubItem from './ElectricalSubItem';
import { Form, Keychain, Protocol, User } from '../../models';
import FormItem from './FormItem';
import { DraftChange, ProgressStatus } from '../../API';
import {
  getObjectDifference,
  globals,
  isObjectEqual,
  isObjectInArray,
} from '../../ui/_global/common/Utils';
import DepartmentItem from './DepartmentItem';
import ModelItem from './ModelItem';
import PatientWeight from '../../ui/_global/common/PatientWeight';
import { ProtocolJSON } from '../functions/ProtocolDB';
import KeychainItem from './KeychainItem';
import InfusionSubItem from './InfusionSubItem';
import { fetchUser } from '../functions/UserDB';
import { Response, ResponseType } from '../AmplifyDB';
import CPRItem from './CPRItem';
import ModelSubItem from './ModelSubItem';

export const cloneProtocol = (protocol: ProtocolItem): ProtocolItem => {
  const clone = new ProtocolItem(protocol.model, protocol.parent);
  clone.pairedDeps = protocol.pairedDeps;
  clone.medications = protocol.medications;
  clone.infusions = protocol.infusions;
  clone.equipment = protocol.equipment;
  clone.electrical = protocol.electrical;
  clone.forms = protocol.forms;
  clone.pairedProtocols = protocol.pairedProtocols;
  clone.fileURI = protocol.fileURI;
  clone.createdBy = protocol.createdBy;
  clone.modifiedBy = protocol.modifiedBy;
  clone.sum = protocol.sum;
  clone.keychain = protocol.keychain;
  return clone;
};
export const cloneProtocolJSON = (protocol: ProtocolItem): ProtocolJSON => {
  const clone: ProtocolJSON = {
    departmentID: protocol.departmentID,
    name: protocol.name,
    nickname: protocol.nickname,
    index: protocol.index,
    rangeLow: protocol.rangeLow,
    rangeHigh: protocol.rangeHigh,
    parentCategory: protocol.parent,
    pdfID: protocol.pdfUrl,
    pairedDepsIDs: protocol.pairedDepIDs ? protocol.pairedDepIDs : [],
    pairedProtocols: protocol.model.pairedProtocols
      ? protocol.model.pairedProtocols
      : [],
    medications: protocol.model.medicationIDs
      ? protocol.model.medicationIDs
      : [],
    equipment: protocol.model.equipmentIDs ? protocol.model.equipmentIDs : [],
    electrical: protocol.model.electricalIDs
      ? protocol.model.electricalIDs
      : [],
    infusions: protocol.model.dripIDs ? protocol.model.dripIDs : [],
    forms: protocol.model.formIDs ? protocol.model.formIDs : [],

    createdBy: protocol.model.createdBy ? protocol.model.createdBy : '',
    modifiedBy: protocol.model.modifiedBy ? protocol.model.modifiedBy : '',

    status: protocol.status,
    activeID: protocol.activeID,
    version: protocol.version ? protocol.version : 'v1.0.0',
    pdfVersion: protocol.pdfVersion ? protocol.pdfVersion : 'v1.0.0',
    isPublic: protocol.isPublic,
    isRestrictive: protocol.isRestrictive,
    keychainID: protocol.keychainID,
    color: protocol.color,
  };
  return clone;
};
class ProtocolItem extends ModelItem<Protocol> {
  nickname: string;
  index: number;
  sum: number;
  rangeLow: number;
  rangeHigh: number;
  ageLow: number;
  ageHigh: number;
  parent: CategoryItem;
  parentID: string;
  medications: MedicationSubItem[];
  infusions: InfusionSubItem[];
  equipment: EquipmentItem[];
  electrical: ElectricalSubItem[];
  pairedProtocols: ProtocolItem[];
  forms: FormItem[];
  pairedDepIDs: string[] | null | undefined;
  pairedDeps: DepartmentItem[];
  pdfUrl: string;
  verifiedPdfUrl: string;
  pdfVersion: string;
  fileURI: string;
  createdBy: User | undefined | null;
  isRestrictive: boolean;
  cpr: CPRItem | undefined;
  color: string;
  pairedProtocolIDs: string[] | null | undefined;
  equipmentIDs: string[] | null | undefined;
  checklistIDs: string[] | null | undefined;

  keychain: KeychainItem | null | undefined;

  // loadedPdf             : File;
  _isCalculationNeeded: boolean;
  _isFormNeeded: boolean;

  constructor(protocol: Protocol, parent: CategoryItem) {
    super(protocol, protocol.name, protocol.departmentID);
    this.TAG = 'ProtocolItem';
    this.parent = parent;
    this.name = protocol.name;
    this.parentID = parent.id;
    this.nickname = protocol.nickname;
    this.pdfUrl = protocol.pdfID;
    this.verifiedPdfUrl = '';
    this.pdfVersion = protocol.pdfVersion ? protocol.pdfVersion : 'v1.0.0';
    this.fileURI = '';
    this.rangeLow = protocol.rangeLow ? protocol.rangeLow : 0;
    this.rangeHigh = protocol.rangeHigh
      ? protocol.rangeHigh
      : globals.MAX_VALUE;
    this.ageLow = protocol.ageLow ? protocol.ageLow : 0;
    this.ageHigh = protocol.ageHigh ? protocol.ageHigh : 0;

    //Cast the model to Protool
    this.model = protocol as Protocol;
    this.isPublic = protocol.isPublic ? protocol.isPublic : false;
    this.isRestrictive = protocol.isRestrictive
      ? protocol.isRestrictive
      : false;
    this.color = protocol.color ? protocol.color : '#616161';

    // this.loadedPdf          = null;
    this.pairedDepIDs = protocol.pairedDepIDs;
    this.pairedDeps = this.pairedDepIDs?.length ? [] : [];

    this.pairedProtocolIDs = protocol.pairedProtocols;
    this.equipmentIDs = protocol.equipmentIDs;
    this.checklistIDs = protocol.formIDs;

    this.pairedProtocols = [];
    this.medications = [];
    this.infusions = [];
    this.equipment = [];
    this.forms = [];
    this.electrical = [];
    this.index = protocol.index;

    this._isCalculationNeeded = false;
    this._isFormNeeded = false;
    this.sum = 0;
  }

  getAmount(weight: PatientWeight): string {
    throw new Error('Method not implemented.');
  }

  // constructor(parent : CategoryItem, name: string, index: number, _isCalculationNeeded: boolean, _isFormNeeded: boolean) {
  //     this.name               = name;
  //     this.parent             = parent;
  //     this.uid                = "1234-567-890";
  //     this.depID              = "00089c2e-9f11-409a-8b37-afa9924e965c";
  //     this.id                 = parent.getId() + index;
  //     this.pdfUrl             = "";
  //     this.protocolVersion    = "v1.0.0";
  //     this.loadedPdf          = null;
  //     this.pairedProtocols    = [];
  //     this.medications        = [];
  //     this.equipment          = [];
  //     this.forms              = [];
  //     this.electrical         = [];

  //     this._isCalculationNeeded = _isCalculationNeeded;
  //     this._isFormNeeded        = _isFormNeeded;

  // }

  addCPR(cpr: CPRItem): void {
    this.cpr = cpr;
  }
  getCPR(): CPRItem | undefined {
    return this.cpr;
  }

  addMedication(medication: MedicationSubItem): void {
    if (!this._isCalculationNeeded) this._isCalculationNeeded = true;
    let find = this.medications.find((item) =>
      this.isItemsEqual(item, medication)
    );
    if (find) return;
    this.sum += 1;
    this.medications.push(medication);
    this.medications.sort((a, b) => {
      if (a.index === b.index) {
        if (a.name === b.name) return a.rangeLow - b.rangeLow;
        return a.name.localeCompare(b.name);
      }
      return a.index - b.index;
    });
  }

  removeMedication(medication: MedicationSubItem): void {
    this.sum -= 1;
    this.medications = this.medications.filter((item) =>
      this.isItemsEqual(item, medication) ? false : true
    );
  }

  addInfusion(infusion: InfusionSubItem): void {
    if (!this._isCalculationNeeded) this._isCalculationNeeded = true;
    let find = this.infusions.find((item) => this.isItemsEqual(item, infusion));
    if (find) return;
    this.infusions.push(infusion);
    this.sum += 1;
    this.infusions.sort((a, b) => {
      if (a.index === b.index) {
        if (a.name === b.name) return a.rangeLow - b.rangeLow;
        return a.name.localeCompare(b.name);
      }
      return a.index - b.index;
    });
  }

  removeInfusion(infusion: InfusionSubItem): void {
    this.sum -= 1;
    this.infusions = this.infusions.filter((item) =>
      this.isItemsEqual(item, infusion) ? false : true
    );
  }

  addEquipment(equipment: EquipmentItem): void {
    if (!this._isCalculationNeeded) this._isCalculationNeeded = true;
    let find = this.equipment.find((item) =>
      this.isItemsEqual(item, equipment)
    );
    if (find) return;
    this.equipment.push(equipment);
    this.sum += 1;
    this.equipment.sort((a, b) => a.name.localeCompare(b.name));
  }

  addElectrical(electrical: ElectricalSubItem): void {
    if (!this._isCalculationNeeded) this._isCalculationNeeded = true;
    let find = this.electrical.find((item) =>
      this.isItemsEqual(item, electrical)
    );
    if (find) return;
    this.electrical.push(electrical);
    this.sum += 1;
    this.electrical.sort((a, b) => {
      if (a.index === b.index) {
        if (a.name === b.name) return a.rangeLow - b.rangeLow;
        return a.name.localeCompare(b.name);
      }
      return a.index - b.index;
    });
  }

  removeElectrical(electrical: ElectricalSubItem): void {
    this.sum -= 1;
    this.electrical = this.electrical.filter((item) =>
      this.isItemsEqual(item, electrical) ? false : true
    );
  }

  addForm(form: FormItem): void {
    if (!this._isFormNeeded) this._isFormNeeded = true;
    if (isObjectInArray(this.forms, form)) return;
    this.forms.push(form);
    this.sum += 1;
    this.forms.sort((a, b) => a.getName().localeCompare(b.getName()));
  }

  addPairedProtocol(protocol: ProtocolItem): void {
    if (isObjectInArray(this.pairedProtocols, protocol)) return;
    if (!this._isCalculationNeeded) this._isCalculationNeeded = true;

    // For sorting, consider implementing a comparison function
    this.pairedProtocols.push(protocol);
    this.sum += 1;
    this.pairedProtocols.sort((a, b) => a.getName().localeCompare(b.getName()));
  }

  getSum(): number {
    let sum = 1; // 1 for the mandatory protocol PDF
    sum += this.medications.length;
    sum += this.infusions.length;
    sum += this.equipment.length;
    sum += this.electrical.length;
    sum += this.forms.length;
    return sum;
  }

  isItemsEqual(
    item1: ModelItem<any> | ModelSubItem<any>,
    item2: ModelItem<any> | ModelSubItem<any>
  ): boolean {
    return isObjectEqual(item1, item2) || item1.uid === item2.uid;
  }

  setFileURI(uri: string): void {
    this.fileURI = uri;
  }

  getMedications(): MedicationSubItem[] {
    return this.medications;
  }

  getInfusions(): InfusionSubItem[] {
    return this.infusions;
  }

  getEquipment(): EquipmentItem[] {
    return this.equipment;
  }

  getElectrical(): ElectricalSubItem[] {
    return this.electrical;
  }

  getPairedProtocols(): ProtocolItem[] {
    return this.pairedProtocols;
  }

  getForms(): FormItem[] {
    return this.forms;
  }

  getUid(): string {
    return this.uid;
  }

  getName(): string {
    return this.name;
  }

  getNickname(): string {
    return this.nickname;
  }

  getPdfUrl(): string {
    return this.pdfUrl;
  }

  getFileURI(): string {
    return this.fileURI;
  }

  isCalculationNeeded(): boolean {
    return this._isCalculationNeeded;
  }

  isChecklistNeeded(): boolean {
    return this._isFormNeeded;
  }
  getParent(): CategoryItem {
    return this.parent;
  }

  getModel(): Protocol {
    return this.model;
  }

  getDescription(): string {
    return `${this.name}`;
  }

  setModifiedBy(user: User): void {
    this.modifiedBy = user;
  }

  setCreatedBy(user: User): void {
    this.createdBy = user;
  }

  equals(obj: any): boolean {
    return obj instanceof ProtocolItem && this.uid === obj.uid;
  }

  toString(): string {
    return `ProtocolItem -> {
            uid=${this.uid},
            name=${this.name},
            medications=${this.medications.length},
            infusions=${this.infusions.length},
            equipment=${this.equipment.length},
            electrical=${this.electrical.length},
            pairedProtocols=${this.pairedProtocols.length},
            forms=${this.forms.length},
            pdfUrl=${this.pdfUrl},
            Version=${this.version},
            PDF Version=${this.pdfVersion},
            isCalculationNeeded=${this._isCalculationNeeded},
            isFormNeeded=${this._isFormNeeded},
            URI=${this.fileURI}
            status=${this.status}
            activeID=${this.activeID}
            createdBy=${this.createdBy}
            modifiedBy=${this.modifiedBy}
            keychainID=${this.keychainID}
            keychain=${this.keychain}
            isPublic=${this.isPublic}
            
        }`;
  }
}

export default ProtocolItem;
