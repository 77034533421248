import * as _ from 'lodash';
import { InputText } from 'primereact/inputtext';
import { useState } from 'react';
import { HiPlus } from 'react-icons/hi';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { ViewportList } from 'react-viewport-list';
import { handleGetDepartment } from '../../../../../store/actions';
import ConfirmModal from '../../../../components/Modal/ConfirmModal';
import ProtocolDoseSideout from '../../../../components/SideOut/doseSideOut/ProtocolDoseSideout';
import ProtocolHeader from '../../ProtocolHeader';
import VitalItem from '../../../../../data/model/VitalItem';
import { VitalDB, createVital } from '../../../../../data/functions/VitalDB';
import { useFormik } from 'formik';
import ProtocolItem from '../../../../../data/model/ProtocolItem';
import { User, VitalOption } from '../../../../../models';
import { ProgressStatus } from '../../../../../API';
import {
  ResponseType,
  Response,
  loadDatabase,
  DatabaseResponse,
} from '../../../../../data/AmplifyDB';
import VitalDose from './VitalDose';
import {
  generateID,
  globals,
  isObjectEqual,
  mapRangeToIndex,
  removeTypename,
} from '../../../../_global/common/Utils';
import DepartmentItem from '../../../../../data/model/DepartmentItem';

interface VitalProtocolCreatePageProps {
  stateData?: any;
}

/* 11-02-23 Arul: Created Vital Protoco lCreatePage component globally */
const VitalProtocolCreateFromHM: React.FC<VitalProtocolCreatePageProps> = ({
  stateData,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { selectedProtocol, value, subValue, type, editType, editMode, page } =
    stateData;
  const hmParmItem: VitalItem = value;

  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [selectedData, setSelectedData] = useState<VitalItem | null>(null);
  const [editDose, setEditDose] = useState(false);
  const user: User = useSelector((state: any) => state?.user);
  const [database, setDatabase] = useState<DatabaseResponse>(
    useSelector((state: any) => state?.protocol?.departmentItem)
  );
  const department: DepartmentItem = database.department;
  const protocol: ProtocolItem = stateData.selectedProtocol;

  const [doseIndex, setDoseIndex] = useState<number>(0);
  const [isWarningModal, setIsWarningModal] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: hmParmItem ? hmParmItem.name : '',
      departmentID: department.id,
      optionItems: hmParmItem ? hmParmItem.options : [],
      protocol: protocol,
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Name is required'),
      departmentID: Yup.string(),
      protocol: Yup.string(),
      optionItems: Yup.array().required(),
    }),
    onSubmit: (values) => {},
  });

  const reloadDatabase = async (): Promise<boolean> => {
    /* 1-22-24 Guru:  Updated the current data to the database change and keep the current state */
    const resp: Response = await loadDatabase(database);
    if (resp.type === ResponseType.Success) {
      const newDB: DatabaseResponse = resp.data;
      setDatabase(newDB);
      dispatch<any>(handleGetDepartment(newDB));
      return true;
    } else {
      console.error('ERROR LOADING DATABASE', resp.data);
      return false;
    }
  };

  const handleCreateVital = async () => {
    try {
      if (formik.isValid && formik.values.optionItems.length > 0) {
        let cleanedItems = removeTypename(formik.values.optionItems);
        const newVital: VitalDB = {
          title: formik.values.name,
          departmentID: department.id,
          optionItems: cleanedItems,
          status: ProgressStatus.DRAFT,
          activeID: null,
          version: 'v1.0.0',
          createdBy: user.id,
        };
        let results: Response = await createVital(newVital);
        if (results.type === ResponseType.Success) {
          if (globals.debug)
            console.log('Successfully created Vital', results.data);
          let isLoaded: boolean = await reloadDatabase();
          if (isLoaded) {
            if (globals.debug) console.log('Successfully reloaded database');
            const vital: VitalItem = results.data;
            if (protocol) {
              navigate(`/${protocol.nickname}/protocol-detail`, {
                state: { selectedProtocol: protocol, editMode: true },
              });
            } else
              navigate(`/database/list-vitals`, {
                state: {
                  department: department,
                  data: database.vitals,
                  database: database,
                },
              });
          }
        }
      } else {
        /* If the form is invalid, then show the error message */
        if (globals.debug)
          console.log('Form is invalid, showing error message');
      }
    } catch (error) {
      if (globals.debug) console.log(error);
    }
  };

  /* 11-3-23 Arul: Close the sideout */
  const handleCloseModal = (e: any) => {
    setSidebarVisible(false);
    setSelectedData(null);
  };

  const handleVitalOptionCreate = (data: any, prevDose?: VitalOption) => {
    const rangeLowIndex =
      data.rangeLow !== '' && data.rangeLow !== 'MIN'
        ? mapRangeToIndex(data.rangeLow)
        : mapRangeToIndex('MIN');
    const rangeHighIndex =
      data.rangeHigh !== '' && data.rangeHigh !== 'MAX'
        ? mapRangeToIndex(data.rangeHigh)
        : mapRangeToIndex('MAX');

    let newVitalOption: VitalOption = {
      id: generateID(),
      amntLow: data.valueLow,
      amntHigh: data.valueHigh,
      rangeLow: rangeLowIndex,
      rangeHigh: rangeHighIndex,
      index:
        prevDose && prevDose.index
          ? prevDose.index
          : formik.values.optionItems.length,
      // rangeLow: data.rangeLow ? Number(data.rangeLow) : 0,
      // rangeHigh: data.rangeHigh ? Number(data.rangeHigh) : globals.MAX_VALUE,
    };

    if (!prevDose || formik.values.optionItems.length === 0) {
      // If there is no previous dose or the list is empty, add a new item
      let newOptions = [...formik.values.optionItems, newVitalOption];
      newOptions.sort(
        (a: VitalOption, b: VitalOption) => a.rangeLow - b.rangeLow
      );
      formik.setFieldValue('optionItems', newOptions);
    } else if (prevDose) {
      // If there is a previous dose, update the existing item at the correct index
      let updatedOptionItems: VitalOption[] = formik.values.optionItems.filter(
        (item: any) => !isObjectEqual(prevDose, item)
      );
      updatedOptionItems.push(newVitalOption);
      updatedOptionItems.sort(
        (a: VitalOption, b: VitalOption) => a.rangeLow - b.rangeLow
      );
      formik.setFieldValue('optionItems', updatedOptionItems);
    } else {
      console.error(
        'Unexpected case: prevDose is not provided and also optionItems is not empty.'
      );
    }
  };

  const handleCancel = () => {
    /* If the form is dirty, then show the warning modal */
    if (formik.dirty) {
      setIsWarningModal(true);
    } else if (protocol) {
      navigate(`/${protocol.nickname}/protocol-detail`, {
        state: { selectedProtocol: protocol, editMode: true },
      });
    } else {
      navigate(`/database/list-vitals`, {
        state: {
          department: department,
          data: database.medications,
          database: database,
        },
      });
    }
  };

  // Author: Guruprasad Venkatraman (01-23-2024)
  // Remvoing the dose from the formik state and updating the formik state
  const handleRemoveVital = (dose: any) => {
    const updatedOptionItems = formik.values.optionItems.filter(
      (item) => !isObjectEqual(dose, item)
    );

    // Set the filtered items back to the formik state
    formik.setFieldValue('optionItems', updatedOptionItems);
  };

  const onAddDoseClick = () => {
    setEditDose(false);
    setSidebarVisible(!sidebarVisible);
  };

  return (
    <div className="screen-container">
      {sidebarVisible && (
        <ProtocolDoseSideout
          type="Vital"
          dose={selectedData ? selectedData : undefined}
          visible={sidebarVisible}
          setVisible={handleCloseModal}
          editDose={editDose}
          doseIndex={doseIndex}
          parentModel={null}
          protocol={null}
          onSubmit={handleVitalOptionCreate}
          onRemove={handleRemoveVital}
        />
      )}
      {isWarningModal && (
        <ConfirmModal
          isVisible={isWarningModal}
          title="Abandon Changes?"
          handleClose={() => {
            setIsWarningModal(false);
          }}
          handleSubmit={handleCancel}
          isDeleteBtn={true}
          primaryBtnName="Cancel"
          secondaryBtnName="Abandon"
          primaryDescription={`Changes were made to this Vital.  Click cancel to return to Vital details.  To continue without saving changes, select Abandon Changes.`}
        />
      )}
      <ProtocolHeader
        name={'Create From HM Vital'}
        type={'protocol'}
        page={protocol ? protocol.name : 'Vitals'}
        protocolDetail={protocol}
        isCreateButton={true}
        isBackButton={true}
        isDotButton={true}
        isCreateActive={formik.isValid}
        handleCancel={handleCancel}
        handleCreate={handleCreateVital}
      />
      <div className="ketamineContent">
        <div className="KetamineGeneral">
          <h5 className="ketmine-header-text" data-testid="vital-header">
            Vital Information
          </h5>
          <label htmlFor="Name" className="ketamine-general-label">
            Name <span className="required-field">*</span>
          </label>
          <div className="input-container">
            <InputText
              type="text"
              className="form-control-general"
              id="Name"
              name="name"
              data-testid="name"
              value={formik.values.name}
              onChange={(e: any) => {
                formik.setFieldValue('name', e.target.value);
              }}
              onBlur={formik.handleBlur}
            />
            <div className="input-border"></div>
          </div>
          {formik.touched.name && formik.errors.name ? (
            <span className="errorText">{formik.errors.name}</span>
          ) : null}
        </div>
        <div className="KetamineGeneral">
          <h5 className="ketmine-header-text">Vital Options</h5>
          <div style={{ marginTop: '5px' }}>
            <span className="ketamine-general-label">Option</span>
            <div
              onClick={onAddDoseClick}
              className="cursorPointer contentBorder protocolCalculationPad contentHeading newProtocolBorder newRouteButton"
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <span>
                <HiPlus className="text-icon " /> Add Option
              </span>
            </div>
          </div>
          <ViewportList items={formik.values.optionItems}>
            {(item: any, index: any) => (
              <div
                key={index}
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setSelectedData(item);
                  setSidebarVisible(!sidebarVisible);
                  setDoseIndex(index);
                  setEditDose(true);
                }}
              >
                <VitalDose dose={item} doseIndex={index} />
              </div>
            )}
          </ViewportList>
        </div>
      </div>
    </div>
  );
};

export default VitalProtocolCreateFromHM;
