import { useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import DepartmentItem from '../../../../../data/model/DepartmentItem';
import VitalItem from '../../../../../data/model/VitalItem';
import FormItem from '../../../../../data/model/FormItem';
import ProtocolHeader from '../../../protocol/ProtocolHeader';
import { InputText } from 'primereact/inputtext';
import { Button } from 'react-bootstrap';
import Dropdown from '../../../../components/Dropdown/Dropdown';
import { DraftChangeType } from '../../../../../data/model/DraftChangeItem';
import {
  deleteCategoryItem,
  getCategoryByID,
} from '../../../../../data/functions/CategoryDB';
import { DatabaseResponse } from '../../../../../data/AmplifyDB';
import ConfirmModal from '../../../../components/Modal/ConfirmModal';
import CopyToClipboard from '../../../../components/CopyToClipboard';
import { FaMagnifyingGlass } from 'react-icons/fa6';
import {
  deleteProtocolItem,
  getProtocolByID,
} from '../../../../../data/functions/ProtocolDB';
import { toTitleCase } from '../../../../_global/common/Utils';
import CategoryItem from '../../../../../data/model/CategoryItem';
import { User } from '../../../../../models';
import ProtocolItem from '../../../../../data/model/ProtocolItem';
import {
  deleteMedicationDoseItem,
  getMedicationByID,
  getMedicationDoseByID,
} from '../../../../../data/functions/MedicationDB';
import {
  deleteInfusionDoseItem,
  getInfusionByID,
  getInfusionDoseByID,
} from '../../../../../data/functions/InfusionDB';
import {
  deleteElectricalDoseItem,
  getElectricalByID,
  getElectricalDoseByID,
} from '../../../../../data/functions/ElectricalDB';
import {
  deleteEquipmentItem,
  getEquipmentByID,
} from '../../../../../data/functions/EquipmentDB';
import {
  deleteVitalItem,
  getVitalByID,
} from '../../../../../data/functions/VitalDB';
import {
  deleteChecklistItem,
  getChecklistByID,
} from '../../../../../data/functions/CheckListDB';
import {
  deleteWorkbook,
  getWorkbookByID,
} from '../../../../../data/functions/WorkbookDB';
import { deleteCPRItem, getCPRByID } from '../../../../../data/functions/CprDB';
import MedicationItem from '../../../../../data/model/MedicationItem';
import MedicationSubItem from '../../../../../data/model/MedicationSubItem';
import InfusionSubItem from '../../../../../data/model/InfusionSubItem';
import InfusionItem from '../../../../../data/model/InfusionItem';
import ElectricalItem from '../../../../../data/model/ElectricalItem';
import ElectricalSubItem from '../../../../../data/model/ElectricalSubItem';
import EquipmentItem from '../../../../../data/model/EquipmentItem';
import CPRItem from '../../../../../data/model/CPRItem';
import WorkbookItem from '../../../../../data/model/WorkbookItem';
import { Divider } from 'primereact/divider';
import {
  getAllUsersByCognitoID,
  getAllUsersByEmail,
  getUserByCognitoID,
  getUserByID,
} from '../../../../../data/functions/UserDB';
import { IoIosArrowDown, IoIosArrowForward } from 'react-icons/io';
import SearchBar from '../../../../components/Search/SearchBar';
import { getDepartmentByID } from '../../../../../data/functions/DepartmentDB';

const AVAILABLE_TYPES = [
  { label: 'Department', value: 'Department' },
  { label: 'User', value: 'User' },
  { label: 'Category', value: 'Category' },
  { label: 'Protocol', value: 'Protocol' },
  { label: 'Medication', value: 'Medication' },
  { label: 'Medication Dose', value: 'MedicationDose' },
  { label: 'Infusion', value: 'Drip' },
  { label: 'Infusion Dose', value: 'InfusionDose' },
  { label: 'Electrical', value: 'ElectricalShock' },
  { label: 'Electrical Dose', value: 'ElectricalDose' },
  { label: 'Equipment', value: 'Equipment' },
  { label: 'Vital', value: 'Vitals' },
  { label: 'Form', value: 'Form' },
  { label: 'Workbook', value: 'Workbook' },
  { label: 'CPRAssist', value: 'CPRAssist' },
  { label: 'Ambulance', value: 'Ambulance' },
  { label: 'InputForm', value: 'InputForm' },
  { label: 'Notification', value: 'Notification' },
  { label: 'Concentration', value: 'Concentration' },
  { label: 'WeightObject', value: 'WeightObject' },
  { label: 'MedicShift', value: 'MedicShift' },
  { label: 'PatientInteraction', value: 'PatientInteraction' },
  { label: 'Invite', value: 'Invite' },
  { label: 'Keychain', value: 'Keychain' },
  { label: 'Group', value: 'Group' },
  { label: 'Notify', value: 'Notify' },
  { label: 'NotifyACK', value: 'NotifyACK' },
  { label: 'DeveloperNotification', value: 'DeveloperNotification' },
  { label: 'OneWeight', value: 'OneWeight' },
  { label: 'Log', value: 'Log' },
  { label: 'Reviewal', value: 'Reviewal' },
  { label: 'DraftGroup', value: 'DraftGroup' },
  { label: 'DraftChange', value: 'DraftChange' },
  { label: 'Acknowledge', value: 'Acknowledge' },
  { label: 'UserComment', value: 'UserComment' },
];

/* 09-27-23 Arul: Created Component for Protocol Screen*/
const FindModelPage = (props: any) => {
  const navigate = useNavigate();
  const database: DatabaseResponse = useSelector(
    (state: any) => state.protocol.departmentItem
  );
  const user: User = useSelector((state: any) => state.user);
  const department: DepartmentItem = database.department;
  const [info, setInfo] = useState<any>({
    id: '',
    username: '',
    email: '',
    item: null,
    type: 'Protocol',
    isSoftDelete: false,
  });

  const [confirm, setConfirm] = useState<any>({
    title: '',
    description: '',
    isVisible: false,
    isSingleBtn: false,
    secondaryBtnName: '',
    primaryDescription: '',
    isDeleteBtn: false,
    primaryBtnName: '',
  });

  const [expandedItems, setExpandedItems] = useState<{
    [key: string]: boolean;
  }>({});

  const [searchText, setSearchText] = useState('');

  const toggleExpand = (index: number) => {
    setExpandedItems((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const handleBack = () => {
    navigate(`/actions/database-actions`);
  };

  const isIDValid = useMemo(() => {
    //Check if it is a valid UUIDv4
    return /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[1-5][0-9a-fA-F]{3}-[89ab][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/.test(
      info.id
    );
  }, [info]);

  const isInputValid = useMemo(() => {
    if (info.type === 'User') {
      let emailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(info.email);
      return (
        (info.username.length > 0 && info.username.length < 256) ||
        (info.email.length > 0 && info.email.length < 256 && emailValid) ||
        (info.id.length > 0 && info.id.length < 256 && isIDValid)
      );
    } else return isIDValid;
  }, [info, isIDValid]);

  const handleFind = async () => {
    if (!isInputValid) return;
    let item: any = null;
    switch (info.type) {
      case 'Department':
        item = await getDepartmentByID(info.id);
        break;
      case 'Category':
        item = await getCategoryByID(database, info.id);
        break;
      case 'Protocol':
        item = await getProtocolByID(database, info.id);
        break;
      case 'Medication':
        item = await getMedicationByID(database, info.id);
        break;
      case 'MedicationDose':
        item = await getMedicationDoseByID(database, info.id);
        break;
      case 'Infusion':
        item = await getInfusionByID(database, info.id);
        break;
      case 'InfusionDose':
        item = await getInfusionDoseByID(database, info.id);
        break;
      case 'Electrical':
        item = await getElectricalByID(database, info.id);
        break;
      case 'ElectricalDose':
        item = await getElectricalDoseByID(database, info.id);
        break;
      case 'Equipment':
        item = await getEquipmentByID(database, info.id);
        break;
      case 'Vitals':
        item = await getVitalByID(database, info.id);
        break;
      case 'Form':
        item = await getChecklistByID(database, info.id);
        break;
      case 'Workbook':
        item = await getWorkbookByID(database, info.id);
        break;
      case 'CPRAssist':
        item = await getCPRByID(database, info.id);
        break;
      case 'User':
        if (info.id && isIDValid) {
          item = await getUserByID(info.id, true);
          item = [item];
        } else if (info.email && info.email.length > 0) {
          console.log('Getting user by email', info.email);
          item = await getAllUsersByEmail(info.email);
          if (info.username && info.username.length > 0) {
            item = item.filter((user: any) =>
              user.cognitoID.toLowerCase().includes(info.username.toLowerCase())
            );
          }
        } else if (info.username && info.username.length > 0) {
          console.log('Getting user by username', info.username);
          item = await getAllUsersByCognitoID(info.username, true);
          if (info.email && info.email.length > 0) {
            item = item.filter((user: any) =>
              user.email.toLowerCase().includes(info.email.toLowerCase())
            );
          }
        }
        break;
      default:
        window.alert(
          'CONTACT COLTON: Find Model is not implemented for this type: ' +
            info.type
        );
        return;
    }
    if (item) {
      setInfo({ ...info, item: item });
    } else {
      setConfirm({
        title: 'Error: Item Not Found',
        secondaryDescription: 'ID: ' + info.id + '\nType: ' + info.type,
        isVisible: true,
        isSingleBtn: true,
        secondaryBtnName: 'Okay',
        primaryDescription: 'The item you are trying to delete was not found.',
        primaryBtnName: 'Okay',
        isDeleteBtn: false,
        handleSubmit: () => {
          setConfirm({ ...confirm, isVisible: false });
          setInfo({ ...info, item: null });
        },
        handleClose: () => {
          setConfirm({ ...confirm, isVisible: false });
          setInfo({ ...info, item: null });
        },
      });
    }
  };

  const isArrayResponse = (response: any) => {
    return Array.isArray(response);
  };

  const searchInObject = (obj: any, searchTerm: string): boolean => {
    if (!searchTerm) return true;
    const term = searchTerm.toLowerCase();

    if (!obj) return false;

    if (typeof obj !== 'object') {
      return String(obj).toLowerCase().includes(term);
    }

    return Object.entries(obj).some(([key, value]) => {
      const keyMatch = key.toLowerCase().includes(term);
      const valueMatch =
        typeof value === 'object'
          ? searchInObject(value, term)
          : String(value).toLowerCase().includes(term);
      return keyMatch || valueMatch;
    });
  };

  const list = useMemo(() => {
    if (isArrayResponse(info.item)) {
      return info.item.filter((item: any) => searchInObject(item, searchText));
    }
    return [info.item];
  }, [info.item, searchText]);

  return (
    <div className="screen-container">
      <ProtocolHeader
        page={'Actions'}
        name={'Find Model Page'}
        description={
          'Find any model in the database by entering the id and selecting the type'
        }
        isBackButton={true}
        rightSideBtn={'edit'}
        isEditButton={false}
        isCancelButton={false}
        isDeleteDisabled={false}
        isSaveButton={false}
        type={'protocol'}
        handleCancel={handleBack}
      />
      <ConfirmModal
        isVisible={confirm.isVisible}
        title={confirm.title}
        handleClose={confirm.handleClose}
        handleSubmit={confirm.handleSubmit}
        isSingleBtn={confirm.isSingleBtn}
        secondaryBtnName={confirm.secondaryBtnName}
        primaryDescription={confirm.primaryDescription}
        isDeleteBtn={confirm.isDeleteBtn}
        secondaryDescription={confirm.secondaryDescription}
        primaryBtnName={confirm.primaryBtnName}
      />
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={{ flex: 1, minWidth: '20vw', maxWidth: '30vw' }}>
          {/* Left column */}
          <div
            style={{
              display: 'flex',
              marginTop: '20px',
              alignItems: 'center',
              // justifyContent: 'center',
              gap: '24px',
            }}
          >
            <Dropdown
              value={info.type}
              options={AVAILABLE_TYPES}
              onChange={(value: any) => {
                setInfo({ ...info, type: value, item: null });
              }}
              style={{
                marginTop: '-6px',
                padding: 0,
              }}
              buttonColor={'#E0EADD'}
              textColor={'#00534C'}
            />
            <Button
              disabled={!isInputValid}
              className="primary-button"
              onClick={handleFind}
            >
              <span>
                <FaMagnifyingGlass className="icon-normal" size="1rem" />
              </span>{' '}
              Find
            </Button>
          </div>

          <label
            className="ketamine-general-label"
            style={{ marginTop: '20px' }}
          >
            {info.type} ID: <span className="required-field">*</span>
            {isIDValid && (
              <CopyToClipboard text={info.id} style={{ marginLeft: '10px' }} />
            )}
          </label>
          <p className="sidebarText" style={{ marginLeft: '10px' }}>
            Enter in the id of the model you want to find.
          </p>
          <div className="input-container">
            <InputText
              type="text"
              className="form-control-general"
              id="pin"
              data-testid="action"
              name="pin"
              value={info.id}
              onChange={(e: any) => {
                setInfo({ ...info, id: e.target.value, item: null });
              }}
            />
            <div className="input-border"></div>
          </div>

          {info.type === 'User' && (
            <>
              <div
                style={{
                  marginTop: '20px',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Divider
                  style={{
                    padding: '20px 10px',
                  }}
                />
                <label
                  className="ketamine-general-label"
                  style={{ padding: '0 16px 0 10px' }}
                >
                  or
                </label>
                <Divider
                  style={{
                    padding: '20px 10px',
                  }}
                />
              </div>
              <label className="ketamine-general-label" style={{}}>
                Username: <span className="required-field">*</span>
                {isIDValid && (
                  <CopyToClipboard
                    text={info.id}
                    style={{ marginLeft: '10px' }}
                  />
                )}
              </label>
              <p className="sidebarText" style={{ marginLeft: '10px' }}>
                Enter in the username of the user you want to find.
              </p>
              <div className="input-container">
                <InputText
                  type="text"
                  className="form-control-general"
                  id="username"
                  data-testid="action"
                  name="username"
                  value={info.username}
                  onChange={(e: any) => {
                    setInfo({ ...info, username: e.target.value, item: null });
                  }}
                  onSubmit={handleFind}
                />
                <div className="input-border"></div>
              </div>
              <div
                style={{
                  marginTop: '20px',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Divider
                  style={{
                    padding: '20px 10px',
                  }}
                />
                <label
                  className="ketamine-general-label"
                  style={{ padding: '0 16px 0 10px' }}
                >
                  or
                </label>
                <Divider
                  style={{
                    padding: '20px 10px',
                  }}
                />
              </div>
              <label className="ketamine-general-label" style={{}}>
                Email: <span className="required-field">*</span>
                {isIDValid && (
                  <CopyToClipboard
                    text={info.id}
                    style={{ marginLeft: '10px' }}
                  />
                )}
              </label>
              <p className="sidebarText" style={{ marginLeft: '10px' }}>
                Enter in the email of the user you want to find.
              </p>
              <div className="input-container">
                <InputText
                  type="text"
                  className="form-control-general"
                  id="email"
                  data-testid="action"
                  name="email"
                  value={info.email}
                  onChange={(e: any) => {
                    setInfo({ ...info, email: e.target.value, item: null });
                  }}
                  onSubmit={handleFind}
                />
                <div className="input-border"></div>
              </div>
            </>
          )}
        </div>
        <div style={{ flex: 1, width: '60vw' }}>
          {info.item && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
              }}
            >
              <label
                className="ketamine-general-label"
                style={
                  {
                    // margin: 0,
                  }
                }
              >
                Found{' '}
                {toTitleCase(info.type) +
                  (isArrayResponse(info.item)
                    ? ' ' + list.length + ' results'
                    : '')}
                {info.item.name ? ' ' + info.item.name : ''}
                <span className="required-field">*</span>
              </label>
              {!isArrayResponse(info.item) ? (
                <span
                  style={{
                    fontWeight: '600',
                    fontSize: '14px',
                    color: '#616161',
                    marginLeft: '12px',
                    margin: 0,
                    padding: 0,
                  }}
                >
                  ID: {info.id}
                  <CopyToClipboard
                    text={info.id}
                    style={{ marginLeft: '10px' }}
                  />
                </span>
              ) : (
                <SearchBar
                  value={searchText}
                  onChange={(value: string) => setSearchText(value)}
                  placeholder="Search in results..."
                  containerStyle={{
                    width: '50%',
                    marginLeft: '10px',
                  }}
                />
              )}

              {isArrayResponse(info.item)
                ? list.map((item: any, index: number) => (
                    <div
                      key={index}
                      style={{
                        marginLeft: '10px',
                        backgroundColor: '#f5f5f5',
                        alignItems: 'center',
                        padding: '12px',
                        borderRadius: '8px',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          cursor: 'pointer',
                        }}
                        onClick={() => toggleExpand(index)}
                      >
                        {expandedItems[index] ? (
                          <IoIosArrowDown size={20} />
                        ) : (
                          <IoIosArrowForward size={20} />
                        )}
                        <label
                          className="ketamine-general-label"
                          style={{ marginLeft: '8px' }}
                        >
                          Result {index + 1}
                        </label>
                      </div>
                      {expandedItems[index] && (
                        <p
                          className="sidebarText"
                          style={{
                            whiteSpace: 'pre-wrap',
                            marginLeft: '28px',
                            backgroundColor: '#f5f5f5',
                            padding: '12px',
                            borderRadius: '4px',
                          }}
                        >
                          {item.model
                            ? JSON.stringify(item.model, null, 2)
                            : JSON.stringify(item, null, 2)}
                        </p>
                      )}
                    </div>
                  ))
                : searchInObject(info.item, searchText) && (
                    <p
                      className="sidebarText"
                      style={{
                        marginLeft: '10px',
                        whiteSpace: 'pre-wrap',
                        backgroundColor: '#f5f5f5',
                        padding: '12px',
                        borderRadius: '4px',
                      }}
                    >
                      {info.item.model
                        ? JSON.stringify(info.item.model, null, 2)
                        : JSON.stringify(info.item, null, 2)}
                    </p>
                  )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FindModelPage;
