import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ProtocolHeader from '../../protocol/ProtocolHeader';
import { FaChevronRight, FaLock } from 'react-icons/fa6';
import { ProgressStatus } from '../../../../API';
import {
  compareVersions,
  getFormattedDate,
  globals,
  linkSubItemsToItem,
  updateArchiveItemsCreatedAt,
  upgradeVersion,
} from '../../../_global/common/Utils';
import { ElectricalDose, ElectricalShock, User } from '../../../../models';
import Status from '../../../components/ProgressStatus/ProgressStatus';
import ElectricalItem from '../../../../data/model/ElectricalItem';
import ElectricalSubItem from '../../../../data/model/ElectricalSubItem';
import DataList, { Header } from '../../database/lists/DataList';
import { Alert, Snackbar } from '@mui/material';
import {
  ArchiveItem,
  reloadModelItem,
} from '../../../../data/functions/ModelDB';
import { useSelector } from 'react-redux';
import {
  createElectricalDoseItem,
  deleteElectricalDoseItem,
  ElectricalDoseJSON,
} from '../../../../data/functions/ElectricalDB';
import { ResponseType } from '../../../../data/AmplifyDB';
import ProtocolItem from '../../../../data/model/ProtocolItem';
import PatientAge from '../../../_global/common/PatientAge';
import ConfirmModal from '../../../components/Modal/ConfirmModal';
import SubItemRestoreSideout from '../../../components/SideOut/doseSideOut/SubItemRestoreSideout';
import CPRItem from '../../../../data/model/CPRItem';

const DEFAULT_USERNAME = 'Hinckley Medical';

const ListArchiveElectricalDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;

  // Validate location state
  if (
    !state?.data ||
    !state?.department ||
    !state?.database ||
    !state?.archiveData ||
    !state?.activeItem ||
    !state?.tab
  ) {
    navigate('/archive');
  }

  const {
    data: sourceData,
    subData,
    department,
    database,
    archiveData: electrical,
    activeItem,
    tab,
  } = state;

  const user: User = useSelector((state: any) => state?.user);

  const [activeParmElectrical, setActiveParmElectrical] = useState<
    ElectricalItem | ElectricalSubItem
  >(activeItem);

  const [selectedShock, setSelectedShock] = useState<ElectricalSubItem | null>(
    null
  );
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [subArchiveData, setSubArchiveData] = useState<ArchiveItem[]>(subData);

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success' as 'success' | 'error' | 'warning' | 'info',
  });

  const [electricalList, setElectricalList] = useState<
    ElectricalItem[] | ElectricalSubItem[]
  >(
    electrical.sort(
      (
        a: ElectricalItem | ElectricalSubItem,
        b: ElectricalItem | ElectricalSubItem
      ) => compareVersions(b.version, a.version)
    )
  );

  const isDraft = useMemo(() => {
    return activeParmElectrical.status === ProgressStatus.DRAFT;
  }, [activeParmElectrical]);

  const [modal, setModal] = useState({
    isVisible: false,
    title: '',
    primaryDescription: '',
    primaryBtnName: '',
    secondaryBtnName: '',
    handleClose: () => {},
    handleSubmit: () => {},
  });

  const reloadItem = async () => {
    if (
      activeParmElectrical instanceof ElectricalSubItem ||
      activeParmElectrical.TAG === 'ElectricalSubItem'
    ) {
      const response = await reloadModelItem(
        ElectricalDose,
        activeParmElectrical
      );
      if (response.type === ResponseType.Success) {
        const cur = activeParmElectrical as ElectricalSubItem;
        const item = new ElectricalSubItem(
          cur.parent,
          cur.parentCPR || cur.parentProtocol,
          response.data.item as ElectricalDose
        );
        const activeItem = response.data.activeItem as
          | ElectricalDose
          | null
          | undefined;
        item.activeItem = activeItem
          ? new ElectricalSubItem(
              cur.parent,
              cur.parentCPR || cur.parentProtocol,
              activeItem as ElectricalDose
            )
          : null;
        setActiveParmElectrical(item);
      }
    } else {
      const response = await reloadModelItem(
        ElectricalShock,
        activeParmElectrical
      );
      if (response.type === ResponseType.Success) {
        const item = new ElectricalItem(response.data.item as ElectricalShock);
        const activeItem = response.data.activeItem as
          | ElectricalShock
          | null
          | undefined;
        item.activeItem = activeItem ? new ElectricalItem(activeItem) : null;
        item.subItems = activeParmElectrical.subItems;
        item.parents = activeParmElectrical.parents;
        setActiveParmElectrical(item);
      }
    }
  };

  /**
   * Check if the draft exists for the active item
   */
  useEffect(() => {
    reloadItem();
  }, []);

  /* Hotkey detection */
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.ctrlKey && event.shiftKey && event.key === 'O') {
        console.log('Active Electrical', activeParmElectrical);
        console.log('Archive Electricals', electrical);
        console.log('Tab', tab);
      }
    };

    // Add event listener
    document.addEventListener('keydown', handleKeyDown);

    // Remove event listener on cleanup
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [activeParmElectrical, electrical, tab]);

  const handleItemClick = useCallback(
    (elec: ElectricalItem | ElectricalSubItem) => {
      console.log('Electrical Item', elec);
      if (tab === 'Electrical') {
        navigate(`/archive/electrical-archive`, {
          state: {
            value: elec,
            sourceData: sourceData,
            subData: subArchiveData,
            archiveData: electrical,
            department: department,
            database: database,
            activeItem: activeParmElectrical,
          },
        });
      } else {
        setSelectedShock(elec as ElectricalSubItem);
        setSidebarVisible(true);
      }
    },
    [
      navigate,
      sourceData,
      electrical,
      department,
      database,
      activeParmElectrical,
      tab,
      subArchiveData,
    ]
  );

  /*function to close the modal*/
  const handleCloseModal = () => {
    setSidebarVisible(false);
    setSelectedShock(null);
    // setSelectionModel([]);
  };

  const handleBack = useCallback(() => {
    navigate(`/archive/list-electricals`, {
      state: {
        data: sourceData,
        subData: subArchiveData,
        department: department,
        database: database,
        tab: tab,
      },
    });
  }, [navigate, sourceData, department, database, subArchiveData, tab]);

  const handleDoseRestore = async () => {
    setModal({ ...modal, isVisible: false });
    try {
      const isDraft = activeParmElectrical.status === 'DRAFT';
      const oldActiveItem = isDraft
        ? activeParmElectrical.activeItem
        : activeParmElectrical;

      if (isDraft) {
        // Delete the existing draft
        const response = await deleteElectricalDoseItem(
          activeParmElectrical as ElectricalSubItem,
          false
        );

        if (response.type === ResponseType.Failure) {
          setSnackbar({
            open: true,
            message: 'Failed to delete already existing draft.',
            severity: 'error',
          });
          // Exit the function
          console.error('Failed to delete electrical shock', response.data);
          setSidebarVisible(false);
          setSelectedShock(null);
          return;
        }
      }

      const protocol = (activeParmElectrical as ElectricalSubItem)
        ?.parentProtocol;
      let protocolID =
        protocol.status === ProgressStatus.ACTIVE || protocol.activeID == null
          ? protocol.uid
          : protocol.activeID;

      const cpr = database.cprModel;

      if (
        !protocolID &&
        (activeParmElectrical as ElectricalSubItem).cprAssistID === null
      ) {
        console.error('Failed to find protocol ID or CPR Assist ID');
        return;
      }

      /* Create a new ElectricalDose for the medicaiton */
      let prevDose = activeParmElectrical as ElectricalSubItem;
      const isOwner = prevDose ? department.id === prevDose.departmentID : true;
      let index = prevDose
        ? prevDose.index
        : (protocol as ProtocolItem).electrical.length;
      if (department.parentDep?.parentDep) index += 10000;
      else if (department.parentDep) index += 1000;

      let newMedDose: ElectricalDoseJSON = {
        departmentID: department.id,
        electrical: (oldActiveItem as ElectricalSubItem).parent,
        protocol: (oldActiveItem as ElectricalSubItem).protocolID
          ? protocol
          : undefined,
        cpr: (oldActiveItem as ElectricalSubItem).cprAssistID
          ? (cpr as CPRItem)
          : undefined,
        rangeLow: selectedShock?.model?.rangeLow
          ? Number(selectedShock.model?.rangeLow)
          : 0,
        rangeHigh: selectedShock?.model?.rangeHigh
          ? Number(selectedShock.model?.rangeHigh)
          : globals.MAX_VALUE,
        createdBy: (selectedShock?.model?.createdBy || user.id) as string,
        basis: selectedShock?.model?.basis || '',
        title: selectedShock?.model?.title || '',
        warning: selectedShock?.model?.warning || '',
        instruction: selectedShock?.model?.instruction || '',
        note: selectedShock?.model?.note || '',
        maxDose: selectedShock?.model?.maxDose,
        minDose: selectedShock?.model?.minDose,
        maxTotalDose: selectedShock?.model?.maxTotalDose,
        calcMax: selectedShock?.model?.calcMax,
        calcMin: selectedShock?.model?.calcMin,
        index: index,
        ageLow: selectedShock?.ageLow
          ? (selectedShock.ageLow as PatientAge).ageValue
          : 0,
        ageHigh: selectedShock?.ageHigh
          ? (selectedShock.ageHigh as PatientAge).ageValue
          : globals.MAX_VALUE,
        ageGroup: selectedShock?.ageGroup,
        repeatTime: (selectedShock?.model?.repeatTime || 0) as number,

        activeID: oldActiveItem?.uid,

        overrideID: isOwner ? null : oldActiveItem?.uid,
        status: ProgressStatus.DRAFT,
        version: upgradeVersion(oldActiveItem?.version || 'v1.0.0'),
        createdAt: oldActiveItem?.model?.createdAt
          ? new Date(oldActiveItem.model.createdAt)
          : new Date(),
      };

      let results = await createElectricalDoseItem(
        newMedDose,
        oldActiveItem as ElectricalSubItem
      );

      if (results.type === ResponseType.Success) {
        const newDraftMedication = results.data as ElectricalSubItem;
        newDraftMedication.activeItem = oldActiveItem as ElectricalSubItem;

        setSnackbar({
          open: true,
          message:
            'Successfully Restored this electrical shock with a new DRAFT version ' +
            newDraftMedication.version,
          severity: 'success',
        });

        // Update subData with new draft
        const newSubData = subArchiveData.map((item: ArchiveItem) =>
          item.activeItem?.uid === activeParmElectrical.uid
            ? { ...item, activeItem: newDraftMedication }
            : item
        );
        setSubArchiveData(newSubData);
        setActiveParmElectrical(newDraftMedication);
        if (globals.debug)
          console.log('Successfully restored electrical shock', results.data);

        setModal({
          isVisible: true,
          title: 'Successfully Restored Electrical Shock',
          primaryDescription: `Successfully restored ${newDraftMedication.name} to version ${newDraftMedication.version}. Would you like to navigate to the electrical shock?`,
          primaryBtnName: 'Dismiss',
          secondaryBtnName: 'Navigate',
          handleClose: () => {
            setModal({ ...modal, isVisible: false });
          },
          handleSubmit: () => {
            if (newDraftMedication.cprAssistID !== null) {
              navigate(`/database/cpr-assist`, {
                state: {
                  selectedDose: newDraftMedication,
                },
              });
            } else if (newDraftMedication.protocolID !== null) {
              const state = {
                selectedProtocol: protocol,
                editMode: false,
                backPage: 'list-protocols',
                search: { searchQuery: '', filters: [], categoriesFilter: [] },
                selectedDose: newDraftMedication,
              };
              const elecFormattedString = protocol.nickname.replace(
                /[\s/]/g,
                '-'
              );
              navigate(`/${elecFormattedString}/protocol-detail`, { state });
            }
          },
        });
      } else if (results.type === ResponseType.Failure) {
        setSnackbar({
          open: true,
          message: 'Failed to restore Electrical Shock.',
          severity: 'error',
        });
      }
      setSidebarVisible(false);
      setSelectedShock(null);
      // setSelectionModel([]);
    } catch (error) {
      console.error('Failed to restore electrical shock', error);
    }
  };

  const handleSortItem = useCallback(
    (
      key: string,
      direction: string,
      a: ElectricalItem | ElectricalSubItem,
      b: ElectricalItem | ElectricalSubItem
    ) => {
      if (key === 'version') {
        const aVersion = a.version;
        const bVersion = b.version;
        return direction === 'asc'
          ? compareVersions(aVersion, bVersion)
          : compareVersions(bVersion, aVersion);
      } else if (key === 'name') {
        return direction === 'asc'
          ? a.name.localeCompare(b.name)
          : b.name.localeCompare(a.name);
      } else if (key === 'archived_date') {
        const aDate = new Date(a.modifiedAt || Date.now());
        const bDate = new Date(b.modifiedAt || Date.now());
        return direction === 'asc'
          ? aDate.getTime() - bDate.getTime()
          : bDate.getTime() - aDate.getTime();
      } else if (key === 'archived_by') {
        const aUserId = a.model?.modifiedBy || a.model?.createdBy;
        const bUserId = b.model?.modifiedBy || b.model?.createdBy;

        const aUser = aUserId
          ? database.users.find((user: User) => user.id === aUserId)
          : null;
        const bUser = bUserId
          ? database.users.find((user: User) => user.id === bUserId)
          : null;

        const aName = aUser
          ? `${aUser.firstName} ${aUser.lastName}`
          : DEFAULT_USERNAME;
        const bName = bUser
          ? `${bUser.firstName} ${bUser.lastName}`
          : DEFAULT_USERNAME;

        return direction === 'asc'
          ? aName.toLowerCase().localeCompare(bName.toLowerCase())
          : bName.toLowerCase().localeCompare(aName.toLowerCase());
      } else if (key === 'shocks' && tab === 'Electrical') {
        return direction === 'asc'
          ? (a as ElectricalItem).subItems.length -
              (b as ElectricalItem).subItems.length
          : (b as ElectricalItem).subItems.length -
              (a as ElectricalItem).subItems.length;
      }
      return 0;
    },
    [tab, database.users]
  );

  /**
   * This function is used to map the electrical shocks to the active item
   */
  useEffect(() => {
    const mapElectricalShocks = async () => {
      try {
        const activeElectricalID =
          activeParmElectrical.status === ProgressStatus.DRAFT
            ? activeParmElectrical.activeItem?.uid
            : activeParmElectrical.uid;

        // Fetch all the active and its archived Electrical Shocks
        const electricalShocks = subArchiveData.filter((item: ArchiveItem) =>
          (item.activeItem as ElectricalSubItem).parent.activeID
            ? (item.activeItem as ElectricalSubItem).parent.activeID ===
              activeElectricalID
            : (item.activeItem as ElectricalSubItem).parent.uid ===
              activeElectricalID
        );

        // Update the createdAt field for each of the items in the electricalShocksList.data
        const processedData = updateArchiveItemsCreatedAt(
          electricalShocks
        ) as ArchiveItem[];

        setElectricalList((prev: ElectricalItem[] | ElectricalSubItem[]) =>
          (prev as ElectricalItem[]).map((electricalItem: ElectricalItem) => {
            const tempSubItems = linkSubItemsToItem(
              electricalItem,
              processedData
            );
            electricalItem.subItems = tempSubItems as ElectricalSubItem[];
            return electricalItem;
          })
        );
      } catch (err) {
        console.error('Error fetching infusion Shocks:', err);
      }
    };

    // Perform this mapping only if the tab is Electrical
    if (tab === 'Electrical') {
      mapElectricalShocks();
    }
  }, [
    electrical,
    activeParmElectrical,
    database,
    department,
    activeItem,
    tab,
    subArchiveData,
  ]);

  const electricalHeaders: Header<ElectricalItem | ElectricalSubItem>[] =
    useMemo(
      () => [
        {
          key: 'version',
          name: 'Version',
          sortable: true,
          flex: 0.75,
          render: (item: ElectricalItem | ElectricalSubItem) => (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {item.version}
              {tab === 'Shocks' && (
                <Status status={item.status} style={{ marginLeft: '8px' }} />
              )}
            </div>
          ),
        },
        {
          key: 'name',
          name: 'Name',
          sortable: true,
          flex: 2,
          render: (item: ElectricalItem | ElectricalSubItem) => (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {!(
                item.departmentID === department.id &&
                item.status !== 'DEACTIVATED'
              ) && (
                <div style={{ marginRight: '8px' }}>
                  <FaLock className="table-icon" color="#A3A3A3" />
                </div>
              )}
              {item.name}
              {tab === 'Electrical' && (
                <Status status={item.status} style={{ marginLeft: '8px' }} />
              )}
            </div>
          ),
        },
        {
          key: 'archived_date',
          name: 'Archived Date',
          sortable: true,
          flex: 1,
          render: (item: ElectricalItem | ElectricalSubItem) =>
            getFormattedDate(new Date(item.modifiedAt || Date.now()), true),
        },
        {
          key: 'archived_by',
          name: 'Archived By',
          sortable: true,
          flex: 1,
          render: (item: ElectricalItem | ElectricalSubItem) => {
            const userId = item.model?.modifiedBy || item.model?.createdBy;
            const user = userId
              ? database.users.find((user: User) => user.id === userId)
              : null;
            const username = user
              ? `${user.firstName} ${user.lastName}`
              : DEFAULT_USERNAME;

            return (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <span>{username}</span>
                {tab === 'Shocks' && (
                  <FaChevronRight
                    className="icon-normal"
                    style={{ margin: '4px' }}
                  />
                )}
              </div>
            );
          },
        },

        {
          key: 'shocks',
          name: 'Shocks',
          sortable: true,
          flex: 1,
          render: (item: ElectricalItem | ElectricalSubItem) => {
            if ('subItems' in item) {
              return (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%',
                    paddingRight: '15px',
                  }}
                >
                  <span>{item.subItems.length}</span>
                  <FaChevronRight
                    className="icon-normal"
                    style={{ margin: '4px' }}
                  />
                </div>
              );
            }
            return null;
          },
        },
      ],
      [tab, department.id, database.users]
    );

  const doseHeaders: Header<ElectricalItem | ElectricalSubItem>[] = useMemo(
    () => [
      {
        key: 'version',
        name: 'Version',
        sortable: true,
        flex: 0.75,
        render: (item: ElectricalItem | ElectricalSubItem) => (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {item.version}
          </div>
        ),
      },
      {
        key: 'status',
        name: 'Status',
        sortable: true,
        flex: 0.75,
        render: (item: ElectricalItem | ElectricalSubItem) => (
          <Status status={item.status} />
        ),
      },
      {
        key: 'name',
        name: 'Name',
        sortable: true,
        flex: 2,
        render: (item: ElectricalItem | ElectricalSubItem) => (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {!(
              item.departmentID === department.id &&
              item.status !== 'DEACTIVATED'
            ) && (
              <div style={{ marginRight: '8px' }}>
                <FaLock className="table-icon" color="#A3A3A3" />
              </div>
            )}
            {item.name}
            {tab === 'Electrical' && (
              <Status status={item.status} style={{ marginLeft: '8px' }} />
            )}
          </div>
        ),
      },

      {
        key: 'protocol_name',
        name: 'Protocol Name',
        sortable: true,
        flex: 2,
        render: (item: ElectricalItem | ElectricalSubItem) => {
          if ('parentCPR' in item && 'parentProtocol' in item) {
            return item.parentCPR != null
              ? 'CPR Assist'
              : item.parentProtocol.name;
          }
          return '';
        },
      },
      {
        key: 'shock_name',
        name: 'Title',
        sortable: true,
        flex: 1,
        render: (item: ElectricalItem | ElectricalSubItem) => {
          if ('title' in item) {
            return item.title;
          }
          return '';
        },
      },
      {
        key: 'full_basis',
        name: 'Full Basis',
        sortable: true,
        flex: 0.75,
        render: (item: ElectricalItem | ElectricalSubItem) => {
          if ('fullBasis' in item) {
            return item.fullBasis;
          }
          return '';
        },
      },

      {
        key: 'archived_date',
        name: 'Archived Date',
        sortable: true,
        flex: 1,
        render: (item: ElectricalItem | ElectricalSubItem) =>
          getFormattedDate(new Date(item.modifiedAt || Date.now()), true),
      },
      {
        key: 'archived_by',
        name: 'Archived By',
        sortable: true,
        flex: 1,
        render: (item: ElectricalItem | ElectricalSubItem) => {
          const userId = item.model?.modifiedBy || item.model?.createdBy;
          const user = userId
            ? database.users.find((user: User) => user.id === userId)
            : null;
          const username = user
            ? `${user.firstName} ${user.lastName}`
            : DEFAULT_USERNAME;

          return (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
                paddingRight: '15px',
              }}
            >
              <span>{username}</span>
              {tab === 'Shocks' && (
                <FaChevronRight
                  className="icon-normal"
                  style={{ margin: '4px' }}
                />
              )}
            </div>
          );
        },
      },
    ],
    [tab, department.id, database.users]
  );

  const handleRestorePressed = async () => {
    if (isDraft) {
      setModal({
        isVisible: true,
        title: 'Override Existing Draft?',
        primaryDescription: `There is already a draft of ${activeParmElectrical.name} (${activeParmElectrical.version}) which is not yet published. Are you sure you want to override the existing draft and restore ${selectedShock?.name} of version ${selectedShock?.version}?`,
        primaryBtnName: 'Cancel',
        secondaryBtnName: 'Restore',
        handleClose: () => {
          setModal({ ...modal, isVisible: false });
        },
        handleSubmit: handleDoseRestore,
      });
    } else {
      setModal({
        isVisible: true,
        title: 'Restore Electrical Shock?',
        primaryDescription: `Are you sure you want to restore ${selectedShock?.name} of version ${selectedShock?.version}? This will create a new draft version of the electrical shock.`,
        primaryBtnName: 'Cancel',
        secondaryBtnName: 'Restore',
        handleClose: () => {
          setModal({ ...modal, isVisible: false });
        },
        handleSubmit: handleDoseRestore,
      });
    }
  };

  return (
    <div className="screen-container">
      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>

      <ConfirmModal
        isVisible={modal.isVisible}
        title={modal.title}
        primaryDescription={modal.primaryDescription}
        primaryBtnName={modal.primaryBtnName}
        secondaryBtnName={modal.secondaryBtnName}
        handleClose={modal.handleClose}
        handleSubmit={modal.handleSubmit}
      />

      <SubItemRestoreSideout
        dose={selectedShock ? selectedShock : undefined}
        type="electrical"
        protocol={
          selectedShock?.model?.protocolID
            ? selectedShock?.parentProtocol
            : selectedShock?.parentCPR
        }
        visible={sidebarVisible}
        setVisible={handleCloseModal}
        onRestore={handleRestorePressed}
      />

      <ProtocolHeader
        isBackButton={true}
        handleCancel={handleBack}
        name={`${activeParmElectrical.activeItem?.name || activeParmElectrical.name}: ${electricalList.length - 1} archive${electricalList.length - 1 === 1 ? '' : 's'}`}
        page={`${tab} Archives`}
        type="protocol"
      />

      <DataList<ElectricalItem | ElectricalSubItem, never>
        items={electricalList}
        headers={tab === 'Electrical' ? electricalHeaders : doseHeaders}
        onItemClick={handleItemClick}
        searchPlaceholder={`Search ${tab} Archives...`}
        sortItem={handleSortItem}
      />
    </div>
  );
};

export default ListArchiveElectricalDetails;
