import { InputText } from 'primereact/inputtext';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ViewportList } from 'react-viewport-list';
import {
  getArchivedDosesToDisplay,
  getFormattedDate,
  globals,
  handleCopy,
  removeTypename,
  upgradeVersion,
} from '../../../../_global/common/Utils';
import InfusionItem from '../../../../../data/model/InfusionItem';
import {
  Concentration,
  Drip,
  MedicationProtocol,
  User,
} from '../../../../../models';
import ProtocolItem from '../../../../../data/model/ProtocolItem';
import InfusionSubItem from '../../../../../data/model/InfusionSubItem';
import ProtocolHeader from '../../ProtocolHeader';
import { ProgressStatus } from '../../../../../models';
import { Col, Row } from 'react-bootstrap';
import { UserType } from '../../../../../models';
import { BiCopy, BiSolidCopy } from 'react-icons/bi';
import { getConcentrationString } from '../../../../../data/model/MedicationItem';
import InfusionDoseUI from './InfusionDoseUI';
import AccordionItem from '../../../../components/Accordion/AccordionItem';
import ConfirmModal from '../../../../components/Modal/ConfirmModal';
import {
  createParentInfusion,
  deleteInfusionItem,
  InfusionJSON,
} from '../../../../../data/functions/InfusionDB';
import { ResponseType } from '../../../../../data/AmplifyDB';
import {
  ArchiveItem,
  reloadModelItem,
} from '../../../../../data/functions/ModelDB';
import ArchivedDosesSideout from '../../../../components/SideOut/doseSideOut/ArchivedDosesSideout';
import { Alert, Snackbar } from '@mui/material';
import CopyToClipboard from '../../../../components/CopyToClipboard';

/* 10-24-23  Created Infusion Archive Page component for Infusion layout */
const InfusionProtocolArchivePage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  if (
    !location.state?.value ||
    !location.state?.department ||
    !location.state?.database
  ) {
    navigate('/archive');
  }

  const {
    value,
    sourceData,
    subData,
    archiveData,
    department,
    database,
    activeItem,
  } = location.state;

  const user: User = useSelector((state: any) => state?.user);

  const [activeParmInfusion, setActiveParmInfusion] =
    useState<InfusionItem>(activeItem);

  const [parmInfusion, setParmInfusion] = useState<InfusionItem>(value);
  const [allSubInfusionItems, setAllSubInfusionItems] = useState<
    InfusionSubItem[]
  >(value.subItems);
  const [expanded, setExpanded] = useState<string[]>([]);
  const [modifiedBy, setModifiedBy] = useState<User | null>(null);

  const [isRestoring, setIsRestoring] = useState(false);

  // Check if the most recent version of the vital is a draft
  const isDraft = useMemo(
    () => activeParmInfusion.status === ProgressStatus.DRAFT,
    [activeParmInfusion]
  );

  const [snackbar, setSnackbar] = useState<any>({
    open: false,
    message: '',
    severity: 'success',
  });

  const allProtocols = useMemo(() => {
    let prots = allSubInfusionItems
      .filter((item: InfusionSubItem) => item.parentProtocol != null)
      .map((m) => m.parentProtocol);
    // Remove all the duplicates
    return prots
      .filter((v, i, a) => a.findIndex((t) => t.uid === v.uid) === i)
      .sort((a, b) => {
        if (a.parent.index === b.parent.index)
          return a.name.localeCompare(b.name);
        return a.parent.index - b.parent.index;
      });
  }, [allSubInfusionItems]);

  const [modal, setModal] = useState({
    isVisible: false,
    title: '',
    primaryDescription: '',
    primaryBtnName: '',
    secondaryBtnName: '',
    handleClose: () => {},
    handleSubmit: () => {},
  });

  const reloadItem = async () => {
    const response = await reloadModelItem(Drip, activeParmInfusion);
    if (response.type === ResponseType.Success) {
      const item = new InfusionItem(response.data.item as Drip);
      const activeItem = response.data.activeItem as Drip | null | undefined;
      item.activeItem = activeItem ? new InfusionItem(activeItem) : null;
      item.subItems = activeParmInfusion.subItems;
      setActiveParmInfusion(item);
    }
  };

  /**
   * Check if the draft exists for the active item
   */
  useEffect(() => {
    reloadItem();
  }, []);

  /* Hotkey detection */
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.ctrlKey && event.shiftKey && event.key === 'O') {
        console.log('ARCHIVE INFUSION', parmInfusion);
        console.log('ACTIVE INFUSION', activeParmInfusion);
      }
    };

    // Add event listener
    document.addEventListener('keydown', handleKeyDown);

    // Remove event listener on cleanup
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [parmInfusion, activeParmInfusion]);

  useEffect(() => {
    if (value) {
      setParmInfusion(value);
      setAllSubInfusionItems(value.subItems);
    }
  }, [value]);

  useEffect(() => {
    let id = parmInfusion.model?.modifiedBy;
    if (id == null) id = parmInfusion.model?.createdBy;
    if (id) {
      let user = database.users.find((u: User) => u.id === id);
      setModifiedBy(user ? user : null);
      if (user) {
        parmInfusion.modifiedBy = user;
      }
    } else {
      setModifiedBy(null);
    }
  }, [database, parmInfusion]);

  /**
   * Back button has been pressed
   */
  const handleBack = useCallback(() => {
    navigate('/archive/list-infusions-details', {
      state: {
        data: sourceData,
        subData: subData,
        department: department,
        database: database,
        archiveData: archiveData,
        activeItem: activeParmInfusion,
        tab: 'Infusions',
      },
    });
  }, [
    navigate,
    sourceData,
    subData,
    department,
    database,
    activeParmInfusion,
    archiveData,
  ]);

  const ListRender = ({ data }: any) => {
    const [showArchiveSideout, setShowArchiveSideout] = useState(false);
    const [selectedDose, setSelectedDose] = useState<InfusionSubItem | null>(
      null
    );
    const [selectedArchives, setSelectedArchives] = useState<InfusionSubItem[]>(
      []
    );

    // Process all data once at the beginning
    const { displayItems, archivedItemsMap } = useMemo(() => {
      return getArchivedDosesToDisplay(data);
    }, [data]);

    const handleViewHistory = (item: InfusionSubItem, e: React.MouseEvent) => {
      e.stopPropagation(); // Prevent event bubbling if inside clickable containers
      const archivedItems =
        archivedItemsMap.get(item.uid) ||
        archivedItemsMap.get(item.activeID || '') ||
        [];

      setSelectedDose(item);
      setSelectedArchives(archivedItems as InfusionSubItem[]);
      setShowArchiveSideout(true);
    };

    return (
      <>
        <ViewportList items={displayItems as InfusionSubItem[]}>
          {(dose: InfusionSubItem, index: number) => {
            const archivedItems =
              dose.status === ProgressStatus.ARCHIVE
                ? archivedItemsMap.get(dose.activeID || '')
                : archivedItemsMap.get(dose.uid);

            const archivedCount = archivedItems?.length || 0;

            return (
              <div key={index}>
                <InfusionDoseUI
                  dose={dose}
                  doseIndex={dose.version}
                  showProtocol={false}
                  isEditMode={false}
                  archiveMode={true}
                />
                {archivedCount > 0 && (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      margin: 0,
                      marginTop: '0px',
                      cursor: 'pointer',
                    }}
                    onClick={(e) => handleViewHistory(dose, e)}
                  >
                    <p className="sidebarText-hover">
                      {archivedCount} archived
                      {archivedCount === 1 ? ' dose >' : ' doses >'}
                    </p>
                  </div>
                )}
              </div>
            );
          }}
        </ViewportList>

        {selectedDose && (
          <ArchivedDosesSideout
            visible={showArchiveSideout}
            currentDose={selectedDose}
            archivedDoses={selectedArchives}
            type={'infusion'}
            setVisible={setShowArchiveSideout}
          />
        )}
      </>
    );
  };

  const ProtocolItemUI = ({ protocol }: any) => {
    const doses = allSubInfusionItems.filter(
      (item: InfusionSubItem) => item.parentProtocol.uid === protocol.uid
    );
    doses
      .filter(
        (item: InfusionSubItem) => item.status !== ProgressStatus.DEACTIVATED
      )
      .sort((a: InfusionSubItem, b: InfusionSubItem) => {
        if (a.index === b.index) return a.rangeLow - b.rangeLow;
        return a.index - b.index;
      });

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          padding: '10px',
          marginTop: '.25rem',
          marginBottom: '.25rem',
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <AccordionItem
          title={protocol.name}
          titleStyle={{ fontWeight: '400' }}
          rightTitle={`${doses.length} doses`}
          rightTitleStyle={{ fontWeight: '700' }}
          expanded={expanded.includes(protocol.uid)}
          onChange={() => {
            if (expanded.includes(protocol.uid)) {
              setExpanded(expanded.filter((item) => item !== protocol.uid));
            } else {
              setExpanded([...expanded, protocol.uid]);
            }
          }}
        >
          <div
            style={{ flex: 1, padding: '0 10px 10px 10px' }}
            onClick={(e) => e.stopPropagation()} // Add this to prevent event bubbling
          >
            <ListRender data={doses} />
          </div>
        </AccordionItem>
      </div>
    );
  };

  /**
   * Find the protocols from the protocol options
   * @param protocolOptions The protocol options to search for
   * @returns The protocols that were found
   */
  const findProtocols = useCallback(
    (protocolOptions: MedicationProtocol[]): ProtocolItem[] => {
      let protocols: ProtocolItem[] = [];
      for (let i = 0; i < protocolOptions.length; i++) {
        let po = protocolOptions[i];
        let protocol = database.protocols.find((p: ProtocolItem) => {
          let id =
            p.status === ProgressStatus.ACTIVE ||
            (p.status === ProgressStatus.DRAFT && p.activeID == null)
              ? p.uid
              : p.activeID;
          return id === po.protocolID;
        });
        if (protocol) protocols.push(protocol);
      }
      return protocols;
    },
    [database.protocols]
  );

  const handleRestore = useCallback(async () => {
    setIsRestoring(true);
    setModal({ ...modal, isVisible: false });
    try {
      const oldActiveItem = isDraft
        ? activeParmInfusion.activeItem
        : activeParmInfusion;
      if (isDraft) {
        const response = await deleteInfusionItem(
          department,
          activeParmInfusion,
          false
        );
        if (response.type === ResponseType.Failure) {
          setSnackbar({
            open: true,
            message:
              'Failed to delete already existing draft of ' +
              oldActiveItem?.name,
            severity: 'error',
          });
          // Exit the function
          console.error('Failed to delete infusion', response.data);
          return;
        }
      }

      const protocolList = findProtocols(parmInfusion.model.dripOptions);

      let overrideID = undefined;

      const updatedMedication: InfusionJSON = {
        name: parmInfusion.name,
        concentration: removeTypename(parmInfusion.concentrations),
        routes: parmInfusion.routes,
        rangeLow: !isNaN(Number(parmInfusion.rangeLow))
          ? Number(parmInfusion.rangeLow)
          : 0,
        rangeHigh: !isNaN(Number(parmInfusion.rangeHigh))
          ? Number(parmInfusion.rangeHigh)
          : globals.MAX_VALUE,
        departmentID: department.id,
        dripOptions: removeTypename(
          parmInfusion.model.dripOptions.map((item) => ({
            ...item,
            options: removeTypename(
              item.options.map((nr) => ({
                ...nr,
                nemsisRoutes: removeTypename(nr.nemsisRoutes),
              }))
            ),
          }))
        ),
        taggedProtocols: protocolList,

        contraindication: parmInfusion.model.contraindication,
        note: parmInfusion.model.note,
        warning: parmInfusion.model.warning,
        medClass: parmInfusion.model.medClass,
        action: parmInfusion.model.action,
        indication: parmInfusion.model.indication,
        interaction: parmInfusion.model.interaction,
        onset: parmInfusion.model.onset,
        duration: parmInfusion.model.duration,

        createdBy:
          parmInfusion.model && parmInfusion.model.createdBy
            ? parmInfusion.model.createdBy
            : user.id,
        modifiedBy: user.id,
        status: ProgressStatus.DRAFT,
        activeID: oldActiveItem?.uid,
        overrideID: overrideID,
        version: upgradeVersion(oldActiveItem?.version || 'v1.0.0'),
      };

      let results = await createParentInfusion(
        department,
        updatedMedication,
        oldActiveItem as InfusionItem
      );

      if (results.type === ResponseType.Success) {
        const newDraftInfusion = results.data as InfusionItem;
        newDraftInfusion.activeItem = oldActiveItem;

        setSnackbar({
          open: true,
          message:
            'Successfully Restored ' +
            newDraftInfusion.name +
            ' with a new DRAFT version ' +
            newDraftInfusion.version,
          severity: 'success',
        });

        // Update sourceData with new draft
        const newSourceData = sourceData.map((item: ArchiveItem) =>
          item.activeItem?.uid === activeParmInfusion.uid
            ? { ...item, activeItem: newDraftInfusion }
            : item
        );

        if (globals.debug)
          console.log('Successfully restored infusion', results.data);
        // Handle successful restoration
        setModal({
          isVisible: true,
          title: 'Successfully Restored Infusion',
          primaryDescription: `Successfully restored ${newDraftInfusion.name} to version ${newDraftInfusion.version}. Would you like to navigate to the infusion?`,
          primaryBtnName: 'Dismiss',
          secondaryBtnName: 'Navigate',
          handleClose: () => {
            setModal({ ...modal, isVisible: false });
            navigate('/archive/list-infusions-details', {
              state: {
                data: newSourceData,
                subData: subData,
                department: department,
                database: database,
                archiveData: archiveData,
                activeItem: newDraftInfusion,
                tab: 'Infusions',
              },
            });
          },
          handleSubmit: () => {
            const state = {
              selectedProtocol: null,
              value: newDraftInfusion,
              subValue: null,
              type: 'Infusion',
              editType: 'edit',
              editMode: false,
              page: 'listInfusionPage',
            };
            navigate(`/protocol/edit/edit-protocol`, { state });
          },
        });
      } else if (results.type === ResponseType.Failure) {
        setSnackbar({
          open: true,
          message: 'Failed to restore Infusion.',
          severity: 'error',
        });
      }
    } catch (error) {
      setIsRestoring(false);
      console.error('Failed to restore infusion', error);
    } finally {
      setIsRestoring(false);
    }
  }, [
    navigate,
    sourceData,
    department,
    database,
    activeParmInfusion,
    archiveData,
    findProtocols,
    isDraft,
    parmInfusion,
    user,
    subData,
  ]);

  const handleRestorePressed = async () => {
    if (isDraft) {
      setModal({
        isVisible: true,
        title: 'Override Existing Draft?',
        primaryDescription: `There is already a draft of ${activeParmInfusion.name} (${activeParmInfusion.version}) which is not yet published. Are you sure you want to override the existing draft and restore ${parmInfusion.name} of version ${parmInfusion.version}?`,
        primaryBtnName: 'Cancel',
        secondaryBtnName: 'Restore',
        handleClose: () => {
          setModal({ ...modal, isVisible: false });
        },
        handleSubmit: handleRestore,
      });
    } else {
      setModal({
        isVisible: true,
        title: 'Restore Infusion?',
        primaryDescription: `Are you sure you want to restore ${parmInfusion.name} of version ${parmInfusion.version}? This will create a new draft version of the infusion.`,
        primaryBtnName: 'Cancel',
        secondaryBtnName: 'Restore',
        handleClose: () => {
          setModal({ ...modal, isVisible: false });
        },
        handleSubmit: handleRestore,
      });
    }
  };

  return (
    <div className={'screen-container'}>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>

      <ConfirmModal
        isVisible={modal.isVisible}
        title={modal.title}
        primaryDescription={modal.primaryDescription}
        primaryBtnName={modal.primaryBtnName}
        secondaryBtnName={modal.secondaryBtnName}
        handleClose={modal.handleClose}
        handleSubmit={modal.handleSubmit}
      />

      <ProtocolHeader
        name={parmInfusion.name}
        status={parmInfusion.status}
        isBackButton={true}
        page={parmInfusion.name + ' Archive'}
        type={'protocol'}
        isCopyDescription={user.type === UserType.ADMIN}
        descriptionTitle={user.type === UserType.ADMIN ? 'ID: ' : ''}
        description={user.type === UserType.ADMIN ? parmInfusion.uid : ''}
        handleCancel={handleBack}
        isRestoreButton={true}
        isRestoreActive={!isRestoring && parmInfusion.status !== 'ACTIVE'}
        bypassActiveStatusCheck={true}
        handleRestore={handleRestorePressed}
      />

      <div className="ketamineContent">
        <div className="KetamineGeneral">
          <h5 className="ketmine-header-text">General Information</h5>
          <div className="input-container roundBorder">
            <div>
              <div
                style={{ display: 'flex', marginTop: '5px' }}
                className="ketamine-general-label"
              >
                <div style={{ marginRight: '10px' }}>Modified By:</div>
                <div style={{ fontWeight: '500' }}>
                  {modifiedBy
                    ? modifiedBy.firstName + ' ' + modifiedBy.lastName
                    : 'Hinckley Medical'}
                </div>
              </div>
              {parmInfusion.model?.updatedAt && (
                <div
                  style={{ display: 'flex' }}
                  className="ketamine-general-label"
                >
                  <div style={{ marginRight: '10px' }}>Last Updated:</div>
                  <div style={{ fontWeight: '500' }}>
                    {getFormattedDate(parmInfusion.model.updatedAt, true)}
                  </div>
                </div>
              )}
              {user.type === UserType.ADMIN && (
                <div
                  style={{ display: 'flex' }}
                  className="ketamine-general-label"
                >
                  <div style={{ marginRight: '10px' }}>ID:</div>
                  <div style={{ fontWeight: '500' }}>
                    {parmInfusion.uid}
                    <CopyToClipboard text={parmInfusion.uid} />
                  </div>
                </div>
              )}
              {user.type === UserType.ADMIN &&
                parmInfusion.activeID != null && (
                  <div
                    style={{ display: 'flex' }}
                    className="ketamine-general-label"
                  >
                    <div style={{ marginRight: '10px' }}>Active ID:</div>
                    <div style={{ fontWeight: '500' }}>
                      {parmInfusion.activeID}
                      <CopyToClipboard text={parmInfusion.activeID} />
                    </div>
                  </div>
                )}
              {user.type === UserType.ADMIN && (
                <div
                  style={{ display: 'flex' }}
                  className="ketamine-general-label"
                >
                  <div style={{ marginRight: '10px' }}>Owner ID:</div>
                  <div style={{ fontWeight: '500' }}>
                    {parmInfusion.departmentID}
                    <CopyToClipboard text={parmInfusion.departmentID} />
                  </div>
                </div>
              )}
              {user.type === UserType.ADMIN &&
                parmInfusion.overrideID != null && (
                  <div
                    style={{ display: 'flex' }}
                    className="ketamine-general-label"
                  >
                    <div style={{ marginRight: '10px' }}>Override ID:</div>
                    <div style={{ fontWeight: '500' }}>
                      {parmInfusion.overrideID}
                      <CopyToClipboard text={parmInfusion.overrideID} />
                    </div>
                  </div>
                )}
              <div
                style={{ display: 'flex' }}
                className="ketamine-general-label"
              >
                <div style={{ marginRight: '10px' }}>Version:</div>
                <div style={{ fontWeight: '500' }}>{parmInfusion.version}</div>
              </div>
            </div>
          </div>
          <label htmlFor="Name" className="ketamine-general-label">
            Name
          </label>
          <div className="input-container">
            <InputText
              type="text"
              className="form-control-general"
              id="name"
              name="name"
              data-testid="name"
              value={parmInfusion.name}
              disabled={true}
            />
            <div className="input-border"></div>
          </div>
          <label htmlFor="Concentration" className="ketamine-general-label">
            Concentration
          </label>
          <div
            data-testid="outsideConcentration"
            data-div-id="outsideConcentration"
            className={`selectionArea-hover selectionArea-disable ${
              parmInfusion.concentrations?.length !== 0 ? '' : 'centerContent'
            }`}
          >
            {parmInfusion.concentrations?.length !== 0 && (
              <ViewportList items={parmInfusion.concentrations}>
                {(item: Concentration, index: any) => {
                  return (
                    <div
                      key={index.toLocaleString()}
                      className={`selectedGreybox disabled`}
                    >
                      <div>{getConcentrationString(item)}</div>
                    </div>
                  );
                }}
              </ViewportList>
            )}
          </div>
          <label htmlFor="Concentration" className="ketamine-general-label">
            Routes
          </label>
          <div
            data-testid="outsideRoute"
            data-div-id="outsideRoute"
            className={`selectionArea-hover selectionArea-disable ${parmInfusion.routes.length !== 0 ? '' : 'centerContent'}`}
          >
            {parmInfusion.routes.length >= 1 && (
              <ViewportList items={parmInfusion.routes}>
                {(item: any, index: any) => {
                  return (
                    <div
                      key={index.toLocaleString()}
                      className={`selectedGreybox disabled`}
                    >
                      <div>{item}</div>
                    </div>
                  );
                }}
              </ViewportList>
            )}
          </div>
          <Row>
            <Col sm={6}>
              <label htmlFor="rangeLow" className="ketamine-general-label">
                Range Low (kg)
              </label>
              <div className="input-container">
                <InputText
                  type="text"
                  className="form-control-general"
                  id="name"
                  name="rangeLow"
                  data-testid="rangeLow"
                  disabled
                  value={
                    parmInfusion.rangeLow && Number(parmInfusion.rangeLow) !== 0
                      ? parmInfusion.rangeLow + ''
                      : ''
                  }
                  placeholder="Min"
                />
                <div className="input-border"></div>
              </div>
            </Col>
            <Col sm={6}>
              <label htmlFor="rangeHigh" className="ketamine-general-label">
                Range High (kg)
              </label>
              <div className="input-container">
                <InputText
                  type="text"
                  className="form-control-general"
                  id="rangeHigh"
                  name="rangeHigh"
                  data-testid="rangeHigh"
                  disabled
                  value={
                    parmInfusion.rangeHigh &&
                    Number(parmInfusion.rangeHigh) !== globals.MAX_VALUE
                      ? parmInfusion.rangeHigh + ''
                      : ''
                  }
                  placeholder="Max"
                />
                <div className="input-border"></div>
              </div>
            </Col>
          </Row>
          <label htmlFor="Contraindication" className="ketamine-general-label">
            Contraindication
          </label>
          <div className="input-container">
            <InputText
              type="text"
              className="form-control-general"
              id="contraindication"
              data-testid="contraindication"
              name="contraindication"
              value={parmInfusion.model?.contraindication || ''}
              disabled
            />
            <div className="input-border"></div>
          </div>
          <label htmlFor="Warning" className="ketamine-general-label">
            Warning
          </label>
          <div className="input-container">
            <InputText
              type="text"
              className="form-control-general"
              id="warning"
              data-testid="warning"
              name="warning"
              value={parmInfusion.model?.warning || ''}
              disabled
            />
            <div className="input-border"></div>
          </div>
          <label htmlFor="Indication" className="ketamine-general-label">
            Indication
          </label>
          <div className="input-container">
            <InputText
              type="text"
              className="form-control-general"
              id="indication"
              data-testid="indication"
              name="indication"
              value={parmInfusion.model?.indication || ''}
              disabled
            />
            <div className="input-border"></div>
          </div>
          <label htmlFor="DrugInteraction" className="ketamine-general-label">
            Drug Interaction
          </label>
          <div className="input-container">
            <InputText
              type="text"
              className="form-control-general"
              id="interaction"
              data-testid="interaction"
              name="interaction"
              value={parmInfusion.model?.interaction || ''}
              disabled
            />
            <div className="input-border"></div>
          </div>
          <label htmlFor="Onset" className="ketamine-general-label">
            Onset
          </label>
          <div className="input-container">
            <InputText
              type="text"
              className="form-control-general"
              id="onset"
              data-testid="onset"
              name="onset"
              value={parmInfusion.model?.onset || ''}
              disabled
            />
            <div className="input-border"></div>
          </div>
          <label htmlFor="Duration" className="ketamine-general-label">
            Duration
          </label>
          <div className="input-container">
            <InputText
              type="text"
              className="form-control-general"
              id="duration"
              data-testid="duration"
              name="duration"
              value={parmInfusion.model?.duration || ''}
              disabled
            />
            <div className="input-border"></div>
          </div>
          <label htmlFor="Note" className="ketamine-general-label">
            Note
          </label>
          <div className="input-container">
            <InputText
              type="text"
              className="form-control-general"
              id="notes"
              data-testid="notes"
              name="notes"
              value={parmInfusion.model?.note || ''}
              disabled
            />
            <div className="input-border"></div>
          </div>
          <label htmlFor="Class" className="ketamine-general-label">
            Class
          </label>
          <div className="input-container">
            <InputText
              type="text"
              className="form-control-general"
              id="class"
              data-testid="class"
              name="class"
              value={parmInfusion.model?.medClass || ''}
              disabled
            />
            <div className="input-border"></div>
          </div>
          <label htmlFor="Action" className="ketamine-general-label">
            Action
          </label>
          <div className="input-container">
            <InputText
              type="text"
              className="form-control-general"
              id="action"
              data-testid="action"
              name="action"
              value={parmInfusion.model?.action || ''}
              disabled
            />
            <div className="input-border"></div>
          </div>
        </div>
        <div className="KetamineGeneral">
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: '10px',
            }}
          >
            <h5 className="ketmine-header-text">Protocol Dependent</h5>{' '}
          </div>

          <>
            {allProtocols.map((protocol: ProtocolItem, index: number) => {
              return <ProtocolItemUI key={index} protocol={protocol} />;
            })}
          </>
        </div>
      </div>
    </div>
  );
};

export default InfusionProtocolArchivePage;
