import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  compareVersions,
  getFormattedDate,
  globals,
  linkSubItemsToItem,
  updateArchiveItemsCreatedAt,
  upgradeVersion,
} from '../../../_global/common/Utils';
import { ProgressStatus } from '../../../../API';
import { getConcentrationsArrayString } from '../../../../data/model/MedicationItem';
import ProtocolHeader from '../../protocol/ProtocolHeader';
import { useLocation, useNavigate } from 'react-router-dom';
import { Drip, InfusionDose, User } from '../../../../models';
import { FaChevronRight, FaLock } from 'react-icons/fa6';
import InfusionItem from '../../../../data/model/InfusionItem';
import Status from '../../../components/ProgressStatus/ProgressStatus';
import {
  createInfusionDoseItem,
  deleteInfusionDoseItem,
  InfusionDoseJSON,
} from '../../../../data/functions/InfusionDB';
import { ResponseType } from '../../../../data/AmplifyDB';
import {
  ArchiveItem,
  reloadModelItem,
} from '../../../../data/functions/ModelDB';
import InfusionSubItem, {
  getRoutesString,
} from '../../../../data/model/InfusionSubItem';
import SubItemRestoreSideout from '../../../components/SideOut/doseSideOut/SubItemRestoreSideout';
import ProtocolItem from '../../../../data/model/ProtocolItem';
import { useSelector } from 'react-redux';
import PatientAge from '../../../_global/common/PatientAge';
import { Alert, Snackbar } from '@mui/material';
import ConfirmModal from '../../../components/Modal/ConfirmModal';
import DataList, { Header } from '../../database/lists/DataList';

const DEFAULT_USERNAME = 'Hinckley Medical';
/* 12-31-2024 Gagan: Added this file to list the infusions items in the archive */
const ListArchiveInfusionsDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;

  if (
    !state?.data ||
    !state?.department ||
    !state?.database ||
    !state?.archiveData ||
    !state?.activeItem ||
    !state?.tab
  ) {
    navigate('/archive');
  }

  const {
    data: sourceData,
    subData,
    department,
    database,
    archiveData: infusions,
    activeItem,
    tab = 'Infusions',
  } = state;

  const user: User = useSelector((state: any) => state?.user);

  const [activeParmInfusion, setActiveParmInfusion] = useState<
    InfusionItem | InfusionSubItem
  >(activeItem);

  const [infusionList, setInfusionList] = useState<
    InfusionItem[] | InfusionSubItem[]
  >(
    infusions.sort(
      (a: InfusionItem | InfusionSubItem, b: InfusionItem | InfusionSubItem) =>
        compareVersions(b.version, a.version)
    )
  );

  const [selectedDose, setSelectedDose] = useState<InfusionSubItem | null>(
    null
  );
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [subArchiveData, setSubArchiveData] = useState<ArchiveItem[]>(subData);

  const [snackbar, setSnackbar] = useState<any>({
    open: false,
    message: '',
    severity: 'success',
  });

  const isDraft = useMemo(() => {
    return activeParmInfusion.status === ProgressStatus.DRAFT;
  }, [activeParmInfusion]);

  const [modal, setModal] = useState({
    isVisible: false,
    title: '',
    primaryDescription: '',
    primaryBtnName: '',
    secondaryBtnName: '',
    handleClose: () => {},
    handleSubmit: () => {},
  });

  const reloadItem = async () => {
    if (
      activeParmInfusion instanceof InfusionSubItem ||
      activeParmInfusion.TAG === 'InfusionSubItem'
    ) {
      const response = await reloadModelItem(InfusionDose, activeParmInfusion);
      if (response.type === ResponseType.Success) {
        const cur = activeParmInfusion as InfusionSubItem;
        const item = new InfusionSubItem(
          cur.parent,
          cur.parentProtocol,
          response.data.item as InfusionDose
        );
        const activeItem = response.data.activeItem as
          | InfusionDose
          | null
          | undefined;
        item.activeItem = activeItem
          ? new InfusionSubItem(
              cur.parent,
              cur.parentProtocol,
              activeItem as InfusionDose
            )
          : null;
        setActiveParmInfusion(item);
      }
    } else {
      const response = await reloadModelItem(Drip, activeParmInfusion);
      if (response.type === ResponseType.Success) {
        const item = new InfusionItem(response.data.item as Drip);
        const activeItem = response.data.activeItem as Drip | null | undefined;
        item.activeItem = activeItem ? new InfusionItem(activeItem) : null;
        item.subItems = activeParmInfusion.subItems;
        setActiveParmInfusion(item);
      }
    }
  };

  /**
   * Check if the draft exists for the active item
   */
  useEffect(() => {
    reloadItem();
  }, []);

  const handleItemClick = useCallback(
    (infusion: InfusionItem | InfusionSubItem) => {
      if (tab === 'Infusions') {
        navigate(`/archive/infusion-archive`, {
          state: {
            value: infusion,
            sourceData: sourceData,
            subData: subArchiveData,
            archiveData: infusions,
            department: department,
            database: database,
            activeItem: activeParmInfusion,
          },
        });
      } else {
        setSelectedDose(infusion as InfusionSubItem);
        setSidebarVisible(true);
      }
    },
    [
      navigate,
      sourceData,
      infusions,
      department,
      database,
      activeParmInfusion,
      subArchiveData,
      tab,
    ]
  );

  /*function to close the modal*/
  const handleCloseModal = () => {
    setSidebarVisible(false);
    setSelectedDose(null);
  };

  const handleBack = useCallback(() => {
    navigate(`/archive/list-infusions`, {
      state: {
        data: sourceData,
        subData: subArchiveData,
        department: department,
        database: database,
        tab: tab,
      },
    });
  }, [navigate, sourceData, department, database, tab, subArchiveData]);

  const handleDoseRestore = async () => {
    setModal({ ...modal, isVisible: false });
    try {
      const isDraft = activeParmInfusion.status === ProgressStatus.DRAFT;
      const oldActiveItem = isDraft
        ? activeParmInfusion.activeItem
        : activeParmInfusion;

      if (isDraft) {
        // Delete the existing draft
        const response = await deleteInfusionDoseItem(
          activeParmInfusion as InfusionSubItem,
          false
        );

        if (response.type === ResponseType.Failure) {
          setSnackbar({
            open: true,
            message: 'Failed to delete already existing draft.',
            severity: 'error',
          });
          // Exit the function
          console.error('Failed to delete infusion dose', response.data);
          setSidebarVisible(false);
          setSelectedDose(null);
          return;
        }
      }

      const protocol = (activeParmInfusion as InfusionSubItem)?.parentProtocol;
      let protocolID =
        protocol.status === ProgressStatus.ACTIVE || protocol.activeID == null
          ? protocol.uid
          : protocol.activeID;

      if (!protocolID) {
        console.error('Failed to find protocol ID');
        return;
      }

      let prevDose = activeParmInfusion as InfusionSubItem;
      const isOwner = prevDose ? department.id === prevDose.departmentID : true;
      let index = prevDose
        ? prevDose.index
        : (protocol as ProtocolItem).infusions.length;
      if (department.parentDep?.parentDep) index += 10000;
      else if (department.parentDep) index += 1000;

      /* Create a new InfusionDose for the medicaiton */
      let newMedDose: InfusionDoseJSON = {
        departmentID: department.id,
        infusion: (oldActiveItem as InfusionSubItem).parent,
        protocol: protocol,
        rangeLow: selectedDose?.model?.rangeLow
          ? Number(selectedDose.model?.rangeLow)
          : 0,
        rangeHigh: selectedDose?.model?.rangeHigh
          ? Number(selectedDose.model?.rangeHigh)
          : globals.MAX_VALUE,
        createdBy: (selectedDose?.model?.createdBy || user.id) as string,
        basis: selectedDose?.model?.basis || '',
        route: selectedDose?.routes || [],
        nemsisRoutes: selectedDose?.nemsisRoutes || [],
        title: selectedDose?.model?.title || '',
        warning: selectedDose?.model?.warning || '',
        instruction: selectedDose?.model?.instruction || '',
        note: selectedDose?.model?.note || '',
        maxDose: selectedDose?.model?.maxDose,
        minDose: selectedDose?.model?.minDose,
        maxTotalDose: selectedDose?.model?.maxTotalDose,
        calcMax: selectedDose?.model?.calcMax,
        calcMin: selectedDose?.model?.calcMin,
        index: index,
        ageLow: selectedDose?.ageLow
          ? (selectedDose.ageLow as PatientAge).ageValue
          : 0,
        ageHigh: selectedDose?.ageHigh
          ? (selectedDose.ageHigh as PatientAge).ageValue
          : globals.MAX_VALUE,
        ageGroup: selectedDose?.ageGroup,
        repeatTime: (selectedDose?.model?.repeatTime || 0) as number,

        activeID: oldActiveItem?.uid,
        overrideID: isOwner ? null : oldActiveItem?.uid,

        status: ProgressStatus.DRAFT,
        version: upgradeVersion(oldActiveItem?.version || 'v1.0.0'),
        createdAt: oldActiveItem?.model?.createdAt
          ? new Date(oldActiveItem.model.createdAt)
          : new Date(),
      };

      let results = await createInfusionDoseItem(
        newMedDose,
        oldActiveItem as InfusionSubItem
      );

      if (results.type === ResponseType.Success) {
        const newDraftInfusion = results.data as InfusionSubItem;
        newDraftInfusion.activeItem = oldActiveItem as InfusionSubItem;

        setSnackbar({
          open: true,
          message:
            'Successfully Restored this infusion dose with a new DRAFT version ' +
            newDraftInfusion.version,
          severity: 'success',
        });

        // Update subData with new draft
        const newSubData = subArchiveData.map((item: ArchiveItem) =>
          item.activeItem?.uid === activeParmInfusion.uid
            ? { ...item, activeItem: newDraftInfusion }
            : item
        );
        setSubArchiveData(newSubData);
        setActiveParmInfusion(newDraftInfusion);

        if (globals.debug)
          console.log('Successfully restored Infusion dose', results.data);

        setModal({
          isVisible: true,
          title: 'Successfully Restored Infusion Dose',
          primaryDescription: `Successfully restored ${newDraftInfusion.name} to version ${newDraftInfusion.version}. Would you like to navigate to the infusion dose?`,
          primaryBtnName: 'Dismiss',
          secondaryBtnName: 'Navigate',
          handleClose: () => {
            setModal({ ...modal, isVisible: false });
          },
          handleSubmit: () => {
            const state = {
              selectedProtocol: protocol,
              editMode: false,
              backPage: 'list-protocols',
              search: { searchQuery: '', filters: [], categoriesFilter: [] },
              selectedDose: newDraftInfusion,
            };
            const elecFormattedString = protocol.nickname.replace(
              /[\s/]/g,
              '-'
            );
            navigate(`/${elecFormattedString}/protocol-detail`, { state });
          },
        });
      } else if (results.type === ResponseType.Failure) {
        setSnackbar({
          open: true,
          message: 'Failed to restore Infusion Dose.',
          severity: 'error',
        });
      }
      setSidebarVisible(false);
      setSelectedDose(null);
    } catch (error) {
      console.error('Failed to restore infusion dose', error);
    }
  };

  useEffect(() => {
    const mapInfusionDoses = async () => {
      try {
        const activeInfusionID =
          activeParmInfusion.status === ProgressStatus.DRAFT
            ? activeParmInfusion.activeItem?.uid
            : activeParmInfusion.uid;

        // Fetch all the active and its archived infusion doses
        const infusionDoses = subArchiveData.filter((item: ArchiveItem) =>
          (item.activeItem as InfusionSubItem).parent.activeID
            ? (item.activeItem as InfusionSubItem).parent.activeID ===
              activeInfusionID
            : (item.activeItem as InfusionSubItem).parent.uid ===
              activeInfusionID
        );

        // Update the createdAt field for each of the items in the infusionDoses
        const processedData = updateArchiveItemsCreatedAt(
          infusionDoses
        ) as ArchiveItem[];

        setInfusionList((prev: InfusionItem[] | InfusionSubItem[]) =>
          (prev as InfusionItem[]).map((infusionItem: InfusionItem) => {
            const tempSubItems = linkSubItemsToItem(
              infusionItem,
              processedData
            );
            infusionItem.subItems = tempSubItems as InfusionSubItem[];
            return infusionItem;
          })
        );
      } catch (err) {
        console.error('Error fetching infusion doses:', err);
      }
    };

    // Perform this mapping only if the tab is Infusions
    if (tab === 'Infusions') {
      mapInfusionDoses();
    }
  }, [
    infusions,
    activeParmInfusion,
    database,
    department,
    activeItem,
    tab,
    subArchiveData,
  ]);

  /* Hotkey detection */
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.ctrlKey && event.shiftKey && event.key === 'O') {
        console.log('Active Infusion', activeParmInfusion);
        console.log('Archive Infusions', infusions);
        console.log('Infusion List', infusionList);
        console.log('Tab', tab);
      }
    };

    // Add event listener
    document.addEventListener('keydown', handleKeyDown);

    // Remove event listener on cleanup
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [activeParmInfusion, infusions, infusionList, tab]);

  const handleSortItem = useCallback(
    (
      key: string,
      direction: string,
      a: InfusionItem | InfusionSubItem,
      b: InfusionItem | InfusionSubItem
    ) => {
      if (key === 'version') {
        return direction === 'asc'
          ? a.version.localeCompare(b.version)
          : b.version.localeCompare(a.version);
      } else if (key === 'name') {
        return direction === 'asc'
          ? a.name.localeCompare(b.name)
          : b.name.localeCompare(a.name);
      } else if (key === 'archived_date') {
        const aDate = new Date(a.modifiedAt || Date.now());
        const bDate = new Date(b.modifiedAt || Date.now());
        return direction === 'asc'
          ? aDate.getTime() - bDate.getTime()
          : bDate.getTime() - aDate.getTime();
      } else if (key === 'archived_by') {
        const aUserId = a.model?.modifiedBy || a.model?.createdBy;
        const bUserId = b.model?.modifiedBy || b.model?.createdBy;

        const aUser = aUserId
          ? database.users.find((user: User) => user.id === aUserId)
          : null;
        const bUser = bUserId
          ? database.users.find((user: User) => user.id === bUserId)
          : null;

        const aName = aUser
          ? `${aUser.firstName} ${aUser.lastName}`
          : DEFAULT_USERNAME;
        const bName = bUser
          ? `${bUser.firstName} ${bUser.lastName}`
          : DEFAULT_USERNAME;

        return direction === 'asc'
          ? aName.localeCompare(bName)
          : bName.localeCompare(aName);
      } else if (key === 'doses' && tab === 'Medications') {
        return direction === 'asc'
          ? (a as InfusionItem).subItems.length -
              (b as InfusionItem).subItems.length
          : (b as InfusionItem).subItems.length -
              (a as InfusionItem).subItems.length;
      }
      return 0;
    },
    [database.users, tab]
  );

  const infusionHeaders: Header<InfusionItem | InfusionSubItem>[] = useMemo(
    () => [
      {
        key: 'version',
        name: 'Version',
        sortable: true,
        flex: 1,
        render: (item: InfusionItem | InfusionSubItem) => (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {item.version}
            {tab === 'Doses' && (
              <Status status={item.status} style={{ marginLeft: '8px' }} />
            )}
          </div>
        ),
      },
      {
        key: 'name',
        name: 'Name',
        sortable: true,
        flex: 2,
        render: (item: InfusionItem | InfusionSubItem) => (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {!(
              item.departmentID === department.id &&
              item.status !== 'DEACTIVATED'
            ) && (
              <div style={{ marginRight: '8px' }}>
                <FaLock className="table-icon" color="#A3A3A3" />
              </div>
            )}
            {item.name}
            {tab === 'Infusions' && (
              <Status status={item.status} style={{ marginLeft: '8px' }} />
            )}
          </div>
        ),
      },

      {
        key: 'concentration',
        name: 'Concentration',
        sortable: true,
        flex: 1,
        render: (item: InfusionItem | InfusionSubItem) =>
          (item as InfusionItem).loadingConcentrations
            ? undefined
            : getConcentrationsArrayString(
                (item as InfusionItem).concentrations
              ),
      },

      {
        key: 'archived_date',
        name: 'Archived Date',
        sortable: true,
        flex: 1,
        render: (item: InfusionItem | InfusionSubItem) =>
          getFormattedDate(new Date(item.modifiedAt || Date.now()), true),
      },
      {
        key: 'archived_by',
        name: 'Archived By',
        sortable: true,
        flex: 1,
        render: (item: InfusionItem | InfusionSubItem) => {
          const userId = item.model?.modifiedBy || item.model?.createdBy;
          const user = userId
            ? database.users.find((user: User) => user.id === userId)
            : null;
          const username = user
            ? `${user.firstName} ${user.lastName}`
            : DEFAULT_USERNAME;

          return (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
                paddingRight: '15px',
              }}
            >
              <span>{username}</span>
              {tab === 'Doses' && (
                <FaChevronRight
                  className="icon-normal"
                  style={{ margin: '4px' }}
                />
              )}
            </div>
          );
        },
      },

      {
        key: 'doses',
        name: 'Doses',
        sortable: true,
        flex: 1,
        render: (item: InfusionItem | InfusionSubItem) => (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
              paddingRight: '15px',
            }}
          >
            <span>{(item as InfusionItem).subItems?.length || 0}</span>
            <FaChevronRight className="icon-normal" style={{ margin: '4px' }} />
          </div>
        ),
      },
    ],
    [tab, department.id, database.users]
  );

  const doseHeaders: Header<InfusionItem | InfusionSubItem>[] = useMemo(
    () => [
      {
        key: 'version',
        name: 'Version',
        sortable: true,
        flex: 0.75,
        render: (item: InfusionItem | InfusionSubItem) => (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {item.version}
          </div>
        ),
      },
      {
        key: 'status',
        name: 'Status',
        sortable: true,
        flex: 0.75,
        render: (item: InfusionItem | InfusionSubItem) => (
          <Status status={item.status} />
        ),
      },
      {
        key: 'name',
        name: 'Name',
        sortable: true,
        flex: 2,
        render: (item: InfusionItem | InfusionSubItem) => (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {!(
              item.departmentID === department.id &&
              item.status !== 'DEACTIVATED'
            ) && (
              <div style={{ marginRight: '8px' }}>
                <FaLock className="table-icon" color="#A3A3A3" />
              </div>
            )}
            {item.name}
            {tab === 'Infusions' && (
              <Status status={item.status} style={{ marginLeft: '8px' }} />
            )}
          </div>
        ),
      },

      {
        key: 'protocol_name',
        name: 'Protocol Name',
        sortable: true,
        flex: 2,
        render: (item: InfusionItem | InfusionSubItem) =>
          (item as InfusionSubItem).parentProtocol.name,
      },
      {
        key: 'full_basis',
        name: 'Full Basis',
        sortable: true,
        flex: 1,
        render: (item: InfusionItem | InfusionSubItem) =>
          (item as InfusionSubItem).fullBasis,
      },
      {
        key: 'routes',
        name: 'Routes',
        sortable: true,
        flex: 1,
        render: (item: InfusionItem | InfusionSubItem) =>
          (item as InfusionSubItem).routes.length > 0
            ? getRoutesString(item as InfusionSubItem)
            : '',
      },

      {
        key: 'archived_date',
        name: 'Archived Date',
        sortable: true,
        flex: 1,
        render: (item: InfusionItem | InfusionSubItem) =>
          getFormattedDate(new Date(item.model?.updatedAt || Date.now()), true),
      },
      {
        key: 'archived_by',
        name: 'Archived By',
        sortable: true,
        flex: 1,
        render: (item: InfusionItem | InfusionSubItem) => {
          const userId = item.model?.modifiedBy || item.model?.createdBy;
          const user = userId
            ? database.users.find((user: User) => user.id === userId)
            : null;
          const username = user
            ? `${user.firstName} ${user.lastName}`
            : DEFAULT_USERNAME;

          return (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
                paddingRight: '15px',
              }}
            >
              <span>{username}</span>
              {tab === 'Doses' && (
                <FaChevronRight
                  className="icon-normal"
                  style={{ margin: '4px' }}
                />
              )}
            </div>
          );
        },
      },
    ],
    [tab, department.id, database.users]
  );

  const handleRestorePressed = async () => {
    if (isDraft) {
      setModal({
        isVisible: true,
        title: 'Override Existing Draft?',
        primaryDescription: `There is already a draft of ${activeParmInfusion.name} (${activeParmInfusion.version}) which is not yet published. Are you sure you want to override the existing draft and restore ${selectedDose?.name} of version ${selectedDose?.version}?`,
        primaryBtnName: 'Cancel',
        secondaryBtnName: 'Restore',
        handleClose: () => {
          setModal({ ...modal, isVisible: false });
        },
        handleSubmit: handleDoseRestore,
      });
    } else {
      setModal({
        isVisible: true,
        title: 'Restore Infusion?',
        primaryDescription: `Are you sure you want to restore ${selectedDose?.name} of version ${selectedDose?.version}? This will create a new draft version of the infusion dose.`,
        primaryBtnName: 'Cancel',
        secondaryBtnName: 'Restore',
        handleClose: () => {
          setModal({ ...modal, isVisible: false });
        },
        handleSubmit: handleDoseRestore,
      });
    }
  };

  return (
    <div className="screen-container">
      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>

      <ConfirmModal
        isVisible={modal.isVisible}
        title={modal.title}
        primaryDescription={modal.primaryDescription}
        primaryBtnName={modal.primaryBtnName}
        secondaryBtnName={modal.secondaryBtnName}
        handleClose={modal.handleClose}
        handleSubmit={modal.handleSubmit}
      />

      <SubItemRestoreSideout
        dose={selectedDose ? selectedDose : undefined}
        type="infusion"
        visible={sidebarVisible}
        setVisible={handleCloseModal}
        onRestore={handleRestorePressed}
      />

      <ProtocolHeader
        isBackButton={true}
        handleCancel={handleBack}
        name={`${activeParmInfusion.activeItem?.name || activeParmInfusion.name}: ${infusionList.length - 1} archive${infusionList.length - 1 === 1 ? '' : 's'}`}
        page={`${tab === 'Infusions' ? 'Infusion' : 'Infusion Doses'} Archives`}
        type="protocol"
      />

      <DataList<InfusionItem | InfusionSubItem, never>
        items={infusionList}
        headers={tab === 'Infusions' ? infusionHeaders : doseHeaders}
        onItemClick={handleItemClick}
        searchPlaceholder={`Search ${tab} Archives...`}
        sortItem={handleSortItem}
      />
    </div>
  );
};

export default ListArchiveInfusionsDetails;
