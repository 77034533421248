import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { ViewportList } from 'react-viewport-list';
import { FaCircleCheck, FaCircleXmark } from 'react-icons/fa6';
import { BiCopy, BiSolidCopy } from 'react-icons/bi';
import {
  getArchivedDosesToDisplay,
  getFormattedDateTime,
  globals,
  handleCopy,
  removeTypename,
  upgradeVersion,
} from '../../../../_global/common/Utils';

import MedicationItem, {
  getConcentrationString,
} from '../../../../../data/model/MedicationItem';
import {
  Concentration,
  Medication,
  MedicationProtocol,
  User,
  UserType,
} from '../../../../../models';
import ProtocolItem from '../../../../../data/model/ProtocolItem';
import MedicationSubItem from '../../../../../data/model/MedicationSubItem';
import MedicationDoseUI from './MedicationDoseUI';
import ProtocolHeader from '../../ProtocolHeader';
import { ProgressStatus } from '../../../../../models';
import { Col, Row } from 'react-bootstrap';
import AccordionItem from '../../../../components/Accordion/AccordionItem';
import ConfirmModal from '../../../../components/Modal/ConfirmModal';
import {
  createParentMedication,
  deleteMedicationItem,
  MedicationJSON,
} from '../../../../../data/functions/MedicationDB';
import { ResponseType } from '../../../../../data/AmplifyDB';
import {
  ArchiveItem,
  reloadModelItem,
} from '../../../../../data/functions/ModelDB';
import ArchivedDosesSideout from '../../../../components/SideOut/doseSideOut/ArchivedDosesSideout';
import { Alert, Snackbar } from '@mui/material';
import CopyToClipboard from '../../../../components/CopyToClipboard';

/* 12-31-24 Gagan: Created medication Archive Page component */
const MedicationProtocolArchivePage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  if (
    !location.state?.value ||
    !location.state?.department ||
    !location.state?.database
  ) {
    navigate('/archive');
  }

  const {
    value,
    sourceData,
    subData,
    archiveData,
    department,
    database,
    activeItem,
  } = location.state;

  const [activeParmMedication, setActiveParmMedication] =
    useState<MedicationItem>(activeItem);

  const user: User = useSelector((state: any) => state?.user);

  const [parmMedication, setParmMedication] = useState<MedicationItem>(value);

  const [allSubMedicationItems, setAllSubMedicationItems] = useState<
    MedicationSubItem[]
  >(
    value.subItems.filter(
      (item: MedicationSubItem) => item.parentProtocol != null
    )
  );

  const [expanded, setExpanded] = useState<string[]>([]);
  const [modifiedBy, setModifiedBy] = useState<User | null>(null);

  const [isRestoreModal, setIsRestoreModal] = useState(false);
  const [isRestoring, setIsRestoring] = useState(false);

  // Check if the most recent version of the medication is a draft
  const isDraft = useMemo(
    () => activeParmMedication.status === ProgressStatus.DRAFT,
    [activeParmMedication]
  );

  const [snackbar, setSnackbar] = useState<any>({
    open: false,
    message: '',
    severity: 'success',
  });

  const [modal, setModal] = useState({
    isVisible: false,
    title: '',
    primaryDescription: '',
    primaryBtnName: '',
    secondaryBtnName: '',
    handleClose: () => {},
    handleSubmit: () => {},
  });

  const reloadItem = async () => {
    const response = await reloadModelItem(
      MedicationItem,
      activeParmMedication
    );
    if (response.type === ResponseType.Success) {
      const item = new MedicationItem(response.data.item as Medication);
      const activeItem = response.data.activeItem as
        | Medication
        | null
        | undefined;
      item.activeItem = activeItem ? new MedicationItem(activeItem) : null;
      item.subItems = activeParmMedication.subItems;
      setActiveParmMedication(item);
    }
  };

  /**
   * Check if the draft exists for the active item
   */
  useEffect(() => {
    reloadItem();
  }, []);

  const allProtocols = useMemo(() => {
    let prots = allSubMedicationItems
      .filter((item: MedicationSubItem) => item.parentProtocol != null)
      .map((m) => m.parentProtocol);
    // Remove all the duplicates
    return prots
      .filter((v, i, a) => a.findIndex((t) => t.uid === v.uid) === i)
      .sort((a, b) => {
        if (a.parent.index === b.parent.index)
          return a.name.localeCompare(b.name);
        return a.parent.index - b.parent.index;
      });
  }, [allSubMedicationItems]);

  /* Hotkey detection */
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.ctrlKey && event.shiftKey && event.key === 'O') {
        console.log('ARCHIVE MEDICATION', parmMedication);
        console.log('ALL SUB MEDICATION ITEMS', allSubMedicationItems);
        console.log('ACTIVE PARM MEDICATION', activeParmMedication);
      }
    };

    // Add event listener
    document.addEventListener('keydown', handleKeyDown);

    // Remove event listener on cleanup
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [parmMedication, allSubMedicationItems, activeParmMedication]);

  useEffect(() => {
    if (value) {
      setParmMedication(value);
      setAllSubMedicationItems(
        value.subItems.filter(
          (item: MedicationSubItem) => item.parentProtocol != null
        )
      );
    }
  }, [value]);

  useEffect(() => {
    let id = parmMedication.model?.modifiedBy;
    if (id == null) id = parmMedication.model?.createdBy;
    if (id) {
      let user = database.users.find((u: User) => u.id === id);
      setModifiedBy(user ? user : null);
      if (user) {
        parmMedication.modifiedBy = user;
      }
    } else {
      setModifiedBy(null);
    }
  }, [database, parmMedication]);

  /**
   * Find the protocols from the protocol options
   * @param protocolOptions The protocol options to search for
   * @returns The protocols that were found
   */
  const findProtocols = useCallback(
    (protocolOptions: MedicationProtocol[]): ProtocolItem[] => {
      let protocols: ProtocolItem[] = [];
      for (let i = 0; i < protocolOptions.length; i++) {
        let po = protocolOptions[i];
        let protocol = database.protocols.find((p: ProtocolItem) => {
          let id =
            p.status === ProgressStatus.ACTIVE ||
            (p.status === ProgressStatus.DRAFT && p.activeID == null)
              ? p.uid
              : p.activeID;
          return id === po.protocolID;
        });
        if (protocol) protocols.push(protocol);
      }
      return protocols;
    },
    [database]
  );

  /**
   * Back button has been pressed
   */
  const handleBack = useCallback(() => {
    navigate('/archive/list-medications-details', {
      state: {
        data: sourceData,
        subData: subData,
        department: department,
        database: database,
        archiveData: archiveData,
        activeItem: activeParmMedication,
        tab: 'Medications',
      },
    });
  }, [
    navigate,
    sourceData,
    subData,
    department,
    database,
    archiveData,
    activeParmMedication,
  ]);

  const ListRender = ({ data }: any) => {
    const [showArchiveSideout, setShowArchiveSideout] = useState(false);
    const [selectedDose, setSelectedDose] = useState<MedicationSubItem | null>(
      null
    );
    const [selectedArchives, setSelectedArchives] = useState<
      MedicationSubItem[]
    >([]);

    // Process all data once at the beginning
    const { displayItems, archivedItemsMap } = useMemo(() => {
      return getArchivedDosesToDisplay(data);
    }, [data]);

    const handleViewHistory = (
      item: MedicationSubItem,
      e: React.MouseEvent
    ) => {
      e.stopPropagation(); // Prevent event bubbling if inside clickable containers
      const archivedItems =
        archivedItemsMap.get(item.uid) ||
        archivedItemsMap.get(item.activeID || '') ||
        [];

      setSelectedDose(item);
      setSelectedArchives(archivedItems as MedicationSubItem[]);
      setShowArchiveSideout(true);
    };

    return (
      <>
        <ViewportList items={displayItems as MedicationSubItem[]}>
          {(dose: MedicationSubItem, index: number) => {
            const archivedItems =
              dose.status === ProgressStatus.ARCHIVE
                ? archivedItemsMap.get(dose.activeID || '')
                : archivedItemsMap.get(dose.uid);

            const archivedCount = archivedItems?.length || 0;

            return (
              <div key={index}>
                <MedicationDoseUI
                  dose={dose}
                  doseIndex={dose.version}
                  showProtocol={false}
                  isEditMode={false}
                  archiveMode={true}
                />
                {archivedCount > 0 && (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      margin: 0,
                      marginTop: '0px',
                      cursor: 'pointer',
                    }}
                    onClick={(e) => handleViewHistory(dose, e)}
                  >
                    <p className="sidebarText-hover">
                      {archivedCount} archived
                      {archivedCount === 1 ? ' dose >' : ' doses >'}
                    </p>
                  </div>
                )}
              </div>
            );
          }}
        </ViewportList>

        {selectedDose && (
          <ArchivedDosesSideout
            visible={showArchiveSideout}
            currentDose={selectedDose}
            archivedDoses={selectedArchives}
            type={'medication'}
            setVisible={setShowArchiveSideout}
          />
        )}
      </>
    );
  };

  const handleAccordionChange = useCallback(
    (protocol: ProtocolItem) => {
      if (expanded.includes(protocol.uid)) {
        setExpanded(expanded.filter((item) => item !== protocol.uid));
      } else {
        setExpanded([...expanded, protocol.uid]);
      }
    },
    [expanded]
  );

  const ProtocolItemUI = ({ protocol }: any) => {
    const doses = allSubMedicationItems.filter(
      (item: MedicationSubItem) => item.parentProtocol?.uid === protocol.uid
    );
    let list = doses
      .filter(
        (item: MedicationSubItem) => item.status !== ProgressStatus.DEACTIVATED
      )
      .sort((a: MedicationSubItem, b: MedicationSubItem) => {
        if (a.index === b.index) return a.rangeLow - b.rangeLow;
        return a.index - b.index;
      });

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          padding: '10px',
          marginTop: '.25rem',
          marginBottom: '.25rem',
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <AccordionItem
          title={protocol.name}
          titleStyle={{ fontWeight: '400' }}
          rightTitle={`${list.length} doses`}
          rightTitleStyle={{ fontWeight: '700' }}
          expanded={expanded.includes(protocol.uid)}
          onChange={() => {
            handleAccordionChange(protocol);
          }}
        >
          <div
            style={{ flex: 1, padding: '0 10px 10px 10px' }}
            onClick={(e) => e.stopPropagation()} // Add this to prevent event bubbling
          >
            <ListRender data={list} />
          </div>
        </AccordionItem>
      </div>
    );
  };

  const handleRestore = useCallback(async () => {
    setIsRestoring(true);
    setModal({ ...modal, isVisible: false });
    try {
      const oldActiveItem = isDraft
        ? activeParmMedication.activeItem
        : activeParmMedication;
      if (isDraft) {
        const response = await deleteMedicationItem(
          department,
          activeParmMedication,
          false
        );
        if (response.type === ResponseType.Failure) {
          setSnackbar({
            open: true,
            message:
              'Failed to delete already existing draft of ' +
              oldActiveItem?.name,
            severity: 'error',
          });
          // Exit the function
          console.error('Failed to delete medication', response.data);
          return;
        }
      }

      const protocolList = findProtocols(parmMedication.protocolOptions);

      let overrideID = undefined;

      const updatedMedication: MedicationJSON = {
        name: parmMedication.name,
        concentration: removeTypename(parmMedication.concentrations),
        routes: parmMedication.routes,
        rangeLow: !isNaN(Number(parmMedication.rangeLow))
          ? Number(parmMedication.rangeLow)
          : 0,
        rangeHigh: !isNaN(Number(parmMedication.rangeHigh))
          ? Number(parmMedication.rangeHigh)
          : globals.MAX_VALUE,
        departmentID: department.id,
        protocolOptions: removeTypename(
          parmMedication.protocolOptions.map((item) => ({
            ...item,
            options: removeTypename(
              item.options.map((nr) => ({
                ...nr,
                nemsisRoutes: removeTypename(nr.nemsisRoutes),
              }))
            ),
          }))
        ),
        contraindication: parmMedication.contraindication,
        note: parmMedication.note,
        warning: parmMedication.warning,
        medClass: parmMedication.medClass,
        action: parmMedication.action,
        indication: parmMedication.indication,
        interaction: parmMedication.interaction,
        onset: parmMedication.onset,
        duration: parmMedication.duration,
        taggedProtocols: protocolList,
        createdBy:
          parmMedication.model && parmMedication.model.createdBy
            ? parmMedication.model.createdBy
            : user.id,
        modifiedBy: user.id,

        status: ProgressStatus.DRAFT,
        activeID: oldActiveItem?.uid,
        overrideID: overrideID,
        version: upgradeVersion(oldActiveItem?.version || 'v1.0.0'),
      };

      let results = await createParentMedication(
        department,
        updatedMedication,
        oldActiveItem as MedicationItem
      );

      if (results.type === ResponseType.Success) {
        const newDraftMedication = results.data as MedicationItem;
        newDraftMedication.activeItem = oldActiveItem;

        setSnackbar({
          open: true,
          message:
            'Successfully Restored ' +
            newDraftMedication.name +
            ' with a new DRAFT version ' +
            newDraftMedication.version,
          severity: 'success',
        });

        // Update sourceData with new draft
        const newSourceData = sourceData.map((item: ArchiveItem) =>
          item.activeItem?.uid === activeParmMedication.uid
            ? { ...item, activeItem: newDraftMedication }
            : item
        );

        if (globals.debug)
          console.log('Successfully restored medication', results.data);
        // Handle successful restoration

        setModal({
          isVisible: true,
          title: 'Successfully Restored Medication',
          primaryDescription: `Successfully restored ${newDraftMedication.name} to version ${newDraftMedication.version}. Would you like to navigate to the medication?`,
          primaryBtnName: 'Dismiss',
          secondaryBtnName: 'Navigate',
          handleClose: () => {
            setModal({ ...modal, isVisible: false });
            navigate('/archive/list-electricals-details', {
              state: {
                data: newSourceData,
                subData: subData,
                department: department,
                database: database,
                archiveData: archiveData,
                activeItem: newDraftMedication,
                tab: 'Medications',
              },
            });
          },
          handleSubmit: () => {
            const state = {
              selectedProtocol: null,
              value: newDraftMedication,
              subValue: null,
              type: 'Medication',
              editType: 'edit',
              editMode: false,
              page: 'listMedicationPage',
            };
            navigate(`/protocol/edit/edit-protocol`, { state });
          },
        });
      } else if (results.type === ResponseType.Failure) {
        setSnackbar({
          open: true,
          message: 'Failed to restore Medication.',
          severity: 'error',
        });
      }
    } catch (error) {
      console.error('Failed to restore medication', error);
    } finally {
      setIsRestoring(false);
    }
  }, [
    navigate,
    sourceData,
    department,
    database,
    archiveData,
    activeParmMedication,
    findProtocols,
    user,
    isDraft,
    parmMedication,
    subData,
  ]);

  const handleRestorePressed = async () => {
    if (isDraft) {
      setModal({
        isVisible: true,
        title: 'Override Existing Draft?',
        primaryDescription: `There is already a draft of ${activeParmMedication.name} (${activeParmMedication.version}) which is not yet published. Are you sure you want to override the existing draft and restore ${parmMedication.name} of version ${parmMedication.version}?`,
        primaryBtnName: 'Cancel',
        secondaryBtnName: 'Restore',
        handleClose: () => {
          setModal({ ...modal, isVisible: false });
        },
        handleSubmit: handleRestore,
      });
    } else {
      setModal({
        isVisible: true,
        title: 'Restore Medication?',
        primaryDescription: `Are you sure you want to restore ${parmMedication.name} of version ${parmMedication.version}? This will create a new draft version of the medication.`,
        primaryBtnName: 'Cancel',
        secondaryBtnName: 'Restore',
        handleClose: () => {
          setModal({ ...modal, isVisible: false });
        },
        handleSubmit: handleRestore,
      });
    }
  };

  return (
    <div className="screen-container">
      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>

      <ConfirmModal
        isVisible={modal.isVisible}
        title={modal.title}
        primaryDescription={modal.primaryDescription}
        primaryBtnName={modal.primaryBtnName}
        secondaryBtnName={modal.secondaryBtnName}
        handleClose={modal.handleClose}
        handleSubmit={modal.handleSubmit}
      />

      <ProtocolHeader
        name={parmMedication.name}
        status={parmMedication.status}
        isBackButton={true}
        page={parmMedication.name + ' Archive'}
        type={'protocol'}
        isCopyDescription={user.type === UserType.ADMIN}
        descriptionTitle={user.type === UserType.ADMIN ? 'ID: ' : ''}
        description={user.type === UserType.ADMIN ? parmMedication.uid : ''}
        handleCancel={handleBack}
        isRestoreButton={true}
        isRestoreActive={!isRestoring && parmMedication.status !== 'ACTIVE'}
        bypassActiveStatusCheck={true}
        handleRestore={handleRestorePressed}
      />

      <div className="ketamineContent">
        <div className="KetamineGeneral">
          <h5 className="ketmine-header-text">General Information</h5>
          <div className="input-container roundBorder">
            <div>
              <div
                style={{ display: 'flex', marginTop: '5px' }}
                className="ketamine-general-label"
              >
                <div style={{ marginRight: '10px' }}>Modified By:</div>
                <div style={{ fontWeight: '500' }}>
                  {modifiedBy
                    ? modifiedBy.firstName + ' ' + modifiedBy.lastName
                    : 'Hinckley Medical'}
                </div>
              </div>

              {parmMedication.model?.updatedAt && (
                <div
                  style={{ display: 'flex' }}
                  className="ketamine-general-label"
                >
                  <div style={{ marginRight: '10px' }}>Last Updated:</div>
                  <div style={{ fontWeight: '500' }}>
                    {getFormattedDateTime(
                      parmMedication.model.updatedAt,
                      false
                    )}
                  </div>
                </div>
              )}

              <div
                style={{ display: 'flex' }}
                className="ketamine-general-label"
              >
                <div style={{ marginRight: '10px' }}>Version:</div>
                <div style={{ fontWeight: '500' }}>
                  {parmMedication.version}
                </div>
              </div>
              {user.type === UserType.ADMIN &&
                parmMedication.rxNormCode != null && (
                  <div
                    style={{ display: 'flex' }}
                    className="ketamine-general-label"
                  >
                    <div style={{ marginRight: '10px' }}>RxNorm Code:</div>
                    <div style={{ fontWeight: '500' }}>
                      {parmMedication.rxNormCode}
                      <CopyToClipboard text={parmMedication.rxNormCode} />
                    </div>
                  </div>
                )}
              {user.type === UserType.ADMIN && (
                <div
                  style={{ display: 'flex' }}
                  className="ketamine-general-label"
                >
                  <div style={{ marginRight: '10px' }}>Owner ID:</div>
                  <div style={{ fontWeight: '500' }}>
                    {parmMedication.departmentID}
                    <CopyToClipboard text={parmMedication.departmentID} />
                  </div>
                </div>
              )}
              {user.type === UserType.ADMIN &&
                parmMedication.overrideID != null && (
                  <div
                    style={{ display: 'flex' }}
                    className="ketamine-general-label"
                  >
                    <div style={{ marginRight: '10px' }}>Override ID:</div>
                    <div style={{ fontWeight: '500' }}>
                      {parmMedication.overrideID}
                      <span>
                        {parmMedication.overrideItem != null ? (
                          <FaCircleCheck
                            color={'#037F02'}
                            size=".75rem"
                            className="copy_icon"
                          />
                        ) : (
                          <FaCircleXmark
                            color={'#8c1010'}
                            size=".75rem"
                            className="copy_icon"
                          />
                        )}
                      </span>
                      <CopyToClipboard text={parmMedication.overrideID} />
                    </div>
                  </div>
                )}
              {user.type === UserType.ADMIN && (
                <div
                  style={{ display: 'flex' }}
                  className="ketamine-general-label"
                >
                  <div style={{ marginRight: '10px' }}>ID:</div>
                  <div style={{ fontWeight: '500' }}>
                    {parmMedication.uid}
                    <CopyToClipboard text={parmMedication.uid} />
                  </div>
                </div>
              )}
              {user.type === UserType.ADMIN &&
                parmMedication.activeID != null && (
                  <div
                    style={{ display: 'flex' }}
                    className="ketamine-general-label"
                  >
                    <div style={{ marginRight: '10px' }}>Active ID:</div>
                    <div style={{ fontWeight: '500' }}>
                      {parmMedication.activeID}
                      <CopyToClipboard text={parmMedication.activeID} />
                    </div>
                  </div>
                )}
            </div>
          </div>

          <label className="ketamine-general-label">Name</label>
          <div className="input-container">
            <InputText
              type="text"
              className="form-control-general"
              id="name"
              name="name"
              data-testid="name"
              value={parmMedication.name}
              disabled
            />
            <div className="input-border"></div>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginTop: '10px',
            }}
          >
            <label className="ketamine-general-label">Concentration</label>
          </div>
          <div
            data-testid="outsideConcentration"
            data-div-id="outsideConcentration"
            className={`selectionArea-hover selectionArea-disable ${
              parmMedication.concentrations?.length !== 0 ? '' : 'centerContent'
            }`}
          >
            {parmMedication.concentrations?.length !== 0 && (
              <ViewportList items={parmMedication.concentrations}>
                {(item: Concentration, index: any) => {
                  return (
                    <div
                      key={index.toLocaleString()}
                      className={`selectedGreybox disabled`}
                    >
                      <div>{getConcentrationString(item)}</div>
                    </div>
                  );
                }}
              </ViewportList>
            )}
          </div>

          <label className="ketamine-general-label">Routes</label>
          <div
            data-testid="outsideRoute"
            data-div-id="outsideRoute"
            className={`selectionArea-hover selectionArea-disable ${parmMedication.routes.length !== 0 ? '' : 'centerContent'}`}
          >
            {parmMedication.routes.length >= 1 && (
              <ViewportList items={parmMedication.routes}>
                {(item: any, index: any) => {
                  return (
                    <div
                      key={index.toLocaleString()}
                      className={`selectedGreybox disabled`}
                    >
                      <div>{item}</div>
                    </div>
                  );
                }}
              </ViewportList>
            )}
          </div>

          <Row>
            <Col sm={6}>
              <label className="ketamine-general-label">Range Low (kg)</label>
              <div className="input-container">
                <InputText
                  type="text"
                  className="form-control-general"
                  id="name"
                  name="rangeLow"
                  data-testid="rangeLow"
                  value={
                    parmMedication.rangeLow &&
                    Number(parmMedication.rangeLow) !== 0
                      ? parmMedication.rangeLow + ''
                      : ''
                  }
                  placeholder="Min"
                  disabled
                />
                <div className="input-border"></div>
              </div>
            </Col>
            <Col sm={6}>
              <label className="ketamine-general-label">Range High (kg)</label>
              <div className="input-container">
                <InputText
                  type="text"
                  className="form-control-general"
                  id="rangeHigh"
                  name="rangeHigh"
                  data-testid="rangeHigh"
                  disabled
                  value={
                    parmMedication.rangeHigh &&
                    Number(parmMedication.rangeHigh) !== globals.MAX_VALUE
                      ? parmMedication.rangeHigh + ''
                      : ''
                  }
                  placeholder="Max"
                />
                <div className="input-border"></div>
              </div>
            </Col>
          </Row>

          <label className="ketamine-general-label">Contraindication</label>
          <div className="input-container">
            <InputText
              type="text"
              className="form-control-general"
              id="contraindication"
              data-testid="contraindication"
              name="contraindication"
              value={parmMedication?.model?.contraindication || ''}
              disabled
            />
            <div className="input-border"></div>
          </div>

          <label className="ketamine-general-label">Warning</label>
          <div className="input-container">
            <InputText
              type="text"
              className="form-control-general"
              id="warning"
              data-testid="warning"
              name="warning"
              value={parmMedication?.model?.warning || ''}
              disabled
            />
            <div className="input-border"></div>
          </div>
          <label htmlFor="Indication" className="ketamine-general-label">
            Indication
          </label>
          <div className="input-container">
            <InputText
              type="text"
              className="form-control-general"
              id="indication"
              data-testid="indication"
              name="indication"
              value={parmMedication?.model?.indication || ''}
              disabled
            />
            <div className="input-border"></div>
          </div>
          <label htmlFor="DrugInteraction" className="ketamine-general-label">
            Drug Interaction
          </label>
          <div className="input-container">
            <InputText
              type="text"
              className="form-control-general"
              id="interaction"
              data-testid="interaction"
              name="interaction"
              value={parmMedication?.model?.interaction || ''}
              disabled
            />
            <div className="input-border"></div>
          </div>
          <label htmlFor="Onset" className="ketamine-general-label">
            Onset
          </label>
          <div className="input-container">
            <InputText
              type="text"
              className="form-control-general"
              id="onset"
              data-testid="onset"
              name="onset"
              value={parmMedication?.model?.onset || ''}
              disabled
            />
            <div className="input-border"></div>
          </div>
          <label htmlFor="Duration" className="ketamine-general-label">
            Duration
          </label>
          <div className="input-container">
            <InputText
              type="text"
              className="form-control-general"
              id="duration"
              data-testid="duration"
              name="duration"
              value={parmMedication?.model?.duration || ''}
              disabled
            />
            <div className="input-border"></div>
          </div>
          <label htmlFor="Note" className="ketamine-general-label">
            Note
          </label>
          <div className="input-container">
            <InputText
              type="text"
              className="form-control-general"
              id="notes"
              data-testid="notes"
              name="notes"
              value={parmMedication?.model?.note || ''}
              disabled
            />
            <div className="input-border"></div>
          </div>
          <label htmlFor="Class" className="ketamine-general-label">
            Class
          </label>
          <div className="input-container">
            <InputText
              type="text"
              className="form-control-general"
              id="class"
              data-testid="class"
              name="class"
              value={parmMedication?.model?.medClass || ''}
              disabled
            />
            <div className="input-border"></div>
          </div>
          <label htmlFor="Action" className="ketamine-general-label">
            Action
          </label>
          <div className="input-container">
            <InputText
              type="text"
              className="form-control-general"
              id="action"
              data-testid="action"
              name="action"
              value={parmMedication?.model?.action || ''}
              disabled
            />
            <div className="input-border"></div>
          </div>
        </div>

        <div className="KetamineGeneral">
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: '10px',
            }}
          >
            <h5 className="ketmine-header-text">
              All Doses: {allSubMedicationItems.length} Items
              <span
                className="no-select"
                style={{
                  fontSize: '12px',
                  marginLeft: '10px',
                  marginTop: '5px',
                }}
                onClick={() => {
                  if (allProtocols.length === expanded.length) setExpanded([]);
                  else setExpanded(allProtocols.map((p) => p.uid));
                }}
              >
                <div className="clickableText">
                  {expanded.length === allProtocols.length
                    ? 'Collapse All'
                    : 'Expand All'}
                </div>
              </span>
            </h5>
          </div>

          <>
            {allProtocols.map((protocol: ProtocolItem, index: number) => {
              return <ProtocolItemUI key={index} protocol={protocol} />;
            })}
          </>
        </div>
      </div>
    </div>
  );
};

export default MedicationProtocolArchivePage;
