import { InputText } from 'primereact/inputtext';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ViewportList } from 'react-viewport-list';
import VitalItem from '../../../../../data/model/VitalItem';
import VitalDose from './VitalDose';
import { ProgressStatus, User, Vitals } from '../../../../../models';
import {
  getFormattedDate,
  globals,
  handleCopy,
  removeTypename,
  upgradeVersion,
} from '../../../../_global/common/Utils';
import ProtocolHeader from '../../ProtocolHeader';
import { UserType } from '../../../../../models';
import {
  createVital,
  deleteVitalItem,
} from '../../../../../data/functions/VitalDB';
import { BiCopy, BiSolidCopy } from 'react-icons/bi';
import ConfirmModal from '../../../../components/Modal/ConfirmModal';
import { ResponseType } from '../../../../../data/AmplifyDB';
import { VitalDB } from '../../../../../data/functions/VitalDB';
import {
  ArchiveItem,
  reloadModelItem,
} from '../../../../../data/functions/ModelDB';
import { Alert, Snackbar } from '@mui/material';
import CopyToClipboard from '../../../../components/CopyToClipboard';

/* 12-31-24 Gagan: Created vital Archive Page component */
const VitalProtocolArchivePage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  if (
    !location.state?.value ||
    !location.state?.department ||
    !location.state?.database
  ) {
    navigate('/archive');
  }

  const { value, sourceData, archiveData, department, database, activeItem } =
    location.state;

  const user: User = useSelector((state: any) => state?.user);

  const [activeParmVital, setActiveParmVital] = useState<VitalItem>(activeItem);

  const [parmVital, setParmVital] = useState<VitalItem>(value);
  const [isRestoring, setIsRestoring] = useState(false);

  const isDraft = useMemo(() => {
    return activeParmVital.status === ProgressStatus.DRAFT;
  }, [activeParmVital]);

  const [snackbar, setSnackbar] = useState<any>({
    open: false,
    message: '',
    severity: 'success',
  });

  const [modal, setModal] = useState({
    isVisible: false,
    title: '',
    primaryDescription: '',
    primaryBtnName: '',
    secondaryBtnName: '',
    handleClose: () => {},
    handleSubmit: () => {},
  });

  const reloadItem = async () => {
    const response = await reloadModelItem(Vitals, activeParmVital);
    if (response.type === ResponseType.Success) {
      const item = new VitalItem(response.data.item);
      const activeItem = response.data.activeItem as Vitals | null | undefined;
      item.activeItem = activeItem ? new VitalItem(activeItem) : null;
      setActiveParmVital(item);
    }
  };

  /**
   * Check if the draft exists for the active item
   */
  useEffect(() => {
    reloadItem();
  }, []);

  /* Hotkey detection */
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.ctrlKey && event.shiftKey && event.key === 'O') {
        console.log('ARCHIVE VITAL', parmVital);
        console.log('ACTIVE ITEM', activeParmVital);
      }
    };

    // Add event listener
    document.addEventListener('keydown', handleKeyDown);

    // Remove event listener on cleanup
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [parmVital, activeParmVital]);

  // Update the vital state when the parmVital state changes
  useEffect(() => {
    if (value) {
      setParmVital(value);
    }
  }, [value]);

  const handleBack = useCallback(() => {
    navigate('/archive/list-vitals-details', {
      state: {
        data: sourceData,
        department: department,
        database: database,
        archiveData: archiveData,
        activeItem: activeParmVital,
      },
    });
  }, [
    navigate,
    sourceData,
    department,
    database,
    archiveData,
    activeParmVital,
  ]);

  const handleRestore = useCallback(async () => {
    setIsRestoring(true);
    setModal({ ...modal, isVisible: false });
    try {
      const oldActiveItem = isDraft
        ? activeParmVital.activeItem
        : activeParmVital;
      if (isDraft) {
        const response = await deleteVitalItem(activeParmVital, false);
        if (response.type === ResponseType.Failure) {
          setSnackbar({
            open: true,
            message:
              'Failed to delete already existing draft of ' +
              oldActiveItem?.name,
            severity: 'error',
          });
          // Exit the function
          console.error('Failed to delete vital', response.data);
          return;
        }
      }

      const updatedVital: VitalDB = {
        title: parmVital.name,
        status: ProgressStatus.DRAFT,
        activeID: oldActiveItem?.uid,
        departmentID: department.id,
        version: upgradeVersion(oldActiveItem?.version || 'v1.0.0'),
        optionItems: removeTypename(parmVital.options),
        createdBy:
          parmVital.model && parmVital.model.createdBy
            ? parmVital.model.createdBy
            : user.id,
        modifiedBy: user.id,
      };
      const results = await createVital(
        updatedVital,
        oldActiveItem as VitalItem
      );
      if (results.type === ResponseType.Success) {
        const newDraftVital = results.data as VitalItem;
        newDraftVital.activeItem = oldActiveItem;

        setSnackbar({
          open: true,
          message:
            'Successfully Restored ' +
            newDraftVital.name +
            ' with a new DRAFT version ' +
            newDraftVital.version,
          severity: 'success',
        });

        // Update sourceData with new draft
        const newSourceData = sourceData.map((item: ArchiveItem) =>
          item.activeItem?.uid === activeParmVital.uid
            ? { ...item, activeItem: newDraftVital }
            : item
        );

        if (globals.debug)
          console.log('Successfully restored Vital', results.data);

        setModal({
          isVisible: true,
          title: 'Successfully Restored Vital',
          primaryDescription: `Successfully restored ${newDraftVital.name} to version ${newDraftVital.version}. Would you like to navigate to the vital?`,
          primaryBtnName: 'Dismiss',
          secondaryBtnName: 'Navigate',
          handleClose: () => {
            setModal({ ...modal, isVisible: false });
            navigate('/archive/list-vitals-details', {
              state: {
                data: newSourceData,
                department: department,
                database: database,
                archiveData: archiveData,
                activeItem: newDraftVital,
              },
            });
          },
          handleSubmit: () => {
            const state = {
              selectedProtocol: null,
              value: newDraftVital,
              subValue: null,
              type: 'Vital',
              editType: 'edit',
              editMode: false,
              page: 'listVitalPage',
            };
            navigate(`/protocol/edit/edit-protocol`, { state });
          },
        });
      } else if (results.type === ResponseType.Failure) {
        setSnackbar({
          open: true,
          message: 'Failed to restore Vital.',
          severity: 'error',
        });
      }
    } catch (error) {
      setIsRestoring(false);
      console.error('Restore error:', error);
    } finally {
      setIsRestoring(false);
    }
  }, [
    isDraft,
    activeParmVital,
    parmVital.name,
    parmVital.options,
    parmVital.model,
    department,
    user.id,
    sourceData,
    navigate,
    database,
    archiveData,
  ]);

  const handleRestorePressed = async () => {
    if (isDraft) {
      setModal({
        isVisible: true,
        title: 'Override Existing Draft?',
        primaryDescription: `There is already a draft of ${activeParmVital.name} (${activeParmVital.version}) which is not yet published. Are you sure you want to override the existing draft and restore ${parmVital.name} of version ${parmVital.version}?`,
        primaryBtnName: 'Cancel',
        secondaryBtnName: 'Restore',
        handleClose: () => {
          setModal({ ...modal, isVisible: false });
        },
        handleSubmit: handleRestore,
      });
    } else {
      setModal({
        isVisible: true,
        title: 'Restore Vital?',
        primaryDescription: `Are you sure you want to restore ${parmVital.name} of version ${parmVital.version}? This will create a new draft version of the vital.`,
        primaryBtnName: 'Cancel',
        secondaryBtnName: 'Restore',
        handleClose: () => {
          setModal({ ...modal, isVisible: false });
        },
        handleSubmit: handleRestore,
      });
    }
  };

  return (
    <div className="screen-container">
      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
      <ConfirmModal
        isVisible={modal.isVisible}
        title={modal.title}
        primaryDescription={modal.primaryDescription}
        primaryBtnName={modal.primaryBtnName}
        secondaryBtnName={modal.secondaryBtnName}
        handleClose={modal.handleClose}
        handleSubmit={modal.handleSubmit}
      />
      <ProtocolHeader
        name={parmVital.name}
        status={parmVital.status}
        isBackButton={true}
        page={parmVital.name + ' Archive'}
        type={'protocol'}
        isCopyDescription={user.type === UserType.ADMIN}
        descriptionTitle={user.type === UserType.ADMIN ? 'ID:' : ''}
        description={user.type === UserType.ADMIN ? parmVital.uid : ''}
        handleCancel={handleBack}
        isRestoreButton={true}
        isRestoreActive={!isRestoring && parmVital.status !== 'ACTIVE'}
        bypassActiveStatusCheck={true}
        handleRestore={handleRestorePressed}
      />

      <div className="ketamineContent">
        <div className="KetamineGeneral">
          <h5 className="ketmine-header-text" data-testid="editVital-header">
            Vital Information
          </h5>
          <div className="input-container roundBorder">
            <div
              style={{ display: 'flex', marginTop: '5px' }}
              className="ketamine-general-label"
            >
              <div style={{ marginRight: '10px' }}>Modified By:</div>
              <div style={{ fontWeight: '500' }}>
                {parmVital.modifiedBy
                  ? parmVital.modifiedBy?.firstName +
                    ' ' +
                    parmVital.modifiedBy?.lastName
                  : 'Hinckley Medical'}
              </div>
            </div>
            {parmVital.model?.updatedAt && (
              <div
                style={{ display: 'flex' }}
                className="ketamine-general-label"
              >
                <div style={{ marginRight: '10px' }}>Last Updated:</div>
                <div style={{ fontWeight: '500' }}>
                  {getFormattedDate(parmVital.model.updatedAt, true)}
                </div>
              </div>
            )}
            <div style={{ display: 'flex' }} className="ketamine-general-label">
              <div style={{ marginRight: '10px' }}>Version:</div>
              <div style={{ fontWeight: '500' }}>{parmVital.version}</div>
            </div>
            {user.type === UserType.ADMIN && parmVital.activeID && (
              <div
                style={{ display: 'flex' }}
                className="ketamine-general-label"
              >
                <div style={{ marginRight: '10px' }}>Active ID:</div>
                <div style={{ fontWeight: '500' }}>{parmVital.activeID}</div>
                <CopyToClipboard text={parmVital.activeID} />
              </div>
            )}
          </div>
          <label htmlFor="Name" className="ketamine-general-label">
            Name
          </label>
          <div className="input-container">
            <InputText
              type="text"
              className="form-control-general"
              id="name"
              name="name"
              data-testid="name"
              value={parmVital.name}
              disabled
            />
            <div className="input-border"></div>
          </div>
        </div>
        <div className="KetamineGeneral">
          <h5 className="ketmine-header-text">Vital Options</h5>
          <ViewportList items={parmVital.options}>
            {(item: any, index: any) => (
              <div style={{ cursor: 'pointer' }}>
                <VitalDose dose={item} doseIndex={index} isEditMode={false} />
              </div>
            )}
          </ViewportList>
        </div>
      </div>
    </div>
  );
};

export default VitalProtocolArchivePage;
