import { InputText } from 'primereact/inputtext';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ViewportList } from 'react-viewport-list';
import ProtocolHeader from '../../ProtocolHeader';
import CheckListGroups from './CheckListGroups';
import { Form, ProgressStatus, User } from '../../../../../models';
import {
  getFormattedDate,
  globals,
  removeTypename,
  upgradeVersion,
} from '../../../../_global/common/Utils';
import FormItem from '../../../../../data/model/FormItem';
import { UserType } from '../../../../../models';
import ConfirmModal from '../../../../components/Modal/ConfirmModal';
import {
  CheckListDB,
  createCheckList,
  deleteChecklistItem,
} from '../../../../../data/functions/CheckListDB';
import { ResponseType } from '../../../../../data/AmplifyDB';
import {
  ArchiveItem,
  reloadModelItem,
} from '../../../../../data/functions/ModelDB';
import { Alert, Snackbar } from '@mui/material';
import { DataStore } from 'aws-amplify';

/* 12-31-24 Gagan: Created checklist Archive Page component */
const CheckListArchivePage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  if (
    !location.state?.value ||
    !location.state?.department ||
    !location.state?.database
  ) {
    navigate('/archive');
  }

  const { value, sourceData, archiveData, department, database, activeItem } =
    location.state;

  const user: User = useSelector((state: any) => state?.user);

  const [parmChecklist, setParmChecklist] = useState<FormItem>(value);

  const [activeParmChecklist, setActiveParmChecklist] =
    useState<FormItem>(activeItem);

  // const [isRestoreModal, setIsRestoreModal] = useState(false);
  const [isRestoring, setIsRestoring] = useState(false);
  // const [confirmOverrideDraft, setConfirmOverrideDraft] = useState(false);

  // Check if the most recent version of the vital is a draft
  const [isDraft, setIsDraft] = useState(
    activeParmChecklist.status === ProgressStatus.DRAFT
  );

  const [snackbar, setSnackbar] = useState<any>({
    open: false,
    message: '',
    severity: 'success',
  });

  const [modal, setModal] = useState({
    isVisible: false,
    title: '',
    primaryDescription: '',
    primaryBtnName: '',
    secondaryBtnName: '',
    handleClose: () => {},
    handleSubmit: () => {},
  });

  /* Hotkey detection */
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.ctrlKey && event.shiftKey && event.key === 'O') {
        console.log('ARCHIVE CHECKLIST', parmChecklist);
      }
    };

    // Add event listener
    document.addEventListener('keydown', handleKeyDown);

    // Remove event listener on cleanup
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [parmChecklist]);

  const reloadItem = async () => {
    const response = await reloadModelItem(Form, activeParmChecklist);
    if (response.type === ResponseType.Success) {
      const item = new FormItem(response.data.item);
      const activeItem = response.data.activeItem as Form | null | undefined;
      item.activeItem = activeItem ? new FormItem(activeItem) : null;
      setActiveParmChecklist(item);
    }
  };

  /**
   * Check if the draft exists for the active item
   */
  useEffect(() => {
    reloadItem();
  }, []);

  useEffect(() => {
    if (value) {
      setParmChecklist(value);
    }
  }, [value]);

  const handleBack = useCallback(() => {
    navigate('/archive/list-checklists-details', {
      state: {
        data: sourceData,
        department: department,
        database: database,
        archiveData: archiveData,
        activeItem: activeParmChecklist,
      },
    });
  }, [
    navigate,
    sourceData,
    department,
    database,
    archiveData,
    activeParmChecklist,
  ]);

  const handleRestore = useCallback(async () => {
    setIsRestoring(true);
    setModal({ ...modal, isVisible: false });
    try {
      const oldActiveItem = isDraft
        ? activeParmChecklist.activeItem
        : activeParmChecklist;
      if (isDraft) {
        const response = await deleteChecklistItem(activeParmChecklist, false);
        if (response.type === ResponseType.Failure) {
          setSnackbar({
            open: true,
            message:
              'Failed to delete already existing draft of ' +
              oldActiveItem?.name,
            severity: 'error',
          });
          // Exit the function
          console.error('Failed to delete checklist', response.data);
          setIsRestoring(false);
          setModal({
            ...modal,
            isVisible: false,
          });
          return;
        }
      }

      const updatedEquipment: CheckListDB = {
        name: parmChecklist.name,
        // Remove the typenames from the items and options
        items: removeTypename(
          parmChecklist.items.map((item) => ({
            ...item,
            options: removeTypename(item.options),
          }))
        ),
        departmentID: department.id,
        status: ProgressStatus.DRAFT,
        activeID: oldActiveItem?.uid,
        version: upgradeVersion(oldActiveItem?.version || 'v1.0.0'),
        modifiedBy: user.id,
        createdBy:
          parmChecklist.model && parmChecklist.model.createdBy
            ? parmChecklist.model.createdBy
            : user.id,
      };

      const results = await createCheckList(
        updatedEquipment,
        oldActiveItem as FormItem
      );

      if (results.type === ResponseType.Success) {
        const newDraftChecklist = results.data as FormItem;
        newDraftChecklist.activeItem = oldActiveItem;

        setSnackbar({
          open: true,
          message:
            'Successfully Restored ' +
            newDraftChecklist.name +
            ' with a new DRAFT version ' +
            newDraftChecklist.version,
          severity: 'success',
        });

        // Update sourceData with new draft
        const newSourceData = sourceData.map((item: ArchiveItem) =>
          item.activeItem?.uid === activeParmChecklist.uid
            ? { ...item, activeItem: newDraftChecklist }
            : item
        );

        if (globals.debug)
          console.log('Successfully restored checklist', results.data);

        setModal({
          isVisible: true,
          title: 'Successfully Restored Checklist',
          primaryDescription: `Successfully restored ${newDraftChecklist.name} to version ${newDraftChecklist.version}. Would you like to navigate to the checklist?`,
          primaryBtnName: 'Dismiss',
          secondaryBtnName: 'Navigate',
          handleClose: () => {
            setModal({ ...modal, isVisible: false });
            navigate('/archive/list-checklists-details', {
              state: {
                data: newSourceData,
                department: department,
                database: database,
                archiveData: archiveData,
                activeItem: newDraftChecklist,
              },
            });
          },
          handleSubmit: () => {
            const state = {
              selectedProtocol: null,
              value: newDraftChecklist,
              subValue: null,
              type: 'Checklist',
              editType: 'edit',
              editMode: false,
              page: 'listChecklistPage',
            };
            navigate(`/protocol/edit/edit-protocol`, { state });
          },
        });
      } else if (results.type === ResponseType.Failure) {
        setSnackbar({
          open: true,
          message: 'Failed to restore Checklist.',
          severity: 'error',
        });
      }

      setIsRestoring(false);
    } catch (error) {
      setIsRestoring(false);
      console.error('Restore error:', error);
    }
  }, [
    navigate,
    sourceData,
    department,
    database,
    archiveData,
    activeParmChecklist,
    parmChecklist,
    user,
    isDraft,
    modal,
  ]);

  const handleRestorePressed = async () => {
    if (isDraft) {
      setModal({
        isVisible: true,
        title: 'Override Existing Draft?',
        primaryDescription: `There is already a draft of ${activeParmChecklist.name}  (${activeParmChecklist.version}) which is not yet published. Are you sure you want to override the existing draft and restore ${parmChecklist.name} of version ${parmChecklist.version}?`,
        primaryBtnName: 'Cancel',
        secondaryBtnName: 'Restore',
        handleClose: () => {
          setModal({ ...modal, isVisible: false });
        },
        handleSubmit: handleRestore,
      });
    } else {
      setModal({
        isVisible: true,
        title: 'Restore Checklist?',
        primaryDescription: `Are you sure you want to restore ${parmChecklist.name} of version ${parmChecklist.version}? This will create a new draft version of the checklist.`,
        primaryBtnName: 'Cancel',
        secondaryBtnName: 'Restore',
        handleClose: () => {
          setModal({ ...modal, isVisible: false });
        },
        handleSubmit: handleRestore,
      });
    }
  };

  return (
    <div className="screen-container">
      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
      <ConfirmModal
        isVisible={modal.isVisible}
        title={modal.title}
        primaryDescription={modal.primaryDescription}
        primaryBtnName={modal.primaryBtnName}
        secondaryBtnName={modal.secondaryBtnName}
        handleClose={modal.handleClose}
        handleSubmit={modal.handleSubmit}
      />

      <ProtocolHeader
        name={parmChecklist.name}
        status={parmChecklist.status}
        isBackButton={true}
        page={parmChecklist.name + ' Archive'}
        type={'protocol'}
        isCopyDescription={user.type === UserType.ADMIN}
        descriptionTitle={user.type === UserType.ADMIN ? 'ID:' : ''}
        description={user.type === UserType.ADMIN ? parmChecklist.uid : ''}
        handleCancel={handleBack}
        isRestoreButton={true}
        isRestoreActive={!isRestoring && parmChecklist.status !== 'ACTIVE'}
        bypassActiveStatusCheck={true}
        handleRestore={handleRestorePressed}
      />

      <div className="ketamineContent">
        <div className="KetamineGeneral">
          <h5 className="ketmine-header-text" data-testid="form-header">
            Checklist
          </h5>
          <div className="input-container roundBorder">
            <div
              style={{ display: 'flex', marginTop: '5px' }}
              className="ketamine-general-label"
            >
              <div style={{ marginRight: '10px' }}>Modified By:</div>
              <div style={{ fontWeight: '500' }}>
                {parmChecklist.modifiedBy
                  ? parmChecklist.modifiedBy?.firstName +
                    ' ' +
                    parmChecklist.modifiedBy?.lastName
                  : 'Hinckley Medical'}
              </div>
            </div>
            {parmChecklist.model?.updatedAt && (
              <div
                style={{ display: 'flex' }}
                className="ketamine-general-label"
              >
                <div style={{ marginRight: '10px' }}>Last Updated:</div>
                <div style={{ fontWeight: '500' }}>
                  {getFormattedDate(parmChecklist.model.updatedAt, true)}
                </div>
              </div>
            )}
            <div style={{ display: 'flex' }} className="ketamine-general-label">
              <div style={{ marginRight: '10px' }}>Version:</div>
              <div style={{ fontWeight: '500' }}>{parmChecklist.version}</div>
            </div>
          </div>
          <label htmlFor="Name" className="ketamine-general-label">
            Name<span className="required-field">*</span>
          </label>
          <div className="input-container">
            <InputText
              type="text"
              className="form-control-general"
              id="Name"
              data-testid="name"
              value={parmChecklist.name}
              disabled
            />
            <div className="input-border"></div>
          </div>
        </div>
        <div className="KetamineGeneral">
          <h5 className="ketmine-header-text" data-testid="form-header-right">
            Checklist Groups
          </h5>

          <ViewportList items={parmChecklist.items}>
            {(item: any, index: any) => (
              <div key={index}>
                <CheckListGroups
                  data={item}
                  groupIndex={index}
                  isEdit={false}
                />
              </div>
            )}
          </ViewportList>
        </div>
      </div>
    </div>
  );
};

export default CheckListArchivePage;
