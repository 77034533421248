import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ProtocolHeader from '../../protocol/ProtocolHeader';
import {
  compareVersions,
  getFormattedDate,
} from '../../../_global/common/Utils';
import { FaChevronRight, FaLock } from 'react-icons/fa6';
import EquipmentItem from '../../../../data/model/EquipmentItem';
import { ProgressStatus, User } from '../../../../models';
import Status from '../../../components/ProgressStatus/ProgressStatus';
import DataList, { Header } from '../../database/lists/DataList';

const DEFAULT_USERNAME = 'Hinckley Medical';

const ListArchiveEquipmentDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;

  if (
    !state?.data ||
    !state?.department ||
    !state?.database ||
    !state?.archiveData ||
    !state?.activeItem
  ) {
    navigate('/archive');
  }

  const {
    data: sourceData,
    department,
    database,
    archiveData: equipment,
    activeItem,
  } = state;

  const [equipmentList, setEquipmentList] = useState<EquipmentItem[]>(
    equipment.sort((a: EquipmentItem, b: EquipmentItem) =>
      compareVersions(b.version, a.version)
    )
  );

  const activeParmEquipment: EquipmentItem = useMemo(() => {
    return activeItem;
  }, [activeItem]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.ctrlKey && event.shiftKey && event.key === 'O') {
        console.log('ARCHIVE EQUIPMENT DETAILS', equipment);
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [equipment]);

  const handleItemClick = useCallback(
    (equi: EquipmentItem) => {
      navigate(`/archive/equipment-archive`, {
        state: {
          value: equi,
          sourceData: sourceData,
          archiveData: equipment,
          department: department,
          database: database,
          activeItem: activeParmEquipment,
        },
      });
    },
    [navigate, sourceData, equipment, department, database, activeParmEquipment]
  );

  const handleBack = useCallback(() => {
    navigate('/archive/list-equipment', {
      state: {
        data: sourceData,
        department: department,
        database: database,
      },
    });
  }, [navigate, sourceData, department, database]);

  const handleSortItem = useCallback(
    (key: string, direction: string, a: EquipmentItem, b: EquipmentItem) => {
      if (key === 'version') {
        // Remove 'v' prefix if present and compare version numbers
        const ver1 = a.version.replace(/^v/, '').split('.').map(Number);
        const ver2 = b.version.replace(/^v/, '').split('.').map(Number);

        for (let i = 0; i < Math.max(ver1.length, ver2.length); i++) {
          const num1 = ver1[i] || 0;
          const num2 = ver2[i] || 0;
          if (num1 !== num2) {
            return direction === 'asc' ? num1 - num2 : num2 - num1;
          }
        }
        return 0;
      } else if (key === 'name') {
        return direction === 'asc'
          ? a.name.localeCompare(b.name)
          : b.name.localeCompare(a.name);
      } else if (key === 'archived_date') {
        const aDate = new Date(a.modifiedAt || Date.now());
        const bDate = new Date(b.modifiedAt || Date.now());
        return direction === 'asc'
          ? aDate.getTime() - bDate.getTime()
          : bDate.getTime() - aDate.getTime();
      } else if (key === 'archived_by') {
        const aUserId = a.model?.modifiedBy || a.model?.createdBy;
        const bUserId = b.model?.modifiedBy || b.model?.createdBy;

        const aUser = aUserId
          ? database.users.find((user: User) => user.id === aUserId)
          : null;
        const bUser = bUserId
          ? database.users.find((user: User) => user.id === bUserId)
          : null;

        const aName = aUser
          ? `${aUser.firstName} ${aUser.lastName}`
          : DEFAULT_USERNAME;
        const bName = bUser
          ? `${bUser.firstName} ${bUser.lastName}`
          : DEFAULT_USERNAME;

        return direction === 'asc'
          ? aName.localeCompare(bName)
          : bName.localeCompare(aName);
      } else if (key === 'sizes') {
        return direction === 'asc'
          ? a.options.length - b.options.length
          : b.options.length - a.options.length;
      }
      return 0;
    },
    [database.users]
  );

  const headers: Header<EquipmentItem>[] = useMemo(
    () => [
      {
        key: 'version',
        name: 'Version',
        sortable: true,
        flex: 1,
        render: (item: EquipmentItem) => item.version,
      },
      {
        key: 'name',
        name: 'Name',
        sortable: true,
        flex: 2,
        render: (item: EquipmentItem) => (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {!(
              item.departmentID === department.id &&
              item.status !== 'DEACTIVATED'
            ) && (
              <div style={{ marginRight: '8px' }}>
                <FaLock className="table-icon" color="#A3A3A3" />
              </div>
            )}
            {item.name}
            <Status status={item.status} style={{ marginLeft: '8px' }} />
          </div>
        ),
      },
      {
        key: 'archived_date',
        name: 'Archived Date',
        sortable: true,
        flex: 1,
        render: (item: EquipmentItem) =>
          getFormattedDate(new Date(item.modifiedAt || Date.now()), true),
      },
      {
        key: 'archived_by',
        name: 'Archived By',
        sortable: true,
        flex: 1,
        render: (item: EquipmentItem) => {
          const userId = item.model?.modifiedBy || item.model?.createdBy;
          const user = userId
            ? database.users.find((user: User) => user.id === userId)
            : null;
          return user ? `${user.firstName} ${user.lastName}` : DEFAULT_USERNAME;
        },
      },
      {
        key: 'sizes',
        name: 'Sizes',
        sortable: true,
        flex: 1,
        render: (item: EquipmentItem) => (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
              paddingRight: '15px',
            }}
          >
            <span>{item.options.length}</span>
            <FaChevronRight className="icon-normal" style={{ margin: '4px' }} />
          </div>
        ),
      },
    ],
    [department.id, database.users]
  );

  return (
    <div className="screen-container">
      <ProtocolHeader
        isBackButton={true}
        handleCancel={handleBack}
        name={`${activeParmEquipment.activeItem?.name || activeParmEquipment.name}: ${equipmentList.length - 1} archive${equipmentList.length - 1 === 1 ? '' : 's'}`}
        page={'Equipment Archive'}
        type="protocol"
      />

      <DataList<EquipmentItem, never>
        items={equipmentList}
        headers={headers}
        onItemClick={handleItemClick}
        searchPlaceholder="Search Equipment..."
        sortItem={handleSortItem}
      />
    </div>
  );
};

export default ListArchiveEquipmentDetails;
