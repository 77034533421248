// Note: EditDB for ElectricalShock
// Date: 01/15/2024
// Author: Guruprasad Venkatraman

import { PatientAgeGroup, ProgressStatus } from '../../API';
import { DataStore, graphqlOperation } from 'aws-amplify';

import {
  ResponseType,
  Response,
  DatabaseResponse,
  mapModelItems,
  executeQuery,
  executeSingleQuery,
  mapModelSubItems,
  BatchQuery,
} from '../AmplifyDB';
import ElectricalItem, { cloneElectrical } from '../model/ElectricalItem';
import DepartmentItem from '../model/DepartmentItem';
import ProtocolItem from '../model/ProtocolItem';
import {
  ProtocolJSON,
  createProtocol,
  getProtocolByID,
  validatePointerID,
} from './ProtocolDB';
import {
  findItemByID,
  compareVersions,
  getActiveID,
  getModelItem,
  globals,
  upgradeVersion,
} from '../../ui/_global/common/Utils';
import ElectricalSubItem, {
  cloneElectricalSubItem,
} from '../model/ElectricalSubItem';
import {
  electricalDosesByDepartmentID,
  electricalDosesByElectricalID,
  electricalShocksByDepartmentID,
  getElectricalDose,
  getElectricalShock,
  listElectricalDoses,
  listElectricalShocks,
  electricalDosesByProtocolID,
} from '../../graphql/queries';
import DraftChangeItem, { DraftChangeType } from '../model/DraftChangeItem';
import {
  ElectricalDose,
  ElectricalShock,
  ElectricalShockOption,
  ElectricalShockRange,
  ModelMetaData,
  User,
} from '../../models';
import { checkIdUpdateDraftChange, Draft } from '../AmplifyVersion';
import { DraftChangeJSON, updateDraftChangeItem } from './ReviewalDB';
import CPRItem from '../model/CPRItem';
import {
  ArchiveItem,
  findUsersForModelItems,
  setAllArchiveItemsToNewActiveID,
  setAllDosesToNewParentID,
} from './ModelDB';
import {
  updateElectricalDose,
  updateElectricalShock,
} from '../../graphql/mutations';
export type ElectricalJSON = {
  title: string;
  options: ElectricalShockOption[];
  rangeLow: number;
  rangeHigh: number;
  departmentID: string;
  instruction?: string | null | undefined;
  rxNormCode?: string | null | undefined;
  warning?: string;
  note?: string;
  taggedProtocols?: ProtocolItem[];
  modifiedBy?: string;
  createdBy: string;
  status: ProgressStatus | keyof typeof ProgressStatus;
  activeID: string | null | undefined;
  overrideID?: string | null | undefined;
  version: string | null | undefined;
  metaData?: ModelMetaData | null | undefined;
};

export type ElectricalDoseJSON = {
  departmentID: string;
  electrical: ElectricalItem;
  cpr?: CPRItem | null;
  protocol?: ProtocolItem | null;

  index: number;
  basis: string;

  rangeLow: number;
  rangeHigh: number;
  ageLow?: number | null | undefined;
  ageHigh?: number | null | undefined;
  ageGroup?: PatientAgeGroup | null | undefined;
  repeatTime?: number | null | undefined;

  title?: string;
  warning?: string;
  instruction?: string;
  note?: string;

  maxDose?: string | null | undefined;
  minDose?: string | null | undefined;
  maxTotalDose?: string | null | undefined;
  calcMax?: string | null | undefined;
  calcMin?: string | null | undefined;

  createdBy: string;
  modifiedBy?: string;

  status: ProgressStatus | 'DRAFT' | 'ACTIVE' | 'ARCHIVE' | 'DELETED';
  activeID: string | null | undefined;
  overrideID?: string | null | undefined;
  version: string | null | undefined;
  createdAt: Date;
};

/**
 * Create a new electrical in the database and choose the version
 * @param electrical ElectricalJSON JSON format
 * @returns The successful electricalItem or the error
 */
export const createElectrical = async (
  electrical: ElectricalJSON | ElectricalItem,
  previousItem?: ElectricalItem
): Promise<Response> => {
  try {
    let json: ElectricalJSON;
    if (electrical instanceof ElectricalItem) {
      let elec = electrical as ElectricalItem;
      let protocols: ProtocolItem[] = [];
      for (let i = 0; i < elec.subItems.length; i++) {
        if (!protocols.includes(elec.subItems[i].parentProtocol))
          protocols.push(elec.subItems[i].parentProtocol);
      }
      json = {
        title: elec.name,
        rangeLow: elec.rangeLow,
        rangeHigh: elec.rangeHigh,
        instruction: elec.model.instruction ? elec.model.instruction : '',
        warning: elec.model.warning ? elec.model.warning : '',
        note: elec.model.note ? elec.model.note : '',
        status: elec.status,
        activeID: elec.activeID,
        overrideID: elec.overrideID,
        departmentID: elec.departmentID,
        taggedProtocols: protocols,
        version: elec.version != null ? elec.version : 'v1.0.0',
        options: elec.options != null ? elec.options : [],
        createdBy: elec.model.createdBy ? elec.model.createdBy : '',
        modifiedBy: elec.modifiedBy
          ? elec.modifiedBy.id
          : elec.model.modifiedBy || undefined,
      };
    } else json = electrical as ElectricalJSON;

    /* 
			1. Creating a DRAFT the first time
			2. Creating a DRAFT from an ACTIVE version
			3. Updating a DRAFT from a DRAFT version
			4. Creating a ARCHIVE from an ACTIVE version
		*/

    let e: ElectricalShock;

    /* Use Case 3: Updating a current DRAFT version */
    if (
      previousItem &&
      previousItem.status.includes('DRAFT') &&
      json.status.includes('DRAFT')
    ) {
      let dbElec = await DataStore.query(ElectricalShock, previousItem.uid);
      if (dbElec == null) {
        return {
          type: ResponseType.Failure,
          data: 'The DRAFT Electrical does not exist could not update',
        };
      }

      if (globals.debug)
        console.log('Updating DRAFT Electrical: ', dbElec, json);

      e = await DataStore.save(
        ElectricalShock.copyOf(dbElec, (updated) => {
          updated.title = json.title;
          updated.options = json.options;
          updated.rangeLow = json.rangeLow;
          updated.rangeHigh = json.rangeHigh;
          updated.instruction = json.instruction;
          updated.warning = json.warning;
          updated.note = json.note;
          updated.modifiedBy = json.modifiedBy;
        })
      );
    } else {
      /* Use Case 1, 2, & 4: Creating a DRAFT the first time */
      if (globals.debug) console.log('Creating DRAFT Electrical: ', json);
      e = await DataStore.save(
        new ElectricalShock({
          title: json.title,
          departmentID: json.departmentID,
          status: json.status,
          activeID: json.activeID,
          overrideID: json.overrideID,
          version: json.version,
          options: json.options,
          rangeLow: json.rangeLow,
          rangeHigh: json.rangeHigh,
          instruction: json.instruction,
          warning: json.warning,
          note: json.note,
          createdBy: json.createdBy,
          modifiedBy: json.modifiedBy,
        })
      );
    }

    let elecItem = new ElectricalItem(e);

    /* Validate the protocols point to the electrical --TODO need to update this for if there is an ACTIVE ID */
    if (json.taggedProtocols != null) {
      for (let i = 0; i < json.taggedProtocols.length; i++) {
        let protocol = json.taggedProtocols[i];
        let elecID: string | null = getActiveID(elecItem);
        if (elecID == null) {
          return {
            type: ResponseType.Failure,
            data:
              'The electrical does not have a proper version ID ' +
              elecItem.uid +
              ' ' +
              elecItem.activeID +
              ' ' +
              elecItem.status,
          };
        }
        let result = await validatePointerID(
          protocol,
          elecID,
          json.modifiedBy,
          'Electrical'
        );
        if (result.type === ResponseType.Failure) {
          return {
            type: ResponseType.Failure,
            data: result.data,
          };
        }
      }
    }
    return {
      type: ResponseType.Success,
      data: elecItem,
    };
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

/**
 * Create a new medication DOSE in the database and choose the version
 * @param medication ElectricalDoseJSON JSON format or Model ElectricalSubItem
 * @returns The successful ElectricalSubItem or the error
 */
export const createElectricalDoseItem = async (
  medication: ElectricalDoseJSON | ElectricalSubItem,
  previousItem?: ElectricalSubItem
): Promise<Response> => {
  try {
    let json: ElectricalDoseJSON;
    if (
      medication instanceof ElectricalSubItem ||
      (medication as any).TAG === 'ElectricalSubItem'
    ) {
      let medItem = medication as ElectricalSubItem;
      json = {
        departmentID: medItem.departmentID,
        electrical: medItem.parent,
        protocol: medItem.parentProtocol,
        cpr: medItem.parentCPR,
        basis: medItem.fullBasis,
        index: medItem.index,
        rangeLow: medItem.rangeLow,
        rangeHigh: medItem.rangeHigh,
        ageLow: medItem.ageLow ? medItem.ageLow.ageValue : null,
        ageHigh: medItem.ageHigh ? medItem.ageHigh.ageValue : null,
        ageGroup: medItem.ageGroup,
        repeatTime: medItem.repeatTimeSec,
        title: medItem.title,
        warning: medItem.warning,
        instruction: medItem.instruction,
        note: medItem.note,
        maxDose: medItem.fullMaxDose,
        minDose: medItem.fullMinDose,
        maxTotalDose: medItem.fullMaxTotalDose,
        calcMax: medItem.model.calcMax,
        calcMin: medItem.model.calcMin,
        createdBy: medItem.model.createdBy,
        modifiedBy: medItem.modifiedBy
          ? medItem.modifiedBy.id
          : medItem.model.modifiedBy || undefined,
        status: medItem.status,
        activeID: medItem.activeID,
        overrideID: medItem.overrideID,
        version: medItem.version,
        createdAt: new Date(medItem.model.createdAt),
      };
    } else json = medication as ElectricalDoseJSON;

    /* 
        1. Creating a DRAFT the first time
        2. Creating a DRAFT from an ACTIVE version
        3. Updating a DRAFT from a DRAFT version
        4. Creating a ARCHIVE from an ACTIVE version
    */
    let m: ElectricalDose;
    let cprID: string | null = json.cpr ? getActiveID(json.cpr) : null;
    let protID: string | null = cprID
      ? null
      : json.protocol
        ? getActiveID(json.protocol)
        : null;

    if (protID == null && cprID == null) {
      return {
        type: ResponseType.Failure,
        data:
          'The protocol or CPR does not have a proper version ID ' +
          json.protocol,
      };
    }
    let elecID = getActiveID(json.electrical) ?? '';
    if (elecID == null) {
      return {
        type: ResponseType.Failure,
        data:
          'The electrical does not have a proper version ID 3' +
          json.electrical,
      };
    }

    /* Use Case 3: Updating a current DRAFT version */
    if (
      previousItem &&
      previousItem.status === ProgressStatus.DRAFT &&
      json.status === ProgressStatus.DRAFT
    ) {
      let dbMed = await DataStore.query(ElectricalDose, previousItem.uid);
      if (dbMed == null) {
        return {
          type: ResponseType.Failure,
          data: 'The DRAFT Electrical Dose does not exist could not update',
        };
      }

      m = await DataStore.save(
        ElectricalDose.copyOf(dbMed, (updated) => {
          updated.cprAssistID = cprID;
          updated.protocolID = protID;
          updated.index = json.index;
          updated.basis = json.basis;
          updated.rangeLow = json.rangeLow;
          updated.rangeHigh = json.rangeHigh;
          updated.ageLow = json.ageLow;
          updated.ageHigh = json.ageHigh;
          updated.ageGroup = json.ageGroup;
          updated.repeatTime = json.repeatTime
            ? json.repeatTime + ''
            : undefined;
          updated.title = json.title;
          updated.warning = json.warning;
          updated.instruction = json.instruction;
          updated.note = json.note;

          updated.maxDose = json.maxDose;
          updated.minDose = json.minDose;
          updated.maxTotalDose = json.maxTotalDose;
          updated.calcMax = json.calcMax;
          updated.calcMin = json.calcMin;

          updated.status = json.status;
          updated.activeID = json.activeID;

          updated.version = json.version ? json.version : 'v1.0.0';
          updated.modifiedBy = json.modifiedBy;
        })
      );
    } else {
      /* Use Case 1, 2, & 4: Creating a DRAFT the first time */
      m = await DataStore.save(
        new ElectricalDose({
          departmentID: json.departmentID,
          electricalID: elecID,
          cprAssistID: cprID,
          protocolID: protID,
          index: json.index,
          basis: json.basis,
          rangeLow: json.rangeLow,
          rangeHigh: json.rangeHigh,
          ageLow: json.ageLow,
          ageHigh: json.ageHigh,
          ageGroup: json.ageGroup,
          repeatTime: json.repeatTime ? json.repeatTime + '' : undefined,
          title: json.title,
          warning: json.warning,
          instruction: json.instruction,
          note: json.note,
          maxDose: json.maxDose,
          minDose: json.minDose,
          maxTotalDose: json.maxTotalDose,
          calcMax: json.calcMax,
          calcMin: json.calcMin,
          createdBy: json.createdBy,
          modifiedBy: json.modifiedBy,
          status: json.status,
          activeID: json.activeID,
          overrideID: json.overrideID,
          version: json.version ? json.version : 'v1.0.0',
          createdAt: new Date().toISOString(),
        })
      );
    }

    let parent = json.cpr ? json.cpr : json.protocol;
    let medItem = new ElectricalSubItem(json.electrical, parent, m);
    if (json.cpr) medItem.parentCPR = json.cpr;

    return {
      type: ResponseType.Success,
      data: medItem,
    };
  } catch (e) {
    if (globals.debug)
      console.log('ERROR CREATING ELECTRICAL DOSE:', e, medication);
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

export const fetchElectrical = async (
  dep: DepartmentItem,
  db?: DatabaseResponse,
  useDataStore: boolean = true,
  waitForUsers: boolean = false
): Promise<Response> => {
  try {
    let depIDs = [dep.id];
    if (dep.parentDep) depIDs.push(dep.parentDep.id);
    if (dep.parentDep?.parentDep) depIDs.push(dep.parentDep.parentDep.id);

    let electricalList: ElectricalShock[];
    if (useDataStore) {
      electricalList = await DataStore.query(ElectricalShock, (d) =>
        d.and((d) => [
          d.or((d) => depIDs.map((id) => d.departmentID.eq(id))),
          d.and((d) => [d.status.ne('ARCHIVE'), d.status.ne('DELETED')]),
        ])
      );
    } else {
      electricalList = await executeQuery(listElectricalShocks, {
        filter: {
          and: [
            { or: depIDs.map((id) => ({ departmentID: { eq: id } })) },
            { status: { ne: 'ARCHIVE' } },
            { status: { ne: 'DELETED' } },
          ],
        },
      });
    }
    let electrical: ElectricalItem[] = [];
    // let promises: Promise<User | null>[] = [];
    for (let i = 0; i < electricalList.length; i++) {
      let elec = new ElectricalItem(electricalList[i]);
      // promises.push(elec.findUser());
      /* Take out the active version if there is one */
      mapModelItems(elec, electrical, elec.status, dep);
    }
    // if (waitForUsers) await Promise.all(promises);
    if (waitForUsers) await findUsersForModelItems(electrical);
    electrical.sort((a, b) => a.getName().localeCompare(b.getName()));

    if (db) {
      let promises: any[] = [];
      for (let i = 0; i < electrical.length; i++)
        promises.push(
          fetchElectricalDosesForElectrical(db, dep, electrical[i])
        );
      await Promise.all(promises);
    }
    return {
      type: ResponseType.Success,
      data: electrical,
    };
  } catch (error) {
    console.error('Error fetching electrical:', error);
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const fetchElectricalDoses = async (
  dep: DepartmentItem,
  useDataStore: boolean = true
): Promise<Response> => {
  try {
    let depIDs = [dep.id];
    if (dep.parentDep) depIDs.push(dep.parentDep.id);
    if (dep.parentDep?.parentDep) depIDs.push(dep.parentDep.parentDep.id);

    let electricalList;
    if (useDataStore) {
      electricalList = await DataStore.query(ElectricalDose, (m) =>
        m.and((m) => [
          m.or((m) => depIDs.map((id) => m.departmentID.eq(id))),
          m.and((m) => [m.status.ne('ARCHIVE'), m.status.ne('DELETED')]),
        ])
      );
    } else {
      electricalList = await executeQuery(listElectricalDoses, {
        filter: {
          and: [
            { or: depIDs.map((id) => ({ departmentID: { eq: id } })) },
            { status: { ne: 'ARCHIVE' } },
            { status: { ne: 'DELETED' } },
          ],
        },
      });
    }
    for (let i = 0; i < electricalList.length; i++) {
      let electrical = electricalList[i];
      if (
        electrical.status.includes('DRAFT') &&
        electrical.departmentID !== dep.id
      )
        electricalList.splice(i, 1);
    }

    return {
      type: ResponseType.Success,
      data: electricalList,
    };
  } catch (error) {
    console.error('Error fetching electricalList:', error);
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const fetchElectricalDosesForElectrical = async (
  db: DatabaseResponse,
  dep: DepartmentItem,
  electrical: ElectricalItem,
  useDataStore: boolean = true
): Promise<Response> => {
  try {
    let depIDs = [dep.id];
    if (dep.parentDep) depIDs.push(dep.parentDep.id);
    if (dep.parentDep?.parentDep) depIDs.push(dep.parentDep.parentDep.id);

    let electricalID = getActiveID(electrical);
    if (!electricalID) {
      return {
        type: ResponseType.Failure,
        data:
          'The electrical does not have a proper version ID ' +
          electrical.uid +
          ' ' +
          electrical.activeID +
          ' ' +
          electrical.status,
      };
    }

    let electricalList;
    if (useDataStore) {
      electricalList = await DataStore.query(ElectricalDose, (m) =>
        m.and((m) => [
          m.electricalID.eq(electricalID as string),
          m.or((m) => depIDs.map((id) => m.departmentID.eq(id))),
          m.and((m) => [m.status.ne('ARCHIVE'), m.status.ne('DELETED')]),
        ])
      );
    } else {
      electricalList = await executeQuery(electricalDosesByElectricalID, {
        electricalID: electricalID,
        filter: {
          and: [
            { or: depIDs.map((id) => ({ departmentID: { eq: id } })) },
            { status: { ne: 'ARCHIVE' } },
            { status: { ne: 'DELETED' } },
          ],
        },
      });
    }
    let electricalDoses: ElectricalSubItem[] = [];
    for (let i = 0; i < electricalList.length; i++) {
      let electricalDose: ElectricalDose = electricalList[i];
      if (
        (electricalDose.status.includes('DRAFT') &&
          electricalDose.departmentID !== dep.id) ||
        electricalDose.cprAssistID != null
      )
        continue;
      else {
        let parentItem;
        if (electricalDose.cprAssistID && db.cprModel) {
          parentItem = getModelItem(electricalDose.cprAssistID, db.cprModel);
        } else if (electricalDose.protocolID != null) {
          parentItem = db.protocols.find((p) =>
            getModelItem(electricalDose.protocolID as string, p)
          );
        }
        if (parentItem) {
          let elec = new ElectricalSubItem(
            electrical,
            parentItem,
            electricalDose
          );
          mapModelSubItems(elec, electricalDoses, elec.status, dep);
        }
      }
    }

    electricalDoses.sort((a, b) => a.index - b.index);
    electrical.subItems = electricalDoses;

    return {
      type: ResponseType.Success,
      data: electricalDoses,
    };
  } catch (error) {
    console.error('Error fetching electricalList:', error);
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

/**
 * Duplicate the medication doses from the one protocol to another protocol
 * @param department The department to duplicate the medication doses from
 * @param fromProtocol The protocol to duplicate the medication doses from
 * @param toProtocol The protocol to duplicate the medication doses to
 * @param user The user that is duplicating the medication doses
 * @param electrical The electrical to duplicate the doses for
 * @returns Success if the medication doses were duplicated or Failure if there was an critical error
 *  - Success data: { successes: MedicationSubItem[], errors: any[] }
 */
export const duplicateProtocolElectricalDoses = (
  department: DepartmentItem,
  fromProtocol: ProtocolItem,
  toProtocol: ProtocolItem,
  user: User,
  electrical?: ElectricalItem
): Promise<Response> => {
  return new Promise(async (resolve, reject) => {
    try {
      let doses: ElectricalDose[] = [];
      let protocolID = getActiveID(fromProtocol);
      if (protocolID == null) {
        return {
          type: ResponseType.Failure,
          data: 'The protocol does not have a proper version ID 6',
        };
      }
      if (electrical) {
        let elecID = getActiveID(electrical);
        if (elecID == null) {
          return {
            type: ResponseType.Failure,
            data: 'The medication does not have a proper version ID 5',
          };
        }
        doses = await DataStore.query(ElectricalDose, (c) =>
          c.and((c) => [
            c.departmentID.eq(department.id),
            c.protocolID.eq(protocolID),
            c.electricalID.eq(elecID as string),
            c.status.eq(ProgressStatus.ACTIVE),
          ])
        );
      } else {
        doses = await DataStore.query(ElectricalDose, (c) =>
          c.and((c) => [
            c.departmentID.eq(department.id),
            c.protocolID.eq(protocolID),
            c.status.eq(ProgressStatus.ACTIVE),
          ])
        );
      }
      console.log('Duplicating Electrical Doses', doses);
      let promiseFunctions: (() => Promise<Response>)[] = [];
      for (let i = 0; i < doses.length; i++) {
        let elecItem: ElectricalItem | undefined = electrical;
        if (elecItem == null) {
          let elec = await DataStore.query(
            ElectricalShock,
            doses[i].electricalID
          );
          if (elec == null) continue;
          elecItem = new ElectricalItem(elec);
        }
        console.log('Duplicating Electrical Dose', elecItem.name);
        let subItem = new ElectricalSubItem(elecItem, fromProtocol, doses[i]);
        promiseFunctions.push(() =>
          duplicateElectricalDose(department, subItem, user, toProtocol)
        );
      }
      console.log('Duplicating Electrical Doses', promiseFunctions);
      let responses: Response[] = await BatchQuery(promiseFunctions).catch(
        (e) => {
          console.error('Error duplicating medication doses:', e);
          return [];
        }
      );

      console.log('Responses', responses);
      /* Check if any of the responses are failures */
      let successes = responses.filter((r) => r.type === ResponseType.Success);
      let errors = responses.filter((r) => r.type === ResponseType.Failure);

      resolve({
        type: ResponseType.Success,
        data: {
          successes: successes.map((r) => r.data),
          errors: errors.map((r) => r.data),
        },
      });
    } catch (e) {
      console.error('Error duplicating medication doses:', e);
      reject(e);
    }
  });
};

/**
 * Duplicate the electrical doses from the one protocol to another protocol
 * @param department The department to duplicate the electrical doses from
 * @param dose The electrical dose to duplicate
 * @param user The user that is duplicating the electrical doses
 * @param protocolItem The protocol that the dose is being duplicated to
 * @returns Success if the electrical doses were duplicated or Failure if there was an critical error
 *  - Success data: { successes: ElectricalSubItem[], errors: any[] }
 */
export const duplicateElectricalDose = async (
  department: DepartmentItem,
  dose: ElectricalSubItem,
  user: User,
  protocolItem?: ProtocolItem
): Promise<Response> => {
  try {
    /* Get the ID of the protocol to map to the new protocol */
    let protID = protocolItem
      ? protocolItem.status === ProgressStatus.DRAFT && protocolItem.activeID
        ? protocolItem.activeID
        : protocolItem.uid
      : dose.parentProtocol.status === ProgressStatus.DRAFT &&
          dose.parentProtocol.activeID != null
        ? dose.parentProtocol.activeID
        : dose.parentProtocol.uid;

    if (protID == null) {
      return {
        type: ResponseType.Failure,
        data:
          'The protocol does not have a proper version ID ' +
          dose.parentProtocol +
          ' ' +
          protID,
      };
    }

    let clone = cloneElectricalSubItem(dose);
    clone.parentProtocol = protocolItem ? protocolItem : dose.parentProtocol;
    clone.departmentID = department.id;
    clone.status = ProgressStatus.DRAFT;
    clone.activeID = null;
    clone.version = 'v1.0.0';
    clone.modifiedBy = user;

    let result = await createElectricalDoseItem(clone);
    if (result.type === ResponseType.Failure) {
      return {
        type: ResponseType.Failure,
        data: result.data,
      };
    }

    if (globals.debug)
      console.log('Successfully duplicated electrical Dose', result.data);
    return {
      type: ResponseType.Success,
      data: result.data as ElectricalSubItem,
    };
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

/**
 * Check if the protocol is already pointing at the electrical
 *    - Otherwise add the electrical to the protocol and create a new protocol draft
 * @param electrical The electrical o check if it is paired to the protocol
 * @param protocol The protocol to check if it is paired to the electrical
 * @param modifiedBy The user that modified the protocol
 * @returns Success if the electrical is paired with the protocol and returns a ElectricalProtocol or Failure if there was an error
 */
export const validateElectricalProtocolPairing = async (
  electrical: ElectricalItem,
  protocol: ProtocolItem,
  modifiedBy: User
): Promise<Response> => {
  try {
    if (electrical.model == null) {
      return {
        type: ResponseType.Failure,
        data: 'The electrical does not have a database object could be an infusion item',
      };
    }
    let electricalOption: ElectricalShockOption;
    let elecID: string | null = getActiveID(electrical);
    let pairedID = protocol.model.electricalIDs?.find((id) => id === elecID);

    if (elecID == null) {
      return {
        type: ResponseType.Failure,
        data:
          'The electrical does not have a proper version ID ' +
          electrical.uid +
          ' ' +
          electrical.activeID +
          ' ' +
          electrical.status,
      };
    }

    /* Create a new protocol draft if the electrical is not paired with the protocol */
    if (pairedID == null) {
      let elecIDs = protocol.model.electricalIDs
        ? protocol.model.electricalIDs
        : [];
      elecIDs.push(elecID);

      let draftProtocol: ProtocolJSON = {
        departmentID: protocol.parent.departmentID,
        name: protocol.name,
        nickname: protocol.nickname,
        index: protocol.index,
        rangeLow: protocol.rangeLow,
        rangeHigh: protocol.rangeHigh,
        parentCategory: protocol.parent,
        pdfID: protocol.pdfUrl,
        pairedDepsIDs: protocol.pairedDepIDs ?? [],
        pairedProtocols: protocol.pairedProtocols.map((p) => p.uid),
        medications: protocol.medications.map((m) => m.uid),
        infusions: protocol.infusions.map((i) => i.uid),
        equipment: protocol.equipment.map((e) => e.uid),
        forms: protocol.forms.map((f) => f.uid),
        electrical: elecIDs,
        createdBy: protocol.model.createdBy ? protocol.model.createdBy : '',
        modifiedBy: modifiedBy ? modifiedBy.id : undefined,

        status: ProgressStatus.DRAFT,
        activeID: protocol.uid,
        version: upgradeVersion(protocol.version),
        pdfVersion: protocol.pdfVersion,
        isPublic: protocol.isPublic,
        isRestrictive: protocol.isRestrictive,
        keychainID: protocol.keychainID,
        color: protocol.color,
      };

      let result: Response = await createProtocol(draftProtocol, protocol);

      if (result.type === ResponseType.Failure) {
        return {
          type: ResponseType.Failure,
          data: result.data,
        };
      }

      electricalOption = new ElectricalShockOption({
        protocolID: protocol.uid,
        ranges: [],
      });
    } else {
      /* Find the electrical protocol */
      let protID =
        protocol.status === ProgressStatus.ACTIVE
          ? protocol.uid
          : protocol.activeID;
      let eo = electrical.model.options.find((p) => p.protocolID === protID);
      if (eo == null) {
        return {
          type: ResponseType.Failure,
          data: 'The electrical protocol does not exist',
        };
      }
      electricalOption = eo;
    }

    return {
      type: ResponseType.Success,
      data: electricalOption,
    };
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

/**
 * This function will publish the electrical to the database
 *    1. Create a new ARCHEIVED electrical based on the current ACTIVE electrical
 *    2. Update the ACTIVE electrical with the new information
 *    3. Delete the DRAFT electrical
 * @param draftElectricalItem The electrical to publish
 */

export const publishElectrical = async (
  draftElectricalItem: ElectricalItem,
  draftChangeItem?: DraftChangeItem
): Promise<Response> => {
  try {
    /* Base Case 1 -- check if the electrical is configured correctly as a draft version */
    if (draftElectricalItem.status !== ProgressStatus.DRAFT) {
      return {
        type: ResponseType.Failure,
        data: 'The electrical is not a draft version',
      };
    }

    let dbElec = await DataStore.query(
      ElectricalShock,
      draftElectricalItem.uid
    );
    if (dbElec == null) {
      return {
        type: ResponseType.Failure,
        data: 'The DRAFT Electrical does not exist could not update',
      };
    }

    let activeElectrical: ElectricalShock;

    /* Use case 1: Creating the FIRST active version */
    if (draftElectricalItem.activeID == null) {
      /* This is required because if it is unfullfilled then it will delete the otehr items */
      /* Update the draft electrical to be active */
      activeElectrical = await DataStore.save(
        ElectricalShock.copyOf(dbElec, (updated) => {
          updated.status = ProgressStatus.ACTIVE;
        })
      );
    } else {
      /* Use case 2: Upgrading a active version */
      /* Step 1. Fetch the active electrical item */
      let id: string = draftElectricalItem.activeID;
      let curElectrical = await DataStore.query(ElectricalShock, id);

      /* Base Case 3 -- check if the active electrical exists */
      if (curElectrical == null) {
        return {
          type: ResponseType.Failure,
          data: 'The active electrical does not exist',
        };
      }

      let archive = new ElectricalItem(curElectrical);
      archive.status = ProgressStatus.ARCHIVE;
      archive.activeID = curElectrical.id;

      // 2. Create a new ARCHEIVED electrical based on the current ACTIVE electrical
      let archiveResult: Response = await createElectrical(archive);
      if (archiveResult.type === ResponseType.Failure) return archiveResult;
      archive = archiveResult.data as ElectricalItem;

      // 2. Update the ACTIVE electrical with the new information
      activeElectrical = await DataStore.save(
        ElectricalShock.copyOf(curElectrical, (updated) => {
          updated.title = draftElectricalItem.name;
          updated.options = draftElectricalItem.options;
          updated.rangeLow = draftElectricalItem.rangeLow;
          updated.rangeHigh = draftElectricalItem.rangeHigh;
          updated.instruction = draftElectricalItem.model.instruction;
          updated.warning = draftElectricalItem.model.warning;
          updated.note = draftElectricalItem.model.note;

          updated.createdBy = draftElectricalItem.model.createdBy;
          updated.modifiedBy = draftElectricalItem.model.modifiedBy;

          updated.status = ProgressStatus.ACTIVE;
          updated.activeID = null;
          updated.overrideID = draftElectricalItem.overrideID;
          updated.version = draftElectricalItem.version;
        })
      );

      // 3. Delete the DRAFT electrical
      let draftElectrical = await DataStore.delete(
        ElectricalShock,
        draftElectricalItem.uid
      );
      if (draftElectrical == null) {
        return {
          type: ResponseType.Failure,
          data: 'The draft electrical does not exist',
        };
      }

      // 4. Query if there any closed draft changes with the actvie model item
      checkIdUpdateDraftChange(activeElectrical.id, archive.uid);

      // 5. If there is a draftChangeItem then update the changeID to the new active category and the previousID to the archeived category
      if (draftChangeItem) {
        let new_dc: DraftChangeJSON = {
          previousDraftChange: draftChangeItem,
          changeItem: activeElectrical.id,
          changeType: draftChangeItem.changeType,
          previousItem: archive.uid,
          isClosed: true,
        };

        updateDraftChangeItem(new_dc).then((result) => {
          if (result == null) {
            return {
              type: ResponseType.Failure,
              data: 'The draft change item did not update correctly',
            };
          }
        });
      }
    }

    let elecItem = new ElectricalItem(activeElectrical);
    return {
      type: ResponseType.Success,
      data: elecItem,
    };
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

/**
 * This function will publish the electrical to the database
 *    1. Create a new ARCHEIVED electrical based on the current ACTIVE electrical
 *    2. Update the ACTIVE electrical with the new information
 *    3. Delete the DRAFT electrical
 * @param draftElectricalItem The electrical to publish
 */
export const publishElectricalDoses = async (
  draftElectricalItem: ElectricalSubItem,
  draftChangeItem?: DraftChangeItem
): Promise<Response> => {
  try {
    if (globals.debug)
      console.log('Publishing Electrical Dose', draftElectricalItem);
    /* Base Case 1 -- check if the electrical is configured correctly as a draft version */
    if (draftElectricalItem.status !== ProgressStatus.DRAFT) {
      return {
        type: ResponseType.Failure,
        data: 'The electrical is not a draft version',
      };
    }

    let cprID: string | null = draftElectricalItem.cprAssistID;
    let protID: string | null = cprID ? null : draftElectricalItem.protocolID;

    let dbMed = await DataStore.query(ElectricalDose, draftElectricalItem.uid);
    if (dbMed == null) {
      return {
        type: ResponseType.Failure,
        data: 'The electrical dose does not exist',
      };
    }

    let activeElectrical: ElectricalDose;

    /* Use case 1: Creating the FIRST active version */
    if (draftElectricalItem.activeID == null) {
      /* Update the draft electrical to be active */
      activeElectrical = await DataStore.save(
        ElectricalDose.copyOf(dbMed, (updated) => {
          updated.status = ProgressStatus.ACTIVE;
        })
      );
    } else {
      /* Use case 2: Upgrading a active version */
      /* Step 1. Fetch the active electrical item */
      let id: string = draftElectricalItem.activeID;
      let curElectrical = await DataStore.query(ElectricalDose, id);

      /* Base Case 3 -- check if the active electrical exists */
      if (curElectrical == null) {
        return {
          type: ResponseType.Failure,
          data: 'The active electrical does not exist',
        };
      }

      let activeItem =
        draftElectricalItem.activeItem as ElectricalSubItem | null;
      if (activeItem == null && draftElectricalItem.activeID != null) {
        let activeMedDose = await DataStore.query(
          ElectricalDose,
          draftElectricalItem.activeID
        );
        if (activeMedDose == null) {
          return {
            type: ResponseType.Failure,
            data:
              'The active electrical does not exist by ID: ' +
              draftElectricalItem.activeID,
          };
        }
        activeItem = new ElectricalSubItem(
          draftElectricalItem.parent,
          draftElectricalItem.parentCPR
            ? draftElectricalItem.parentCPR
            : draftElectricalItem.parentProtocol,
          activeMedDose
        );
      }

      if (activeItem == null) {
        return {
          type: ResponseType.Failure,
          data: 'The active electrical does not exist after error checking',
        };
      }
      activeItem.status = ProgressStatus.ARCHIVE;
      activeItem.activeID = curElectrical.id;

      // 2. Create a new ARCHEIVED electrical based on the current ACTIVE electrical
      let archiveResult: Response = await createElectricalDoseItem(activeItem);
      if (archiveResult.type === ResponseType.Failure) return archiveResult;
      activeItem = archiveResult.data as ElectricalSubItem;

      // 2. Update the ACTIVE electrical with the new information
      activeElectrical = await DataStore.save(
        ElectricalDose.copyOf(curElectrical, (updated) => {
          updated.protocolID = protID;
          updated.cprAssistID = cprID;
          updated.index = draftElectricalItem.index;
          updated.basis = draftElectricalItem.fullBasis;
          updated.rangeLow = draftElectricalItem.rangeLow;
          updated.rangeHigh = draftElectricalItem.rangeHigh;
          updated.ageLow = draftElectricalItem.ageLow
            ? draftElectricalItem.ageLow.ageValue
            : null;
          updated.ageHigh = draftElectricalItem.ageHigh
            ? draftElectricalItem.ageHigh.ageValue
            : null;
          updated.ageGroup = draftElectricalItem.ageGroup;
          updated.repeatTime = draftElectricalItem.repeatTimeSec
            ? draftElectricalItem.repeatTimeSec + ''
            : undefined;
          updated.title = draftElectricalItem.title;
          updated.warning = draftElectricalItem.warning;
          updated.instruction = draftElectricalItem.instruction;
          updated.note = draftElectricalItem.note;
          updated.maxDose = draftElectricalItem.fullMaxDose;
          updated.minDose = draftElectricalItem.fullMinDose;
          updated.maxTotalDose = draftElectricalItem.fullMaxTotalDose;
          updated.calcMax = draftElectricalItem.model.calcMax;
          updated.calcMin = draftElectricalItem.model.calcMin;
          updated.status = ProgressStatus.ACTIVE;
          updated.activeID = null;
          updated.overrideID = draftElectricalItem.overrideID;
          updated.version = draftElectricalItem.version
            ? draftElectricalItem.version
            : 'v1.0.0';
          updated.modifiedBy = draftElectricalItem.modifiedBy
            ? draftElectricalItem.modifiedBy.id
            : undefined;
        })
      );

      // 3. Delete the DRAFT electrical
      let draftElectrical = await DataStore.delete(
        ElectricalDose,
        draftElectricalItem.uid
      );
      if (draftElectrical == null) {
        return {
          type: ResponseType.Failure,
          data: 'The draft electrical does not exist',
        };
      }

      // 4. Query if there any closed draft changes with the actvie model item
      checkIdUpdateDraftChange(activeElectrical.id, activeItem.uid);

      // 5. If there is a draftChangeItem then update the changeID to the new active category and the previousID to the archeived category
      if (draftChangeItem) {
        let new_dc: DraftChangeJSON = {
          previousDraftChange: draftChangeItem,
          changeItem: activeElectrical.id,
          changeType: draftChangeItem.changeType,
          previousItem: activeItem.uid,
          isClosed: true,
        };

        updateDraftChangeItem(new_dc).then((result) => {
          if (result == null) {
            return {
              type: ResponseType.Failure,
              data: 'The draft change item did not update correctly',
            };
          }
        });
      }
    }

    let medItem = new ElectricalSubItem(
      draftElectricalItem.parent,
      draftElectricalItem.parentCPR
        ? draftElectricalItem.parentCPR
        : draftElectricalItem.parentProtocol,
      activeElectrical
    );
    return {
      type: ResponseType.Success,
      data: medItem,
    };
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

/**
 * This function will delete all the electricals that are associated with the protocol
 * @param protocol The protocol to delete the electrical from
 * @param modifiedBy The user that modified the electrical
 * @returns Success: data is the number of electricals deleted or Failure if there was an error
 */
export const deleteElectricalsForProtocol = async (
  oldProtocol: ProtocolItem,
  newDeletedProtocol: ProtocolItem,
  modifiedBy: User
): Promise<Response> => {
  try {
    let promiseFunctions: (() => Promise<Response>)[] = [];
    for (let dose of oldProtocol.electrical) {
      if (dose.status === ProgressStatus.DRAFT && dose.activeItem == null)
        continue;
      dose = dose.activeItem ?? dose;
      dose.parentProtocol = newDeletedProtocol;
      dose.status = ProgressStatus.DELETED;
      dose.modifiedBy = modifiedBy;
      // promiseFunctions.push(() => createElectricalDoseItem(dose));
      promiseFunctions.push(() => deleteElectricalDoseItem(dose, true));
    }
    let responses: Response[] = await BatchQuery(promiseFunctions, 3).catch(
      (e) => {
        console.error('Error deleting electrical doses:', e);
        return [];
      }
    );

    let successes = responses.filter((r) => r.type === ResponseType.Success);
    let errors = responses.filter((r) => r.type === ResponseType.Failure);

    return {
      type: ResponseType.Success,
      data: {
        results: responses,
        successes: successes.map((r) => r.data),
        errors: errors.map((r) => r.data),
      },
    };
  } catch (error: any) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

/**
 * This function will delete all the electricals that are associated with the CPR Assist
 * @param cprAssist The CPR Assist to delete the electrical from
 * @param modifiedBy The user that modified the electrical
 * @returns Success: data is the number of electricals deleted or Failure if there was an error
 */
export const deleteElectricalsForCPR = async (
  oldCPR: CPRItem,
  newDeletedCPR: CPRItem,
  modifiedBy: User
): Promise<Response> => {
  try {
    let promiseFunctions: (() => Promise<Response>)[] = [];
    for (let dose of oldCPR.defibShocks) {
      if (dose.status === ProgressStatus.DRAFT && dose.activeItem == null)
        continue;
      dose = dose.activeItem ?? dose;
      dose.parentCPR = newDeletedCPR;
      dose.status = ProgressStatus.DELETED;
      dose.modifiedBy = modifiedBy;
      // promiseFunctions.push(() => createElectricalDoseItem(dose));
      promiseFunctions.push(() => deleteElectricalDoseItem(dose, true));
    }
    let responses: Response[] = await BatchQuery(promiseFunctions, 3).catch(
      (e) => {
        console.error('Error deleting electrical doses:', e);
        return [];
      }
    );

    let successes = responses.filter((r) => r.type === ResponseType.Success);
    let errors = responses.filter((r) => r.type === ResponseType.Failure);

    return {
      type: ResponseType.Success,
      data: {
        results: responses,
        successes: successes.map((r) => r.data),
        errors: errors.map((r) => r.data),
      },
    };
  } catch (error: any) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const deleteElectricalItem = async (
  electricalItem: ElectricalItem,
  isSoft: boolean
): Promise<Response> => {
  try {
    let id: string = electricalItem.uid;
    if (isSoft) {
      let electrical = await DataStore.query(ElectricalShock, id);
      if (electrical == null) {
        return {
          type: ResponseType.Failure,
          data: 'The electrical does not exist',
        };
      }
      electricalItem.status = ProgressStatus.DELETED;
      let response = await createElectrical(electricalItem);
      if (response.type === ResponseType.Failure) {
        return response;
      }

      /* Copy the medication doses to the new deleted medication */
      let newDeletedElectrical = response.data as ElectricalItem;
      let promiseFunctions: (() => Promise<Response>)[] = [];
      for (let dose of electricalItem.subItems) {
        if (dose.status === ProgressStatus.DRAFT && dose.activeItem == null)
          continue;
        dose = dose.activeItem ?? dose;
        dose.parent = newDeletedElectrical;
        dose.status = ProgressStatus.DELETED;
        // promiseFunctions.push(() => createElectricalDoseItem(dose));
        promiseFunctions.push(() => deleteElectricalDoseItem(dose, true));
      }
      let responses = await BatchQuery(promiseFunctions);
      for (let i = 0; i < responses.length; i++) {
        if (responses[i].type === ResponseType.Failure) {
          console.error('Error in createElectricalDoseItem', responses[i].data);
        }
      }

      await setAllDosesToNewParentID(
        electricalItem,
        newDeletedElectrical,
        updateElectricalDose,
        electricalDosesByElectricalID,
        'electricalID'
      ).then((result: Response) => {
        if (result.type === ResponseType.Failure) {
          console.error('Error in setAllDosesToNewParentID: ', result.data);
          return;
        } else if (globals.debug) {
          console.log('setAllDosesToNewParentID: ', result.data);
        }
      });
      let deletedItem = await DataStore.delete(ElectricalShock, id).catch(
        (error) => {
          console.error('Error in setAllArchiveItemsToNewActiveID: ', error);
          return null;
        }
      );
      if (deletedItem == null) {
        return {
          type: ResponseType.Failure,
          data: 'The electrical did not delete correctly',
        };
      }

      /* Update all the archive items to the new active item */
      setAllArchiveItemsToNewActiveID(
        electricalItem,
        newDeletedElectrical,
        updateElectricalShock,
        electricalShocksByDepartmentID,
        {
          departmentID: electricalItem.departmentID,
        }
      )
        .then((result: Response) => {
          if (result.type === ResponseType.Failure) {
            console.error(
              'Error in setAllArchiveItemsToNewActiveID',
              result.data
            );
            return;
          }
          if (globals.debug)
            console.log('setAllArchiveItemsToNewActiveID', result.data);
        })
        .catch((e) => {
          console.error('Error in setAllArchiveItemsToNewActiveID', e);
          return {
            type: ResponseType.Failure,
            data: e,
          };
        });

      /* Check to see if there was a DraftChange referencing this item and update it to the new ID */
      checkIdUpdateDraftChange(electricalItem.id, newDeletedElectrical.uid);
    } else {
      let electrical = await DataStore.delete(ElectricalShock, id);
      if (electrical == null) {
        return {
          type: ResponseType.Failure,
          data: 'The electrical does not exist',
        };
      }
    }
    return {
      type: ResponseType.Success,
      data: electricalItem,
    };
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

export const isElectricalDraftCreated = async (
  department: DepartmentItem
): Promise<Response> => {
  try {
    let drafts = await DataStore.query(ElectricalShock, (e) =>
      e.and((e) => [e.status.eq('DRAFT'), e.departmentID.eq(department.id)])
    );
    return {
      type: ResponseType.Success,
      data: drafts.length !== 0,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

/**
 * This function will create a new electrical draft in the database
 * @param department The department to check for electrical drafts
 * @returns Success if the draft was created or Failure if there was an error
 */
export const isElectricalDosesDraftCreated = async (
  department: DepartmentItem
): Promise<Response> => {
  try {
    let drafts = await DataStore.query(ElectricalDose, (c) =>
      c.and((c) => [
        c.or((c) => [c.status.eq('DRAFT'), c.status.eq('DRAFT_DELETE')]),
        c.departmentID.eq(department.id),
      ])
    );
    return {
      type: ResponseType.Success,
      data: drafts.length !== 0,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

/**
 * This function will get all the electrical drafts from the database
 * @param db The database to get the electrical drafts from
 * @returns Success if the drafts were found with an array of the updates or Failure if there was an error
 */
export const getElectricalDrafts = async (
  db: DatabaseResponse
): Promise<Response> => {
  try {
    let updates: any[] = [];
    let promses: any[] = [
      DataStore.query(ElectricalShock, (c) =>
        c.and((c) => [
          c.status.eq('DRAFT'),
          c.departmentID.eq(db.department.id),
        ])
      ),
      DataStore.query(ElectricalDose, (c) =>
        c.and((c) => [
          c.status.eq('DRAFT'),
          c.departmentID.eq(db.department.id),
        ])
      ),
    ];
    let responses = await Promise.all(promses);
    let modelUpdates: ElectricalShock[] = responses[0];
    let doseUpdates: ElectricalDose[] = responses[1];

    for (let i = 0; i < modelUpdates.length; i++) {
      let model = new ElectricalItem(modelUpdates[i]);
      let activeItem = db.electrical.find(
        (c) =>
          c.uid === model.activeID ||
          (c.activeItem && c.activeItem.uid === model.activeID)
      );
      if (activeItem) {
        model.activeItem =
          activeItem.uid === model.activeID
            ? activeItem
            : activeItem.activeItem;
      }

      updates.push({
        model: model,
        title: 'Electrical ' + model.name,
        message: getChangeDescription(model),
        changeType: DraftChangeType.ELECTRICAL,
      });
    }

    for (let j = 0; j < doseUpdates.length; j++) {
      const dose = doseUpdates[j];
      const parent = db.electrical.find(
        (e) =>
          e.uid === doseUpdates[j].electricalID ||
          e.activeID === doseUpdates[j].electricalID
      );
      if (!parent) continue;

      let parentItem;
      if (dose.cprAssistID && db.cprModel) {
        parentItem = getModelItem(dose.cprAssistID, db.cprModel);
      } else if (dose.protocolID != null) {
        parentItem = db.protocols.find((p) =>
          getModelItem(dose.protocolID as string, p)
        );
      }
      if (!parentItem) continue;
      const doseItem = new ElectricalSubItem(parent, parentItem, dose);
      let activeItem = db.electricalDoses.find(
        (c) =>
          c.uid === doseItem.activeID ||
          (c.activeItem && c.activeItem.uid === doseItem.activeID)
      );
      if (activeItem) {
        doseItem.activeItem =
          activeItem.uid === doseItem.activeID
            ? activeItem
            : activeItem.activeItem;
      }
      let doseMessage = '';
      let parentName = dose.cprAssistID ? 'CPR Assist' : parentItem.name;
      if (dose.activeID == null)
        doseMessage = `Created Electrical Shock: ${doseItem.name} in ${parentName}`;
      else
        doseMessage = `Updated Electrical Shock: ${doseItem.name} in ${parentName}`;
      updates.push({
        model: doseItem,
        title: 'Electrical Shock ' + doseItem.name,
        message: doseMessage,
        changeType: DraftChangeType.ELECTRICAL_SHOCK,
      });
    }

    return {
      type: ResponseType.Success,
      data: updates,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

function findParentProtocol(
  db: DatabaseResponse,
  dose: ElectricalDose
): CPRItem | ProtocolItem | null {
  if (dose.cprAssistID) {
    return db.cprModel?.uid === dose.cprAssistID
      ? db.cprModel
      : dose.cprAssistID === db.cprModel?.activeID
        ? (db.cprModel?.activeItem as CPRItem)
        : null;
  } else {
    let find = db.protocols.find(
      (p) =>
        p.uid === dose.protocolID ||
        (p.activeItem && p.activeItem.uid === dose.protocolID)
    );
    return find
      ? dose.protocolID === find.uid
        ? find
        : (find.activeItem as ProtocolItem)
      : null;
  }
}

export const convertElectricalChangeToDraft = (
  dc: DraftChangeItem
): Draft | null => {
  try {
    if (dc.changeItem == null) {
      return null;
    }
    let update: Draft = {
      draftChangeItem: dc,
      model: dc.changeItem,
      title: 'Electrical ' + dc.changeItem.name,
      message: getChangeDescription(dc.changeItem as ElectricalItem),
      changeType: DraftChangeType.ELECTRICAL,
    };

    return update;
  } catch (error) {
    return null;
  }
};

/**
 * This function will convert an electrical dose change to a draft
 * @param dc The draft change item to convert
 * @returns The draft item or null if there was an error
 */
export const convertElectricalDoseChangeToDraft = (
  dc: DraftChangeItem
): Draft | null => {
  try {
    if (dc.changeItem == null) {
      return null;
    }
    let update: Draft = {
      draftChangeItem: dc,
      model: dc.changeItem,
      title: 'Electrical Shock ' + dc.changeItem.name,
      message: getDoseChangeDescription(dc.changeItem as ElectricalSubItem),
      changeType: DraftChangeType.ELECTRICAL_SHOCK,
    };

    return update;
  } catch (error) {
    return null;
  }
};

function getChangeDescription(draftItem: ElectricalItem): string {
  if (draftItem.activeItem == null)
    return `Created Electrical: ${draftItem.name}`;
  return `Updated Electrical: ${draftItem.name}`;
}

function getDoseChangeDescription(dose: ElectricalSubItem): string {
  let doseMessage = '';
  let parentName = dose.model.protocolID
    ? dose.parentProtocol.name
    : 'CPR Assist';
  if (dose.status === 'DRAFT_DELETE')
    doseMessage = `Deleted Electrical Shock: ${dose.name} in ${parentName}`;
  else if (dose.activeID == null)
    doseMessage = `Created Electrical Shock: ${dose.name} in ${parentName}`;
  else doseMessage = `Updated Electrical Shock: ${dose.name} in ${parentName}`;
  return doseMessage;
}

export const removeCurrentElectricalDrafts = async (
  db: DatabaseResponse
): Promise<Response> => {
  try {
    let updates: any[] = [];
    let draftElectricals = await DataStore.query(ElectricalShock, (e) =>
      e.and((e) => [e.status.eq('DRAFT'), e.departmentID.eq(db.department.id)])
    );
    for (let i = 0; i < draftElectricals.length; i++) {
      let elec: ElectricalShock = draftElectricals[i];
      await DataStore.delete(elec);
      updates.push({
        model: elec,
        message: `Removed Folder: ${elec.title}`,
      });
    }

    /* Now remove the electrical doses */
    let draftElectricalDoses = await DataStore.query(ElectricalDose, (c) =>
      c.and((c) => [
        c.or((c) => [c.status.eq('DRAFT'), c.status.eq('DRAFT_DELETE')]),
        c.departmentID.eq(db.department.id),
      ])
    );
    for (let j = 0; j < draftElectricalDoses.length; j++) {
      let dose: ElectricalDose = draftElectricalDoses[j];
      await DataStore.delete(dose);
      let protocol = await getProtocolByID(db, dose.protocolID || '');
      let electrical = await getElectricalByID(db, dose.electricalID);
      if (protocol == null || electrical == null) continue;
      updates.push({
        model: dose,
        message: `Removed ${electrical.name} dose in ${protocol.name}`,
      });
    }

    return {
      type: ResponseType.Success,
      data: updates,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

/* GraphQL API Queries */
export const getElectricalByID = async (
  db: DatabaseResponse,
  id: string
): Promise<ElectricalItem | null> => {
  return new Promise(async (resolve, reject) => {
    try {
      /* Fetch the category from the database */
      const dbElec = findItemByID(id, db.electrical);
      if (dbElec != null) return resolve(dbElec as ElectricalItem);
      else {
        executeSingleQuery(getElectricalShock, { id: id }, 1500)
          .then((elec: ElectricalShock | null | undefined) => {
            if (elec == null) {
              resolve(null);
            } else {
              let electrcal = new ElectricalItem(elec);
              /* TODO MAP THE SUB ITEMS AND TO PROTOCOLS */
              resolve(electrcal);
            }
          })
          .catch((error) => {
            reject(error);
          });
      }
    } catch (error: any) {
      reject(error);
    }
  });
};

/* GraphQL API Queries */
export const getElectricalDoseByID = async (
  db: DatabaseResponse,
  id: string
): Promise<ElectricalSubItem | null> => {
  return new Promise(async (resolve, reject) => {
    try {
      /* Fetch the category from the database */
      const dbMed = findItemByID(id, db.electricalDoses);
      if (dbMed != null) return resolve(dbMed as ElectricalSubItem);
      else {
        executeSingleQuery(getElectricalDose, { id: id }, 1500)
          .then((elecDose) => {
            if (elecDose == null) {
              resolve(null);
            } else {
              let promises = [
                getProtocolByID(db, elecDose.protocolID),
                getElectricalByID(db, elecDose.electricalID),
              ];
              Promise.all(promises).then((results) => {
                let protocol = results[0] as ProtocolItem;
                let electrical = results[1] as ElectricalItem;
                if (protocol == null || electrical == null) {
                  resolve(null);
                } else {
                  let electricalDose = new ElectricalSubItem(
                    electrical,
                    protocol,
                    elecDose
                  );
                  resolve(electricalDose);
                }
              });

              // let electrical = new ElectricalItem(med);
              // /* TODO MAP THE SUB ITEMS AND TO PROTOCOLS */
              // resolve(electrical);
            }
          })
          .catch((error) => {
            reject(error);
          });
      }
    } catch (error: any) {
      reject(error);
    }
  });
};

export const deleteElectricalDoseItem = async (
  electricalDose: ElectricalSubItem,
  isSoft: boolean = true
): Promise<Response> => {
  try {
    let dbMed = await DataStore.query(ElectricalDose, electricalDose.uid);
    if (dbMed == null) {
      return {
        type: ResponseType.Failure,
        data: 'The electrical does not exist',
      };
    }
    let id: string = electricalDose.uid;
    if (isSoft) {
      let deletedItem = await DataStore.delete(ElectricalDose, id).catch(
        (error) => {
          console.error('Error in deleteElectricalDoseItem: ', error);
          return null;
        }
      );
      if (deletedItem == null) {
        return {
          type: ResponseType.Failure,
          data: 'The electrical dose did not delete correctly',
        };
      }
      electricalDose.status = ProgressStatus.DELETED;
      let result: Response = await createElectricalDoseItem(electricalDose);
      if (result.type === ResponseType.Failure) {
        console.error('Error in createElectricalDoseItem', result.data);
        return result;
      }
      let newDeletedElectrical: ElectricalItem = result.data as ElectricalItem;

      /* Update all the archive items to the new active item */
      result = await setAllArchiveItemsToNewActiveID(
        electricalDose,
        newDeletedElectrical,
        updateElectricalDose,
        electricalDosesByDepartmentID,
        {
          departmentID: electricalDose.departmentID,
        }
      ).catch((e) => {
        console.error('Error in setAllArchiveItemsToNewActiveID', e);
        return {
          type: ResponseType.Failure,
          data: e,
        };
      });

      if (result.type === ResponseType.Failure) {
        console.error('Error in setAllArchiveItemsToNewActiveID', result.data);
        return result;
      }

      if (globals.debug)
        console.log('setAllArchiveItemsToNewActiveID', result.data);

      /* Check to see if there was a DraftChange referencing this item and update it to the new ID */
      checkIdUpdateDraftChange(electricalDose.id, newDeletedElectrical.uid);
    } else {
      let resp = await DataStore.delete(ElectricalDose, dbMed.id);
      if (resp == null) {
        return {
          type: ResponseType.Failure,
          data: 'The electrical does not exist',
        };
      }
    }
    return {
      type: ResponseType.Success,
      data: electricalDose,
    };
  } catch (error: any) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

/**
 * Fetch the electricals by the status
 * @param dep The department to fetch the electricals from
 * @param status The status of the electricals to fetch
 * @param waitForUsers Whether to wait for the users to be fetched
 * @returns The electricals that were fetched
 */
export const fetchElectricalsByStatusAPI = async (
  dep: DepartmentItem,
  db: DatabaseResponse,
  status: ProgressStatus = ProgressStatus.ACTIVE,
  waitForUsers: boolean = false,
  includeErrors: boolean = false
): Promise<Response> => {
  try {
    /* Fetch the protocols for this category and then sort by index */
    let depIDs = dep.getAllParentDepIDs();
    let start = new Date();
    let promises: any[] = [];
    for (let i = 0; i < depIDs.length; i++) {
      promises.push(
        executeQuery(electricalShocksByDepartmentID, {
          departmentID: depIDs[i],
          filter: {
            and: [
              {
                status: {
                  eq: status,
                },
              },
              {
                _deleted: {
                  ne: true,
                },
              },
            ],
          },
        })
      );
    }
    let electricalResults: any = await Promise.all(promises);
    let electricalList: ElectricalItem[] = [];
    for (let i = 0; i < electricalResults.length; i++) {
      if (electricalResults[i] != null)
        electricalList.push(
          ...electricalResults[i].map(
            (e: ElectricalShock) => new ElectricalItem(e)
          )
        );
    }
    if (globals.debug)
      console.log(
        'Electricals fetched in',
        new Date().getTime() - start.getTime(),
        'ms',
        electricalList
      );

    let archiveItems: ArchiveItem[] = [];
    for (let i = 0; i < electricalList.length; i++) {
      const elec = electricalList[i];
      if (elec.activeID == null) {
        if (includeErrors === false) continue;
        console.log('Electrical has no activeID', elec.name);
        let archiveItem = archiveItems.find((item) => item.id === 'ERROR');
        if (archiveItem) {
          let items = archiveItem.items;
          items.push(elec);
          archiveItem.items = items;
        } else {
          archiveItems.push({
            id: 'ERROR',
            activeItem: elec,
            items: [elec],
          });
        }
      } else {
        /* 1-2-25 Gagan: Needed to check uid and activeID if an item is in DRAFT mode currently */
        const activeElec = db.electrical.find(
          (c) => c.uid === elec.activeID || c.activeID === elec.activeID
        );
        if (activeElec == null) {
          if (includeErrors === false) continue;
          console.log('Electrical has no activeID', elec.name);
        }
        let archiveItem = archiveItems.find(
          (item) => item.id === elec.activeID
        );
        if (archiveItem) {
          let items = archiveItem.items;
          items.push(elec);
          items.sort((a: ElectricalItem, b: ElectricalItem) => {
            if (a.version === b.version) return a.name.localeCompare(b.name);
            return compareVersions(
              a.version || 'v1.0.0',
              b.version || 'v1.0.0'
            ) as number;
          });
          archiveItem.items = items;
        } else {
          archiveItems.push({
            id: elec.activeID || 'ERROR',
            activeItem: activeElec as ElectricalItem,
            items: [elec],
          });
        }
      }
    }

    return {
      type: ResponseType.Success,
      data: archiveItems,
    };
  } catch (error) {
    console.error('Error fetching protocols:', error);
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const fetchAllElectricalDosesForElectrical = async (
  db: DatabaseResponse,
  dep: DepartmentItem,
  electrical: ElectricalItem,
  useDataStore: boolean = false
): Promise<Response> => {
  try {
    let depIDs = [dep.id];
    if (dep.parentDep) depIDs.push(dep.parentDep.id);
    if (dep.parentDep?.parentDep) depIDs.push(dep.parentDep.parentDep.id);

    let electricalID: string | null = getActiveID(electrical);
    const activeElectrical =
      electrical.status === ProgressStatus.DRAFT
        ? electrical.activeItem
        : electrical;

    if (electricalID == null && activeElectrical == null) {
      return {
        type: ResponseType.Failure,
        data:
          'The electrical does not have a proper version ID 4' +
          electrical.uid +
          ' ' +
          electrical.activeID +
          ' ' +
          electrical.status,
      };
    }

    let electricalList;
    if (useDataStore) {
      electricalList = await DataStore.query(ElectricalDose, (m) =>
        m.and((m) => [
          m.electricalID.eq(electricalID as string),
          m.or((m) => depIDs.map((id) => m.departmentID.eq(id))),
          m.and((m) => [m.status.ne('DELETED')]),
        ])
      );
    } else {
      electricalList = await executeQuery(electricalDosesByElectricalID, {
        electricalID: electricalID,
        filter: {
          and: [
            { or: depIDs.map((id) => ({ departmentID: { eq: id } })) },
            { status: { ne: 'DELETED' } },
            { _deleted: { ne: true } },
          ],
        },
      });
    }

    let electricalDoses: ElectricalSubItem[] = [];
    let cpr = db.cprModel;

    for (let i = 0; i < electricalList.length; i++) {
      let electricalDose = electricalList[i];
      if (electricalDose.departmentID !== dep.id) continue;
      else {
        let protocol = db.protocols.find(
          (p) =>
            p.uid === electricalDose.protocolID ||
            p.activeID === electricalDose.protocolID
        );

        if (
          electricalDose.cprAssistID != null &&
          cpr &&
          (electricalDose.cprAssistID === cpr.uid ||
            electricalDose.cprAssistID === cpr.activeID)
        ) {
          let med = new ElectricalSubItem(
            activeElectrical as ElectricalItem,
            cpr,
            electricalDose
          );
          med.parentCPR = cpr;
          electricalDoses.push(med);
        } else if (protocol) {
          let med = new ElectricalSubItem(
            activeElectrical as ElectricalItem,
            protocol,
            electricalDose
          );
          electricalDoses.push(med);
        }
      }
    }

    let archiveItems: ArchiveItem[] = [];
    for (let i = 0; i < electricalDoses.length; i++) {
      const med = electricalDoses[i];

      // When med is in ACTIVE mode
      if (med.activeID == null && med.status === 'ACTIVE') {
        // Find for an existing archive item with the same uid
        let archiveItem = archiveItems.find((item) => item.id === med.uid);
        if (archiveItem) {
          // If found, check if the activeItem is in DRAFT mode
          // If not, update the activeItem
          if (archiveItem.activeItem.status !== 'DRAFT') {
            archiveItem.activeItem = med;
          } else {
            // If the activeItem is in DRAFT mode, update the activeItem.activeItem
            archiveItem.activeItem.activeItem = med;
          }
        } else {
          // If not found, create a new archive item
          archiveItems.push({
            id: med.uid,
            activeItem: med,
            items: [],
          });
        }
      }
      // When med is in DRAFT mode - it is the latest version which is not yet published
      else if (med.activeID != null && med.status === 'DRAFT') {
        // Find for an existing archive item with the same uid
        let archiveItem = archiveItems.find((item) => item.id === med.activeID);
        if (archiveItem) {
          // If found, update the activeItem
          archiveItem.id = med.activeID || 'ERROR';
          med.activeItem = archiveItem.activeItem as ElectricalSubItem;
          archiveItem.activeItem = med;
        } else {
          // If not found, create a new archive item
          archiveItems.push({
            id: med.activeID || 'ERROR',
            activeItem: med,
            items: [],
          });
        }
      }
      // When med is in ARCHIVE mode
      else if (med.activeID != null && med.status === 'ARCHIVE') {
        // Find for an existing archive item with the same activeID
        let archiveItem = archiveItems.find((item) => item.id === med.activeID);
        if (archiveItem) {
          // If found, add this med to the items array
          let items = archiveItem.items;
          items.push(med);
          // Sort the items array by version
          items.sort((a: ElectricalSubItem, b: ElectricalSubItem) => {
            if (a.version === b.version) return a.name.localeCompare(b.name);
            return compareVersions(
              a.version || 'v1.0.0',
              b.version || 'v1.0.0'
            ) as number;
          });
          archiveItem.items = items;
        } else {
          // If not found, create a new archive item
          archiveItems.push({
            id: med.activeID || 'ERROR',
            activeItem: med,
            items: [med],
          });
        }
      }
    }

    // Filter out any activeItems that are in the archiveItems with activeItem.status !== 'ACTIVE'
    archiveItems = archiveItems.filter(
      (item) =>
        item.activeItem.status === 'ACTIVE' ||
        item.activeItem.status === 'DRAFT'
    );

    return {
      type: ResponseType.Success,
      data: archiveItems,
    };
  } catch (error) {
    console.error('Error fetching electricalList:', error);
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const fetchAllElectricalShocksForProtocol = async (
  db: DatabaseResponse,
  dep: DepartmentItem,
  protocol: ProtocolItem
): Promise<Response> => {
  try {
    let protocolID = getActiveID(protocol);
    if (protocolID == null) {
      return {
        type: ResponseType.Failure,
        data: 'The protocol does not have a proper version ID',
      };
    }

    let electricalList = await executeQuery(electricalDosesByProtocolID, {
      protocolID: protocolID,
      filter: {
        and: [
          {
            _deleted: {
              ne: true,
            },
          },
          {
            status: {
              ne: 'DEACTIVATED',
            },
          },
          {
            status: {
              ne: 'DRAFT',
            },
          },
        ],
      },
    });

    // filter out any items that have Medication === null
    electricalList = electricalList.filter(
      (item: any) => item.Electrical != null
    );

    let electricalDoses: ElectricalSubItem[] = [];
    for (let i = 0; i < electricalList.length; i++) {
      let electricalDose = electricalList[i];
      if (electricalDose.departmentID !== dep.id) continue;
      else {
        if (electricalDose.protocolID) {
          let protocol = db.protocols.find(
            (p) =>
              p.uid === electricalDose.protocolID ||
              p.activeID === electricalDose.protocolID
          );
          if (protocol) {
            const med = new ElectricalSubItem(
              new ElectricalItem(electricalDose.Electrical),
              protocol,
              electricalDose
            );
            electricalDoses.push(med);
          }
        }
      }
    }

    let archiveItems: ArchiveItem[] = [];
    for (let i = 0; i < electricalDoses.length; i++) {
      const med = electricalDoses[i];

      // When med is in ACTIVE or DELETED
      if (
        med.activeID == null &&
        (med.status === 'ACTIVE' || med.status === 'DELETED')
      ) {
        // Find for an existing archive item with the same uid
        let archiveItem = archiveItems.find((item) => item.id === med.uid);
        if (archiveItem) {
          archiveItem.activeItem = med;
        } else {
          // If not found, create a new archive item
          archiveItems.push({
            id: med.uid,
            activeItem: med,
            items: [],
          });
        }
      }
      // When med is in ARCHIVE or DELETED
      else if (med.activeID != null && med.status === 'ARCHIVE') {
        // Find for an existing archive item with the same activeID
        let archiveItem = archiveItems.find((item) => item.id === med.activeID);
        if (archiveItem) {
          // If found, add this med to the items array
          let items = archiveItem.items;
          items.push(med);
          // Sort the items array by version
          items.sort((a: ElectricalSubItem, b: ElectricalSubItem) => {
            if (a.version === b.version) return a.name.localeCompare(b.name);
            return compareVersions(
              a.version || 'v1.0.0',
              b.version || 'v1.0.0'
            ) as number;
          });
          archiveItem.items = items;
        } else {
          // If not found, create a new archive item
          archiveItems.push({
            id: med.activeID || 'ERROR',
            activeItem: med,
            items: [med],
          });
        }
      }
    }

    // Filter out any activeItems that are in the archiveItems with activeItem.status !== 'ACTIVE'
    archiveItems = archiveItems.filter(
      (item) =>
        item.activeItem.status === 'ACTIVE' ||
        item.activeItem.status === 'DELETED'
    );

    return {
      type: ResponseType.Success,
      data: archiveItems,
    };
  } catch (error) {
    console.error('Error fetching electricalList:', error);
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};
