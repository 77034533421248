import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Contact, User } from '../../../../../models';

import DepartmentItem from '../../../../../data/model/DepartmentItem';
import {
  DatabaseResponse,
  Response,
  ResponseType,
  loadDatabase,
} from '../../../../../data/AmplifyDB';
import ProtocolHeader from '../../ProtocolHeader';
import { InputText } from 'primereact/inputtext';
import { ViewportList } from 'react-viewport-list';
import { handleGetDepartment } from '../../../../../store/actions';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import ContactItem from '../../../../../data/model/ContactItem';
import { InputTextarea } from 'primereact/inputtextarea';
import SearchableDropdown from '../../../../components/SearchableDropdown';
import { FaTimes } from 'react-icons/fa';
import ConfirmModal from '../../../../components/Modal/ConfirmModal';
import {
  findDepartmentOwner,
  getDepartmentsFromState,
  globals,
  hasAdminUserAccess,
} from '../../../../_global/common/Utils';
import {
  createContact,
  deleteContact,
  editContact,
  fetchContacts,
  subscribeContactFromDepartment,
} from '../../../../../data/functions/ContactDB';
import { Alert, AlertColor, Snackbar } from '@mui/material';

/* 09-27-23 Arul: Created Component for Protocol Screen*/
const CreateEditContact = (props: any) => {
  const isLoggedIn = useSelector((state: any) => state.isLoggedIn);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchQuery, setSearchQuery] = useState('');
  const location = useLocation();
  const { state } = location;
  const user: User = useSelector((state: any) => state?.user);
  const [database, setDatabase] = useState<DatabaseResponse>(
    useSelector((state: any) => state?.protocol?.departmentItem)
  );
  const [snackbar, setSnackbar] = useState({
    isOpen: false,
    message: '',
    severity: 'success',
  });
  const [isDeactivateModal, setIsDeactivateModal] = useState(false);
  const [list, setList] = useState<ContactItem[]>(database.contacts);
  const department: DepartmentItem = database.department;
  const parmContact: ContactItem = state.value;
  const reducerState = useSelector((state: any) => state.department);
  const { allSubDeps } = useMemo(() => {
    return getDepartmentsFromState(department, reducerState);
  }, [department, reducerState]);
  const availableDeps = useMemo(() => {
    return department.isTopEnabled || user.type === 'ADMIN'
      ? [department, ...(allSubDeps || [])]
      : allSubDeps || [];
  }, [department, user, allSubDeps]);

  const [isDelete, setIsDelete] = useState(false);
  const [pairedDeps, setPairedDeps] = useState<DepartmentItem[]>(
    parmContact && parmContact.pairedDeps ? parmContact.pairedDeps : []
  );
  const departmentList = useMemo(() => {
    return availableDeps.filter((dep: DepartmentItem) => {
      return !pairedDeps.some((d: DepartmentItem) => d.id === dep.id);
    });
  }, [availableDeps, pairedDeps]);

  const adminLevel = useMemo(() => {
    return database.department.adminLevel;
  }, [database.department]);

  const isOwner = useMemo(() => {
    return parmContact ? department.id === parmContact.departmentID : true;
  }, [department, parmContact]);

  const adminAccess = useMemo(() => {
    return hasAdminUserAccess(department, reducerState, user);
  }, [department, reducerState, user]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: parmContact ? parmContact.uid : '',
      fullName: parmContact ? parmContact.name : '',
      title: parmContact ? parmContact.title : '',
      note: parmContact ? parmContact.note : '',
      number: parmContact ? parmContact.number : '',
      index: parmContact ? parmContact.index : list.length,
      departmentID: department.id,
    },
    validationSchema: Yup.object({
      fullName: Yup.string().required('Required'),
      title: Yup.string(),
      number: Yup.string().required('Required'),
      note: Yup.string(),
    }),
    onSubmit: async (values) => {
      let result;
      let data = { ...values, pairedDeps: pairedDeps };
      if (parmContact == null) result = await createContact(data);
      else result = await editContact(data);
      if (result.type === ResponseType.Success) {
        let contact = result.data as Contact;
        if (globals.debug)
          console.log(
            'Contact',
            parmContact ? ' editted' : ' created',
            contact
          );

        let db = await reloadDatabase();

        navigate(`/database/list-contacts`, {
          state: { department: department, database: db },
        });
      } else {
        if (globals.debug)
          console.log('Create/Edit contact failed', result.data);
      }
    },
  });

  useEffect(() => {
    formik.resetForm();
  }, [parmContact]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.ctrlKey && event.shiftKey && event.key === 'O') {
        console.log('CONTACT', parmContact);
      }
    };

    // Add event listener
    document.addEventListener('keydown', handleKeyDown);

    // Clean up event listener on component unmount
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [formik]);

  useEffect(() => {
    if (parmContact && parmContact.pairedDepIDs) {
      let l: DepartmentItem[] = [];
      parmContact.pairedDepIDs.forEach((id: string) => {
        let dep = availableDeps?.find((d: DepartmentItem) => d.id === id);
        if (dep) l.push(dep);
      });
      setPairedDeps(l);
    }
  }, [parmContact, availableDeps]);

  const reloadDatabase = async (): Promise<DatabaseResponse | null> => {
    /* 1-22-24 Guru:  Updated the current data to the database change and keep the current state */
    const resp: Response = await fetchContacts(database.department);
    if (resp.type === ResponseType.Success) {
      let newDB: DatabaseResponse = {
        ...database,
        contacts: resp.data,
      };
      dispatch<any>(handleGetDepartment(newDB));
      // const newDB: DatabaseResponse = resp.data;
      // setDatabase(newDB);
      // dispatch<any>(handleGetDepartment(newDB));
      return newDB;
    } else {
      console.error('ERROR LOADING DATABASE', resp.data);
      return null;
    }
  };

  useEffect(() => {
    let l = [...database.contacts];
    setList(l);
  }, [parmContact, database]);

  const handleBack = () => {
    navigate(`/database/list-contacts`, {
      state: { department: department, data: database.contacts, database },
    });
  };

  const Badge = ({ text }: any) => {
    const badgeStyle = {
      display: 'inline-block',
      padding: '2px 10px',
      borderRadius: '5px',
      backgroundColor: '#ADD8E6',
      color: '#0d4d8e',
      fontSize: '12px',
      fontWeight: 'bold',
      marginLeft: '10px',
    };
    return <span style={badgeStyle}>{text}</span>;
  };

  const RenderContact = ({ item, index }: any) => {
    let isThis = parmContact && item.uid === parmContact.uid;
    return (
      <div key={index}>
        <div
          className="departmentItem"
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignContent: 'center',
            padding: '10px 10px',
            borderTopLeftRadius: index === 0 ? '6px' : '0px',
            borderTopRightRadius: index === 0 ? '6px' : '0px',
            borderBottomLeftRadius: index === list.length - 1 ? '6px' : '0px',
            borderBottomRightRadius: index === list.length - 1 ? '6px' : '0px',
            borderBottom:
              index !== list.length - 1 ? '1px solid #E0E0E0' : 'none',
            cursor: 'pointer',
          }}
        >
          <div
            className="headerTextLight"
            style={{ fontSize: '14px', margin: 0 }}
          >
            {index + 1}: {item.name}
            {isThis ? <Badge text="This" /> : ''}
          </div>
          <div
            className="headerTextLight"
            style={{ fontSize: '14px', margin: 0, fontWeight: '500' }}
          >
            {item.number}
          </div>
        </div>
      </div>
    );
  };

  const handleAddDepartment = (option: DepartmentItem) => {
    let l = [...pairedDeps, option];
    l.sort((a, b) => a.name.localeCompare(b.name));
    setPairedDeps(l);
  };

  const handleRemoveDepartment = (option: DepartmentItem, e: any) => {
    e.stopPropagation();
    let l = pairedDeps.filter((d: DepartmentItem) => d.id !== option.id);
    setPairedDeps(l);
  };

  const handleClearDepartments = () => {
    setPairedDeps([]);
  };

  const handleDelete = async () => {
    const result = await deleteContact(parmContact.dbContact);
    if (result.type === ResponseType.Success) {
      if (globals.debug) console.log('Contact', ' deleted', result.data);
      let db = await reloadDatabase();
      setSnackbar({
        isOpen: true,
        message: 'Contact deleted',
        severity: 'success',
      });
      navigate(`/database/list-contacts`, {
        state: { department: department, database: db },
      });
    } else {
      if (globals.debug) console.log('Contact', ' delete failed', result.data);
      setSnackbar({
        isOpen: true,
        message: 'Contact delete failed',
        severity: 'error',
      });
    }
  };

  const handleDeactivate = async () => {
    subscribeContactFromDepartment(parmContact, department, false).then(
      (resp) => {
        if (resp.type === ResponseType.Success) {
          navigate(`/database/list-contacts`, {
            state: { department: department, database: database },
          });
        } else {
          setSnackbar({
            isOpen: true,
            message: 'Contact deactivation failed',
            severity: 'error',
          });
        }
      }
    );
  };

  const isSaveValid = useMemo(() => {
    let ids = pairedDeps.map((d: DepartmentItem) => d.id).join(',');
    if (globals.debug)
      console.log('isSaveValid', formik.dirty, formik.isValid, ids, pairedDeps);
    if (parmContact) {
      return (
        (formik.dirty || ids !== parmContact.pairedDepIDs?.join(',')) &&
        formik.isValid
      );
    }
    return formik.dirty && formik.isValid;
  }, [formik.dirty, formik.isValid, pairedDeps, parmContact]);

  return (
    <div className="screen-container">
      <Snackbar
        open={snackbar.isOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbar({ ...snackbar, isOpen: false })}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, isOpen: false })}
          severity={snackbar.severity as AlertColor}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
      {parmContact && (
        <ConfirmModal
          isVisible={isDelete}
          title="Delete Contact?"
          handleClose={() => {
            setIsDelete(false);
          }}
          handleSubmit={handleDelete}
          isDeleteBtn={true}
          primaryBtnName="Cancel"
          secondaryBtnName="Delete"
          primaryDescription={
            'Are you sure you want delete contact: ' + parmContact.name + '?'
          }
        />
      )}
      {parmContact && (
        <ConfirmModal
          isVisible={isDeactivateModal}
          title="Deactivate Contact?"
          handleClose={() => {
            setIsDelete(false);
          }}
          handleSubmit={handleDeactivate}
          isDeleteBtn={true}
          primaryBtnName="Cancel"
          secondaryBtnName="Deactivate"
          primaryDescription={
            'Are you sure you want deactivate contact: ' +
            parmContact.name +
            '?'
          }
          secondaryDescription={
            'This will remove the contact for ONLY ' + department.name + '.'
          }
        />
      )}
      <ProtocolHeader
        name={parmContact ? 'Edit Contact' : 'Create Contact'}
        type={'protocol'}
        page={'Contacts'}
        isCreateButton={parmContact ? false : true}
        isSaveButton={
          parmContact ? isOwner && adminAccess && adminLevel > 3 : false
        }
        isDeleteButton={
          parmContact ? isOwner && adminAccess && adminLevel > 3 : false
        }
        isDeactivateButton={!isOwner && adminAccess && adminLevel > 2}
        handleDeactivate={() => setIsDeactivateModal(true)}
        isDeactivateActive={true}
        handleDelete={() => {
          setIsDelete(true);
        }}
        isSaveActive={isSaveValid}
        isBackButton={true}
        isDotButton={true}
        isCreateActive={isSaveValid}
        handleCancel={handleBack}
        handleCreate={() => formik.handleSubmit()}
        handleSave={() => formik.handleSubmit()}
        handleCancelEdit={handleBack}
      />
      <div className="ketamineContent">
        <div className="KetamineGeneral">
          <h5 className="ketmine-header-text">Contact Information</h5>
          <label htmlFor="Name" className="ketamine-general-label">
            Name <span className="required-field">*</span>
          </label>
          <div className="input-container">
            <InputText
              type="text"
              className="form-control-general"
              id="Name"
              name="name"
              data-testid="name"
              autoFocus
              value={formik.values.fullName}
              disabled={!isOwner}
              onChange={(e: any) => {
                formik.setFieldValue('fullName', e.target.value);
              }}
            />
            <div className="input-border"></div>
          </div>
          <label htmlFor="Number" className="ketamine-general-label">
            Contact Method <span className="required-field">*</span>
          </label>
          <p className="sidebarText" style={{ marginLeft: '10px' }}>
            Enter the phone number ###-###-#### or a desctiption of the contact
            method. Ex. "Contact Dispatch Center"
          </p>
          <div className="input-container">
            <InputText
              type="text"
              className="form-control-general"
              id="Number"
              name="number"
              data-testid="number"
              value={formik.values.number}
              disabled={!isOwner}
              onChange={(e: any) => {
                formik.setFieldValue('number', e.target.value);
              }}
            />
            <div className="input-border"></div>
          </div>
          <label htmlFor="Title" className="ketamine-general-label">
            Title
          </label>
          <div className="input-container">
            <InputText
              type="text"
              className="form-control-general"
              id="Title"
              name="title"
              data-testid="title"
              value={formik.values.title}
              disabled={!isOwner}
              onChange={(e: any) => {
                formik.setFieldValue('title', e.target.value);
              }}
            />
            <div className="input-border"></div>
          </div>
          <label htmlFor="Note" className="ketamine-general-label">
            Special Note
          </label>
          <p className="sidebarText" style={{ marginLeft: '10px' }}>
            Enter in any special instructions or notes for this contact.
          </p>
          <div className="input-container">
            <InputTextarea
              className="form-control-general"
              id="Note"
              name="Note"
              required={true}
              data-testid="Note"
              value={formik.values.note}
              disabled={!isOwner}
              onChange={(e: any) => {
                formik.setFieldValue('note', e.target.value);
              }}
              style={{ height: '110px', verticalAlign: 'top' }}
            />
            <div className="input-border"></div>
          </div>
          {department.isMultiDep && availableDeps.length > 0 && (
            <>
              <label htmlFor="" className={`notification-css-title`}>
                <span
                  className="headerTextMargin"
                  style={{ fontSize: '16px', marginTop: '10px' }}
                >
                  Subscribed Departments: {pairedDeps.length} /{' '}
                  {availableDeps.length}
                  {isOwner && (
                    <span
                      onClick={() => {
                        setPairedDeps(availableDeps);
                      }}
                    >
                      <div className="clickableText">Add All</div>
                    </span>
                  )}
                </span>
              </label>
              <span
                className="contentText greyText"
                style={{ fontSize: '13px', marginLeft: '10px' }}
              >
                This is a list of every department that will be able to access
                this contact.
              </span>
              <SearchableDropdown<DepartmentItem>
                id="searchDropdown"
                options={departmentList}
                labelField={(option) => option.name}
                valueField={(option) => option.name}
                keyField={(option) => option.id}
                multiSelect={true}
                disabled={!isOwner}
                onChange={(option: DepartmentItem) =>
                  handleAddDepartment(option)
                }
                onClear={handleClearDepartments}
                placeholder="Search department..."
              />
              {pairedDeps.length === 0 && (
                <h6 style={{ textAlign: 'center', marginTop: '10px' }}>
                  No subscribed departments...
                </h6>
              )}
              <div
                style={{
                  // overflowY: 'auto',
                  // maxHeight: '200px',
                  border: pairedDeps.length === 0 ? '0px' : '1px solid #ccc',
                  borderRadius: '5px',
                  marginBottom: '20px',
                  marginTop: '10px',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <ViewportList items={pairedDeps}>
                  {(item: DepartmentItem, index) => (
                    <div
                      key={index}
                      style={{
                        display: 'grid',
                        gridTemplateColumns: '16fr 1fr',
                        padding: '6px 10px',
                        alignItems: 'center',
                        borderBottom:
                          index === pairedDeps.length - 1
                            ? ''
                            : '1px solid #ccc',
                        // borderTopLeftRadius: '6px',
                        // borderTopRightRadius: '6px',
                        // borderBottomLeftRadius: index === pairedDeps.length-1 ? '6px' : '0px',
                        // borderBottomRightRadius: index === pairedDeps.length-1 ? '6px' : '0px',
                      }}
                      className="listItem"
                    >
                      <div className="contentText">{item.name}</div>
                      {isOwner && (
                        <FaTimes
                          className="icon-cancel"
                          size={16}
                          onClick={(e) => handleRemoveDepartment(item, e)}
                        />
                      )}
                    </div>
                  )}
                </ViewportList>
              </div>
            </>
          )}
        </div>
        <div className="KetamineGeneral">
          <h5 className="ketmine-header-text">{department.name} Contacts</h5>
          {list.length === 0 && (
            <div className="container">
              <div className="text" style={{ color: '#616161' }}>
                No Contacts Created...
              </div>
            </div>
          )}
          {list.length > 0 && (
            <div className="list-container">
              <ViewportList items={list}>
                {(item: ContactItem, index: any) => {
                  return (
                    <div key={index} style={{ cursor: 'pointer' }}>
                      <RenderContact item={item} index={index} />
                    </div>
                  );
                }}
              </ViewportList>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CreateEditContact;
