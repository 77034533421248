/*
1-2-24: Abhishek: showOnlySideout is added as a prop that makes this component only show the sideout [MedicationDoseSideout] and not the main page to avoid redundancy
*/
import { useFormik } from 'formik';
import * as _ from 'lodash';
import { InputText } from 'primereact/inputtext';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { HiPlus } from 'react-icons/hi';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ViewportList } from 'react-viewport-list';
import * as Yup from 'yup';
import { handleGetDepartment } from '../../../../../store/actions';
import ConfirmModal from '../../../../components/Modal/ConfirmModal';
import DraftSaveModal from '../../../../components/Modal/DraftSaveModal';
import {
  DatabaseResponse,
  Response,
  ResponseType,
} from '../../../../../data/AmplifyDB';
import ElectricalItem from '../../../../../data/model/ElectricalItem';
import ProtocolItem from '../../../../../data/model/ProtocolItem';
import ElectricalSubItem from '../../../../../data/model/ElectricalSubItem';
import { ElectricalShockOption, User } from '../../../../../models';
import { ProgressStatus } from '../../../../../models';
import {
  ElectricalDoseJSON,
  ElectricalJSON,
  createElectrical,
  createElectricalDoseItem,
  deleteElectricalDoseItem,
  deleteElectricalItem,
  duplicateElectricalDose,
  duplicateProtocolElectricalDoses,
  fetchElectrical,
} from '../../../../../data/functions/ElectricalDB';
import {
  findDepartmentOwner,
  getActiveID,
  getFormattedDate,
  globals,
  hasAdminUserAccess,
  upgradeVersion,
} from '../../../../_global/common/Utils';
import {
  isDraftCreated,
  removeCurrentDraftUpdates,
} from '../../../../../data/AmplifyVersion';
import { Col, Row } from 'react-bootstrap';
import ProtocolHeader from '../../ProtocolHeader';
import { UserType } from '../../../../../models';
import ElectricalShockSideout from '../../../../components/SideOut/doseSideOut/ElectricalShockSideout';
import ElectricalDoseUI from './ElectricalDoseUI';
import DepartmentItem from '../../../../../data/model/DepartmentItem';
import AccordionItem from '../../../../components/Accordion/AccordionItem';
import { auditElectricalDosesToOldDBStyle } from '../../../../../data/AmplifyActions';
import {
  handleToggleEnabled,
  isModelItemLockedInReviewal,
} from '../../../../../data/functions/ModelDB';
import { InputSwitch } from 'primereact/inputswitch';
import PatientAge from '../../../../_global/common/PatientAge';
import ReviewalItem from '../../../../../data/model/ReviewalItem';
import DraftChangeItem from '../../../../../data/model/DraftChangeItem';
import { handleLoadReviewAndNavigate } from '../../../../../data/functions/ReviewalDB';
import Loading from '../../../../components/Loading/Loading';
import { Alert, Snackbar } from '@mui/material';
const SHOW_PROTOCOL_ID = false;

interface ElectricalEditPageProps {
  stateData?: any;
  handleCloseSideout?: () => void;
  showOnlySideout?: boolean;
}

/* 11-08-23 Praveen Created Electrical Edit Page component for Equipment layout */
const ElectricalProtocolEditPage: React.FC<ElectricalEditPageProps> = ({
  stateData,
  handleCloseSideout,
  showOnlySideout,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const reducerState = useSelector((state: any) => state.department);
  const {
    selectedProtocol,
    value,
    subValue,
    type,
    editType,
    editMode,
    page,
    showEditDose,
    reviewal,
    currentDraft,
  } = stateData;
  const [protocol, setProtocol] = useState<ProtocolItem>(selectedProtocol);

  const [database, setDatabase] = useState<DatabaseResponse>(
    useSelector((state: any) => state.protocol.departmentItem)
  );
  const user: User = useSelector((state: any) => state.user);
  const department = database.department;
  const adminLevel = useMemo(() => {
    return database.department.adminLevel;
  }, [database.department]);

  const [parmElectrical, setParmElectrical] = useState<ElectricalItem>(value);

  const [allSubElectricalItems, setAllSubElectricalItems] = useState<
    ElectricalSubItem[]
  >(parmElectrical.subItems);
  // Save the initial deactivation status in a ref
  const initialDeactivationStatusIDs = useRef<string[]>([]);

  const [itemDeactivationStatus, setItemDeactivationStatus] = useState<
    ElectricalSubItem[]
  >([]);
  const [selectedItemDeactivationStatus, setSelectedItemDeactivationStatus] =
    useState<ElectricalSubItem[] | null>(null);

  const [draftChangeInReview, setDraftChangeInReview] = useState<
    DraftChangeItem | null | undefined
  >(parmElectrical.draftChange);

  const subElectricalItems = useMemo(() => {
    return selectedProtocol
      ? allSubElectricalItems.filter(
          (item: ElectricalSubItem) =>
            item.parentProtocol.uid === selectedProtocol.uid
        )
      : allSubElectricalItems;
  }, [allSubElectricalItems, selectedProtocol]);

  useEffect(() => {
    const initialStatus = subElectricalItems.filter(
      (item) => item.status === ProgressStatus.DEACTIVATED
    );
    initialDeactivationStatusIDs.current = initialStatus.map(
      (item: ElectricalSubItem) => item.uid
    );
    setItemDeactivationStatus(initialStatus);
  }, [subElectricalItems]);

  const allProtocols = useMemo(() => {
    let prots = allSubElectricalItems.map((m) => m.parentProtocol);
    //Remove all the duplicates
    return prots
      .filter((v, i, a) => a.findIndex((t) => t.uid === v.uid) === i)
      .sort((a, b) => {
        if (a.parent.index === b.parent.index)
          return a.name.localeCompare(b.name);
        return a.parent.index - b.parent.index;
      });
  }, [allSubElectricalItems]);
  const [expanded, setExpanded] = useState<string[]>([]);

  const numDeactivated = useMemo(() => {
    return subElectricalItems.filter(
      (item) => item.status === ProgressStatus.DEACTIVATED
    ).length;
  }, [subElectricalItems]);
  const [showDeactivated, setShowDeactivated] = useState(false);

  const [departmentOwner, setDepartmentOwner] = useState<DepartmentItem | null>(
    findDepartmentOwner(department, reducerState, parmElectrical)
  );
  const [adminAccess, setAdminAccess] = useState(
    hasAdminUserAccess(department, reducerState, user)
  );

  const isOwner = useMemo(() => {
    return departmentOwner?.id === department.id;
  }, [departmentOwner, department]);

  const [doseIndex, setDoseIndex] = useState(subElectricalItems.length);
  const [selectedData, setSelectedData] = useState<ElectricalSubItem | null>(
    showEditDose && subValue ? subValue : null
  );

  const [sidebarVisible, setSidebarVisible] = useState(
    showOnlySideout ? true : false
  );
  const [editDose, setEditDose] = useState(showEditDose ? true : false);

  const [isOpen, setIsOpen] = useState(false);
  const [isConfirmModal, setIsConfirmModal] = useState(false);
  const [isEditMode, setIsEditMode] = useState(
    !draftChangeInReview && adminAccess && editMode
  );
  const [isBlockModal, setIsBlockModal] = useState(false);
  const [loading, setLoading] = useState<string | null>(null);

  const [isWarningModal, setIsWarningModal] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [modifiedBy, setModifiedBy] = useState<User | null>(null);

  const [snackbar, setSnackbar] = useState<any>({
    open: false,
    message: '',
    severity: 'success',
  });

  useEffect(() => {
    let id = parmElectrical.model?.modifiedBy;
    if (id == null) id = parmElectrical.model?.createdBy;
    if (id) {
      let user = database.users.find((u: User) => u.id === id);
      setModifiedBy(user ? user : null);
      if (user) {
        parmElectrical.modifiedBy = user;
      }
    } else {
      setModifiedBy(null);
    }
  }, [database, parmElectrical]);

  /* 11-7-23 intialize the form initial values */
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: parmElectrical.name,
      rangeLow: parmElectrical.rangeLow + '',
      rangeHigh: parmElectrical.rangeHigh + '',
      warning: parmElectrical.model.warning ?? '',
      instruction: parmElectrical.model.instruction ?? '',
      note: parmElectrical.model.note ?? '',
      options: parmElectrical.model.options ?? [],
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Must have a name for the Electrical Shock'),
      rangeLow: Yup.string(),
      rangeHigh: Yup.string(),
      instruction: Yup.string(),
      note: Yup.string(),
      warning: Yup.string(),
      options: Yup.array(),
    }),
    onSubmit: async (values) => {
      if (globals.debug) console.log('SUBMITTING ELECTRICAL', values);
      if (formik.dirty && formik.isValid) {
        const protocolList = findProtocols(values.options);
        const newElectrical: ElectricalJSON = {
          title: formik.values.name,
          rangeLow: !isNaN(Number(formik.values.rangeLow))
            ? Number(formik.values.rangeLow)
            : 0,
          rangeHigh: !isNaN(Number(formik.values.rangeHigh))
            ? Number(formik.values.rangeHigh)
            : globals.MAX_VALUE,
          departmentID: department.id,
          options: values.options,
          taggedProtocols: protocolList,

          instruction: formik.values.instruction,
          note: formik.values.note,
          warning: formik.values.warning,
          createdBy: parmElectrical.model.createdBy
            ? parmElectrical.model.createdBy
            : '',
          modifiedBy: user.id,

          status: ProgressStatus.DRAFT,
          activeID:
            parmElectrical.status === ProgressStatus.ACTIVE
              ? parmElectrical.uid
              : parmElectrical.activeID,
          version:
            parmElectrical.status === ProgressStatus.ACTIVE
              ? upgradeVersion(
                  parmElectrical.version == null
                    ? 'v1.0.0'
                    : parmElectrical.version
                )
              : parmElectrical.version == null
                ? 'v1.0.0'
                : parmElectrical.version,
        };

        let results: Response = await createElectrical(
          newElectrical,
          parmElectrical
        );
        if (results.type === ResponseType.Success) {
          if (globals.debug)
            console.log('Successfully created Electrical', results.data);
          let newElectrical: ElectricalItem = results.data;
          updateElectrical(newElectrical);
        }
        formik.resetForm();
      }
    },
  });

  /**
   * Checks if the item is locked in an ACTIVE Reviewal Process.
   */
  useEffect(() => {
    if (parmElectrical.draftChange != null) {
      setDraftChangeInReview(parmElectrical.draftChange);
    } else {
      isModelItemLockedInReviewal(parmElectrical, database).then(
        (draftChange: DraftChangeItem | null) => {
          setDraftChangeInReview(draftChange);
        }
      );
    }
  }, [database, parmElectrical]);

  /* After reloading the database update the electrical item */
  useEffect(() => {
    if (reviewal == null) reloadDatabase();
  }, []);

  useEffect(() => {
    if (value) {
      setParmElectrical(value);
      setAllSubElectricalItems(value.subItems);
      formik.resetForm();
    }
  }, [value]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.ctrlKey && event.key === 'b') handleBack();
      else if (event.ctrlKey && event.key === 'e' && !isEditMode) {
        handleCheckIsDraft().then((isDraft: boolean) => {
          if (!isDraft && !isEditMode) setIsEditMode(true);
        });
      } else if (event.ctrlKey && event.key === 's' && isEditMode)
        formik.submitForm();
      else if (event.key === 'Escape') handleCancel();
    };

    // Add event listener
    document.addEventListener('keydown', handleKeyDown);

    // Clean up event listener on component unmount
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [formik, isEditMode]);

  /** e68bc8f5-c88b-4b5f-9bcc-153ec603c9a5
   * Find the protocols from the electrical options
   * @param options The options to search for
   * @returns The protocols that were found
   */
  const findProtocols = (options: ElectricalShockOption[]): ProtocolItem[] => {
    let protocols: ProtocolItem[] = [];
    for (let i = 0; i < options.length; i++) {
      let po = options[i];
      let protocol = database.protocols.find((p: ProtocolItem) => {
        let id =
          p.status === ProgressStatus.ACTIVE ||
          (p.status === ProgressStatus.DRAFT && p.activeID == null)
            ? p.uid
            : p.activeID;
        return id === po.protocolID;
      });
      if (protocol) protocols.push(protocol);
    }
    return protocols;
  };

  /**
   * Update the formik and reload the database
   * @param newElectrical The new electrical item to update the form with
   */
  const updateElectrical = (newElectrical: ElectricalItem) => {
    reloadDatabase();
    formik.setValues({
      ...formik.values,
      name: newElectrical.name,
      rangeLow: newElectrical.rangeLow + '',
      rangeHigh: newElectrical.rangeHigh + '',
      instruction: newElectrical.model.instruction ?? '',
      note: newElectrical.model.note ?? '',
      warning: newElectrical.model.warning ?? '',
      options: newElectrical.model.options ?? [],
    });
  };

  /**
   * Reload the database from the server
   * @returns true when the database is reloaded
   */
  const reloadDatabase = async (parmID?: string): Promise<boolean> => {
    /* 1-10-24 Hazlett:  Update the current data to the database change and keep the current state */
    const resp: Response = await fetchElectrical(
      database.department,
      database,
      true,
      true
    );
    if (resp.type === ResponseType.Success) {
      let electricals = resp.data as ElectricalItem[];
      dispatch<any>(
        handleGetDepartment({
          ...database,
          electrical: electricals,
        } as DatabaseResponse)
      );

      /* Search for the updated electrical item in the database - ALWAYS search for activeID
       *  Use cases:
       *  1.  Electrical was DRAFT FIRST RELEASE and updated item - match uid
       *  2.  Electrical was ACTIVE and updated item - match old uid to new activeID
       */
      let updatedElectrical: ElectricalItem | null = null;
      let electricalID = getActiveID(parmElectrical);
      for (let i = 0; i < electricals.length; i++) {
        let elecID = getActiveID(electricals[i]);
        if (elecID === electricalID) {
          updatedElectrical = electricals[i];
          break;
        }
      }

      /* Search for the right sub medications */
      if (updatedElectrical) {
        if (globals.debug)
          console.log('Found updated electrical', updatedElectrical);
        setParmElectrical(updatedElectrical);
        setAllSubElectricalItems(updatedElectrical.subItems);
      } else if (globals.debug)
        console.log('Failed to find updated electrical');
      return true;
    } else {
      console.error('ERROR LOADING DATABASE', resp.data);
      return false;
    }
  };

  /**
   * Todo have this open up a sideout to view the history of the medication
   */
  const handleVersion = () => {
    /* Output why the formik is dirty in the console */
  };

  /**
   * Back button has been pressed
   */
  const handleBack = () => {
    /* If the form is dirty, then show the warning modal */
    if (formik.dirty && !isWarningModal) {
      setIsWarningModal(true);
    } else if (protocol) {
      const formattedString = protocol.nickname.replace(/[\s\/]/g, '-');
      navigate(`/${formattedString}/protocol-detail`, {
        state: { selectedProtocol: protocol, editMode: true },
      });
    } else if (reviewal) {
      navigate('/reviewChanges', {
        state: {
          data: reviewal,
          activeDraftChange: currentDraft,
        },
      });
    } else {
      navigate(`/database/list-electricals`, {
        state: {
          department: department,
          data: database.medications,
          database: database,
        },
      });
    }
  };

  /**
   * Cancen button has been pressed reset the formik values
   */
  const handleCancel = () => {
    /* Check if the formik is dirty and if the warning modal is not open */
    if (formik.dirty && !isWarningModal) setIsWarningModal(true);
    /* If the formik is not dirty or abandon has been pressed then reset it */ else {
      formik.resetForm();
      setIsEditMode(false);
      setIsWarningModal(false);
      setAllSubElectricalItems(parmElectrical.subItems);
    }
  };

  /* 11-08-23  Function to handle on Add Dose click*/
  const onAddDoseClick = () => {
    if (isEditMode) {
      setSelectedData(null);
      setEditDose(false);
      setSidebarVisible(!sidebarVisible);
    }
  };

  /* 11-08-23  function to close the modal */
  const handleCloseModal = (e: any) => {
    setSidebarVisible(false);
    setSelectedData(null);
    handleCloseSideout && handleCloseSideout();
  };

  /* 1-23-24 Hazlett:  Function to remove the previous draft and make a new one */
  const handleMakeNew = async () => {
    let response = await removeCurrentDraftUpdates(database);
    if (response.type === ResponseType.Success) {
      if (response.data.length > 0) {
        if (globals.debug)
          console.log('Successfully removed current draft updates', response);
        setIsConfirmModal(false);
        setIsOpen(false);
        setIsEditMode(true);
      }
    } else {
      if (globals.debug)
        console.log('Failed to remove current draft updates', response);
    }
  };

  const handleCheckIsDraft = useCallback(async (): Promise<boolean> => {
    if (isEditMode) return false;
    let response: Response = await isDraftCreated(database.department);
    if (response.type === ResponseType.Success) {
      let isDraft = response.data;
      if (isDraft) setIsOpen(true);
      return isDraft;
    } else {
      if (globals.debug)
        console.log('Failed to check if draft exists', response);
    }
    return false;
  }, [database.department, isEditMode]);

  const handleOnSubmitElectricalShock = async (
    data: any,
    previousDose: ElectricalSubItem | undefined,
    parentProtocol: ProtocolItem | undefined
  ) => {
    const protocol = parentProtocol ? parentProtocol : selectedProtocol;
    let protocolID =
      protocol.status === ProgressStatus.ACTIVE || protocol.activeID == null
        ? protocol.uid
        : protocol.activeID;

    if (!protocolID) {
      console.error('Failed to find protocol ID');
      return;
    }

    /* Create a new ElectricalDose for the medicaiton */
    let prevDose = previousDose as ElectricalSubItem;
    const isOwner = prevDose ? department.id === prevDose.departmentID : true;
    let index = prevDose
      ? prevDose.index
      : (protocol as ProtocolItem).electrical.length;
    if (department.parentDep?.parentDep) index += 10000;
    else if (department.parentDep) index += 1000;

    let newMedDose: ElectricalDoseJSON = {
      departmentID: department.id,
      electrical: parmElectrical,
      protocol: protocol,
      rangeLow: data.rangeLow ? Number(data.rangeLow) : 0,
      rangeHigh: data.rangeHigh ? Number(data.rangeHigh) : globals.MAX_VALUE,
      createdBy: user.id,
      basis: data.basis,
      title: data.title,
      warning: data.warning,
      instruction: data.instruction,
      note: data.note,
      maxDose: data.maxDose,
      minDose: data.minDose,
      maxTotalDose: data.maxTotalDose,
      calcMax: data.calcMax,
      calcMin: data.calcMin,
      index: index,
      ageLow: data.ageLow ? (data.ageLow as PatientAge).ageValue : 0,
      ageHigh: data.ageHigh
        ? (data.ageHigh as PatientAge).ageValue
        : globals.MAX_VALUE,
      ageGroup: data.ageGroup,
      repeatTime: data.repeatTime,

      activeID: !isOwner
        ? undefined
        : prevDose == null
          ? null
          : prevDose.status === ProgressStatus.ACTIVE
            ? prevDose.uid
            : prevDose.activeID,

      overrideID: isOwner ? null : prevDose?.uid,
      status: ProgressStatus.DRAFT,
      version:
        prevDose == null
          ? 'v1.0.0'
          : prevDose.status === ProgressStatus.ACTIVE
            ? upgradeVersion(prevDose.version)
            : prevDose.version,
      createdAt: prevDose?.createdAt ? prevDose.createdAt : new Date(),
    };

    createElectricalDoseItem(newMedDose, prevDose).then(
      (response: Response) => {
        if (response.type === ResponseType.Success) {
          let newMedSubItem = response.data as ElectricalSubItem;
          newMedSubItem.activeItem = prevDose ? prevDose : null;
          let allSubMeds = prevDose
            ? allSubElectricalItems.filter(
                (item: ElectricalSubItem) => item !== prevDose
              )
            : allSubElectricalItems;
          setAllSubElectricalItems([...allSubMeds, newMedSubItem]);
        }
      }
    );
  };

  const handleCopyProtocolDoses = async (
    toProtocol: ProtocolItem,
    fromProtocol: ProtocolItem
  ) => {
    /* First find the Medication Protocol */
    let response: Response = await duplicateProtocolElectricalDoses(
      department,
      fromProtocol,
      toProtocol,
      user,
      parmElectrical
    );

    /* Get the successes and errors */
    let successes = response.data.successes;
    let errors = response.data.errors;

    let infusions = [...allSubElectricalItems];
    for (let i = 0; i < successes.length; i++) {
      let result: ElectricalSubItem = successes[i];
      infusions.push(result);
    }
    setAllSubElectricalItems(infusions);

    if (errors.length > 0) {
      console.error('Failed to duplicate electrical doses', errors);
      setSnackbar({
        open: true,
        message:
          'Failed to duplicate ' +
          parmElectrical.name +
          ' ' +
          errors.length +
          ' doses',
        severity: 'error',
      });
    }
  };

  const handleRemoveElectricalDose = async (
    dose: ElectricalSubItem,
    type: 'block' | 'restore' | 'delete'
  ) => {
    let subMeds = [...subElectricalItems];

    if (type === 'block') {
      let resp: Response = await handleToggleEnabled(
        user,
        department,
        reducerState,
        dose
      );
      if (resp.type === ResponseType.Success) {
        let newDose: ElectricalSubItem = resp.data;
        let allDoses = allSubElectricalItems.filter(
          (item: ElectricalSubItem) => item !== dose
        );
        setAllSubElectricalItems([...allDoses, newDose]);
        reloadDatabase(parmElectrical.uid);
      }
      // let clone = cloneElectricalSubItem(dose);
      // clone.status = ProgressStatus.DEACTIVATED;
      // clone.departmentID = department.id;
      // clone.modifiedBy = user;
      // clone.overrideID =
      //   dose.status.includes('DRAFT') && dose.activeID
      //     ? dose.activeID
      //     : dose.uid;
      // clone.activeID = undefined;

      // let resp = await createElectricalDoseItem(clone, undefined);
      // if (resp.type === ResponseType.Success) {
      //   console.log('Successfully blocked dose', resp);
      //   let newDose: ElectricalSubItem = resp.data;
      //   newDose.overrideItem = dose;
      //   let allDoses = [...new Set([...allSubElectricalItems, ...subMeds])];
      //   allDoses = allDoses.filter((item: ElectricalSubItem) => item !== dose);
      //   setAllSubElectricalItems([...allDoses, newDose]);

      //   reloadDatabase(parmElectrical.uid);
      // }
    } else if (
      /* Use Cases: Created a new dose and deleted it, or deleted an existing dose */
      dose.status === ProgressStatus.DEACTIVATED ||
      dose.status === ProgressStatus.DRAFT
    ) {
      let resp = await deleteElectricalDoseItem(dose, false);
      if (resp.type === ResponseType.Success) {
        let deletedDose: ElectricalSubItem = resp.data;

        let allDoses = allSubElectricalItems.filter(
          (item: ElectricalSubItem) => item !== dose
        );
        if (
          dose.status === ProgressStatus.DEACTIVATED &&
          deletedDose.overrideItem
        )
          allDoses.push(deletedDose.overrideItem);
        setAllSubElectricalItems([...allDoses]);
        reloadDatabase(parmElectrical.uid);
        auditElectricalDosesToOldDBStyle([deletedDose])
          .then((response) => {
            if (response.type === ResponseType.Success) {
              console.log('Successfully audited doses', response.data);
            }
          })
          .catch((error) => {
            console.error('Failed to audit doses', error);
          });
      }
    }
    // else if (
    //   dose.status === ProgressStatus.DRAFT_DELETE &&
    //   type === 'restore' &&
    //   (dose.activeItem || dose.overrideItem)
    // ) {
    //   let index = subElectricalItems.findIndex(
    //     (item: ElectricalSubItem) => item === dose
    //   );

    //   let item = dose.activeItem ? dose.activeItem : dose.overrideItem;

    //   let newMedDose = new ElectricalSubItem(
    //     dose.parent,
    //     dose.parentProtocol,
    //     (item as ElectricalSubItem).model
    //   );
    //   console.log('Setting new dose', newMedDose, index);
    //   //Replace the deleted dose with the active dose

    //   subMeds[index] = newMedDose;
    //   deleteElectricalDoseItem(dose, false)
    //     .then((resp) => {
    //       console.log('Deleted Dose Resp', resp);
    //       if (resp.type === ResponseType.Success) {
    //         reloadDatabase(parmElectrical.uid);
    //       }
    //     })
    //     .catch((error) => {
    //       console.error('Failed to delete dose', error);
    //     });
    // }
    else {
      let resp = await deleteElectricalDoseItem(dose, true);
      if (resp.type === ResponseType.Success) {
        let deletedDose: ElectricalSubItem = resp.data;
        if (globals.debug) console.log('Deleted Dose', deletedDose);
        let allDoses = allSubElectricalItems.filter(
          (item: ElectricalSubItem) => item !== dose
        );
        setAllSubElectricalItems([...allDoses]);
        reloadDatabase(parmElectrical.uid);
        auditElectricalDosesToOldDBStyle([deletedDose])
          .then((response) => {
            if (response.type === ResponseType.Success) {
              console.log('Successfully audited doses', response.data);
            }
          })
          .catch((error) => {
            console.error('Failed to audit doses', error);
          });
      }
    }
  };

  const handleActivateState = async () => {
    try {
      let res: Response = await handleToggleEnabled(
        user,
        department,
        reducerState,
        parmElectrical
      );
      if (res.type === ResponseType.Success) {
        let updated: ElectricalItem = res.data;
        console.log('Updated Electrical', updated);
        setParmElectrical(updated);
        reloadDatabase(updated.uid);
      }
    } catch (error) {
      console.error('Error toggling enabled state', error);
    }

    setIsBlockModal(false);
  };

  const handleDelete = () => {
    setIsDelete(true);
  };

  const handleConfirmDeleteItem = async () => {
    const isDraft = parmElectrical.status === ProgressStatus.DRAFT;
    let response = await deleteElectricalItem(parmElectrical, !isDraft);
    if (response.type === ResponseType.Success) {
      handleBack();
    } else {
      console.error('Failed to delete electrical', response.data);
    }
  };

  const Modals = () => {
    return (
      <>
        <Snackbar
          open={snackbar.open}
          autoHideDuration={3000}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Alert
            onClose={() => setSnackbar({ ...snackbar, open: false })}
            severity={snackbar.severity}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
        {isOpen && (
          <DraftSaveModal
            database={database}
            isVisible={isOpen}
            handleClose={() => {
              setIsOpen(false);
            }}
            handleContinue={() => {
              setIsEditMode(true);
              setIsOpen(false);
            }}
            handleNew={() => {
              setIsConfirmModal(true);
            }}
          />
        )}
        {isBlockModal && (
          <ConfirmModal
            isVisible={isBlockModal}
            title={`${
              parmElectrical.status === ProgressStatus.DEACTIVATED
                ? 'Reactivate'
                : 'Deactivate'
            } ${departmentOwner?.name} ${parmElectrical.name} Electrical?`}
            handleClose={() => {
              setIsBlockModal(false);
            }}
            handleSubmit={
              () => handleActivateState()
              // handleDeleteRestoreBlockDose(parmElectrical.status === ProgressStatus.DEACTIVATED ? 'restore' : 'block')
            }
            isDeleteBtn={parmElectrical.status !== ProgressStatus.DEACTIVATED}
            primaryBtnName="Cancel"
            secondaryBtnName={
              parmElectrical.status === ProgressStatus.DEACTIVATED
                ? 'Reactivate'
                : 'Deactivate'
            }
            primaryDescription={`This will ${parmElectrical.status === ProgressStatus.DEACTIVATED ? 'reactivate' : 'deactivate'} the ${departmentOwner?.name} ${parmElectrical?.name} Electricals for ${database.department?.name}. ${parmElectrical.status === ProgressStatus.DEACTIVATED ? '' : 'This can be reactiveated at any time.'}`}
          />
        )}
        {isWarningModal && (
          <ConfirmModal
            isVisible={isWarningModal}
            title="Abandon Changes?"
            handleClose={() => {
              setIsWarningModal(false);
            }}
            handleSubmit={handleBack}
            isDeleteBtn={true}
            primaryBtnName="Cancel"
            secondaryBtnName="Abandon"
            primaryDescription={`Changes were made to this Electrical.  Click cancel to return to Electrical details.  To continue without saving changes, select Abandon.`}
          />
        )}
        {isConfirmModal && (
          <ConfirmModal
            isVisible={isConfirmModal}
            title="Make New Draft?"
            handleClose={() => {
              setIsConfirmModal(false);
            }}
            handleSubmit={handleMakeNew}
            isDeleteBtn={true}
            primaryBtnName="Cancel"
            secondaryBtnName="Make New"
            primaryDescription="Are you sure you would like to remove the 11 detected changes from the previous draft and override it and create a new one."
          />
        )}

        {isDelete && (
          <ConfirmModal
            isVisible={isDelete}
            title={'Delete ' + parmElectrical.name + '?'}
            handleClose={() => {
              setIsDelete(false);
            }}
            handleSubmit={handleConfirmDeleteItem}
            isDeleteBtn={true}
            primaryBtnName="Cancel"
            secondaryBtnName="Delete"
            primaryDescription={`Are you sure you would like to delete ${parmElectrical.name}?`}
          />
        )}

        <ElectricalShockSideout
          visible={sidebarVisible}
          doseIndex={doseIndex}
          parentModel={parmElectrical}
          protocol={protocol}
          allProtocols={allProtocols}
          dose={selectedData ? selectedData : undefined}
          subElectrialItems={allSubElectricalItems}
          setVisible={handleCloseModal}
          editDose={editDose}
          onSubmit={handleOnSubmitElectricalShock}
          onRemove={handleRemoveElectricalDose}
          onSubmitMultiDose={handleCopyProtocolDoses}
        />
      </>
    );
  };

  const handleToggleEnabledUI = async (item: ElectricalSubItem) => {
    // Check if the item is already in itemDeactivationStatus
    const isItemDeactivated = itemDeactivationStatus.some(
      (deactivatedItem) => deactivatedItem.uid === item.uid
    );

    // Update itemDeactivationStatus
    if (isItemDeactivated) {
      // Remove the item if it's already deactivated
      setItemDeactivationStatus(
        itemDeactivationStatus.filter((newItem) => newItem.uid !== item.uid)
      );
    } else {
      // Add the item if it's not deactivated
      setItemDeactivationStatus([...itemDeactivationStatus, item]);
    }

    // Update selectedItemDeactivationStatus
    setSelectedItemDeactivationStatus((prevSelected) => {
      const currentSelected = prevSelected || [];

      const isItemSelected = currentSelected.some(
        (selectedItem) => selectedItem.uid === item.uid
      );

      if (isItemSelected) {
        // Remove the item if it's already selected
        return currentSelected.filter(
          (selectedItem) => selectedItem.uid !== item.uid
        );
      } else {
        // Add the item if it's not selected
        return [...currentSelected, item];
      }
    });
  };

  const handleSaveFormik = async () => {
    if (formik.dirty && formik.isValid) {
      formik.submitForm();
    } else if (
      selectedItemDeactivationStatus &&
      selectedItemDeactivationStatus.length > 0
    ) {
      try {
        console.log(
          'selectedItemDeactivationStatus',
          selectedItemDeactivationStatus
        );
        let promises: any[] = [];
        for (let i = 0; i < selectedItemDeactivationStatus.length; i++) {
          console.log(
            'UPDATING itemDeactivationStatus[' + i + ']',
            selectedItemDeactivationStatus[i]
          );
          promises.push(
            handleToggleEnabled(
              user,
              database.department,
              reducerState,
              selectedItemDeactivationStatus[i]
            )
          );
        }
        console.log('PROMISES', promises);
        let results = await Promise.all(promises);
        console.log('results', results);
        for (let i = 0; i < results.length; i++) {
          if (results[i].type === ResponseType.Success) {
            if (globals.debug) console.log('SAVED ITEM', results[i].data);
          } else {
            console.error('ERROR SAVING ITEM', results[i].data);
          }
        }
        console.log('RELOADING DATABASE');

        setAllSubElectricalItems(
          allSubElectricalItems.map((item: ElectricalSubItem) => {
            const updatedItem = results.find(
              (result) => result.data.uid === item.uid
            );
            return updatedItem ? updatedItem.data : item;
          })
        );
        setSelectedItemDeactivationStatus([]);
        reloadDatabase(parmElectrical.uid);
        setIsEditMode(false);
      } catch (error) {
        console.error('ERROR SAVING DEACTIVATE STATE', error);
      }
    } else {
      setIsEditMode(false);
    }
  };

  const ListRender = ({ data }: any) => {
    let list = data
      .filter(
        (item: ElectricalSubItem) =>
          showDeactivated || item.status !== ProgressStatus.DEACTIVATED
      )
      .sort((a: ElectricalSubItem, b: ElectricalSubItem) => {
        if (a.index === b.index) return a.rangeLow - b.rangeLow;
        return a.index - b.index;
      });

    return (
      <ViewportList items={list}>
        {(dose: ElectricalSubItem, index: number) => {
          const lastIndex = data.length === index + 1;
          return (
            <div
              key={index}
              style={{ cursor: 'pointer' }}
              onClick={(e) => {
                if (isEditMode) {
                  e.stopPropagation();
                  setSelectedData(dose);
                  setDoseIndex(index);
                  setEditDose(true);
                  setSidebarVisible(true);
                }
              }}
            >
              <ElectricalDoseUI
                dose={dose}
                doseIndex={index + 1}
                showProtocol={false}
                isEditMode={isEditMode}
                onToggle={handleToggleEnabledUI}
                isToggled={
                  itemDeactivationStatus.find(
                    (newItem) => newItem.uid === dose.uid
                  )
                    ? false
                    : true
                }
              />
            </div>
          );
        }}
      </ViewportList>
    );
  };

  const ProtocolItemUI = ({ protocol }: any) => {
    const doses = allSubElectricalItems.filter(
      (item: ElectricalSubItem) => item.parentProtocol.uid === protocol.uid
    );
    doses
      .filter(
        (item: ElectricalSubItem) =>
          showDeactivated || item.status !== ProgressStatus.DEACTIVATED
      )
      .sort((a: ElectricalSubItem, b: ElectricalSubItem) => {
        if (a.index === b.index) return a.rangeLow - b.rangeLow;
        return a.index - b.index;
      });

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          padding: '10px',
          marginTop: '.25rem',
          marginBottom: '.25rem',
        }}
        onClick={(e) => e.stopPropagation()} // Add this to prevent event bubbling
      >
        <AccordionItem
          title={protocol.name}
          titleStyle={{ fontWeight: '400' }}
          rightTitle={`${doses.length} doses`}
          rightTitleStyle={{ fontWeight: '700' }}
          expanded={expanded.includes(protocol.uid)}
          onChange={() => {
            if (expanded.includes(protocol.uid)) {
              setExpanded(expanded.filter((item) => item !== protocol.uid));
            } else {
              setExpanded([...expanded, protocol.uid]);
            }
          }}
        >
          <div
            style={{ flex: 1, padding: '0 10px 10px 10px' }}
            onClick={(e) => e.stopPropagation()} // Add this to prevent event bubbling
          >
            <ListRender data={doses} />
          </div>
        </AccordionItem>
      </div>
    );
  };

  return (
    <div className={showOnlySideout ? '' : 'screen-container'}>
      <Modals />
      {!showOnlySideout && (
        <>
          <ProtocolHeader
            name={
              parmElectrical.name
              // (parmElectrical.status === "DRAFT" ? " (Draft)" : "")
            }
            status={parmElectrical.status}
            isBackButton={true}
            protocolDetail={protocol}
            page={
              reviewal
                ? (reviewal as ReviewalItem).name
                : protocol
                  ? protocol.name
                  : 'Electricals'
            }
            type={'protocol'}
            rightSideBtn={isEditMode ? 'save' : 'edit'}
            isSaveButton={isEditMode}
            isSaveActive={
              (formik.dirty && formik.isValid) ||
              !(
                initialDeactivationStatusIDs.current.length ===
                  itemDeactivationStatus.length &&
                initialDeactivationStatusIDs.current.every((id: any) =>
                  itemDeactivationStatus.some(
                    (item: ElectricalSubItem) => item.uid === id
                  )
                )
              )
            }
            isCopyDescription={user.type === UserType.ADMIN}
            descriptionTitle={user.type === UserType.ADMIN ? 'ID:' : ''}
            description={user.type === UserType.ADMIN ? parmElectrical.uid : ''}
            // isVersionButton={true}
            isDeactivateButton={
              isEditMode &&
              protocol == null &&
              (adminLevel === 1 || adminLevel === 3) &&
              parmElectrical.status !== ProgressStatus.DEACTIVATED &&
              !isOwner
            }
            isDeactivateActive={isEditMode}
            isReactivateActive={isEditMode}
            isReactivateButton={
              isEditMode && parmElectrical.status === ProgressStatus.DEACTIVATED
            }
            handleDeactivate={() => {
              setIsBlockModal(true);
            }}
            handleReactivate={() => setIsBlockModal(true)}
            draftChangeLocked={draftChangeInReview}
            handleReviewClicked={() => {
              if (draftChangeInReview) {
                setLoading('Loading Review...');
                handleLoadReviewAndNavigate(
                  database,
                  draftChangeInReview,
                  user,
                  dispatch,
                  navigate
                ).catch((error) => {
                  console.error('Failed to load review and navigate', error);
                  setLoading(null);
                });
              }
            }}
            isDeleteButton={
              isEditMode && protocol == null && adminLevel > 2 && isOwner
            }
            isEditButton={
              !draftChangeInReview &&
              reviewal == null &&
              !isEditMode &&
              adminAccess
            }
            isDotButton={true}
            handleCancel={handleBack}
            handleCancelEdit={handleCancel}
            handleEdit={() => {
              handleCheckIsDraft().then((isDraft: boolean) => {
                if (!isDraft && !isEditMode) setIsEditMode(true);
              });
            }}
            handleDelete={handleDelete}
            handleSave={() => handleSaveFormik()}
            handleVersion={handleVersion}
          />

          <div className="ketamineContent">
            <div className="KetamineGeneral">
              <h5 className="ketmine-header-text">General Information</h5>
              <div className="input-container roundBorder">
                <div
                  style={{ display: 'flex', marginTop: '5px' }}
                  className="ketamine-general-label"
                >
                  <div style={{ marginRight: '10px' }}>Modified By:</div>
                  <div style={{ fontWeight: '500' }}>
                    {modifiedBy
                      ? modifiedBy.firstName + ' ' + modifiedBy.lastName
                      : 'Hinckley Medical'}
                  </div>
                </div>
                {parmElectrical.model?.updatedAt && (
                  <div
                    style={{ display: 'flex' }}
                    className="ketamine-general-label"
                  >
                    <div style={{ marginRight: '10px' }}>Last Updated:</div>
                    <div style={{ fontWeight: '500' }}>
                      {getFormattedDate(parmElectrical.model.updatedAt, true)}
                    </div>
                  </div>
                )}
                {/* {notification.notifyType && (
							<div style={{ display: 'flex' }} className='ketamine-general-label'>
							<div style={{ marginRight: '10px' }}>
								Style:
							</div>
							<div style={{fontWeight: '500'}}>
								{notification.notifyType}
							</div>
							</div>
						)} */}
                <div
                  style={{ display: 'flex' }}
                  className="ketamine-general-label"
                >
                  <div style={{ marginRight: '10px' }}>Version:</div>
                  <div style={{ fontWeight: '500' }}>
                    {parmElectrical.version}
                  </div>
                </div>
              </div>
              <label htmlFor="Name" className="ketamine-general-label">
                Name<span className="required-field">*</span>
              </label>
              <div className="input-container">
                <InputText
                  type="text"
                  className="form-control-general"
                  id="name"
                  name="name"
                  required={true}
                  data-testid="name"
                  value={formik.values.name}
                  onChange={(e: any) => {
                    formik.setFieldValue('name', e.target.value);
                  }}
                  onBlur={formik.handleBlur}
                  disabled={!isEditMode || adminLevel < 3}
                />
                <div className="input-border"></div>
              </div>
              {formik.touched.name && formik.errors.name ? (
                <span className="errorText">{formik.errors.name}</span>
              ) : null}
              <Row>
                <Col sm={6}>
                  <label htmlFor="rangeLow" className="ketamine-general-label">
                    Range Low (kg)
                  </label>
                  <div className="input-container">
                    <InputText
                      type="text"
                      className="form-control-general"
                      id="name"
                      name="rangeLow"
                      data-testid="rangeLow"
                      value={
                        formik.values.rangeLow &&
                        Number(formik.values.rangeLow) !== 0
                          ? formik.values.rangeLow
                          : ''
                      }
                      // style={{color: Number(formik.values.rangeLow) ? '#9e9e9e' : '#000000'}}
                      placeholder="Min"
                      onChange={(e: any) => {
                        /* Check for "Min" */
                        let n = Number(e.target.value);
                        if (isNaN(n))
                          formik.setFieldError('rangeLow', 'Invalid input');
                        else formik.setFieldValue('rangeLow', e.target.value);
                      }}
                      onBlur={formik.handleBlur}
                      disabled={!isEditMode || adminLevel < 3}
                    />
                    <div className="input-border"></div>
                  </div>
                  {formik.touched.rangeLow && formik.errors.rangeLow ? (
                    <span className="errorText">{formik.errors.rangeLow}</span>
                  ) : null}
                </Col>
                <Col sm={6}>
                  <label htmlFor="rangeHigh" className="ketamine-general-label">
                    Range High (kg)
                  </label>
                  <div className="input-container">
                    <InputText
                      type="text"
                      className="form-control-general"
                      id="rangeHigh"
                      name="rangeHigh"
                      data-testid="rangeHigh"
                      disabled={!isEditMode || adminLevel < 3}
                      value={
                        formik.values.rangeHigh &&
                        Number(formik.values.rangeHigh) !== globals.MAX_VALUE
                          ? formik.values.rangeHigh
                          : ''
                      }
                      placeholder="Max"
                      onChange={(e: any) => {
                        let n = Number(e.target.value);
                        if (isNaN(n))
                          formik.setFieldError('rangeHigh', 'Invalid input');
                        else formik.setFieldValue('rangeHigh', e.target.value);
                      }}
                      onBlur={formik.handleBlur}
                    />
                    <div className="input-border"></div>
                  </div>
                  {formik.touched.rangeHigh && formik.errors.rangeHigh ? (
                    <span className="errorText">{formik.errors.rangeHigh}</span>
                  ) : null}
                </Col>
              </Row>
              <label htmlFor="Warning" className="ketamine-general-label">
                Warning
              </label>
              <div className="input-container">
                <InputText
                  type="text"
                  className="form-control-general"
                  id="warning"
                  name="warning"
                  required={true}
                  data-testid="warning"
                  value={formik.values.warning}
                  disabled={!isEditMode || adminLevel < 3}
                  onChange={(e: any) => {
                    formik.setFieldValue('warning', e.target.value);
                  }}
                  onBlur={formik.handleBlur}
                />
                <div className="input-border"></div>
              </div>
              <label htmlFor="Instruction" className="ketamine-general-label">
                Instruction
              </label>
              <div className="input-container">
                <InputText
                  type="text"
                  className="form-control-general"
                  id="instruction"
                  name="instruction"
                  required={true}
                  data-testid="instruction"
                  value={formik.values.instruction}
                  disabled={!isEditMode || adminLevel < 3}
                  onChange={(e: any) => {
                    formik.setFieldValue('instruction', e.target.value);
                  }}
                  onBlur={formik.handleBlur}
                />
                <div className="input-border"></div>
              </div>
              <label htmlFor="Note" className="ketamine-general-label">
                Note
              </label>
              <div className="input-container">
                <InputText
                  type="text"
                  className="form-control-general"
                  id="note"
                  name="note"
                  required={true}
                  data-testid="note"
                  value={formik.values.note}
                  disabled={!isEditMode || adminLevel < 3}
                  onChange={(e: any) => {
                    formik.setFieldValue('note', e.target.value);
                  }}
                  onBlur={formik.handleBlur}
                />
                <div className="input-border"></div>
              </div>
            </div>
            <div className="KetamineGeneral">
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginBottom: '10px',
                }}
              >
                <h5 className="ketmine-header-text">
                  Electrical Shocks
                  <span
                    className="no-select"
                    style={{
                      fontSize: '12px',
                      marginLeft: '10px',
                      marginTop: '5px',
                    }}
                    onClick={() => {
                      if (allProtocols.length === expanded.length)
                        setExpanded([]);
                      else setExpanded(allProtocols.map((p) => p.uid));
                    }}
                  >
                    <div className="clickableText">
                      {expanded.length === allProtocols.length
                        ? 'Collapse All'
                        : 'Expand All'}
                    </div>
                  </span>
                </h5>
                {numDeactivated > 0 && (
                  <InputSwitch
                    tooltip={'Show ' + numDeactivated + ' Deactivated Items'}
                    tooltipOptions={{
                      position: 'bottom',
                      style: { fontSize: '12px' },
                    }}
                    name="isSolidOnly"
                    style={{ marginLeft: '10px' }}
                    checked={showDeactivated}
                    onChange={(e) => {
                      setShowDeactivated(e.value);
                    }}
                  />
                )}
              </div>
              {isEditMode && adminLevel > 2 && (
                <div style={{ marginTop: '5px' }}>
                  <span className="ketamine-general-label">Shocks</span>
                  <div
                    onClick={onAddDoseClick}
                    className={`contentBorder protocolCalculationPad contentHeadingBold newProtocolBorder  ${
                      isEditMode && 'cursorPointer newRouteButton'
                    }`}
                    style={{ display: 'flex', justifyContent: 'center' }}
                  >
                    <span
                      style={{
                        textDecoration: 'underLine',
                        textUnderlinePosition: 'under',
                      }}
                    >
                      <HiPlus className="text-icon " /> Add Shock
                    </span>
                  </div>
                </div>
              )}

              {protocol ? (
                <ViewportList
                  items={subElectricalItems.filter(
                    (item) =>
                      showDeactivated ||
                      item.status !== ProgressStatus.DEACTIVATED
                  )}
                >
                  {(dose: ElectricalSubItem, index: number) => {
                    return (
                      <div
                        key={index}
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          if (isEditMode) {
                            setSelectedData(dose);
                            setDoseIndex(index);
                            setEditDose(true);
                            setSidebarVisible(true);
                          }
                        }}
                      >
                        <ElectricalDoseUI
                          dose={dose}
                          doseIndex={index + 1}
                          showProtocol={protocol == null}
                          isEditMode={isEditMode}
                          onToggle={handleToggleEnabledUI}
                          isToggled={
                            itemDeactivationStatus.find(
                              (newItem) => newItem.uid === dose.uid
                            )
                              ? false
                              : true
                          }
                        />
                      </div>
                    );
                  }}
                </ViewportList>
              ) : (
                <>
                  {allProtocols.map((protocol: ProtocolItem, index: number) => {
                    return <ProtocolItemUI key={index} protocol={protocol} />;
                  })}
                </>
              )}
            </div>
          </div>
        </>
      )}
      {loading && <Loading type="bubbles" message={loading} />}
    </div>
  );
};

export default ElectricalProtocolEditPage;
