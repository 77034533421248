// Note: EditDB for InfusionItem
// Date: 01/09/2024
// Author: Colton Hazlett
//..................................................................................................
import { DataStore } from '@aws-amplify/datastore';

import {
  BatchQuery,
  DatabaseResponse,
  executeQuery,
  executeSingleQuery,
  mapModelItems,
  mapModelSubItems,
  Response,
  ResponseType,
} from '../AmplifyDB';
import {
  MedicationConcentrationInput,
  MedicationRouteInput,
  PatientAgeGroup,
  ProgressStatus,
} from '../../API';
import {
  findItemByID,
  compareVersions,
  getActiveID,
  globals,
  isObjectEqual,
  removeTypename,
  upgradeVersion,
} from '../../ui/_global/common/Utils';
import DepartmentItem from '../model/DepartmentItem';
import InfusionItem, { cloneInfusion } from '../model/InfusionItem';
import InfusionSubItem, {
  cloneInfusionSubItem,
} from '../model/InfusionSubItem';
import ProtocolItem from '../model/ProtocolItem';
import {
  ProtocolJSON,
  createProtocol,
  getProtocolByID,
  validatePointerID,
} from './ProtocolDB';
import { graphqlOperation } from 'aws-amplify';
import {
  concentrationsByDepartmentID,
  concentrationsByDripID,
  dripsByDepartmentID,
  getDrip,
  getInfusionDose,
  infusionDosesByDepartmentID,
  infusionDosesByDripID,
  listDrips,
  infusionDosesByProtocolID,
  listInfusionDoses,
} from '../../graphql/queries';
import DraftChangeItem, { DraftChangeType } from '../model/DraftChangeItem';
import {
  Concentration,
  Drip,
  InfusionDose,
  MedicationProtocol,
  MedicationRange,
  MedicationRoute,
  ModelMetaData,
  User,
} from '../../models';
import { checkIdUpdateDraftChange, Draft } from '../AmplifyVersion';
import { DraftChangeJSON, updateDraftChangeItem } from './ReviewalDB';
import {
  ArchiveItem,
  copyConcentrationsToStatus,
  findUsersForModelItems,
  setAllArchiveItemsToNewActiveID,
  setAllDosesToNewParentID,
} from './ModelDB';
import { updateDrip, updateInfusionDose } from '../../graphql/mutations';

export type InfusionJSON = {
  name: string;
  concentration: Concentration[];
  rangeLow: number;
  rangeHigh: number;
  departmentID: string;
  routes: string[];
  dripOptions: MedicationProtocol[];
  taggedProtocols?: ProtocolItem[];

  rxNormCode?: string | null | undefined;
  nemsisRoutes?: MedicationRoute[] | null | undefined;
  minDose?: string | null | undefined;
  maxDose?: string | null | undefined;
  metaData?: ModelMetaData | null | undefined;

  contraindication: string | null | undefined;
  note: string | null | undefined;
  warning: string | null | undefined;
  medClass: string | null | undefined;
  action: string | null | undefined;
  indication: string | null | undefined;
  interaction: string | null | undefined;
  onset: string | null | undefined;
  duration: string | null | undefined;

  createdBy: string;
  modifiedBy?: string;

  status: ProgressStatus | keyof typeof ProgressStatus;
  activeID: string | null | undefined;
  overrideID?: string | null | undefined;
  version: string | null | undefined;
};

export type InfusionDoseJSON = {
  departmentID: string;
  infusion: InfusionItem;
  protocol: ProtocolItem;
  dripID?: string;
  protocolID?: string;

  index: number;
  basis: string;
  route: string[];
  nemsisRoutes?: MedicationRouteInput[];

  rangeLow: number;
  rangeHigh: number;
  ageLow?: number | null | undefined;
  ageHigh?: number | null | undefined;
  ageGroup?: PatientAgeGroup | null | undefined;
  repeatTime?: number | null | undefined;

  title?: string;
  warning?: string;
  instruction?: string;
  note?: string;

  maxDose?: string | null | undefined;
  minDose?: string | null | undefined;
  maxTotalDose?: string | null | undefined;
  calcMax?: string | null | undefined;
  calcMin?: string | null | undefined;

  createdBy: string;
  modifiedBy?: string;

  status: ProgressStatus | 'DRAFT' | 'ACTIVE' | 'ARCHIVE' | 'DELETED';
  activeID: string | null | undefined;
  overrideID?: string | null | undefined;
  version: string | null | undefined;
  createdAt: Date;
};

/**
 * Create a new PARENT infusion in the database and choose the version
 * @param infusion InfusionJSON JSON format or Model InfusionItem
 * @returns The successful InfusionItem or the error
 */
export const createParentInfusion = async (
  department: DepartmentItem,
  infusion: InfusionJSON | InfusionItem,
  previousItem?: InfusionItem,
  updateInfusionConcentrations: boolean = true
): Promise<Response> => {
  try {
    let json: InfusionJSON;
    if (infusion instanceof InfusionItem) {
      let medItem = infusion as InfusionItem;
      if (medItem.model == null) {
        return {
          type: ResponseType.Failure,
          data: 'The medItem does not have a database object',
        };
      }

      let protocols: ProtocolItem[] = [];
      for (let i = 0; i < medItem.subItems.length; i++) {
        if (!protocols.includes(medItem.subItems[i].parentProtocol))
          protocols.push(medItem.subItems[i].parentProtocol);
      }

      json = {
        name: medItem.name,
        concentration: medItem.concentrations,
        rangeLow: medItem.rangeLow ? medItem.rangeLow : 0,
        rangeHigh: medItem.rangeHigh ? medItem.rangeHigh : globals.MAX_VALUE,
        departmentID: medItem.departmentID,
        routes: medItem.routes,
        dripOptions: medItem.protocolOptions,
        status: medItem.status,
        overrideID: medItem.overrideID,
        activeID: medItem.activeID,
        version: medItem.version,
        taggedProtocols: protocols,

        rxNormCode: medItem.model.rxNormCode,
        nemsisRoutes: medItem.model.nemsisRoutes,
        minDose: medItem.model.minDose,
        maxDose: medItem.model.maxDose,
        metaData: medItem.model.metaData,

        contraindication: medItem.model.contraindication,
        note: medItem.model.note,
        warning: medItem.model.warning,
        medClass: medItem.model.medClass,
        action: medItem.model.action,
        indication: medItem.model.indication,
        interaction: medItem.model.interaction,
        onset: medItem.model.onset,
        duration: medItem.model.duration,

        createdBy: medItem.model.createdBy ? medItem.model.createdBy : '',
        modifiedBy: medItem.modifiedBy
          ? medItem.modifiedBy.id
          : medItem.model.modifiedBy || undefined,
      };
    } else json = infusion as InfusionJSON;

    /* 
			1. Creating a DRAFT the first time
			2. Creating a DRAFT from an ACTIVE version
			3. Updating a DRAFT from a DRAFT version
			4. Creating a ARCHIVE from an ACTIVE version
		*/

    let nemsisRoutes: MedicationRouteInput[] = [];
    if (json.nemsisRoutes != null) {
      for (let i = 0; i < json.nemsisRoutes.length; i++) {
        let newRoute: MedicationRouteInput = {
          route: json.nemsisRoutes[i].route,
          code: json.nemsisRoutes[i].code,
        };
        nemsisRoutes.push(newRoute);
      }
    }

    /* WARNING this only updates the old MedicationConcentrations */
    let oldConcentrations: MedicationConcentrationInput[] = [];
    if (json.concentration != null) {
      for (let i = 0; i < json.concentration.length; i++) {
        let con = json.concentration[i];
        let newConcentration: MedicationConcentrationInput = {
          firstAmnt: con.firstAmnt,
          secAmnt: con.secAmnt,
          firstUnit: con.firstUnit,
          secUnit: con.secUnit,
        };
        oldConcentrations.push(newConcentration);
      }
    }

    let d: Drip;

    /* Use Case 3: Updating a current DRAFT version */
    if (
      previousItem &&
      previousItem.status.includes('DRAFT') &&
      json.status.includes('DRAFT')
    ) {
      let dbDrip = await DataStore.query(Drip, previousItem.uid);
      if (dbDrip == null) {
        return {
          type: ResponseType.Failure,
          data:
            'The DRAFT Infusion does not exist could not update. ID: ' +
            previousItem.uid,
        };
      }

      d = await DataStore.save(
        Drip.copyOf(dbDrip, (updated) => {
          updated.name = json.name;
          updated.concentration = removeTypename(oldConcentrations);
          updated.rangeLow = json.rangeLow;
          updated.rangeHigh = json.rangeHigh;
          updated.route = json.routes;
          updated.dripOptions = json.dripOptions;

          updated.contraindication = json.contraindication;
          updated.note = json.note;
          updated.warning = json.warning;
          updated.medClass = json.medClass;
          updated.action = json.action;
          updated.indication = json.indication;
          updated.interaction = json.interaction;
          updated.onset = json.onset;
          updated.duration = json.duration;

          updated.modifiedBy = json.modifiedBy;
          updated.nemsisRoutes = removeTypename(nemsisRoutes);
        })
      );
    } else {
      /* Use Case 1, 2, & 4: Creating a DRAFT the first time */
      d = await DataStore.save(
        new Drip({
          name: json.name,
          concentration: removeTypename(oldConcentrations),
          rangeLow: json.rangeLow,
          rangeHigh: json.rangeHigh,
          departmentID: json.departmentID,
          route: json.routes,
          dripOptions: json.dripOptions,

          contraindication: json.contraindication,
          note: json.note,
          warning: json.warning,
          medClass: json.medClass,
          action: json.action,
          indication: json.indication,
          interaction: json.interaction,
          onset: json.onset,
          duration: json.duration,

          createdBy: json.createdBy,
          modifiedBy: json.modifiedBy,

          status: json.status,
          activeID: json.activeID,
          overrideID: json.overrideID,
          version: json.version,

          rxNormCode: json.rxNormCode,
          nemsisRoutes: removeTypename(nemsisRoutes),
          minDose: json.minDose,
          maxDose: json.maxDose,
          metaData: removeTypename(json.metaData),
        })
      );
    }

    let infusItem = new InfusionItem(d);

    if (updateInfusionConcentrations) {
      let concenResp = await updateConcentrations(
        department,
        infusItem,
        json.concentration
      );
      infusItem.concentrations = concenResp.data as Concentration[];
    }

    /* Validate the protocols point to the infusion --TODO need to update this for if there is an ACTIVE ID */
    if (json.taggedProtocols != null) {
      for (let i = 0; i < json.taggedProtocols.length; i++) {
        let protocol = json.taggedProtocols[i];
        let infusID: string | null = getActiveID(infusItem);
        if (infusID == null) {
          return {
            type: ResponseType.Failure,
            data:
              'The infusion does not have a proper version ID ' +
              infusItem.uid +
              ' ' +
              infusItem.activeID +
              ' ' +
              infusItem.status,
          };
        }
        let result = await validatePointerID(
          protocol,
          infusID,
          json.modifiedBy,
          'Infusion'
        );
        if (result.type === ResponseType.Failure) {
          return {
            type: ResponseType.Failure,
            data: result.data,
          };
        }
      }
    }
    return {
      type: ResponseType.Success,
      data: infusItem,
    };
  } catch (e) {
    if (globals.debug) console.log('ERROR CREATING INFUSION:', e, infusion);
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

export const fetchDrips = async (
  dep: DepartmentItem,
  db?: DatabaseResponse,
  useDataStore: boolean = true,
  waitForUsers: boolean = false,
  waitForConcentrations: boolean = false
): Promise<Response> => {
  try {
    const findAllConcentrations = (
      infusions: InfusionItem[],
      useDataStore: boolean = true
    ): Promise<InfusionItem[]> => {
      return new Promise(async (resolve, reject) => {
        try {
          // let promises: Promise<Concentration[]>[] = [];
          let promise: Promise<Concentration[]>;
          if (useDataStore) {
            promise = DataStore.query(Concentration, (c) =>
              c.departmentID.eq(dep.id)
            );
          } else {
            promise = executeQuery(concentrationsByDepartmentID, {
              departmentID: dep.id,
              filter: {
                and: [
                  {
                    _deleted: { ne: true },
                  },
                  {
                    status: {
                      eq: 'ACTIVE',
                    },
                  },
                ],
              },
            });
          }
          let concentrations = await promise;
          for (let i = 0; i < infusions.length; i++) {
            const med = infusions[i];
            med.concentrations = concentrations
              .filter((c) => c.dripID === med.uid)
              .sort((a, b) => {
                let aVal = a.secAmnt ? a.firstAmnt / a.secAmnt : a.firstAmnt;
                let bVal = b.secAmnt ? b.firstAmnt / b.secAmnt : b.firstAmnt;
                return aVal - bVal;
              });
            med.loadingConcentrations = false;
            const activeMed = med.activeItem;
            if (activeMed) {
              activeMed.concentrations = concentrations
                .filter((c) => c.dripID === med.uid)
                .sort((a, b) => {
                  let aVal = a.secAmnt ? a.firstAmnt / a.secAmnt : a.firstAmnt;
                  let bVal = b.secAmnt ? b.firstAmnt / b.secAmnt : b.firstAmnt;
                  return aVal - bVal;
                });
              activeMed.loadingConcentrations = false;
            }
          }
          resolve(infusions);
        } catch (e) {
          reject(e);
        }
      });
    };
    let depIDs = [dep.id];
    if (dep.parentDep) depIDs.push(dep.parentDep.id);
    if (dep.parentDep?.parentDep) depIDs.push(dep.parentDep.parentDep.id);

    let dripList: Drip[];
    if (useDataStore) {
      dripList = await DataStore.query(Drip, (d) =>
        d.and((d) => [
          d.or((d) => depIDs.map((id) => d.departmentID.eq(id))),
          d.and((d) => [d.status.ne('ARCHIVE'), d.status.ne('DELETED')]),
        ])
      );
    } else {
      dripList = await executeQuery(listDrips, {
        filter: {
          and: [
            { or: depIDs.map((id) => ({ departmentID: { eq: id } })) },
            { status: { ne: 'ARCHIVE' } },
            { status: { ne: 'DELETED' } },
          ],
        },
      });
    }

    let infus: InfusionItem[] = [];
    for (let i = 0; i < dripList.length; i++) {
      let infusion = new InfusionItem(dripList[i]);
      mapModelItems(infusion, infus, infusion.status, dep);
    }
    if (waitForUsers)
      await findUsersForModelItems(infus, db?.users || undefined);
    else findUsersForModelItems(infus, db?.users || undefined);

    if (waitForConcentrations) {
      infus = await findAllConcentrations(infus, useDataStore);
    } else findAllConcentrations(infus, useDataStore);

    infus.sort((a, b) => a.getName().localeCompare(b.getName()));

    if (db) {
      let promises: any[] = [];
      for (let i = 0; i < infus.length; i++) {
        promises.push(fetchInfusionDosesForInfusion(db, dep, infus[i]));
        if (promises.length > globals.QUERY_BATCH_SIZE) {
          await Promise.all(promises);
          promises = [];
        }
      }
      if (promises.length > 0) await Promise.all(promises);
    }
    return {
      type: ResponseType.Success,
      data: infus,
    };
  } catch (error) {
    console.error('Error fetching infusions:', error);
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const fetchInfusionDoses = async (
  dep: DepartmentItem
): Promise<Response> => {
  try {
    let depIDs = [dep.id];
    if (dep.parentDep) depIDs.push(dep.parentDep.id);
    if (dep.parentDep?.parentDep) depIDs.push(dep.parentDep.parentDep.id);

    let infusionList = await DataStore.query(InfusionDose, (m) =>
      m.and((m) => [
        m.or((m) => depIDs.map((id) => m.departmentID.eq(id))),
        m.and((m) => [m.status.ne('ARCHIVE'), m.status.ne('DELETED')]),
      ])
    );

    for (let i = 0; i < infusionList.length; i++) {
      let infusion = infusionList[i];
      if (infusion.status.includes('DRAFT') && infusion.departmentID !== dep.id)
        infusionList.splice(i, 1);
    }

    return {
      type: ResponseType.Success,
      data: infusionList,
    };
  } catch (error) {
    console.error('Error fetching infusions:', error);
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const fetchInfusionDosesForInfusion = async (
  db: DatabaseResponse,
  dep: DepartmentItem,
  infusion: InfusionItem,
  useDataStore: boolean = true
): Promise<Response> => {
  try {
    let depIDs = [dep.id];
    if (dep.parentDep) depIDs.push(dep.parentDep.id);
    if (dep.parentDep?.parentDep) depIDs.push(dep.parentDep.parentDep.id);

    let infusionID: string | null = getActiveID(infusion);
    if (infusionID == null) {
      return {
        type: ResponseType.Failure,
        data:
          'The infusion does not have a proper version ID ' +
          infusion.uid +
          ' ' +
          infusion.activeID +
          ' ' +
          infusion.status,
      };
    }

    let infusionList;
    if (useDataStore) {
      infusionList = await DataStore.query(InfusionDose, (m) =>
        m.and((m) => [
          m.dripID.eq(infusionID as string),
          m.or((m) => depIDs.map((id) => m.departmentID.eq(id))),
          m.and((m) => [m.status.ne('ARCHIVE'), m.status.ne('DELETED')]),
        ])
      );
    } else {
      infusionList = await executeQuery(infusionDosesByDripID, {
        dripID: infusionID,
        filter: {
          and: [
            { or: depIDs.map((id) => ({ departmentID: { eq: id } })) },
            { status: { ne: 'ARCHIVE' } },
            { status: { ne: 'DELETED' } },
          ],
        },
      });
    }

    let infusionDoses: InfusionSubItem[] = [];
    for (let i = 0; i < infusionList.length; i++) {
      let infusionDose = infusionList[i];
      if (
        infusionDose.status.includes('DRAFT') &&
        infusionDose.departmentID !== dep.id
      )
        continue;
      else {
        let protocol = db.protocols.find(
          (p) =>
            p.uid === infusionDose.protocolID ||
            p.activeID === infusionDose.protocolID
        );
        if (protocol) {
          let elec = new InfusionSubItem(infusion, protocol, infusionDose);
          mapModelSubItems(elec, infusionDoses, elec.status, dep);
          // infusionDoses.push(elec);
        }
      }
    }

    infusionDoses.sort((a, b) => a.index - b.index);
    infusion.subItems = infusionDoses;

    return {
      type: ResponseType.Success,
      data: infusionDoses,
    };
  } catch (error) {
    console.error('Error fetching infusionList:', error);
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

/**
 * Replace the existing concentrations with the new ones and add new ones
 * 1. Exisiting infusion has more concentrations than the new one
 *    - Delete the extra concentrations
 * 2. Exisiting infusion has less concentrations than the new one
 *    - Create the new concentrations
 * 3. Exisiting infusion has the same number of concentrations as the new one
 *    - Update the existing concentrations
 * @param med The infusion to update the concentrations
 * @param concentrations The new concentrations to update
 * @returns Success if the concentrations were updated or Failure if there was an error
 */
export const updateConcentrations = async (
  department: DepartmentItem,
  infus: InfusionItem,
  concentrations: Concentration[],
  isDelete: boolean = false
): Promise<Response> => {
  try {
    /* First query for existing concentrations */
    let existingConcentrations = await DataStore.query(Concentration, (c) =>
      c.and((c) => [c.dripID.eq(infus.uid), c.departmentID.eq(department.id)])
    );

    if (isDelete) {
      for (let i = 0; i < existingConcentrations.length; i++) {
        let con = existingConcentrations[i];
        let result = await DataStore.delete(Concentration, con.id);
        if (result == null) {
          return {
            type: ResponseType.Failure,
            data: 'The concentration did not delete correctly',
          };
        }
      }

      return {
        type: ResponseType.Success,
        data: concentrations,
      };
    } else {
      let concens: Concentration[] = [...concentrations];
      let oldConcens: Concentration[] = [...existingConcentrations];
      let n = 0;
      for (
        let i = 0;
        i < Math.min(concentrations.length, existingConcentrations.length);
        i++
      ) {
        let newCon = concentrations[i];
        let oldCon = existingConcentrations[i];
        /* Update the existing concentration */
        let result = await DataStore.save(
          Concentration.copyOf(oldCon, (updated) => {
            updated.firstAmnt = newCon.firstAmnt;
            updated.secAmnt = newCon.secAmnt;
            updated.firstUnit = newCon.firstUnit;
            updated.secUnit = newCon.secUnit;
            updated.status = infus.status;
          })
        );
        if (result == null) {
          return {
            type: ResponseType.Failure,
            data: 'The concentration did not update correctly',
          };
        }
        /* Remove the concentration from the list */
        concens = concens.filter((c) => !isObjectEqual(c, newCon));
        oldConcens = oldConcens.filter((c) => !isObjectEqual(c, oldCon));
        n++;
      }

      /* Create any new concentrations */
      for (let i = 0; i < concens.length; i++) {
        let con = concens[i];
        let result = await DataStore.save(
          new Concentration({
            departmentID: department.id,
            dripID: infus.uid,
            firstAmnt: con.firstAmnt,
            secAmnt: con.secAmnt,
            firstUnit: con.firstUnit,
            secUnit: con.secUnit,
            status: infus.status,
          })
        );
        if (result == null) {
          return {
            type: ResponseType.Failure,
            data: 'The concentration did not create correctly',
          };
        }
      }

      /* Delete any extra concentrations */
      for (let i = 0; i < oldConcens.length; i++) {
        let con = oldConcens[i];
        let result = await DataStore.delete(Concentration, con.id);
        if (result == null) {
          return {
            type: ResponseType.Failure,
            data: 'The concentration did not delete correctly',
          };
        }
      }

      return {
        type: ResponseType.Success,
        data: concentrations,
      };
    }
  } catch (e) {
    if (globals.debug) console.log('ERROR UPDATING CONCENTRATIONS:', e, infus);
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

/**
 * Propagate the concentrations to all the infusions that are associated with the department
 *  - This should only be called when creating a new infusion
 * @param department The curren department to propagate the concentrations from
 * @param infus The infusion to propagate the concentrations to
 * @param concentrations The new concentrations to update
 * @returns Success if the concentrations were updated or Failure if there was an error
 */
export const propagateInfusionConcentrations = async (
  department: DepartmentItem,
  allSubDeps: DepartmentItem[],
  infus: InfusionItem,
  concentrations: Concentration[]
): Promise<Response> => {
  try {
    let deps = allSubDeps;
    if (deps == null || deps.length === 0)
      return {
        type: ResponseType.Success,
        data: concentrations,
      };
    let concens: Concentration[] = [...concentrations];
    let dripID =
      infus.status === ProgressStatus.DRAFT && infus.activeID
        ? infus.activeID
        : infus.uid;
    for (let i = 0; i < deps.length; i++) {
      let dep = deps[i];
      /* Create any new concentrations */
      for (let j = 0; j < concens.length; j++) {
        let con = concens[j];
        let result = await DataStore.save(
          new Concentration({
            departmentID: dep.id,
            dripID: dripID,
            firstAmnt: con.firstAmnt,
            secAmnt: con.secAmnt,
            firstUnit: con.firstUnit,
            secUnit: con.secUnit,
            status: 'ACTIVE',
          })
        );
        if (result == null) {
          return {
            type: ResponseType.Failure,
            data: 'The concentration did not create correctly',
          };
        }
      }
    }

    return {
      type: ResponseType.Success,
      data: concentrations,
    };
  } catch (e) {
    if (globals.debug) console.log('ERROR UPDATING CONCENTRATIONS:', e, infus);
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

/**
 * Create a new infusion DOSE in the database and choose the version
 * @param infusion InfusionDoseJSON JSON format or Model InfusionSubItem
 * @returns The successful InfusionSubItem or the error
 */
export const createInfusionDoseItem = async (
  infusion: InfusionDoseJSON | InfusionSubItem,
  previousItem?: InfusionSubItem
): Promise<Response> => {
  try {
    let json: InfusionDoseJSON;
    if (
      infusion instanceof InfusionSubItem ||
      (infusion as any).TAG === 'InfusionSubItem'
    ) {
      let medItem = infusion as InfusionSubItem;
      json = {
        departmentID: medItem.departmentID,
        infusion: medItem.parent,
        protocol: medItem.parentProtocol,
        index: medItem.index,
        basis: medItem.fullBasis,
        route: medItem.routes,
        nemsisRoutes: medItem.nemsisRoutes,
        rangeLow: medItem.rangeLow,
        rangeHigh: medItem.rangeHigh,
        ageLow: medItem.ageLow ? medItem.ageLow.ageValue : null,
        ageHigh: medItem.ageHigh ? medItem.ageHigh.ageValue : null,
        ageGroup: medItem.ageGroup,
        repeatTime: medItem.repeatTimeSec,
        title: medItem.title,
        warning: medItem.warning,
        instruction: medItem.instruction,
        note: medItem.note,
        maxDose: medItem.fullMaxDose,
        minDose: medItem.fullMinDose,
        maxTotalDose: medItem.fullMaxTotalDose,
        calcMax: medItem.model.calcMax,
        calcMin: medItem.model.calcMin,
        createdBy: medItem.model.createdBy,
        modifiedBy: medItem.modifiedBy
          ? medItem.modifiedBy.id
          : medItem.model.modifiedBy || undefined,
        status: medItem.status,
        activeID: medItem.activeID,
        overrideID: medItem.overrideID,
        version: medItem.version,
        createdAt: new Date(medItem.model.createdAt),
      };
    } else json = infusion as InfusionDoseJSON;

    /* Validate the routes are of string array or convert to string array */
    if (json.route == null) json.route = [];
    else if (typeof json.route === 'string') json.route = [json.route];

    /* 
        1. Creating a DRAFT the first time
        2. Creating a DRAFT from an ACTIVE version
        3. Updating a DRAFT from a DRAFT version
        4. Creating a ARCHIVE from an ACTIVE version
    */

    let nemsisRoutes: MedicationRouteInput[] = [];
    if (json.nemsisRoutes != null) {
      for (let i = 0; i < json.nemsisRoutes.length; i++) {
        let newRoute: MedicationRouteInput = {
          route: json.nemsisRoutes[i].route,
          code: json.nemsisRoutes[i].code,
        };
        nemsisRoutes.push(newRoute);
      }
    }

    let m: InfusionDose;
    let protID: string | null = json.protocolID
      ? json.protocolID
      : getActiveID(json.protocol);
    let infusID: string | null = json.dripID
      ? json.dripID
      : getActiveID(json.infusion);
    if (protID == null || infusID == null) {
      return {
        type: ResponseType.Failure,
        data:
          'The protocol or infusion does not have a proper version ID ' +
          json.protocol.uid +
          ' ' +
          json.protocol.activeID +
          ' ' +
          json.protocol.status +
          ' ' +
          json.infusion.uid +
          ' ' +
          json.infusion.activeID +
          ' ' +
          json.infusion.status,
      };
    }

    /* Use Case 3: Updating a current DRAFT version */
    if (
      previousItem &&
      previousItem.status.includes('DRAFT') &&
      json.status.includes('DRAFT')
    ) {
      let dbMed = await DataStore.query(InfusionDose, previousItem.uid);
      if (dbMed == null) {
        return {
          type: ResponseType.Failure,
          data: 'The DRAFT Infusion Dose does not exist could not update',
        };
      }

      m = await DataStore.save(
        InfusionDose.copyOf(dbMed, (updated) => {
          updated.index = json.index;
          updated.basis = json.basis;
          updated.route = json.route;
          updated.nemsisRoutes = json.nemsisRoutes;
          updated.rangeLow = json.rangeLow;
          updated.rangeHigh = json.rangeHigh;
          updated.ageLow = json.ageLow;
          updated.ageHigh = json.ageHigh;
          updated.ageGroup = json.ageGroup;
          updated.repeatTime = json.repeatTime
            ? json.repeatTime + ''
            : undefined;
          updated.title = json.title;
          updated.warning = json.warning;
          updated.instruction = json.instruction;
          updated.note = json.note;

          updated.maxDose = json.maxDose;
          updated.minDose = json.minDose;
          updated.maxTotalDose = json.maxTotalDose;
          updated.calcMax = json.calcMax;
          updated.calcMin = json.calcMin;

          updated.status = json.status;
          updated.activeID = json.activeID;
          updated.version = json.version ? json.version : 'v1.0.0';
          updated.modifiedBy = json.modifiedBy;
        })
      );
    } else {
      /* Use Case 1, 2, & 4: Creating a DRAFT the first time */
      m = await DataStore.save(
        new InfusionDose({
          departmentID: json.departmentID,
          dripID: infusID,
          protocolID: protID,
          index: json.index,
          basis: json.basis,
          route: json.route,
          nemsisRoutes: json.nemsisRoutes,
          rangeLow: json.rangeLow,
          rangeHigh: json.rangeHigh,
          ageLow: json.ageLow,
          ageHigh: json.ageHigh,
          ageGroup: json.ageGroup,
          repeatTime: json.repeatTime ? json.repeatTime + '' : undefined,
          title: json.title,
          warning: json.warning,
          instruction: json.instruction,
          note: json.note,
          maxDose: json.maxDose,
          minDose: json.minDose,
          maxTotalDose: json.maxTotalDose,
          calcMax: json.calcMax,
          calcMin: json.calcMin,
          createdBy: json.createdBy,
          modifiedBy: json.modifiedBy,
          status: json.status,
          activeID: json.activeID,
          overrideID: json.overrideID ? json.overrideID : undefined,
          version: json.version ? json.version : 'v1.0.0',
          createdAt: new Date().toISOString(),
        })
      );
    }

    let medItem = new InfusionSubItem(json.infusion, json.protocol, m);

    return {
      type: ResponseType.Success,
      data: medItem,
    };
  } catch (e) {
    if (globals.debug)
      console.log('ERROR CREATING INFUSION DOSE:', e, infusion);
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

/**
 * This function will delete all the infusions that are associated with the protocol
 * @param protocol The protocol to delete the infusions from
 * @param modifiedBy The user that modified the infusions
 * @returns Success: data is the number of infusions deleted or Failure if there was an error
 */
export const deleteInfusionsForProtocol = async (
  oldProtocol: ProtocolItem,
  newDeletedProtocol: ProtocolItem,
  modifiedBy: User
): Promise<Response> => {
  try {
    let promiseFunctions: (() => Promise<Response>)[] = [];
    for (let dose of oldProtocol.infusions) {
      if (dose.status === ProgressStatus.DRAFT && dose.activeItem == null)
        continue;
      dose = dose.activeItem ?? dose;
      dose.parentProtocol = newDeletedProtocol;
      dose.status = ProgressStatus.DELETED;
      dose.modifiedBy = modifiedBy;
      // promiseFunctions.push(() => createInfusionDoseItem(dose));
      promiseFunctions.push(() => deleteInfusionDoseItem(dose, true));
    }
    let responses: Response[] = await BatchQuery(promiseFunctions, 3).catch(
      (e) => {
        console.error('Error deleting infusions:', e);
        return [];
      }
    );

    let successes = responses.filter((r) => r.type === ResponseType.Success);
    let errors = responses.filter((r) => r.type === ResponseType.Failure);

    return {
      type: ResponseType.Success,
      data: {
        results: responses,
        successes: successes.map((r) => r.data),
        errors: errors.map((r) => r.data),
      },
    };
  } catch (error: any) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

/**
 * Duplicate the medication doses from the one protocol to another protocol
 * @param department The department to duplicate the medication doses from
 * @param fromProtocol The protocol to duplicate the medication doses from
 * @param toProtocol The protocol to duplicate the medication doses to
 * @param user The user that is duplicating the medication doses
 * @param infusion The infusion to duplicate the doses for
 * @returns Success if the medication doses were duplicated or Failure if there was an critical error
 *  - Success data: { successes: MedicationSubItem[], errors: any[] }
 */
export const duplicateProtocolInfusionDoses = (
  department: DepartmentItem,
  fromProtocol: ProtocolItem,
  toProtocol: ProtocolItem,
  user: User,
  infusion?: InfusionItem
): Promise<Response> => {
  return new Promise(async (resolve, reject) => {
    try {
      let doses: InfusionDose[] = [];
      let protocolID = getActiveID(fromProtocol);
      if (protocolID == null) {
        return {
          type: ResponseType.Failure,
          data: 'The protocol does not have a proper version ID 6',
        };
      }
      if (infusion) {
        let infusID = getActiveID(infusion);
        if (infusID == null) {
          return {
            type: ResponseType.Failure,
            data: 'The medication does not have a proper version ID 5',
          };
        }
        doses = await DataStore.query(InfusionDose, (c) =>
          c.and((c) => [
            c.departmentID.eq(department.id),
            c.protocolID.eq(protocolID),
            c.dripID.eq(infusID as string),
            c.status.eq(ProgressStatus.ACTIVE),
          ])
        );
      } else {
        doses = await DataStore.query(InfusionDose, (c) =>
          c.and((c) => [
            c.departmentID.eq(department.id),
            c.protocolID.eq(protocolID),
            c.status.eq(ProgressStatus.ACTIVE),
          ])
        );
      }
      let promiseFunctions: (() => Promise<Response>)[] = [];
      for (let i = 0; i < doses.length; i++) {
        let infusItem: InfusionItem | undefined = infusion;
        if (infusItem == null) {
          let infus = await DataStore.query(Drip, doses[i].dripID);
          if (infus == null) continue;
          infusItem = new InfusionItem(infus);
        }
        let subItem = new InfusionSubItem(infusItem, fromProtocol, doses[i]);
        promiseFunctions.push(() =>
          duplicateInfusionDose(department, subItem, user, toProtocol)
        );
      }
      let responses: Response[] = await BatchQuery(promiseFunctions).catch(
        (e) => {
          console.error('Error duplicating medication doses:', e);
          return [];
        }
      );

      /* Check if any of the responses are failures */
      let successes = responses.filter((r) => r.type === ResponseType.Success);
      let errors = responses.filter((r) => r.type === ResponseType.Failure);

      resolve({
        type: ResponseType.Success,
        data: {
          successes: successes.map((r) => r.data),
          errors: errors.map((r) => r.data),
        },
      });
    } catch (e) {
      console.error('Error duplicating medication doses:', e);
      reject(e);
    }
  });
};

/**
 * Duplicate the infusion doses from the one protocol to another protocol
 * @param protocol The new protocol that has just been created
 * @param previousProtocol The protocol that was duplicated
 * @param isDraft True if the protocol is a draft version, otherwise will update the active version
 * @returns An array of the infusions that were duplicated
 */
export const duplicateInfusionDose = async (
  department: DepartmentItem,
  dose: InfusionSubItem,
  user: User,
  protocolItem?: ProtocolItem
): Promise<Response> => {
  try {
    /* Get the ID of the protocol to map to the new protocol */
    let protID = protocolItem
      ? protocolItem.status === ProgressStatus.DRAFT && protocolItem.activeID
        ? protocolItem.activeID
        : protocolItem.uid
      : dose.parentProtocol.status === ProgressStatus.DRAFT &&
          dose.parentProtocol.activeID != null
        ? dose.parentProtocol.activeID
        : dose.parentProtocol.uid;

    if (protID == null) {
      return {
        type: ResponseType.Failure,
        data:
          'The protocol does not have a proper version ID ' +
          dose.parentProtocol +
          ' ' +
          protID,
      };
    }

    let clone = cloneInfusionSubItem(dose);
    clone.parentProtocol = protocolItem ? protocolItem : dose.parentProtocol;
    clone.departmentID = department.id;
    clone.status = ProgressStatus.DRAFT;
    clone.activeID = null;
    clone.version = 'v1.0.0';
    clone.modifiedBy = user;

    let result = await createInfusionDoseItem(clone);
    if (result.type === ResponseType.Failure) {
      return {
        type: ResponseType.Failure,
        data: result.data,
      };
    }

    if (globals.debug)
      console.log('Successfully duplicated infusion Dose', result.data);
    return {
      type: ResponseType.Success,
      data: result.data as InfusionSubItem,
    };
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

/**
 * Check if the protocol is already pointing at the infusion
 *    - Otherwise add the infusion to the protocol and create a new protocol draft
 * @param infusion The infusion o check if it is paired to the protocol
 * @param protocol The protocol to check if it is paired to the infusion
 * @param modifiedBy The user that modified the protocol
 * @returns Success if the infusion is paired with the protocol and returns a InfusionProtocol or Failure if there was an error
 */
export const validateInfusionProtocolPairing = async (
  infusion: InfusionItem,
  protocol: ProtocolItem,
  modifiedBy: User
): Promise<Response> => {
  try {
    if (infusion.model == null) {
      return {
        type: ResponseType.Failure,
        data: 'The infusion does not have a database object could be an infusion item',
      };
    }
    let infusionProtocol: MedicationProtocol;
    let infusID: string | null = getActiveID(infusion);
    let pairedID = protocol.model.dripIDs?.find((id) => id === infusID);

    if (infusID == null) {
      return {
        type: ResponseType.Failure,
        data:
          'The infusion does not have a proper version ID ' +
          infusion.uid +
          ' ' +
          infusion.activeID +
          ' ' +
          infusion.status,
      };
    }

    /* Create a new protocol draft if the infusion is not paired with the protocol */
    if (pairedID == null) {
      let infusIDs = protocol.model.dripIDs ? protocol.model.dripIDs : [];
      infusIDs.push(infusID);

      let draftProtocol: ProtocolJSON = {
        departmentID: protocol.parent.departmentID,
        name: protocol.name,
        nickname: protocol.nickname,
        index: protocol.index,
        rangeLow: protocol.rangeLow,
        rangeHigh: protocol.rangeHigh,
        parentCategory: protocol.parent,
        pdfID: protocol.pdfUrl,
        pairedDepsIDs: protocol.pairedDepIDs ?? [],
        pairedProtocols: protocol.pairedProtocols.map((p) => p.uid),
        medications: protocol.medications.map((m) => m.uid),
        infusions: infusIDs,
        equipment: protocol.equipment.map((e) => e.uid),
        forms: protocol.forms.map((f) => f.uid),
        electrical: protocol.electrical.map((e) => e.parent.uid),

        createdBy: protocol.model.createdBy ? protocol.model.createdBy : '',
        modifiedBy: modifiedBy ? modifiedBy.id : undefined,

        status: ProgressStatus.DRAFT,
        activeID: protocol.uid,
        version: upgradeVersion(protocol.version),
        pdfVersion: protocol.pdfVersion,
        isPublic: protocol.isPublic,
        isRestrictive: protocol.isRestrictive,
        keychainID: protocol.keychainID,
        color: protocol.color,
      };

      let result: Response = await createProtocol(draftProtocol, protocol);

      if (result.type === ResponseType.Failure) {
        return {
          type: ResponseType.Failure,
          data: result.data,
        };
      }

      infusionProtocol = new MedicationProtocol({
        protocolID: protocol.uid,
        options: [],
      });
    } else {
      /* Find the infusion protocol */
      let protID =
        protocol.status === ProgressStatus.ACTIVE
          ? protocol.uid
          : protocol.activeID;
      let mo = infusion.model.dripOptions.find((p) => p.protocolID === protID);
      if (mo == null) {
        return {
          type: ResponseType.Failure,
          data: 'The infusion protocol does not exist',
        };
      }
      infusionProtocol = mo;
    }

    /* Otherwise we just need to update the InfusionProtocol */
    return {
      type: ResponseType.Success,
      data: infusionProtocol,
    };
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

/**
 * Check if two MedicationRanges are equal
 * @param a MedicationRange to compare
 * @param b MedicationRange to compare
 * @returns if the infusion ranges are equal
 */
export const equalsInfusionRange = (
  a: MedicationRange,
  b: MedicationRange
): boolean => {
  let isRouteSame =
    a.route.length === b.route.length &&
    a.route.every((value, index) => value === b.route[index]);
  return (
    isRouteSame &&
    a.basis === b.basis &&
    a.rangeLow === b.rangeLow &&
    a.rangeHigh === b.rangeHigh &&
    a.title === b.title &&
    a.warning === b.warning &&
    a.instruction === b.instruction &&
    a.note === b.note &&
    a.maxDose === b.maxDose &&
    a.minDose === b.minDose &&
    a.calcMax === b.calcMax &&
    a.calcMin === b.calcMin &&
    a.index === b.index
  );
};

/**
 * This function will publish the infusion to the database
 *    1. Create a new ARCHEIVED infusion based on the current ACTIVE infusion
 *    2. Update the ACTIVE infusion with the new information
 *    3. Delete the DRAFT infusion
 * @param draftInfusionItem The infusion to publish
 */
export const publishInfusion = async (
  department: DepartmentItem,
  draftInfusionItem: InfusionItem,
  draftChangeItem?: DraftChangeItem
): Promise<Response> => {
  try {
    /* Base Case 1 -- check if the infusion is configured correctly as a draft version */
    if (draftInfusionItem.status !== ProgressStatus.DRAFT) {
      return {
        type: ResponseType.Failure,
        data: 'The infusion is not a draft version',
      };
    }

    let dbDrip = await DataStore.query(Drip, draftInfusionItem.uid);
    if (dbDrip == null) {
      return {
        type: ResponseType.Failure,
        data: 'The infusion does not exist',
      };
    }

    let activeInfusion: Drip;

    /* Fetch the draft concentrations for the infusion */
    let draftConcentrations = await DataStore.query(Concentration, (c) =>
      c.and((c) => [
        c.dripID.eq(draftInfusionItem.uid),
        c.departmentID.eq(department.id),
      ])
    );

    /* Use case 1: Creating the FIRST active version */
    if (draftInfusionItem.activeID == null) {
      /* Update the draft infusion to be active */
      activeInfusion = await DataStore.save(
        Drip.copyOf(dbDrip, (updated) => {
          updated.status = ProgressStatus.ACTIVE;
        })
      );

      /* Update the concentrations for the infusion */
      let concentrationResult: Response = await updateConcentrations(
        department,
        new InfusionItem(activeInfusion),
        draftConcentrations
      );
      if (concentrationResult.type === ResponseType.Failure)
        return concentrationResult;
    } else {
      /* Use case 2: Upgrading a active version */
      /* Step 1. Fetch the active infusion item */
      let id: string = draftInfusionItem.activeID;
      let curInfusion = await DataStore.query(Drip, id);

      /* Base Case 3 -- check if the active infusion exists */
      if (curInfusion == null) {
        console.error('Couldn not find active item:', draftInfusionItem);
        return {
          type: ResponseType.Failure,
          data: 'The active infusion does not exist: ' + draftInfusionItem,
        };
      }

      let archive = new InfusionItem(curInfusion);
      archive.status = ProgressStatus.ARCHIVE;
      archive.activeID = curInfusion.id;
      let currentConcentrations = await DataStore.query(Concentration, (c) =>
        c.and((c) => [
          c.dripID.eq(archive.uid),
          c.departmentID.eq(department.id),
        ])
      );

      // 2. Create a new ARCHEIVED infusion based on the current ACTIVE infusion
      let archiveResult: Response = await createParentInfusion(
        department,
        archive
      );
      if (archiveResult.type === ResponseType.Failure) return archiveResult;
      archive = archiveResult.data as InfusionItem;
      let curInfusionItem = new InfusionItem(curInfusion);
      /**
       * 2.5 Hazlett: Copy the concentrations to the new archived infusion
       *  - This will attach all the sub-agency concentrations to the new archived infusion item
       */
      await copyConcentrationsToStatus(
        curInfusionItem,
        archive,
        'dripID',
        ProgressStatus.ARCHIVE,
        40
      )
        .then((result: Response) => {
          if (result.type === ResponseType.Failure) {
            console.error(
              'Error in copyConcentrationsToArchive: ',
              result.data
            );
          }
        })
        .catch((error) => {
          console.error('Error in copyConcentrationsToArchive: ', error);
        });

      //2.5 Update the Concentrations for the medication
      let concentrationResult: Response = await updateConcentrations(
        department,
        archive,
        currentConcentrations
      );
      if (concentrationResult.type === ResponseType.Failure)
        return concentrationResult;
      else if (globals.debug)
        console.log('Concentration Updated:', concentrationResult.data);

      // 2. Update the ACTIVE infusion with the new information
      activeInfusion = await DataStore.save(
        Drip.copyOf(curInfusion, (updated) => {
          updated.name = draftInfusionItem.name;
          updated.concentration = draftInfusionItem.model.concentration;
          updated.rangeLow = draftInfusionItem.rangeLow;
          updated.rangeHigh = draftInfusionItem.rangeHigh;
          updated.route = draftInfusionItem.model.route;
          updated.dripOptions = (draftInfusionItem.model as Drip).dripOptions;

          updated.contraindication = draftInfusionItem.contraindication;
          updated.note = draftInfusionItem.note;
          updated.warning = draftInfusionItem.warning;
          updated.medClass = draftInfusionItem.medClass;
          updated.action = draftInfusionItem.action;
          updated.indication = draftInfusionItem.indication;
          updated.interaction = draftInfusionItem.interaction;
          updated.onset = draftInfusionItem.onset;
          updated.duration = draftInfusionItem.duration;

          updated.createdBy = draftInfusionItem.model.createdBy;
          updated.modifiedBy = draftInfusionItem.model.modifiedBy;

          updated.status = ProgressStatus.ACTIVE;
          updated.activeID = null;
          updated.overrideID = draftInfusionItem.overrideID;
          updated.version = draftInfusionItem.version;
        })
      );

      //3 Update the Concentrations for the infusion
      concentrationResult = await updateConcentrations(
        department,
        new InfusionItem(activeInfusion),
        draftConcentrations
      );
      if (concentrationResult.type === ResponseType.Failure)
        return concentrationResult;

      // 4. Delete the DRAFT infusion
      let draftInfusion = await DataStore.delete(Drip, draftInfusionItem.uid);
      if (draftInfusion == null) {
        return {
          type: ResponseType.Failure,
          data: 'The draft infusion does not exist',
        };
      }

      // 5. Delete the Concentrations for the infusion
      updateConcentrations(
        department,
        draftInfusionItem,
        draftInfusionItem.concentrations,
        true
      );

      // 6. Query if there any closed draft changes with the actvie model item
      checkIdUpdateDraftChange(activeInfusion.id, archive.uid);

      // 6. If there is a draftChangeItem then update the changeID to the new active category and the previousID to the archeived category
      if (draftChangeItem) {
        let new_dc: DraftChangeJSON = {
          previousDraftChange: draftChangeItem,
          changeItem: activeInfusion.id,
          changeType: draftChangeItem.changeType,
          previousItem: archive.uid,
          isClosed: true,
        };

        // 7. Update the draft change item
        updateDraftChangeItem(new_dc).then((result) => {
          if (result == null) {
            return {
              type: ResponseType.Failure,
              data: 'The draft change item did not update correctly',
            };
          }
        });
      }
    }

    let infusItem = new InfusionItem(activeInfusion);
    return {
      type: ResponseType.Success,
      data: infusItem,
    };
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

/**
 * This function will publish the infusion to the database
 *    1. Create a new ARCHEIVED infusion based on the current ACTIVE infusion
 *    2. Update the ACTIVE infusion with the new information
 *    3. Delete the DRAFT infusion
 * @param draftInfusionItem The infusion to publish
 */
export const publishInfusionDoses = async (
  draftInfusionItem: InfusionSubItem,
  draftChangeItem?: DraftChangeItem
): Promise<Response> => {
  try {
    /* Base Case 1 -- check if the infusion is configured correctly as a draft version */
    if (
      draftInfusionItem.status !== ProgressStatus.DRAFT &&
      draftInfusionItem.status !== ProgressStatus.DRAFT_DELETE
    ) {
      return {
        type: ResponseType.Failure,
        data: 'The infusion is not a draft version',
      };
    }

    let protocol = draftInfusionItem.parentProtocol;
    let protID: string =
      protocol.status.includes('DRAFT') && protocol.activeID
        ? protocol.activeID
        : protocol.uid;

    let dbMed = await DataStore.query(InfusionDose, draftInfusionItem.uid);
    if (dbMed == null) {
      return {
        type: ResponseType.Failure,
        data: 'The infusion dose does not exist',
      };
    }

    /* Base Case 1: Fully Deleting a Item */
    if (draftInfusionItem.status === ProgressStatus.DRAFT_DELETE) {
      /* Remove the delete draft infusion and switch the activeID version to DELETED */
      let med = await DataStore.delete(InfusionDose, draftInfusionItem.uid);
      if (med == null) {
        return {
          type: ResponseType.Failure,
          data: 'The draft infusion does not exist',
        };
      }

      if (draftInfusionItem.activeID == null) {
        return {
          type: ResponseType.Success,
          data: 'The infusion dose was deleted',
        };
      }

      /* Update the active infusion to be DELETED */
      let activeMed = await DataStore.query(
        InfusionDose,
        draftInfusionItem.activeID
      );
      if (activeMed == null) {
        return {
          type: ResponseType.Failure,
          data: 'The active infusion does not exist',
        };
      }

      let deletedMed = await DataStore.save(
        InfusionDose.copyOf(activeMed, (updated) => {
          updated.status = ProgressStatus.DELETED;
        })
      );

      let medItem = new InfusionSubItem(
        draftInfusionItem.parent,
        draftInfusionItem.parentProtocol,
        deletedMed
      );

      return {
        type: ResponseType.Success,
        data: medItem,
      };
    }

    let activeInfusion: InfusionDose;

    /* Use case 1: Creating the FIRST active version */
    if (draftInfusionItem.activeID == null) {
      /* Update the draft infusion to be active */
      activeInfusion = await DataStore.save(
        InfusionDose.copyOf(dbMed, (updated) => {
          updated.status = ProgressStatus.ACTIVE;
        })
      );
    } else {
      /* Use case 2: Upgrading a active version */
      /* Step 1. Fetch the active infusion item */
      let id: string = draftInfusionItem.activeID;
      let curInfusion = await DataStore.query(InfusionDose, id);

      /* Base Case 3 -- check if the active infusion exists */
      if (curInfusion == null) {
        return {
          type: ResponseType.Failure,
          data: 'The active infusion does not exist',
        };
      }

      let activeItem = draftInfusionItem.activeItem as InfusionSubItem | null;
      if (activeItem == null && draftInfusionItem.activeID != null) {
        let activeMedDose = await DataStore.query(
          InfusionDose,
          draftInfusionItem.activeID
        );
        if (activeMedDose == null) {
          return {
            type: ResponseType.Failure,
            data:
              'The active infusion does not exist by ID: ' +
              draftInfusionItem.activeID,
          };
        }
        activeItem = new InfusionSubItem(
          draftInfusionItem.parent,
          draftInfusionItem.parentProtocol,
          activeMedDose
        );
      }

      if (activeItem == null) {
        return {
          type: ResponseType.Failure,
          data: 'The active infusion does not exist after error checking',
        };
      }
      activeItem.status = ProgressStatus.ARCHIVE;
      activeItem.activeID = curInfusion.id;

      // 2. Create a new ARCHEIVED infusion based on the current ACTIVE infusion
      let archiveResult: Response = await createInfusionDoseItem(activeItem);
      if (archiveResult.type === ResponseType.Failure) return archiveResult;
      activeItem = archiveResult.data as InfusionSubItem;

      // 2. Update the ACTIVE infusion with the new information
      activeInfusion = await DataStore.save(
        InfusionDose.copyOf(curInfusion, (updated) => {
          updated.protocolID = protID;
          updated.index = draftInfusionItem.index;
          updated.basis = draftInfusionItem.fullBasis;
          updated.route = draftInfusionItem.routes;
          updated.nemsisRoutes = draftInfusionItem.nemsisRoutes;
          updated.rangeLow = draftInfusionItem.rangeLow;
          updated.rangeHigh = draftInfusionItem.rangeHigh;
          updated.ageLow = draftInfusionItem.ageLow
            ? draftInfusionItem.ageLow.ageValue
            : null;
          updated.ageHigh = draftInfusionItem.ageHigh
            ? draftInfusionItem.ageHigh.ageValue
            : null;
          updated.ageGroup = draftInfusionItem.ageGroup;
          updated.repeatTime = draftInfusionItem.repeatTimeSec
            ? draftInfusionItem.repeatTimeSec + ''
            : undefined;
          updated.title = draftInfusionItem.title;
          updated.warning = draftInfusionItem.warning;
          updated.instruction = draftInfusionItem.instruction;
          updated.note = draftInfusionItem.note;
          updated.maxDose = draftInfusionItem.fullMaxDose;
          updated.minDose = draftInfusionItem.fullMinDose;
          updated.maxTotalDose = draftInfusionItem.fullMaxTotalDose;
          updated.calcMax = draftInfusionItem.model.calcMax;
          updated.calcMin = draftInfusionItem.model.calcMin;
          updated.status = ProgressStatus.ACTIVE;
          updated.activeID = null;
          updated.overrideID = draftInfusionItem.overrideID;
          updated.version = draftInfusionItem.version
            ? draftInfusionItem.version
            : 'v1.0.0';
          updated.modifiedBy = draftInfusionItem.modifiedBy
            ? draftInfusionItem.modifiedBy.id
            : undefined;
        })
      );

      // 3. Delete the DRAFT infusion
      let draftInfusion = await DataStore.delete(
        InfusionDose,
        draftInfusionItem.uid
      );
      if (draftInfusion == null) {
        return {
          type: ResponseType.Failure,
          data: 'The draft infusion does not exist',
        };
      }

      // 4. Query if there any closed draft changes with the actvie model item
      checkIdUpdateDraftChange(activeInfusion.id, activeItem.uid);

      // 5. If there is a draftChangeItem then update the changeID to the new active category and the previousID to the archeived category
      if (draftChangeItem) {
        let new_dc: DraftChangeJSON = {
          previousDraftChange: draftChangeItem,
          changeItem: activeInfusion.id,
          changeType: draftChangeItem.changeType,
          previousItem: activeItem.uid,
          isClosed: true,
        };

        updateDraftChangeItem(new_dc).then((result) => {
          if (result == null) {
            return {
              type: ResponseType.Failure,
              data: 'The draft change item did not update correctly',
            };
          }
        });
      }
    }

    let medItem = new InfusionSubItem(
      draftInfusionItem.parent,
      draftInfusionItem.parentProtocol,
      activeInfusion
    );
    return {
      type: ResponseType.Success,
      data: medItem,
    };
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

/**
 * This function will update the infusion in the database
 * @param infusionItem The infusion to delete from the database
 * @param isSoft True if the infusion should be soft deleted, false if it should be hard deleted
 * @returns Success if the infusion was deleted or Failure if there was an error
 * @description
 * IsSoft = True
 *    1. Fetch the item from the database to verify it exists
 *    2. Create a new infusion item with the same data in the DELETED status
 *    3. Set all the doses to the new infusion item
 *    4. Delete the ACTIVE item from the database
 *    5. Set all the archive items activeID to the new infusion item
 * IsSoft = False
 *    1. Delete the item from the database
 */
export const deleteInfusionItem = async (
  department: DepartmentItem,
  infusionItem: InfusionItem,
  isSoft: boolean
): Promise<Response> => {
  try {
    let id: string = infusionItem.uid;
    if (isSoft) {
      let infusion = await DataStore.query(Drip, id);
      if (infusion == null) {
        return {
          type: ResponseType.Failure,
          data: 'The infusion does not exist',
        };
      }
      /* 2-20-25 Hazlett: Update the infusion item to the DELETED status and make sure the concentrations are not updated. HANDLED Below*/
      infusionItem.status = ProgressStatus.DELETED;
      let response = await createParentInfusion(
        department,
        infusionItem,
        undefined,
        false
      );
      if (response.type === ResponseType.Failure) {
        return response;
      }

      /* Copy the medication doses to the new deleted medication */
      let newDeletedInfusion = response.data as InfusionItem;
      let promiseFunctions: (() => Promise<Response>)[] = [];
      for (let dose of infusionItem.subItems) {
        if (dose.status === ProgressStatus.DRAFT && dose.activeItem == null)
          continue;
        dose = dose.activeItem ?? dose;
        dose.parent = newDeletedInfusion;
        dose.status = ProgressStatus.DELETED;
        // promiseFunctions.push(() => createInfusionDoseItem(dose));
        promiseFunctions.push(() => deleteInfusionDoseItem(dose, true));
      }
      let responses = await BatchQuery(promiseFunctions);
      for (let i = 0; i < responses.length; i++) {
        if (responses[i].type === ResponseType.Failure) {
          console.error('Error in createInfusionDoseItem', responses[i].data);
        }
      }

      /**
       * 2-20-25 Hazlett: Delete the concentrations from the infusion item
       *  - This will attach all the sub-agency concentrations to the new deleted infusion item
       */
      copyConcentrationsToStatus(
        infusionItem,
        newDeletedInfusion,
        'dripID',
        ProgressStatus.DELETED,
        5
      )
        .then((result: Response) => {
          if (result.type === ResponseType.Failure) {
            console.error('Error in deleteAllConcentrations: ', result.data);
          }
        })
        .catch((error) => {
          console.error('Error in deleteAllConcentrations: ', error);
        });

      await setAllDosesToNewParentID(
        infusionItem,
        newDeletedInfusion,
        updateInfusionDose,
        infusionDosesByDripID,
        'dripID',
        true
      ).then((result: Response) => {
        if (result.type === ResponseType.Failure) {
          console.error('Error in setAllDosesToNewParentID: ', result.data);
          return;
        } else if (globals.debug) {
          console.log('setAllDosesToNewParentID: ', result.data);
        }
      });
      let deletedItem = await DataStore.delete(Drip, id).catch((error) => {
        console.error('Error in setAllArchiveItemsToNewActiveID: ', error);
        return null;
      });
      if (deletedItem == null) {
        return {
          type: ResponseType.Failure,
          data: 'The infusion did not delete correctly',
        };
      }

      /* Update all the archive items to the new active item */
      setAllArchiveItemsToNewActiveID(
        infusionItem,
        newDeletedInfusion,
        updateDrip,
        dripsByDepartmentID,
        {
          departmentID: infusionItem.departmentID,
        }
      )
        .then((result: Response) => {
          if (result.type === ResponseType.Failure) {
            console.error(
              'Error in setAllArchiveItemsToNewActiveID',
              result.data
            );
            return;
          }
          if (globals.debug)
            console.log('setAllArchiveItemsToNewActiveID', result.data);
        })
        .catch((e) => {
          console.error('Error in setAllArchiveItemsToNewActiveID', e);
          return {
            type: ResponseType.Failure,
            data: e,
          };
        });

      /* Check to see if there was a DraftChange referencing this item and update it to the new ID */
      checkIdUpdateDraftChange(infusionItem.id, newDeletedInfusion.uid);
    } else {
      let infusion = await DataStore.delete(Drip, id);
      if (infusion == null) {
        return {
          type: ResponseType.Failure,
          data: 'The infusion does not exist',
        };
      }
    }
    return {
      type: ResponseType.Success,
      data: infusionItem,
    };
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

/**
 * This function will create a new infusion draft in the database
 * @param department The department to check for infusion drafts
 * @returns Success if the draft was created or Failure if there was an error
 */
export const isInfusionDraftCreated = async (
  department: DepartmentItem
): Promise<Response> => {
  try {
    let drafts = await DataStore.query(Drip, (c) =>
      c.and((c) => [c.status.eq('DRAFT'), c.departmentID.eq(department.id)])
    );
    return {
      type: ResponseType.Success,
      data: drafts.length !== 0,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

/**
 * This function will create a new infusion draft in the database
 * @param department The department to check for infusion drafts
 * @returns Success if the draft was created or Failure if there was an error
 */
export const isInfusionDosesDraftCreated = async (
  department: DepartmentItem
): Promise<Response> => {
  try {
    let drafts = await DataStore.query(InfusionDose, (c) =>
      c.and((c) => [
        c.or((c) => [c.status.eq('DRAFT'), c.status.eq('DRAFT_DELETE')]),
        c.departmentID.eq(department.id),
      ])
    );
    return {
      type: ResponseType.Success,
      data: drafts.length !== 0,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

/**
 * This function will get all the infusion drafts from the database
 * @param db The database to get the infusion drafts from
 * @returns Success if the drafts were found with an array of the updates or Failure if there was an error
 */
export const getInfusionDrafts = async (
  db: DatabaseResponse
): Promise<Response> => {
  try {
    let updates: any[] = [];
    let promses: any[] = [
      DataStore.query(Drip, (c) =>
        c.and((c) => [
          c.status.eq('DRAFT'),
          c.departmentID.eq(db.department.id),
        ])
      ),
      DataStore.query(InfusionDose, (c) =>
        c.and((c) => [
          c.status.eq('DRAFT'),
          c.departmentID.eq(db.department.id),
        ])
      ),
    ];
    let responses = await Promise.all(promses);
    let modelUpdates: Drip[] = responses[0];
    let doseUpdates: InfusionDose[] = responses[1];

    for (let i = 0; i < modelUpdates.length; i++) {
      let model = new InfusionItem(modelUpdates[i]);
      let activeItem = db.infusions.find(
        (c) =>
          c.uid === model.activeID ||
          (c.activeItem && c.activeItem.uid === model.activeID)
      );
      if (activeItem) {
        model.activeItem =
          activeItem.uid === model.activeID
            ? activeItem
            : activeItem.activeItem;
      }

      updates.push({
        model: model,
        title: 'Infusion ' + model.name,
        message: getChangeDescription(model),
        changeType: DraftChangeType.INFUSION,
      });
    }

    for (let j = 0; j < doseUpdates.length; j++) {
      const dose = doseUpdates[j];
      const parent = db.infusions.find(
        (e) =>
          e.uid === doseUpdates[j].dripID ||
          e.activeID === doseUpdates[j].dripID
      );
      if (!parent) continue;
      let find = db.protocols.find(
        (p) =>
          p.uid === dose.protocolID ||
          (p.activeItem && p.activeItem.uid === dose.protocolID)
      );
      const parentProtocol = find
        ? dose.protocolID === find.uid
          ? find
          : (find.activeItem as ProtocolItem)
        : null;
      if (!parentProtocol) continue;
      const doseItem = new InfusionSubItem(parent, parentProtocol, dose);
      let activeItem = db.infusionDoses.find(
        (c) =>
          c.uid === doseItem.activeID ||
          (c.activeItem && c.activeItem.uid === doseItem.activeID)
      );
      if (activeItem) {
        doseItem.activeItem =
          activeItem.uid === doseItem.activeID
            ? activeItem
            : activeItem.activeItem;
      }
      let doseMessage = '';
      let parentName = parentProtocol.name;
      if (dose.activeID == null)
        doseMessage = `Created Infusion Dose: ${doseItem.name} in ${parentName}`;
      else
        doseMessage = `Updated Infusion Dose: ${doseItem.name} in ${parentName}`;
      updates.push({
        model: doseItem,
        title: 'Infusion Dose ' + doseItem.name,
        message: doseMessage,
        changeType: DraftChangeType.INFUSION_DOSE,
      });
    }

    return {
      type: ResponseType.Success,
      data: updates,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

function getChangeDescription(draftItem: InfusionItem): string {
  if (draftItem.activeItem == null)
    return `Created Infusion: ${draftItem.name}`;
  else return `Updated Infusion: ${draftItem.name}`;
}

function getDoseChangeDescription(dose: InfusionSubItem): string {
  let doseMessage = '';
  let parentName = dose.model.protocolID
    ? dose.parentProtocol.name
    : 'CPR Assist';
  if (dose.status === 'DRAFT_DELETE')
    doseMessage = `Deleted Infusion Dose: ${dose.name} in ${parentName}`;
  else if (dose.activeID == null)
    doseMessage = `Created Infusion Dose: ${dose.name} in ${parentName}`;
  else doseMessage = `Updated Infusion Dose: ${dose.name} in ${parentName}`;
  return doseMessage;
}

export const convertInfusionChangeToDraft = (
  dc: DraftChangeItem
): Draft | null => {
  try {
    if (dc.changeItem == null) {
      return null;
    }
    let update: Draft = {
      draftChangeItem: dc,
      model: dc.changeItem,
      title: 'Infusion ' + dc.changeItem.name,
      message: getChangeDescription(dc.changeItem as InfusionItem),
      changeType: DraftChangeType.INFUSION,
    };

    return update;
  } catch (error) {
    return null;
  }
};

/**
 * This function will convert an infusion dose change to a draft
 * @param dc The draft change item to convert
 * @returns The draft item or null if there was an error
 */
export const convertInfusionDoseChangeToDraft = (
  dc: DraftChangeItem
): Draft | null => {
  try {
    if (dc.changeItem == null) {
      return null;
    }
    let update: Draft = {
      draftChangeItem: dc,
      model: dc.changeItem,
      title: 'Infusion Dose ' + dc.changeItem.name,
      message: getDoseChangeDescription(dc.changeItem as InfusionSubItem),
      changeType: DraftChangeType.INFUSION_DOSE,
    };

    return update;
  } catch (error) {
    return null;
  }
};

/**
 * Remove all the infusion drafts from the database - User choose to override the drafts
 * @param department The department to remove the drafts from
 * @returns Success if the drafts were removed or Failure if there was an error
 */
export const removeCurrentInfusionDrafts = async (
  db: DatabaseResponse
): Promise<Response> => {
  try {
    let updates: any[] = [];
    let draftInfusions = await DataStore.query(Drip, (c) =>
      c.and((c) => [c.status.eq('DRAFT'), c.departmentID.eq(db.department.id)])
    );
    for (let i = 0; i < draftInfusions.length; i++) {
      let med: Drip = draftInfusions[i];
      await DataStore.delete(med);
      updates.push({
        model: med,
        message: `Removed Drip Draft for: ${med.name}`,
      });
    }

    /* Now remove the infusion doses */
    let draftInfusionDoses = await DataStore.query(InfusionDose, (c) =>
      c.and((c) => [
        c.or((c) => [c.status.eq('DRAFT'), c.status.eq('DRAFT_DELETE')]),
        c.departmentID.eq(db.department.id),
      ])
    );
    for (let j = 0; j < draftInfusionDoses.length; j++) {
      let dose: InfusionDose = draftInfusionDoses[j];
      await DataStore.delete(dose);
      let protocol = await getProtocolByID(db, dose.protocolID || '');
      let infusion = await getInfusionByID(db, dose.dripID);
      if (protocol == null || infusion == null) continue;
      updates.push({
        model: dose,
        message: `Removed ${infusion.name} dose in ${protocol.name}`,
      });
    }
    return {
      type: ResponseType.Success,
      data: updates,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

/* GraphQL API Queries */
export const getInfusionByID = async (
  db: DatabaseResponse,
  id: string
): Promise<InfusionItem | null> => {
  return new Promise(async (resolve, reject) => {
    try {
      /* Fetch the category from the database */
      const dbMed = findItemByID(id, db.infusions);
      if (dbMed != null) {
        let infusion = dbMed as InfusionItem;
        // if (
        //   infusion.concentrations == null ||
        //   infusion.concentrations.length === 0
        // ) {
        let concentrations = await DataStore.query(Concentration, (c) =>
          c.dripID.eq(infusion.uid)
        );
        infusion.concentrations = concentrations;
        // }
        return resolve(infusion);
      } else {
        executeSingleQuery(getDrip, { id: id }, 1500)
          .then((drip: Drip | null | undefined) => {
            if (drip == null) {
              resolve(null);
            } else {
              let infusion = new InfusionItem(drip, {
                activeConcentrationCheck: false,
              });
              /* TODO MAP THE SUB ITEMS AND TO PROTOCOLS */
              resolve(infusion);
            }
          })
          .catch((error) => {
            reject(error);
          });
      }
    } catch (error: any) {
      reject(error);
    }
  });
};

/* GraphQL API Queries */
export const getInfusionDoseByID = async (
  db: DatabaseResponse,
  id: string
): Promise<InfusionSubItem | null> => {
  return new Promise(async (resolve, reject) => {
    try {
      /* Fetch the category from the database */
      const dbMed = findItemByID(id, db.infusionDoses);
      if (dbMed != null) return resolve(dbMed as InfusionSubItem);
      else {
        executeSingleQuery(getInfusionDose, { id: id }, 1500)
          .then((infusDose: InfusionDose | null | undefined) => {
            if (infusDose == null) {
              resolve(null);
            } else {
              let promises = [
                getProtocolByID(db, infusDose.protocolID || ''),
                getInfusionByID(db, infusDose.dripID),
              ];
              Promise.all(promises).then((results) => {
                let protocol = results[0] as ProtocolItem;
                let infusion = results[1] as InfusionItem;
                if (protocol == null || infusion == null) resolve(null);

                let infusionDose = new InfusionSubItem(
                  infusion,
                  protocol,
                  infusDose
                );
                resolve(infusionDose);
              });

              // let infusion = new MedicationItem(med);
              // /* TODO MAP THE SUB ITEMS AND TO PROTOCOLS */
              // resolve(infusion);
            }
          })
          .catch((error) => {
            reject(error);
          });
      }
    } catch (error: any) {
      reject(error);
    }
  });
};

/**
 * Delete an infusion dose item
 * @param infusionDose The infusion dose item to delete
 * @param isSoft Whether to soft delete the item
 * @returns A response object indicating the success or failure of the operation
 * @description
 * IsSoft = True
 *    1. Fetch the item from the database to verify it exists
 *    2. Delete the ACTIVE item from the database
 *    3. Create a new infusion dose item wiht the same data in the DELETED status
 *    4. Set all the archive items activeID to the new infusion dose item
 * IsSoft = False
 *    1. Delete the item from the database
 */
export const deleteInfusionDoseItem = async (
  infusionDose: InfusionSubItem,
  isSoft: boolean = true
): Promise<Response> => {
  try {
    let dbMed: InfusionDose | null = await executeSingleQuery(getInfusionDose, {
      id: infusionDose.uid,
    });
    if (dbMed == null) {
      return {
        type: ResponseType.Failure,
        data: 'The infusion does not exist',
      };
    }
    let id: string = infusionDose.uid;

    if (isSoft) {
      let deletedItem = await DataStore.delete(InfusionDose, id).catch(
        (error) => {
          console.error('Error in deleteInfusionDoseItem: ', error);
          return null;
        }
      );
      if (deletedItem == null) {
        return {
          type: ResponseType.Failure,
          data: 'The infusion dose did not delete correctly',
        };
      }
      infusionDose.status = ProgressStatus.DELETED;
      let result: Response = await createInfusionDoseItem(infusionDose);
      if (result.type === ResponseType.Failure) {
        console.error('Error in createInfusionDoseItem', result.data);
        return result;
      }
      let newDeletedInfusion: InfusionItem = result.data as InfusionItem;

      /* Update all the archive items to the new active item */
      result = await setAllArchiveItemsToNewActiveID(
        infusionDose,
        newDeletedInfusion,
        updateInfusionDose,
        infusionDosesByDepartmentID,
        {
          departmentID: infusionDose.departmentID,
        }
      ).catch((e) => {
        console.error('Error in setAllArchiveItemsToNewActiveID', e);
        return {
          type: ResponseType.Failure,
          data: e,
        };
      });

      if (result.type === ResponseType.Failure) {
        console.error('Error in setAllArchiveItemsToNewActiveID', result.data);
        return result;
      }

      if (globals.debug)
        console.log('setAllArchiveItemsToNewActiveID', result.data);

      /* Check to see if there was a DraftChange referencing this item and update it to the new ID */
      checkIdUpdateDraftChange(infusionDose.id, newDeletedInfusion.uid);
    } else {
      let resp = await DataStore.delete(InfusionDose, dbMed.id);
      if (resp == null) {
        return {
          type: ResponseType.Failure,
          data: 'The infusion does not exist',
        };
      }
    }
    return {
      type: ResponseType.Success,
      data: infusionDose,
    };
  } catch (error: any) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

/**
 * Fetch the infusions by the status
 * @param dep The department to fetch the infusions from
 * @param status The status of the infusions to fetch
 * @param waitForUsers Whether to wait for the users to be fetched
 * @returns The infusions that were fetched
 */
export const fetchInfusionsByStatusAPI = async (
  dep: DepartmentItem,
  db: DatabaseResponse,
  status: ProgressStatus = ProgressStatus.ACTIVE,
  waitForUsers: boolean = false,
  includeErrors: boolean = false
): Promise<Response> => {
  try {
    /* Fetch the protocols for this category and then sort by index */
    let depIDs = dep.getAllParentDepIDs();
    let start = new Date();
    let promises: any[] = [];
    for (let i = 0; i < depIDs.length; i++) {
      promises.push(
        executeQuery(dripsByDepartmentID, {
          departmentID: depIDs[i],
          filter: {
            and: [
              {
                status: {
                  eq: status,
                },
              },
              {
                _deleted: {
                  ne: true,
                },
              },
            ],
          },
        })
      );
    }
    let infusionResults: any = await Promise.all(promises);
    let infusionList: InfusionItem[] = [];
    for (let i = 0; i < infusionResults.length; i++) {
      if (infusionResults[i] != null)
        infusionList.push(
          ...infusionResults[i].map(
            (m: InfusionDose) =>
              new InfusionItem(m as any, {
                activeConcentrationCheck: false,
              })
          )
        );
    }
    if (globals.debug)
      console.log(
        'Infusions fetched in',
        new Date().getTime() - start.getTime(),
        'ms',
        infusionList
      );

    let archiveItems: ArchiveItem[] = [];
    for (let i = 0; i < infusionList.length; i++) {
      const inf = infusionList[i];
      if (inf.activeID == null) {
        if (includeErrors === false) continue;
        console.log('Infusion has no activeID', inf.name);
        let archiveItem = archiveItems.find((item) => item.id === 'ERROR');
        if (archiveItem) {
          let items = archiveItem.items;
          items.push(inf);
          archiveItem.items = items;
        } else {
          archiveItems.push({
            id: 'ERROR',
            activeItem: inf,
            items: [inf],
          });
        }
      } else {
        /* 1-2-25 Gagan: Needed to check uid and activeID if an item is in DRAFT mode currently */
        const activeInf = db.infusions.find(
          (c) => c.uid === inf.activeID || c.activeID === inf.activeID
        );
        if (activeInf == null) {
          if (includeErrors === false) continue;
          console.log('Infusion has no activeID', inf.name);
        }
        let archiveItem = archiveItems.find((item) => item.id === inf.activeID);
        if (archiveItem) {
          let items = archiveItem.items;
          items.push(inf);
          items.sort((a: InfusionItem, b: InfusionItem) => {
            if (a.version === b.version) return a.name.localeCompare(b.name);
            return compareVersions(
              a.version || 'v1.0.0',
              b.version || 'v1.0.0'
            ) as number;
          });
          archiveItem.items = items;
        } else {
          archiveItems.push({
            id: inf.activeID || 'ERROR',
            activeItem: activeInf as InfusionItem,
            items: [inf],
          });
        }
      }
    }

    return {
      type: ResponseType.Success,
      data: archiveItems,
    };
  } catch (error) {
    console.error('Error fetching protocols:', error);
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const fetchAllInfusionDosesForInfusion = async (
  db: DatabaseResponse,
  dep: DepartmentItem,
  infusion: InfusionItem,
  useDataStore: boolean = false
): Promise<Response> => {
  try {
    let depIDs = [dep.id];
    if (dep.parentDep) depIDs.push(dep.parentDep.id);
    if (dep.parentDep?.parentDep) depIDs.push(dep.parentDep.parentDep.id);

    let infusionID: string | null = getActiveID(infusion);
    const activeInfusion =
      infusion.status === ProgressStatus.DRAFT ? infusion.activeItem : infusion;

    if (infusionID == null && activeInfusion == null) {
      return {
        type: ResponseType.Failure,
        data:
          'The infusion does not have a proper version ID 4' +
          infusion.uid +
          ' ' +
          infusion.activeID +
          ' ' +
          infusion.status,
      };
    }

    let infusionList;
    if (useDataStore) {
      infusionList = await DataStore.query(InfusionDose, (m) =>
        m.and((m) => [
          m.dripID.eq(infusionID as string),
          m.or((m) => depIDs.map((id) => m.departmentID.eq(id))),
          m.and((m) => [m.status.ne('DELETED')]),
        ])
      );
    } else {
      infusionList = await executeQuery(infusionDosesByDripID, {
        dripID: infusionID,
        filter: {
          and: [
            { or: depIDs.map((id) => ({ departmentID: { eq: id } })) },
            { status: { ne: 'DELETED' } },
            { _deleted: { ne: true } },
          ],
        },
      });
    }

    let infusionDoses: InfusionSubItem[] = [];
    for (let i = 0; i < infusionList.length; i++) {
      let infusionDose = infusionList[i];
      if (infusionDose.departmentID !== dep.id) continue;
      else {
        let protocol = db.protocols.find(
          (p) =>
            p.uid === infusionDose.protocolID ||
            p.activeID === infusionDose.protocolID
        );
        if (protocol) {
          let med = new InfusionSubItem(
            activeInfusion as InfusionItem,
            protocol,
            infusionDose
          );
          infusionDoses.push(med);
        }
      }
    }

    let archiveItems: ArchiveItem[] = [];
    for (let i = 0; i < infusionDoses.length; i++) {
      const med = infusionDoses[i];
      // When med is in ACTIVE mode
      if (med.activeID == null && med.status === 'ACTIVE') {
        // Find for an existing archive item with the same uid
        let archiveItem = archiveItems.find((item) => item.id === med.uid);
        if (archiveItem) {
          // If found, check if the activeItem is in DRAFT mode
          // If not, update the activeItem
          if (archiveItem.activeItem.status !== 'DRAFT') {
            archiveItem.activeItem = med;
          } else {
            // If the activeItem is in DRAFT mode, update the activeItem.activeItem
            archiveItem.activeItem.activeItem = med;
          }
        } else {
          // If not found, create a new archive item
          archiveItems.push({
            id: med.uid,
            activeItem: med,
            items: [],
          });
        }
      }
      // When med is in DRAFT mode - it is the latest version which is not yet published
      else if (med.activeID != null && med.status === 'DRAFT') {
        // Find for an existing archive item with the same uid
        let archiveItem = archiveItems.find((item) => item.id === med.activeID);
        if (archiveItem) {
          // If found, update the activeItem
          archiveItem.id = med.activeID || 'ERROR';
          med.activeItem = archiveItem.activeItem as InfusionSubItem;
          archiveItem.activeItem = med;
        } else {
          // If not found, create a new archive item
          archiveItems.push({
            id: med.activeID || 'ERROR',
            activeItem: med,
            items: [],
          });
        }
      }
      // When med is in ARCHIVE mode
      else if (med.activeID != null && med.status === 'ARCHIVE') {
        // Find for an existing archive item with the same activeID
        let archiveItem = archiveItems.find((item) => item.id === med.activeID);
        if (archiveItem) {
          // If found, add this med to the items array
          let items = archiveItem.items;
          items.push(med);
          // Sort the items array by version
          items.sort((a: InfusionSubItem, b: InfusionSubItem) => {
            if (a.version === b.version) return a.name.localeCompare(b.name);
            return compareVersions(
              a.version || 'v1.0.0',
              b.version || 'v1.0.0'
            ) as number;
          });
          archiveItem.items = items;
        } else {
          // If not found, create a new archive item
          archiveItems.push({
            id: med.activeID || 'ERROR',
            activeItem: med,
            items: [med],
          });
        }
      }
    }

    // Filter out any activeItems that are in the archiveItems with activeItem.status !== 'ACTIVE'
    archiveItems = archiveItems.filter(
      (item) =>
        item.activeItem.status === 'ACTIVE' ||
        item.activeItem.status === 'DRAFT'
    );

    return {
      type: ResponseType.Success,
      data: archiveItems,
    };
  } catch (error) {
    console.error('Error fetching infusionList:', error);
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const fetchAllInfusionDosesForProtocol = async (
  db: DatabaseResponse,
  dep: DepartmentItem,
  protocol: ProtocolItem
): Promise<Response> => {
  try {
    let protocolID = getActiveID(protocol);
    if (protocolID == null) {
      return {
        type: ResponseType.Failure,
        data: 'The protocol does not have a proper version ID',
      };
    }

    let infusionList = await executeQuery(infusionDosesByProtocolID, {
      protocolID: protocolID,
      filter: {
        and: [
          {
            _deleted: {
              ne: true,
            },
          },
          {
            status: {
              ne: 'DEACTIVATED',
            },
          },
          {
            status: {
              ne: 'DRAFT',
            },
          },
        ],
      },
    });

    // filter out any items that have Medication === null
    infusionList = infusionList.filter((item: any) => item.Drip != null);

    let infusionDoses: InfusionSubItem[] = [];
    for (let i = 0; i < infusionList.length; i++) {
      let infusionDose = infusionList[i];
      if (infusionDose.departmentID !== dep.id) continue;
      else {
        if (infusionDose.protocolID) {
          let protocol = db.protocols.find(
            (p) =>
              p.uid === infusionDose.protocolID ||
              p.activeID === infusionDose.protocolID
          );
          if (protocol) {
            const med = new InfusionSubItem(
              new InfusionItem(infusionDose.Drip),
              protocol,
              infusionDose
            );
            infusionDoses.push(med);
          }
        }
      }
    }

    let archiveItems: ArchiveItem[] = [];
    for (let i = 0; i < infusionDoses.length; i++) {
      const med = infusionDoses[i];

      // When med is in ACTIVE or DELETED
      if (
        med.activeID == null &&
        (med.status === 'ACTIVE' || med.status === 'DELETED')
      ) {
        // Find for an existing archive item with the same uid
        let archiveItem = archiveItems.find((item) => item.id === med.uid);
        if (archiveItem) {
          archiveItem.activeItem = med;
        } else {
          // If not found, create a new archive item
          archiveItems.push({
            id: med.uid,
            activeItem: med,
            items: [],
          });
        }
      }
      // When med is in ARCHIVE or DELETED mode
      else if (med.activeID != null && med.status === 'ARCHIVE') {
        // Find for an existing archive item with the same activeID
        let archiveItem = archiveItems.find((item) => item.id === med.activeID);
        if (archiveItem) {
          // If found, add this med to the items array
          let items = archiveItem.items;
          items.push(med);
          // Sort the items array by version
          items.sort((a: InfusionSubItem, b: InfusionSubItem) => {
            if (a.version === b.version) return a.name.localeCompare(b.name);
            return compareVersions(
              a.version || 'v1.0.0',
              b.version || 'v1.0.0'
            ) as number;
          });
          archiveItem.items = items;
        } else {
          // If not found, create a new archive item
          archiveItems.push({
            id: med.activeID || 'ERROR',
            activeItem: med,
            items: [med],
          });
        }
      }
    }

    // Filter out any activeItems that are in the archiveItems with activeItem.status !== 'ACTIVE' or 'DELETED'
    archiveItems = archiveItems.filter(
      (item) =>
        item.activeItem.status === 'ACTIVE' ||
        item.activeItem.status === 'DELETED'
    );

    return {
      type: ResponseType.Success,
      data: archiveItems,
    };
  } catch (error) {
    console.error('Error fetching infusionList:', error);
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};
