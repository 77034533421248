import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Io5 from 'react-icons/io5';
import {
  DatabaseResponse,
  Response,
  ResponseType,
  loadDatabase,
} from '../../../data/AmplifyDB';
import ProtocolHeader from '../protocol/ProtocolHeader';
import { useDispatch } from 'react-redux';
import { FaChevronDown, FaHeartPulse } from 'react-icons/fa6';
import {
  findDepartmentOwner,
  getDepartmentsFromState,
  getFormattedDate,
  globals,
  handleCopy,
  hasAdminUserAccess,
  upgradeVersion,
} from '../../_global/common/Utils';
import { BiCopy, BiSolidCopy } from 'react-icons/bi';
import { ViewportList } from 'react-viewport-list';
import ConfirmModal from '../../components/Modal/ConfirmModal';
import './Settings.scss';
import { Col, Row } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import DepartmentItem from '../../../data/model/DepartmentItem';
import Loading from '../../components/Loading/Loading';
import { handleGetDepartment } from '../../../store/actions';
import { InputText } from 'primereact/inputtext';
import { SoftwareType, User, UserType } from '../../../models';
import { ProgressStatus } from '../../../models';
import Status from '../../components/ProgressStatus/ProgressStatus';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tooltip,
} from '@mui/material';
import CPRItem from '../../../data/model/CPRItem';
import MedicationItem from '../../../data/model/MedicationItem';
import ElectricalItem from '../../../data/model/ElectricalItem';
import EquipmentItem from '../../../data/model/EquipmentItem';
import MedicationSubItem, {
  cloneMedicationSubItem,
} from '../../../data/model/MedicationSubItem';
import ElectricalSubItem, {
  cloneElectricalSubItem,
} from '../../../data/model/ElectricalSubItem';
import NewMedicationCPRSideout from '../protocol/details/NewMedicationCPRSideout';
import NewElectricalCPRSideout from '../protocol/details/NewElectricalCPRSideout';
import AccordionItem from '../../components/Accordion/AccordionItem';
import MedicationDoseUI from '../protocol/edit/Medication/MedicationDoseUI';
import EquipmentCPRSideout from '../../components/SideOut/EquipmentCPRSideout';
import ElectricalDoseUI from '../protocol/edit/Electrical/ElectricalDoseUI';
import {
  cprDB,
  createCPRAssist,
  deleteCPRItem,
  fetchCPRAssists,
} from '../../../data/functions/CprDB';
import NewModelItemSideout from '../../components/SideOut/NewModelItemSideout';
import { FaCheckCircle, FaTimes } from 'react-icons/fa';
import {
  isDraftCreated,
  removeCurrentDraftUpdates,
} from '../../../data/AmplifyVersion';
import DraftSaveModal from '../../components/Modal/DraftSaveModal';
import AddCPRDoseSideout from '../../components/SideOut/AddCPRDoseSideout';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';
import { createMedicationDoseItem } from '../../../data/functions/MedicationDB';
import { createElectricalDoseItem } from '../../../data/functions/ElectricalDB';
import GeneralSelection from '../../components/Selection/GeneralSelection';

const CPRAssistPage = (props: any) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const reducerState = useSelector((state: any) => state.department);
  const location = useLocation();
  const { state } = location;
  const dbState = useSelector((state: any) => state);
  // const
  const [database, setDatabase] = useState<DatabaseResponse>(
    dbState.protocol.departmentItem
  );
  const [showDeactivated, setShowDeactivated] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);

  const department: DepartmentItem = useMemo(() => {
    return database.department;
  }, [database.department]);

  const [parmCPR, setParmCPR] = useState<CPRItem | undefined>(
    database.cprModel
  );

  const user: User = useSelector((state: any) => state?.user);

  const [isCopied, setIsCopied] = useState<string | null>(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [loading, setLoading] = useState<string | null>(null);
  const [selectedMedicationItem, setSelectedMedicationItem] =
    useState<MedicationItem>();
  const [selectedElectricalItem, setSelectedElectricalItem] =
    useState<ElectricalItem>();
  const [showMissingItemModal, setShowMissingItemModal] = useState<string>('');
  const [selectedItemType, setSelectedItemType] = useState<string | null>(null);

  const [selectedAirway, setSelectedAirway] = useState<
    EquipmentItem | undefined
  >();
  const [showSelectedAirway, setShowSelectedAirway] = useState<boolean>(false);
  const [showAddCPRDoseSideout, setShowAddCPRDoseSideout] =
    useState<boolean>(false);
  const [showNewModelItemSideout, setShowNewModelItemSideout] =
    useState<boolean>(false);

  const [isOpen, setIsOpen] = useState(false);
  const [isDraftConfirmModal, setIsDraftConfirmModal] = useState(false);
  const [isConfrimBetaModal, setIsConfrimBetaModal] = useState(false);

  const [selectedDose, setSelectedDose] = useState<
    MedicationSubItem | undefined
  >(undefined);
  const [selectedElectricalDose, setSelectedElectricalDose] = useState<
    ElectricalSubItem | undefined
  >(undefined);
  const [isDelete, setIsDelete] = useState(false);
  const departmentOwner = useMemo(() => {
    return parmCPR
      ? findDepartmentOwner(department, reducerState, parmCPR)
      : null;
  }, [department, parmCPR, reducerState]);

  const modifiedBy = useMemo(() => {
    return parmCPR
      ? database.users.find((user) => user.id === parmCPR.modifiedBy?.id)
      : undefined;
  }, [database, parmCPR]);

  const defibElectrical = useMemo(() => {
    return database.electrical.find(
      (item) =>
        item.name.toLowerCase().includes('defibrillation') &&
        item.status !== 'DEACTIVATED'
    );
  }, [database.electrical]);

  const epiMedication = useMemo(() => {
    return database.medications.find(
      (item) =>
        item.name.toLowerCase().includes('epi') && item.status !== 'DEACTIVATED'
    );
  }, [database.medications]);

  const [epiDoses, setEpiDoses] = useState<MedicationSubItem[]>(
    parmCPR?.epiDoses || []
  );

  const [defibShocks, setDefibShocks] = useState<ElectricalSubItem[]>(
    parmCPR?.defibShocks || []
  );

  /* Hotkey detection */
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.ctrlKey && event.shiftKey && event.key === 'O') {
        console.log('PARM CPR', parmCPR);
        console.log('EPI DOSES', epiDoses);
        console.log('DEFIB SHOCKS', defibShocks);
      }
    };

    // Add event listener
    document.addEventListener('keydown', handleKeyDown);

    // Remove event listener on cleanup
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [parmCPR, epiDoses, defibShocks]);

  useEffect(() => {
    if (department.softwarePlan !== SoftwareType.PREMIUM) {
      navigate('/protocol');
    }
  }, [department]);

  useEffect(() => {
    setDefibShocks(parmCPR?.defibShocks || []);
    setEpiDoses(parmCPR?.epiDoses || []);
  }, [parmCPR?.defibShocks, parmCPR?.epiDoses]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      defaultMode: parmCPR?.defaultMode || '15:2',
      epiOrangeSec: parmCPR?.epiOrangeSeconds || 120,
      epiRedSec: parmCPR?.epiRedSeconds || 180,
      compressionRate: parmCPR?.compressionRate || 100,
      ventratePerMin: parmCPR?.ventRatePerMin || 10,
      ventaliteSoundDelay: parmCPR?.ventilateSoundDelay || 10,
      equipmentID: parmCPR?.airwayDevice?.uid || '',
      ventilateLoops: parmCPR?.ventilateLoops || 2,
      continousVentilateLoops: parmCPR?.continousVentilateLoops || 2,
      ventilateSoundSpeed: parmCPR?.ventilateSoundSpeed || 1.15,
      pulseCheckTime: parmCPR?.pulseCheckTime || 120,
      chargingTime: parmCPR?.chargingTime || 105,
    },
    validationSchema: Yup.object({
      defaultMode: Yup.string()
        .oneOf(['15:2', '30:2', 'Continuous'])
        .required('Required'),
      epiOrangeSec: Yup.number()
        .max(Yup.ref('epiRedSec'), 'Orange color must be less than red color')
        .required('Required'),
      epiRedSec: Yup.number()
        .min(
          Yup.ref('epiOrangeSec'),
          'Red color must be greater than orange color'
        )
        .required('Required'),
      compressionRate: Yup.number()
        .min(100, 'Rate must be greater than 100 BPM')
        .required('Compression Rate is required')
        .max(120, 'Rate must be less than 120 BPM'),
      ventratePerMin: Yup.number()
        .min(2, 'Rate must be greater than 2 BPM')
        .required('Async Ventilation Rate is required')
        .max(60, 'Rate must be less than 60 BPM'),
      ventaliteSoundDelay: Yup.number().required('Required'),
      ventilateLoops: Yup.number().required('Required'),
      continousVentilateLoops: Yup.number()
        .required('Required')
        .min(1, 'Must be greater than 0')
        .max(3, 'Must be less than 3'),
      ventilateSoundSpeed: Yup.number()
        .required('Required')
        .min(0.5, 'Must be greater than 0.5')
        .max(3, 'Must be less than 3'),
      pulseCheckTime: Yup.number()
        .required('Required')
        .min(1, 'Must be greater than 0')
        .max(180, 'Must be less than 180'),
      chargingTime: Yup.number()
        .required('Required')
        .min(1, 'Must be greater than 0')
        .max(180, 'Must be less than 180'),
    }),
    onSubmit: async (values) => {
      setLoading('Saving CPR Settings');
      if (parmCPR) {
        try {
          if (formik.isValid && formik.dirty) {
            let updatedCPR: cprDB = {
              departmentID: parmCPR.departmentID,
              activeID:
                parmCPR.status === ProgressStatus.ACTIVE
                  ? parmCPR.uid
                  : parmCPR.activeID,
              version:
                parmCPR.status === ProgressStatus.ACTIVE
                  ? upgradeVersion(
                      parmCPR.version == null ? 'v1.0.0' : parmCPR.version
                    )
                  : parmCPR.version == null
                    ? 'v1.0.0'
                    : parmCPR.version,
              status: ProgressStatus.DRAFT,
              createdBy:
                parmCPR.model && parmCPR.model.createdBy
                  ? parmCPR.model.createdBy
                  : '',
              modifiedBy: user.id,
              defaultMode: values.defaultMode as '15:2' | '30:2' | 'Continuous',
              epiOrangeSec: values.epiOrangeSec,
              epiRedSec: values.epiRedSec,
              compressionRate: values.compressionRate,
              ventRatePerMin: values.ventratePerMin,
              ventilateSoundDelay: values.ventaliteSoundDelay,
              equipmentID: selectedAirway?.uid || '',
              ventilateLoops: Number(values.ventilateLoops),
              continousVentilateLoops: Number(values.continousVentilateLoops),
              ventilateSoundSpeed: Number(values.ventilateSoundSpeed),
              pulseCheckTime: Number(values.pulseCheckTime),
              chargingTime: Number(values.chargingTime),
            };
            console.log('UPDATED CPR', updatedCPR);
            createCPRAssist(updatedCPR, parmCPR).then((resp) => {
              if (resp.type === ResponseType.Success) reloadDatabase();
            });
          }

          let promises: Promise<Response>[] = [];
          for (let i = 0; i < epiDoses.length; i++) {
            const medication = epiDoses[i];
            let index = i;
            if (database.department.parentDep?.parentDep) index += 10000;
            else if (database.department.parentDep) index += 1000;
            if (index !== medication.index) {
              let newMed = cloneMedicationSubItem(medication);
              newMed.index = index;
              newMed.status = ProgressStatus.DRAFT;
              newMed.version = upgradeVersion(medication.version);
              newMed.activeID =
                medication.status === ProgressStatus.ACTIVE
                  ? medication.uid
                  : medication.activeID;
              newMed.parentCPR = parmCPR;
              // newMed.parentProtocol = undefined;
              console.log('Creating new medication', newMed);

              promises.push(createMedicationDoseItem(newMed, medication));
            }
          }

          for (let i = 0; i < defibShocks.length; i++) {
            const shock = defibShocks[i];
            let index = i;
            if (database.department.parentDep?.parentDep) index += 10000;
            else if (database.department.parentDep) index += 1000;
            if (index !== shock.index) {
              let newShock = cloneElectricalSubItem(shock);
              newShock.index = index;
              newShock.status = ProgressStatus.DRAFT;
              newShock.version = upgradeVersion(shock.version);
              newShock.activeID =
                shock.status === ProgressStatus.ACTIVE
                  ? shock.uid
                  : shock.activeID;
              newShock.parentCPR = parmCPR;
              // newShock.parentProtocol = undefined;
              promises.push(createElectricalDoseItem(newShock, shock));
            }
          }

          let results = await Promise.all(promises);
          for (let i = 0; i < results.length; i++) {
            let result = results[i];
            if (result.type === ResponseType.Failure) {
              console.error('Failed to reorder medications', result.data);
            }
          }
        } catch (err) {
          console.log('Error in updating CPR', err);
        } finally {
          setLoading(null);
          reloadDatabase();
        }
      }
    },
  });

  useEffect(() => {
    /* 12-04-24 Gagan: Code to display MedicationDose and ElectricalDose with ParentCPR in Activity Log */
    if (state?.selectedDose == null || parmCPR == null) {
      return;
    } else {
      switch (state.selectedDose.TAG) {
        case 'MedicationSubItem':
          const doseToEdit = parmCPR.epiDoses.find(
            (dose) => dose.uid === state.selectedDose.uid
          );
          if (doseToEdit == null) return;
          setSelectedDose(doseToEdit);
          break;
        case 'ElectricalSubItem':
          const shockToEdit = parmCPR.defibShocks.find(
            (shock) => shock.uid === state.selectedDose.uid
          );
          if (shockToEdit == null) return;
          setSelectedElectricalDose(shockToEdit);
          break;
        default:
          break;
      }
    }
  }, [state?.selectedDose, parmCPR]);

  useEffect(() => {
    if (parmCPR?.model?.equipmentID) {
      const deviceID = parmCPR.model.equipmentID;

      setSelectedAirway(
        database.equipment.find(
          (item) => item.uid === deviceID || item.activeID === deviceID
        )
      );
      formik.setFieldValue('equipmentID', deviceID);
    } else {
      setSelectedAirway(undefined);
      formik.setFieldValue('equipmentID', '');
    }
  }, [parmCPR?.model?.equipmentID, database.equipment]);

  const isSaveValid = useMemo(() => {
    /* Next check if the order of the doses is the same */
    if (epiDoses.length !== parmCPR?.epiDoses.length) return true;
    else if (defibShocks.length !== parmCPR?.defibShocks.length) return true;

    for (let i = 0; i < epiDoses.length; i++) {
      if (epiDoses[i].uid !== parmCPR?.epiDoses[i].uid) return true;
    }
    for (let i = 0; i < defibShocks.length; i++) {
      if (defibShocks[i].uid !== parmCPR?.defibShocks[i].uid) return true;
    }

    //Make sure the equipment is the same
    if (
      (parmCPR.airwayDevice &&
        parmCPR.airwayDevice.uid !== formik.values.equipmentID) ||
      (parmCPR.airwayDevice == null && formik.values.equipmentID !== '')
    )
      return true;

    return formik.dirty && formik.isValid;
  }, [
    formik.isValid,
    formik.dirty,
    parmCPR,
    epiDoses,
    defibShocks,
    formik.values.equipmentID,
  ]);

  const onAddDoseClick = (title: string) => {
    if (title === 'Medication') {
      if (epiMedication) {
        setSelectedMedicationItem(epiMedication);
      } else {
        setShowMissingItemModal('Medication');
      }
    } else if (title === 'Electrical') {
      if (defibElectrical) {
        setSelectedElectricalItem(defibElectrical);
      } else {
        setShowMissingItemModal('Electrical');
      }
    } else if (title === 'Equipment') {
      setShowSelectedAirway(true);
    }
  };

  const ListRenderMedication = ({ data }: any) => {
    let list = data.filter(
      (item: MedicationSubItem) =>
        showDeactivated || item.status !== ProgressStatus.DEACTIVATED
    );

    return (
      <ViewportList items={list}>
        {(dose: MedicationSubItem, index: number) => {
          const lastIndex = list.length === index + 1;
          return (
            <Draggable
              key={dose.uid}
              draggableId={dose.uid}
              isDragDisabled={!isEditMode}
              index={index}
            >
              {(provided: any) => (
                <div
                  key={dose.uid}
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    if (isEditMode) {
                      setSelectedDose(dose);
                      // setEditDose(true);
                      // setSidebarVisible(true);
                    }
                  }}
                >
                  <li ref={provided.innerRef} {...provided.draggableProps}>
                    <MedicationDoseUI
                      dose={dose}
                      doseIndex={dose.index + 1}
                      showProtocol={false}
                      provided={provided}
                      isEditMode={isEditMode}
                    />
                  </li>
                </div>
              )}
            </Draggable>
          );
        }}
      </ViewportList>
    );
  };

  const ListRenderElectrical = ({ data }: any) => {
    const list = data.filter(
      (item: ElectricalSubItem) =>
        showDeactivated || item.status !== ProgressStatus.DEACTIVATED
    );

    return (
      <ViewportList items={list}>
        {(dose: ElectricalSubItem, index: number) => {
          const lastIndex = list.length === index + 1;
          return (
            <Draggable
              key={index}
              draggableId={dose.uid}
              isDragDisabled={!isEditMode}
              index={index}
            >
              {(provided: any) => (
                <div
                  key={dose.uid}
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    if (isEditMode) {
                      setSelectedElectricalDose(dose);
                    }
                  }}
                >
                  <li ref={provided.innerRef} {...provided.draggableProps}>
                    <ElectricalDoseUI
                      dose={dose}
                      doseIndex={dose.index + 1}
                      showProtocol={false}
                      provided={provided}
                      isEditMode={isEditMode}
                    />
                  </li>
                </div>
              )}
            </Draggable>
          );
        }}
      </ViewportList>
    );
  };

  // navigate to protocol edit page
  const handleNavigate = (parent: EquipmentItem, child: EquipmentItem) => {
    if (
      user &&
      user.type !== UserType.ADMIN &&
      user.type !== UserType.DEPT_ADMIN
    )
      return;
    const state = {
      selectedProtocol: null,
      value: parent,
      subValue: child,
      type: 'Equipment',
      editType: 'edit',
      editMode: isEditMode,
      page: 'protocolDetailPage',
    };
    navigate(`/protocol/edit/edit-protocol`, { state });
  };

  const ListRenderEquipment = ({ data }: any) => {
    return (
      <ul className="listItem">
        <div
          className={`listContainer ${isEditMode ? 'cursorPointer hoverableBackground' : ''}`}
          style={{
            borderRadius: '6px',
          }}
        >
          <li
            onClick={() => isEditMode && handleNavigate(data, data)}
            className="listContent"
            style={{
              fontSize: '1rem',
            }}
          >
            <div className="a1SubITems">
              <div
                style={{
                  display: 'flex',
                  flex: 1,
                }}
              >
                <div className={'a1Content'} style={{}}>
                  <span>{data.name}</span>
                  <span>
                    {data.status !== 'ACTIVE' && (
                      <Status status={data.status} />
                    )}
                  </span>
                </div>
              </div>
              <div
                className="a1SubItemsContent"
                style={{ justifyContent: 'flex-end' }}
              >
                <div className="a1type">
                  {data.options?.length} Sizes
                  <span>
                    <Io5.IoChevronForward
                      size="1.5rem"
                      style={{ marginLeft: '10px' }}
                    />
                  </span>
                </div>
                {isEditMode && (
                  <span>
                    <Tooltip title={'Remove'} placement="right" arrow>
                      <span
                        className={`iconButton redHover`}
                        onClick={(e) => {
                          e.stopPropagation();
                          setSelectedAirway(undefined);
                          formik.setFieldValue('equipmentID', '');
                        }}
                      >
                        <FaTimes
                          data-testid="isFullScreenButton"
                          className="icon"
                          style={{
                            padding: '6px',
                            color: '#a94c4c',
                          }}
                        />
                      </span>
                    </Tooltip>
                  </span>
                )}
              </div>
            </div>
          </li>
        </div>
      </ul>
    );
  };

  // function for dragging
  const onDragEnd = (result: DropResult, type: string) => {
    if (!result.destination) return;
    if (type === 'Medication' && epiDoses) {
      console.log(
        'epiDoses',
        epiDoses.map((item) => item.uid)
      );
      const updatedItems = reorder(
        epiDoses,
        result.source.index,
        result.destination.index
      );
      setEpiDoses(updatedItems as MedicationSubItem[]);
      console.log(
        'POST Doses',
        updatedItems.map((item) => item.uid)
      );
    } else if (type === 'Electrical' && defibShocks) {
      const updatedItems = reorder(
        defibShocks,
        result.source.index,
        result.destination.index
      );
      setDefibShocks(updatedItems as ElectricalSubItem[]);
    }
  };

  const reorder = (
    list: any[],
    startIndex: number,
    endIndex: number
  ): MedicationSubItem[] | ElectricalSubItem[] => {
    // Determine if the item being dragged or the target is in a locked area
    const isStartLocked = hasAdminUserAccess(
      department,
      reducerState,
      user,
      list[startIndex]
    );
    const isEndLocked = hasAdminUserAccess(
      department,
      reducerState,
      user,
      list[endIndex]
    );

    // Prevent dragging an unlocked item into a locked area
    if (isStartLocked && !isEndLocked) return list;
    // let departmentOwner = findDepartmentOwner(department, list[startIndex]);

    //Verify the owner of the items are grouped together
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const handleSetFromSideout = (items: EquipmentItem[], type: string) => {
    if (items.length === 0) {
      setSelectedAirway(undefined);
      formik.setFieldValue('equipmentID', '');
    } else {
      setSelectedAirway(items[0]);
      formik.setFieldValue('equipmentID', items[0].uid);
    }
  };

  const reloadDatabase = async () => {
    /* 1-10-24 Hazlett:  Update the current data to the database change and keep the current state */
    const resp: Response = await fetchCPRAssists(
      department,
      true,
      true,
      database
    );
    if (resp.type === ResponseType.Success) {
      const cprAssist = resp.data as CPRItem;
      setParmCPR(cprAssist);
      const newDB = {
        ...database,
        cprModel: cprAssist,
      };
      setDatabase(newDB);
      dispatch<any>(handleGetDepartment(newDB));
      return newDB;
    }
  };

  const handleCheckIsDraft = useCallback(async (): Promise<boolean> => {
    if (isEditMode) return false;
    let response: Response = await isDraftCreated(database.department);
    if (response.type === ResponseType.Success) {
      let isDraft = response.data;
      if (isDraft) setIsOpen(true);
      return isDraft;
    } else {
      if (globals.debug)
        console.log('Failed to check if draft exists', response);
    }
    return false;
  }, [database.department, isEditMode]);

  const handleMakeNew = async () => {
    let response = await removeCurrentDraftUpdates(database);
    if (response.type === ResponseType.Success) {
      if (response.data.length > 0) {
        if (globals.debug)
          console.log('Successfully removed current draft updates', response);
        setIsOpen(false);
        setIsEditMode(true);
        reloadDatabase().then((newDB: DatabaseResponse | undefined) => {});
      }
    } else {
      if (globals.debug)
        console.log('Failed to remove current draft updates', response);
    }
    setIsDraftConfirmModal(false);
  };

  const handleConfirmDeleteItem = async () => {
    setIsDelete(false);
    if (parmCPR) {
      const isDraft = parmCPR.status === ProgressStatus.DRAFT;
      let response = await deleteCPRItem(parmCPR, user, !isDraft);
      if (response.type === ResponseType.Success) {
        if (globals.debug)
          console.log('Successfully deleted CPR', response.data);
        reloadDatabase().then((newDB: DatabaseResponse | undefined) => {});
      } else {
        console.error('Failed to delete CPR', response.data);
      }
    }
    setIsDelete(false);
  };

  const handleCreateCPR = async () => {
    setLoading('Creating the CPR');
    try {
      createCPRAssist({
        departmentID: department.id,
        activeID: null,
        version: 'v1.0.0',
        status: ProgressStatus.DRAFT,
        createdBy: user.id,
        modifiedBy: user.id,
        defaultMode: '15:2',
        epiOrangeSec: 180,
        epiRedSec: 300,
        compressionRate: 100,
        ventRatePerMin: 10,
        ventilateSoundDelay: 10,
        equipmentID: '',
        ventilateLoops: 2,
        continousVentilateLoops: 2,
        ventilateSoundSpeed: 1.15,
        pulseCheckTime: 120,
        chargingTime: 105,
      }).then((resp) => {
        if (resp.type === ResponseType.Success) {
          setIsEditMode(false);
          reloadDatabase().then((newDB: DatabaseResponse | undefined) => {});
        }
      });
    } catch (err) {
      console.log('Error in creating CPR', err);
    } finally {
      setLoading(null);
      setIsConfrimBetaModal(false);
    }
  };

  useEffect(() => {
    reloadDatabase().then((newDB: DatabaseResponse | undefined) => {});
  }, []);

  return (
    <div
      className="screen-container"
      style={{
        height: '100%',
        padding: '0 0rem',
      }}
    >
      {isOpen && (
        <DraftSaveModal
          database={database}
          isVisible={isOpen}
          handleClose={() => {
            setIsOpen(false);
          }}
          handleContinue={() => {
            setIsEditMode(true);
            setIsOpen(false);
          }}
          handleNew={() => {
            setIsDraftConfirmModal(true);
            setIsOpen(false);
          }}
        />
      )}

      <ConfirmModal
        isVisible={isDraftConfirmModal}
        title="Make New Draft?"
        handleClose={() => {
          setIsDraftConfirmModal(false);
        }}
        handleSubmit={handleMakeNew}
        isDeleteBtn={true}
        primaryBtnName="Cancel"
        secondaryBtnName="Make New"
        primaryDescription="Are you sure you would like to remove the previous draft and override it and create a new one? THIS IS NOT REVERSIBLE."
      />

      <ConfirmModal
        isVisible={isConfrimBetaModal}
        title="Warning: CPR Assist is in Beta"
        handleClose={() => {
          setIsConfrimBetaModal(false);
        }}
        handleSubmit={handleCreateCPR}
        primaryBtnName="Cancel"
        secondaryBtnName="Continue"
        primaryDescription={`CPR Assist is currently in Beta testing. While it’s designed to support you effectively, please be aware that there may be some issues or unexpected behaviors. Do you want to continue using CPR Assist?`}
      />

      {parmCPR && (
        <ConfirmModal
          isVisible={isDelete}
          title="Delete CPR?"
          handleClose={() => {
            setIsDelete(false);
          }}
          handleSubmit={handleConfirmDeleteItem}
          isDeleteBtn={true}
          primaryBtnName="Cancel"
          secondaryBtnName="Delete"
          primaryDescription={`Are you sure you would like to delete the CPR?`}
        />
      )}

      <NewModelItemSideout
        type={
          selectedItemType
            ? (selectedItemType as 'medication' | 'electrical')
            : 'medication'
        }
        isVisible={showNewModelItemSideout}
        handleClose={() => setShowNewModelItemSideout(false)}
      />

      {showMissingItemModal && (
        <ConfirmModal
          isVisible={showMissingItemModal !== ''}
          title={
            showMissingItemModal === 'Medication'
              ? 'Epinephrine Medication is missing'
              : 'Defibrillation Electrical is missing'
          }
          handleClose={() => {
            setShowMissingItemModal('');
          }}
          handleSubmit={() => {
            setShowNewModelItemSideout(true);
            setSelectedItemType(showMissingItemModal?.toLowerCase());
            setShowMissingItemModal('');
          }}
          primaryBtnName="Cancel"
          secondaryBtnName="Create"
          primaryDescription={`The ${showMissingItemModal === 'Medication' ? 'Epinephrine Medication' : 'Defibrillation Electrical'} is not available in your agency. Please create the ${showMissingItemModal === 'Medication' ? 'Epinephrine Medication' : 'Defibrillation Electrical'} item before continuing.`}
        />
      )}

      {parmCPR && epiMedication && (
        <NewMedicationCPRSideout
          visible={selectedMedicationItem != null || selectedDose != null}
          protocol={null}
          handleClose={() => {
            setSelectedMedicationItem(undefined);
            setSelectedDose(undefined);
          }}
          handleSubmit={() => {
            setSelectedMedicationItem(undefined);
            setSelectedDose(undefined);
            reloadDatabase().then((newDB: DatabaseResponse | undefined) => {});
            setShowAddCPRDoseSideout(false);
          }}
          cpr={parmCPR}
          dose={selectedDose ? selectedDose : undefined}
        />
      )}

      {parmCPR && defibElectrical && (
        <NewElectricalCPRSideout
          visible={
            selectedElectricalItem != null || selectedElectricalDose != null
          }
          protocol={null}
          handleClose={() => {
            setSelectedElectricalItem(undefined);
            setSelectedElectricalDose(undefined);
          }}
          handleSubmit={() => {
            setSelectedElectricalItem(undefined);
            setSelectedElectricalDose(undefined);
            reloadDatabase().then((newDB: DatabaseResponse | undefined) => {});
            setShowAddCPRDoseSideout(false);
          }}
          cpr={parmCPR}
          dose={selectedElectricalDose ? selectedElectricalDose : undefined}
        />
      )}

      {showSelectedAirway && (
        <EquipmentCPRSideout
          isVisible={showSelectedAirway}
          handleClose={() => setShowSelectedAirway(false)}
          handleAdd={(selectedOptions, type) => {
            handleSetFromSideout(selectedOptions, type);
            setShowSelectedAirway(false);
            setShowAddCPRDoseSideout(false);
          }}
          selectedEquipment={selectedAirway ? [selectedAirway] : []}
        />
      )}

      {showAddCPRDoseSideout && (
        <AddCPRDoseSideout
          isVisible={showAddCPRDoseSideout}
          handleClose={() => setShowAddCPRDoseSideout(false)}
          handleAdd={(option: any) => {
            switch (option.type) {
              case 'Epinephrine':
                onAddDoseClick('Medication');
                break;
              case 'Defibrillation Shock':
                onAddDoseClick('Electrical');
                break;
              case 'Equipment':
                setShowSelectedAirway(true);
                break;
            }
            // setTimeout(() => {
            //   setShowAddCPRDoseSideout(false);
            // }, 100);
          }}
        />
      )}

      <ProtocolHeader
        name={<>{'CPR Assist - Beta'}</>}
        status={parmCPR?.status}
        bypassActiveStatusCheck
        description={
          'Edit the CPR Assist for the OneDose application. NOTE: This is in Beta mode.'
        }
        isBackButton={true}
        page={'Database'}
        handleCancel={() => {
          navigate('/database');
        }}
        type={'protocol'}
        isAddIcon={parmCPR && isEditMode}
        handleAdd={() => {
          setShowAddCPRDoseSideout(true);
        }}
        rightSideBtn={'edit'}
        isEditButton={parmCPR && !isEditMode}
        handleEdit={() => {
          handleCheckIsDraft().then((isDraft: boolean) => {
            if (!isDraft && !isEditMode) setIsEditMode(true);
          });
        }}
        isSaveButton={parmCPR && isEditMode}
        isSaveActive={isSaveValid}
        handleSave={() => {
          formik.handleSubmit();
        }}
        handleCancelEdit={() => {
          setIsEditMode(false);
          formik.resetForm();
          setSelectedAirway(
            database.equipment.find(
              (item) => item.uid === parmCPR?.airwayDevice?.uid
            )
          );
          setEpiDoses(parmCPR?.epiDoses ?? []);
          setDefibShocks(parmCPR?.defibShocks ?? []);
        }}
        isCreateButton={!parmCPR}
        isCreateActive={true}
        isDeleteButton={parmCPR && isEditMode}
        handleDelete={() => {
          setIsDelete(true);
        }}
        handleCreate={() => {
          setIsConfrimBetaModal(true);
        }}
      />

      {!parmCPR && (
        <div
          className="ketamine-general-label"
          style={{
            display: 'flex', // Corrected: the value should be a string
            flex: 1,
            background: 'transparent',
            padding: '20px 0px',
            // border: '1px dashed rgb(205, 200, 200)',
            borderRadius: '10px',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <div
            style={{
              color: 'rgb(163, 163, 163)',
              fontSize: '1.5rem',
              marginBottom: '1rem',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              fontWeight: '500',
            }}
          >
            <div>
              <FaHeartPulse size={300} />
            </div>
            <div>No CPR Found for this Department</div>
            <div>
              Click the "Create" button to create a new CPR for your agency.
            </div>
          </div>
        </div>
      )}

      {parmCPR && (
        <Row>
          <Col
            sm={6}
            style={{
              padding: '0 2rem',
            }}
          >
            {parmCPR && (
              <>
                <label
                  htmlFor="s"
                  className="settings-general-label"
                  style={{
                    marginLeft: '0rem',
                    fontSize: '1.25rem',
                  }}
                >
                  CPR Assist Settings
                </label>
                <div
                  className="input-container roundBorder"
                  style={{
                    margin: '0px 0rem',
                  }}
                >
                  <Row>
                    <Col sm={6}>
                      <div
                        style={{ display: 'flex' }}
                        className="ketamine-general-label"
                      >
                        <div style={{ marginRight: '10px' }}>Version:</div>
                        <div style={{ fontWeight: '500' }}>
                          {parmCPR.version}
                        </div>
                      </div>
                      {parmCPR.model?.createdAt && (
                        <div
                          style={{ display: 'flex', marginTop: '5px' }}
                          className="settings-general-label"
                        >
                          <div style={{ marginRight: '10px' }}>
                            Modified At:
                          </div>
                          <div style={{ fontWeight: '500' }}>
                            {getFormattedDate(parmCPR.model.createdAt)}
                          </div>
                        </div>
                      )}

                      <div
                        style={{ display: 'flex', marginTop: '5px' }}
                        className="settings-general-label"
                      >
                        <div style={{ marginRight: '10px' }}>Modified By:</div>
                        <div style={{ fontWeight: '500' }}>
                          {modifiedBy
                            ? modifiedBy.firstName + ' ' + modifiedBy.lastName
                            : 'Hinckley Medical'}
                        </div>
                      </div>
                    </Col>
                    <Col sm={6}>
                      {user.type === UserType.ADMIN && (
                        <div
                          style={{ display: 'flex' }}
                          className="ketamine-general-label"
                        >
                          <div style={{ marginRight: '10px' }}>ID:</div>
                          <div style={{ fontWeight: '500' }}>
                            {parmCPR.uid}
                            <span>
                              {isCopied && isCopied === parmCPR.uid ? (
                                <BiSolidCopy
                                  color={'#00534C'}
                                  size="1rem"
                                  className="copy_icon"
                                />
                              ) : (
                                <BiCopy
                                  size="1rem"
                                  className="copy_icon"
                                  onClick={(e) =>
                                    handleCopy(
                                      parmCPR.uid as string,
                                      e,
                                      setIsCopied
                                    )
                                  }
                                />
                              )}
                            </span>
                          </div>
                        </div>
                      )}

                      {user.type === UserType.ADMIN &&
                        parmCPR.activeID != null && (
                          <div
                            style={{ display: 'flex' }}
                            className="ketamine-general-label"
                          >
                            <div style={{ marginRight: '10px' }}>
                              Active ID:
                            </div>
                            <div style={{ fontWeight: '500' }}>
                              {parmCPR.activeID}
                              <span>
                                {isCopied && isCopied === parmCPR.activeID ? (
                                  <BiSolidCopy
                                    color={'#00534C'}
                                    size="1rem"
                                    className="copy_icon"
                                  />
                                ) : (
                                  <BiCopy
                                    size="1rem"
                                    className="copy_icon"
                                    onClick={(e) =>
                                      handleCopy(
                                        parmCPR.activeID as string,
                                        e,
                                        setIsCopied
                                      )
                                    }
                                  />
                                )}
                              </span>
                            </div>
                          </div>
                        )}
                      {user.type === UserType.ADMIN &&
                        parmCPR.overrideItem != null && (
                          <div
                            style={{ display: 'flex' }}
                            className="ketamine-general-label"
                          >
                            <div style={{ marginRight: '10px' }}>
                              Override ID:
                            </div>
                            <div style={{ fontWeight: '500' }}>
                              {parmCPR.overrideID}
                              <span>
                                {isCopied && isCopied === parmCPR.overrideID ? (
                                  <BiSolidCopy
                                    color={'#00534C'}
                                    size="1rem"
                                    className="copy_icon"
                                  />
                                ) : (
                                  <BiCopy
                                    size="1rem"
                                    className="copy_icon"
                                    onClick={(e) =>
                                      handleCopy(
                                        parmCPR.overrideID as string,
                                        e,
                                        setIsCopied
                                      )
                                    }
                                  />
                                )}
                              </span>
                            </div>
                          </div>
                        )}
                      {user.type === UserType.ADMIN && departmentOwner && (
                        <div
                          style={{ display: 'flex' }}
                          className="ketamine-general-label"
                        >
                          <div style={{ marginRight: '10px' }}>Owner:</div>
                          <div style={{ fontWeight: '500' }}>
                            {departmentOwner.name}
                            {departmentOwner.id === database.department.id && (
                              <span style={{ marginLeft: '5px' }}>
                                <FaCheckCircle color={'#00534C'} size="1rem" />
                              </span>
                            )}
                          </div>
                        </div>
                      )}
                    </Col>
                  </Row>
                </div>
              </>
            )}
            <label
              htmlFor="neonateCutoff"
              className="settings-general-label"
              style={{
                fontSize: '1.25rem',
                marginTop: '1rem',
                marginLeft: '0rem',
              }}
            >
              General
            </label>
            <label
              htmlFor="Name"
              className="settings-general-label"
              style={{
                marginTop: '1rem',
                marginLeft: '0px',
              }}
            >
              Epinephrine Settings
            </label>
            <p className="sidebarText" style={{ marginLeft: '0px' }}>
              These are the timing settings for when the epinephrine changes
              colors in the CPR Assist.
            </p>
            <Row>
              <Col sm={6}>
                <label
                  htmlFor="epiOrangeSec"
                  className="settings-general-label"
                >
                  Orange Color (Seconds)
                </label>
                <div className="input-container">
                  <InputText
                    type="text"
                    className="form-control-general"
                    id="epiOrangeSec"
                    name="epiOrangeSec"
                    data-testid="epiOrangeSec"
                    required={true}
                    value={formik.values.epiOrangeSec + ''}
                    onChange={(e: any) => {
                      let num = Number(e.target.value);
                      if (isNaN(num)) {
                        formik.setFieldValue('epiOrangeSec', 0);
                        formik.setFieldError(
                          'epiOrangeSec',
                          'Please enter a valid number'
                        );
                      } else formik.setFieldValue('epiOrangeSec', num);
                    }}
                    disabled={isEditMode ? false : true}
                  />
                  {formik.errors.epiOrangeSec !== '' && (
                    <div
                      className="errorText"
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'flex-end',
                        marginBottom: '5px',
                        fontSize: '16px',
                      }}
                    >
                      {formik.errors.epiOrangeSec}
                    </div>
                  )}
                </div>
              </Col>
              <Col sm={6}>
                <label htmlFor="epiRedSec" className="settings-general-label">
                  Red Color (Seconds)
                </label>
                <div className="input-container">
                  <InputText
                    type="text"
                    className="form-control-general"
                    id="epiRedSec"
                    name="epiRedSec"
                    data-testid="epiRedSec"
                    required={true}
                    value={formik.values.epiRedSec + ''}
                    onChange={(e: any) => {
                      let num = Number(e.target.value);
                      if (isNaN(num)) {
                        formik.setFieldValue('epiRedSec', 0);
                        formik.setFieldError(
                          'epiRedSec',
                          'Please enter a valid number'
                        );
                      } else {
                        formik.setFieldValue('epiRedSec', num);
                        let orangeSec = Number(formik.values.epiOrangeSec);
                        console.log('orangeSec', orangeSec);
                        if (num < orangeSec) {
                          console.error('num < orangeSec');
                          formik.setFieldError(
                            'epiRedSec',
                            'Red color must be greater than orange color'
                          );
                        }
                      }
                    }}
                    disabled={isEditMode ? false : true}
                  />
                  {formik.errors.epiRedSec !== '' && (
                    <div
                      className="errorText"
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'flex-end',
                        marginBottom: '5px',
                        fontSize: '16px',
                      }}
                    >
                      {formik.errors.epiRedSec}
                    </div>
                  )}
                </div>
              </Col>
            </Row>

            <label
              htmlFor="Name"
              className="settings-general-label"
              style={{
                marginTop: '1rem',
                marginLeft: '0px',
              }}
            >
              CPR Settings
            </label>
            <p className="sidebarText" style={{ marginLeft: '0px' }}>
              These are the settings for the CPR Assist for the metronome.
            </p>
            <Row>
              <Col sm={6}>
                <label
                  htmlFor="epiOrangeSec"
                  className="settings-general-label"
                >
                  Compression Rate (BPM)
                </label>
                <div className="input-container">
                  <InputText
                    type="text"
                    className="form-control-general"
                    id="compressionRate"
                    name="compressionRate"
                    data-testid="compressionRate"
                    required={true}
                    value={formik.values.compressionRate + ''}
                    placeholder="100-120..."
                    onChange={(e: any) => {
                      let num = Number(e.target.value);
                      if (isNaN(num)) {
                        // formik.setFieldValue('compressionRate', 0);
                        formik.setFieldError(
                          'compressionRate',
                          'Please enter a valid number'
                        );
                      } else formik.setFieldValue('compressionRate', num);
                    }}
                    disabled={isEditMode ? false : true}
                  />
                  {formik.errors.compressionRate !== '' && (
                    <div
                      className="errorText"
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'flex-end',
                        marginBottom: '5px',
                        fontSize: '16px',
                      }}
                    >
                      {formik.errors.compressionRate}
                    </div>
                  )}
                </div>
              </Col>
              <Col sm={6}>
                <label
                  htmlFor="ventratePerMin"
                  className="settings-general-label"
                >
                  Async Ventilation Rate (BPM)
                </label>
                <div className="input-container">
                  <InputText
                    type="text"
                    className="form-control-general"
                    id="ventratePerMin"
                    name="ventratePerMin"
                    data-testid="ventratePerMin"
                    required={true}
                    value={formik.values.ventratePerMin + ''}
                    onChange={(e: any) => {
                      let num = Number(e.target.value);
                      if (isNaN(num)) {
                        formik.setFieldValue('ventratePerMin', 0);
                        formik.setFieldError(
                          'ventratePerMin',
                          'Please enter a valid number'
                        );
                      } else formik.setFieldValue('ventratePerMin', num);
                    }}
                    disabled={isEditMode ? false : true}
                  />
                </div>
                {formik.errors.ventratePerMin !== '' && (
                  <div
                    className="errorText"
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'flex-end',
                      marginBottom: '5px',
                      fontSize: '16px',
                    }}
                  >
                    {formik.errors.ventratePerMin}
                  </div>
                )}
              </Col>
            </Row>
            <label
              htmlFor="Name"
              className="settings-general-label"
              style={{
                marginTop: '1rem',
                marginLeft: '0px',
              }}
            >
              CPR Time Settings
            </label>
            <p className="sidebarText" style={{ marginLeft: '0px' }}>
              These are the settings for the CPR Assist to trigger at a certain
              time.
            </p>
            <Row>
              <Col sm={6}>
                <label
                  htmlFor="epiOrangeSec"
                  className="settings-general-label"
                >
                  Charging Queue (Seconds)
                </label>
                <div className="input-container">
                  <InputText
                    type="text"
                    className="form-control-general"
                    id="chargingTime"
                    name="chargingTime"
                    data-testid="chargingTime"
                    required={true}
                    value={formik.values.chargingTime + ''}
                    placeholder="1-180..."
                    onChange={(e: any) => {
                      let num = Number(e.target.value);
                      if (isNaN(num)) {
                        formik.setFieldValue('chargingTime', 0);
                        formik.setFieldError(
                          'chargingTime',
                          'Please enter a valid number'
                        );
                      } else formik.setFieldValue('chargingTime', num);
                    }}
                    disabled={isEditMode ? false : true}
                  />
                  {formik.errors.chargingTime !== '' && (
                    <div
                      className="errorText"
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'flex-end',
                        marginBottom: '5px',
                        fontSize: '16px',
                      }}
                    >
                      {formik.errors.chargingTime}
                    </div>
                  )}
                </div>
              </Col>
              <Col sm={6}>
                <label
                  htmlFor="ventratePerMin"
                  className="settings-general-label"
                >
                  Pulse Check Command (Seconds)
                </label>
                <div className="input-container">
                  <InputText
                    type="text"
                    className="form-control-general"
                    id="pulseCheckTime"
                    name="pulseCheckTime"
                    data-testid="pulseCheckTime"
                    required={true}
                    value={formik.values.pulseCheckTime + ''}
                    onChange={(e: any) => {
                      let num = Number(e.target.value);
                      if (isNaN(num)) {
                        formik.setFieldValue('pulseCheckTime', 0);
                        formik.setFieldError(
                          'pulseCheckTime',
                          'Please enter a valid number'
                        );
                      } else formik.setFieldValue('pulseCheckTime', num);
                    }}
                    disabled={isEditMode ? false : true}
                  />
                </div>
                {formik.errors.pulseCheckTime !== '' && (
                  <div
                    className="errorText"
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'flex-end',
                      marginBottom: '5px',
                      fontSize: '16px',
                    }}
                  >
                    {formik.errors.pulseCheckTime}
                  </div>
                )}
              </Col>
            </Row>
            <Accordion
              style={{
                borderRadius: '5px',
                boxShadow: 'none',
                alignContent: 'center',
                justifyContent: 'center',

                fontSize: '16px',
                marginTop: '20px',
                backgroundColor: 'transparent',
                border: '1px solid #ccc',
              }}
              onChange={(e, expanded) => {
                // if (!expanded && formik.values.owner == null)
                //   setTimeout(() => {
                //     formik.setFieldValue('owner', department);
                //   }, 100);
              }}
            >
              <AccordionSummary
                expandIcon={<FaChevronDown />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                Advanced Settings
              </AccordionSummary>
              <AccordionDetails>
                <label
                  htmlFor="defaultMode"
                  className={`notification-css-title`}
                >
                  Default Mode
                </label>
                <span
                  className="contentText greyText"
                  style={{ fontSize: '13px', marginLeft: '6px' }}
                >
                  Choose between the available CPR compression-to-ventilation
                  ratios:
                </span>
                <div className="input-container">
                  <select
                    id="defaultMode"
                    name="defaultMode"
                    className="form-control-general"
                    data-testid="defaultMode"
                    value={formik.values.defaultMode}
                    onChange={(e: any) => {
                      formik.setFieldValue('defaultMode', e.target.value);
                    }}
                    disabled={isEditMode ? false : true}
                  >
                    <option value="15:2">15:2</option>
                    <option value="30:2">30:2</option>
                    <option value="Continuous">Continuous</option>
                  </select>
                  <div className="input-border"></div>
                </div>
              </AccordionDetails>

              <AccordionDetails>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '10px',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flex: 1,
                      flexDirection: 'column',
                    }}
                  >
                    <label
                      htmlFor="ventaliteSoundDelay"
                      className={`notification-css-title`}
                    >
                      Ventilate Sound Speed
                    </label>
                    <span
                      className="contentText greyText"
                      style={{ fontSize: '13px', marginLeft: '0px' }}
                    >
                      This is the scalar for fine tuning the speed of the
                      'Ventilate' command sound.
                    </span>
                    <div className="input-container">
                      <InputText
                        type="text"
                        className="form-control-general"
                        id="ventilateSoundSpeed"
                        name="ventilateSoundSpeed"
                        data-testid="ventilateSoundSpeed"
                        required={true}
                        value={formik.values.ventilateSoundSpeed + ''}
                        placeholder="Ventilate Sound Speed..."
                        onChange={(e: any) => {
                          let value = e.target.value;

                          // Add "0" if user starts with decimal point
                          if (value.startsWith('.')) {
                            value = '0' + value;
                          }

                          // Remove leading zeros but keep single zero and decimal numbers
                          const noLeadingZeros = value.replace(/^0+(?=\d)/, '');

                          // Allow only up to 2 decimal places
                          if (/^\d*\.\d{3,}$/.test(noLeadingZeros)) {
                            return; // Ignore input if more than 2 decimal places
                          }

                          // Allow decimal points and numbers
                          if (
                            noLeadingZeros === '' ||
                            noLeadingZeros === '.' ||
                            /^\d*\.?\d{0,2}$/.test(noLeadingZeros)
                          ) {
                            if (
                              noLeadingZeros === '.' ||
                              noLeadingZeros === ''
                            ) {
                              formik.setFieldValue(
                                'ventilateSoundSpeed',
                                noLeadingZeros
                              );
                            } else {
                              const num = Number(noLeadingZeros);
                              if (!isNaN(num)) {
                                // Convert to number to ensure consistent comparison for formik dirty state
                                const finalValue = /\.$/.test(noLeadingZeros)
                                  ? noLeadingZeros // Keep trailing decimal point during typing
                                  : Number(num); // Store as number for completed values

                                formik.setFieldValue(
                                  'ventilateSoundSpeed',
                                  finalValue
                                );
                              } else {
                                formik.setFieldValue('ventilateSoundSpeed', 0);
                                formik.setFieldError(
                                  'ventilateSoundSpeed',
                                  'Please enter a valid number'
                                );
                              }
                            }
                          }
                        }}
                        disabled={isEditMode ? false : true}
                      />
                      {formik.errors.ventilateSoundSpeed !== '' && (
                        <div
                          className="errorText"
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'flex-end',
                            marginBottom: '5px',
                            fontSize: '16px',
                          }}
                        >
                          {formik.errors.ventilateSoundSpeed}
                        </div>
                      )}
                      <div className="input-border"></div>
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '10px',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flex: 1,
                        flexDirection: 'column',
                      }}
                    >
                      <label
                        htmlFor="ventaliteSoundDelay"
                        className={`notification-css-title`}
                      >
                        15:2 & 30:2 Verbal Ventilations
                      </label>
                      <span
                        className="contentText greyText"
                        style={{
                          fontSize: '13px',
                          marginLeft: '0px',
                          paddingBottom: '8px',
                        }}
                      >
                        This is the amount of times to verbalize the 'Ventilate'
                        command in 15:2 and 30:2 mode.
                      </span>
                      <GeneralSelection<string>
                        disabled={!isEditMode}
                        items={['1', '2', '3']}
                        selectedIndex={Number(formik.values.ventilateLoops) - 1}
                        onPress={(item, index) => {
                          formik.setFieldValue('ventilateLoops', index + 1);
                        }}
                        labelField={(option) => option}
                        valueField={(option) => option}
                        padding="compact"
                      />
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flex: 1,
                        flexDirection: 'column',
                      }}
                    >
                      <label
                        htmlFor="ventaliteSoundDelay"
                        className={`notification-css-title`}
                      >
                        Continous Verbal Ventilations
                      </label>
                      <span
                        className="contentText greyText"
                        style={{
                          fontSize: '13px',
                          marginLeft: '0px',
                          paddingBottom: '8px',
                        }}
                      >
                        This is the amount of times to verbalize the 'Ventilate'
                        command in continuous mode.
                      </span>
                      <GeneralSelection<string>
                        disabled={!isEditMode}
                        items={['1', '2', '3']}
                        selectedIndex={
                          Number(formik.values.continousVentilateLoops) - 1
                        }
                        onPress={(item, index) => {
                          formik.setFieldValue(
                            'continousVentilateLoops',
                            index + 1
                          );
                        }}
                        labelField={(option) => option}
                        valueField={(option) => option}
                        padding="compact"
                      />
                    </div>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          </Col>
          <Col sm={6}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
                padding: '10px',
                marginTop: '.25rem',
                marginBottom: '.25rem',
              }}
            >
              <AccordionItem
                title="Epinephrine Doses"
                titleStyle={{ fontWeight: 'bold' }}
                // addDoseBtn={isEditMode}
                // onAddDoseClick={(event) => {
                //   event.stopPropagation();
                //   onAddDoseClick('Medication');
                // }}
                // addDoseBtnText="Add Dose"
                rightTitle={`${epiDoses ? epiDoses.length : 0}`}
                rightTitleStyle={{ fontWeight: '700' }}
              >
                {epiDoses && (
                  <div style={{ flex: 1, padding: '0 10px 10px 10px' }}>
                    <DragDropContext
                      onDragEnd={(result: DropResult) =>
                        onDragEnd(result, 'Medication')
                      }
                    >
                      <Droppable droppableId="droppable">
                        {(provided: any) => (
                          <ul
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            className="listItem"
                          >
                            <ListRenderMedication data={epiDoses} />
                            {provided.placeholder}
                          </ul>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </div>
                )}
              </AccordionItem>
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
                padding: '10px',
                marginTop: '.25rem',
                marginBottom: '.25rem',
              }}
            >
              <AccordionItem
                title="Defibrillation Shocks"
                titleStyle={{ fontWeight: 'bold' }}
                // addDoseBtn={isEditMode}
                // onAddDoseClick={(event: any) => {
                //   event.stopPropagation();
                //   onAddDoseClick('Electrical');
                // }}
                // addDoseBtnText="Add Shock"
                rightTitle={`${defibShocks ? defibShocks.length : 0}`}
                rightTitleStyle={{ fontWeight: '700' }}
              >
                {defibShocks && (
                  <div style={{ flex: 1, padding: '0 10px 10px 10px' }}>
                    <DragDropContext
                      onDragEnd={(result: DropResult) =>
                        onDragEnd(result, 'Electrical')
                      }
                    >
                      <Droppable droppableId="droppable">
                        {(provided: any) => (
                          <ul
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            className="listItem"
                          >
                            <ListRenderElectrical data={defibShocks} />
                            {provided.placeholder}
                          </ul>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </div>
                )}
              </AccordionItem>
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
                padding: '10px',
                marginTop: '.25rem',
                marginBottom: '.25rem',
              }}
            >
              <AccordionItem
                title="Airway Equipment"
                titleStyle={{ fontWeight: 'bold' }}
                // addDoseBtn={isEditMode}
                // onAddDoseClick={(event: any) => {
                //   event.stopPropagation();
                //   onAddDoseClick('Equipment');
                // }}
                // addDoseBtnText="Add Dose"
                rightTitle={`${selectedAirway ? 1 : 0}`}
                rightTitleStyle={{ fontWeight: '700' }}
              >
                {selectedAirway && (
                  <div style={{ flex: 1, padding: '0 10px 10px 10px' }}>
                    <ListRenderEquipment data={selectedAirway} />
                  </div>
                )}
              </AccordionItem>
            </div>
          </Col>
        </Row>
      )}

      {loading && <Loading type="bubbles" message={loading} />}
    </div>
  );
};

export default CPRAssistPage;
