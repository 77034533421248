import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Io5 from 'react-icons/io5';
import ProtocolHeader from '../protocol/ProtocolHeader';
import { FaChevronDown } from 'react-icons/fa6';
import {
  findDepartmentOwner,
  getArchivedDosesToDisplay,
  getFormattedDate,
  globals,
  upgradeVersion,
} from '../../_global/common/Utils';
import { ViewportList } from 'react-viewport-list';
import ConfirmModal from '../../components/Modal/ConfirmModal';
import './Settings.scss';
import { Col, Row } from 'react-bootstrap';
import { InputText } from 'primereact/inputtext';
import { CPRAssist, SoftwareType, User, UserType } from '../../../models';
import { ProgressStatus } from '../../../models';
import Status from '../../components/ProgressStatus/ProgressStatus';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Snackbar,
  Alert,
} from '@mui/material';
import EquipmentItem from '../../../data/model/EquipmentItem';
import MedicationSubItem from '../../../data/model/MedicationSubItem';
import ElectricalSubItem from '../../../data/model/ElectricalSubItem';
import AccordionItem from '../../components/Accordion/AccordionItem';
import MedicationDoseUI from '../protocol/edit/Medication/MedicationDoseUI';
import ElectricalDoseUI from '../protocol/edit/Electrical/ElectricalDoseUI';
import { FaCheckCircle } from 'react-icons/fa';
import CPRItem from '../../../data/model/CPRItem';
import { ResponseType } from '../../../data/AmplifyDB';
import {
  cprDB,
  createCPRAssist,
  deleteCPRItem,
} from '../../../data/functions/CprDB';
import { ArchiveItem, reloadModelItem } from '../../../data/functions/ModelDB';
import ArchivedDosesSideout from '../../components/SideOut/doseSideOut/ArchivedDosesSideout';
import CopyToClipboard from '../../components/CopyToClipboard';
import GeneralSelection from '../../components/Selection/GeneralSelection';

const CPRAssistArchivePage = (props: any) => {
  const navigate = useNavigate();

  const reducerState = useSelector((state: any) => state.department);
  const location = useLocation();

  if (
    !location.state?.value ||
    !location.state?.department ||
    !location.state?.database
  ) {
    navigate('/archive');
  }

  const {
    value: parmCPRAssist,
    sourceData,
    subData,
    department,
    database,
  } = location.state;

  const [activeParmCPRAssist, setActiveParmCPRAssist] = useState<CPRItem>(
    useMemo(() => {
      return sourceData[0].activeItem;
    }, [sourceData])
  );

  const user: User = useSelector((state: any) => state?.user);

  const [selectedAirway, setSelectedAirway] = useState<
    EquipmentItem | undefined
  >();

  const [snackbar, setSnackbar] = useState<any>({
    open: false,
    message: '',
    severity: 'success',
  });

  const [modal, setModal] = useState({
    isVisible: false,
    title: '',
    primaryDescription: '',
    primaryBtnName: '',
    secondaryBtnName: '',
    handleClose: () => {},
    handleSubmit: () => {},
  });

  const reloadItem = async () => {
    const response = await reloadModelItem(CPRAssist, activeParmCPRAssist);
    if (response.type === ResponseType.Success) {
      const item = new CPRItem(response.data.item as CPRAssist);
      const activeItem = response.data.activeItem as
        | CPRAssist
        | null
        | undefined;
      item.activeItem = activeItem ? new CPRItem(activeItem) : null;
      setActiveParmCPRAssist(item);
    }
  };

  /**
   * Check if the draft exists for the active item
   */
  useEffect(() => {
    reloadItem();
  }, []);

  const departmentOwner = useMemo(() => {
    return parmCPRAssist
      ? findDepartmentOwner(department, reducerState, parmCPRAssist)
      : null;
  }, [department, parmCPRAssist, reducerState]);

  const modifiedBy = useMemo(() => {
    return parmCPRAssist
      ? database.users.find(
          (user: User) => user.id === parmCPRAssist.modifiedBy?.id
        )
      : undefined;
  }, [database, parmCPRAssist]);

  const [epiDoses, setEpiDoses] = useState<MedicationSubItem[]>(
    parmCPRAssist?.epiDoses || []
  );

  const [defibShocks, setDefibShocks] = useState<ElectricalSubItem[]>(
    parmCPRAssist?.defibShocks || []
  );

  // Check if the most recent version of the medication is a draft
  const isDraft = useMemo(
    () => activeParmCPRAssist.status === ProgressStatus.DRAFT,
    [activeParmCPRAssist]
  );

  useEffect(() => {
    if (department.softwarePlan !== SoftwareType.PREMIUM) {
      navigate('/protocol');
    }
  }, [department, navigate]);

  useEffect(() => {
    setDefibShocks(parmCPRAssist?.defibShocks || []);
    setEpiDoses(parmCPRAssist?.epiDoses || []);
  }, [parmCPRAssist?.defibShocks, parmCPRAssist?.epiDoses]);

  useEffect(() => {
    if (parmCPRAssist?.model?.equipmentID) {
      const deviceID = parmCPRAssist.model.equipmentID;
      const selectedAirwayFromDB = database.equipment.find(
        (item: EquipmentItem) =>
          item.uid === deviceID || item.activeID === deviceID
      );

      setSelectedAirway(selectedAirwayFromDB);
    }
  }, [parmCPRAssist?.model?.equipmentID, database.equipment]);

  const ListRenderMedication = ({ data }: any) => {
    let list = data.filter(
      (item: MedicationSubItem) => item.status !== ProgressStatus.DEACTIVATED
    );

    const [showArchiveSideout, setShowArchiveSideout] = useState(false);
    const [selectedDose, setSelectedDose] = useState<MedicationSubItem | null>(
      null
    );
    const [selectedArchives, setSelectedArchives] = useState<
      MedicationSubItem[]
    >([]);

    // Process all data once at the beginning
    const { displayItems, archivedItemsMap } = useMemo(() => {
      return getArchivedDosesToDisplay(list);
    }, [list]);

    const handleViewHistory = (
      item: MedicationSubItem,
      e: React.MouseEvent
    ) => {
      e.stopPropagation(); // Prevent event bubbling if inside clickable containers
      const archivedItems =
        archivedItemsMap.get(item.uid) ||
        archivedItemsMap.get(item.activeID || '') ||
        [];

      setSelectedDose(item);
      setSelectedArchives(archivedItems as MedicationSubItem[]);
      setShowArchiveSideout(true);
    };

    return (
      <>
        <ViewportList items={displayItems as MedicationSubItem[]}>
          {(dose: MedicationSubItem, index: number) => {
            const archivedItems =
              dose.status === ProgressStatus.ARCHIVE
                ? archivedItemsMap.get(dose.activeID || '')
                : archivedItemsMap.get(dose.uid);

            const archivedCount = archivedItems?.length || 0;

            return (
              <div
                key={dose.uid}
                style={{ cursor: 'pointer' }}
                onClick={() => {}}
              >
                <MedicationDoseUI
                  dose={dose}
                  doseIndex={dose.version}
                  showProtocol={false}
                  isEditMode={false}
                  archiveMode={true}
                />
                {archivedCount > 0 && (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      margin: 0,
                      marginTop: '0px',
                      cursor: 'pointer',
                    }}
                    onClick={(e) => handleViewHistory(dose, e)}
                  >
                    <p className="sidebarText-hover">
                      {archivedCount} archived
                      {archivedCount === 1 ? ' dose >' : ' doses >'}
                    </p>
                  </div>
                )}
              </div>
            );
          }}
        </ViewportList>

        {selectedDose && (
          <ArchivedDosesSideout
            visible={showArchiveSideout}
            currentDose={selectedDose}
            archivedDoses={selectedArchives}
            type={'medication'}
            setVisible={setShowArchiveSideout}
          />
        )}
      </>
    );
  };

  const ListRenderElectrical = ({ data }: any) => {
    const list = data.filter(
      (item: ElectricalSubItem) => item.status !== ProgressStatus.DEACTIVATED
    );

    const [showArchiveSideout, setShowArchiveSideout] = useState(false);
    const [selectedDose, setSelectedDose] = useState<ElectricalSubItem | null>(
      null
    );
    const [selectedArchives, setSelectedArchives] = useState<
      ElectricalSubItem[]
    >([]);

    // Process all data once at the beginning
    const { displayItems, archivedItemsMap } = useMemo(() => {
      return getArchivedDosesToDisplay(list);
    }, [list]);

    const handleViewHistory = (
      item: ElectricalSubItem,
      e: React.MouseEvent
    ) => {
      e.stopPropagation(); // Prevent event bubbling if inside clickable containers
      const archivedItems =
        archivedItemsMap.get(item.uid) ||
        archivedItemsMap.get(item.activeID || '') ||
        [];

      setSelectedDose(item);
      setSelectedArchives(archivedItems as ElectricalSubItem[]);
      setShowArchiveSideout(true);
    };

    return (
      <>
        <ViewportList items={displayItems as ElectricalSubItem[]}>
          {(dose: ElectricalSubItem, index: number) => {
            const archivedItems =
              dose.status === ProgressStatus.ARCHIVE
                ? archivedItemsMap.get(dose.activeID || '')
                : archivedItemsMap.get(dose.uid);

            const archivedCount = archivedItems?.length || 0;

            return (
              <div
                key={dose.uid}
                style={{ cursor: 'pointer' }}
                onClick={() => {}}
              >
                <ElectricalDoseUI
                  dose={dose}
                  doseIndex={dose.version}
                  showProtocol={false}
                  isEditMode={false}
                  archiveMode={true}
                />
                {archivedCount > 0 && (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      margin: 0,
                      marginTop: '0px',
                      cursor: 'pointer',
                    }}
                    onClick={(e) => handleViewHistory(dose, e)}
                  >
                    <p className="sidebarText-hover">
                      {archivedCount} archived
                      {archivedCount === 1 ? ' shock >' : ' shocks >'}
                    </p>
                  </div>
                )}
              </div>
            );
          }}
        </ViewportList>

        {selectedDose && (
          <ArchivedDosesSideout
            visible={showArchiveSideout}
            currentDose={selectedDose}
            archivedDoses={selectedArchives}
            type={'electrical'}
            setVisible={setShowArchiveSideout}
          />
        )}
      </>
    );
  };

  const ListRenderEquipment = ({ data }: any) => {
    return (
      <ul className="listItem">
        <div
          className={`listContainer }`}
          style={{
            borderRadius: '6px',
          }}
        >
          <li
            onClick={() => {}}
            className="listContent"
            style={{
              fontSize: '1rem',
            }}
          >
            <div className="a1SubITems">
              <div
                style={{
                  display: 'flex',
                  flex: 1,
                }}
              >
                <div className={'a1Content'} style={{}}>
                  <span>{data.name}</span>
                  <span>
                    {data.status !== 'ACTIVE' && (
                      <Status status={data.status} />
                    )}
                  </span>
                </div>
              </div>
              <div
                className="a1SubItemsContent"
                style={{ justifyContent: 'flex-end' }}
              >
                <div className="a1type">
                  {data.options?.length} Sizes
                  <span>
                    <Io5.IoChevronForward
                      size="1.5rem"
                      style={{ marginLeft: '10px' }}
                    />
                  </span>
                </div>
              </div>
            </div>
          </li>
        </div>
      </ul>
    );
  };

  /**
   * Back button has been pressed
   */
  const handleBack = useCallback(() => {
    navigate('/archive/list-cpr-assist', {
      state: {
        data: sourceData,
        subData: subData,
        department: department,
        database: database,
      },
    });
  }, [navigate, sourceData, department, database, subData]);

  const handleRestore = useCallback(async () => {
    setModal({ ...modal, isVisible: false });
    try {
      const oldActiveItem = isDraft
        ? activeParmCPRAssist.activeItem
        : activeParmCPRAssist;

      if (isDraft) {
        const response = await deleteCPRItem(activeParmCPRAssist, user, false);
        if (response.type === ResponseType.Failure) {
          setSnackbar({
            open: true,
            message:
              'Failed to delete already existing draft of ' +
              oldActiveItem?.name,
            severity: 'error',
          });
          // Exit the function
          console.error('Failed to delete medication', response.data);
          return;
        }
      }

      const createData: cprDB = {
        departmentID: department.id,
        activeID: oldActiveItem?.uid,
        version: upgradeVersion(oldActiveItem?.version || 'v1.0.0'),
        status: ProgressStatus.DRAFT,
        createdBy:
          parmCPRAssist.model && parmCPRAssist.model.createdBy
            ? parmCPRAssist.model.createdBy
            : user.id,
        modifiedBy: user.id,
        defaultMode: parmCPRAssist.defaultMode,
        epiOrangeSec: parmCPRAssist.epiOrangeSeconds,
        epiRedSec: parmCPRAssist.epiRedSeconds,
        compressionRate: parmCPRAssist.compressionRate,
        ventRatePerMin: parmCPRAssist.ventRatePerMin,
        ventilateSoundDelay: parmCPRAssist.ventilateSoundDelay,
        equipmentID: parmCPRAssist.model.equipmentID,
        ventilateLoops: parmCPRAssist.ventilateLoops,
        continousVentilateLoops: parmCPRAssist.continousVentilateLoops,
        ventilateSoundSpeed: parmCPRAssist.ventilateSoundSpeed,
        pulseCheckTime: parmCPRAssist.pulseCheckTime,
        chargingTime: parmCPRAssist.chargingTime,
      };

      let results = await createCPRAssist(createData, oldActiveItem as CPRItem);

      if (results.type === ResponseType.Success) {
        const newDraftMedication = results.data as CPRItem;
        newDraftMedication.activeItem = oldActiveItem;
        setSnackbar({
          open: true,
          message:
            'Successfully Restored CPR Assist with a new DRAFT version ' +
            newDraftMedication.version,
          severity: 'success',
        });
        // Update sourceData with new draft
        const newSourceData = sourceData.map((item: ArchiveItem) =>
          item.activeItem?.uid === activeParmCPRAssist.uid
            ? { ...item, activeItem: newDraftMedication }
            : item
        );
        if (globals.debug)
          console.log('Successfully restored medication', results.data);
        // Handle successful restoration
        // Delay navigation to allow snackbar to be shown
        setModal({
          isVisible: true,
          title: 'Successfully Restored CPR Assist',
          primaryDescription: `Successfully restored CPR Assist to version ${newDraftMedication.version}. Would you like to navigate to the CPR Assist?`,
          primaryBtnName: 'Dismiss',
          secondaryBtnName: 'Navigate',
          handleClose: () => {
            setModal({ ...modal, isVisible: false });
            navigate('/archive/list-cpr-assist', {
              state: {
                data: newSourceData,
                subData: subData,
                department: department,
                database: database,
              },
            });
          },
          handleSubmit: () => {
            setModal({ ...modal, isVisible: false });
            navigate('/database/cpr-assist');
          },
        });
      } else if (results.type === ResponseType.Failure) {
        console.error('Failed to restore medication', results.data);
        setSnackbar({
          open: true,
          message: 'Failed to restore CPR Assist.',
          severity: 'error',
        });
      }
    } catch (error) {
      console.error('Failed to restore medication', error);
    }
  }, [
    activeParmCPRAssist,
    isDraft,
    sourceData,
    subData,
    department,
    database,
    navigate,
    parmCPRAssist,
    modal,
    user,
  ]);

  const handleRestorePressed = async () => {
    if (isDraft) {
      setModal({
        isVisible: true,
        title: 'Override Existing Draft?',
        primaryDescription: `There is already a draft of CPR Assist (${activeParmCPRAssist.version}) which is not yet published. Are you sure you want to override the existing draft and restore CPR Assist of version ${parmCPRAssist.version}?`,
        primaryBtnName: 'Cancel',
        secondaryBtnName: 'Restore',
        handleClose: () => {
          setModal({ ...modal, isVisible: false });
        },
        handleSubmit: handleRestore,
      });
    } else {
      setModal({
        isVisible: true,
        title: 'Restore CPR Assist?',
        primaryDescription: `Are you sure you want to restore CPR Assist of version ${parmCPRAssist.version}? This will create a new draft version of the CPR Assist.`,
        primaryBtnName: 'Cancel',
        secondaryBtnName: 'Restore',
        handleClose: () => {
          setModal({ ...modal, isVisible: false });
        },
        handleSubmit: handleRestore,
      });
    }
  };

  return (
    <div
      className="screen-container"
      style={{
        height: '100%',
        padding: '0 0rem',
      }}
    >
      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>

      <ConfirmModal
        isVisible={modal.isVisible}
        title={modal.title}
        primaryDescription={modal.primaryDescription}
        primaryBtnName={modal.primaryBtnName}
        secondaryBtnName={modal.secondaryBtnName}
        handleClose={modal.handleClose}
        handleSubmit={modal.handleSubmit}
      />

      <ProtocolHeader
        name={<>{'CPR Assist - Beta'}</>}
        status={parmCPRAssist?.status}
        bypassActiveStatusCheck
        description={`This is the CPR Assist with version ${parmCPRAssist?.version} for the OneDose application. NOTE: This is in Beta mode.`}
        isBackButton={true}
        page={'CPR Assist Archives'}
        handleCancel={handleBack}
        type={'protocol'}
        isRestoreButton={true}
        isRestoreActive={parmCPRAssist.status !== 'ACTIVE'}
        handleRestore={handleRestorePressed}
      />

      {parmCPRAssist && (
        <Row>
          <Col
            sm={6}
            style={{
              padding: '0 2rem',
            }}
          >
            {parmCPRAssist && (
              <>
                <label
                  htmlFor="s"
                  className="settings-general-label"
                  style={{
                    marginLeft: '0rem',
                    fontSize: '1.25rem',
                  }}
                >
                  CPR Assist Settings
                </label>
                <div
                  className="input-container roundBorder"
                  style={{
                    margin: '0px 0rem',
                  }}
                >
                  <Row>
                    <Col sm={6}>
                      <div
                        style={{ display: 'flex' }}
                        className="ketamine-general-label"
                      >
                        <div style={{ marginRight: '10px' }}>Version:</div>
                        <div style={{ fontWeight: '500' }}>
                          {parmCPRAssist.version}
                        </div>
                      </div>
                      {parmCPRAssist.model?.createdAt && (
                        <div
                          style={{ display: 'flex', marginTop: '5px' }}
                          className="settings-general-label"
                        >
                          <div style={{ marginRight: '10px' }}>
                            Modified At:
                          </div>
                          <div style={{ fontWeight: '500' }}>
                            {getFormattedDate(parmCPRAssist.model.createdAt)}
                          </div>
                        </div>
                      )}

                      <div
                        style={{ display: 'flex', marginTop: '5px' }}
                        className="settings-general-label"
                      >
                        <div style={{ marginRight: '10px' }}>Modified By:</div>
                        <div style={{ fontWeight: '500' }}>
                          {modifiedBy
                            ? modifiedBy.firstName + ' ' + modifiedBy.lastName
                            : 'Hinckley Medical'}
                        </div>
                      </div>
                    </Col>
                    <Col sm={6}>
                      {user.type === UserType.ADMIN && (
                        <div
                          style={{ display: 'flex' }}
                          className="ketamine-general-label"
                        >
                          <div style={{ marginRight: '10px' }}>ID:</div>
                          <div style={{ fontWeight: '500' }}>
                            {parmCPRAssist.uid}
                            <CopyToClipboard text={parmCPRAssist.uid} />
                          </div>
                        </div>
                      )}

                      {user.type === UserType.ADMIN &&
                        parmCPRAssist.activeID != null && (
                          <div
                            style={{ display: 'flex' }}
                            className="ketamine-general-label"
                          >
                            <div style={{ marginRight: '10px' }}>
                              Active ID:
                            </div>
                            <div style={{ fontWeight: '500' }}>
                              {parmCPRAssist.activeID}
                              <CopyToClipboard text={parmCPRAssist.activeID} />
                            </div>
                          </div>
                        )}
                      {user.type === UserType.ADMIN &&
                        parmCPRAssist.overrideItem != null && (
                          <div
                            style={{ display: 'flex' }}
                            className="ketamine-general-label"
                          >
                            <div style={{ marginRight: '10px' }}>
                              Override ID:
                            </div>
                            <div style={{ fontWeight: '500' }}>
                              {parmCPRAssist.overrideID}
                              <CopyToClipboard
                                text={parmCPRAssist.overrideID}
                              />
                            </div>
                          </div>
                        )}
                      {user.type === UserType.ADMIN && departmentOwner && (
                        <div
                          style={{ display: 'flex' }}
                          className="ketamine-general-label"
                        >
                          <div style={{ marginRight: '10px' }}>Owner:</div>
                          <div style={{ fontWeight: '500' }}>
                            {departmentOwner.name}
                            {departmentOwner.id === database.department.id && (
                              <span style={{ marginLeft: '5px' }}>
                                <FaCheckCircle color={'#00534C'} size="1rem" />
                              </span>
                            )}
                          </div>
                        </div>
                      )}
                    </Col>
                  </Row>
                </div>
              </>
            )}
            <label
              htmlFor="neonateCutoff"
              className="settings-general-label"
              style={{
                fontSize: '1.25rem',
                marginTop: '1rem',
                marginLeft: '0rem',
              }}
            >
              General
            </label>
            <label
              htmlFor="Name"
              className="settings-general-label"
              style={{
                marginTop: '1rem',
                marginLeft: '0px',
              }}
            >
              Epinephrine Settings
            </label>
            <p className="sidebarText" style={{ marginLeft: '0px' }}>
              These are the timing settings for when the epinephrine changes
              colors in the CPR Assist.
            </p>
            <Row>
              <Col sm={6}>
                <label
                  htmlFor="epiOrangeSec"
                  className="settings-general-label"
                >
                  Orange Color (Seconds)
                </label>
                <div className="input-container">
                  <InputText
                    type="text"
                    className="form-control-general"
                    id="epiOrangeSec"
                    name="epiOrangeSec"
                    data-testid="epiOrangeSec"
                    required={true}
                    value={parmCPRAssist.epiOrangeSeconds + ''}
                    onChange={(e: any) => {}}
                    disabled={true}
                  />
                </div>
              </Col>
              <Col sm={6}>
                <label htmlFor="epiRedSec" className="settings-general-label">
                  Red Color (Seconds)
                </label>
                <div className="input-container">
                  <InputText
                    type="text"
                    className="form-control-general"
                    id="epiRedSec"
                    name="epiRedSec"
                    data-testid="epiRedSec"
                    required={true}
                    value={parmCPRAssist.epiRedSeconds + ''}
                    onChange={(e: any) => {}}
                    disabled={true}
                  />
                </div>
              </Col>
            </Row>

            <label
              htmlFor="Name"
              className="settings-general-label"
              style={{
                marginTop: '1rem',
                marginLeft: '0px',
              }}
            >
              CPR Settings
            </label>
            <p className="sidebarText" style={{ marginLeft: '0px' }}>
              These are the settings for the CPR Assist for the metronome.
            </p>
            <Row>
              <Col sm={6}>
                <label
                  htmlFor="epiOrangeSec"
                  className="settings-general-label"
                >
                  Compression Rate (BPM)
                </label>
                <div className="input-container">
                  <InputText
                    type="text"
                    className="form-control-general"
                    id="compressionRate"
                    name="compressionRate"
                    data-testid="compressionRate"
                    required={true}
                    value={parmCPRAssist.compressionRate + ''}
                    placeholder="100-120..."
                    onChange={(e: any) => {}}
                    disabled={true}
                  />
                </div>
              </Col>
              <Col sm={6}>
                <label
                  htmlFor="ventratePerMin"
                  className="settings-general-label"
                >
                  Async Ventilation Rate (BPM)
                </label>
                <div className="input-container">
                  <InputText
                    type="text"
                    className="form-control-general"
                    id="ventratePerMin"
                    name="ventratePerMin"
                    data-testid="ventratePerMin"
                    required={true}
                    value={parmCPRAssist.ventRatePerMin + ''}
                    onChange={(e: any) => {}}
                    disabled={true}
                  />
                </div>
              </Col>
            </Row>
            <Accordion
              style={{
                borderRadius: '5px',
                boxShadow: 'none',
                alignContent: 'center',
                justifyContent: 'center',

                fontSize: '16px',
                marginTop: '20px',
                backgroundColor: 'transparent',
                border: '1px solid #ccc',
              }}
              onChange={(e, expanded) => {}}
            >
              <AccordionSummary
                expandIcon={<FaChevronDown />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                Advanced Settings
              </AccordionSummary>
              <AccordionDetails>
                <label
                  htmlFor="defaultMode"
                  className={`notification-css-title`}
                >
                  Default Mode
                </label>
                <span
                  className="contentText greyText"
                  style={{ fontSize: '13px', marginLeft: '6px' }}
                >
                  This is the CPR compression-to-ventilation ratio.
                </span>
                <div className="input-container">
                  <InputText
                    type="text"
                    className="form-control-general"
                    id="defaultMode"
                    name="defaultMode"
                    data-testid="defaultMode"
                    required={true}
                    value={parmCPRAssist.defaultMode + ''}
                    placeholder="Default Mode..."
                    onChange={(e: any) => {}}
                    disabled={true}
                  />
                  <div className="input-border"></div>
                </div>
              </AccordionDetails>

              <AccordionDetails>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '10px',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flex: 1,
                      flexDirection: 'column',
                    }}
                  >
                    <label
                      htmlFor="ventaliteSoundDelay"
                      className={`notification-css-title`}
                    >
                      Ventilate Sound Speed
                    </label>
                    <span
                      className="contentText greyText"
                      style={{ fontSize: '13px', marginLeft: '0px' }}
                    >
                      This is the scalar for fine tuning the speed of the
                      'Ventilate' command sound.
                    </span>
                    <div className="input-container">
                      <InputText
                        type="text"
                        className="form-control-general"
                        id="ventilateSoundSpeed"
                        name="ventilateSoundSpeed"
                        data-testid="ventilateSoundSpeed"
                        value={parmCPRAssist.ventilateSoundSpeed + ''}
                        placeholder="Ventilate Sound Speed..."
                        disabled={true}
                      />
                      <div className="input-border"></div>
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '10px',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flex: 1,
                        flexDirection: 'column',
                      }}
                    >
                      <label
                        htmlFor="ventaliteSoundDelay"
                        className={`notification-css-title`}
                      >
                        15:2 & 30:2 Verbal Ventilations
                      </label>
                      <span
                        className="contentText greyText"
                        style={{
                          fontSize: '13px',
                          marginLeft: '0px',
                          paddingBottom: '8px',
                        }}
                      >
                        This is the amount of times to verbalize the 'Ventilate'
                        command in 15:2 and 30:2 mode.
                      </span>
                      <GeneralSelection<string>
                        disabled={true}
                        items={['1', '2', '3']}
                        selectedIndex={Number(parmCPRAssist.ventilateLoops) - 1}
                        onPress={(item, index) => {}}
                        labelField={(option) => option}
                        valueField={(option) => option}
                        padding="compact"
                      />
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flex: 1,
                        flexDirection: 'column',
                      }}
                    >
                      <label
                        htmlFor="ventaliteSoundDelay"
                        className={`notification-css-title`}
                      >
                        Continous Verbal Ventilations
                      </label>
                      <span
                        className="contentText greyText"
                        style={{
                          fontSize: '13px',
                          marginLeft: '0px',
                          paddingBottom: '8px',
                        }}
                      >
                        This is the amount of times to verbalize the 'Ventilate'
                        command in continuous mode.
                      </span>
                      <GeneralSelection<string>
                        disabled={true}
                        items={['1', '2', '3']}
                        selectedIndex={
                          Number(parmCPRAssist.continousVentilateLoops) - 1
                        }
                        onPress={(item, index) => {}}
                        labelField={(option) => option}
                        valueField={(option) => option}
                        padding="compact"
                      />
                    </div>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          </Col>
          <Col sm={6}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
                padding: '10px',
                marginTop: '.25rem',
                marginBottom: '.25rem',
              }}
            >
              <AccordionItem
                title="Epinephrine Doses"
                titleStyle={{ fontWeight: 'bold' }}
                rightTitle={`${epiDoses ? epiDoses.length : 0}`}
                rightTitleStyle={{ fontWeight: '700' }}
              >
                {epiDoses && (
                  <div style={{ flex: 1, padding: '0 10px 10px 10px' }}>
                    <ListRenderMedication data={epiDoses} />
                  </div>
                )}
              </AccordionItem>
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
                padding: '10px',
                marginTop: '.25rem',
                marginBottom: '.25rem',
              }}
            >
              <AccordionItem
                title="Defibrillation Shocks"
                titleStyle={{ fontWeight: 'bold' }}
                rightTitle={`${defibShocks ? defibShocks.length : 0}`}
                rightTitleStyle={{ fontWeight: '700' }}
              >
                {defibShocks && (
                  <div style={{ flex: 1, padding: '0 10px 10px 10px' }}>
                    <ListRenderElectrical data={defibShocks} />
                  </div>
                )}
              </AccordionItem>
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
                padding: '10px',
                marginTop: '.25rem',
                marginBottom: '.25rem',
              }}
            >
              <AccordionItem
                title="Airway Equipment"
                titleStyle={{ fontWeight: 'bold' }}
                rightTitle={`${selectedAirway ? 1 : 0}`}
                rightTitleStyle={{ fontWeight: '700' }}
              >
                {selectedAirway && (
                  <div style={{ flex: 1, padding: '0 10px 10px 10px' }}>
                    <ListRenderEquipment data={selectedAirway} />
                  </div>
                )}
              </AccordionItem>
            </div>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default CPRAssistArchivePage;
