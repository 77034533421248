import React, { useMemo, useRef, useState } from 'react';
import ModelItem from '../../../../data/model/ModelInterface';
import DraftChangeItem from '../../../../data/model/DraftChangeItem';
import MedicationSubItem from '../../../../data/model/MedicationSubItem';
import {
  globals,
  jsonToTitleCase,
  toTitleCase,
} from '../../../_global/common/Utils';
import MedicationDoseComparisonView from '../comparisonView/MedicationDoseComparisonView';
import DepartmentItem from '../../../../data/model/DepartmentItem';
import ComparisonView from '../comparisonView/ComparisonView';
import MedicationDoseSideout from '../../../components/SideOut/doseSideOut/MedicationDoseSideout';
import ReviewalItem from '../../../../data/model/ReviewalItem';
import { User } from '../../../../models';

interface FolderComparisonProps {
  currentDraft: DraftChangeItem;
  changeItem?: ModelItem | null;
  previousItem?: ModelItem | null;
  onChangesCount?: (count: number) => void; // Add the callback prop
  department: DepartmentItem;
  reviewal: ReviewalItem;
  user: User;
}

const UI_STYLE_2 = false;

const MEDICATION_DOSE_DATA = [
  {
    keys: ['rangeLow', 'rangeHigh'],
    titles: ['Weight Low (kg)', 'Weight High (kg)'],
  },
  {
    keys: ['ageLow', 'ageHigh'],
    titles: ['Age Low (Months)', 'Age High (Months)'],
  },
  {
    keys: ['fullBasis', 'routes'],
    titles: ['Calculation Basis', 'Routes'],
  },
  {
    keys: ['fullMinDose', 'fullMaxDose', 'fullMaxTotalDose'],
    titles: ['Min Dose', 'Max Dose', 'Max Total Dose'],
  },
  {
    keys: ['title'],
    titles: ['Title'],
  },
  {
    keys: ['warning'],
    titles: ['Warning'],
  },
  {
    keys: ['instruction'],
    titles: ['Instruction'],
  },
  {
    keys: ['note'],
    titles: ['Note'],
  },
];

interface Change {
  key: string;
  currentValue: string;
  archiveValue: string;
}
export const compareMedicationSubItems = (
  currentItem: MedicationSubItem,
  archiveItem: MedicationSubItem,
  countMedicationDose: number
): { changes: Change[]; count: number } => {
  const changes: Change[] = [];

  // Define the specific fields to check
  const fieldsToCompare: (keyof MedicationSubItem)[] = [
    'rangeLow',
    'rangeHigh',
    'routes',
    'nemsisRoutes',
    'title',
    'warning',
    'note',
    'instruction',
    'ageLow',
    'ageHigh',
    'fullBasis',
    'fullMaxDose',
    'fullMaxTotalDose',
    'fullMinDose',
    'index',
  ];

  // Function to compare values
  const compareValues = (value1: any, value2: any): boolean => {
    if (Array.isArray(value1) && Array.isArray(value2)) {
      return JSON.stringify(value1) !== JSON.stringify(value2);
    }

    return value1 !== value2;
  };

  fieldsToCompare.forEach((key) => {
    let currentValue = currentItem[key];
    let archiveValue = archiveItem ? archiveItem[key] : '';

    if (
      key === 'rangeHigh' ||
      key === 'fullMaxDose' ||
      key === 'fullMaxTotalDose'
    ) {
      currentValue =
        currentValue === globals.MAX_VALUE || currentValue === ''
          ? 'Max'
          : (currentValue as string) || '';
      archiveValue =
        archiveItem &&
        (archiveValue === globals.MAX_VALUE || archiveValue === '')
          ? 'Max'
          : (archiveValue as string) || '';
    }

    if (key === 'ageLow' || key === 'ageHigh') {
      currentValue = currentValue
        ? currentValue.age + ' ' + currentValue.units
        : '';
      archiveValue =
        archiveItem && archiveValue
          ? archiveValue.age + ' ' + archiveValue.units
          : '';
    }

    if (key === 'rangeLow' || key === 'fullMinDose') {
      currentValue =
        currentValue === 0 || currentValue === ''
          ? 'Min'
          : (currentValue as string) || '';
      archiveValue =
        archiveItem && (archiveValue === 0 || archiveValue === '')
          ? 'Min'
          : (archiveValue as string) || '';
    }

    if (key === 'routes' || key === 'nemsisRoutes') {
      currentValue = (currentValue as string[])?.join(', ') || '';
      archiveValue =
        (archiveItem && (archiveValue as string[])?.join(', ')) || '';
    }

    if (compareValues(currentValue, archiveValue)) {
      countMedicationDose += 1;
    }
    if (key !== 'index' || currentValue !== archiveValue) {
      changes.push({
        key,
        currentValue: String(currentValue),
        archiveValue: String(archiveValue),
      });
    }
  });

  return { changes, count: countMedicationDose };
};

const MedicationDoseComparison: React.FC<FolderComparisonProps> = ({
  currentDraft,
  changeItem,
  previousItem,
  onChangesCount,
  department,
  reviewal,
  user,
}) => {
  const countMedicationDose = 0;
  const [selectedDose, setSelectedDose] = useState<MedicationSubItem | null>(
    null
  );
  const { changes, count } = useMemo(() => {
    const { changes: comparedChanges, count: changeCount } =
      compareMedicationSubItems(
        changeItem as MedicationSubItem,
        previousItem as MedicationSubItem,
        countMedicationDose
      );
    if (onChangesCount) {
      onChangesCount(changeCount);
    }

    return { changes: comparedChanges, count: changeCount };
  }, [changeItem, previousItem, onChangesCount]);
  const [viewMode, setViewMode] = useState<'none' | 'before' | 'after'>('none');

  const handleViewClick = (mode: 'before' | 'after') => {
    let med = mode === 'before' && previousItem ? previousItem : changeItem;
    setSelectedDose(med as MedicationSubItem);
  };

  const isDraftRejected = useMemo(() => {
    // if (user.id === reviewal.user.id) {
    //   return currentDraft.acknowledgements.some(
    //     (ack) => ack.state === 'REJECTED'
    //   );
    // }
    return false;
  }, [currentDraft.acknowledgements, reviewal, user]);

  return (
    <div>
      {selectedDose != null && selectedDose.parent != null && (
        <MedicationDoseSideout
          viewOnlyMode={!isDraftRejected}
          visible={selectedDose != null}
          solidUnit=""
          doseIndex={selectedDose?.index}
          parentModel={selectedDose?.parent}
          protocol={selectedDose?.parentProtocol || null}
          allProtocols={[]}
          dose={selectedDose ? selectedDose : undefined}
          subMedicationItems={[]}
          setVisible={() => setSelectedDose(null)}
          onSubmit={() => {}}
          onRemove={() => {}}
          onSubmitMultiDose={() => {}}
        />
      )}
      <div className="doseChangesWrapper">
        <table className="changesTable">
          {previousItem ? (
            <>
              <thead>
                <tr>
                  <th className="changeHeader beforeChange">
                    <div className="headerContent">
                      Before
                      <button
                        className="secondary-button-small-border btn-rightMargin btn btn-primary viewButton"
                        onClick={() => handleViewClick('before')}
                      >
                        View
                      </button>
                    </div>{' '}
                  </th>
                  <th className="changeHeader afterChange">
                    <div className="headerContent">
                      After
                      <button
                        className="secondary-button-small-border btn-rightMargin btn btn-primary viewButton"
                        onClick={() => handleViewClick('after')}
                      >
                        View
                      </button>
                    </div>
                  </th>
                </tr>
              </thead>
              {/* pass the changes to medicationFullView to display only the changes for before and after in tabular */}
              {UI_STYLE_2 ? (
                <ComparisonView
                  changes={changes}
                  data={MEDICATION_DOSE_DATA}
                  focusItem={changeItem as MedicationSubItem}
                />
              ) : (
                <tbody>
                  <tr>
                    <td>
                      <MedicationDoseComparisonView
                        focusItem={previousItem as MedicationSubItem}
                        isPrevious={true}
                        changes={changes}
                        department={department}
                      />
                    </td>
                    <td>
                      <MedicationDoseComparisonView
                        focusItem={changeItem as MedicationSubItem}
                        changes={changes}
                        department={department}
                      />
                    </td>
                  </tr>
                </tbody>
              )}
            </>
          ) : (
            <>
              <thead>
                <tr>
                  <th className="changeHeader afterChange">
                    <div className="headerContent">
                      New {toTitleCase(currentDraft.changeType)}
                      <button
                        className="secondary-button-small-border btn-rightMargin btn btn-primary viewButton"
                        onClick={() => handleViewClick('after')}
                      >
                        {isDraftRejected ? 'Edit' : 'View'}
                      </button>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <MedicationDoseComparisonView
                      focusItem={changeItem as MedicationSubItem}
                      changes={changes}
                      department={department}
                    />
                  </td>
                </tr>
              </tbody>
            </>
          )}
        </table>
      </div>
    </div>
  );
};

export default MedicationDoseComparison;
