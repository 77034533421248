import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Col, Row } from 'react-grid-system';
import {
  FaChevronLeft,
  FaChevronRight,
  FaArrowUp,
  FaArrowDown,
} from 'react-icons/fa6';
import { ViewportList } from 'react-viewport-list';
import Paper from '@mui/material/Paper';
import { IconButton, Tooltip } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Loading from '../../../components/Loading/Loading';
import SearchBar from '../../../components/Search/SearchBar';
import MultiSelectDropdown from '../../../components/MultiSelectDropdown/MultiSelectDropdown';
import Dropdown from '../../../components/Dropdown/Dropdown';
import HMCheckbox from '../../../components/general/HMCheckbox';
import debounce from 'lodash/debounce';
import '../scss/ListModel.scss';
import ReactLoading from 'react-loading';
import { useLocation } from 'react-router-dom';
import HMSwitch from '../../../components/general/HMSwitch';

export type Header<T> = {
  key: keyof T | 'input' | any;
  name: string;
  sortable: boolean;
  flex: number;
  style?: React.CSSProperties;
  children?: React.ReactNode;
  render?: (item: T) => React.ReactNode;
};

export type FilterOption = {
  label: string;
  value: any;
};

type DataListProps<T extends { id: string }, F> = {
  items: T[];
  headers: Header<T>[];
  pageSize?: number;
  mainInputValue?: boolean;
  selectedItems?: T[];
  filterOptions?: {
    key: keyof F;
    title: string;
    options: F[];
    labelField: (option: F) => string;
    keyField: (option: F) => string;
    filterFn: (item: T, selectedFilters: F[]) => boolean;
  }[];
  sortItem?: (key: string, direction: 'asc' | 'desc', a: T, b: T) => number;
  onItemClick?: (item: T) => void;
  onSelectionChange?: (selectedItems: T[]) => void;
  handleSelectAll?: (
    e: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
  getRowClassName?: (item: T) => string;
  searchFilter?: (item: T, searchQuery: string) => boolean;
  searchPlaceholder?: string;
  isLoading?: boolean;
  customTopComponent?: React.ReactNode;
  customFilterComponent?: React.ReactNode;
  spacing?: 'compact' | 'normal' | 'none' | 'large';
  maxHeight?: string;
  totalCount?: number;
  isLazyLoading?: boolean;
  config?: {
    mainInputType?: 'checkbox' | 'switch';
    highlightSelectedItems?: boolean;
    pagination?: boolean;
  };
};

const pageSizeOptions = [
  { label: '10', value: 10 },
  { label: '20', value: 20 },
  { label: '50', value: 50 },
  { label: '100', value: 100 },
];

type DataListState<T extends { id: string }, F> = {
  expiration?: number;
  pageSize?: number;
  page?: number;
  searchQuery?: string;
  debouncedSearchQuery?: string;
  sortDirection?: 'asc' | 'desc' | 'none';
  sortField?: keyof T | '';
  filters?: Record<string, F[]>;
  scrollPosition?: number;
};

function DataList<T extends { id: string }, F>({
  items,
  headers,
  pageSize: initialPageSize = 20,
  filterOptions,
  mainInputValue,
  selectedItems = [],
  totalCount,
  onItemClick,
  onSelectionChange,
  handleSelectAll,
  getRowClassName,
  searchFilter,
  searchPlaceholder = 'Search...',
  isLoading = false,
  customTopComponent,
  customFilterComponent,
  sortItem,
  spacing = 'normal',
  maxHeight = '62.5vh',
  isLazyLoading = false,
  config = {
    mainInputType: 'checkbox',
    highlightSelectedItems: false,
    pagination: false,
  },
}: DataListProps<T, F>) {
  const location = useLocation();

  const localState = sessionStorage.getItem('DataList-' + location.pathname);
  let state: DataListState<T, F> = localState ? JSON.parse(localState) : {};
  if (!state.expiration || state.expiration < new Date().getTime()) {
    state = {};
  }

  const [pageSize, setPageSize] = useState(state.pageSize || initialPageSize);
  const [page, setPage] = useState(state.page || 0);
  const [searchQuery, setSearchQuery] = useState(state.searchQuery || '');
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState(
    state.debouncedSearchQuery || ''
  );
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc' | 'none'>(
    state.sortDirection || 'none'
  );
  const [sortField, setSortField] = useState<keyof T | ''>(
    state.sortField || ''
  );
  const [filters, setFilters] = useState<Record<string, F[]>>(
    state.filters || {}
  );
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success' as 'success' | 'error',
  });

  const [isSelectAll, setIsSelectAll] = useState(mainInputValue || false);
  const listRef = useRef(null);

  // Add ref for measuring row height
  const rowRef = useRef<HTMLDivElement>(null);
  const [rowHeight, setRowHeight] = useState(60); // Default height

  // Add state for scroll position
  const [scrollPosition, setScrollPosition] = useState(
    state.scrollPosition || 0
  );

  // Measure actual row height on mount
  useEffect(() => {
    if (rowRef.current) {
      const height = rowRef.current.getBoundingClientRect().height;
      setRowHeight(height);
    }
  }, []);

  useEffect(() => {
    setIsSelectAll(mainInputValue || false);
  }, [mainInputValue]);

  // Add scroll handler
  const handleScroll = useCallback((e: Event) => {
    const target = e.target as HTMLDivElement;
    setScrollPosition(target.scrollTop);
  }, []);

  // Add effect to track scroll position
  useEffect(() => {
    const listContainer = listRef.current as HTMLDivElement | null;
    if (listContainer) {
      listContainer.addEventListener('scroll', handleScroll);
      return () => {
        listContainer.removeEventListener('scroll', handleScroll);
      };
    }
  }, [handleScroll]);

  // Create a debounced search function
  const debouncedSearch = useCallback(
    debounce((searchTerm: string) => {
      setDebouncedSearchQuery(searchTerm);
    }, 100),
    []
  );

  // Add useEffect to save state to session storage when state changes
  useEffect(() => {
    // set expiration to 10 minutes
    const expiration = new Date().getTime() + 10 * 60 * 1000;
    const currentState: DataListState<T, F> = {
      expiration,
      pageSize,
      page,
      searchQuery,
      debouncedSearchQuery,
      sortDirection,
      sortField,
      filters,
      scrollPosition,
    };

    sessionStorage.setItem(
      'DataList-' + location.pathname,
      JSON.stringify(currentState)
    );
  }, [
    location.pathname,
    pageSize,
    page,
    searchQuery,
    debouncedSearchQuery,
    sortDirection,
    sortField,
    filters,
    scrollPosition,
  ]);

  // Add effect to check session storage on mount
  useEffect(() => {
    const savedState = sessionStorage.getItem('DataList-' + location.pathname);
    if (savedState) {
      const parsedState: DataListState<T, F> = JSON.parse(savedState);
      if (
        parsedState.expiration &&
        parsedState.expiration > new Date().getTime() &&
        parsedState.scrollPosition != null
      ) {
        const listContainer = listRef.current as HTMLDivElement | null;
        if (listContainer) {
          setTimeout(() => {
            listContainer.scrollTo({
              top: parsedState.scrollPosition as number,
              behavior: 'smooth',
            });
          }, 150);
        }
      }
    }
  }, []);

  const scrollToTop = () => {
    const listContainer = listRef.current as HTMLDivElement | null;
    if (listContainer) {
      listContainer.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  const handleSort = (field: keyof T) => {
    // Skip sorting for input column
    if (field === 'input') return;

    setPage(0);
    scrollToTop();
    if (sortField === field) {
      if (sortDirection === 'asc') {
        setSortDirection('desc');
      } else if (sortDirection === 'desc') {
        setSortDirection('none');
      } else {
        setSortDirection('asc');
      }
    } else {
      setSortField(field);
      setSortDirection('asc');
    }
  };

  const getSortArrow = (field: string) => {
    const isSorted = sortField === field && sortDirection !== 'none';
    return (
      <div className={`arrow-container ${isSorted ? 'visible' : ''}`}>
        {sortDirection === 'asc' && isSorted ? (
          <FaArrowUp className="arrow-icon" />
        ) : sortDirection === 'desc' && isSorted ? (
          <FaArrowDown className="arrow-icon" />
        ) : (
          <FaArrowUp className="arrow-icon" />
        )}
      </div>
    );
  };

  const filteredAndSortedItems = useMemo(() => {
    let filtered = [...items];

    // Apply search filter
    if (debouncedSearchQuery) {
      const searchLower = debouncedSearchQuery.toLowerCase();
      filtered = filtered.filter((item) => {
        if (searchFilter) {
          return searchFilter(item, searchLower);
        }
        return Object.values(item).some((value) =>
          String(value).toLowerCase().includes(searchLower)
        );
      });
    }

    // Apply custom filters using the filterFn
    if (filters && Object.keys(filters).length > 0) {
      Object.entries(filters).forEach(([key, selectedFilters]) => {
        if (selectedFilters.length > 0) {
          const filterOption = filterOptions?.find(
            (opt) => String(opt.key) === key
          );
          if (filterOption) {
            filtered = filtered.filter((item) =>
              filterOption.filterFn(item, selectedFilters)
            );
          }
        }
      });
    }

    // Apply sorting using sortItem prop if provided
    if (sortField && sortDirection !== 'none') {
      filtered.sort((a, b) => {
        if (sortItem) {
          return sortItem(String(sortField), sortDirection, a, b);
        }
        const aValue = a[sortField];
        const bValue = b[sortField];
        const comparison = String(aValue).localeCompare(String(bValue));
        return sortDirection === 'asc' ? comparison : -comparison;
      });
    }

    return filtered;
  }, [
    items,
    debouncedSearchQuery,
    filters,
    sortField,
    sortDirection,
    sortItem,
    filterOptions,
  ]);

  // Pagination calculations
  const totalItems = useMemo(
    () => totalCount ?? filteredAndSortedItems.length,
    [filteredAndSortedItems, totalCount]
  );
  const hasNextPage = config.pagination && (page + 1) * pageSize < totalItems;
  const displayedItems = config.pagination
    ? filteredAndSortedItems.slice(page * pageSize, (page + 1) * pageSize)
    : filteredAndSortedItems;

  const handleSelectAllDataList = (
    e: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
    intermediate: boolean
  ) => {
    if (checked && !intermediate) {
      // setSelectedItems(filteredAndSortedItems);
      handleSelectAll?.(e, true);
      setIsSelectAll(true);
    } else {
      // setSelectedItems([]);
      handleSelectAll?.(e, false);
      setIsSelectAll(false);
    }
  };

  // 1. Memoize the TableHeader component
  const TableHeader = React.memo(() => (
    <div className="model-table-header">
      {headers.map((header, index) => (
        <span
          key={String(header.key)}
          className={`column ${header.key === 'input' ? 'checkbox-column' : ''}`}
          style={{
            ...header.style,
            flex: header.flex,
            borderRight: index < headers.length - 1 ? undefined : 'none',
            minHeight: '32px',
          }}
        >
          {header.key === 'input' ? (
            <>
              {config.mainInputType === 'checkbox' ? (
                <HMCheckbox
                  checked={
                    isSelectAll ||
                    (selectedItems.length > 0 &&
                      selectedItems.length === totalItems)
                  }
                  indeterminate={
                    selectedItems.length > 0 &&
                    selectedItems.length < totalItems
                  }
                  onChange={(e, checked) =>
                    handleSelectAllDataList?.(
                      e,
                      checked,
                      selectedItems.length > 0 &&
                        selectedItems.length < totalItems
                    )
                  }
                />
              ) : (
                <HMSwitch
                  checked={isSelectAll}
                  onChange={(e, checked) =>
                    handleSelectAllDataList(e, checked, false)
                  }
                />
              )}
            </>
          ) : (
            <>
              {header.name}
              {header.sortable && header.key !== 'input' && (
                <span
                  style={{ cursor: 'pointer', marginLeft: '5px' }}
                  onClick={() => handleSort(header.key as keyof T)}
                >
                  {getSortArrow(String(header.key))}
                </span>
              )}
            </>
          )}
        </span>
      ))}
    </div>
  ));

  // 2. Memoize the row rendering function
  const renderRow = useCallback(
    (item: T, index: number) => (
      <div
        ref={index === 0 ? rowRef : undefined} // Measure first row
        key={item.id}
        className={`model-table-data ${index < displayedItems.length - 1 ? 'border-bottom' : ''} ${getRowClassName?.(
          item
        )} ${config.highlightSelectedItems && selectedItems.includes(item) ? 'selected' : ''}`}
        onClick={() => onItemClick?.(item)}
      >
        {headers.map((header) => (
          <span
            key={String(header.key)}
            className={`column ${spacing} ${header.key === 'input' ? 'checkbox-column' : ''}`}
            style={{ ...header.style, flex: header.flex }}
            onClick={(e: any) => {
              if (header.key === 'input') {
                e.stopPropagation();
                const checked = !selectedItems.includes(item);
                onSelectionChange?.(
                  checked
                    ? [...selectedItems, item]
                    : selectedItems.filter((i) => i !== item)
                );
              }
            }}
          >
            {header.render ? (
              header.render(item)
            ) : header.key === 'input' ? (
              <HMCheckbox
                checked={selectedItems.includes(item)}
                onChange={(e, checked) =>
                  onSelectionChange?.(
                    checked
                      ? [...selectedItems, item]
                      : selectedItems.filter((i) => i !== item)
                  )
                }
              />
            ) : (
              String(item[header.key as keyof T] || '')
            )}
          </span>
        ))}
      </div>
    ),
    [
      headers,
      onSelectionChange,
      handleSelectAll,
      onItemClick,
      spacing,
      getRowClassName,
      totalCount,
      selectedItems,
      displayedItems,
    ]
  );

  // 3. Memoize pagination calculations
  const paginationData = useMemo(() => {
    const totalCount = filteredAndSortedItems.length;
    const hasNextPage = (page + 1) * pageSize < totalCount;
    const paginatedItems = filteredAndSortedItems.slice(
      page * pageSize,
      (page + 1) * pageSize
    );
    return { totalCount, hasNextPage, paginatedItems };
  }, [filteredAndSortedItems, page, pageSize]);

  // 4. Memoize filter options rendering
  const filterOptionsComponent = useMemo(
    () =>
      filterOptions?.map((filterOption) => (
        <MultiSelectDropdown
          key={String(filterOption.key)}
          searchable={filterOption.options.length > 10}
          title={filterOption.title}
          options={filterOption.options}
          buttonColor="#E0EADD"
          textColor="#434343"
          selectedItems={filters[filterOption.key as string] || []}
          labelField={filterOption.labelField}
          keyField={filterOption.keyField}
          onSelected={(selected) => {
            setFilters((prev) => ({
              ...prev,
              [filterOption.key]: selected,
            }));
          }}
        />
      )),
    [filterOptions, filters]
  );

  return (
    <div className="data-list-container">
      {customTopComponent}

      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>

      <Row>
        <Col sm={6}>
          <SearchBar
            containerStyle={{ width: '100%' }}
            value={searchQuery}
            onChange={(searchTerm: string) => {
              setSearchQuery(searchTerm);
              debouncedSearch(searchTerm);
            }}
            onSubmit={(searchTerm: string) => {
              setDebouncedSearchQuery(searchTerm);
              setPage(0);
              scrollToTop();
            }}
            placeholder={searchPlaceholder}
          />
        </Col>
        <Col sm={6}>
          <div className="pagination">
            {filterOptionsComponent}

            {customFilterComponent}

            {config.pagination && (
              <>
                <Dropdown
                  value={pageSize}
                  options={pageSizeOptions}
                  onChange={(e: number) => {
                    setPageSize(e);
                    setPage(0);
                    scrollToTop();
                  }}
                  buttonColor={'transparent'}
                  textColor={'#434343'}
                />

                <div className="pagination-controls">
                  <span className="pagination-text">
                    {`${page * pageSize + 1}-${Math.min((page + 1) * pageSize, totalItems)} of ${totalItems}`}
                  </span>
                  <Tooltip title="Previous Page" arrow placement="top">
                    <IconButton
                      onClick={() => setPage((prev) => Math.max(0, prev - 1))}
                      disabled={page === 0}
                      sx={{
                        backgroundColor: 'transparent',
                        color: '#434343',
                        '&:hover': { backgroundColor: '#e0eadd' },
                        '&.Mui-disabled': { color: '#bdbdbd' },
                        width: 35,
                        height: 35,
                        padding: '6px',
                      }}
                    >
                      <FaChevronLeft size={16} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Next Page" arrow placement="top">
                    <IconButton
                      onClick={() => setPage((prev) => prev + 1)}
                      disabled={!hasNextPage}
                      sx={{
                        backgroundColor: 'transparent',
                        color: '#434343',
                        '&:hover': { backgroundColor: '#e0eadd' },
                        '&.Mui-disabled': { color: '#bdbdbd' },
                        width: 35,
                        height: 35,
                        padding: '6px',
                      }}
                    >
                      <FaChevronRight size={16} />
                    </IconButton>
                  </Tooltip>
                </div>
              </>
            )}

            {isLazyLoading && (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: config.pagination ? '-38px' : '-5px',
                }}
              >
                <Tooltip title="Syncing to the Cloud." arrow placement="top">
                  <div
                    style={{
                      display: 'block',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <ReactLoading
                      className="load"
                      type="spokes"
                      height={24}
                      width={24}
                      color="#00534C"
                    />
                  </div>
                </Tooltip>
              </div>
            )}
          </div>
        </Col>
      </Row>

      <Paper>
        <div className="model-table-header-container">
          <TableHeader />
        </div>
        <div
          className="list-container"
          ref={listRef}
          style={{ maxHeight: maxHeight ? maxHeight : undefined }}
        >
          <ViewportList
            viewportRef={listRef}
            items={displayedItems}
            itemSize={rowHeight}
            overscan={Math.min(5, Math.max(25, displayedItems.length * 0.25))}
          >
            {renderRow}
          </ViewportList>
        </div>
      </Paper>

      {isLoading && <Loading type="bubbles" />}
    </div>
  );
}

export default DataList;
