import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  DatabaseResponse,
  ResponseType,
  Response,
} from '../../../../data/AmplifyDB';
import ProtocolHeader from '../../protocol/ProtocolHeader';
import {
  findDepartmentOwner,
  getFormattedDate,
  globals,
  hasAdminUserAccess,
} from '../../../_global/common/Utils';
import { FaChevronRight, FaLock } from 'react-icons/fa6';
import MedicationItem, {
  getConcentrationsArrayString,
} from '../../../../data/model/MedicationItem';
import SearchBar from '../../../components/Search/SearchBar';
import Status from '../../../components/ProgressStatus/ProgressStatus';
import { handleGetDepartment } from '../../../../store/actions';
import { useDispatch } from 'react-redux';
import NewMedicationSideout from '../../../components/SideOut/NewModelItemSideout';
import {
  deleteMedicationItem,
  fetchMedications,
} from '../../../../data/functions/MedicationDB';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';
import ConfirmModal from '../../../components/Modal/ConfirmModal';
import { ProgressStatus, User } from '../../../../models';
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import OwnerImage from '../../../components/OwnerImage/OwnerImage';
import HMSwitch from '../../../components/general/HMSwitch';
import HMCheckbox from '../../../components/general/HMCheckbox';
import { ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material';
import DepartmentItem from '../../../../data/model/DepartmentItem';
import {
  isDraftCreated,
  removeCurrentDraftUpdates,
} from '../../../../data/AmplifyVersion';
import { handleToggleEnabled } from '../../../../data/functions/ModelDB';
import DraftSaveModal from '../../../components/Modal/DraftSaveModal';
import { set } from 'lodash';
import ReactLoading from 'react-loading';
import DataList, { Header } from './DataList';

/* 09-27-23 Arul: Created Component for Protocol Screen*/
const ListMedications = (props: any) => {
  const isLoggedIn = useSelector((state: any) => state.isLoggedIn);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const reducerState = useSelector((state: any) => state.department);
  const { state } = location;
  const [totalDoses, setTotalDoses] = useState(-1);
  const [database, setDatabase] = useState<DatabaseResponse>(
    useSelector((state: any) => state?.protocol?.departmentItem)
  );
  const user: User = useSelector((state: any) => state?.user);
  const [isOpen, setIsOpen] = useState(false);
  const [isConfirmModal, setIsConfirmModal] = useState(false);

  const [sort, setSort] = useState('name_asc');
  const department = database.department;
  const [medications, setMedications] = useState<MedicationItem[]>(
    database.medications
  );
  const [isCreateMedication, setIsCreateMedication] = useState(false);
  const scrollPosition = useRef(0);

  const [searchQuery, setSearchQuery] = useState(
    state?.search?.searchQuery ?? ''
  );
  const [filters, setFilters] = useState<any[]>(state?.search?.filters ?? []);
  const [categoriesFilter, setCategoriesFilter] = useState<any[]>(
    state?.search?.categoriesFilter ?? []
  );
  const adminLevel = useMemo(() => {
    return department?.adminLevel ?? 4;
  }, [department]);
  const adminAccess = useMemo(() => {
    return hasAdminUserAccess(department, reducerState, user);
  }, [department, reducerState, user]);
  const [list, setList] = useState<any[]>(medications);
  const [selectedItems, setSelectedItems] = useState<MedicationItem[]>([]);
  const paginationModel = { page: 0, pageSize: 50 };
  const [isDelete, setIsDelete] = useState(false);
  // allCheckboxBtn is to set the state for "delete all" button
  const [allCheckedBtn, setAllCheckedBtn] = useState(false);
  //  mainCheckbox is to set the state for the main checkbox
  const [mainCheckbox, setMainCheckbox] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success' as 'success' | 'error',
  });
  const [itemDeactivationStatus, setItemDeactivationStatus] = useState<
    MedicationItem[]
  >([]);
  const [viewing, setViewing] = useState<string | null>('all');

  const allDepartmentOwners = useMemo(() => {
    let deps: DepartmentItem[] = [];
    for (let i = 0; i < medications.length; i++) {
      let owner = findDepartmentOwner(department, reducerState, medications[i]);
      if (owner && !deps.find((dep) => dep.id === owner?.id)) deps.push(owner);
    }
    setViewing('all');
    setFilters([]);
    return deps.sort((a, b) => a.name.localeCompare(b.name));
  }, [medications, department, reducerState]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.ctrlKey && event.key === 'n') {
        event.preventDefault(); // Prevent the default browser action (e.g., saving the webpage)
        handleCreateItem();
        // Add your custom logic here
      } else if (event.ctrlKey && event.key === 'b') handleBack();
    };

    // Add event listener
    document.addEventListener('keydown', handleKeyDown);

    // Clean up event listener on component unmount
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  /* TODO Need to redo the mappings */
  // const reloadDatabase = async () => {
  //   const response = await loadDatabase(department, undefined, true);

  //   if (response.type === ResponseType.Success) {
  //     let medications = response.data.medications;
  //     setMedications(medications);
  //     handleFilterChange(medications);
  //     dispatch<any>(
  //       handleGetDepartment({
  //         ...database,
  //         medications: medications,
  //       } as DatabaseResponse)
  //     );
  //   }
  // };

  const reloadDatabase = async () => {
    const response = await fetchMedications(
      department,
      database,
      true,
      true,
      true
    );
    if (response.type === ResponseType.Success) {
      setMedications(response.data);
      handleFilterChange(response.data);
      dispatch<any>(
        handleGetDepartment({
          ...database,
          medications: response.data,
        } as DatabaseResponse)
      );
    }
  };

  useEffect(() => {
    reloadDatabase();
  }, []);

  useEffect(() => {
    let count = 0;
    medications.forEach((item) => {
      count += item.subItems.length;
    });
    setTotalDoses(count !== 0 ? count : -1);
  }, [database]);

  const handleFilterChange = (pList: any[]) => {
    let filteredList = [...(pList ? pList : medications)];
    if (searchQuery !== '' || filters.length > 0) {
      filteredList = medications.filter((item) => {
        return item.name.toLowerCase().includes(searchQuery.toLowerCase());
      });
      if (filters.length > 0) {
        filteredList = filteredList.filter((item) => {
          //Check if the Filters (Department ID)
          return filters.some((filter) => {
            return (
              (item.status === ProgressStatus.DEACTIVATED &&
                item.overrideItem &&
                item.overrideItem.departmentID === filter.id) ||
              (item.status !== ProgressStatus.DEACTIVATED &&
                item.departmentID === filter.id)
            );
          });
        });
      }
    }
    //Group by the parent first then sort by index
    filteredList = filteredList.sort((a: MedicationItem, b: MedicationItem) => {
      return a.name.localeCompare(b.name);
    });

    if (viewing === 'all') {
    }
    filteredList = handleSort(filteredList);
    setList(filteredList);
  };

  const handleSave = async () => {
    if (itemDeactivationStatus && itemDeactivationStatus.length > 0) {
      try {
        let promises: any[] = [];
        for (let i = 0; i < itemDeactivationStatus.length; i++) {
          promises.push(
            handleToggleEnabled(
              user,
              database.department,
              reducerState,
              itemDeactivationStatus[i]
            )
          );
        }
        let results = await Promise.all(promises);
        for (let i = 0; i < results.length; i++) {
          if (results[i].type === ResponseType.Success) {
            if (globals.debug) console.log('SAVED ITEM', results[i].data);
          } else {
            console.error('ERROR SAVING ITEM', results[i].data);
          }
        }
        let n = results.filter(
          (result) => result.type === ResponseType.Success
        ).length;
        setSnackbar({
          ...snackbar,
          open: true,
          message:
            'Successfully updated ' + n + ' medication' + (n === 1 ? '' : 's'),
          severity: 'success',
        });

        reloadDatabase().then(() => {
          setItemDeactivationStatus([]);
        });
      } catch (error) {
        console.error('ERROR SAVING DEACTIVATE STATE', error);
        setSnackbar({
          ...snackbar,
          open: true,
          message: 'Failed to save medications',
          severity: 'error',
        });
      }
    }
  };

  useEffect(() => {
    handleFilterChange(medications);
  }, [searchQuery, filters, categoriesFilter, sort, medications, viewing]);

  const handleSort = (list: MedicationItem[]): MedicationItem[] => {
    const sortedList = list.sort((a, b) => {
      if (sort === 'name_desc') return b.name.localeCompare(a.name);
      else if (sort === 'name_asc') return a.name.localeCompare(b.name);
      else if (sort === 'doses_desc') {
        if (a.subItems.length > b.subItems.length) return -1;
        else if (a.subItems.length < b.subItems.length) return 1;
        else return 0;
      } else if (sort === 'doses_asc') {
        if (a.subItems.length < b.subItems.length) return -1;
        else if (a.subItems.length > b.subItems.length) return 1;
        else return 0;
      } else if (!a.model || !b.model) return 0;
      else if (sort === 'modified_asc') {
        if (!a.model.updatedAt || !b.model.updatedAt) return 0;
        const aDate = new Date(a.model.updatedAt);
        const bDate = new Date(b.model.updatedAt);
        return aDate.getTime() - bDate.getTime();
      } else if (sort === 'modified_desc') {
        if (!a.model.updatedAt || !b.model.updatedAt) return 0;
        const aDate = new Date(a.model.updatedAt);
        const bDate = new Date(b.model.updatedAt);
        return bDate.getTime() - aDate.getTime();
      }
      return 0;
    });
    return sortedList;
  };

  /* 09-29-23 Arul: handle function to Navigate protocol detail page*/
  const handleItemClick = (medication: MedicationItem) => {
    const state = {
      selectedProtocol: null,
      value: medication,
      subValue: null,
      type: 'Medication',
      editType: 'edit',
      editMode: false,
      page: 'listMedicationsPage',
    };
    navigate(`/protocol/edit/edit-protocol`, { state });
    // const state = { selectedProtocol: protocol, editMode: false };
    // navigate(`/${protocol.nickname}/protocol-detail`, { state });
  };

  const handleCreateItem = () => {
    setIsCreateMedication(true);
    // const state = {
    //   selectedProtocol: null,
    //   value: null,
    //   subValue: null,
    //   type: 'Medication',
    //   editType: 'new',
    //   editMode: false,
    //   page: 'listMedicationsPage',
    // };
    // navigate(`/protocol/edit/edit-protocol`, { state });
    // const state = { selectedProtocol: protocol, editMode: false };
    // navigate(`/${protocol.nickname}/protocol-detail`, { state });
  };

  /* 09-29-23 Arul: Render the Protocol Accordion*/
  const rowStyle = {
    display: 'grid',
    gridTemplateColumns: '3fr 2fr 2fr 2fr 1fr', // + (department.subDeps ? ' 1fr' : ''),
    // backgroundColor: isHovered ? '#e0e0e0' : '#fff'
  };

  const handleBack = () => {
    sessionStorage.removeItem('DataList-' + location.pathname);
    navigate(`/database`, { state: department });
  };

  const handleMakeNew = async () => {
    let response = await removeCurrentDraftUpdates(database);
    if (response.type === ResponseType.Success) {
      if (response.data.length > 0) {
        if (globals.debug)
          console.log('Successfully removed current draft updates', response);
        setIsConfirmModal(false);
        setIsOpen(false);
      }
    } else {
      if (globals.debug)
        console.log('Failed to remove current draft updates', response);
    }
  };

  const handleCheckIsDraft = async () => {
    let response: Response = await isDraftCreated(database.department);
    if (response.type === ResponseType.Success) {
      let isDraft = response.data;
      if (isDraft) setIsOpen(true);
    } else {
      if (globals.debug)
        console.log('Failed to check if draft exists', response);
    }
  };

  const headers: Header<MedicationItem>[] = useMemo(
    () => [
      {
        key: 'input',
        name: '',
        sortable: false,
        flex: 0.25,
        render: (item: MedicationItem) => {
          const isOwner = item.departmentID === department.id;
          const adminAccess = hasAdminUserAccess(
            department,
            reducerState,
            user
          );
          const isSwitchChecked = !itemDeactivationStatus.find(
            (newItem) => newItem.uid === item.uid
          )
            ? item.status !== ProgressStatus.DEACTIVATED
            : item.status === ProgressStatus.DEACTIVATED;

          return (
            <div
              style={{ display: 'flex', alignItems: 'center', height: '100%' }}
              onClick={(e: any) => {
                e.stopPropagation();
                // handleItemClick(item);
              }}
            >
              {adminAccess && (
                <>
                  {isOwner && item.status !== ProgressStatus.DEACTIVATED ? (
                    <HMCheckbox
                      checked={selectedItems.some(
                        (selected) => selected.uid === item.uid
                      )}
                      onChange={() => handleSelectionChange(item)}
                    />
                  ) : (
                    <HMSwitch
                      checked={isSwitchChecked}
                      onChange={() => handleToggleEnabledUI(item)}
                    />
                  )}
                </>
              )}
            </div>
          );
        },
      },
      {
        key: 'name',
        name: 'Name',
        sortable: true,
        flex: 2,
        render: (item: MedicationItem) => {
          const isOwner = item.departmentID === department.id;
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {!(isOwner && item.status !== 'DEACTIVATED') && (
                <FaLock
                  className="table-icon"
                  style={{ marginRight: '8px' }}
                  color="#A3A3A3"
                />
              )}
              {item.name}
              {item.status !== 'ACTIVE' && (
                <Status status={item.status} style={{ marginLeft: '8px' }} />
              )}
            </div>
          );
        },
      },
      {
        key: 'concentration',
        name: 'Concentration',
        sortable: false,
        flex: 1,
        render: (item: MedicationItem) =>
          getConcentrationsArrayString(item.concentrations),
      },
      {
        key: 'modified_date',
        name: 'Modified Date',
        sortable: true,
        flex: 1,
        render: (item: MedicationItem) =>
          getFormattedDate(
            item?.model?.updatedAt
              ? new Date(item.model.updatedAt)
              : new Date(),
            true
          ),
      },
      {
        key: 'modified_by',
        name: 'Modified By',
        sortable: true,
        flex: 1,
        render: (item: MedicationItem) => {
          return item.modifiedBy
            ? `${item.modifiedBy.firstName} ${item.modifiedBy.lastName}`
            : 'Hinckley Medical';
        },
      },
      {
        key: 'owner',
        name: 'Owner',
        sortable: true,
        flex: 1,
        render: (item: MedicationItem) => {
          const departmentOwner = findDepartmentOwner(
            department,
            reducerState,
            item
          );
          return departmentOwner ? (
            <OwnerImage
              owner={departmentOwner}
              size={32}
              style={{ padding: 0, margin: 0 }}
            />
          ) : null;
        },
      },
      {
        key: 'doses',
        name: `Doses${totalDoses !== -1 ? ` (${totalDoses})` : ''}`,
        sortable: true,
        flex: 1,
        render: (item: MedicationItem) => (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              flex: 1,
              marginLeft: '0.5rem',
            }}
          >
            <span>{item.subItems.length}</span>
            <FaChevronRight className="icon-normal" style={{ margin: '4px' }} />
          </div>
        ),
      },
    ],
    [
      totalDoses,
      department,
      reducerState,
      user,
      itemDeactivationStatus,
      selectedItems,
    ]
  );

  const handleSortItem = (
    key: string,
    direction: string,
    a: MedicationItem,
    b: MedicationItem
  ) => {
    if (key === 'name') {
      return direction === 'asc'
        ? a.name.localeCompare(b.name)
        : b.name.localeCompare(a.name);
    } else if (key === 'modified_date') {
      const aDate = new Date(a.model?.updatedAt ?? new Date());
      const bDate = new Date(b.model?.updatedAt ?? new Date());
      return direction === 'asc'
        ? aDate.getTime() - bDate.getTime()
        : bDate.getTime() - aDate.getTime();
    } else if (key === 'doses') {
      return direction === 'asc'
        ? a.subItems.length - b.subItems.length
        : b.subItems.length - a.subItems.length;
    } else if (key === 'modified_by') {
      const aName = a.modifiedBy
        ? `${a.modifiedBy.firstName} ${a.modifiedBy.lastName}`
        : 'Hinckley Medical';
      const bName = b.modifiedBy
        ? `${b.modifiedBy.firstName} ${b.modifiedBy.lastName}`
        : 'Hinckley Medical';
      return direction === 'asc'
        ? aName.localeCompare(bName)
        : bName.localeCompare(aName);
    } else if (key === 'owner') {
      const aOwner =
        findDepartmentOwner(department, reducerState, a)?.name ?? '';
      const bOwner =
        findDepartmentOwner(department, reducerState, b)?.name ?? '';
      return direction === 'asc'
        ? aOwner.localeCompare(bOwner)
        : bOwner.localeCompare(aOwner);
    }
    return 0;
  };

  const customFilterComponent = useMemo(
    () =>
      allDepartmentOwners.length > 1 ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            margin: '4px 0px',
          }}
        >
          <ToggleButtonGroup
            value={viewing}
            exclusive
            onChange={(event, newAlignment) => setViewing(newAlignment)}
            aria-label="owner"
            sx={{
              maxHeight: '50px',
            }}
          >
            <Tooltip title={'View All Medications'} placement="bottom" arrow>
              <ToggleButton
                value="all"
                aria-label="all"
                autoCapitalize="none"
                sx={{
                  padding: '0px 8px', // Adjust padding as needed for the "All" button
                  minWidth: 'auto', // Ensures the width is only as wide as the content
                }}
                onChange={() => {
                  setViewing('all');
                  setFilters([]);
                }}
              >
                All
              </ToggleButton>
            </Tooltip>
            {allDepartmentOwners.map((owner) => (
              <ToggleButton
                key={owner.id}
                value={owner.id}
                onClick={() => {
                  setViewing(owner.id);
                  setFilters([owner]);
                }}
              >
                <Tooltip title={owner.name} placement="bottom" arrow>
                  <img
                    className="no-select owner-logo"
                    src={owner.logoVerifiedUrl ?? ''}
                    alt="Agency Logo"
                    style={{ width: 40, height: 40 }}
                  />
                </Tooltip>
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </div>
      ) : null,
    [allDepartmentOwners, viewing]
  );

  const handleSelectionChange = (protocol: MedicationItem) => {
    if (selectedItems.find((item) => item.uid === protocol.uid)) {
      setSelectedItems((prevItems: MedicationItem[]) => {
        const updatedItems = prevItems.filter(
          (item: MedicationItem) => item.uid !== protocol.uid
        );

        if (updatedItems.length === 0) {
          setMainCheckbox(false);
        }
        return updatedItems;
      });
    } else {
      let items = [...selectedItems, protocol];
      let available = medications.filter(
        (item) =>
          item.status !== 'DEACTIVATED' && department.id === item.departmentID
      );
      setSelectedItems(items);
      if (items.length === available.length) {
        setMainCheckbox(true);
      } else if (mainCheckbox) {
        setMainCheckbox(false);
      }
    }
  };

  const deleteNextMedication = async () => {
    try {
      // If there are no items left, exit the function
      if (selectedItems.length === 0) {
        setIsDelete(false);
        return;
      }

      // Get the current medication to delete (the first item in the array)
      const currentMedication = selectedItems[0];
      const isDraft = currentMedication.status === ProgressStatus.DRAFT;

      // Delete the medication
      let response = await deleteMedicationItem(
        department,
        currentMedication,
        !isDraft
      );
      if (response.type === ResponseType.Success) {
        setSnackbar({
          open: true,
          message: `Successfully deleted ${currentMedication.name}`,
          severity: 'success',
        });

        // Remove the successfully deleted medication from the selectedItems array
        setSelectedItems((prevItems: MedicationItem[]) => {
          const updatedItems = prevItems.filter(
            (item: MedicationItem) => item.uid !== currentMedication.uid
          );
          // If no items are left after deletion, close the modal
          if (updatedItems.length === 0) {
            setIsDelete(false);
            setMainCheckbox(false);
          }
          return updatedItems;
        });
      } else {
        setSnackbar({
          open: true,
          message: 'Failed to delete medication',
          severity: 'error',
        });
      }

      // Reload the database and check if there are any items left
      reloadDatabase();
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Failed to delete medication',
        severity: 'error',
      });
    }
  };

  const handleConfirmDeleteMedications = async () => {
    if (allCheckedBtn) {
      try {
        // Initialize counters for success and failure
        let successCount = 0;
        let failureCount = 0;

        for (const parmMedication of selectedItems) {
          const isDraft = parmMedication.status === ProgressStatus.DRAFT;
          let response = await deleteMedicationItem(
            department,
            parmMedication,
            !isDraft
          );
          if (response.type === ResponseType.Success) {
            successCount++;
          } else {
            failureCount++;
          }
        }

        // Set snackbar message based on the results
        if (successCount > 0) {
          setSnackbar({
            open: true,
            message:
              `Successfully deleted ${successCount} medication` +
              (successCount === 1 ? '' : 's'),
            severity: 'success',
          });
        }
        if (failureCount > 0) {
          setSnackbar({
            open: true,
            message: `Failed to delete ${failureCount} medications.`,
            severity: 'error',
          });
        }
        if (successCount === selectedItems.length) {
          setMainCheckbox(false);
        }

        // Clear selectedItems after all deletions
        setSelectedItems([]);
        reloadDatabase();
      } catch (error) {
        setSnackbar({
          open: true,
          message: 'Failed to delete medication',
          severity: 'error',
        });
      }
      setIsDelete(false); // Close modal after all deletions
    } else {
      deleteNextMedication(); // Call the refactored function
    }
  };

  const handleSnackbarClose = () => {
    setSnackbar({
      open: false,
      message: '',
      severity: 'success',
    });
  };

  const handleToggleEnabledUI = async (item: MedicationItem) => {
    let find = itemDeactivationStatus.find(
      (newItem) => newItem.uid === item.uid
    );
    if (find)
      setItemDeactivationStatus(
        itemDeactivationStatus.filter((newItem) => newItem.uid !== item.uid)
      );
    else setItemDeactivationStatus([...itemDeactivationStatus, item]);
  };

  const getRowClassName = useCallback(
    (params: MedicationItem) => {
      const rowItem = list.find((item) => item.uid === params.uid);
      const adminAccess = hasAdminUserAccess(
        department,
        reducerState,
        user,
        rowItem
      );
      // Apply background color when adminAccess is false or the item status is not DEACTIVATED.
      if (!(adminAccess && rowItem?.status !== 'DEACTIVATED')) {
        return 'inactive';
      }

      // If the row is selected, give it the selected class.
      const isSelected = selectedItems.some((item) => item.uid === params.id);
      if (isSelected) return 'selectedRow';

      // Default return for other cases
      return '';
    },
    [department, list, user, reducerState, selectedItems]
  );

  const handleSelectAll = (
    e: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    if (checked) {
      setSelectedItems([
        ...medications.filter(
          (item) =>
            item.status !== 'DEACTIVATED' && department.id === item.departmentID
        ),
      ]);
    } else {
      setSelectedItems([]);
    }
  };

  return (
    <div className="screen-container">
      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
      {isDelete && (
        <ConfirmModal
          isVisible={isDelete}
          title={'Delete Medication?'}
          handleClose={() => {
            setIsDelete(false);
            setAllCheckedBtn(false);
          }}
          handleSubmit={handleConfirmDeleteMedications}
          isDeleteBtn={true}
          primaryBtnName="Cancel"
          secondaryBtnName="Delete"
          primaryDescription={
            allCheckedBtn
              ? `Are you sure you would like to delete ${selectedItems.length} medications?`
              : `Are you sure you would like to delete ${selectedItems[0].name}?`
          }
          isSelectAllBtn={selectedItems.length > 1}
          handleCheckAll={(check: any) => {
            setAllCheckedBtn(check);
          }}
        />
      )}
      {isOpen && (
        <DraftSaveModal
          database={database}
          isVisible={isOpen}
          handleClose={() => {
            setIsOpen(false);
          }}
          handleContinue={() => {
            setIsOpen(false);
          }}
          handleNew={() => {
            setIsConfirmModal(true);
          }}
        />
      )}
      {isConfirmModal && (
        <ConfirmModal
          isVisible={isConfirmModal}
          title="Make New Draft?"
          handleClose={() => {
            setIsConfirmModal(false);
          }}
          handleSubmit={handleMakeNew}
          isDeleteBtn={true}
          primaryBtnName="Cancel"
          secondaryBtnName="Make New"
          primaryDescription="Are you sure you would like to remove the previous draft and override it and create a new one? THIS IS NOT REVERSIBLE."
        />
      )}

      <NewMedicationSideout
        type="medication"
        isVisible={isCreateMedication}
        handleClose={() => setIsCreateMedication(false)}
      />
      <ProtocolHeader
        // homeScreen={true}
        isBackButton={true}
        handleCancel={handleBack}
        name={
          selectedItems.length === 0
            ? 'Medications: ' + list.length + ' items'
            : 'Medications: ' +
              selectedItems.length +
              ' / ' +
              list.length +
              ' items'
        }
        isSaveButton={itemDeactivationStatus.length > 0}
        isSaveActive={itemDeactivationStatus.length > 0}
        page={department.name}
        rightSideBtn={'edit'}
        isEditIcon={false}
        handleSave={() => handleSave()}
        handleCancelEdit={() => {
          console.log('Cancel Edit');
          setItemDeactivationStatus([]);
          setMedications([...medications]);
          handleFilterChange([...medications]);
        }}
        isCreateButton={adminLevel > 2 && adminAccess}
        isCreateActive={true}
        handleCreate={handleCreateItem}
        handleEdit={() => {
          handleCheckIsDraft();
        }}
        isDeleteButton={selectedItems.length > 0}
        isDeleteDisabled={selectedItems.length === 0}
        handleDelete={() => setIsDelete(true)}
        type={'protocol'}
      />
      <DataList<MedicationItem, never>
        items={list}
        headers={headers}
        onItemClick={handleItemClick}
        onSelectionChange={setSelectedItems}
        searchPlaceholder="Search Medications..."
        customFilterComponent={customFilterComponent}
        sortItem={handleSortItem}
        getRowClassName={getRowClassName}
        handleSelectAll={handleSelectAll}
        selectedItems={selectedItems}
        searchFilter={(item, searchQuery) => {
          return (
            item.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            (user.type === 'ADMIN' &&
              item.uid.toLowerCase().includes(searchQuery.toLowerCase()))
          );
        }}
      />
    </div>
  );
};

export default ListMedications;
