import { useMemo, useState } from 'react';
import ElectricalItem from '../../../../data/model/ElectricalItem';
import ElectricalSubItem, {
  cloneElectricalSubItem,
} from '../../../../data/model/ElectricalSubItem';
import ProtocolItem from '../../../../data/model/ProtocolItem';
import {
  createElectricalDoseItem,
  deleteElectricalDoseItem,
  duplicateElectricalDose,
  ElectricalDoseJSON,
} from '../../../../data/functions/ElectricalDB';
import { useSelector } from 'react-redux';
import {
  DatabaseResponse,
  Response,
  ResponseType,
} from '../../../../data/AmplifyDB';
import { User } from '../../../../models';
import { ProgressStatus } from '../../../../models';
import { globals, upgradeVersion } from '../../../_global/common/Utils';
import ElectricalShockSideout from '../../../components/SideOut/doseSideOut/ElectricalShockSideout';
import { auditElectricalDosesToOldDBStyle } from '../../../../data/AmplifyActions';
import { handleToggleEnabled } from '../../../../data/functions/ModelDB';
import PatientAge from '../../../_global/common/PatientAge';

interface ElectricalDoseSideoutProps {
  visible: boolean;
  protocol?: ProtocolItem | undefined | null;
  parentModel: ElectricalItem;
  dose?: ElectricalSubItem;
  handleClose: () => void;
  handleSubmit: (reloadDatabase: boolean) => void;
}
/* 09-28-23 Arul: Created Department component  for Protocol page to display the category and protocol accordion*/
const NewElectricalDoseSideout = (props: ElectricalDoseSideoutProps) => {
  const { visible, dose, parentModel, protocol, handleClose, handleSubmit } =
    props;
  const reducerState = useSelector((state: any) => state.department);

  const [database, setDatabase] = useState<DatabaseResponse>(
    useSelector((state: any) => state.protocol.departmentItem)
  );
  const department = database.department;
  const user: User = useSelector((state: any) => state?.user);

  const parmElectrical = useMemo(() => {
    return parentModel;
  }, [parentModel]);

  const [allSubElectricalItems, setAllSubElectricalItems] = useState<
    ElectricalSubItem[]
  >(parmElectrical.subItems);
  const subElectricalItems = useMemo(() => {
    return protocol
      ? allSubElectricalItems
          .filter(
            (item: ElectricalSubItem) =>
              item.parentProtocol.uid === protocol.uid
          )
          .sort((a, b) => a.index - b.index)
      : allSubElectricalItems;
  }, [allSubElectricalItems, protocol]);

  const handleOnSubmitElectricalShock = async (
    data: any,
    previousDose: ElectricalSubItem | undefined,
    parentProtocol: ProtocolItem | undefined
  ) => {
    let prot = parentProtocol ? parentProtocol : protocol;
    if (!prot) {
      console.error('Failed to find protocol');
      return;
    }

    let protocolID =
      prot.status === ProgressStatus.DRAFT && prot.activeID !== null
        ? prot.activeID
        : prot.uid;

    if (!protocolID) {
      console.error('Failed to find protocol ID');
      return;
    }

    /* Create a new ElectricalDose for the medicaiton */
    let prevDose = previousDose as ElectricalSubItem;
    const isOwner = prevDose ? department.id === prevDose.departmentID : true;
    let index = prevDose
      ? prevDose.index
      : (prot as ProtocolItem).electrical.length;
    if (department.parentDep?.parentDep) index += 10000;
    else if (department.parentDep) index += 1000;

    let newMedDose: ElectricalDoseJSON = {
      departmentID: department.id,
      electrical: parmElectrical,
      protocol: prot,
      rangeLow: data.rangeLow ? Number(data.rangeLow) : 0,
      rangeHigh: data.rangeHigh ? Number(data.rangeHigh) : globals.MAX_VALUE,
      createdBy: user.id,
      basis: data.basis,
      title: data.title,
      warning: data.warning,
      instruction: data.instruction,
      note: data.note,
      maxDose: data.maxDose,
      minDose: data.minDose,
      maxTotalDose: data.maxTotalDose,
      calcMax: data.calcMax,
      calcMin: data.calcMin,
      index: index,
      ageLow: data.ageLow ? (data.ageLow as PatientAge).ageValue : 0,
      ageHigh: data.ageHigh
        ? (data.ageHigh as PatientAge).ageValue
        : globals.MAX_VALUE,
      ageGroup: data.ageGroup,
      repeatTime: data.repeatTime,

      activeID: !isOwner
        ? undefined
        : prevDose == null
          ? null
          : prevDose.status === ProgressStatus.ACTIVE
            ? prevDose.uid
            : prevDose.activeID,

      overrideID: isOwner ? null : prevDose?.uid,
      status: ProgressStatus.DRAFT,
      version:
        prevDose == null
          ? 'v1.0.0'
          : prevDose.status === ProgressStatus.ACTIVE
            ? upgradeVersion(prevDose.version)
            : prevDose.version,
      createdAt: prevDose?.createdAt ? prevDose.createdAt : new Date(),
    };

    createElectricalDoseItem(newMedDose, prevDose).then(
      (response: Response) => {
        if (response.type === ResponseType.Success) {
          let newMedSubItem = response.data as ElectricalSubItem;
          if (globals.debug) console.log('New Electrical Dose', newMedSubItem);
          handleSubmit(true);
        }
      }
    );
  };

  const handleCopyProtocolDoses = async (
    toProtocol: ProtocolItem,
    fromProtocol: ProtocolItem
  ) => {
    /* First find the Medication Protocol */
    let promises: any[] = [];
    let medDoses = [...fromProtocol.electrical];
    for (let i = 0; i < medDoses.length; i++) {
      let medDose = medDoses[i];
      let parent = parmElectrical;
      if (
        medDose.parent.uid === parent.uid ||
        medDose.parent.activeID === parent.uid
      ) {
        promises.push(
          duplicateElectricalDose(department, medDose, user, toProtocol)
        );
      }
    }
    let results = await Promise.all(promises);
    handleSubmit(true);
  };

  const handleRemoveElectricalDose = async (
    dose: ElectricalSubItem,
    type: 'block' | 'restore' | 'delete'
  ) => {
    let subMeds = [...subElectricalItems];

    if (type === 'block') {
      let resp = await handleToggleEnabled(
        user,
        department,
        reducerState,
        dose
      );
      if (resp.type === ResponseType.Success) {
        console.log('Blocked Dose', resp);
        handleSubmit(true);
      } else {
        console.error('Failed to block dose', resp);
      }
    } else if (
      /* Use Cases: Created a new dose and deleted it, or deleted an existing dose */
      dose.status === ProgressStatus.DEACTIVATED ||
      dose.status === ProgressStatus.DRAFT
    ) {
      let resp = await deleteElectricalDoseItem(dose, false);
      if (resp.type === ResponseType.Success) {
        if (globals.debug) console.log('Deleted Dose', resp);
        handleSubmit(true);
        auditElectricalDosesToOldDBStyle([dose])
          .then((response) => {
            if (response.type === ResponseType.Success) {
              console.log('Successfully audited doses', response.data);
            }
          })
          .catch((error) => {
            console.error('Failed to audit doses', error);
          });
      }
    } else {
      let prevStatus = dose.status;

      let resp = await deleteElectricalDoseItem(dose, true);
      if (resp.type === ResponseType.Success) {
        let deletedDose: ElectricalSubItem = resp.data;
        if (globals.debug) console.log('Deleted Dose', deletedDose);
        handleSubmit(true);
        if (prevStatus === ProgressStatus.ACTIVE) {
          auditElectricalDosesToOldDBStyle([dose])
            .then((response) => {
              if (response.type === ResponseType.Success) {
                console.log('Successfully audited doses', response.data);
              }
            })
            .catch((error) => {
              console.error('Failed to audit doses', error);
            });
        }
      }
    }
  };

  return (
    <ElectricalShockSideout
      visible={visible}
      setVisible={handleClose}
      doseIndex={dose?.index ?? 1}
      protocol={protocol != null ? protocol : null}
      parentModel={parmElectrical}
      dose={dose}
      subElectrialItems={allSubElectricalItems}
      editDose={dose != null}
      onSubmit={handleOnSubmitElectricalShock}
      onSubmitMultiDose={handleCopyProtocolDoses}
      onRemove={handleRemoveElectricalDose}
    />
  );
};

export default NewElectricalDoseSideout;
