import { useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { globals } from './ui/_global/common/Utils';
import { useSelector } from 'react-redux';
import DepartmentItem from './data/model/DepartmentItem';

interface AuthRouteProps {
  content: any;
}

/* Determines if we need to check the user's authentication */
export function checkPublicURL(path: string) {
  let split = path.split('/');
  if (split.length > 1) {
    if (
      (split[1] === 'sites' || split[1] === 'fullscreen-pdf') &&
      (split.length >= 3 || split.length <= 5) &&
      split[2].length > 0
    ) {
      return true;
    } else if (
      split[1] === 'privacy-policy' ||
      split[1] === 'account-deletion'
    ) {
      return true;
    } else if (split[1] === 'share-create-account') {
      return true;
    } else if (split[1] === 'create-account') {
      return true;
    }
  }
  return false;
}

const checkPremiumOnly = (path: string, department: DepartmentItem) => {
  if (path.includes('ems-calculators')) {
    return (
      department.softwarePlan !== 'PREMIUM' &&
      department.softwarePlan !== 'ENTERPRISE'
    );
  } else if (path.includes('cpr-assist')) {
    return department.softwarePlan !== 'PREMIUM';
  } else if (path.includes('ambulances')) {
    return department.softwarePlan !== 'PREMIUM';
  }
  return false;
};

const checkOneWeightOnly = (path: string, department: DepartmentItem) => {
  if (path.includes('subtractable-weights')) {
    return !department.isOneWeightEnabled;
  }
  return false;
};

const AuthRoute: React.FC<AuthRouteProps> = ({ content }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isLoggedIn = useSelector((state: any) => state.isLoggedIn);
  const database = useSelector((state: any) => state.protocol.departmentItem);

  const department: DepartmentItem = useMemo(() => {
    return database.department;
  }, [database.department]);

  useEffect(() => {
    let split = location.pathname.split('/');
    if (globals.debug)
      console.log('Location Pathname: ', location.pathname, split);

    /* Base case for fullscreen PDF & sites */
    if (checkPublicURL(location.pathname)) return;

    if (
      isLoggedIn &&
      department &&
      checkPremiumOnly(location.pathname, department)
    ) {
      navigate(`/protocol`);
      return;
    }

    if (
      isLoggedIn &&
      department &&
      checkOneWeightOnly(location.pathname, department)
    ) {
      navigate(`/protocol`);
      return;
    }

    if (
      !isLoggedIn &&
      location.pathname !== '/login' &&
      location.pathname !== '/404'
    ) {
      navigate(`/login`);
    } else if (isLoggedIn && location.pathname === '/') {
      navigate(`/protocol`);
    }
  }, [location.pathname, department, isLoggedIn]);

  return <>{content}</>;
};

export default AuthRoute;
