import React, { useCallback } from 'react';
import { Sidebar } from 'primereact/sidebar';
import { BiArrowBack } from 'react-icons/bi';
import { ViewportList } from 'react-viewport-list';
import MedicationDoseUI from '../../../pages/protocol/edit/Medication/MedicationDoseUI';
import { Button } from 'react-bootstrap';
import { AiOutlineClose } from 'react-icons/ai';
import ModelSubItem from '../../../../data/model/ModelSubItem';
import InfusionSubItem from '../../../../data/model/InfusionSubItem';
import InfusionDoseUI from '../../../pages/protocol/edit/Infusion/InfusionDoseUI';
import MedicationSubItem from '../../../../data/model/MedicationSubItem';
import ElectricalDoseUI from '../../../pages/protocol/edit/Electrical/ElectricalDoseUI';
import ElectricalSubItem from '../../../../data/model/ElectricalSubItem';

interface ArchivedDosesSideoutProps {
  visible: boolean;
  currentDose: ModelSubItem<any>;
  archivedDoses: ModelSubItem<any>[];
  type: string;
  setVisible: (visible: boolean) => void;
}

const ArchivedDosesSideout: React.FC<ArchivedDosesSideoutProps> = ({
  visible,
  currentDose,
  archivedDoses,
  type,
  setVisible,
}) => {
  const customHeader = () => {
    return (
      <div>
        <h4 className="headerText hoverText" onClick={() => setVisible(false)}>
          <span className="">
            <BiArrowBack className="header-icon" data-testid="isBackBtn" />
          </span>{' '}
          Archive History
        </h4>
      </div>
    );
  };

  const ListRender = useCallback(
    ({ data }: any) => {
      let list = data.sort((a: ModelSubItem<any>, b: ModelSubItem<any>) => {
        if (a.index === b.index) return a.rangeLow - b.rangeLow;
        return a.index - b.index;
      });

      return (
        <ViewportList items={list}>
          {(dose: ModelSubItem<any>, index: number) => (
            <div key={index}>
              {type === 'medication' && (
                <MedicationDoseUI
                  dose={dose as MedicationSubItem}
                  doseIndex={dose.version}
                  showProtocol={false}
                  isEditMode={false}
                  archiveMode={true}
                />
              )}
              {type === 'infusion' && (
                <InfusionDoseUI
                  dose={dose as InfusionSubItem}
                  doseIndex={dose.version}
                  showProtocol={false}
                  isEditMode={false}
                  archiveMode={true}
                />
              )}
              {type === 'electrical' && (
                <ElectricalDoseUI
                  dose={dose as ElectricalSubItem}
                  doseIndex={dose.version}
                  showProtocol={false}
                  isEditMode={false}
                  archiveMode={true}
                />
              )}
            </div>
          )}
        </ViewportList>
      );
    },
    [type]
  );

  return (
    <Sidebar
      header={customHeader}
      visible={visible}
      position="right"
      onHide={() => setVisible(false)}
      style={{
        minWidth: '600px',
      }}
      className="sidebarWidth"
    >
      <div style={{ padding: '0px 15px' }}>
        <p className="sidebarText">
          View other archived versions of {currentDose.name}{' '}
          {type === 'electrical'
            ? 'shock'
            : type === 'medication' || type === 'infusion'
              ? 'dose'
              : ''}
          :
        </p>

        <div className="sidebarContent">
          <div style={{ flex: 1, padding: '0 10px 10px 10px' }}>
            <ListRender data={archivedDoses} />
          </div>
        </div>
      </div>

      <div className="sidebarButtons">
        <Button
          className="secondary-button btn-rightMargin"
          data-testid="closeBtn"
          onClick={() => setVisible(false)}
        >
          <span>
            <AiOutlineClose className="icon-normal" />
          </span>{' '}
          Close
        </Button>
      </div>
    </Sidebar>
  );
};

export default ArchivedDosesSideout;
