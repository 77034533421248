import { InputText } from 'primereact/inputtext';
import { Sidebar } from 'primereact/sidebar';
import React, { useEffect, useMemo, useState } from 'react';
import { Button } from 'react-bootstrap';
import { BiArrowBack } from 'react-icons/bi';
import { FiSearch } from 'react-icons/fi';
import { ViewportList } from 'react-viewport-list';
import { useSelector } from 'react-redux';
import { User } from '../../../../models';
import SearchBar from '../../Search/SearchBar';
import { FaCheckCircle, FaQuestionCircle } from 'react-icons/fa';
import NotificationItem from '../../../../data/model/NotificationItem';
import { getFormattedDateTime } from '../../../_global/common/Utils';

interface AddProtocolSideoutProps {
  handleClose: () => void;
  notification: NotificationItem;
  totalUsers: User[];
  isVisible?: boolean;
  users?: User[];
  type?: any;
}

/* 12-01-23 Praveen: Created the side out component for Protocol creation flow */
const ViewUserSideout: React.FC<AddProtocolSideoutProps> = (props) => {
  const { isVisible, handleClose, users, type, totalUsers, notification } =
    props;
  const [value, setValue] = useState<any>('');
  const [options, setOptions] = useState<any>(users);
  const departmentItem = useSelector(
    (state: any) => state.protocol.departmentItem
  );

  const sortedUsers = useMemo(() => {
    return totalUsers
      .filter((user: any) => {
        const name = user.firstName + ' ' + user.lastName;
        return name.toLowerCase().includes(value.toLowerCase());
      })
      .sort((a, b) => {
        const inListA = options.some((user: any) => user.id === a.id);
        const inListB = options.some((user: any) => user.id === b.id);
        if (inListA && !inListB) {
          return -1;
        } else if (!inListA && inListB) {
          return 1;
        }
        return 0;
      });
  }, [totalUsers, users, value, options]);

  useEffect(() => {
    if (users) {
      setOptions(users);
    } else {
      setOptions(departmentItem.users);
    }
  }, [users, departmentItem]);

  /* 12-01-23Praveen: For filtering the protocol sub item list */
  const handleFilter = (value: any) => {
    if (value) {
      const filterData = options?.filter((item: any) =>
        item.firstName.toLowerCase().includes(value.toLowerCase())
      );
      setOptions(filterData);
    } else {
      if (users) {
        setOptions(users);
      } else {
        setOptions(departmentItem.users);
      }
    }
  };

  /* 12-01-23 Praveen: to handle the search input*/
  const handleSearch = (value: string) => {
    setValue(value);
    handleFilter(value);
  };

  const customHeader = (
    <div
      className="buttonContainer contentTitleLarge hoverText"
      onClick={handleClose}
    >
      <span className="">
        <BiArrowBack className="header-icon" style={{}} />{' '}
        {type === 'viewed' ? 'Viewed List' : ' Acknowledge List'}{' '}
      </span>
    </div>
  );

  return (
    <div>
      <Sidebar
        header={customHeader}
        visible={isVisible}
        position="right"
        onHide={() => {
          handleClose();
        }}
        style={{ width: '25%' }}
        className={'sidebarWidth'}
      >
        <div style={{ padding: '0px 15px', height: '100%' }}>
          <div className="contentText">
            {type === 'viewed'
              ? 'All the users that have viewed this notification in the OneDose application.'
              : 'All the users that have acknowledged this notification in the OneDose application.'}
          </div>
          {/* <div className="search-custom-input">
                    <span className='icon_search_pad'><FiSearch className='icon-normal' /></span>
                    <InputText value={value} onChange={handleSearch} className="search-inputs" />
                </div> */}
          <SearchBar
            value={value}
            onChange={handleSearch}
            placeholder="Search users..."
            containerStyle={{
              width: '100%',
              padding: '0px 0px 0px 0px',
              marginTop: '20px',
            }}
          />
          <div className="contentLabel" style={{ display: 'block' }}>
            <div className="contentLabel" style={{ fontSize: '15px' }}>
              {type === 'viewed'
                ? `Viewed: ${options.length}/${totalUsers.length}`
                : `Acknowledged: ${options.length}/${totalUsers.length}`}
            </div>
          </div>
          <div className="contentBorder protocolCalculationPad reviewListScroll">
            <ViewportList items={sortedUsers}>
              {(item: any, index: any) => {
                const inList = options.some((user: any) => user.id === item.id);
                const ackList =
                  type === 'viewed'
                    ? notification.viewed
                    : notification.acknowledged;
                const notifyAck = ackList.find(
                  (ack: any) => ack.userID === item.id
                );
                return (
                  <div
                    key={item + index}
                    className={`listhover spaceBetween cursorPointer item contentHeading ${totalUsers.length !== index + 1 ? 'contentUnderline' : ''}`}
                    style={{
                      color: inList ? 'black' : '#636363',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      padding: '10px 12px',
                    }}
                  >
                    <div>
                      {inList ? (
                        <FaCheckCircle
                          style={{
                            color: '#037F02',
                            marginRight: '5px',
                          }}
                        />
                      ) : (
                        <FaQuestionCircle
                          style={{
                            color: inList ? 'black' : '#636363',
                            marginRight: '5px',
                          }}
                        />
                      )}
                      {item?.firstName} {item?.lastName}
                    </div>
                    {notifyAck && (
                      <div
                        className="lightText"
                        style={{ fontSize: '12px', fontWeight: '500' }}
                      >
                        {getFormattedDateTime(notifyAck.createdAt, false)}
                      </div>
                    )}
                  </div>
                );
              }}
            </ViewportList>
          </div>
          <div className="btn_Bottom">
            <Button className="primary-button" onClick={handleClose}>
              Dismiss
            </Button>
          </div>
        </div>
      </Sidebar>
    </div>
  );
};

export default ViewUserSideout;
