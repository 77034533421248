import ProgressBar from '@asyrafhussin/react-progress-bar';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Button } from 'react-bootstrap';
import { Col, Row } from 'react-grid-system';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { FaChevronDown } from 'react-icons/fa6';
import { GoChecklist } from 'react-icons/go';
import { IoMdCheckmarkCircleOutline } from 'react-icons/io';
import { IoChevronForward, IoClose, IoSearch } from 'react-icons/io5';
import { TbBellRinging } from 'react-icons/tb';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ViewportList } from 'react-viewport-list';
import {
  getDepartmentsFromState,
  getFormattedDate,
} from '../../../_global/common/Utils';
import {
  filterOption,
  filterOptionDateRange,
} from '../../../_global/constants/Protocol_constants';
import ConfirmWarningModal from '../../../components/Modal/ConfirmWarningModal';
import ProtocolHeader from '../../protocol/ProtocolHeader';
import { useDispatch } from 'react-redux';
import { handleNotificationCache } from '../../../../store/actions';
import {
  DatabaseResponse,
  Response,
  ResponseType,
} from '../../../../data/AmplifyDB';
import { User } from '../../../../models';
import NotificationItem from '../../../../data/model/NotificationItem';
import { NotificationType } from '../../../../API';
import SearchBar from '../../../components/Search/SearchBar';
import { fetchNotifications } from '../../../../data/functions/NotificationDB';
import GeneralSelection from '../../../components/Selection/GeneralSelection';
import ReactLoading from 'react-loading';

const VISIBLE_STATUS = 0,
  EXPIRED_STATUS = 1;
/* 11-27-23 Arul: Created common Component for Notification Main Screen*/
const NotificationMainPage = (props: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const [database, setDatabase] = useState<DatabaseResponse>(
    useSelector((state: any) => state.protocol.departmentItem)
  );

  const notificationList: NotificationItem[] = useSelector(
    (state: any) => state.notification.notificationList
  );

  const [notifications, setNotificactions] = useState(notificationList || []);

  const [searchQuery, setSearchQuery] = useState('');

  const reducerState = useSelector((state: any) => state.department);
  const { subDeps, allSubDeps } = useMemo(() => {
    return getDepartmentsFromState(database.department, reducerState);
  }, [database.department, reducerState]);

  const [isSuccessModal, setIsSuccessModal] = useState(false);
  const [isFilterBtn, setIsFilterBtn] = useState(false);
  const [isDateBtn, setIsDateBtn] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<any>([]);
  const [selectedDateRange, setSelectedDateRange] = useState<any>('');
  const [progressData, setProgressData] = useState<Record<string, Progress>>(
    {}
  );
  const newButtonRef: any = useRef(null);
  const dateButtonRef: any = useRef(null);
  const [inputType, setInputType] = useState(VISIBLE_STATUS);
  const cacheUsers: User[] | null | undefined = useSelector(
    (state: any) => state.cacheUsers
  );
  const adminLevel = useMemo(() => {
    return database.department.adminLevel;
  }, [database.department]);

  const reloadNotifications = async (): Promise<boolean> => {
    /* 1-10-24 Hazlett:  Update the current data to the database change and keep the current state */
    const resp: Response = await fetchNotifications(
      database.department,
      notificationList == null || notificationList.length === 0 ? false : true,
      (resp: any) => {
        let nots = [...resp];
        setNotificactions(nots);
      }
    );
    if (resp.type === ResponseType.Success) {
      dispatch<any>(handleNotificationCache(resp.data));
      setNotificactions(resp.data);
      return true;
    } else {
      console.error('ERROR LOADING DATABASE', resp.data);
      return false;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      // const progressResults: any = {};
      let promises: Promise<Progress>[] = [];
      for (let i = 0; i < notifications.length; i++) {
        promises.push(handleGetProgress(notifications[i]));
      }
      const progressResults = await Promise.all(promises);
      setProgressData(
        progressResults.reduce((acc: any, curr: Progress, index: number) => {
          acc[notifications[index].uid] = curr;
          return acc;
        }, {})
      );
    };

    fetchData();
  }, [notifications, cacheUsers]);

  useEffect(() => {
    if (database) {
      //   const subscription = DataStore.observe(Notification).subscribe((msg) => {
      //     if (
      //       msg.opType === 'UPDATE' ||
      //       msg.opType === 'INSERT' ||
      //       msg.opType === 'DELETE'
      //     ) {
      //       reloadNotifications();
      //     }
      //   });
      reloadNotifications();
      //   return () => subscription.unsubscribe();
    }
  }, [database]);

  useEffect(() => {
    if (location?.state?.isCreate) {
      setIsSuccessModal(true);
    }
  }, [location?.state]);

  /* 11-27-23 Arul: function for create new notification*/
  const handleCreate = () => {
    navigate('/create/notification');
  };
  //onrow click to navigate view page
  const handleNotificationTitleClick = (item: any, index: any) => () => {
    const progress: Progress = (progressData as any)[item.uid] || {
      amount: 0,
      percentage: 0,
      validAckIds: [],
      validViewIds: [],
      totalUsers: [],
    };
    const value = { ...item, index: index, ...progress };
    navigate('/view/notification', { state: value });
  };

  const NotificationIcon = ({ _type }: any) => {
    let type = _type as NotificationType;
    return (
      <>
        {type == NotificationType.GENERAL && <TbBellRinging size={24} />}
        {type == NotificationType.ACKNOWLEDGE && (
          <IoMdCheckmarkCircleOutline size={24} />
        )}
        {type == NotificationType.TRAINING && <GoChecklist size={24} />}
      </>
    );
  };

  const filterData = useCallback(
    (selectedOption: any) => {
      const currentDate = new Date(); // Current date
      const parseDate = (dateStr: any) => new Date(dateStr);

      switch (selectedOption) {
        case '3 Days':
          return notifications.filter(
            (item: NotificationItem) =>
              (currentDate.getTime() - parseDate(item.timestamp).getTime()) /
                (1000 * 60 * 60 * 24) <=
              3
          );
        case '1 Week':
          return notifications.filter(
            (item: NotificationItem) =>
              (currentDate.getTime() - parseDate(item.timestamp).getTime()) /
                (1000 * 60 * 60 * 24) <=
              7
          );
        case '2 Weeks':
          return notifications.filter(
            (item: NotificationItem) =>
              (currentDate.getTime() - parseDate(item.timestamp).getTime()) /
                (1000 * 60 * 60 * 24) <=
              14
          );
        case '1 Month':
          return notifications.filter(
            (item: NotificationItem) =>
              (currentDate.getTime() - parseDate(item.timestamp).getTime()) /
                (1000 * 60 * 60 * 24) <=
              30
          );
        case '3 Months':
          return notifications.filter(
            (item: NotificationItem) =>
              (currentDate.getTime() - parseDate(item.timestamp).getTime()) /
                (1000 * 60 * 60 * 24) <=
              90
          );
        case '2023 - Dates back to the furthest date':
          const furthestDate = new Date('01/01/2023');
          return notifications.filter(
            (item: NotificationItem) =>
              parseDate(item.timestamp) >= furthestDate
          );
        default:
          return notifications;
      }
    },
    [notifications]
  );

  const handleSearchChange = (text: string) => {
    setSearchQuery(text);
    // handleFilterData(selectedOptions, selectedDateRange, text);
  };

  //progress value
  type Progress = {
    amount: number;
    percentage: number;
    validAckIds: string[];
    validViewIds: string[];
    totalUsers: string[];
  };
  const handleGetProgress = async (
    notification: NotificationItem
  ): Promise<Progress> => {
    if (!cacheUsers)
      return {
        amount: 0,
        percentage: 0,
        validAckIds: [],
        validViewIds: [],
        totalUsers: [],
      };

    let uniqueViewIds = new Set<string>();

    if (notification.viewed)
      uniqueViewIds = new Set(notification.viewed.map((view) => view.userID));
    let uniqueAckIds = new Set<string>();

    if (notification.acknowledged)
      uniqueAckIds = new Set(
        notification.acknowledged.map((ack) => ack.userID)
      );

    const allUsers = cacheUsers ?? [];
    const validViewIds = allUsers
      .filter((user) => uniqueViewIds.has(user.id))
      .map((user) => user.id);
    const validAckIds = allUsers
      .filter((user) => uniqueAckIds.has(user.id))
      .map((user) => user.id);

    let relevantIds =
      notification.type === 'GENERAL' ? validViewIds : validAckIds;

    let totalUsers: string[] = [];
    if (
      notification &&
      notification.groups &&
      notification.groups?.length > 0
    ) {
      // const groupItems = totalGroupsResponse.data;
      for (const group of notification.groups) {
        let users = group.pairedUserIDs;
        let uniqueUsers = new Set(users);
        totalUsers.push(...uniqueUsers);
      }
    } else {
      let depIDs = [notification.depID, ...(notification.pairedDepIDs ?? [])];
      const availableDepartments = [database.department, ...allSubDeps];
      for (const depID of depIDs) {
        let dep = availableDepartments.find((dep) => dep.id === depID);
        if (dep) {
          let departmentUsers = cacheUsers.filter(
            (user) =>
              user.departmentID === depID || user.pairedDepIDs?.includes(depID)
          );
          if (departmentUsers) {
            const userIds = departmentUsers.map((user: User) => user.id);
            const uniqueUsers = [...new Set(userIds)];
            totalUsers = [...new Set([...totalUsers, ...uniqueUsers])];
          }
        }
      }
    }

    let percentage =
      totalUsers.length === 0
        ? 0
        : Math.round((relevantIds.length / totalUsers.length) * 100);

    return {
      amount: relevantIds.length,
      percentage: percentage,
      validAckIds: validAckIds,
      validViewIds: validViewIds,
      totalUsers: totalUsers,
    };
  };

  // const handleFilterData = (
  //   options: any,
  //   dateRange: any,
  //   searchQuery: string
  // ) => {
  //   let filteredNotifications = notificationList ? [...notificationList] : [];

  //   // Convert selectedOptions to uppercase for case-insensitive comparison
  //   const upperCaseOptions = options.map((option: any) => option.toUpperCase());

  //   // Filter by selected notification type
  //   if (upperCaseOptions.length > 0) {
  //     filteredNotifications = filteredNotifications.filter((notification) =>
  //       upperCaseOptions.includes(notification.type.toUpperCase())
  //     );
  //   }

  //   // Filter by selected date range
  //   if (dateRange && dateRange !== '') {
  //     const filteredByDate = filterData(dateRange);
  //     filteredNotifications = filteredNotifications.filter((notification) =>
  //       filteredByDate.includes(notification)
  //     );
  //   }

  //   if (searchQuery) {
  //     filteredNotifications = filteredNotifications.filter(
  //       (notification: NotificationItem) =>
  //         notification.title.toLowerCase().includes(searchQuery.toLowerCase())
  //     );
  //   }

  //   setNotificactions(filteredNotifications);
  // };

  const handeleDateChange = (e: any) => {
    setSelectedDateRange(e);
    setIsDateBtn(false);
    // handleFilterData(selectedOptions, e, searchQuery);
  };

  /* 10-16-2023 Arul: Removing the row selection while clicking apart from the table Row*/
  useEffect(() => {
    document.addEventListener('click', handleDocumentClick);
    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, [isFilterBtn, isDateBtn]);

  /* 10-16-2023 Arul: function for clicking outside to remove selection and update rename*/
  const handleDocumentClick = (e: any) => {
    if (
      !newButtonRef?.current ||
      !newButtonRef?.current.contains(e.target as Node)
    ) {
      setIsFilterBtn(false);
    }
    if (
      !dateButtonRef?.current ||
      !dateButtonRef?.current.contains(e.target as Node)
    ) {
      setIsDateBtn(false);
    }
  };

  const handleCheckboxChange = (option: any, protocol: any) => {
    let newSelectedOptions = selectedOptions.includes(option)
      ? selectedOptions.filter((item: any) => item !== option)
      : [...selectedOptions, option];

    setSelectedOptions(newSelectedOptions);
    // handleFilterData(newSelectedOptions, selectedDateRange, searchQuery);
  };

  // useEffect(() => {
  //   handleFilterData(selectedOptions, selectedDateRange, searchQuery);
  // }, [selectedOptions, selectedDateRange, searchQuery, notificationList]);

  const filteredNotifications = useMemo(() => {
    let nots = [...notificationList];

    nots = nots.filter((item) =>
      inputType === VISIBLE_STATUS
        ? item.expirationTimestamp >= new Date()
        : item.expirationTimestamp < new Date()
    );

    if (selectedOptions.length > 0) {
      nots = nots.filter((item) =>
        selectedOptions
          .map((option: any) => option.toUpperCase())
          .includes(item.type.toUpperCase())
      );
    }
    if (selectedDateRange) {
      nots = nots.filter((item) =>
        filterData(selectedDateRange).includes(item)
      );
    }
    if (searchQuery) {
      nots = nots.filter((item) =>
        item.title.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    return nots;
  }, [
    inputType,
    selectedOptions,
    selectedDateRange,
    searchQuery,
    notificationList,
  ]);

  const renderNotification = useCallback(
    (item: NotificationItem, index: number) => {
      const progress: Progress = progressData[item.uid] || {
        amount: 0,
        percentage: 0,
        validAckIds: [],
        validViewIds: [],
        totalUsers: [],
      };

      const notificationUser = (cacheUsers ?? []).find((user) =>
        item.dbNotification.modifiedBy
          ? item.dbNotification.modifiedBy === user.id
          : item.dbNotification.createdBy === user.id
      );

      return (
        <div
          data-testid={`viewNotification-${index}`}
          key={index}
          className={`cursorPointer hoverItem`}
          style={{ borderRadius: '10px' }}
          onClick={handleNotificationTitleClick(item, index)}
        >
          <div
            style={{
              padding: '2.5px 10px',
              width: '100%',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {/* Main Notification UI */}
            <div
              style={{
                display: 'flex',
                marginLeft: '10px',
                width: '70%',
                alignItems: 'center',
              }}
            >
              <NotificationIcon _type={item.type} />
              <span className={'a1Content'} style={{ width: '100%' }}>
                {/* Notification Timestamp */}
                <div
                  className="notification-css-grey"
                  style={{ fontSize: '14px' }}
                >
                  {getFormattedDate(item.timestamp, false)}
                </div>

                {/* Notification Title */}

                <div className="contentTitle">
                  {item.title}
                  {notificationUser && (
                    <div className="notification-css-modifiedBy">
                      {notificationUser.firstName +
                        ' ' +
                        notificationUser.lastName}
                    </div>
                  )}
                </div>

                {/* Notification Message */}
                <div
                  style={{
                    justifyContent: 'flex-start',
                    fontSize: '14px',
                    color: '#212121',
                  }}
                  className="notification-css-grey"
                >
                  {item.message}
                </div>
              </span>
            </div>

            {/* Progress bar title */}
            <div
              className="lightText"
              style={{
                width: '10%',
                marginBottom: '5px',
                fontWeight: '400',
                justifyContent: 'flex-end',
                marginRight: '5px',
              }}
            >
              <div>{item.type === 'GENERAL' ? 'Viewed' : 'Acknowledge'}</div>
            </div>

            {/* Progress Bar */}
            <div style={{ width: '15%', marginTop: '-30px' }}>
              {progress.totalUsers.length > 0 ? (
                <div
                  className="lightText"
                  style={{ justifyContent: 'center', color: '#000' }}
                >
                  {progress.percentage}% ({progress.amount} /{' '}
                  {progress.totalUsers.length})
                </div>
              ) : (
                <div
                  className="lightText"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '6px',
                  }}
                >
                  <span
                    style={{
                      fontSize: '12px',
                      fontWeight: '500',
                      color: '#939393',
                    }}
                  >
                    Loading Stats
                  </span>
                  <ReactLoading
                    type="bubbles"
                    color="#939393"
                    height={20}
                    width={20}
                  />
                </div>
              )}
              <ProgressBar
                baseBgColor={'#D0D0D0'}
                completed={progress.percentage}
                height={'10px'}
                width={'100%'}
                bgColor={'#00534C'}
                isLabelVisible={false}
              />
            </div>

            {/* Arrow Icon */}
            <div className="" style={{ position: 'absolute', right: '30px' }}>
              <IoChevronForward size={20} style={{ marginRight: '5px' }} />
            </div>
          </div>

          {/* Bottom border */}
          {index !== notifications.length - 1 && (
            <div
              style={{
                width: '100%',
                height: '1px',
                background: '#E0E0E0',
              }}
            ></div>
          )}
        </div>
      );
    },
    [progressData, handleNotificationTitleClick]
  );

  return (
    <div className="screen-container">
      <ConfirmWarningModal
        isVisible={isSuccessModal}
        modalType={'success'}
        handleClose={() => {
          setIsSuccessModal(false);
        }}
        handleDelete={() => {
          setIsSuccessModal(false);
        }}
      />
      <ProtocolHeader
        homeScreen={true}
        type={'protocol'}
        page={'Notifications'}
        // description={'Manage all notifications'}
        isCreateButton={adminLevel > 2}
        isCreateActive={true}
        handleCreate={handleCreate}
      />

      <div>
        <div>
          <div>
            <Row>
              <Col sm={7}>
                <SearchBar
                  containerStyle={{ width: '85%' }}
                  value={searchQuery}
                  onChange={(searchTerm: string) => {
                    handleSearchChange(searchTerm);
                  }}
                  onSubmit={(searchTerm: string) => {}}
                  placeholder={'Search Notifications...'}
                />
              </Col>
              <Col sm={3}>
                <div style={{ width: '80%' }}>
                  <GeneralSelection
                    items={['Visible', 'Expired']}
                    selectedIndex={inputType}
                    onPress={(item, index) => {
                      setInputType(index);
                    }}
                    labelField={(option) => option}
                    valueField={(option) => option}
                    badgeField={(option) =>
                      option === 'Visible'
                        ? notifications
                            .filter(
                              (item) => item.expirationTimestamp >= new Date()
                            )
                            .length.toString()
                        : notifications
                            .filter(
                              (item) => item.expirationTimestamp < new Date()
                            )
                            .length.toString()
                    }
                    containerStyle={{
                      marginTop: '2px',
                      padding: '0px 10px',
                    }}
                  />
                </div>
              </Col>
              <Col sm={2}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginTop: '-12px',
                  }}
                >
                  <div
                    ref={dateButtonRef}
                    style={{ display: 'flex', justifyContent: 'end' }}
                  >
                    <div>
                      <Button
                        onClick={() => {
                          setIsDateBtn(!isDateBtn);
                          setIsFilterBtn(false);
                        }}
                        style={{ width: 'auto', minWidth: '100px' }}
                        className="filtBtn filtCloseIcon secondary-button-small-border btn-rightMargin"
                      >
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-evenly',
                            padding: '0px 10px',
                          }}
                        >
                          <span>Date Range:</span>
                          {selectedDateRange && (
                            <div className="filtCloseIcon">
                              {selectedDateRange}
                              <span className="filtCloseIcon close_icon_color">
                                <AiOutlineCloseCircle
                                  onClick={() => {
                                    setSelectedDateRange('');
                                    setIsDateBtn(false);
                                    // handleFilterData(
                                    //   selectedOptions,
                                    //   '',
                                    //   searchQuery
                                    // );
                                  }}
                                  className="cursorPointer filtCloseIcon pairedProtocol-cancel-icon"
                                />
                              </span>
                            </div>
                          )}
                        </div>
                      </Button>
                      {isDateBtn && (
                        <div className="subBtnBorder">
                          <ViewportList items={filterOptionDateRange}>
                            {(item: any, index: any) => (
                              <div
                                key={index}
                                onClick={() => {
                                  handeleDateChange(item.value);
                                }}
                                className={`radioBtnSelectedColor listhover cursorPointer item contentHeading ${
                                  selectedDateRange === item.value
                                    ? 'list_column_selected_Checklist'
                                    : 'column_unselected1'
                                } ${
                                  filterOptionDateRange.length !== index + 1
                                    ? 'contentUnderline'
                                    : ''
                                }`}
                              >
                                <div className="radioBtnSelectedColor">
                                  {item.value}
                                </div>
                              </div>
                            )}
                          </ViewportList>
                        </div>
                      )}
                    </div>
                  </div>
                  <div
                    ref={newButtonRef}
                    style={{ display: 'flex', justifyContent: 'end' }}
                  >
                    <div>
                      <Button
                        onClick={() => {
                          setIsFilterBtn(!isFilterBtn);
                          setIsDateBtn(false);
                        }}
                        style={{ width: 'auto', minWidth: '100px' }}
                        className="filtBtn filtCloseIcon secondary-button-small-border btn-rightMargin"
                      >
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-evenly',
                            padding: '0px 10px',
                          }}
                        >
                          <span>Filter </span>
                          {selectedOptions?.length >= 1 && (
                            <div style={{ marginLeft: '5px' }}>
                              ( {selectedOptions?.length} )
                              <span className="close_icon_color">
                                <AiOutlineCloseCircle
                                  onClick={() => {
                                    setSelectedOptions([]);
                                    // handleFilterData(
                                    //   [],
                                    //   selectedDateRange,
                                    //   searchQuery
                                    // );
                                  }}
                                  className="cursorPointer filtCloseIcon pairedProtocol-cancel-icon"
                                />
                              </span>
                            </div>
                          )}
                          <span>
                            <FaChevronDown
                              className="icon-normal"
                              size="1rem"
                            />
                          </span>
                        </div>
                      </Button>
                      {isFilterBtn && (
                        <div className="subBtnBorder" style={{ right: '15px' }}>
                          <ViewportList items={filterOption}>
                            {(item: any, index: any) => (
                              <div
                                key={index}
                                onClick={() => {
                                  handleCheckboxChange(item.name, item);
                                }}
                                className={`radioBtnSelectedColor listhover cursorPointer item contentHeading ${
                                  selectedOptions.includes(item.name)
                                    ? 'list_column_selected_Checklist'
                                    : 'column_unselected1'
                                } ${
                                  filterOption.length !== index + 1
                                    ? 'contentUnderline'
                                    : ''
                                }`}
                              >
                                <input
                                  id="pairedProtocol"
                                  type="checkbox"
                                  className="custom-radio-button-input"
                                  value={item.name}
                                  checked={selectedOptions.includes(item.name)}
                                />
                                {item.name}
                              </div>
                            )}
                          </ViewportList>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>

          <div className="contentBorder" style={{ height: '100%' }}>
            <ViewportList items={filteredNotifications}>
              {(item: NotificationItem, index: number) =>
                renderNotification(item, index)
              }
            </ViewportList>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationMainPage;
