import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import MedicationItem, {
  getConcentrationsArrayString,
} from '../../../../data/model/MedicationItem';
import ProtocolHeader from '../../protocol/ProtocolHeader';
import { FaChevronRight, FaLock } from 'react-icons/fa6';
import { ProgressStatus } from '../../../../API';
import {
  compareVersions,
  getActiveID,
  getFormattedDate,
  globals,
  linkSubItemsToItem,
  updateArchiveItemsCreatedAt,
  upgradeVersion,
} from '../../../_global/common/Utils';
import { Medication, MedicationDose, User } from '../../../../models';
import Status from '../../../components/ProgressStatus/ProgressStatus';
import {
  MedicationDoseJSON,
  createMedicationDoseItem,
  deleteMedicationDoseItem,
} from '../../../../data/functions/MedicationDB';
import MedicationSubItem, {
  getRoutesString,
} from '../../../../data/model/MedicationSubItem';
import { ResponseType } from '../../../../data/AmplifyDB';
import {
  ArchiveItem,
  reloadModelItem,
} from '../../../../data/functions/ModelDB';
import SubItemRestoreSideout from '../../../components/SideOut/doseSideOut/SubItemRestoreSideout';
import PatientAge from '../../../_global/common/PatientAge';
import { useSelector } from 'react-redux';
import { Alert, Snackbar } from '@mui/material';
import ConfirmModal from '../../../components/Modal/ConfirmModal';
import DataList, { Header } from '../../database/lists/DataList';
import CPRItem from '../../../../data/model/CPRItem';

const DEFAULT_USERNAME = 'Hinckley Medical';

/* 12-31-2024 Gagan: Added this file to list the medications items in the archive */
const ListArchiveMedicationsDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;

  if (
    !state?.data ||
    !state?.department ||
    !state?.database ||
    !state?.archiveData ||
    !state?.activeItem ||
    !state?.tab
  ) {
    navigate('/archive');
  }

  const {
    data: sourceData,
    subData,
    department,
    database,
    archiveData: medications,
    activeItem,
    tab = 'Medications',
  } = state;

  const user: User = useSelector((state: any) => state?.user);

  const [activeParmMedication, setActiveParmMedication] = useState<
    MedicationItem | MedicationSubItem
  >(activeItem);

  const [medicationList, setMedicationList] = useState<
    MedicationItem[] | MedicationSubItem[]
  >(
    medications.sort(
      (
        a: MedicationItem | MedicationSubItem,
        b: MedicationItem | MedicationSubItem
      ) => compareVersions(b.version, a.version)
    )
  );

  const [selectedDose, setSelectedDose] = useState<MedicationSubItem | null>(
    null
  );
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [subArchiveData, setSubArchiveData] = useState<ArchiveItem[]>(subData);

  const [snackbar, setSnackbar] = useState<any>({
    open: false,
    message: '',
    severity: 'success',
  });

  const isDraft = useMemo(() => {
    return activeParmMedication.status === ProgressStatus.DRAFT;
  }, [activeParmMedication]);

  const [modal, setModal] = useState({
    isVisible: false,
    title: '',
    primaryDescription: '',
    primaryBtnName: '',
    secondaryBtnName: '',
    handleClose: () => {},
    handleSubmit: () => {},
  });

  const reloadItem = async () => {
    if (
      activeParmMedication instanceof MedicationSubItem ||
      activeParmMedication.TAG === 'MedicationSubItem'
    ) {
      const response = await reloadModelItem(
        MedicationDose,
        activeParmMedication
      );
      if (response.type === ResponseType.Success) {
        const cur = activeParmMedication as MedicationSubItem;
        const item = new MedicationSubItem(
          cur.parent,
          cur.parentCPR || cur.parentProtocol,
          response.data.item as MedicationDose
        );
        const activeItem = response.data.activeItem as
          | MedicationDose
          | null
          | undefined;
        item.activeItem = activeItem
          ? new MedicationSubItem(
              cur.parent,
              cur.parentCPR || cur.parentProtocol,
              activeItem as MedicationDose
            )
          : null;
        setActiveParmMedication(item);
      }
    } else {
      const response = await reloadModelItem(Medication, activeParmMedication);
      if (response.type === ResponseType.Success) {
        const item = new MedicationItem(response.data.item as Medication);
        const activeItem = response.data.activeItem as
          | Medication
          | null
          | undefined;
        item.activeItem = activeItem ? new MedicationItem(activeItem) : null;
        item.subItems = activeParmMedication.subItems;
        setActiveParmMedication(item);
      }
    }
  };

  /**
   * Check if the draft exists for the active item
   */
  useEffect(() => {
    reloadItem();
  }, []);

  const handleItemClick = useCallback(
    (medication: MedicationItem | MedicationSubItem) => {
      if (tab === 'Medications') {
        navigate(`/archive/medication-archive`, {
          state: {
            value: medication,
            sourceData: sourceData,
            subData: subArchiveData,
            archiveData: medications,
            department: department,
            database: database,
            activeItem: activeParmMedication,
          },
        });
      } else {
        setSelectedDose(medication as MedicationSubItem);
        setSidebarVisible(true);
      }
    },
    [
      navigate,
      sourceData,
      medications,
      department,
      database,
      activeParmMedication,
      subArchiveData,
      tab,
    ]
  );

  /*function to close the modal*/
  const handleCloseModal = () => {
    setSidebarVisible(false);
    setSelectedDose(null);
  };

  const handleBack = useCallback(() => {
    navigate(`/archive/list-medications`, {
      state: {
        data: sourceData,
        subData: subArchiveData,
        department: department,
        database: database,
        tab: tab,
      },
    });
  }, [navigate, sourceData, department, database, subArchiveData, tab]);

  const handleDoseRestore = async () => {
    setModal({ ...modal, isVisible: false });
    try {
      const isDraft = activeParmMedication.status === ProgressStatus.DRAFT;
      const oldActiveItem = isDraft
        ? activeParmMedication.activeItem
        : activeParmMedication;

      if (isDraft) {
        // Delete the existing draft
        const response = await deleteMedicationDoseItem(
          activeParmMedication as MedicationSubItem,
          false
        );

        if (response.type === ResponseType.Failure) {
          setSnackbar({
            open: true,
            message: 'Failed to delete already existing draft.',
            severity: 'error',
          });
          // Exit the function
          setSidebarVisible(false);
          setSelectedDose(null);
          return;
        }
      }

      const protocol = (activeParmMedication as MedicationSubItem)
        ?.parentProtocol;
      const cpr = database.cprModel;
      let protocolID = getActiveID(protocol);

      if (
        !protocolID &&
        (activeParmMedication as MedicationSubItem).cprAssistID === null
      ) {
        console.error('Failed to find protocol ID or CPR Assist ID');
        return;
      }

      /* Create a new MedicationDose for the medicaiton */
      let prevDose = activeParmMedication as MedicationSubItem;
      const isOwner = prevDose ? department.id === prevDose.departmentID : true;
      let index = prevDose ? prevDose.index : protocol.medications.length;
      if (department.parentDep?.parentDep) index += 10000;
      else if (department.parentDep) index += 1000;

      let newMedDose: MedicationDoseJSON = {
        departmentID: department.id,
        medication: (oldActiveItem as MedicationSubItem).parent,
        protocol: (oldActiveItem as MedicationSubItem).protocolID
          ? protocol
          : undefined,
        cpr: (oldActiveItem as MedicationSubItem).cprAssistID
          ? (cpr as CPRItem)
          : undefined,
        rangeLow: selectedDose?.model?.rangeLow
          ? Number(selectedDose.model?.rangeLow)
          : 0,
        rangeHigh: selectedDose?.model?.rangeHigh
          ? Number(selectedDose.model?.rangeHigh)
          : globals.MAX_VALUE,
        createdBy: (selectedDose?.model?.createdBy || user.id) as string,
        basis: (selectedDose?.model?.basis || '').toString(),
        route: selectedDose?.routes || [],
        title: selectedDose?.model?.warning
          ? undefined
          : selectedDose?.model?.title?.toString(),
        nemsisRoutes: selectedDose?.nemsisRoutes,
        warning: selectedDose?.model?.warning
          ? selectedDose?.model?.warning
          : undefined,
        instruction: selectedDose?.model?.instruction
          ? selectedDose?.model?.instruction
          : undefined,
        note: selectedDose?.model?.note ? selectedDose?.model?.note : undefined,
        maxDose: selectedDose?.model?.maxDose,
        minDose: selectedDose?.model?.minDose,
        maxTotalDose: selectedDose?.model?.maxTotalDose,
        calcMax: selectedDose?.model?.calcMax,
        calcMin: selectedDose?.model?.calcMin,
        index: index,
        ageLow: selectedDose?.ageLow
          ? (selectedDose.ageLow as PatientAge).ageValue
          : 0,
        ageHigh: selectedDose?.ageHigh
          ? (selectedDose.ageHigh as PatientAge).ageValue
          : globals.MAX_VALUE,
        ageGroup: selectedDose?.ageGroup,
        repeatTime: (selectedDose?.model?.repeatTime || 0) as number,

        activeID: oldActiveItem?.uid,

        overrideID: isOwner ? null : oldActiveItem?.uid,
        status: ProgressStatus.DRAFT,
        version: upgradeVersion(oldActiveItem?.version || 'v1.0.0'),
        createdAt: oldActiveItem?.model?.createdAt
          ? new Date(oldActiveItem.model.createdAt)
          : new Date(),
      };

      let results = await createMedicationDoseItem(
        newMedDose,
        oldActiveItem as MedicationSubItem
      );

      if (results.type === ResponseType.Success) {
        const newDraftMedication = results.data as MedicationSubItem;
        newDraftMedication.activeItem = oldActiveItem as MedicationSubItem;

        setSnackbar({
          open: true,
          message:
            'Successfully Restored this medication dose with a new DRAFT version ' +
            newDraftMedication.version,
          severity: 'success',
        });

        // Update subData with new draft
        const newSubData = subArchiveData.map((item: ArchiveItem) =>
          item.activeItem?.uid === activeParmMedication.uid
            ? { ...item, activeItem: newDraftMedication }
            : item
        );
        setSubArchiveData(newSubData);
        setActiveParmMedication(newDraftMedication);

        if (globals.debug)
          console.log('Successfully restored medication dose', results.data);

        setModal({
          isVisible: true,
          title: 'Successfully Restored Medication Dose',
          primaryDescription: `Successfully restored ${newDraftMedication.name} to version ${newDraftMedication.version}. Would you like to navigate to the medication dose?`,
          primaryBtnName: 'Dismiss',
          secondaryBtnName: 'Navigate',
          handleClose: () => {
            setModal({ ...modal, isVisible: false });
          },
          handleSubmit: () => {
            if (newDraftMedication.cprAssistID !== null) {
              navigate(`/database/cpr-assist`, {
                state: {
                  selectedDose: newDraftMedication,
                },
              });
            } else if (newDraftMedication.protocolID !== null) {
              const state = {
                selectedProtocol: protocol,
                editMode: false,
                backPage: 'list-protocols',
                search: { searchQuery: '', filters: [], categoriesFilter: [] },
                selectedDose: newDraftMedication,
              };
              const elecFormattedString = protocol.nickname.replace(
                /[\s/]/g,
                '-'
              );
              navigate(`/${elecFormattedString}/protocol-detail`, { state });
            }
          },
        });
      } else if (results.type === ResponseType.Failure) {
        setSnackbar({
          open: true,
          message: 'Failed to restore Medication Dose.',
          severity: 'error',
        });
      }

      setSidebarVisible(false);
      setSelectedDose(null);
    } catch (error) {
      console.error('Failed to restore medication dose', error);
    }
  };

  useEffect(() => {
    const mapMedicationDoses = async () => {
      try {
        const activeMedicationID =
          activeParmMedication.status === ProgressStatus.DRAFT
            ? activeParmMedication.activeItem?.uid
            : activeParmMedication.uid;

        // Fetch all the active and its archived medication doses
        const medicationDoses = subArchiveData.filter((item: ArchiveItem) =>
          (item.activeItem as MedicationSubItem).parent.activeID
            ? (item.activeItem as MedicationSubItem).parent.activeID ===
              activeMedicationID
            : (item.activeItem as MedicationSubItem).parent.uid ===
              activeMedicationID
        );

        // Update the createdAt field for each of the items in the medicationDoses
        const processedData = updateArchiveItemsCreatedAt(
          medicationDoses
        ) as ArchiveItem[];

        setMedicationList((prev: MedicationItem[] | MedicationSubItem[]) =>
          (prev as MedicationItem[])
            .map((medItem: MedicationItem) => {
              const tempSubItems = linkSubItemsToItem(medItem, processedData);
              medItem.subItems = tempSubItems as MedicationSubItem[];
              return medItem;
            })
            .sort((a: MedicationItem, b: MedicationItem) =>
              compareVersions(b.version, a.version)
            )
        );
      } catch (err) {
        console.error('Error fetching medication doses:', err);
      }
    };

    // Perform this mapping only if the tab is Medications
    if (tab === 'Medications') {
      mapMedicationDoses();
    }
  }, [
    medications,
    activeParmMedication,
    database,
    department,
    activeItem,
    tab,
    subArchiveData,
  ]);

  /* Hotkey detection */
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.ctrlKey && event.shiftKey && event.key === 'O') {
        console.log('Active Medication', activeParmMedication);
        console.log('Archive Medications', medications);
        console.log('Medication List', medicationList);
        console.log('Tab', tab);
      }
    };

    // Add event listener
    document.addEventListener('keydown', handleKeyDown);

    // Remove event listener on cleanup
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [activeParmMedication, medications, medicationList, tab]);

  const handleSortItem = useCallback(
    (
      key: string,
      direction: string,
      a: MedicationItem | MedicationSubItem,
      b: MedicationItem | MedicationSubItem
    ) => {
      if (key === 'version') {
        return direction === 'asc'
          ? a.version.localeCompare(b.version)
          : b.version.localeCompare(a.version);
      } else if (key === 'name') {
        return direction === 'asc'
          ? a.name.localeCompare(b.name)
          : b.name.localeCompare(a.name);
      } else if (key === 'archived_date') {
        const aDate = new Date(a.modifiedAt || Date.now());
        const bDate = new Date(b.modifiedAt || Date.now());
        return direction === 'asc'
          ? aDate.getTime() - bDate.getTime()
          : bDate.getTime() - aDate.getTime();
      } else if (key === 'archived_by') {
        const aUserId = a.model?.modifiedBy || a.model?.createdBy;
        const bUserId = b.model?.modifiedBy || b.model?.createdBy;

        const aUser = aUserId
          ? database.users.find((user: User) => user.id === aUserId)
          : null;
        const bUser = bUserId
          ? database.users.find((user: User) => user.id === bUserId)
          : null;

        const aName = aUser
          ? `${aUser.firstName} ${aUser.lastName}`
          : DEFAULT_USERNAME;
        const bName = bUser
          ? `${bUser.firstName} ${bUser.lastName}`
          : DEFAULT_USERNAME;

        return direction === 'asc'
          ? aName.localeCompare(bName)
          : bName.localeCompare(aName);
      } else if (key === 'doses' && tab === 'Medications') {
        return direction === 'asc'
          ? (a as MedicationItem).subItems.length -
              (b as MedicationItem).subItems.length
          : (b as MedicationItem).subItems.length -
              (a as MedicationItem).subItems.length;
      }
      return 0;
    },
    [database.users, tab]
  );

  const medicationHeaders: Header<MedicationItem | MedicationSubItem>[] =
    useMemo(
      () => [
        {
          key: 'version',
          name: 'Version',
          sortable: true,
          flex: 1,
          render: (item: MedicationItem | MedicationSubItem) => (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {item.version}
            </div>
          ),
        },

        {
          key: 'name',
          name: 'Name',
          sortable: true,
          flex: 3,
          render: (item: MedicationItem | MedicationSubItem) => (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {!(
                item.departmentID === department.id &&
                item.status !== 'DEACTIVATED'
              ) && (
                <div style={{ marginRight: '8px' }}>
                  <FaLock className="table-icon" color="#A3A3A3" />
                </div>
              )}
              {item.name}
              {tab === 'Medications' && (
                <Status status={item.status} style={{ marginLeft: '8px' }} />
              )}
            </div>
          ),
        },

        {
          key: 'concentration',
          name: 'Concentration',
          sortable: true,
          flex: 2,
          render: (item: MedicationItem | MedicationSubItem) =>
            'concentrations' in item
              ? getConcentrationsArrayString(item.concentrations)
              : '',
        },

        {
          key: 'archived_date',
          name: 'Archived Date',
          sortable: true,
          flex: 2,
          render: (item: MedicationItem | MedicationSubItem) =>
            getFormattedDate(new Date(item.modifiedAt || Date.now()), true),
        },
        {
          key: 'archived_by',
          name: 'Archived By',
          sortable: true,
          flex: 2,
          render: (item: MedicationItem | MedicationSubItem) => {
            const userId = item.model?.modifiedBy || item.model?.createdBy;
            const user = userId
              ? database.users.find((user: User) => user.id === userId)
              : null;
            const username = user
              ? `${user.firstName} ${user.lastName}`
              : DEFAULT_USERNAME;

            return (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%',
                  paddingRight: '15px',
                }}
              >
                <span>{username}</span>
                {tab === 'Doses' && (
                  <FaChevronRight
                    className="icon-normal"
                    style={{ margin: '4px' }}
                  />
                )}
              </div>
            );
          },
        },

        {
          key: 'doses',
          name: 'Doses',
          sortable: true,
          flex: 1,
          render: (item: MedicationItem | MedicationSubItem) => (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
                paddingRight: '15px',
              }}
            >
              <span>{(item as MedicationItem).subItems?.length || 0}</span>
              <FaChevronRight
                className="icon-normal"
                style={{ margin: '4px' }}
              />
            </div>
          ),
        },
      ],
      [tab, department.id, database.users]
    );

  const doseHeaders: Header<MedicationItem | MedicationSubItem>[] = useMemo(
    () => [
      {
        key: 'version',
        name: 'Version',
        sortable: true,
        flex: 1,
        render: (item: MedicationItem | MedicationSubItem) => (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {item.version}
          </div>
        ),
      },

      {
        key: 'status',
        name: 'Status',
        sortable: true,
        flex: 1,
        render: (item: MedicationItem | MedicationSubItem) => (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Status status={item.status} />
          </div>
        ),
      },
      {
        key: 'name',
        name: 'Name',
        sortable: true,
        flex: 3,
        render: (item: MedicationItem | MedicationSubItem) => (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {!(
              item.departmentID === department.id &&
              item.status !== 'DEACTIVATED'
            ) && (
              <div style={{ marginRight: '8px' }}>
                <FaLock className="table-icon" color="#A3A3A3" />
              </div>
            )}
            {item.name}
            {tab === 'Medications' && (
              <Status status={item.status} style={{ marginLeft: '8px' }} />
            )}
          </div>
        ),
      },
      {
        key: 'protocol_name',
        name: 'Protocol Name',
        sortable: true,
        flex: 2,
        render: (item: MedicationItem | MedicationSubItem) =>
          (item as MedicationSubItem).parentCPR != null
            ? 'CPR Assist'
            : (item as MedicationSubItem).parentProtocol.name,
      },
      {
        key: 'full_basis',
        name: 'Full Basis',
        sortable: false,
        flex: 1,
        render: (item: MedicationItem | MedicationSubItem) =>
          (item as MedicationSubItem).fullBasis,
      },
      {
        key: 'routes',
        name: 'Routes',
        sortable: false,
        flex: 1,
        render: (item: MedicationItem | MedicationSubItem) =>
          getRoutesString(item as MedicationSubItem),
      },

      {
        key: 'archived_date',
        name: 'Archived Date',
        sortable: true,
        flex: 2,
        render: (item: MedicationItem | MedicationSubItem) =>
          getFormattedDate(new Date(item.modifiedAt || Date.now()), true),
      },
      {
        key: 'archived_by',
        name: 'Archived By',
        sortable: true,
        flex: 2,
        render: (item: MedicationItem | MedicationSubItem) => {
          const userId = item.model?.modifiedBy || item.model?.createdBy;
          const user = userId
            ? database.users.find((user: User) => user.id === userId)
            : null;
          const username = user
            ? `${user.firstName} ${user.lastName}`
            : DEFAULT_USERNAME;

          return (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
                paddingRight: '15px',
              }}
            >
              <span>{username}</span>
              {tab === 'Doses' && (
                <FaChevronRight
                  className="icon-normal"
                  style={{ margin: '4px' }}
                />
              )}
            </div>
          );
        },
      },
    ],
    [tab, department.id, database.users]
  );

  const handleRestorePressed = async () => {
    if (isDraft) {
      setModal({
        isVisible: true,
        title: 'Override Existing Draft?',
        primaryDescription: `There is already a draft of ${activeParmMedication.name} (${activeParmMedication.version}) which is not yet published. Are you sure you want to override the existing draft and restore ${selectedDose?.name} of version ${selectedDose?.version}?`,
        primaryBtnName: 'Cancel',
        secondaryBtnName: 'Restore',
        handleClose: () => {
          setModal({ ...modal, isVisible: false });
        },
        handleSubmit: handleDoseRestore,
      });
    } else {
      setModal({
        isVisible: true,
        title: 'Restore Medication Dose?',
        primaryDescription: `Are you sure you want to restore ${selectedDose?.name} of version ${selectedDose?.version}? This will create a new draft version of the medication dose.`,
        primaryBtnName: 'Cancel',
        secondaryBtnName: 'Restore',
        handleClose: () => {
          setModal({ ...modal, isVisible: false });
        },
        handleSubmit: handleDoseRestore,
      });
    }
  };

  return (
    <div className="screen-container">
      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>

      <ConfirmModal
        isVisible={modal.isVisible}
        title={modal.title}
        primaryDescription={modal.primaryDescription}
        primaryBtnName={modal.primaryBtnName}
        secondaryBtnName={modal.secondaryBtnName}
        handleClose={modal.handleClose}
        handleSubmit={modal.handleSubmit}
      />

      <SubItemRestoreSideout
        dose={selectedDose ? selectedDose : undefined}
        type="medication"
        visible={sidebarVisible}
        setVisible={handleCloseModal}
        onRestore={handleRestorePressed}
      />

      <ProtocolHeader
        isBackButton={true}
        handleCancel={handleBack}
        name={`${activeParmMedication.activeItem?.name || activeParmMedication.name}: ${medicationList.length - 1} archive${medicationList.length - 1 === 1 ? '' : 's'}`}
        page={`${tab === 'Medications' ? 'Medication' : 'Medication Doses'} Archives`}
        type="protocol"
      />

      <DataList<MedicationItem | MedicationSubItem, never>
        items={medicationList}
        headers={tab === 'Medications' ? medicationHeaders : doseHeaders}
        onItemClick={handleItemClick}
        searchPlaceholder={`Search ${tab} Archives...`}
        sortItem={handleSortItem}
      />
    </div>
  );
};

export default ListArchiveMedicationsDetails;
