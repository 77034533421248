/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getDepartment = /* GraphQL */ `query GetDepartment($id: ID!) {
  getDepartment(id: $id) {
    id
    Categories {
      items {
        id
        name
        departmentID
        department {
          id
          name
          location
          protocolVersions
          shiftTypes
          cognitoID
          logoID
          userID
          neonateCutoff
          pediatricCutoff
          calculators
          softwarePlan
          subDepIDs
          parentDepID
          indexedParentDepID
          activeStatus
          agencyNumEMS
          stateIdEMS
          gnisCodeEMS
          uniqueCode
          uniquePublicURL
          hashedPin
          saltedPin
          keychainID
          isPublic
          infusionCalculation
          isPublicSignup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          departmentRequireSyncId
          __typename
        }
        index
        pairedDepIDs
        parentCategoryID
        activeID
        status
        version
        createdBy
        modifiedBy
        isPublic
        isRestrictive
        color
        tags
        overrideID
        keychainID
        Protocols {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        departmentCategoriesId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    OneWeights {
      items {
        id
        name
        serialNumber
        projectNumber
        lastCalibration
        type
        departmentID
        department {
          id
          name
          location
          protocolVersions
          shiftTypes
          cognitoID
          logoID
          userID
          neonateCutoff
          pediatricCutoff
          calculators
          softwarePlan
          subDepIDs
          parentDepID
          indexedParentDepID
          activeStatus
          agencyNumEMS
          stateIdEMS
          gnisCodeEMS
          uniqueCode
          uniquePublicURL
          hashedPin
          saltedPin
          keychainID
          isPublic
          infusionCalculation
          isPublicSignup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          departmentRequireSyncId
          __typename
        }
        Logs {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        departmentOneWeightsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    name
    location
    protocolVersions
    shiftTypes
    Medications {
      items {
        id
        name
        rxNormCode
        contraindication
        note
        warning
        route
        nemsisRoutes {
          route
          code
          __typename
        }
        concentration {
          firstAmnt
          firstUnit
          secAmnt
          secUnit
          color
          __typename
        }
        medClass
        action
        indication
        interaction
        onset
        duration
        departmentID
        protocolOptions {
          protocolID
          __typename
        }
        rangeLow
        rangeHigh
        maxDose
        minDose
        activeID
        overrideID
        status
        version
        createdBy
        modifiedBy
        metaData {
          parentID
          fullName
          nickName
          brandNames
          maxDose
          minDose
          absMaxDose
          absMinDose
          releaseNotes
          __typename
        }
        MedicationDoses {
          nextToken
          startedAt
          __typename
        }
        Concentrations {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Equipment {
      items {
        id
        name
        snomedCode
        optionItems {
          id
          amnt
          amntHigh
          rangeLow
          rangeHigh
          ageLow
          ageHigh
          index
          __typename
        }
        departmentID
        title
        note
        warning
        instruction
        activeID
        overrideID
        status
        version
        createdBy
        modifiedBy
        metaData {
          parentID
          fullName
          nickName
          brandNames
          maxDose
          minDose
          absMaxDose
          absMinDose
          releaseNotes
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Forms {
      items {
        id
        name
        departmentID
        items {
          id
          title
          isUrgent
          isDouble
          index
          __typename
        }
        activeID
        overrideID
        status
        version
        createdBy
        modifiedBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Vitals {
      items {
        id
        title
        options {
          id
          amntLow
          amntHigh
          rangeLow
          rangeHigh
          ageLow
          ageHigh
          index
          __typename
        }
        index
        departmentID
        activeID
        overrideID
        status
        version
        createdBy
        modifiedBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Electricals {
      items {
        id
        title
        options {
          title
          __typename
        }
        departmentID
        activeID
        status
        version
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    ElectricalShocks {
      items {
        id
        title
        rxNormCode
        options {
          protocolID
          __typename
        }
        warning
        instruction
        note
        rangeHigh
        rangeLow
        departmentID
        activeID
        overrideID
        status
        version
        createdBy
        modifiedBy
        metaData {
          parentID
          fullName
          nickName
          brandNames
          maxDose
          minDose
          absMaxDose
          absMinDose
          releaseNotes
          __typename
        }
        ElectricalDoses {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    cognitoID
    Users {
      items {
        id
        firstName
        lastName
        type
        cognitoID
        indexedParentDepID
        departmentID
        depAdmins
        logs {
          nextToken
          startedAt
          __typename
        }
        hashedPin
        saltPin
        pairedDepIDs
        oneDoseVersion
        lastActivity
        notificationTokens
        deviceIDs
        maxDevices
        allowAnalytics
        status
        FormLogs {
          nextToken
          startedAt
          __typename
        }
        Reviewals {
          nextToken
          startedAt
          __typename
        }
        Acknowledges {
          nextToken
          startedAt
          __typename
        }
        UserComments {
          nextToken
          startedAt
          __typename
        }
        NotifyACKs {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Logs {
      items {
        id
        actualWeight
        ageValue
        protocolID
        Users {
          nextToken
          startedAt
          __typename
        }
        departmentID
        oneweightID
        ambulanceID
        incidentNumber
        startTime
        endTime
        estimatedWeight
        medications {
          medicationID
          amntSolid
          amntLiquid
          timestamp
          verified
          name
          rxNormCode
          concentration
          isActualWeight
          adminType
          weight
          protocolID
          __typename
        }
        equipments {
          equipmentID
          amount
          name
          snomedCode
          timestamp
          isActualWeight
          verified
          weight
          successful
          protocolID
          __typename
        }
        electricals {
          electricalID
          name
          snomedCode
          amount
          timestamp
          isActualWeight
          verified
          weight
          protocolID
          __typename
        }
        cpr {
          cprID
          startTime
          endTime
          outcome
          __typename
        }
        emsCalculators {
          calculatorID
          responses
          scores
          timestamp
          __typename
        }
        patientSaved
        patientReleased
        arrivedAtHospital
        ageTimestamp
        forms {
          formID
          name
          completed
          startedTime
          protocolID
          __typename
        }
        subtractedItems {
          weightObjectID
          name
          weight
          added
          __typename
        }
        userIDs
        infusions {
          dripID
          amnt
          amnt2
          timestamp
          verified
          name
          rxNormCode
          concentration
          adminType
          weight
          protocolID
          amntUnit
          amntUnit2
          isActualWeight
          gttsDropFactor
          gttsAmount
          gttsUnit
          __typename
        }
        estimatedSaved
        tags
        shifts {
          shiftStart
          shiftEnd
          completedLogs
          taggedAmbulance
          taggedOneWeight
          taggedUsers
          ownerID
          departmentID
          metaData
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Ambulances {
      items {
        id
        name
        departmentID
        Logs {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Drips {
      items {
        id
        name
        rxNormCode
        contraindication
        note
        warning
        route
        nemsisRoutes {
          route
          code
          __typename
        }
        concentration {
          firstAmnt
          firstUnit
          secAmnt
          secUnit
          color
          __typename
        }
        medClass
        action
        indication
        interaction
        onset
        duration
        dripOptions {
          protocolID
          __typename
        }
        departmentID
        rangeLow
        rangeHigh
        maxDose
        minDose
        activeID
        overrideID
        status
        version
        createdBy
        modifiedBy
        metaData {
          parentID
          fullName
          nickName
          brandNames
          maxDose
          minDose
          absMaxDose
          absMinDose
          releaseNotes
          __typename
        }
        InfusionDoses {
          nextToken
          startedAt
          __typename
        }
        Concentrations {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    RequireSync {
      id
      sync
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    logoID
    userID
    neonateCutoff
    pediatricCutoff
    calculators
    adultRanges {
      label
      value
      __typename
    }
    softwarePlan
    Notifications {
      items {
        id
        type
        title
        message
        timestamp
        expirationTimestamp
        deadlineTimestamp
        isReadIDs
        isAckIDs
        imageURLs
        videoURLs
        fileURLs
        taggedProtocols
        questions {
          question
          options
          answer
          taggedProtocol
          __typename
        }
        formQuestions {
          index
          type
          question
          description
          answers
          correctAnswer
          __typename
        }
        isPush
        isPinRequired
        departmentID
        pairedDepIDs
        Groups {
          nextToken
          startedAt
          __typename
        }
        createdBy
        modifiedBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Contacts {
      items {
        id
        fullName
        title
        number
        note
        index
        departmentID
        pairedDepIDs
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    InputForms {
      items {
        id
        name
        items {
          index
          type
          question
          description
          answers
          correctAnswer
          __typename
        }
        FormLogs {
          nextToken
          startedAt
          __typename
        }
        departmentID
        pairedDepIDs
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    FormLogs {
      items {
        id
        responses {
          questionIndex
          type
          responses
          __typename
        }
        inputformID
        departmentID
        userID
        pairedDepID
        pairedUserIDs
        notes
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Workbooks {
      items {
        id
        name
        workbookID
        fileSize
        aiPdfParserResults
        activeID
        createdBy
        modifiedBy
        status
        version
        pairedDepIDs
        departmentID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    WeightObjects {
      items {
        id
        name
        weight
        createdBy
        modifiedBy
        version
        pairedDepIDs
        departmentID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Keychains {
      items {
        id
        name
        hashedPin
        saltPin
        hashType
        version
        createdBy
        modifiedBy
        metaData
        departmentID
        Protocols {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Reviewals {
      items {
        id
        title
        description
        reviewers
        status
        events {
          color
          title
          description
          timestamp
          icon
          __typename
        }
        createdAt
        endedAt
        departmentID
        userID
        User {
          id
          firstName
          lastName
          type
          cognitoID
          indexedParentDepID
          departmentID
          depAdmins
          hashedPin
          saltPin
          pairedDepIDs
          oneDoseVersion
          lastActivity
          notificationTokens
          deviceIDs
          maxDevices
          allowAnalytics
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        DraftGroups {
          nextToken
          startedAt
          __typename
        }
        Acknowledges {
          nextToken
          startedAt
          __typename
        }
        UserComments {
          nextToken
          startedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Groups {
      items {
        id
        name
        type
        departmentID
        pairedDepIDs
        pairedUserIDs
        isAutoGen
        Department {
          id
          name
          location
          protocolVersions
          shiftTypes
          cognitoID
          logoID
          userID
          neonateCutoff
          pediatricCutoff
          calculators
          softwarePlan
          subDepIDs
          parentDepID
          indexedParentDepID
          activeStatus
          agencyNumEMS
          stateIdEMS
          gnisCodeEMS
          uniqueCode
          uniquePublicURL
          hashedPin
          saltedPin
          keychainID
          isPublic
          infusionCalculation
          isPublicSignup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          departmentRequireSyncId
          __typename
        }
        Notifications {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    CPRAssists {
      items {
        id
        protocolIDs
        epiIDs
        defibIDs
        equipmentID
        departmentID
        activeID
        overrideID
        status
        version
        createdBy
        modifiedBy
        cprMetaData {
          defaultMode
          epiOrangeSec
          epiRedSec
          compressionRate
          ventRatePerMin
          ventilateSoundDelay
          ventilateLoops
          continousVentilateLoops
          ventilateSoundSpeed
          pulseCheckTime
          chargingTime
          __typename
        }
        DefibDoses {
          nextToken
          startedAt
          __typename
        }
        EpiDoses {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    subDepIDs
    parentDepID
    indexedParentDepID
    activeStatus
    agencyNumEMS
    stateIdEMS
    gnisCodeEMS
    uniqueCode
    uniquePublicURL
    hashedPin
    saltedPin
    keychainID
    isPublic
    infusionCalculation
    isPublicSignup
    config {
      isTopEnabled
      neonateCutoff
      pediatricCutoff
      calculators
      adultRanges {
        label
        value
        __typename
      }
      softwarePlan
      infusionCalculation
      isPublic
      realTimeUpdating
      epcrProvider
      oneweightEnabled
      ageFilterEnabled
      ageGroupFilterEnabled
      renewalDate
      infusionGTTS
      signedAgreement
      isToggleAdvancedFiltersEnabled
      premiumTrialExpiration
      estimationMethod
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    departmentRequireSyncId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDepartmentQueryVariables,
  APITypes.GetDepartmentQuery
>;
export const listDepartments = /* GraphQL */ `query ListDepartments(
  $filter: ModelDepartmentFilterInput
  $limit: Int
  $nextToken: String
) {
  listDepartments(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      Categories {
        items {
          id
          name
          departmentID
          index
          pairedDepIDs
          parentCategoryID
          activeID
          status
          version
          createdBy
          modifiedBy
          isPublic
          isRestrictive
          color
          tags
          overrideID
          keychainID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          departmentCategoriesId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      OneWeights {
        items {
          id
          name
          serialNumber
          projectNumber
          lastCalibration
          type
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          departmentOneWeightsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      name
      location
      protocolVersions
      shiftTypes
      Medications {
        items {
          id
          name
          rxNormCode
          contraindication
          note
          warning
          route
          medClass
          action
          indication
          interaction
          onset
          duration
          departmentID
          rangeLow
          rangeHigh
          maxDose
          minDose
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Equipment {
        items {
          id
          name
          snomedCode
          departmentID
          title
          note
          warning
          instruction
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Forms {
        items {
          id
          name
          departmentID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Vitals {
        items {
          id
          title
          index
          departmentID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Electricals {
        items {
          id
          title
          departmentID
          activeID
          status
          version
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      ElectricalShocks {
        items {
          id
          title
          rxNormCode
          warning
          instruction
          note
          rangeHigh
          rangeLow
          departmentID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      cognitoID
      Users {
        items {
          id
          firstName
          lastName
          type
          cognitoID
          indexedParentDepID
          departmentID
          depAdmins
          hashedPin
          saltPin
          pairedDepIDs
          oneDoseVersion
          lastActivity
          notificationTokens
          deviceIDs
          maxDevices
          allowAnalytics
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Logs {
        items {
          id
          actualWeight
          ageValue
          protocolID
          departmentID
          oneweightID
          ambulanceID
          incidentNumber
          startTime
          endTime
          estimatedWeight
          patientSaved
          patientReleased
          arrivedAtHospital
          ageTimestamp
          userIDs
          estimatedSaved
          tags
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Ambulances {
        items {
          id
          name
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Drips {
        items {
          id
          name
          rxNormCode
          contraindication
          note
          warning
          route
          medClass
          action
          indication
          interaction
          onset
          duration
          departmentID
          rangeLow
          rangeHigh
          maxDose
          minDose
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      RequireSync {
        id
        sync
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      logoID
      userID
      neonateCutoff
      pediatricCutoff
      calculators
      adultRanges {
        label
        value
        __typename
      }
      softwarePlan
      Notifications {
        items {
          id
          type
          title
          message
          timestamp
          expirationTimestamp
          deadlineTimestamp
          isReadIDs
          isAckIDs
          imageURLs
          videoURLs
          fileURLs
          taggedProtocols
          isPush
          isPinRequired
          departmentID
          pairedDepIDs
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Contacts {
        items {
          id
          fullName
          title
          number
          note
          index
          departmentID
          pairedDepIDs
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      InputForms {
        items {
          id
          name
          departmentID
          pairedDepIDs
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      FormLogs {
        items {
          id
          inputformID
          departmentID
          userID
          pairedDepID
          pairedUserIDs
          notes
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Workbooks {
        items {
          id
          name
          workbookID
          fileSize
          aiPdfParserResults
          activeID
          createdBy
          modifiedBy
          status
          version
          pairedDepIDs
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      WeightObjects {
        items {
          id
          name
          weight
          createdBy
          modifiedBy
          version
          pairedDepIDs
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Keychains {
        items {
          id
          name
          hashedPin
          saltPin
          hashType
          version
          createdBy
          modifiedBy
          metaData
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Reviewals {
        items {
          id
          title
          description
          reviewers
          status
          createdAt
          endedAt
          departmentID
          userID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Groups {
        items {
          id
          name
          type
          departmentID
          pairedDepIDs
          pairedUserIDs
          isAutoGen
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      CPRAssists {
        items {
          id
          protocolIDs
          epiIDs
          defibIDs
          equipmentID
          departmentID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      subDepIDs
      parentDepID
      indexedParentDepID
      activeStatus
      agencyNumEMS
      stateIdEMS
      gnisCodeEMS
      uniqueCode
      uniquePublicURL
      hashedPin
      saltedPin
      keychainID
      isPublic
      infusionCalculation
      isPublicSignup
      config {
        isTopEnabled
        neonateCutoff
        pediatricCutoff
        calculators
        adultRanges {
          label
          value
          __typename
        }
        softwarePlan
        infusionCalculation
        isPublic
        realTimeUpdating
        epcrProvider
        oneweightEnabled
        ageFilterEnabled
        ageGroupFilterEnabled
        renewalDate
        infusionGTTS
        signedAgreement
        isToggleAdvancedFiltersEnabled
        premiumTrialExpiration
        estimationMethod
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      departmentRequireSyncId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDepartmentsQueryVariables,
  APITypes.ListDepartmentsQuery
>;
export const syncDepartments = /* GraphQL */ `query SyncDepartments(
  $filter: ModelDepartmentFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncDepartments(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      Categories {
        items {
          id
          name
          departmentID
          index
          pairedDepIDs
          parentCategoryID
          activeID
          status
          version
          createdBy
          modifiedBy
          isPublic
          isRestrictive
          color
          tags
          overrideID
          keychainID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          departmentCategoriesId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      OneWeights {
        items {
          id
          name
          serialNumber
          projectNumber
          lastCalibration
          type
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          departmentOneWeightsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      name
      location
      protocolVersions
      shiftTypes
      Medications {
        items {
          id
          name
          rxNormCode
          contraindication
          note
          warning
          route
          medClass
          action
          indication
          interaction
          onset
          duration
          departmentID
          rangeLow
          rangeHigh
          maxDose
          minDose
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Equipment {
        items {
          id
          name
          snomedCode
          departmentID
          title
          note
          warning
          instruction
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Forms {
        items {
          id
          name
          departmentID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Vitals {
        items {
          id
          title
          index
          departmentID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Electricals {
        items {
          id
          title
          departmentID
          activeID
          status
          version
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      ElectricalShocks {
        items {
          id
          title
          rxNormCode
          warning
          instruction
          note
          rangeHigh
          rangeLow
          departmentID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      cognitoID
      Users {
        items {
          id
          firstName
          lastName
          type
          cognitoID
          indexedParentDepID
          departmentID
          depAdmins
          hashedPin
          saltPin
          pairedDepIDs
          oneDoseVersion
          lastActivity
          notificationTokens
          deviceIDs
          maxDevices
          allowAnalytics
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Logs {
        items {
          id
          actualWeight
          ageValue
          protocolID
          departmentID
          oneweightID
          ambulanceID
          incidentNumber
          startTime
          endTime
          estimatedWeight
          patientSaved
          patientReleased
          arrivedAtHospital
          ageTimestamp
          userIDs
          estimatedSaved
          tags
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Ambulances {
        items {
          id
          name
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Drips {
        items {
          id
          name
          rxNormCode
          contraindication
          note
          warning
          route
          medClass
          action
          indication
          interaction
          onset
          duration
          departmentID
          rangeLow
          rangeHigh
          maxDose
          minDose
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      RequireSync {
        id
        sync
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      logoID
      userID
      neonateCutoff
      pediatricCutoff
      calculators
      adultRanges {
        label
        value
        __typename
      }
      softwarePlan
      Notifications {
        items {
          id
          type
          title
          message
          timestamp
          expirationTimestamp
          deadlineTimestamp
          isReadIDs
          isAckIDs
          imageURLs
          videoURLs
          fileURLs
          taggedProtocols
          isPush
          isPinRequired
          departmentID
          pairedDepIDs
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Contacts {
        items {
          id
          fullName
          title
          number
          note
          index
          departmentID
          pairedDepIDs
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      InputForms {
        items {
          id
          name
          departmentID
          pairedDepIDs
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      FormLogs {
        items {
          id
          inputformID
          departmentID
          userID
          pairedDepID
          pairedUserIDs
          notes
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Workbooks {
        items {
          id
          name
          workbookID
          fileSize
          aiPdfParserResults
          activeID
          createdBy
          modifiedBy
          status
          version
          pairedDepIDs
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      WeightObjects {
        items {
          id
          name
          weight
          createdBy
          modifiedBy
          version
          pairedDepIDs
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Keychains {
        items {
          id
          name
          hashedPin
          saltPin
          hashType
          version
          createdBy
          modifiedBy
          metaData
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Reviewals {
        items {
          id
          title
          description
          reviewers
          status
          createdAt
          endedAt
          departmentID
          userID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Groups {
        items {
          id
          name
          type
          departmentID
          pairedDepIDs
          pairedUserIDs
          isAutoGen
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      CPRAssists {
        items {
          id
          protocolIDs
          epiIDs
          defibIDs
          equipmentID
          departmentID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      subDepIDs
      parentDepID
      indexedParentDepID
      activeStatus
      agencyNumEMS
      stateIdEMS
      gnisCodeEMS
      uniqueCode
      uniquePublicURL
      hashedPin
      saltedPin
      keychainID
      isPublic
      infusionCalculation
      isPublicSignup
      config {
        isTopEnabled
        neonateCutoff
        pediatricCutoff
        calculators
        adultRanges {
          label
          value
          __typename
        }
        softwarePlan
        infusionCalculation
        isPublic
        realTimeUpdating
        epcrProvider
        oneweightEnabled
        ageFilterEnabled
        ageGroupFilterEnabled
        renewalDate
        infusionGTTS
        signedAgreement
        isToggleAdvancedFiltersEnabled
        premiumTrialExpiration
        estimationMethod
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      departmentRequireSyncId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncDepartmentsQueryVariables,
  APITypes.SyncDepartmentsQuery
>;
export const departmentsByParentDepID = /* GraphQL */ `query DepartmentsByParentDepID(
  $indexedParentDepID: ID!
  $name: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelDepartmentFilterInput
  $limit: Int
  $nextToken: String
) {
  departmentsByParentDepID(
    indexedParentDepID: $indexedParentDepID
    name: $name
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      Categories {
        items {
          id
          name
          departmentID
          index
          pairedDepIDs
          parentCategoryID
          activeID
          status
          version
          createdBy
          modifiedBy
          isPublic
          isRestrictive
          color
          tags
          overrideID
          keychainID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          departmentCategoriesId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      OneWeights {
        items {
          id
          name
          serialNumber
          projectNumber
          lastCalibration
          type
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          departmentOneWeightsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      name
      location
      protocolVersions
      shiftTypes
      Medications {
        items {
          id
          name
          rxNormCode
          contraindication
          note
          warning
          route
          medClass
          action
          indication
          interaction
          onset
          duration
          departmentID
          rangeLow
          rangeHigh
          maxDose
          minDose
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Equipment {
        items {
          id
          name
          snomedCode
          departmentID
          title
          note
          warning
          instruction
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Forms {
        items {
          id
          name
          departmentID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Vitals {
        items {
          id
          title
          index
          departmentID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Electricals {
        items {
          id
          title
          departmentID
          activeID
          status
          version
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      ElectricalShocks {
        items {
          id
          title
          rxNormCode
          warning
          instruction
          note
          rangeHigh
          rangeLow
          departmentID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      cognitoID
      Users {
        items {
          id
          firstName
          lastName
          type
          cognitoID
          indexedParentDepID
          departmentID
          depAdmins
          hashedPin
          saltPin
          pairedDepIDs
          oneDoseVersion
          lastActivity
          notificationTokens
          deviceIDs
          maxDevices
          allowAnalytics
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Logs {
        items {
          id
          actualWeight
          ageValue
          protocolID
          departmentID
          oneweightID
          ambulanceID
          incidentNumber
          startTime
          endTime
          estimatedWeight
          patientSaved
          patientReleased
          arrivedAtHospital
          ageTimestamp
          userIDs
          estimatedSaved
          tags
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Ambulances {
        items {
          id
          name
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Drips {
        items {
          id
          name
          rxNormCode
          contraindication
          note
          warning
          route
          medClass
          action
          indication
          interaction
          onset
          duration
          departmentID
          rangeLow
          rangeHigh
          maxDose
          minDose
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      RequireSync {
        id
        sync
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      logoID
      userID
      neonateCutoff
      pediatricCutoff
      calculators
      adultRanges {
        label
        value
        __typename
      }
      softwarePlan
      Notifications {
        items {
          id
          type
          title
          message
          timestamp
          expirationTimestamp
          deadlineTimestamp
          isReadIDs
          isAckIDs
          imageURLs
          videoURLs
          fileURLs
          taggedProtocols
          isPush
          isPinRequired
          departmentID
          pairedDepIDs
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Contacts {
        items {
          id
          fullName
          title
          number
          note
          index
          departmentID
          pairedDepIDs
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      InputForms {
        items {
          id
          name
          departmentID
          pairedDepIDs
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      FormLogs {
        items {
          id
          inputformID
          departmentID
          userID
          pairedDepID
          pairedUserIDs
          notes
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Workbooks {
        items {
          id
          name
          workbookID
          fileSize
          aiPdfParserResults
          activeID
          createdBy
          modifiedBy
          status
          version
          pairedDepIDs
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      WeightObjects {
        items {
          id
          name
          weight
          createdBy
          modifiedBy
          version
          pairedDepIDs
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Keychains {
        items {
          id
          name
          hashedPin
          saltPin
          hashType
          version
          createdBy
          modifiedBy
          metaData
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Reviewals {
        items {
          id
          title
          description
          reviewers
          status
          createdAt
          endedAt
          departmentID
          userID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Groups {
        items {
          id
          name
          type
          departmentID
          pairedDepIDs
          pairedUserIDs
          isAutoGen
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      CPRAssists {
        items {
          id
          protocolIDs
          epiIDs
          defibIDs
          equipmentID
          departmentID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      subDepIDs
      parentDepID
      indexedParentDepID
      activeStatus
      agencyNumEMS
      stateIdEMS
      gnisCodeEMS
      uniqueCode
      uniquePublicURL
      hashedPin
      saltedPin
      keychainID
      isPublic
      infusionCalculation
      isPublicSignup
      config {
        isTopEnabled
        neonateCutoff
        pediatricCutoff
        calculators
        adultRanges {
          label
          value
          __typename
        }
        softwarePlan
        infusionCalculation
        isPublic
        realTimeUpdating
        epcrProvider
        oneweightEnabled
        ageFilterEnabled
        ageGroupFilterEnabled
        renewalDate
        infusionGTTS
        signedAgreement
        isToggleAdvancedFiltersEnabled
        premiumTrialExpiration
        estimationMethod
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      departmentRequireSyncId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.DepartmentsByParentDepIDQueryVariables,
  APITypes.DepartmentsByParentDepIDQuery
>;
export const departmentsByAgencyNumEMS = /* GraphQL */ `query DepartmentsByAgencyNumEMS(
  $agencyNumEMS: String!
  $sortDirection: ModelSortDirection
  $filter: ModelDepartmentFilterInput
  $limit: Int
  $nextToken: String
) {
  departmentsByAgencyNumEMS(
    agencyNumEMS: $agencyNumEMS
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      Categories {
        items {
          id
          name
          departmentID
          index
          pairedDepIDs
          parentCategoryID
          activeID
          status
          version
          createdBy
          modifiedBy
          isPublic
          isRestrictive
          color
          tags
          overrideID
          keychainID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          departmentCategoriesId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      OneWeights {
        items {
          id
          name
          serialNumber
          projectNumber
          lastCalibration
          type
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          departmentOneWeightsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      name
      location
      protocolVersions
      shiftTypes
      Medications {
        items {
          id
          name
          rxNormCode
          contraindication
          note
          warning
          route
          medClass
          action
          indication
          interaction
          onset
          duration
          departmentID
          rangeLow
          rangeHigh
          maxDose
          minDose
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Equipment {
        items {
          id
          name
          snomedCode
          departmentID
          title
          note
          warning
          instruction
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Forms {
        items {
          id
          name
          departmentID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Vitals {
        items {
          id
          title
          index
          departmentID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Electricals {
        items {
          id
          title
          departmentID
          activeID
          status
          version
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      ElectricalShocks {
        items {
          id
          title
          rxNormCode
          warning
          instruction
          note
          rangeHigh
          rangeLow
          departmentID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      cognitoID
      Users {
        items {
          id
          firstName
          lastName
          type
          cognitoID
          indexedParentDepID
          departmentID
          depAdmins
          hashedPin
          saltPin
          pairedDepIDs
          oneDoseVersion
          lastActivity
          notificationTokens
          deviceIDs
          maxDevices
          allowAnalytics
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Logs {
        items {
          id
          actualWeight
          ageValue
          protocolID
          departmentID
          oneweightID
          ambulanceID
          incidentNumber
          startTime
          endTime
          estimatedWeight
          patientSaved
          patientReleased
          arrivedAtHospital
          ageTimestamp
          userIDs
          estimatedSaved
          tags
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Ambulances {
        items {
          id
          name
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Drips {
        items {
          id
          name
          rxNormCode
          contraindication
          note
          warning
          route
          medClass
          action
          indication
          interaction
          onset
          duration
          departmentID
          rangeLow
          rangeHigh
          maxDose
          minDose
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      RequireSync {
        id
        sync
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      logoID
      userID
      neonateCutoff
      pediatricCutoff
      calculators
      adultRanges {
        label
        value
        __typename
      }
      softwarePlan
      Notifications {
        items {
          id
          type
          title
          message
          timestamp
          expirationTimestamp
          deadlineTimestamp
          isReadIDs
          isAckIDs
          imageURLs
          videoURLs
          fileURLs
          taggedProtocols
          isPush
          isPinRequired
          departmentID
          pairedDepIDs
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Contacts {
        items {
          id
          fullName
          title
          number
          note
          index
          departmentID
          pairedDepIDs
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      InputForms {
        items {
          id
          name
          departmentID
          pairedDepIDs
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      FormLogs {
        items {
          id
          inputformID
          departmentID
          userID
          pairedDepID
          pairedUserIDs
          notes
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Workbooks {
        items {
          id
          name
          workbookID
          fileSize
          aiPdfParserResults
          activeID
          createdBy
          modifiedBy
          status
          version
          pairedDepIDs
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      WeightObjects {
        items {
          id
          name
          weight
          createdBy
          modifiedBy
          version
          pairedDepIDs
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Keychains {
        items {
          id
          name
          hashedPin
          saltPin
          hashType
          version
          createdBy
          modifiedBy
          metaData
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Reviewals {
        items {
          id
          title
          description
          reviewers
          status
          createdAt
          endedAt
          departmentID
          userID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Groups {
        items {
          id
          name
          type
          departmentID
          pairedDepIDs
          pairedUserIDs
          isAutoGen
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      CPRAssists {
        items {
          id
          protocolIDs
          epiIDs
          defibIDs
          equipmentID
          departmentID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      subDepIDs
      parentDepID
      indexedParentDepID
      activeStatus
      agencyNumEMS
      stateIdEMS
      gnisCodeEMS
      uniqueCode
      uniquePublicURL
      hashedPin
      saltedPin
      keychainID
      isPublic
      infusionCalculation
      isPublicSignup
      config {
        isTopEnabled
        neonateCutoff
        pediatricCutoff
        calculators
        adultRanges {
          label
          value
          __typename
        }
        softwarePlan
        infusionCalculation
        isPublic
        realTimeUpdating
        epcrProvider
        oneweightEnabled
        ageFilterEnabled
        ageGroupFilterEnabled
        renewalDate
        infusionGTTS
        signedAgreement
        isToggleAdvancedFiltersEnabled
        premiumTrialExpiration
        estimationMethod
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      departmentRequireSyncId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.DepartmentsByAgencyNumEMSQueryVariables,
  APITypes.DepartmentsByAgencyNumEMSQuery
>;
export const departmentsByUniqueCode = /* GraphQL */ `query DepartmentsByUniqueCode(
  $uniqueCode: String!
  $sortDirection: ModelSortDirection
  $filter: ModelDepartmentFilterInput
  $limit: Int
  $nextToken: String
) {
  departmentsByUniqueCode(
    uniqueCode: $uniqueCode
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      Categories {
        items {
          id
          name
          departmentID
          index
          pairedDepIDs
          parentCategoryID
          activeID
          status
          version
          createdBy
          modifiedBy
          isPublic
          isRestrictive
          color
          tags
          overrideID
          keychainID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          departmentCategoriesId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      OneWeights {
        items {
          id
          name
          serialNumber
          projectNumber
          lastCalibration
          type
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          departmentOneWeightsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      name
      location
      protocolVersions
      shiftTypes
      Medications {
        items {
          id
          name
          rxNormCode
          contraindication
          note
          warning
          route
          medClass
          action
          indication
          interaction
          onset
          duration
          departmentID
          rangeLow
          rangeHigh
          maxDose
          minDose
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Equipment {
        items {
          id
          name
          snomedCode
          departmentID
          title
          note
          warning
          instruction
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Forms {
        items {
          id
          name
          departmentID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Vitals {
        items {
          id
          title
          index
          departmentID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Electricals {
        items {
          id
          title
          departmentID
          activeID
          status
          version
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      ElectricalShocks {
        items {
          id
          title
          rxNormCode
          warning
          instruction
          note
          rangeHigh
          rangeLow
          departmentID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      cognitoID
      Users {
        items {
          id
          firstName
          lastName
          type
          cognitoID
          indexedParentDepID
          departmentID
          depAdmins
          hashedPin
          saltPin
          pairedDepIDs
          oneDoseVersion
          lastActivity
          notificationTokens
          deviceIDs
          maxDevices
          allowAnalytics
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Logs {
        items {
          id
          actualWeight
          ageValue
          protocolID
          departmentID
          oneweightID
          ambulanceID
          incidentNumber
          startTime
          endTime
          estimatedWeight
          patientSaved
          patientReleased
          arrivedAtHospital
          ageTimestamp
          userIDs
          estimatedSaved
          tags
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Ambulances {
        items {
          id
          name
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Drips {
        items {
          id
          name
          rxNormCode
          contraindication
          note
          warning
          route
          medClass
          action
          indication
          interaction
          onset
          duration
          departmentID
          rangeLow
          rangeHigh
          maxDose
          minDose
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      RequireSync {
        id
        sync
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      logoID
      userID
      neonateCutoff
      pediatricCutoff
      calculators
      adultRanges {
        label
        value
        __typename
      }
      softwarePlan
      Notifications {
        items {
          id
          type
          title
          message
          timestamp
          expirationTimestamp
          deadlineTimestamp
          isReadIDs
          isAckIDs
          imageURLs
          videoURLs
          fileURLs
          taggedProtocols
          isPush
          isPinRequired
          departmentID
          pairedDepIDs
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Contacts {
        items {
          id
          fullName
          title
          number
          note
          index
          departmentID
          pairedDepIDs
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      InputForms {
        items {
          id
          name
          departmentID
          pairedDepIDs
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      FormLogs {
        items {
          id
          inputformID
          departmentID
          userID
          pairedDepID
          pairedUserIDs
          notes
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Workbooks {
        items {
          id
          name
          workbookID
          fileSize
          aiPdfParserResults
          activeID
          createdBy
          modifiedBy
          status
          version
          pairedDepIDs
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      WeightObjects {
        items {
          id
          name
          weight
          createdBy
          modifiedBy
          version
          pairedDepIDs
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Keychains {
        items {
          id
          name
          hashedPin
          saltPin
          hashType
          version
          createdBy
          modifiedBy
          metaData
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Reviewals {
        items {
          id
          title
          description
          reviewers
          status
          createdAt
          endedAt
          departmentID
          userID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Groups {
        items {
          id
          name
          type
          departmentID
          pairedDepIDs
          pairedUserIDs
          isAutoGen
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      CPRAssists {
        items {
          id
          protocolIDs
          epiIDs
          defibIDs
          equipmentID
          departmentID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      subDepIDs
      parentDepID
      indexedParentDepID
      activeStatus
      agencyNumEMS
      stateIdEMS
      gnisCodeEMS
      uniqueCode
      uniquePublicURL
      hashedPin
      saltedPin
      keychainID
      isPublic
      infusionCalculation
      isPublicSignup
      config {
        isTopEnabled
        neonateCutoff
        pediatricCutoff
        calculators
        adultRanges {
          label
          value
          __typename
        }
        softwarePlan
        infusionCalculation
        isPublic
        realTimeUpdating
        epcrProvider
        oneweightEnabled
        ageFilterEnabled
        ageGroupFilterEnabled
        renewalDate
        infusionGTTS
        signedAgreement
        isToggleAdvancedFiltersEnabled
        premiumTrialExpiration
        estimationMethod
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      departmentRequireSyncId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.DepartmentsByUniqueCodeQueryVariables,
  APITypes.DepartmentsByUniqueCodeQuery
>;
export const departmentsByUniquePublicURL = /* GraphQL */ `query DepartmentsByUniquePublicURL(
  $uniquePublicURL: String!
  $sortDirection: ModelSortDirection
  $filter: ModelDepartmentFilterInput
  $limit: Int
  $nextToken: String
) {
  departmentsByUniquePublicURL(
    uniquePublicURL: $uniquePublicURL
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      Categories {
        items {
          id
          name
          departmentID
          index
          pairedDepIDs
          parentCategoryID
          activeID
          status
          version
          createdBy
          modifiedBy
          isPublic
          isRestrictive
          color
          tags
          overrideID
          keychainID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          departmentCategoriesId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      OneWeights {
        items {
          id
          name
          serialNumber
          projectNumber
          lastCalibration
          type
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          departmentOneWeightsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      name
      location
      protocolVersions
      shiftTypes
      Medications {
        items {
          id
          name
          rxNormCode
          contraindication
          note
          warning
          route
          medClass
          action
          indication
          interaction
          onset
          duration
          departmentID
          rangeLow
          rangeHigh
          maxDose
          minDose
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Equipment {
        items {
          id
          name
          snomedCode
          departmentID
          title
          note
          warning
          instruction
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Forms {
        items {
          id
          name
          departmentID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Vitals {
        items {
          id
          title
          index
          departmentID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Electricals {
        items {
          id
          title
          departmentID
          activeID
          status
          version
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      ElectricalShocks {
        items {
          id
          title
          rxNormCode
          warning
          instruction
          note
          rangeHigh
          rangeLow
          departmentID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      cognitoID
      Users {
        items {
          id
          firstName
          lastName
          type
          cognitoID
          indexedParentDepID
          departmentID
          depAdmins
          hashedPin
          saltPin
          pairedDepIDs
          oneDoseVersion
          lastActivity
          notificationTokens
          deviceIDs
          maxDevices
          allowAnalytics
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Logs {
        items {
          id
          actualWeight
          ageValue
          protocolID
          departmentID
          oneweightID
          ambulanceID
          incidentNumber
          startTime
          endTime
          estimatedWeight
          patientSaved
          patientReleased
          arrivedAtHospital
          ageTimestamp
          userIDs
          estimatedSaved
          tags
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Ambulances {
        items {
          id
          name
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Drips {
        items {
          id
          name
          rxNormCode
          contraindication
          note
          warning
          route
          medClass
          action
          indication
          interaction
          onset
          duration
          departmentID
          rangeLow
          rangeHigh
          maxDose
          minDose
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      RequireSync {
        id
        sync
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      logoID
      userID
      neonateCutoff
      pediatricCutoff
      calculators
      adultRanges {
        label
        value
        __typename
      }
      softwarePlan
      Notifications {
        items {
          id
          type
          title
          message
          timestamp
          expirationTimestamp
          deadlineTimestamp
          isReadIDs
          isAckIDs
          imageURLs
          videoURLs
          fileURLs
          taggedProtocols
          isPush
          isPinRequired
          departmentID
          pairedDepIDs
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Contacts {
        items {
          id
          fullName
          title
          number
          note
          index
          departmentID
          pairedDepIDs
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      InputForms {
        items {
          id
          name
          departmentID
          pairedDepIDs
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      FormLogs {
        items {
          id
          inputformID
          departmentID
          userID
          pairedDepID
          pairedUserIDs
          notes
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Workbooks {
        items {
          id
          name
          workbookID
          fileSize
          aiPdfParserResults
          activeID
          createdBy
          modifiedBy
          status
          version
          pairedDepIDs
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      WeightObjects {
        items {
          id
          name
          weight
          createdBy
          modifiedBy
          version
          pairedDepIDs
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Keychains {
        items {
          id
          name
          hashedPin
          saltPin
          hashType
          version
          createdBy
          modifiedBy
          metaData
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Reviewals {
        items {
          id
          title
          description
          reviewers
          status
          createdAt
          endedAt
          departmentID
          userID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Groups {
        items {
          id
          name
          type
          departmentID
          pairedDepIDs
          pairedUserIDs
          isAutoGen
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      CPRAssists {
        items {
          id
          protocolIDs
          epiIDs
          defibIDs
          equipmentID
          departmentID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      subDepIDs
      parentDepID
      indexedParentDepID
      activeStatus
      agencyNumEMS
      stateIdEMS
      gnisCodeEMS
      uniqueCode
      uniquePublicURL
      hashedPin
      saltedPin
      keychainID
      isPublic
      infusionCalculation
      isPublicSignup
      config {
        isTopEnabled
        neonateCutoff
        pediatricCutoff
        calculators
        adultRanges {
          label
          value
          __typename
        }
        softwarePlan
        infusionCalculation
        isPublic
        realTimeUpdating
        epcrProvider
        oneweightEnabled
        ageFilterEnabled
        ageGroupFilterEnabled
        renewalDate
        infusionGTTS
        signedAgreement
        isToggleAdvancedFiltersEnabled
        premiumTrialExpiration
        estimationMethod
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      departmentRequireSyncId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.DepartmentsByUniquePublicURLQueryVariables,
  APITypes.DepartmentsByUniquePublicURLQuery
>;
export const departmentsByKeychainID = /* GraphQL */ `query DepartmentsByKeychainID(
  $keychainID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelDepartmentFilterInput
  $limit: Int
  $nextToken: String
) {
  departmentsByKeychainID(
    keychainID: $keychainID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      Categories {
        items {
          id
          name
          departmentID
          index
          pairedDepIDs
          parentCategoryID
          activeID
          status
          version
          createdBy
          modifiedBy
          isPublic
          isRestrictive
          color
          tags
          overrideID
          keychainID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          departmentCategoriesId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      OneWeights {
        items {
          id
          name
          serialNumber
          projectNumber
          lastCalibration
          type
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          departmentOneWeightsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      name
      location
      protocolVersions
      shiftTypes
      Medications {
        items {
          id
          name
          rxNormCode
          contraindication
          note
          warning
          route
          medClass
          action
          indication
          interaction
          onset
          duration
          departmentID
          rangeLow
          rangeHigh
          maxDose
          minDose
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Equipment {
        items {
          id
          name
          snomedCode
          departmentID
          title
          note
          warning
          instruction
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Forms {
        items {
          id
          name
          departmentID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Vitals {
        items {
          id
          title
          index
          departmentID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Electricals {
        items {
          id
          title
          departmentID
          activeID
          status
          version
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      ElectricalShocks {
        items {
          id
          title
          rxNormCode
          warning
          instruction
          note
          rangeHigh
          rangeLow
          departmentID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      cognitoID
      Users {
        items {
          id
          firstName
          lastName
          type
          cognitoID
          indexedParentDepID
          departmentID
          depAdmins
          hashedPin
          saltPin
          pairedDepIDs
          oneDoseVersion
          lastActivity
          notificationTokens
          deviceIDs
          maxDevices
          allowAnalytics
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Logs {
        items {
          id
          actualWeight
          ageValue
          protocolID
          departmentID
          oneweightID
          ambulanceID
          incidentNumber
          startTime
          endTime
          estimatedWeight
          patientSaved
          patientReleased
          arrivedAtHospital
          ageTimestamp
          userIDs
          estimatedSaved
          tags
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Ambulances {
        items {
          id
          name
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Drips {
        items {
          id
          name
          rxNormCode
          contraindication
          note
          warning
          route
          medClass
          action
          indication
          interaction
          onset
          duration
          departmentID
          rangeLow
          rangeHigh
          maxDose
          minDose
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      RequireSync {
        id
        sync
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      logoID
      userID
      neonateCutoff
      pediatricCutoff
      calculators
      adultRanges {
        label
        value
        __typename
      }
      softwarePlan
      Notifications {
        items {
          id
          type
          title
          message
          timestamp
          expirationTimestamp
          deadlineTimestamp
          isReadIDs
          isAckIDs
          imageURLs
          videoURLs
          fileURLs
          taggedProtocols
          isPush
          isPinRequired
          departmentID
          pairedDepIDs
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Contacts {
        items {
          id
          fullName
          title
          number
          note
          index
          departmentID
          pairedDepIDs
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      InputForms {
        items {
          id
          name
          departmentID
          pairedDepIDs
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      FormLogs {
        items {
          id
          inputformID
          departmentID
          userID
          pairedDepID
          pairedUserIDs
          notes
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Workbooks {
        items {
          id
          name
          workbookID
          fileSize
          aiPdfParserResults
          activeID
          createdBy
          modifiedBy
          status
          version
          pairedDepIDs
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      WeightObjects {
        items {
          id
          name
          weight
          createdBy
          modifiedBy
          version
          pairedDepIDs
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Keychains {
        items {
          id
          name
          hashedPin
          saltPin
          hashType
          version
          createdBy
          modifiedBy
          metaData
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Reviewals {
        items {
          id
          title
          description
          reviewers
          status
          createdAt
          endedAt
          departmentID
          userID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Groups {
        items {
          id
          name
          type
          departmentID
          pairedDepIDs
          pairedUserIDs
          isAutoGen
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      CPRAssists {
        items {
          id
          protocolIDs
          epiIDs
          defibIDs
          equipmentID
          departmentID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      subDepIDs
      parentDepID
      indexedParentDepID
      activeStatus
      agencyNumEMS
      stateIdEMS
      gnisCodeEMS
      uniqueCode
      uniquePublicURL
      hashedPin
      saltedPin
      keychainID
      isPublic
      infusionCalculation
      isPublicSignup
      config {
        isTopEnabled
        neonateCutoff
        pediatricCutoff
        calculators
        adultRanges {
          label
          value
          __typename
        }
        softwarePlan
        infusionCalculation
        isPublic
        realTimeUpdating
        epcrProvider
        oneweightEnabled
        ageFilterEnabled
        ageGroupFilterEnabled
        renewalDate
        infusionGTTS
        signedAgreement
        isToggleAdvancedFiltersEnabled
        premiumTrialExpiration
        estimationMethod
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      departmentRequireSyncId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.DepartmentsByKeychainIDQueryVariables,
  APITypes.DepartmentsByKeychainIDQuery
>;
export const getMedicShift = /* GraphQL */ `query GetMedicShift($id: ID!) {
  getMedicShift(id: $id) {
    id
    shiftStart
    shiftEnd
    completedLogs
    taggedAmbulance
    taggedOneWeight
    taggedUsers
    subscribedDevices
    invitedUsers {
      itemID
      expiration
      sentTime
      title
      description
      __typename
    }
    ownerID
    departmentID
    interactionID
    metaData
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetMedicShiftQueryVariables,
  APITypes.GetMedicShiftQuery
>;
export const listMedicShifts = /* GraphQL */ `query ListMedicShifts(
  $filter: ModelMedicShiftFilterInput
  $limit: Int
  $nextToken: String
) {
  listMedicShifts(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      shiftStart
      shiftEnd
      completedLogs
      taggedAmbulance
      taggedOneWeight
      taggedUsers
      subscribedDevices
      invitedUsers {
        itemID
        expiration
        sentTime
        title
        description
        __typename
      }
      ownerID
      departmentID
      interactionID
      metaData
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListMedicShiftsQueryVariables,
  APITypes.ListMedicShiftsQuery
>;
export const syncMedicShifts = /* GraphQL */ `query SyncMedicShifts(
  $filter: ModelMedicShiftFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncMedicShifts(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      shiftStart
      shiftEnd
      completedLogs
      taggedAmbulance
      taggedOneWeight
      taggedUsers
      subscribedDevices
      invitedUsers {
        itemID
        expiration
        sentTime
        title
        description
        __typename
      }
      ownerID
      departmentID
      interactionID
      metaData
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncMedicShiftsQueryVariables,
  APITypes.SyncMedicShiftsQuery
>;
export const medicShiftsByOwnerID = /* GraphQL */ `query MedicShiftsByOwnerID(
  $ownerID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelMedicShiftFilterInput
  $limit: Int
  $nextToken: String
) {
  medicShiftsByOwnerID(
    ownerID: $ownerID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      shiftStart
      shiftEnd
      completedLogs
      taggedAmbulance
      taggedOneWeight
      taggedUsers
      subscribedDevices
      invitedUsers {
        itemID
        expiration
        sentTime
        title
        description
        __typename
      }
      ownerID
      departmentID
      interactionID
      metaData
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.MedicShiftsByOwnerIDQueryVariables,
  APITypes.MedicShiftsByOwnerIDQuery
>;
export const medicShiftsByDepartmentID = /* GraphQL */ `query MedicShiftsByDepartmentID(
  $departmentID: ID!
  $shiftStart: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelMedicShiftFilterInput
  $limit: Int
  $nextToken: String
) {
  medicShiftsByDepartmentID(
    departmentID: $departmentID
    shiftStart: $shiftStart
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      shiftStart
      shiftEnd
      completedLogs
      taggedAmbulance
      taggedOneWeight
      taggedUsers
      subscribedDevices
      invitedUsers {
        itemID
        expiration
        sentTime
        title
        description
        __typename
      }
      ownerID
      departmentID
      interactionID
      metaData
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.MedicShiftsByDepartmentIDQueryVariables,
  APITypes.MedicShiftsByDepartmentIDQuery
>;
export const medicShiftsByInteractionID = /* GraphQL */ `query MedicShiftsByInteractionID(
  $interactionID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelMedicShiftFilterInput
  $limit: Int
  $nextToken: String
) {
  medicShiftsByInteractionID(
    interactionID: $interactionID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      shiftStart
      shiftEnd
      completedLogs
      taggedAmbulance
      taggedOneWeight
      taggedUsers
      subscribedDevices
      invitedUsers {
        itemID
        expiration
        sentTime
        title
        description
        __typename
      }
      ownerID
      departmentID
      interactionID
      metaData
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.MedicShiftsByInteractionIDQueryVariables,
  APITypes.MedicShiftsByInteractionIDQuery
>;
export const getPatientInteraction = /* GraphQL */ `query GetPatientInteraction($id: ID!) {
  getPatientInteraction(id: $id) {
    id
    isLogActive
    actualWeight
    ageValue
    startTime
    endTime
    estimatedWeight
    medications {
      medicationID
      amntSolid
      amntLiquid
      timestamp
      verified
      name
      rxNormCode
      concentration
      isActualWeight
      adminType
      route {
        route
        code
        __typename
      }
      weight
      protocolID
      __typename
    }
    equipments {
      equipmentID
      amount
      name
      snomedCode
      timestamp
      isActualWeight
      verified
      weight
      successful
      protocolID
      __typename
    }
    electricals {
      electricalID
      name
      snomedCode
      amount
      timestamp
      isActualWeight
      verified
      weight
      protocolID
      __typename
    }
    infusions {
      dripID
      amnt
      amnt2
      timestamp
      verified
      name
      rxNormCode
      concentration
      adminType
      route {
        route
        code
        __typename
      }
      weight
      protocolID
      amntUnit
      amntUnit2
      isActualWeight
      gttsDropFactor
      gttsAmount
      gttsUnit
      __typename
    }
    forms {
      formID
      name
      completed
      startedTime
      protocolID
      __typename
    }
    subtractedItems {
      weightObjectID
      name
      weight
      added
      __typename
    }
    cpr {
      cprID
      startTime
      endTime
      outcome
      __typename
    }
    emsCalculators {
      calculatorID
      responses
      scores
      timestamp
      __typename
    }
    taggedUsers
    taggedAmbulance
    taggedOneWeight
    incidentNumber
    actualTime
    estimatedTime
    patientReleased
    ageTimestamp
    departmentID
    subscribedUsers
    subscribedDevices
    events {
      color
      title
      description
      timestamp
      icon
      __typename
    }
    shifts
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPatientInteractionQueryVariables,
  APITypes.GetPatientInteractionQuery
>;
export const listPatientInteractions = /* GraphQL */ `query ListPatientInteractions(
  $filter: ModelPatientInteractionFilterInput
  $limit: Int
  $nextToken: String
) {
  listPatientInteractions(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      isLogActive
      actualWeight
      ageValue
      startTime
      endTime
      estimatedWeight
      medications {
        medicationID
        amntSolid
        amntLiquid
        timestamp
        verified
        name
        rxNormCode
        concentration
        isActualWeight
        adminType
        route {
          route
          code
          __typename
        }
        weight
        protocolID
        __typename
      }
      equipments {
        equipmentID
        amount
        name
        snomedCode
        timestamp
        isActualWeight
        verified
        weight
        successful
        protocolID
        __typename
      }
      electricals {
        electricalID
        name
        snomedCode
        amount
        timestamp
        isActualWeight
        verified
        weight
        protocolID
        __typename
      }
      infusions {
        dripID
        amnt
        amnt2
        timestamp
        verified
        name
        rxNormCode
        concentration
        adminType
        route {
          route
          code
          __typename
        }
        weight
        protocolID
        amntUnit
        amntUnit2
        isActualWeight
        gttsDropFactor
        gttsAmount
        gttsUnit
        __typename
      }
      forms {
        formID
        name
        completed
        startedTime
        protocolID
        __typename
      }
      subtractedItems {
        weightObjectID
        name
        weight
        added
        __typename
      }
      cpr {
        cprID
        startTime
        endTime
        outcome
        __typename
      }
      emsCalculators {
        calculatorID
        responses
        scores
        timestamp
        __typename
      }
      taggedUsers
      taggedAmbulance
      taggedOneWeight
      incidentNumber
      actualTime
      estimatedTime
      patientReleased
      ageTimestamp
      departmentID
      subscribedUsers
      subscribedDevices
      events {
        color
        title
        description
        timestamp
        icon
        __typename
      }
      shifts
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPatientInteractionsQueryVariables,
  APITypes.ListPatientInteractionsQuery
>;
export const syncPatientInteractions = /* GraphQL */ `query SyncPatientInteractions(
  $filter: ModelPatientInteractionFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncPatientInteractions(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      isLogActive
      actualWeight
      ageValue
      startTime
      endTime
      estimatedWeight
      medications {
        medicationID
        amntSolid
        amntLiquid
        timestamp
        verified
        name
        rxNormCode
        concentration
        isActualWeight
        adminType
        route {
          route
          code
          __typename
        }
        weight
        protocolID
        __typename
      }
      equipments {
        equipmentID
        amount
        name
        snomedCode
        timestamp
        isActualWeight
        verified
        weight
        successful
        protocolID
        __typename
      }
      electricals {
        electricalID
        name
        snomedCode
        amount
        timestamp
        isActualWeight
        verified
        weight
        protocolID
        __typename
      }
      infusions {
        dripID
        amnt
        amnt2
        timestamp
        verified
        name
        rxNormCode
        concentration
        adminType
        route {
          route
          code
          __typename
        }
        weight
        protocolID
        amntUnit
        amntUnit2
        isActualWeight
        gttsDropFactor
        gttsAmount
        gttsUnit
        __typename
      }
      forms {
        formID
        name
        completed
        startedTime
        protocolID
        __typename
      }
      subtractedItems {
        weightObjectID
        name
        weight
        added
        __typename
      }
      cpr {
        cprID
        startTime
        endTime
        outcome
        __typename
      }
      emsCalculators {
        calculatorID
        responses
        scores
        timestamp
        __typename
      }
      taggedUsers
      taggedAmbulance
      taggedOneWeight
      incidentNumber
      actualTime
      estimatedTime
      patientReleased
      ageTimestamp
      departmentID
      subscribedUsers
      subscribedDevices
      events {
        color
        title
        description
        timestamp
        icon
        __typename
      }
      shifts
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncPatientInteractionsQueryVariables,
  APITypes.SyncPatientInteractionsQuery
>;
export const patientInteractionsByTaggedAmbulance = /* GraphQL */ `query PatientInteractionsByTaggedAmbulance(
  $taggedAmbulance: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelPatientInteractionFilterInput
  $limit: Int
  $nextToken: String
) {
  patientInteractionsByTaggedAmbulance(
    taggedAmbulance: $taggedAmbulance
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      isLogActive
      actualWeight
      ageValue
      startTime
      endTime
      estimatedWeight
      medications {
        medicationID
        amntSolid
        amntLiquid
        timestamp
        verified
        name
        rxNormCode
        concentration
        isActualWeight
        adminType
        route {
          route
          code
          __typename
        }
        weight
        protocolID
        __typename
      }
      equipments {
        equipmentID
        amount
        name
        snomedCode
        timestamp
        isActualWeight
        verified
        weight
        successful
        protocolID
        __typename
      }
      electricals {
        electricalID
        name
        snomedCode
        amount
        timestamp
        isActualWeight
        verified
        weight
        protocolID
        __typename
      }
      infusions {
        dripID
        amnt
        amnt2
        timestamp
        verified
        name
        rxNormCode
        concentration
        adminType
        route {
          route
          code
          __typename
        }
        weight
        protocolID
        amntUnit
        amntUnit2
        isActualWeight
        gttsDropFactor
        gttsAmount
        gttsUnit
        __typename
      }
      forms {
        formID
        name
        completed
        startedTime
        protocolID
        __typename
      }
      subtractedItems {
        weightObjectID
        name
        weight
        added
        __typename
      }
      cpr {
        cprID
        startTime
        endTime
        outcome
        __typename
      }
      emsCalculators {
        calculatorID
        responses
        scores
        timestamp
        __typename
      }
      taggedUsers
      taggedAmbulance
      taggedOneWeight
      incidentNumber
      actualTime
      estimatedTime
      patientReleased
      ageTimestamp
      departmentID
      subscribedUsers
      subscribedDevices
      events {
        color
        title
        description
        timestamp
        icon
        __typename
      }
      shifts
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.PatientInteractionsByTaggedAmbulanceQueryVariables,
  APITypes.PatientInteractionsByTaggedAmbulanceQuery
>;
export const patientInteractionsByIncidentNumber = /* GraphQL */ `query PatientInteractionsByIncidentNumber(
  $incidentNumber: String!
  $sortDirection: ModelSortDirection
  $filter: ModelPatientInteractionFilterInput
  $limit: Int
  $nextToken: String
) {
  patientInteractionsByIncidentNumber(
    incidentNumber: $incidentNumber
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      isLogActive
      actualWeight
      ageValue
      startTime
      endTime
      estimatedWeight
      medications {
        medicationID
        amntSolid
        amntLiquid
        timestamp
        verified
        name
        rxNormCode
        concentration
        isActualWeight
        adminType
        route {
          route
          code
          __typename
        }
        weight
        protocolID
        __typename
      }
      equipments {
        equipmentID
        amount
        name
        snomedCode
        timestamp
        isActualWeight
        verified
        weight
        successful
        protocolID
        __typename
      }
      electricals {
        electricalID
        name
        snomedCode
        amount
        timestamp
        isActualWeight
        verified
        weight
        protocolID
        __typename
      }
      infusions {
        dripID
        amnt
        amnt2
        timestamp
        verified
        name
        rxNormCode
        concentration
        adminType
        route {
          route
          code
          __typename
        }
        weight
        protocolID
        amntUnit
        amntUnit2
        isActualWeight
        gttsDropFactor
        gttsAmount
        gttsUnit
        __typename
      }
      forms {
        formID
        name
        completed
        startedTime
        protocolID
        __typename
      }
      subtractedItems {
        weightObjectID
        name
        weight
        added
        __typename
      }
      cpr {
        cprID
        startTime
        endTime
        outcome
        __typename
      }
      emsCalculators {
        calculatorID
        responses
        scores
        timestamp
        __typename
      }
      taggedUsers
      taggedAmbulance
      taggedOneWeight
      incidentNumber
      actualTime
      estimatedTime
      patientReleased
      ageTimestamp
      departmentID
      subscribedUsers
      subscribedDevices
      events {
        color
        title
        description
        timestamp
        icon
        __typename
      }
      shifts
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.PatientInteractionsByIncidentNumberQueryVariables,
  APITypes.PatientInteractionsByIncidentNumberQuery
>;
export const interactionsByDepartmentID = /* GraphQL */ `query InteractionsByDepartmentID(
  $departmentID: ID!
  $startTime: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelPatientInteractionFilterInput
  $limit: Int
  $nextToken: String
) {
  interactionsByDepartmentID(
    departmentID: $departmentID
    startTime: $startTime
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      isLogActive
      actualWeight
      ageValue
      startTime
      endTime
      estimatedWeight
      medications {
        medicationID
        amntSolid
        amntLiquid
        timestamp
        verified
        name
        rxNormCode
        concentration
        isActualWeight
        adminType
        route {
          route
          code
          __typename
        }
        weight
        protocolID
        __typename
      }
      equipments {
        equipmentID
        amount
        name
        snomedCode
        timestamp
        isActualWeight
        verified
        weight
        successful
        protocolID
        __typename
      }
      electricals {
        electricalID
        name
        snomedCode
        amount
        timestamp
        isActualWeight
        verified
        weight
        protocolID
        __typename
      }
      infusions {
        dripID
        amnt
        amnt2
        timestamp
        verified
        name
        rxNormCode
        concentration
        adminType
        route {
          route
          code
          __typename
        }
        weight
        protocolID
        amntUnit
        amntUnit2
        isActualWeight
        gttsDropFactor
        gttsAmount
        gttsUnit
        __typename
      }
      forms {
        formID
        name
        completed
        startedTime
        protocolID
        __typename
      }
      subtractedItems {
        weightObjectID
        name
        weight
        added
        __typename
      }
      cpr {
        cprID
        startTime
        endTime
        outcome
        __typename
      }
      emsCalculators {
        calculatorID
        responses
        scores
        timestamp
        __typename
      }
      taggedUsers
      taggedAmbulance
      taggedOneWeight
      incidentNumber
      actualTime
      estimatedTime
      patientReleased
      ageTimestamp
      departmentID
      subscribedUsers
      subscribedDevices
      events {
        color
        title
        description
        timestamp
        icon
        __typename
      }
      shifts
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.InteractionsByDepartmentIDQueryVariables,
  APITypes.InteractionsByDepartmentIDQuery
>;
export const getKeychain = /* GraphQL */ `query GetKeychain($id: ID!) {
  getKeychain(id: $id) {
    id
    name
    hashedPin
    saltPin
    hashType
    version
    createdBy
    modifiedBy
    metaData
    departmentID
    Protocols {
      items {
        id
        name
        pdfID
        categoryID
        pairedProtocols
        medicationIDs
        equipmentIDs
        electricalIDs
        dripIDs
        formIDs
        index
        nickname
        pairedDepIDs
        rangeLow
        rangeHigh
        ageLow
        ageHigh
        ageGroup
        status
        version
        pdfVersion
        activeID
        overrideID
        privatePin
        keychainID
        createdBy
        modifiedBy
        isPublic
        isRestrictive
        color
        tags
        departmentID
        MedicationDoses {
          nextToken
          startedAt
          __typename
        }
        ElectricalDoses {
          nextToken
          startedAt
          __typename
        }
        DripDoses {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetKeychainQueryVariables,
  APITypes.GetKeychainQuery
>;
export const listKeychains = /* GraphQL */ `query ListKeychains(
  $filter: ModelKeychainFilterInput
  $limit: Int
  $nextToken: String
) {
  listKeychains(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      hashedPin
      saltPin
      hashType
      version
      createdBy
      modifiedBy
      metaData
      departmentID
      Protocols {
        items {
          id
          name
          pdfID
          categoryID
          pairedProtocols
          medicationIDs
          equipmentIDs
          electricalIDs
          dripIDs
          formIDs
          index
          nickname
          pairedDepIDs
          rangeLow
          rangeHigh
          ageLow
          ageHigh
          ageGroup
          status
          version
          pdfVersion
          activeID
          overrideID
          privatePin
          keychainID
          createdBy
          modifiedBy
          isPublic
          isRestrictive
          color
          tags
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListKeychainsQueryVariables,
  APITypes.ListKeychainsQuery
>;
export const syncKeychains = /* GraphQL */ `query SyncKeychains(
  $filter: ModelKeychainFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncKeychains(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      name
      hashedPin
      saltPin
      hashType
      version
      createdBy
      modifiedBy
      metaData
      departmentID
      Protocols {
        items {
          id
          name
          pdfID
          categoryID
          pairedProtocols
          medicationIDs
          equipmentIDs
          electricalIDs
          dripIDs
          formIDs
          index
          nickname
          pairedDepIDs
          rangeLow
          rangeHigh
          ageLow
          ageHigh
          ageGroup
          status
          version
          pdfVersion
          activeID
          overrideID
          privatePin
          keychainID
          createdBy
          modifiedBy
          isPublic
          isRestrictive
          color
          tags
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncKeychainsQueryVariables,
  APITypes.SyncKeychainsQuery
>;
export const keychainsByDepartmentID = /* GraphQL */ `query KeychainsByDepartmentID(
  $departmentID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelKeychainFilterInput
  $limit: Int
  $nextToken: String
) {
  keychainsByDepartmentID(
    departmentID: $departmentID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      hashedPin
      saltPin
      hashType
      version
      createdBy
      modifiedBy
      metaData
      departmentID
      Protocols {
        items {
          id
          name
          pdfID
          categoryID
          pairedProtocols
          medicationIDs
          equipmentIDs
          electricalIDs
          dripIDs
          formIDs
          index
          nickname
          pairedDepIDs
          rangeLow
          rangeHigh
          ageLow
          ageHigh
          ageGroup
          status
          version
          pdfVersion
          activeID
          overrideID
          privatePin
          keychainID
          createdBy
          modifiedBy
          isPublic
          isRestrictive
          color
          tags
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.KeychainsByDepartmentIDQueryVariables,
  APITypes.KeychainsByDepartmentIDQuery
>;
export const getContact = /* GraphQL */ `query GetContact($id: ID!) {
  getContact(id: $id) {
    id
    fullName
    title
    number
    note
    index
    departmentID
    pairedDepIDs
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetContactQueryVariables,
  APITypes.GetContactQuery
>;
export const listContacts = /* GraphQL */ `query ListContacts(
  $filter: ModelContactFilterInput
  $limit: Int
  $nextToken: String
) {
  listContacts(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      fullName
      title
      number
      note
      index
      departmentID
      pairedDepIDs
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListContactsQueryVariables,
  APITypes.ListContactsQuery
>;
export const syncContacts = /* GraphQL */ `query SyncContacts(
  $filter: ModelContactFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncContacts(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      fullName
      title
      number
      note
      index
      departmentID
      pairedDepIDs
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncContactsQueryVariables,
  APITypes.SyncContactsQuery
>;
export const contactsByDepartmentID = /* GraphQL */ `query ContactsByDepartmentID(
  $departmentID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelContactFilterInput
  $limit: Int
  $nextToken: String
) {
  contactsByDepartmentID(
    departmentID: $departmentID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      fullName
      title
      number
      note
      index
      departmentID
      pairedDepIDs
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ContactsByDepartmentIDQueryVariables,
  APITypes.ContactsByDepartmentIDQuery
>;
export const getFormLog = /* GraphQL */ `query GetFormLog($id: ID!) {
  getFormLog(id: $id) {
    id
    responses {
      questionIndex
      type
      responses
      __typename
    }
    inputformID
    departmentID
    userID
    pairedDepID
    pairedUserIDs
    notes
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetFormLogQueryVariables,
  APITypes.GetFormLogQuery
>;
export const listFormLogs = /* GraphQL */ `query ListFormLogs(
  $filter: ModelFormLogFilterInput
  $limit: Int
  $nextToken: String
) {
  listFormLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      responses {
        questionIndex
        type
        responses
        __typename
      }
      inputformID
      departmentID
      userID
      pairedDepID
      pairedUserIDs
      notes
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListFormLogsQueryVariables,
  APITypes.ListFormLogsQuery
>;
export const syncFormLogs = /* GraphQL */ `query SyncFormLogs(
  $filter: ModelFormLogFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncFormLogs(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      responses {
        questionIndex
        type
        responses
        __typename
      }
      inputformID
      departmentID
      userID
      pairedDepID
      pairedUserIDs
      notes
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncFormLogsQueryVariables,
  APITypes.SyncFormLogsQuery
>;
export const formLogsByInputformID = /* GraphQL */ `query FormLogsByInputformID(
  $inputformID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelFormLogFilterInput
  $limit: Int
  $nextToken: String
) {
  formLogsByInputformID(
    inputformID: $inputformID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      responses {
        questionIndex
        type
        responses
        __typename
      }
      inputformID
      departmentID
      userID
      pairedDepID
      pairedUserIDs
      notes
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.FormLogsByInputformIDQueryVariables,
  APITypes.FormLogsByInputformIDQuery
>;
export const formLogsByDepartmentID = /* GraphQL */ `query FormLogsByDepartmentID(
  $departmentID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelFormLogFilterInput
  $limit: Int
  $nextToken: String
) {
  formLogsByDepartmentID(
    departmentID: $departmentID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      responses {
        questionIndex
        type
        responses
        __typename
      }
      inputformID
      departmentID
      userID
      pairedDepID
      pairedUserIDs
      notes
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.FormLogsByDepartmentIDQueryVariables,
  APITypes.FormLogsByDepartmentIDQuery
>;
export const formLogsByUserID = /* GraphQL */ `query FormLogsByUserID(
  $userID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelFormLogFilterInput
  $limit: Int
  $nextToken: String
) {
  formLogsByUserID(
    userID: $userID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      responses {
        questionIndex
        type
        responses
        __typename
      }
      inputformID
      departmentID
      userID
      pairedDepID
      pairedUserIDs
      notes
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.FormLogsByUserIDQueryVariables,
  APITypes.FormLogsByUserIDQuery
>;
export const getInputForm = /* GraphQL */ `query GetInputForm($id: ID!) {
  getInputForm(id: $id) {
    id
    name
    items {
      index
      type
      question
      description
      answers
      correctAnswer
      __typename
    }
    FormLogs {
      items {
        id
        responses {
          questionIndex
          type
          responses
          __typename
        }
        inputformID
        departmentID
        userID
        pairedDepID
        pairedUserIDs
        notes
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    departmentID
    pairedDepIDs
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetInputFormQueryVariables,
  APITypes.GetInputFormQuery
>;
export const listInputForms = /* GraphQL */ `query ListInputForms(
  $filter: ModelInputFormFilterInput
  $limit: Int
  $nextToken: String
) {
  listInputForms(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      items {
        index
        type
        question
        description
        answers
        correctAnswer
        __typename
      }
      FormLogs {
        items {
          id
          inputformID
          departmentID
          userID
          pairedDepID
          pairedUserIDs
          notes
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      departmentID
      pairedDepIDs
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListInputFormsQueryVariables,
  APITypes.ListInputFormsQuery
>;
export const syncInputForms = /* GraphQL */ `query SyncInputForms(
  $filter: ModelInputFormFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncInputForms(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      name
      items {
        index
        type
        question
        description
        answers
        correctAnswer
        __typename
      }
      FormLogs {
        items {
          id
          inputformID
          departmentID
          userID
          pairedDepID
          pairedUserIDs
          notes
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      departmentID
      pairedDepIDs
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncInputFormsQueryVariables,
  APITypes.SyncInputFormsQuery
>;
export const inputFormsByDepartmentID = /* GraphQL */ `query InputFormsByDepartmentID(
  $departmentID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelInputFormFilterInput
  $limit: Int
  $nextToken: String
) {
  inputFormsByDepartmentID(
    departmentID: $departmentID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      items {
        index
        type
        question
        description
        answers
        correctAnswer
        __typename
      }
      FormLogs {
        items {
          id
          inputformID
          departmentID
          userID
          pairedDepID
          pairedUserIDs
          notes
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      departmentID
      pairedDepIDs
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.InputFormsByDepartmentIDQueryVariables,
  APITypes.InputFormsByDepartmentIDQuery
>;
export const getNotification = /* GraphQL */ `query GetNotification($id: ID!) {
  getNotification(id: $id) {
    id
    type
    title
    message
    timestamp
    expirationTimestamp
    deadlineTimestamp
    isReadIDs
    isAckIDs
    imageURLs
    videoURLs
    fileURLs
    taggedProtocols
    questions {
      question
      options
      answer
      taggedProtocol
      __typename
    }
    formQuestions {
      index
      type
      question
      description
      answers
      correctAnswer
      __typename
    }
    isPush
    isPinRequired
    departmentID
    pairedDepIDs
    Groups {
      items {
        id
        notificationId
        groupId
        notification {
          id
          type
          title
          message
          timestamp
          expirationTimestamp
          deadlineTimestamp
          isReadIDs
          isAckIDs
          imageURLs
          videoURLs
          fileURLs
          taggedProtocols
          isPush
          isPinRequired
          departmentID
          pairedDepIDs
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        group {
          id
          name
          type
          departmentID
          pairedDepIDs
          pairedUserIDs
          isAutoGen
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdBy
    modifiedBy
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetNotificationQueryVariables,
  APITypes.GetNotificationQuery
>;
export const listNotifications = /* GraphQL */ `query ListNotifications(
  $filter: ModelNotificationFilterInput
  $limit: Int
  $nextToken: String
) {
  listNotifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      type
      title
      message
      timestamp
      expirationTimestamp
      deadlineTimestamp
      isReadIDs
      isAckIDs
      imageURLs
      videoURLs
      fileURLs
      taggedProtocols
      questions {
        question
        options
        answer
        taggedProtocol
        __typename
      }
      formQuestions {
        index
        type
        question
        description
        answers
        correctAnswer
        __typename
      }
      isPush
      isPinRequired
      departmentID
      pairedDepIDs
      Groups {
        items {
          id
          notificationId
          groupId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdBy
      modifiedBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListNotificationsQueryVariables,
  APITypes.ListNotificationsQuery
>;
export const syncNotifications = /* GraphQL */ `query SyncNotifications(
  $filter: ModelNotificationFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncNotifications(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      type
      title
      message
      timestamp
      expirationTimestamp
      deadlineTimestamp
      isReadIDs
      isAckIDs
      imageURLs
      videoURLs
      fileURLs
      taggedProtocols
      questions {
        question
        options
        answer
        taggedProtocol
        __typename
      }
      formQuestions {
        index
        type
        question
        description
        answers
        correctAnswer
        __typename
      }
      isPush
      isPinRequired
      departmentID
      pairedDepIDs
      Groups {
        items {
          id
          notificationId
          groupId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdBy
      modifiedBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncNotificationsQueryVariables,
  APITypes.SyncNotificationsQuery
>;
export const notificationsByDepartmentID = /* GraphQL */ `query NotificationsByDepartmentID(
  $departmentID: ID!
  $timestamp: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelNotificationFilterInput
  $limit: Int
  $nextToken: String
) {
  notificationsByDepartmentID(
    departmentID: $departmentID
    timestamp: $timestamp
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      type
      title
      message
      timestamp
      expirationTimestamp
      deadlineTimestamp
      isReadIDs
      isAckIDs
      imageURLs
      videoURLs
      fileURLs
      taggedProtocols
      questions {
        question
        options
        answer
        taggedProtocol
        __typename
      }
      formQuestions {
        index
        type
        question
        description
        answers
        correctAnswer
        __typename
      }
      isPush
      isPinRequired
      departmentID
      pairedDepIDs
      Groups {
        items {
          id
          notificationId
          groupId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdBy
      modifiedBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.NotificationsByDepartmentIDQueryVariables,
  APITypes.NotificationsByDepartmentIDQuery
>;
export const getNotifyACK = /* GraphQL */ `query GetNotifyACK($id: ID!) {
  getNotifyACK(id: $id) {
    id
    ownerID
    userID
    status
    createdAt
    User {
      id
      firstName
      lastName
      type
      cognitoID
      indexedParentDepID
      departmentID
      depAdmins
      logs {
        items {
          id
          userId
          logId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      hashedPin
      saltPin
      pairedDepIDs
      oneDoseVersion
      lastActivity
      notificationTokens
      deviceIDs
      maxDevices
      allowAnalytics
      status
      FormLogs {
        items {
          id
          inputformID
          departmentID
          userID
          pairedDepID
          pairedUserIDs
          notes
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Reviewals {
        items {
          id
          title
          description
          reviewers
          status
          createdAt
          endedAt
          departmentID
          userID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Acknowledges {
        items {
          id
          reviewalID
          ownerID
          userID
          status
          isArchived
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      UserComments {
        items {
          id
          ownerID
          userID
          acknowledgeID
          message
          relatedItems
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      NotifyACKs {
        items {
          id
          ownerID
          userID
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetNotifyACKQueryVariables,
  APITypes.GetNotifyACKQuery
>;
export const listNotifyACKS = /* GraphQL */ `query ListNotifyACKS(
  $filter: ModelNotifyACKFilterInput
  $limit: Int
  $nextToken: String
) {
  listNotifyACKS(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      ownerID
      userID
      status
      createdAt
      User {
        id
        firstName
        lastName
        type
        cognitoID
        indexedParentDepID
        departmentID
        depAdmins
        logs {
          nextToken
          startedAt
          __typename
        }
        hashedPin
        saltPin
        pairedDepIDs
        oneDoseVersion
        lastActivity
        notificationTokens
        deviceIDs
        maxDevices
        allowAnalytics
        status
        FormLogs {
          nextToken
          startedAt
          __typename
        }
        Reviewals {
          nextToken
          startedAt
          __typename
        }
        Acknowledges {
          nextToken
          startedAt
          __typename
        }
        UserComments {
          nextToken
          startedAt
          __typename
        }
        NotifyACKs {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListNotifyACKSQueryVariables,
  APITypes.ListNotifyACKSQuery
>;
export const syncNotifyACKS = /* GraphQL */ `query SyncNotifyACKS(
  $filter: ModelNotifyACKFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncNotifyACKS(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      ownerID
      userID
      status
      createdAt
      User {
        id
        firstName
        lastName
        type
        cognitoID
        indexedParentDepID
        departmentID
        depAdmins
        logs {
          nextToken
          startedAt
          __typename
        }
        hashedPin
        saltPin
        pairedDepIDs
        oneDoseVersion
        lastActivity
        notificationTokens
        deviceIDs
        maxDevices
        allowAnalytics
        status
        FormLogs {
          nextToken
          startedAt
          __typename
        }
        Reviewals {
          nextToken
          startedAt
          __typename
        }
        Acknowledges {
          nextToken
          startedAt
          __typename
        }
        UserComments {
          nextToken
          startedAt
          __typename
        }
        NotifyACKs {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncNotifyACKSQueryVariables,
  APITypes.SyncNotifyACKSQuery
>;
export const notifyACKsByOwnerID = /* GraphQL */ `query NotifyACKsByOwnerID(
  $ownerID: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelNotifyACKFilterInput
  $limit: Int
  $nextToken: String
) {
  notifyACKsByOwnerID(
    ownerID: $ownerID
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      ownerID
      userID
      status
      createdAt
      User {
        id
        firstName
        lastName
        type
        cognitoID
        indexedParentDepID
        departmentID
        depAdmins
        logs {
          nextToken
          startedAt
          __typename
        }
        hashedPin
        saltPin
        pairedDepIDs
        oneDoseVersion
        lastActivity
        notificationTokens
        deviceIDs
        maxDevices
        allowAnalytics
        status
        FormLogs {
          nextToken
          startedAt
          __typename
        }
        Reviewals {
          nextToken
          startedAt
          __typename
        }
        Acknowledges {
          nextToken
          startedAt
          __typename
        }
        UserComments {
          nextToken
          startedAt
          __typename
        }
        NotifyACKs {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.NotifyACKsByOwnerIDQueryVariables,
  APITypes.NotifyACKsByOwnerIDQuery
>;
export const notifyACKsByUserID = /* GraphQL */ `query NotifyACKsByUserID(
  $userID: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelNotifyACKFilterInput
  $limit: Int
  $nextToken: String
) {
  notifyACKsByUserID(
    userID: $userID
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      ownerID
      userID
      status
      createdAt
      User {
        id
        firstName
        lastName
        type
        cognitoID
        indexedParentDepID
        departmentID
        depAdmins
        logs {
          nextToken
          startedAt
          __typename
        }
        hashedPin
        saltPin
        pairedDepIDs
        oneDoseVersion
        lastActivity
        notificationTokens
        deviceIDs
        maxDevices
        allowAnalytics
        status
        FormLogs {
          nextToken
          startedAt
          __typename
        }
        Reviewals {
          nextToken
          startedAt
          __typename
        }
        Acknowledges {
          nextToken
          startedAt
          __typename
        }
        UserComments {
          nextToken
          startedAt
          __typename
        }
        NotifyACKs {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.NotifyACKsByUserIDQueryVariables,
  APITypes.NotifyACKsByUserIDQuery
>;
export const getGroup = /* GraphQL */ `query GetGroup($id: ID!) {
  getGroup(id: $id) {
    id
    name
    type
    departmentID
    pairedDepIDs
    pairedUserIDs
    isAutoGen
    Department {
      id
      Categories {
        items {
          id
          name
          departmentID
          index
          pairedDepIDs
          parentCategoryID
          activeID
          status
          version
          createdBy
          modifiedBy
          isPublic
          isRestrictive
          color
          tags
          overrideID
          keychainID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          departmentCategoriesId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      OneWeights {
        items {
          id
          name
          serialNumber
          projectNumber
          lastCalibration
          type
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          departmentOneWeightsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      name
      location
      protocolVersions
      shiftTypes
      Medications {
        items {
          id
          name
          rxNormCode
          contraindication
          note
          warning
          route
          medClass
          action
          indication
          interaction
          onset
          duration
          departmentID
          rangeLow
          rangeHigh
          maxDose
          minDose
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Equipment {
        items {
          id
          name
          snomedCode
          departmentID
          title
          note
          warning
          instruction
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Forms {
        items {
          id
          name
          departmentID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Vitals {
        items {
          id
          title
          index
          departmentID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Electricals {
        items {
          id
          title
          departmentID
          activeID
          status
          version
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      ElectricalShocks {
        items {
          id
          title
          rxNormCode
          warning
          instruction
          note
          rangeHigh
          rangeLow
          departmentID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      cognitoID
      Users {
        items {
          id
          firstName
          lastName
          type
          cognitoID
          indexedParentDepID
          departmentID
          depAdmins
          hashedPin
          saltPin
          pairedDepIDs
          oneDoseVersion
          lastActivity
          notificationTokens
          deviceIDs
          maxDevices
          allowAnalytics
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Logs {
        items {
          id
          actualWeight
          ageValue
          protocolID
          departmentID
          oneweightID
          ambulanceID
          incidentNumber
          startTime
          endTime
          estimatedWeight
          patientSaved
          patientReleased
          arrivedAtHospital
          ageTimestamp
          userIDs
          estimatedSaved
          tags
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Ambulances {
        items {
          id
          name
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Drips {
        items {
          id
          name
          rxNormCode
          contraindication
          note
          warning
          route
          medClass
          action
          indication
          interaction
          onset
          duration
          departmentID
          rangeLow
          rangeHigh
          maxDose
          minDose
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      RequireSync {
        id
        sync
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      logoID
      userID
      neonateCutoff
      pediatricCutoff
      calculators
      adultRanges {
        label
        value
        __typename
      }
      softwarePlan
      Notifications {
        items {
          id
          type
          title
          message
          timestamp
          expirationTimestamp
          deadlineTimestamp
          isReadIDs
          isAckIDs
          imageURLs
          videoURLs
          fileURLs
          taggedProtocols
          isPush
          isPinRequired
          departmentID
          pairedDepIDs
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Contacts {
        items {
          id
          fullName
          title
          number
          note
          index
          departmentID
          pairedDepIDs
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      InputForms {
        items {
          id
          name
          departmentID
          pairedDepIDs
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      FormLogs {
        items {
          id
          inputformID
          departmentID
          userID
          pairedDepID
          pairedUserIDs
          notes
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Workbooks {
        items {
          id
          name
          workbookID
          fileSize
          aiPdfParserResults
          activeID
          createdBy
          modifiedBy
          status
          version
          pairedDepIDs
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      WeightObjects {
        items {
          id
          name
          weight
          createdBy
          modifiedBy
          version
          pairedDepIDs
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Keychains {
        items {
          id
          name
          hashedPin
          saltPin
          hashType
          version
          createdBy
          modifiedBy
          metaData
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Reviewals {
        items {
          id
          title
          description
          reviewers
          status
          createdAt
          endedAt
          departmentID
          userID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Groups {
        items {
          id
          name
          type
          departmentID
          pairedDepIDs
          pairedUserIDs
          isAutoGen
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      CPRAssists {
        items {
          id
          protocolIDs
          epiIDs
          defibIDs
          equipmentID
          departmentID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      subDepIDs
      parentDepID
      indexedParentDepID
      activeStatus
      agencyNumEMS
      stateIdEMS
      gnisCodeEMS
      uniqueCode
      uniquePublicURL
      hashedPin
      saltedPin
      keychainID
      isPublic
      infusionCalculation
      isPublicSignup
      config {
        isTopEnabled
        neonateCutoff
        pediatricCutoff
        calculators
        adultRanges {
          label
          value
          __typename
        }
        softwarePlan
        infusionCalculation
        isPublic
        realTimeUpdating
        epcrProvider
        oneweightEnabled
        ageFilterEnabled
        ageGroupFilterEnabled
        renewalDate
        infusionGTTS
        signedAgreement
        isToggleAdvancedFiltersEnabled
        premiumTrialExpiration
        estimationMethod
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      departmentRequireSyncId
      __typename
    }
    Notifications {
      items {
        id
        notificationId
        groupId
        notification {
          id
          type
          title
          message
          timestamp
          expirationTimestamp
          deadlineTimestamp
          isReadIDs
          isAckIDs
          imageURLs
          videoURLs
          fileURLs
          taggedProtocols
          isPush
          isPinRequired
          departmentID
          pairedDepIDs
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        group {
          id
          name
          type
          departmentID
          pairedDepIDs
          pairedUserIDs
          isAutoGen
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetGroupQueryVariables, APITypes.GetGroupQuery>;
export const listGroups = /* GraphQL */ `query ListGroups(
  $filter: ModelGroupFilterInput
  $limit: Int
  $nextToken: String
) {
  listGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      type
      departmentID
      pairedDepIDs
      pairedUserIDs
      isAutoGen
      Department {
        id
        Categories {
          nextToken
          startedAt
          __typename
        }
        OneWeights {
          nextToken
          startedAt
          __typename
        }
        name
        location
        protocolVersions
        shiftTypes
        Medications {
          nextToken
          startedAt
          __typename
        }
        Equipment {
          nextToken
          startedAt
          __typename
        }
        Forms {
          nextToken
          startedAt
          __typename
        }
        Vitals {
          nextToken
          startedAt
          __typename
        }
        Electricals {
          nextToken
          startedAt
          __typename
        }
        ElectricalShocks {
          nextToken
          startedAt
          __typename
        }
        cognitoID
        Users {
          nextToken
          startedAt
          __typename
        }
        Logs {
          nextToken
          startedAt
          __typename
        }
        Ambulances {
          nextToken
          startedAt
          __typename
        }
        Drips {
          nextToken
          startedAt
          __typename
        }
        RequireSync {
          id
          sync
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        logoID
        userID
        neonateCutoff
        pediatricCutoff
        calculators
        adultRanges {
          label
          value
          __typename
        }
        softwarePlan
        Notifications {
          nextToken
          startedAt
          __typename
        }
        Contacts {
          nextToken
          startedAt
          __typename
        }
        InputForms {
          nextToken
          startedAt
          __typename
        }
        FormLogs {
          nextToken
          startedAt
          __typename
        }
        Workbooks {
          nextToken
          startedAt
          __typename
        }
        WeightObjects {
          nextToken
          startedAt
          __typename
        }
        Keychains {
          nextToken
          startedAt
          __typename
        }
        Reviewals {
          nextToken
          startedAt
          __typename
        }
        Groups {
          nextToken
          startedAt
          __typename
        }
        CPRAssists {
          nextToken
          startedAt
          __typename
        }
        subDepIDs
        parentDepID
        indexedParentDepID
        activeStatus
        agencyNumEMS
        stateIdEMS
        gnisCodeEMS
        uniqueCode
        uniquePublicURL
        hashedPin
        saltedPin
        keychainID
        isPublic
        infusionCalculation
        isPublicSignup
        config {
          isTopEnabled
          neonateCutoff
          pediatricCutoff
          calculators
          softwarePlan
          infusionCalculation
          isPublic
          realTimeUpdating
          epcrProvider
          oneweightEnabled
          ageFilterEnabled
          ageGroupFilterEnabled
          renewalDate
          infusionGTTS
          signedAgreement
          isToggleAdvancedFiltersEnabled
          premiumTrialExpiration
          estimationMethod
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        departmentRequireSyncId
        __typename
      }
      Notifications {
        items {
          id
          notificationId
          groupId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListGroupsQueryVariables,
  APITypes.ListGroupsQuery
>;
export const syncGroups = /* GraphQL */ `query SyncGroups(
  $filter: ModelGroupFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncGroups(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      name
      type
      departmentID
      pairedDepIDs
      pairedUserIDs
      isAutoGen
      Department {
        id
        Categories {
          nextToken
          startedAt
          __typename
        }
        OneWeights {
          nextToken
          startedAt
          __typename
        }
        name
        location
        protocolVersions
        shiftTypes
        Medications {
          nextToken
          startedAt
          __typename
        }
        Equipment {
          nextToken
          startedAt
          __typename
        }
        Forms {
          nextToken
          startedAt
          __typename
        }
        Vitals {
          nextToken
          startedAt
          __typename
        }
        Electricals {
          nextToken
          startedAt
          __typename
        }
        ElectricalShocks {
          nextToken
          startedAt
          __typename
        }
        cognitoID
        Users {
          nextToken
          startedAt
          __typename
        }
        Logs {
          nextToken
          startedAt
          __typename
        }
        Ambulances {
          nextToken
          startedAt
          __typename
        }
        Drips {
          nextToken
          startedAt
          __typename
        }
        RequireSync {
          id
          sync
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        logoID
        userID
        neonateCutoff
        pediatricCutoff
        calculators
        adultRanges {
          label
          value
          __typename
        }
        softwarePlan
        Notifications {
          nextToken
          startedAt
          __typename
        }
        Contacts {
          nextToken
          startedAt
          __typename
        }
        InputForms {
          nextToken
          startedAt
          __typename
        }
        FormLogs {
          nextToken
          startedAt
          __typename
        }
        Workbooks {
          nextToken
          startedAt
          __typename
        }
        WeightObjects {
          nextToken
          startedAt
          __typename
        }
        Keychains {
          nextToken
          startedAt
          __typename
        }
        Reviewals {
          nextToken
          startedAt
          __typename
        }
        Groups {
          nextToken
          startedAt
          __typename
        }
        CPRAssists {
          nextToken
          startedAt
          __typename
        }
        subDepIDs
        parentDepID
        indexedParentDepID
        activeStatus
        agencyNumEMS
        stateIdEMS
        gnisCodeEMS
        uniqueCode
        uniquePublicURL
        hashedPin
        saltedPin
        keychainID
        isPublic
        infusionCalculation
        isPublicSignup
        config {
          isTopEnabled
          neonateCutoff
          pediatricCutoff
          calculators
          softwarePlan
          infusionCalculation
          isPublic
          realTimeUpdating
          epcrProvider
          oneweightEnabled
          ageFilterEnabled
          ageGroupFilterEnabled
          renewalDate
          infusionGTTS
          signedAgreement
          isToggleAdvancedFiltersEnabled
          premiumTrialExpiration
          estimationMethod
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        departmentRequireSyncId
        __typename
      }
      Notifications {
        items {
          id
          notificationId
          groupId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncGroupsQueryVariables,
  APITypes.SyncGroupsQuery
>;
export const groupsByDepartmentID = /* GraphQL */ `query GroupsByDepartmentID(
  $departmentID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelGroupFilterInput
  $limit: Int
  $nextToken: String
) {
  groupsByDepartmentID(
    departmentID: $departmentID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      type
      departmentID
      pairedDepIDs
      pairedUserIDs
      isAutoGen
      Department {
        id
        Categories {
          nextToken
          startedAt
          __typename
        }
        OneWeights {
          nextToken
          startedAt
          __typename
        }
        name
        location
        protocolVersions
        shiftTypes
        Medications {
          nextToken
          startedAt
          __typename
        }
        Equipment {
          nextToken
          startedAt
          __typename
        }
        Forms {
          nextToken
          startedAt
          __typename
        }
        Vitals {
          nextToken
          startedAt
          __typename
        }
        Electricals {
          nextToken
          startedAt
          __typename
        }
        ElectricalShocks {
          nextToken
          startedAt
          __typename
        }
        cognitoID
        Users {
          nextToken
          startedAt
          __typename
        }
        Logs {
          nextToken
          startedAt
          __typename
        }
        Ambulances {
          nextToken
          startedAt
          __typename
        }
        Drips {
          nextToken
          startedAt
          __typename
        }
        RequireSync {
          id
          sync
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        logoID
        userID
        neonateCutoff
        pediatricCutoff
        calculators
        adultRanges {
          label
          value
          __typename
        }
        softwarePlan
        Notifications {
          nextToken
          startedAt
          __typename
        }
        Contacts {
          nextToken
          startedAt
          __typename
        }
        InputForms {
          nextToken
          startedAt
          __typename
        }
        FormLogs {
          nextToken
          startedAt
          __typename
        }
        Workbooks {
          nextToken
          startedAt
          __typename
        }
        WeightObjects {
          nextToken
          startedAt
          __typename
        }
        Keychains {
          nextToken
          startedAt
          __typename
        }
        Reviewals {
          nextToken
          startedAt
          __typename
        }
        Groups {
          nextToken
          startedAt
          __typename
        }
        CPRAssists {
          nextToken
          startedAt
          __typename
        }
        subDepIDs
        parentDepID
        indexedParentDepID
        activeStatus
        agencyNumEMS
        stateIdEMS
        gnisCodeEMS
        uniqueCode
        uniquePublicURL
        hashedPin
        saltedPin
        keychainID
        isPublic
        infusionCalculation
        isPublicSignup
        config {
          isTopEnabled
          neonateCutoff
          pediatricCutoff
          calculators
          softwarePlan
          infusionCalculation
          isPublic
          realTimeUpdating
          epcrProvider
          oneweightEnabled
          ageFilterEnabled
          ageGroupFilterEnabled
          renewalDate
          infusionGTTS
          signedAgreement
          isToggleAdvancedFiltersEnabled
          premiumTrialExpiration
          estimationMethod
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        departmentRequireSyncId
        __typename
      }
      Notifications {
        items {
          id
          notificationId
          groupId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GroupsByDepartmentIDQueryVariables,
  APITypes.GroupsByDepartmentIDQuery
>;
export const getDrip = /* GraphQL */ `query GetDrip($id: ID!) {
  getDrip(id: $id) {
    id
    name
    rxNormCode
    contraindication
    note
    warning
    route
    nemsisRoutes {
      route
      code
      __typename
    }
    concentration {
      firstAmnt
      firstUnit
      secAmnt
      secUnit
      color
      __typename
    }
    medClass
    action
    indication
    interaction
    onset
    duration
    dripOptions {
      protocolID
      options {
        doseID
        basis
        rangeLow
        rangeHigh
        route
        nemsisRoutes {
          route
          code
          __typename
        }
        title
        warning
        instruction
        note
        maxDose
        minDose
        maxTotalDose
        calcMax
        calcMin
        index
        ageLow
        ageHigh
        ageGroup
        repeatTime
        __typename
      }
      __typename
    }
    departmentID
    rangeLow
    rangeHigh
    maxDose
    minDose
    activeID
    overrideID
    status
    version
    createdBy
    modifiedBy
    metaData {
      parentID
      fullName
      nickName
      brandNames
      maxDose
      minDose
      absMaxDose
      absMinDose
      releaseNotes
      __typename
    }
    InfusionDoses {
      items {
        id
        basis
        rangeLow
        rangeHigh
        route
        nemsisRoutes {
          route
          code
          __typename
        }
        title
        warning
        instruction
        note
        maxDose
        minDose
        maxTotalDose
        calcMax
        calcMin
        index
        ageLow
        ageHigh
        ageGroup
        repeatTime
        incrementAmnt
        departmentID
        protocolID
        dripID
        activeID
        overrideID
        status
        version
        createdBy
        modifiedBy
        createdAt
        Protocol {
          id
          name
          pdfID
          categoryID
          pairedProtocols
          medicationIDs
          equipmentIDs
          electricalIDs
          dripIDs
          formIDs
          index
          nickname
          pairedDepIDs
          rangeLow
          rangeHigh
          ageLow
          ageHigh
          ageGroup
          status
          version
          pdfVersion
          activeID
          overrideID
          privatePin
          keychainID
          createdBy
          modifiedBy
          isPublic
          isRestrictive
          color
          tags
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Drip {
          id
          name
          rxNormCode
          contraindication
          note
          warning
          route
          medClass
          action
          indication
          interaction
          onset
          duration
          departmentID
          rangeLow
          rangeHigh
          maxDose
          minDose
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Concentrations {
      items {
        id
        firstAmnt
        firstUnit
        secAmnt
        secUnit
        color
        departmentID
        medicationID
        dripID
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetDripQueryVariables, APITypes.GetDripQuery>;
export const listDrips = /* GraphQL */ `query ListDrips(
  $filter: ModelDripFilterInput
  $limit: Int
  $nextToken: String
) {
  listDrips(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      rxNormCode
      contraindication
      note
      warning
      route
      nemsisRoutes {
        route
        code
        __typename
      }
      concentration {
        firstAmnt
        firstUnit
        secAmnt
        secUnit
        color
        __typename
      }
      medClass
      action
      indication
      interaction
      onset
      duration
      dripOptions {
        protocolID
        options {
          doseID
          basis
          rangeLow
          rangeHigh
          route
          title
          warning
          instruction
          note
          maxDose
          minDose
          maxTotalDose
          calcMax
          calcMin
          index
          ageLow
          ageHigh
          ageGroup
          repeatTime
          __typename
        }
        __typename
      }
      departmentID
      rangeLow
      rangeHigh
      maxDose
      minDose
      activeID
      overrideID
      status
      version
      createdBy
      modifiedBy
      metaData {
        parentID
        fullName
        nickName
        brandNames
        maxDose
        minDose
        absMaxDose
        absMinDose
        releaseNotes
        __typename
      }
      InfusionDoses {
        items {
          id
          basis
          rangeLow
          rangeHigh
          route
          title
          warning
          instruction
          note
          maxDose
          minDose
          maxTotalDose
          calcMax
          calcMin
          index
          ageLow
          ageHigh
          ageGroup
          repeatTime
          incrementAmnt
          departmentID
          protocolID
          dripID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Concentrations {
        items {
          id
          firstAmnt
          firstUnit
          secAmnt
          secUnit
          color
          departmentID
          medicationID
          dripID
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.ListDripsQueryVariables, APITypes.ListDripsQuery>;
export const syncDrips = /* GraphQL */ `query SyncDrips(
  $filter: ModelDripFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncDrips(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      name
      rxNormCode
      contraindication
      note
      warning
      route
      nemsisRoutes {
        route
        code
        __typename
      }
      concentration {
        firstAmnt
        firstUnit
        secAmnt
        secUnit
        color
        __typename
      }
      medClass
      action
      indication
      interaction
      onset
      duration
      dripOptions {
        protocolID
        options {
          doseID
          basis
          rangeLow
          rangeHigh
          route
          title
          warning
          instruction
          note
          maxDose
          minDose
          maxTotalDose
          calcMax
          calcMin
          index
          ageLow
          ageHigh
          ageGroup
          repeatTime
          __typename
        }
        __typename
      }
      departmentID
      rangeLow
      rangeHigh
      maxDose
      minDose
      activeID
      overrideID
      status
      version
      createdBy
      modifiedBy
      metaData {
        parentID
        fullName
        nickName
        brandNames
        maxDose
        minDose
        absMaxDose
        absMinDose
        releaseNotes
        __typename
      }
      InfusionDoses {
        items {
          id
          basis
          rangeLow
          rangeHigh
          route
          title
          warning
          instruction
          note
          maxDose
          minDose
          maxTotalDose
          calcMax
          calcMin
          index
          ageLow
          ageHigh
          ageGroup
          repeatTime
          incrementAmnt
          departmentID
          protocolID
          dripID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Concentrations {
        items {
          id
          firstAmnt
          firstUnit
          secAmnt
          secUnit
          color
          departmentID
          medicationID
          dripID
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.SyncDripsQueryVariables, APITypes.SyncDripsQuery>;
export const dripsByRxNormCode = /* GraphQL */ `query DripsByRxNormCode(
  $rxNormCode: String!
  $sortDirection: ModelSortDirection
  $filter: ModelDripFilterInput
  $limit: Int
  $nextToken: String
) {
  dripsByRxNormCode(
    rxNormCode: $rxNormCode
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      rxNormCode
      contraindication
      note
      warning
      route
      nemsisRoutes {
        route
        code
        __typename
      }
      concentration {
        firstAmnt
        firstUnit
        secAmnt
        secUnit
        color
        __typename
      }
      medClass
      action
      indication
      interaction
      onset
      duration
      dripOptions {
        protocolID
        options {
          doseID
          basis
          rangeLow
          rangeHigh
          route
          title
          warning
          instruction
          note
          maxDose
          minDose
          maxTotalDose
          calcMax
          calcMin
          index
          ageLow
          ageHigh
          ageGroup
          repeatTime
          __typename
        }
        __typename
      }
      departmentID
      rangeLow
      rangeHigh
      maxDose
      minDose
      activeID
      overrideID
      status
      version
      createdBy
      modifiedBy
      metaData {
        parentID
        fullName
        nickName
        brandNames
        maxDose
        minDose
        absMaxDose
        absMinDose
        releaseNotes
        __typename
      }
      InfusionDoses {
        items {
          id
          basis
          rangeLow
          rangeHigh
          route
          title
          warning
          instruction
          note
          maxDose
          minDose
          maxTotalDose
          calcMax
          calcMin
          index
          ageLow
          ageHigh
          ageGroup
          repeatTime
          incrementAmnt
          departmentID
          protocolID
          dripID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Concentrations {
        items {
          id
          firstAmnt
          firstUnit
          secAmnt
          secUnit
          color
          departmentID
          medicationID
          dripID
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.DripsByRxNormCodeQueryVariables,
  APITypes.DripsByRxNormCodeQuery
>;
export const dripsByDepartmentID = /* GraphQL */ `query DripsByDepartmentID(
  $departmentID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelDripFilterInput
  $limit: Int
  $nextToken: String
) {
  dripsByDepartmentID(
    departmentID: $departmentID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      rxNormCode
      contraindication
      note
      warning
      route
      nemsisRoutes {
        route
        code
        __typename
      }
      concentration {
        firstAmnt
        firstUnit
        secAmnt
        secUnit
        color
        __typename
      }
      medClass
      action
      indication
      interaction
      onset
      duration
      dripOptions {
        protocolID
        options {
          doseID
          basis
          rangeLow
          rangeHigh
          route
          title
          warning
          instruction
          note
          maxDose
          minDose
          maxTotalDose
          calcMax
          calcMin
          index
          ageLow
          ageHigh
          ageGroup
          repeatTime
          __typename
        }
        __typename
      }
      departmentID
      rangeLow
      rangeHigh
      maxDose
      minDose
      activeID
      overrideID
      status
      version
      createdBy
      modifiedBy
      metaData {
        parentID
        fullName
        nickName
        brandNames
        maxDose
        minDose
        absMaxDose
        absMinDose
        releaseNotes
        __typename
      }
      InfusionDoses {
        items {
          id
          basis
          rangeLow
          rangeHigh
          route
          title
          warning
          instruction
          note
          maxDose
          minDose
          maxTotalDose
          calcMax
          calcMin
          index
          ageLow
          ageHigh
          ageGroup
          repeatTime
          incrementAmnt
          departmentID
          protocolID
          dripID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Concentrations {
        items {
          id
          firstAmnt
          firstUnit
          secAmnt
          secUnit
          color
          departmentID
          medicationID
          dripID
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.DripsByDepartmentIDQueryVariables,
  APITypes.DripsByDepartmentIDQuery
>;
export const getInfusionDose = /* GraphQL */ `query GetInfusionDose($id: ID!) {
  getInfusionDose(id: $id) {
    id
    basis
    rangeLow
    rangeHigh
    route
    nemsisRoutes {
      route
      code
      __typename
    }
    title
    warning
    instruction
    note
    maxDose
    minDose
    maxTotalDose
    calcMax
    calcMin
    index
    ageLow
    ageHigh
    ageGroup
    repeatTime
    incrementAmnt
    departmentID
    protocolID
    dripID
    activeID
    overrideID
    status
    version
    createdBy
    modifiedBy
    createdAt
    Protocol {
      id
      name
      pdfID
      categoryID
      pairedProtocols
      medicationIDs
      equipmentIDs
      electricalIDs
      dripIDs
      formIDs
      index
      nickname
      pairedDepIDs
      rangeLow
      rangeHigh
      ageLow
      ageHigh
      ageGroup
      status
      version
      pdfVersion
      activeID
      overrideID
      privatePin
      keychainID
      createdBy
      modifiedBy
      isPublic
      isRestrictive
      color
      tags
      departmentID
      MedicationDoses {
        items {
          id
          basis
          rangeLow
          rangeHigh
          route
          title
          warning
          instruction
          note
          maxDose
          minDose
          maxTotalDose
          calcMax
          calcMin
          index
          ageLow
          ageHigh
          ageGroup
          repeatTime
          incrementAmnt
          departmentID
          protocolID
          medicationID
          cprAssistID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      ElectricalDoses {
        items {
          id
          basis
          rangeLow
          rangeHigh
          title
          warning
          instruction
          note
          maxDose
          minDose
          maxTotalDose
          calcMax
          calcMin
          index
          ageLow
          ageHigh
          ageGroup
          repeatTime
          incrementAmnt
          departmentID
          protocolID
          electricalID
          cprAssistID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      DripDoses {
        items {
          id
          basis
          rangeLow
          rangeHigh
          route
          title
          warning
          instruction
          note
          maxDose
          minDose
          maxTotalDose
          calcMax
          calcMin
          index
          ageLow
          ageHigh
          ageGroup
          repeatTime
          incrementAmnt
          departmentID
          protocolID
          dripID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    Drip {
      id
      name
      rxNormCode
      contraindication
      note
      warning
      route
      nemsisRoutes {
        route
        code
        __typename
      }
      concentration {
        firstAmnt
        firstUnit
        secAmnt
        secUnit
        color
        __typename
      }
      medClass
      action
      indication
      interaction
      onset
      duration
      dripOptions {
        protocolID
        options {
          doseID
          basis
          rangeLow
          rangeHigh
          route
          title
          warning
          instruction
          note
          maxDose
          minDose
          maxTotalDose
          calcMax
          calcMin
          index
          ageLow
          ageHigh
          ageGroup
          repeatTime
          __typename
        }
        __typename
      }
      departmentID
      rangeLow
      rangeHigh
      maxDose
      minDose
      activeID
      overrideID
      status
      version
      createdBy
      modifiedBy
      metaData {
        parentID
        fullName
        nickName
        brandNames
        maxDose
        minDose
        absMaxDose
        absMinDose
        releaseNotes
        __typename
      }
      InfusionDoses {
        items {
          id
          basis
          rangeLow
          rangeHigh
          route
          title
          warning
          instruction
          note
          maxDose
          minDose
          maxTotalDose
          calcMax
          calcMin
          index
          ageLow
          ageHigh
          ageGroup
          repeatTime
          incrementAmnt
          departmentID
          protocolID
          dripID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Concentrations {
        items {
          id
          firstAmnt
          firstUnit
          secAmnt
          secUnit
          color
          departmentID
          medicationID
          dripID
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetInfusionDoseQueryVariables,
  APITypes.GetInfusionDoseQuery
>;
export const listInfusionDoses = /* GraphQL */ `query ListInfusionDoses(
  $filter: ModelInfusionDoseFilterInput
  $limit: Int
  $nextToken: String
) {
  listInfusionDoses(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      basis
      rangeLow
      rangeHigh
      route
      nemsisRoutes {
        route
        code
        __typename
      }
      title
      warning
      instruction
      note
      maxDose
      minDose
      maxTotalDose
      calcMax
      calcMin
      index
      ageLow
      ageHigh
      ageGroup
      repeatTime
      incrementAmnt
      departmentID
      protocolID
      dripID
      activeID
      overrideID
      status
      version
      createdBy
      modifiedBy
      createdAt
      Protocol {
        id
        name
        pdfID
        categoryID
        pairedProtocols
        medicationIDs
        equipmentIDs
        electricalIDs
        dripIDs
        formIDs
        index
        nickname
        pairedDepIDs
        rangeLow
        rangeHigh
        ageLow
        ageHigh
        ageGroup
        status
        version
        pdfVersion
        activeID
        overrideID
        privatePin
        keychainID
        createdBy
        modifiedBy
        isPublic
        isRestrictive
        color
        tags
        departmentID
        MedicationDoses {
          nextToken
          startedAt
          __typename
        }
        ElectricalDoses {
          nextToken
          startedAt
          __typename
        }
        DripDoses {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Drip {
        id
        name
        rxNormCode
        contraindication
        note
        warning
        route
        nemsisRoutes {
          route
          code
          __typename
        }
        concentration {
          firstAmnt
          firstUnit
          secAmnt
          secUnit
          color
          __typename
        }
        medClass
        action
        indication
        interaction
        onset
        duration
        dripOptions {
          protocolID
          __typename
        }
        departmentID
        rangeLow
        rangeHigh
        maxDose
        minDose
        activeID
        overrideID
        status
        version
        createdBy
        modifiedBy
        metaData {
          parentID
          fullName
          nickName
          brandNames
          maxDose
          minDose
          absMaxDose
          absMinDose
          releaseNotes
          __typename
        }
        InfusionDoses {
          nextToken
          startedAt
          __typename
        }
        Concentrations {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListInfusionDosesQueryVariables,
  APITypes.ListInfusionDosesQuery
>;
export const syncInfusionDoses = /* GraphQL */ `query SyncInfusionDoses(
  $filter: ModelInfusionDoseFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncInfusionDoses(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      basis
      rangeLow
      rangeHigh
      route
      nemsisRoutes {
        route
        code
        __typename
      }
      title
      warning
      instruction
      note
      maxDose
      minDose
      maxTotalDose
      calcMax
      calcMin
      index
      ageLow
      ageHigh
      ageGroup
      repeatTime
      incrementAmnt
      departmentID
      protocolID
      dripID
      activeID
      overrideID
      status
      version
      createdBy
      modifiedBy
      createdAt
      Protocol {
        id
        name
        pdfID
        categoryID
        pairedProtocols
        medicationIDs
        equipmentIDs
        electricalIDs
        dripIDs
        formIDs
        index
        nickname
        pairedDepIDs
        rangeLow
        rangeHigh
        ageLow
        ageHigh
        ageGroup
        status
        version
        pdfVersion
        activeID
        overrideID
        privatePin
        keychainID
        createdBy
        modifiedBy
        isPublic
        isRestrictive
        color
        tags
        departmentID
        MedicationDoses {
          nextToken
          startedAt
          __typename
        }
        ElectricalDoses {
          nextToken
          startedAt
          __typename
        }
        DripDoses {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Drip {
        id
        name
        rxNormCode
        contraindication
        note
        warning
        route
        nemsisRoutes {
          route
          code
          __typename
        }
        concentration {
          firstAmnt
          firstUnit
          secAmnt
          secUnit
          color
          __typename
        }
        medClass
        action
        indication
        interaction
        onset
        duration
        dripOptions {
          protocolID
          __typename
        }
        departmentID
        rangeLow
        rangeHigh
        maxDose
        minDose
        activeID
        overrideID
        status
        version
        createdBy
        modifiedBy
        metaData {
          parentID
          fullName
          nickName
          brandNames
          maxDose
          minDose
          absMaxDose
          absMinDose
          releaseNotes
          __typename
        }
        InfusionDoses {
          nextToken
          startedAt
          __typename
        }
        Concentrations {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncInfusionDosesQueryVariables,
  APITypes.SyncInfusionDosesQuery
>;
export const infusionDosesByDepartmentID = /* GraphQL */ `query InfusionDosesByDepartmentID(
  $departmentID: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelInfusionDoseFilterInput
  $limit: Int
  $nextToken: String
) {
  infusionDosesByDepartmentID(
    departmentID: $departmentID
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      basis
      rangeLow
      rangeHigh
      route
      nemsisRoutes {
        route
        code
        __typename
      }
      title
      warning
      instruction
      note
      maxDose
      minDose
      maxTotalDose
      calcMax
      calcMin
      index
      ageLow
      ageHigh
      ageGroup
      repeatTime
      incrementAmnt
      departmentID
      protocolID
      dripID
      activeID
      overrideID
      status
      version
      createdBy
      modifiedBy
      createdAt
      Protocol {
        id
        name
        pdfID
        categoryID
        pairedProtocols
        medicationIDs
        equipmentIDs
        electricalIDs
        dripIDs
        formIDs
        index
        nickname
        pairedDepIDs
        rangeLow
        rangeHigh
        ageLow
        ageHigh
        ageGroup
        status
        version
        pdfVersion
        activeID
        overrideID
        privatePin
        keychainID
        createdBy
        modifiedBy
        isPublic
        isRestrictive
        color
        tags
        departmentID
        MedicationDoses {
          nextToken
          startedAt
          __typename
        }
        ElectricalDoses {
          nextToken
          startedAt
          __typename
        }
        DripDoses {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Drip {
        id
        name
        rxNormCode
        contraindication
        note
        warning
        route
        nemsisRoutes {
          route
          code
          __typename
        }
        concentration {
          firstAmnt
          firstUnit
          secAmnt
          secUnit
          color
          __typename
        }
        medClass
        action
        indication
        interaction
        onset
        duration
        dripOptions {
          protocolID
          __typename
        }
        departmentID
        rangeLow
        rangeHigh
        maxDose
        minDose
        activeID
        overrideID
        status
        version
        createdBy
        modifiedBy
        metaData {
          parentID
          fullName
          nickName
          brandNames
          maxDose
          minDose
          absMaxDose
          absMinDose
          releaseNotes
          __typename
        }
        InfusionDoses {
          nextToken
          startedAt
          __typename
        }
        Concentrations {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.InfusionDosesByDepartmentIDQueryVariables,
  APITypes.InfusionDosesByDepartmentIDQuery
>;
export const infusionDosesByProtocolID = /* GraphQL */ `query InfusionDosesByProtocolID(
  $protocolID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelInfusionDoseFilterInput
  $limit: Int
  $nextToken: String
) {
  infusionDosesByProtocolID(
    protocolID: $protocolID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      basis
      rangeLow
      rangeHigh
      route
      nemsisRoutes {
        route
        code
        __typename
      }
      title
      warning
      instruction
      note
      maxDose
      minDose
      maxTotalDose
      calcMax
      calcMin
      index
      ageLow
      ageHigh
      ageGroup
      repeatTime
      incrementAmnt
      departmentID
      protocolID
      dripID
      activeID
      overrideID
      status
      version
      createdBy
      modifiedBy
      createdAt
      Protocol {
        id
        name
        pdfID
        categoryID
        pairedProtocols
        medicationIDs
        equipmentIDs
        electricalIDs
        dripIDs
        formIDs
        index
        nickname
        pairedDepIDs
        rangeLow
        rangeHigh
        ageLow
        ageHigh
        ageGroup
        status
        version
        pdfVersion
        activeID
        overrideID
        privatePin
        keychainID
        createdBy
        modifiedBy
        isPublic
        isRestrictive
        color
        tags
        departmentID
        MedicationDoses {
          nextToken
          startedAt
          __typename
        }
        ElectricalDoses {
          nextToken
          startedAt
          __typename
        }
        DripDoses {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Drip {
        id
        name
        rxNormCode
        contraindication
        note
        warning
        route
        nemsisRoutes {
          route
          code
          __typename
        }
        concentration {
          firstAmnt
          firstUnit
          secAmnt
          secUnit
          color
          __typename
        }
        medClass
        action
        indication
        interaction
        onset
        duration
        dripOptions {
          protocolID
          __typename
        }
        departmentID
        rangeLow
        rangeHigh
        maxDose
        minDose
        activeID
        overrideID
        status
        version
        createdBy
        modifiedBy
        metaData {
          parentID
          fullName
          nickName
          brandNames
          maxDose
          minDose
          absMaxDose
          absMinDose
          releaseNotes
          __typename
        }
        InfusionDoses {
          nextToken
          startedAt
          __typename
        }
        Concentrations {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.InfusionDosesByProtocolIDQueryVariables,
  APITypes.InfusionDosesByProtocolIDQuery
>;
export const infusionDosesByDripID = /* GraphQL */ `query InfusionDosesByDripID(
  $dripID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelInfusionDoseFilterInput
  $limit: Int
  $nextToken: String
) {
  infusionDosesByDripID(
    dripID: $dripID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      basis
      rangeLow
      rangeHigh
      route
      nemsisRoutes {
        route
        code
        __typename
      }
      title
      warning
      instruction
      note
      maxDose
      minDose
      maxTotalDose
      calcMax
      calcMin
      index
      ageLow
      ageHigh
      ageGroup
      repeatTime
      incrementAmnt
      departmentID
      protocolID
      dripID
      activeID
      overrideID
      status
      version
      createdBy
      modifiedBy
      createdAt
      Protocol {
        id
        name
        pdfID
        categoryID
        pairedProtocols
        medicationIDs
        equipmentIDs
        electricalIDs
        dripIDs
        formIDs
        index
        nickname
        pairedDepIDs
        rangeLow
        rangeHigh
        ageLow
        ageHigh
        ageGroup
        status
        version
        pdfVersion
        activeID
        overrideID
        privatePin
        keychainID
        createdBy
        modifiedBy
        isPublic
        isRestrictive
        color
        tags
        departmentID
        MedicationDoses {
          nextToken
          startedAt
          __typename
        }
        ElectricalDoses {
          nextToken
          startedAt
          __typename
        }
        DripDoses {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Drip {
        id
        name
        rxNormCode
        contraindication
        note
        warning
        route
        nemsisRoutes {
          route
          code
          __typename
        }
        concentration {
          firstAmnt
          firstUnit
          secAmnt
          secUnit
          color
          __typename
        }
        medClass
        action
        indication
        interaction
        onset
        duration
        dripOptions {
          protocolID
          __typename
        }
        departmentID
        rangeLow
        rangeHigh
        maxDose
        minDose
        activeID
        overrideID
        status
        version
        createdBy
        modifiedBy
        metaData {
          parentID
          fullName
          nickName
          brandNames
          maxDose
          minDose
          absMaxDose
          absMinDose
          releaseNotes
          __typename
        }
        InfusionDoses {
          nextToken
          startedAt
          __typename
        }
        Concentrations {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.InfusionDosesByDripIDQueryVariables,
  APITypes.InfusionDosesByDripIDQuery
>;
export const getEquipment = /* GraphQL */ `query GetEquipment($id: ID!) {
  getEquipment(id: $id) {
    id
    name
    snomedCode
    optionItems {
      id
      amnt
      amntHigh
      rangeLow
      rangeHigh
      ageLow
      ageHigh
      index
      __typename
    }
    departmentID
    title
    note
    warning
    instruction
    activeID
    overrideID
    status
    version
    createdBy
    modifiedBy
    metaData {
      parentID
      fullName
      nickName
      brandNames
      maxDose
      minDose
      absMaxDose
      absMinDose
      releaseNotes
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetEquipmentQueryVariables,
  APITypes.GetEquipmentQuery
>;
export const listEquipment = /* GraphQL */ `query ListEquipment(
  $filter: ModelEquipmentFilterInput
  $limit: Int
  $nextToken: String
) {
  listEquipment(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      snomedCode
      optionItems {
        id
        amnt
        amntHigh
        rangeLow
        rangeHigh
        ageLow
        ageHigh
        index
        __typename
      }
      departmentID
      title
      note
      warning
      instruction
      activeID
      overrideID
      status
      version
      createdBy
      modifiedBy
      metaData {
        parentID
        fullName
        nickName
        brandNames
        maxDose
        minDose
        absMaxDose
        absMinDose
        releaseNotes
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListEquipmentQueryVariables,
  APITypes.ListEquipmentQuery
>;
export const syncEquipment = /* GraphQL */ `query SyncEquipment(
  $filter: ModelEquipmentFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncEquipment(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      name
      snomedCode
      optionItems {
        id
        amnt
        amntHigh
        rangeLow
        rangeHigh
        ageLow
        ageHigh
        index
        __typename
      }
      departmentID
      title
      note
      warning
      instruction
      activeID
      overrideID
      status
      version
      createdBy
      modifiedBy
      metaData {
        parentID
        fullName
        nickName
        brandNames
        maxDose
        minDose
        absMaxDose
        absMinDose
        releaseNotes
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncEquipmentQueryVariables,
  APITypes.SyncEquipmentQuery
>;
export const equipmentBySnomedCode = /* GraphQL */ `query EquipmentBySnomedCode(
  $snomedCode: String!
  $sortDirection: ModelSortDirection
  $filter: ModelEquipmentFilterInput
  $limit: Int
  $nextToken: String
) {
  equipmentBySnomedCode(
    snomedCode: $snomedCode
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      snomedCode
      optionItems {
        id
        amnt
        amntHigh
        rangeLow
        rangeHigh
        ageLow
        ageHigh
        index
        __typename
      }
      departmentID
      title
      note
      warning
      instruction
      activeID
      overrideID
      status
      version
      createdBy
      modifiedBy
      metaData {
        parentID
        fullName
        nickName
        brandNames
        maxDose
        minDose
        absMaxDose
        absMinDose
        releaseNotes
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.EquipmentBySnomedCodeQueryVariables,
  APITypes.EquipmentBySnomedCodeQuery
>;
export const equipmentByDepartmentID = /* GraphQL */ `query EquipmentByDepartmentID(
  $departmentID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelEquipmentFilterInput
  $limit: Int
  $nextToken: String
) {
  equipmentByDepartmentID(
    departmentID: $departmentID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      snomedCode
      optionItems {
        id
        amnt
        amntHigh
        rangeLow
        rangeHigh
        ageLow
        ageHigh
        index
        __typename
      }
      departmentID
      title
      note
      warning
      instruction
      activeID
      overrideID
      status
      version
      createdBy
      modifiedBy
      metaData {
        parentID
        fullName
        nickName
        brandNames
        maxDose
        minDose
        absMaxDose
        absMinDose
        releaseNotes
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.EquipmentByDepartmentIDQueryVariables,
  APITypes.EquipmentByDepartmentIDQuery
>;
export const getMedication = /* GraphQL */ `query GetMedication($id: ID!) {
  getMedication(id: $id) {
    id
    name
    rxNormCode
    contraindication
    note
    warning
    route
    nemsisRoutes {
      route
      code
      __typename
    }
    concentration {
      firstAmnt
      firstUnit
      secAmnt
      secUnit
      color
      __typename
    }
    medClass
    action
    indication
    interaction
    onset
    duration
    departmentID
    protocolOptions {
      protocolID
      options {
        doseID
        basis
        rangeLow
        rangeHigh
        route
        nemsisRoutes {
          route
          code
          __typename
        }
        title
        warning
        instruction
        note
        maxDose
        minDose
        maxTotalDose
        calcMax
        calcMin
        index
        ageLow
        ageHigh
        ageGroup
        repeatTime
        __typename
      }
      __typename
    }
    rangeLow
    rangeHigh
    maxDose
    minDose
    activeID
    overrideID
    status
    version
    createdBy
    modifiedBy
    metaData {
      parentID
      fullName
      nickName
      brandNames
      maxDose
      minDose
      absMaxDose
      absMinDose
      releaseNotes
      __typename
    }
    MedicationDoses {
      items {
        id
        basis
        rangeLow
        rangeHigh
        route
        nemsisRoutes {
          route
          code
          __typename
        }
        title
        warning
        instruction
        note
        maxDose
        minDose
        maxTotalDose
        calcMax
        calcMin
        index
        ageLow
        ageHigh
        ageGroup
        repeatTime
        incrementAmnt
        departmentID
        protocolID
        medicationID
        cprAssistID
        activeID
        overrideID
        status
        version
        createdBy
        modifiedBy
        createdAt
        Protocol {
          id
          name
          pdfID
          categoryID
          pairedProtocols
          medicationIDs
          equipmentIDs
          electricalIDs
          dripIDs
          formIDs
          index
          nickname
          pairedDepIDs
          rangeLow
          rangeHigh
          ageLow
          ageHigh
          ageGroup
          status
          version
          pdfVersion
          activeID
          overrideID
          privatePin
          keychainID
          createdBy
          modifiedBy
          isPublic
          isRestrictive
          color
          tags
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Medication {
          id
          name
          rxNormCode
          contraindication
          note
          warning
          route
          medClass
          action
          indication
          interaction
          onset
          duration
          departmentID
          rangeLow
          rangeHigh
          maxDose
          minDose
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        CPRAssist {
          id
          protocolIDs
          epiIDs
          defibIDs
          equipmentID
          departmentID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Concentrations {
      items {
        id
        firstAmnt
        firstUnit
        secAmnt
        secUnit
        color
        departmentID
        medicationID
        dripID
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetMedicationQueryVariables,
  APITypes.GetMedicationQuery
>;
export const listMedications = /* GraphQL */ `query ListMedications(
  $filter: ModelMedicationFilterInput
  $limit: Int
  $nextToken: String
) {
  listMedications(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      rxNormCode
      contraindication
      note
      warning
      route
      nemsisRoutes {
        route
        code
        __typename
      }
      concentration {
        firstAmnt
        firstUnit
        secAmnt
        secUnit
        color
        __typename
      }
      medClass
      action
      indication
      interaction
      onset
      duration
      departmentID
      protocolOptions {
        protocolID
        options {
          doseID
          basis
          rangeLow
          rangeHigh
          route
          title
          warning
          instruction
          note
          maxDose
          minDose
          maxTotalDose
          calcMax
          calcMin
          index
          ageLow
          ageHigh
          ageGroup
          repeatTime
          __typename
        }
        __typename
      }
      rangeLow
      rangeHigh
      maxDose
      minDose
      activeID
      overrideID
      status
      version
      createdBy
      modifiedBy
      metaData {
        parentID
        fullName
        nickName
        brandNames
        maxDose
        minDose
        absMaxDose
        absMinDose
        releaseNotes
        __typename
      }
      MedicationDoses {
        items {
          id
          basis
          rangeLow
          rangeHigh
          route
          title
          warning
          instruction
          note
          maxDose
          minDose
          maxTotalDose
          calcMax
          calcMin
          index
          ageLow
          ageHigh
          ageGroup
          repeatTime
          incrementAmnt
          departmentID
          protocolID
          medicationID
          cprAssistID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Concentrations {
        items {
          id
          firstAmnt
          firstUnit
          secAmnt
          secUnit
          color
          departmentID
          medicationID
          dripID
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListMedicationsQueryVariables,
  APITypes.ListMedicationsQuery
>;
export const syncMedications = /* GraphQL */ `query SyncMedications(
  $filter: ModelMedicationFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncMedications(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      name
      rxNormCode
      contraindication
      note
      warning
      route
      nemsisRoutes {
        route
        code
        __typename
      }
      concentration {
        firstAmnt
        firstUnit
        secAmnt
        secUnit
        color
        __typename
      }
      medClass
      action
      indication
      interaction
      onset
      duration
      departmentID
      protocolOptions {
        protocolID
        options {
          doseID
          basis
          rangeLow
          rangeHigh
          route
          title
          warning
          instruction
          note
          maxDose
          minDose
          maxTotalDose
          calcMax
          calcMin
          index
          ageLow
          ageHigh
          ageGroup
          repeatTime
          __typename
        }
        __typename
      }
      rangeLow
      rangeHigh
      maxDose
      minDose
      activeID
      overrideID
      status
      version
      createdBy
      modifiedBy
      metaData {
        parentID
        fullName
        nickName
        brandNames
        maxDose
        minDose
        absMaxDose
        absMinDose
        releaseNotes
        __typename
      }
      MedicationDoses {
        items {
          id
          basis
          rangeLow
          rangeHigh
          route
          title
          warning
          instruction
          note
          maxDose
          minDose
          maxTotalDose
          calcMax
          calcMin
          index
          ageLow
          ageHigh
          ageGroup
          repeatTime
          incrementAmnt
          departmentID
          protocolID
          medicationID
          cprAssistID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Concentrations {
        items {
          id
          firstAmnt
          firstUnit
          secAmnt
          secUnit
          color
          departmentID
          medicationID
          dripID
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncMedicationsQueryVariables,
  APITypes.SyncMedicationsQuery
>;
export const medicationsByRxNormCode = /* GraphQL */ `query MedicationsByRxNormCode(
  $rxNormCode: String!
  $sortDirection: ModelSortDirection
  $filter: ModelMedicationFilterInput
  $limit: Int
  $nextToken: String
) {
  medicationsByRxNormCode(
    rxNormCode: $rxNormCode
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      rxNormCode
      contraindication
      note
      warning
      route
      nemsisRoutes {
        route
        code
        __typename
      }
      concentration {
        firstAmnt
        firstUnit
        secAmnt
        secUnit
        color
        __typename
      }
      medClass
      action
      indication
      interaction
      onset
      duration
      departmentID
      protocolOptions {
        protocolID
        options {
          doseID
          basis
          rangeLow
          rangeHigh
          route
          title
          warning
          instruction
          note
          maxDose
          minDose
          maxTotalDose
          calcMax
          calcMin
          index
          ageLow
          ageHigh
          ageGroup
          repeatTime
          __typename
        }
        __typename
      }
      rangeLow
      rangeHigh
      maxDose
      minDose
      activeID
      overrideID
      status
      version
      createdBy
      modifiedBy
      metaData {
        parentID
        fullName
        nickName
        brandNames
        maxDose
        minDose
        absMaxDose
        absMinDose
        releaseNotes
        __typename
      }
      MedicationDoses {
        items {
          id
          basis
          rangeLow
          rangeHigh
          route
          title
          warning
          instruction
          note
          maxDose
          minDose
          maxTotalDose
          calcMax
          calcMin
          index
          ageLow
          ageHigh
          ageGroup
          repeatTime
          incrementAmnt
          departmentID
          protocolID
          medicationID
          cprAssistID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Concentrations {
        items {
          id
          firstAmnt
          firstUnit
          secAmnt
          secUnit
          color
          departmentID
          medicationID
          dripID
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.MedicationsByRxNormCodeQueryVariables,
  APITypes.MedicationsByRxNormCodeQuery
>;
export const medicationsByDepartmentID = /* GraphQL */ `query MedicationsByDepartmentID(
  $departmentID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelMedicationFilterInput
  $limit: Int
  $nextToken: String
) {
  medicationsByDepartmentID(
    departmentID: $departmentID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      rxNormCode
      contraindication
      note
      warning
      route
      nemsisRoutes {
        route
        code
        __typename
      }
      concentration {
        firstAmnt
        firstUnit
        secAmnt
        secUnit
        color
        __typename
      }
      medClass
      action
      indication
      interaction
      onset
      duration
      departmentID
      protocolOptions {
        protocolID
        options {
          doseID
          basis
          rangeLow
          rangeHigh
          route
          title
          warning
          instruction
          note
          maxDose
          minDose
          maxTotalDose
          calcMax
          calcMin
          index
          ageLow
          ageHigh
          ageGroup
          repeatTime
          __typename
        }
        __typename
      }
      rangeLow
      rangeHigh
      maxDose
      minDose
      activeID
      overrideID
      status
      version
      createdBy
      modifiedBy
      metaData {
        parentID
        fullName
        nickName
        brandNames
        maxDose
        minDose
        absMaxDose
        absMinDose
        releaseNotes
        __typename
      }
      MedicationDoses {
        items {
          id
          basis
          rangeLow
          rangeHigh
          route
          title
          warning
          instruction
          note
          maxDose
          minDose
          maxTotalDose
          calcMax
          calcMin
          index
          ageLow
          ageHigh
          ageGroup
          repeatTime
          incrementAmnt
          departmentID
          protocolID
          medicationID
          cprAssistID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Concentrations {
        items {
          id
          firstAmnt
          firstUnit
          secAmnt
          secUnit
          color
          departmentID
          medicationID
          dripID
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.MedicationsByDepartmentIDQueryVariables,
  APITypes.MedicationsByDepartmentIDQuery
>;
export const getMedicationDose = /* GraphQL */ `query GetMedicationDose($id: ID!) {
  getMedicationDose(id: $id) {
    id
    basis
    rangeLow
    rangeHigh
    route
    nemsisRoutes {
      route
      code
      __typename
    }
    title
    warning
    instruction
    note
    maxDose
    minDose
    maxTotalDose
    calcMax
    calcMin
    index
    ageLow
    ageHigh
    ageGroup
    repeatTime
    incrementAmnt
    departmentID
    protocolID
    medicationID
    cprAssistID
    activeID
    overrideID
    status
    version
    createdBy
    modifiedBy
    createdAt
    Protocol {
      id
      name
      pdfID
      categoryID
      pairedProtocols
      medicationIDs
      equipmentIDs
      electricalIDs
      dripIDs
      formIDs
      index
      nickname
      pairedDepIDs
      rangeLow
      rangeHigh
      ageLow
      ageHigh
      ageGroup
      status
      version
      pdfVersion
      activeID
      overrideID
      privatePin
      keychainID
      createdBy
      modifiedBy
      isPublic
      isRestrictive
      color
      tags
      departmentID
      MedicationDoses {
        items {
          id
          basis
          rangeLow
          rangeHigh
          route
          title
          warning
          instruction
          note
          maxDose
          minDose
          maxTotalDose
          calcMax
          calcMin
          index
          ageLow
          ageHigh
          ageGroup
          repeatTime
          incrementAmnt
          departmentID
          protocolID
          medicationID
          cprAssistID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      ElectricalDoses {
        items {
          id
          basis
          rangeLow
          rangeHigh
          title
          warning
          instruction
          note
          maxDose
          minDose
          maxTotalDose
          calcMax
          calcMin
          index
          ageLow
          ageHigh
          ageGroup
          repeatTime
          incrementAmnt
          departmentID
          protocolID
          electricalID
          cprAssistID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      DripDoses {
        items {
          id
          basis
          rangeLow
          rangeHigh
          route
          title
          warning
          instruction
          note
          maxDose
          minDose
          maxTotalDose
          calcMax
          calcMin
          index
          ageLow
          ageHigh
          ageGroup
          repeatTime
          incrementAmnt
          departmentID
          protocolID
          dripID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    Medication {
      id
      name
      rxNormCode
      contraindication
      note
      warning
      route
      nemsisRoutes {
        route
        code
        __typename
      }
      concentration {
        firstAmnt
        firstUnit
        secAmnt
        secUnit
        color
        __typename
      }
      medClass
      action
      indication
      interaction
      onset
      duration
      departmentID
      protocolOptions {
        protocolID
        options {
          doseID
          basis
          rangeLow
          rangeHigh
          route
          title
          warning
          instruction
          note
          maxDose
          minDose
          maxTotalDose
          calcMax
          calcMin
          index
          ageLow
          ageHigh
          ageGroup
          repeatTime
          __typename
        }
        __typename
      }
      rangeLow
      rangeHigh
      maxDose
      minDose
      activeID
      overrideID
      status
      version
      createdBy
      modifiedBy
      metaData {
        parentID
        fullName
        nickName
        brandNames
        maxDose
        minDose
        absMaxDose
        absMinDose
        releaseNotes
        __typename
      }
      MedicationDoses {
        items {
          id
          basis
          rangeLow
          rangeHigh
          route
          title
          warning
          instruction
          note
          maxDose
          minDose
          maxTotalDose
          calcMax
          calcMin
          index
          ageLow
          ageHigh
          ageGroup
          repeatTime
          incrementAmnt
          departmentID
          protocolID
          medicationID
          cprAssistID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Concentrations {
        items {
          id
          firstAmnt
          firstUnit
          secAmnt
          secUnit
          color
          departmentID
          medicationID
          dripID
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    CPRAssist {
      id
      protocolIDs
      epiIDs
      defibIDs
      equipmentID
      departmentID
      activeID
      overrideID
      status
      version
      createdBy
      modifiedBy
      cprMetaData {
        defaultMode
        epiOrangeSec
        epiRedSec
        compressionRate
        ventRatePerMin
        ventilateSoundDelay
        ventilateLoops
        continousVentilateLoops
        ventilateSoundSpeed
        pulseCheckTime
        chargingTime
        __typename
      }
      DefibDoses {
        items {
          id
          basis
          rangeLow
          rangeHigh
          title
          warning
          instruction
          note
          maxDose
          minDose
          maxTotalDose
          calcMax
          calcMin
          index
          ageLow
          ageHigh
          ageGroup
          repeatTime
          incrementAmnt
          departmentID
          protocolID
          electricalID
          cprAssistID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      EpiDoses {
        items {
          id
          basis
          rangeLow
          rangeHigh
          route
          title
          warning
          instruction
          note
          maxDose
          minDose
          maxTotalDose
          calcMax
          calcMin
          index
          ageLow
          ageHigh
          ageGroup
          repeatTime
          incrementAmnt
          departmentID
          protocolID
          medicationID
          cprAssistID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetMedicationDoseQueryVariables,
  APITypes.GetMedicationDoseQuery
>;
export const listMedicationDoses = /* GraphQL */ `query ListMedicationDoses(
  $filter: ModelMedicationDoseFilterInput
  $limit: Int
  $nextToken: String
) {
  listMedicationDoses(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      basis
      rangeLow
      rangeHigh
      route
      nemsisRoutes {
        route
        code
        __typename
      }
      title
      warning
      instruction
      note
      maxDose
      minDose
      maxTotalDose
      calcMax
      calcMin
      index
      ageLow
      ageHigh
      ageGroup
      repeatTime
      incrementAmnt
      departmentID
      protocolID
      medicationID
      cprAssistID
      activeID
      overrideID
      status
      version
      createdBy
      modifiedBy
      createdAt
      Protocol {
        id
        name
        pdfID
        categoryID
        pairedProtocols
        medicationIDs
        equipmentIDs
        electricalIDs
        dripIDs
        formIDs
        index
        nickname
        pairedDepIDs
        rangeLow
        rangeHigh
        ageLow
        ageHigh
        ageGroup
        status
        version
        pdfVersion
        activeID
        overrideID
        privatePin
        keychainID
        createdBy
        modifiedBy
        isPublic
        isRestrictive
        color
        tags
        departmentID
        MedicationDoses {
          nextToken
          startedAt
          __typename
        }
        ElectricalDoses {
          nextToken
          startedAt
          __typename
        }
        DripDoses {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Medication {
        id
        name
        rxNormCode
        contraindication
        note
        warning
        route
        nemsisRoutes {
          route
          code
          __typename
        }
        concentration {
          firstAmnt
          firstUnit
          secAmnt
          secUnit
          color
          __typename
        }
        medClass
        action
        indication
        interaction
        onset
        duration
        departmentID
        protocolOptions {
          protocolID
          __typename
        }
        rangeLow
        rangeHigh
        maxDose
        minDose
        activeID
        overrideID
        status
        version
        createdBy
        modifiedBy
        metaData {
          parentID
          fullName
          nickName
          brandNames
          maxDose
          minDose
          absMaxDose
          absMinDose
          releaseNotes
          __typename
        }
        MedicationDoses {
          nextToken
          startedAt
          __typename
        }
        Concentrations {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      CPRAssist {
        id
        protocolIDs
        epiIDs
        defibIDs
        equipmentID
        departmentID
        activeID
        overrideID
        status
        version
        createdBy
        modifiedBy
        cprMetaData {
          defaultMode
          epiOrangeSec
          epiRedSec
          compressionRate
          ventRatePerMin
          ventilateSoundDelay
          ventilateLoops
          continousVentilateLoops
          ventilateSoundSpeed
          pulseCheckTime
          chargingTime
          __typename
        }
        DefibDoses {
          nextToken
          startedAt
          __typename
        }
        EpiDoses {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListMedicationDosesQueryVariables,
  APITypes.ListMedicationDosesQuery
>;
export const syncMedicationDoses = /* GraphQL */ `query SyncMedicationDoses(
  $filter: ModelMedicationDoseFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncMedicationDoses(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      basis
      rangeLow
      rangeHigh
      route
      nemsisRoutes {
        route
        code
        __typename
      }
      title
      warning
      instruction
      note
      maxDose
      minDose
      maxTotalDose
      calcMax
      calcMin
      index
      ageLow
      ageHigh
      ageGroup
      repeatTime
      incrementAmnt
      departmentID
      protocolID
      medicationID
      cprAssistID
      activeID
      overrideID
      status
      version
      createdBy
      modifiedBy
      createdAt
      Protocol {
        id
        name
        pdfID
        categoryID
        pairedProtocols
        medicationIDs
        equipmentIDs
        electricalIDs
        dripIDs
        formIDs
        index
        nickname
        pairedDepIDs
        rangeLow
        rangeHigh
        ageLow
        ageHigh
        ageGroup
        status
        version
        pdfVersion
        activeID
        overrideID
        privatePin
        keychainID
        createdBy
        modifiedBy
        isPublic
        isRestrictive
        color
        tags
        departmentID
        MedicationDoses {
          nextToken
          startedAt
          __typename
        }
        ElectricalDoses {
          nextToken
          startedAt
          __typename
        }
        DripDoses {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Medication {
        id
        name
        rxNormCode
        contraindication
        note
        warning
        route
        nemsisRoutes {
          route
          code
          __typename
        }
        concentration {
          firstAmnt
          firstUnit
          secAmnt
          secUnit
          color
          __typename
        }
        medClass
        action
        indication
        interaction
        onset
        duration
        departmentID
        protocolOptions {
          protocolID
          __typename
        }
        rangeLow
        rangeHigh
        maxDose
        minDose
        activeID
        overrideID
        status
        version
        createdBy
        modifiedBy
        metaData {
          parentID
          fullName
          nickName
          brandNames
          maxDose
          minDose
          absMaxDose
          absMinDose
          releaseNotes
          __typename
        }
        MedicationDoses {
          nextToken
          startedAt
          __typename
        }
        Concentrations {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      CPRAssist {
        id
        protocolIDs
        epiIDs
        defibIDs
        equipmentID
        departmentID
        activeID
        overrideID
        status
        version
        createdBy
        modifiedBy
        cprMetaData {
          defaultMode
          epiOrangeSec
          epiRedSec
          compressionRate
          ventRatePerMin
          ventilateSoundDelay
          ventilateLoops
          continousVentilateLoops
          ventilateSoundSpeed
          pulseCheckTime
          chargingTime
          __typename
        }
        DefibDoses {
          nextToken
          startedAt
          __typename
        }
        EpiDoses {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncMedicationDosesQueryVariables,
  APITypes.SyncMedicationDosesQuery
>;
export const medicationDosesByDepartmentID = /* GraphQL */ `query MedicationDosesByDepartmentID(
  $departmentID: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelMedicationDoseFilterInput
  $limit: Int
  $nextToken: String
) {
  medicationDosesByDepartmentID(
    departmentID: $departmentID
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      basis
      rangeLow
      rangeHigh
      route
      nemsisRoutes {
        route
        code
        __typename
      }
      title
      warning
      instruction
      note
      maxDose
      minDose
      maxTotalDose
      calcMax
      calcMin
      index
      ageLow
      ageHigh
      ageGroup
      repeatTime
      incrementAmnt
      departmentID
      protocolID
      medicationID
      cprAssistID
      activeID
      overrideID
      status
      version
      createdBy
      modifiedBy
      createdAt
      Protocol {
        id
        name
        pdfID
        categoryID
        pairedProtocols
        medicationIDs
        equipmentIDs
        electricalIDs
        dripIDs
        formIDs
        index
        nickname
        pairedDepIDs
        rangeLow
        rangeHigh
        ageLow
        ageHigh
        ageGroup
        status
        version
        pdfVersion
        activeID
        overrideID
        privatePin
        keychainID
        createdBy
        modifiedBy
        isPublic
        isRestrictive
        color
        tags
        departmentID
        MedicationDoses {
          nextToken
          startedAt
          __typename
        }
        ElectricalDoses {
          nextToken
          startedAt
          __typename
        }
        DripDoses {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Medication {
        id
        name
        rxNormCode
        contraindication
        note
        warning
        route
        nemsisRoutes {
          route
          code
          __typename
        }
        concentration {
          firstAmnt
          firstUnit
          secAmnt
          secUnit
          color
          __typename
        }
        medClass
        action
        indication
        interaction
        onset
        duration
        departmentID
        protocolOptions {
          protocolID
          __typename
        }
        rangeLow
        rangeHigh
        maxDose
        minDose
        activeID
        overrideID
        status
        version
        createdBy
        modifiedBy
        metaData {
          parentID
          fullName
          nickName
          brandNames
          maxDose
          minDose
          absMaxDose
          absMinDose
          releaseNotes
          __typename
        }
        MedicationDoses {
          nextToken
          startedAt
          __typename
        }
        Concentrations {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      CPRAssist {
        id
        protocolIDs
        epiIDs
        defibIDs
        equipmentID
        departmentID
        activeID
        overrideID
        status
        version
        createdBy
        modifiedBy
        cprMetaData {
          defaultMode
          epiOrangeSec
          epiRedSec
          compressionRate
          ventRatePerMin
          ventilateSoundDelay
          ventilateLoops
          continousVentilateLoops
          ventilateSoundSpeed
          pulseCheckTime
          chargingTime
          __typename
        }
        DefibDoses {
          nextToken
          startedAt
          __typename
        }
        EpiDoses {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.MedicationDosesByDepartmentIDQueryVariables,
  APITypes.MedicationDosesByDepartmentIDQuery
>;
export const medicationDosesByProtocolID = /* GraphQL */ `query MedicationDosesByProtocolID(
  $protocolID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelMedicationDoseFilterInput
  $limit: Int
  $nextToken: String
) {
  medicationDosesByProtocolID(
    protocolID: $protocolID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      basis
      rangeLow
      rangeHigh
      route
      nemsisRoutes {
        route
        code
        __typename
      }
      title
      warning
      instruction
      note
      maxDose
      minDose
      maxTotalDose
      calcMax
      calcMin
      index
      ageLow
      ageHigh
      ageGroup
      repeatTime
      incrementAmnt
      departmentID
      protocolID
      medicationID
      cprAssistID
      activeID
      overrideID
      status
      version
      createdBy
      modifiedBy
      createdAt
      Protocol {
        id
        name
        pdfID
        categoryID
        pairedProtocols
        medicationIDs
        equipmentIDs
        electricalIDs
        dripIDs
        formIDs
        index
        nickname
        pairedDepIDs
        rangeLow
        rangeHigh
        ageLow
        ageHigh
        ageGroup
        status
        version
        pdfVersion
        activeID
        overrideID
        privatePin
        keychainID
        createdBy
        modifiedBy
        isPublic
        isRestrictive
        color
        tags
        departmentID
        MedicationDoses {
          nextToken
          startedAt
          __typename
        }
        ElectricalDoses {
          nextToken
          startedAt
          __typename
        }
        DripDoses {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Medication {
        id
        name
        rxNormCode
        contraindication
        note
        warning
        route
        nemsisRoutes {
          route
          code
          __typename
        }
        concentration {
          firstAmnt
          firstUnit
          secAmnt
          secUnit
          color
          __typename
        }
        medClass
        action
        indication
        interaction
        onset
        duration
        departmentID
        protocolOptions {
          protocolID
          __typename
        }
        rangeLow
        rangeHigh
        maxDose
        minDose
        activeID
        overrideID
        status
        version
        createdBy
        modifiedBy
        metaData {
          parentID
          fullName
          nickName
          brandNames
          maxDose
          minDose
          absMaxDose
          absMinDose
          releaseNotes
          __typename
        }
        MedicationDoses {
          nextToken
          startedAt
          __typename
        }
        Concentrations {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      CPRAssist {
        id
        protocolIDs
        epiIDs
        defibIDs
        equipmentID
        departmentID
        activeID
        overrideID
        status
        version
        createdBy
        modifiedBy
        cprMetaData {
          defaultMode
          epiOrangeSec
          epiRedSec
          compressionRate
          ventRatePerMin
          ventilateSoundDelay
          ventilateLoops
          continousVentilateLoops
          ventilateSoundSpeed
          pulseCheckTime
          chargingTime
          __typename
        }
        DefibDoses {
          nextToken
          startedAt
          __typename
        }
        EpiDoses {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.MedicationDosesByProtocolIDQueryVariables,
  APITypes.MedicationDosesByProtocolIDQuery
>;
export const medicationDosesByMedicationID = /* GraphQL */ `query MedicationDosesByMedicationID(
  $medicationID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelMedicationDoseFilterInput
  $limit: Int
  $nextToken: String
) {
  medicationDosesByMedicationID(
    medicationID: $medicationID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      basis
      rangeLow
      rangeHigh
      route
      nemsisRoutes {
        route
        code
        __typename
      }
      title
      warning
      instruction
      note
      maxDose
      minDose
      maxTotalDose
      calcMax
      calcMin
      index
      ageLow
      ageHigh
      ageGroup
      repeatTime
      incrementAmnt
      departmentID
      protocolID
      medicationID
      cprAssistID
      activeID
      overrideID
      status
      version
      createdBy
      modifiedBy
      createdAt
      Protocol {
        id
        name
        pdfID
        categoryID
        pairedProtocols
        medicationIDs
        equipmentIDs
        electricalIDs
        dripIDs
        formIDs
        index
        nickname
        pairedDepIDs
        rangeLow
        rangeHigh
        ageLow
        ageHigh
        ageGroup
        status
        version
        pdfVersion
        activeID
        overrideID
        privatePin
        keychainID
        createdBy
        modifiedBy
        isPublic
        isRestrictive
        color
        tags
        departmentID
        MedicationDoses {
          nextToken
          startedAt
          __typename
        }
        ElectricalDoses {
          nextToken
          startedAt
          __typename
        }
        DripDoses {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Medication {
        id
        name
        rxNormCode
        contraindication
        note
        warning
        route
        nemsisRoutes {
          route
          code
          __typename
        }
        concentration {
          firstAmnt
          firstUnit
          secAmnt
          secUnit
          color
          __typename
        }
        medClass
        action
        indication
        interaction
        onset
        duration
        departmentID
        protocolOptions {
          protocolID
          __typename
        }
        rangeLow
        rangeHigh
        maxDose
        minDose
        activeID
        overrideID
        status
        version
        createdBy
        modifiedBy
        metaData {
          parentID
          fullName
          nickName
          brandNames
          maxDose
          minDose
          absMaxDose
          absMinDose
          releaseNotes
          __typename
        }
        MedicationDoses {
          nextToken
          startedAt
          __typename
        }
        Concentrations {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      CPRAssist {
        id
        protocolIDs
        epiIDs
        defibIDs
        equipmentID
        departmentID
        activeID
        overrideID
        status
        version
        createdBy
        modifiedBy
        cprMetaData {
          defaultMode
          epiOrangeSec
          epiRedSec
          compressionRate
          ventRatePerMin
          ventilateSoundDelay
          ventilateLoops
          continousVentilateLoops
          ventilateSoundSpeed
          pulseCheckTime
          chargingTime
          __typename
        }
        DefibDoses {
          nextToken
          startedAt
          __typename
        }
        EpiDoses {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.MedicationDosesByMedicationIDQueryVariables,
  APITypes.MedicationDosesByMedicationIDQuery
>;
export const medicationDosesByCprAssistID = /* GraphQL */ `query MedicationDosesByCprAssistID(
  $cprAssistID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelMedicationDoseFilterInput
  $limit: Int
  $nextToken: String
) {
  medicationDosesByCprAssistID(
    cprAssistID: $cprAssistID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      basis
      rangeLow
      rangeHigh
      route
      nemsisRoutes {
        route
        code
        __typename
      }
      title
      warning
      instruction
      note
      maxDose
      minDose
      maxTotalDose
      calcMax
      calcMin
      index
      ageLow
      ageHigh
      ageGroup
      repeatTime
      incrementAmnt
      departmentID
      protocolID
      medicationID
      cprAssistID
      activeID
      overrideID
      status
      version
      createdBy
      modifiedBy
      createdAt
      Protocol {
        id
        name
        pdfID
        categoryID
        pairedProtocols
        medicationIDs
        equipmentIDs
        electricalIDs
        dripIDs
        formIDs
        index
        nickname
        pairedDepIDs
        rangeLow
        rangeHigh
        ageLow
        ageHigh
        ageGroup
        status
        version
        pdfVersion
        activeID
        overrideID
        privatePin
        keychainID
        createdBy
        modifiedBy
        isPublic
        isRestrictive
        color
        tags
        departmentID
        MedicationDoses {
          nextToken
          startedAt
          __typename
        }
        ElectricalDoses {
          nextToken
          startedAt
          __typename
        }
        DripDoses {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Medication {
        id
        name
        rxNormCode
        contraindication
        note
        warning
        route
        nemsisRoutes {
          route
          code
          __typename
        }
        concentration {
          firstAmnt
          firstUnit
          secAmnt
          secUnit
          color
          __typename
        }
        medClass
        action
        indication
        interaction
        onset
        duration
        departmentID
        protocolOptions {
          protocolID
          __typename
        }
        rangeLow
        rangeHigh
        maxDose
        minDose
        activeID
        overrideID
        status
        version
        createdBy
        modifiedBy
        metaData {
          parentID
          fullName
          nickName
          brandNames
          maxDose
          minDose
          absMaxDose
          absMinDose
          releaseNotes
          __typename
        }
        MedicationDoses {
          nextToken
          startedAt
          __typename
        }
        Concentrations {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      CPRAssist {
        id
        protocolIDs
        epiIDs
        defibIDs
        equipmentID
        departmentID
        activeID
        overrideID
        status
        version
        createdBy
        modifiedBy
        cprMetaData {
          defaultMode
          epiOrangeSec
          epiRedSec
          compressionRate
          ventRatePerMin
          ventilateSoundDelay
          ventilateLoops
          continousVentilateLoops
          ventilateSoundSpeed
          pulseCheckTime
          chargingTime
          __typename
        }
        DefibDoses {
          nextToken
          startedAt
          __typename
        }
        EpiDoses {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.MedicationDosesByCprAssistIDQueryVariables,
  APITypes.MedicationDosesByCprAssistIDQuery
>;
export const getProtocol = /* GraphQL */ `query GetProtocol($id: ID!) {
  getProtocol(id: $id) {
    id
    name
    pdfID
    categoryID
    pairedProtocols
    medicationIDs
    equipmentIDs
    electricalIDs
    dripIDs
    formIDs
    index
    nickname
    pairedDepIDs
    rangeLow
    rangeHigh
    ageLow
    ageHigh
    ageGroup
    status
    version
    pdfVersion
    activeID
    overrideID
    privatePin
    keychainID
    createdBy
    modifiedBy
    isPublic
    isRestrictive
    color
    tags
    departmentID
    MedicationDoses {
      items {
        id
        basis
        rangeLow
        rangeHigh
        route
        nemsisRoutes {
          route
          code
          __typename
        }
        title
        warning
        instruction
        note
        maxDose
        minDose
        maxTotalDose
        calcMax
        calcMin
        index
        ageLow
        ageHigh
        ageGroup
        repeatTime
        incrementAmnt
        departmentID
        protocolID
        medicationID
        cprAssistID
        activeID
        overrideID
        status
        version
        createdBy
        modifiedBy
        createdAt
        Protocol {
          id
          name
          pdfID
          categoryID
          pairedProtocols
          medicationIDs
          equipmentIDs
          electricalIDs
          dripIDs
          formIDs
          index
          nickname
          pairedDepIDs
          rangeLow
          rangeHigh
          ageLow
          ageHigh
          ageGroup
          status
          version
          pdfVersion
          activeID
          overrideID
          privatePin
          keychainID
          createdBy
          modifiedBy
          isPublic
          isRestrictive
          color
          tags
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Medication {
          id
          name
          rxNormCode
          contraindication
          note
          warning
          route
          medClass
          action
          indication
          interaction
          onset
          duration
          departmentID
          rangeLow
          rangeHigh
          maxDose
          minDose
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        CPRAssist {
          id
          protocolIDs
          epiIDs
          defibIDs
          equipmentID
          departmentID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    ElectricalDoses {
      items {
        id
        basis
        rangeLow
        rangeHigh
        title
        warning
        instruction
        note
        maxDose
        minDose
        maxTotalDose
        calcMax
        calcMin
        index
        ageLow
        ageHigh
        ageGroup
        repeatTime
        incrementAmnt
        departmentID
        protocolID
        electricalID
        cprAssistID
        activeID
        overrideID
        status
        version
        createdBy
        modifiedBy
        createdAt
        Protocol {
          id
          name
          pdfID
          categoryID
          pairedProtocols
          medicationIDs
          equipmentIDs
          electricalIDs
          dripIDs
          formIDs
          index
          nickname
          pairedDepIDs
          rangeLow
          rangeHigh
          ageLow
          ageHigh
          ageGroup
          status
          version
          pdfVersion
          activeID
          overrideID
          privatePin
          keychainID
          createdBy
          modifiedBy
          isPublic
          isRestrictive
          color
          tags
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Electrical {
          id
          title
          rxNormCode
          warning
          instruction
          note
          rangeHigh
          rangeLow
          departmentID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        CPRAssist {
          id
          protocolIDs
          epiIDs
          defibIDs
          equipmentID
          departmentID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    DripDoses {
      items {
        id
        basis
        rangeLow
        rangeHigh
        route
        nemsisRoutes {
          route
          code
          __typename
        }
        title
        warning
        instruction
        note
        maxDose
        minDose
        maxTotalDose
        calcMax
        calcMin
        index
        ageLow
        ageHigh
        ageGroup
        repeatTime
        incrementAmnt
        departmentID
        protocolID
        dripID
        activeID
        overrideID
        status
        version
        createdBy
        modifiedBy
        createdAt
        Protocol {
          id
          name
          pdfID
          categoryID
          pairedProtocols
          medicationIDs
          equipmentIDs
          electricalIDs
          dripIDs
          formIDs
          index
          nickname
          pairedDepIDs
          rangeLow
          rangeHigh
          ageLow
          ageHigh
          ageGroup
          status
          version
          pdfVersion
          activeID
          overrideID
          privatePin
          keychainID
          createdBy
          modifiedBy
          isPublic
          isRestrictive
          color
          tags
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Drip {
          id
          name
          rxNormCode
          contraindication
          note
          warning
          route
          medClass
          action
          indication
          interaction
          onset
          duration
          departmentID
          rangeLow
          rangeHigh
          maxDose
          minDose
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetProtocolQueryVariables,
  APITypes.GetProtocolQuery
>;
export const listProtocols = /* GraphQL */ `query ListProtocols(
  $filter: ModelProtocolFilterInput
  $limit: Int
  $nextToken: String
) {
  listProtocols(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      pdfID
      categoryID
      pairedProtocols
      medicationIDs
      equipmentIDs
      electricalIDs
      dripIDs
      formIDs
      index
      nickname
      pairedDepIDs
      rangeLow
      rangeHigh
      ageLow
      ageHigh
      ageGroup
      status
      version
      pdfVersion
      activeID
      overrideID
      privatePin
      keychainID
      createdBy
      modifiedBy
      isPublic
      isRestrictive
      color
      tags
      departmentID
      MedicationDoses {
        items {
          id
          basis
          rangeLow
          rangeHigh
          route
          title
          warning
          instruction
          note
          maxDose
          minDose
          maxTotalDose
          calcMax
          calcMin
          index
          ageLow
          ageHigh
          ageGroup
          repeatTime
          incrementAmnt
          departmentID
          protocolID
          medicationID
          cprAssistID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      ElectricalDoses {
        items {
          id
          basis
          rangeLow
          rangeHigh
          title
          warning
          instruction
          note
          maxDose
          minDose
          maxTotalDose
          calcMax
          calcMin
          index
          ageLow
          ageHigh
          ageGroup
          repeatTime
          incrementAmnt
          departmentID
          protocolID
          electricalID
          cprAssistID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      DripDoses {
        items {
          id
          basis
          rangeLow
          rangeHigh
          route
          title
          warning
          instruction
          note
          maxDose
          minDose
          maxTotalDose
          calcMax
          calcMin
          index
          ageLow
          ageHigh
          ageGroup
          repeatTime
          incrementAmnt
          departmentID
          protocolID
          dripID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListProtocolsQueryVariables,
  APITypes.ListProtocolsQuery
>;
export const syncProtocols = /* GraphQL */ `query SyncProtocols(
  $filter: ModelProtocolFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncProtocols(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      name
      pdfID
      categoryID
      pairedProtocols
      medicationIDs
      equipmentIDs
      electricalIDs
      dripIDs
      formIDs
      index
      nickname
      pairedDepIDs
      rangeLow
      rangeHigh
      ageLow
      ageHigh
      ageGroup
      status
      version
      pdfVersion
      activeID
      overrideID
      privatePin
      keychainID
      createdBy
      modifiedBy
      isPublic
      isRestrictive
      color
      tags
      departmentID
      MedicationDoses {
        items {
          id
          basis
          rangeLow
          rangeHigh
          route
          title
          warning
          instruction
          note
          maxDose
          minDose
          maxTotalDose
          calcMax
          calcMin
          index
          ageLow
          ageHigh
          ageGroup
          repeatTime
          incrementAmnt
          departmentID
          protocolID
          medicationID
          cprAssistID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      ElectricalDoses {
        items {
          id
          basis
          rangeLow
          rangeHigh
          title
          warning
          instruction
          note
          maxDose
          minDose
          maxTotalDose
          calcMax
          calcMin
          index
          ageLow
          ageHigh
          ageGroup
          repeatTime
          incrementAmnt
          departmentID
          protocolID
          electricalID
          cprAssistID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      DripDoses {
        items {
          id
          basis
          rangeLow
          rangeHigh
          route
          title
          warning
          instruction
          note
          maxDose
          minDose
          maxTotalDose
          calcMax
          calcMin
          index
          ageLow
          ageHigh
          ageGroup
          repeatTime
          incrementAmnt
          departmentID
          protocolID
          dripID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncProtocolsQueryVariables,
  APITypes.SyncProtocolsQuery
>;
export const protocolsByCategoryID = /* GraphQL */ `query ProtocolsByCategoryID(
  $categoryID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelProtocolFilterInput
  $limit: Int
  $nextToken: String
) {
  protocolsByCategoryID(
    categoryID: $categoryID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      pdfID
      categoryID
      pairedProtocols
      medicationIDs
      equipmentIDs
      electricalIDs
      dripIDs
      formIDs
      index
      nickname
      pairedDepIDs
      rangeLow
      rangeHigh
      ageLow
      ageHigh
      ageGroup
      status
      version
      pdfVersion
      activeID
      overrideID
      privatePin
      keychainID
      createdBy
      modifiedBy
      isPublic
      isRestrictive
      color
      tags
      departmentID
      MedicationDoses {
        items {
          id
          basis
          rangeLow
          rangeHigh
          route
          title
          warning
          instruction
          note
          maxDose
          minDose
          maxTotalDose
          calcMax
          calcMin
          index
          ageLow
          ageHigh
          ageGroup
          repeatTime
          incrementAmnt
          departmentID
          protocolID
          medicationID
          cprAssistID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      ElectricalDoses {
        items {
          id
          basis
          rangeLow
          rangeHigh
          title
          warning
          instruction
          note
          maxDose
          minDose
          maxTotalDose
          calcMax
          calcMin
          index
          ageLow
          ageHigh
          ageGroup
          repeatTime
          incrementAmnt
          departmentID
          protocolID
          electricalID
          cprAssistID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      DripDoses {
        items {
          id
          basis
          rangeLow
          rangeHigh
          route
          title
          warning
          instruction
          note
          maxDose
          minDose
          maxTotalDose
          calcMax
          calcMin
          index
          ageLow
          ageHigh
          ageGroup
          repeatTime
          incrementAmnt
          departmentID
          protocolID
          dripID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ProtocolsByCategoryIDQueryVariables,
  APITypes.ProtocolsByCategoryIDQuery
>;
export const protocolsByKeychainID = /* GraphQL */ `query ProtocolsByKeychainID(
  $keychainID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelProtocolFilterInput
  $limit: Int
  $nextToken: String
) {
  protocolsByKeychainID(
    keychainID: $keychainID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      pdfID
      categoryID
      pairedProtocols
      medicationIDs
      equipmentIDs
      electricalIDs
      dripIDs
      formIDs
      index
      nickname
      pairedDepIDs
      rangeLow
      rangeHigh
      ageLow
      ageHigh
      ageGroup
      status
      version
      pdfVersion
      activeID
      overrideID
      privatePin
      keychainID
      createdBy
      modifiedBy
      isPublic
      isRestrictive
      color
      tags
      departmentID
      MedicationDoses {
        items {
          id
          basis
          rangeLow
          rangeHigh
          route
          title
          warning
          instruction
          note
          maxDose
          minDose
          maxTotalDose
          calcMax
          calcMin
          index
          ageLow
          ageHigh
          ageGroup
          repeatTime
          incrementAmnt
          departmentID
          protocolID
          medicationID
          cprAssistID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      ElectricalDoses {
        items {
          id
          basis
          rangeLow
          rangeHigh
          title
          warning
          instruction
          note
          maxDose
          minDose
          maxTotalDose
          calcMax
          calcMin
          index
          ageLow
          ageHigh
          ageGroup
          repeatTime
          incrementAmnt
          departmentID
          protocolID
          electricalID
          cprAssistID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      DripDoses {
        items {
          id
          basis
          rangeLow
          rangeHigh
          route
          title
          warning
          instruction
          note
          maxDose
          minDose
          maxTotalDose
          calcMax
          calcMin
          index
          ageLow
          ageHigh
          ageGroup
          repeatTime
          incrementAmnt
          departmentID
          protocolID
          dripID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ProtocolsByKeychainIDQueryVariables,
  APITypes.ProtocolsByKeychainIDQuery
>;
export const protocolsByDepartmentID = /* GraphQL */ `query ProtocolsByDepartmentID(
  $departmentID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelProtocolFilterInput
  $limit: Int
  $nextToken: String
) {
  protocolsByDepartmentID(
    departmentID: $departmentID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      pdfID
      categoryID
      pairedProtocols
      medicationIDs
      equipmentIDs
      electricalIDs
      dripIDs
      formIDs
      index
      nickname
      pairedDepIDs
      rangeLow
      rangeHigh
      ageLow
      ageHigh
      ageGroup
      status
      version
      pdfVersion
      activeID
      overrideID
      privatePin
      keychainID
      createdBy
      modifiedBy
      isPublic
      isRestrictive
      color
      tags
      departmentID
      MedicationDoses {
        items {
          id
          basis
          rangeLow
          rangeHigh
          route
          title
          warning
          instruction
          note
          maxDose
          minDose
          maxTotalDose
          calcMax
          calcMin
          index
          ageLow
          ageHigh
          ageGroup
          repeatTime
          incrementAmnt
          departmentID
          protocolID
          medicationID
          cprAssistID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      ElectricalDoses {
        items {
          id
          basis
          rangeLow
          rangeHigh
          title
          warning
          instruction
          note
          maxDose
          minDose
          maxTotalDose
          calcMax
          calcMin
          index
          ageLow
          ageHigh
          ageGroup
          repeatTime
          incrementAmnt
          departmentID
          protocolID
          electricalID
          cprAssistID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      DripDoses {
        items {
          id
          basis
          rangeLow
          rangeHigh
          route
          title
          warning
          instruction
          note
          maxDose
          minDose
          maxTotalDose
          calcMax
          calcMin
          index
          ageLow
          ageHigh
          ageGroup
          repeatTime
          incrementAmnt
          departmentID
          protocolID
          dripID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ProtocolsByDepartmentIDQueryVariables,
  APITypes.ProtocolsByDepartmentIDQuery
>;
export const getAmbulance = /* GraphQL */ `query GetAmbulance($id: ID!) {
  getAmbulance(id: $id) {
    id
    name
    departmentID
    Logs {
      items {
        id
        actualWeight
        ageValue
        protocolID
        Users {
          nextToken
          startedAt
          __typename
        }
        departmentID
        oneweightID
        ambulanceID
        incidentNumber
        startTime
        endTime
        estimatedWeight
        medications {
          medicationID
          amntSolid
          amntLiquid
          timestamp
          verified
          name
          rxNormCode
          concentration
          isActualWeight
          adminType
          weight
          protocolID
          __typename
        }
        equipments {
          equipmentID
          amount
          name
          snomedCode
          timestamp
          isActualWeight
          verified
          weight
          successful
          protocolID
          __typename
        }
        electricals {
          electricalID
          name
          snomedCode
          amount
          timestamp
          isActualWeight
          verified
          weight
          protocolID
          __typename
        }
        cpr {
          cprID
          startTime
          endTime
          outcome
          __typename
        }
        emsCalculators {
          calculatorID
          responses
          scores
          timestamp
          __typename
        }
        patientSaved
        patientReleased
        arrivedAtHospital
        ageTimestamp
        forms {
          formID
          name
          completed
          startedTime
          protocolID
          __typename
        }
        subtractedItems {
          weightObjectID
          name
          weight
          added
          __typename
        }
        userIDs
        infusions {
          dripID
          amnt
          amnt2
          timestamp
          verified
          name
          rxNormCode
          concentration
          adminType
          weight
          protocolID
          amntUnit
          amntUnit2
          isActualWeight
          gttsDropFactor
          gttsAmount
          gttsUnit
          __typename
        }
        estimatedSaved
        tags
        shifts {
          shiftStart
          shiftEnd
          completedLogs
          taggedAmbulance
          taggedOneWeight
          taggedUsers
          ownerID
          departmentID
          metaData
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAmbulanceQueryVariables,
  APITypes.GetAmbulanceQuery
>;
export const listAmbulances = /* GraphQL */ `query ListAmbulances(
  $filter: ModelAmbulanceFilterInput
  $limit: Int
  $nextToken: String
) {
  listAmbulances(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      departmentID
      Logs {
        items {
          id
          actualWeight
          ageValue
          protocolID
          departmentID
          oneweightID
          ambulanceID
          incidentNumber
          startTime
          endTime
          estimatedWeight
          patientSaved
          patientReleased
          arrivedAtHospital
          ageTimestamp
          userIDs
          estimatedSaved
          tags
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAmbulancesQueryVariables,
  APITypes.ListAmbulancesQuery
>;
export const syncAmbulances = /* GraphQL */ `query SyncAmbulances(
  $filter: ModelAmbulanceFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncAmbulances(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      name
      departmentID
      Logs {
        items {
          id
          actualWeight
          ageValue
          protocolID
          departmentID
          oneweightID
          ambulanceID
          incidentNumber
          startTime
          endTime
          estimatedWeight
          patientSaved
          patientReleased
          arrivedAtHospital
          ageTimestamp
          userIDs
          estimatedSaved
          tags
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncAmbulancesQueryVariables,
  APITypes.SyncAmbulancesQuery
>;
export const ambulancesByDepartmentID = /* GraphQL */ `query AmbulancesByDepartmentID(
  $departmentID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelAmbulanceFilterInput
  $limit: Int
  $nextToken: String
) {
  ambulancesByDepartmentID(
    departmentID: $departmentID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      departmentID
      Logs {
        items {
          id
          actualWeight
          ageValue
          protocolID
          departmentID
          oneweightID
          ambulanceID
          incidentNumber
          startTime
          endTime
          estimatedWeight
          patientSaved
          patientReleased
          arrivedAtHospital
          ageTimestamp
          userIDs
          estimatedSaved
          tags
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AmbulancesByDepartmentIDQueryVariables,
  APITypes.AmbulancesByDepartmentIDQuery
>;
export const getUser = /* GraphQL */ `query GetUser($id: ID!) {
  getUser(id: $id) {
    id
    firstName
    lastName
    type
    cognitoID
    indexedParentDepID
    departmentID
    depAdmins
    logs {
      items {
        id
        userId
        logId
        user {
          id
          firstName
          lastName
          type
          cognitoID
          indexedParentDepID
          departmentID
          depAdmins
          hashedPin
          saltPin
          pairedDepIDs
          oneDoseVersion
          lastActivity
          notificationTokens
          deviceIDs
          maxDevices
          allowAnalytics
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        log {
          id
          actualWeight
          ageValue
          protocolID
          departmentID
          oneweightID
          ambulanceID
          incidentNumber
          startTime
          endTime
          estimatedWeight
          patientSaved
          patientReleased
          arrivedAtHospital
          ageTimestamp
          userIDs
          estimatedSaved
          tags
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    hashedPin
    saltPin
    pairedDepIDs
    oneDoseVersion
    lastActivity
    notificationTokens
    deviceIDs
    maxDevices
    allowAnalytics
    status
    FormLogs {
      items {
        id
        responses {
          questionIndex
          type
          responses
          __typename
        }
        inputformID
        departmentID
        userID
        pairedDepID
        pairedUserIDs
        notes
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Reviewals {
      items {
        id
        title
        description
        reviewers
        status
        events {
          color
          title
          description
          timestamp
          icon
          __typename
        }
        createdAt
        endedAt
        departmentID
        userID
        User {
          id
          firstName
          lastName
          type
          cognitoID
          indexedParentDepID
          departmentID
          depAdmins
          hashedPin
          saltPin
          pairedDepIDs
          oneDoseVersion
          lastActivity
          notificationTokens
          deviceIDs
          maxDevices
          allowAnalytics
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        DraftGroups {
          nextToken
          startedAt
          __typename
        }
        Acknowledges {
          nextToken
          startedAt
          __typename
        }
        UserComments {
          nextToken
          startedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Acknowledges {
      items {
        id
        reviewalID
        ownerID
        userID
        status
        isArchived
        createdAt
        User {
          id
          firstName
          lastName
          type
          cognitoID
          indexedParentDepID
          departmentID
          depAdmins
          hashedPin
          saltPin
          pairedDepIDs
          oneDoseVersion
          lastActivity
          notificationTokens
          deviceIDs
          maxDevices
          allowAnalytics
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Comment {
          id
          ownerID
          userID
          acknowledgeID
          message
          relatedItems
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    UserComments {
      items {
        id
        ownerID
        userID
        acknowledgeID
        message
        relatedItems
        createdAt
        User {
          id
          firstName
          lastName
          type
          cognitoID
          indexedParentDepID
          departmentID
          depAdmins
          hashedPin
          saltPin
          pairedDepIDs
          oneDoseVersion
          lastActivity
          notificationTokens
          deviceIDs
          maxDevices
          allowAnalytics
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Acknowledge {
          id
          reviewalID
          ownerID
          userID
          status
          isArchived
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    NotifyACKs {
      items {
        id
        ownerID
        userID
        status
        createdAt
        User {
          id
          firstName
          lastName
          type
          cognitoID
          indexedParentDepID
          departmentID
          depAdmins
          hashedPin
          saltPin
          pairedDepIDs
          oneDoseVersion
          lastActivity
          notificationTokens
          deviceIDs
          maxDevices
          allowAnalytics
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetUserQueryVariables, APITypes.GetUserQuery>;
export const listUsers = /* GraphQL */ `query ListUsers(
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      firstName
      lastName
      type
      cognitoID
      indexedParentDepID
      departmentID
      depAdmins
      logs {
        items {
          id
          userId
          logId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      hashedPin
      saltPin
      pairedDepIDs
      oneDoseVersion
      lastActivity
      notificationTokens
      deviceIDs
      maxDevices
      allowAnalytics
      status
      FormLogs {
        items {
          id
          inputformID
          departmentID
          userID
          pairedDepID
          pairedUserIDs
          notes
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Reviewals {
        items {
          id
          title
          description
          reviewers
          status
          createdAt
          endedAt
          departmentID
          userID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Acknowledges {
        items {
          id
          reviewalID
          ownerID
          userID
          status
          isArchived
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      UserComments {
        items {
          id
          ownerID
          userID
          acknowledgeID
          message
          relatedItems
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      NotifyACKs {
        items {
          id
          ownerID
          userID
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.ListUsersQueryVariables, APITypes.ListUsersQuery>;
export const syncUsers = /* GraphQL */ `query SyncUsers(
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncUsers(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      firstName
      lastName
      type
      cognitoID
      indexedParentDepID
      departmentID
      depAdmins
      logs {
        items {
          id
          userId
          logId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      hashedPin
      saltPin
      pairedDepIDs
      oneDoseVersion
      lastActivity
      notificationTokens
      deviceIDs
      maxDevices
      allowAnalytics
      status
      FormLogs {
        items {
          id
          inputformID
          departmentID
          userID
          pairedDepID
          pairedUserIDs
          notes
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Reviewals {
        items {
          id
          title
          description
          reviewers
          status
          createdAt
          endedAt
          departmentID
          userID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Acknowledges {
        items {
          id
          reviewalID
          ownerID
          userID
          status
          isArchived
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      UserComments {
        items {
          id
          ownerID
          userID
          acknowledgeID
          message
          relatedItems
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      NotifyACKs {
        items {
          id
          ownerID
          userID
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.SyncUsersQueryVariables, APITypes.SyncUsersQuery>;
export const usersByCognitoID = /* GraphQL */ `query UsersByCognitoID(
  $cognitoID: String!
  $sortDirection: ModelSortDirection
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  usersByCognitoID(
    cognitoID: $cognitoID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      firstName
      lastName
      type
      cognitoID
      indexedParentDepID
      departmentID
      depAdmins
      logs {
        items {
          id
          userId
          logId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      hashedPin
      saltPin
      pairedDepIDs
      oneDoseVersion
      lastActivity
      notificationTokens
      deviceIDs
      maxDevices
      allowAnalytics
      status
      FormLogs {
        items {
          id
          inputformID
          departmentID
          userID
          pairedDepID
          pairedUserIDs
          notes
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Reviewals {
        items {
          id
          title
          description
          reviewers
          status
          createdAt
          endedAt
          departmentID
          userID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Acknowledges {
        items {
          id
          reviewalID
          ownerID
          userID
          status
          isArchived
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      UserComments {
        items {
          id
          ownerID
          userID
          acknowledgeID
          message
          relatedItems
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      NotifyACKs {
        items {
          id
          ownerID
          userID
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UsersByCognitoIDQueryVariables,
  APITypes.UsersByCognitoIDQuery
>;
export const usersByIndexedParentDepID = /* GraphQL */ `query UsersByIndexedParentDepID(
  $indexedParentDepID: ID!
  $firstName: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  usersByIndexedParentDepID(
    indexedParentDepID: $indexedParentDepID
    firstName: $firstName
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      firstName
      lastName
      type
      cognitoID
      indexedParentDepID
      departmentID
      depAdmins
      logs {
        items {
          id
          userId
          logId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      hashedPin
      saltPin
      pairedDepIDs
      oneDoseVersion
      lastActivity
      notificationTokens
      deviceIDs
      maxDevices
      allowAnalytics
      status
      FormLogs {
        items {
          id
          inputformID
          departmentID
          userID
          pairedDepID
          pairedUserIDs
          notes
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Reviewals {
        items {
          id
          title
          description
          reviewers
          status
          createdAt
          endedAt
          departmentID
          userID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Acknowledges {
        items {
          id
          reviewalID
          ownerID
          userID
          status
          isArchived
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      UserComments {
        items {
          id
          ownerID
          userID
          acknowledgeID
          message
          relatedItems
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      NotifyACKs {
        items {
          id
          ownerID
          userID
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UsersByIndexedParentDepIDQueryVariables,
  APITypes.UsersByIndexedParentDepIDQuery
>;
export const usersByDepartmentID = /* GraphQL */ `query UsersByDepartmentID(
  $departmentID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  usersByDepartmentID(
    departmentID: $departmentID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      firstName
      lastName
      type
      cognitoID
      indexedParentDepID
      departmentID
      depAdmins
      logs {
        items {
          id
          userId
          logId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      hashedPin
      saltPin
      pairedDepIDs
      oneDoseVersion
      lastActivity
      notificationTokens
      deviceIDs
      maxDevices
      allowAnalytics
      status
      FormLogs {
        items {
          id
          inputformID
          departmentID
          userID
          pairedDepID
          pairedUserIDs
          notes
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Reviewals {
        items {
          id
          title
          description
          reviewers
          status
          createdAt
          endedAt
          departmentID
          userID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Acknowledges {
        items {
          id
          reviewalID
          ownerID
          userID
          status
          isArchived
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      UserComments {
        items {
          id
          ownerID
          userID
          acknowledgeID
          message
          relatedItems
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      NotifyACKs {
        items {
          id
          ownerID
          userID
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UsersByDepartmentIDQueryVariables,
  APITypes.UsersByDepartmentIDQuery
>;
export const getCategory = /* GraphQL */ `query GetCategory($id: ID!) {
  getCategory(id: $id) {
    id
    name
    departmentID
    department {
      id
      Categories {
        items {
          id
          name
          departmentID
          index
          pairedDepIDs
          parentCategoryID
          activeID
          status
          version
          createdBy
          modifiedBy
          isPublic
          isRestrictive
          color
          tags
          overrideID
          keychainID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          departmentCategoriesId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      OneWeights {
        items {
          id
          name
          serialNumber
          projectNumber
          lastCalibration
          type
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          departmentOneWeightsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      name
      location
      protocolVersions
      shiftTypes
      Medications {
        items {
          id
          name
          rxNormCode
          contraindication
          note
          warning
          route
          medClass
          action
          indication
          interaction
          onset
          duration
          departmentID
          rangeLow
          rangeHigh
          maxDose
          minDose
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Equipment {
        items {
          id
          name
          snomedCode
          departmentID
          title
          note
          warning
          instruction
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Forms {
        items {
          id
          name
          departmentID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Vitals {
        items {
          id
          title
          index
          departmentID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Electricals {
        items {
          id
          title
          departmentID
          activeID
          status
          version
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      ElectricalShocks {
        items {
          id
          title
          rxNormCode
          warning
          instruction
          note
          rangeHigh
          rangeLow
          departmentID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      cognitoID
      Users {
        items {
          id
          firstName
          lastName
          type
          cognitoID
          indexedParentDepID
          departmentID
          depAdmins
          hashedPin
          saltPin
          pairedDepIDs
          oneDoseVersion
          lastActivity
          notificationTokens
          deviceIDs
          maxDevices
          allowAnalytics
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Logs {
        items {
          id
          actualWeight
          ageValue
          protocolID
          departmentID
          oneweightID
          ambulanceID
          incidentNumber
          startTime
          endTime
          estimatedWeight
          patientSaved
          patientReleased
          arrivedAtHospital
          ageTimestamp
          userIDs
          estimatedSaved
          tags
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Ambulances {
        items {
          id
          name
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Drips {
        items {
          id
          name
          rxNormCode
          contraindication
          note
          warning
          route
          medClass
          action
          indication
          interaction
          onset
          duration
          departmentID
          rangeLow
          rangeHigh
          maxDose
          minDose
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      RequireSync {
        id
        sync
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      logoID
      userID
      neonateCutoff
      pediatricCutoff
      calculators
      adultRanges {
        label
        value
        __typename
      }
      softwarePlan
      Notifications {
        items {
          id
          type
          title
          message
          timestamp
          expirationTimestamp
          deadlineTimestamp
          isReadIDs
          isAckIDs
          imageURLs
          videoURLs
          fileURLs
          taggedProtocols
          isPush
          isPinRequired
          departmentID
          pairedDepIDs
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Contacts {
        items {
          id
          fullName
          title
          number
          note
          index
          departmentID
          pairedDepIDs
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      InputForms {
        items {
          id
          name
          departmentID
          pairedDepIDs
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      FormLogs {
        items {
          id
          inputformID
          departmentID
          userID
          pairedDepID
          pairedUserIDs
          notes
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Workbooks {
        items {
          id
          name
          workbookID
          fileSize
          aiPdfParserResults
          activeID
          createdBy
          modifiedBy
          status
          version
          pairedDepIDs
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      WeightObjects {
        items {
          id
          name
          weight
          createdBy
          modifiedBy
          version
          pairedDepIDs
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Keychains {
        items {
          id
          name
          hashedPin
          saltPin
          hashType
          version
          createdBy
          modifiedBy
          metaData
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Reviewals {
        items {
          id
          title
          description
          reviewers
          status
          createdAt
          endedAt
          departmentID
          userID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Groups {
        items {
          id
          name
          type
          departmentID
          pairedDepIDs
          pairedUserIDs
          isAutoGen
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      CPRAssists {
        items {
          id
          protocolIDs
          epiIDs
          defibIDs
          equipmentID
          departmentID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      subDepIDs
      parentDepID
      indexedParentDepID
      activeStatus
      agencyNumEMS
      stateIdEMS
      gnisCodeEMS
      uniqueCode
      uniquePublicURL
      hashedPin
      saltedPin
      keychainID
      isPublic
      infusionCalculation
      isPublicSignup
      config {
        isTopEnabled
        neonateCutoff
        pediatricCutoff
        calculators
        adultRanges {
          label
          value
          __typename
        }
        softwarePlan
        infusionCalculation
        isPublic
        realTimeUpdating
        epcrProvider
        oneweightEnabled
        ageFilterEnabled
        ageGroupFilterEnabled
        renewalDate
        infusionGTTS
        signedAgreement
        isToggleAdvancedFiltersEnabled
        premiumTrialExpiration
        estimationMethod
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      departmentRequireSyncId
      __typename
    }
    index
    pairedDepIDs
    parentCategoryID
    activeID
    status
    version
    createdBy
    modifiedBy
    isPublic
    isRestrictive
    color
    tags
    overrideID
    keychainID
    Protocols {
      items {
        id
        name
        pdfID
        categoryID
        pairedProtocols
        medicationIDs
        equipmentIDs
        electricalIDs
        dripIDs
        formIDs
        index
        nickname
        pairedDepIDs
        rangeLow
        rangeHigh
        ageLow
        ageHigh
        ageGroup
        status
        version
        pdfVersion
        activeID
        overrideID
        privatePin
        keychainID
        createdBy
        modifiedBy
        isPublic
        isRestrictive
        color
        tags
        departmentID
        MedicationDoses {
          nextToken
          startedAt
          __typename
        }
        ElectricalDoses {
          nextToken
          startedAt
          __typename
        }
        DripDoses {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    departmentCategoriesId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCategoryQueryVariables,
  APITypes.GetCategoryQuery
>;
export const listCategories = /* GraphQL */ `query ListCategories(
  $filter: ModelCategoryFilterInput
  $limit: Int
  $nextToken: String
) {
  listCategories(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      departmentID
      department {
        id
        Categories {
          nextToken
          startedAt
          __typename
        }
        OneWeights {
          nextToken
          startedAt
          __typename
        }
        name
        location
        protocolVersions
        shiftTypes
        Medications {
          nextToken
          startedAt
          __typename
        }
        Equipment {
          nextToken
          startedAt
          __typename
        }
        Forms {
          nextToken
          startedAt
          __typename
        }
        Vitals {
          nextToken
          startedAt
          __typename
        }
        Electricals {
          nextToken
          startedAt
          __typename
        }
        ElectricalShocks {
          nextToken
          startedAt
          __typename
        }
        cognitoID
        Users {
          nextToken
          startedAt
          __typename
        }
        Logs {
          nextToken
          startedAt
          __typename
        }
        Ambulances {
          nextToken
          startedAt
          __typename
        }
        Drips {
          nextToken
          startedAt
          __typename
        }
        RequireSync {
          id
          sync
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        logoID
        userID
        neonateCutoff
        pediatricCutoff
        calculators
        adultRanges {
          label
          value
          __typename
        }
        softwarePlan
        Notifications {
          nextToken
          startedAt
          __typename
        }
        Contacts {
          nextToken
          startedAt
          __typename
        }
        InputForms {
          nextToken
          startedAt
          __typename
        }
        FormLogs {
          nextToken
          startedAt
          __typename
        }
        Workbooks {
          nextToken
          startedAt
          __typename
        }
        WeightObjects {
          nextToken
          startedAt
          __typename
        }
        Keychains {
          nextToken
          startedAt
          __typename
        }
        Reviewals {
          nextToken
          startedAt
          __typename
        }
        Groups {
          nextToken
          startedAt
          __typename
        }
        CPRAssists {
          nextToken
          startedAt
          __typename
        }
        subDepIDs
        parentDepID
        indexedParentDepID
        activeStatus
        agencyNumEMS
        stateIdEMS
        gnisCodeEMS
        uniqueCode
        uniquePublicURL
        hashedPin
        saltedPin
        keychainID
        isPublic
        infusionCalculation
        isPublicSignup
        config {
          isTopEnabled
          neonateCutoff
          pediatricCutoff
          calculators
          softwarePlan
          infusionCalculation
          isPublic
          realTimeUpdating
          epcrProvider
          oneweightEnabled
          ageFilterEnabled
          ageGroupFilterEnabled
          renewalDate
          infusionGTTS
          signedAgreement
          isToggleAdvancedFiltersEnabled
          premiumTrialExpiration
          estimationMethod
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        departmentRequireSyncId
        __typename
      }
      index
      pairedDepIDs
      parentCategoryID
      activeID
      status
      version
      createdBy
      modifiedBy
      isPublic
      isRestrictive
      color
      tags
      overrideID
      keychainID
      Protocols {
        items {
          id
          name
          pdfID
          categoryID
          pairedProtocols
          medicationIDs
          equipmentIDs
          electricalIDs
          dripIDs
          formIDs
          index
          nickname
          pairedDepIDs
          rangeLow
          rangeHigh
          ageLow
          ageHigh
          ageGroup
          status
          version
          pdfVersion
          activeID
          overrideID
          privatePin
          keychainID
          createdBy
          modifiedBy
          isPublic
          isRestrictive
          color
          tags
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      departmentCategoriesId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCategoriesQueryVariables,
  APITypes.ListCategoriesQuery
>;
export const syncCategories = /* GraphQL */ `query SyncCategories(
  $filter: ModelCategoryFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncCategories(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      name
      departmentID
      department {
        id
        Categories {
          nextToken
          startedAt
          __typename
        }
        OneWeights {
          nextToken
          startedAt
          __typename
        }
        name
        location
        protocolVersions
        shiftTypes
        Medications {
          nextToken
          startedAt
          __typename
        }
        Equipment {
          nextToken
          startedAt
          __typename
        }
        Forms {
          nextToken
          startedAt
          __typename
        }
        Vitals {
          nextToken
          startedAt
          __typename
        }
        Electricals {
          nextToken
          startedAt
          __typename
        }
        ElectricalShocks {
          nextToken
          startedAt
          __typename
        }
        cognitoID
        Users {
          nextToken
          startedAt
          __typename
        }
        Logs {
          nextToken
          startedAt
          __typename
        }
        Ambulances {
          nextToken
          startedAt
          __typename
        }
        Drips {
          nextToken
          startedAt
          __typename
        }
        RequireSync {
          id
          sync
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        logoID
        userID
        neonateCutoff
        pediatricCutoff
        calculators
        adultRanges {
          label
          value
          __typename
        }
        softwarePlan
        Notifications {
          nextToken
          startedAt
          __typename
        }
        Contacts {
          nextToken
          startedAt
          __typename
        }
        InputForms {
          nextToken
          startedAt
          __typename
        }
        FormLogs {
          nextToken
          startedAt
          __typename
        }
        Workbooks {
          nextToken
          startedAt
          __typename
        }
        WeightObjects {
          nextToken
          startedAt
          __typename
        }
        Keychains {
          nextToken
          startedAt
          __typename
        }
        Reviewals {
          nextToken
          startedAt
          __typename
        }
        Groups {
          nextToken
          startedAt
          __typename
        }
        CPRAssists {
          nextToken
          startedAt
          __typename
        }
        subDepIDs
        parentDepID
        indexedParentDepID
        activeStatus
        agencyNumEMS
        stateIdEMS
        gnisCodeEMS
        uniqueCode
        uniquePublicURL
        hashedPin
        saltedPin
        keychainID
        isPublic
        infusionCalculation
        isPublicSignup
        config {
          isTopEnabled
          neonateCutoff
          pediatricCutoff
          calculators
          softwarePlan
          infusionCalculation
          isPublic
          realTimeUpdating
          epcrProvider
          oneweightEnabled
          ageFilterEnabled
          ageGroupFilterEnabled
          renewalDate
          infusionGTTS
          signedAgreement
          isToggleAdvancedFiltersEnabled
          premiumTrialExpiration
          estimationMethod
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        departmentRequireSyncId
        __typename
      }
      index
      pairedDepIDs
      parentCategoryID
      activeID
      status
      version
      createdBy
      modifiedBy
      isPublic
      isRestrictive
      color
      tags
      overrideID
      keychainID
      Protocols {
        items {
          id
          name
          pdfID
          categoryID
          pairedProtocols
          medicationIDs
          equipmentIDs
          electricalIDs
          dripIDs
          formIDs
          index
          nickname
          pairedDepIDs
          rangeLow
          rangeHigh
          ageLow
          ageHigh
          ageGroup
          status
          version
          pdfVersion
          activeID
          overrideID
          privatePin
          keychainID
          createdBy
          modifiedBy
          isPublic
          isRestrictive
          color
          tags
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      departmentCategoriesId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncCategoriesQueryVariables,
  APITypes.SyncCategoriesQuery
>;
export const categoriesByDepartmentID = /* GraphQL */ `query CategoriesByDepartmentID(
  $departmentID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelCategoryFilterInput
  $limit: Int
  $nextToken: String
) {
  categoriesByDepartmentID(
    departmentID: $departmentID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      departmentID
      department {
        id
        Categories {
          nextToken
          startedAt
          __typename
        }
        OneWeights {
          nextToken
          startedAt
          __typename
        }
        name
        location
        protocolVersions
        shiftTypes
        Medications {
          nextToken
          startedAt
          __typename
        }
        Equipment {
          nextToken
          startedAt
          __typename
        }
        Forms {
          nextToken
          startedAt
          __typename
        }
        Vitals {
          nextToken
          startedAt
          __typename
        }
        Electricals {
          nextToken
          startedAt
          __typename
        }
        ElectricalShocks {
          nextToken
          startedAt
          __typename
        }
        cognitoID
        Users {
          nextToken
          startedAt
          __typename
        }
        Logs {
          nextToken
          startedAt
          __typename
        }
        Ambulances {
          nextToken
          startedAt
          __typename
        }
        Drips {
          nextToken
          startedAt
          __typename
        }
        RequireSync {
          id
          sync
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        logoID
        userID
        neonateCutoff
        pediatricCutoff
        calculators
        adultRanges {
          label
          value
          __typename
        }
        softwarePlan
        Notifications {
          nextToken
          startedAt
          __typename
        }
        Contacts {
          nextToken
          startedAt
          __typename
        }
        InputForms {
          nextToken
          startedAt
          __typename
        }
        FormLogs {
          nextToken
          startedAt
          __typename
        }
        Workbooks {
          nextToken
          startedAt
          __typename
        }
        WeightObjects {
          nextToken
          startedAt
          __typename
        }
        Keychains {
          nextToken
          startedAt
          __typename
        }
        Reviewals {
          nextToken
          startedAt
          __typename
        }
        Groups {
          nextToken
          startedAt
          __typename
        }
        CPRAssists {
          nextToken
          startedAt
          __typename
        }
        subDepIDs
        parentDepID
        indexedParentDepID
        activeStatus
        agencyNumEMS
        stateIdEMS
        gnisCodeEMS
        uniqueCode
        uniquePublicURL
        hashedPin
        saltedPin
        keychainID
        isPublic
        infusionCalculation
        isPublicSignup
        config {
          isTopEnabled
          neonateCutoff
          pediatricCutoff
          calculators
          softwarePlan
          infusionCalculation
          isPublic
          realTimeUpdating
          epcrProvider
          oneweightEnabled
          ageFilterEnabled
          ageGroupFilterEnabled
          renewalDate
          infusionGTTS
          signedAgreement
          isToggleAdvancedFiltersEnabled
          premiumTrialExpiration
          estimationMethod
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        departmentRequireSyncId
        __typename
      }
      index
      pairedDepIDs
      parentCategoryID
      activeID
      status
      version
      createdBy
      modifiedBy
      isPublic
      isRestrictive
      color
      tags
      overrideID
      keychainID
      Protocols {
        items {
          id
          name
          pdfID
          categoryID
          pairedProtocols
          medicationIDs
          equipmentIDs
          electricalIDs
          dripIDs
          formIDs
          index
          nickname
          pairedDepIDs
          rangeLow
          rangeHigh
          ageLow
          ageHigh
          ageGroup
          status
          version
          pdfVersion
          activeID
          overrideID
          privatePin
          keychainID
          createdBy
          modifiedBy
          isPublic
          isRestrictive
          color
          tags
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      departmentCategoriesId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CategoriesByDepartmentIDQueryVariables,
  APITypes.CategoriesByDepartmentIDQuery
>;
export const categoriesByKeychainID = /* GraphQL */ `query CategoriesByKeychainID(
  $keychainID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelCategoryFilterInput
  $limit: Int
  $nextToken: String
) {
  categoriesByKeychainID(
    keychainID: $keychainID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      departmentID
      department {
        id
        Categories {
          nextToken
          startedAt
          __typename
        }
        OneWeights {
          nextToken
          startedAt
          __typename
        }
        name
        location
        protocolVersions
        shiftTypes
        Medications {
          nextToken
          startedAt
          __typename
        }
        Equipment {
          nextToken
          startedAt
          __typename
        }
        Forms {
          nextToken
          startedAt
          __typename
        }
        Vitals {
          nextToken
          startedAt
          __typename
        }
        Electricals {
          nextToken
          startedAt
          __typename
        }
        ElectricalShocks {
          nextToken
          startedAt
          __typename
        }
        cognitoID
        Users {
          nextToken
          startedAt
          __typename
        }
        Logs {
          nextToken
          startedAt
          __typename
        }
        Ambulances {
          nextToken
          startedAt
          __typename
        }
        Drips {
          nextToken
          startedAt
          __typename
        }
        RequireSync {
          id
          sync
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        logoID
        userID
        neonateCutoff
        pediatricCutoff
        calculators
        adultRanges {
          label
          value
          __typename
        }
        softwarePlan
        Notifications {
          nextToken
          startedAt
          __typename
        }
        Contacts {
          nextToken
          startedAt
          __typename
        }
        InputForms {
          nextToken
          startedAt
          __typename
        }
        FormLogs {
          nextToken
          startedAt
          __typename
        }
        Workbooks {
          nextToken
          startedAt
          __typename
        }
        WeightObjects {
          nextToken
          startedAt
          __typename
        }
        Keychains {
          nextToken
          startedAt
          __typename
        }
        Reviewals {
          nextToken
          startedAt
          __typename
        }
        Groups {
          nextToken
          startedAt
          __typename
        }
        CPRAssists {
          nextToken
          startedAt
          __typename
        }
        subDepIDs
        parentDepID
        indexedParentDepID
        activeStatus
        agencyNumEMS
        stateIdEMS
        gnisCodeEMS
        uniqueCode
        uniquePublicURL
        hashedPin
        saltedPin
        keychainID
        isPublic
        infusionCalculation
        isPublicSignup
        config {
          isTopEnabled
          neonateCutoff
          pediatricCutoff
          calculators
          softwarePlan
          infusionCalculation
          isPublic
          realTimeUpdating
          epcrProvider
          oneweightEnabled
          ageFilterEnabled
          ageGroupFilterEnabled
          renewalDate
          infusionGTTS
          signedAgreement
          isToggleAdvancedFiltersEnabled
          premiumTrialExpiration
          estimationMethod
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        departmentRequireSyncId
        __typename
      }
      index
      pairedDepIDs
      parentCategoryID
      activeID
      status
      version
      createdBy
      modifiedBy
      isPublic
      isRestrictive
      color
      tags
      overrideID
      keychainID
      Protocols {
        items {
          id
          name
          pdfID
          categoryID
          pairedProtocols
          medicationIDs
          equipmentIDs
          electricalIDs
          dripIDs
          formIDs
          index
          nickname
          pairedDepIDs
          rangeLow
          rangeHigh
          ageLow
          ageHigh
          ageGroup
          status
          version
          pdfVersion
          activeID
          overrideID
          privatePin
          keychainID
          createdBy
          modifiedBy
          isPublic
          isRestrictive
          color
          tags
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      departmentCategoriesId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CategoriesByKeychainIDQueryVariables,
  APITypes.CategoriesByKeychainIDQuery
>;
export const getElectrical = /* GraphQL */ `query GetElectrical($id: ID!) {
  getElectrical(id: $id) {
    id
    title
    options {
      title
      ranges {
        basis
        rangeLow
        rangeHigh
        calcMax
        fixedMax
        __typename
      }
      __typename
    }
    departmentID
    activeID
    status
    version
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetElectricalQueryVariables,
  APITypes.GetElectricalQuery
>;
export const listElectricals = /* GraphQL */ `query ListElectricals(
  $filter: ModelElectricalFilterInput
  $limit: Int
  $nextToken: String
) {
  listElectricals(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      title
      options {
        title
        ranges {
          basis
          rangeLow
          rangeHigh
          calcMax
          fixedMax
          __typename
        }
        __typename
      }
      departmentID
      activeID
      status
      version
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListElectricalsQueryVariables,
  APITypes.ListElectricalsQuery
>;
export const syncElectricals = /* GraphQL */ `query SyncElectricals(
  $filter: ModelElectricalFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncElectricals(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      title
      options {
        title
        ranges {
          basis
          rangeLow
          rangeHigh
          calcMax
          fixedMax
          __typename
        }
        __typename
      }
      departmentID
      activeID
      status
      version
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncElectricalsQueryVariables,
  APITypes.SyncElectricalsQuery
>;
export const electricalsByDepartmentID = /* GraphQL */ `query ElectricalsByDepartmentID(
  $departmentID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelElectricalFilterInput
  $limit: Int
  $nextToken: String
) {
  electricalsByDepartmentID(
    departmentID: $departmentID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      title
      options {
        title
        ranges {
          basis
          rangeLow
          rangeHigh
          calcMax
          fixedMax
          __typename
        }
        __typename
      }
      departmentID
      activeID
      status
      version
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ElectricalsByDepartmentIDQueryVariables,
  APITypes.ElectricalsByDepartmentIDQuery
>;
export const getElectricalShock = /* GraphQL */ `query GetElectricalShock($id: ID!) {
  getElectricalShock(id: $id) {
    id
    title
    rxNormCode
    options {
      protocolID
      ranges {
        doseID
        index
        basis
        rangeLow
        rangeHigh
        calcMax
        fixedMax
        title
        warning
        instruction
        note
        ageLow
        ageHigh
        ageGroup
        __typename
      }
      __typename
    }
    warning
    instruction
    note
    rangeHigh
    rangeLow
    departmentID
    activeID
    overrideID
    status
    version
    createdBy
    modifiedBy
    metaData {
      parentID
      fullName
      nickName
      brandNames
      maxDose
      minDose
      absMaxDose
      absMinDose
      releaseNotes
      __typename
    }
    ElectricalDoses {
      items {
        id
        basis
        rangeLow
        rangeHigh
        title
        warning
        instruction
        note
        maxDose
        minDose
        maxTotalDose
        calcMax
        calcMin
        index
        ageLow
        ageHigh
        ageGroup
        repeatTime
        incrementAmnt
        departmentID
        protocolID
        electricalID
        cprAssistID
        activeID
        overrideID
        status
        version
        createdBy
        modifiedBy
        createdAt
        Protocol {
          id
          name
          pdfID
          categoryID
          pairedProtocols
          medicationIDs
          equipmentIDs
          electricalIDs
          dripIDs
          formIDs
          index
          nickname
          pairedDepIDs
          rangeLow
          rangeHigh
          ageLow
          ageHigh
          ageGroup
          status
          version
          pdfVersion
          activeID
          overrideID
          privatePin
          keychainID
          createdBy
          modifiedBy
          isPublic
          isRestrictive
          color
          tags
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Electrical {
          id
          title
          rxNormCode
          warning
          instruction
          note
          rangeHigh
          rangeLow
          departmentID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        CPRAssist {
          id
          protocolIDs
          epiIDs
          defibIDs
          equipmentID
          departmentID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetElectricalShockQueryVariables,
  APITypes.GetElectricalShockQuery
>;
export const listElectricalShocks = /* GraphQL */ `query ListElectricalShocks(
  $filter: ModelElectricalShockFilterInput
  $limit: Int
  $nextToken: String
) {
  listElectricalShocks(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      title
      rxNormCode
      options {
        protocolID
        ranges {
          doseID
          index
          basis
          rangeLow
          rangeHigh
          calcMax
          fixedMax
          title
          warning
          instruction
          note
          ageLow
          ageHigh
          ageGroup
          __typename
        }
        __typename
      }
      warning
      instruction
      note
      rangeHigh
      rangeLow
      departmentID
      activeID
      overrideID
      status
      version
      createdBy
      modifiedBy
      metaData {
        parentID
        fullName
        nickName
        brandNames
        maxDose
        minDose
        absMaxDose
        absMinDose
        releaseNotes
        __typename
      }
      ElectricalDoses {
        items {
          id
          basis
          rangeLow
          rangeHigh
          title
          warning
          instruction
          note
          maxDose
          minDose
          maxTotalDose
          calcMax
          calcMin
          index
          ageLow
          ageHigh
          ageGroup
          repeatTime
          incrementAmnt
          departmentID
          protocolID
          electricalID
          cprAssistID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListElectricalShocksQueryVariables,
  APITypes.ListElectricalShocksQuery
>;
export const syncElectricalShocks = /* GraphQL */ `query SyncElectricalShocks(
  $filter: ModelElectricalShockFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncElectricalShocks(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      title
      rxNormCode
      options {
        protocolID
        ranges {
          doseID
          index
          basis
          rangeLow
          rangeHigh
          calcMax
          fixedMax
          title
          warning
          instruction
          note
          ageLow
          ageHigh
          ageGroup
          __typename
        }
        __typename
      }
      warning
      instruction
      note
      rangeHigh
      rangeLow
      departmentID
      activeID
      overrideID
      status
      version
      createdBy
      modifiedBy
      metaData {
        parentID
        fullName
        nickName
        brandNames
        maxDose
        minDose
        absMaxDose
        absMinDose
        releaseNotes
        __typename
      }
      ElectricalDoses {
        items {
          id
          basis
          rangeLow
          rangeHigh
          title
          warning
          instruction
          note
          maxDose
          minDose
          maxTotalDose
          calcMax
          calcMin
          index
          ageLow
          ageHigh
          ageGroup
          repeatTime
          incrementAmnt
          departmentID
          protocolID
          electricalID
          cprAssistID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncElectricalShocksQueryVariables,
  APITypes.SyncElectricalShocksQuery
>;
export const electricalShocksByRxNormCode = /* GraphQL */ `query ElectricalShocksByRxNormCode(
  $rxNormCode: String!
  $sortDirection: ModelSortDirection
  $filter: ModelElectricalShockFilterInput
  $limit: Int
  $nextToken: String
) {
  electricalShocksByRxNormCode(
    rxNormCode: $rxNormCode
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      title
      rxNormCode
      options {
        protocolID
        ranges {
          doseID
          index
          basis
          rangeLow
          rangeHigh
          calcMax
          fixedMax
          title
          warning
          instruction
          note
          ageLow
          ageHigh
          ageGroup
          __typename
        }
        __typename
      }
      warning
      instruction
      note
      rangeHigh
      rangeLow
      departmentID
      activeID
      overrideID
      status
      version
      createdBy
      modifiedBy
      metaData {
        parentID
        fullName
        nickName
        brandNames
        maxDose
        minDose
        absMaxDose
        absMinDose
        releaseNotes
        __typename
      }
      ElectricalDoses {
        items {
          id
          basis
          rangeLow
          rangeHigh
          title
          warning
          instruction
          note
          maxDose
          minDose
          maxTotalDose
          calcMax
          calcMin
          index
          ageLow
          ageHigh
          ageGroup
          repeatTime
          incrementAmnt
          departmentID
          protocolID
          electricalID
          cprAssistID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ElectricalShocksByRxNormCodeQueryVariables,
  APITypes.ElectricalShocksByRxNormCodeQuery
>;
export const electricalShocksByDepartmentID = /* GraphQL */ `query ElectricalShocksByDepartmentID(
  $departmentID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelElectricalShockFilterInput
  $limit: Int
  $nextToken: String
) {
  electricalShocksByDepartmentID(
    departmentID: $departmentID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      title
      rxNormCode
      options {
        protocolID
        ranges {
          doseID
          index
          basis
          rangeLow
          rangeHigh
          calcMax
          fixedMax
          title
          warning
          instruction
          note
          ageLow
          ageHigh
          ageGroup
          __typename
        }
        __typename
      }
      warning
      instruction
      note
      rangeHigh
      rangeLow
      departmentID
      activeID
      overrideID
      status
      version
      createdBy
      modifiedBy
      metaData {
        parentID
        fullName
        nickName
        brandNames
        maxDose
        minDose
        absMaxDose
        absMinDose
        releaseNotes
        __typename
      }
      ElectricalDoses {
        items {
          id
          basis
          rangeLow
          rangeHigh
          title
          warning
          instruction
          note
          maxDose
          minDose
          maxTotalDose
          calcMax
          calcMin
          index
          ageLow
          ageHigh
          ageGroup
          repeatTime
          incrementAmnt
          departmentID
          protocolID
          electricalID
          cprAssistID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ElectricalShocksByDepartmentIDQueryVariables,
  APITypes.ElectricalShocksByDepartmentIDQuery
>;
export const getElectricalDose = /* GraphQL */ `query GetElectricalDose($id: ID!) {
  getElectricalDose(id: $id) {
    id
    basis
    rangeLow
    rangeHigh
    title
    warning
    instruction
    note
    maxDose
    minDose
    maxTotalDose
    calcMax
    calcMin
    index
    ageLow
    ageHigh
    ageGroup
    repeatTime
    incrementAmnt
    departmentID
    protocolID
    electricalID
    cprAssistID
    activeID
    overrideID
    status
    version
    createdBy
    modifiedBy
    createdAt
    Protocol {
      id
      name
      pdfID
      categoryID
      pairedProtocols
      medicationIDs
      equipmentIDs
      electricalIDs
      dripIDs
      formIDs
      index
      nickname
      pairedDepIDs
      rangeLow
      rangeHigh
      ageLow
      ageHigh
      ageGroup
      status
      version
      pdfVersion
      activeID
      overrideID
      privatePin
      keychainID
      createdBy
      modifiedBy
      isPublic
      isRestrictive
      color
      tags
      departmentID
      MedicationDoses {
        items {
          id
          basis
          rangeLow
          rangeHigh
          route
          title
          warning
          instruction
          note
          maxDose
          minDose
          maxTotalDose
          calcMax
          calcMin
          index
          ageLow
          ageHigh
          ageGroup
          repeatTime
          incrementAmnt
          departmentID
          protocolID
          medicationID
          cprAssistID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      ElectricalDoses {
        items {
          id
          basis
          rangeLow
          rangeHigh
          title
          warning
          instruction
          note
          maxDose
          minDose
          maxTotalDose
          calcMax
          calcMin
          index
          ageLow
          ageHigh
          ageGroup
          repeatTime
          incrementAmnt
          departmentID
          protocolID
          electricalID
          cprAssistID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      DripDoses {
        items {
          id
          basis
          rangeLow
          rangeHigh
          route
          title
          warning
          instruction
          note
          maxDose
          minDose
          maxTotalDose
          calcMax
          calcMin
          index
          ageLow
          ageHigh
          ageGroup
          repeatTime
          incrementAmnt
          departmentID
          protocolID
          dripID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    Electrical {
      id
      title
      rxNormCode
      options {
        protocolID
        ranges {
          doseID
          index
          basis
          rangeLow
          rangeHigh
          calcMax
          fixedMax
          title
          warning
          instruction
          note
          ageLow
          ageHigh
          ageGroup
          __typename
        }
        __typename
      }
      warning
      instruction
      note
      rangeHigh
      rangeLow
      departmentID
      activeID
      overrideID
      status
      version
      createdBy
      modifiedBy
      metaData {
        parentID
        fullName
        nickName
        brandNames
        maxDose
        minDose
        absMaxDose
        absMinDose
        releaseNotes
        __typename
      }
      ElectricalDoses {
        items {
          id
          basis
          rangeLow
          rangeHigh
          title
          warning
          instruction
          note
          maxDose
          minDose
          maxTotalDose
          calcMax
          calcMin
          index
          ageLow
          ageHigh
          ageGroup
          repeatTime
          incrementAmnt
          departmentID
          protocolID
          electricalID
          cprAssistID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    CPRAssist {
      id
      protocolIDs
      epiIDs
      defibIDs
      equipmentID
      departmentID
      activeID
      overrideID
      status
      version
      createdBy
      modifiedBy
      cprMetaData {
        defaultMode
        epiOrangeSec
        epiRedSec
        compressionRate
        ventRatePerMin
        ventilateSoundDelay
        ventilateLoops
        continousVentilateLoops
        ventilateSoundSpeed
        pulseCheckTime
        chargingTime
        __typename
      }
      DefibDoses {
        items {
          id
          basis
          rangeLow
          rangeHigh
          title
          warning
          instruction
          note
          maxDose
          minDose
          maxTotalDose
          calcMax
          calcMin
          index
          ageLow
          ageHigh
          ageGroup
          repeatTime
          incrementAmnt
          departmentID
          protocolID
          electricalID
          cprAssistID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      EpiDoses {
        items {
          id
          basis
          rangeLow
          rangeHigh
          route
          title
          warning
          instruction
          note
          maxDose
          minDose
          maxTotalDose
          calcMax
          calcMin
          index
          ageLow
          ageHigh
          ageGroup
          repeatTime
          incrementAmnt
          departmentID
          protocolID
          medicationID
          cprAssistID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetElectricalDoseQueryVariables,
  APITypes.GetElectricalDoseQuery
>;
export const listElectricalDoses = /* GraphQL */ `query ListElectricalDoses(
  $filter: ModelElectricalDoseFilterInput
  $limit: Int
  $nextToken: String
) {
  listElectricalDoses(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      basis
      rangeLow
      rangeHigh
      title
      warning
      instruction
      note
      maxDose
      minDose
      maxTotalDose
      calcMax
      calcMin
      index
      ageLow
      ageHigh
      ageGroup
      repeatTime
      incrementAmnt
      departmentID
      protocolID
      electricalID
      cprAssistID
      activeID
      overrideID
      status
      version
      createdBy
      modifiedBy
      createdAt
      Protocol {
        id
        name
        pdfID
        categoryID
        pairedProtocols
        medicationIDs
        equipmentIDs
        electricalIDs
        dripIDs
        formIDs
        index
        nickname
        pairedDepIDs
        rangeLow
        rangeHigh
        ageLow
        ageHigh
        ageGroup
        status
        version
        pdfVersion
        activeID
        overrideID
        privatePin
        keychainID
        createdBy
        modifiedBy
        isPublic
        isRestrictive
        color
        tags
        departmentID
        MedicationDoses {
          nextToken
          startedAt
          __typename
        }
        ElectricalDoses {
          nextToken
          startedAt
          __typename
        }
        DripDoses {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Electrical {
        id
        title
        rxNormCode
        options {
          protocolID
          __typename
        }
        warning
        instruction
        note
        rangeHigh
        rangeLow
        departmentID
        activeID
        overrideID
        status
        version
        createdBy
        modifiedBy
        metaData {
          parentID
          fullName
          nickName
          brandNames
          maxDose
          minDose
          absMaxDose
          absMinDose
          releaseNotes
          __typename
        }
        ElectricalDoses {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      CPRAssist {
        id
        protocolIDs
        epiIDs
        defibIDs
        equipmentID
        departmentID
        activeID
        overrideID
        status
        version
        createdBy
        modifiedBy
        cprMetaData {
          defaultMode
          epiOrangeSec
          epiRedSec
          compressionRate
          ventRatePerMin
          ventilateSoundDelay
          ventilateLoops
          continousVentilateLoops
          ventilateSoundSpeed
          pulseCheckTime
          chargingTime
          __typename
        }
        DefibDoses {
          nextToken
          startedAt
          __typename
        }
        EpiDoses {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListElectricalDosesQueryVariables,
  APITypes.ListElectricalDosesQuery
>;
export const syncElectricalDoses = /* GraphQL */ `query SyncElectricalDoses(
  $filter: ModelElectricalDoseFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncElectricalDoses(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      basis
      rangeLow
      rangeHigh
      title
      warning
      instruction
      note
      maxDose
      minDose
      maxTotalDose
      calcMax
      calcMin
      index
      ageLow
      ageHigh
      ageGroup
      repeatTime
      incrementAmnt
      departmentID
      protocolID
      electricalID
      cprAssistID
      activeID
      overrideID
      status
      version
      createdBy
      modifiedBy
      createdAt
      Protocol {
        id
        name
        pdfID
        categoryID
        pairedProtocols
        medicationIDs
        equipmentIDs
        electricalIDs
        dripIDs
        formIDs
        index
        nickname
        pairedDepIDs
        rangeLow
        rangeHigh
        ageLow
        ageHigh
        ageGroup
        status
        version
        pdfVersion
        activeID
        overrideID
        privatePin
        keychainID
        createdBy
        modifiedBy
        isPublic
        isRestrictive
        color
        tags
        departmentID
        MedicationDoses {
          nextToken
          startedAt
          __typename
        }
        ElectricalDoses {
          nextToken
          startedAt
          __typename
        }
        DripDoses {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Electrical {
        id
        title
        rxNormCode
        options {
          protocolID
          __typename
        }
        warning
        instruction
        note
        rangeHigh
        rangeLow
        departmentID
        activeID
        overrideID
        status
        version
        createdBy
        modifiedBy
        metaData {
          parentID
          fullName
          nickName
          brandNames
          maxDose
          minDose
          absMaxDose
          absMinDose
          releaseNotes
          __typename
        }
        ElectricalDoses {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      CPRAssist {
        id
        protocolIDs
        epiIDs
        defibIDs
        equipmentID
        departmentID
        activeID
        overrideID
        status
        version
        createdBy
        modifiedBy
        cprMetaData {
          defaultMode
          epiOrangeSec
          epiRedSec
          compressionRate
          ventRatePerMin
          ventilateSoundDelay
          ventilateLoops
          continousVentilateLoops
          ventilateSoundSpeed
          pulseCheckTime
          chargingTime
          __typename
        }
        DefibDoses {
          nextToken
          startedAt
          __typename
        }
        EpiDoses {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncElectricalDosesQueryVariables,
  APITypes.SyncElectricalDosesQuery
>;
export const electricalDosesByDepartmentID = /* GraphQL */ `query ElectricalDosesByDepartmentID(
  $departmentID: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelElectricalDoseFilterInput
  $limit: Int
  $nextToken: String
) {
  electricalDosesByDepartmentID(
    departmentID: $departmentID
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      basis
      rangeLow
      rangeHigh
      title
      warning
      instruction
      note
      maxDose
      minDose
      maxTotalDose
      calcMax
      calcMin
      index
      ageLow
      ageHigh
      ageGroup
      repeatTime
      incrementAmnt
      departmentID
      protocolID
      electricalID
      cprAssistID
      activeID
      overrideID
      status
      version
      createdBy
      modifiedBy
      createdAt
      Protocol {
        id
        name
        pdfID
        categoryID
        pairedProtocols
        medicationIDs
        equipmentIDs
        electricalIDs
        dripIDs
        formIDs
        index
        nickname
        pairedDepIDs
        rangeLow
        rangeHigh
        ageLow
        ageHigh
        ageGroup
        status
        version
        pdfVersion
        activeID
        overrideID
        privatePin
        keychainID
        createdBy
        modifiedBy
        isPublic
        isRestrictive
        color
        tags
        departmentID
        MedicationDoses {
          nextToken
          startedAt
          __typename
        }
        ElectricalDoses {
          nextToken
          startedAt
          __typename
        }
        DripDoses {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Electrical {
        id
        title
        rxNormCode
        options {
          protocolID
          __typename
        }
        warning
        instruction
        note
        rangeHigh
        rangeLow
        departmentID
        activeID
        overrideID
        status
        version
        createdBy
        modifiedBy
        metaData {
          parentID
          fullName
          nickName
          brandNames
          maxDose
          minDose
          absMaxDose
          absMinDose
          releaseNotes
          __typename
        }
        ElectricalDoses {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      CPRAssist {
        id
        protocolIDs
        epiIDs
        defibIDs
        equipmentID
        departmentID
        activeID
        overrideID
        status
        version
        createdBy
        modifiedBy
        cprMetaData {
          defaultMode
          epiOrangeSec
          epiRedSec
          compressionRate
          ventRatePerMin
          ventilateSoundDelay
          ventilateLoops
          continousVentilateLoops
          ventilateSoundSpeed
          pulseCheckTime
          chargingTime
          __typename
        }
        DefibDoses {
          nextToken
          startedAt
          __typename
        }
        EpiDoses {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ElectricalDosesByDepartmentIDQueryVariables,
  APITypes.ElectricalDosesByDepartmentIDQuery
>;
export const electricalDosesByProtocolID = /* GraphQL */ `query ElectricalDosesByProtocolID(
  $protocolID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelElectricalDoseFilterInput
  $limit: Int
  $nextToken: String
) {
  electricalDosesByProtocolID(
    protocolID: $protocolID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      basis
      rangeLow
      rangeHigh
      title
      warning
      instruction
      note
      maxDose
      minDose
      maxTotalDose
      calcMax
      calcMin
      index
      ageLow
      ageHigh
      ageGroup
      repeatTime
      incrementAmnt
      departmentID
      protocolID
      electricalID
      cprAssistID
      activeID
      overrideID
      status
      version
      createdBy
      modifiedBy
      createdAt
      Protocol {
        id
        name
        pdfID
        categoryID
        pairedProtocols
        medicationIDs
        equipmentIDs
        electricalIDs
        dripIDs
        formIDs
        index
        nickname
        pairedDepIDs
        rangeLow
        rangeHigh
        ageLow
        ageHigh
        ageGroup
        status
        version
        pdfVersion
        activeID
        overrideID
        privatePin
        keychainID
        createdBy
        modifiedBy
        isPublic
        isRestrictive
        color
        tags
        departmentID
        MedicationDoses {
          nextToken
          startedAt
          __typename
        }
        ElectricalDoses {
          nextToken
          startedAt
          __typename
        }
        DripDoses {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Electrical {
        id
        title
        rxNormCode
        options {
          protocolID
          __typename
        }
        warning
        instruction
        note
        rangeHigh
        rangeLow
        departmentID
        activeID
        overrideID
        status
        version
        createdBy
        modifiedBy
        metaData {
          parentID
          fullName
          nickName
          brandNames
          maxDose
          minDose
          absMaxDose
          absMinDose
          releaseNotes
          __typename
        }
        ElectricalDoses {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      CPRAssist {
        id
        protocolIDs
        epiIDs
        defibIDs
        equipmentID
        departmentID
        activeID
        overrideID
        status
        version
        createdBy
        modifiedBy
        cprMetaData {
          defaultMode
          epiOrangeSec
          epiRedSec
          compressionRate
          ventRatePerMin
          ventilateSoundDelay
          ventilateLoops
          continousVentilateLoops
          ventilateSoundSpeed
          pulseCheckTime
          chargingTime
          __typename
        }
        DefibDoses {
          nextToken
          startedAt
          __typename
        }
        EpiDoses {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ElectricalDosesByProtocolIDQueryVariables,
  APITypes.ElectricalDosesByProtocolIDQuery
>;
export const electricalDosesByElectricalID = /* GraphQL */ `query ElectricalDosesByElectricalID(
  $electricalID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelElectricalDoseFilterInput
  $limit: Int
  $nextToken: String
) {
  electricalDosesByElectricalID(
    electricalID: $electricalID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      basis
      rangeLow
      rangeHigh
      title
      warning
      instruction
      note
      maxDose
      minDose
      maxTotalDose
      calcMax
      calcMin
      index
      ageLow
      ageHigh
      ageGroup
      repeatTime
      incrementAmnt
      departmentID
      protocolID
      electricalID
      cprAssistID
      activeID
      overrideID
      status
      version
      createdBy
      modifiedBy
      createdAt
      Protocol {
        id
        name
        pdfID
        categoryID
        pairedProtocols
        medicationIDs
        equipmentIDs
        electricalIDs
        dripIDs
        formIDs
        index
        nickname
        pairedDepIDs
        rangeLow
        rangeHigh
        ageLow
        ageHigh
        ageGroup
        status
        version
        pdfVersion
        activeID
        overrideID
        privatePin
        keychainID
        createdBy
        modifiedBy
        isPublic
        isRestrictive
        color
        tags
        departmentID
        MedicationDoses {
          nextToken
          startedAt
          __typename
        }
        ElectricalDoses {
          nextToken
          startedAt
          __typename
        }
        DripDoses {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Electrical {
        id
        title
        rxNormCode
        options {
          protocolID
          __typename
        }
        warning
        instruction
        note
        rangeHigh
        rangeLow
        departmentID
        activeID
        overrideID
        status
        version
        createdBy
        modifiedBy
        metaData {
          parentID
          fullName
          nickName
          brandNames
          maxDose
          minDose
          absMaxDose
          absMinDose
          releaseNotes
          __typename
        }
        ElectricalDoses {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      CPRAssist {
        id
        protocolIDs
        epiIDs
        defibIDs
        equipmentID
        departmentID
        activeID
        overrideID
        status
        version
        createdBy
        modifiedBy
        cprMetaData {
          defaultMode
          epiOrangeSec
          epiRedSec
          compressionRate
          ventRatePerMin
          ventilateSoundDelay
          ventilateLoops
          continousVentilateLoops
          ventilateSoundSpeed
          pulseCheckTime
          chargingTime
          __typename
        }
        DefibDoses {
          nextToken
          startedAt
          __typename
        }
        EpiDoses {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ElectricalDosesByElectricalIDQueryVariables,
  APITypes.ElectricalDosesByElectricalIDQuery
>;
export const electricalDosesByCprAssistID = /* GraphQL */ `query ElectricalDosesByCprAssistID(
  $cprAssistID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelElectricalDoseFilterInput
  $limit: Int
  $nextToken: String
) {
  electricalDosesByCprAssistID(
    cprAssistID: $cprAssistID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      basis
      rangeLow
      rangeHigh
      title
      warning
      instruction
      note
      maxDose
      minDose
      maxTotalDose
      calcMax
      calcMin
      index
      ageLow
      ageHigh
      ageGroup
      repeatTime
      incrementAmnt
      departmentID
      protocolID
      electricalID
      cprAssistID
      activeID
      overrideID
      status
      version
      createdBy
      modifiedBy
      createdAt
      Protocol {
        id
        name
        pdfID
        categoryID
        pairedProtocols
        medicationIDs
        equipmentIDs
        electricalIDs
        dripIDs
        formIDs
        index
        nickname
        pairedDepIDs
        rangeLow
        rangeHigh
        ageLow
        ageHigh
        ageGroup
        status
        version
        pdfVersion
        activeID
        overrideID
        privatePin
        keychainID
        createdBy
        modifiedBy
        isPublic
        isRestrictive
        color
        tags
        departmentID
        MedicationDoses {
          nextToken
          startedAt
          __typename
        }
        ElectricalDoses {
          nextToken
          startedAt
          __typename
        }
        DripDoses {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Electrical {
        id
        title
        rxNormCode
        options {
          protocolID
          __typename
        }
        warning
        instruction
        note
        rangeHigh
        rangeLow
        departmentID
        activeID
        overrideID
        status
        version
        createdBy
        modifiedBy
        metaData {
          parentID
          fullName
          nickName
          brandNames
          maxDose
          minDose
          absMaxDose
          absMinDose
          releaseNotes
          __typename
        }
        ElectricalDoses {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      CPRAssist {
        id
        protocolIDs
        epiIDs
        defibIDs
        equipmentID
        departmentID
        activeID
        overrideID
        status
        version
        createdBy
        modifiedBy
        cprMetaData {
          defaultMode
          epiOrangeSec
          epiRedSec
          compressionRate
          ventRatePerMin
          ventilateSoundDelay
          ventilateLoops
          continousVentilateLoops
          ventilateSoundSpeed
          pulseCheckTime
          chargingTime
          __typename
        }
        DefibDoses {
          nextToken
          startedAt
          __typename
        }
        EpiDoses {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ElectricalDosesByCprAssistIDQueryVariables,
  APITypes.ElectricalDosesByCprAssistIDQuery
>;
export const getDeveloperNotification = /* GraphQL */ `query GetDeveloperNotification($id: ID!) {
  getDeveloperNotification(id: $id) {
    id
    title
    message
    targetVersion
    additionalData
    type
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDeveloperNotificationQueryVariables,
  APITypes.GetDeveloperNotificationQuery
>;
export const listDeveloperNotifications = /* GraphQL */ `query ListDeveloperNotifications(
  $filter: ModelDeveloperNotificationFilterInput
  $limit: Int
  $nextToken: String
) {
  listDeveloperNotifications(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      title
      message
      targetVersion
      additionalData
      type
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDeveloperNotificationsQueryVariables,
  APITypes.ListDeveloperNotificationsQuery
>;
export const syncDeveloperNotifications = /* GraphQL */ `query SyncDeveloperNotifications(
  $filter: ModelDeveloperNotificationFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncDeveloperNotifications(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      title
      message
      targetVersion
      additionalData
      type
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncDeveloperNotificationsQueryVariables,
  APITypes.SyncDeveloperNotificationsQuery
>;
export const getVitals = /* GraphQL */ `query GetVitals($id: ID!) {
  getVitals(id: $id) {
    id
    title
    options {
      id
      amntLow
      amntHigh
      rangeLow
      rangeHigh
      ageLow
      ageHigh
      index
      __typename
    }
    index
    departmentID
    activeID
    overrideID
    status
    version
    createdBy
    modifiedBy
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetVitalsQueryVariables, APITypes.GetVitalsQuery>;
export const listVitals = /* GraphQL */ `query ListVitals(
  $filter: ModelVitalsFilterInput
  $limit: Int
  $nextToken: String
) {
  listVitals(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      title
      options {
        id
        amntLow
        amntHigh
        rangeLow
        rangeHigh
        ageLow
        ageHigh
        index
        __typename
      }
      index
      departmentID
      activeID
      overrideID
      status
      version
      createdBy
      modifiedBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListVitalsQueryVariables,
  APITypes.ListVitalsQuery
>;
export const syncVitals = /* GraphQL */ `query SyncVitals(
  $filter: ModelVitalsFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncVitals(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      title
      options {
        id
        amntLow
        amntHigh
        rangeLow
        rangeHigh
        ageLow
        ageHigh
        index
        __typename
      }
      index
      departmentID
      activeID
      overrideID
      status
      version
      createdBy
      modifiedBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncVitalsQueryVariables,
  APITypes.SyncVitalsQuery
>;
export const vitalsByDepartmentID = /* GraphQL */ `query VitalsByDepartmentID(
  $departmentID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelVitalsFilterInput
  $limit: Int
  $nextToken: String
) {
  vitalsByDepartmentID(
    departmentID: $departmentID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      title
      options {
        id
        amntLow
        amntHigh
        rangeLow
        rangeHigh
        ageLow
        ageHigh
        index
        __typename
      }
      index
      departmentID
      activeID
      overrideID
      status
      version
      createdBy
      modifiedBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.VitalsByDepartmentIDQueryVariables,
  APITypes.VitalsByDepartmentIDQuery
>;
export const getOneWeight = /* GraphQL */ `query GetOneWeight($id: ID!) {
  getOneWeight(id: $id) {
    id
    name
    serialNumber
    projectNumber
    lastCalibration
    type
    departmentID
    department {
      id
      Categories {
        items {
          id
          name
          departmentID
          index
          pairedDepIDs
          parentCategoryID
          activeID
          status
          version
          createdBy
          modifiedBy
          isPublic
          isRestrictive
          color
          tags
          overrideID
          keychainID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          departmentCategoriesId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      OneWeights {
        items {
          id
          name
          serialNumber
          projectNumber
          lastCalibration
          type
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          departmentOneWeightsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      name
      location
      protocolVersions
      shiftTypes
      Medications {
        items {
          id
          name
          rxNormCode
          contraindication
          note
          warning
          route
          medClass
          action
          indication
          interaction
          onset
          duration
          departmentID
          rangeLow
          rangeHigh
          maxDose
          minDose
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Equipment {
        items {
          id
          name
          snomedCode
          departmentID
          title
          note
          warning
          instruction
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Forms {
        items {
          id
          name
          departmentID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Vitals {
        items {
          id
          title
          index
          departmentID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Electricals {
        items {
          id
          title
          departmentID
          activeID
          status
          version
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      ElectricalShocks {
        items {
          id
          title
          rxNormCode
          warning
          instruction
          note
          rangeHigh
          rangeLow
          departmentID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      cognitoID
      Users {
        items {
          id
          firstName
          lastName
          type
          cognitoID
          indexedParentDepID
          departmentID
          depAdmins
          hashedPin
          saltPin
          pairedDepIDs
          oneDoseVersion
          lastActivity
          notificationTokens
          deviceIDs
          maxDevices
          allowAnalytics
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Logs {
        items {
          id
          actualWeight
          ageValue
          protocolID
          departmentID
          oneweightID
          ambulanceID
          incidentNumber
          startTime
          endTime
          estimatedWeight
          patientSaved
          patientReleased
          arrivedAtHospital
          ageTimestamp
          userIDs
          estimatedSaved
          tags
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Ambulances {
        items {
          id
          name
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Drips {
        items {
          id
          name
          rxNormCode
          contraindication
          note
          warning
          route
          medClass
          action
          indication
          interaction
          onset
          duration
          departmentID
          rangeLow
          rangeHigh
          maxDose
          minDose
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      RequireSync {
        id
        sync
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      logoID
      userID
      neonateCutoff
      pediatricCutoff
      calculators
      adultRanges {
        label
        value
        __typename
      }
      softwarePlan
      Notifications {
        items {
          id
          type
          title
          message
          timestamp
          expirationTimestamp
          deadlineTimestamp
          isReadIDs
          isAckIDs
          imageURLs
          videoURLs
          fileURLs
          taggedProtocols
          isPush
          isPinRequired
          departmentID
          pairedDepIDs
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Contacts {
        items {
          id
          fullName
          title
          number
          note
          index
          departmentID
          pairedDepIDs
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      InputForms {
        items {
          id
          name
          departmentID
          pairedDepIDs
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      FormLogs {
        items {
          id
          inputformID
          departmentID
          userID
          pairedDepID
          pairedUserIDs
          notes
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Workbooks {
        items {
          id
          name
          workbookID
          fileSize
          aiPdfParserResults
          activeID
          createdBy
          modifiedBy
          status
          version
          pairedDepIDs
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      WeightObjects {
        items {
          id
          name
          weight
          createdBy
          modifiedBy
          version
          pairedDepIDs
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Keychains {
        items {
          id
          name
          hashedPin
          saltPin
          hashType
          version
          createdBy
          modifiedBy
          metaData
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Reviewals {
        items {
          id
          title
          description
          reviewers
          status
          createdAt
          endedAt
          departmentID
          userID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Groups {
        items {
          id
          name
          type
          departmentID
          pairedDepIDs
          pairedUserIDs
          isAutoGen
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      CPRAssists {
        items {
          id
          protocolIDs
          epiIDs
          defibIDs
          equipmentID
          departmentID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      subDepIDs
      parentDepID
      indexedParentDepID
      activeStatus
      agencyNumEMS
      stateIdEMS
      gnisCodeEMS
      uniqueCode
      uniquePublicURL
      hashedPin
      saltedPin
      keychainID
      isPublic
      infusionCalculation
      isPublicSignup
      config {
        isTopEnabled
        neonateCutoff
        pediatricCutoff
        calculators
        adultRanges {
          label
          value
          __typename
        }
        softwarePlan
        infusionCalculation
        isPublic
        realTimeUpdating
        epcrProvider
        oneweightEnabled
        ageFilterEnabled
        ageGroupFilterEnabled
        renewalDate
        infusionGTTS
        signedAgreement
        isToggleAdvancedFiltersEnabled
        premiumTrialExpiration
        estimationMethod
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      departmentRequireSyncId
      __typename
    }
    Logs {
      items {
        id
        actualWeight
        ageValue
        protocolID
        Users {
          nextToken
          startedAt
          __typename
        }
        departmentID
        oneweightID
        ambulanceID
        incidentNumber
        startTime
        endTime
        estimatedWeight
        medications {
          medicationID
          amntSolid
          amntLiquid
          timestamp
          verified
          name
          rxNormCode
          concentration
          isActualWeight
          adminType
          weight
          protocolID
          __typename
        }
        equipments {
          equipmentID
          amount
          name
          snomedCode
          timestamp
          isActualWeight
          verified
          weight
          successful
          protocolID
          __typename
        }
        electricals {
          electricalID
          name
          snomedCode
          amount
          timestamp
          isActualWeight
          verified
          weight
          protocolID
          __typename
        }
        cpr {
          cprID
          startTime
          endTime
          outcome
          __typename
        }
        emsCalculators {
          calculatorID
          responses
          scores
          timestamp
          __typename
        }
        patientSaved
        patientReleased
        arrivedAtHospital
        ageTimestamp
        forms {
          formID
          name
          completed
          startedTime
          protocolID
          __typename
        }
        subtractedItems {
          weightObjectID
          name
          weight
          added
          __typename
        }
        userIDs
        infusions {
          dripID
          amnt
          amnt2
          timestamp
          verified
          name
          rxNormCode
          concentration
          adminType
          weight
          protocolID
          amntUnit
          amntUnit2
          isActualWeight
          gttsDropFactor
          gttsAmount
          gttsUnit
          __typename
        }
        estimatedSaved
        tags
        shifts {
          shiftStart
          shiftEnd
          completedLogs
          taggedAmbulance
          taggedOneWeight
          taggedUsers
          ownerID
          departmentID
          metaData
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    departmentOneWeightsId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetOneWeightQueryVariables,
  APITypes.GetOneWeightQuery
>;
export const listOneWeights = /* GraphQL */ `query ListOneWeights(
  $filter: ModelOneWeightFilterInput
  $limit: Int
  $nextToken: String
) {
  listOneWeights(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      serialNumber
      projectNumber
      lastCalibration
      type
      departmentID
      department {
        id
        Categories {
          nextToken
          startedAt
          __typename
        }
        OneWeights {
          nextToken
          startedAt
          __typename
        }
        name
        location
        protocolVersions
        shiftTypes
        Medications {
          nextToken
          startedAt
          __typename
        }
        Equipment {
          nextToken
          startedAt
          __typename
        }
        Forms {
          nextToken
          startedAt
          __typename
        }
        Vitals {
          nextToken
          startedAt
          __typename
        }
        Electricals {
          nextToken
          startedAt
          __typename
        }
        ElectricalShocks {
          nextToken
          startedAt
          __typename
        }
        cognitoID
        Users {
          nextToken
          startedAt
          __typename
        }
        Logs {
          nextToken
          startedAt
          __typename
        }
        Ambulances {
          nextToken
          startedAt
          __typename
        }
        Drips {
          nextToken
          startedAt
          __typename
        }
        RequireSync {
          id
          sync
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        logoID
        userID
        neonateCutoff
        pediatricCutoff
        calculators
        adultRanges {
          label
          value
          __typename
        }
        softwarePlan
        Notifications {
          nextToken
          startedAt
          __typename
        }
        Contacts {
          nextToken
          startedAt
          __typename
        }
        InputForms {
          nextToken
          startedAt
          __typename
        }
        FormLogs {
          nextToken
          startedAt
          __typename
        }
        Workbooks {
          nextToken
          startedAt
          __typename
        }
        WeightObjects {
          nextToken
          startedAt
          __typename
        }
        Keychains {
          nextToken
          startedAt
          __typename
        }
        Reviewals {
          nextToken
          startedAt
          __typename
        }
        Groups {
          nextToken
          startedAt
          __typename
        }
        CPRAssists {
          nextToken
          startedAt
          __typename
        }
        subDepIDs
        parentDepID
        indexedParentDepID
        activeStatus
        agencyNumEMS
        stateIdEMS
        gnisCodeEMS
        uniqueCode
        uniquePublicURL
        hashedPin
        saltedPin
        keychainID
        isPublic
        infusionCalculation
        isPublicSignup
        config {
          isTopEnabled
          neonateCutoff
          pediatricCutoff
          calculators
          softwarePlan
          infusionCalculation
          isPublic
          realTimeUpdating
          epcrProvider
          oneweightEnabled
          ageFilterEnabled
          ageGroupFilterEnabled
          renewalDate
          infusionGTTS
          signedAgreement
          isToggleAdvancedFiltersEnabled
          premiumTrialExpiration
          estimationMethod
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        departmentRequireSyncId
        __typename
      }
      Logs {
        items {
          id
          actualWeight
          ageValue
          protocolID
          departmentID
          oneweightID
          ambulanceID
          incidentNumber
          startTime
          endTime
          estimatedWeight
          patientSaved
          patientReleased
          arrivedAtHospital
          ageTimestamp
          userIDs
          estimatedSaved
          tags
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      departmentOneWeightsId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListOneWeightsQueryVariables,
  APITypes.ListOneWeightsQuery
>;
export const syncOneWeights = /* GraphQL */ `query SyncOneWeights(
  $filter: ModelOneWeightFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncOneWeights(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      name
      serialNumber
      projectNumber
      lastCalibration
      type
      departmentID
      department {
        id
        Categories {
          nextToken
          startedAt
          __typename
        }
        OneWeights {
          nextToken
          startedAt
          __typename
        }
        name
        location
        protocolVersions
        shiftTypes
        Medications {
          nextToken
          startedAt
          __typename
        }
        Equipment {
          nextToken
          startedAt
          __typename
        }
        Forms {
          nextToken
          startedAt
          __typename
        }
        Vitals {
          nextToken
          startedAt
          __typename
        }
        Electricals {
          nextToken
          startedAt
          __typename
        }
        ElectricalShocks {
          nextToken
          startedAt
          __typename
        }
        cognitoID
        Users {
          nextToken
          startedAt
          __typename
        }
        Logs {
          nextToken
          startedAt
          __typename
        }
        Ambulances {
          nextToken
          startedAt
          __typename
        }
        Drips {
          nextToken
          startedAt
          __typename
        }
        RequireSync {
          id
          sync
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        logoID
        userID
        neonateCutoff
        pediatricCutoff
        calculators
        adultRanges {
          label
          value
          __typename
        }
        softwarePlan
        Notifications {
          nextToken
          startedAt
          __typename
        }
        Contacts {
          nextToken
          startedAt
          __typename
        }
        InputForms {
          nextToken
          startedAt
          __typename
        }
        FormLogs {
          nextToken
          startedAt
          __typename
        }
        Workbooks {
          nextToken
          startedAt
          __typename
        }
        WeightObjects {
          nextToken
          startedAt
          __typename
        }
        Keychains {
          nextToken
          startedAt
          __typename
        }
        Reviewals {
          nextToken
          startedAt
          __typename
        }
        Groups {
          nextToken
          startedAt
          __typename
        }
        CPRAssists {
          nextToken
          startedAt
          __typename
        }
        subDepIDs
        parentDepID
        indexedParentDepID
        activeStatus
        agencyNumEMS
        stateIdEMS
        gnisCodeEMS
        uniqueCode
        uniquePublicURL
        hashedPin
        saltedPin
        keychainID
        isPublic
        infusionCalculation
        isPublicSignup
        config {
          isTopEnabled
          neonateCutoff
          pediatricCutoff
          calculators
          softwarePlan
          infusionCalculation
          isPublic
          realTimeUpdating
          epcrProvider
          oneweightEnabled
          ageFilterEnabled
          ageGroupFilterEnabled
          renewalDate
          infusionGTTS
          signedAgreement
          isToggleAdvancedFiltersEnabled
          premiumTrialExpiration
          estimationMethod
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        departmentRequireSyncId
        __typename
      }
      Logs {
        items {
          id
          actualWeight
          ageValue
          protocolID
          departmentID
          oneweightID
          ambulanceID
          incidentNumber
          startTime
          endTime
          estimatedWeight
          patientSaved
          patientReleased
          arrivedAtHospital
          ageTimestamp
          userIDs
          estimatedSaved
          tags
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      departmentOneWeightsId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncOneWeightsQueryVariables,
  APITypes.SyncOneWeightsQuery
>;
export const getForm = /* GraphQL */ `query GetForm($id: ID!) {
  getForm(id: $id) {
    id
    name
    departmentID
    items {
      id
      title
      options {
        id
        firstItem
        secItem
        index
        __typename
      }
      isUrgent
      isDouble
      index
      __typename
    }
    activeID
    overrideID
    status
    version
    createdBy
    modifiedBy
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetFormQueryVariables, APITypes.GetFormQuery>;
export const listForms = /* GraphQL */ `query ListForms(
  $filter: ModelFormFilterInput
  $limit: Int
  $nextToken: String
) {
  listForms(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      departmentID
      items {
        id
        title
        options {
          id
          firstItem
          secItem
          index
          __typename
        }
        isUrgent
        isDouble
        index
        __typename
      }
      activeID
      overrideID
      status
      version
      createdBy
      modifiedBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.ListFormsQueryVariables, APITypes.ListFormsQuery>;
export const syncForms = /* GraphQL */ `query SyncForms(
  $filter: ModelFormFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncForms(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      name
      departmentID
      items {
        id
        title
        options {
          id
          firstItem
          secItem
          index
          __typename
        }
        isUrgent
        isDouble
        index
        __typename
      }
      activeID
      overrideID
      status
      version
      createdBy
      modifiedBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.SyncFormsQueryVariables, APITypes.SyncFormsQuery>;
export const formsByDepartmentID = /* GraphQL */ `query FormsByDepartmentID(
  $departmentID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelFormFilterInput
  $limit: Int
  $nextToken: String
) {
  formsByDepartmentID(
    departmentID: $departmentID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      departmentID
      items {
        id
        title
        options {
          id
          firstItem
          secItem
          index
          __typename
        }
        isUrgent
        isDouble
        index
        __typename
      }
      activeID
      overrideID
      status
      version
      createdBy
      modifiedBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.FormsByDepartmentIDQueryVariables,
  APITypes.FormsByDepartmentIDQuery
>;
export const getLog = /* GraphQL */ `query GetLog($id: ID!) {
  getLog(id: $id) {
    id
    actualWeight
    ageValue
    protocolID
    Users {
      items {
        id
        userId
        logId
        user {
          id
          firstName
          lastName
          type
          cognitoID
          indexedParentDepID
          departmentID
          depAdmins
          hashedPin
          saltPin
          pairedDepIDs
          oneDoseVersion
          lastActivity
          notificationTokens
          deviceIDs
          maxDevices
          allowAnalytics
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        log {
          id
          actualWeight
          ageValue
          protocolID
          departmentID
          oneweightID
          ambulanceID
          incidentNumber
          startTime
          endTime
          estimatedWeight
          patientSaved
          patientReleased
          arrivedAtHospital
          ageTimestamp
          userIDs
          estimatedSaved
          tags
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    departmentID
    oneweightID
    ambulanceID
    incidentNumber
    startTime
    endTime
    estimatedWeight
    medications {
      medicationID
      amntSolid
      amntLiquid
      timestamp
      verified
      name
      rxNormCode
      concentration
      isActualWeight
      adminType
      route {
        route
        code
        __typename
      }
      weight
      protocolID
      __typename
    }
    equipments {
      equipmentID
      amount
      name
      snomedCode
      timestamp
      isActualWeight
      verified
      weight
      successful
      protocolID
      __typename
    }
    electricals {
      electricalID
      name
      snomedCode
      amount
      timestamp
      isActualWeight
      verified
      weight
      protocolID
      __typename
    }
    cpr {
      cprID
      startTime
      endTime
      outcome
      __typename
    }
    emsCalculators {
      calculatorID
      responses
      scores
      timestamp
      __typename
    }
    patientSaved
    patientReleased
    arrivedAtHospital
    ageTimestamp
    forms {
      formID
      name
      completed
      startedTime
      protocolID
      __typename
    }
    subtractedItems {
      weightObjectID
      name
      weight
      added
      __typename
    }
    userIDs
    infusions {
      dripID
      amnt
      amnt2
      timestamp
      verified
      name
      rxNormCode
      concentration
      adminType
      route {
        route
        code
        __typename
      }
      weight
      protocolID
      amntUnit
      amntUnit2
      isActualWeight
      gttsDropFactor
      gttsAmount
      gttsUnit
      __typename
    }
    estimatedSaved
    tags
    shifts {
      shiftStart
      shiftEnd
      completedLogs
      taggedAmbulance
      taggedOneWeight
      taggedUsers
      ownerID
      departmentID
      metaData
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetLogQueryVariables, APITypes.GetLogQuery>;
export const listLogs = /* GraphQL */ `query ListLogs($filter: ModelLogFilterInput, $limit: Int, $nextToken: String) {
  listLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      actualWeight
      ageValue
      protocolID
      Users {
        items {
          id
          userId
          logId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      departmentID
      oneweightID
      ambulanceID
      incidentNumber
      startTime
      endTime
      estimatedWeight
      medications {
        medicationID
        amntSolid
        amntLiquid
        timestamp
        verified
        name
        rxNormCode
        concentration
        isActualWeight
        adminType
        route {
          route
          code
          __typename
        }
        weight
        protocolID
        __typename
      }
      equipments {
        equipmentID
        amount
        name
        snomedCode
        timestamp
        isActualWeight
        verified
        weight
        successful
        protocolID
        __typename
      }
      electricals {
        electricalID
        name
        snomedCode
        amount
        timestamp
        isActualWeight
        verified
        weight
        protocolID
        __typename
      }
      cpr {
        cprID
        startTime
        endTime
        outcome
        __typename
      }
      emsCalculators {
        calculatorID
        responses
        scores
        timestamp
        __typename
      }
      patientSaved
      patientReleased
      arrivedAtHospital
      ageTimestamp
      forms {
        formID
        name
        completed
        startedTime
        protocolID
        __typename
      }
      subtractedItems {
        weightObjectID
        name
        weight
        added
        __typename
      }
      userIDs
      infusions {
        dripID
        amnt
        amnt2
        timestamp
        verified
        name
        rxNormCode
        concentration
        adminType
        route {
          route
          code
          __typename
        }
        weight
        protocolID
        amntUnit
        amntUnit2
        isActualWeight
        gttsDropFactor
        gttsAmount
        gttsUnit
        __typename
      }
      estimatedSaved
      tags
      shifts {
        shiftStart
        shiftEnd
        completedLogs
        taggedAmbulance
        taggedOneWeight
        taggedUsers
        ownerID
        departmentID
        metaData
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.ListLogsQueryVariables, APITypes.ListLogsQuery>;
export const syncLogs = /* GraphQL */ `query SyncLogs(
  $filter: ModelLogFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncLogs(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      actualWeight
      ageValue
      protocolID
      Users {
        items {
          id
          userId
          logId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      departmentID
      oneweightID
      ambulanceID
      incidentNumber
      startTime
      endTime
      estimatedWeight
      medications {
        medicationID
        amntSolid
        amntLiquid
        timestamp
        verified
        name
        rxNormCode
        concentration
        isActualWeight
        adminType
        route {
          route
          code
          __typename
        }
        weight
        protocolID
        __typename
      }
      equipments {
        equipmentID
        amount
        name
        snomedCode
        timestamp
        isActualWeight
        verified
        weight
        successful
        protocolID
        __typename
      }
      electricals {
        electricalID
        name
        snomedCode
        amount
        timestamp
        isActualWeight
        verified
        weight
        protocolID
        __typename
      }
      cpr {
        cprID
        startTime
        endTime
        outcome
        __typename
      }
      emsCalculators {
        calculatorID
        responses
        scores
        timestamp
        __typename
      }
      patientSaved
      patientReleased
      arrivedAtHospital
      ageTimestamp
      forms {
        formID
        name
        completed
        startedTime
        protocolID
        __typename
      }
      subtractedItems {
        weightObjectID
        name
        weight
        added
        __typename
      }
      userIDs
      infusions {
        dripID
        amnt
        amnt2
        timestamp
        verified
        name
        rxNormCode
        concentration
        adminType
        route {
          route
          code
          __typename
        }
        weight
        protocolID
        amntUnit
        amntUnit2
        isActualWeight
        gttsDropFactor
        gttsAmount
        gttsUnit
        __typename
      }
      estimatedSaved
      tags
      shifts {
        shiftStart
        shiftEnd
        completedLogs
        taggedAmbulance
        taggedOneWeight
        taggedUsers
        ownerID
        departmentID
        metaData
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.SyncLogsQueryVariables, APITypes.SyncLogsQuery>;
export const logsByDepartmentAndEndTime = /* GraphQL */ `query LogsByDepartmentAndEndTime(
  $departmentID: ID!
  $endTime: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelLogFilterInput
  $limit: Int
  $nextToken: String
) {
  logsByDepartmentAndEndTime(
    departmentID: $departmentID
    endTime: $endTime
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      actualWeight
      ageValue
      protocolID
      Users {
        items {
          id
          userId
          logId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      departmentID
      oneweightID
      ambulanceID
      incidentNumber
      startTime
      endTime
      estimatedWeight
      medications {
        medicationID
        amntSolid
        amntLiquid
        timestamp
        verified
        name
        rxNormCode
        concentration
        isActualWeight
        adminType
        route {
          route
          code
          __typename
        }
        weight
        protocolID
        __typename
      }
      equipments {
        equipmentID
        amount
        name
        snomedCode
        timestamp
        isActualWeight
        verified
        weight
        successful
        protocolID
        __typename
      }
      electricals {
        electricalID
        name
        snomedCode
        amount
        timestamp
        isActualWeight
        verified
        weight
        protocolID
        __typename
      }
      cpr {
        cprID
        startTime
        endTime
        outcome
        __typename
      }
      emsCalculators {
        calculatorID
        responses
        scores
        timestamp
        __typename
      }
      patientSaved
      patientReleased
      arrivedAtHospital
      ageTimestamp
      forms {
        formID
        name
        completed
        startedTime
        protocolID
        __typename
      }
      subtractedItems {
        weightObjectID
        name
        weight
        added
        __typename
      }
      userIDs
      infusions {
        dripID
        amnt
        amnt2
        timestamp
        verified
        name
        rxNormCode
        concentration
        adminType
        route {
          route
          code
          __typename
        }
        weight
        protocolID
        amntUnit
        amntUnit2
        isActualWeight
        gttsDropFactor
        gttsAmount
        gttsUnit
        __typename
      }
      estimatedSaved
      tags
      shifts {
        shiftStart
        shiftEnd
        completedLogs
        taggedAmbulance
        taggedOneWeight
        taggedUsers
        ownerID
        departmentID
        metaData
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.LogsByDepartmentAndEndTimeQueryVariables,
  APITypes.LogsByDepartmentAndEndTimeQuery
>;
export const logsByOneweightID = /* GraphQL */ `query LogsByOneweightID(
  $oneweightID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelLogFilterInput
  $limit: Int
  $nextToken: String
) {
  logsByOneweightID(
    oneweightID: $oneweightID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      actualWeight
      ageValue
      protocolID
      Users {
        items {
          id
          userId
          logId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      departmentID
      oneweightID
      ambulanceID
      incidentNumber
      startTime
      endTime
      estimatedWeight
      medications {
        medicationID
        amntSolid
        amntLiquid
        timestamp
        verified
        name
        rxNormCode
        concentration
        isActualWeight
        adminType
        route {
          route
          code
          __typename
        }
        weight
        protocolID
        __typename
      }
      equipments {
        equipmentID
        amount
        name
        snomedCode
        timestamp
        isActualWeight
        verified
        weight
        successful
        protocolID
        __typename
      }
      electricals {
        electricalID
        name
        snomedCode
        amount
        timestamp
        isActualWeight
        verified
        weight
        protocolID
        __typename
      }
      cpr {
        cprID
        startTime
        endTime
        outcome
        __typename
      }
      emsCalculators {
        calculatorID
        responses
        scores
        timestamp
        __typename
      }
      patientSaved
      patientReleased
      arrivedAtHospital
      ageTimestamp
      forms {
        formID
        name
        completed
        startedTime
        protocolID
        __typename
      }
      subtractedItems {
        weightObjectID
        name
        weight
        added
        __typename
      }
      userIDs
      infusions {
        dripID
        amnt
        amnt2
        timestamp
        verified
        name
        rxNormCode
        concentration
        adminType
        route {
          route
          code
          __typename
        }
        weight
        protocolID
        amntUnit
        amntUnit2
        isActualWeight
        gttsDropFactor
        gttsAmount
        gttsUnit
        __typename
      }
      estimatedSaved
      tags
      shifts {
        shiftStart
        shiftEnd
        completedLogs
        taggedAmbulance
        taggedOneWeight
        taggedUsers
        ownerID
        departmentID
        metaData
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.LogsByOneweightIDQueryVariables,
  APITypes.LogsByOneweightIDQuery
>;
export const logsByAmbulanceID = /* GraphQL */ `query LogsByAmbulanceID(
  $ambulanceID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelLogFilterInput
  $limit: Int
  $nextToken: String
) {
  logsByAmbulanceID(
    ambulanceID: $ambulanceID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      actualWeight
      ageValue
      protocolID
      Users {
        items {
          id
          userId
          logId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      departmentID
      oneweightID
      ambulanceID
      incidentNumber
      startTime
      endTime
      estimatedWeight
      medications {
        medicationID
        amntSolid
        amntLiquid
        timestamp
        verified
        name
        rxNormCode
        concentration
        isActualWeight
        adminType
        route {
          route
          code
          __typename
        }
        weight
        protocolID
        __typename
      }
      equipments {
        equipmentID
        amount
        name
        snomedCode
        timestamp
        isActualWeight
        verified
        weight
        successful
        protocolID
        __typename
      }
      electricals {
        electricalID
        name
        snomedCode
        amount
        timestamp
        isActualWeight
        verified
        weight
        protocolID
        __typename
      }
      cpr {
        cprID
        startTime
        endTime
        outcome
        __typename
      }
      emsCalculators {
        calculatorID
        responses
        scores
        timestamp
        __typename
      }
      patientSaved
      patientReleased
      arrivedAtHospital
      ageTimestamp
      forms {
        formID
        name
        completed
        startedTime
        protocolID
        __typename
      }
      subtractedItems {
        weightObjectID
        name
        weight
        added
        __typename
      }
      userIDs
      infusions {
        dripID
        amnt
        amnt2
        timestamp
        verified
        name
        rxNormCode
        concentration
        adminType
        route {
          route
          code
          __typename
        }
        weight
        protocolID
        amntUnit
        amntUnit2
        isActualWeight
        gttsDropFactor
        gttsAmount
        gttsUnit
        __typename
      }
      estimatedSaved
      tags
      shifts {
        shiftStart
        shiftEnd
        completedLogs
        taggedAmbulance
        taggedOneWeight
        taggedUsers
        ownerID
        departmentID
        metaData
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.LogsByAmbulanceIDQueryVariables,
  APITypes.LogsByAmbulanceIDQuery
>;
export const logsByIncidentNumber = /* GraphQL */ `query LogsByIncidentNumber(
  $incidentNumber: String!
  $sortDirection: ModelSortDirection
  $filter: ModelLogFilterInput
  $limit: Int
  $nextToken: String
) {
  logsByIncidentNumber(
    incidentNumber: $incidentNumber
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      actualWeight
      ageValue
      protocolID
      Users {
        items {
          id
          userId
          logId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      departmentID
      oneweightID
      ambulanceID
      incidentNumber
      startTime
      endTime
      estimatedWeight
      medications {
        medicationID
        amntSolid
        amntLiquid
        timestamp
        verified
        name
        rxNormCode
        concentration
        isActualWeight
        adminType
        route {
          route
          code
          __typename
        }
        weight
        protocolID
        __typename
      }
      equipments {
        equipmentID
        amount
        name
        snomedCode
        timestamp
        isActualWeight
        verified
        weight
        successful
        protocolID
        __typename
      }
      electricals {
        electricalID
        name
        snomedCode
        amount
        timestamp
        isActualWeight
        verified
        weight
        protocolID
        __typename
      }
      cpr {
        cprID
        startTime
        endTime
        outcome
        __typename
      }
      emsCalculators {
        calculatorID
        responses
        scores
        timestamp
        __typename
      }
      patientSaved
      patientReleased
      arrivedAtHospital
      ageTimestamp
      forms {
        formID
        name
        completed
        startedTime
        protocolID
        __typename
      }
      subtractedItems {
        weightObjectID
        name
        weight
        added
        __typename
      }
      userIDs
      infusions {
        dripID
        amnt
        amnt2
        timestamp
        verified
        name
        rxNormCode
        concentration
        adminType
        route {
          route
          code
          __typename
        }
        weight
        protocolID
        amntUnit
        amntUnit2
        isActualWeight
        gttsDropFactor
        gttsAmount
        gttsUnit
        __typename
      }
      estimatedSaved
      tags
      shifts {
        shiftStart
        shiftEnd
        completedLogs
        taggedAmbulance
        taggedOneWeight
        taggedUsers
        ownerID
        departmentID
        metaData
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.LogsByIncidentNumberQueryVariables,
  APITypes.LogsByIncidentNumberQuery
>;
export const getRequireSync = /* GraphQL */ `query GetRequireSync($id: ID!) {
  getRequireSync(id: $id) {
    id
    sync
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetRequireSyncQueryVariables,
  APITypes.GetRequireSyncQuery
>;
export const listRequireSyncs = /* GraphQL */ `query ListRequireSyncs(
  $filter: ModelRequireSyncFilterInput
  $limit: Int
  $nextToken: String
) {
  listRequireSyncs(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      sync
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListRequireSyncsQueryVariables,
  APITypes.ListRequireSyncsQuery
>;
export const syncRequireSyncs = /* GraphQL */ `query SyncRequireSyncs(
  $filter: ModelRequireSyncFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncRequireSyncs(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      sync
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncRequireSyncsQueryVariables,
  APITypes.SyncRequireSyncsQuery
>;
export const getHardwareVersion = /* GraphQL */ `query GetHardwareVersion($id: ID!) {
  getHardwareVersion(id: $id) {
    id
    version
    name
    description
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetHardwareVersionQueryVariables,
  APITypes.GetHardwareVersionQuery
>;
export const listHardwareVersions = /* GraphQL */ `query ListHardwareVersions(
  $filter: ModelHardwareVersionFilterInput
  $limit: Int
  $nextToken: String
) {
  listHardwareVersions(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      version
      name
      description
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListHardwareVersionsQueryVariables,
  APITypes.ListHardwareVersionsQuery
>;
export const syncHardwareVersions = /* GraphQL */ `query SyncHardwareVersions(
  $filter: ModelHardwareVersionFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncHardwareVersions(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      version
      name
      description
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncHardwareVersionsQueryVariables,
  APITypes.SyncHardwareVersionsQuery
>;
export const getFirmwareVersion = /* GraphQL */ `query GetFirmwareVersion($id: ID!) {
  getFirmwareVersion(id: $id) {
    id
    name
    version
    fileURL
    description
    cover_image
    compatibleHW
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetFirmwareVersionQueryVariables,
  APITypes.GetFirmwareVersionQuery
>;
export const listFirmwareVersions = /* GraphQL */ `query ListFirmwareVersions(
  $filter: ModelFirmwareVersionFilterInput
  $limit: Int
  $nextToken: String
) {
  listFirmwareVersions(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      version
      fileURL
      description
      cover_image
      compatibleHW
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListFirmwareVersionsQueryVariables,
  APITypes.ListFirmwareVersionsQuery
>;
export const syncFirmwareVersions = /* GraphQL */ `query SyncFirmwareVersions(
  $filter: ModelFirmwareVersionFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncFirmwareVersions(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      name
      version
      fileURL
      description
      cover_image
      compatibleHW
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncFirmwareVersionsQueryVariables,
  APITypes.SyncFirmwareVersionsQuery
>;
export const getWorkbook = /* GraphQL */ `query GetWorkbook($id: ID!) {
  getWorkbook(id: $id) {
    id
    name
    workbookID
    fileSize
    aiPdfParserResults
    activeID
    createdBy
    modifiedBy
    status
    version
    pairedDepIDs
    departmentID
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetWorkbookQueryVariables,
  APITypes.GetWorkbookQuery
>;
export const listWorkbooks = /* GraphQL */ `query ListWorkbooks(
  $filter: ModelWorkbookFilterInput
  $limit: Int
  $nextToken: String
) {
  listWorkbooks(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      workbookID
      fileSize
      aiPdfParserResults
      activeID
      createdBy
      modifiedBy
      status
      version
      pairedDepIDs
      departmentID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListWorkbooksQueryVariables,
  APITypes.ListWorkbooksQuery
>;
export const syncWorkbooks = /* GraphQL */ `query SyncWorkbooks(
  $filter: ModelWorkbookFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncWorkbooks(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      name
      workbookID
      fileSize
      aiPdfParserResults
      activeID
      createdBy
      modifiedBy
      status
      version
      pairedDepIDs
      departmentID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncWorkbooksQueryVariables,
  APITypes.SyncWorkbooksQuery
>;
export const workbooksByDepartmentID = /* GraphQL */ `query WorkbooksByDepartmentID(
  $departmentID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelWorkbookFilterInput
  $limit: Int
  $nextToken: String
) {
  workbooksByDepartmentID(
    departmentID: $departmentID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      workbookID
      fileSize
      aiPdfParserResults
      activeID
      createdBy
      modifiedBy
      status
      version
      pairedDepIDs
      departmentID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.WorkbooksByDepartmentIDQueryVariables,
  APITypes.WorkbooksByDepartmentIDQuery
>;
export const getWeightObject = /* GraphQL */ `query GetWeightObject($id: ID!) {
  getWeightObject(id: $id) {
    id
    name
    weight
    createdBy
    modifiedBy
    version
    pairedDepIDs
    departmentID
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetWeightObjectQueryVariables,
  APITypes.GetWeightObjectQuery
>;
export const listWeightObjects = /* GraphQL */ `query ListWeightObjects(
  $filter: ModelWeightObjectFilterInput
  $limit: Int
  $nextToken: String
) {
  listWeightObjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      weight
      createdBy
      modifiedBy
      version
      pairedDepIDs
      departmentID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListWeightObjectsQueryVariables,
  APITypes.ListWeightObjectsQuery
>;
export const syncWeightObjects = /* GraphQL */ `query SyncWeightObjects(
  $filter: ModelWeightObjectFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncWeightObjects(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      name
      weight
      createdBy
      modifiedBy
      version
      pairedDepIDs
      departmentID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncWeightObjectsQueryVariables,
  APITypes.SyncWeightObjectsQuery
>;
export const weightObjectsByDepartmentID = /* GraphQL */ `query WeightObjectsByDepartmentID(
  $departmentID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelWeightObjectFilterInput
  $limit: Int
  $nextToken: String
) {
  weightObjectsByDepartmentID(
    departmentID: $departmentID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      weight
      createdBy
      modifiedBy
      version
      pairedDepIDs
      departmentID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.WeightObjectsByDepartmentIDQueryVariables,
  APITypes.WeightObjectsByDepartmentIDQuery
>;
export const getConcentration = /* GraphQL */ `query GetConcentration($id: ID!) {
  getConcentration(id: $id) {
    id
    firstAmnt
    firstUnit
    secAmnt
    secUnit
    color
    departmentID
    medicationID
    dripID
    status
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetConcentrationQueryVariables,
  APITypes.GetConcentrationQuery
>;
export const listConcentrations = /* GraphQL */ `query ListConcentrations(
  $filter: ModelConcentrationFilterInput
  $limit: Int
  $nextToken: String
) {
  listConcentrations(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      firstAmnt
      firstUnit
      secAmnt
      secUnit
      color
      departmentID
      medicationID
      dripID
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListConcentrationsQueryVariables,
  APITypes.ListConcentrationsQuery
>;
export const syncConcentrations = /* GraphQL */ `query SyncConcentrations(
  $filter: ModelConcentrationFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncConcentrations(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      firstAmnt
      firstUnit
      secAmnt
      secUnit
      color
      departmentID
      medicationID
      dripID
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncConcentrationsQueryVariables,
  APITypes.SyncConcentrationsQuery
>;
export const concentrationsByDepartmentID = /* GraphQL */ `query ConcentrationsByDepartmentID(
  $departmentID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelConcentrationFilterInput
  $limit: Int
  $nextToken: String
) {
  concentrationsByDepartmentID(
    departmentID: $departmentID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      firstAmnt
      firstUnit
      secAmnt
      secUnit
      color
      departmentID
      medicationID
      dripID
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ConcentrationsByDepartmentIDQueryVariables,
  APITypes.ConcentrationsByDepartmentIDQuery
>;
export const concentrationsByMedicationID = /* GraphQL */ `query ConcentrationsByMedicationID(
  $medicationID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelConcentrationFilterInput
  $limit: Int
  $nextToken: String
) {
  concentrationsByMedicationID(
    medicationID: $medicationID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      firstAmnt
      firstUnit
      secAmnt
      secUnit
      color
      departmentID
      medicationID
      dripID
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ConcentrationsByMedicationIDQueryVariables,
  APITypes.ConcentrationsByMedicationIDQuery
>;
export const concentrationsByDripID = /* GraphQL */ `query ConcentrationsByDripID(
  $dripID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelConcentrationFilterInput
  $limit: Int
  $nextToken: String
) {
  concentrationsByDripID(
    dripID: $dripID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      firstAmnt
      firstUnit
      secAmnt
      secUnit
      color
      departmentID
      medicationID
      dripID
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ConcentrationsByDripIDQueryVariables,
  APITypes.ConcentrationsByDripIDQuery
>;
export const getCPRAssist = /* GraphQL */ `query GetCPRAssist($id: ID!) {
  getCPRAssist(id: $id) {
    id
    protocolIDs
    epiIDs
    defibIDs
    equipmentID
    departmentID
    activeID
    overrideID
    status
    version
    createdBy
    modifiedBy
    cprMetaData {
      defaultMode
      epiOrangeSec
      epiRedSec
      compressionRate
      ventRatePerMin
      ventilateSoundDelay
      ventilateLoops
      continousVentilateLoops
      ventilateSoundSpeed
      pulseCheckTime
      chargingTime
      __typename
    }
    DefibDoses {
      items {
        id
        basis
        rangeLow
        rangeHigh
        title
        warning
        instruction
        note
        maxDose
        minDose
        maxTotalDose
        calcMax
        calcMin
        index
        ageLow
        ageHigh
        ageGroup
        repeatTime
        incrementAmnt
        departmentID
        protocolID
        electricalID
        cprAssistID
        activeID
        overrideID
        status
        version
        createdBy
        modifiedBy
        createdAt
        Protocol {
          id
          name
          pdfID
          categoryID
          pairedProtocols
          medicationIDs
          equipmentIDs
          electricalIDs
          dripIDs
          formIDs
          index
          nickname
          pairedDepIDs
          rangeLow
          rangeHigh
          ageLow
          ageHigh
          ageGroup
          status
          version
          pdfVersion
          activeID
          overrideID
          privatePin
          keychainID
          createdBy
          modifiedBy
          isPublic
          isRestrictive
          color
          tags
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Electrical {
          id
          title
          rxNormCode
          warning
          instruction
          note
          rangeHigh
          rangeLow
          departmentID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        CPRAssist {
          id
          protocolIDs
          epiIDs
          defibIDs
          equipmentID
          departmentID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    EpiDoses {
      items {
        id
        basis
        rangeLow
        rangeHigh
        route
        nemsisRoutes {
          route
          code
          __typename
        }
        title
        warning
        instruction
        note
        maxDose
        minDose
        maxTotalDose
        calcMax
        calcMin
        index
        ageLow
        ageHigh
        ageGroup
        repeatTime
        incrementAmnt
        departmentID
        protocolID
        medicationID
        cprAssistID
        activeID
        overrideID
        status
        version
        createdBy
        modifiedBy
        createdAt
        Protocol {
          id
          name
          pdfID
          categoryID
          pairedProtocols
          medicationIDs
          equipmentIDs
          electricalIDs
          dripIDs
          formIDs
          index
          nickname
          pairedDepIDs
          rangeLow
          rangeHigh
          ageLow
          ageHigh
          ageGroup
          status
          version
          pdfVersion
          activeID
          overrideID
          privatePin
          keychainID
          createdBy
          modifiedBy
          isPublic
          isRestrictive
          color
          tags
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Medication {
          id
          name
          rxNormCode
          contraindication
          note
          warning
          route
          medClass
          action
          indication
          interaction
          onset
          duration
          departmentID
          rangeLow
          rangeHigh
          maxDose
          minDose
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        CPRAssist {
          id
          protocolIDs
          epiIDs
          defibIDs
          equipmentID
          departmentID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCPRAssistQueryVariables,
  APITypes.GetCPRAssistQuery
>;
export const listCPRAssists = /* GraphQL */ `query ListCPRAssists(
  $filter: ModelCPRAssistFilterInput
  $limit: Int
  $nextToken: String
) {
  listCPRAssists(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      protocolIDs
      epiIDs
      defibIDs
      equipmentID
      departmentID
      activeID
      overrideID
      status
      version
      createdBy
      modifiedBy
      cprMetaData {
        defaultMode
        epiOrangeSec
        epiRedSec
        compressionRate
        ventRatePerMin
        ventilateSoundDelay
        ventilateLoops
        continousVentilateLoops
        ventilateSoundSpeed
        pulseCheckTime
        chargingTime
        __typename
      }
      DefibDoses {
        items {
          id
          basis
          rangeLow
          rangeHigh
          title
          warning
          instruction
          note
          maxDose
          minDose
          maxTotalDose
          calcMax
          calcMin
          index
          ageLow
          ageHigh
          ageGroup
          repeatTime
          incrementAmnt
          departmentID
          protocolID
          electricalID
          cprAssistID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      EpiDoses {
        items {
          id
          basis
          rangeLow
          rangeHigh
          route
          title
          warning
          instruction
          note
          maxDose
          minDose
          maxTotalDose
          calcMax
          calcMin
          index
          ageLow
          ageHigh
          ageGroup
          repeatTime
          incrementAmnt
          departmentID
          protocolID
          medicationID
          cprAssistID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCPRAssistsQueryVariables,
  APITypes.ListCPRAssistsQuery
>;
export const syncCPRAssists = /* GraphQL */ `query SyncCPRAssists(
  $filter: ModelCPRAssistFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncCPRAssists(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      protocolIDs
      epiIDs
      defibIDs
      equipmentID
      departmentID
      activeID
      overrideID
      status
      version
      createdBy
      modifiedBy
      cprMetaData {
        defaultMode
        epiOrangeSec
        epiRedSec
        compressionRate
        ventRatePerMin
        ventilateSoundDelay
        ventilateLoops
        continousVentilateLoops
        ventilateSoundSpeed
        pulseCheckTime
        chargingTime
        __typename
      }
      DefibDoses {
        items {
          id
          basis
          rangeLow
          rangeHigh
          title
          warning
          instruction
          note
          maxDose
          minDose
          maxTotalDose
          calcMax
          calcMin
          index
          ageLow
          ageHigh
          ageGroup
          repeatTime
          incrementAmnt
          departmentID
          protocolID
          electricalID
          cprAssistID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      EpiDoses {
        items {
          id
          basis
          rangeLow
          rangeHigh
          route
          title
          warning
          instruction
          note
          maxDose
          minDose
          maxTotalDose
          calcMax
          calcMin
          index
          ageLow
          ageHigh
          ageGroup
          repeatTime
          incrementAmnt
          departmentID
          protocolID
          medicationID
          cprAssistID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncCPRAssistsQueryVariables,
  APITypes.SyncCPRAssistsQuery
>;
export const cPRAssistsByDepartmentID = /* GraphQL */ `query CPRAssistsByDepartmentID(
  $departmentID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelCPRAssistFilterInput
  $limit: Int
  $nextToken: String
) {
  cPRAssistsByDepartmentID(
    departmentID: $departmentID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      protocolIDs
      epiIDs
      defibIDs
      equipmentID
      departmentID
      activeID
      overrideID
      status
      version
      createdBy
      modifiedBy
      cprMetaData {
        defaultMode
        epiOrangeSec
        epiRedSec
        compressionRate
        ventRatePerMin
        ventilateSoundDelay
        ventilateLoops
        continousVentilateLoops
        ventilateSoundSpeed
        pulseCheckTime
        chargingTime
        __typename
      }
      DefibDoses {
        items {
          id
          basis
          rangeLow
          rangeHigh
          title
          warning
          instruction
          note
          maxDose
          minDose
          maxTotalDose
          calcMax
          calcMin
          index
          ageLow
          ageHigh
          ageGroup
          repeatTime
          incrementAmnt
          departmentID
          protocolID
          electricalID
          cprAssistID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      EpiDoses {
        items {
          id
          basis
          rangeLow
          rangeHigh
          route
          title
          warning
          instruction
          note
          maxDose
          minDose
          maxTotalDose
          calcMax
          calcMin
          index
          ageLow
          ageHigh
          ageGroup
          repeatTime
          incrementAmnt
          departmentID
          protocolID
          medicationID
          cprAssistID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CPRAssistsByDepartmentIDQueryVariables,
  APITypes.CPRAssistsByDepartmentIDQuery
>;
export const getReviewal = /* GraphQL */ `query GetReviewal($id: ID!) {
  getReviewal(id: $id) {
    id
    title
    description
    reviewers
    status
    events {
      color
      title
      description
      timestamp
      icon
      __typename
    }
    createdAt
    endedAt
    departmentID
    userID
    User {
      id
      firstName
      lastName
      type
      cognitoID
      indexedParentDepID
      departmentID
      depAdmins
      logs {
        items {
          id
          userId
          logId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      hashedPin
      saltPin
      pairedDepIDs
      oneDoseVersion
      lastActivity
      notificationTokens
      deviceIDs
      maxDevices
      allowAnalytics
      status
      FormLogs {
        items {
          id
          inputformID
          departmentID
          userID
          pairedDepID
          pairedUserIDs
          notes
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Reviewals {
        items {
          id
          title
          description
          reviewers
          status
          createdAt
          endedAt
          departmentID
          userID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Acknowledges {
        items {
          id
          reviewalID
          ownerID
          userID
          status
          isArchived
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      UserComments {
        items {
          id
          ownerID
          userID
          acknowledgeID
          message
          relatedItems
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      NotifyACKs {
        items {
          id
          ownerID
          userID
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    DraftGroups {
      items {
        id
        reviewalID
        departmentID
        ownerID
        title
        description
        createdAt
        Reviewal {
          id
          title
          description
          reviewers
          status
          createdAt
          endedAt
          departmentID
          userID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        DraftChanges {
          nextToken
          startedAt
          __typename
        }
        isClosed
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Acknowledges {
      items {
        id
        reviewalID
        ownerID
        userID
        status
        isArchived
        createdAt
        User {
          id
          firstName
          lastName
          type
          cognitoID
          indexedParentDepID
          departmentID
          depAdmins
          hashedPin
          saltPin
          pairedDepIDs
          oneDoseVersion
          lastActivity
          notificationTokens
          deviceIDs
          maxDevices
          allowAnalytics
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Comment {
          id
          ownerID
          userID
          acknowledgeID
          message
          relatedItems
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    UserComments {
      items {
        id
        ownerID
        userID
        acknowledgeID
        message
        relatedItems
        createdAt
        User {
          id
          firstName
          lastName
          type
          cognitoID
          indexedParentDepID
          departmentID
          depAdmins
          hashedPin
          saltPin
          pairedDepIDs
          oneDoseVersion
          lastActivity
          notificationTokens
          deviceIDs
          maxDevices
          allowAnalytics
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Acknowledge {
          id
          reviewalID
          ownerID
          userID
          status
          isArchived
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetReviewalQueryVariables,
  APITypes.GetReviewalQuery
>;
export const listReviewals = /* GraphQL */ `query ListReviewals(
  $filter: ModelReviewalFilterInput
  $limit: Int
  $nextToken: String
) {
  listReviewals(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      title
      description
      reviewers
      status
      events {
        color
        title
        description
        timestamp
        icon
        __typename
      }
      createdAt
      endedAt
      departmentID
      userID
      User {
        id
        firstName
        lastName
        type
        cognitoID
        indexedParentDepID
        departmentID
        depAdmins
        logs {
          nextToken
          startedAt
          __typename
        }
        hashedPin
        saltPin
        pairedDepIDs
        oneDoseVersion
        lastActivity
        notificationTokens
        deviceIDs
        maxDevices
        allowAnalytics
        status
        FormLogs {
          nextToken
          startedAt
          __typename
        }
        Reviewals {
          nextToken
          startedAt
          __typename
        }
        Acknowledges {
          nextToken
          startedAt
          __typename
        }
        UserComments {
          nextToken
          startedAt
          __typename
        }
        NotifyACKs {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      DraftGroups {
        items {
          id
          reviewalID
          departmentID
          ownerID
          title
          description
          createdAt
          isClosed
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Acknowledges {
        items {
          id
          reviewalID
          ownerID
          userID
          status
          isArchived
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      UserComments {
        items {
          id
          ownerID
          userID
          acknowledgeID
          message
          relatedItems
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListReviewalsQueryVariables,
  APITypes.ListReviewalsQuery
>;
export const syncReviewals = /* GraphQL */ `query SyncReviewals(
  $filter: ModelReviewalFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncReviewals(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      title
      description
      reviewers
      status
      events {
        color
        title
        description
        timestamp
        icon
        __typename
      }
      createdAt
      endedAt
      departmentID
      userID
      User {
        id
        firstName
        lastName
        type
        cognitoID
        indexedParentDepID
        departmentID
        depAdmins
        logs {
          nextToken
          startedAt
          __typename
        }
        hashedPin
        saltPin
        pairedDepIDs
        oneDoseVersion
        lastActivity
        notificationTokens
        deviceIDs
        maxDevices
        allowAnalytics
        status
        FormLogs {
          nextToken
          startedAt
          __typename
        }
        Reviewals {
          nextToken
          startedAt
          __typename
        }
        Acknowledges {
          nextToken
          startedAt
          __typename
        }
        UserComments {
          nextToken
          startedAt
          __typename
        }
        NotifyACKs {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      DraftGroups {
        items {
          id
          reviewalID
          departmentID
          ownerID
          title
          description
          createdAt
          isClosed
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Acknowledges {
        items {
          id
          reviewalID
          ownerID
          userID
          status
          isArchived
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      UserComments {
        items {
          id
          ownerID
          userID
          acknowledgeID
          message
          relatedItems
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncReviewalsQueryVariables,
  APITypes.SyncReviewalsQuery
>;
export const reviewalsByDepartmentID = /* GraphQL */ `query ReviewalsByDepartmentID(
  $departmentID: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelReviewalFilterInput
  $limit: Int
  $nextToken: String
) {
  reviewalsByDepartmentID(
    departmentID: $departmentID
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      title
      description
      reviewers
      status
      events {
        color
        title
        description
        timestamp
        icon
        __typename
      }
      createdAt
      endedAt
      departmentID
      userID
      User {
        id
        firstName
        lastName
        type
        cognitoID
        indexedParentDepID
        departmentID
        depAdmins
        logs {
          nextToken
          startedAt
          __typename
        }
        hashedPin
        saltPin
        pairedDepIDs
        oneDoseVersion
        lastActivity
        notificationTokens
        deviceIDs
        maxDevices
        allowAnalytics
        status
        FormLogs {
          nextToken
          startedAt
          __typename
        }
        Reviewals {
          nextToken
          startedAt
          __typename
        }
        Acknowledges {
          nextToken
          startedAt
          __typename
        }
        UserComments {
          nextToken
          startedAt
          __typename
        }
        NotifyACKs {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      DraftGroups {
        items {
          id
          reviewalID
          departmentID
          ownerID
          title
          description
          createdAt
          isClosed
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Acknowledges {
        items {
          id
          reviewalID
          ownerID
          userID
          status
          isArchived
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      UserComments {
        items {
          id
          ownerID
          userID
          acknowledgeID
          message
          relatedItems
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ReviewalsByDepartmentIDQueryVariables,
  APITypes.ReviewalsByDepartmentIDQuery
>;
export const reviewalsByUserID = /* GraphQL */ `query ReviewalsByUserID(
  $userID: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelReviewalFilterInput
  $limit: Int
  $nextToken: String
) {
  reviewalsByUserID(
    userID: $userID
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      title
      description
      reviewers
      status
      events {
        color
        title
        description
        timestamp
        icon
        __typename
      }
      createdAt
      endedAt
      departmentID
      userID
      User {
        id
        firstName
        lastName
        type
        cognitoID
        indexedParentDepID
        departmentID
        depAdmins
        logs {
          nextToken
          startedAt
          __typename
        }
        hashedPin
        saltPin
        pairedDepIDs
        oneDoseVersion
        lastActivity
        notificationTokens
        deviceIDs
        maxDevices
        allowAnalytics
        status
        FormLogs {
          nextToken
          startedAt
          __typename
        }
        Reviewals {
          nextToken
          startedAt
          __typename
        }
        Acknowledges {
          nextToken
          startedAt
          __typename
        }
        UserComments {
          nextToken
          startedAt
          __typename
        }
        NotifyACKs {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      DraftGroups {
        items {
          id
          reviewalID
          departmentID
          ownerID
          title
          description
          createdAt
          isClosed
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Acknowledges {
        items {
          id
          reviewalID
          ownerID
          userID
          status
          isArchived
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      UserComments {
        items {
          id
          ownerID
          userID
          acknowledgeID
          message
          relatedItems
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ReviewalsByUserIDQueryVariables,
  APITypes.ReviewalsByUserIDQuery
>;
export const getDraftGroup = /* GraphQL */ `query GetDraftGroup($id: ID!) {
  getDraftGroup(id: $id) {
    id
    reviewalID
    departmentID
    ownerID
    title
    description
    createdAt
    Reviewal {
      id
      title
      description
      reviewers
      status
      events {
        color
        title
        description
        timestamp
        icon
        __typename
      }
      createdAt
      endedAt
      departmentID
      userID
      User {
        id
        firstName
        lastName
        type
        cognitoID
        indexedParentDepID
        departmentID
        depAdmins
        logs {
          nextToken
          startedAt
          __typename
        }
        hashedPin
        saltPin
        pairedDepIDs
        oneDoseVersion
        lastActivity
        notificationTokens
        deviceIDs
        maxDevices
        allowAnalytics
        status
        FormLogs {
          nextToken
          startedAt
          __typename
        }
        Reviewals {
          nextToken
          startedAt
          __typename
        }
        Acknowledges {
          nextToken
          startedAt
          __typename
        }
        UserComments {
          nextToken
          startedAt
          __typename
        }
        NotifyACKs {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      DraftGroups {
        items {
          id
          reviewalID
          departmentID
          ownerID
          title
          description
          createdAt
          isClosed
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Acknowledges {
        items {
          id
          reviewalID
          ownerID
          userID
          status
          isArchived
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      UserComments {
        items {
          id
          ownerID
          userID
          acknowledgeID
          message
          relatedItems
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    DraftChanges {
      items {
        id
        draftGroupID
        departmentID
        changeID
        previousID
        changeType
        isClosed
        createdAt
        DraftGroup {
          id
          reviewalID
          departmentID
          ownerID
          title
          description
          createdAt
          isClosed
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Comments {
          nextToken
          startedAt
          __typename
        }
        Acknowledges {
          nextToken
          startedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    isClosed
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDraftGroupQueryVariables,
  APITypes.GetDraftGroupQuery
>;
export const listDraftGroups = /* GraphQL */ `query ListDraftGroups(
  $filter: ModelDraftGroupFilterInput
  $limit: Int
  $nextToken: String
) {
  listDraftGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      reviewalID
      departmentID
      ownerID
      title
      description
      createdAt
      Reviewal {
        id
        title
        description
        reviewers
        status
        events {
          color
          title
          description
          timestamp
          icon
          __typename
        }
        createdAt
        endedAt
        departmentID
        userID
        User {
          id
          firstName
          lastName
          type
          cognitoID
          indexedParentDepID
          departmentID
          depAdmins
          hashedPin
          saltPin
          pairedDepIDs
          oneDoseVersion
          lastActivity
          notificationTokens
          deviceIDs
          maxDevices
          allowAnalytics
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        DraftGroups {
          nextToken
          startedAt
          __typename
        }
        Acknowledges {
          nextToken
          startedAt
          __typename
        }
        UserComments {
          nextToken
          startedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      DraftChanges {
        items {
          id
          draftGroupID
          departmentID
          changeID
          previousID
          changeType
          isClosed
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      isClosed
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDraftGroupsQueryVariables,
  APITypes.ListDraftGroupsQuery
>;
export const syncDraftGroups = /* GraphQL */ `query SyncDraftGroups(
  $filter: ModelDraftGroupFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncDraftGroups(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      reviewalID
      departmentID
      ownerID
      title
      description
      createdAt
      Reviewal {
        id
        title
        description
        reviewers
        status
        events {
          color
          title
          description
          timestamp
          icon
          __typename
        }
        createdAt
        endedAt
        departmentID
        userID
        User {
          id
          firstName
          lastName
          type
          cognitoID
          indexedParentDepID
          departmentID
          depAdmins
          hashedPin
          saltPin
          pairedDepIDs
          oneDoseVersion
          lastActivity
          notificationTokens
          deviceIDs
          maxDevices
          allowAnalytics
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        DraftGroups {
          nextToken
          startedAt
          __typename
        }
        Acknowledges {
          nextToken
          startedAt
          __typename
        }
        UserComments {
          nextToken
          startedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      DraftChanges {
        items {
          id
          draftGroupID
          departmentID
          changeID
          previousID
          changeType
          isClosed
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      isClosed
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncDraftGroupsQueryVariables,
  APITypes.SyncDraftGroupsQuery
>;
export const draftGroupsByReviewalID = /* GraphQL */ `query DraftGroupsByReviewalID(
  $reviewalID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelDraftGroupFilterInput
  $limit: Int
  $nextToken: String
) {
  draftGroupsByReviewalID(
    reviewalID: $reviewalID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      reviewalID
      departmentID
      ownerID
      title
      description
      createdAt
      Reviewal {
        id
        title
        description
        reviewers
        status
        events {
          color
          title
          description
          timestamp
          icon
          __typename
        }
        createdAt
        endedAt
        departmentID
        userID
        User {
          id
          firstName
          lastName
          type
          cognitoID
          indexedParentDepID
          departmentID
          depAdmins
          hashedPin
          saltPin
          pairedDepIDs
          oneDoseVersion
          lastActivity
          notificationTokens
          deviceIDs
          maxDevices
          allowAnalytics
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        DraftGroups {
          nextToken
          startedAt
          __typename
        }
        Acknowledges {
          nextToken
          startedAt
          __typename
        }
        UserComments {
          nextToken
          startedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      DraftChanges {
        items {
          id
          draftGroupID
          departmentID
          changeID
          previousID
          changeType
          isClosed
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      isClosed
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.DraftGroupsByReviewalIDQueryVariables,
  APITypes.DraftGroupsByReviewalIDQuery
>;
export const draftGroupsByDepartmentID = /* GraphQL */ `query DraftGroupsByDepartmentID(
  $departmentID: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelDraftGroupFilterInput
  $limit: Int
  $nextToken: String
) {
  draftGroupsByDepartmentID(
    departmentID: $departmentID
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      reviewalID
      departmentID
      ownerID
      title
      description
      createdAt
      Reviewal {
        id
        title
        description
        reviewers
        status
        events {
          color
          title
          description
          timestamp
          icon
          __typename
        }
        createdAt
        endedAt
        departmentID
        userID
        User {
          id
          firstName
          lastName
          type
          cognitoID
          indexedParentDepID
          departmentID
          depAdmins
          hashedPin
          saltPin
          pairedDepIDs
          oneDoseVersion
          lastActivity
          notificationTokens
          deviceIDs
          maxDevices
          allowAnalytics
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        DraftGroups {
          nextToken
          startedAt
          __typename
        }
        Acknowledges {
          nextToken
          startedAt
          __typename
        }
        UserComments {
          nextToken
          startedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      DraftChanges {
        items {
          id
          draftGroupID
          departmentID
          changeID
          previousID
          changeType
          isClosed
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      isClosed
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.DraftGroupsByDepartmentIDQueryVariables,
  APITypes.DraftGroupsByDepartmentIDQuery
>;
export const getDraftChange = /* GraphQL */ `query GetDraftChange($id: ID!) {
  getDraftChange(id: $id) {
    id
    draftGroupID
    departmentID
    changeID
    previousID
    changeType
    isClosed
    createdAt
    DraftGroup {
      id
      reviewalID
      departmentID
      ownerID
      title
      description
      createdAt
      Reviewal {
        id
        title
        description
        reviewers
        status
        events {
          color
          title
          description
          timestamp
          icon
          __typename
        }
        createdAt
        endedAt
        departmentID
        userID
        User {
          id
          firstName
          lastName
          type
          cognitoID
          indexedParentDepID
          departmentID
          depAdmins
          hashedPin
          saltPin
          pairedDepIDs
          oneDoseVersion
          lastActivity
          notificationTokens
          deviceIDs
          maxDevices
          allowAnalytics
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        DraftGroups {
          nextToken
          startedAt
          __typename
        }
        Acknowledges {
          nextToken
          startedAt
          __typename
        }
        UserComments {
          nextToken
          startedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      DraftChanges {
        items {
          id
          draftGroupID
          departmentID
          changeID
          previousID
          changeType
          isClosed
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      isClosed
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    Comments {
      items {
        id
        ownerID
        userID
        acknowledgeID
        message
        relatedItems
        createdAt
        User {
          id
          firstName
          lastName
          type
          cognitoID
          indexedParentDepID
          departmentID
          depAdmins
          hashedPin
          saltPin
          pairedDepIDs
          oneDoseVersion
          lastActivity
          notificationTokens
          deviceIDs
          maxDevices
          allowAnalytics
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Acknowledge {
          id
          reviewalID
          ownerID
          userID
          status
          isArchived
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Acknowledges {
      items {
        id
        reviewalID
        ownerID
        userID
        status
        isArchived
        createdAt
        User {
          id
          firstName
          lastName
          type
          cognitoID
          indexedParentDepID
          departmentID
          depAdmins
          hashedPin
          saltPin
          pairedDepIDs
          oneDoseVersion
          lastActivity
          notificationTokens
          deviceIDs
          maxDevices
          allowAnalytics
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Comment {
          id
          ownerID
          userID
          acknowledgeID
          message
          relatedItems
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDraftChangeQueryVariables,
  APITypes.GetDraftChangeQuery
>;
export const listDraftChanges = /* GraphQL */ `query ListDraftChanges(
  $filter: ModelDraftChangeFilterInput
  $limit: Int
  $nextToken: String
) {
  listDraftChanges(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      draftGroupID
      departmentID
      changeID
      previousID
      changeType
      isClosed
      createdAt
      DraftGroup {
        id
        reviewalID
        departmentID
        ownerID
        title
        description
        createdAt
        Reviewal {
          id
          title
          description
          reviewers
          status
          createdAt
          endedAt
          departmentID
          userID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        DraftChanges {
          nextToken
          startedAt
          __typename
        }
        isClosed
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Comments {
        items {
          id
          ownerID
          userID
          acknowledgeID
          message
          relatedItems
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Acknowledges {
        items {
          id
          reviewalID
          ownerID
          userID
          status
          isArchived
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDraftChangesQueryVariables,
  APITypes.ListDraftChangesQuery
>;
export const syncDraftChanges = /* GraphQL */ `query SyncDraftChanges(
  $filter: ModelDraftChangeFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncDraftChanges(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      draftGroupID
      departmentID
      changeID
      previousID
      changeType
      isClosed
      createdAt
      DraftGroup {
        id
        reviewalID
        departmentID
        ownerID
        title
        description
        createdAt
        Reviewal {
          id
          title
          description
          reviewers
          status
          createdAt
          endedAt
          departmentID
          userID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        DraftChanges {
          nextToken
          startedAt
          __typename
        }
        isClosed
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Comments {
        items {
          id
          ownerID
          userID
          acknowledgeID
          message
          relatedItems
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Acknowledges {
        items {
          id
          reviewalID
          ownerID
          userID
          status
          isArchived
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncDraftChangesQueryVariables,
  APITypes.SyncDraftChangesQuery
>;
export const draftChangesByDraftGroupID = /* GraphQL */ `query DraftChangesByDraftGroupID(
  $draftGroupID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelDraftChangeFilterInput
  $limit: Int
  $nextToken: String
) {
  draftChangesByDraftGroupID(
    draftGroupID: $draftGroupID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      draftGroupID
      departmentID
      changeID
      previousID
      changeType
      isClosed
      createdAt
      DraftGroup {
        id
        reviewalID
        departmentID
        ownerID
        title
        description
        createdAt
        Reviewal {
          id
          title
          description
          reviewers
          status
          createdAt
          endedAt
          departmentID
          userID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        DraftChanges {
          nextToken
          startedAt
          __typename
        }
        isClosed
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Comments {
        items {
          id
          ownerID
          userID
          acknowledgeID
          message
          relatedItems
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Acknowledges {
        items {
          id
          reviewalID
          ownerID
          userID
          status
          isArchived
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.DraftChangesByDraftGroupIDQueryVariables,
  APITypes.DraftChangesByDraftGroupIDQuery
>;
export const draftChangesByDepartmentID = /* GraphQL */ `query DraftChangesByDepartmentID(
  $departmentID: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelDraftChangeFilterInput
  $limit: Int
  $nextToken: String
) {
  draftChangesByDepartmentID(
    departmentID: $departmentID
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      draftGroupID
      departmentID
      changeID
      previousID
      changeType
      isClosed
      createdAt
      DraftGroup {
        id
        reviewalID
        departmentID
        ownerID
        title
        description
        createdAt
        Reviewal {
          id
          title
          description
          reviewers
          status
          createdAt
          endedAt
          departmentID
          userID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        DraftChanges {
          nextToken
          startedAt
          __typename
        }
        isClosed
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Comments {
        items {
          id
          ownerID
          userID
          acknowledgeID
          message
          relatedItems
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Acknowledges {
        items {
          id
          reviewalID
          ownerID
          userID
          status
          isArchived
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.DraftChangesByDepartmentIDQueryVariables,
  APITypes.DraftChangesByDepartmentIDQuery
>;
export const draftChangesByChangeID = /* GraphQL */ `query DraftChangesByChangeID(
  $changeID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelDraftChangeFilterInput
  $limit: Int
  $nextToken: String
) {
  draftChangesByChangeID(
    changeID: $changeID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      draftGroupID
      departmentID
      changeID
      previousID
      changeType
      isClosed
      createdAt
      DraftGroup {
        id
        reviewalID
        departmentID
        ownerID
        title
        description
        createdAt
        Reviewal {
          id
          title
          description
          reviewers
          status
          createdAt
          endedAt
          departmentID
          userID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        DraftChanges {
          nextToken
          startedAt
          __typename
        }
        isClosed
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Comments {
        items {
          id
          ownerID
          userID
          acknowledgeID
          message
          relatedItems
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Acknowledges {
        items {
          id
          reviewalID
          ownerID
          userID
          status
          isArchived
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.DraftChangesByChangeIDQueryVariables,
  APITypes.DraftChangesByChangeIDQuery
>;
export const getAcknowledge = /* GraphQL */ `query GetAcknowledge($id: ID!) {
  getAcknowledge(id: $id) {
    id
    reviewalID
    ownerID
    userID
    status
    isArchived
    createdAt
    User {
      id
      firstName
      lastName
      type
      cognitoID
      indexedParentDepID
      departmentID
      depAdmins
      logs {
        items {
          id
          userId
          logId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      hashedPin
      saltPin
      pairedDepIDs
      oneDoseVersion
      lastActivity
      notificationTokens
      deviceIDs
      maxDevices
      allowAnalytics
      status
      FormLogs {
        items {
          id
          inputformID
          departmentID
          userID
          pairedDepID
          pairedUserIDs
          notes
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Reviewals {
        items {
          id
          title
          description
          reviewers
          status
          createdAt
          endedAt
          departmentID
          userID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Acknowledges {
        items {
          id
          reviewalID
          ownerID
          userID
          status
          isArchived
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      UserComments {
        items {
          id
          ownerID
          userID
          acknowledgeID
          message
          relatedItems
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      NotifyACKs {
        items {
          id
          ownerID
          userID
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    Comment {
      id
      ownerID
      userID
      acknowledgeID
      message
      relatedItems
      createdAt
      User {
        id
        firstName
        lastName
        type
        cognitoID
        indexedParentDepID
        departmentID
        depAdmins
        logs {
          nextToken
          startedAt
          __typename
        }
        hashedPin
        saltPin
        pairedDepIDs
        oneDoseVersion
        lastActivity
        notificationTokens
        deviceIDs
        maxDevices
        allowAnalytics
        status
        FormLogs {
          nextToken
          startedAt
          __typename
        }
        Reviewals {
          nextToken
          startedAt
          __typename
        }
        Acknowledges {
          nextToken
          startedAt
          __typename
        }
        UserComments {
          nextToken
          startedAt
          __typename
        }
        NotifyACKs {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Acknowledge {
        id
        reviewalID
        ownerID
        userID
        status
        isArchived
        createdAt
        User {
          id
          firstName
          lastName
          type
          cognitoID
          indexedParentDepID
          departmentID
          depAdmins
          hashedPin
          saltPin
          pairedDepIDs
          oneDoseVersion
          lastActivity
          notificationTokens
          deviceIDs
          maxDevices
          allowAnalytics
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Comment {
          id
          ownerID
          userID
          acknowledgeID
          message
          relatedItems
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAcknowledgeQueryVariables,
  APITypes.GetAcknowledgeQuery
>;
export const listAcknowledges = /* GraphQL */ `query ListAcknowledges(
  $filter: ModelAcknowledgeFilterInput
  $limit: Int
  $nextToken: String
) {
  listAcknowledges(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      reviewalID
      ownerID
      userID
      status
      isArchived
      createdAt
      User {
        id
        firstName
        lastName
        type
        cognitoID
        indexedParentDepID
        departmentID
        depAdmins
        logs {
          nextToken
          startedAt
          __typename
        }
        hashedPin
        saltPin
        pairedDepIDs
        oneDoseVersion
        lastActivity
        notificationTokens
        deviceIDs
        maxDevices
        allowAnalytics
        status
        FormLogs {
          nextToken
          startedAt
          __typename
        }
        Reviewals {
          nextToken
          startedAt
          __typename
        }
        Acknowledges {
          nextToken
          startedAt
          __typename
        }
        UserComments {
          nextToken
          startedAt
          __typename
        }
        NotifyACKs {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Comment {
        id
        ownerID
        userID
        acknowledgeID
        message
        relatedItems
        createdAt
        User {
          id
          firstName
          lastName
          type
          cognitoID
          indexedParentDepID
          departmentID
          depAdmins
          hashedPin
          saltPin
          pairedDepIDs
          oneDoseVersion
          lastActivity
          notificationTokens
          deviceIDs
          maxDevices
          allowAnalytics
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Acknowledge {
          id
          reviewalID
          ownerID
          userID
          status
          isArchived
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAcknowledgesQueryVariables,
  APITypes.ListAcknowledgesQuery
>;
export const syncAcknowledges = /* GraphQL */ `query SyncAcknowledges(
  $filter: ModelAcknowledgeFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncAcknowledges(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      reviewalID
      ownerID
      userID
      status
      isArchived
      createdAt
      User {
        id
        firstName
        lastName
        type
        cognitoID
        indexedParentDepID
        departmentID
        depAdmins
        logs {
          nextToken
          startedAt
          __typename
        }
        hashedPin
        saltPin
        pairedDepIDs
        oneDoseVersion
        lastActivity
        notificationTokens
        deviceIDs
        maxDevices
        allowAnalytics
        status
        FormLogs {
          nextToken
          startedAt
          __typename
        }
        Reviewals {
          nextToken
          startedAt
          __typename
        }
        Acknowledges {
          nextToken
          startedAt
          __typename
        }
        UserComments {
          nextToken
          startedAt
          __typename
        }
        NotifyACKs {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Comment {
        id
        ownerID
        userID
        acknowledgeID
        message
        relatedItems
        createdAt
        User {
          id
          firstName
          lastName
          type
          cognitoID
          indexedParentDepID
          departmentID
          depAdmins
          hashedPin
          saltPin
          pairedDepIDs
          oneDoseVersion
          lastActivity
          notificationTokens
          deviceIDs
          maxDevices
          allowAnalytics
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Acknowledge {
          id
          reviewalID
          ownerID
          userID
          status
          isArchived
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncAcknowledgesQueryVariables,
  APITypes.SyncAcknowledgesQuery
>;
export const acknowledgesByReviewalID = /* GraphQL */ `query AcknowledgesByReviewalID(
  $reviewalID: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelAcknowledgeFilterInput
  $limit: Int
  $nextToken: String
) {
  acknowledgesByReviewalID(
    reviewalID: $reviewalID
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      reviewalID
      ownerID
      userID
      status
      isArchived
      createdAt
      User {
        id
        firstName
        lastName
        type
        cognitoID
        indexedParentDepID
        departmentID
        depAdmins
        logs {
          nextToken
          startedAt
          __typename
        }
        hashedPin
        saltPin
        pairedDepIDs
        oneDoseVersion
        lastActivity
        notificationTokens
        deviceIDs
        maxDevices
        allowAnalytics
        status
        FormLogs {
          nextToken
          startedAt
          __typename
        }
        Reviewals {
          nextToken
          startedAt
          __typename
        }
        Acknowledges {
          nextToken
          startedAt
          __typename
        }
        UserComments {
          nextToken
          startedAt
          __typename
        }
        NotifyACKs {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Comment {
        id
        ownerID
        userID
        acknowledgeID
        message
        relatedItems
        createdAt
        User {
          id
          firstName
          lastName
          type
          cognitoID
          indexedParentDepID
          departmentID
          depAdmins
          hashedPin
          saltPin
          pairedDepIDs
          oneDoseVersion
          lastActivity
          notificationTokens
          deviceIDs
          maxDevices
          allowAnalytics
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Acknowledge {
          id
          reviewalID
          ownerID
          userID
          status
          isArchived
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AcknowledgesByReviewalIDQueryVariables,
  APITypes.AcknowledgesByReviewalIDQuery
>;
export const acknowledgesByOwnerID = /* GraphQL */ `query AcknowledgesByOwnerID(
  $ownerID: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelAcknowledgeFilterInput
  $limit: Int
  $nextToken: String
) {
  acknowledgesByOwnerID(
    ownerID: $ownerID
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      reviewalID
      ownerID
      userID
      status
      isArchived
      createdAt
      User {
        id
        firstName
        lastName
        type
        cognitoID
        indexedParentDepID
        departmentID
        depAdmins
        logs {
          nextToken
          startedAt
          __typename
        }
        hashedPin
        saltPin
        pairedDepIDs
        oneDoseVersion
        lastActivity
        notificationTokens
        deviceIDs
        maxDevices
        allowAnalytics
        status
        FormLogs {
          nextToken
          startedAt
          __typename
        }
        Reviewals {
          nextToken
          startedAt
          __typename
        }
        Acknowledges {
          nextToken
          startedAt
          __typename
        }
        UserComments {
          nextToken
          startedAt
          __typename
        }
        NotifyACKs {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Comment {
        id
        ownerID
        userID
        acknowledgeID
        message
        relatedItems
        createdAt
        User {
          id
          firstName
          lastName
          type
          cognitoID
          indexedParentDepID
          departmentID
          depAdmins
          hashedPin
          saltPin
          pairedDepIDs
          oneDoseVersion
          lastActivity
          notificationTokens
          deviceIDs
          maxDevices
          allowAnalytics
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Acknowledge {
          id
          reviewalID
          ownerID
          userID
          status
          isArchived
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AcknowledgesByOwnerIDQueryVariables,
  APITypes.AcknowledgesByOwnerIDQuery
>;
export const acknowledgesByUserID = /* GraphQL */ `query AcknowledgesByUserID(
  $userID: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelAcknowledgeFilterInput
  $limit: Int
  $nextToken: String
) {
  acknowledgesByUserID(
    userID: $userID
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      reviewalID
      ownerID
      userID
      status
      isArchived
      createdAt
      User {
        id
        firstName
        lastName
        type
        cognitoID
        indexedParentDepID
        departmentID
        depAdmins
        logs {
          nextToken
          startedAt
          __typename
        }
        hashedPin
        saltPin
        pairedDepIDs
        oneDoseVersion
        lastActivity
        notificationTokens
        deviceIDs
        maxDevices
        allowAnalytics
        status
        FormLogs {
          nextToken
          startedAt
          __typename
        }
        Reviewals {
          nextToken
          startedAt
          __typename
        }
        Acknowledges {
          nextToken
          startedAt
          __typename
        }
        UserComments {
          nextToken
          startedAt
          __typename
        }
        NotifyACKs {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Comment {
        id
        ownerID
        userID
        acknowledgeID
        message
        relatedItems
        createdAt
        User {
          id
          firstName
          lastName
          type
          cognitoID
          indexedParentDepID
          departmentID
          depAdmins
          hashedPin
          saltPin
          pairedDepIDs
          oneDoseVersion
          lastActivity
          notificationTokens
          deviceIDs
          maxDevices
          allowAnalytics
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Acknowledge {
          id
          reviewalID
          ownerID
          userID
          status
          isArchived
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AcknowledgesByUserIDQueryVariables,
  APITypes.AcknowledgesByUserIDQuery
>;
export const getUserComment = /* GraphQL */ `query GetUserComment($id: ID!) {
  getUserComment(id: $id) {
    id
    ownerID
    userID
    acknowledgeID
    message
    relatedItems
    createdAt
    User {
      id
      firstName
      lastName
      type
      cognitoID
      indexedParentDepID
      departmentID
      depAdmins
      logs {
        items {
          id
          userId
          logId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      hashedPin
      saltPin
      pairedDepIDs
      oneDoseVersion
      lastActivity
      notificationTokens
      deviceIDs
      maxDevices
      allowAnalytics
      status
      FormLogs {
        items {
          id
          inputformID
          departmentID
          userID
          pairedDepID
          pairedUserIDs
          notes
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Reviewals {
        items {
          id
          title
          description
          reviewers
          status
          createdAt
          endedAt
          departmentID
          userID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Acknowledges {
        items {
          id
          reviewalID
          ownerID
          userID
          status
          isArchived
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      UserComments {
        items {
          id
          ownerID
          userID
          acknowledgeID
          message
          relatedItems
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      NotifyACKs {
        items {
          id
          ownerID
          userID
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    Acknowledge {
      id
      reviewalID
      ownerID
      userID
      status
      isArchived
      createdAt
      User {
        id
        firstName
        lastName
        type
        cognitoID
        indexedParentDepID
        departmentID
        depAdmins
        logs {
          nextToken
          startedAt
          __typename
        }
        hashedPin
        saltPin
        pairedDepIDs
        oneDoseVersion
        lastActivity
        notificationTokens
        deviceIDs
        maxDevices
        allowAnalytics
        status
        FormLogs {
          nextToken
          startedAt
          __typename
        }
        Reviewals {
          nextToken
          startedAt
          __typename
        }
        Acknowledges {
          nextToken
          startedAt
          __typename
        }
        UserComments {
          nextToken
          startedAt
          __typename
        }
        NotifyACKs {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Comment {
        id
        ownerID
        userID
        acknowledgeID
        message
        relatedItems
        createdAt
        User {
          id
          firstName
          lastName
          type
          cognitoID
          indexedParentDepID
          departmentID
          depAdmins
          hashedPin
          saltPin
          pairedDepIDs
          oneDoseVersion
          lastActivity
          notificationTokens
          deviceIDs
          maxDevices
          allowAnalytics
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Acknowledge {
          id
          reviewalID
          ownerID
          userID
          status
          isArchived
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserCommentQueryVariables,
  APITypes.GetUserCommentQuery
>;
export const listUserComments = /* GraphQL */ `query ListUserComments(
  $filter: ModelUserCommentFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      ownerID
      userID
      acknowledgeID
      message
      relatedItems
      createdAt
      User {
        id
        firstName
        lastName
        type
        cognitoID
        indexedParentDepID
        departmentID
        depAdmins
        logs {
          nextToken
          startedAt
          __typename
        }
        hashedPin
        saltPin
        pairedDepIDs
        oneDoseVersion
        lastActivity
        notificationTokens
        deviceIDs
        maxDevices
        allowAnalytics
        status
        FormLogs {
          nextToken
          startedAt
          __typename
        }
        Reviewals {
          nextToken
          startedAt
          __typename
        }
        Acknowledges {
          nextToken
          startedAt
          __typename
        }
        UserComments {
          nextToken
          startedAt
          __typename
        }
        NotifyACKs {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Acknowledge {
        id
        reviewalID
        ownerID
        userID
        status
        isArchived
        createdAt
        User {
          id
          firstName
          lastName
          type
          cognitoID
          indexedParentDepID
          departmentID
          depAdmins
          hashedPin
          saltPin
          pairedDepIDs
          oneDoseVersion
          lastActivity
          notificationTokens
          deviceIDs
          maxDevices
          allowAnalytics
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Comment {
          id
          ownerID
          userID
          acknowledgeID
          message
          relatedItems
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserCommentsQueryVariables,
  APITypes.ListUserCommentsQuery
>;
export const syncUserComments = /* GraphQL */ `query SyncUserComments(
  $filter: ModelUserCommentFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncUserComments(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      ownerID
      userID
      acknowledgeID
      message
      relatedItems
      createdAt
      User {
        id
        firstName
        lastName
        type
        cognitoID
        indexedParentDepID
        departmentID
        depAdmins
        logs {
          nextToken
          startedAt
          __typename
        }
        hashedPin
        saltPin
        pairedDepIDs
        oneDoseVersion
        lastActivity
        notificationTokens
        deviceIDs
        maxDevices
        allowAnalytics
        status
        FormLogs {
          nextToken
          startedAt
          __typename
        }
        Reviewals {
          nextToken
          startedAt
          __typename
        }
        Acknowledges {
          nextToken
          startedAt
          __typename
        }
        UserComments {
          nextToken
          startedAt
          __typename
        }
        NotifyACKs {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Acknowledge {
        id
        reviewalID
        ownerID
        userID
        status
        isArchived
        createdAt
        User {
          id
          firstName
          lastName
          type
          cognitoID
          indexedParentDepID
          departmentID
          depAdmins
          hashedPin
          saltPin
          pairedDepIDs
          oneDoseVersion
          lastActivity
          notificationTokens
          deviceIDs
          maxDevices
          allowAnalytics
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Comment {
          id
          ownerID
          userID
          acknowledgeID
          message
          relatedItems
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncUserCommentsQueryVariables,
  APITypes.SyncUserCommentsQuery
>;
export const commentsByOwnerID = /* GraphQL */ `query CommentsByOwnerID(
  $ownerID: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelUserCommentFilterInput
  $limit: Int
  $nextToken: String
) {
  commentsByOwnerID(
    ownerID: $ownerID
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      ownerID
      userID
      acknowledgeID
      message
      relatedItems
      createdAt
      User {
        id
        firstName
        lastName
        type
        cognitoID
        indexedParentDepID
        departmentID
        depAdmins
        logs {
          nextToken
          startedAt
          __typename
        }
        hashedPin
        saltPin
        pairedDepIDs
        oneDoseVersion
        lastActivity
        notificationTokens
        deviceIDs
        maxDevices
        allowAnalytics
        status
        FormLogs {
          nextToken
          startedAt
          __typename
        }
        Reviewals {
          nextToken
          startedAt
          __typename
        }
        Acknowledges {
          nextToken
          startedAt
          __typename
        }
        UserComments {
          nextToken
          startedAt
          __typename
        }
        NotifyACKs {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Acknowledge {
        id
        reviewalID
        ownerID
        userID
        status
        isArchived
        createdAt
        User {
          id
          firstName
          lastName
          type
          cognitoID
          indexedParentDepID
          departmentID
          depAdmins
          hashedPin
          saltPin
          pairedDepIDs
          oneDoseVersion
          lastActivity
          notificationTokens
          deviceIDs
          maxDevices
          allowAnalytics
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Comment {
          id
          ownerID
          userID
          acknowledgeID
          message
          relatedItems
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CommentsByOwnerIDQueryVariables,
  APITypes.CommentsByOwnerIDQuery
>;
export const commentsByUserID = /* GraphQL */ `query CommentsByUserID(
  $userID: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelUserCommentFilterInput
  $limit: Int
  $nextToken: String
) {
  commentsByUserID(
    userID: $userID
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      ownerID
      userID
      acknowledgeID
      message
      relatedItems
      createdAt
      User {
        id
        firstName
        lastName
        type
        cognitoID
        indexedParentDepID
        departmentID
        depAdmins
        logs {
          nextToken
          startedAt
          __typename
        }
        hashedPin
        saltPin
        pairedDepIDs
        oneDoseVersion
        lastActivity
        notificationTokens
        deviceIDs
        maxDevices
        allowAnalytics
        status
        FormLogs {
          nextToken
          startedAt
          __typename
        }
        Reviewals {
          nextToken
          startedAt
          __typename
        }
        Acknowledges {
          nextToken
          startedAt
          __typename
        }
        UserComments {
          nextToken
          startedAt
          __typename
        }
        NotifyACKs {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Acknowledge {
        id
        reviewalID
        ownerID
        userID
        status
        isArchived
        createdAt
        User {
          id
          firstName
          lastName
          type
          cognitoID
          indexedParentDepID
          departmentID
          depAdmins
          hashedPin
          saltPin
          pairedDepIDs
          oneDoseVersion
          lastActivity
          notificationTokens
          deviceIDs
          maxDevices
          allowAnalytics
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Comment {
          id
          ownerID
          userID
          acknowledgeID
          message
          relatedItems
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CommentsByUserIDQueryVariables,
  APITypes.CommentsByUserIDQuery
>;
export const getGroupNotification = /* GraphQL */ `query GetGroupNotification($id: ID!) {
  getGroupNotification(id: $id) {
    id
    notificationId
    groupId
    notification {
      id
      type
      title
      message
      timestamp
      expirationTimestamp
      deadlineTimestamp
      isReadIDs
      isAckIDs
      imageURLs
      videoURLs
      fileURLs
      taggedProtocols
      questions {
        question
        options
        answer
        taggedProtocol
        __typename
      }
      formQuestions {
        index
        type
        question
        description
        answers
        correctAnswer
        __typename
      }
      isPush
      isPinRequired
      departmentID
      pairedDepIDs
      Groups {
        items {
          id
          notificationId
          groupId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdBy
      modifiedBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    group {
      id
      name
      type
      departmentID
      pairedDepIDs
      pairedUserIDs
      isAutoGen
      Department {
        id
        Categories {
          nextToken
          startedAt
          __typename
        }
        OneWeights {
          nextToken
          startedAt
          __typename
        }
        name
        location
        protocolVersions
        shiftTypes
        Medications {
          nextToken
          startedAt
          __typename
        }
        Equipment {
          nextToken
          startedAt
          __typename
        }
        Forms {
          nextToken
          startedAt
          __typename
        }
        Vitals {
          nextToken
          startedAt
          __typename
        }
        Electricals {
          nextToken
          startedAt
          __typename
        }
        ElectricalShocks {
          nextToken
          startedAt
          __typename
        }
        cognitoID
        Users {
          nextToken
          startedAt
          __typename
        }
        Logs {
          nextToken
          startedAt
          __typename
        }
        Ambulances {
          nextToken
          startedAt
          __typename
        }
        Drips {
          nextToken
          startedAt
          __typename
        }
        RequireSync {
          id
          sync
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        logoID
        userID
        neonateCutoff
        pediatricCutoff
        calculators
        adultRanges {
          label
          value
          __typename
        }
        softwarePlan
        Notifications {
          nextToken
          startedAt
          __typename
        }
        Contacts {
          nextToken
          startedAt
          __typename
        }
        InputForms {
          nextToken
          startedAt
          __typename
        }
        FormLogs {
          nextToken
          startedAt
          __typename
        }
        Workbooks {
          nextToken
          startedAt
          __typename
        }
        WeightObjects {
          nextToken
          startedAt
          __typename
        }
        Keychains {
          nextToken
          startedAt
          __typename
        }
        Reviewals {
          nextToken
          startedAt
          __typename
        }
        Groups {
          nextToken
          startedAt
          __typename
        }
        CPRAssists {
          nextToken
          startedAt
          __typename
        }
        subDepIDs
        parentDepID
        indexedParentDepID
        activeStatus
        agencyNumEMS
        stateIdEMS
        gnisCodeEMS
        uniqueCode
        uniquePublicURL
        hashedPin
        saltedPin
        keychainID
        isPublic
        infusionCalculation
        isPublicSignup
        config {
          isTopEnabled
          neonateCutoff
          pediatricCutoff
          calculators
          softwarePlan
          infusionCalculation
          isPublic
          realTimeUpdating
          epcrProvider
          oneweightEnabled
          ageFilterEnabled
          ageGroupFilterEnabled
          renewalDate
          infusionGTTS
          signedAgreement
          isToggleAdvancedFiltersEnabled
          premiumTrialExpiration
          estimationMethod
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        departmentRequireSyncId
        __typename
      }
      Notifications {
        items {
          id
          notificationId
          groupId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetGroupNotificationQueryVariables,
  APITypes.GetGroupNotificationQuery
>;
export const listGroupNotifications = /* GraphQL */ `query ListGroupNotifications(
  $filter: ModelGroupNotificationFilterInput
  $limit: Int
  $nextToken: String
) {
  listGroupNotifications(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      notificationId
      groupId
      notification {
        id
        type
        title
        message
        timestamp
        expirationTimestamp
        deadlineTimestamp
        isReadIDs
        isAckIDs
        imageURLs
        videoURLs
        fileURLs
        taggedProtocols
        questions {
          question
          options
          answer
          taggedProtocol
          __typename
        }
        formQuestions {
          index
          type
          question
          description
          answers
          correctAnswer
          __typename
        }
        isPush
        isPinRequired
        departmentID
        pairedDepIDs
        Groups {
          nextToken
          startedAt
          __typename
        }
        createdBy
        modifiedBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      group {
        id
        name
        type
        departmentID
        pairedDepIDs
        pairedUserIDs
        isAutoGen
        Department {
          id
          name
          location
          protocolVersions
          shiftTypes
          cognitoID
          logoID
          userID
          neonateCutoff
          pediatricCutoff
          calculators
          softwarePlan
          subDepIDs
          parentDepID
          indexedParentDepID
          activeStatus
          agencyNumEMS
          stateIdEMS
          gnisCodeEMS
          uniqueCode
          uniquePublicURL
          hashedPin
          saltedPin
          keychainID
          isPublic
          infusionCalculation
          isPublicSignup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          departmentRequireSyncId
          __typename
        }
        Notifications {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListGroupNotificationsQueryVariables,
  APITypes.ListGroupNotificationsQuery
>;
export const syncGroupNotifications = /* GraphQL */ `query SyncGroupNotifications(
  $filter: ModelGroupNotificationFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncGroupNotifications(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      notificationId
      groupId
      notification {
        id
        type
        title
        message
        timestamp
        expirationTimestamp
        deadlineTimestamp
        isReadIDs
        isAckIDs
        imageURLs
        videoURLs
        fileURLs
        taggedProtocols
        questions {
          question
          options
          answer
          taggedProtocol
          __typename
        }
        formQuestions {
          index
          type
          question
          description
          answers
          correctAnswer
          __typename
        }
        isPush
        isPinRequired
        departmentID
        pairedDepIDs
        Groups {
          nextToken
          startedAt
          __typename
        }
        createdBy
        modifiedBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      group {
        id
        name
        type
        departmentID
        pairedDepIDs
        pairedUserIDs
        isAutoGen
        Department {
          id
          name
          location
          protocolVersions
          shiftTypes
          cognitoID
          logoID
          userID
          neonateCutoff
          pediatricCutoff
          calculators
          softwarePlan
          subDepIDs
          parentDepID
          indexedParentDepID
          activeStatus
          agencyNumEMS
          stateIdEMS
          gnisCodeEMS
          uniqueCode
          uniquePublicURL
          hashedPin
          saltedPin
          keychainID
          isPublic
          infusionCalculation
          isPublicSignup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          departmentRequireSyncId
          __typename
        }
        Notifications {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncGroupNotificationsQueryVariables,
  APITypes.SyncGroupNotificationsQuery
>;
export const groupNotificationsByNotificationId = /* GraphQL */ `query GroupNotificationsByNotificationId(
  $notificationId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelGroupNotificationFilterInput
  $limit: Int
  $nextToken: String
) {
  groupNotificationsByNotificationId(
    notificationId: $notificationId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      notificationId
      groupId
      notification {
        id
        type
        title
        message
        timestamp
        expirationTimestamp
        deadlineTimestamp
        isReadIDs
        isAckIDs
        imageURLs
        videoURLs
        fileURLs
        taggedProtocols
        questions {
          question
          options
          answer
          taggedProtocol
          __typename
        }
        formQuestions {
          index
          type
          question
          description
          answers
          correctAnswer
          __typename
        }
        isPush
        isPinRequired
        departmentID
        pairedDepIDs
        Groups {
          nextToken
          startedAt
          __typename
        }
        createdBy
        modifiedBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      group {
        id
        name
        type
        departmentID
        pairedDepIDs
        pairedUserIDs
        isAutoGen
        Department {
          id
          name
          location
          protocolVersions
          shiftTypes
          cognitoID
          logoID
          userID
          neonateCutoff
          pediatricCutoff
          calculators
          softwarePlan
          subDepIDs
          parentDepID
          indexedParentDepID
          activeStatus
          agencyNumEMS
          stateIdEMS
          gnisCodeEMS
          uniqueCode
          uniquePublicURL
          hashedPin
          saltedPin
          keychainID
          isPublic
          infusionCalculation
          isPublicSignup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          departmentRequireSyncId
          __typename
        }
        Notifications {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GroupNotificationsByNotificationIdQueryVariables,
  APITypes.GroupNotificationsByNotificationIdQuery
>;
export const groupNotificationsByGroupId = /* GraphQL */ `query GroupNotificationsByGroupId(
  $groupId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelGroupNotificationFilterInput
  $limit: Int
  $nextToken: String
) {
  groupNotificationsByGroupId(
    groupId: $groupId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      notificationId
      groupId
      notification {
        id
        type
        title
        message
        timestamp
        expirationTimestamp
        deadlineTimestamp
        isReadIDs
        isAckIDs
        imageURLs
        videoURLs
        fileURLs
        taggedProtocols
        questions {
          question
          options
          answer
          taggedProtocol
          __typename
        }
        formQuestions {
          index
          type
          question
          description
          answers
          correctAnswer
          __typename
        }
        isPush
        isPinRequired
        departmentID
        pairedDepIDs
        Groups {
          nextToken
          startedAt
          __typename
        }
        createdBy
        modifiedBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      group {
        id
        name
        type
        departmentID
        pairedDepIDs
        pairedUserIDs
        isAutoGen
        Department {
          id
          name
          location
          protocolVersions
          shiftTypes
          cognitoID
          logoID
          userID
          neonateCutoff
          pediatricCutoff
          calculators
          softwarePlan
          subDepIDs
          parentDepID
          indexedParentDepID
          activeStatus
          agencyNumEMS
          stateIdEMS
          gnisCodeEMS
          uniqueCode
          uniquePublicURL
          hashedPin
          saltedPin
          keychainID
          isPublic
          infusionCalculation
          isPublicSignup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          departmentRequireSyncId
          __typename
        }
        Notifications {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GroupNotificationsByGroupIdQueryVariables,
  APITypes.GroupNotificationsByGroupIdQuery
>;
export const getLogUser = /* GraphQL */ `query GetLogUser($id: ID!) {
  getLogUser(id: $id) {
    id
    userId
    logId
    user {
      id
      firstName
      lastName
      type
      cognitoID
      indexedParentDepID
      departmentID
      depAdmins
      logs {
        items {
          id
          userId
          logId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      hashedPin
      saltPin
      pairedDepIDs
      oneDoseVersion
      lastActivity
      notificationTokens
      deviceIDs
      maxDevices
      allowAnalytics
      status
      FormLogs {
        items {
          id
          inputformID
          departmentID
          userID
          pairedDepID
          pairedUserIDs
          notes
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Reviewals {
        items {
          id
          title
          description
          reviewers
          status
          createdAt
          endedAt
          departmentID
          userID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Acknowledges {
        items {
          id
          reviewalID
          ownerID
          userID
          status
          isArchived
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      UserComments {
        items {
          id
          ownerID
          userID
          acknowledgeID
          message
          relatedItems
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      NotifyACKs {
        items {
          id
          ownerID
          userID
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    log {
      id
      actualWeight
      ageValue
      protocolID
      Users {
        items {
          id
          userId
          logId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      departmentID
      oneweightID
      ambulanceID
      incidentNumber
      startTime
      endTime
      estimatedWeight
      medications {
        medicationID
        amntSolid
        amntLiquid
        timestamp
        verified
        name
        rxNormCode
        concentration
        isActualWeight
        adminType
        route {
          route
          code
          __typename
        }
        weight
        protocolID
        __typename
      }
      equipments {
        equipmentID
        amount
        name
        snomedCode
        timestamp
        isActualWeight
        verified
        weight
        successful
        protocolID
        __typename
      }
      electricals {
        electricalID
        name
        snomedCode
        amount
        timestamp
        isActualWeight
        verified
        weight
        protocolID
        __typename
      }
      cpr {
        cprID
        startTime
        endTime
        outcome
        __typename
      }
      emsCalculators {
        calculatorID
        responses
        scores
        timestamp
        __typename
      }
      patientSaved
      patientReleased
      arrivedAtHospital
      ageTimestamp
      forms {
        formID
        name
        completed
        startedTime
        protocolID
        __typename
      }
      subtractedItems {
        weightObjectID
        name
        weight
        added
        __typename
      }
      userIDs
      infusions {
        dripID
        amnt
        amnt2
        timestamp
        verified
        name
        rxNormCode
        concentration
        adminType
        route {
          route
          code
          __typename
        }
        weight
        protocolID
        amntUnit
        amntUnit2
        isActualWeight
        gttsDropFactor
        gttsAmount
        gttsUnit
        __typename
      }
      estimatedSaved
      tags
      shifts {
        shiftStart
        shiftEnd
        completedLogs
        taggedAmbulance
        taggedOneWeight
        taggedUsers
        ownerID
        departmentID
        metaData
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetLogUserQueryVariables,
  APITypes.GetLogUserQuery
>;
export const listLogUsers = /* GraphQL */ `query ListLogUsers(
  $filter: ModelLogUserFilterInput
  $limit: Int
  $nextToken: String
) {
  listLogUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      userId
      logId
      user {
        id
        firstName
        lastName
        type
        cognitoID
        indexedParentDepID
        departmentID
        depAdmins
        logs {
          nextToken
          startedAt
          __typename
        }
        hashedPin
        saltPin
        pairedDepIDs
        oneDoseVersion
        lastActivity
        notificationTokens
        deviceIDs
        maxDevices
        allowAnalytics
        status
        FormLogs {
          nextToken
          startedAt
          __typename
        }
        Reviewals {
          nextToken
          startedAt
          __typename
        }
        Acknowledges {
          nextToken
          startedAt
          __typename
        }
        UserComments {
          nextToken
          startedAt
          __typename
        }
        NotifyACKs {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      log {
        id
        actualWeight
        ageValue
        protocolID
        Users {
          nextToken
          startedAt
          __typename
        }
        departmentID
        oneweightID
        ambulanceID
        incidentNumber
        startTime
        endTime
        estimatedWeight
        medications {
          medicationID
          amntSolid
          amntLiquid
          timestamp
          verified
          name
          rxNormCode
          concentration
          isActualWeight
          adminType
          weight
          protocolID
          __typename
        }
        equipments {
          equipmentID
          amount
          name
          snomedCode
          timestamp
          isActualWeight
          verified
          weight
          successful
          protocolID
          __typename
        }
        electricals {
          electricalID
          name
          snomedCode
          amount
          timestamp
          isActualWeight
          verified
          weight
          protocolID
          __typename
        }
        cpr {
          cprID
          startTime
          endTime
          outcome
          __typename
        }
        emsCalculators {
          calculatorID
          responses
          scores
          timestamp
          __typename
        }
        patientSaved
        patientReleased
        arrivedAtHospital
        ageTimestamp
        forms {
          formID
          name
          completed
          startedTime
          protocolID
          __typename
        }
        subtractedItems {
          weightObjectID
          name
          weight
          added
          __typename
        }
        userIDs
        infusions {
          dripID
          amnt
          amnt2
          timestamp
          verified
          name
          rxNormCode
          concentration
          adminType
          weight
          protocolID
          amntUnit
          amntUnit2
          isActualWeight
          gttsDropFactor
          gttsAmount
          gttsUnit
          __typename
        }
        estimatedSaved
        tags
        shifts {
          shiftStart
          shiftEnd
          completedLogs
          taggedAmbulance
          taggedOneWeight
          taggedUsers
          ownerID
          departmentID
          metaData
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListLogUsersQueryVariables,
  APITypes.ListLogUsersQuery
>;
export const syncLogUsers = /* GraphQL */ `query SyncLogUsers(
  $filter: ModelLogUserFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncLogUsers(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      userId
      logId
      user {
        id
        firstName
        lastName
        type
        cognitoID
        indexedParentDepID
        departmentID
        depAdmins
        logs {
          nextToken
          startedAt
          __typename
        }
        hashedPin
        saltPin
        pairedDepIDs
        oneDoseVersion
        lastActivity
        notificationTokens
        deviceIDs
        maxDevices
        allowAnalytics
        status
        FormLogs {
          nextToken
          startedAt
          __typename
        }
        Reviewals {
          nextToken
          startedAt
          __typename
        }
        Acknowledges {
          nextToken
          startedAt
          __typename
        }
        UserComments {
          nextToken
          startedAt
          __typename
        }
        NotifyACKs {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      log {
        id
        actualWeight
        ageValue
        protocolID
        Users {
          nextToken
          startedAt
          __typename
        }
        departmentID
        oneweightID
        ambulanceID
        incidentNumber
        startTime
        endTime
        estimatedWeight
        medications {
          medicationID
          amntSolid
          amntLiquid
          timestamp
          verified
          name
          rxNormCode
          concentration
          isActualWeight
          adminType
          weight
          protocolID
          __typename
        }
        equipments {
          equipmentID
          amount
          name
          snomedCode
          timestamp
          isActualWeight
          verified
          weight
          successful
          protocolID
          __typename
        }
        electricals {
          electricalID
          name
          snomedCode
          amount
          timestamp
          isActualWeight
          verified
          weight
          protocolID
          __typename
        }
        cpr {
          cprID
          startTime
          endTime
          outcome
          __typename
        }
        emsCalculators {
          calculatorID
          responses
          scores
          timestamp
          __typename
        }
        patientSaved
        patientReleased
        arrivedAtHospital
        ageTimestamp
        forms {
          formID
          name
          completed
          startedTime
          protocolID
          __typename
        }
        subtractedItems {
          weightObjectID
          name
          weight
          added
          __typename
        }
        userIDs
        infusions {
          dripID
          amnt
          amnt2
          timestamp
          verified
          name
          rxNormCode
          concentration
          adminType
          weight
          protocolID
          amntUnit
          amntUnit2
          isActualWeight
          gttsDropFactor
          gttsAmount
          gttsUnit
          __typename
        }
        estimatedSaved
        tags
        shifts {
          shiftStart
          shiftEnd
          completedLogs
          taggedAmbulance
          taggedOneWeight
          taggedUsers
          ownerID
          departmentID
          metaData
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncLogUsersQueryVariables,
  APITypes.SyncLogUsersQuery
>;
export const logUsersByUserId = /* GraphQL */ `query LogUsersByUserId(
  $userId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelLogUserFilterInput
  $limit: Int
  $nextToken: String
) {
  logUsersByUserId(
    userId: $userId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      userId
      logId
      user {
        id
        firstName
        lastName
        type
        cognitoID
        indexedParentDepID
        departmentID
        depAdmins
        logs {
          nextToken
          startedAt
          __typename
        }
        hashedPin
        saltPin
        pairedDepIDs
        oneDoseVersion
        lastActivity
        notificationTokens
        deviceIDs
        maxDevices
        allowAnalytics
        status
        FormLogs {
          nextToken
          startedAt
          __typename
        }
        Reviewals {
          nextToken
          startedAt
          __typename
        }
        Acknowledges {
          nextToken
          startedAt
          __typename
        }
        UserComments {
          nextToken
          startedAt
          __typename
        }
        NotifyACKs {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      log {
        id
        actualWeight
        ageValue
        protocolID
        Users {
          nextToken
          startedAt
          __typename
        }
        departmentID
        oneweightID
        ambulanceID
        incidentNumber
        startTime
        endTime
        estimatedWeight
        medications {
          medicationID
          amntSolid
          amntLiquid
          timestamp
          verified
          name
          rxNormCode
          concentration
          isActualWeight
          adminType
          weight
          protocolID
          __typename
        }
        equipments {
          equipmentID
          amount
          name
          snomedCode
          timestamp
          isActualWeight
          verified
          weight
          successful
          protocolID
          __typename
        }
        electricals {
          electricalID
          name
          snomedCode
          amount
          timestamp
          isActualWeight
          verified
          weight
          protocolID
          __typename
        }
        cpr {
          cprID
          startTime
          endTime
          outcome
          __typename
        }
        emsCalculators {
          calculatorID
          responses
          scores
          timestamp
          __typename
        }
        patientSaved
        patientReleased
        arrivedAtHospital
        ageTimestamp
        forms {
          formID
          name
          completed
          startedTime
          protocolID
          __typename
        }
        subtractedItems {
          weightObjectID
          name
          weight
          added
          __typename
        }
        userIDs
        infusions {
          dripID
          amnt
          amnt2
          timestamp
          verified
          name
          rxNormCode
          concentration
          adminType
          weight
          protocolID
          amntUnit
          amntUnit2
          isActualWeight
          gttsDropFactor
          gttsAmount
          gttsUnit
          __typename
        }
        estimatedSaved
        tags
        shifts {
          shiftStart
          shiftEnd
          completedLogs
          taggedAmbulance
          taggedOneWeight
          taggedUsers
          ownerID
          departmentID
          metaData
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.LogUsersByUserIdQueryVariables,
  APITypes.LogUsersByUserIdQuery
>;
export const logUsersByLogId = /* GraphQL */ `query LogUsersByLogId(
  $logId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelLogUserFilterInput
  $limit: Int
  $nextToken: String
) {
  logUsersByLogId(
    logId: $logId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      userId
      logId
      user {
        id
        firstName
        lastName
        type
        cognitoID
        indexedParentDepID
        departmentID
        depAdmins
        logs {
          nextToken
          startedAt
          __typename
        }
        hashedPin
        saltPin
        pairedDepIDs
        oneDoseVersion
        lastActivity
        notificationTokens
        deviceIDs
        maxDevices
        allowAnalytics
        status
        FormLogs {
          nextToken
          startedAt
          __typename
        }
        Reviewals {
          nextToken
          startedAt
          __typename
        }
        Acknowledges {
          nextToken
          startedAt
          __typename
        }
        UserComments {
          nextToken
          startedAt
          __typename
        }
        NotifyACKs {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      log {
        id
        actualWeight
        ageValue
        protocolID
        Users {
          nextToken
          startedAt
          __typename
        }
        departmentID
        oneweightID
        ambulanceID
        incidentNumber
        startTime
        endTime
        estimatedWeight
        medications {
          medicationID
          amntSolid
          amntLiquid
          timestamp
          verified
          name
          rxNormCode
          concentration
          isActualWeight
          adminType
          weight
          protocolID
          __typename
        }
        equipments {
          equipmentID
          amount
          name
          snomedCode
          timestamp
          isActualWeight
          verified
          weight
          successful
          protocolID
          __typename
        }
        electricals {
          electricalID
          name
          snomedCode
          amount
          timestamp
          isActualWeight
          verified
          weight
          protocolID
          __typename
        }
        cpr {
          cprID
          startTime
          endTime
          outcome
          __typename
        }
        emsCalculators {
          calculatorID
          responses
          scores
          timestamp
          __typename
        }
        patientSaved
        patientReleased
        arrivedAtHospital
        ageTimestamp
        forms {
          formID
          name
          completed
          startedTime
          protocolID
          __typename
        }
        subtractedItems {
          weightObjectID
          name
          weight
          added
          __typename
        }
        userIDs
        infusions {
          dripID
          amnt
          amnt2
          timestamp
          verified
          name
          rxNormCode
          concentration
          adminType
          weight
          protocolID
          amntUnit
          amntUnit2
          isActualWeight
          gttsDropFactor
          gttsAmount
          gttsUnit
          __typename
        }
        estimatedSaved
        tags
        shifts {
          shiftStart
          shiftEnd
          completedLogs
          taggedAmbulance
          taggedOneWeight
          taggedUsers
          ownerID
          departmentID
          metaData
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.LogUsersByLogIdQueryVariables,
  APITypes.LogUsersByLogIdQuery
>;
