import { DataStore } from 'aws-amplify';
import {
  DatabaseResponse,
  executeQuery,
  executeSingleQuery,
  mapModelItems,
  mapModelSubItems,
  Response,
  ResponseType,
} from '../AmplifyDB';
import {
  CPRAssist,
  ElectricalDose,
  ElectricalShock,
  Medication,
  MedicationDose,
  User,
} from '../../models';
import DepartmentItem from '../model/DepartmentItem';
import {
  getCPRAssist,
  cPRAssistsByDepartmentID,
  listCPRAssists,
  medicationDosesByCprAssistID,
  electricalDosesByCprAssistID,
} from '../../graphql/queries';
import CPRItem from '../model/CPRItem';
import { ProgressStatus } from '../../API';
import {
  compareVersions,
  getActiveID,
  globals,
} from '../../ui/_global/common/Utils';
import DraftChangeItem, { DraftChangeType } from '../model/DraftChangeItem';
import { checkIdUpdateDraftChange, Draft } from '../AmplifyVersion';
import { DraftChangeJSON, updateDraftChangeItem } from './ReviewalDB';
import {
  ArchiveItem,
  setAllArchiveItemsToNewActiveID,
  setAllDosesToNewParentID,
} from './ModelDB';
import {
  updateCPRAssist,
  updateElectricalDose,
  updateMedicationDose,
} from '../../graphql/mutations';
import { deleteMedicationsForCPR } from './MedicationDB';
import { deleteElectricalsForCPR } from './ElectricalDB';
import ElectricalSubItem from '../model/ElectricalSubItem';
import ElectricalItem from '../model/ElectricalItem';
import MedicationItem from '../model/MedicationItem';
import MedicationSubItem from '../model/MedicationSubItem';
import ModelItem from '../model/ModelItem';

export type cprDB = {
  departmentID: string;
  activeID: string | null | undefined;
  version: string;
  status: ProgressStatus | keyof typeof ProgressStatus;
  modifiedBy?: string;
  createdBy: string;
  defaultMode: string;
  epiOrangeSec: number;
  epiRedSec: number;
  compressionRate: number;
  ventRatePerMin: number;
  ventilateSoundDelay: number;
  equipmentID?: string;
  ventilateLoops: number;
  continousVentilateLoops: number;
  ventilateSoundSpeed: number;
  pulseCheckTime: number;
  chargingTime: number;
};

/**
 * This function will check if the cpr is a draft version and delete it
 * @param cprItem The cpr to check
 * @returns Success if ready to create a new cpr or Failure if there is a draft version
 */

export const checkUpgradeDraftVersion = async (
  id: string,
  isActive: boolean
): Promise<Response> => {
  try {
    let results: CPRAssist[];
    if (isActive)
      results = await DataStore.query(CPRAssist, (v) =>
        v.and((v) => [v.status.eq('DRAFT'), v.activeID.eq(id)])
      );
    else
      results = await DataStore.query(CPRAssist, (v) =>
        v.and((v) => [v.status.eq('DRAFT'), v.id.eq(id)])
      );
    /* There is no current draft version */
    if (results == null || results.length === 0) {
      return {
        type: ResponseType.Success,
        data: undefined,
      };
    }
    if (results.length > 1) {
      return {
        type: ResponseType.Failure,
        data: 'There are multiple draft versions',
      };
    }

    let dbCPR = results[0];
    if (dbCPR.status === ProgressStatus.DRAFT) {
      let result = await DataStore.delete(CPRAssist, dbCPR.id);
      if (result == null) {
        return {
          type: ResponseType.Failure,
          data: 'The cpr did not delete correctly',
        };
      }
    }

    return {
      type: ResponseType.Success,
      data: dbCPR,
    };
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

export const createCPRAssist = async (
  cpr: cprDB | CPRItem,
  previousItem?: CPRItem
): Promise<Response> => {
  try {
    let json: cprDB;
    if (cpr instanceof CPRItem) {
      json = {
        departmentID: cpr.departmentID,
        activeID: cpr.activeID,
        version: cpr.version,
        status: cpr.status,
        modifiedBy: cpr.modifiedBy
          ? cpr.modifiedBy.id
          : cpr.model.modifiedBy || undefined,
        createdBy: cpr.model.createdBy ? cpr.model.createdBy : '',
        defaultMode: cpr.defaultMode,
        epiOrangeSec: cpr.epiOrangeSeconds,
        epiRedSec: cpr.epiRedSeconds,
        compressionRate: cpr.compressionRate,
        ventRatePerMin: cpr.ventRatePerMin,
        ventilateSoundDelay: cpr.ventilateSoundDelay,
        equipmentID: cpr.airwayDevice?.uid || cpr.model.equipmentID || '',
        ventilateLoops: cpr.ventilateLoops,
        continousVentilateLoops: cpr.continousVentilateLoops,
        ventilateSoundSpeed: cpr.ventilateSoundSpeed,
        pulseCheckTime: cpr.pulseCheckTime,
        chargingTime: cpr.chargingTime,
      };
    } else {
      json = cpr;
    }
    /* 
			1. Creating a DRAFT the first time
			2. Creating a DRAFT from an ACTIVE version
			3. Updating a DRAFT from a DRAFT version
			4. Creating a ARCHIVE from an ACTIVE version
		*/
    let v: CPRAssist;
    /* Use Case 3: Updating a current DRAFT version */
    if (
      previousItem &&
      previousItem.status.includes('DRAFT') &&
      json.status.includes('DRAFT')
    ) {
      let dbCPR = await DataStore.query(CPRAssist, previousItem.uid);
      if (dbCPR == null) {
        return {
          type: ResponseType.Failure,
          data: 'The DRAFT CPR does not exist could not update',
        };
      }

      v = await DataStore.save(
        CPRAssist.copyOf(dbCPR, (updated) => {
          updated.cprMetaData = {
            defaultMode: json.defaultMode,
            epiOrangeSec: json.epiOrangeSec,
            epiRedSec: json.epiRedSec,
            compressionRate: json.compressionRate,
            ventRatePerMin: json.ventRatePerMin,
            ventilateSoundDelay: json.ventilateSoundDelay,
            ventilateLoops: json.ventilateLoops,
            continousVentilateLoops: json.continousVentilateLoops,
            ventilateSoundSpeed: json.ventilateSoundSpeed,
            pulseCheckTime: json.pulseCheckTime,
            chargingTime: json.chargingTime,
          };
          updated.equipmentID = json.equipmentID;
          updated.modifiedBy = json.modifiedBy;
          updated.version = json.version;
        })
      );
    } else {
      /* Use Case 1, 2, & 4: Creating a DRAFT the first time */
      v = await DataStore.save(
        new CPRAssist({
          protocolIDs: [],
          epiIDs: [],
          defibIDs: [],
          equipmentID: json.equipmentID,
          departmentID: json.departmentID,
          activeID: json.activeID,
          version: json.version,
          status: json.status,
          cprMetaData: {
            defaultMode: json.defaultMode,
            epiOrangeSec: json.epiOrangeSec,
            epiRedSec: json.epiRedSec,
            compressionRate: json.compressionRate,
            ventRatePerMin: json.ventRatePerMin,
            ventilateSoundDelay: json.ventilateSoundDelay,
            ventilateLoops: json.ventilateLoops,
            continousVentilateLoops: json.continousVentilateLoops,
            ventilateSoundSpeed: json.ventilateSoundSpeed,
            pulseCheckTime: json.pulseCheckTime,
            chargingTime: json.chargingTime,
          },
          modifiedBy: json.modifiedBy,
          createdBy: json.createdBy,
        })
      );
    }
    if (globals.debug) console.log('Created CPR:', v);
    let cprItem = new CPRItem(v);
    return {
      type: ResponseType.Success,
      data: cprItem,
    };
  } catch (error: any) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

/**
 * This function will publish the cpr to the database
 *    1. Create a new ARCHEIVED cpr based on the current ACTIVE cpr
 *    2. Update the ACTIVE cpr with the new information
 *    3. Delete the DRAFT cpr
 * @param draftCPRItem The cpr to publish
 */

export const publishCPR = async (
  draftCPRItem: CPRItem,
  draftChangeItem?: DraftChangeItem
): Promise<Response> => {
  try {
    /* Base Case 1 -- check if the cpr is configured correctly as a draft version */
    if (draftCPRItem.status !== ProgressStatus.DRAFT) {
      return {
        type: ResponseType.Failure,
        data: 'The cpr is not a draft version',
      };
    }

    let dbCPR = await DataStore.query(CPRAssist, draftCPRItem.uid);
    if (dbCPR == null) {
      return {
        type: ResponseType.Failure,
        data: 'The cpr does not exist',
      };
    }

    let activeCPR: CPRAssist;

    /* Use case 1: Creating the FIRST active version */
    if (draftCPRItem.activeID == null) {
      /* Update the draft cpr to be active */
      activeCPR = await DataStore.save(
        CPRAssist.copyOf(dbCPR, (updated) => {
          updated.status = ProgressStatus.ACTIVE;
        })
      );
      if (globals.debug)
        if (globals.debug)
          console.log('Created the first release of the cpr:', activeCPR);
    } else {
      /* Use case 2: Upgrading a active version */
      /* Step 1. Fetch the active cpr item */
      let id: string = draftCPRItem.activeID;
      let curCPR = await DataStore.query(CPRAssist, id);

      /* Base Case 3 -- check if the active cpr exists */
      if (curCPR == null) {
        return {
          type: ResponseType.Failure,
          data: 'The active cpr does not exist',
        };
      }

      let archive = new CPRItem(curCPR);
      archive.status = ProgressStatus.ARCHIVE;
      archive.activeID = curCPR.id;

      // 2. Create a new ARCHEIVED cpr based on the current ACTIVE cpr
      let archiveResult: Response = await createCPRAssist(archive);
      if (archiveResult.type === ResponseType.Failure) return archiveResult;
      archive = archiveResult.data as CPRItem;

      // 2. Update the ACTIVE cpr with the new information
      activeCPR = await DataStore.save(
        CPRAssist.copyOf(curCPR, (updated) => {
          updated.cprMetaData = {
            defaultMode: draftCPRItem.defaultMode,
            epiOrangeSec: draftCPRItem.epiOrangeSeconds,
            epiRedSec: draftCPRItem.epiRedSeconds,
            compressionRate: draftCPRItem.compressionRate,
            ventRatePerMin: draftCPRItem.ventRatePerMin,
            ventilateSoundDelay: draftCPRItem.ventilateSoundDelay,
            ventilateLoops: draftCPRItem.ventilateLoops,
            continousVentilateLoops: draftCPRItem.continousVentilateLoops,
            ventilateSoundSpeed: draftCPRItem.ventilateSoundSpeed,
            pulseCheckTime: draftCPRItem.pulseCheckTime,
            chargingTime: draftCPRItem.chargingTime,
          };
          updated.equipmentID = draftCPRItem.model.equipmentID;
          updated.modifiedBy = draftCPRItem.model.modifiedBy;
          updated.createdBy = draftCPRItem.model.createdBy;

          updated.status = ProgressStatus.ACTIVE;
          updated.activeID = null;
          updated.version = draftCPRItem.version;
        })
      );

      // 3. Delete the DRAFT cpr
      let draftCPR = await DataStore.delete(CPRAssist, draftCPRItem.uid);
      if (draftCPR == null) {
        return {
          type: ResponseType.Failure,
          data: 'The draft cpr does not exist',
        };
      }

      // 4. Query if there any closed draft changes with the actvie model item
      checkIdUpdateDraftChange(activeCPR.id, archive.uid);

      // 5. If there is a draftChangeItem then update the changeID to the new active category and the previousID to the archeived category
      if (draftChangeItem) {
        console.log('Draft Change Item', draftChangeItem);
        let new_dc: DraftChangeJSON = {
          previousDraftChange: draftChangeItem,
          changeItem: activeCPR.id,
          changeType: draftChangeItem.changeType,
          previousItem: archive.uid,
          isClosed: true,
        };

        console.log('Preivous Draft Change Item', draftChangeItem);
        updateDraftChangeItem(new_dc).then((result) => {
          console.log('Modified Draft Change Item', result);
          if (result == null) {
            return {
              type: ResponseType.Failure,
              data: 'The draft change item did not update correctly',
            };
          }
        });
      }
    }

    let cprItem = new CPRItem(activeCPR);
    return {
      type: ResponseType.Success,
      data: cprItem,
    };
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

/**
 * This function will delete the cpr item from the database
 *    1. If the cpr item is a draft then it will be soft deleted
 *    2. If the cpr item is an active then it will be hard deleted
 * @param cprItem The cpr item to delete
 * @param isSoft If true then the cpr item will be soft deleted otherwise it will be hard deleted
 * @returns The deleted cpr item
 * @description
 * IsSoft = True
 *    1. Fetch the item from the database to verify it exists
 *    2. Create a new cpr item with the same data in the DELETED status
 *    3. Delete the ACTIVE item from the database
 *    4. Set all the archive items activeID to the new cpr item
 *    5. Check if there any draft changes with the active cpr and update the changeID to the new cpr item
 * IsSoft = False
 *    1. Delete the item from the database
 */
export const deleteCPRItem = async (
  cprItem: CPRItem,
  modifiedBy: User,
  isSoft: boolean
): Promise<Response> => {
  try {
    let id: string = cprItem.uid;
    if (isSoft) {
      let cpr = await DataStore.query(CPRAssist, id);
      if (cpr == null) {
        return {
          type: ResponseType.Failure,
          data: 'The cpr does not exist',
        };
      }
      cprItem.status = ProgressStatus.DELETED;
      let response = await createCPRAssist(cprItem);
      if (response.type === ResponseType.Failure) {
        return response;
      }
      let newDeletedCPR = response.data as CPRItem;
      if (globals.debug) console.log('New Deleted CPR Item: ', newDeletedCPR);

      /* First, update all the SubDoses to the new Protocol so they do not get deleted */
      let promises = [
        deleteMedicationsForCPR(cprItem, newDeletedCPR, modifiedBy),
        deleteElectricalsForCPR(cprItem, newDeletedCPR, modifiedBy),

        // epiDoses (Medication Doses)
        setAllDosesToNewParentID(
          cprItem,
          newDeletedCPR,
          updateMedicationDose,
          medicationDosesByCprAssistID,
          'cprAssistID',
          true,
          7
        ),
        // defibShocks (Electrical Doses)
        setAllDosesToNewParentID(
          cprItem,
          newDeletedCPR,
          updateElectricalDose,
          electricalDosesByCprAssistID,
          'cprAssistID',
          false,
          6
        ),
      ];

      let results = await Promise.all(promises);
      if (results.some((r) => r.type === ResponseType.Failure)) {
        console.error('Error in deleteCPRItem', results);
      }

      if (globals.debug)
        console.log('Set all doses to new parent ID:', results);

      /* Now delete the CPR Assist */
      let deletedItem = await DataStore.delete(CPRAssist, id).catch((error) => {
        console.error('Error in deleteCPRItem: ', error);
        return null;
      });
      if (deletedItem == null) {
        return {
          type: ResponseType.Failure,
          data: 'The cpr did not delete correctly',
        };
      }

      /* Update all the archive items to the new active item */
      setAllArchiveItemsToNewActiveID(
        cprItem,
        newDeletedCPR,
        updateCPRAssist,
        cPRAssistsByDepartmentID,
        {
          departmentID: cprItem.departmentID,
        }
      )
        .then((result: Response) => {
          if (result.type === ResponseType.Failure) {
            console.error(
              'Error in setAllArchiveItemsToNewActiveID',
              result.data
            );
            return;
          }
          if (globals.debug)
            console.log('setAllArchiveItemsToNewActiveID', result.data);
        })
        .catch((e) => {
          console.error('Error in setAllArchiveItemsToNewActiveID', e);
          return {
            type: ResponseType.Failure,
            data: e,
          };
        });

      /* Check to see if there was a DraftChange referencing this item and update it to the new ID */
      checkIdUpdateDraftChange(cprItem.id, newDeletedCPR.uid);
    } else {
      let cpr = await DataStore.delete(CPRAssist, id);
      if (cpr == null) {
        return {
          type: ResponseType.Failure,
          data: 'The cpr does not exist',
        };
      }

      if (globals.debug) console.log('Hard Deleted cpr:', cpr);
    }
    return {
      type: ResponseType.Success,
      data: cprItem,
    };
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

export const isCPRDraftCreated = async (
  department: DepartmentItem
): Promise<Response> => {
  try {
    let drafts = await DataStore.query(CPRAssist, (v) =>
      v.and((v) => [v.status.eq('DRAFT'), v.departmentID.eq(department.id)])
    );
    return {
      type: ResponseType.Success,
      data: drafts.length !== 0,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const getCPRDrafts = async (db: DatabaseResponse): Promise<Response> => {
  try {
    let updates: any[] = [];
    let modelUpdates = await DataStore.query(CPRAssist, (c) =>
      c.and((c) => [c.status.eq('DRAFT'), c.departmentID.eq(db.department.id)])
    );

    for (let i = 0; i < modelUpdates.length; i++) {
      let model = new CPRItem(modelUpdates[i]);
      let activeItem =
        db.cprModel?.activeItem?.uid === model.activeID
          ? (db.cprModel?.activeItem as CPRItem)
          : null;
      if (activeItem) {
        model.activeItem =
          activeItem.uid === model.activeID
            ? activeItem
            : activeItem.activeItem;
      }
      updates.push({
        model: model,
        title: 'CPR ' + model.name,
        message: getChangeDescription(model),
        changeType: DraftChangeType.CPR,
      });
    }

    return {
      type: ResponseType.Success,
      data: updates,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const convertCPRChangeToDraft = (dc: DraftChangeItem): Draft | null => {
  try {
    if (dc.changeItem == null) {
      return null;
    }
    let update: Draft = {
      draftChangeItem: dc,
      model: dc.changeItem,
      title: 'CPR ' + dc.changeItem.name,
      message: getChangeDescription(dc.changeItem as CPRItem),
      changeType: DraftChangeType.CPR,
    };

    return update;
  } catch (error) {
    return null;
  }
};

function getChangeDescription(draftItem: CPRItem): string {
  if (draftItem.activeItem == null) return `Created CPR`;
  return `Updated CPR`;
}

export const removeCurrentCPRDrafts = async (
  db: DatabaseResponse
): Promise<Response> => {
  try {
    let updates: any[] = [];
    let draftCPRs = await DataStore.query(CPRAssist, (v) =>
      v.and((v) => [v.status.eq('DRAFT'), v.departmentID.eq(db.department.id)])
    );
    if (globals.debug) console.log('Found draft CPRAssist:', draftCPRs.length);
    for (let i = 0; i < draftCPRs.length; i++) {
      let cpr: CPRAssist = draftCPRs[i];
      if (globals.debug) console.log('Removing CPRAssist:', cpr.id);
      await DataStore.delete(cpr);
      updates.push({
        model: cpr,
        message: `Removed: ${cpr.id}`,
      });
    }

    return {
      type: ResponseType.Success,
      data: updates,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};
export const fetchCPRAssists = async (
  dep: DepartmentItem,
  useDataStore: boolean = true,
  includeSubItems: boolean = false,
  db?: DatabaseResponse
): Promise<Response> => {
  try {
    let depIDs = [dep.id];

    // 2-25-2025 Halzett Commented this out because the CPR Assist is owned by only ONE deparmtent cannot be owned by a parent
    // if (dep.parentDep) depIDs.push(dep.parentDep.id);
    // if (dep.parentDep?.parentDep) depIDs.push(dep.parentDep.parentDep.id);

    let allCPR;
    if (useDataStore) {
      allCPR = await DataStore.query(CPRAssist, (c) =>
        c.and((c) => [
          c.or((c) => depIDs.map((id) => c.departmentID.eq(id))),
          c.status.ne('DELETED'),
        ])
      );
    } else {
      allCPR = await executeQuery(listCPRAssists, {
        departmentID: dep.id,
        filter: {
          and: [
            { or: depIDs.map((id) => ({ departmentID: { eq: id } })) },
            {
              and: [
                { status: { ne: 'ARCHIVE' } },
                { status: { ne: 'DELETED' } },
              ],
            },
          ],
        },
      });
    }

    let cprItems: CPRItem[] = [];
    for (let i = 0; i < allCPR.length; i++) {
      let cpr = new CPRItem(allCPR[i]);
      // if (cpr.status.includes('DRAFT') || cpr.status === 'ACTIVE') {
      /* Take out the active version if there is one */
      mapModelItems(cpr, cprItems, cpr.status, dep);
    }
    cprItems.sort((a, b) => a.version.localeCompare(b.version));

    if (includeSubItems && db && cprItems.length > 0) {
      let promises = [];
      for (let i = 0; i < cprItems.length; i++) {
        let cpr = cprItems[i];
        promises.push(fetchSubItemsForCPRAssist(cpr, db, dep, useDataStore));
      }
      let results = await Promise.all(promises);
      for (let i = 0; i < results.length; i++) {
        let response = results[i];
        if (response.type === ResponseType.Success) {
          cprItems[i] = response.data as CPRItem;
        }
      }
    } else if (includeSubItems && db == null) {
      throw new Error('DatabaseResponse JSON is required to fetch sub items');
    }

    if (cprItems.length === 0)
      return {
        type: ResponseType.Success,
        data: undefined,
      };

    return {
      type: ResponseType.Success,
      data: cprItems[0],
    };
  } catch (error: any) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const fetchSubItemsForCPRAssist = async (
  cprAssist: CPRItem,
  db: DatabaseResponse,
  dep: DepartmentItem,
  useDataStore: boolean = false
): Promise<Response> => {
  try {
    let cprID = getActiveID(cprAssist);
    let promises = [
      DataStore.query(MedicationDose, (m) =>
        m.and((m) => [
          m.cprAssistID.eq(cprID),
          m.or((m) => [m.status.eq('ACTIVE'), m.status.eq('DRAFT')]),
        ])
      ),
      DataStore.query(ElectricalDose, (v) =>
        v.and((v) => [
          v.cprAssistID.eq(cprID),
          v.or((v) => [v.status.eq('ACTIVE'), v.status.eq('DRAFT')]),
        ])
      ),
    ];
    let [medicationDoses, electricalDoses] = await Promise.all(promises);

    /* Convert all the medication doses to MedicationSubItem */
    let medMisses: string[] = [];
    let medicationSubItems: MedicationSubItem[] = [];
    for (let i = 0; i < medicationDoses.length; i++) {
      let dose = medicationDoses[i] as MedicationDose;
      let medication = db.medications.find((m) => {
        const medID = getActiveID(m);
        return medID === dose.medicationID;
      });
      if (medication == null) {
        medMisses.push(dose.id);
        continue;
      }
      let medicationSubItem = new MedicationSubItem(
        medication,
        cprAssist,
        dose
      );
      mapModelSubItems(
        medicationSubItem,
        medicationSubItems,
        medicationSubItem.status,
        dep
      );
    }

    /* Convert all the electrical doses to ElectricalSubItem */
    let eleMisses: string[] = [];
    let electricalSubItems: ElectricalSubItem[] = [];
    for (let i = 0; i < electricalDoses.length; i++) {
      let dose = electricalDoses[i] as ElectricalDose;
      let electrical = db.electrical.find((e) => {
        const eleID = getActiveID(e);
        return eleID === dose.electricalID;
      });
      if (electrical == null) {
        eleMisses.push(dose.id);
        continue;
      }
      let electricalSubItem = new ElectricalSubItem(
        electrical,
        cprAssist,
        dose
      );
      mapModelSubItems(
        electricalSubItem,
        electricalSubItems,
        electricalSubItem.status,
        dep
      );
    }

    /* Add the medication and electrical sub items to the CPRAssist */
    cprAssist.epiDoses = medicationSubItems;
    cprAssist.defibShocks = electricalSubItems;

    if (medMisses.length > 0) {
      console.warn(
        `HINCKLEY MEDICAL WARNING: Medication doses not found for CPRAssist ${cprAssist.uid}: ${medMisses.join(', ')}`
      );
    }
    if (eleMisses.length > 0) {
      console.warn(
        `HINCKLEY MEDICAL WARNING: Electrical doses not found for CPRAssist ${cprAssist.uid}: ${eleMisses.join(', ')}`
      );
    }

    return {
      type: ResponseType.Success,
      data: cprAssist,
    };
  } catch (error: any) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

/* GraphQL API Queries */
export const getCPRByID = async (
  db: DatabaseResponse,
  id: string
): Promise<CPRItem | null> => {
  return new Promise(async (resolve, reject) => {
    try {
      /* Fetch the category from the database */
      const dbCPR = db.cprModel && db.cprModel.uid === id ? db.cprModel : null;
      if (dbCPR != null) return resolve(dbCPR);
      else {
        executeSingleQuery(getCPRAssist, { id: id }, 1500)
          .then((cpr: CPRAssist | null | undefined) => {
            if (cpr == null) return null;
            let cprItem = new CPRItem(cpr);
            return resolve(cprItem);
          })
          .catch((error: any) => {
            console.error('getProtocolByID', error);
            reject(error);
          });
      }
    } catch (error: any) {
      reject(error);
    }
  });
};

export const fetchArchivedCPRAssists = async (
  dep: DepartmentItem,
  db: DatabaseResponse,
  status: ProgressStatus = ProgressStatus.ACTIVE,
  useDataStore: boolean = false
): Promise<Response> => {
  try {
    let depIDs = [dep.id];
    if (dep.parentDep) depIDs.push(dep.parentDep.id);
    if (dep.parentDep?.parentDep) depIDs.push(dep.parentDep.parentDep.id);

    let allCPR;
    if (useDataStore) {
      allCPR = await DataStore.query(CPRAssist, (c) =>
        c.and((c) => [
          c.or((c) => depIDs.map((id) => c.departmentID.eq(id))),
          c.status.ne('DELETED'),
        ])
      );
    } else {
      allCPR = await executeQuery(listCPRAssists, {
        departmentID: dep.id,
        filter: {
          and: [
            { or: depIDs.map((id) => ({ departmentID: { eq: id } })) },
            {
              and: [{ status: { ne: 'DELETED' } }],
            },
          ],
        },
      });
    }

    const cprItems: CPRItem[] = allCPR.map((cprItem: CPRAssist) => {
      let cpr = new CPRItem(cprItem);

      if (cpr.anyModel.DefibDoses != null) {
        let items = cpr.anyModel.DefibDoses.items;
        items.forEach((item: any) => {
          if (!item || item._deleted === true) return;
          let find = db.electrical.find(
            (e) =>
              e.uid === item.electricalID || e.activeID === item.electricalID
          );
          if (find) {
            find =
              find.activeItem?.uid === item.electricalID
                ? (find.activeItem as ElectricalItem)
                : find;
            let defibShock = new ElectricalSubItem(find, cpr, item);
            cpr.defibShocks.push(defibShock);
          }
        });
      }
      if (cpr.anyModel.EpiDoses != null) {
        let items = cpr.anyModel.EpiDoses.items;
        items.forEach((item: any) => {
          if (!item || item._deleted === true) return;
          let find = db.medications.find(
            (e) =>
              e.uid === item.medicationID || e.activeID === item.medicationID
          );
          if (find) {
            find =
              find.activeItem?.uid === item.medicationID
                ? (find.activeItem as MedicationItem)
                : find;
            let epiDose = new MedicationSubItem(find, cpr, item);
            cpr.epiDoses.push(epiDose);
          }
        });
      }
      return cpr;
    });

    let archiveItems: ArchiveItem[] = [];
    for (let i = 0; i < cprItems.length; i++) {
      const cpr = cprItems[i];
      // When med is in ACTIVE mode
      if (cpr.activeID == null && cpr.status === 'ACTIVE') {
        // Find for an existing archive item with the same uid
        let archiveItem = archiveItems.find((item) => item.id === cpr.uid);
        if (archiveItem) {
          // If found, check if the activeItem is in DRAFT mode
          // If not, update the activeItem
          if (archiveItem.activeItem.status !== 'DRAFT') {
            archiveItem.activeItem = cpr;
          } else {
            // If the activeItem is in DRAFT mode, update the activeItem.activeItem
            archiveItem.activeItem.activeItem = cpr;
          }
        } else {
          // If not found, create a new archive item
          archiveItems.push({
            id: cpr.uid,
            activeItem: cpr,
            items: [],
          });
        }
      }
      // When cpr is in DRAFT mode - it is the latest version which is not yet published
      else if (cpr.activeID !== null && cpr.status === 'DRAFT') {
        // Find for an existing archive item with the same uid
        let archiveItem = archiveItems.find((item) => item.id === cpr.activeID);
        if (archiveItem) {
          // If found, update the activeItem
          archiveItem.id = cpr.activeID || 'ERROR';
          cpr.activeItem = archiveItem.activeItem as CPRItem;
          archiveItem.activeItem = cpr;
        } else {
          // If not found, create a new archive item
          archiveItems.push({
            id: cpr.activeID || 'ERROR',
            activeItem: cpr,
            items: [],
          });
        }
      }
      // When cpr is in ARCHIVE mode
      else if (cpr.activeID !== null && cpr.status === 'ARCHIVE') {
        // Find for an existing archive item with the same activeID
        let archiveItem = archiveItems.find((item) => item.id === cpr.activeID);
        if (archiveItem) {
          // If found, add this cpr to the items array
          let items = archiveItem.items;
          items.push(cpr);
          // Sort the items array by version
          items.sort((a: CPRItem, b: CPRItem) => {
            if (a.version === b.version) return a.name.localeCompare(b.name);
            return compareVersions(
              a.version || 'v1.0.0',
              b.version || 'v1.0.0'
            ) as number;
          });
          archiveItem.items = items;
        } else {
          // If not found, create a new archive item
          archiveItems.push({
            id: cpr.activeID || 'ERROR',
            activeItem: cpr,
            items: [cpr],
          });
        }
      }
    }

    // Filter out any activeItems that are in the archiveItems with activeItem.status !== 'ACTIVE'
    archiveItems = archiveItems.filter(
      (item) =>
        item.activeItem.status === 'ACTIVE' ||
        item.activeItem.status === 'DRAFT'
    );

    return {
      type: ResponseType.Success,
      data: archiveItems,
    };
  } catch (error: any) {
    console.error('Error fetching CPRAssists:', error);
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const listToArchiveItems = (
  list: (ElectricalSubItem | MedicationSubItem)[]
): ArchiveItem[] => {
  let archiveItems: ArchiveItem[] = [];
  for (let i = 0; i < list.length; i++) {
    const cpr = list[i];
    // When med is in ACTIVE mode
    if (cpr.activeID == null && cpr.status === 'ACTIVE') {
      // Find for an existing archive item with the same uid
      let archiveItem = archiveItems.find((item) => item.id === cpr.uid);
      if (archiveItem) {
        // If found, check if the activeItem is in DRAFT mode
        // If not, update the activeItem
        if (archiveItem.activeItem.status !== 'DRAFT') {
          archiveItem.activeItem = cpr;
        } else {
          // If the activeItem is in DRAFT mode, update the activeItem.activeItem
          archiveItem.activeItem.activeItem = cpr;
        }
      } else {
        // If not found, create a new archive item
        archiveItems.push({
          id: cpr.uid,
          activeItem: cpr,
          items: [],
        });
      }
    }
    // When cpr is in DRAFT mode - it is the latest version which is not yet published
    else if (cpr.activeID !== null && cpr.status === 'DRAFT') {
      // Find for an existing archive item with the same uid
      let archiveItem = archiveItems.find((item) => item.id === cpr.activeID);
      if (archiveItem) {
        // If found, update the activeItem
        archiveItem.id = cpr.activeID || 'ERROR';
        cpr.activeItem = archiveItem.activeItem as
          | ElectricalSubItem
          | MedicationSubItem;
        archiveItem.activeItem = cpr;
      } else {
        // If not found, create a new archive item
        archiveItems.push({
          id: cpr.activeID || 'ERROR',
          activeItem: cpr,
          items: [],
        });
      }
    }
    // When cpr is in ARCHIVE mode
    else if (cpr.activeID !== null && cpr.status === 'ARCHIVE') {
      // Find for an existing archive item with the same activeID
      let archiveItem = archiveItems.find((item) => item.id === cpr.activeID);
      if (archiveItem) {
        // If found, add this cpr to the items array
        let items = archiveItem.items;
        items.push(cpr);
        // Sort the items array by version
        items.sort((a: CPRItem, b: CPRItem) => {
          if (a.version === b.version) return a.name.localeCompare(b.name);
          return compareVersions(
            a.version || 'v1.0.0',
            b.version || 'v1.0.0'
          ) as number;
        });
        archiveItem.items = items;
      } else {
        // If not found, create a new archive item
        archiveItems.push({
          id: cpr.activeID || 'ERROR',
          activeItem: cpr,
          items: [cpr],
        });
      }
    }
  }

  // Filter out any activeItems that are in the archiveItems with activeItem.status !== 'ACTIVE'
  archiveItems = archiveItems.filter(
    (item) =>
      item.activeItem.status === 'ACTIVE' || item.activeItem.status === 'DRAFT'
  );

  return archiveItems;
};

export const fetchAllDosesShocksForCPRAssist = async (
  db: DatabaseResponse,
  dep: DepartmentItem,
  cprAssist: CPRItem,
  useDataStore: boolean = false
): Promise<Response> => {
  try {
    let depIDs = [dep.id];
    if (dep.parentDep) depIDs.push(dep.parentDep.id);
    if (dep.parentDep?.parentDep) depIDs.push(dep.parentDep.parentDep.id);

    let defibList: (ElectricalSubItem | MedicationSubItem)[] = [];
    let epiList: (ElectricalSubItem | MedicationSubItem)[] = [];

    if (cprAssist.anyModel.DefibDoses != null) {
      let items = cprAssist.anyModel.DefibDoses.items;
      items.forEach((item: any) => {
        if (!item || item._deleted === true) return;
        let find = db.electrical.find(
          (e) => e.uid === item.electricalID || e.activeID === item.electricalID
        );
        if (find) {
          find =
            find.activeItem?.uid === item.electricalID
              ? (find.activeItem as ElectricalItem)
              : find;
          let defibShock = new ElectricalSubItem(find, cprAssist, item);
          defibList.push(defibShock);
        }
      });
    }
    if (cprAssist.anyModel.EpiDoses != null) {
      let items = cprAssist.anyModel.EpiDoses.items;
      items.forEach((item: any) => {
        if (!item || item._deleted === true) return;
        let find = db.medications.find(
          (e) => e.uid === item.medicationID || e.activeID === item.medicationID
        );
        if (find) {
          find =
            find.activeItem?.uid === item.medicationID
              ? (find.activeItem as MedicationItem)
              : find;
          let epiDose = new MedicationSubItem(find, cprAssist, item);
          epiList.push(epiDose);
        }
      });
    }

    return {
      type: ResponseType.Success,
      data: [listToArchiveItems(defibList), listToArchiveItems(epiList)],
    };
  } catch (error: any) {
    console.error('Error fetching CPRAssists:', error);
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};
