import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import DepartmentItem from '../../../../data/model/DepartmentItem';
import {
  DatabaseResponse,
  findDepartmentLogo,
  ResponseType,
} from '../../../../data/AmplifyDB';
import ProtocolHeader from '../../protocol/ProtocolHeader';
import {
  getFormattedDate,
  globals,
  hasAdminUserAccess,
} from '../../../_global/common/Utils';
import { FaChevronRight, FaLock } from 'react-icons/fa6';
import ConfirmModal from '../../../components/Modal/ConfirmModal';
import { CalculatorType, ProgressStatus } from '../../../../models';
import { useDispatch } from 'react-redux';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import HMCheckbox from '../../../components/general/HMCheckbox';
import HMSwitch from '../../../components/general/HMSwitch';
import DataList, { Header } from './DataList';
import EMSCalculatorItem from '../../../../data/model/EMSCalculatorItem';
import {
  getDepartmentByID,
  updateDepartment,
} from '../../../../data/functions/DepartmentDB';
import { Tooltip } from '@mui/material';
import { handleGetDepartment } from '../../../../store/actions';

const allCalculators: EMSCalculatorItem[] = [
  new EMSCalculatorItem(CalculatorType.PARKLAND),
  new EMSCalculatorItem(CalculatorType.RACE),
  new EMSCalculatorItem(CalculatorType.RASS),
  new EMSCalculatorItem(CalculatorType.APGAR_CALC),
  new EMSCalculatorItem(CalculatorType.CPSSS_CALC),
  new EMSCalculatorItem(CalculatorType.ETT_DEPTH_TIDAL_VOL_CALC),
  new EMSCalculatorItem(CalculatorType.GCS_CALC),
  new EMSCalculatorItem(CalculatorType.IBW_ABW_CALC),
  new EMSCalculatorItem(CalculatorType.PETT_CALC),
  new EMSCalculatorItem(CalculatorType.PGCS_CALC),
  new EMSCalculatorItem(CalculatorType.LAMS_CALC),
  new EMSCalculatorItem(CalculatorType.MNIHSS_CALC),
  new EMSCalculatorItem(CalculatorType.FASTED_CALC),
  new EMSCalculatorItem(CalculatorType.OXYGEN_TANK_MINUTES_CALC),
].sort((a, b) => a.title.localeCompare(b.title));

/* 09-27-23 Arul: Created Component for Protocol Screen*/
const ListContacts = (props: any) => {
  const isLoggedIn = useSelector((state: any) => state.isLoggedIn);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const reducerState = useSelector((state: any) => state.department);

  const [database, setDatabase] = useState<DatabaseResponse>(
    useSelector((state: any) => state?.protocol?.departmentItem)
  );
  const user = useSelector((state: any) => state?.user);
  const department: DepartmentItem = database.department;

  const baseCalculators = useMemo(() => {
    return database.calculators;
  }, [database.calculators]);

  const [calculators, setCalculators] =
    useState<EMSCalculatorItem[]>(baseCalculators);

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success' as 'success' | 'error',
  });

  const [mainSwitch, setMainSwitch] = useState(false);

  const adminAccess = useMemo(
    () => hasAdminUserAccess(department, reducerState, user),
    [department, user]
  );

  const adminLevel = useMemo(() => {
    return department?.adminLevel ?? 4;
  }, [department]);

  const [modal, setModal] = useState<any>({
    isVisible: false,
    title: '',
    primaryBtnName: '',
    secondaryBtnName: '',
    primaryDescription: '',
    secondaryDescription: '',
    isSingleBtn: false,
    isDeleteBtn: false,
    handleSubmit: () => {},
    handleClose: () => {},
  });

  /**
   * Reset the calculators when the base calculators change
   */
  useEffect(() => {
    setCalculators(baseCalculators);
  }, [baseCalculators]);

  /**
   * Detect if all calculators are selected
   */
  useEffect(() => {
    if (calculators.length === allCalculators.length) setMainSwitch(true);
    else setMainSwitch(false);
  }, [calculators, allCalculators]);

  const reloadDatabase = async () => {
    const id = department.id;
    const dep = await getDepartmentByID(id);
    if (dep) {
      await dep.checkParentDep(false);
      dep.calculateAdminLevel(user);
      let logoResult = await findDepartmentLogo(dep);
      if (logoResult.type === ResponseType.Success)
        dep.logoVerifiedUrl = logoResult.data;
      else console.error('Error fetching department logo:', logoResult.data);

      const depCalculators = dep.calculators.map(
        (calc) => new EMSCalculatorItem(calc)
      );
      if (dep) {
        let db = {
          ...database,
          department: dep,
          calculators: depCalculators,
        };
        setDatabase(db);
        dispatch<any>(handleGetDepartment(db));
      }
    }
  };

  useEffect(() => {
    reloadDatabase();
  }, []);

  // /* 09-29-23 Arul: handle function to Navigate protocol detail page*/
  // const handleItemClick = (check: EMSCalculatorItem) => {
  //   const state = {
  //     selectedProtocol: null,
  //     value: check,
  //     subValue: null,
  //     type: 'Contact',
  //     editType: 'edit',
  //     editMode: false,
  //     page: 'listContactPage',
  //   };
  //   navigate(`/database/edit/contact`, { state });
  // };

  const handleSave = () => {
    department.calculators = calculators.map((calc) => calc.type);
    updateDepartment(department, department)
      .then((resp) => {
        if (resp.type === ResponseType.Success) {
          setSnackbar({
            open: true,
            message:
              'Successfully updated ' + department.name + ' EMS Calculators.',
            severity: 'success',
          });
        } else {
          setSnackbar({
            open: true,
            message:
              'Failed to update ' + department.name + ' EMS Calculators.',
            severity: 'error',
          });
        }
      })
      .finally(() => {
        reloadDatabase();
      });
  };

  const handleBack = () => {
    sessionStorage.removeItem('DataList-' + location.pathname);
    navigate(`/database`, { state: department });
  };

  const headers: Header<EMSCalculatorItem>[] = useMemo(
    () => [
      {
        key: 'input',
        name: '',
        sortable: false,
        flex: 0.15,
        render: (item: EMSCalculatorItem) => {
          const adminAccess = hasAdminUserAccess(
            department,
            reducerState,
            user
          );
          const isSwitchChecked =
            calculators.find((newItem) => newItem.uid === item.uid) != null;

          return (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                flex: 1,
                alignItems: 'center',
                height: '100%',
              }}
              onClick={(e) => e.stopPropagation()}
            >
              {adminAccess && (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    height: '100%',
                    width: '100%',
                    flexDirection: 'row',
                    marginLeft: '-12px',
                  }}
                >
                  <HMSwitch
                    checked={isSwitchChecked}
                    onChange={(e, checked) => handleSwitchChange(item)}
                  />
                </div>
              )}
            </div>
          );
        },
      },
      {
        key: 'name',
        name: 'Name',
        sortable: true,
        flex: 2,
        render: (item: EMSCalculatorItem) => {
          const isActive =
            calculators.find((newItem) => newItem.uid === item.uid) != null;
          return (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                color: isActive ? 'black' : '#636363',
              }}
            >
              {item.name}
            </div>
          );
        },
      },
      {
        key: 'modified_date',
        name: 'Modified Date',
        sortable: true,
        flex: 1,
        render: (item: EMSCalculatorItem) => {
          const isActive =
            calculators.find((newItem) => newItem.uid === item.uid) != null;
          return (
            <div style={{ color: isActive ? 'black' : '#636363' }}>
              {getFormattedDate(item.modifiedDate, true)}
            </div>
          );
        },
      },
      {
        key: 'questions',
        name: 'Questions',
        sortable: true,
        flex: 1,
        render: (item: EMSCalculatorItem) => {
          const isActive =
            calculators.find((newItem) => newItem.uid === item.uid) != null;

          // Calculate total questions count - account for nested questions as well
          const totalCount = item.questions.reduce((count, question) => {
            if (question.nested_questions) {
              // For nested questions, add 1 for the parent + max length of nested arrays
              const nestedLengths = Object.values(question.nested_questions)
                .filter(Array.isArray)
                .map((arr) => arr.length);
              const maxNestedLength = Math.max(...nestedLengths, 0);
              return count + 1 + maxNestedLength;
            } else {
              // For non-nested questions, just add 1
              return count + 1;
            }
          }, 0);

          return (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                paddingRight: '15px',
              }}
            >
              <span
                style={{
                  flex: 1,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  marginRight: '8px',
                  color: isActive ? 'black' : '#636363',
                }}
              >
                {totalCount}
              </span>
              {/* <FaChevronRight
                className="icon-normal"
                style={{
                  margin: '4px',
                  flexShrink: 0,
                  color: isActive ? 'black' : '#636363',
                }}
              /> */}
            </div>
          );
        },
      },
      {
        key: 'description',
        name: 'Description',
        sortable: false,
        flex: 2,
        render: (item: EMSCalculatorItem) => {
          const isActive =
            calculators.find((newItem) => newItem.uid === item.uid) != null;
          return (
            <Tooltip title={item.description} arrow placement="left">
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                  paddingRight: '15px',
                }}
              >
                <span
                  style={{
                    flex: 1,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    marginRight: '8px',
                    color: isActive ? 'black' : '#636363',
                  }}
                >
                  {item.description}
                </span>
              </div>
            </Tooltip>
          );
        },
      },
    ],
    [department, reducerState, user, calculators]
  );

  /**
   * Handle the sort item for the calculator
   * @param key - The key to sort by
   * @param direction - The direction to sort by
   * @param a - The first item to compare
   * @param b - The second item to compare
   * @returns The sorted item
   */
  const handleSortItem = (
    key: string,
    direction: string,
    a: EMSCalculatorItem,
    b: EMSCalculatorItem
  ) => {
    if (key === 'name') {
      return direction === 'asc'
        ? a.title.localeCompare(b.title)
        : b.title.localeCompare(a.title);
    } else if (key === 'modified_date') {
      const aDate = new Date(a.modifiedDate);
      const bDate = new Date(b.modifiedDate);
      return direction === 'asc'
        ? aDate.getTime() - bDate.getTime()
        : bDate.getTime() - aDate.getTime();
    } else if (key === 'questions') {
      return direction === 'asc'
        ? a.questions.length - b.questions.length
        : b.questions.length - a.questions.length;
    }
    return 0;
  };

  /**
   * Handle the select all for the calculator
   * @param e - The change event
   * @param checked - The checked state
   */
  const handleSelectAll = (
    e: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    if (checked) setCalculators(allCalculators);
    else setCalculators([]);
  };

  /**
   * Handle the switch change for the calculator
   * @param calculator - The calculator to change
   */
  const handleSwitchChange = (calculator: EMSCalculatorItem) => {
    let find = calculators.find((newItem) => newItem.uid === calculator.uid);
    let newCalculators = [...calculators];
    if (find)
      newCalculators = calculators.filter(
        (newItem) => newItem.uid !== calculator.uid
      );
    else newCalculators = [...calculators, calculator];
    setCalculators(newCalculators);
    if (newCalculators.length === allCalculators.length) setMainSwitch(true);
    else setMainSwitch(false);
  };

  const handleCreateClicked = () => {
    setModal({
      isVisible: true,
      title: 'Route to Comments Form?',
      primaryDescription:
        'Please route to our comments form to request a new EMS Calculator. We will review your request and get back to you as soon as possible.',
      secondaryDescription:
        'This is currently the best way to notify us of a new EMS Calculator.',
      primaryBtnName: 'Cancel',
      secondaryBtnName: 'Route',
      handleSubmit: () => {
        setModal({ ...modal, isVisible: false });
        window.open('https://hinckleymed.com/comments/', '_blank');
      },
      handleClose: () => {
        setModal({ ...modal, isVisible: false });
      },
    });
  };

  /**
   * Check if the save button is active
   * @returns True if the save button is active, false otherwise
   */
  const isSaveActive = useMemo(() => {
    if (calculators.length !== baseCalculators.length) return true;
    for (const calc of calculators) {
      let found = baseCalculators.find((newCalc) => newCalc.uid === calc.uid);
      if (found == null) return true;
    }
    return false;
  }, [calculators, baseCalculators]);

  return (
    <div className="screen-container">
      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>

      <ConfirmModal
        isVisible={modal.isVisible}
        title={modal.title}
        primaryBtnName={modal.primaryBtnName}
        secondaryBtnName={modal.secondaryBtnName}
        primaryDescription={modal.primaryDescription}
        secondaryDescription={modal.secondaryDescription}
        handleSubmit={modal.handleSubmit}
        handleClose={modal.handleClose}
      />
      <ProtocolHeader
        isBackButton={true}
        handleCancel={handleBack}
        name={
          calculators.length === 0
            ? 'EMS Calculators: ' + allCalculators.length + ' available'
            : 'EMS Calculators: ' +
              calculators.length +
              ' / ' +
              allCalculators.length +
              ' items'
        }
        description={`Manage the EMS calculators that are available just for ${department.name}.`}
        page={department.name}
        rightSideBtn={'edit'}
        isEditButton={false}
        isCreateButton={adminAccess}
        isCreateActive={true}
        isSaveButton={adminAccess}
        isSaveActive={isSaveActive}
        handleSave={handleSave}
        handleCancelEdit={() => {
          setCalculators(baseCalculators);
        }}
        handleCreate={handleCreateClicked}
        handleEdit={() => {}}
        type={'protocol'}
      />

      <DataList<EMSCalculatorItem, never>
        items={allCalculators}
        headers={headers}
        // onItemClick={handleItemClick}
        onSelectionChange={setCalculators}
        searchPlaceholder="Search EMS Calculators..."
        sortItem={handleSortItem}
        handleSelectAll={handleSelectAll}
        selectedItems={calculators}
        config={{
          mainInputType: 'switch',
          highlightSelectedItems: false,
        }}
        mainInputValue={mainSwitch}
        searchFilter={(item, searchQuery) => {
          return (
            item.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            (user.type === 'ADMIN' &&
              item.uid.toLowerCase().includes(searchQuery.toLowerCase()))
          );
        }}
      />
    </div>
  );
};

export default ListContacts;
