import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { DatabaseResponse, ResponseType } from '../../../../data/AmplifyDB';
import ProtocolHeader from '../../protocol/ProtocolHeader';
import {
  getDepartmentsFromState,
  getFormattedDate,
} from '../../../_global/common/Utils';
import { FaChevronRight } from 'react-icons/fa6';
import { User, WeightObject } from '../../../../models';
import { handleGetDepartment } from '../../../../store/actions';
import { useDispatch } from 'react-redux';
import {
  deleteWeightObject,
  fetchWeightObjects,
} from '../../../../data/functions/WeightObjectDB';
import ConfirmModal from '../../../components/Modal/ConfirmModal';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import DataList, { Header } from './DataList';
import HMCheckbox from '../../../components/general/HMCheckbox';

/* 09-27-23 Arul: Created Component for Protocol Screen*/
const ListWeightObjects = (props: any) => {
  const isLoggedIn = useSelector((state: any) => state.isLoggedIn);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { state } = location;
  const user: User = useSelector((state: any) => state.user);

  const [database, setDatabase] = useState<DatabaseResponse>(
    useSelector((state: any) => state?.protocol?.departmentItem)
  );
  const department = database.department;
  const [objects, setWeightObjects] = useState<WeightObject[]>(
    database.weightObjects ? database.weightObjects : []
  );

  const scrollPosition = useRef(0);
  const [sort, setSort] = useState('name_asc');
  const [searchQuery, setSearchQuery] = useState(
    state?.search?.searchQuery ?? ''
  );
  const [filters, setFilters] = useState<any[]>(state?.search?.filters ?? []);
  const [categoriesFilter, setCategoriesFilter] = useState<any[]>(
    state?.search?.categoriesFilter ?? []
  );
  const [list, setList] = useState<any[]>([]);
  const [selectedItems, setSelectedItems] = useState<WeightObject[]>([]);
  const paginationModel = { page: 0, pageSize: 50 };
  const [isDelete, setIsDelete] = useState(false);
  const [allCheckedBtn, setAllCheckedBtn] = useState(false);

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success' as 'success' | 'error',
  });

  const reducerState = useSelector((state: any) => state.department);
  const { subDeps, allSubDeps, userDepartments } = useMemo(() => {
    return getDepartmentsFromState(department, reducerState);
  }, [department, reducerState]);

  const reloadDatabase = async () => {
    const response = await fetchWeightObjects(department);
    if (response.type === ResponseType.Success) {
      setWeightObjects(response.data);
      handleFilterChange(response.data);
      dispatch<any>(
        handleGetDepartment({
          ...database,
          weightObjects: response.data,
        } as DatabaseResponse)
      );
    }
  };

  useEffect(() => {
    reloadDatabase();
    setAllCheckedBtn(false);
  }, []);

  /* 09-28-23 Arul: handle function for filter search*/
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
  };

  const handleFilterChange = (pList: any[]) => {
    let filteredList = [...(pList ? pList : objects)];
    if (searchQuery !== '') {
      filteredList = filteredList.filter((item) => {
        return item.name.toLowerCase().includes(searchQuery.toLowerCase());
      });
    }
    //Group by the parent first then sort by index
    filteredList = filteredList.sort((a: WeightObject, b: WeightObject) => {
      return a.name.localeCompare(b.name);
    });

    /* Sort the list based on the sort value */
    filteredList = filteredList.sort((a: WeightObject, b: WeightObject) => {
      if (sort === 'name_asc') return a.name.localeCompare(b.name);
      else if (sort === 'name_desc') return b.name.localeCompare(a.name);
      else if (sort.includes('modified')) {
        if (!a.updatedAt || !b.updatedAt) return 0;
        const aDate = new Date(a.updatedAt);
        const bDate = new Date(b.updatedAt);
        if (sort === 'modified_desc') return bDate.getTime() - aDate.getTime();
        return aDate.getTime() - bDate.getTime();
      } else if (sort === 'weight_asc') return a.weight - b.weight;
      else if (sort === 'weight_desc') return b.weight - a.weight;
      else return a.name.localeCompare(b.name);
    });
    setList(filteredList);
  };

  useEffect(() => {
    handleFilterChange(objects);
  }, [searchQuery, filters, categoriesFilter, objects, sort]);

  /* 09-29-23 Arul: handle function to Navigate protocol detail page*/
  const handleItemClick = (obj: WeightObject) => {
    const state = {
      selectedProtocol: null,
      value: obj,
      subValue: null,
      type: 'WeightObject',
      editType: 'edit',
      editMode: false,
      page: 'listWeightObjectPage',
    };
    navigate(`/database/edit/subtractable-weights`, { state });
    // const state = { selectedProtocol: protocol, editMode: false };
    // navigate(`/${protocol.nickname}/protocol-detail`, { state });
  };

  const handleCreateItem = () => {
    const state = {
      selectedProtocol: null,
      value: null,
      subValue: null,
      type: 'WeightObject',
      editType: 'new',
      editMode: false,
      page: 'listWeightObjectPage',
    };
    navigate(`/database/new/subtractable-weights`, { state });
    // const state = { selectedProtocol: protocol, editMode: false };
    // navigate(`/${protocol.nickname}/protocol-detail`, { state });
  };

  const handleBack = () => {
    sessionStorage.removeItem('DataList-' + location.pathname);
    navigate(`/database`, { state: department });
  };

  const headers: Header<WeightObject>[] = useMemo(
    () => [
      {
        key: 'input',
        name: '',
        sortable: false,
        flex: 0.25,
        render: (item: WeightObject) => {
          return (
            <div
              style={{ display: 'flex', alignItems: 'center', height: '100%' }}
              onClick={(e: any) => {
                e.stopPropagation();
              }}
            >
              <HMCheckbox
                checked={selectedItems.some(
                  (selected) => selected.id === item.id
                )}
                onChange={() => handleSelectionChange(item)}
              />
            </div>
          );
        },
      },
      {
        key: 'name',
        name: 'Name',
        sortable: true,
        flex: 2,
      },
      {
        key: 'modified_date',
        name: 'Modified Date',
        sortable: true,
        flex: 1,
        render: (item: WeightObject) =>
          getFormattedDate(
            item?.updatedAt ? new Date(item.updatedAt) : new Date(),
            true
          ),
      },
      {
        key: 'modified_by',
        name: 'Modified By',
        sortable: true,
        flex: 1,
        render: (item: WeightObject) => getModifiedByName(item),
      },
      {
        key: 'department_subs',
        name: 'Department Subs',
        sortable: true,
        flex: 1,
        render: (item: WeightObject) => {
          const count = item.pairedDepIDs?.length ?? 0;
          const total = Math.max(1, (allSubDeps?.length ?? 0) + 1);
          console.log(count, total);
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span>{Math.min(total, count) + '/' + total}</span>
            </div>
          );
        },
      },
      {
        key: 'weight',
        name: 'Weight',
        sortable: true,
        flex: 1,
        render: (item: WeightObject) => (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              flex: 1,
              marginLeft: '0.5rem',
            }}
          >
            <span>
              {item.weight} lbs
              {' (' + (item.weight * 0.453592).toFixed(2) + ' kg)'}
            </span>
            <FaChevronRight className="icon-normal" style={{ margin: '4px' }} />
          </div>
        ),
      },
    ],
    [department, selectedItems, allSubDeps]
  );

  const handleSortItem = (
    key: string,
    direction: string,
    a: WeightObject,
    b: WeightObject
  ) => {
    if (key === 'name') {
      return direction === 'asc'
        ? a.name.localeCompare(b.name)
        : b.name.localeCompare(a.name);
    } else if (key === 'modified_date') {
      const aDate = new Date(a.updatedAt ?? new Date());
      const bDate = new Date(b.updatedAt ?? new Date());
      return direction === 'asc'
        ? aDate.getTime() - bDate.getTime()
        : bDate.getTime() - aDate.getTime();
    } else if (key === 'modified_by') {
      const aName = getModifiedByName(a);
      const bName = getModifiedByName(b);
      return direction === 'asc'
        ? aName.localeCompare(bName)
        : bName.localeCompare(aName);
    } else if (key === 'department_subs') {
      const aCount = a.pairedDepIDs?.length ?? 0;
      const bCount = b.pairedDepIDs?.length ?? 0;
      return direction === 'asc' ? aCount - bCount : bCount - aCount;
    } else if (key === 'weight') {
      return direction === 'asc' ? a.weight - b.weight : b.weight - a.weight;
    }
    return 0;
  };

  const handleSelectAll = (
    e: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    if (checked) {
      setSelectedItems([
        ...objects.filter((item) => department.id === item.departmentID),
      ]);
    } else {
      setSelectedItems([]);
    }
  };

  // Helper function for getting modified by name
  const getModifiedByName = (item: WeightObject) => {
    const id = item.modifiedBy || item.createdBy;
    if (id) {
      const user = database.users.find((user) => user.id === id);
      if (user) {
        return `${user.firstName} ${user.lastName}`;
      }
    }
    return 'Hinckley Medical';
  };

  const handleSelectionChange = (weightObject: WeightObject) => {
    if (selectedItems.find((item) => item.id === weightObject.id)) {
      setSelectedItems((prevItems: WeightObject[]) => {
        const updatedItems = prevItems.filter(
          (item: WeightObject) => item.id !== weightObject.id
        );

        if (updatedItems.length === 0) {
          setAllCheckedBtn(false);
        }
        return updatedItems;
      });
    } else {
      let items = [...selectedItems, weightObject];
      let available = objects.filter(
        (item) => department.id === item.departmentID
      );
      setSelectedItems(items);
      if (items.length === available.length) {
        setAllCheckedBtn(true);
      } else if (allCheckedBtn) {
        setAllCheckedBtn(false);
      }
    }
  };

  const deleteNextMedication = async () => {
    try {
      // If there are no items left, exit the function
      if (selectedItems.length === 0) {
        setIsDelete(false);
        return;
      }

      // Get the current medication to delete (the first item in the array)
      const currentMedication = selectedItems[0];

      // Delete the medication
      let response = await deleteWeightObject(currentMedication);
      if (response.type === ResponseType.Success) {
        setSnackbar({
          ...snackbar,
          message: `Successfully deleted Subtracted Weight: ${currentMedication.name}`,
          severity: 'success',
          open: true,
        });

        // Remove the successfully deleted medication from the selectedItems array
        setSelectedItems((prevItems: WeightObject[]) => {
          const updatedItems = prevItems.filter(
            (item: WeightObject) => item.id !== currentMedication.id
          );
          // If no items are left after deletion, close the modal
          if (updatedItems.length === 0) {
            setIsDelete(false);
          }
          return updatedItems;
        });
      } else {
        setSnackbar({
          ...snackbar,
          message: 'Failed to delete Subtracted Weight',
          severity: 'error',
          open: true,
        });
      }

      // Reload the database and check if there are any items left
      reloadDatabase();
    } catch (error) {
      setSnackbar({
        ...snackbar,
        message: 'Failed to delete Subtracted Weight',
        severity: 'error',
        open: true,
      });
    }
  };

  const handleConfirmDeleteMedications = async () => {
    if (allCheckedBtn) {
      try {
        // Initialize counters for success and failure
        let successCount = 0;
        let failureCount = 0;

        for (const parmMedication of selectedItems) {
          let response = await deleteWeightObject(parmMedication);
          if (response.type === ResponseType.Success) {
            successCount++;
          } else {
            failureCount++;
          }
        }

        // Set snackbar message based on the results
        if (successCount > 0) {
          setSnackbar({
            ...snackbar,
            message:
              `Successfully deleted ${successCount} Subtracted Weight` +
              (successCount > 1 ? 's' : '') +
              '.',
            severity: 'success',
            open: true,
          });
        }
        if (failureCount > 0) {
          setSnackbar({
            ...snackbar,
            message:
              `Failed to delete ${failureCount} Subtracted Weight` +
              (failureCount > 1 ? 's' : '') +
              '.',
            severity: 'error',
            open: true,
          });
        }

        // Clear selectedItems after all deletions
        setSelectedItems([]);
        reloadDatabase();
      } catch (error) {
        setSnackbar({
          ...snackbar,
          message: 'Failed to delete Subtracted Weight.',
          severity: 'error',
          open: true,
        });
      }
      setIsDelete(false); // Close modal after all deletions
    } else {
      deleteNextMedication(); // Call the refactored function
    }
  };

  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const getRowClassName = useCallback(
    (params: WeightObject) => {
      const isSelected = selectedItems.some((item) => item.id === params.id);
      if (isSelected) return 'selectedRow';
      // Default return for other cases
      return '';
    },
    [selectedItems]
  );

  return (
    <div className="screen-container">
      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
      {isDelete && (
        <ConfirmModal
          isVisible={isDelete}
          title={'Delete Weights?'}
          handleClose={() => {
            setIsDelete(false);
          }}
          handleSubmit={handleConfirmDeleteMedications}
          isDeleteBtn={true}
          primaryBtnName="Cancel"
          secondaryBtnName="Delete"
          primaryDescription={
            allCheckedBtn
              ? `Are you sure you would like to delete ${selectedItems.length} weights?`
              : `Are you sure you would like to delete ${selectedItems[0].name}?`
          }
          isSelectAllBtn={selectedItems.length > 1}
          handleCheckAll={(check: any) => {
            setAllCheckedBtn(check);
          }}
        />
      )}
      <ProtocolHeader
        // homeScreen={true}
        isBackButton={true}
        handleCancel={handleBack}
        name={
          selectedItems.length === 0
            ? 'Subtractable Weights: ' + list.length + ' items'
            : 'Subtractable Weights: ' +
              selectedItems.length +
              ' / ' +
              list.length +
              ' items'
        }
        page={department.name}
        rightSideBtn={'edit'}
        isEditButton={false}
        isCreateButton={true}
        isCreateActive={true}
        handleCreate={handleCreateItem}
        handleEdit={() => {}}
        isDeleteButton={selectedItems.length > 0}
        isDeleteDisabled={selectedItems.length === 0}
        handleDelete={() => setIsDelete(true)}
        type={'protocol'}
      />

      <DataList<WeightObject, never>
        items={list}
        headers={headers}
        onItemClick={handleItemClick}
        onSelectionChange={setSelectedItems}
        searchPlaceholder="Search Weight Objects..."
        sortItem={handleSortItem}
        getRowClassName={getRowClassName}
        handleSelectAll={handleSelectAll}
        selectedItems={selectedItems}
        searchFilter={(item, searchQuery) => {
          return (
            item.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            (user.type === 'ADMIN' &&
              item.id.toLowerCase().includes(searchQuery.toLowerCase()))
          );
        }}
      />
    </div>
  );
};

export default ListWeightObjects;
