/*
1-2-24: Abhishek: showOnlySideout is added as a prop that makes this component only show the sideout [MedicationDoseSideout] and not the main page to avoid redundancy
*/
import { useFormik } from 'formik';
import { InputText } from 'primereact/inputtext';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { HiPencil, HiPlus } from 'react-icons/hi';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ViewportList } from 'react-viewport-list';
import * as Yup from 'yup';
import { handleGetDepartment } from '../../../../../store/actions';
import AddRouteModal from '../../../../components/Modal/AddRouteModal';
import ConcentrationDialog from '../../../../components/Modal/ConcentrationDialogModal';
import ConfirmModal from '../../../../components/Modal/ConfirmModal';
import ConfirmWarningModal from '../../../../components/Modal/ConfirmWarningModal';
import DraftSaveModal from '../../../../components/Modal/DraftSaveModal';
import * as _ from 'lodash';
import {
  findDepartmentOwner,
  getActiveID,
  getDepartmentsFromState,
  getFormattedDate,
  globals,
  handleCopy,
  hasAdminUserAccess,
  isObjectEqual,
  upgradeVersion,
} from '../../../../_global/common/Utils';
import {
  DatabaseResponse,
  Response,
  ResponseType,
  loadDatabase,
} from '../../../../../data/AmplifyDB';
import InfusionItem, {
  cloneInfusion,
} from '../../../../../data/model/InfusionItem';
import {
  Concentration,
  MedicationConcentration,
  MedicationProtocol,
  User,
} from '../../../../../models';
import ProtocolItem from '../../../../../data/model/ProtocolItem';
import InfusionSubItem, {
  cloneInfusionSubItem,
} from '../../../../../data/model/InfusionSubItem';
import { isDraftCreated } from '../../../../../data/AmplifyVersion';
import ProtocolHeader from '../../ProtocolHeader';
import { ProgressStatus } from '../../../../../models';
import {
  InfusionDoseJSON,
  InfusionJSON,
  createInfusionDoseItem,
  createParentInfusion,
  deleteInfusionDoseItem,
  deleteInfusionItem,
  duplicateInfusionDose,
  duplicateProtocolInfusionDoses,
  fetchDrips,
  updateConcentrations,
} from '../../../../../data/functions/InfusionDB';
import { Col, Row } from 'react-bootstrap';
import { UserType } from '../../../../../models';
import InfusionDoseSideout from '../../../../components/SideOut/doseSideOut/InfusionDoseSideout';
import { FaChevronDown, FaChevronRight } from 'react-icons/fa6';
import { BiCopy, BiSolidCopy } from 'react-icons/bi';
import { getConcentrationString } from '../../../../../data/model/MedicationItem';
import InfusionDoseUI from './InfusionDoseUI';
import DepartmentItem from '../../../../../data/model/DepartmentItem';
import AccordionItem from '../../../../components/Accordion/AccordionItem';
import { auditInfusionDosesToOldDBStyle } from '../../../../../data/AmplifyActions';
import {
  handleToggleEnabled,
  isModelItemLockedInReviewal,
  propagateConcentrations,
} from '../../../../../data/functions/ModelDB';
import { InputSwitch } from 'primereact/inputswitch';
import PatientAge from '../../../../_global/common/PatientAge';
import CopyToClipboard from '../../../../components/CopyToClipboard';
import { FaCheckCircle } from 'react-icons/fa';
import ReviewalItem from '../../../../../data/model/ReviewalItem';
import SelectDepartmentsModal from '../../../../components/Modal/SelectDepartmentsModal';
import { Alert, Snackbar } from '@mui/material';
import DraftChangeItem from '../../../../../data/model/DraftChangeItem';
import { handleLoadReviewAndNavigate } from '../../../../../data/functions/ReviewalDB';
import Loading from '../../../../components/Loading/Loading';

interface InfusionProtocolEditPageProps {
  stateData?: any;
  handleCloseSideout?: () => void;
  showOnlySideout?: boolean;
}
/* 10-24-23  Created Infusion Edit Page component for Infusion layout */
const InfusionProtocolEditPage: React.FC<InfusionProtocolEditPageProps> = ({
  stateData,
  handleCloseSideout,
  showOnlySideout,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const reducerState = useSelector((state: any) => state.department);
  const {
    selectedProtocol,
    value,
    subValue,
    type,
    editType,
    editMode,
    page,
    showEditDose,
    reviewal,
    currentDraft,
  } = stateData;
  const [protocol, setProtocol] = useState<ProtocolItem>(selectedProtocol);

  const [database, setDatabase] = useState<DatabaseResponse>(
    useSelector((state: any) => state.protocol.departmentItem)
  );
  const department = database.department;
  const { allSubDeps } = useMemo(
    () => getDepartmentsFromState(department, reducerState),
    [department, reducerState]
  );
  const user: User = useSelector((state: any) => state?.user);

  const adminLevel = useMemo(() => {
    return database.department.adminLevel;
  }, [database.department]);

  // const parmInfusion: InfusionItem = protocolDetail;
  const [parmInfusion, setParmInfusion] = useState<InfusionItem>(value);
  const [isCopied, setIsCopied] = useState<string | null>(null);

  const [draftChangeInReview, setDraftChangeInReview] = useState<
    DraftChangeItem | null | undefined
  >(parmInfusion.draftChange);

  const [allSubInfusionItems, setAllSubInfusionItems] = useState<
    InfusionSubItem[]
  >(value.subItems);

  const subInfusionItems = useMemo(() => {
    return selectedProtocol
      ? allSubInfusionItems.filter(
          (item: InfusionSubItem) =>
            item.parentProtocol.uid === selectedProtocol.uid
        )
      : allSubInfusionItems;
  }, [allSubInfusionItems, selectedProtocol]);

  const numDeactivated = useMemo(() => {
    return subInfusionItems.filter(
      (item) => item.status === ProgressStatus.DEACTIVATED
    ).length;
  }, [subInfusionItems]);
  const [showDeactivated, setShowDeactivated] = useState(false);
  // Save the initial deactivation status in a ref
  const initialDeactivationStatusIDs = useRef<string[]>([]);

  const [itemDeactivationStatus, setItemDeactivationStatus] = useState<
    InfusionSubItem[]
  >([]);
  const [selectedItemDeactivationStatus, setSelectedItemDeactivationStatus] =
    useState<InfusionSubItem[] | null>(null);

  const allProtocols = useMemo(() => {
    let prots = allSubInfusionItems.map((m) => m.parentProtocol);
    //Remove all the duplicates
    return prots
      .filter((v, i, a) => a.findIndex((t) => t.uid === v.uid) === i)
      .sort((a, b) => {
        if (a.parent.index === b.parent.index)
          return a.name.localeCompare(b.name);
        return a.parent.index - b.parent.index;
      });
  }, [allSubInfusionItems]);

  const departmentOwner = useMemo(() => {
    return findDepartmentOwner(department, reducerState, parmInfusion);
  }, [department, reducerState, parmInfusion]);
  const adminAccess = useMemo(() => {
    return hasAdminUserAccess(department, reducerState, user);
  }, [department, reducerState, user]);
  const isOwner = useMemo(() => {
    return departmentOwner?.id === department.id;
  }, [departmentOwner, department]);

  // const [selectedConcentration, setSelectedConcentration] = useState<MedicationConcentration | null>(null);
  const [loading, setLoading] = useState<string | null>(null);
  const [isBlockModal, setIsBlockModal] = useState(false);
  const [isWarningModal, setIsWarningModal] = useState(false);
  const [isCancelWarningModal, setIsCancelWarningModal] = useState(false);
  const [isEditMode, setIsEditMode] = useState(
    !draftChangeInReview && adminAccess && editMode
  );
  const [isAddRouteVisible, setIsAddRouteVisible] = useState(false);

  const [sidebarVisible, setSidebarVisible] = useState(
    showOnlySideout ? true : false
  );
  const [selectedDose, setSelectedDose] = useState<InfusionSubItem | null>(
    showEditDose && subValue ? subValue : null
  );
  const [expanded, setExpanded] = useState<string[]>([]);

  const [editDose, setEditDose] = useState(showEditDose ? true : false);
  const [concentrationDialogVisible, setConcentrationDialogVisible] =
    useState(false);
  const [isConfirmModal, setIsConfirmModal] = useState(false);
  const [modalType, setModalType] = useState('');

  const [deleteData, setDeleteData] = useState<any>(null);
  const [isWarning, setIsWarning] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isDraftConfirmModal, setIsDraftConfirmModal] = useState(false);

  const [isDelete, setIsDelete] = useState(false);
  const [doseIndex, setDoseIndex] = useState(subInfusionItems.length);
  const [modifiedBy, setModifiedBy] = useState<User | null>(null);

  const [selectDepartmentsModal, setSelectDepartmentsModal] = useState(false);
  const [snackbar, setSnackbar] = useState<any>({
    open: false,
    message: '',
    severity: 'success',
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: parmInfusion ? parmInfusion.name : '',
      concentration: parmInfusion ? parmInfusion.concentrations : [],
      routes: parmInfusion ? parmInfusion.routes : [],
      rangeLow: parmInfusion ? parmInfusion.rangeLow + '' : '',
      rangeHigh: parmInfusion ? parmInfusion.rangeHigh + '' : '',
      dripOptions:
        parmInfusion && parmInfusion.model
          ? parmInfusion.model.dripOptions
          : [],

      contraindication:
        parmInfusion &&
        parmInfusion.model &&
        parmInfusion.model.contraindication
          ? parmInfusion.model.contraindication
          : '',
      note:
        parmInfusion && parmInfusion.model && parmInfusion.model.note
          ? parmInfusion.model.note
          : '',
      warning:
        parmInfusion && parmInfusion.model && parmInfusion.model.warning
          ? parmInfusion.model.warning
          : '',
      medClass:
        parmInfusion && parmInfusion.model && parmInfusion.model.medClass
          ? parmInfusion.model.medClass
          : '',
      action:
        parmInfusion && parmInfusion.model && parmInfusion.model.action
          ? parmInfusion.model.action
          : '',
      indication:
        parmInfusion && parmInfusion.model && parmInfusion.model.indication
          ? parmInfusion.model.indication
          : '',
      interaction:
        parmInfusion && parmInfusion.model && parmInfusion.model.interaction
          ? parmInfusion.model.interaction
          : '',
      onset:
        parmInfusion && parmInfusion.model && parmInfusion.model.onset
          ? parmInfusion.model.onset
          : '',
      duration:
        parmInfusion && parmInfusion.model && parmInfusion.model.duration
          ? parmInfusion.model.duration
          : '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required(),
      concentration: Yup.array().min(
        1,
        'At least one concentration is required'
      ),
      routes: Yup.array().min(1, 'At least one route is required'),
      rangeLow: Yup.number(),
      rangeHigh: Yup.number(),
      dripOptions: Yup.array(),
      contraindication: Yup.string(),
      note: Yup.string(),
      warning: Yup.string(),
      medClass: Yup.string(),
      action: Yup.string(),
      indication: Yup.string(),
      interaction: Yup.string(),
      onset: Yup.string(),
      duration: Yup.string(),
    }),
    onSubmit: async (values) => {},
  });

  /* After reloading the database update the infusion item */
  useEffect(() => {
    if (reviewal == null) reloadDatabase();
  }, [reviewal]);

  useEffect(() => {
    if (value) {
      setParmInfusion(value);
      setAllSubInfusionItems(value.subItems);
      formik.resetForm();
    }
  }, [value]);

  function isConcenChanged(values: any) {
    let concenChanged = false;
    if (values.concentration.length !== parmInfusion.concentrations.length)
      return true;
    for (let i = 0; i < values.concentration.length; i++) {
      let concen = values.concentration[i];
      let found = parmInfusion.concentrations.find((c: Concentration) =>
        isObjectEqual(c, concen)
      );
      if (!found) {
        concenChanged = true;
        break;
      }
    }
    return concenChanged;
  }
  useEffect(() => {
    const initialStatus = subInfusionItems.filter(
      (item) => item.status === ProgressStatus.DEACTIVATED
    );
    initialDeactivationStatusIDs.current = initialStatus.map(
      (item: InfusionSubItem) => item.uid
    );
    setItemDeactivationStatus(initialStatus);
  }, [subInfusionItems]);

  /**
   * Checks if the item is locked in an ACTIVE Reviewal Process.
   */
  useEffect(() => {
    if (parmInfusion.draftChange != null) {
      setDraftChangeInReview(parmInfusion.draftChange);
    } else {
      isModelItemLockedInReviewal(parmInfusion, database).then(
        (draftChange: DraftChangeItem | null) => {
          setDraftChangeInReview(draftChange);
        }
      );
    }
  }, [database, parmInfusion]);

  /* Hotkey detection */
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (adminAccess && event.ctrlKey && event.key === 'e') {
        event.preventDefault();
        handleCheckIsDraft();
      } else if (adminAccess && event.ctrlKey && event.key === 's') {
        event.preventDefault();
        formik.submitForm();
      } else if (event.ctrlKey && event.key === 'b') {
        event.preventDefault();
        handleBack();
      } else if (
        adminAccess &&
        event.ctrlKey &&
        event.shiftKey &&
        event.key === 'c' &&
        isEditMode
      ) {
        event.preventDefault();
        setConcentrationDialogVisible(true);
      } else if (event.ctrlKey && event.shiftKey && event.key === 'O') {
        console.log('INFUSION', parmInfusion);
      }
    };

    // Add event listener
    document.addEventListener('keydown', handleKeyDown);

    // Remove event listener on cleanup
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [adminAccess, isEditMode, parmInfusion]);

  useEffect(() => {
    let id = parmInfusion.model?.modifiedBy;
    if (id == null) id = parmInfusion.model?.createdBy;
    if (id) {
      let user = database.users.find((u: User) => u.id === id);
      setModifiedBy(user ? user : null);
      if (user) {
        parmInfusion.modifiedBy = user;
      }
    } else {
      setModifiedBy(null);
    }
  }, [database, parmInfusion]);

  const solidUnits = useMemo(() => {
    if (parmInfusion.concentrations?.length > 0) {
      let concen = parmInfusion.concentrations[0];
      return concen.firstUnit;
    }
    return '';
  }, [parmInfusion]);

  async function handleSave(
    values: any,
    isConfirmConcenPropagation: boolean = false,
    propogateSubDeps: DepartmentItem[] = []
  ) {
    if (globals.debug) console.log('SUBMITTING INFUSION', values);
    /* First check if the medication has changed */
    if (
      formik.dirty &&
      formik.isValid &&
      values.concentration.length > 0 &&
      values.routes.length > 0
    ) {
      const isConcenUpdated = isConcenChanged(values);
      const isOwner = department.id === parmInfusion.departmentID;
      if (!isOwner) {
        if (isConcenUpdated) {
          console.log('CONCENTRATION ONLY CHANGED - UPDATE IT');
          let resp = await updateConcentrations(
            department,
            parmInfusion,
            values.concentration
          );
          if (resp.type === ResponseType.Success) {
            parmInfusion.concentrations = values.concentration;
            formik.resetForm();
            return;
          } else {
            console.error('Failed to update concentrations', resp.data);
          }
        }
      } else if (
        !isConfirmConcenPropagation &&
        isConcenUpdated &&
        department.isMultiDep &&
        allSubDeps.length > 0
      ) {
        setSelectDepartmentsModal(true);
        return;
      }
      const protocolList = findProtocols(values.dripOptions);

      let activeID =
        parmInfusion.status === ProgressStatus.ACTIVE
          ? parmInfusion.uid
          : parmInfusion.activeID;
      let overrideID = undefined;
      if (!isOwner) {
        overrideID = activeID;
        activeID = undefined;
      }
      const newInfusion: InfusionJSON = {
        name: values.name,
        concentration: values.concentration,
        routes: values.routes,
        rangeLow: !isNaN(Number(values.rangeLow)) ? Number(values.rangeLow) : 0,
        rangeHigh: !isNaN(Number(values.rangeHigh))
          ? Number(values.rangeHigh)
          : globals.MAX_VALUE,
        departmentID: department.id,
        dripOptions: values.dripOptions,
        taggedProtocols: protocolList,

        contraindication: values.contraindication,
        note: values.note,
        warning: values.warning,
        medClass: values.medClass,
        action: values.action,
        indication: values.indication,
        interaction: values.interaction,
        onset: values.onset,
        duration: values.duration,

        createdBy:
          parmInfusion.model && parmInfusion.model.createdBy
            ? parmInfusion.model.createdBy
            : '',
        modifiedBy: user.id,
        status: ProgressStatus.DRAFT,
        activeID: activeID,
        overrideID: overrideID,
        version:
          parmInfusion.status === ProgressStatus.ACTIVE
            ? upgradeVersion(
                parmInfusion.version == null ? 'v1.0.0' : parmInfusion.version
              )
            : parmInfusion.version == null
              ? 'v1.0.0'
              : parmInfusion.version,
      };

      let results: Response = await createParentInfusion(
        department,
        newInfusion,
        parmInfusion
      );
      if (results.type === ResponseType.Success) {
        if (globals.debug)
          console.log('Successfully created infusion', results.data);
        let newInfusion: InfusionItem = results.data;
        updateInfusion(newInfusion);

        if (isConfirmConcenPropagation) {
          propagateConcentrations(
            department,
            formik.values.concentration,
            newInfusion,
            propogateSubDeps
          ).then((resp) => {
            if (resp.type === ResponseType.Success) {
              setSnackbar({
                open: true,
                message:
                  'Successfully Updated ' +
                  newInfusion.name +
                  ' and Propogated Concentrations to ' +
                  propogateSubDeps.length +
                  ' Departments',
                severity: 'success',
              });
            } else {
              console.error('Failed to propogate concentrations', resp.data);
              setSnackbar({
                open: true,
                message:
                  'Failed to Propogate ' + newInfusion.name + ' Concentrations',
                severity: 'error',
              });
            }
          });
        } else {
          setSnackbar({
            open: true,
            message: 'Successfully Updated ' + newInfusion.name,
            severity: 'success',
          });
        }
      } else console.error('Failed to create infusion', results.data);

      formik.resetForm();
    }
  }

  /**
   * Find the protocols from the protocol options
   * @param protocolOptions The protocol options to search for
   * @returns The protocols that were found
   */
  const findProtocols = (
    protocolOptions: MedicationProtocol[]
  ): ProtocolItem[] => {
    let protocols: ProtocolItem[] = [];
    for (let i = 0; i < protocolOptions.length; i++) {
      let po = protocolOptions[i];
      let protocol = database.protocols.find((p: ProtocolItem) => {
        let id =
          p.status === ProgressStatus.ACTIVE ||
          (p.status === ProgressStatus.DRAFT && p.activeID == null)
            ? p.uid
            : p.activeID;
        return id === po.protocolID;
      });
      if (protocol) protocols.push(protocol);
    }
    return protocols;
  };

  /* After reloading the database update the infusion item */
  // useEffect(() => {
  //   /* Search for the updated infusion item in the database */
  //   if (globals.debug) console.log('Searching for updated infusion item');
  //   let updatedInfusion: InfusionItem | null = null;
  //   for (let i = 0; i < database.infusions.length; i++) {
  //     if (database.infusions[i].uid === parmInfusion.uid) {
  //       updatedInfusion = database.infusions[i];
  //       break;
  //     }
  //   }

  //   /* Search for the right sub infusions */
  //   if (updatedInfusion) {
  //     if (globals.debug) console.log('Found updated infusion', updatedInfusion);
  //     setParmInfusion(updatedInfusion);
  //     setAllSubInfusionItems(updatedInfusion.subItems);
  //   } else if (globals.debug) console.log('Failed to find updated infusion');

  //   /* Also update the protocol */
  //   if (protocol) {
  //     let updatedProtocol: ProtocolItem | null = null;
  //     for (let i = 0; i < database.protocols.length; i++) {
  //       if (database.protocols[i].uid === protocol.uid) {
  //         updatedProtocol = database.protocols[i];
  //         break;
  //       }
  //     }

  //     /* Set the updated protocol to the useState */
  //     if (updatedProtocol) {
  //       if (globals.debug)
  //         console.log('Found updated protocol', updatedProtocol);
  //       setProtocol(updatedProtocol);
  //     } else if (globals.debug) console.log('Failed to find updated protocol');
  //   }
  // }, [database]);

  /**
   * Reload the database from the server
   * @returns true when the database is reloaded
   */
  const reloadDatabase = async (parmInfusID?: string): Promise<boolean> => {
    /* 1-10-24 Hazlett:  Update the current data to the database change and keep the current state */
    const resp: Response = await fetchDrips(
      database.department,
      database,
      true,
      true,
      true
    );
    if (resp.type === ResponseType.Success) {
      let infusions = resp.data as InfusionItem[];
      dispatch<any>(
        handleGetDepartment({
          ...database,
          infusions: infusions,
        } as DatabaseResponse)
      );

      /* Search for the updated medication item in the database - ALWAYS search for activeID */
      let updatedInfusion: InfusionItem | null = null;
      let infusionID = getActiveID(parmInfusion);
      for (let i = 0; i < infusions.length; i++) {
        let infusID = getActiveID(infusions[i]);
        if (infusID === infusionID) {
          updatedInfusion = infusions[i];
          break;
        }
      }

      /* Search for the right sub medications */
      if (updatedInfusion) {
        if (globals.debug)
          console.log('Found updated infusion', updatedInfusion);
        setParmInfusion(updatedInfusion);
        formik.resetForm();
        setAllSubInfusionItems(updatedInfusion.subItems);
      } else if (globals.debug) console.log('Failed to find updated infusion');
      return true;
    } else {
      console.error('ERROR LOADING DATABASE', resp.data);
      return false;
    }
  };

  /**
   * Todo have this open up a sideout to view the history of the infusion
   */
  const handleVersion = () => {
    /* Output why the formik is dirty in the console */
  };

  /**
   * Back button has been pressed
   */
  const handleBack = () => {
    if (formik.dirty && !isWarningModal) setIsWarningModal(true);
    else if (protocol) {
      const state = { selectedProtocol: protocol, editMode: isEditMode };
      const formattedString = protocol.nickname.replace(/[\s\/]/g, '-');
      navigate(`/${formattedString}/protocol-detail`, { state });
    } else if (reviewal) {
      navigate('/reviewChanges', {
        state: {
          data: reviewal,
          activeDraftChange: currentDraft,
        },
      });
    } else {
      navigate(`/database/list-infusions`, {
        state: {
          department: department,
          data: database.infusions,
          database: database,
        },
      });
    }
  };

  /**
   * Cancen button has been pressed reset the formik values
   */
  const handleCancel = () => {
    /* Check if the formik is dirty and if the warning modal is not open */
    if (formik.dirty && !isCancelWarningModal) setIsCancelWarningModal(true);
    /* If the formik is not dirty or abandon has been pressed then reset it */ else {
      formik.resetForm();
      setIsEditMode(false);
      setIsCancelWarningModal(false);
      if (formik.values.dripOptions.length === 0) handleBack();

      /* Reset the sub medication items */
      setAllSubInfusionItems(value.subItems);
    }
  };

  /* 10/11/2023: Praveen, Function to handle on Add Dose click*/
  const onAddDoseClick = () => {
    if (isEditMode) {
      setSelectedDose(null);
      setEditDose(false);
      setSidebarVisible(!sidebarVisible);
    }
  };

  /*function to close the modal*/
  const handleCloseModal = (e: any) => {
    setSidebarVisible(false);
    setSelectedDose(null);
    handleCloseSideout && handleCloseSideout();
  };

  /* 11-01-23 Praveen: handled function to remove concentration values to show confirm popup*/
  const handleRemoveConcentration = (e: any, item: MedicationConcentration) => {
    e.stopPropagation();
    setModalType('Concentration');
    setDeleteData(item);
    setIsConfirmModal(true);
  };

  /* 11-01-23 Praveen: handled function to remove route values to show confirm popup*/
  const handleRemoveRoute = (e: any, value: any) => {
    e.stopPropagation();
    setModalType('Route');
    setIsConfirmModal(true);
    setDeleteData(value);
  };

  const updateInfusion = async (newInfusion: InfusionItem) => {
    if (globals.debug) console.log('Upgraded infusion rotues:', newInfusion);
    reloadDatabase(
      newInfusion.status === ProgressStatus.DRAFT &&
        newInfusion.activeID != null
        ? newInfusion.activeID
        : newInfusion.uid
    );

    formik.setValues({
      ...formik.values,
      name: newInfusion?.name ? newInfusion?.name : '',
      concentration: newInfusion?.concentrations?.length
        ? newInfusion?.concentrations
        : [],
      routes: newInfusion?.routes ? newInfusion?.routes : [],
      rangeLow: newInfusion?.rangeLow ? newInfusion?.rangeLow + '' : '',
      rangeHigh: newInfusion?.rangeHigh ? newInfusion?.rangeHigh + '' : '',
      dripOptions: newInfusion?.model?.dripOptions
        ? newInfusion?.model?.dripOptions
        : [],
      contraindication: newInfusion?.model?.contraindication
        ? newInfusion?.model?.contraindication
        : '',
      note: newInfusion?.model?.note ? newInfusion?.model?.note : '',
      warning: newInfusion?.model?.warning ? newInfusion?.model?.warning : '',
      medClass: newInfusion?.model?.medClass
        ? newInfusion?.model?.medClass
        : '',
      action: newInfusion?.model?.action ? newInfusion?.model?.action : '',
      indication: newInfusion?.model?.indication
        ? newInfusion?.model?.indication
        : '',
      interaction: newInfusion?.model?.interaction
        ? newInfusion?.model?.interaction
        : '',
      onset: newInfusion?.model?.onset ? newInfusion?.model?.onset : '',
      duration: newInfusion?.model?.duration
        ? newInfusion?.model?.duration
        : '',
    });
  };

  /* 11-01-23 Praveen: handled function to add new administration route*/
  const handleNewRouteSet = async (value: string) => {
    setIsAddRouteVisible(false);
    let routes = [...formik.values.routes, value];
    formik.setFieldValue('routes', routes);
  };

  /* 11-01-23 Praveen: handled function to delete concentartion and route values */
  const handleConfirmDelete = async (type: any) => {
    setIsConfirmModal(false);
    if (type === 'Route') {
      if (globals.debug)
        console.log(
          'Removing route',
          value,
          'from infusion',
          formik.values.name
        );
      let routes = formik.values.routes.filter((r: string) => r !== deleteData);
      formik.setFieldValue('routes', routes);
    } else if (type === 'Concentration') {
      if (globals.debug)
        console.log(
          'Removing concentration',
          value,
          'from infusion',
          formik.values.name
        );
      let concentrations = formik.values.concentration.filter(
        (c: MedicationConcentration) => {
          return (
            c.firstAmnt !== deleteData.firstAmnt ||
            c.secAmnt !== deleteData.secAmnt ||
            c.firstUnit !== deleteData.firstUnit ||
            c.secUnit !== deleteData.secUnit
          );
        }
      );
      formik.setFieldValue('concentration', concentrations);
    }
  };

  /* 11-01-23 Praveen: To set concentration popup value */
  const handleConcentrationSet = async (
    amount1: any,
    amount2: any,
    unit1: any,
    unit2: any
  ) => {
    setConcentrationDialogVisible(false);
    let newConcentration: MedicationConcentration = {
      firstAmnt: amount1,
      secAmnt: amount2,
      firstUnit: unit1,
      secUnit: unit2,
    };

    formik.setFieldValue('concentration', [
      ...formik.values.concentration,
      newConcentration,
    ]);
  };

  const handleRouteEdit = (event: any) => {
    if (!isEditMode || !isOwner || adminLevel < 3) return;
    event.stopPropagation();
    setIsAddRouteVisible(true);
  };

  /* 11-01-23 Praveen: To set concentration popup value */
  const handleConcentrationEdit = (event: any) => {
    if (!isEditMode) return;
    event.stopPropagation();
    setConcentrationDialogVisible(true);
  };

  // set edit protocol
  const handleMakeNew = () => {
    setIsDraftConfirmModal(false);
    setIsOpen(false);
    setIsEditMode(true);
  };

  const handleOnSubmitInfusionDose = async (
    data: any,
    previousDose: InfusionSubItem | undefined,
    parentProtocol: ProtocolItem | undefined
  ) => {
    const protocol = parentProtocol ? parentProtocol : selectedProtocol;
    let protocolID =
      protocol.status === ProgressStatus.ACTIVE || protocol.activeID == null
        ? protocol.uid
        : protocol.activeID;

    if (!protocolID) {
      console.error('Failed to find protocol ID');
      return;
    }

    let prevDose = previousDose as InfusionSubItem;
    const isOwner = prevDose ? department.id === prevDose.departmentID : true;
    let index = prevDose
      ? prevDose.index
      : (protocol as ProtocolItem).infusions.length;
    if (department.parentDep?.parentDep) index += 10000;
    else if (department.parentDep) index += 1000;

    /* Create a new InfusionDose for the medicaiton */
    let newMedDose: InfusionDoseJSON = {
      departmentID: department.id,
      infusion: parmInfusion,
      protocol: protocol,
      rangeLow: data.rangeLow ? Number(data.rangeLow) : 0,
      rangeHigh: data.rangeHigh ? Number(data.rangeHigh) : globals.MAX_VALUE,
      createdBy: user.id,
      basis: data.basis,
      route: data.routes,
      nemsisRoutes: data.nemsisRoutes,
      title: data.title,
      warning: data.warning,
      instruction: data.instruction,
      note: data.note,
      maxDose: data.maxDose,
      minDose: data.minDose,
      maxTotalDose: data.maxTotalDose,
      calcMax: data.calcMax,
      calcMin: data.calcMin,
      index: index,
      ageLow: data.ageLow ? (data.ageLow as PatientAge).ageValue : 0,
      ageHigh: data.ageHigh
        ? (data.ageHigh as PatientAge).ageValue
        : globals.MAX_VALUE,
      ageGroup: data.ageGroup,
      repeatTime: data.repeatTime,

      activeID: !isOwner
        ? undefined
        : prevDose == null
          ? null
          : prevDose.status === ProgressStatus.ACTIVE
            ? prevDose.uid
            : prevDose.activeID,
      overrideID: isOwner ? null : prevDose?.uid,

      status: ProgressStatus.DRAFT,
      version:
        prevDose == null
          ? 'v1.0.0'
          : prevDose.status === ProgressStatus.ACTIVE
            ? upgradeVersion(prevDose.version)
            : prevDose.version,
      createdAt: prevDose?.createdAt ? prevDose.createdAt : new Date(),
    };

    createInfusionDoseItem(newMedDose, prevDose).then((response: Response) => {
      if (response.type === ResponseType.Success) {
        let newMedSubItem = response.data as InfusionSubItem;
        newMedSubItem.activeItem = prevDose ? prevDose : null;
        let allSubMeds = prevDose
          ? allSubInfusionItems.filter(
              (item: InfusionSubItem) => item !== prevDose
            )
          : allSubInfusionItems;
        setAllSubInfusionItems([...allSubMeds, newMedSubItem]);
        // reloadDatabase(parmInfusion.uid);
      }
    });
  };
  const handleCopyProtocolDoses = async (
    toProtocol: ProtocolItem,
    fromProtocol: ProtocolItem
  ) => {
    /* First find the Medication Protocol */
    let response: Response = await duplicateProtocolInfusionDoses(
      department,
      fromProtocol,
      toProtocol,
      user,
      parmInfusion
    );

    /* Get the successes and errors */
    let successes = response.data.successes;
    let errors = response.data.errors;

    let infusions = [...allSubInfusionItems];
    for (let i = 0; i < successes.length; i++) {
      let result: InfusionSubItem = successes[i];
      infusions.push(result);
    }
    setAllSubInfusionItems(infusions);

    if (errors.length > 0) {
      console.error('Failed to duplicate infusion doses', errors);
      setSnackbar({
        open: true,
        message:
          'Failed to duplicate ' +
          parmInfusion.name +
          ' ' +
          errors.length +
          ' doses',
        severity: 'error',
      });
    }
  };

  const handleRemoveInfusionDose = async (
    dose: InfusionSubItem,
    type: 'block' | 'restore' | 'delete'
  ) => {
    let subMeds = [...subInfusionItems];

    if (type === 'block') {
      let resp: Response = await handleToggleEnabled(
        user,
        department,
        reducerState,
        dose
      );
      if (resp.type === ResponseType.Success) {
        console.log('Successfully blocked dose', resp);
        let newDose: InfusionSubItem = resp.data;
        let allDoses = allSubInfusionItems.filter(
          (item: InfusionSubItem) => item !== dose
        );
        setAllSubInfusionItems([...allDoses, newDose]);
        reloadDatabase(parmInfusion.uid);
      }
    } else if (
      /* Use Cases: Created a new dose and deleted it, or deleted an existing dose */
      dose.status === ProgressStatus.DEACTIVATED ||
      dose.status === ProgressStatus.DRAFT
    ) {
      let resp = await deleteInfusionDoseItem(dose, false);
      if (resp.type === ResponseType.Success) {
        let deletedDose: InfusionSubItem = resp.data;

        let allDoses = allSubInfusionItems.filter(
          (item: InfusionSubItem) => item !== dose
        );
        if (
          dose.status === ProgressStatus.DEACTIVATED &&
          deletedDose.overrideItem
        )
          allDoses.push(deletedDose.overrideItem);
        setAllSubInfusionItems([...allDoses]);
        reloadDatabase(parmInfusion.uid);
        auditInfusionDosesToOldDBStyle([deletedDose])
          .then((response) => {
            if (response.type === ResponseType.Success) {
              console.log('Successfully audited doses', response.data);
            }
          })
          .catch((error) => {
            console.error('Failed to audit doses', error);
          });
      }
    }
    // else if (
    //   dose.status === ProgressStatus.DRAFT_DELETE &&
    //   type === 'restore' &&
    //   (dose.activeItem || dose.overrideItem)
    // ) {
    //   let index = subInfusionItems.findIndex(
    //     (item: InfusionSubItem) => item === dose
    //   );

    //   let item = dose.activeItem ? dose.activeItem : dose.overrideItem;

    //   let newMedDose = new InfusionSubItem(
    //     dose.parent,
    //     dose.parentProtocol,
    //     (item as InfusionSubItem).model
    //   );
    //   console.log('Setting new dose', newMedDose, index);
    //   //Replace the deleted dose with the active dose

    //   subMeds[index] = newMedDose;
    //   deleteInfusionDoseItem(dose, false)
    //     .then((resp) => {
    //       console.log('Deleted Dose Resp', resp);
    //       if (resp.type === ResponseType.Success) {
    //         reloadDatabase(parmInfusion.uid);
    //       }
    //     })
    //     .catch((error) => {
    //       console.error('Failed to delete dose', error);
    //     });
    // }
    else {
      let resp = await deleteInfusionDoseItem(dose, true);
      if (resp.type === ResponseType.Success) {
        let deletedDose: InfusionSubItem = resp.data;
        if (globals.debug) console.log('Deleted Dose', deletedDose);
        let allDoses = allSubInfusionItems.filter(
          (item: InfusionSubItem) => item !== dose
        );
        setAllSubInfusionItems([...allDoses]);
        reloadDatabase(parmInfusion.uid);
        auditInfusionDosesToOldDBStyle([deletedDose])
          .then((response) => {
            if (response.type === ResponseType.Success) {
              console.log('Successfully audited doses', response.data);
            }
          })
          .catch((error) => {
            console.error('Failed to audit doses', error);
          });
      }
    }
  };

  const handleActivateState = async () => {
    try {
      let res: Response = await handleToggleEnabled(
        user,
        department,
        reducerState,
        parmInfusion
      );
      if (res.type === ResponseType.Success) {
        let updated: InfusionItem = res.data;
        console.log('Updated Infusion', updated);
        setParmInfusion(updated);
        reloadDatabase(updated.uid);
      }
    } catch (error) {
      console.error('Error toggling enabled state', error);
    }

    setIsBlockModal(false);
  };

  const handleCheckIsDraft = useCallback(async (): Promise<boolean> => {
    if (isEditMode) return false;
    let response: Response = await isDraftCreated(database.department);
    if (response.type === ResponseType.Success) {
      let isDraft = response.data;
      if (isDraft) setIsOpen(true);
      return isDraft;
    } else {
      if (globals.debug)
        console.log('Failed to check if draft exists', response);
    }
    return false;
  }, [database.department, isEditMode]);

  const handleDelete = () => {
    setIsDelete(true);
  };

  const handleConfirmDeleteItem = async () => {
    const isDraft = parmInfusion.status === ProgressStatus.DRAFT;
    let response = await deleteInfusionItem(department, parmInfusion, !isDraft);
    if (response.type === ResponseType.Success) {
      handleBack();
    } else {
      console.error('Failed to delete infusion', response.data);
    }
  };

  const ProtocolItemUI = ({ protocol }: any) => {
    const doses = allSubInfusionItems.filter(
      (item: InfusionSubItem) => item.parentProtocol.uid === protocol.uid
    );
    doses
      .filter(
        (item: InfusionSubItem) =>
          showDeactivated || item.status !== ProgressStatus.DEACTIVATED
      )
      .sort((a: InfusionSubItem, b: InfusionSubItem) => {
        if (a.index === b.index) return a.rangeLow - b.rangeLow;
        return a.index - b.index;
      });
    // if (globals.debug) console.log('Doses', doses);
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          padding: '10px',
          marginTop: '.25rem',
          marginBottom: '.25rem',
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <AccordionItem
          title={protocol.name}
          titleStyle={{ fontWeight: '400' }}
          rightTitle={`${doses.length} doses`}
          rightTitleStyle={{ fontWeight: '700' }}
          expanded={expanded.includes(protocol.uid)}
          onChange={() => {
            if (expanded.includes(protocol.uid)) {
              setExpanded(expanded.filter((item) => item !== protocol.uid));
            } else {
              setExpanded([...expanded, protocol.uid]);
            }
          }}
        >
          <div
            style={{ flex: 1, padding: '0 10px 10px 10px' }}
            onClick={(e) => e.stopPropagation()} // Add this to prevent event bubbling
          >
            <ListRender data={doses} />
          </div>
        </AccordionItem>
      </div>
    );
  };

  const ListRender = ({ data }: any) => {
    let list = data
      .filter(
        (item: InfusionSubItem) =>
          showDeactivated || item.status !== ProgressStatus.DEACTIVATED
      )
      .sort((a: InfusionSubItem, b: InfusionSubItem) => {
        if (a.index === b.index) return a.rangeLow - b.rangeLow;
        return a.index - b.index;
      });
    return (
      <ViewportList items={list}>
        {(dose: InfusionSubItem, index: number) => {
          const lastIndex = data.length === index + 1;
          return (
            <div
              key={index}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                if (isEditMode) {
                  setSelectedDose(dose);
                  setDoseIndex(index);
                  setEditDose(true);
                  setSidebarVisible(true);
                }
              }}
            >
              <InfusionDoseUI
                dose={dose}
                doseIndex={index + 1}
                showProtocol={false}
                isEditMode={isEditMode}
                onToggle={handleToggleEnabledUI}
                isToggled={
                  itemDeactivationStatus.find(
                    (newItem) => newItem.uid === dose.uid
                  )
                    ? false
                    : true
                }
              />
            </div>
          );
        }}
      </ViewportList>
    );
  };

  const Modals = () => {
    return (
      <>
        <Snackbar
          open={snackbar.open}
          autoHideDuration={3000}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Alert
            onClose={() => setSnackbar({ ...snackbar, open: false })}
            severity={snackbar.severity}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
        <SelectDepartmentsModal
          isVisible={selectDepartmentsModal}
          title={`Propogate Concentrations to Additional Departments?`}
          description={`Would you like to propogate the concentrations to any other departments under ${department.name}? Select the departments you would like to propogate the concentrations to. Press Dismiss to continue without propogating.`}
          note={`This will propogate to every ACTIVE infusion in the selected departments meaning it will go live right away for the selected infusions if you propogate.`}
          buttonText={'Propogate'}
          department={department}
          values={[department]}
          handleClose={() => setSelectDepartmentsModal(false)}
          handleSubmit={(deps) => {
            setSelectDepartmentsModal(false);
            handleSave(formik.values, true, deps);
          }}
        />
        {isOpen && (
          <DraftSaveModal
            database={database}
            isVisible={isOpen}
            handleClose={() => {
              setIsOpen(false);
            }}
            handleContinue={() => {
              setIsEditMode(true);
              setIsOpen(false);
            }}
            handleNew={() => {
              setIsDraftConfirmModal(true);
            }}
          />
        )}

        {isBlockModal && (
          <ConfirmModal
            isVisible={isBlockModal}
            title={`${
              parmInfusion.status === ProgressStatus.DEACTIVATED
                ? 'Reactivate'
                : 'Deactivate'
            } ${departmentOwner?.name} ${parmInfusion.name} Infusion?`}
            handleClose={() => {
              setIsBlockModal(false);
            }}
            handleSubmit={
              () => handleActivateState()
              // handleDeleteRestoreBlockDose(parmInfusion.status === ProgressStatus.DEACTIVATED ? 'restore' : 'block')
            }
            isDeleteBtn={parmInfusion.status !== ProgressStatus.DEACTIVATED}
            primaryBtnName="Cancel"
            secondaryBtnName={
              parmInfusion.status === ProgressStatus.DEACTIVATED
                ? 'Reactivate'
                : 'Deactivate'
            }
            primaryDescription={`This will ${parmInfusion.status === ProgressStatus.DEACTIVATED ? 'reactivate' : 'deactivate'} the ${departmentOwner?.name} ${parmInfusion?.name} Infusions for ${database.department?.name}. ${parmInfusion.status === ProgressStatus.DEACTIVATED ? '' : 'This can be reactiveated at any time.'}`}
          />
        )}

        {isDraftConfirmModal && (
          <ConfirmModal
            isVisible={isConfirmModal}
            title="Make New Draft?"
            handleClose={() => {
              setIsDraftConfirmModal(false);
            }}
            handleSubmit={handleMakeNew}
            isDeleteBtn={true}
            primaryBtnName="Cancel"
            secondaryBtnName="Make New"
            primaryDescription="Are you sure you would like to remove the 11 detected changes from the previous draft and override it and create a new one."
          />
        )}

        {isDelete && (
          <ConfirmModal
            isVisible={isDelete}
            title={'Delete Infusion?'}
            handleClose={() => {
              setIsDelete(false);
            }}
            handleSubmit={handleConfirmDeleteItem}
            isDeleteBtn={true}
            primaryBtnName="Cancel"
            secondaryBtnName="Delete"
            primaryDescription={`Are you sure you would like to delete ${parmInfusion.name}?`}
          />
        )}

        {isWarningModal && (
          <ConfirmModal
            isVisible={isWarningModal}
            title="Abandon Changes?"
            handleClose={() => {
              setIsWarningModal(false);
            }}
            handleSubmit={handleBack}
            isDeleteBtn={true}
            primaryBtnName="Cancel"
            secondaryBtnName="Abandon"
            primaryDescription={`Changes were made to this Infusion.  Click cancel to return to Infusion details.  To continue without saving changes, select Abandon.`}
          />
        )}

        {isCancelWarningModal && (
          <ConfirmModal
            isVisible={isCancelWarningModal}
            title="Abandon Changes?"
            handleClose={() => {
              setIsCancelWarningModal(false);
            }}
            handleSubmit={handleCancel}
            isDeleteBtn={true}
            primaryBtnName="Cancel"
            secondaryBtnName="Abandon"
            primaryDescription={`Changes were made to this Infusion.  Click cancel to return to Infusion details.  To cancel without saving changes, select Abandon.`}
          />
        )}

        <ConcentrationDialog
          dialogVisible={concentrationDialogVisible}
          handleSet={handleConcentrationSet}
          setDialogVisible={setConcentrationDialogVisible}
          headerText="Concentration"
        />

        <ConfirmWarningModal
          isVisible={isConfirmModal}
          modalType={isWarning ? 'warning' : modalType}
          type={modalType}
          handleClose={() => {
            setIsConfirmModal(false);
            setIsWarning(false);
          }}
          handleDelete={handleConfirmDelete}
        />

        {sidebarVisible && (
          <InfusionDoseSideout
            doseIndex={doseIndex}
            protocol={protocol}
            parentModel={parmInfusion}
            solidUnit={solidUnits}
            adminRoutes={formik.values.routes}
            dose={selectedDose ? selectedDose : undefined}
            subInfusionItems={allSubInfusionItems}
            visible={sidebarVisible}
            setVisible={handleCloseModal}
            editDose={editDose}
            onSubmit={handleOnSubmitInfusionDose}
            onSubmitMultiDose={handleCopyProtocolDoses}
            onSetNewRoutes={(newRoutes: string[]) => {
              formik.setFieldValue('routes', newRoutes);
            }}
            onRemove={handleRemoveInfusionDose}
          />
        )}

        <AddRouteModal
          dialogVisible={isAddRouteVisible}
          handleSet={handleNewRouteSet}
          onNewRouteCancel={() => {
            setIsAddRouteVisible(false);
          }}
          headerText="New Administration Route"
        />
      </>
    );
  };

  const isSaveActive = useMemo(() => {
    return (
      (formik.dirty &&
        formik.isValid &&
        formik.values.concentration?.length > 0 &&
        formik.values.routes.length > 0) ||
      !(
        initialDeactivationStatusIDs.current.length ===
          itemDeactivationStatus.length &&
        initialDeactivationStatusIDs.current.every((id: any) =>
          itemDeactivationStatus.some(
            (item: InfusionSubItem) => item.uid === id
          )
        )
      )
    );
  }, [formik.dirty, formik.isValid, formik.values, itemDeactivationStatus]);

  const handleToggleEnabledUI = async (item: InfusionSubItem) => {
    // Check if the item is already in itemDeactivationStatus
    const isItemDeactivated = itemDeactivationStatus.some(
      (deactivatedItem) => deactivatedItem.uid === item.uid
    );

    // Update itemDeactivationStatus
    if (isItemDeactivated) {
      // Remove the item if it's already deactivated
      setItemDeactivationStatus(
        itemDeactivationStatus.filter((newItem) => newItem.uid !== item.uid)
      );
    } else {
      // Add the item if it's not deactivated
      setItemDeactivationStatus([...itemDeactivationStatus, item]);
    }

    // Update selectedItemDeactivationStatus
    setSelectedItemDeactivationStatus((prevSelected) => {
      const currentSelected = prevSelected || [];

      const isItemSelected = currentSelected.some(
        (selectedItem) => selectedItem.uid === item.uid
      );

      if (isItemSelected) {
        // Remove the item if it's already selected
        return currentSelected.filter(
          (selectedItem) => selectedItem.uid !== item.uid
        );
      } else {
        // Add the item if it's not selected
        return [...currentSelected, item];
      }
    });
  };

  const handleSaveFormik = async () => {
    if (formik.dirty && formik.isValid) {
      handleSave(formik.values);
    } else if (
      selectedItemDeactivationStatus &&
      selectedItemDeactivationStatus.length > 0
    ) {
      try {
        console.log(
          'selectedItemDeactivationStatus',
          selectedItemDeactivationStatus
        );
        let promises: any[] = [];
        for (let i = 0; i < selectedItemDeactivationStatus.length; i++) {
          console.log(
            'UPDATING itemDeactivationStatus[' + i + ']',
            selectedItemDeactivationStatus[i]
          );
          promises.push(
            handleToggleEnabled(
              user,
              database.department,
              reducerState,
              selectedItemDeactivationStatus[i]
            )
          );
        }
        console.log('PROMISES', promises);
        let results = await Promise.all(promises);
        console.log('results', results);
        for (let i = 0; i < results.length; i++) {
          if (results[i].type === ResponseType.Success) {
            if (globals.debug) console.log('SAVED ITEM', results[i].data);
          } else {
            console.error('ERROR SAVING ITEM', results[i].data);
          }
        }
        console.log('RELOADING DATABASE');

        setAllSubInfusionItems(
          allSubInfusionItems.map((item: InfusionSubItem) => {
            const updatedItem = results.find(
              (result) => result.data.uid === item.uid
            );
            return updatedItem ? updatedItem.data : item;
          })
        );
        setSelectedItemDeactivationStatus([]);
        reloadDatabase(parmInfusion.uid);
        setIsEditMode(false);
      } catch (error) {
        console.error('ERROR SAVING DEACTIVATE STATE', error);
      }
    } else {
      setIsEditMode(false);
    }
  };
  return (
    <div className={showOnlySideout ? '' : 'screen-container'}>
      <Modals />
      {!showOnlySideout && (
        <>
          <ProtocolHeader
            name={
              parmInfusion.name
              // (parmInfusion.status === "DRAFT" ? " (Draft)" : "")
            }
            status={parmInfusion.status}
            isBackButton={true}
            page={
              reviewal
                ? (reviewal as ReviewalItem).name
                : protocol
                  ? protocol.name
                  : 'Infusions'
            }
            protocolDetail={protocol}
            type={'protocol'}
            rightSideBtn={isEditMode ? 'save' : 'edit'}
            isSaveButton={isEditMode}
            isSaveActive={isSaveActive}
            // isVersionButton={true}
            isDeactivateButton={
              isEditMode &&
              protocol == null &&
              (adminLevel === 1 || adminLevel === 3) &&
              parmInfusion.status !== ProgressStatus.DEACTIVATED &&
              !isOwner
            }
            isDeactivateActive={isEditMode}
            isReactivateActive={isEditMode}
            isReactivateButton={
              isEditMode && parmInfusion.status === ProgressStatus.DEACTIVATED
            }
            handleDeactivate={() => {
              setIsBlockModal(true);
            }}
            handleReactivate={() => setIsBlockModal(true)}
            draftChangeLocked={draftChangeInReview}
            handleReviewClicked={() => {
              if (draftChangeInReview) {
                setLoading('Loading Review...');
                handleLoadReviewAndNavigate(
                  database,
                  draftChangeInReview,
                  user,
                  dispatch,
                  navigate
                ).catch((error) => {
                  console.error('Failed to load review and navigate', error);
                  setLoading(null);
                });
              }
            }}
            isDeleteButton={
              isEditMode && protocol == null && adminLevel > 2 && isOwner
            }
            isCopyDescription={user.type === UserType.ADMIN}
            descriptionTitle={user.type === UserType.ADMIN ? 'ID:' : ''}
            description={user.type === UserType.ADMIN ? parmInfusion.uid : ''}
            // isVersionButton={true}
            isEditButton={
              !draftChangeInReview &&
              reviewal == null &&
              !isEditMode &&
              adminAccess
            }
            isDotButton={true}
            handleCancel={handleBack}
            handleCancelEdit={handleCancel}
            handleEdit={() => {
              handleCheckIsDraft().then((isDraft: boolean) => {
                if (!isDraft && !isEditMode) setIsEditMode(true);
              });
            }}
            handleDelete={handleDelete}
            handleSave={() => handleSaveFormik()}
            handleVersion={handleVersion}
          />

          <div className="ketamineContent">
            <div className="KetamineGeneral ">
              <h5 className="ketmine-header-text">General Information</h5>
              <div className="input-container roundBorder">
                <div>
                  <div
                    style={{ display: 'flex', marginTop: '5px' }}
                    className="ketamine-general-label"
                  >
                    <div style={{ marginRight: '10px' }}>Modified By:</div>
                    <div style={{ fontWeight: '500' }}>
                      {modifiedBy
                        ? modifiedBy.firstName + ' ' + modifiedBy.lastName
                        : 'Hinckley Medical'}
                    </div>
                  </div>
                  {parmInfusion.model?.updatedAt && (
                    <div
                      style={{ display: 'flex' }}
                      className="ketamine-general-label"
                    >
                      <div style={{ marginRight: '10px' }}>Last Updated:</div>
                      <div style={{ fontWeight: '500' }}>
                        {getFormattedDate(parmInfusion.model.updatedAt, true)}
                      </div>
                    </div>
                  )}
                  {user.type === UserType.ADMIN && (
                    <div
                      style={{ display: 'flex' }}
                      className="ketamine-general-label"
                    >
                      <div style={{ marginRight: '10px' }}>ID:</div>
                      <div style={{ fontWeight: '500' }}>
                        {parmInfusion.uid}
                        <span>
                          {isCopied && isCopied === parmInfusion.uid ? (
                            <BiSolidCopy
                              color={'#00534C'}
                              size="1rem"
                              className="copy_icon"
                            />
                          ) : (
                            <BiCopy
                              size="1rem"
                              className="copy_icon"
                              onClick={(e) =>
                                handleCopy(parmInfusion.uid, e, setIsCopied)
                              }
                            />
                          )}
                        </span>
                      </div>
                    </div>
                  )}
                  {user.type === UserType.ADMIN &&
                    parmInfusion.status === ProgressStatus.DRAFT &&
                    parmInfusion.activeID != null && (
                      <div
                        style={{ display: 'flex' }}
                        className="ketamine-general-label"
                      >
                        <div style={{ marginRight: '10px' }}>Active ID:</div>
                        <div style={{ fontWeight: '500' }}>
                          {parmInfusion.activeID}
                          <CopyToClipboard text={parmInfusion.activeID} />
                          {parmInfusion.activeItem && (
                            <FaCheckCircle
                              data-testid="isFullScreenButton"
                              className="icon"
                              style={{
                                padding: '6px',
                                color: '#037F02',
                              }}
                            />
                          )}
                        </div>
                      </div>
                    )}
                  {user.type === UserType.ADMIN && (
                    <div
                      style={{ display: 'flex' }}
                      className="ketamine-general-label"
                    >
                      <div style={{ marginRight: '10px' }}>Owner ID:</div>
                      <div style={{ fontWeight: '500' }}>
                        {parmInfusion.departmentID}
                        <span>
                          {isCopied &&
                          isCopied === parmInfusion.departmentID ? (
                            <BiSolidCopy
                              color={'#00534C'}
                              size="1rem"
                              className="copy_icon"
                            />
                          ) : (
                            <BiCopy
                              size="1rem"
                              className="copy_icon"
                              onClick={(e) =>
                                handleCopy(
                                  parmInfusion.departmentID,
                                  e,
                                  setIsCopied
                                )
                              }
                            />
                          )}
                        </span>
                      </div>
                    </div>
                  )}
                  {user.type === UserType.ADMIN &&
                    parmInfusion.overrideID != null && (
                      <div
                        style={{ display: 'flex' }}
                        className="ketamine-general-label"
                      >
                        <div style={{ marginRight: '10px' }}>Override ID:</div>
                        <div style={{ fontWeight: '500' }}>
                          {parmInfusion.overrideID}
                          <span>
                            {isCopied &&
                            isCopied === parmInfusion.overrideID ? (
                              <BiSolidCopy
                                color={'#00534C'}
                                size="1rem"
                                className="copy_icon"
                              />
                            ) : (
                              <BiCopy
                                size="1rem"
                                className="copy_icon"
                                onClick={(e) =>
                                  handleCopy(
                                    parmInfusion.overrideID as string,
                                    e,
                                    setIsCopied
                                  )
                                }
                              />
                            )}
                          </span>
                        </div>
                      </div>
                    )}
                  {/* {notification.notifyType && (
							<div style={{ display: 'flex' }} className='ketamine-general-label'>
							<div style={{ marginRight: '10px' }}>
								Style:
							</div>
							<div style={{fontWeight: '500'}}>
								{notification.notifyType}
							</div>
							</div>
						)} */}
                  <div
                    style={{ display: 'flex' }}
                    className="ketamine-general-label"
                  >
                    <div style={{ marginRight: '10px' }}>Version:</div>
                    <div style={{ fontWeight: '500' }}>
                      {parmInfusion.version}
                    </div>
                  </div>
                </div>
              </div>
              <label htmlFor="Name" className="ketamine-general-label">
                Name <span className="required-field">*</span>
              </label>
              <div className="input-container">
                <InputText
                  type="text"
                  className="form-control-general"
                  id="name"
                  name="name"
                  data-testid="name"
                  required={true}
                  value={formik.values.name}
                  onChange={(e: any) => {
                    formik.setFieldValue('name', e.target.value);
                  }}
                  disabled={!isEditMode || adminLevel < 3}
                />
                <div className="input-border"></div>
              </div>
              <label htmlFor="Concentration" className="ketamine-general-label">
                Concentration <span className="required-field">*</span>
              </label>
              <div
                data-testid="outsideConcentration"
                data-div-id="outsideConcentration"
                onClick={handleConcentrationEdit}
                className={`selectionArea-hover ${
                  isEditMode ? 'cursorPointer ' : 'selectionArea-disable'
                } ${
                  formik.values.concentration?.length !== 0
                    ? ''
                    : 'centerContent'
                }`}
              >
                {formik.values.concentration?.length !== 0 && (
                  <ViewportList items={formik.values.concentration}>
                    {(item: Concentration, index: any) => {
                      return (
                        <div
                          key={index.toLocaleString()}
                          className={`selectedGreybox ${
                            isEditMode ? 'cursorPointer' : 'disabled'
                          }`}
                        >
                          <div>{getConcentrationString(item)}</div>
                          {isEditMode ? (
                            <span
                              className="close_icon_color"
                              data-testid={`deleteConcentration ${index}`}
                              onClick={(e) =>
                                handleRemoveConcentration(e, item)
                              }
                            >
                              <AiOutlineCloseCircle className="icon-normal" />
                            </span>
                          ) : (
                            ''
                          )}
                        </div>
                      );
                    }}
                  </ViewportList>
                )}
                {formik.values.concentration?.length !== 0 && isEditMode && (
                  <HiPencil
                    data-testid={`editConcentration`}
                    className={`icon-black rightIcon ${
                      isEditMode && 'cursorPointer'
                    }`}
                    onClick={() => {
                      if (isEditMode) setConcentrationDialogVisible(true);
                    }}
                  />
                )}
                {formik.values.concentration?.length === 0 && (
                  <div
                    onClick={() => {
                      isEditMode &&
                        setConcentrationDialogVisible(
                          !concentrationDialogVisible
                        );
                    }}
                  >
                    <span className="formAnchorText">+Add Concentration</span>
                  </div>
                )}
              </div>
              <label htmlFor="Concentration" className="ketamine-general-label">
                Routes <span className="required-field">*</span>
              </label>
              <div
                data-testid="outsideRoute"
                data-div-id="outsideRoute"
                onClick={handleRouteEdit}
                className={`selectionArea-hover ${
                  isEditMode && isOwner
                    ? 'cursorPointer '
                    : 'selectionArea-disable'
                } ${formik.values.routes.length !== 0 ? '' : 'centerContent'}`}
              >
                {formik.values.routes.length >= 1 && (
                  <ViewportList items={formik.values.routes}>
                    {(item: any, index: any) => {
                      return (
                        <div
                          key={index.toLocaleString()}
                          className={`selectedGreybox ${
                            isEditMode && isOwner ? 'cursorPointer' : 'disabled'
                          }`}
                        >
                          <div>{item}</div>
                          {isEditMode && adminLevel > 2 && isOwner && (
                            <span className="close_icon_color">
                              <AiOutlineCloseCircle
                                data-testid={`deleteRoute ${index}`}
                                className={`icon-normal ${
                                  isEditMode ? 'cursorPointer' : 'disabled'
                                }`}
                                onClick={(e) => {
                                  if (isEditMode && adminLevel > 2)
                                    handleRemoveRoute(e, item);
                                }}
                              />
                            </span>
                          )}
                        </div>
                      );
                    }}
                  </ViewportList>
                )}
                {formik.values.routes.length >= 1 && isEditMode && isOwner && (
                  <HiPencil
                    data-testid={`EditRoute`}
                    className={`icon-black rightIcon ${
                      isEditMode ? 'cursorPointer' : 'disabled'
                    }`}
                  />
                )}
                {formik.values.routes.length === 0 && (
                  <div
                    onClick={() => {
                      isEditMode && setIsAddRouteVisible(true);
                    }}
                  >
                    <span className="cursorPointer formAnchorText">
                      +Add Route
                    </span>
                  </div>
                )}
              </div>
              <Row>
                <Col sm={6}>
                  <label htmlFor="rangeLow" className="ketamine-general-label">
                    Range Low (kg)
                  </label>
                  <div className="input-container">
                    <InputText
                      type="text"
                      className="form-control-general"
                      id="name"
                      name="rangeLow"
                      data-testid="rangeLow"
                      disabled={!isEditMode || adminLevel < 3}
                      value={
                        formik.values.rangeLow &&
                        Number(formik.values.rangeLow) !== 0
                          ? formik.values.rangeLow
                          : ''
                      }
                      // style={{color: Number(formik.values.rangeLow) ? '#9e9e9e' : '#000000'}}
                      placeholder="Min"
                      onChange={(e: any) => {
                        /* Check for "Min" */
                        let n = Number(e.target.value);
                        if (isNaN(n))
                          formik.setFieldError('rangeLow', 'Invalid input');
                        else formik.setFieldValue('rangeLow', e.target.value);
                      }}
                      onBlur={formik.handleBlur}
                    />
                    <div className="input-border"></div>
                  </div>
                  {formik.touched.rangeLow && formik.errors.rangeLow ? (
                    <span className="errorText">{formik.errors.rangeLow}</span>
                  ) : null}
                </Col>
                <Col sm={6}>
                  <label htmlFor="rangeHigh" className="ketamine-general-label">
                    Range High (kg)
                  </label>
                  <div className="input-container">
                    <InputText
                      type="text"
                      className="form-control-general"
                      id="rangeHigh"
                      name="rangeHigh"
                      data-testid="rangeHigh"
                      disabled={!isEditMode || adminLevel < 3}
                      value={
                        formik.values.rangeHigh &&
                        Number(formik.values.rangeHigh) !== globals.MAX_VALUE
                          ? formik.values.rangeHigh
                          : ''
                      }
                      placeholder="Max"
                      onChange={(e: any) => {
                        let n = Number(e.target.value);
                        if (isNaN(n))
                          formik.setFieldError('rangeHigh', 'Invalid input');
                        else formik.setFieldValue('rangeHigh', e.target.value);
                      }}
                      onBlur={formik.handleBlur}
                    />
                    <div className="input-border"></div>
                  </div>
                  {formik.touched.rangeHigh && formik.errors.rangeHigh ? (
                    <span className="errorText">{formik.errors.rangeHigh}</span>
                  ) : null}
                </Col>
              </Row>
              <label
                htmlFor="Contraindication"
                className="ketamine-general-label"
              >
                Contraindication
              </label>
              <div className="input-container">
                <InputText
                  type="text"
                  className="form-control-general"
                  id="contraindication"
                  data-testid="contraindication"
                  name="contraindication"
                  value={formik.values.contraindication}
                  disabled={!isEditMode || adminLevel < 3}
                  onChange={(e: any) => {
                    formik.setFieldValue('contraindication', e.target.value);
                  }}
                />
                <div className="input-border"></div>
              </div>
              <label htmlFor="Warning" className="ketamine-general-label">
                Warning
              </label>
              <div className="input-container">
                <InputText
                  type="text"
                  className="form-control-general"
                  id="warning"
                  data-testid="warning"
                  name="warning"
                  value={formik.values.warning}
                  disabled={!isEditMode || adminLevel < 3}
                  onChange={(e: any) => {
                    formik.setFieldValue('warning', e.target.value);
                  }}
                />
                <div className="input-border"></div>
              </div>
              <label htmlFor="Indication" className="ketamine-general-label">
                Indication
              </label>
              <div className="input-container">
                <InputText
                  type="text"
                  className="form-control-general"
                  id="indication"
                  data-testid="indication"
                  name="indication"
                  value={formik.values.indication}
                  disabled={!isEditMode || adminLevel < 3}
                  onChange={(e: any) => {
                    formik.setFieldValue('indication', e.target.value);
                  }}
                />
                <div className="input-border"></div>
              </div>
              <label
                htmlFor="DrugInteraction"
                className="ketamine-general-label"
              >
                Drug Interaction
              </label>
              <div className="input-container">
                <InputText
                  type="text"
                  className="form-control-general"
                  id="interaction"
                  data-testid="interaction"
                  name="interaction"
                  value={formik.values.interaction}
                  disabled={!isEditMode || adminLevel < 3}
                  onChange={(e: any) => {
                    formik.setFieldValue('interaction', e.target.value);
                  }}
                />
                <div className="input-border"></div>
              </div>
              <label htmlFor="Onset" className="ketamine-general-label">
                Onset
              </label>
              <div className="input-container">
                <InputText
                  type="text"
                  className="form-control-general"
                  id="onset"
                  data-testid="onset"
                  name="onset"
                  value={formik.values.onset}
                  disabled={!isEditMode || adminLevel < 3}
                  onChange={(e: any) => {
                    formik.setFieldValue('onset', e.target.value);
                  }}
                />
                <div className="input-border"></div>
              </div>
              <label htmlFor="Duration" className="ketamine-general-label">
                Duration
              </label>
              <div className="input-container">
                <InputText
                  type="text"
                  className="form-control-general"
                  id="duration"
                  data-testid="duration"
                  name="duration"
                  value={formik.values.duration}
                  disabled={!isEditMode || adminLevel < 3}
                  onChange={(e: any) => {
                    formik.setFieldValue('duration', e.target.value);
                  }}
                />
                <div className="input-border"></div>
              </div>
              <label htmlFor="Note" className="ketamine-general-label">
                Note
              </label>
              <div className="input-container">
                <InputText
                  type="text"
                  className="form-control-general"
                  id="notes"
                  data-testid="notes"
                  name="notes"
                  value={formik.values.note}
                  disabled={!isEditMode || adminLevel < 3}
                  onChange={(e: any) => {
                    formik.setFieldValue('note', e.target.value);
                  }}
                />
                <div className="input-border"></div>
              </div>
              <label htmlFor="Class" className="ketamine-general-label">
                Class
              </label>
              <div className="input-container">
                <InputText
                  type="text"
                  className="form-control-general"
                  id="class"
                  data-testid="class"
                  name="class"
                  value={formik.values.medClass}
                  disabled={!isEditMode || adminLevel < 3}
                  onChange={(e: any) => {
                    formik.setFieldValue('medClass', e.target.value);
                  }}
                />
                <div className="input-border"></div>
              </div>
              <label htmlFor="Action" className="ketamine-general-label">
                Action
              </label>
              <div className="input-container">
                <InputText
                  type="text"
                  className="form-control-general"
                  id="action"
                  data-testid="action"
                  name="action"
                  value={formik.values.action}
                  disabled={!isEditMode || adminLevel < 3}
                  onChange={(e: any) => {
                    formik.setFieldValue('action', e.target.value);
                  }}
                />
                <div className="input-border"></div>
              </div>
            </div>
            <div className="KetamineGeneral">
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginBottom: '10px',
                }}
              >
                <h5 className="ketmine-header-text">Protocol Dependent</h5>{' '}
                {numDeactivated > 0 && (
                  <InputSwitch
                    tooltip={'Show ' + numDeactivated + ' Deactivated Items'}
                    tooltipOptions={{
                      position: 'bottom',
                      style: { fontSize: '12px' },
                    }}
                    name="isSolidOnly"
                    style={{ marginLeft: '10px' }}
                    checked={showDeactivated}
                    onChange={(e) => {
                      setShowDeactivated(e.value);
                    }}
                  />
                )}
              </div>
              {isEditMode && adminLevel > 2 && (
                <div style={{ marginTop: '5px' }}>
                  <span className="ketamine-general-label">Doses</span>
                  <div
                    onClick={onAddDoseClick}
                    className={`contentBorder protocolCalculationPad contentHeadingBold newProtocolBorder  ${
                      isEditMode && 'cursorPointer newRouteButton'
                    }`}
                    style={{ display: 'flex', justifyContent: 'center' }}
                  >
                    <span>
                      <HiPlus className="text-icon " /> Add Dose
                    </span>
                  </div>
                </div>
              )}
              {/* <ViewportList items={subInfusionItems}>
            {(dose: InfusionSubItem, index: number) => {
              return (
                <div
                  key={index.toLocaleString()}
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    if (isEditMode) {
                      setSelectedDose(dose);
                      setDoseIndex(index);
                      setEditDose(true);
                      setSidebarVisible(true);
                    }
                  }}
                >
                  <InfusionDose
                    dose={dose}
                    doseIndex={index + 1}
                    showProtocol={protocol == null}
                  />
                  {/* <DoseSelection
									data={dose}
									doseIndex={index + 1}
									rangeLow={rangeLow}
									setRangeLow={setRangeLow}
									rangeHigh={rangeHigh}
									setRangeHigh={setRangeHigh}
									route={route}
									setRoute={setRoute}
									calculationBasis={calculationBasis}
									setCalculationBasis={setCalculationBasis}
									minDose={minDose}
									setMinDose={setMinDose}
									maxDose={maxDose}
									setMaxDose={setMaxDose}
									instructions={true}
									header={true}
									editDose={true}
									viewOnly={true}
									isEditMode={isEditMode}
									modalName={"Infusion"} 
									/> 
                </div>
              );
            }}
          </ViewportList> */}
              {protocol ? (
                <ViewportList
                  items={subInfusionItems.filter(
                    (item) =>
                      showDeactivated ||
                      item.status !== ProgressStatus.DEACTIVATED
                  )}
                >
                  {(dose: InfusionSubItem, index: number) => {
                    return (
                      <div
                        key={index}
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          if (isEditMode) {
                            setSelectedDose(dose);
                            setDoseIndex(index);
                            setEditDose(true);
                            setSidebarVisible(true);
                          }
                        }}
                      >
                        <InfusionDoseUI
                          dose={dose}
                          doseIndex={index + 1}
                          showProtocol={protocol == null}
                          isEditMode={isEditMode}
                          onToggle={handleToggleEnabledUI}
                          isToggled={
                            itemDeactivationStatus.find(
                              (newItem) => newItem.uid === dose.uid
                            )
                              ? false
                              : true
                          }
                        />
                      </div>
                    );
                  }}
                </ViewportList>
              ) : (
                <>
                  {allProtocols.map((protocol: ProtocolItem, index: number) => {
                    return <ProtocolItemUI key={index} protocol={protocol} />;
                  })}
                </>
              )}
            </div>
          </div>
        </>
      )}
      {loading && <Loading type="bubbles" message={loading} />}
    </div>
  );
};

export default InfusionProtocolEditPage;
