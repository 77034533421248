import * as _ from 'lodash';
import { InputText } from 'primereact/inputtext';
import { RadioButton } from 'primereact/radiobutton';
import { Sidebar } from 'primereact/sidebar';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button } from 'react-bootstrap';
import { AiOutlineCloseCircle, AiOutlinePlus } from 'react-icons/ai';
import { BiArrowBack, BiSolidChevronRight } from 'react-icons/bi';
import { FiSearch } from 'react-icons/fi';
import { HiPencil, HiPlus } from 'react-icons/hi';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ViewportList } from 'react-viewport-list';
import { sideoutList } from '../../_global/constants/Protocol_constants';
import ConfirmModal from '../Modal/ConfirmModal';
import {
  DatabaseResponse,
  ResponseType,
  Response,
} from '../../../data/AmplifyDB';
import ProtocolItem from '../../../data/model/ProtocolItem';
import EquipmentItem from '../../../data/model/EquipmentItem';
import FormItem from '../../../data/model/FormItem';
import SearchBar from '../Search/SearchBar';
import { fetchItemFromAPI } from '../../../data/GraphQL_API';
import { handleHinckleyCache } from '../../../store/actions';
import NewModelItemSideout from './NewModelItemSideout';
import MedicationItem from '../../../data/model/MedicationItem';
import NewMedicationDoseSideout from '../../pages/protocol/details/NewMedicationDoseSideout';
import InfusionItem from '../../../data/model/InfusionItem';
import NewInfusionDoseSideout from '../../pages/protocol/details/NewInfusionDoseSideout';
import ElectricalItem from '../../../data/model/ElectricalItem';
import NewElectricalDoseSideout from '../../pages/protocol/details/NewElectricalDoseSideout';
import { globals } from '../../_global/common/Utils';
import GeneralSelection from '../Selection/GeneralSelection';
import SearchableDropdown from '../SearchableDropdown';
import ModelSubItem from '../../../data/model/ModelSubItem';

const HM_DB_ID =
  process.env.REACT_APP_HM_DB_ID ??
  (function () {
    throw new Error('Hinckley Medical DB ID is not defined');
  })();

interface AddProtocolSideoutProps {
  handleClose: () => void;
  handleAdd?: (
    items: ProtocolItem[] | EquipmentItem[] | FormItem[],
    type: string
  ) => void | any;
  handleCopyFromProtocol?: (protocol: ProtocolItem, type: string) => void | any;
  handleSubmit?: (reloadDatabase: boolean) => void;
  details?: any;
  title?: string;
  isVisible?: boolean;
  isEditMode?: any;
  page?: any;
  protocol?: ProtocolItem;
  selectedProtocols?: ProtocolItem[];
  selectedEquipment?: EquipmentItem[];
  selectedForm?: FormItem[];
}

const CREATE_NEW = 0;
const COPY_PROTOCOL_DOSE = 1;

/* 10-13-23 Praveen: Created the side out component for Protocol creation flow */
const AddProtocolSideout: React.FC<AddProtocolSideoutProps> = (props) => {
  const {
    isVisible,
    handleClose,
    isEditMode,
    page,
    handleAdd,
    handleSubmit,
    protocol,
    selectedEquipment,
    selectedForm,
    selectedProtocols,
    handleCopyFromProtocol,
  } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const database: DatabaseResponse = useSelector(
    (state: any) => state.protocol.departmentItem
  );
  const [isSecondPage, setIsSecondPage] = useState(false);
  const [isNewModelItem, setIsNewModelItem] = useState<
    'medication' | 'infusion' | 'electrical' | 'equipment' | 'vital' | null
  >(null);
  const [isDelete, setIsDelete] = useState(false);
  const [selectedData, setSelectedData] = useState<any>('');
  const [value, setValue] = useState<any>('');
  const [options, setOptions] = useState<any>([]);
  const [hmDB, setHmDB] = useState<any>({
    medications: [],
    infusions: [],
    electrical: [],
    equipment: [],
    vitals: [],
    checklists: [],
  });

  const [selectedMedication, setSelectedMedication] = useState<
    MedicationItem | undefined
  >();
  const [selectedInfusion, setSelectedInfusion] = useState<
    InfusionItem | undefined
  >();
  const [selectedElectrical, setSelectedElectrical] = useState<
    ElectricalItem | undefined
  >();

  const [sideoutData, setSideoutData] = useState<any>(null);
  const editProtocol = useSelector(
    (state: any) => state?.protocol?.editProtocol
  );
  const departmentItem = useSelector(
    (state: any) => state.protocol.departmentItem
  );

  const pairedProtocolList: any = [];
  const [selectedOptions, setSelectedOptions] = useState<any>([]);
  const [selectedProtocolList, setSelectedProtocolList] = useState<any>([]);
  const [deleteItem, setDeleteItem] = useState<any>(null);
  const hinckleyState = useSelector((state: any) => state.hinckley);
  const [inputType, setInputType] = useState<number>(CREATE_NEW);
  const [parentProtocol, setParentProtocol] = useState<ProtocolItem | null>(
    null
  );

  useEffect(() => {
    if (protocol && selectedData.type !== 'Paired Protocol') {
      setSelectedProtocolList(protocol);
    }
    setSelectedProtocolList(editProtocol?.pairedProtocols);
  }, [protocol, editProtocol?.pairedProtocols, selectedData.type]);

  useEffect(() => {
    if (isVisible) {
      setHmDB({
        medications: hinckleyState.medications,
        infusions: hinckleyState.infusions,
        electrical: hinckleyState.electricals,
        equipment: hinckleyState.equipment,
        vitals: hinckleyState.vitals,
        checklists: hinckleyState.checklists,
      });
    }
  }, [isVisible, hinckleyState]);

  const loadHinckleyDB = async () => {
    if (globals.debug) console.log('Loading Hinckley DB Medicaitons' + '...');
    let response: Response = await fetchItemFromAPI(
      database.department,
      selectedData.type === 'Medication'
        ? 'Medications'
        : selectedData.type === 'Infusion'
          ? 'Drips'
          : selectedData.type === 'Electrical'
            ? 'Electrical'
            : selectedData.type === 'Equipment'
              ? 'Equipment'
              : 'Vitals',
      HM_DB_ID
    );
    if (response.type === ResponseType.Success) {
      if (globals.debug)
        console.log('Hinckley DB Medications loaded successfully');
      dispatch<any>(
        handleHinckleyCache({
          medications:
            selectedData.type === 'Medication'
              ? response.data
              : hinckleyState.medications,
          infusions:
            selectedData.type === 'Infusion'
              ? response.data
              : hinckleyState.infusions,
          electricals:
            selectedData.type === 'Electrical'
              ? response.data
              : hinckleyState.electricals,
          equipment:
            selectedData.type === 'Equipment'
              ? response.data
              : hinckleyState.equipment,
          vitals:
            selectedData.type === 'Vital'
              ? response.data
              : hinckleyState.vitals,
        })
      );
    } else {
      console.error('Failed to load Hinckley DB Medications');
    }
  };

  /* 11-07-23 Arul: handle function for radio buttton onChange */
  const handleCheckboxChange = (item: any) => {
    if (selectedData.type === 'Checklist') {
      if (selectedOptions.includes(item)) {
        // If the option is already selected, remove it
        return setSelectedOptions([]);
      } else {
        // If the option is not selected, add it
        return setSelectedOptions([item]);
      }
    } else {
      if (selectedOptions.find((option: any) => option.uid === item.uid)) {
        // If the option is already selected, remove it
        return setSelectedOptions(
          selectedOptions?.filter((option: any) => option.uid !== item.uid)
        );
      } else {
        // If the option is not selected, add it
        return setSelectedOptions([...selectedOptions, item]);
      }
    }
  };

  /* 10-13-23 Praveen: handling fuction to navigate edit protocol page*/
  const handleNavigateToEditPage = (data: any) => {
    const state = {
      selectedProtocol: protocol === undefined ? null : protocol,
      value: data,
      type: selectedData?.type,
      editType: 'edit',
      editMode: true,
      page,
    };
    // navigate(`/protocol/edit/edit-protocol`, { state });
    setSideoutData(data);
    if (selectedData?.type === 'Medication') {
      setSelectedMedication(data as MedicationItem);
      // setShowMedicationProtocolSideout(true);
    } else if (selectedData?.type === 'Infusion') {
      setSelectedInfusion(data as InfusionItem);
      // setShowInfusionProtocolSideout(true);
    } else if (selectedData?.type === 'Electrical') {
      setSelectedElectrical(data as ElectricalItem);
      // setShowElectricalProtocolSideout(true);
    } else navigate(`/protocol/edit/edit-protocol`, { state });
  };

  /* 10-13-23 Praveen: handled to navigate new protocol page*/
  const handleNavigateToNewPage = (selectedDataType: any) => {
    const state = {
      selectedProtocol: protocol,
      value: '',
      type: selectedDataType,
      editType: 'new',
      editMode: isEditMode,
      page,
    };
    navigate(`/protocol/edit/new-protocol`, { state });
  };

  const dataList = useMemo(() => {
    if (selectedData.type === 'Medication') {
      return database.medications;
    } else if (selectedData.type === 'Paired Protocol') {
      let sortedArray = departmentItem?.protocols.sort(
        (a: ProtocolItem, b: ProtocolItem) => a.name.localeCompare(b.name)
      );
      sortedArray = sortedArray?.filter((item: ProtocolItem) => {
        return item.uid !== protocol?.uid;
      });
      return sortedArray;
    } else if (selectedData.type === 'Infusion') {
      return database.infusions;
    } else if (selectedData.type === 'Electrical') {
      return database.electrical;
    } else if (selectedData.type === 'Equipment') {
      return database.equipment;
    } else if (selectedData.type === 'Vital') {
      return database.vitals;
    } else if (selectedData.type === 'Checklist') {
      return database.checklists;
    }
  }, [editProtocol, selectedData, isNewModelItem, hmDB.medications]);

  useEffect(() => {
    setOptions(dataList);
  }, [dataList]);

  useEffect(() => {
    if (selectedData.type === 'Paired Protocol') {
      setSelectedOptions(
        selectedProtocols ? selectedProtocols : protocol?.pairedProtocols || []
      );
    } else if (selectedData.type === 'Checklist') {
      setSelectedOptions(selectedForm ? [selectedForm] : protocol?.forms || []);
    } else if (selectedData.type === 'Equipment') {
      setSelectedOptions(
        selectedEquipment ? selectedEquipment : protocol?.equipment || []
      );
    }
  }, [
    selectedData.type,
    protocol,
    selectedEquipment,
    selectedForm,
    selectedProtocols,
  ]);

  /* 10-13-23 Praveen: For filtering the protocol sub item list */
  const handleFilter = useCallback(
    (value: any) => {
      if (value) {
        const filterData = dataList?.filter((item: any) =>
          item.name.toLowerCase().includes(value.toLowerCase())
        );
        setOptions(filterData);
      } else {
        setOptions(dataList);
      }
    },
    [value, dataList, setOptions]
  );

  /* 10-13-23 Praveen: to handle the search input*/
  const handleSearch = (text: string) => {
    setValue(text);
    handleFilter(text);
  };

  /* 11-07-23 Arul: select the paired protocol */
  const handleDeletePairedProtocol = () => {
    setIsDelete(false);
    const data = selectedProtocolList?.filter(
      (item: any) => item.name !== deleteItem.name
    );
    setSelectedProtocolList(data);
  };

  /**
   * Author: Guruprasad Venkatraman (01-29-2024)
   * Handles the addition of a paired protocol.
   * If the selected data type is not "Checklist", it calls the handleAdd function with the selected list of uids, selected data type, and selected list.
   * If the selected data type is "Checklist", it calls the handleAdd function with the selected list uid, selected data type, and selected list.
   * After adding the protocol, it navigates to the protocol detail page with the selected protocol state.
   * Finally, it resets the state and closes the sideout.
   */
  const handleAddPairedProtocol = async () => {
    if (handleAdd) {
      handleAdd(selectedOptions, selectedData.type);
      handleBack();
    }
  };

  const handleBack = () => {
    setIsSecondPage(false);
    setIsNewModelItem(null);
    setIsDelete(false);
    setSelectedData('');
    setSelectedOptions([]);
    setParentProtocol(null);
    handleClose();
  };

  const customHeader = (
    <div
      className="buttonContainer contentTitleLarge hoverText"
      onClick={handleBack}
    >
      <span className="">
        <HiPlus className="header-icon" style={{ marginLeft: '5px' }} /> Add
      </span>
    </div>
  );

  const isSaveValid = useMemo(() => {
    if (selectedData.type === 'Paired Protocol') {
      if (selectedProtocols?.length !== selectedOptions?.length) return true;
      else if (selectedProtocols) {
        let change = false;
        selectedProtocols.forEach((protocol) => {
          if (
            !selectedOptions.find((option: any) => option.uid === protocol.uid)
          ) {
            change = true;
          }
        });
        return change;
      }
    } else if (selectedData.type === 'Equipment') {
      if (selectedEquipment) {
        if (selectedEquipment.length !== selectedOptions.length) return true;
        else if (selectedEquipment) {
          let change = false;
          selectedEquipment.forEach((equipment) => {
            if (
              !selectedOptions.find(
                (option: any) => option.uid === equipment.uid
              )
            ) {
              change = true;
            }
          });
          return change;
        }
      } else if (
        protocol &&
        protocol.equipment &&
        protocol.equipment.length > 0
      ) {
        let change = false;
        protocol.equipment.forEach((equipment) => {
          if (
            !selectedOptions.find((option: any) => option.uid === equipment.uid)
          ) {
            change = true;
          }
        });
        return change;
      }
    }
    return selectedOptions?.length > 0;
  }, [
    selectedData.type,
    selectedOptions,
    selectedProtocols,
    selectedEquipment,
    selectedForm,
    protocol,
  ]);

  const selectedDataList: ModelSubItem<any>[] = useMemo(() => {
    if (parentProtocol) {
      if (selectedData.type === 'Medication') return parentProtocol.medications;
      else if (selectedData.type === 'Infusion')
        return parentProtocol.infusions;
      else if (selectedData.type === 'Electrical')
        return parentProtocol.electrical;
    }
    return [];
  }, [selectedData, parentProtocol]);

  const allProtocols: ProtocolItem[] = useMemo(() => {
    if (selectedData) {
      return database.protocols
        .filter((protocol) => {
          if (selectedData.type === 'Medication')
            return protocol.medications.length > 0;
          else if (selectedData.type === 'Infusion')
            return protocol.infusions.length > 0;
          else if (selectedData.type === 'Electrical')
            return protocol.electrical.length > 0;
          else return false;
        })
        .sort((a, b) => {
          if (a.parent.index === b.parent.index && a.index === b.index)
            return a.name.localeCompare(b.name);
          else if (a.parent.index === b.parent.index) return a.index - b.index;
          else return a.parent.index - b.parent.index;
        });
    }
    return [];
  }, [database.protocols, selectedData]);

  return (
    <div>
      {selectedMedication && (
        <NewMedicationDoseSideout
          visible={selectedMedication != null}
          protocol={protocol}
          parentModel={selectedMedication}
          handleClose={() => setSelectedMedication(undefined)}
          handleSubmit={() => {
            setSelectedMedication(undefined);

            handleSubmit && handleSubmit(true);
            handleBack();
          }}
        />
      )}

      {selectedElectrical && (
        <NewElectricalDoseSideout
          visible={selectedElectrical != null}
          protocol={protocol}
          parentModel={selectedElectrical}
          handleClose={() => setSelectedElectrical(undefined)}
          handleSubmit={() => {
            setSelectedElectrical(undefined);
            handleSubmit && handleSubmit(true);
            handleBack();
          }}
        />
      )}

      {selectedInfusion && (
        <NewInfusionDoseSideout
          visible={selectedInfusion != null}
          protocol={protocol}
          parentModel={selectedInfusion}
          handleClose={() => setSelectedInfusion(undefined)}
          handleSubmit={() => {
            setSelectedInfusion(undefined);
            handleSubmit && handleSubmit(true);
            handleBack();
          }}
        />
      )}

      {isDelete && (
        <ConfirmModal
          isVisible={isDelete}
          title="Remove Paired Protocol?"
          handleClose={() => {
            setIsDelete(false);
          }}
          handleSubmit={handleDeletePairedProtocol}
          isDeleteBtn={true}
          primaryBtnName="Cancel"
          secondaryBtnName="Delete"
          primaryDescription="Are you sure you want remove this paired protocol?"
        />
      )}
      <Sidebar
        visible={isVisible}
        position="right"
        onHide={handleBack}
        style={{ width: '25%', minWidth: '400px' }}
        className={
          isVisible ? 'protocolSidebar sidebarWidth' : 'sidebar-hidden'
        }
        header={customHeader}
      >
        <Sidebar
          visible={isSecondPage}
          position="right"
          onHide={() => {
            setIsSecondPage(false);
          }}
          style={{
            width: '25%',
            minWidth: '400px',
          }}
          className={
            isSecondPage ? 'protocolSidebar sidebarWidth' : 'sidebar-hidden'
          }
        >
          {/* Third Sidebar for New Medication */}
          {selectedData != null && isNewModelItem && (
            <NewModelItemSideout
              isVisible={isNewModelItem !== null}
              handleClose={() => setIsNewModelItem(null)}
              type={isNewModelItem}
            />
          )}
          <div style={{ height: '100%', padding: '10px' }}>
            <div
              className="buttonContainer contentTitleLarge hoverText"
              onClick={() => {
                setIsSecondPage(false);
              }}
            >
              <span className="headerTilte">
                <BiArrowBack
                  className="header-icon cursorPointer"
                  style={{ paddingLeft: '4px' }}
                />
                <HiPlus
                  className="header-icon "
                  style={{ marginLeft: '8px' }}
                />{' '}
                {selectedData.name}
              </span>
            </div>
            {selectedData.type === 'Paired Protocol' ? (
              <div className="contentText">
                Manage paired protocols to this protocol and add new by
                searching for existing protocols
              </div>
            ) : (
              <div className="contentText">
                Add a {selectedData?.type} to this protocol either be searching
                already made {selectedData?.type} or by creating a new{' '}
                {selectedData?.type}.
              </div>
            )}
            <div
              style={{
                paddingTop: '10px',
              }}
            >
              {(selectedData.type === 'Medication' ||
                selectedData.type === 'Infusion' ||
                selectedData.type === 'Electrical') && (
                <GeneralSelection
                  items={['Create New', 'Copy Protocol']}
                  selectedIndex={inputType}
                  onPress={(item, index) => {
                    setInputType(index);
                  }}
                  labelField={(option) => option}
                  valueField={(option) => option}
                />
              )}
              {inputType === CREATE_NEW ? (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  {selectedData.type !== 'Paired Protocol' && (
                    <>
                      <div className="contentLabelBold">
                        Create New {selectedData?.type}
                      </div>
                      <div
                        onClick={() => {
                          if (selectedData.type === 'Medication') {
                            if (hmDB.medications == null) loadHinckleyDB();
                            setIsNewModelItem('medication');
                          } else if (selectedData.type === 'Infusion') {
                            if (hmDB.infusions == null) loadHinckleyDB();
                            setIsNewModelItem('infusion');
                          } else if (selectedData.type === 'Electrical') {
                            if (hmDB.electrical == null) loadHinckleyDB();
                            setIsNewModelItem('electrical');
                          } else handleNavigateToNewPage(selectedData.type);
                        }}
                        className="createBtnhover cursorPointer contentBorder protocolCalculationPad contentHeadingBold newProtocolBorder "
                        style={{ display: 'flex', justifyContent: 'center' }}
                      >
                        <span>
                          <HiPlus className="text-icon " /> Create New{' '}
                          {selectedData.type}
                        </span>
                      </div>
                      <div className="contentLabelBold">
                        Select {selectedData?.type}
                      </div>
                    </>
                  )}
                  <SearchBar
                    value={value}
                    onChange={handleSearch}
                    placeholder="Search"
                    containerStyle={{ width: '100%' }}
                  />
                  <div
                    style={{ maxHeight: '60vh' }}
                    className="contentBorder protocolCalculationPad secondaryListScroll"
                  >
                    {selectedData.type === 'Paired Protocol' ||
                    selectedData.type === 'Checklist' ||
                    selectedData.type === 'Equipment' ? (
                      <ViewportList items={options}>
                        {(item: any, index: any) => (
                          <div
                            key={item?.name + index}
                            onClick={() => {
                              handleCheckboxChange(item);
                            }}
                            className={`radioBtnSelectedColor listhover cursorPointer item contentHeading contentHeight ${
                              selectedData.type === 'Checklist' &&
                              options?.length !== index + 1
                                ? 'contentUnderline '
                                : ''
                            } ${
                              selectedData.type === 'Paired Protocol' &&
                              pairedProtocolList?.length !== index + 1
                                ? 'contentUnderline '
                                : ''
                            }
                      ${
                        selectedData.type === 'Vital' &&
                        options?.length !== index + 1
                          ? 'contentUnderline '
                          : ''
                      }
                      ${
                        selectedData.type === 'Equipment' &&
                        options?.length !== index + 1
                          ? 'contentUnderline '
                          : ''
                      }`}
                          >
                            <RadioButton
                              inputId="pairedProtocol"
                              name="pairedProtocol"
                              className="radioBtnColor"
                              style={{ marginRight: '10px' }}
                              value={item.name}
                              checked={selectedOptions?.find(
                                (option: any) => option.uid === item.uid
                              )}
                              onChange={() => {
                                handleCheckboxChange(item);
                              }}
                            />
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                width: '100%',
                              }}
                            >
                              <div>{item.name}</div>
                              {selectedData.type !== 'Paired Protocol' && (
                                <div className="editIconContainer">
                                  <HiPencil
                                    onClick={() => {
                                      handleNavigateToEditPage(item);
                                    }}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      </ViewportList>
                    ) : (
                      <ViewportList items={options}>
                        {(item: any, index: any) => (
                          <div
                            key={item + index}
                            onClick={() => {
                              handleNavigateToEditPage(item);
                            }}
                            className={`listhover cursorPointer item contentHeading ${
                              options.length !== index + 1
                                ? 'contentUnderline'
                                : ''
                            }`}
                          >
                            {item?.name}
                          </div>
                        )}
                      </ViewportList>
                    )}
                  </div>

                  {(selectedData.type === 'Paired Protocol' ||
                    selectedData.type === 'Checklist' ||
                    selectedData.type === 'Equipment') && (
                    <div className="btn_Bottom">
                      <Button
                        data-testid="cancel"
                        className="secondary-button btn-rightMargin"
                        onClick={handleBack}
                      >
                        X Cancel
                      </Button>
                      <Button
                        data-testid="save"
                        className="primary-button"
                        disabled={!isSaveValid}
                        onClick={handleAddPairedProtocol}
                      >
                        + Add
                      </Button>
                    </div>
                  )}
                </div>
              ) : (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <div className="contentLabelBold">
                    Search Protocol: {allProtocols.length} items
                  </div>
                  <SearchableDropdown<ProtocolItem>
                    id="searchDropdown"
                    value={parentProtocol ? parentProtocol : undefined}
                    options={allProtocols}
                    labelField={(option) => {
                      if (selectedData?.type === 'Medication') {
                        return option.medications.length + ' - ' + option.name;
                      } else if (selectedData?.type === 'Infusion') {
                        return option.infusions.length + ' - ' + option.name;
                      } else if (selectedData?.type === 'Electrical') {
                        return option.electrical.length + ' - ' + option.name;
                      }
                      return option.name;
                    }}
                    valueField={(option) => option.name}
                    keyField={(option) => option.uid}
                    onChange={(option: ProtocolItem) => {
                      setParentProtocol(option);
                    }}
                    onClear={() => {
                      setParentProtocol(null);
                    }}
                    placeholder="Search protocol..."
                  />
                  <div
                    className="contentText"
                    style={{
                      marginTop: '10px',
                    }}
                  >
                    Found {selectedDataList.length} {selectedData?.type}s
                  </div>
                  <div
                    style={{ maxHeight: '60vh', marginTop: '0px' }}
                    className={`${
                      selectedDataList.length === 0 ? '' : 'contentBorder'
                    } protocolCalculationPad secondaryListScroll`}
                  >
                    <ViewportList items={selectedDataList}>
                      {(item: ModelSubItem<any>, index: any) => (
                        <div
                          key={item + index}
                          onClick={() => {
                            // handleNavigateToEditPage(item);
                          }}
                          className={`listhover cursorPointer item contentHeading ${
                            options.length !== index + 1
                              ? 'contentUnderline'
                              : ''
                          }`}
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            // width: '100%',
                          }}
                        >
                          <div>{item?.name}</div>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              flexDirection: 'row',
                            }}
                          >
                            <div>{item.fullBasis}</div>

                            {(item as any).routes && (
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                }}
                              >
                                <div
                                  style={{
                                    marginLeft: '10px',
                                    marginRight: '10px',
                                    color: '#696969',
                                  }}
                                >
                                  |
                                </div>
                                <div>
                                  {(item as any).routes
                                    .map((route: any) => route)
                                    .join(', ')}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </ViewportList>
                  </div>

                  <div className="btn_Bottom">
                    <Button
                      data-testid="cancel"
                      className="secondary-button btn-rightMargin"
                      onClick={handleBack}
                    >
                      X Cancel
                    </Button>
                    <Button
                      data-testid="save"
                      className="primary-button"
                      disabled={parentProtocol == null}
                      onClick={() => {
                        if (
                          handleCopyFromProtocol &&
                          parentProtocol &&
                          selectedData?.type
                        ) {
                          handleCopyFromProtocol(
                            parentProtocol,
                            selectedData.type
                          );
                          handleBack();
                        }
                      }}
                    >
                      <span>
                        <AiOutlinePlus className="icon-normal" />
                      </span>{' '}
                      {'Copy ' +
                        selectedDataList.length +
                        ' ' +
                        selectedData?.type}
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Sidebar>
        <div className="sidebarContainer">
          <div>
            <div
              className="buttonContainer contentTitleLarge"
              onClick={handleBack}
            >
              <span className="">
                <HiPlus
                  className="header-icon"
                  style={{ marginLeft: '0px', color: '#000' }}
                />
                Add
              </span>
            </div>
            <div className="contentText">
              Select an option to continue to edit the information that will be
              displayed on the Protocols Screen.
            </div>
            <div className="contentLabelBold">Select Option</div>
            <div className="contentBorder protocolCalculationPad primaryListScroll">
              <ViewportList items={sideoutList}>
                {(item: any, index: any) => (
                  <div
                    key={item + index}
                    onClick={() => {
                      setSelectedData(item);
                      setIsSecondPage(true);
                      setInputType(CREATE_NEW);
                    }}
                    className={`listhover spaceBetween cursorPointer item contentHeading ${
                      sideoutList?.length !== index + 1
                        ? 'contentUnderline'
                        : ''
                    }`}
                  >
                    <div>{item?.name}</div>
                    <div>
                      <span style={{ marginLeft: '0px' }}>
                        <BiSolidChevronRight className="icon_medium_small " />
                      </span>
                    </div>
                  </div>
                )}
              </ViewportList>
            </div>
          </div>
        </div>
      </Sidebar>
    </div>
  );
};

export default AddProtocolSideout;
