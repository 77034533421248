// Note: EditDB for CheckList/Form
// Date: 01/24/2024
// Author: Guruprasad Venkatraman

import { DataStore } from 'aws-amplify';
import {
  ResponseType,
  Response,
  DatabaseResponse,
  mapModelItems,
  executeSingleQuery,
  executeQuery,
} from '../AmplifyDB';
import { ProgressStatus } from '../../API';
import DepartmentItem, { getAllParentDepIDs } from '../model/DepartmentItem';
import FormItem from '../model/FormItem';
import DraftChangeItem, { DraftChangeType } from '../model/DraftChangeItem';
import { Form, FormGroup, FormOption, User } from '../../models';
import { checkIdUpdateDraftChange, Draft } from '../AmplifyVersion';
import { DraftChangeJSON, updateDraftChangeItem } from './ReviewalDB';
import {
  ArchiveItem,
  findUsersForModelItems,
  setAllArchiveItemsToNewActiveID,
  setAllReferencesToNewID,
} from './ModelDB';
import {
  formsByDepartmentID,
  getElectricalDose,
  getForm,
  listForms,
  protocolsByDepartmentID,
} from '../../graphql/queries';
import {
  confirmAllItemsHaveID,
  findItemByID,
  generateID,
  getActiveID,
  globals,
  removeTypename,
} from '../../ui/_global/common/Utils';
import { compareVersions } from '../../ui/_global/common/Utils';
import ProtocolItem from '../model/ProtocolItem';
import { updateForm, updateProtocol } from '../../graphql/mutations';

export type CheckListDB = {
  name: string;
  items: FormGroup[];
  departmentID: string;
  activeID?: string | null | undefined;
  overrideID?: string | null | undefined;
  version?: string | null | undefined;
  status?: ProgressStatus | keyof typeof ProgressStatus;
  modifiedBy?: string;
  createdBy: string;
};

/**
 * This function will check if the CheckList is a draft version and delete it
 * @param CheckListItem The CheckList to check
 * @returns Success if ready to create a new CheckList or Failure if there is a draft version
 */

export const checkUpgradeDraftVersion = async (
  id: string,
  isActive: boolean
): Promise<Response> => {
  try {
    let results: Form[];
    if (isActive)
      results = await DataStore.query(Form, (f) =>
        f.and((f) => [f.activeID.eq(id), f.status.eq('DRAFT')])
      );
    else
      results = await DataStore.query(Form, (f) =>
        f.and((f) => [f.status.eq('DRAFT'), f.id.eq(id)])
      );
    /* There is no current draft version */
    if (results == null || results.length === 0) {
      return {
        type: ResponseType.Success,
        data: undefined,
      };
    }
    if (results.length > 1) {
      return {
        type: ResponseType.Failure,
        data: 'There are multiple draft versions',
      };
    }

    let dbCheckList = results[0];
    if (dbCheckList.status === ProgressStatus.DRAFT) {
      let result = await DataStore.delete(Form, dbCheckList.id);
      if (result == null) {
        return {
          type: ResponseType.Failure,
          data: 'The dbCheckList did not delete correctly',
        };
      }
    }

    return {
      type: ResponseType.Success,
      data: dbCheckList,
    };
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

export const fetchChecklists = async (
  dep: DepartmentItem,
  useDatabase: boolean = false,
  waitForUsers: boolean = false
): Promise<Response> => {
  try {
    let depIDs = getAllParentDepIDs(dep);

    let checklistList = [];
    if (useDatabase) {
      checklistList = await DataStore.query(Form, (m) =>
        m.and((m) => [
          m.or((m) => depIDs.map((id) => m.departmentID.eq(id))),
          m.and((m) => [m.status.ne('ARCHIVE'), m.status.ne('DELETED')]),
        ])
      );
    } else {
      checklistList = await executeQuery(listForms, {
        filter: {
          and: [
            { or: depIDs.map((id) => ({ departmentID: { eq: id } })) },
            { status: { ne: 'ARCHIVE' } },
            { status: { ne: 'DELETED' } },
          ],
        },
      });
    }
    let checklists: FormItem[] = [];
    // let promises: Promise<User | null>[] = [];
    for (let i = 0; i < checklistList.length; i++) {
      let checklist = new FormItem(checklistList[i]);
      // promises.push(checklist.findUser());
      /* Take out the active version if there is one */
      mapModelItems(checklist, checklists, checklist.status, dep);
    }
    // if (waitForUsers) await Promise.all(promises);
    if (waitForUsers) await findUsersForModelItems(checklists);
    checklists.sort((a, b) => a.getName().localeCompare(b.getName()));

    return {
      type: ResponseType.Success,
      data: checklists,
    };
  } catch (error) {
    console.error('Error fetching checklists:', error);
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

/**
 * Create a new dbCheckList in the database and choose the version
 * @param dbCheckList CheckListDB JSON format
 * @returns The successful CheckListItem or the error
 */
export const createCheckList = async (
  checkList: CheckListDB | FormItem,
  previousItem?: FormItem
): Promise<Response> => {
  try {
    let json: CheckListDB;
    if (
      checkList instanceof FormItem ||
      (checkList as any).TAG === 'FormItem'
    ) {
      let checklistItem = checkList as FormItem;
      json = {
        name: checklistItem.name,
        status: checklistItem.status,
        activeID: checklistItem.activeID,
        overrideID: checklistItem.overrideID,
        departmentID: checklistItem.departmentID,
        version:
          checklistItem.version != null ? checklistItem.version : 'v1.0.0',
        items: checklistItem.items != null ? checklistItem.items : [],
        createdBy: checklistItem.model.createdBy
          ? checklistItem.model.createdBy
          : '',
        modifiedBy: checklistItem.modifiedBy
          ? checklistItem.modifiedBy.id
          : checklistItem.model.modifiedBy || undefined,
      };
    } else json = checkList;

    /* Confirm that all items have an ID */
    json.items = json.items.map((item: FormGroup, index: number) => {
      let newItem: FormGroup = {
        ...item,
        id: item.id ? item.id : generateID(),
        options: item.options
          ? item.options.map(
              (option: FormOption, index: number) =>
                ({
                  ...option,
                  id: option.id ? option.id : generateID(),
                  index: option.index == null ? index : option.index,
                }) as FormOption
            )
          : [],
        index: item.index == null ? index : item.index,
      };
      return newItem;
    });

    if (globals.debug) console.log('CHECKLIST json.items', json.items);

    let cleanedItems = removeTypename(
      json.items.map((item: FormGroup) => {
        let cleanedOptions = removeTypename(item.options);
        return {
          ...item,
          options: cleanedOptions,
        };
      })
    );

    /* 
			1. Creating a DRAFT the first time
			2. Creating a DRAFT from an ACTIVE version
			3. Updating a DRAFT from a DRAFT version
			4. Creating a ARCHIVE from an ACTIVE version
		*/
    let c: Form;

    /* Use Case 3: Updating a current DRAFT version */
    if (
      previousItem &&
      previousItem.status.includes('DRAFT') &&
      json.status?.includes('DRAFT')
    ) {
      let dbCheck = await DataStore.query(Form, previousItem.uid);
      if (dbCheck == null) {
        return {
          type: ResponseType.Failure,
          data: 'The DRAFT Checklist does not exist could not update',
        };
      }

      c = await DataStore.save(
        Form.copyOf(dbCheck, (updated) => {
          updated.name = json.name;
          updated.items = cleanedItems;
          updated.modifiedBy = json.modifiedBy;
        })
      );
    } else {
      /* Use Case 1, 2, & 4: Creating a DRAFT the first time */
      c = await DataStore.save(
        new Form({
          name: json.name,
          departmentID: json.departmentID,
          status: json.status,
          activeID: json.activeID,
          overrideID: json.overrideID,
          version: json.version,
          items: cleanedItems,
          createdBy: json.createdBy,
          modifiedBy: json.modifiedBy,
        })
      );
    }

    let checkListItem = new FormItem(c);
    return {
      type: ResponseType.Success,
      data: checkListItem,
    };
  } catch (e) {
    console.error('Error in checkList function: ', e);
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

/**
 * This function will publish the checkList to the database
 *    1. Create a new ARCHEIVED checkList based on the current ACTIVE checkList
 *    2. Update the ACTIVE checkList with the new information
 *    3. Delete the DRAFT checkList
 * @param draftCheckListItem The checkList to publish
 */

export const publishCheckList = async (
  draftCheckListItem: FormItem,
  draftChangeItem?: DraftChangeItem
): Promise<Response> => {
  try {
    /* Base Case 1 -- check if the checkList is configured correctly as a draft version */
    if (draftCheckListItem.status !== ProgressStatus.DRAFT) {
      return {
        type: ResponseType.Failure,
        data: 'The checkList is not a draft version',
      };
    }

    let dbForm = await DataStore.query(Form, draftCheckListItem.uid);
    if (dbForm == null) {
      return {
        type: ResponseType.Failure,
        data: 'The checkList does not exist',
      };
    }

    let activeCheckList: Form;

    /* Use case 1: Creating the FIRST active version */
    if (draftCheckListItem.activeID == null) {
      /* Update the draft checkList to be active */
      activeCheckList = await DataStore.save(
        Form.copyOf(dbForm, (updated) => {
          updated.status = ProgressStatus.ACTIVE;
        })
      );
    } else {
      /* Use case 2: Upgrading a active version */
      /* Step 1. Fetch the active checkList item */
      let id: string = draftCheckListItem.activeID;
      let curCheckList = await DataStore.query(Form, id);

      /* Base Case 3 -- check if the active checkList exists */
      if (curCheckList == null) {
        return {
          type: ResponseType.Failure,
          data: 'The active checkList does not exist',
        };
      }

      let archive = new FormItem(curCheckList);
      archive.status = ProgressStatus.ARCHIVE;
      archive.activeID = curCheckList.id;

      // 2. Create a new ARCHEIVED checkList based on the current ACTIVE checkList
      let archiveResult: Response = await createCheckList(archive);
      if (archiveResult.type === ResponseType.Failure) return archiveResult;
      archive = archiveResult.data as FormItem;

      // 2. Update the ACTIVE checkList with the new information
      activeCheckList = await DataStore.save(
        Form.copyOf(curCheckList, (updated) => {
          updated.name = draftCheckListItem.name;
          updated.items = draftCheckListItem.items;

          updated.createdBy = draftCheckListItem.model.createdBy;
          updated.modifiedBy = draftCheckListItem.model.modifiedBy;

          updated.status = ProgressStatus.ACTIVE;
          updated.activeID = null;
          updated.version = draftCheckListItem.version;
        })
      );

      // 3. Delete the DRAFT checkList
      let draftCheckList = await DataStore.delete(Form, draftCheckListItem.uid);
      if (draftCheckList == null) {
        return {
          type: ResponseType.Failure,
          data: 'The draft checkList does not exist',
        };
      }

      // 4. Query if there any closed draft changes with the actvie model item
      checkIdUpdateDraftChange(activeCheckList.id, archive.uid);

      // 5. If there is a draftChangeItem then update the changeID to the new active category and the previousID to the archeived category
      // 4. If there is a draftChangeItem then update the changeID to the new active category and the previousID to the archeived category
      if (draftChangeItem) {
        let new_dc: DraftChangeJSON = {
          previousDraftChange: draftChangeItem,
          changeItem: activeCheckList.id,
          changeType: draftChangeItem.changeType,
          previousItem: archive.uid,
          isClosed: true,
        };

        updateDraftChangeItem(new_dc).then((result) => {
          if (result == null) {
            return {
              type: ResponseType.Failure,
              data: 'The draft change item did not update correctly',
            };
          }
        });
      }
    }

    let checkListItem = new FormItem(activeCheckList);
    return {
      type: ResponseType.Success,
      data: checkListItem,
    };
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

export const deleteChecklistItem = async (
  checkListItem: FormItem,
  isSoft: boolean
): Promise<Response> => {
  try {
    let id: string = checkListItem.uid;
    if (isSoft) {
      let checklist = await DataStore.query(Form, id);
      if (checklist == null) {
        return {
          type: ResponseType.Failure,
          data: 'The checklist does not exist',
        };
      }
      console.log('Found Checklist Item: ', checklist);

      checkListItem.status = ProgressStatus.DELETED;
      console.log('Checklist Item Status: ', checkListItem);
      let response = await createCheckList(checkListItem);
      if (response.type === ResponseType.Failure) {
        return response;
      }
      let newDeletedChecklist = response.data as FormItem;
      console.log('New Deleted Checklist Item: ', newDeletedChecklist);
      let deletedItem = await DataStore.delete(Form, id).catch((error) => {
        console.error('Error in deleteChecklistItem: ', error);
        return null;
      });
      if (deletedItem == null) {
        return {
          type: ResponseType.Failure,
          data: 'The checklist did not delete correctly',
        };
      }
      console.log('Deleted Item: ', deletedItem);

      /* Update all the archive items to the new active item */
      setAllArchiveItemsToNewActiveID(
        checkListItem,
        newDeletedChecklist,
        updateForm,
        formsByDepartmentID,
        {
          departmentID: checkListItem.departmentID,
        }
      )
        .then((result: Response) => {
          if (result.type === ResponseType.Failure) {
            console.error(
              'Error in setAllArchiveItemsToNewActiveID',
              result.data
            );
            return;
          }
          if (globals.debug)
            console.log('setAllArchiveItemsToNewActiveID', result.data);
        })
        .catch((e) => {
          console.error('Error in setAllArchiveItemsToNewActiveID', e);
          return {
            type: ResponseType.Failure,
            data: e,
          };
        });

      /* Check to see if there was a DraftChange referencing this item and update it to the new ID */
      checkIdUpdateDraftChange(checkListItem.id, newDeletedChecklist.uid);

      /* Check to see if there was a Protocol referencing this item and update it to the new ID */
      setAllReferencesToNewID(
        checkListItem,
        newDeletedChecklist,
        updateProtocol,
        protocolsByDepartmentID,
        'formIDs',
        {
          departmentID: checkListItem.departmentID,
          isArray: true,
        }
      );
    } else {
      let checkList = await DataStore.delete(Form, id);
      if (checkList == null) {
        return {
          type: ResponseType.Failure,
          data: 'The checkList does not exist',
        };
      }
    }
    return {
      type: ResponseType.Success,
      data: checkListItem,
    };
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

export const isCheckListDraftCreated = async (
  department: DepartmentItem
): Promise<Response> => {
  try {
    let drafts = await DataStore.query(Form, (f) =>
      f.and((f) => [f.status.eq('DRAFT'), f.departmentID.eq(department.id)])
    );
    return {
      type: ResponseType.Success,
      data: drafts.length !== 0,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const getCheckListDrafts = async (
  db: DatabaseResponse
): Promise<Response> => {
  try {
    let updates: any[] = [];
    let modelUpdates = await DataStore.query(Form, (c) =>
      c.and((c) => [c.status.eq('DRAFT'), c.departmentID.eq(db.department.id)])
    );

    for (let i = 0; i < modelUpdates.length; i++) {
      let model = new FormItem(modelUpdates[i]);
      let activeItem = db.checklists.find(
        (c) =>
          c.uid === model.activeID ||
          (c.activeItem && c.activeItem.uid === model.activeID)
      );
      if (activeItem) {
        model.activeItem =
          activeItem.uid === model.activeID
            ? activeItem
            : activeItem.activeItem;
      }
      updates.push({
        model: model,
        title: 'Checklist ' + model.name,
        message: getChangeDescription(model),
        changeType: DraftChangeType.CHECKLIST,
      });
    }

    return {
      type: ResponseType.Success,
      data: updates,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const convertChecklistChangeToDraft = (
  dc: DraftChangeItem
): Draft | null => {
  try {
    if (dc.changeItem == null) {
      return null;
    }
    let update: Draft = {
      draftChangeItem: dc,
      model: dc.changeItem,
      title: 'CheckList ' + dc.changeItem.name,
      message: getChangeDescription(dc.changeItem as FormItem),
      changeType: DraftChangeType.CHECKLIST,
    };

    return update;
  } catch (error) {
    return null;
  }
};

function getChangeDescription(draftItem: FormItem): string {
  if (draftItem.activeItem == null)
    return `Created CheckList: ${draftItem.name}`;
  return `Updated CheckList: ${draftItem.name}`;
}

export const removeCurrentCheckListDrafts = async (
  db: DatabaseResponse
): Promise<Response> => {
  try {
    let updates: any[] = [];
    let draftCheckList = await DataStore.query(Form, (f) =>
      f.and((f) => [f.status.eq('DRAFT'), f.departmentID.eq(db.department.id)])
    );
    for (let i = 0; i < draftCheckList.length; i++) {
      let checkList: Form = draftCheckList[i];
      await DataStore.delete(checkList);
      updates.push({
        model: checkList,
        message: `Removed Checklist: ${checkList.name}`,
      });
    }

    return {
      type: ResponseType.Success,
      data: updates,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

/* GraphQL API Queries */
export const getChecklistByID = async (
  db: DatabaseResponse,
  id: string
): Promise<FormItem | null> => {
  return new Promise(async (resolve, reject) => {
    try {
      /* Fetch the category from the database */
      const dbMed = findItemByID(id, db.checklists);
      if (dbMed != null) return resolve(dbMed as FormItem);
      else {
        executeSingleQuery(getForm, { id: id }, 1500, false, true)
          .then((form) => {
            if (form == null) {
              resolve(null);
            } else {
              let formItem = new FormItem(form);
              resolve(formItem);
            }
          })
          .catch((error) => {
            reject(error);
          });
      }
    } catch (error: any) {
      reject(error);
    }
  });
};

/**
 * Fetch the checklists by the status
 * @param dep The department to fetch the checklists from
 * @param status The status of the checklists to fetch
 * @param waitForUsers Whether to wait for the users to be fetched
 * @returns The checklists that were fetched
 */
export const fetchCheckListsByStatusAPI = async (
  dep: DepartmentItem,
  db: DatabaseResponse,
  status: ProgressStatus = ProgressStatus.ACTIVE,
  waitForUsers: boolean = false,
  includeErrors: boolean = false
): Promise<Response> => {
  try {
    /* Fetch the checklists for this department and then sort by index */
    let depIDs = dep.getAllParentDepIDs();
    let start = new Date();
    let promises: any[] = [];
    for (let i = 0; i < depIDs.length; i++) {
      promises.push(
        executeQuery(formsByDepartmentID, {
          departmentID: depIDs[i],
          filter: {
            and: [
              {
                status: {
                  eq: status,
                },
              },
              {
                _deleted: {
                  ne: true,
                },
              },
            ],
          },
        })
      );
    }
    let checkListResults: any = await Promise.all(promises);
    let checkListList: FormItem[] = [];
    for (let i = 0; i < checkListResults.length; i++) {
      if (checkListResults[i] != null)
        checkListList.push(
          ...checkListResults[i].map((m: Form) => new FormItem(m))
        );
    }
    if (globals.debug)
      console.log(
        'Checklists fetched in',
        new Date().getTime() - start.getTime(),
        'ms',
        checkListList
      );

    let archiveItems: ArchiveItem[] = [];
    for (let i = 0; i < checkListList.length; i++) {
      const checkList = checkListList[i];
      if (checkList.activeID == null) {
        if (includeErrors === false) continue;
        console.log('Checklist has no activeID', checkList.name);
        let archiveItem = archiveItems.find((item) => item.id === 'ERROR');
        if (archiveItem) {
          let items = archiveItem.items;
          items.push(checkList);
          archiveItem.items = items;
        } else {
          archiveItems.push({
            id: 'ERROR',
            activeItem: checkList,
            items: [checkList],
          });
        }
      } else {
        /* 1-2-25 Gagan: Needed to check uid and activeID if an item is in DRAFT mode currently */
        const activeCheckList = db.checklists.find(
          (c) =>
            c.uid === checkList.activeID || c.activeID === checkList.activeID
        );
        if (activeCheckList == null) {
          if (includeErrors === false) continue;
          console.log('Checklist has no activeID', checkList.name);
        }
        let archiveItem = archiveItems.find(
          (item) => item.id === checkList.activeID
        );
        if (archiveItem) {
          let items = archiveItem.items;
          items.push(checkList);
          items.sort((a: FormItem, b: FormItem) => {
            if (a.version === b.version) return a.name.localeCompare(b.name);
            return compareVersions(
              a.version || 'v1.0.0',
              b.version || 'v1.0.0'
            ) as number;
          });
          archiveItem.items = items;
        } else {
          archiveItems.push({
            id: checkList.activeID || 'ERROR',
            activeItem: activeCheckList as FormItem,
            items: [checkList],
          });
        }
      }
    }

    return {
      type: ResponseType.Success,
      data: archiveItems,
    };
  } catch (error) {
    console.error('Error fetching checklists:', error);
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const fetchAllChecklistsForProtocol = async (
  db: DatabaseResponse,
  dep: DepartmentItem,
  protocol: ProtocolItem
): Promise<Response> => {
  try {
    let protocolID = getActiveID(protocol);
    if (protocolID == null) {
      return {
        type: ResponseType.Failure,
        data: 'The protocol does not have a proper version ID',
      };
    }

    let checklistIDs: string[] = protocol.checklistIDs ?? [];
    if (checklistIDs.length === 0) {
      return {
        type: ResponseType.Success,
        data: [],
      };
    }

    let checklistList = await executeQuery(formsByDepartmentID, {
      departmentID: dep.id,
      filter: {
        and: [
          {
            _deleted: {
              ne: true,
            },
          },
          {
            status: {
              ne: 'DEACTIVATED',
            },
          },
          {
            status: {
              ne: 'DRAFT',
            },
          },
        ],
      },
    });

    // Filter out items from checklistList that have uid or activeID that is not in the checklistIDs array
    checklistList = checklistList.filter(
      (item: any) =>
        checklistIDs.includes(item.id) || checklistIDs.includes(item.activeID)
    );

    checklistList = checklistList.map((item: any) => new FormItem(item));

    let archiveItems: ArchiveItem[] = [];
    for (let i = 0; i < checklistList.length; i++) {
      const med = checklistList[i];

      // When med is in ACTIVE or DELETED
      if (
        med.activeID == null &&
        (med.status === 'ACTIVE' || med.status === 'DELETED')
      ) {
        // Find for an existing archive item with the same uid
        let archiveItem = archiveItems.find((item) => item.id === med.uid);
        if (archiveItem) {
          archiveItem.activeItem = med;
        } else {
          // If not found, create a new archive item
          archiveItems.push({
            id: med.uid,
            activeItem: med,
            items: [],
          });
        }
      }
      // When med is in ARCHIVE or DELETED
      else if (med.activeID != null && med.status === 'ARCHIVE') {
        // Find for an existing archive item with the same activeID
        let archiveItem = archiveItems.find((item) => item.id === med.activeID);
        if (archiveItem) {
          // If found, add this med to the items array
          let items = archiveItem.items;
          items.push(med);
          // Sort the items array by version
          items.sort((a: FormItem, b: FormItem) => {
            if (a.version === b.version) return a.name.localeCompare(b.name);
            return compareVersions(
              a.version || 'v1.0.0',
              b.version || 'v1.0.0'
            ) as number;
          });
          archiveItem.items = items;
        } else {
          // If not found, create a new archive item
          archiveItems.push({
            id: med.activeID || 'ERROR',
            activeItem: med,
            items: [med],
          });
        }
      }
    }

    // Filter out any activeItems that are in the archiveItems with activeItem.status !== 'ACTIVE' or 'DELETED'
    archiveItems = archiveItems.filter(
      (item) =>
        item.activeItem.status === 'ACTIVE' ||
        item.activeItem.status === 'DELETED'
    );

    return {
      type: ResponseType.Success,
      data: archiveItems,
    };
  } catch (error) {
    console.error('Error fetching checklistList:', error);
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};
