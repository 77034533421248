// Note: EditDB for Vital
// Date: 01/19/2024
// Author: Guruprasad Venkatraman

import { DataStore, graphqlOperation } from 'aws-amplify';
import {
  ResponseType,
  Response,
  DatabaseResponse,
  executeQuery,
  mapModelItems,
  executeSingleQuery,
} from '../AmplifyDB';
import { ProgressStatus, VitalOption } from '../../API';
import VitalItem from '../model/VitalItem';
import DepartmentItem from '../model/DepartmentItem';
import {
  findItemByID,
  generateID,
  compareVersions,
  globals,
  removeTypename,
} from '../../ui/_global/common/Utils';
import DraftChangeItem, { DraftChangeType } from '../model/DraftChangeItem';
import { User, Vitals } from '../../models';
import { checkIdUpdateDraftChange, Draft } from '../AmplifyVersion';
import { DraftChangeJSON, updateDraftChangeItem } from './ReviewalDB';
import {
  getVitals,
  listVitals,
  vitalsByDepartmentID,
} from '../../graphql/queries';
import {
  ArchiveItem,
  findUsersForModelItems,
  setAllArchiveItemsToNewActiveID,
} from './ModelDB';
import { updateVitals } from '../../graphql/mutations';

export type VitalDB = {
  title: string;
  optionItems: any[];
  index?: number;
  departmentID: string;
  activeID: string | null | undefined;
  overrideID?: string | null | undefined;
  version: string | null | undefined;
  status: ProgressStatus | keyof typeof ProgressStatus;
  modifiedBy?: string;
  createdBy: string;
};

/**
 * This function will check if the Vital is a draft version and delete it
 * @param vitalItem The vital to check
 * @returns Success if ready to create a new vital or Failure if there is a draft version
 */

export const checkUpgradeDraftVersion = async (
  id: string,
  isActive: boolean
): Promise<Response> => {
  try {
    let results: Vitals[];
    if (isActive)
      results = await DataStore.query(Vitals, (v) =>
        v.and((v) => [v.status.eq('DRAFT'), v.activeID.eq(id)])
      );
    else
      results = await DataStore.query(Vitals, (v) =>
        v.and((v) => [v.status.eq('DRAFT'), v.id.eq(id)])
      );
    /* There is no current draft version */
    if (results == null || results.length === 0) {
      return {
        type: ResponseType.Success,
        data: undefined,
      };
    }
    if (results.length > 1) {
      return {
        type: ResponseType.Failure,
        data: 'There are multiple draft versions',
      };
    }

    let dbVital = results[0];
    if (dbVital.status === ProgressStatus.DRAFT) {
      let result = await DataStore.delete(Vitals, dbVital.id);
      if (result == null) {
        return {
          type: ResponseType.Failure,
          data: 'The vital did not delete correctly',
        };
      }
    }

    return {
      type: ResponseType.Success,
      data: dbVital,
    };
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

/**
 * Create a new vital in the database and choose the version
 * @param vital VitalDB JSON format
 * @returns The successful vitalItem or the error
 */
export const createVital = async (
  vital: VitalDB | VitalItem,
  previousItem?: VitalItem
): Promise<Response> => {
  try {
    let json: VitalDB;
    if (vital instanceof VitalItem || (vital as any).TAG === 'VitalItem') {
      let vitalItem = vital as VitalItem;
      json = {
        title: vitalItem.name,
        status: vitalItem.status,
        index: vitalItem.index,
        activeID: vitalItem.activeID,
        overrideID: vitalItem.overrideID,
        departmentID: vitalItem.departmentID,
        version: vitalItem.version != null ? vitalItem.version : 'v1.0.0',
        optionItems: vitalItem.options != null ? vitalItem.options : [],
        createdBy: vitalItem.model.createdBy ? vitalItem.model.createdBy : '',
        modifiedBy: vitalItem.modifiedBy
          ? vitalItem.modifiedBy.id
          : vitalItem.model.modifiedBy || undefined,
      };
    } else json = vital;

    /* Confirm that all items have an ID */
    json.optionItems = json.optionItems.map(
      (item: VitalOption, index: number) => {
        let newItem: VitalOption = {
          ...item,
          id: item.id ? item.id : generateID(),
          index: item.index == null ? index : item.index,
        };
        return newItem;
      }
    );

    let cleanedItems = removeTypename(json.optionItems);

    /* 
			1. Creating a DRAFT the first time
			2. Creating a DRAFT from an ACTIVE version
			3. Updating a DRAFT from a DRAFT version
			4. Creating a ARCHIVE from an ACTIVE version
		*/
    let v: Vitals;

    /* Use Case 3: Updating a current DRAFT version */
    if (
      previousItem &&
      previousItem.status.includes('DRAFT') &&
      json.status.includes('DRAFT')
    ) {
      let dbVital = await DataStore.query(Vitals, previousItem.uid);
      if (dbVital == null) {
        return {
          type: ResponseType.Failure,
          data: 'The DRAFT Vital does not exist could not update',
        };
      }

      v = await DataStore.save(
        Vitals.copyOf(dbVital, (updated) => {
          updated.title = json.title;
          updated.options = cleanedItems;
          updated.modifiedBy = json.modifiedBy;
          updated.index = json.index;
        })
      );
    } else {
      /* Use Case 1, 2, & 4: Creating a DRAFT the first time */
      v = await DataStore.save(
        new Vitals({
          title: json.title,
          departmentID: json.departmentID,
          status: json.status,
          activeID: json.activeID,
          overrideID: json.overrideID,
          version: json.version,
          options: cleanedItems,
          createdBy: json.createdBy,
          modifiedBy: json.modifiedBy,
          index: json.index,
        })
      );
    }

    if (globals.debug) console.log('Created vital:', v);
    let vitalItem = new VitalItem(v);
    return {
      type: ResponseType.Success,
      data: vitalItem,
    };
  } catch (e) {
    console.error('Error in createVital function: ', e);
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

/**
 * This function will publish the vital to the database
 *    1. Create a new ARCHEIVED vital based on the current ACTIVE vital
 *    2. Update the ACTIVE vital with the new information
 *    3. Delete the DRAFT vital
 * @param draftVitalItem The vital to publish
 */

export const publishVital = async (
  draftVitalItem: VitalItem,
  draftChangeItem?: DraftChangeItem
): Promise<Response> => {
  try {
    /* Base Case 1 -- check if the vital is configured correctly as a draft version */
    if (draftVitalItem.status !== ProgressStatus.DRAFT) {
      return {
        type: ResponseType.Failure,
        data: 'The vital is not a draft version',
      };
    }

    let dbVital = await DataStore.query(Vitals, draftVitalItem.uid);
    if (dbVital == null) {
      return {
        type: ResponseType.Failure,
        data: 'The vital does not exist',
      };
    }

    let activeVital: Vitals;

    /* Use case 1: Creating the FIRST active version */
    if (draftVitalItem.activeID == null) {
      /* Update the draft vital to be active */
      activeVital = await DataStore.save(
        Vitals.copyOf(dbVital, (updated) => {
          updated.status = ProgressStatus.ACTIVE;
        })
      );
      if (globals.debug)
        if (globals.debug)
          console.log('Created the first release of the vital:', activeVital);
    } else {
      /* Use case 2: Upgrading a active version */
      /* Step 1. Fetch the active vital item */
      let id: string = draftVitalItem.activeID;
      let curVital = await DataStore.query(Vitals, id);

      /* Base Case 3 -- check if the active vital exists */
      if (curVital == null) {
        return {
          type: ResponseType.Failure,
          data: 'The active vital does not exist',
        };
      }

      let archive = new VitalItem(curVital);
      archive.status = ProgressStatus.ARCHIVE;
      archive.activeID = curVital.id;

      // 2. Create a new ARCHEIVED vital based on the current ACTIVE vital
      let archiveResult: Response = await createVital(archive);
      if (archiveResult.type === ResponseType.Failure) return archiveResult;
      archive = archiveResult.data as VitalItem;

      // 2. Update the ACTIVE vital with the new information
      activeVital = await DataStore.save(
        Vitals.copyOf(curVital, (updated) => {
          updated.title = draftVitalItem.name;
          updated.options = draftVitalItem.options;

          updated.modifiedBy = draftVitalItem.model.modifiedBy;
          updated.createdBy = draftVitalItem.model.createdBy;

          updated.status = ProgressStatus.ACTIVE;
          updated.activeID = null;
          updated.version = draftVitalItem.version;
          updated.index = draftVitalItem.index;
        })
      );

      // 3. Delete the DRAFT vital
      let draftVital = await DataStore.delete(Vitals, draftVitalItem.uid);
      if (draftVital == null) {
        return {
          type: ResponseType.Failure,
          data: 'The draft vital does not exist',
        };
      }

      // 4. Query if there any closed draft changes with the active model item
      checkIdUpdateDraftChange(activeVital.id, archive.uid).then((result) => {
        console.log('Check Active to Archive Draft Change', result);
      });

      // 5. If there is a draftChangeItem then update the changeID to the new active category and the previousID to the archeived category
      if (draftChangeItem) {
        console.log('Draft Change Item', draftChangeItem);
        let new_dc: DraftChangeJSON = {
          previousDraftChange: draftChangeItem,
          changeItem: activeVital.id,
          changeType: draftChangeItem.changeType,
          previousItem: archive.uid,
          isClosed: true,
        };

        console.log('Preivous Draft Change Item', draftChangeItem);
        updateDraftChangeItem(new_dc).then((result) => {
          console.log('Modified Draft Change Item', result);
          if (result == null) {
            return {
              type: ResponseType.Failure,
              data: 'The draft change item did not update correctly',
            };
          }
        });
      }
    }

    let vitalItem = new VitalItem(activeVital);
    return {
      type: ResponseType.Success,
      data: vitalItem,
    };
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

export const deleteVitalItem = async (
  vitalItem: VitalItem,
  isSoft: boolean
): Promise<Response> => {
  try {
    let id: string = vitalItem.uid;
    if (isSoft) {
      let vital = await DataStore.query(Vitals, id);
      if (vital == null) {
        return {
          type: ResponseType.Failure,
          data: 'The vital does not exist',
        };
      }
      console.log('Found Vital Item: ', vitalItem);

      vitalItem.status = ProgressStatus.DELETED;
      console.log('Vital Item Status: ', vitalItem);
      let response = await createVital(vitalItem);
      if (response.type === ResponseType.Failure) {
        return response;
      }
      let newDeletedVital = response.data as VitalItem;
      console.log('New Deleted Vital Item: ', newDeletedVital);
      let deletedItem = await DataStore.delete(Vitals, id).catch((error) => {
        console.error('Error in deleteVitalItem: ', error);
        return null;
      });
      if (deletedItem == null) {
        return {
          type: ResponseType.Failure,
          data: 'The vital did not delete correctly',
        };
      }
      console.log('Deleted Item: ', deletedItem);

      /* Update all the archive items to the new active item */
      setAllArchiveItemsToNewActiveID(
        vitalItem,
        newDeletedVital,
        updateVitals,
        vitalsByDepartmentID,
        {
          departmentID: vitalItem.departmentID,
        }
      )
        .then((result: Response) => {
          if (result.type === ResponseType.Failure) {
            console.error(
              'Error in setAllArchiveItemsToNewActiveID',
              result.data
            );
            return;
          }
          if (globals.debug)
            console.log('setAllArchiveItemsToNewActiveID', result.data);
        })
        .catch((e) => {
          console.error('Error in setAllArchiveItemsToNewActiveID', e);
          return {
            type: ResponseType.Failure,
            data: e,
          };
        });

      /* Check to see if there was a DraftChange referencing this item and update it to the new ID */
      checkIdUpdateDraftChange(vitalItem.id, newDeletedVital.uid);
    } else {
      let vital = await DataStore.delete(Vitals, id);
      if (vital == null) {
        return {
          type: ResponseType.Failure,
          data: 'The vital does not exist',
        };
      }

      if (globals.debug) console.log('Hard Deleted vital:', vital);
    }
    return {
      type: ResponseType.Success,
      data: vitalItem,
    };
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

export const isVitalDraftCreated = async (
  department: DepartmentItem
): Promise<Response> => {
  try {
    let drafts = await DataStore.query(Vitals, (v) =>
      v.and((v) => [v.status.eq('DRAFT'), v.departmentID.eq(department.id)])
    );
    return {
      type: ResponseType.Success,
      data: drafts.length !== 0,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const getVitalDrafts = async (
  db: DatabaseResponse
): Promise<Response> => {
  try {
    let updates: any[] = [];
    let modelUpdates = await DataStore.query(Vitals, (c) =>
      c.and((c) => [c.status.eq('DRAFT'), c.departmentID.eq(db.department.id)])
    );

    for (let i = 0; i < modelUpdates.length; i++) {
      let model = new VitalItem(modelUpdates[i]);
      let activeItem = db.vitals.find(
        (c) =>
          c.uid === model.activeID ||
          (c.activeItem && c.activeItem.uid === model.activeID)
      );
      if (activeItem) {
        model.activeItem =
          activeItem.uid === model.activeID
            ? activeItem
            : activeItem.activeItem;
      }

      updates.push({
        model: model,
        title: 'Vital ' + model.name,
        message: getChangeDescription(model),
        changeType: DraftChangeType.VITAL,
      });
    }

    return {
      type: ResponseType.Success,
      data: updates,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const convertVitalChangeToDraft = (
  dc: DraftChangeItem
): Draft | null => {
  try {
    if (dc.changeItem == null) {
      return null;
    }
    let update: Draft = {
      draftChangeItem: dc,
      model: dc.changeItem,
      title: 'Vital ' + dc.changeItem.name,
      message: getChangeDescription(dc.changeItem as VitalItem),
      changeType: DraftChangeType.VITAL,
    };

    return update;
  } catch (error) {
    return null;
  }
};

function getChangeDescription(draftItem: VitalItem): string {
  if (draftItem.activeItem == null) return `Created Vital: ${draftItem.name}`;
  return `Updated Vital: ${draftItem.name}`;
}

export const removeCurrentVitalDrafts = async (
  db: DatabaseResponse
): Promise<Response> => {
  try {
    let updates: any[] = [];
    let draftVitals = await DataStore.query(Vitals, (v) =>
      v.and((v) => [v.status.eq('DRAFT'), v.departmentID.eq(db.department.id)])
    );
    if (globals.debug) console.log('Found draft vitals:', draftVitals.length);
    for (let i = 0; i < draftVitals.length; i++) {
      let vital: Vitals = draftVitals[i];
      if (globals.debug) console.log('Removing vitals:', vital.title);
      await DataStore.delete(vital);
      updates.push({
        model: vital,
        message: `Removed Folder: ${vital.title}`,
      });
    }

    return {
      type: ResponseType.Success,
      data: updates,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const fetchVitals = async (
  dep: DepartmentItem,
  useDataStore = true,
  waitForUsers = false
): Promise<Response> => {
  try {
    let depIDs = [dep.id];
    if (dep.parentDep) depIDs.push(dep.parentDep.id);
    if (dep.parentDep?.parentDep) depIDs.push(dep.parentDep.parentDep.id);

    let vitalsList: Vitals[] = [];
    if (useDataStore) {
      vitalsList = await DataStore.query(Vitals, (m) =>
        m.and((m) => [
          m.or((m) => depIDs.map((id) => m.departmentID.eq(id))),
          m.and((m) => [m.status.ne('ARCHIVE'), m.status.ne('DELETED')]),
        ])
      );
    } else {
      vitalsList = await executeQuery(listVitals, {
        filter: {
          and: [
            { or: depIDs.map((id) => ({ departmentID: { eq: id } })) },
            {
              and: [
                { status: { ne: 'ARCHIVE' } },
                { status: { ne: 'DELETED' } },
              ],
            },
          ],
        },
      });
    }

    // let promises: Promise<User | null>[] = [];
    let vitals: VitalItem[] = [];
    for (let i = 0; i < vitalsList.length; i++) {
      let vital = new VitalItem(vitalsList[i]);
      // promises.push(vital.findUser());
      /* Take out the active version if there is one */
      mapModelItems(vital, vitals, vital.status, dep);
    }
    // if (waitForUsers) await Promise.all(promises);
    if (waitForUsers) await findUsersForModelItems(vitals);

    vitals.sort((a, b) => {
      if (a.index === b.index) return a.name.localeCompare(b.name);
      return a.index - b.index;
    });
    return {
      type: ResponseType.Success,
      data: vitals,
    };
  } catch (error) {
    console.error('Error fetching vitals:', error);
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

/* GraphQL API Queries */
export const getVitalByID = async (
  db: DatabaseResponse,
  id: string
): Promise<VitalItem | null> => {
  return new Promise(async (resolve, reject) => {
    try {
      /* Fetch the category from the database */
      const dbEquip = findItemByID(id, db.vitals);
      if (dbEquip != null) return resolve(dbEquip as VitalItem);
      else {
        executeSingleQuery(getVitals, { id: id }, 1500)
          .then((vitals) => {
            if (vitals == null) {
              resolve(null);
            } else {
              let vital = new VitalItem(vitals);
              /* TODO MAP THE SUB ITEMS AND TO PROTOCOLS */
              resolve(vital);
            }
          })
          .catch((error) => {
            reject(error);
          });
      }
    } catch (error: any) {
      reject(error);
    }
  });
};

/**
 * Fetch the vitals by the status
 * @param dep The department to fetch the vitals from
 * @param status The status of the vitals to fetch
 * @param waitForUsers Whether to wait for the users to be fetched
 * @returns The vitals that were fetched
 */
export const fetchVitalsByStatusAPI = async (
  dep: DepartmentItem,
  db: DatabaseResponse,
  status: ProgressStatus = ProgressStatus.ACTIVE,
  waitForUsers: boolean = false,
  includeErrors: boolean = false
): Promise<Response> => {
  try {
    /* Fetch the protocols for this category and then sort by index */
    let depIDs = dep.getAllParentDepIDs();
    let start = new Date();
    let promises: any[] = [];
    for (let i = 0; i < depIDs.length; i++) {
      promises.push(
        executeQuery(vitalsByDepartmentID, {
          departmentID: depIDs[i],
          filter: {
            and: [
              {
                status: {
                  eq: status,
                },
              },
              {
                _deleted: {
                  ne: true,
                },
              },
            ],
          },
        })
      );
    }
    let vitalResults: any = await Promise.all(promises);
    let vitalList: VitalItem[] = [];
    for (let i = 0; i < vitalResults.length; i++) {
      if (vitalResults[i] != null)
        vitalList.push(...vitalResults[i].map((m: Vitals) => new VitalItem(m)));
    }
    if (globals.debug)
      console.log(
        'Vitals fetched in',
        new Date().getTime() - start.getTime(),
        'ms',
        vitalList
      );

    let archiveItems: ArchiveItem[] = [];
    for (let i = 0; i < vitalList.length; i++) {
      const vital = vitalList[i];
      if (vital.activeID == null) {
        if (includeErrors === false) continue;
        console.log('Vital has no activeID', vital.name);
        let archiveItem = archiveItems.find((item) => item.id === 'ERROR');
        if (archiveItem) {
          let items = archiveItem.items;
          items.push(vital);
          archiveItem.items = items;
        } else {
          archiveItems.push({
            id: 'ERROR',
            activeItem: vital,
            items: [vital],
          });
        }
      } else {
        /* 1-2-25 Hazlett: Needed to check uid and activeID if an item is in DRAFT mode currently */
        const activeVital = db.vitals.find(
          (c) => c.uid === vital.activeID || c.activeID === vital.activeID
        );
        if (activeVital == null) {
          if (includeErrors === false) continue;
          console.log('Vital has no activeID', vital.name);
        }
        let archiveItem = archiveItems.find(
          (item) => item.id === vital.activeID
        );
        if (archiveItem) {
          let items = archiveItem.items;
          items.push(vital);
          items.sort((a: VitalItem, b: VitalItem) => {
            if (a.version === b.version) return a.name.localeCompare(b.name);
            return compareVersions(
              a.version || 'v1.0.0',
              b.version || 'v1.0.0'
            ) as number;
          });
          archiveItem.items = items;
        } else {
          archiveItems.push({
            id: vital.activeID || 'ERROR',
            activeItem: activeVital as VitalItem,
            items: [vital],
          });
        }
      }
    }

    return {
      type: ResponseType.Success,
      data: archiveItems,
    };
  } catch (error) {
    console.error('Error fetching protocols:', error);
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};
