import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ProtocolHeader from '../../protocol/ProtocolHeader';
import {
  getFormattedDate,
  getDepartmentsFromState,
  upgradeVersion,
  globals,
  compareVersions,
} from '../../../_global/common/Utils';
import { FaChevronRight, FaLock } from 'react-icons/fa6';
import CategoryItem from '../../../../data/model/CategoryItem';
import { Category, ProgressStatus, User } from '../../../../models';
import Status from '../../../components/ProgressStatus/ProgressStatus';
import { Alert, Snackbar } from '@mui/material';
import {
  ArchiveItem,
  reloadModelItem,
} from '../../../../data/functions/ModelDB';
import ConfirmModal from '../../../components/Modal/ConfirmModal';
import FolderArchiveModal from '../../../components/Modal/FolderArchiveModal';
import DataList, { Header } from '../../database/lists/DataList';
import {
  createCategory,
  deleteCategoryItem,
} from '../../../../data/functions/CategoryDB';
import { ResponseType } from '../../../../data/AmplifyDB';

const DEFAULT_USERNAME = 'Hinckley Medical';

const ListArchiveFoldersDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;

  if (
    !state?.data ||
    !state?.department ||
    !state?.database ||
    !state?.archiveData ||
    !state?.activeItem
  ) {
    navigate('/archive');
  }

  const {
    data: sourceData,
    department,
    database,
    archiveData: category,
    activeItem,
  } = state;

  const user: User = useSelector((state: any) => state?.user);
  const reducerState = useSelector((state: any) => state.department);

  const [categoryList, setCategoryList] = useState<CategoryItem[]>(
    category.sort((a: CategoryItem, b: CategoryItem) =>
      compareVersions(b.version, a.version)
    )
  );
  const [activeParmCategory, setActiveParmCategory] =
    useState<CategoryItem>(activeItem);

  const [selectedFolder, setSelectedFolder] = useState<CategoryItem | null>(
    null
  );
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [sourceArchiveData, setSourceArchiveData] =
    useState<ArchiveItem[]>(sourceData);

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success' as 'success' | 'error' | 'warning' | 'info',
  });

  const { allSubDeps } = useMemo(() => {
    return getDepartmentsFromState(department, reducerState);
  }, [department, reducerState]);

  // Check if the most recent version of the folder is a draft
  const isDraft = useMemo(
    () => activeParmCategory.status === ProgressStatus.DRAFT,
    [activeParmCategory]
  );

  const [modal, setModal] = useState({
    isVisible: false,
    title: '',
    primaryDescription: '',
    primaryBtnName: '',
    secondaryBtnName: '',
    handleClose: () => {},
    handleSubmit: () => {},
  });

  const reloadItem = async () => {
    const response = await reloadModelItem(Category, activeParmCategory);
    if (response.type === ResponseType.Success) {
      const item = new CategoryItem(response.data.item as Category);
      const activeItem = response.data.activeItem as
        | Category
        | null
        | undefined;
      item.activeItem = activeItem ? new CategoryItem(activeItem) : null;
      setActiveParmCategory(item);
    }
  };

  /**
   * Check if the draft exists for the active item
   */
  useEffect(() => {
    reloadItem();
  }, []);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.ctrlKey && event.shiftKey && event.key === 'O') {
        console.log('ARCHIVE FOLDERS DETAILS', category);
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [category]);

  const handleItemClick = useCallback((folder: CategoryItem) => {
    setSelectedFolder(folder);
    setSidebarVisible(true);
  }, []);

  const handleCloseModal = () => {
    setSidebarVisible(false);
    setSelectedFolder(null);
  };

  const handleBack = useCallback(() => {
    navigate('/archive/list-folders', {
      state: {
        data: sourceData,
        department: department,
        database: database,
      },
    });
  }, [navigate, sourceData, department, database]);

  const handleFolderRestore = async () => {
    try {
      setModal({ ...modal, isVisible: false });
      const oldActiveItem = isDraft
        ? activeParmCategory.activeItem
        : activeParmCategory;

      if (isDraft) {
        // Delete the existing draft
        const response = await deleteCategoryItem(
          department,
          activeParmCategory as CategoryItem,
          user,
          false
        );

        if (response.type === ResponseType.Failure) {
          setSnackbar({
            open: true,
            message: 'Failed to delete already existing draft.',
            severity: 'error',
          });
          // Exit the function
          console.error('Failed to delete Folder', response.data);
          setSidebarVisible(false);
          setSelectedFolder(null);
          return;
        }
      }

      let newItem = new CategoryItem((selectedFolder as CategoryItem)?.model);
      newItem.protocols = (selectedFolder as CategoryItem)?.protocols;
      newItem.subCategories = (selectedFolder as CategoryItem)?.subCategories;
      newItem.index =
        selectedFolder?.model?.index || selectedFolder?.index || 0;
      newItem.activeID = oldActiveItem?.uid;
      newItem.status = ProgressStatus.DRAFT;
      newItem.version = upgradeVersion(
        oldActiveItem?.version ? oldActiveItem?.version : 'v1.0.0'
      );
      newItem.modifiedBy = user;
      let results = await createCategory(
        newItem,
        oldActiveItem as CategoryItem
      );

      if (results.type === ResponseType.Success) {
        const newDraftFolder = results.data as CategoryItem;
        newDraftFolder.activeItem = oldActiveItem as CategoryItem;

        setSnackbar({
          open: true,
          message:
            'Successfully Restored this folder with a new DRAFT version ' +
            newDraftFolder.version,
          severity: 'success',
        });

        // Update data with new draft
        const newSourceData = sourceData.map((item: ArchiveItem) =>
          item.activeItem?.uid === activeParmCategory.uid
            ? { ...item, activeItem: newDraftFolder }
            : item
        );
        setSourceArchiveData(newSourceData);
        setActiveParmCategory(newDraftFolder);

        if (globals.debug)
          console.log('Successfully restored folder', results.data);

        setModal({
          isVisible: true,
          title: 'Successfully Restored Folder',
          primaryDescription: `Successfully restored ${newDraftFolder.name} to version ${newDraftFolder.version}. Would you like to navigate to the folder?`,
          primaryBtnName: 'Dismiss',
          secondaryBtnName: 'Navigate',
          handleClose: () => {
            setModal({ ...modal, isVisible: false });
            navigate('/archive/list-folders-details', {
              state: {
                data: newSourceData,
                department: department,
                database: database,
                archiveData: category,
                activeItem: newDraftFolder,
              },
            });
          },
          handleSubmit: () => {
            navigate('/protocol/edit', {
              state: {
                initialSelectedItem: newDraftFolder,
              },
            });
          },
        });
      } else if (results.type === ResponseType.Failure) {
        setSnackbar({
          open: true,
          message: 'Failed to restore folder.',
          severity: 'error',
        });
      }

      setSidebarVisible(false);
      setSelectedFolder(null);
    } catch (error) {
      console.error('Failed to restore folder', error);
    }
  };

  const handleSortItem = useCallback(
    (key: string, direction: string, a: CategoryItem, b: CategoryItem) => {
      if (key === 'version') {
        const ver1 = a.version.replace(/^v/, '').split('.').map(Number);
        const ver2 = b.version.replace(/^v/, '').split('.').map(Number);

        for (let i = 0; i < Math.max(ver1.length, ver2.length); i++) {
          const num1 = ver1[i] || 0;
          const num2 = ver2[i] || 0;
          if (num1 !== num2) {
            return direction === 'asc' ? num1 - num2 : num2 - num1;
          }
        }
        return 0;
      } else if (key === 'name') {
        return direction === 'asc'
          ? a.name.localeCompare(b.name)
          : b.name.localeCompare(a.name);
      } else if (key === 'subs') {
        const aAvailable = (allSubDeps?.length ?? 0) + 1;
        const bAvailable = (allSubDeps?.length ?? 0) + 1;
        const aCount = !a.isRestrictive
          ? aAvailable
          : (a.pairedDepIDs?.length ?? 0);
        const bCount = !b.isRestrictive
          ? bAvailable
          : (b.pairedDepIDs?.length ?? 0);
        return direction === 'asc' ? aCount - bCount : bCount - aCount;
      } else if (key === 'access') {
        const getAccessValue = (item: CategoryItem) => {
          if (!item.model.isPublic) return 0; // Private
          if (item.model.keychainID) return 1; // Protected
          return 2; // Public
        };
        return direction === 'asc'
          ? getAccessValue(a) - getAccessValue(b)
          : getAccessValue(b) - getAccessValue(a);
      } else if (key === 'archived_date') {
        const aDate = new Date(a.modifiedAt || Date.now());
        const bDate = new Date(b.modifiedAt || Date.now());
        return direction === 'asc'
          ? aDate.getTime() - bDate.getTime()
          : bDate.getTime() - aDate.getTime();
      } else if (key === 'archived_by') {
        const aUserId = a.model?.modifiedBy || a.model?.createdBy;
        const bUserId = b.model?.modifiedBy || b.model?.createdBy;

        const aUser = aUserId
          ? database.users.find((user: User) => user.id === aUserId)
          : null;
        const bUser = bUserId
          ? database.users.find((user: User) => user.id === bUserId)
          : null;

        const aName = aUser
          ? `${aUser.firstName} ${aUser.lastName}`
          : DEFAULT_USERNAME;
        const bName = bUser
          ? `${bUser.firstName} ${bUser.lastName}`
          : DEFAULT_USERNAME;

        return direction === 'asc'
          ? aName.localeCompare(bName)
          : bName.localeCompare(aName);
      }
      return 0;
    },
    [database.users, allSubDeps]
  );

  const headers: Header<CategoryItem>[] = useMemo(
    () => [
      {
        key: 'version',
        name: 'Version',
        sortable: true,
        flex: 0.5,
        render: (item: CategoryItem) => item.version,
      },
      {
        key: 'name',
        name: 'Name',
        sortable: true,
        flex: 2,
        render: (item: CategoryItem) => (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {!(
              item.departmentID === department.id &&
              item.status !== 'DEACTIVATED'
            ) && (
              <div style={{ marginRight: '8px' }}>
                <FaLock className="table-icon" color="#A3A3A3" />
              </div>
            )}
            {item.name}
            <Status status={item.status} style={{ marginLeft: '8px' }} />
          </div>
        ),
      },
      {
        key: 'subs',
        name: 'Subs',
        sortable: true,
        flex: 0.5,
        render: (item: CategoryItem) => {
          const availableSubs = (allSubDeps?.length ?? 0) + 1;
          const itemSubs = !item.isRestrictive
            ? availableSubs
            : (item.pairedDepIDs?.length ?? 0);
          return `${itemSubs}/${availableSubs}`;
        },
      },
      {
        key: 'access',
        name: 'Access',
        sortable: true,
        flex: 0.5,
        render: (item: CategoryItem) => {
          const status: 'Private' | 'Public' | 'Protected' = !item.model
            .isPublic
            ? 'Private'
            : item.model.keychainID
              ? 'Protected'
              : 'Public';
          return (
            <span style={{ flex: 6, textAlign: 'center' }}>
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Status status={status} />
              </div>
            </span>
          );
        },
      },
      {
        key: 'archived_date',
        name: 'Archived Date',
        sortable: true,
        flex: 1,
        render: (item: CategoryItem) =>
          getFormattedDate(new Date(item.modifiedAt || Date.now()), true),
      },
      {
        key: 'archived_by',
        name: 'Archived By',
        sortable: true,
        flex: 1,
        render: (item: CategoryItem) => {
          const userId = item.model?.modifiedBy || item.model?.createdBy;
          const user = userId
            ? database.users.find((user: User) => user.id === userId)
            : null;
          const username = user
            ? `${user.firstName} ${user.lastName}`
            : DEFAULT_USERNAME;
          return (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
                paddingRight: '15px',
              }}
            >
              <span>{username}</span>
              <FaChevronRight
                className="icon-normal"
                style={{ margin: '4px' }}
              />
            </div>
          );
        },
      },
    ],
    [department.id, database.users, allSubDeps]
  );

  const handleRestorePressed = async () => {
    if (isDraft) {
      setModal({
        isVisible: true,
        title: 'Override Existing Draft?',
        primaryDescription: `There is already a draft of ${activeParmCategory.name} (${activeParmCategory.version}) which is not yet published. Are you sure you want to override the existing draft and restore ${selectedFolder?.name} of version ${selectedFolder?.version}?`,
        primaryBtnName: 'Cancel',
        secondaryBtnName: 'Restore',
        handleClose: () => {
          setModal({ ...modal, isVisible: false });
        },
        handleSubmit: handleFolderRestore,
      });
    } else {
      setModal({
        isVisible: true,
        title: 'Restore Folder?',
        primaryDescription: `Are you sure you want to restore ${selectedFolder?.name} of version ${selectedFolder?.version}? This will create a new draft version of the folder.`,
        primaryBtnName: 'Cancel',
        secondaryBtnName: 'Restore',
        handleClose: () => {
          setModal({ ...modal, isVisible: false });
        },
        handleSubmit: handleFolderRestore,
      });
    }
  };

  return (
    <div className="screen-container">
      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>

      <ConfirmModal
        isVisible={modal.isVisible}
        title={modal.title}
        primaryDescription={modal.primaryDescription}
        primaryBtnName={modal.primaryBtnName}
        secondaryBtnName={modal.secondaryBtnName}
        handleClose={modal.handleClose}
        handleSubmit={modal.handleSubmit}
      />

      {selectedFolder && sidebarVisible && (
        <FolderArchiveModal
          isVisible={sidebarVisible}
          handleClose={handleCloseModal}
          folder={selectedFolder}
          activeID={activeParmCategory.activeID ?? activeParmCategory.uid}
          onRestore={handleRestorePressed}
        />
      )}

      <ProtocolHeader
        isBackButton={true}
        handleCancel={handleBack}
        name={`${activeParmCategory.activeItem?.name || activeParmCategory.name}: ${categoryList.length - 1} archive${categoryList.length - 1 === 1 ? '' : 's'}`}
        page={'Folders Archive'}
        type="protocol"
      />

      <DataList<CategoryItem, never>
        items={categoryList}
        headers={headers}
        onItemClick={handleItemClick}
        searchPlaceholder="Search Folders..."
        sortItem={handleSortItem}
      />
    </div>
  );
};

export default ListArchiveFoldersDetails;
