import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ProtocolHeader from '../../protocol/ProtocolHeader';
import { FaChevronRight } from 'react-icons/fa6';
import {
  compareVersions,
  getFormattedDate,
  linkSubItemsToItem,
} from '../../../_global/common/Utils';
import { User } from '../../../../models';
import Status from '../../../components/ProgressStatus/ProgressStatus';
import CPRItem from '../../../../data/model/CPRItem';
import ElectricalSubItem from '../../../../data/model/ElectricalSubItem';
import MedicationSubItem from '../../../../data/model/MedicationSubItem';
import DataList, { Header } from '../../database/lists/DataList';
import EquipmentItem from '../../../../data/model/EquipmentItem';

const DEFAULT_USERNAME = 'Hinckley Medical';

/* 12-31-2024 Gagan: Added this file to list the CPR Assist items in the archive */
const ListArchiveCPRAssistDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;

  // Validate location state
  if (!state?.data || !state?.department || !state?.database) {
    navigate('/archive');
  }

  const { data: sourceData, subData, department, database } = state;

  const [defibSubDoses, epiSubDoses] = subData;

  const activeParmMedication: CPRItem = useMemo(
    () => (sourceData.length > 0 ? sourceData[0].activeItem : null),
    [sourceData]
  );

  const cprAssists: CPRItem[] = useMemo(
    () =>
      (sourceData.length > 0
        ? [
            activeParmMedication.status === 'DRAFT'
              ? activeParmMedication.activeItem
              : activeParmMedication,
            ...sourceData[0].items,
          ]
        : []
      ).sort((a, b) => compareVersions(b.version, a.version)),
    [activeParmMedication, sourceData]
  );

  const [filteredCPRAssists, setFilteredCPRAssists] =
    useState<CPRItem[]>(cprAssists);

  const [searchQuery, setSearchQuery] = useState('');

  const filterCPRAssists = useCallback((items: CPRItem[], query: string) => {
    return (items as CPRItem[]).filter((item: CPRItem) => {
      return item.name?.toLowerCase()?.includes(query.toLowerCase());
    });
  }, []);

  const handleItemClick = useCallback(
    (cpr: CPRItem) => {
      navigate(`/archive/cprassist-archive`, {
        state: {
          value: cpr,
          sourceData: sourceData,
          subData: subData,
          department: department,
          database: database,
        },
      });
    },
    [navigate, sourceData, department, database, subData]
  );

  const handleBack = useCallback(() => {
    sessionStorage.removeItem('DataList-' + location.pathname);
    navigate('/archive');
  }, [navigate, location.pathname]);

  useEffect(() => {
    const filtered = filterCPRAssists(cprAssists, searchQuery) as CPRItem[];
    setFilteredCPRAssists(filtered);
  }, [searchQuery, cprAssists, filterCPRAssists]);

  /* Hotkey detection */
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.ctrlKey && event.shiftKey && event.key === 'O') {
        console.log('Active Medication', activeParmMedication);
        console.log('Archive CPRAssists', cprAssists);
        console.log('Filtered CPRAssists', filteredCPRAssists);
      }
    };

    // Add event listener
    document.addEventListener('keydown', handleKeyDown);

    // Remove event listener on cleanup
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [activeParmMedication, cprAssists, filteredCPRAssists]);

  useEffect(() => {
    const mapDosesShocks = async () => {
      try {
        setFilteredCPRAssists((prev: CPRItem[]) =>
          (prev as CPRItem[]).map((cprItem: CPRItem) => {
            const tempDefibShocks = linkSubItemsToItem(cprItem, defibSubDoses);
            cprItem.defibShocks = [];
            cprItem.defibShocks.push(
              ...(tempDefibShocks as ElectricalSubItem[])
            );

            const tempEpiDoses = linkSubItemsToItem(cprItem, epiSubDoses);
            cprItem.epiDoses = [];
            cprItem.epiDoses.push(...(tempEpiDoses as MedicationSubItem[]));

            return cprItem;
          })
        );
      } catch (err) {
        console.error('Error fetching medication doses:', err);
      }
    };

    mapDosesShocks();
  }, [defibSubDoses, epiSubDoses]);

  const headers: Header<CPRItem>[] = useMemo(
    () => [
      {
        key: 'version',
        name: 'Version',
        sortable: true,
        flex: 0.5,
        render: (item: CPRItem) => (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {item.version}
          </div>
        ),
      },
      {
        key: 'status',
        name: 'Status',
        sortable: false,
        flex: 0.5,
        render: (item: CPRItem) => (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Status status={item.status} />
          </div>
        ),
      },
      {
        key: 'epi_doses',
        name: 'Epi Doses',
        sortable: true,
        flex: 1,
        render: (item: CPRItem) => <div>{item.epiDoses?.length || 0}</div>,
      },
      {
        key: 'defib_shocks',
        name: 'Defib Shocks',
        sortable: true,
        flex: 1,
        render: (item: CPRItem) => <div>{item.defibShocks?.length || 0}</div>,
      },
      {
        key: 'equipment',
        name: 'Equipment',
        sortable: true,
        flex: 1,
        render: (item: CPRItem) => {
          const equipmentID = item.model.equipmentID;
          const equipment = database.equipment.find(
            (equipment: EquipmentItem) => equipment.id === equipmentID
          );
          return <div>{equipment?.name || '-'}</div>;
        },
      },
      {
        key: 'archived_date',
        name: 'Archived Date',
        sortable: true,
        flex: 1,
        render: (item: CPRItem) => (
          <div>
            {getFormattedDate(new Date(item?.modifiedAt || Date.now()), true)}
          </div>
        ),
      },
      {
        key: 'archived_by',
        name: 'Archived By',
        sortable: true,
        flex: 1,
        render: (item: CPRItem) => {
          const userId = item.model?.modifiedBy || item.model?.createdBy;
          const user = userId
            ? database.users.find((user: User) => user.id === userId)
            : null;
          const username = user
            ? `${user?.firstName} ${user?.lastName}`
            : DEFAULT_USERNAME;

          return (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
                paddingRight: '15px',
              }}
            >
              <span>{username}</span>
              <FaChevronRight
                className="icon-normal"
                style={{ margin: '4px' }}
              />
            </div>
          );
        },
      },
    ],
    [database.users]
  );

  const handleSortItem = useCallback(
    (key: string, direction: string, a: CPRItem, b: CPRItem) => {
      if (key === 'version') {
        return direction === 'asc'
          ? compareVersions(a.version, b.version)
          : compareVersions(b.version, a.version);
      } else if (key === 'defib_shocks') {
        return direction === 'asc'
          ? (a.defibShocks?.length || 0) - (b.defibShocks?.length || 0)
          : (b.defibShocks?.length || 0) - (a.defibShocks?.length || 0);
      } else if (key === 'epi_doses') {
        return direction === 'asc'
          ? (a.epiDoses?.length || 0) - (b.epiDoses?.length || 0)
          : (b.epiDoses?.length || 0) - (a.epiDoses?.length || 0);
      } else if (key === 'equipment') {
        const aEquipmentID = a.model.equipmentID;
        const bEquipmentID = b.model.equipmentID;
        const aEquipment = database.equipment.find(
          (equipment: EquipmentItem) => equipment.id === aEquipmentID
        );
        const bEquipment = database.equipment.find(
          (equipment: EquipmentItem) => equipment.id === bEquipmentID
        );
        return direction === 'asc'
          ? aEquipment?.name.localeCompare(bEquipment?.name)
          : bEquipment?.name.localeCompare(aEquipment?.name);
      } else if (key === 'archived_date') {
        const aDate = new Date(a?.modifiedAt || Date.now());
        const bDate = new Date(b?.modifiedAt || Date.now());
        return direction === 'asc'
          ? aDate.getTime() - bDate.getTime()
          : bDate.getTime() - aDate.getTime();
      } else if (key === 'archived_by') {
        const aUserId = a.model?.modifiedBy || a.model?.createdBy;
        const bUserId = b.model?.modifiedBy || b.model?.createdBy;

        const aUser = aUserId
          ? database.users.find((user: User) => user.id === aUserId)
          : null;
        const bUser = bUserId
          ? database.users.find((user: User) => user.id === bUserId)
          : null;

        const aName = aUser
          ? `${aUser.firstName} ${aUser.lastName}`
          : DEFAULT_USERNAME;
        const bName = bUser
          ? `${bUser.firstName} ${bUser.lastName}`
          : DEFAULT_USERNAME;

        return direction === 'asc'
          ? aName.toLowerCase().localeCompare(bName.toLowerCase())
          : bName.toLowerCase().localeCompare(aName.toLowerCase());
      }
      return 0;
    },
    [database.users]
  );

  const searchFilter = useCallback((item: CPRItem, searchQuery: string) => {
    return item.name?.toLowerCase().includes(searchQuery.toLowerCase());
  }, []);

  return (
    <div className="screen-container">
      <ProtocolHeader
        isBackButton={true}
        handleCancel={handleBack}
        name={`CPR Assist: ${filteredCPRAssists.length - 1} archive${filteredCPRAssists.length - 1 === 1 ? '' : 's'}`}
        page={'Hinckley Medical Archive'}
        type="protocol"
      />

      <DataList<CPRItem, never>
        items={filteredCPRAssists}
        headers={headers}
        onItemClick={handleItemClick}
        searchPlaceholder="Search CPR Assist..."
        sortItem={handleSortItem}
        searchFilter={searchFilter}
      />
    </div>
  );
};

export default ListArchiveCPRAssistDetails;
