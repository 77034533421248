import { useEffect, useMemo, useRef, useState } from 'react';
import DepartmentItem from '../../../data/model/DepartmentItem';
import { HiPlus } from 'react-icons/hi';
import { Sidebar } from 'primereact/sidebar';
import { InputText } from 'primereact/inputtext';
import { Button } from 'react-bootstrap';
import CategoryItem from '../../../data/model/CategoryItem';
import ProtocolItem from '../../../data/model/ProtocolItem';
import {
  findPairedDepartments,
  handleCopy,
  toTitleCase,
} from '../../_global/common/Utils';
import Status from '../ProgressStatus/ProgressStatus';
import EditAccessModal from './EditAccessModal';
import { useSelector } from 'react-redux';
import { DatabaseResponse } from '../../../data/AmplifyDB';
import { User } from '../../../models';
import { BiCopy, BiRename, BiSolidCopy } from 'react-icons/bi';
import FolderProtocolAdvancedSettings from '../FolderProtocolAdvancedSettings';
import { FaFolderOpen, FaLock } from 'react-icons/fa6';
import SearchableDropdown from '../SearchableDropdown';
import { Sketch } from '@uiw/react-color';
import { IoDocumentText } from 'react-icons/io5';
import { ViewportList } from 'react-viewport-list';

interface ColorModalProps {
  isVisible: boolean;
  handleClose: () => void;
  handleColor: (color: string) => void;
  department: DepartmentItem;
  items: (CategoryItem | ProtocolItem)[];
}
/* 10-11-23 Arul: Created the FolderProtocolModal component for globally for Edit Folder Page */
const ColorModal = (props: ColorModalProps) => {
  const { isVisible, handleClose, handleColor, department, items } = props;
  const user: User = useSelector((state: any) => state.user);

  const database: DatabaseResponse = useSelector(
    (state: any) => state.protocol.departmentItem
  );

  const colorPresets = useSelector((state: any) => state.colorPresets);

  const [color, setColor] = useState<string>('');

  /* 10-11-23 Arul: Function for handling modal submit */
  const handleSubmit = async () => {
    handleColor(color);
  };

  const customHeader = (
    <div
      className="buttonContainer contentTitleLarge hoverText"
      onClick={handleClose}
    >
      <span className="">
        <HiPlus className="header-icon" style={{ marginLeft: '5px' }} /> Add{' '}
      </span>
    </div>
  );

  const isSaveValid = useMemo(() => {
    return color !== '' && color.length === 7 && color.startsWith('#');
  }, [color]);

  return (
    <Sidebar
      visible={isVisible}
      position="right"
      onHide={handleClose}
      header={customHeader}
      style={{ width: '25%', minWidth: '400px' }}
      className={isVisible ? 'protocolSidebar sidebarWidth' : 'sidebar-hidden'}
    >
      <div className="sidebarContainer">
        <div
          className="buttonContainer contentTitleLarge"
          onClick={handleClose}
        >
          Change Color
        </div>
        <div className="contentText">
          Change the color of the {items.length}{' '}
          {items.length === 1 ? 'protocol' : 'protocols'}.
        </div>
        <div
          style={{
            maxHeight: '600px',
            overflow: 'auto',
            border: '1px solid #ddd',
            borderRadius: '6px',
            padding: '5px',
          }}
        >
          <ViewportList items={items}>
            {(item: CategoryItem | ProtocolItem, index) => {
              return (
                <li
                  key={item.uid}
                  className="contentText hoverableBackground listItem"
                  style={{
                    padding: '5px',
                    cursor: 'pointer',
                    borderBottom:
                      index === items.length - 1 ? 'none' : '1px solid #ddd',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  {item.TAG === 'ProtocolItem' ? (
                    <IoDocumentText
                      className="table-icon"
                      style={{ margin: '0 4px' }}
                      color={color !== '' ? color : item.color}
                    />
                  ) : (
                    <FaFolderOpen
                      className="table-icon"
                      style={{ margin: '0 4px' }}
                      color={color !== '' ? color : item.color}
                    />
                  )}
                  <div style={{ flex: 1 }} className="one-line-ellipsis">
                    {item.name}
                  </div>
                </li>
              );
            }}
          </ViewportList>
        </div>

        <label className="contentText" style={{ marginTop: '2rem' }}>
          Select Color
        </label>
        <div style={{ marginTop: '1rem', marginLeft: '1rem' }}>
          <Sketch
            color={color}
            onChange={(color) => {
              setColor(color.hex);
            }}
            presetColors={colorPresets}
            disableAlpha={true}
            // onKeyDown={(e) => {
            //   if (e.key === 'Enter' || e.key === ' ') {
            //     setIsColorPickerOpen(false);
            //   }
            // }}
          />
        </div>

        <div className="dialogButtonsForce">
          <Button
            data-testid="cancelBtnn"
            className="secondary-button-small-border btn-rightMargin"
            onClick={handleClose}
          >
            {' '}
            Cancel
          </Button>
          <Button
            data-testid="addBttn"
            className="primary-button-small"
            disabled={!isSaveValid}
            onClick={() => {
              handleSubmit();
            }}
          >
            Save
          </Button>
        </div>
      </div>
    </Sidebar>
  );
};

export default ColorModal;
