import React, { useMemo, useState } from 'react';
import ModelItem from '../../../../data/model/ModelInterface';
import DraftChangeItem from '../../../../data/model/DraftChangeItem';
import {
  globals,
  jsonToTitleCase,
  toTitleCase,
} from '../../../_global/common/Utils';
import ElectricalSubItem from '../../../../data/model/ElectricalSubItem';
import ElectricalShockComparisonView from '../comparisonView/ElectricalShockComparisonView';
import DepartmentItem from '../../../../data/model/DepartmentItem';
import { useNavigate } from 'react-router-dom';
import ReviewalItem from '../../../../data/model/ReviewalItem';
import ElectricalShockSideout from '../../../components/SideOut/doseSideOut/ElectricalShockSideout';
import { getMaxString, getMinString } from '../../../../data/model/ModelItem';

interface FolderComparisonProps {
  currentDraft: DraftChangeItem;
  reviewal: ReviewalItem;
  department: DepartmentItem;
}

interface Change {
  key: string;
  currentValue: string;
  archiveValue: string;
}
export const compareElectricalSubItemItems = (
  currentItem: ElectricalSubItem,
  archiveItem: ElectricalSubItem,
  countElectricalShock: number
): { changes: Change[]; count: number } => {
  const changes: Change[] = [];

  // Define the specific fields to check
  const fieldsToCompare: (keyof ElectricalSubItem)[] = [
    'rangeLow',
    'rangeHigh',
    'fullBasis',
    'fullMaxDose',
    'fullMaxTotalDose',
    'fullMinDose',
    'warning',
    'instruction',
    'note',
    'title',
    'index',
  ];

  // Function to compare values
  const compareValues = (value1: any, value2: any): boolean => {
    if (Array.isArray(value1) && Array.isArray(value2)) {
      return JSON.stringify(value1) !== JSON.stringify(value2);
    }
    return value1 !== value2;
  };

  fieldsToCompare.forEach((key) => {
    let currentValue = currentItem[key];
    let archiveValue = archiveItem ? archiveItem[key] : '';

    if (
      key === 'rangeHigh' ||
      key === 'fullMaxDose' ||
      key === 'fullMaxTotalDose'
    ) {
      currentValue = getMaxString(currentValue);
      archiveValue = getMaxString(archiveValue);
    }

    if (key === 'rangeLow' || key === 'fullMinDose') {
      currentValue = getMinString(currentValue);
      archiveValue = getMinString(archiveValue);
    }

    // if (key === 'routes' || key === 'nemsisRoutes') {
    //   currentValue = (currentValue as string[])?.join(', ') || '';
    //   archiveValue =
    //     (archiveValue !== '' && (archiveValue as string[])?.join(', ')) || '';
    // }

    if (compareValues(currentValue, archiveValue)) {
      countElectricalShock += 1;
    }

    if (key !== 'index' || currentValue !== archiveValue) {
      changes.push({
        key,
        currentValue: String(currentValue),
        archiveValue: String(archiveValue),
      });
    }
  });

  return { changes, count: countElectricalShock };
};

const ElectricalShockComparison: React.FC<FolderComparisonProps> = ({
  currentDraft,
  reviewal,
  department,
}) => {
  const navigate = useNavigate();
  const countElectricalShock = 0;
  const [selectedDose, setSelectedDose] = useState<ElectricalSubItem | null>(
    null
  );
  const { changeItem, previousItem } = useMemo(() => {
    return {
      changeItem: currentDraft.changeItem as ElectricalSubItem,
      previousItem: currentDraft.previousItem as
        | ElectricalSubItem
        | null
        | undefined,
    };
  }, [currentDraft]);
  const { changes, count } = useMemo(() => {
    const { changes: comparedChanges, count: changeCount } =
      compareElectricalSubItemItems(
        changeItem as ElectricalSubItem,
        previousItem as ElectricalSubItem,
        countElectricalShock
      );
    return { changes: comparedChanges, count: changeCount };
  }, [changeItem, previousItem]);
  const [viewMode, setViewMode] = useState<'none' | 'before' | 'after'>('none');

  const handleViewClick = (mode: 'before' | 'after') => {
    let elec = mode === 'before' && previousItem ? previousItem : changeItem;
    setSelectedDose(elec as ElectricalSubItem);
  };
  return (
    <div>
      {selectedDose != null && selectedDose.parent != null && (
        <ElectricalShockSideout
          viewOnlyMode={true}
          visible={selectedDose != null}
          doseIndex={selectedDose?.index}
          parentModel={selectedDose?.parent}
          protocol={selectedDose?.parentProtocol || null}
          allProtocols={[]}
          dose={selectedDose ? selectedDose : undefined}
          subElectrialItems={[]}
          setVisible={() => setSelectedDose(null)}
          editDose={false}
          onSubmit={() => {}}
          onRemove={() => {}}
          onSubmitMultiDose={() => {}}
        />
      )}
      <div className="doseChangesWrapper">
        <table className="changesTable">
          {previousItem ? (
            <>
              <thead>
                <tr>
                  <th className="changeHeader beforeChange">
                    <div className="headerContent">
                      Before
                      <button
                        className="secondary-button-small-border btn-rightMargin btn btn-primary viewButton"
                        onClick={() => handleViewClick('before')}
                      >
                        View
                      </button>
                    </div>
                  </th>
                  <th className="changeHeader afterChange">
                    <div className="headerContent">
                      After
                      <button
                        className="secondary-button-small-border btn-rightMargin btn btn-primary viewButton"
                        onClick={() => handleViewClick('after')}
                      >
                        View
                      </button>
                    </div>
                  </th>
                </tr>
              </thead>
              {/* pass the changes to medicationFullView to display only the changes for before and after in tabular */}
              <tbody>
                <tr>
                  <td>
                    <ElectricalShockComparisonView
                      focusItem={previousItem as ElectricalSubItem}
                      changes={changes}
                      isPrevious={true}
                      department={department}
                    />
                  </td>
                  <td>
                    <ElectricalShockComparisonView
                      focusItem={changeItem as ElectricalSubItem}
                      changes={changes}
                      department={department}
                    />
                  </td>
                </tr>
              </tbody>
            </>
          ) : (
            <>
              {' '}
              <thead>
                <tr>
                  <th className="changeHeader afterChange">
                    <div className="headerContent">
                      New {toTitleCase(currentDraft.changeType)}
                      <button
                        className="secondary-button-small-border btn-rightMargin btn btn-primary viewButton"
                        onClick={() => handleViewClick('after')}
                      >
                        View
                      </button>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <ElectricalShockComparisonView
                      focusItem={changeItem as ElectricalSubItem}
                      changes={changes}
                      department={department}
                    />
                  </td>
                </tr>
              </tbody>
            </>
          )}
        </table>
      </div>
    </div>
  );
};

export default ElectricalShockComparison;
