import { Sidebar } from 'primereact/sidebar';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button } from 'react-bootstrap';
import { AiOutlineClose } from 'react-icons/ai';
import { User } from '../../../../models';
import { findDepartmentOwner, handleCopy } from '../../../_global/common/Utils';
import MedicationSubItem from '../../../../data/model/MedicationSubItem';
import { BiArrowBack, BiCopy, BiSolidCopy } from 'react-icons/bi';
import ProtocolItem from '../../../../data/model/ProtocolItem';
import {
  DatabaseResponse,
  ResponseType,
  fetchPDF,
} from '../../../../data/AmplifyDB';
import { useSelector } from 'react-redux';
import MedicationDoseUI from '../../../pages/protocol/edit/Medication/MedicationDoseUI';
import { FaCircleCheck, FaCircleXmark, FaLock } from 'react-icons/fa6';
import DepartmentItem from '../../../../data/model/DepartmentItem';
import { Tooltip } from '@mui/material';
import InfusionSubItem from '../../../../data/model/InfusionSubItem';
import ElectricalSubItem from '../../../../data/model/ElectricalSubItem';
import InfusionDoseUI from '../../../pages/protocol/edit/Infusion/InfusionDoseUI';
import ElectricalDoseUI from '../../../pages/protocol/edit/Electrical/ElectricalDoseUI';
import CPRItem from '../../../../data/model/CPRItem';
import GeneralSelection from '../../Selection/GeneralSelection';
import { DeviceMockup } from '../../DeviceMockup/DeviceMockup';
import MedicationItem from '../../../../data/model/MedicationItem';
import ElectricalItem from '../../../../data/model/ElectricalItem';
import InfusionItem from '../../../../data/model/InfusionItem';

interface SubItemRestoreSideoutProps {
  visible: boolean;
  dose?: MedicationSubItem | InfusionSubItem | ElectricalSubItem;
  type: string;
  protocol?: ProtocolItem | CPRItem;
  setVisible: (visible: boolean) => void;
  onRestore: (dose: any) => void;
}

const SHOW_PDF = 0;
const SHOW_DEVICE_MOCKUP = 1;

/* 01-11-25 Gagan: Created common component to display Archive Dose/Shock restore sideout view*/
const SubItemRestoreSideout: React.FC<SubItemRestoreSideoutProps> = ({
  dose,
  visible,
  type,
  protocol,
  setVisible,
  onRestore,
}) => {
  const reducerState = useSelector((state: any) => state.department);

  const user: User = useSelector((state: any) => state.user);
  const database: DatabaseResponse = useSelector(
    (state: any) => state?.protocol?.departmentItem
  );

  const [parentProtocol, setParentProtocol] = useState<
    ProtocolItem | CPRItem | null
  >(protocol ? protocol : dose ? dose.parentProtocol : null);

  const [isCopied, setIsCopied] = useState<string | null>(null);
  const [pdfLoaded, setPdfLoaded] = useState(false);
  const [pdfUrl, setPdfUrl] = useState('');
  const [pdfOrDeviceMock, setPdfOrDeviceMock] = useState(SHOW_PDF);

  const [departmentOwner, setDepartmentOwner] = useState<DepartmentItem | null>(
    dose ? findDepartmentOwner(database.department, reducerState, dose) : null
  );

  const modifiedBy = useMemo(() => {
    if (dose) {
      let id = dose.modifiedByID ?? dose.createdByID;
      let user = database.users.find((u) => u.id === id);
      if (user) return user.firstName + ' ' + user.lastName;
      else return 'Hinckley Medical';
    }
    return null;
  }, [dose, database.users]);

  useEffect(() => {
    if (visible && dose) {
      setParentProtocol(dose?.parentProtocol ?? null);
    }
  }, [visible, dose]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.ctrlKey && event.shiftKey && event.key === 'O') {
        console.log('Medication Dose', dose);
      }
    };

    // Add event listener
    document.addEventListener('keydown', handleKeyDown);

    // Clean up event listener on component unmount
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [dose]);

  useEffect(() => {
    if (dose && database.department) {
      setDepartmentOwner(
        findDepartmentOwner(database.department, reducerState, dose)
      );
    }
  }, [dose, database.department, reducerState]);

  const loadPDF = useCallback(async () => {
    let result = await fetchPDF((parentProtocol as ProtocolItem)?.pdfUrl ?? '');
    if (result.type === ResponseType.Success) {
      setPdfUrl(result.data);
    } else {
      console.error(
        'ERROR: Could not load pdf for protocol: ' + parentProtocol?.name,
        result.data
      );
      setPdfUrl('');
    }
  }, [parentProtocol]);

  useEffect(() => {
    // Also check if parentProtocol.__typename is CPRItem
    if (
      (parentProtocol as ProtocolItem)?.pdfUrl &&
      !pdfLoaded &&
      (parentProtocol as ProtocolItem)?.TAG === 'ProtocolItem'
    ) {
      loadPDF();
      setPdfLoaded(true);
    }
  }, [parentProtocol, pdfLoaded, loadPDF]);

  const handleCloseSideout = () => {
    setVisible(false);
  };

  const customHeader = () => {
    return (
      <div>
        <h4 className="headerText hoverText" onClick={handleCloseSideout}>
          <span className="">
            <BiArrowBack className="header-icon" data-testid="isBackBtn" />
          </span>{' '}
          {dose?.name} {type === 'electrical' ? 'Shock' : 'Dose'} Archives
        </h4>
        {departmentOwner && (
          <Tooltip
            title={departmentOwner.name + ' owns this item.'}
            placement="bottom"
            arrow
            slotProps={{
              popper: {
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [0, -14],
                    },
                  },
                ],
              },
            }}
          >
            <div
              className="protocol-header-logo-container"
              style={{
                top: '2px',
                right: '0px',
              }}
            >
              <img
                className="no-select owner-logo"
                src={departmentOwner.logoVerifiedUrl ?? ''}
                alt="Agency Logo"
              />
              <div
                className="lock-icon"
                style={{
                  top: '5px',
                }}
              >
                <FaLock
                  data-testid="isLockButton"
                  style={{
                    color:
                      departmentOwner.id !== database.department.id
                        ? '#A3A3A3'
                        : 'transparent',
                    fontSize: '1.25rem',
                  }}
                />
              </div>
            </div>
          </Tooltip>
        )}
      </div>
    );
  };

  const handleRestoreDose = () => {
    if (onRestore) onRestore(dose);
  };

  return (
    <div>
      <Sidebar
        header={customHeader}
        visible={visible}
        position="right"
        onHide={() => handleCloseSideout()}
        style={{
          minWidth: '600px',
        }}
        className="sidebarWidth"
      >
        {parentProtocol &&
          (parentProtocol as ProtocolItem)?.TAG === 'ProtocolItem' && (
            <>
              <div
                style={{
                  position: 'absolute',
                  left: `calc(-40vw - 20px)`, // Adjust for the sidebar width (40% of viewport) and the clip width (20px)
                  top: '0',
                  height: '100%',
                  backgroundColor: '#fff',

                  padding: '10px',
                  borderRadius: '8px',
                  boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
                  width: `calc(100vw - 60vw)`, // Full width minus the sidebar width (40% of viewport) + 20% for clip
                  overflowY: 'auto', // Allow scrolling if content overflows
                }}
              >
                <GeneralSelection
                  items={['PDF', 'Show Device Mockup']}
                  selectedIndex={pdfOrDeviceMock}
                  onPress={(item, index) => {
                    setPdfOrDeviceMock(index);
                  }}
                  labelField={(option) => option}
                  valueField={(option) => option}
                />
                {pdfOrDeviceMock === SHOW_DEVICE_MOCKUP && dose?.parent && (
                  <div
                    style={{
                      justifyContent: 'center',
                      display: 'flex',
                      // paddingTop: '20px',

                      alignItems: 'center',
                      // height: '90vh',
                    }}
                  >
                    <DeviceMockup
                      parentModel={
                        dose.parent as
                          | MedicationItem
                          | InfusionItem
                          | ElectricalItem
                      }
                      formik={undefined}
                      subItem={
                        dose as
                          | MedicationSubItem
                          | InfusionSubItem
                          | ElectricalSubItem
                      }
                      protocol={parentProtocol as ProtocolItem}
                    />
                  </div>
                )}
                {pdfOrDeviceMock === SHOW_PDF && (
                  <div
                    style={{
                      justifyContent: 'center',
                      display: 'flex',
                      paddingTop: '20px',
                    }}
                  >
                    <iframe
                      src={pdfUrl}
                      title="PDF Viewer"
                      width="100%"
                      style={{
                        border: 'none',
                        height: '90vh',
                      }}
                    />
                  </div>
                )}
              </div>
            </>
          )}
        <div style={{ padding: '0px 15px' }}>
          <p className="sidebarText">
            Here's the information for "{dose?.name}"{' '}
            {type === 'electrical' ? 'Shock' : 'Dose'}
          </p>
          {dose != null && user.type === 'ADMIN' && (
            <div>
              <div
                className="contentText"
                style={{
                  marginTop: '10px',
                }}
              >
                ID: {dose.uid}
                <span>
                  {isCopied && isCopied === dose.uid ? (
                    <BiSolidCopy
                      color={'#00534C'}
                      size=".75rem"
                      className="copy_icon"
                    />
                  ) : (
                    <BiCopy
                      size=".75rem"
                      className="copy_icon"
                      onClick={(e) => handleCopy(dose.uid, e, setIsCopied)}
                    />
                  )}
                </span>
              </div>
              {dose.activeID != null && (
                <div
                  className="contentText"
                  style={{
                    marginTop: '10px',
                  }}
                >
                  Active ID: {dose.activeID}
                  <span>
                    {isCopied && isCopied === dose.activeID ? (
                      <BiSolidCopy
                        color={'#00534C'}
                        size=".75rem"
                        className="copy_icon"
                      />
                    ) : (
                      <BiCopy
                        size=".75rem"
                        className="copy_icon"
                        onClick={(e) =>
                          handleCopy(dose.activeID as string, e, setIsCopied)
                        }
                      />
                    )}
                  </span>
                  <span>
                    {dose.activeItem != null ? (
                      <FaCircleCheck
                        color={'#037F02'}
                        size=".75rem"
                        className="copy_icon"
                      />
                    ) : (
                      <FaCircleXmark
                        color={'#8c1010'}
                        size=".75rem"
                        className="copy_icon"
                      />
                    )}
                  </span>
                </div>
              )}
            </div>
          )}
          {dose != null && modifiedBy && (
            <>
              <div style={{ marginTop: '5px' }}>
                <span className="sidebarText">
                  Modified By: {modifiedBy}
                  {user.type === 'ADMIN' &&
                    ' (' + (dose.modifiedByID ?? dose.createdByID) + ')'}
                </span>
              </div>
              {dose.updatedAt && (
                <div style={{ marginTop: '5px' }}>
                  <span className="sidebarText">
                    Modified At: {new Date(dose.updatedAt).toLocaleString()}
                  </span>
                </div>
              )}
            </>
          )}
        </div>
        {dose && (
          <div className="sidebarContent" style={{ marginTop: '15px' }}>
            {type === 'medication' ? (
              <MedicationDoseUI
                dose={dose as MedicationSubItem}
                doseIndex={dose?.version ?? ''}
                showProtocol={parentProtocol != null}
                isEditMode={false}
                archiveMode={true}
              />
            ) : type === 'infusion' ? (
              <InfusionDoseUI
                dose={dose as InfusionSubItem}
                doseIndex={dose?.version ?? ''}
                showProtocol={parentProtocol != null}
                isEditMode={false}
                archiveMode={true}
              />
            ) : type === 'electrical' ? (
              <ElectricalDoseUI
                dose={dose as ElectricalSubItem}
                doseIndex={
                  (parentProtocol as ProtocolItem)?.TAG === 'ProtocolItem'
                    ? (dose?.version ?? '')
                    : dose?.version + ' - CPR Assist'
                }
                showProtocol={
                  parentProtocol != null &&
                  (parentProtocol as ProtocolItem)?.TAG === 'ProtocolItem'
                }
                isEditMode={false}
                archiveMode={true}
              />
            ) : null}
          </div>
        )}

        <div className="sidebarButtons">
          <Button
            className="secondary-button btn-rightMargin"
            data-testid="cancelBtn"
            onClick={handleCloseSideout}
          >
            <span>
              <AiOutlineClose className="icon-normal" />
            </span>{' '}
            Cancel
          </Button>

          <Button
            className="red-background-button btn-rightMargin "
            data-testid="deleteBtn"
            onClick={() => {
              handleRestoreDose();
            }}
            disabled={dose?.status === 'ACTIVE'}
          >
            {'Restore'}
          </Button>
        </div>
      </Sidebar>
    </div>
  );
};

export default SubItemRestoreSideout;
