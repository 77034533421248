import { Concentration, ProgressStatus, Vitals } from '../../models';
import { User } from '../../models';
import {
  findDepartmentOwner,
  getActiveID,
  globals,
} from '../../ui/_global/common/Utils';
import {
  BatchQuery,
  DatabaseResponse,
  executeQuery,
  executeSingleQuery,
  Response,
  ResponseType,
} from '../AmplifyDB';
import CategoryItem, { cloneCategory } from '../model/CategoryItem';
import DepartmentItem from '../model/DepartmentItem';
import MedicationItem, { cloneMedication } from '../model/MedicationItem';
import ModelItem from '../model/ModelItem';
import ModelSubItem from '../model/ModelSubItem';
import ProtocolItem, { cloneProtocol } from '../model/ProtocolItem';
import { createCategory, deleteCategoryItem } from './CategoryDB';
import {
  createMedicationDoseItem,
  createParentMedication,
  deleteMedicationDoseItem,
  deleteMedicationItem,
} from './MedicationDB';
import { createProtocol, deleteProtocolItem } from './ProtocolDB';
import MedicationSubItem, {
  cloneMedicationSubItem,
} from '../model/MedicationSubItem';
import {
  createInfusionDoseItem,
  createParentInfusion,
  deleteInfusionDoseItem,
  deleteInfusionItem,
} from './InfusionDB';
import InfusionItem, { cloneInfusion } from '../model/InfusionItem';
import InfusionSubItem, {
  cloneInfusionSubItem,
} from '../model/InfusionSubItem';
import {
  createElectrical,
  createElectricalDoseItem,
  deleteElectricalDoseItem,
  deleteElectricalItem,
} from './ElectricalDB';
import ElectricalItem, { cloneElectrical } from '../model/ElectricalItem';
import ElectricalSubItem, {
  cloneElectricalSubItem,
} from '../model/ElectricalSubItem';
import { createEquipment, deleteEquipmentItem } from './EquipmentDB';
import EquipmentItem from '../model/EquipmentItem';
import { createVital, deleteVitalItem } from './VitalDB';
import VitalItem from '../model/VitalItem';
import FormItem from '../model/FormItem';
import { createCheckList, deleteChecklistItem } from './CheckListDB';
import {
  concentrationsByDepartmentID,
  concentrationsByMedicationID,
  concentrationsByDripID,
  draftChangesByChangeID,
  listMedications,
} from '../../graphql/queries';
import { DataStore } from 'aws-amplify';
import {
  Acknowledge,
  ACKStatus,
  CreateConcentrationInput,
  DeleteConcentrationInput,
  DraftChange,
  DraftGroup,
  Reviewal,
} from '../../API';
import {
  createConcentration,
  deleteConcentration,
  updateConcentration,
  updateMedication,
} from '../../graphql/mutations';
import DraftChangeItem from '../model/DraftChangeItem';
import ReviewalItem from '../model/ReviewalItem';
import DraftGroupItem from '../model/DraftGroupItem';
import * as APITypes from '../../API';

type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export type ArchiveItem = {
  id: string;
  activeItem: ModelItem<any> | ModelSubItem<any>;
  items: ModelItem<any>[] | ModelSubItem<any>[] | any[];
};

export const cloneModelItem = (
  item: ModelItem<any> | ModelSubItem<any>
): ModelItem<any> | ModelSubItem<any> => {
  switch (item.TAG) {
    case 'CategoryItem':
      return cloneCategory(item as CategoryItem);
    case 'ProtocolItem':
      return cloneProtocol(item as ProtocolItem);
    case 'MedicationItem':
      return cloneMedication(item as MedicationItem);
    case 'MedicationSubItem':
      return cloneMedicationSubItem(item as MedicationSubItem);
    case 'InfusionItem':
      return cloneInfusion(item as InfusionItem);
    case 'InfusionSubItem':
      return cloneInfusionSubItem(item as InfusionSubItem);
    case 'ElectricalItem':
      return cloneElectrical(item as ElectricalItem);
    case 'ElectricalSubItem':
      return cloneElectricalSubItem(item as ElectricalSubItem);
    case 'EquipmentItem':
      return new EquipmentItem(item.model);
    case 'VitalItem':
      return new VitalItem(item.model);
    case 'FormItem':
      return new FormItem(item.model);
    default:
      console.error('Error in cloneItem: unknown item type');
      return item;
  }
};

export const handleToggleEnabled = (
  user: User,
  department: DepartmentItem,
  reducerState: any,
  item: ModelItem<any> | ModelSubItem<any>
): Promise<Response> => {
  return new Promise(async (resolve, reject) => {
    try {
      /* Find the owner (Department) of the ModelItem or ModelSubItem */
      const owner = findDepartmentOwner(department, reducerState, item);
      if (owner == null) {
        console.error('Error in handleToggleEnabled: owner not found');
        return reject('Owner not found');
      }
      const isDisable = item.status !== ProgressStatus.DEACTIVATED;
      if (isDisable) {
        let clone = cloneModelItem(item);
        clone.departmentID = department.id;
        clone.modifiedBy = user;
        clone.overrideID =
          item.status.includes('DRAFT') && item.activeID
            ? item.activeID
            : item.uid;
        clone.status = ProgressStatus.DEACTIVATED;

        clone.activeID = undefined;
        let createPromise;
        switch (item.TAG) {
          case 'CategoryItem':
            createPromise = createCategory(
              clone as CategoryItem,
              item as CategoryItem
            );
            break;
          case 'ProtocolItem':
            createPromise = createProtocol(
              clone as ProtocolItem,
              item as ProtocolItem
            );
            break;
          case 'MedicationItem':
            createPromise = createParentMedication(
              department,
              clone as MedicationItem,
              item as MedicationItem
            );
            break;
          case 'MedicationSubItem':
            createPromise = createMedicationDoseItem(
              clone as MedicationSubItem,
              item as MedicationSubItem
            );
            break;
          case 'InfusionItem':
            createPromise = createParentInfusion(
              department,
              clone as InfusionItem,
              item as InfusionItem
            );
            break;
          case 'InfusionSubItem':
            createPromise = createInfusionDoseItem(
              clone as InfusionSubItem,
              item as InfusionSubItem
            );
            break;
          case 'ElectricalItem':
            createPromise = createElectrical(
              clone as ElectricalItem,
              item as ElectricalItem
            );
            break;
          case 'ElectricalSubItem':
            createPromise = createElectricalDoseItem(
              clone as ElectricalSubItem,
              item as ElectricalSubItem
            );
            break;
          case 'EquipmentItem':
            createPromise = createEquipment(
              clone as EquipmentItem,
              item as EquipmentItem
            );
            break;
          case 'VitalItem':
            createPromise = createVital(clone as VitalItem, item as VitalItem);
            break;
          case 'FormItem':
            createPromise = createCheckList(
              clone as FormItem,
              item as FormItem
            );
            break;
          default:
            console.error('Error in handleToggleEnabled: unknown item type');
            return reject('Unknown item type');
        }
        let createResult: Response = await createPromise;
        if (createResult.type === ResponseType.Success) {
          const newItem = createResult.data as
            | ModelItem<any>
            | ModelSubItem<any>;
          newItem.overrideItem = item;

          return resolve(createResult);
        } else {
          console.error(
            'Error in handleToggleEnabled: delete failed: ',
            createResult.data
          );
          return resolve(createResult);
        }
      } else {
        /* Find the Delete function */
        let deletePromise;
        switch (item.TAG) {
          case 'CategoryItem':
            deletePromise = deleteCategoryItem(
              department,
              item as CategoryItem,
              user,
              false
            );
            break;
          case 'ProtocolItem':
            deletePromise = deleteProtocolItem(
              department,
              item as ProtocolItem,
              user,
              false,
              false,
              false
            );
            break;
          case 'MedicationItem':
            deletePromise = deleteMedicationItem(
              department,
              item as MedicationItem,
              false
            );
            break;
          case 'MedicationSubItem':
            deletePromise = deleteMedicationDoseItem(
              item as MedicationSubItem,
              false
            );
            break;
          case 'InfusionItem':
            deletePromise = deleteInfusionItem(
              department,
              item as InfusionItem,
              false
            );
            break;
          case 'InfusionSubItem':
            deletePromise = deleteInfusionDoseItem(
              item as InfusionSubItem,
              false
            );
            break;
          case 'ElectricalItem':
            deletePromise = deleteElectricalItem(item as ElectricalItem, false);
            break;
          case 'ElectricalSubItem':
            deletePromise = deleteElectricalDoseItem(
              item as ElectricalSubItem,
              false
            );
            break;
          case 'EquipmentItem':
            deletePromise = deleteEquipmentItem(item as EquipmentItem, false);
            break;
          case 'VitalItem':
            deletePromise = deleteVitalItem(item as VitalItem, false);
            break;
          case 'FormItem':
            deletePromise = deleteChecklistItem(item as FormItem, false);
            break;
          default:
            console.error('Error in handleToggleEnabled: unknown item type');
            return reject('Unknown item type');
        }
        let deleteResult: Response = await deletePromise;
        if (deleteResult.type === ResponseType.Success) {
          if (globals.debug)
            console.log(
              'Success in handleToggleEnabled: delete succeeded',
              item
            );
          if (item.overrideItem) {
            let newItem = cloneModelItem(item.overrideItem);
            newItem.status = ProgressStatus.DEACTIVATED;
            return resolve({
              type: ResponseType.Success,
              data: newItem,
            });
          } else {
            if (globals.debug)
              console.log('No override item found in handleToggleEnabled');
            return resolve(deleteResult);
          }
        } else {
          console.error(
            'Error in handleToggleEnabled: delete failed: ',
            deleteResult.data
          );
          return resolve({
            type: ResponseType.Failure,
            data: deleteResult.data,
          });
        }
      }
    } catch (error) {
      console.error('Error in handleToggleEnabled:', error);
      reject(error);
    }
  });
};

/**
 * Finds all users for a list of ModelItems.
 * @param items - The list of ModelItems to find users for.
 * @returns A Promise that resolves to an array of User objects.
 */
export const findUsersForModelItems = async (
  items: ModelItem<any>[] | ModelSubItem<any>[],
  users?: User[]
): Promise<ModelItem<any>[] | ModelSubItem<any>[]> => {
  let promises: Promise<User | null>[] = [];
  for (let i = 0; i < items.length; i++) {
    let userID = items[i].modifiedByID ?? items[i].createdByID;
    let find = users?.find((user) => user.id === userID);
    if (find) items[i].modifiedBy = find;
    else promises.push(items[i].findUser());

    if (promises.length > globals.QUERY_BATCH_SIZE) {
      await Promise.all(promises);
      promises = [];
    }
  }
  if (promises.length > 0) await Promise.all(promises);
  return items;
};

/**
 * Finds all users for a list of ModelItems.
 * @param items - The list of ModelItems to find users for.
 * @returns A Promise that resolves to an array of User objects.
 */
export const findUsersForDatabase = async (db: DatabaseResponse) => {
  let items: (ModelItem<any> | ModelSubItem<any>)[] = [
    ...db.medications,
    ...db.medicationDoses,
    ...db.infusions,
    ...db.infusionDoses,
    ...db.equipment,
    ...db.electrical,
    ...db.electricalDoses,
    ...db.checklists,
    ...db.vitals,
    ...db.contacts,
    ...db.keychains,
    ...db.groups,
  ];
  if (db.cprModel) items.push(db.cprModel);

  let promises: Promise<User | null>[] = [];
  for (let i = 0; i < items.length; i++) {
    let userID = items[i].modifiedByID ?? items[i].createdByID;
    let find = db.users.find((user) => user.id === userID);
    if (find) items[i].modifiedBy = find;
    else promises.push(items[i].findUser());

    if (promises.length > globals.QUERY_BATCH_SIZE) {
      await Promise.all(promises);
      promises = [];
    }
  }
  if (promises.length > 0) await Promise.all(promises);

  return items;
};

type PropagateConcenResponse = {
  department: DepartmentItem;
  medication: MedicationItem | InfusionItem;
  previousConcentrations: Concentration[];
  concentrations: Concentration[];
  createdConcentrations: Concentration[];
  duplicateConcentrations: Concentration[];
  deletedConcentrations: Concentration[];
  errors?: string[];
};

/**
 * Propagates the concentrations from the parent department to the sub departments.
 * @param department - The parent department to copy the concentrations from.
 * @param medication - The medication to copy the concentrations from.
 * @param subDeps - The list of sub departments to copy the concentrations to.
 * @returns A Promise that resolves to a Response object.
 *  @data: PropagateConcenResponse[]
 */
export const propagateConcentrations = async (
  department: DepartmentItem,
  concentrations: Concentration[],
  medication: MedicationItem | InfusionItem,
  subDeps: DepartmentItem[]
): Promise<Response> => {
  try {
    if (concentrations == null || concentrations.length === 0)
      return {
        type: ResponseType.Success,
        data: [],
      };

    let filteredDeps = subDeps.filter((dep) => dep.id !== department.id);
    let promiseFunctions: (() => Promise<PropagateConcenResponse | null>)[] =
      [];
    for (let i = 0; i < filteredDeps.length; i++) {
      promiseFunctions.push(() =>
        copyConcentrationsToSubDepartment(
          filteredDeps[i],
          medication,
          concentrations
        )
      );
    }
    let copyResults: (PropagateConcenResponse | null)[] = await BatchQuery(
      promiseFunctions,
      5
    ).catch((error) => {
      console.error('Error in propagateConcentrations:', error);
      throw error;
    });
    let results: PropagateConcenResponse[] = [];
    for (let i = 0; i < copyResults.length; i++) {
      if (copyResults[i] != null) {
        results.push(copyResults[i] as PropagateConcenResponse);
      } else {
        console.error('Copy Results is NULL: ', copyResults[i], i, subDeps[i]);
      }
    }
    if (globals.debug) {
      console.log('Propagated Concentrations Results: ', results);
      let createdConcentrations = results
        .map((r) => r.createdConcentrations)
        .flat();
      let duplicateConcentrations = results
        .map((r) => r.duplicateConcentrations)
        .flat();
      let deletedConcentrations = results
        .map((r) => r.deletedConcentrations)
        .flat();
      console.log('\tCreated Concentrations: ', createdConcentrations);
      console.log('\tDuplicate Concentrations: ', duplicateConcentrations);
      console.log('\tDeleted Concentrations: ', deletedConcentrations);
    }

    return {
      type: ResponseType.Success,
      data: results,
    };
  } catch (error) {
    console.error('Error in propagateConcentrations:', error);
    throw error;
  }
};

/**
 * Copies the concentrations from the parent department to the sub department.
 * @param dep - The sub department to copy the concentrations to.
 * @param medication - The medication to copy the concentrations from.
 * @returns A Promise that resolves to an array of Concentration objects.
 */
const copyConcentrationsToSubDepartment = async (
  dep: DepartmentItem,
  medication: MedicationItem | InfusionItem,
  newConcentrations: Concentration[]
): Promise<PropagateConcenResponse | null> => {
  return new Promise(async (resolve, reject) => {
    try {
      let filter: any;
      const medicationID = getActiveID(medication);
      const isMedItem = medication.TAG === 'MedicationItem';
      if (medicationID == null) {
        console.error(
          'Error in copyConcentrationsToSubDepartment: medicationID is null'
        );
        return null;
      }
      if (isMedItem) {
        filter = {
          and: [
            {
              _deleted: {
                ne: true,
              },
            },
            {
              medicationID: {
                eq: medicationID,
              },
            },
          ],
        };
      } else {
        filter = {
          and: [
            {
              _deleted: {
                ne: true,
              },
            },
            {
              dripID: {
                eq: medicationID,
              },
            },
          ],
        };
      }
      /* Load the Concentrations for the Medication */
      let concenResponse = await executeQuery(concentrationsByDepartmentID, {
        departmentID: dep.id,
        filter: filter,
      }).catch((error) => {
        console.error(dep.name, 'Error in finding concentrations:', error);
        reject(error);
        return null;
      });

      /* Base case for error handling */
      if (concenResponse == null) {
        console.error(
          dep.name,
          'Error in querying concentrations:',
          concenResponse
        );
        return null;
      }

      const currentConcentrations: Concentration[] = concenResponse;
      // console.log(dep.name, 'Current Concentrations: ', currentConcentrations);

      /* Grab all the concentrations that are already in the current concentrations */
      let duplicateConcentrations = currentConcentrations.filter(
        (concentration) =>
          newConcentrations.findIndex(
            (c) =>
              c.firstAmnt === concentration.firstAmnt &&
              c.firstUnit === concentration.firstUnit &&
              c.secAmnt === concentration.secAmnt &&
              c.secUnit === concentration.secUnit
          ) !== -1
      );
      /* Filter out concentrations that are already in the current concentrations */
      let createConcentrations = newConcentrations.filter(
        (concentration) =>
          currentConcentrations.findIndex(
            (c) =>
              c.firstAmnt === concentration.firstAmnt &&
              c.firstUnit === concentration.firstUnit &&
              c.secAmnt === concentration.secAmnt &&
              c.secUnit === concentration.secUnit
          ) === -1
      );
      /* Find any concentrations that are in the current concentrations but not in the new concentrations */
      let deletedConcentrations = currentConcentrations.filter(
        (concentration) =>
          newConcentrations.findIndex(
            (c) =>
              c.firstAmnt === concentration.firstAmnt &&
              c.firstUnit === concentration.firstUnit &&
              c.secAmnt === concentration.secAmnt &&
              c.secUnit === concentration.secUnit
          ) === -1
      );
      // console.log(dep.name, 'CREATING Concentrations: ', createConcentrations);
      // console.log(
      //   dep.name,
      //   'DUPLICATE Concentrations: ',
      //   duplicateConcentrations
      // );
      // console.log(dep.name, 'DELETING Concentrations: ', deletedConcentrations);
      /* Loop through the new concentrations and create new Concentration objects */
      let concentrations: Concentration[] | null = [];
      let promiseFunctions: (() => Promise<any>)[] = [];
      for (let i = 0; i < createConcentrations.length; i++) {
        let c = createConcentrations[i];
        let newConcentration: CreateConcentrationInput = {
          departmentID: dep.id,
          medicationID: isMedItem ? medicationID : null,
          dripID: !isMedItem ? medicationID : null,
          firstAmnt: c.firstAmnt,
          firstUnit: c.firstUnit,
          secAmnt: c.secAmnt,
          secUnit: c.secUnit,
          color: c.color,
          status: ProgressStatus.ACTIVE,
        };
        promiseFunctions.push(() =>
          executeSingleQuery(
            createConcentration,
            { input: newConcentration },
            undefined,
            false
          )
        );
      }

      /* Delete the concentrations that are in the deletedConcentrations array */
      for (let i = 0; i < deletedConcentrations.length; i++) {
        let c = deletedConcentrations[i];
        let input: DeleteConcentrationInput = {
          id: c.id,
          _version: (c as any)._version,
        };
        promiseFunctions.push(() =>
          executeSingleQuery(
            deleteConcentration,
            { input: input },
            undefined,
            false
          )
        );
      }

      /* Batch query the promises */
      concentrations = await BatchQuery(promiseFunctions).catch((error) => {
        console.error(
          dep.name,
          'Error in copyConcentrationsToSubDepartment:',
          error
        );
        reject(error);
        return null;
      });

      /* Return the results */
      if (concentrations) {
        let errors: string[] = [];
        for (let i = 0; i < concentrations.length; i++) {
          if (concentrations[i] == null) {
            errors.push(`Error in copyConcentrationsToSubDepartment: ${i}`);
          }
        }
        resolve({
          department: dep,
          medication: medication,
          previousConcentrations: currentConcentrations,
          concentrations: newConcentrations,
          createdConcentrations: createConcentrations,
          duplicateConcentrations: duplicateConcentrations,
          deletedConcentrations: deletedConcentrations,
        });
      } else {
        resolve({
          department: dep,
          medication: medication,
          previousConcentrations: currentConcentrations,
          concentrations: newConcentrations,
          createdConcentrations: [],
          duplicateConcentrations: [],
          deletedConcentrations: [],
        });
      }
      // else reject('Error in copyConcentrationsToSubDepartment');
    } catch (error) {
      console.error('Error in propagateConcentrations:', error);
      reject(error);
    }
  });
};

/**
 * Checks if a model item is locked in a reviewal.
 * @param item - The model item to check.
 * @param filterOutClosed - Whether to filter out closed reviewals.
 * @returns A Promise that resolves to an array of DraftChange objects.
 */
export const isModelItemLockedInReviewal = (
  item: ModelItem<any> | ModelSubItem<any>,
  db: DatabaseResponse,
  filterOutClosed: boolean = true
): Promise<DraftChangeItem | null> => {
  return new Promise(async (resolve, reject) => {
    try {
      if (item.status !== ProgressStatus.DRAFT) {
        resolve(null);
        return;
      }
      let filter = filterOutClosed
        ? {
            and: [{ _deleted: { ne: true } }, { isClosed: { ne: true } }],
          }
        : { _deleted: { ne: true } };
      const response: DraftChange[] = await executeQuery(
        draftChangesByChangeID,
        {
          changeID: item.uid,
          filter: filter,
        }
      );

      /**
       * Now decide if th item should be locked in a reviewal process.
       *  1. PENDING
       *  2. APPROVED
       *  3. REJECTED - Acknowledge has a REJECTED status
       */
      if (response.length === 1) {
        /* Grab all the DraftChange data */
        let draftChangeData: any = response[0] as any;
        let acknowledges: Acknowledge[] | null | undefined =
          draftChangeData.Acknowledges?.items || [];
        let draftGroupData: any | null | undefined = draftChangeData.DraftGroup;
        let reviewalData: Reviewal | null | undefined =
          draftGroupData?.Reviewal;

        const reviewal = reviewalData
          ? new ReviewalItem(reviewalData, db)
          : null;
        if (reviewal == null) {
          resolve(null);
          return;
        }
        const draftGroup = draftGroupData
          ? new DraftGroupItem(draftGroupData, db)
          : null;
        if (draftGroup == null) {
          resolve(null);
          return;
        }
        const draftChange = draftChangeData
          ? new DraftChangeItem(reviewal, draftGroup, draftChangeData, db)
          : null;
        if (draftChange == null) {
          resolve(null);
          return;
        }
        draftChange.changeItem = item;
        draftChange.previousItem = item.activeItem;

        if (
          reviewal != null &&
          (reviewal.state === ACKStatus.PENDING ||
            reviewal.state === ACKStatus.APPROVED)
        ) {
          item.draftChange = draftChange;
          return resolve(draftChange);
        } else if (reviewal != null && reviewal.state === ACKStatus.REJECTED) {
          let findREJECTED = acknowledges?.find(
            (a) => a.status === ACKStatus.REJECTED
          );
          if (!findREJECTED) {
            item.draftChange = draftChange;
            return resolve(draftChange);
          }
        }
      } else if (response.length > 1) {
        console.log('MULTIPLE DRAFT CHANGE DATA: ', response);
      }
      resolve(null);
    } catch (error: any) {
      reject(error);
    }
  });
};

type SetAllArchiveItemsToNewActiveID = {
  departmentID?: string;
  categoryID?: string;
};
/**
 * Checks if a draft item has been created.
 * @param model - The model to query.
 * @param activeItem - The active item to check.
 * @returns A Promise that resolves to a boolean.
 */
export const reloadModelItem = async (
  model: any,
  modelItem: ModelItem<any> | ModelSubItem<any>
): Promise<Response> => {
  try {
    const item = await DataStore.query(model, modelItem.uid);
    let activeItem: any = null;
    if (item) {
      if (item.status === ProgressStatus.DRAFT) {
        const id = item.activeID;
        if (id) {
          const draft = await DataStore.query(model, id);
          if (draft) {
            activeItem = draft;
          }
        }
      } else {
        const activeID = item.uid;
        const drafts: any[] | undefined = await DataStore.query(
          model,
          (c: any) =>
            c.and((c: any) => [
              c.activeID.eq(activeID),
              c.status.eq(ProgressStatus.DRAFT),
            ])
        );
        if (drafts && drafts.length > 0) activeItem = drafts[0];
      }

      return {
        type: ResponseType.Success,
        data: {
          item: item,
          activeItem: activeItem,
        },
      };
    } else {
      return {
        type: ResponseType.Failure,
        data: null,
      };
    }
  } catch (error) {
    console.error('Error in reloadModelItem: ', error);
    return {
      type: ResponseType.Failure,
      data: null,
    };
  }
};

/**
 * Sets all archive items to the new active ID.
 * @param prevItem - The previous item.
 * @param newItem - The new item.
 * @param updateFunction - The update function.
 * @param queryFunction - The query function.
 * @returns A Promise that resolves to a Response object.
 */
export const setAllArchiveItemsToNewActiveID = async (
  prevItem: ModelItem<any> | ModelSubItem<any>,
  newItem: ModelItem<any> | ModelSubItem<any>,
  updateFunction: GeneratedMutation<any, any>,
  queryFunction: GeneratedQuery<any, any>,
  config?: SetAllArchiveItemsToNewActiveID,
  debug: boolean = false
): Promise<Response> => {
  return new Promise(async (resolve, reject) => {
    try {
      let filter: any = {
        and: [
          {
            _deleted: {
              ne: true,
            },
          },
          {
            activeID: {
              eq: prevItem.uid,
            },
          },
          {
            status: {
              eq: ProgressStatus.ARCHIVE,
            },
          },
        ],
      };

      if (debug) {
        console.log(prevItem.TAG, 'Filter: ', filter);
        console.log(prevItem.TAG, 'Config: ', config);
      }

      let items = await executeQuery(
        queryFunction,
        config?.departmentID
          ? {
              departmentID: config?.departmentID,
              filter: filter,
            }
          : {
              filter: filter,
            }
      );

      if (debug) console.log(prevItem.TAG, 'Queried Items: ', items);
      // console.log('Items: ', items);
      let writePromiseFunctions: (() => Promise<any>)[] = [];

      if (items.length > 0) {
        for (let i = 0; i < items.length; i++) {
          let item = items[i];
          writePromiseFunctions.push(() =>
            executeSingleQuery(
              updateFunction,
              {
                input: config?.categoryID
                  ? {
                      id: item.id,
                      activeID: newItem.uid,
                      categoryID: config?.categoryID,
                      _version: item._version,
                    }
                  : {
                      id: item.id,
                      activeID: newItem.uid,
                      _version: item._version,
                    },
              },
              undefined,
              false,
              true
            ).catch((error) => {
              console.error(
                'Error in setAllArchiveItemsToNewActiveID: ',
                error
              );
              return null;
            })
          );
        }
      }
      let results: any | null = await BatchQuery(writePromiseFunctions); //861d7b29-8b89-4040-8d8d-95f3c226f49b
      if (debug) console.log(prevItem.TAG, 'Write Results: ', results);
      for (let i = 0; i < results.length; i++) {
        let result = results[i];
        if (result == null) {
          console.error(
            'Error in setAllArchiveItemsToNewActiveID: ',
            results[i]
          );
        }
      }
      resolve({
        type: ResponseType.Success,
        data: results,
      });
    } catch (error) {
      reject(error);
    }
  });
};

export const setAllDosesToNewParentID = async (
  prevItem: ModelItem<any> | ModelSubItem<any>,
  newItem: ModelItem<any> | ModelSubItem<any>,
  updateFunction: GeneratedMutation<any, any>,
  queryFunction: GeneratedQuery<any, any>,
  key: string,
  checkForConcentrations: boolean = false,
  querySize?: number,
  debug: boolean = false
): Promise<Response> => {
  return new Promise(async (resolve, reject) => {
    try {
      let filter: any = {
        and: [
          {
            _deleted: {
              ne: true,
            },
          },
          {
            status: {
              eq: ProgressStatus.ARCHIVE,
            },
          },
        ],
      };

      let items = await executeQuery(queryFunction, {
        [key]: prevItem.uid,
        filter: filter,
      });
      if (debug) console.log(prevItem.TAG, 'Queried Items: ', items);
      // let concentrations: Concentration[] | null = null;
      // if (
      //   checkForConcentrations &&
      //   (key === 'medicationID' || key === 'dripID')
      // ) {
      //   concentrations = await executeQuery(
      //     key === 'medicationID'
      //       ? concentrationsByMedicationID
      //       : concentrationsByDripID,
      //     {
      //       [key]: prevItem.uid,
      //       filter: {
      //         _deleted: {
      //           ne: true,
      //         },
      //       },
      //     }
      //   );
      // }
      // if (debug) console.log(prevItem.TAG, 'Concentrations: ', concentrations);
      let writePromiseFunctions: (() => Promise<any>)[] = [];
      if (items.length > 0) {
        for (let i = 0; i < items.length; i++) {
          let item = items[i];
          writePromiseFunctions.push(() =>
            executeSingleQuery(
              updateFunction,
              {
                input: {
                  id: item.id,
                  [key]: newItem.uid,
                  _version: item._version,
                  status:
                    item.status === ProgressStatus.ACTIVE
                      ? ProgressStatus.DELETED
                      : item.status,
                },
              },
              undefined,
              false,
              true
            ).catch((error) => {
              console.error('Error in setAllDosesToNewParentID: ', error);
              return null;
            })
          );
        }
      }
      // if (concentrations && concentrations.length > 0) {
      //   console.log('Concentrations: ', concentrations);
      //   for (let i = 0; i < concentrations.length; i++) {
      //     let concentration = concentrations[i];
      //     writePromiseFunctions.push(() =>
      //       executeSingleQuery(
      //         updateConcentration,
      //         {
      //           input: {
      //             id: concentration.id,
      //             _version: (concentration as any)._version,
      //             [key]: newItem.uid,
      //             status:
      //               concentration.status === ProgressStatus.ACTIVE
      //                 ? ProgressStatus.DELETED
      //                 : concentration.status,
      //           },
      //         },
      //         undefined,
      //         false,
      //         true
      //       ).catch((error) => {
      //         console.error('Error in setAllDosesToNewParentID: ', error);
      //         return null;
      //       })
      //     );
      //   }
      // }
      let results: any | null = await BatchQuery(
        writePromiseFunctions,
        querySize
      );
      if (debug) console.log(prevItem.TAG, 'Write Results: ', results);
      for (let i = 0; i < results.length; i++) {
        let result = results[i];
        if (result == null) {
          if (globals.debug)
            console.error('Error in setAllDosesToNewParentID: ', results[i]);
        }
      }
      resolve({
        type: ResponseType.Success,
        data: results,
      });
    } catch (error) {
      reject(error);
    }
  });
};

type SetAllReferencesToNewID = {
  filter?: any;
  isArray?: boolean;
  querySize?: number;
  departmentID?: string;
};
/**
 * Sets all references to the new ID.
 * @param prevItem - The previous item.
 * @param newItem - The new item.
 * @param updateFunction - The update function. Ex. updateMedicationDose
 * @param queryFunction - The query function. Ex. medicationDosesByMedicationID
 * @param key - The key to update. Ex. medicationID
 * @param isArray - Whether the key is an array. Ex. equipmentIDs
 * @param querySize - The query size. Ex. 100
 * @returns A Promise that resolves to a Response object.
 */
export const setAllReferencesToNewID = async (
  prevItem: ModelItem<any> | ModelSubItem<any>,
  newItem: ModelItem<any> | ModelSubItem<any>,
  updateFunction: GeneratedMutation<any, any>,
  queryFunction: GeneratedQuery<any, any>,
  key: string,
  config?: SetAllReferencesToNewID
): Promise<Response> => {
  return new Promise(async (resolve, reject) => {
    try {
      let filter: any = {
        and: [
          {
            _deleted: {
              ne: true,
            },
          },
          {
            [key]: config?.isArray
              ? {
                  contains: prevItem.uid,
                }
              : {
                  eq: prevItem.uid,
                },
          },
        ],
      };
      let queryArgs: any = config?.departmentID
        ? {
            departmentID: config?.departmentID,
            filter: filter,
          }
        : {
            filter: filter,
          };
      let items = await executeQuery(queryFunction, queryArgs);
      if (globals.debug)
        console.log('setAllReferencesToNewID:', key, 'Items: ', items);

      let writePromiseFunctions: (() => Promise<any>)[] = [];
      if (items.length > 0) {
        for (let i = 0; i < items.length; i++) {
          let item = items[i];
          if (config?.isArray) {
            let arr = item[key];
            if (arr && arr.length > 0 && arr.includes(prevItem.uid)) {
              let newArr = arr.map((id: string) => {
                if (id === prevItem.uid) return newItem.uid;
                return id;
              });
              writePromiseFunctions.push(() =>
                executeSingleQuery(
                  updateFunction,
                  {
                    input: {
                      id: item.id,
                      [key]: newArr,
                      _version: item._version,
                    },
                  },
                  undefined,
                  false,
                  true
                )
              );
            }
          } else if (item[key] === prevItem.uid) {
            writePromiseFunctions.push(() =>
              executeSingleQuery(
                updateFunction,
                {
                  input: {
                    id: item.id,
                    [key]: newItem.uid,
                    _version: item._version,
                  },
                },
                undefined,
                false,
                true
              )
            );
          }
        }
      }
      let results: any | null = await BatchQuery(
        writePromiseFunctions,
        config?.querySize
      );
      if (globals.debug)
        console.log('setAllReferencesToNewID:', key, 'Results: ', results);
      for (let i = 0; i < results.length; i++) {
        let result = results[i];
        if (result == null) {
          console.error('Error in setAllReferencesToNewID: ', results[i]);
        }
      }
      resolve({
        type: ResponseType.Success,
        data: results,
      });
    } catch (error) {
      reject(error);
    }
  });
};

/**
 * Copies all OTHER department concentrations to the archive.
 * @param prevItem - The previous item.
 * @param newItem - The new item.
 * @param key - The key.
 * @param status - The status to set the concentrations to.
 * @param querySize - The query size.
 * @param debug - Whether to debug.
 * @returns A Promise that resolves to a Response object.
 *  - If status is DELETED, the ACTIVE concentrations will be deleted.
 */
export const copyConcentrationsToStatus = async (
  prevItem: MedicationItem | InfusionItem,
  newItem: MedicationItem | InfusionItem,
  key: string,
  status: ProgressStatus,
  querySize?: number,
  debug: boolean = false
): Promise<Response> => {
  return new Promise(async (resolve, reject) => {
    try {
      let filter: any = {
        and: [
          {
            _deleted: {
              ne: true,
            },
          },
          {
            status: {
              eq: ProgressStatus.ACTIVE,
            },
          },
        ],
      };

      let concentrations: Concentration[] = await executeQuery(
        key === 'medicationID'
          ? concentrationsByMedicationID
          : concentrationsByDripID,
        {
          [key]: prevItem.uid,
          filter: filter,
        }
      );

      if (debug)
        console.log(
          prevItem.TAG,
          prevItem.name,
          'Concentrations: ',
          concentrations
        );
      let writePromiseFunctions: (() => Promise<any>)[] = [];

      if (concentrations.length > 0) {
        for (let i = 0; i < concentrations.length; i++) {
          let concentration = concentrations[i];
          writePromiseFunctions.push(() =>
            executeSingleQuery(
              createConcentration,
              {
                input: {
                  [key]: newItem.uid,
                  status: status,
                  firstAmnt: concentration.firstAmnt,
                  firstUnit: concentration.firstUnit,
                  secAmnt: concentration.secAmnt,
                  secUnit: concentration.secUnit,
                  departmentID: concentration.departmentID,
                } as CreateConcentrationInput,
              },
              undefined,
              false,
              true
            )
          );
          if (status === ProgressStatus.DELETED) {
            writePromiseFunctions.push(() =>
              executeSingleQuery(
                deleteConcentration,
                {
                  input: {
                    id: concentration.id,
                    _version: (concentration as any)._version,
                  },
                },
                undefined,
                false,
                true
              ).catch((error) => {
                console.error('Error in setAllDosesToNewParentID: ', error);
                return null;
              })
            );
          }
        }
      }
      let results: any | null = await BatchQuery(
        writePromiseFunctions,
        querySize
      );
      if (debug)
        console.log(prevItem.TAG, prevItem.name, 'Write Results: ', results);
      for (let i = 0; i < results.length; i++) {
        let result = results[i];
        if (result == null) {
          console.error('Error in copyConcentrationsToArchive: ', results[i]);
        }
      }
      resolve({
        type: ResponseType.Success,
        data: results,
      });
    } catch (error) {
      reject(error);
    }
  });
};
