import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ProtocolHeader from '../../protocol/ProtocolHeader';
import {
  groupArchivedItemsByActiveID,
  getFormattedDate,
  getDepartmentsFromState,
  updateArchiveItemsCreatedAt,
  linkSubItemsToItem,
  compareVersions,
} from '../../../_global/common/Utils';
import { FaChevronRight, FaLock } from 'react-icons/fa6';
import ProtocolItem from '../../../../data/model/ProtocolItem';
import { ProgressStatus, User } from '../../../../models';
import Status from '../../../components/ProgressStatus/ProgressStatus';
import { Alert, Snackbar } from '@mui/material';
import { ArchiveItem } from '../../../../data/functions/ModelDB';
import Loading from '../../../components/Loading/Loading';
import DataList, { Header } from '../../database/lists/DataList';
import { DatabaseResponse, Response } from '../../../../data/AmplifyDB';
import DepartmentItem from '../../../../data/model/DepartmentItem';
import { fetchAllMedicationDosesForProtocol } from '../../../../data/functions/MedicationDB';
import { fetchAllInfusionDosesForProtocol } from '../../../../data/functions/InfusionDB';
import { fetchAllElectricalShocksForProtocol } from '../../../../data/functions/ElectricalDB';
import { fetchAllEquipmentForProtocol } from '../../../../data/functions/EquipmentDB';
import { fetchAllChecklistsForProtocol } from '../../../../data/functions/CheckListDB';
import FormItem from '../../../../data/model/FormItem';
import ElectricalSubItem from '../../../../data/model/ElectricalSubItem';
import MedicationSubItem from '../../../../data/model/MedicationSubItem';
import EquipmentItem from '../../../../data/model/EquipmentItem';
import InfusionSubItem from '../../../../data/model/InfusionSubItem';

const DEFAULT_USERNAME = 'Hinckley Medical';

const ListArchiveProtocolsDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;

  if (
    !state?.data ||
    !state?.department ||
    !state?.database ||
    !state?.archiveData ||
    !state?.activeItem
  ) {
    navigate('/archive');
  }

  const {
    data: sourceData,
    department,
    database,
    archiveData: protocol,
    activeItem,
  } = state;

  const reducerState = useSelector((state: any) => state.department);

  const [protocolList, setProtocolList] = useState<ProtocolItem[]>(
    protocol.sort((a: ProtocolItem, b: ProtocolItem) =>
      compareVersions(b.version, a.version)
    )
  );

  const [isLoading, setIsLoading] = useState<string>('');
  const [selectedProtocol, setSelectedProtocol] = useState<ProtocolItem | null>(
    null
  );

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success' as 'success' | 'error' | 'warning' | 'info',
  });

  const activeParmProtocol = useMemo(() => activeItem, [activeItem]);

  const { allSubDeps } = useMemo(() => {
    return getDepartmentsFromState(department, reducerState);
  }, [department, reducerState]);

  const processItem = useCallback(
    async (
      database: DatabaseResponse,
      department: DepartmentItem,
      prot: ProtocolItem,
      type: string
    ) => {
      let itemResponse: Response | null = null;
      if (type === 'MedicationDoses') {
        itemResponse = await fetchAllMedicationDosesForProtocol(
          database,
          department,
          prot
        );
      } else if (type === 'InfusionDoses') {
        itemResponse = await fetchAllInfusionDosesForProtocol(
          database,
          department,
          prot
        );
      } else if (type === 'ElectricalShocks') {
        itemResponse = await fetchAllElectricalShocksForProtocol(
          database,
          department,
          prot
        );
      } else if (type === 'Equipment') {
        itemResponse = await fetchAllEquipmentForProtocol(
          database,
          department,
          prot
        );
      } else if (type === 'Checklist') {
        itemResponse = await fetchAllChecklistsForProtocol(
          database,
          department,
          prot
        );
      } else if (type === 'PairedProtocol') {
        const pairedProtocols = prot.pairedProtocols;
        itemResponse = groupArchivedItemsByActiveID(pairedProtocols);
      }

      if (itemResponse) {
        const processedData = updateArchiveItemsCreatedAt(
          itemResponse.data
        ) as ArchiveItem[];
        const tempSubItems = linkSubItemsToItem(prot, processedData);
        return tempSubItems;
      }
      return null;
    },
    []
  );

  const handleItemClick = useCallback(
    async (prot: ProtocolItem) => {
      setSelectedProtocol(prot);
      setIsLoading('Loading protocol details...');

      try {
        if (prot.status !== ProgressStatus.ACTIVE) {
          // Storing the previous pairedProtocols length to update the sum later
          const pairedProtocolsLength = prot.pairedProtocols.length;

          const [
            medicationTempSubItems,
            infusionTempSubItems,
            electricalTempSubItems,
            equipmentTempItems,
            checklistTempItems,
            pairedProtocolsTempItems,
          ] = await Promise.all([
            processItem(database, department, prot, 'MedicationDoses'),
            processItem(database, department, prot, 'InfusionDoses'),
            processItem(database, department, prot, 'ElectricalShocks'),
            processItem(database, department, prot, 'Equipment'),
            processItem(database, department, prot, 'Checklist'),
            processItem(database, department, prot, 'PairedProtocol'),
          ]);

          // Update the respective arrays with the new sub items
          prot.medications = medicationTempSubItems as MedicationSubItem[];
          prot.infusions = infusionTempSubItems as InfusionSubItem[];
          prot.electrical = electricalTempSubItems as ElectricalSubItem[];
          prot.equipment = equipmentTempItems as EquipmentItem[];
          prot.forms = checklistTempItems as FormItem[];
          prot.pairedProtocols = pairedProtocolsTempItems as ProtocolItem[];

          // Update sum with counts of all sub-items
          prot.sum =
            prot.sum +
            [
              medicationTempSubItems,
              infusionTempSubItems,
              electricalTempSubItems,
              equipmentTempItems,
              checklistTempItems,
            ].reduce((acc, items) => acc + (items?.length ?? 0), 0);

          // Update the sum with the count of the pairedProtocols
          prot.sum =
            prot.sum -
            pairedProtocolsLength +
            (pairedProtocolsTempItems?.length ?? 0);
        } else if (
          prot.status === ProgressStatus.ACTIVE &&
          activeParmProtocol.status === ProgressStatus.DRAFT
        ) {
          /* When the selected protocol is active and there is a draft item for the same protocol,
           * there are some items that are not loaded. This logic is to load those items.
           */

          // Set the medication items if they are not already set
          if (prot.medications.length === 0) {
            prot.medications = activeParmProtocol.medications;
            prot.sum = prot.sum + prot.medications.length;
          }

          // Set the infusion items if they are not already set
          if (prot.infusions.length === 0) {
            prot.infusions = activeParmProtocol.infusions;
            prot.sum = prot.sum + prot.infusions.length;
          }

          // Set the electrical items if they are not already set
          if (prot.electrical.length === 0) {
            prot.electrical = activeParmProtocol.electrical;
            prot.sum = prot.sum + prot.electrical.length;
          }

          // Set the equipment items if they are not already set
          if (
            prot.model?.equipmentIDs?.length &&
            prot.model.equipmentIDs.length > 0 &&
            prot.equipment.length === 0
          ) {
            prot.model.equipmentIDs.forEach((id) => {
              const equipment = database.equipment.find(
                (e: EquipmentItem) => e.uid === id || e.activeID === id
              );
              if (equipment) {
                prot.equipment.push(equipment);
              }
            });
            prot.sum = prot.sum + prot.equipment.length;
          }

          // Set the checklist items if they are not already set
          if (
            prot.model?.formIDs?.length &&
            prot.model.formIDs.length > 0 &&
            prot.forms.length === 0
          ) {
            prot.model.formIDs.forEach((id) => {
              const form = database.checklists.find(
                (f: FormItem) => f.uid === id || f.activeID === id
              );
              if (form) {
                prot.forms.push(form);
              }
            });
            prot.sum = prot.sum + prot.forms.length;
          }

          // Set the paired protocols if they are not already set
          if (
            prot.model?.pairedProtocols?.length &&
            prot.model.pairedProtocols.length > 0 &&
            prot.pairedProtocols.length === 0
          ) {
            prot.model.pairedProtocols.forEach((id) => {
              const pairedProtocol = database.protocols.find(
                (p: ProtocolItem) => p.uid === id || p.activeID === id
              );
              if (pairedProtocol) {
                prot.pairedProtocols.push(pairedProtocol);
              }
            });
            prot.sum = prot.sum + prot.pairedProtocols.length;
          }
        }

        const protocolState = {
          selectedProtocol: prot,
          editMode: false,
          backPage: 'list-protocols-details',
          archivePayload: state,
        };
        const formattedString = prot.nickname.replace(/[\s/]/g, '-');
        navigate(`/${formattedString}/protocol-detail`, {
          state: protocolState,
        });
      } catch (error) {
        console.error('Error processing protocol:', error);
        setSnackbar({
          open: true,
          message: 'Error loading protocol details',
          severity: 'error',
        });
      } finally {
        setIsLoading('');
      }
    },
    [navigate, state, database, department, processItem, activeParmProtocol]
  );

  const handleBack = useCallback(() => {
    navigate('/archive/list-protocols', {
      state: {
        data: sourceData,
        department: department,
        database: database,
      },
    });
  }, [navigate, sourceData, department, database]);

  const handleSortItem = useCallback(
    (key: string, direction: string, a: ProtocolItem, b: ProtocolItem) => {
      if (key === 'version') {
        return direction === 'asc'
          ? compareVersions(a.version, b.version)
          : compareVersions(b.version, a.version);
      } else if (key === 'name') {
        return direction === 'asc'
          ? a.name.localeCompare(b.name)
          : b.name.localeCompare(a.name);
      } else if (key === 'subs') {
        const aAvailable = (allSubDeps?.length ?? 0) + 1;
        const bAvailable = (allSubDeps?.length ?? 0) + 1;
        const aCount = !a.isRestrictive
          ? aAvailable
          : (a.pairedDepIDs?.length ?? 0);
        const bCount = !b.isRestrictive
          ? bAvailable
          : (b.pairedDepIDs?.length ?? 0);
        return direction === 'asc' ? aCount - bCount : bCount - aCount;
      } else if (key === 'archived_date') {
        const aDate = new Date(a.modifiedAt || Date.now());
        const bDate = new Date(b.modifiedAt || Date.now());
        return direction === 'asc'
          ? aDate.getTime() - bDate.getTime()
          : bDate.getTime() - aDate.getTime();
      }
      return 0;
    },
    [allSubDeps]
  );

  const headers: Header<ProtocolItem>[] = useMemo(
    () => [
      {
        key: 'version',
        name: 'Version',
        sortable: true,
        flex: 0.5,
        render: (item: ProtocolItem) => item.version,
      },
      {
        key: 'name',
        name: 'Name',
        sortable: true,
        flex: 2,
        render: (item: ProtocolItem) => (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {!(
              item.departmentID === department.id &&
              item.status !== 'DEACTIVATED'
            ) && (
              <div style={{ marginRight: '8px' }}>
                <FaLock className="table-icon" color="#A3A3A3" />
              </div>
            )}
            {item.name}
            <Status status={item.status} style={{ marginLeft: '8px' }} />
          </div>
        ),
      },
      {
        key: 'subs',
        name: 'Subs',
        sortable: true,
        flex: 0.75,
        render: (item: ProtocolItem) => {
          const availableSubs = (allSubDeps?.length ?? 0) + 1;
          const itemSubs = !item.isRestrictive
            ? availableSubs
            : (item.pairedDepIDs?.length ?? 0);
          return `${itemSubs}/${availableSubs}`;
        },
      },
      {
        key: 'access',
        name: 'Access',
        sortable: true,
        flex: 0.75,
        render: (item: ProtocolItem) => {
          const status: 'Private' | 'Public' | 'Protected' = !item.model
            .isPublic
            ? 'Private'
            : item.model.keychainID
              ? 'Protected'
              : 'Public';
          return (
            <span style={{ flex: 6, textAlign: 'center' }}>
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Status status={status} />
              </div>
            </span>
          );
        },
      },
      {
        key: 'archived_date',
        name: 'Archived Date',
        sortable: true,
        flex: 1,
        render: (item: ProtocolItem) =>
          getFormattedDate(new Date(item.modifiedAt || Date.now()), true),
      },
      {
        key: 'archived_by',
        name: 'Archived By',
        sortable: true,
        flex: 1,
        render: (item: ProtocolItem) => {
          const userId = item.model?.modifiedBy || item.model?.createdBy;
          const user = userId
            ? database.users.find((user: User) => user.id === userId)
            : null;
          const username = user
            ? `${user.firstName} ${user.lastName}`
            : DEFAULT_USERNAME;
          return (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
                paddingRight: '15px',
              }}
            >
              <span>{username}</span>
              <FaChevronRight
                className="icon-normal"
                style={{ margin: '4px' }}
              />
            </div>
          );
        },
      },
    ],
    [department.id, database.users, allSubDeps]
  );

  return (
    <div className="screen-container">
      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>

      <ProtocolHeader
        isBackButton={true}
        handleCancel={handleBack}
        name={`${activeParmProtocol.activeItem?.name || activeParmProtocol.name}: ${protocolList.length - 1} archive${protocolList.length - 1 === 1 ? '' : 's'}`}
        page={'Protocols Archive'}
        type="protocol"
      />

      <DataList<ProtocolItem, never>
        items={protocolList}
        headers={headers}
        onItemClick={handleItemClick}
        searchPlaceholder="Search Protocols..."
        sortItem={handleSortItem}
      />

      {isLoading && <Loading type="bubbles" visible={isLoading} />}
    </div>
  );
};

export default ListArchiveProtocolsDetails;
