import React, { useEffect, useMemo, useRef, useState } from 'react';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { BiCopy } from 'react-icons/bi';
import { BiSolidCopy } from 'react-icons/bi';
import { FaBoltLightning, FaSyringe } from 'react-icons/fa6';
import { RiDownload2Line, RiUpload2Line } from 'react-icons/ri';
import { handleCopy } from '../_global/common/Utils';
import { IoDocument, IoWater } from 'react-icons/io5';

interface CustomIconProps {
  icon: 'medication dose' | 'infusion dose' | 'electrical dose';
  style?: React.CSSProperties;
  size?: number;
}

/* 10-11-23 Arul: Created the CustomPdfUpload component for Protocol Modal */
const CustomIcon: React.FC<CustomIconProps> = (props) => {
  const type = useMemo(() => {
    return props.icon;
  }, [props.icon]);

  return (
    <span style={props.style}>
      {type === 'medication dose' && (
        <div style={{ position: 'relative', display: 'inline-block' }}>
          <IoDocument style={{ fontSize: props.size ? props.size : '24px' }} />
          <div
            style={{
              position: 'absolute',
              bottom: '-3px',
              right: '-3px',
              backgroundColor: 'white',
              borderRadius: '50%',
              padding: '2px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <FaSyringe
              style={{
                fontSize: props.size ? props.size / 2 : '12px',
              }}
            />
          </div>
        </div>
      )}
      {type === 'infusion dose' && (
        <div style={{ position: 'relative', display: 'inline-block' }}>
          <IoDocument style={{ fontSize: props.size ? props.size : '24px' }} />
          <div
            style={{
              position: 'absolute',
              bottom: '-3px',
              right: '-3px',
              backgroundColor: 'white',
              borderRadius: '50%',
              padding: '2px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <IoWater
              style={{
                fontSize: props.size ? props.size / 2 : '12px',
              }}
            />
          </div>
        </div>
      )}
      {type === 'electrical dose' && (
        <div style={{ position: 'relative', display: 'inline-block' }}>
          <IoDocument style={{ fontSize: props.size ? props.size : '24px' }} />
          <div
            style={{
              position: 'absolute',
              bottom: '-3px',
              right: '-3px',
              backgroundColor: 'white',
              borderRadius: '50%',
              padding: '2px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <FaBoltLightning
              style={{
                fontSize: props.size ? props.size / 2 : '12px',
              }}
            />
          </div>
        </div>
      )}
    </span>
  );
};

export default CustomIcon;
