import React, { useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ProtocolHeader from '../../protocol/ProtocolHeader';
import { getFormattedDate } from '../../../_global/common/Utils';
import { FaChevronRight, FaLock } from 'react-icons/fa6';
import EquipmentItem from '../../../../data/model/EquipmentItem';
import { ProgressStatus, User } from '../../../../models';
import { ArchiveItem } from '../../../../data/functions/ModelDB';
import DataList, { Header } from '../../database/lists/DataList';

const DEFAULT_USERNAME = 'Hinckley Medical';

const ListArchiveEquipment = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;

  // Validate location state
  if (!state?.data || !state?.department || !state?.database) {
    navigate('/archive');
  }

  const { data: equi, department, database } = state;

  const equipmentList = useMemo(() => {
    return equi.sort((a: ArchiveItem, b: ArchiveItem) => {
      let aDate = new Date(a.activeItem?.modifiedAt || Date.now());
      let bDate = new Date(b.activeItem?.modifiedAt || Date.now());
      return bDate.getTime() - aDate.getTime();
    });
  }, [equi]);

  const handleItemClick = useCallback(
    (equipment: ArchiveItem) => {
      navigate(`/archive/list-equipment-details`, {
        state: {
          data: state.data,
          department: department,
          database: database,
          archiveData: [
            ...equipment.items,
            equipment.activeItem.status === 'DRAFT'
              ? equipment.activeItem.activeItem
              : equipment.activeItem,
          ],
          activeItem: equipment.activeItem,
        },
      });
    },
    [navigate, state.data, department, database]
  );

  const handleBack = useCallback(() => {
    sessionStorage.removeItem('DataList-' + location.pathname);
    navigate('/archive');
  }, [navigate, location.pathname]);

  const getActiveForm = (item: ArchiveItem): EquipmentItem => {
    return item.activeItem.status === ProgressStatus.DRAFT
      ? (item.activeItem.activeItem as EquipmentItem)
      : (item.activeItem as EquipmentItem);
  };

  const headers: Header<ArchiveItem>[] = useMemo(
    () => [
      {
        key: 'name',
        name: 'Name',
        sortable: true,
        flex: 2,
        render: (item: ArchiveItem) => {
          const activeForm = getActiveForm(item);
          const isOwner = activeForm?.departmentID === department.id;

          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {!(isOwner && activeForm?.status !== 'DEACTIVATED') && (
                <div style={{ marginRight: '8px' }}>
                  <FaLock className="table-icon" color="#A3A3A3" />
                </div>
              )}
              {activeForm?.name}
            </div>
          );
        },
      },
      {
        key: 'modified_date',
        name: 'Modified Date',
        sortable: true,
        flex: 1,
        render: (item: ArchiveItem) => {
          const activeForm = getActiveForm(item);
          return getFormattedDate(
            new Date(activeForm?.modifiedAt || Date.now()),
            true
          );
        },
      },
      {
        key: 'modified_by',
        name: 'Modified By',
        sortable: true,
        flex: 1,
        render: (item: ArchiveItem) => {
          const activeForm = getActiveForm(item);
          const userId =
            activeForm?.model?.modifiedBy || activeForm?.model?.createdBy;
          const user = userId
            ? database.users.find((user: User) => user.id === userId)
            : null;
          return user ? `${user.firstName} ${user.lastName}` : DEFAULT_USERNAME;
        },
      },
      {
        key: 'archived_items',
        name: 'Archived Items',
        sortable: true,
        flex: 1,
        render: (item: ArchiveItem) => (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
              paddingRight: '15px',
            }}
          >
            <span>{item.items.length}</span>
            <FaChevronRight className="icon-normal" style={{ margin: '4px' }} />
          </div>
        ),
      },
    ],
    [department.id, database.users]
  );

  const handleSortItem = useCallback(
    (key: string, direction: string, a: ArchiveItem, b: ArchiveItem) => {
      const getActiveForm = (item: ArchiveItem) => {
        return item.activeItem.status === ProgressStatus.DRAFT
          ? item.activeItem.activeItem
          : item.activeItem;
      };

      if (key === 'name') {
        const aName = getActiveForm(a)?.name || '';
        const bName = getActiveForm(b)?.name || '';
        return direction === 'asc'
          ? aName.localeCompare(bName)
          : bName.localeCompare(aName);
      } else if (key === 'modified_date') {
        const aDate = new Date(getActiveForm(a)?.modifiedAt || Date.now());
        const bDate = new Date(getActiveForm(b)?.modifiedAt || Date.now());
        return direction === 'asc'
          ? aDate.getTime() - bDate.getTime()
          : bDate.getTime() - aDate.getTime();
      } else if (key === 'archived_items') {
        return direction === 'asc'
          ? a.items.length - b.items.length
          : b.items.length - a.items.length;
      } else if (key === 'modified_by') {
        const aForm = getActiveForm(a);
        const bForm = getActiveForm(b);
        const aUserId = aForm?.model?.modifiedBy || aForm?.model?.createdBy;
        const bUserId = bForm?.model?.modifiedBy || bForm?.model?.createdBy;

        const aUser = aUserId
          ? database.users.find((user: User) => user.id === aUserId)
          : null;
        const bUser = bUserId
          ? database.users.find((user: User) => user.id === bUserId)
          : null;

        const aName = aUser
          ? `${aUser.firstName} ${aUser.lastName}`
          : DEFAULT_USERNAME;
        const bName = bUser
          ? `${bUser.firstName} ${bUser.lastName}`
          : DEFAULT_USERNAME;

        return direction === 'asc'
          ? aName.toLowerCase().localeCompare(bName.toLowerCase())
          : bName.toLowerCase().localeCompare(aName.toLowerCase());
      }
      return 0;
    },
    [database.users]
  );

  const searchFilter = useCallback((item: ArchiveItem, searchQuery: string) => {
    const activeForm = getActiveForm(item);
    return activeForm?.name?.toLowerCase().includes(searchQuery.toLowerCase());
  }, []);

  return (
    <div className="screen-container">
      <ProtocolHeader
        isBackButton={true}
        handleCancel={handleBack}
        name={`Equipment: ${equipmentList.length} Archive${equipmentList.length === 1 ? '' : 's'}`}
        page={`${department.name} Archive`}
        type="protocol"
      />

      <DataList<ArchiveItem, never>
        items={equipmentList}
        headers={headers}
        onItemClick={handleItemClick}
        searchPlaceholder="Search Equipment..."
        sortItem={handleSortItem}
        searchFilter={searchFilter}
      />
    </div>
  );
};

export default ListArchiveEquipment;
