import { useEffect, useMemo, useRef, useState } from 'react';
import DepartmentItem from '../../../data/model/DepartmentItem';
import { HiPlus } from 'react-icons/hi';
import { Sidebar } from 'primereact/sidebar';
import { InputText } from 'primereact/inputtext';
import { Button } from 'react-bootstrap';
import CategoryItem from '../../../data/model/CategoryItem';
import ProtocolItem from '../../../data/model/ProtocolItem';
import {
  findPairedDepartments,
  getDepartmentsFromState,
  handleCopy,
  toTitleCase,
} from '../../_global/common/Utils';
import Status from '../ProgressStatus/ProgressStatus';
import EditAccessModal from './EditAccessModal';
import { useSelector } from 'react-redux';
import { DatabaseResponse } from '../../../data/AmplifyDB';
import { User } from '../../../models';
import { BiCopy, BiRename, BiSolidCopy } from 'react-icons/bi';
import FolderProtocolAdvancedSettings from '../FolderProtocolAdvancedSettings';
import { FaFolderOpen, FaLock } from 'react-icons/fa6';
import { Sketch } from '@uiw/react-color';
import { IoDocumentText } from 'react-icons/io5';

interface FolderProtocolModalProps {
  isVisible: boolean;
  handleClose: () => void;
  handleAdd: (data: any) => void;
  department: DepartmentItem;
  item: CategoryItem | ProtocolItem;
}
/* 10-11-23 Arul: Created the FolderProtocolModal component for globally for Edit Folder Page */
const EditFolderModal = (props: FolderProtocolModalProps) => {
  const { isVisible, handleClose, handleAdd, department, item } = props;
  const [departmentList, setDepartmentList] = useState<DepartmentItem[]>([]);
  const user: User = useSelector((state: any) => state.user);
  const colorPresets = useSelector((state: any) => state.colorPresets);

  const [name, setName] = useState<string>('');
  const [nickname, setNickname] = useState<string>('');
  const [index, setIndex] = useState<number>(0);
  const [color, setColor] = useState<string>(item.color ?? '#616161');
  const [isColorPickerOpen, setIsColorPickerOpen] = useState<boolean>(false);

  const colorPickerRef = useRef<HTMLDivElement>(null);
  const colorInputRef = useRef<HTMLDivElement>(null);

  const reducerState = useSelector((state: any) => state.department);
  const { subDeps, allSubDeps, userDepartments } = useMemo(() => {
    return getDepartmentsFromState(department, reducerState);
  }, [department, reducerState]);

  const [pairedDeps, setPairedDeps] = useState<DepartmentItem[]>(
    findPairedDepartments(department, reducerState, item) ?? []
  );
  const [activeID, setActiveID] = useState<string | null | undefined>(
    item.activeID
  );
  const [owner, setOwner] = useState<DepartmentItem | undefined>(department);
  const [isRestrictive, setIsRestrictive] = useState<boolean>(
    item?.isRestrictive ?? false
  );
  const [isAccessVisible, setAccessVisible] = useState<boolean>(false);
  const database: DatabaseResponse = useSelector(
    (state: any) => state.protocol.departmentItem
  );
  const [isCopied, setIsCopied] = useState<string | null>(null);

  const isLockedStatus = useMemo(() => {
    if (item.TAG === 'ProtocolItem' && !(item as ProtocolItem).parent.isPublic)
      return true;
    return false;
  }, [item]);

  useEffect(() => {
    if (subDeps) {
      let l = [...subDeps];
      l = l.filter((dep) => {
        return !pairedDeps.some((d: DepartmentItem) => d.id === dep.id);
      });
      setDepartmentList(l);
    }
  }, [department, subDeps, pairedDeps]);

  useEffect(() => {
    if (item) {
      setName(item.name);
      setIndex(item.index);
      if (item.TAG === 'ProtocolItem')
        setNickname((item as ProtocolItem).nickname);
      let l = findPairedDepartments(department, reducerState, item);
      setPairedDeps(l ?? []);
    }
  }, [item, department, reducerState]);

  /* 10-11-23 Arul: Function for handling modal submit */
  const handleSubmit = async () => {
    const data = {
      value: name,
      index: index,
      nickName: nickname,
      pdf: '',
      pairedDeps: pairedDeps,
      owner: owner,
      isRestrictive: isRestrictive,
      color: color,
    };
    handleAdd(data);
  };

  const customHeader = (
    <div
      className="buttonContainer contentTitleLarge hoverText"
      onClick={handleClose}
    >
      <span className="">
        <HiPlus className="header-icon" style={{ marginLeft: '5px' }} /> Add{' '}
      </span>
    </div>
  );

  const handleAddDepartment = (option: DepartmentItem) => {
    let l = [...pairedDeps, option];
    l.sort((a, b) => a.name.localeCompare(b.name));
    setPairedDeps(l);
  };

  const handleRemoveDepartment = (option: DepartmentItem, e: any) => {
    e.stopPropagation();
    let l = pairedDeps.filter((d: DepartmentItem) => d.id !== option.id);
    setPairedDeps(l);
  };

  const handleClearDepartments = () => {
    setPairedDeps([]);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (isColorPickerOpen) {
        // Check if the click is outside both the color picker and the input container
        if (
          colorPickerRef.current &&
          colorInputRef.current &&
          !colorPickerRef.current.contains(event.target as Node) &&
          !colorInputRef.current.contains(event.target as Node)
        ) {
          setIsColorPickerOpen(false);
        }
      }
    };

    // Add event listener when color picker is open
    if (isColorPickerOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    // Clean up
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isColorPickerOpen]);

  const isSaveActive = useMemo(() => {
    if (item && owner) {
      let changed = false;
      //Check if every ID is in the pairedDeps
      if (item.isRestrictive !== isRestrictive) changed = true;
      else if (isRestrictive) {
        if (pairedDeps && item.pairedDepIDs) {
          if (pairedDeps.length !== item.pairedDepIDs.length) changed = true;
          else {
            let ids = item.pairedDepIDs;
            if (ids) {
              for (let i = 0; i < pairedDeps.length; i++) {
                if (!ids.includes(pairedDeps[i].id)) {
                  changed = true;
                  break;
                }
              }
            }
          }
        }
      }

      //Check if the owner is different
      changed = changed || owner.id !== item.departmentID;
      if (item.TAG === 'ProtocolItem')
        changed =
          changed ||
          (nickname !== (item as ProtocolItem).nickname && nickname !== '');
      return (
        (changed ||
          name !== item.name ||
          index !== item.index ||
          color !== item.color) &&
        name !== '' &&
        index >= 0
      );
    }
    return false;
  }, [name, item, pairedDeps, index, nickname, owner, isRestrictive, color]);

  const availableDepartments = useMemo(() => {
    if (owner == null) return allSubDeps;
    const ownerDeps = getDepartmentsFromState(owner, reducerState).allSubDeps;
    let list = ownerDeps ?? [];

    if (item.TAG === 'ProtocolItem') {
      const parentFolder = (item as ProtocolItem).parent;
      list = list.filter((dep) => {
        if (!parentFolder.isRestrictive) return true;
        else if (parentFolder.pairedDepIDs) {
          return parentFolder.pairedDepIDs.includes(dep.id);
        } else return false;
      });
    }
    return list;
  }, [owner, item, reducerState, allSubDeps]);

  return (
    <Sidebar
      visible={isVisible}
      position="right"
      onHide={handleClose}
      header={customHeader}
      style={{ width: '25%', minWidth: '400px' }}
      className={isVisible ? 'protocolSidebar sidebarWidth' : 'sidebar-hidden'}
    >
      <EditAccessModal
        isVisible={isAccessVisible}
        handleClose={() => setAccessVisible(false)}
        handleSubmit={(
          newItem: DepartmentItem | CategoryItem | ProtocolItem,
          metaData: any
        ) => {
          setAccessVisible(false);
        }}
        department={department}
        keychains={database.keychains}
        item={item}
      />

      <div className="sidebarContainer">
        <div
          className="buttonContainer contentTitleLarge"
          onClick={handleClose}
        >
          Edit {item.TAG === 'CategoryItem' ? 'Folder' : 'Protocol'}
        </div>
        <div className="contentText">
          {item.TAG === 'CategoryItem'
            ? 'Edit the folder name, index and the protocols that are subscribed to it.'
            : 'Edit the protocol name, index and the departments that are subscribed to it.'}
        </div>
        {user.type === 'ADMIN' && (
          <div>
            <div
              className="contentText"
              style={{
                marginTop: '10px',
              }}
            >
              ID: {item.uid}
              <span>
                {isCopied && isCopied === item.uid ? (
                  <BiSolidCopy
                    color={'#00534C'}
                    size=".75rem"
                    className="copy_icon"
                  />
                ) : (
                  <BiCopy
                    size=".75rem"
                    className="copy_icon"
                    onClick={(e) => handleCopy(item.uid, e, setIsCopied)}
                  />
                )}
              </span>
            </div>
            {item.activeID != null && (
              <div
                className="contentText"
                style={{
                  marginTop: '10px',
                }}
              >
                Actv. ID: {item.uid}
                <span>
                  {isCopied && isCopied === item.activeID ? (
                    <BiSolidCopy
                      color={'#00534C'}
                      size=".75rem"
                      className="copy_icon"
                    />
                  ) : (
                    <BiCopy
                      size=".75rem"
                      className="copy_icon"
                      onClick={(e) =>
                        handleCopy(item.activeID as string, e, setIsCopied)
                      }
                    />
                  )}
                </span>
              </div>
            )}
          </div>
        )}
        {/* <label htmlFor="name" className={`notification-css-title`} style={{fontSize: '16px'}}>
                        Version: <span style={{fontWeight: '400', marginLeft: '10px'}}>{"   " + (item instanceof CategoryItem ? item.version : item.protocolVersion)}</span>
                    </label> */}
        <label
          htmlFor="index"
          className={`notification-css-title`}
          style={{
            marginTop: '10px',
            alignContent: 'flex-end',
          }}
        >
          <span className="headerTextMargin" style={{ fontSize: '16px' }}>
            Access:
          </span>
          <span
            style={{
              marginTop: '2px',
              marginLeft: '6px',
            }}
          >
            <Status
              status={
                !item.isPublic
                  ? 'Private'
                  : item.keychainID
                    ? 'Protected'
                    : 'Public'
              }
              onClick={
                isLockedStatus
                  ? undefined
                  : () => {
                      setAccessVisible(true);
                    }
              }
            />
          </span>
          {isLockedStatus && (
            <FaLock
              size={16}
              color={'#616161'}
              style={{
                marginLeft: '5px',
                marginTop: '4px',
              }}
            />
          )}
        </label>
        {isLockedStatus && (
          <div
            className="contentText"
            style={{
              marginTop: 0,
            }}
          >
            NOTE: Protocol Access is locked because the folder is private. To
            change the access, edit the folder.
          </div>
        )}
        <label
          htmlFor="name"
          className={`notification-css-title`}
          style={{ fontSize: '16px' }}
        >
          Name
          <span className="required-field ">
            *
            <BiRename
              className="refresh-icon"
              onClick={() => {
                setName(toTitleCase(name));
              }}
              size="1.25rem"
              style={{ marginRight: '5px', cursor: 'pointer' }}
            />
          </span>
        </label>
        <div className="input-container">
          <InputText
            type="text"
            className=" notification-model"
            id="name"
            name="name"
            autoFocus={true}
            required={true}
            value={name}
            onChange={(e: any) => {
              setName(e.target.value);
            }}
            style={{ fontSize: '16px', cursor: 'auto', padding: '20px 20px' }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSubmit();
              }
            }}
          />
          <div className="input-border"></div>
        </div>
        {item.TAG === 'ProtocolItem' && (
          <>
            <label
              htmlFor="nickname"
              className={`notification-css-title`}
              style={{ fontSize: '16px' }}
            >
              Nickname
              <span className="required-field ">
                *
                <BiRename
                  className="refresh-icon"
                  onClick={() => {
                    setNickname(toTitleCase(nickname));
                  }}
                  size="1.25rem"
                  style={{ marginRight: '5px', cursor: 'pointer' }}
                />
              </span>
            </label>
            <span
              className="contentText"
              style={{ fontSize: '13px', margin: 0 }}
            >
              This will be the protocols shorthand name (Ex. "A1.")
            </span>
            <div className="input-container">
              <InputText
                type="text"
                className=" notification-model"
                id="nickname"
                name="nickname"
                autoFocus={true}
                required={true}
                value={nickname}
                onChange={(e: any) => {
                  setNickname(e.target.value);
                }}
                style={{
                  fontSize: '16px',
                  cursor: 'auto',
                  padding: '20px 20px',
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleSubmit();
                  }
                }}
              />
              <div className="input-border"></div>
            </div>
          </>
        )}
        <label
          htmlFor="name"
          className={`notification-css-title`}
          style={{ fontSize: '16px' }}
        >
          Color
        </label>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            position: 'relative',
          }}
        >
          <div
            ref={colorInputRef}
            className="color-input-container"
            onClick={() => {
              setIsColorPickerOpen(!isColorPickerOpen);
            }}
          >
            {item.TAG === 'ProtocolItem' ? (
              <IoDocumentText
                className="table-icon"
                style={{ margin: '0 4px' }}
                color={color}
              />
            ) : (
              <FaFolderOpen
                className="table-icon"
                style={{
                  marginBottom: '4px',
                }}
                color={color}
              />
            )}
          </div>
          {isColorPickerOpen && (
            <div
              ref={colorPickerRef}
              className="color-picker-container"
              style={{
                position: 'absolute',
                left: '18%',
                top: '-30px',
                zIndex: 1000,
              }}
            >
              <Sketch
                color={color}
                onChange={(color) => {
                  setColor(color.hex);
                }}
                presetColors={colorPresets}
                disableAlpha={true}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    setIsColorPickerOpen(false);
                  }
                }}
              />
            </div>
          )}
        </div>
        {/* <label htmlFor="index" className={`notification-css-title`}>
          <span className="headerTextMargin" style={{ fontSize: '16px' }}>
            Index
          </span>
        </label>
        <div className="input-container">
          <InputText
            type="number"
            className=" notification-model"
            id="index"
            name="index"
            autoFocus={true}
            required={true}
            value={index + ''}
            onChange={(e: any) => {
              if (isNaN(Number(e.target.value))) return;
              setIndex(Number(e.target.value));
            }}
            style={{ fontSize: '16px', cursor: 'auto', padding: '20px 20px' }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSubmit();
              }
            }}
          />
          <div className="input-border"></div>
        </div> */}

        {department.isMultiDep && (
          <FolderProtocolAdvancedSettings
            type={item.TAG === 'CategoryItem' ? 'Folder' : 'Protocol'}
            owner={owner}
            setOwner={setOwner}
            isRestrictive={isRestrictive}
            setIsRestrictive={setIsRestrictive}
            availableDepartments={availableDepartments}
            pairedDeps={pairedDeps}
            setPairedDeps={setPairedDeps}
            department={department}
            activeID={activeID}
            setActiveID={setActiveID}
          />
        )}

        <div className="dialogButtonsForce">
          <Button
            data-testid="cancelBtnn"
            className="secondary-button-small-border btn-rightMargin"
            onClick={handleClose}
          >
            {' '}
            Cancel
          </Button>
          <Button
            data-testid="addBttn"
            className="primary-button-small"
            disabled={!isSaveActive}
            onClick={() => {
              handleSubmit();
            }}
          >
            Save
          </Button>
        </div>
      </div>
    </Sidebar>
  );
};

export default EditFolderModal;
