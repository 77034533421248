import { useLocation, useNavigate } from 'react-router-dom';
import ProtocolHeader from '../../../protocol/ProtocolHeader';
import { useEffect, useMemo, useState } from 'react';
import '../import/Import.scss';
import { ViewportList } from 'react-viewport-list';
import { FaChevronDown, FaChevronRight, FaPlus } from 'react-icons/fa6';
import {
  getDepartmentsFromState,
  globals,
  upgradeVersion,
} from '../../../../_global/common/Utils';
import AIPdfParserMedicationInput from './AIPdfParserMedicationInput';
import {
  ElectricalOption,
  ElectricalRange,
  ElectricalShockOption,
  ElectricalShockRange,
  MedicationProtocol,
  MedicationRange,
  ProgressStatus,
} from '../../../../../models';
import MedicationItem from '../../../../../data/model/MedicationItem';
import ProtocolItem from '../../../../../data/model/ProtocolItem';
import {
  MedicationDoseJSON,
  MedicationJSON,
  createMedicationDoseItem,
  createParentMedication,
} from '../../../../../data/functions/MedicationDB';
import {
  DatabaseResponse,
  Response,
  ResponseType,
  loadDatabase,
} from '../../../../../data/AmplifyDB';
import { find } from 'lodash';
// import NewMedicationInput from './NewMedicationInput';
import { useDispatch, useSelector } from 'react-redux';
import { handleGetDepartment } from '../../../../../store/actions';
import SearchableDropdown from '../../../../components/SearchableDropdown';
import {
  ProtocolJSON,
  createProtocol,
} from '../../../../../data/functions/ProtocolDB';
import Fuse from 'fuse.js';
import { Button, Col, Row } from 'react-bootstrap';
import { error, group } from 'console';
import DepartmentItem from '../../../../../data/model/DepartmentItem';
import {
  createInfusionDoseItem,
  createParentInfusion,
  InfusionDoseJSON,
  InfusionJSON,
} from '../../../../../data/functions/InfusionDB';
import ConfirmModal from '../../../../components/Modal/ConfirmModal';
import Loading from '../../../../components/Loading/Loading';
import { IoArrowBack } from 'react-icons/io5';
import ElectricalItem from '../../../../../data/model/ElectricalItem';
import {
  createElectrical,
  createElectricalDoseItem,
  ElectricalDoseJSON,
  ElectricalJSON,
} from '../../../../../data/functions/ElectricalDB';
import AIPdfParserElectricalInput from './AIPdfParserElectricalInput';
import InfusionItem from '../../../../../data/model/InfusionItem';
import MedicationSubItem from '../../../../../data/model/MedicationSubItem';
import InfusionSubItem from '../../../../../data/model/InfusionSubItem';
import ElectricalSubItem from '../../../../../data/model/ElectricalSubItem';
import { AiOutlineClose, AiOutlineRight } from 'react-icons/ai';

interface Dose {
  name: string;
  dose: string;
  administrationRoutes: any;
  maxSingleDose: string;
  minSingleDose: string;
  maxTotalDose: string;
  repeatTime: string;
  repeatAllowed: string;
  ageGroup: string;
  weightLow: string;
  weightHigh: string;
  ageLow: string;
  ageHigh: string;
  title: string;
  instruction: string;
  warning: string;
  source: string;
  medication?: MedicationItem | InfusionItem | ElectricalItem;
  confirmedData?: any;
  isHmMedication?: boolean;
}
interface MedicationData {
  file: string;
  index: number;
  doses: Dose[];
}
interface ExpandedStates {
  [key: string]: boolean;
}
const AIPdfParserResultAutomatic = (props: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const {
    database,
    dbMeds,
    dbInfusions,
    dbElectricals,
    pdfKey,
    file,
    folder,
    startPage,
    endPage,
    aiParsedData,
    parsedProtocolData,
    pattern,
  } = location.state;
  const reducerState = useSelector((state: any) => state.department);

  const [completedResults, setCompletedResults] = useState<any[]>(
    location.state.completedResults || []
  );
  const [results, setResults] = useState<any[]>(location.state.results);
  const [selectedResult, setSelectResult] = useState<any | undefined>();

  const [db, setDatabase] = useState<DatabaseResponse>(database);
  const [meds, setMedications] = useState<any[]>(database.medications);
  const department: DepartmentItem = db.department;

  const [expandedStates, setExpandedStates] = useState<ExpandedStates>({
    Electrical: true,
    Infusion: true,
    Medication: true,
  });
  const [loading, setLoading] = useState<string | null>(null);

  const [final, setFinal] = useState<any>();

  const objURL = useMemo(
    () =>
      selectedResult
        ? URL.createObjectURL(selectedResult.fileUploaded)
        : ((file ? URL.createObjectURL(file) : '') as string),
    [selectedResult?.fileUploaded, file]
  );

  const [modalState, setModalState] = useState<any>({
    isVisible: false,
    title: '',
    description: '',
    primaryBtnName: '',
    secondaryBtnName: '',
    primaryDescription: '',
    secondaryDescription: '',
  });

  useEffect(() => {
    let res = [...location.state.results];
    for (let i = 0; i < res.length; i++) {
      if (res[i].groupByType == null) continue;
      /* Map all the medications */
      let medicationData = res[i].groupByType.Medication;
      for (let j = 0; j < medicationData.length; j++) {
        let doses = medicationData[j].doses;
        for (let k = 0; k < doses.length; k++) {
          let dose = doses[k];
          let medication = findMedicationOrElectrical(dose.name, meds, dbMeds);
          if (medication) {
            dose.medication = medication.med;
            dose.isHmMedication = medication.isHM;
          }
        }
      }
      /* Map all the infusions */
      let infusionData = res[i].groupByType.Infusion;
      for (let j = 0; j < infusionData.length; j++) {
        let doses = infusionData[j].doses;
        for (let k = 0; k < doses.length; k++) {
          let dose = doses[k];
          let medication = findMedicationOrElectrical(
            dose.name,
            database.infusions,
            dbInfusions
          );
          if (medication) {
            dose.medication = medication.med;
            dose.isHmMedication = medication.isHM;
          }
        }
      }

      /* Map all the electrical */
      let electricalData = res[i].groupByType.Electrical;
      for (let j = 0; j < electricalData.length; j++) {
        let doses = electricalData[j].doses;
        for (let k = 0; k < doses.length; k++) {
          let dose = doses[k];
          let medication = findMedicationOrElectrical(
            dose.name,
            database.electrical,
            dbElectricals
          );
          if (medication) {
            dose.medication = medication.med;
            dose.isHmMedication = medication.isHM;
          }
        }
      }

      /* Update the results */
      res[i].groupByType.Medication = medicationData;
      res[i].groupByType.Infusion = infusionData;
      res[i].groupByType.Electrical = electricalData;
    }
    setResults(res);
  }, [location.state.results]);

  const handleBack = () => {
    navigate(`/actions/ai-pdf-parser/auto-protocol-extraction`, {
      state: {
        results: results,
        database: db,
        dbMeds: dbMeds,
        dbInfusions: dbInfusions,
        dbElectricals: dbElectricals,
        pdfKey: pdfKey,
        folder: folder,
        startPage: startPage,
        endPage: endPage,
        file: file,
        aiParsedData: aiParsedData,
        parsedProtocolData: parsedProtocolData,
        pattern: pattern,
        completedResults: completedResults,
      },
    });
  };

  const handleCreateMedications = async (
    hmMedications: MedicationItem[]
  ): Promise<MedicationItem[]> => {
    if (globals.debug)
      console.log('Creating medications', hmMedications.length);
    let newMeds: MedicationItem[] = [];
    /* Create all the medications for now I will just do one */
    let index = 0;
    for (let i = 0; i < hmMedications.length; i++) {
      let medication = hmMedications[i];
      let createdBy = 'OneBot';
      let activeID = null;
      let version = 'v1.0.0';

      const newMedication: MedicationJSON = {
        name: medication.name,
        concentration: medication.concentrations,
        routes: medication.routes,
        rangeLow: !isNaN(Number(medication.rangeLow))
          ? Number(medication.rangeLow)
          : 0,
        rangeHigh: !isNaN(Number(medication.rangeHigh))
          ? Number(medication.rangeHigh)
          : globals.MAX_VALUE,
        departmentID: department.id,
        protocolOptions: [],

        rxNormCode: medication.model.rxNormCode,
        nemsisRoutes: medication.model.nemsisRoutes,
        minDose: medication.model.minDose,
        maxDose: medication.model.maxDose,
        metaData: medication.model.metaData,

        contraindication: medication.model.contraindication,
        note: medication.model.note,
        warning: medication.model.warning,
        medClass: medication.model.medClass,
        action: medication.model.action,
        indication: medication.model.indication,
        interaction: medication.model.interaction,
        onset: medication.model.onset,
        duration: medication.model.duration,
        taggedProtocols: [], //protocolList,

        createdBy: createdBy,
        status: ProgressStatus.DRAFT,
        activeID: activeID,
        version: version,
      };

      if (globals.debug) console.log('Creating New Medication', newMedication);
      let results: Response = await createParentMedication(
        department,
        newMedication,
        medication.departmentID !== department.id ? undefined : medication
      );
      if (results.type === ResponseType.Success) {
        if (globals.debug)
          console.log('Successfully created medication', results.data);
        let newMedication: MedicationItem = results.data;
        newMeds.push(newMedication);
        index++;
      } else {
        console.error('Failed to create medication', results.data);
      }
    }
    if (globals.debug)
      console.log('All Medications Created', index, '/', hmMedications.length);
    return newMeds;
  };

  const handleCreateMedicationDoses = async (
    protocol: ProtocolItem,
    doses: Dose[],
    medications: MedicationItem[]
  ): Promise<MedicationSubItem[]> => {
    if (globals.debug)
      console.log('Creating medications', protocol.name, doses.length);

    let newMeds: MedicationSubItem[] = [];
    /* Create all the medications for now I will just do one */
    let index = protocol.medications.length;
    for (let i = 0; i < doses.length; i++) {
      let dose = doses[i];
      console.log('CREATING DOSE', dose);
      if (dose.medication == null || dose.medication.TAG !== 'MedicationItem') {
        console.log('DOSE NOT OF TYPE MedicationItem', dose);
        continue;
      }

      if (dose.isHmMedication) {
        let med = findMedicationOrElectrical(dose.name, medications, dbMeds);
        if (med) {
          console.log(
            'FOUND MEDICATION',
            med,
            med.departmentID === department.id
          );
          dose.medication = med.med;
        }
      }

      //Check if adminRoutes are of type string then clean it and convert to array
      let routes = dose.administrationRoutes;
      if (typeof routes === 'string') {
        routes = [];
        let newRoutes = dose.administrationRoutes.split(',');
        for (let j = 0; j < newRoutes.length; j++) {
          if (newRoutes[j] != null && newRoutes[j].trim() !== '')
            routes.push(newRoutes[j].trim());
        }
      }
      let medDose: MedicationDoseJSON = {
        departmentID: department.id,
        medication: dose.medication as MedicationItem,
        protocol: protocol,

        index: index,
        basis: dose.dose,
        route: routes,

        rangeLow: dose.weightLow ? Number(dose.weightLow.split(' ')[0]) : 0,
        rangeHigh: dose.weightHigh
          ? Number(dose.weightHigh.split(' ')[0])
          : globals.MAX_VALUE,
        ageLow: dose.ageLow ? Number(dose.ageLow.split(' ')[0]) : 0,
        ageHigh: dose.ageHigh
          ? Number(dose.ageHigh.split(' ')[0])
          : globals.MAX_VALUE,
        // repeatTime: dose.repeatTime ? Number(dose.repeatTime) : '',

        title: dose.title,
        instruction: dose.instruction,
        warning: dose.warning,

        maxDose: dose.maxTotalDose,
        minDose: dose.minSingleDose,
        maxTotalDose: dose.maxTotalDose,

        createdBy: 'OneBot',
        status: ProgressStatus.DRAFT,
        activeID: null,
        version: 'v1.0.0',
        createdAt: new Date(),
      };
      let results: Response = await createMedicationDoseItem(medDose);
      if (results.type === ResponseType.Success) {
        if (globals.debug)
          console.log('Successfully created medication dose', results.data);
        let newMedication: MedicationSubItem = results.data;
        newMeds.push(newMedication);
        index++;
      }
    }

    return newMeds;
  };

  const handleCreateInfusions = async (
    infusions: InfusionItem[]
  ): Promise<InfusionItem[]> => {
    try {
      if (globals.debug) console.log('Creating infusions');
      let newMeds: InfusionItem[] = [];
      /* Create all the medications for now I will just do one */
      let index = 0;
      for (let i = 0; i < infusions.length; i++) {
        let infusion = infusions[i];

        const newInfusion: InfusionJSON = {
          name: infusion.name,
          concentration: infusion.concentrations,
          rangeLow: !isNaN(Number(infusion.rangeLow))
            ? Number(infusion.rangeLow)
            : 0,
          rangeHigh: !isNaN(Number(infusion.rangeHigh))
            ? Number(infusion.rangeHigh)
            : globals.MAX_VALUE,
          departmentID: department.id,
          routes: infusion.routes,
          dripOptions: [],

          nemsisRoutes: infusion.model?.nemsisRoutes,
          rxNormCode: infusion.model?.rxNormCode,
          minDose: infusion.model?.minDose,
          maxDose: infusion.model?.maxDose,
          // minDose: infusion.dbMedication?.minDose,
          // maxDose: infusion.dbInfusion,
          metaData: infusion.model?.metaData,

          contraindication: infusion.model?.contraindication,
          note: infusion.model?.note ?? '',
          warning: infusion.model?.warning ?? '',
          medClass: infusion.model?.medClass,
          action: infusion.model?.action,
          indication: infusion.model?.indication,
          interaction: infusion.model?.interaction,
          onset: infusion.model?.onset,
          duration: infusion.model?.duration,
          taggedProtocols: [], //protocolList,

          createdBy: 'OneBot',
          status: ProgressStatus.DRAFT,
          activeID: null,
          version: 'v1.0.0',
        };

        if (globals.debug) console.log('Creating New Infusion', newInfusion);
        if (globals.debug)
          console.log(
            'Previous Infusion',
            infusion.departmentID !== department.id ? undefined : infusion
          );
        let results: Response = await createParentInfusion(
          department,
          newInfusion,
          infusion.departmentID !== department.id ? undefined : infusion
        );
        if (results.type === ResponseType.Success) {
          if (globals.debug)
            console.log('Successfully created infusion', results.data);
          let newMedication: InfusionItem = results.data;
          newMeds.push(newMedication);
          index++;
        }
      }
      if (globals.debug)
        console.log('All Infusions Created', index, '/', infusions.length);
      return newMeds;
    } catch (error) {
      console.log('INFUSION ERROR', error);
      return [];
    }
  };

  const handleCreateInfusionDoses = async (
    protocol: ProtocolItem,
    doses: Dose[],
    infusions: InfusionItem[]
  ): Promise<InfusionSubItem[]> => {
    if (globals.debug)
      console.log('Creating infusions', protocol.name, doses.length);
    let newMeds: InfusionSubItem[] = [];
    /* Create all the medications for now I will just do one */
    let index = protocol.infusions.length;
    for (let i = 0; i < doses.length; i++) {
      let dose = doses[i];
      if (dose.medication == null || dose.medication.TAG !== 'InfusionItem')
        continue;

      if (dose.isHmMedication) {
        let med = findMedicationOrElectrical(dose.name, infusions, dbInfusions);
        if (med) {
          console.log(
            'FOUND INFUSION',
            med,
            med.departmentID === department.id
          );
          dose.medication = med.med;
        }
      }

      //Check if adminRoutes are of type string then clean it and convert to array
      let routes = dose.administrationRoutes;
      if (typeof routes === 'string') {
        routes = [];
        let newRoutes = dose.administrationRoutes.split(',');
        for (let j = 0; j < newRoutes.length; j++) {
          if (newRoutes[j] != null && newRoutes[j].trim() !== '')
            routes.push(newRoutes[j].trim());
        }
      }
      let medDose: InfusionDoseJSON = {
        departmentID: department.id,
        infusion: dose.medication as InfusionItem,
        protocol: protocol,

        index: index,
        basis: dose.dose,
        route: routes,

        rangeLow: dose.weightLow ? Number(dose.weightLow.split(' ')[0]) : 0,
        rangeHigh: dose.weightHigh
          ? Number(dose.weightHigh.split(' ')[0])
          : globals.MAX_VALUE,
        ageLow: dose.ageLow ? Number(dose.ageLow.split(' ')[0]) : 0,
        ageHigh: dose.ageHigh
          ? Number(dose.ageHigh.split(' ')[0])
          : globals.MAX_VALUE,
        // repeatTime: dose.repeatTime ? Number(dose.repeatTime) : '',

        title: dose.title,
        instruction: dose.instruction,
        warning: dose.warning,

        maxDose: dose.maxTotalDose,
        minDose: dose.minSingleDose,
        maxTotalDose: dose.maxTotalDose,

        createdBy: 'OneBot',
        status: ProgressStatus.DRAFT,
        activeID: null,
        version: 'v1.0.0',
        createdAt: new Date(),
      };
      let results: Response = await createInfusionDoseItem(medDose);
      if (results.type === ResponseType.Success) {
        if (globals.debug)
          console.log('Successfully created infusion dose', results.data);
        let newMedication: InfusionSubItem = results.data;
        newMeds.push(newMedication);
        index++;
      }
    }

    return newMeds;
  };

  const handleCreateElectricals = async (
    groupedElectricals: ElectricalItem[]
  ): Promise<ElectricalItem[]> => {
    try {
      if (globals.debug) console.log('Creating electricals');
      let newElecs: ElectricalItem[] = [];
      /* Create all the medications for now I will just do one */
      let index = 0;
      for (let i = 0; i < groupedElectricals.length; i++) {
        let electrical = groupedElectricals[i];

        const newElectrical: ElectricalJSON = {
          title: electrical.name,
          rangeLow: !isNaN(Number(electrical.rangeLow))
            ? Number(electrical.rangeLow)
            : 0,
          rangeHigh: !isNaN(Number(electrical.rangeHigh))
            ? Number(electrical.rangeHigh)
            : globals.MAX_VALUE,
          departmentID: department.id,
          options: [],
          taggedProtocols: [],

          rxNormCode: electrical.model?.rxNormCode,
          metaData: electrical.model?.metaData,

          instruction: electrical.model?.instruction,
          note: electrical.model?.note ?? '',
          warning: electrical.model?.warning ?? '',

          createdBy: 'OneBot',
          status: ProgressStatus.DRAFT,
          activeID: null,
          version: 'v1.0.0',
        };
        if (globals.debug)
          console.log('Creating New Electrical', newElectrical);
        let results: Response = await createElectrical(newElectrical);
        if (results.type === ResponseType.Success) {
          if (globals.debug)
            console.log('Successfully created electrical', results.data);
          let newMedication: ElectricalItem = results.data;
          newElecs.push(newMedication);
          index++;
        }
      }
      if (globals.debug)
        console.log(
          'All Electricals Created',
          index,
          '/',
          groupedElectricals.length
        );
      return newElecs;
    } catch (error) {
      console.log('ELECTRICAL ERROR', error);
      return [];
    }
  };

  const handleCreateElectricalDoses = async (
    protocol: ProtocolItem,
    doses: Dose[],
    electricals: ElectricalItem[]
  ): Promise<ElectricalSubItem[]> => {
    if (globals.debug)
      console.log('Creating electricals', protocol.name, doses.length);
    let newMeds: ElectricalSubItem[] = [];
    /* Create all the medications for now I will just do one */
    let index = protocol.electrical.length;
    for (let i = 0; i < doses.length; i++) {
      let dose = doses[i];
      if (dose.medication == null || dose.medication.TAG !== 'ElectricalItem') {
        console.log('DOSE NOT OF TYPE ElectricalItem', dose);
        continue;
      }

      if (dose.isHmMedication) {
        let med = findMedicationOrElectrical(
          dose.name,
          electricals,
          dbElectricals
        );
        if (med) {
          console.log(
            'FOUND ELECTRICAL',
            med,
            med.departmentID === department.id
          );
          dose.medication = med.med;
        }
      }
      //Check if adminRoutes are of type string then clean it and convert to array
      let medDose: ElectricalDoseJSON = {
        departmentID: department.id,
        electrical: dose.medication as ElectricalItem,
        protocol: protocol,

        index: index,
        basis: dose.dose,

        rangeLow: dose.weightLow ? Number(dose.weightLow.split(' ')[0]) : 0,
        rangeHigh: dose.weightHigh
          ? Number(dose.weightHigh.split(' ')[0])
          : globals.MAX_VALUE,
        ageLow: dose.ageLow ? Number(dose.ageLow.split(' ')[0]) : 0,
        ageHigh: dose.ageHigh
          ? Number(dose.ageHigh.split(' ')[0])
          : globals.MAX_VALUE,
        // repeatTime: dose.repeatTime ? Number(dose.repeatTime) : '',

        title: dose.title,
        instruction: dose.instruction,
        warning: dose.warning,

        maxDose: dose.maxTotalDose,
        minDose: dose.minSingleDose,
        maxTotalDose: dose.maxTotalDose,

        createdBy: 'OneBot',
        status: ProgressStatus.DRAFT,
        activeID: null,
        version: 'v1.0.0',
        createdAt: new Date(),
      };
      let results: Response = await createElectricalDoseItem(medDose);
      if (results.type === ResponseType.Success) {
        if (globals.debug)
          console.log('Successfully created electrical dose', results.data);
        let newMedication: ElectricalSubItem = results.data;
        newMeds.push(newMedication);
        index++;
      }
    }

    return newMeds;
  };

  const handleUpdateProtocol = async (
    protocol: ProtocolItem,
    medications: string[],
    infusions: string[],
    electricals: string[]
  ): Promise<ProtocolItem> => {
    let p: ProtocolItem = protocol;
    if (globals.debug) console.log('Updating Protocols', p);
    let medIDs: string[] = p.model.medicationIDs
      ? ((protocol as ProtocolItem).model.medicationIDs as string[])
      : [];
    let infIDs: string[] = p.model.dripIDs ? (p.model.dripIDs as string[]) : [];
    let elecIDs: string[] = p.model.electricalIDs
      ? (p.model.electricalIDs as string[])
      : [];
    let newMedIDs = [...new Set([...medIDs, ...medications])];
    let newInfIDs = [...new Set([...infIDs, ...infusions])];
    let newElecIDs = [...new Set([...elecIDs, ...electricals])];

    let json: ProtocolJSON = {
      departmentID: database.department.id,
      name: p.name,
      nickname: p.nickname,
      pdfID: p.pdfUrl,
      rangeLow: p.rangeLow,
      rangeHigh: p.rangeHigh,
      parentCategory: p.parent,
      pairedDepsIDs: p.pairedDepIDs ? p.pairedDepIDs : [],
      pairedProtocols: p.model.pairedProtocols ? p.model.pairedProtocols : [],
      medications: newMedIDs,
      infusions: newInfIDs,
      equipment: p.model.equipmentIDs ? p.model.equipmentIDs : [],
      electrical: newElecIDs,
      forms: p.model.formIDs ? p.model.formIDs : [],
      createdBy: p.model.createdBy ? p.model.createdBy : '',
      status: ProgressStatus.DRAFT,
      activeID: null,
      version: upgradeVersion(p.version),
      pdfVersion: p.pdfVersion,
      index: p.index,
      isPublic: department.isPublic,
      isRestrictive: false,
      keychainID: null,
      color: p.color,
    };

    let resp: Response = await createProtocol(json, protocol);
    if (resp.type === ResponseType.Success) {
      let newProtocol: ProtocolItem = resp.data;
      if (globals.debug) console.log('Updated Protocol', newProtocol);
      return newProtocol;
    } else {
      console.error('Failed to update protocol', resp.data);
      return protocol;
    }
  };

  const handleContinue = async () => {
    try {
      setLoading('Uploading Treatments to Protocols...');
      let allMedicationDoses: MedicationSubItem[] = [];
      let allInfusionDoses: InfusionSubItem[] = [];
      let allElectricalDoses: ElectricalSubItem[] = [];

      let meds: MedicationItem[] = [];
      let infusions: InfusionItem[] = [];
      let electricals: ElectricalItem[] = [];

      /* Fist find all medications that need to be created */
      let hmMeds: MedicationItem[] = [];
      let hmInfusions: InfusionItem[] = [];
      let hmElectricals: ElectricalItem[] = [];

      for (let i = 0; i < results.length; i++) {
        let result = results[i];
        if (result.groupByType == null) continue;
        let allTypeData = result.groupByType;

        /* First Update all the medications */
        let medicationsDoses: Dose[] = allTypeData.Medication?.reduce(
          (acc: Dose[], page: MedicationData) => {
            return [...acc, ...page.doses];
          },
          []
        );

        for (let j = 0; j < medicationsDoses.length; j++) {
          let dose = medicationsDoses[j];
          let med = dose.medication as MedicationItem | undefined;
          if (
            med &&
            dose.isHmMedication &&
            !hmMeds.includes(med as MedicationItem)
          )
            hmMeds.push(med as MedicationItem);
          else if (med && !dose.isHmMedication) {
            meds.push(med as MedicationItem);
          }
        }

        let infusionsDoses: Dose[] = allTypeData.Infusion?.reduce(
          (acc: Dose[], page: MedicationData) => {
            return [...acc, ...page.doses];
          },
          []
        );

        for (let j = 0; j < infusionsDoses.length; j++) {
          let dose = infusionsDoses[j];
          let med = dose.medication as InfusionItem | undefined;
          if (
            med &&
            dose.isHmMedication &&
            !hmInfusions.includes(med as InfusionItem)
          )
            hmInfusions.push(med as InfusionItem);
          else if (med) {
            infusions.push(med as InfusionItem);
          }
        }

        let electricalDoses: Dose[] = allTypeData.Electrical?.reduce(
          (acc: Dose[], page: MedicationData) => {
            return [...acc, ...page.doses];
          },
          []
        );

        for (let j = 0; j < electricalDoses.length; j++) {
          let dose = electricalDoses[j];
          let med = dose.medication as ElectricalItem | undefined;
          if (
            med &&
            dose.isHmMedication &&
            !hmElectricals.includes(med as ElectricalItem)
          )
            hmElectricals.push(med as ElectricalItem);
          else if (med) {
            electricals.push(med as ElectricalItem);
          }
        }
      }

      console.log('HM MEDS', hmMeds);
      console.log('HM INFUSIONS', hmInfusions);
      console.log('HM ELECTRICALS', hmElectricals);

      if (hmMeds.length > 0) {
        let medications = await handleCreateMedications(hmMeds);
        meds = [...meds, ...medications];
      }
      if (hmInfusions.length > 0) {
        let _infusions = await handleCreateInfusions(hmInfusions);
        infusions = [...infusions, ..._infusions];
      }

      if (hmElectricals.length > 0) {
        let _electricals = await handleCreateElectricals(hmElectricals);
        electricals = [...electricals, ..._electricals];
      }

      console.log('Medications', meds);
      console.log('Infusions', infusions);
      console.log('Electricals', electricals);

      for (let i = 0; i < results.length; i++) {
        let result = results[i];
        if (result.groupByType == null) continue;
        let protocol: ProtocolItem = result.newProtocol;
        let allTypeData = result.groupByType;

        /* First Update all the medications */
        let medicationsDoses: Dose[] = allTypeData.Medication?.reduce(
          (acc: Dose[], page: MedicationData) => {
            return [...acc, ...page.doses];
          },
          []
        );

        let infusionsDoses: Dose[] = allTypeData.Infusion?.reduce(
          (acc: Dose[], page: MedicationData) => {
            return [...acc, ...page.doses];
          },
          []
        );

        let electricalsDoses: Dose[] = allTypeData.Electrical?.reduce(
          (acc: Dose[], page: MedicationData) => {
            return [...acc, ...page.doses];
          },
          []
        );

        console.log('Protcol', protocol.name);
        console.log('Medication Doses', medicationsDoses);
        console.log('Infusion Doses', infusionsDoses);
        console.log('Electrical Doses', electricalsDoses);

        let promises: Promise<any>[] = [
          handleCreateMedicationDoses(protocol, medicationsDoses, meds),
          handleCreateInfusionDoses(protocol, infusionsDoses, infusions),
          handleCreateElectricalDoses(protocol, electricalsDoses, electricals),
        ];
        let [medicationDoses, infusionDoses, electricalDoses] =
          await Promise.all(promises);
        allMedicationDoses = [...allMedicationDoses, ...medicationDoses];
        allInfusionDoses = [...allInfusionDoses, ...infusionDoses];
        allElectricalDoses = [...allElectricalDoses, ...electricalDoses];

        // // console.log('Group Medication Doses by Medication');
        // allGroupedMeds = medicationsDoses
        //   ? groupMedicationsDataByParent(
        //       protocol,
        //       allGroupedMeds,
        //       medicationsDoses
        //     )
        //   : [];
        // allGroupedInfus = infusionsDoses
        //   ? groupMedicationsDataByParent(
        //       protocol,
        //       allGroupedInfus,
        //       infusionsDoses
        //     )
        //   : [];
        // allGroupedElectricals = electricalDoses
        //   ? groupElectricalDataByParent(
        //       protocol,
        //       allGroupedElectricals,
        //       electricalDoses
        //     )
        //   : [];
      }
      // let medications = await handleCreateMedications(allGroupedMeds);
      // let infusions = await handleCreateInfusions(allGroupedInfus);
      // console.log('ALL GROUOPDED Electricals', allGroupedElectricals);
      // let electricals = await handleCreateElectricals(allGroupedElectricals);

      for (let i = 0; i < results.length; i++) {
        let result = results[i];
        let protocol: ProtocolItem = result.newProtocol;
        let protocolMedications = [
          ...new Set(
            allMedicationDoses
              .filter((item) => item.parentProtocol.uid === protocol.uid)
              .map((item) =>
                item.parent.status === ProgressStatus.DRAFT && item.activeID
                  ? item.activeID
                  : item.uid
              )
          ),
        ];
        let protocolInfusions = [
          ...new Set(
            allInfusionDoses
              .filter((item) => item.parentProtocol.uid === protocol.uid)
              .map((item) =>
                item.parent.status === ProgressStatus.DRAFT && item.activeID
                  ? item.activeID
                  : item.uid
              )
          ),
        ];
        let protocolElectricals = [
          ...new Set(
            allElectricalDoses
              .filter((item) => item.parentProtocol.uid === protocol.uid)
              .map((item) =>
                item.parent.status === ProgressStatus.DRAFT && item.activeID
                  ? item.activeID
                  : item.uid
              )
          ),
        ];

        let newProtocol = await handleUpdateProtocol(
          protocol,
          protocolMedications,
          protocolInfusions,
          protocolElectricals
        );
        console.log('Successfully Updated Protocol', newProtocol.name);
      }

      /* Now reload the database */
      let success = await reloadDatabase();
      if (success) {
        setCompletedResults([...completedResults, ...results]);
        setModalState({
          isVisible: true,
          title: 'Successfully Updated ' + folder.name + ' Protocols',
          description:
            'Successfully updated the folder ' +
            folder.name +
            ' with ' +
            results.length +
            ' protocols. There was ' +
            allMedicationDoses.length +
            ' medications, ' +
            allInfusionDoses.length +
            ' infusions, and ' +
            allElectricalDoses.length +
            ' electricals added to the protocols.',
          primaryBtnName: 'Okay',
          secondaryBtnName: 'Okay',
          primaryDescription: '',
          secondaryDescription: '',
        });
      } else {
        console.error('ERROR RELOADING DATABASE');
      }

      setLoading(null);
    } catch (error) {
      console.error('Error Uploading Treatments to Protocols', error);
      setLoading(null);
    }

    /* Now create all the medications & infusions */

    // for (let i = 0; i < allTypeData.Medication.length; i++) {
  };

  const groupMedicationsDataByParent = (
    protocol: ProtocolItem,
    curGroupedMeds: any[],
    doses: any[]
  ) => {
    let groupedMeds = [...curGroupedMeds];
    // let groupedMeds: any[] = [];
    for (let i = 0; i < doses.length; i++) {
      let dose = doses[i];
      if (groupedMeds.length === 0) {
        groupedMeds.push({
          medication: dose.medication,
          protocolOptions: [
            {
              protocolID: protocol.activeID ? protocol.activeID : protocol.uid,
              options: [create_medication_range(dose)],
            },
          ] as MedicationProtocol[],
        });
      } else {
        let foundMedication = groupedMeds.find(
          (item) => item.medication.uid === dose.medication.uid
        );
        if (!foundMedication) {
          groupedMeds.push({
            medication: dose.medication,
            protocolOptions: [
              {
                protocolID: protocol.activeID
                  ? protocol.activeID
                  : protocol.uid,
                options: [create_medication_range(dose)],
              },
            ] as MedicationProtocol[],
          });
        } else {
          /* Parse out the found medication */
          groupedMeds = groupedMeds.filter(
            (item) => item.medication.uid !== dose.medication.uid
          );

          let protOptions = [...foundMedication.protocolOptions];
          let index = protOptions.findIndex(
            (item) =>
              item.protocolID ===
              (protocol.activeID ? protocol.activeID : protocol.uid)
          );
          if (index === -1) {
            protOptions.push({
              protocolID: protocol.activeID ? protocol.activeID : protocol.uid,
              options: [create_medication_range(dose)],
            });
          } else {
            protOptions[index].options.push(create_medication_range(dose));
          }

          groupedMeds.push({
            medication: dose.medication,
            protocolOptions: protOptions,
          });
        }
      }
    }

    return groupedMeds;
  };

  const groupElectricalDataByParent = (
    protocol: ProtocolItem,
    curGroupedElecs: any[],
    doses: any[]
  ) => {
    let groupedMeds = [...curGroupedElecs];
    // let groupedMeds: any[] = [];
    for (let i = 0; i < doses.length; i++) {
      let dose = doses[i];
      if (groupedMeds.length === 0) {
        groupedMeds.push({
          medication: dose.medication,
          protocolOptions: [
            {
              protocolID: protocol.activeID ? protocol.activeID : protocol.uid,
              ranges: [create_electrical_range(dose)],
            },
          ] as ElectricalShockOption[],
        });
      } else {
        let foundMedication = groupedMeds.find(
          (item) => item.medication.uid === dose.medication.uid
        );
        if (!foundMedication) {
          groupedMeds.push({
            medication: dose.medication,
            protocolOptions: [
              {
                protocolID: protocol.activeID
                  ? protocol.activeID
                  : protocol.uid,
                ranges: [create_electrical_range(dose)],
              },
            ] as ElectricalShockOption[],
          });
        } else {
          /* Parse out the found medication */
          groupedMeds = groupedMeds.filter(
            (item) => item.medication.uid !== dose.medication.uid
          );

          let protOptions = [...foundMedication.protocolOptions];
          let index = protOptions.findIndex(
            (item) =>
              item.protocolID ===
              (protocol.activeID ? protocol.activeID : protocol.uid)
          );
          if (index === -1) {
            protOptions.push({
              protocolID: protocol.activeID ? protocol.activeID : protocol.uid,
              ranges: [create_electrical_range(dose)],
            });
          } else {
            protOptions[index].ranges.push(create_electrical_range(dose));
          }

          groupedMeds.push({
            medication: dose.medication,
            protocolOptions: protOptions,
          });
        }
      }
    }

    return groupedMeds;
  };

  const create_medication_range = (dose: any, index?: number) => {
    console.log('Creating Medication Range', dose);
    /* Convert the administration routes to an array */
    if (typeof dose.administrationRoutes === 'string')
      dose.administrationRoutes = dose.administrationRoutes.split(',');

    let range = new MedicationRange({
      basis: dose.dose,
      rangeLow: dose.weightLow
        ? Number(dose.weightLow.split(' ')[0])
        : dose.rangeLow
          ? Number(dose.rangeLow.split(' ')[0])
          : 0,
      rangeHigh: dose.weightHigh
        ? Number(dose.weightHigh.split(' ')[0])
        : dose.rangeHigh
          ? Number(dose.rangeHigh.split(' ')[0])
          : globals.MAX_VALUE,
      route: dose.administrationRoutes,
      title: dose.title,
      warning: dose.warning,
      instruction: dose.instruction,
      note: dose.note,
      maxDose: dose.maxSingleDose,
      minDose: dose.minSingleDose,
      calcMax: dose.calcMax,
      index: index ? index : 0,
      maxTotalDose: dose.maxTotalDose,
      repeatTime: dose.repeatTime,
      ageHigh: cleanAge(dose.ageHigh),
      ageLow: cleanAge(dose.ageLow),
    });
    return range;
  };

  const create_electrical_range = (dose: any, index?: number) => {
    console.log('Creating Electrical Range', dose);
    /* Convert the administration routes to an array */
    if (typeof dose.administrationRoutes === 'string')
      dose.administrationRoutes = dose.administrationRoutes.split(',');

    let range = new ElectricalShockRange({
      basis: dose.dose,
      rangeLow: dose.weightLow
        ? Number(dose.weightLow.split(' ')[0])
        : dose.rangeLow
          ? Number(dose.rangeLow.split(' ')[0])
          : 0,
      rangeHigh: dose.weightHigh
        ? Number(dose.weightHigh.split(' ')[0])
        : dose.rangeHigh
          ? Number(dose.rangeHigh.split(' ')[0])
          : globals.MAX_VALUE,
      title: dose.title,
      warning: dose.warning,
      instruction: dose.instruction,
      note: dose.note,
      fixedMax: dose.maxSingleDose,
      // minDose: dose.minSingleDose,
      calcMax: dose.calcMax,
      index: index ? index : 0,
      // maxTotalDose: dose.maxTotalDose,
      // repeatTime: dose.repeatTime,
      ageHigh: cleanAge(dose.ageHigh),
      ageLow: cleanAge(dose.ageLow),
    });
    return range;
  };

  const cleanAge = (age: string) => {
    if (age == null) return null;
    //Test if it is a number
    if (!isNaN(Number(age))) {
      return Number(age);
    }
    return null;
  };

  const reloadDatabase = async (): Promise<boolean> => {
    /* 1-10-24 Hazlett:  Update the current data to the database change and keep the current state */
    const resp: Response = await loadDatabase(db, undefined, {
      wait: true,
      waitForUsers: true,
    });
    if (resp.type === ResponseType.Success) {
      const newDB: DatabaseResponse = resp.data;
      setDatabase(newDB);
      dispatch<any>(handleGetDepartment(newDB));
      return true;
    } else {
      console.error('ERROR LOADING DATABASE', resp.data);
      return false;
    }
  };

  const preprocessText = (text: string) => {
    return text
      .toLowerCase()
      .trim()
      .replace(/[.\s()+\-\/\\_]/g, ''); // Modified regex to capture other characters you mentioned
  };

  function create_process_list(
    medList: (MedicationItem | InfusionItem | ElectricalItem)[],
    isHM: boolean
  ): any[] {
    let processList = [];
    // if (isHM) {
    for (let i = 0; i < medList?.length; i++) {
      let med = medList[i];
      let fullName = med.model?.metaData?.fullName ?? '';
      let nickName = med.model?.metaData?.nickName ?? '';
      let brandNames = med.model?.metaData?.brandNames ?? [];
      processList.push({
        name: preprocessText(med.name),
        med: med,
        isHM: isHM,
      });
      processList.push({
        name: preprocessText(fullName),
        med: med,
        isHM: isHM,
      });
      processList.push({
        name: preprocessText(nickName),
        med: med,
        isHM: isHM,
      });
      for (let j = 0; j < brandNames.length; j++) {
        processList.push({
          name: preprocessText(brandNames[j]),
          med: med,
          isHM: isHM,
        });
      }
    }
    return processList;
  }

  function findMedicationOrElectrical(
    name: string,
    meds: (MedicationItem | InfusionItem | ElectricalItem)[],
    hmMeds: (MedicationItem | InfusionItem | ElectricalItem)[]
  ): any | undefined {
    const processedList = create_process_list(meds, false);

    // Options for Fuse.js
    const options = {
      includeScore: true, // Include the score in the result set
      findAllMatches: false, // Find all matches, not just the best match
      threshold: 0.2, // Threshold for matching. A lower number means more exact matches.
      keys: ['name'], // Fields to search in each object
    };
    const fuse = new Fuse(processedList, options);
    //   const splitName = name.split(' ');
    //   for (let i = 0; i < splitName.length; i++) {
    let search = name
      .toLowerCase()
      .trim()
      .replace(/()-\/\\_/g, '');
    const result = fuse.search(search);
    for (let i = 0; i < result.length; i++) {
      if (result[i].score != null && (result[i].score as number) < 0.2)
        return {
          med: result[i].item.med,
          isHM: result[i].item.isHM,
        };
    }

    const hmProcessedList = create_process_list(hmMeds, true);
    const fuseHM = new Fuse(hmProcessedList, options);
    const resultHM = fuseHM.search(search);
    for (let i = 0; i < resultHM.length; i++) {
      if (resultHM[i].score != null && (resultHM[i].score as number) < 0.2)
        return {
          med: resultHM[i].item.med,
          isHM: resultHM[i].item.isHM,
        };
    }
    return undefined;
  }

  const toggleExpanded = (key: string) => {
    setExpandedStates((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  const ProtocolItemUI = ({ name, length, errorCount }: any) => {
    const isExpanded = expandedStates[name];
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          padding: '10px',
          marginTop: '.25rem',
          marginBottom: '.25rem',
        }}
      >
        <div
          className={`border hover-raise-elevation ${
            isExpanded ? 'expanded' : ''
          }`}
          style={{
            display: 'flex',
            width: '100%',
            padding: '10px',
          }}
          onClick={() => {
            toggleExpanded(name);
          }}
        >
          {isExpanded ? (
            <FaChevronDown size={14} color={'#a0a0a0'} />
          ) : (
            <FaChevronRight size={14} color={'#a0a0a0'} />
          )}
          <div
            style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <span
              className="ketamine-general-label"
              style={{ flex: 1, fontWeight: '400' }}
            >
              {name}
            </span>
          </div>

          {errorCount > 0 && (
            <span
              className="ketamine-general-label"
              style={{ marginRight: '10px', color: 'red' }}
            >
              Errors: {errorCount}
            </span>
          )}

          <span
            className="ketamine-general-label"
            style={{ marginRight: '10px' }}
          >
            {name === 'Electrical' ? 'Shocks: ' + length : 'Doses: ' + length}
          </span>
        </div>
      </div>
    );
  };

  const RenderDoses = (
    medicationData: MedicationData[],
    errorCount: number,
    key: string
  ) => {
    // const [data, setData] = useState<MedicationData[]>(medicationData);
    // const [errorCount, setErrorCount] = useState(
    //   calculateErrorCount(key, medicationData)
    // );

    // useEffect(() => {
    //   setData(medicationData);
    //   setErrorCount(calculateErrorCount(key, medicationData));
    // }, [medicationData, key]);

    if (medicationData.length === 0) return null;
    return (
      medicationData && (
        <div key={key}>
          <ProtocolItemUI
            name={key}
            length={medicationData.length}
            errorCount={errorCount}
            onClick={() => toggleExpanded(key)}
            expanded={expandedStates[key]}
          />
          {expandedStates[key] && key === 'Electrical' && (
            <p
              style={{
                color: 'red',
                fontSize: '14px',
                padding: 0,
                margin: 0,
              }}
            >
              NOTE: Title is a required field add the title of the shock for
              example: 'First Shock', 'Second Shock', 'Subsequent Shocks', 'V
              Fib', 'V Tach' or 'Asystole'
            </p>
          )}
          {expandedStates[key] &&
            medicationData.map((page, pageIndex) => (
              <div key={pageIndex}>
                <ViewportList items={page.doses}>
                  {(item: Dose, index: number) => {
                    item = item.confirmedData
                      ? { ...item.confirmedData }
                      : { ...item };
                    if (typeof item.administrationRoutes === 'string') {
                      item.administrationRoutes =
                        item.administrationRoutes.split(',');
                    }

                    const medicationDetails = findMedicationOrElectrical(
                      item.name == null ? '' : item.name,
                      key === 'Medication'
                        ? db.medications
                        : key === 'Infusion'
                          ? db.infusions
                          : db.electrical,
                      key === 'Medication'
                        ? dbMeds
                        : key === 'Infusion'
                          ? dbInfusions
                          : dbElectricals
                    );

                    console.log('Medication Details', medicationDetails);
                    console.log('Electricals', database.electrical);

                    return (
                      <div key={index} style={{ marginBottom: '10px' }}>
                        {key === 'Electrical' ? (
                          <AIPdfParserElectricalInput
                            dose={item}
                            db={database}
                            dbMeds={dbElectricals}
                            medication={
                              item.medication != null
                                ? item.medication
                                : medicationDetails?.med
                            }
                            isHMMedication={medicationDetails?.isHM}
                            index={index}
                            page_number={
                              page.index - Number(selectedResult.startPage)
                            }
                            onChange={(
                              data: any,
                              index: number,
                              med: ElectricalItem | null
                            ) => {
                              let result = { ...selectedResult };

                              let newMedicationData = [...medicationData];
                              let newData = {
                                ...data,
                                medication: med,
                                isHmMedication: med
                                  ? med.departmentID !== database.department.id
                                  : false,
                              };
                              newMedicationData[pageIndex].doses[index] =
                                newData;

                              result.groupByType[key] = newMedicationData;
                              setSelectResult(result);

                              /* Update the results array with the new data */
                              let newResults = [...results];
                              let findIndex = newResults.findIndex(
                                (item) => item === selectedResult
                              );
                              newResults[findIndex] = result;
                              setResults(newResults);
                            }}
                            onDelete={(index: number) => {
                              let result = { ...selectedResult };

                              let newMedicationData = [...medicationData];
                              newMedicationData[pageIndex].doses.splice(
                                index,
                                1
                              );
                              result.groupByType[key] = newMedicationData;
                              setSelectResult(result);

                              /* Update the results array with the new data */
                              let newResults = [...results];
                              let findIndex = newResults.findIndex(
                                (item) => item === selectedResult
                              );
                              newResults[findIndex] = result;
                              setResults(newResults);
                            }}
                            onCopy={(index) => {
                              let result = { ...selectedResult };

                              let newMedicationData = [...medicationData];
                              let copyData =
                                newMedicationData[pageIndex].doses[index];
                              let newData = {
                                ...copyData,
                                title: copyData.title,
                              };
                              newMedicationData[pageIndex].doses.splice(
                                index + 1,
                                0,
                                newData
                              );
                              result.groupByType[key] = newMedicationData;
                              setSelectResult(result);

                              /* Update the results array with the new data */
                              let newResults = [...results];
                              let findIndex = newResults.findIndex(
                                (item) => item === selectedResult
                              );
                              newResults[findIndex] = result;
                              setResults(newResults);
                            }}
                          />
                        ) : (
                          <AIPdfParserMedicationInput
                            dose={item}
                            db={database}
                            dbMeds={key === 'Medication' ? dbMeds : dbInfusions}
                            medication={
                              item.medication != null
                                ? item.medication
                                : medicationDetails?.med
                            }
                            isHMMedication={medicationDetails?.isHM}
                            index={index}
                            page_number={
                              page.index - Number(selectedResult.startPage)
                            }
                            onChange={(
                              data: any,
                              index: number,
                              med: MedicationItem | InfusionItem | null
                            ) => {
                              let result = { ...selectedResult };

                              let newMedicationData = [...medicationData];
                              let newData = {
                                ...data,
                                medication: med,
                                isHmMedication: med
                                  ? med.departmentID !== database.department.id
                                  : false,
                              };
                              newMedicationData[pageIndex].doses[index] =
                                newData;

                              result.groupByType[key] = newMedicationData;
                              setSelectResult(result);

                              /* Update the results array with the new data */
                              let newResults = [...results];
                              let findIndex = newResults.findIndex(
                                (item) => item === selectedResult
                              );
                              newResults[findIndex] = result;
                              setResults(newResults);
                            }}
                            onDelete={(index: number) => {
                              let result = { ...selectedResult };

                              let newMedicationData = [...medicationData];
                              newMedicationData[pageIndex].doses.splice(
                                index,
                                1
                              );
                              result.groupByType[key] = newMedicationData;
                              setSelectResult(result);

                              /* Update the results array with the new data */
                              let newResults = [...results];
                              let findIndex = newResults.findIndex(
                                (item) => item === selectedResult
                              );
                              newResults[findIndex] = result;
                              setResults(newResults);
                            }}
                            onCopy={(index) => {
                              let result = { ...selectedResult };

                              let newMedicationData = [...medicationData];
                              let copyData =
                                newMedicationData[pageIndex].doses[index];
                              let newData = {
                                ...copyData,
                                title: copyData.title,
                              };
                              newMedicationData[pageIndex].doses.splice(
                                index + 1,
                                0,
                                newData
                              );
                              result.groupByType[key] = newMedicationData;
                              setSelectResult(result);

                              /* Update the results array with the new data */
                              let newResults = [...results];
                              let findIndex = newResults.findIndex(
                                (item) => item === selectedResult
                              );
                              newResults[findIndex] = result;
                              setResults(newResults);
                            }}
                          />
                        )}
                      </div>
                    );
                  }}
                </ViewportList>
              </div>
            ))}
        </div>
      )
    );
  };

  function calculateErrorCount(key: string, medicationData: MedicationData[]) {
    if (medicationData == null) return 0;
    let count = 0;
    for (let i = 0; i < medicationData?.length; i++) {
      for (let j = 0; j < medicationData[i].doses.length; j++) {
        if (key === 'Electrical') {
          let dose =
            medicationData[i].doses[j].confirmedData ||
            medicationData[i].doses[j];

          if (dose.dose === '' || dose.dose === null) count++;
          if (dose.title === '' || dose.title === null) count++;
        } else if (key === 'Infusion' || key === 'Medication') {
          let dose =
            medicationData[i].doses[j].confirmedData ||
            medicationData[i].doses[j];

          if (dose.dose === '' || dose.dose === null) count++;
          if (
            dose.administrationRoutes == null ||
            dose.administrationRoutes === '' ||
            dose.administrationRoutes?.length === 0
          )
            count++;
          if (dose.medication == null) count++;
        }
      }
    }
    return count;
  }

  const isContinueValid = useMemo(() => {
    let errorCount = 0;
    for (let i = 0; i < results.length; i++) {
      if (results[i].groupByType == null) continue;
      let keys = Object.keys(results[i].groupByType);
      for (let j = 0; j < keys.length; j++) {
        let key = keys[j];
        errorCount += calculateErrorCount(key, results[i].groupByType[key]);
      }
    }
    return errorCount === 0;
  }, [results]);

  return (
    <div className="screen-container">
      {loading !== null && <Loading type="bubbles" message={loading} />}
      <ConfirmModal
        isVisible={modalState.isVisible}
        title={modalState.title}
        handleClose={() => {
          setModalState({ ...modalState, isVisible: false });
        }}
        handleSubmit={() => {
          setModalState({ ...modalState, isVisible: false });
          navigate(`/actions/ai-pdf-parser/auto-protocol-extraction`, {
            state: {
              results: results,
              database: db,
              dbMeds: dbMeds,
              dbInfusions: dbInfusions,
              dbElectricals: dbElectricals,
              pdfKey: pdfKey,
              folder: null,
              startPage: startPage,
              endPage: endPage,
              file: file,
              aiParsedData: aiParsedData,
              parsedProtocolData: parsedProtocolData,
              pattern: pattern,
              completedResults: completedResults,
            },
          });
        }}
        isDeleteBtn={false}
        isSingleBtn={modalState.primaryBtnName === 'Okay' ? true : false}
        primaryBtnName={modalState.primaryBtnName}
        secondaryBtnName={modalState.secondaryBtnName}
        primaryDescription={modalState.description}
        secondaryDescription={modalState.secondaryDescription}
      />
      {/* <div className="fixedHeader fixedHeaderPad" data-testid="protocol-header">
        <ProtocolHeader
          page={'AI PDF Parser'}
          name={'Review Protocols and Extarcted Treatments'}
          description={
            'Successfully created ' +
            results.length +
            ' protocols in ' +
            folder.name +
            '. Review all of the extracted treatments and make any necessary changes.'
          }
          isBackButton={true}
          rightSideBtn={'edit'}
          isEditButton={false}
          isReviewButton={false}
          isReviewActive={false}
          isCustomButton={true}
          isCustomActive={isContinueValid}
          customText="Continue"
          isCancelButton={true}
          handleCustom={handleContinue}
          type={'protocol'}
          handleCancel={() => {
            if (selectedResult) setSelectResult(undefined);
            else handleBack();
          }}
          handleCancelEdit={handleBack}
        />
      </div> */}

      <div
        className="content-container"
        style={
          {
            // maxHeight: 'calc(100vh - 250px)',
            // overflowY: 'auto',
          }
        }
      >
        <div
          style={{
            display: 'flex',
            flex: 1,
            flexDirection: 'row',
          }}
        >
          <div
            className="left-panel"
            style={
              {
                // maxHeight: 'calc(100vh - 250px)',
                // overflowY: 'auto',
              }
            }
          >
            <div
              className="fixedHeader fixedHeaderPad"
              data-testid="protocol-header"
            >
              <div
                style={{
                  margin: '0px 10px',
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: '10px 0px',
                    flex: 1,
                    overflowX: 'auto',
                  }}
                >
                  <div className={`titleStyling`}>
                    <div
                      className=""
                      style={{ alignItems: 'center', justifyContent: 'center' }}
                    >
                      <h6
                        className={`backTextLight`}
                        onClick={() => {
                          //handleBackButton();
                        }}
                      >
                        <span>
                          <IoArrowBack
                            data-testid="isBackBttn"
                            size="1.15rem"
                            onClick={handleBack}
                            style={{ marginRight: '5px' }}
                          />
                        </span>
                        AI PDF Parser
                      </h6>
                    </div>

                    <div className="titleItemContainer">
                      <div className="titleTextPH">
                        Review Protocols and Extarcted Treatments
                      </div>
                    </div>

                    <h6
                      className={`headerTextLight`}
                      style={{
                        fontWeight: '400',
                        color: '#616161',
                        marginRight: '2rem',
                      }}
                    >
                      {'Successfully created ' +
                        results.length +
                        ' protocols in ' +
                        folder.name +
                        '. Review all of the extracted treatments and make any necessary changes.'}
                    </h6>
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    paddingRight: 0,
                  }}
                >
                  <div className="headerButtonContainer">
                    <Button
                      data-testid="isCancelButton"
                      className="secondary-button-small-border btn-rightMargin"
                      onClick={() => {
                        handleBack();
                      }}
                    >
                      <span>
                        <AiOutlineClose className="icon-normal" size="1rem" />
                      </span>{' '}
                      Cancel
                    </Button>
                    <Button
                      data-testid="isSaveButton"
                      className={` ${
                        // rightSideBtn === "save"
                        'primary-button btn-rightMargin'
                        // : "secondary-button-small-border btn-rightMargin"
                      }`}
                      onClick={() => {
                        handleContinue();
                      }}
                      disabled={!isContinueValid}
                    >
                      Continue
                      <span>
                        <AiOutlineRight className="icon-normal" />
                      </span>
                    </Button>
                  </div>
                </div>
              </div>
              <hr />
            </div>

            {selectedResult ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  padding: '0px 15px',
                  flex: 1,
                }}
              >
                <div
                  className=""
                  style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <h6
                    className={`backTextLight hoverText`}
                    onClick={() => {
                      setSelectResult(undefined);
                    }}
                  >
                    <span>
                      <IoArrowBack
                        data-testid="isBackBttn"
                        size="1.15rem"
                        style={{ marginRight: '5px' }}
                      />
                    </span>
                    {folder.name} Protocols
                  </h6>
                </div>

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <label
                    htmlFor="protocolName"
                    className="ketamine-general-label"
                    style={{
                      fontSize: '1.5rem',
                      flex: 1,
                    }}
                  >
                    {selectedResult.newProtocol.name} Treatments
                  </label>
                  <Button
                    className={` ${
                      // rightSideBtn === "save"
                      'primary-button btn-rightMargin'
                      // : "secondary-button-small-border btn-rightMargin"
                    }`}
                    style={{
                      marginTop: '4px',
                    }}
                    onClick={() => {
                      //Create a new medication
                      let result = { ...selectedResult };
                      let newMedicationData = [
                        ...result.groupByType['Medication'],
                      ];
                      newMedicationData.push({
                        medication: null,
                        doses: [],
                      });
                      result.groupByType['Medication'] = newMedicationData;
                      setSelectResult(result);

                      /* Update the results array with the new data */
                      let newResults = [...results];
                      let findIndex = newResults.findIndex(
                        (item) => item === selectedResult
                      );
                      newResults[findIndex] = result;
                      setResults(newResults);
                    }}
                  >
                    <span>
                      <FaPlus size="0.8rem" />
                    </span>{' '}
                    Add
                  </Button>
                </div>
                <span
                  className="contentText greyText"
                  style={{ fontWeight: '500', marginLeft: '10px' }}
                >
                  Review the extracted treatments and make any necessary changes
                </span>
                {selectedResult.groupByType &&
                  Object.entries(selectedResult.groupByType).map(
                    ([key, data]) =>
                      RenderDoses(
                        data as MedicationData[],
                        calculateErrorCount(key, data as MedicationData[]),
                        key
                      )
                  )}
                {selectedResult.groupByType == null && (
                  <div
                    style={{
                      flex: 1,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginTop: '5rem',
                    }}
                  >
                    <span
                      className="contentText greyText"
                      style={{ fontWeight: '500', marginLeft: '10px' }}
                    >
                      No treatments were extracted from this protocol...
                    </span>
                  </div>
                )}
              </div>
            ) : (
              <>
                <label
                  htmlFor="protocolName"
                  className="ketamine-general-label"
                  style={{
                    fontSize: '1.5rem',
                  }}
                >
                  Folder "{folder.name}" {results.length} Protocols
                </label>
                <span
                  className="contentText greyText"
                  style={{ fontWeight: '500', marginLeft: '10px' }}
                >
                  Select a protocol to view the extracted treatments
                </span>
                <div
                  style={{
                    // overflowY: 'auto',
                    // maxHeight: 'calc(100vh - 200px)',
                    border: '1px solid #e0e0e0',
                    borderRadius: '5px',
                  }}
                >
                  {results.map((item, index) => {
                    let errorCount = 0;
                    if (item.groupByType) {
                      errorCount =
                        calculateErrorCount(
                          'Medication',
                          item.groupByType.Medication
                        ) +
                        calculateErrorCount(
                          'Infusion',
                          item.groupByType.Infusion
                        ) +
                        calculateErrorCount(
                          'Electrical',
                          item.groupByType.Electrical
                        );
                    }
                    return (
                      <div
                        onClick={() => {
                          // if (
                          //   item.groupByType &&
                          //   (item.groupByType.Medication.length > 0 ||
                          //     item.groupByType.Infusion.length > 0 ||
                          //     item.groupByType.Electrical.length > 0)
                          // )
                          setSelectResult(item);
                        }}
                        className={`hover-background ${index % 2 === 0 ? 'even' : 'odd'}`}
                        style={{}}
                      >
                        <label
                          className=""
                          style={{
                            flex: 1,
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            margin: 0,
                            padding: 0,
                            fontSize: '1rem',
                            fontWeight: '500',
                            cursor: 'pointer',
                          }}
                        >
                          {item.newProtocol.name}
                        </label>
                        {errorCount > 0 && (
                          <label
                            className=""
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                              margin: 0,
                              padding: 0,
                              fontWeight: '600',
                              cursor: 'pointer',
                              fontSize: '1rem',
                              color: 'red',
                              marginRight: '1rem',
                            }}
                          >
                            {errorCount} Errors
                          </label>
                        )}
                        <label
                          className=""
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            margin: 0,
                            padding: 0,
                            fontSize: '1rem',
                            fontWeight: '500',
                            cursor: 'pointer',
                          }}
                        >
                          {item.groupByType
                            ? item.groupByType.Medication.length +
                              item.groupByType.Infusion.length +
                              item.groupByType.Electrical.length
                            : 0}{' '}
                          Treatments
                        </label>
                        <FaChevronRight
                          style={{
                            marginLeft: '1rem',
                            cursor: 'pointer',
                            padding: '0px',
                            height: '1rem',
                            width: '1rem',
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              </>
            )}
          </div>

          <div
            className="right-panel fixedHeader"
            style={{
              maxHeight: 'calc(100vh - 90px)',
              top: '84px',
            }}
          >
            {objURL ? (
              <embed
                title="PDF Document"
                type="application/pdf"
                src={objURL}
                style={{
                  height: '100%',
                  width: '100%',
                }}
              />
            ) : (
              <div>Loading...</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AIPdfParserResultAutomatic;
