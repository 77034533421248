import ModelItem from './ModelItem';
import { CalculatorType, FormQuestion } from '../../models';

export interface Calculator {
  id: string;
  title: string;
  description: string;
  questions: EMSQuestion[];
  modifiedDate: Date;
}

export interface EMSQuestion extends FormQuestion {
  unit?: string;
  placeholder?: string;
  nested_questions?: {};
  optional?: boolean;
  config?: any;
}

class EMSCalculatorItem extends ModelItem<Calculator> {
  TAG: string = 'EMSCalculatorItem';
  title: string;
  description: string;
  questions: EMSQuestion[];
  dbCalculator: Calculator;
  modifiedDate: Date;
  type: CalculatorType;

  constructor(calculator: CalculatorType) {
    const dbCalculator = createCalculator(calculator);
    super(dbCalculator, getTitle(calculator), '');
    this.TAG = 'EMSCalculatorItem';
    this.title = getTitle(calculator);
    this.description = getDescription(calculator);
    this.questions = getQuestions(calculator);
    this.modifiedDate = getModifiedDate(calculator);
    this.dbCalculator = dbCalculator;
    this.type = calculator;
  }

  public getDescription(): string {
    return this.title;
  }

  public getCalculator(): Calculator {
    return this.dbCalculator;
  }

  public getQuestions(): EMSQuestion[] {
    return this.questions;
  }

  public getTitle(): string {
    return this.title;
  }

  public getDepartmentID(): string {
    return this.departmentID;
  }
}

export default EMSCalculatorItem;

export const createCalculator = (calculator: CalculatorType): Calculator => {
  return {
    id: calculator,
    title: getTitle(calculator),
    description: getDescription(calculator),
    questions: getQuestions(calculator),
    modifiedDate: getModifiedDate(calculator),
  };
};

const getTitle = (calculator: CalculatorType): string => {
  switch (calculator) {
    case CalculatorType.PARKLAND:
      return 'Parkland Formula for Burns';
    case CalculatorType.RACE:
      return 'RACE Scale for Stroke';
    case CalculatorType.RASS:
      return 'Richmond Agitation-Sedation Scale (RASS)';
    case CalculatorType.APGAR_CALC:
      return 'APGAR Score';
    case CalculatorType.CPSSS_CALC:
      return 'Cincinnati Prehospital Stroke Severity Scale (CP-SSS)';
    case CalculatorType.ETT_DEPTH_TIDAL_VOL_CALC:
      return 'Endotracheal Tube Depth and Tidal Volume';
    case CalculatorType.GCS_CALC:
      return 'Glasgow Coma Scale (GCS)';
    case CalculatorType.IBW_ABW_CALC:
      return 'Ideal Body Weight (IBW) and Adjusted Body Weight (ABW)';
    case CalculatorType.PETT_CALC:
      return 'Pediatric Endotracheal Tube Size (PETT)';
    case CalculatorType.PGCS_CALC:
      return 'Pediatric Glasgow Coma Scale (PGCS)';
    case CalculatorType.LAMS_CALC:
      return 'LAMS Score for Stroke';
    case CalculatorType.MNIHSS_CALC:
      return 'mNIHSS Score';
    case CalculatorType.FASTED_CALC:
      return 'FASTED Score for Stroke';
    case CalculatorType.OXYGEN_TANK_MINUTES_CALC:
      return 'Oxygen Cylinder Duration Calculator';

    default:
      return 'EMS Calculator';
  }
};

const getDescription = (calculator: CalculatorType): string => {
  switch (calculator) {
    case CalculatorType.PARKLAND:
      return 'Calculates fluid requirements for burn patients in a 24-hour period.';
    case CalculatorType.RACE:
      return 'Rapid Arterial Occlusion Evaluation(RACE) Scale for Stroke Calculator predicts large vessel occlusion (LVO) in patients with acute stroke.';
    case CalculatorType.RASS:
      return 'Ranks agitation and possibility for sedation.';
    case CalculatorType.APGAR_CALC:
      return 'Assesses neonates 1 and 5 minutes after birth.';
    case CalculatorType.CPSSS_CALC:
      return 'Cincinnati Prehospital Stroke Severity Scale(CP-SSS) predicts large vessel occlusion (LVO) and severe stroke in patients with stroke symptoms.';
    case CalculatorType.ETT_DEPTH_TIDAL_VOL_CALC:
      return 'Endotracheal Tube (ETT) Depth and Tidal Volume Calculator estimates depth of optimal ETT placement and target tidal volume by height.';
    case CalculatorType.GCS_CALC:
      return 'Coma severity based on Eye (4), Verbal (5), and Motor (6) criteria.';
    case CalculatorType.IBW_ABW_CALC:
      return 'Calculates ideal body weight (Devine formula) and adjusted body weight.';
    case CalculatorType.PETT_CALC:
      return 'Estimates Pediatric Endotracheal Tube (PETT) size based on age.';
    case CalculatorType.PGCS_CALC:
      return 'Assesses impaired consciousness and coma in pediatric patients.';
    case CalculatorType.LAMS_CALC:
      return 'Los Angeles Motor Scale (LAMS) stratifies stroke severity in the field.';
    case CalculatorType.MNIHSS_CALC:
      return 'Modified NIH Stroke Scale/Score (mNIHSS) is a shortened, validated version of the NIHSS.';
    case CalculatorType.FASTED_CALC:
      return 'Field Assessment Stroke Triage for Emergency Destination(FAST-ED): A Simple and Accurate Prehospital Scale to Detect Large Vessel Occlusion Strokes.';
    case CalculatorType.OXYGEN_TANK_MINUTES_CALC:
      return 'Calculates the time that a cylinder will support delivery of gas at a particular flow rate.';
    default:
      return 'EMS Calculator Description';
  }
};

const getQuestions = (calculator: CalculatorType): EMSQuestion[] => {
  switch (calculator) {
    case CalculatorType.PARKLAND:
      return [
        {
          index: 0,
          type: 'NUMBER_ANSWER',
          question: 'Weight',
          description: '',
          placeholder: 'Enter a number',
          unit: 'lbs', // Default unit
          answers: [],
          config: {
            autoPopulateWeight: true,
          },
        },
        {
          index: 1,
          type: 'NUMBER_ANSWER',
          question: 'Estimated percentage body burned',
          description:
            'Excluding 1st degree burns\n\nRule of 9s for Adults: 9% for each arm, 18% for each leg, 9% for head,18% for front torso, 18% for back torso.\nRule of 9s for Children: 9% for each arm, 14% for each leg, 18% for head, 18% for front torso, 18% for back torso.',
          placeholder: 'Enter a number',
          unit: '%', // Default unit
          answers: [],
        },
      ];
    case CalculatorType.RACE:
      return [
        {
          index: 0,
          type: 'SINGLE_SELECT',
          question: 'Facial palsy',
          description: '',
          answers: ['0 - Absent', '1 - Mild', '2 - Moderate to severe'],
        },
        {
          index: 1,
          type: 'SINGLE_SELECT',
          question: 'Arm motor impairment',
          description: '',
          answers: ['0 - Normal to mild', '1 - Moderate', '2 - Severe'],
        },
        {
          index: 2,
          type: 'SINGLE_SELECT',
          question: 'Leg motor impairment',
          description: '',
          answers: ['0 - Normal to mild', '1 - Moderate', '2 - Severe'],
        },
        {
          index: 3,
          type: 'SINGLE_SELECT',
          question: 'Head and gaze deviation',
          description: '',
          answers: ['0 - Absent', '1 - Present'],
        },
        {
          index: 4,
          type: 'NESTED_SINGLE_SELECT' as any,
          question: 'Hemiparesis',
          description: '',
          answers: ['Left', 'Right'],
          nested_questions: [
            [
              {
                index: 5,
                type: 'SINGLE_SELECT',
                question: 'Agnosia',
                description: '',
                answers: [
                  '0 - Patient recognizes his/her arm and the impairment',
                  '1 - Does not recognize his/her arm or the impairment',
                  '2 - Does not recognize his/her arm nor the impairment',
                ],
              },
            ],
            [
              {
                index: 6,
                type: 'SINGLE_SELECT',
                question: 'Aphasia',
                description: '',
                answers: [
                  '0 - Performs both tasks correctly',
                  '1 - Performs one task correctly',
                  '2 - Performs neither task',
                ],
              },
            ],
          ],
        },
      ];
    case CalculatorType.RASS:
      return [
        {
          index: 0,
          type: 'SINGLE_SELECT',
          question: 'Patient description',
          description: '',
          answers: [
            '4 - Combative',
            '3 - Very agitated',
            '2 - Agitated',
            '1 - Restless',
            '0 - Alert and calm',
            '-1 - Drowsy',
            '-2 - Light sedation',
            '-3 - Moderate sedation',
            '-4 - Deep sedation',
            '-5 - Unarousable sedation',
          ],
        },
      ];
    case CalculatorType.APGAR_CALC:
      return [
        // Add questions and options for APGAR Score
        {
          index: 0,
          type: 'SINGLE_SELECT',
          question: 'Activity / Muscle Tone',
          description: '',
          answers: ['2 - Active', '1 - Some extremity flexion', '0 - Limp'],
        },
        {
          index: 1,
          type: 'SINGLE_SELECT',
          question: 'Pulse',
          description: '',
          answers: ['2 - "≥100 BPM"', '1 - "<100 BPM"', '0 - Absent'],
        },
        {
          index: 2,
          type: 'SINGLE_SELECT',
          question: 'Grimace',
          description: '',
          answers: ['2 - Sneeze / Cough', '1 - Grimace', '0 - None'],
        },
        {
          index: 3,
          type: 'SINGLE_SELECT',
          question: 'Appearance / Color',
          description: '',
          answers: [
            '2 - All Pink',
            '1 - Blue extremities, pink body',
            '0 - Blue / pale',
          ],
        },
        {
          index: 4,
          type: 'SINGLE_SELECT',
          question: 'Respirations',
          description: '',
          answers: ['2 - Good / crying', '1 - Irregular / slow', '0 - Absent'],
        },
      ];
    case CalculatorType.CPSSS_CALC:
      return [
        {
          index: 0,
          type: 'SINGLE_SELECT',
          question: 'Conjugate Gaze Deviation',
          description: '',
          answers: ['0 - No', '2 - Yes'],
        },
        {
          index: 1,
          type: 'SINGLE_SELECT',
          question: 'Ask Patient Age and Current Month',
          description: '',
          answers: [
            '0 - Both Correct',
            '0.5 - One Correct',
            '0.5 - Neither Correct',
          ],
        },
        {
          index: 2,
          type: 'SINGLE_SELECT',
          question: 'Ask Patient to close eyes and open/close hand',
          description: '',
          answers: [
            '0 - Follows both commands',
            '0.5 - Follows one command',
            '0.5 - Follows neither command',
          ],
        },
        {
          index: 3,
          type: 'SINGLE_SELECT',
          question:
            'Instruct patient to hold arm (either or both) up for 10 seconds',
          description: '',
          answers: ['0 - Can do', '1 - Cannot do'],
        },
      ];
    case CalculatorType.ETT_DEPTH_TIDAL_VOL_CALC:
      return [
        {
          index: 0,
          type: 'NUMBER_ANSWER',
          question: 'Height',
          description: '',
          placeholder: 'Enter a number',
          unit: 'in', // Default unit
          answers: [],
        },
        {
          index: 1,
          type: 'SINGLE_SELECT',
          question: 'Gender',
          description: '',
          answers: ['Female', 'Male'],
        },
        {
          index: 2,
          type: 'SINGLE_SELECT',
          question: 'Tidal Volume Target',
          description: '',
          answers: ['6 mL/kg', '8 mL/kg'],
        },
      ];
    case CalculatorType.GCS_CALC:
      return [
        {
          index: 0,
          type: 'SINGLE_SELECT',
          question: 'Best Eye Response',
          description:
            'If local injury, edema, or otherwise unable to be assessed, mark "Not testable (NT)"',
          answers: [
            '4 - Spontaneously',
            '3 - To verbal command',
            '2 - To pain',
            '1 - No eye opening',
            'NT - Not testable',
          ],
        },
        {
          index: 1,
          type: 'SINGLE_SELECT',
          question: 'Best Verbal Response',
          description:
            'If intubated or otherwise unable to be assessed, mark "Not testable (NT)"',
          answers: [
            '5 - Oriented',
            '4 - Confused',
            '3 - Inappropriate words',
            '2 - Incomprehensible sounds',
            '1 - No verbal response',
            'NT - Not testable/intubated',
          ],
        },
        {
          index: 2,
          type: 'SINGLE_SELECT',
          question: 'Best Motor Response',
          description:
            'If on sedation/paralysis or unable to be assessed, mark "Not testable (NT)"',
          answers: [
            '6 - Obeys commands',
            '5 - Localizes pain',
            '4 - Withdraws from pain',
            '3 - Flexion to pain',
            '2 - Extension to pain',
            '1 - No motor response',
            'NT - Not testable',
          ],
        },
      ];
    case CalculatorType.IBW_ABW_CALC:
      return [
        {
          index: 0,
          type: 'SINGLE_SELECT',
          question: 'Gender',
          description: '',
          answers: ['Female', 'Male'],
        },
        {
          index: 1,
          type: 'NUMBER_ANSWER',
          question: 'Height',
          description: '',
          placeholder: 'Enter a number',
          unit: 'in', // Default unit
          answers: [],
        },
        {
          index: 2,
          type: 'NUMBER_ANSWER',
          question: 'Actual Body Weight',
          description:
            'Optional, for calculating adjusted body weight in obese patients',
          placeholder: 'Enter a number',
          unit: 'lbs', // Default unit
          answers: [],
          optional: true,
          config: {
            autoPopulateWeight: true,
          },
        },
      ];
    case CalculatorType.PETT_CALC:
      return [
        {
          index: 0,
          type: 'NUMBER_ANSWER',
          question: 'Age',
          description: '',
          placeholder: 'Enter a number',
          unit: 'years', // Default unit
          answers: [],
        },
      ];
    case CalculatorType.PGCS_CALC:
      return [
        {
          index: 0,
          type: 'NESTED_SINGLE_SELECT' as any,
          question: 'Age',
          description: '',
          answers: ['<= 2 years', '> 2 years'],
          nested_questions: [
            [
              {
                index: 1,
                type: 'SINGLE_SELECT',
                question: 'Best Eye Response',
                description: '',
                answers: [
                  '4 - Open Spontaneously',
                  '3 - Open to verbal stimuli',
                  '2 - Open to pain only',
                  '1 - No response',
                ],
              },
              {
                index: 2,
                type: 'SINGLE_SELECT',
                question: 'Best Verbal Response',
                description: '',
                answers: [
                  '5 - Coos, babbles',
                  '4 - Irritable cries',
                  '3 - Cries in response to pain',
                  '2 - Moans in response to pain',
                  '1 - No response',
                ],
              },
              {
                index: 3,
                type: 'SINGLE_SELECT',
                question: 'Best Motor Response',
                description: '',
                answers: [
                  '6 - Moves spontaneously/purposefully',
                  '5 - Withdraws to touch',
                  '4 - Withdraws to pain',
                  '3 - Flexor posturing to pain',
                  '2 - Extensor posturing to pain',
                  '1 - No response',
                ],
              },
            ],
            [
              {
                index: 0,
                type: 'SINGLE_SELECT',
                question: 'Best Eye Response',
                description:
                  'If local injury, edema, or otherwise unable to be assessed, mark "Not testable (NT)"',
                answers: [
                  '4 - Spontaneously',
                  '3 - To verbal command',
                  '2 - To pain',
                  '1 - No eye opening',
                  'NT - Not testable',
                ],
              },
              {
                index: 1,
                type: 'SINGLE_SELECT',
                question: 'Best Verbal Response',
                description:
                  'If intubated or otherwise unable to be assessed, mark "Not testable (NT)"',
                answers: [
                  '5 - Oriented',
                  '4 - Confused',
                  '3 - Inappropriate words',
                  '2 - Incomprehensible sounds',
                  '1 - No verbal response',
                  'NT - Not testable/intubated',
                ],
              },
              {
                index: 2,
                type: 'SINGLE_SELECT',
                question: 'Best Motor Response',
                description:
                  'If on sedation/paralysis or unable to be assessed, mark "Not testable (NT)"',
                answers: [
                  '6 - Obeys commands',
                  '5 - Localizes pain',
                  '4 - Withdraws from pain',
                  '3 - Flexion to pain',
                  '2 - Extension to pain',
                  '1 - No motor response',
                  'NT - Not testable',
                ],
              },
            ],
          ],
        },
      ];
    case CalculatorType.LAMS_CALC:
      return [
        {
          index: 0,
          type: 'SINGLE_SELECT',
          question: 'Facial droop',
          description: '',
          answers: ['0 - Absent', '1 - Present'],
        },
        {
          index: 1,
          type: 'SINGLE_SELECT',
          question: 'Arm drift',
          description: '',
          answers: ['0 - Absent', '1 - Drifts down', '2 - Falls rapidly'],
        },
        {
          index: 2,
          type: 'SINGLE_SELECT',
          question: 'Grip strength',
          description: '',
          answers: ['0 - Normal', '1 - Weak grip', '2 - No grip'],
        },
      ];
    case CalculatorType.MNIHSS_CALC:
      return [
        {
          index: 0,
          type: 'SINGLE_SELECT',
          question: '1B: Level of consciousness/orientation questions',
          description: 'Ask month and age',
          answers: [
            '0 - Both questions correct',
            '1 - One question correct',
            '2 - Zero questions correct',
          ],
        },
        {
          index: 1,
          type: 'SINGLE_SELECT',
          question: '1C: Level of consciousness commands',
          description: `'Blink eyes' & 'squeeze hands' (pantomime commands if communication barrier)`,
          answers: [
            '0 - Both tasks correct',
            '1 - One task correct',
            '2 - Zero tasks correct',
          ],
        },
        {
          index: 2,
          type: 'SINGLE_SELECT',
          question: '2: Test horizontal extra-ocular movements',
          description: '',
          answers: [
            '0 - Normal',
            '1 - Partial gaze palsy',
            '2 - Total gaze palsy',
          ],
        },
        {
          index: 3,
          type: 'SINGLE_SELECT',
          question: '3: Test visual fields',
          description: '',
          answers: [
            '0 - No visual loss',
            '1 - Partial hemianopia',
            '2 - Complete hemianopia',
            '3 - Bilateral hemianopia',
          ],
        },
        {
          index: 4,
          type: 'SINGLE_SELECT',
          question: '5A: Test left arm motor drift',
          description: '',
          answers: [
            '0 - No drift',
            '1 - Drift before 10 seconds',
            '2 - Falls before 10 seconds',
            '3 - No effort against gravity',
            '4 - No movement',
          ],
        },
        {
          index: 5,
          type: 'SINGLE_SELECT',
          question: '5B: Test right arm motor drift',
          description: '',
          answers: [
            '0 - No drift',
            '1 - Drift before 10 seconds',
            '2 - Falls before 10 seconds',
            '3 - No effort against gravity',
            '4 - No movement',
          ],
        },
        {
          index: 6,
          type: 'SINGLE_SELECT',
          question: '6A: Test left leg motor drift',
          description: '',
          answers: [
            '0 - No drift',
            '1 - Drift before 5 seconds',
            '2 - Falls before 5 seconds',
            '3 - No effort against gravity',
            '4 - No movement',
          ],
        },
        {
          index: 7,
          type: 'SINGLE_SELECT',
          question: '6B: Test right leg motor drift',
          description: '',
          answers: [
            '0 - No drift',
            '1 - Drift before 5 seconds',
            '2 - Falls before 5 seconds',
            '3 - No effort against gravity',
            '4 - No movement',
          ],
        },
        {
          index: 8,
          type: 'SINGLE_SELECT',
          question: '8: Test sensation',
          description: '',
          answers: [
            '0 - Normal; no sensory loss',
            '1 - Abnormal; sensory loss',
          ],
        },
        {
          index: 9,
          type: 'SINGLE_SELECT',
          question: '9: Test language/aphasia',
          description: 'Describe the scene; name the items; read the sentences',
          answers: [
            '0 - Normal; no aphasia',
            '1 - Mild aphasia',
            '2 - Severe aphasia',
            '3 - Mute/global aphasia',
          ],
        },
        {
          index: 10,
          type: 'SINGLE_SELECT',
          question: '11: Test extinction/inattention/neglect',
          description: '',
          answers: ['0 - Normal', '1 - Mild', '2 - Severe'],
        },
      ];
    case CalculatorType.FASTED_CALC:
      return [
        {
          index: 0,
          type: 'SINGLE_SELECT',
          question: 'Facial Palsy',
          description: '',
          answers: ['0 - Absent', '1 - Present'],
        },
        {
          index: 1,
          type: 'SINGLE_SELECT',
          question: 'Arm Weakness',
          description: '',
          answers: [
            '0 - Normal arm strength',
            '1 - Mild arm weakness',
            '2 - Significant arm weakness or No movement',
          ],
        },
        {
          index: 2,
          type: 'SINGLE_SELECT',
          question: 'Speech Changes',
          description: '',
          answers: [
            '0 - Absent',
            '1 - Mild to Moderate',
            '2 - Severe, global aphasia, or mute',
          ],
        },
        {
          index: 3,
          type: 'SINGLE_SELECT',
          question: 'Eye Deviation',
          description: '',
          answers: ['0 - Absent', '1 - Partial', '2 - Forced deviation'],
        },
        {
          index: 4,
          type: 'SINGLE_SELECT',
          question: 'Denial/Neglect',
          description: '',
          answers: [
            '0 - Absent',
            '1 - Extinction to bilateral simultaneous stiumation in only one sensory modality',
            '2 - Does not recognize own hand or orients to only one side of the body',
          ],
        },
      ];
    case CalculatorType.OXYGEN_TANK_MINUTES_CALC:
      return [
        {
          index: 0,
          type: 'SINGLE_SELECT',
          question: 'Cylinder Type',
          description: '',
          answers: [
            '"D" (Portable Tank) Cylinder (k = 0.16)',
            '"E" Cylinder (k = 0.28)',
            '"H" Cylinder (k = 3.14)',
            '"M" (Main Tank) Cylinder (k = 1.56)',
          ],
        },
        {
          index: 1,
          type: 'NUMBER_ANSWER',
          question: 'Safe Residual Pressure',
          description: '',
          placeholder: 'Enter a number',
          unit: 'PSI', // Default unit
          answers: [],
          config: {
            preload: 200,
          },
        },
        {
          index: 2,
          type: 'NUMBER_ANSWER',
          question: 'Gauge Pressure',
          description:
            'This is the current pressure reading on the tank gauge.',
          placeholder: 'Enter a number',
          unit: 'PSI', // Default unit
          answers: [],
        },
        {
          index: 3,
          type: 'NUMBER_ANSWER',
          question: 'Oxygen Consumption',
          description:
            'This is the liters per minute of supplemental oxygen or the measured oxygen consumption displayed on the ventilator.',
          placeholder: 'Enter a number',
          unit: 'L/min', // Default unit
          answers: [],
        },
      ];
    default:
      return [];
  }
};

const getModifiedDate = (calculator: CalculatorType): Date => {
  switch (calculator) {
    case CalculatorType.PARKLAND:
    case CalculatorType.RACE:
    case CalculatorType.RASS:
    case CalculatorType.APGAR_CALC:
    case CalculatorType.CPSSS_CALC:
    case CalculatorType.ETT_DEPTH_TIDAL_VOL_CALC:
    case CalculatorType.GCS_CALC:
    case CalculatorType.IBW_ABW_CALC:
    case CalculatorType.PETT_CALC:
    case CalculatorType.PGCS_CALC:
      return new Date('2025-02-06T17:34:00-06:00'); //Feb 6th 2025 at 5:34 pm central time format in iso
    case CalculatorType.LAMS_CALC:
    case CalculatorType.MNIHSS_CALC:
    case CalculatorType.FASTED_CALC:
    case CalculatorType.OXYGEN_TANK_MINUTES_CALC:
      return new Date('2025-03-03T09:08:00-06:00'); //Feb 6th 2025 at 5:34 pm central time format in iso
    default:
      return new Date();
  }
};
