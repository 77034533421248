import { DataStore, graphqlOperation } from 'aws-amplify';
import {
  getUser,
  listUsers,
  usersByCognitoID,
  usersByDepartmentID,
} from '../../graphql/queries';
import DepartmentItem, {
  getTopLevelDep,
  getTopLevelDepID,
} from '../model/DepartmentItem';
import { User, UserStatus, UserType } from '../../models';
import {
  BatchQuery,
  executePaginationQuery,
  executeQuery,
  executeSingleQuery,
  Response,
  ResponseType,
} from '../AmplifyDB';
import { globals } from '../../ui/_global/common/Utils';
import { ModelUserFilterInput, UpdateUserInput } from '../../API';
import { deleteUser, updateUser } from '../../graphql/mutations';
import { HM_getUser, HM_usersByIndexedParentDepID } from '../QueryTypes';
const AWS = require('aws-sdk');
AWS.config.update({
  accessKeyId: process.env.REACT_APP_AI_PARSER_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_AI_PARSER_SECRET_KEY,
  region: 'us-east-2',
});

const cognito = new AWS.CognitoIdentityServiceProvider();

export const getUserByCognitoID = async (
  cognitoID: string,
  includeEmail: boolean = false
): Promise<User | undefined> => {
  try {
    let userData: any = await executeSingleQuery(usersByCognitoID, {
      cognitoID: cognitoID,
    });
    if (includeEmail) {
      let email = await getEmailByCognitoId(cognitoID);
      userData.email = email;
    }
    return userData;
    // if (userData.data.usersByCognitoID.items.length === 0) return undefined;
    // else return userData.data.usersByCognitoID.items[0];
  } catch (error: any) {
    return undefined;
  }
};

export const getAllUsersByCognitoID = async (
  cognitoID: string,
  includeEmail: boolean = false
): Promise<User[]> => {
  let userData: any = await executeQuery(usersByCognitoID, {
    cognitoID: cognitoID,
  });
  let items = userData;
  if (includeEmail) {
    let promiseFunctions: (() => Promise<string | null>)[] = [];
    for (let item of items)
      promiseFunctions.push(() => getEmailByCognitoId(item.cognitoID));

    let emails = await BatchQuery(promiseFunctions);
    for (let i = 0; i < items.length; i++) items[i].email = emails[i];
  }
  return items;
};

/*
  const userFormik = useFormik({
    initialValues: {
      firstName: user.firstName,
      lastName: user.lastName,
      cognitoID: user.cognitoID,
      type: user.type,
      depAdmins: user.depAdmins,
      departmentID: user.departmentID,
      hashedPin: user.hashedPin,
      saltPin: user.saltPin,
      status: user.status,
      notificationTokens: user.notificationTokens,
      pairedDepIDs: user.pairedDepIDs,
      oneDoseVersion: user.oneDoseVersion,
    },
    */
export const editUserItem = async (
  user: User,
  values: any
): Promise<User | undefined> => {
  try {
    // let oldUser = await DataStore.query(User, user.id);
    let oldUser = await executeSingleQuery(getUser, {
      id: user.id,
    });
    if (!oldUser) return undefined;

    let input: UpdateUserInput = {
      id: user.id,
      firstName: values.firstName,
      lastName: values.lastName,
      type: values.type,
      status: values.status,
      pairedDepIDs: values.pairedDepIDs,
      oneDoseVersion: values.oneDoseVersion,
      notificationTokens: values.notificationTokens,
      depAdmins: values.depAdmins,
      hashedPin: values.hashedPin,
      saltPin: values.saltPin,
      departmentID: values.departmentID,
      indexedParentDepID: values.indexedParentDepID,
    };

    let updatedUser = await executeSingleQuery(updateUser, {
      input: input,
    });

    return updatedUser;
    // if (userData.data.usersByCognitoID.items.length === 0) return undefined;
    // else return userData.data.usersByCognitoID.items[0];
  } catch (error: any) {
    return undefined;
  }
};

export const fetchUsers = async (
  department: DepartmentItem,
  useDataStore: boolean = true,
  status: UserStatus | undefined = undefined,
  lazyLoadCallback?: (users: User[], isCompleted: boolean) => void,
  allSubDeps: DepartmentItem[] = []
): Promise<Response> => {
  try {
    let usersList;
    if (useDataStore) {
      usersList = await DataStore.query(User, (u) =>
        u.and((u) => [
          u.or((u) => [
            u.pairedDepIDs.contains(department.id),
            u.departmentID.eq(department.id),
            u.indexedParentDepID.eq(department.id),
          ]),
          status ? u.status.eq(status) : u.status.ne('DELETED'),
        ])
      );
    } else {
      const topDep: DepartmentItem = getTopLevelDep(department);
      let filter: ModelUserFilterInput = {
        and: [
          // {
          //   or: [
          //     { indexedParentDepID: { eq: department.id } },
          //     { departmentID: { eq: department.id } },
          //     { pairedDepIDs: { contains: department.id } },
          //   ],
          // },

          { status: status ? { eq: status } : { ne: UserStatus.DELETED } },
          { _deleted: { ne: true } },
        ],
      };
      let promises = [
        executeQuery(
          HM_usersByIndexedParentDepID,
          {
            indexedParentDepID: topDep.organizationID,
            filter: filter,
          },
          {
            limit: -1,
            cacheQuery: false,
            loadLimit: 100,
          },
          lazyLoadCallback
        ),
      ];
      if (topDep.id !== topDep.organizationID) {
        promises.push(
          executeQuery(
            HM_usersByIndexedParentDepID,
            {
              indexedParentDepID: topDep.id,
              filter: filter,
            },
            {
              limit: -1,
              cacheQuery: false,
              loadLimit: 100,
            },
            lazyLoadCallback
          )
        );
      }
      let results = await Promise.all(promises);
      usersList = results.flat();

      //Eliminate duplicates
      usersList = usersList.filter(
        (user: User, index: number, self: User[]) =>
          index === self.findIndex((t: User) => t.id === user.id)
      );

      let deps = [department, ...allSubDeps];

      usersList = usersList.filter((user: User) => {
        if (
          user.pairedDepIDs &&
          user.pairedDepIDs.length > 0 &&
          deps.length > 0
        ) {
          return user.pairedDepIDs.some((id: string) =>
            deps.some((dep: DepartmentItem) => dep.id === id)
          );
        }
        return true;
      });
    }
    auditUsers(usersList);
    return {
      type: ResponseType.Success,
      data: usersList,
    };
  } catch (error) {
    console.error('Error fetching users:', error);
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

const auditUsers = async (users: User[]) => {
  let pairedDepIDUsers = users.filter(
    (user) => user.pairedDepIDs == null || user.pairedDepIDs.length === 0
  );
  if (pairedDepIDUsers.length > 0) {
    let promiseFunctions: (() => Promise<User>)[] = [];
    for (let user of pairedDepIDUsers) {
      let userJson: UpdateUserInput = {
        id: user.id,
        pairedDepIDs: [user.departmentID],
        _version: (user as any)._version,
      };
      promiseFunctions.push(() =>
        executeSingleQuery(updateUser, {
          input: userJson,
        })
      );
    }
    await BatchQuery(promiseFunctions);
  }
};

export const fetchPaginatedUsers = async (
  department: DepartmentItem,
  useDataStore: boolean = true,
  config?: {
    status?: UserStatus;
    limit?: number;
    nextToken?: string;
  }
): Promise<Response> => {
  const { status, limit, nextToken } = config || {};
  try {
    let usersList;
    let nextTokenResult;
    if (useDataStore) {
      let page = nextToken ? parseInt(nextToken) : 0;
      if (isNaN(page)) page = 0;
      usersList = await DataStore.query(
        User,
        (u) =>
          u.and((u) => [
            u.or((u) => [
              u.pairedDepIDs.contains(department.id),
              u.departmentID.eq(department.id),
              u.indexedParentDepID.eq(department.organizationID),
            ]),
            status ? u.status.eq(status) : u.status.ne('DELETED'),
          ]),
        {
          page: page,
          limit: limit,
        }
      );
    } else {
      let response = await executePaginationQuery(
        usersByDepartmentID,
        {
          departmentID: department.id,
          filter: status
            ? { status: { eq: status } }
            : { status: { ne: 'DELETED' } },
        },
        {
          loadLimit: limit,
          limit: limit,
          nextQueryToken: nextToken,
        }
      );
      usersList = response.items;
      nextTokenResult = response.nextToken;
    }
    return {
      type: ResponseType.Success,
      data: {
        list: usersList,
        nextToken: nextTokenResult,
      },
    };
  } catch (error) {
    console.error('Error fetching users:', error);
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export type UserStats = {
  totalUsers: number;
  createdToday: number;
  createdThisWeek: number;
  createdThisMonth: number;
  createdThisYear: number;
  superAdminUsers: number;
  deptAdminUsers: number;
  deptUsers: number;
  userUsers: number;
  active: number;
  disabled: number;
  deleted: number;
  notifications: number;
};

/**
 * Create a functon that returns a list of stats about the users, such as, created in the last 30 days, created in the last 60 days, created in the last 90 days, etc.
 * @param department - The department to fetch the users from
 * @param status - The status of the users to fetch
 * @returns The list of stats about the users
 */
export const fetchUserStatsAPI = async (
  department: DepartmentItem,
  status: UserStatus | undefined = undefined,
  allSubDeps: DepartmentItem[] = [],
  userListParms?: User[]
): Promise<{ stats: UserStats; users: User[] }> => {
  // Calculate time boundaries once
  const todayStart = new Date(new Date().setHours(0, 0, 0, 0)).getTime();
  const weekAgo = todayStart - 7 * 24 * 60 * 60 * 1000;
  const monthAgo = todayStart - 30 * 24 * 60 * 60 * 1000;
  const yearAgo = todayStart - 365 * 24 * 60 * 60 * 1000;

  try {
    let usersList: User[] = [];
    if (userListParms) {
      usersList = userListParms;
    } else {
      const userResponse: Response = await fetchUsers(
        department,
        false,
        status,
        undefined,
        allSubDeps
      );
      usersList = userResponse.data;
    }

    // Early return for empty list
    if (!usersList?.length) {
      return {
        stats: {
          totalUsers: 0,
          active: 0,
          disabled: 0,
          deleted: 0,
          notifications: 0,
          createdToday: 0,
          createdThisWeek: 0,
          createdThisMonth: 0,
          createdThisYear: 0,
          superAdminUsers: 0,
          deptAdminUsers: 0,
          deptUsers: 0,
          userUsers: 0,
        },
        users: [],
      };
    }

    let stats = usersList.reduce(
      (acc: UserStats, item: User) => {
        // Status counts - simplified logic
        const userStatus = item.status || UserStatus.ACTIVE;
        acc[
          userStatus === UserStatus.ACTIVE
            ? 'active'
            : userStatus === UserStatus.SUSPENDED
              ? 'disabled'
              : 'deleted'
        ]++;

        // Notification counts
        if (item.notificationTokens?.length) acc.notifications++;

        // Creation date stats - only parse date once
        if (item.createdAt) {
          const createdTime = new Date(item.createdAt).getTime();
          if (createdTime >= todayStart) acc.createdToday++;
          if (createdTime >= weekAgo) acc.createdThisWeek++;
          if (createdTime >= monthAgo) acc.createdThisMonth++;
          if (createdTime >= yearAgo) acc.createdThisYear++;
        }

        // User type counts
        switch (item.type) {
          case UserType.ADMIN:
            acc.superAdminUsers++;
            break;
          case UserType.DEPT_ADMIN:
            acc.deptAdminUsers++;
            break;
          case UserType.USER:
            acc.userUsers++;
            break;
          case UserType.DEPT:
            acc.deptUsers++;
            break;
        }

        return acc;
      },
      {
        totalUsers: usersList.length,
        active: 0,
        disabled: 0,
        deleted: 0,
        notifications: 0,
        createdToday: 0,
        createdThisWeek: 0,
        createdThisMonth: 0,
        createdThisYear: 0,
        superAdminUsers: 0,
        deptAdminUsers: 0,
        deptUsers: 0,
        userUsers: 0,
      }
    );
    return {
      stats: stats,
      users: usersList,
    };
  } catch (error) {
    console.error('Error fetching user stats:', error);
    throw error; // Let caller handle the error
  }
};

export const unsubscribeUser = async (
  department: DepartmentItem,
  user: User
): Promise<Response> => {
  try {
    // let dbUser = await DataStore.query(User, user.id);
    let dbUser = await executeSingleQuery(getUser, {
      id: user.id,
    });
    if (!dbUser)
      return {
        type: ResponseType.Failure,
        data: 'User not found',
      };

    if (dbUser.pairedDepIDs) {
      let newPairedDepIDs = dbUser.pairedDepIDs.filter(
        (id: string) => id !== department.id
      );
      let newUser: User;
      if (newPairedDepIDs.length === 0) {
        let input: UpdateUserInput = {
          id: user.id,
          pairedDepIDs: undefined,
          status: UserStatus.SUSPENDED,
        };
        newUser = await executeSingleQuery(updateUser, {
          input: input,
        });
      } else {
        let input: UpdateUserInput = {
          id: user.id,
          pairedDepIDs: newPairedDepIDs,
        };
        newUser = await executeSingleQuery(updateUser, {
          input: input,
        });
      }
      return {
        type: ResponseType.Success,
        data: newUser,
      };
    } else {
      return {
        type: ResponseType.Failure,
        data: 'User is not subscribed to ANY departments',
      };
    }
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const fetchUser = async (
  userID: string,
  useDataStore: boolean = true
): Promise<Response> => {
  try {
    let user: User | undefined;
    if (useDataStore) {
      user = await DataStore.query(User, userID);
    } else {
      user = await executeSingleQuery(getUser, {
        id: userID,
      });
    }
    return {
      type: ResponseType.Success,
      data: user,
    };
  } catch (error) {
    console.error('Error fetching users:', error);
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export type UserJSON = {
  id: string;
  firstName: string;
  lastName: string;
  type: UserType;
  departmentID: string;
  indexedParentDepID: string;
};
export const editUser = async (
  user: UserJSON,
  pairedDepIDs: string[],
  depAdminsIDs: string[],
  department: DepartmentItem
): Promise<Response> => {
  try {
    let u = await executeSingleQuery(HM_getUser, {
      id: user.id,
    });
    if (!u)
      return {
        type: ResponseType.Failure,
        data: 'User not found',
      };

    if (pairedDepIDs.length === 0) pairedDepIDs = [department.id];

    let ids = depAdminsIDs;
    if (user.type === 'ADMIN' || user.type === 'DEPT_ADMIN') {
      ids = [...new Set([...ids])]; //, department.id]
      if (ids.length === 0) ids = [department.id];
    } else if (user.type === 'DEPT' || user.type === 'USER') ids = [];

    let input: UpdateUserInput = {
      id: user.id,
      departmentID: user.departmentID,
      indexedParentDepID: user.indexedParentDepID,
      firstName: user.firstName,
      lastName: user.lastName,
      pairedDepIDs: pairedDepIDs,
      type: user.type,
      depAdmins: ids,
      _version: u._version,
    };
    let res = await executeSingleQuery(updateUser, {
      input: input,
    });
    return {
      type: ResponseType.Success,
      data: res,
    };
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

export const updateUserStatus = async (
  user: User | null,
  status: UserStatus
): Promise<Response> => {
  if (!user) {
    if (globals.debug) console.log('User not found', 'Params:', user, status);
    return {
      type: ResponseType.Failure,
      data: 'User not found',
    };
  }
  try {
    // const dbUser = await DataStore.query(User, user.id);
    let dbUser = await executeSingleQuery(getUser, {
      id: user.id,
    });
    if (dbUser) {
      let input: UpdateUserInput = {
        id: user.id,
        status: status,
        _version: dbUser._version,
      };
      //Modify the user status to
      let res = await executeSingleQuery(updateUser, {
        input: input,
      });
      if (globals.debug) console.log('User status updated:', res);
      return {
        type: ResponseType.Success,
        data: res,
      };
    }
    return {
      type: ResponseType.Failure,
      data: 'User not found',
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

/**
 * 1-28-24 Hazlett: Get a user by their ID. If the user is not found in the database,
 * it will query the database for the user.
 * @param id - The ID of the user to get.
 * @param includeEmail - Whether to include the email in the user object.
 * @returns The user object if found, otherwise undefined.
 */
export const getUserByID = async (
  id: string,
  includeEmail: boolean = false
): Promise<User | undefined> => {
  try {
    let user: any = await DataStore.query(User, id);
    if (!user) {
      user = await executeSingleQuery(getUser, {
        id: id,
      });
    }
    if (includeEmail) {
      let email = await getEmailByCognitoId(user.cognitoID);
      user = {
        ...user,
        email: email,
      };
    }
    return user;
  } catch (error) {
    return undefined;
  }
};

export const getEmailByCognitoId = async (
  cognitoId: string
): Promise<string | null> => {
  try {
    const userData = await cognito
      .adminGetUser({
        UserPoolId: process.env.REACT_APP_USER_POOL_ID,
        Username: cognitoId,
      })
      .promise();
    const email = userData.UserAttributes.find(
      (attr: any) => attr.Name === 'email'
    ).Value;

    return email || null;
  } catch (error) {
    if (globals.debug) console.error('Error fetching user data:', error);
    return null;
  }
};

export const getAllUsersByEmail = async (email: string): Promise<User[]> => {
  try {
    // List users in Cognito by email filter
    const cognitoUsers: any = await cognito
      .listUsers({
        UserPoolId: process.env.REACT_APP_USER_POOL_ID,
        Filter: `email = "${email}"`,
      })
      .promise();

    // Get all cognito IDs from the results
    const cognitoIDs = cognitoUsers.Users.map((user: any) => user.Username);

    // If no users found, return empty array
    if (cognitoIDs.length === 0) return [];

    // Get all users for each cognito ID
    let promiseFunctions: (() => Promise<User[]>)[] = [];
    for (let id of cognitoIDs)
      promiseFunctions.push(() => getAllUsersByCognitoID(id, false));
    const userArrays = await BatchQuery(promiseFunctions);
    let flattenedUsers = userArrays.flat().map((user: User) => {
      return {
        ...user,
        email: email,
      };
    });

    return flattenedUsers;
  } catch (error) {
    console.error('Error in getAllUsersByEmail:', error);
    return [];
  }
};

export const deleteUserItem = async (user: User): Promise<Response> => {
  try {
    let res = await executeSingleQuery(deleteUser, {
      id: user.id,
    });
    return {
      type: ResponseType.Success,
      data: res,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};
