import ProtocolItem from './ProtocolItem';
import PatientWeight from '../../ui/_global/common/PatientWeight';
import { globals, roundToDec } from '../../ui/_global/common/Utils';
import ElectricalItem from './ElectricalItem';
import { ElectricalDose } from '../../models';
import ModelSubItem from './ModelSubItem';
import CPRItem from './CPRItem';

export const cloneAllElectricalSubItems = (
  subItems: ElectricalSubItem[]
): ElectricalSubItem[] => {
  let clone: ElectricalSubItem[] = [];
  subItems.forEach((subElectrical: ElectricalSubItem) => {
    clone.push(cloneElectricalSubItem(subElectrical));
  });
  return clone;
};

export const cloneElectricalSubItem = (
  subElectrical: ElectricalSubItem
): ElectricalSubItem => {
  let clone = new ElectricalSubItem(
    subElectrical.parent,
    subElectrical.parentProtocol,
    subElectrical.model
  );
  clone.basis = subElectrical.basis;
  clone.basisHigh = subElectrical.basisHigh;
  clone.calcMax = subElectrical.calcMax;
  clone.maxDose = subElectrical.maxDose;
  clone.basisUnit = subElectrical.basisUnit;
  clone.calcUnit = subElectrical.calcUnit;
  clone.calcMaxUnit = subElectrical.calcMaxUnit;
  clone.maxDoseUnit = subElectrical.maxDoseUnit;
  clone.calcCalcMaxUnit = subElectrical.calcCalcMaxUnit;
  clone.parentCPR = subElectrical.parentCPR;
  clone.parentProtocol = subElectrical.parentProtocol;
  return clone;
};

/**
 *
 * @param item
 * @returns
 */
export const getElectricalBasisString = (item: ElectricalSubItem): string => {
  if (!item.calcUnit || item.calcUnit.length == 0) {
    if (item.basisHigh === globals.MAX_VALUE)
      return `${item.basis} ${item.basisUnit}`;
    return `${item.basis}-${item.basisHigh} ${item.basisUnit}`;
  } else {
    if (item.basisHigh === globals.MAX_VALUE)
      return `${item.basis} ${item.basisUnit}/${item.calcUnit}`;
    return `${item.basis}-${item.basisHigh} ${item.basisUnit}/${item.calcUnit}`;
  }
};

const timeUnits = ['sec', 'min', 'hr', 'seconds', 'minutes', 'hours'];

class ElectricalSubItem extends ModelSubItem<ElectricalDose> {
  parent: ElectricalItem;
  overrideItem: ElectricalSubItem | null | undefined;
  activeItem: ElectricalSubItem | null | undefined;
  parentCPR: CPRItem | undefined;
  basisUnit: string = '';
  calcUnit: string = '';
  maxDoseUnit: string = '';
  calcMaxUnit: string = '';
  calcCalcMaxUnit: string = '';

  cprAssistID: string | null = null;
  protocolID: string | null = null;

  constructor(electrical: ElectricalItem, parent: any, range: ElectricalDose) {
    super(electrical, parent, range);
    this.TAG = 'ElectricalSubItem';
    this.parent = electrical;
    if (parent.TAG === 'CPRItem') {
      this.parentCPR = parent;
    }
    this.title = range.title ?? '';
    this.cprAssistID = range.cprAssistID ?? null;
    this.protocolID = range.protocolID ?? null;

    this.parseRange(range);

    let { time, timeHigh } = this.parseRepeatTime(range.repeatTime ?? '');
    this.repeatTimeSec = time;
  }

  parseRepeatTime(repeatTime: string, isConfirmUnits: boolean = true): any {
    if (repeatTime === '' || repeatTime === '0')
      return {
        time: -1,
        timeHigh: -1,
      };
    let time: number = -1;
    let timeHigh: number = -1;
    let timeArr: string[] = repeatTime.split(' ', 2);

    /* Parse dose amount to a double for comparison */
    let timeNums: string[] = timeArr[0].split('-');
    time = Number(timeNums[0]);
    if (timeNums.length > 1) timeHigh = parseFloat(timeNums[1]);

    /* Parse the units for the dose to validate calculations */
    if (timeArr.length > 1) {
      let units = timeArr[1];
      if (timeArr.length > 2)
        console.error(
          'ERROR: ' +
            this.name +
            ' Repeat time has more than 2 units -> ' +
            repeatTime
        );
      if (timeUnits.indexOf(units.toLocaleLowerCase()) === -1) {
        console.error(
          'ERROR: ' +
            this.name +
            ' Repeat time unit is not valid -> ' +
            repeatTime
        );
      } else {
        if (['min', 'minutes'].indexOf(units.toLocaleLowerCase()) !== -1) {
          time = time * 60;
          timeHigh = timeHigh * 60;
        } else if (['hr', 'hours'].indexOf(units.toLocaleLowerCase()) !== -1) {
          time = time * 3600;
          timeHigh = timeHigh * 3600;
        }
      }
      return {
        time: time,
        timeHigh: timeHigh,
      };
    }

    return { time: time, timeHigh: timeHigh };
  }

  // constructor(parent : ProtocolItem, name: string) {
  //     this.name               = name;
  //     this.parentProtocol     = parent;
  //     this.uid                = "1234-567-890";
  //     this.depID              = "00089c2e-9f11-409a-8b37-afa9924e965c";
  //     this.instruction        = "";
  //     this.warning            = "";
  //     this.option             = {
  //         title : "1st Dose",
  //         ranges: [{
  //             rangeLow: 0,
  //             rangeHigh: globals.MAX_VALUE,
  //             basis: "1 J/kg",
  //             basisHigh: "2 J/kg",
  //             calcMax: "10 J",
  //             maxDose: "10 J"
  //         }]};
  //     this.dbElectrical       = null;
  // }

  getAmount(weight: PatientWeight): string {
    if (!this.inRange(weight)) return '';

    try {
      /* First check if it a single dose or a range */
      if (this.basisHigh === globals.MAX_VALUE) {
        /* If there is no calculation needed return the dose amount */
        if (this.calcUnit == null || this.calcUnit.length == 0)
          return `${roundToDec(this.basis, 1).toFixed(1)} ${this.basisUnit}`;
        else {
          /* First calculate the dose based on the basis */
          let dose: number = roundToDec(this.basis * weight.getWeightKg(), 1);

          /* If there is a calcMax and it is not zero, then use it to limit the dose */
          if (this.calcMax != -1.0) {
            if (this.basisUnit !== this.calcMaxUnit) {
              console.error(
                'ERROR: Basis unit is not the same as calcMax unit: ' +
                  this.basisUnit +
                  ' ' +
                  this.calcMaxUnit
              );
              return '';
            }
            dose = roundToDec(
              Math.min(dose, this.calcMax * weight.getWeightKg()),
              1
            );
          }

          /* If there is a maxDose and it is not zero, then use it to limit the dose */
          if (this.maxDose != -1.0) {
            if (this.basisUnit !== this.maxDoseUnit) {
              console.error(
                'ERROR: Basis unit is not the same as maxDose unit: ' +
                  this.basisUnit +
                  ' ' +
                  this.maxDoseUnit
              );
              return '';
            }
            dose = roundToDec(Math.min(dose, this.maxDose), 1);
          }

          /* Return the dose */
          return `${dose.toFixed(1)} ${this.basisUnit}`;
        }
      } else {
        /* Otherwise it is a range dose */
        /* If there is no calculation needed return the dose amount */
        if (!this.calcUnit || this.calcUnit.length == 0) {
          if (this.basis === this.basisHigh)
            return `${roundToDec(this.basis, 1).toFixed(1)} ${this.basisUnit}`;
          return `${roundToDec(this.basis, 1).toFixed(1)}-${roundToDec(this.basisHigh, 1).toFixed(1)} ${this.basisUnit}`;
        } else {
          /* First calculate the dose based on the basis low and high */
          let doseLow: number = roundToDec(
            this.basis * weight.getWeightKg(),
            1
          );
          let doseHigh: number = roundToDec(
            this.basisHigh * weight.getWeightKg(),
            1
          );

          /* Next check if there is a calculated max dose and check the units match, if so check to see if it is within the bounds */
          if (this.calcMax != -1.0) {
            if (this.basisUnit !== this.calcMaxUnit) {
              console.error(
                'ERROR: Basis unit is not the same as calcMax unit: ' +
                  this.basisUnit +
                  ' ' +
                  this.calcMaxUnit
              );
              return '';
            }
            doseLow = roundToDec(
              Math.min(doseLow, this.calcMax * weight.getWeightKg()),
              1
            );
            doseHigh = roundToDec(
              Math.min(doseHigh, this.calcMax * weight.getWeightKg()),
              1
            );
          }

          /* Next check if there is a fixed max dose and check the units match, if so check to see if it is within the bounds */
          if (this.maxDose != -1.0) {
            if (this.basisUnit !== this.maxDoseUnit) {
              console.error(
                'ERROR: Basis unit is not the same as maxDose unit: ' +
                  this.basisUnit +
                  ' ' +
                  this.maxDoseUnit
              );
              return '';
            }
            doseLow = roundToDec(Math.min(doseLow, this.maxDose), 1);
            doseHigh = roundToDec(Math.min(doseHigh, this.maxDose), 1);
          }

          /* Lastly, check if the values are the same, if so return a single value, otherwise return a range */
          if (doseLow == doseHigh)
            return `${doseLow.toFixed(1)} ${this.basisUnit}`;
          return `${doseLow.toFixed(1)}-${doseHigh.toFixed(1)} ${this.basisUnit}`;
        }
      }
    } catch (error) {
      console.error(
        'ERROR: Failed to get electrical -> ' +
          this.basis +
          '-' +
          this.basisHigh +
          ' ' +
          this.basisUnit
      );
    }
    return '';
  }

  getCalculatedMax(weight: PatientWeight): string {
    if (this.calcMax && this.calcMax < globals.MAX_VALUE)
      return `${roundToDec(this.calcMax * weight.getWeightKg(), 1).toFixed(1)} ${this.calcMaxUnit} (${roundToDec(this.calcMax, 1).toFixed(1)} ${this.calcMaxUnit}/${this.calcUnit})`;
    else return '';
  }

  getFixedMax(): string {
    if (this.maxDose != -1.0 && this.maxDoseUnit != null)
      return `${roundToDec(this.maxDose, 1).toFixed(1)} ${this.maxDoseUnit}`;
    else return '';
  }

  getAmountLow(weight: PatientWeight): number {
    /* First check if it is a calculated dose or a standard */
    if (this.calcUnit == null || this.calcUnit.length == 0) return this.basis;

    /* Next calculate the dose based on the basis */
    let dose = roundToDec(this.basis * weight.getWeightKg(), 1);

    /* If there is a calcMax and it is not zero, then use it to limit the dose */
    if (this.calcMax != -1.0) {
      if (this.basisUnit !== this.calcMaxUnit) {
        console.error(
          'ERROR: Basis unit is not the same as calcMax unit: ' +
            this.basisUnit +
            ' ' +
            this.calcMaxUnit
        );
        return -1;
      }
      dose = roundToDec(Math.min(dose, this.calcMax * weight.getWeightKg()), 1);
    }

    /* If there is a maxDose and it is not zero, then use it to limit the dose */
    if (this.maxDose != -1.0) {
      if (this.basisUnit !== this.maxDoseUnit) {
        console.error(
          'ERROR: Basis unit is not the same as maxDose unit: ' +
            this.basisUnit +
            ' ' +
            this.maxDoseUnit
        );
        return -1;
      }
      dose = roundToDec(Math.min(dose, this.maxDose), 1);
    }
    return dose;
  }

  getAmountHigh(weight: PatientWeight): number {
    /* First check if it is a calculated dose or a standard */
    if (this.calcUnit == null || this.calcUnit.length == 0)
      return this.basisHigh;

    /* Next calculate the dose based on the basis */
    let dose = roundToDec(this.basisHigh * weight.getWeightKg(), 1);

    /* If there is a calcMax and it is not zero, then use it to limit the dose */
    if (this.calcMax != -1.0) {
      if (this.basisUnit !== this.calcMaxUnit) {
        console.error(
          'ERROR: Basis unit is not the same as calcMax unit: ' +
            this.basisUnit +
            ' ' +
            this.calcMaxUnit
        );
        return -1;
      }
      dose = roundToDec(Math.min(dose, this.calcMax * weight.getWeightKg()), 1);
    }

    /* If there is a maxDose and it is not zero, then use it to limit the dose */
    if (this.maxDose != -1.0) {
      if (this.basisUnit !== this.maxDoseUnit) {
        console.error(
          'ERROR: Basis unit is not the same as maxDose unit: ' +
            this.basisUnit +
            ' ' +
            this.maxDoseUnit
        );
        return -1;
      }
      dose = roundToDec(Math.min(dose, this.maxDose), 1);
    }
    return dose;
  }

  getBasisString(): string {
    if (!this.calcUnit || this.calcUnit.length == 0) {
      if (this.basisHigh === globals.MAX_VALUE)
        return `${this.basis} ${this.basisUnit}`;
      return `${this.basis}-${this.basisHigh} ${this.basisUnit}`;
    } else {
      if (this.basisHigh === globals.MAX_VALUE)
        return `${this.basis} ${this.basisUnit}/${this.calcUnit}`;
      return `${this.basis}-${this.basisHigh} ${this.basisUnit}/${this.calcUnit}`;
    }
  }

  /**
   * Get the interval to use for the slider
   * @param weight The patient's weight
   * @returns The interval to use for the slider
   */
  getInterval(weight: PatientWeight): number {
    let low: number = this.getAmountLow(weight);
    let high: number = this.getAmountHigh(weight);
    if (high - low > 10) return 1;
    else if (high - low > 5) return 0.1;
    else return 0.05;
  }

  getBasisUnit(): string {
    return this.basisUnit;
  }

  inRange(weight: PatientWeight): boolean {
    return (
      this.rangeLow <= weight.getWeightKg() &&
      weight.getWeightKg() < this.rangeHigh
    );
  }

  isRangeElectrical(): boolean {
    return this.basisHigh !== globals.MAX_VALUE;
  }

  getParentProtocol(): ProtocolItem {
    return this.parentProtocol;
  }
  getParentCPR(): CPRItem | undefined {
    return this.parentCPR;
  }

  getUid(): string {
    return this.parent?.getUid();
  }

  getName(): string {
    return this.parent.getName();
  }

  getTitle(): string {
    return this.title;
  }

  equals(obj: any): boolean {
    if (obj == null) return false;
    if (!(obj instanceof ElectricalSubItem)) return false;
    let other: ElectricalSubItem = obj as ElectricalSubItem;
    return (
      this.uid === other.uid &&
      this.name === other.name &&
      this.title === other.title &&
      this.rangeLow === other.rangeLow &&
      this.rangeHigh === other.rangeHigh &&
      this.basis === other.basis &&
      this.basisHigh === other.basisHigh &&
      this.calcMax === other.calcMax &&
      this.maxDose === other.maxDose &&
      this.basisUnit === other.basisUnit &&
      this.calcUnit === other.calcUnit &&
      this.calcMaxUnit === other.calcMaxUnit &&
      this.maxDoseUnit === other.maxDoseUnit &&
      this.calcCalcMaxUnit === other.calcCalcMaxUnit
    );
  }

  /**
   * Parses the range of this subitem into the variables of this subitem.
   * @param range The ElectricalRange element to parse
   * @returns A boolean indicating whether the parsing was successful
   */
  private parseRange(range: ElectricalDose): boolean {
    if (!range.basis || range.basis === '') return false;

    let obj = this.parseBasisString(range.basis);
    this.basis = obj[0];
    this.basisHigh = obj[1];
    this.basisUnit = obj[2];
    this.calcUnit = obj[3];

    if (range.calcMax) {
      obj = this.parseBasisString(range.calcMax);
      this.calcMax = obj[0];
      this.calcMaxUnit = obj[2];
      this.calcCalcMaxUnit = obj[3];
    }

    if (range.maxDose) {
      obj = this.parseBasisString(range.maxDose);
      this.maxDose = obj[0];
      this.maxDoseUnit = obj[2];
    }

    return true;
  }

  /**
   * Parses a basis string into a basis value, basis unit, and calculation unit.
   * @param basis The basis string to parse
   * @returns An array of the basis value, basis unit, and calculation unit
   */
  private parseBasisString(basis: string): any {
    let basisVal: number = 0.0;
    let basisValHigh: number = globals.MAX_VALUE;
    let basisUnit: string = '';
    let calcUnit: string | null = null;

    try {
      let basisArr: string[] = basis.split(' ');
      if (basisArr[0].includes('-')) {
        let basisArr2: string[] = basisArr[0].split('-');
        basisVal = parseFloat(basisArr2[0]);
        basisValHigh = parseFloat(basisArr2[1]);
      } else basisVal = parseFloat(basisArr[0]);
      let unitArr: string[] = basisArr[1].split('/');
      basisUnit = unitArr[0];
      if (unitArr.length > 1) {
        calcUnit = unitArr[1];
        if (calcUnit !== 'kg')
          if (globals.debug)
            console.log(
              this.TAG,
              'ERROR: Basis unit calculation is not kg -> ' +
                calcUnit +
                ' basis: ' +
                basis
            );
      }
      return [basisVal, basisValHigh, basisUnit, calcUnit];
    } catch (e) {
      if (globals.debug)
        console.log(this.TAG, 'ERROR: Failed to parse basis -> ' + basis);
      return [basisVal, basisValHigh, basisUnit, calcUnit];
    }
  }

  toString(): string {
    return `ElectricalSubItem -> {
            uid=${this.getUid()}, 
            name=${this.getName()},  
            basis=${this.basis},
            basisHigh=${this.basisHigh},
            calcMax=${this.calcMax},
            maxDose=${this.maxDose},
            rangeLow=${this.rangeLow},
            rangeHigh=${this.rangeHigh},
            basisUnit=${this.basisUnit},
            calcUnit=${this.calcUnit},
            calcMaxUnit=${this.calcMaxUnit},
            maxDoseUnit=${this.maxDoseUnit},
            calcCalcMaxUnit=${this.calcCalcMaxUnit}
        }`;
  }
}

export default ElectricalSubItem;
