import { useMemo, useState } from 'react';
import MedicationItem from '../../../../data/model/MedicationItem';
import MedicationSubItem, {
  cloneMedicationSubItem,
} from '../../../../data/model/MedicationSubItem';
import ProtocolItem from '../../../../data/model/ProtocolItem';
import MedicationDoseSideout from '../../../components/SideOut/doseSideOut/MedicationDoseSideout';
import {
  createMedicationDoseItem,
  deleteMedicationDoseItem,
  duplicateMedicationDose,
  MedicationDoseJSON,
} from '../../../../data/functions/MedicationDB';
import { useSelector } from 'react-redux';
import {
  DatabaseResponse,
  Response,
  ResponseType,
} from '../../../../data/AmplifyDB';
import { globals, upgradeVersion } from '../../../_global/common/Utils';
import { auditMedicationDosesToOldDBStyle } from '../../../../data/AmplifyActions';
import MedicationDoseCPRSideout from '../../../components/SideOut/doseSideOut/MedicationDoseCPRSideout';
import CPRItem from '../../../../data/model/CPRItem';
import { User, ProgressStatus } from '../../../../models';
import PatientAge from '../../../_global/common/PatientAge';

interface MedicationDoseSideoutProps {
  visible: boolean;
  protocol?: ProtocolItem | undefined | null;
  dose?: MedicationSubItem;
  handleClose: () => void;
  handleSubmit: (reloadDatabase: boolean) => void;
  cpr?: CPRItem;
}
/* 09-28-23 Arul: Created Department component  for Protocol page to display the category and protocol accordion*/
const NewMedicationCPRSideout = (props: MedicationDoseSideoutProps) => {
  const { visible, dose, protocol, handleClose, handleSubmit, cpr } = props;

  const [database, setDatabase] = useState<DatabaseResponse>(
    useSelector((state: any) => state.protocol.departmentItem)
  );
  const department = database.department;
  const user: User = useSelector((state: any) => state?.user);

  const handleOnSubmitMedicationDose = async (
    parent: MedicationItem,
    data: any,
    previousDose: MedicationSubItem | undefined,
    parentProtocol: ProtocolItem | undefined
  ) => {
    if (globals.debug)
      console.log('Submitting Dose', data, previousDose, parentProtocol);

    // let protocolID =
    //   prot.status === ProgressStatus.DRAFT && prot.activeID !== null
    //     ? prot.activeID
    //     : prot.uid;

    // if (!protocolID) {
    //   console.error('Failed to find protocol ID');
    //   return;
    // }

    /* Create a new MedicationDose for the medicaiton */
    let prevDose = previousDose as MedicationSubItem;
    const isOwner = prevDose ? department.id === prevDose.departmentID : true;
    let index = prevDose ? prevDose.index : cpr ? cpr.epiDoses.length : 0;
    if (department.parentDep?.parentDep) index += 10000;
    else if (department.parentDep) index += 1000;

    let newMedDose: MedicationDoseJSON = {
      departmentID: department.id,
      medication: parent,
      cpr: cpr,
      rangeLow: data.rangeLow ? Number(data.rangeLow) : 0,
      rangeHigh: data.rangeHigh ? Number(data.rangeHigh) : globals.MAX_VALUE,
      createdBy: user.id,
      basis: data.basis,
      route: data.routes,
      title: data.warning ? undefined : data.title,
      nemsisRoutes: data.nemsisRoutes,
      warning: data.warning,
      instruction: data.instruction,
      note: data.note,
      maxDose: data.maxDose,
      minDose: data.minDose,
      maxTotalDose: data.maxTotalDose,
      calcMax: data.calcMax,
      calcMin: data.calcMin,
      index: index,
      ageLow: data.ageLow ? (data.ageLow as PatientAge).ageValue : 0,
      ageHigh: data.ageHigh
        ? (data.ageHigh as PatientAge).ageValue
        : globals.MAX_VALUE,
      ageGroup: data.ageGroup,
      repeatTime: data.repeatTime,

      activeID: !isOwner
        ? undefined
        : prevDose == null
          ? null
          : prevDose.status === ProgressStatus.ACTIVE
            ? prevDose.uid
            : prevDose.activeID,

      overrideID: isOwner ? null : prevDose?.uid,
      status: ProgressStatus.DRAFT,
      version:
        prevDose == null
          ? 'v1.0.0'
          : prevDose.status === ProgressStatus.ACTIVE
            ? upgradeVersion(prevDose.version)
            : prevDose.version,
      createdAt: prevDose?.createdAt ? prevDose.createdAt : new Date(),
    };

    console.log('New Medication Dose', newMedDose);

    createMedicationDoseItem(newMedDose, isOwner ? prevDose : undefined).then(
      (response: Response) => {
        if (response.type === ResponseType.Success) {
          let newMedSubItem = response.data as MedicationSubItem;
          if (globals.debug)
            console.log(
              'Successfully created CPR Medication dose',
              newMedSubItem
            );
          //   newMedSubItem.activeItem = prevDose ? prevDose : null;
          //   let allSubMeds = prevDose
          //     ? allSubMedicationItems.filter(
          //         (item: MedicationSubItem) => item !== prevDose
          //       )
          //     : allSubMedicationItems;
          //   setAllSubMedicationItems([...allSubMeds, newMedSubItem]);
          handleSubmit(true);
          // reloadDatabase(parmMedication.uid);
        }
      }
    );
  };

  const handleRemoveMedicationDose = async (
    dose: MedicationSubItem,
    type: 'block' | 'restore' | 'delete'
  ) => {
    if (type === 'block') {
      let clone = cloneMedicationSubItem(dose);
      clone.status = ProgressStatus.DEACTIVATED;
      clone.departmentID = department.id;
      clone.modifiedBy = user;
      clone.overrideID =
        dose.status.includes('DRAFT') && dose.activeID
          ? dose.activeID
          : dose.uid;
      clone.activeID = undefined;

      let resp = await createMedicationDoseItem(clone, undefined);
      if (resp.type === ResponseType.Success) {
        console.log('Successfully blocked dose', resp);
        let newDose: MedicationSubItem = resp.data;
        // newDose.overrideItem = dose;
        // let allDoses = [...new Set([...allSubMedicationItems, ...subMeds])];
        // allDoses = allDoses.filter((item: MedicationSubItem) => item !== dose);
        // setAllSubMedicationItems([...allDoses, newDose]);
        handleSubmit(true);
        // reloadDatabase(parmMedication.uid);
      }
    } else if (
      /* Use Cases: Created a new dose and deleted it, or deleted an existing dose */
      dose.status === ProgressStatus.DEACTIVATED ||
      dose.status === ProgressStatus.DRAFT
    ) {
      let resp = await deleteMedicationDoseItem(dose, false);
      if (resp.type === ResponseType.Success) {
        if (globals.debug) console.log('Deleted Dose', resp);
        let deletedDose: MedicationSubItem = resp.data;
        auditMedicationDosesToOldDBStyle([deletedDose])
          .then((response) => {
            if (response.type === ResponseType.Success) {
              console.log('Successfully audited doses', response.data);
            }
          })
          .catch((error) => {
            console.error('Failed to audit doses', error);
          });
        handleSubmit(true);
        // let deletedDose: MedicationSubItem = resp.data;

        // let allDoses = allSubMedicationItems.filter(
        //   (item: MedicationSubItem) => item !== dose
        // );
        // if (
        //   dose.status === ProgressStatus.DEACTIVATED &&
        //   deletedDose.overrideItem
        // )
        //   allDoses.push(deletedDose.overrideItem);
        // setAllSubMedicationItems([...allDoses]);
        // reloadDatabase(parmMedication.uid);
      }
    } else {
      let resp = await deleteMedicationDoseItem(dose, true);
      if (resp.type === ResponseType.Success) {
        let deletedDose: MedicationSubItem = resp.data;
        if (globals.debug) console.log('Deleted Dose', deletedDose.name);
        auditMedicationDosesToOldDBStyle([deletedDose])
          .then((response) => {
            if (response.type === ResponseType.Success) {
              console.log('Successfully audited doses', response.data);
            }
          })
          .catch((error) => {
            console.error('Failed to audit doses', error);
          });
        handleSubmit(true);
        // let allDoses = allSubMedicationItems.filter(
        //   (item: MedicationSubItem) => item !== dose
        // );
        // setAllSubMedicationItems([...allDoses]);
        // reloadDatabase(parmMedication.uid);
      }
    }
  };

  return (
    <MedicationDoseCPRSideout
      visible={visible}
      setVisible={handleClose}
      doseIndex={dose?.index ?? 0}
      protocol={protocol != null ? protocol : null}
      dose={dose}
      editDose={dose != null}
      onSubmit={handleOnSubmitMedicationDose}
      onSetNewRoutes={(newRoutes: string[]) => {
        // formik.setFieldValue('routes', newRoutes);
      }}
      onRemove={handleRemoveMedicationDose}
      showDeviceMockup={false}
    />
  );
};

export default NewMedicationCPRSideout;
