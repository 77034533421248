// Author: Guruprasad (01-18-2024)
// Modified the Equipment page to be a single page for both edit and create equipment purpose
// and integrated new DB functions.
import { useFormik } from 'formik';
import * as _ from 'lodash';
import { InputText } from 'primereact/inputtext';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { handleGetDepartment } from '../../../../../store/actions';
import ConfirmModal from '../../../../components/Modal/ConfirmModal';
import { HiPlus } from 'react-icons/hi';
import { ViewportList } from 'react-viewport-list';
import EquipmentItem from '../../../../../data/model/EquipmentItem';
import {
  EquipmentDB,
  deleteEquipmentItem,
} from '../../../../../data/functions/EquipmentDB';
import ProtocolDoseSideout from '../../../../components/SideOut/doseSideOut/ProtocolDoseSideout';
import DraftSaveModal from '../../../../components/Modal/DraftSaveModal';
import {
  DatabaseResponse,
  ResponseType,
  Response,
  loadDatabase,
} from '../../../../../data/AmplifyDB';
import ProtocolItem from '../../../../../data/model/ProtocolItem';
import { createEquipment } from '../../../../../data/functions/EquipmentDB';
import { ProgressStatus, User } from '../../../../../models';
import { ProgressStatus as Progress } from '../../../../../API';
import { EquipmentOption } from '../../../../../models';
import EquipmentDose from './EquipmentDose';
import {
  generateID,
  getFormattedDate,
  globals,
  isObjectEqual,
  upgradeVersion,
} from '../../../../_global/common/Utils';
import {
  isDraftCreated,
  removeCurrentDraftUpdates,
} from '../../../../../data/AmplifyVersion';
import ProtocolHeader from '../../ProtocolHeader';
import { UserType } from '../../../../../models';
import EquipmentAddProtocolSideout from './EquipmentAddProtocolSideout';
import { BiCopy, BiSolidCopy } from 'react-icons/bi';
import { validatePointerID } from '../../../../../data/functions/ProtocolDB';
import { FaTimes } from 'react-icons/fa';
import { FaChevronRight } from 'react-icons/fa6';

interface EquipmentNewPageProps {
  stateData?: any;
}

/* 10-25-23  Created Equipment New Page component for Equipment layout */
const EquipmentNewHMPage: React.FC<EquipmentNewPageProps> = ({ stateData }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const protocol: ProtocolItem = stateData.selectedProtocol;

  const [database, setDatabase] = useState<DatabaseResponse>(
    useSelector((state: any) => state?.protocol?.departmentItem)
  );

  const [isWarningModal, setIsWarningModal] = useState(false);
  const [editDose, setEditDose] = useState(false);
  const [editCanelled, setEditCancelled] = useState(false);
  const [parmEquipment, setParmEquipment] = useState<EquipmentItem>(
    stateData.value
  );
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const user: User = useSelector((state: any) => state?.user);
  const [isCancelWarningModal, setIsCancelWarningModal] = useState(false);
  const [isConfirmModal, setIsConfirmModal] = useState(false);
  const [selectedData, setSelectedData] = useState<any>(null);
  const [doseIndex, setDoseIndex] = useState<number>(0);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const department = database.department;
  const [pairedProtocols, setPairedProtocols] = useState<ProtocolItem[]>(
    parmEquipment ? parmEquipment.parents : []
  );
  const [isAddProtocols, setIsAddProtocols] = useState(false);
  const [isCopied, setIsCopied] = useState<string | null>(null);
  const [modifiedBy, setModifiedBy] = useState<User | null>(null);

  const formik = useFormik({
    enableReinitialize: isEditMode ? true : false,
    initialValues: {
      name: parmEquipment ? parmEquipment.name : '',
      // protocols: "",
      departmentID: department.id,
      title: parmEquipment ? parmEquipment.title : '',
      warning: parmEquipment ? parmEquipment.warning : '',
      instruction: parmEquipment ? parmEquipment.instruction : '',
      note: parmEquipment ? parmEquipment.note : '',
      optionItems: parmEquipment ? parmEquipment.options : [],
    },
    validationSchema: Yup.object({
      name: Yup.string().required('The name is a required field.'),
      // protocols: Yup.string(),
      departmentID: Yup.string(),
      title: Yup.string(),
      warning: Yup.string(),
      instruction: Yup.string(),
      note: Yup.string(),
      optionItems: Yup.array(),
    }),
    onSubmit: async (values) => {
      if (isSaveActive) {
        if (formik.isValid && formik.dirty) {
          const updatedEquipment: EquipmentDB = {
            name: values.name,
            departmentID: department.id,
            title: values.title,
            warning: values.warning,
            instruction: values.instruction,
            note: values.note,
            optionItems: values.optionItems,
            status: ProgressStatus.DRAFT,
            taggedProtocols: pairedProtocols,
            activeID:
              parmEquipment.status === ProgressStatus.ACTIVE
                ? parmEquipment.uid
                : parmEquipment.activeID,
            version:
              parmEquipment.status === ProgressStatus.ACTIVE
                ? upgradeVersion(
                    parmEquipment.version ? parmEquipment.version : 'v1.0.0'
                  )
                : parmEquipment.version
                  ? parmEquipment.version
                  : 'v1.0.0',
            modifiedBy: user.id,
            createdBy:
              parmEquipment.model && parmEquipment.model.createdBy
                ? parmEquipment.model.createdBy
                : '',
          };
          let results: Response = await createEquipment(
            updatedEquipment,
            parmEquipment
          );
          if (results.type === ResponseType.Success) {
            if (globals.debug)
              console.log('Successfully edited Equipment', results.data);
            let updatedEquipment: EquipmentItem = results.data;
            updateEquipment(updatedEquipment);
          }
          formik.resetForm();
        } else if (parmEquipment && pairedProtocols.length > 0) {
          /* The protocols have been updated, so update the database */
          for (let i = 0; i < pairedProtocols.length; i++) {
            let protocol = pairedProtocols[i];

            /* ACTIVE - pass ID, DRAFT and activeID is null (HAS NEVER BEEN PUBLISHED) - pass UID, DRAFT with previous publish - pass activeID */
            let equipID =
              parmEquipment.status === ProgressStatus.ACTIVE ||
              (parmEquipment.status === ProgressStatus.DRAFT &&
                parmEquipment.activeID == null)
                ? parmEquipment.uid
                : parmEquipment.activeID;
            if (equipID == null) {
              return {
                type: ResponseType.Failure,
                data:
                  'The equipment does not have a proper version ID ' +
                  parmEquipment.uid +
                  ' ' +
                  parmEquipment.activeID +
                  ' ' +
                  parmEquipment.status,
              };
            }
            let result = await validatePointerID(
              protocol,
              equipID,
              user.id,
              'Equipment'
            );
            if (result.type === ResponseType.Failure) {
              return {
                type: ResponseType.Failure,
                data: result.data,
              };
            }
          }

          parmEquipment.parents = pairedProtocols;
          formik.resetForm();
          reloadDatabase();
        }
      }
    },
  });

  useEffect(() => {
    if (!parmEquipment) return;
    let id = parmEquipment.model?.modifiedBy;
    if (id == null) id = parmEquipment.model?.createdBy;
    if (id) {
      let user = database.users.find((u: User) => u.id === id);
      setModifiedBy(user ? user : null);
      if (user) {
        parmEquipment.modifiedBy = user;
      }
    } else {
      setModifiedBy(null);
    }
  }, [database, parmEquipment]);

  const updateEquipment = async (updatedEquipment: EquipmentItem) => {
    reloadUpdatedDatabase();
    formik.setValues({
      ...formik.values,
      name: updatedEquipment?.name ? updatedEquipment?.name : '',
      optionItems: updatedEquipment?.options ? updatedEquipment?.options : [],
      title: updatedEquipment?.title ? updatedEquipment?.title : '',
      warning: updatedEquipment?.warning ? updatedEquipment?.warning : '',
      instruction: updatedEquipment?.instruction
        ? updatedEquipment?.instruction
        : '',
      note: updatedEquipment?.note ? updatedEquipment?.note : '',
    });
  };

  const reloadUpdatedDatabase = async (): Promise<boolean> => {
    const resp: Response = await loadDatabase(database);
    if (resp.type === ResponseType.Success) {
      const newDB: DatabaseResponse = resp.data;
      setDatabase(newDB);
      dispatch<any>(handleGetDepartment(newDB));

      let updateEquipment: EquipmentItem | null = null;
      let equipmentID =
        parmEquipment.status === ProgressStatus.ACTIVE
          ? parmEquipment.uid
          : parmEquipment.activeID;
      for (let i = 0; i < newDB.equipment.length; i++) {
        if (newDB.equipment[i].activeID === equipmentID) {
          updateEquipment = newDB.equipment[i];
          break;
        }
      }

      if (updateEquipment) {
        setParmEquipment(updateEquipment);
      } else {
        if (globals.debug) console.log('Failed to find updated Equipment');
      }
      return true;
    } else {
      console.error('ERROR LOADING DATABASE', resp.data);
      return false;
    }
  };

  // 	/*10/30/2023 Function to handle cancel in header*/
  const handleCancel = () => {
    if (formik.dirty && !isCancelWarningModal) {
      setIsCancelWarningModal(true);
    } else {
      formik.resetForm();
      setEditCancelled(true);
      setIsCancelWarningModal(false);
      setPairedProtocols(parmEquipment ? parmEquipment.parents : []);
    }
  };

  const reloadDatabase = async (): Promise<boolean> => {
    /* 1-19-24 Guru:  Updated the current data to the database change and keep the current state */
    const resp: Response = await loadDatabase(database);
    if (resp.type === ResponseType.Success) {
      const newDB: DatabaseResponse = resp.data;
      setDatabase(newDB);
      dispatch<any>(handleGetDepartment(newDB));
      return true;
    } else {
      console.error('ERROR LOADING DATABASE', resp.data);
      return false;
    }
  };

  /*
  1/19/2024: Guru
  Function to handle creating of Equipment
  */
  const handleCreateEquipment = async () => {
    try {
      if (formik.isValid) {
        const newEquipment: EquipmentDB = {
          name: formik.values.name,
          title: formik.values.title,
          warning: formik.values.warning,
          instruction: formik.values.instruction,
          note: formik.values.note,
          departmentID: department.id,
          optionItems: formik.values.optionItems
            ? formik.values.optionItems
            : [],
          status: Progress.DRAFT,
          createdBy: user.id,
          activeID: null,
          version: 'v1.0.0',
        };
        let results: Response = await createEquipment(newEquipment);
        if (results.type === ResponseType.Success) {
          if (globals.debug)
            console.log('Successfully created Equipment', results.data);
          let isLoaded: boolean = await reloadDatabase();
          if (isLoaded) {
            if (globals.debug) console.log('Successfully reloaded database');
            const equipment: EquipmentItem = results.data;
            if (protocol) {
              navigate(`/${protocol.nickname}/protocol-detail`, {
                state: { selectedProtocol: protocol, editMode: true },
              });
            } else
              navigate(`/database/list-equipment`, {
                state: {
                  department: department,
                  data: database.equipment,
                  database: database,
                },
              });
          }
        }
      } else {
        /* If the form is invalid, then show the error message */
        if (globals.debug)
          console.log('Form is invalid, showing error message');
      }
    } catch (error) {
      if (globals.debug) console.log('error', error);
    }
  };

  const onAddDoseClick = () => {
    setEditDose(false);
    setSelectedData(null);
    setSidebarVisible(!sidebarVisible);
  };

  const handleEquipmentOptionCreated = (
    dose: any,
    prevDose: EquipmentOption
  ) => {
    let newEquipmentOption: EquipmentOption = {
      id: prevDose && prevDose.id ? prevDose.id : generateID(),
      amnt: dose.valueLow,
      amntHigh: dose.valueHigh,
      rangeLow: dose.rangeLow ? Number(dose.rangeLow) : 0,
      rangeHigh: dose.rangeHigh ? Number(dose.rangeHigh) : globals.MAX_VALUE,
      index:
        prevDose && prevDose.index
          ? prevDose.index
          : formik.values.optionItems.length,
    };
    if (!prevDose || formik.values.optionItems.length === 0) {
      let newOptions = [...formik.values.optionItems, newEquipmentOption];
      newOptions.sort((a: EquipmentOption, b: EquipmentOption) => {
        return a.rangeLow - b.rangeLow;
      });
      formik.setFieldValue('optionItems', newOptions);
    } else if (prevDose) {
      /* Filter out the previous dose and add the new dose */
      let updatedOptionItems: EquipmentOption[] =
        formik.values.optionItems.filter(
          (item: any) => !isObjectEqual(prevDose, item)
        );
      updatedOptionItems.push(newEquipmentOption);
      updatedOptionItems.sort((a: EquipmentOption, b: EquipmentOption) => {
        return a.rangeLow - b.rangeLow;
      });
      formik.setFieldValue('optionItems', updatedOptionItems);
    } else {
      console.error(
        'Unexpected case: prevDose is not provided and also optionItems is not empty.'
      );
    }
  };

  // Author: Guruprasad Venkatraman (01-23-2024)
  // Remvoing the dose from the formik state and updating the formik state
  const handleRemoveEquipment = (dose: any) => {
    if (globals.debug)
      console.log('Removing dose', dose, formik.values.optionItems);
    const updatedOptionItems = formik.values.optionItems.filter(
      (item: any) => !isObjectEqual(dose, item)
    );
    if (globals.debug) console.log('updatedOptionItems', updatedOptionItems);
    // Set the filtered items back to the formik state
    formik.setFieldValue('optionItems', updatedOptionItems);
  };

  const handleCloseModal = (e: any) => {
    setSidebarVisible(false);
    setSelectedData(null);
  };

  useEffect(() => {
    setIsEditMode(stateData.editType === 'edit');
  }, [stateData.editType]);

  const handleBack = () => {
    /* If the form is dirty, then show the warning modal */
    if (formik.dirty && !isWarningModal) {
      setIsWarningModal(true);
    } else if (protocol) {
      navigate(`/${protocol.nickname}/protocol-detail`, {
        state: { selectedProtocol: protocol, editMode: true },
      });
    } else {
      navigate(`/database/list-equipment`, {
        state: {
          department: department,
          data: database.equipment,
          database: database,
        },
      });
    }
  };

  const handleMakeNew = async () => {
    let response = await removeCurrentDraftUpdates(database);
    if (response.type === ResponseType.Success) {
      if (response.data.length > 0) {
        if (globals.debug)
          console.log('Successfully removed current draft updates', response);
        setIsConfirmModal(false);
        setEditCancelled(false);
        setIsOpen(false);
        setIsEditMode(true);
      }
    } else {
      if (globals.debug)
        console.log('Failed to remove current draft updates', response);
    }
  };

  const handleCheckIsDraft = useCallback(async (): Promise<boolean> => {
    if (isEditMode) return false;
    let response: Response = await isDraftCreated(database.department);
    if (response.type === ResponseType.Success) {
      let isDraft = response.data;
      if (isDraft) setIsOpen(true);
      return isDraft;
    } else {
      if (globals.debug)
        console.log('Failed to check if draft exists', response);
    }
    return false;
  }, [database.department, isEditMode]);

  const handleDelete = () => {
    setIsDelete(true);
  };

  const handleConfirmDeleteItem = async () => {
    const isDraft = parmEquipment.status === ProgressStatus.DRAFT;
    let response = await deleteEquipmentItem(parmEquipment, !isDraft);
    if (response.type === ResponseType.Success) {
      handleBack();
    } else {
      console.error('Failed to delete infusion', response.data);
    }
  };

  const isSaveActive = useMemo(() => {
    let change = false;
    if (formik.isValid) return true;
    // else if (parmEquipment) {
    //   /* Check if there has been a protocol change */
    //   if (pairedProtocols.length !== parmEquipment.parents.length) {
    //     change = true;
    //   } else {
    //     for (let i = 0; i < pairedProtocols.length; i++) {
    //       let find = parmEquipment.parents.find(
    //         (item: ProtocolItem) => item.uid === pairedProtocols[i].uid
    //       );
    //       if (!find) {
    //         change = true;
    //         break;
    //       }
    //     }
    //   }
    // }

    // if (change) return true;
    return false;
  }, [formik.dirty, formik.isValid, pairedProtocols, parmEquipment]);

  const handleCopy = (text: string, event: any) => {
    event.stopPropagation(); // Prevents the click event from bubbling up
    navigator.clipboard.writeText(text);

    setIsCopied(text);
    setTimeout(() => setIsCopied(null), 2000); // show a "copied!" message for 2 seconds
  };

  const handleRemoveProtoocol = (item: ProtocolItem, e: any) => {
    e.stopPropagation();
    let updatedProtocols = pairedProtocols.filter(
      (protocol: ProtocolItem) => protocol.uid !== item.uid
    );
    setPairedProtocols(updatedProtocols);
  };

  const handleProtocolSelected = (protocol: ProtocolItem) => {
    const state = {
      selectedProtocol: protocol,
      editMode: isEditMode,
      backPage: 'equipment',
      search: { searchQuery: '', filters: [], categoriesFilter: [] },
    };
    const formattedString = protocol.nickname.replace(/[\s\/]/g, '-');
    navigate(`/${formattedString}/protocol-detail`, { state });
  };
  return (
    <div className="screen-container">
      {isOpen && (
        <DraftSaveModal
          database={database}
          isVisible={isOpen}
          handleClose={() => {
            setIsOpen(false);
          }}
          handleContinue={() => {
            setIsEditMode(true);
            setEditCancelled(false);
            setIsOpen(false);
          }}
          handleNew={() => {
            setIsConfirmModal(true);
          }}
        />
      )}
      {isAddProtocols && (
        <EquipmentAddProtocolSideout
          handleClose={() => {
            setIsAddProtocols(false);
          }}
          handleAdd={(items: ProtocolItem[]) => {
            setIsAddProtocols(false);
            setPairedProtocols(items);
          }}
          isVisible={isAddProtocols}
          selectedItems={pairedProtocols}
        />
      )}
      {isConfirmModal && (
        <ConfirmModal
          isVisible={isConfirmModal}
          title="Make New Draft?"
          handleClose={() => {
            setIsConfirmModal(false);
          }}
          handleSubmit={handleMakeNew}
          isDeleteBtn={true}
          primaryBtnName="Cancel"
          secondaryBtnName="Make New"
          primaryDescription="Are you sure you would like to remove the previous draft and override it and create a new one? THIS IS NOT REVERSIBLE."
        />
      )}

      {isDelete && (
        <ConfirmModal
          isVisible={isDelete}
          title={`Delete Equipment?`}
          handleClose={() => {
            setIsDelete(false);
          }}
          handleSubmit={handleConfirmDeleteItem}
          isDeleteBtn={true}
          primaryBtnName="Cancel"
          secondaryBtnName="Delete"
          primaryDescription={`Are you sure you would like to delete ${parmEquipment.name}?`}
        />
      )}

      {isWarningModal && (
        <ConfirmModal
          isVisible={isWarningModal}
          title="Abandon Changes?"
          handleClose={() => {
            setIsWarningModal(false);
          }}
          handleSubmit={handleBack}
          isDeleteBtn={true}
          primaryBtnName="Cancel"
          secondaryBtnName="Abandon"
          primaryDescription={`Changes were made to this Equipment.  Click cancel to return to Equipment details.  To continue without saving changes, select Abandon Changes.`}
        />
      )}
      {isCancelWarningModal && (
        <ConfirmModal
          isVisible={isCancelWarningModal}
          title="Abandon Changes?"
          handleClose={() => {
            setIsCancelWarningModal(false);
          }}
          handleSubmit={handleCancel}
          isDeleteBtn={true}
          primaryBtnName="Cancel"
          secondaryBtnName="Abandon"
          primaryDescription={`Changes were made to this Equipment.  Click cancel to return to Equipment details.  To cancel without saving changes, select Abandon.`}
        />
      )}
      {sidebarVisible && (
        <ProtocolDoseSideout
          type="Equipment"
          doseIndex={doseIndex}
          parentModel={parmEquipment && isEditMode ? parmEquipment : null}
          protocol={null}
          dose={selectedData ? selectedData : undefined}
          visible={sidebarVisible}
          editDose={editDose}
          setVisible={handleCloseModal}
          onSubmit={handleEquipmentOptionCreated}
          onRemove={handleRemoveEquipment}
        />
      )}
      <ProtocolHeader
        name={'Create from HM Equipment'}
        isBackButton={true}
        type={'protocol'}
        status={parmEquipment ? parmEquipment.status : undefined}
        page={protocol ? protocol.name : 'Equipment'}
        description={
          'Create a new Equipment from Hinckley Medicals Equipment databse.'
        }
        protocolDetail={protocol}
        isCreateActive={isSaveActive}
        isSaveActive={isSaveActive}
        rightSideBtn={isEditMode ? 'save' : 'edit'}
        isCreateButton={isEditMode ? false : true}
        isAddButton={isEditMode}
        handleAdd={() => {
          setIsAddProtocols(true);
        }}
        customDescription={
          user.type === 'ADMIN' && parmEquipment ? (
            <h6
              className={`headerTextLight`}
              style={{
                fontWeight: '400',
                color: '#616161',
                marginRight: '2rem',
              }}
            >
              ID:
              {parmEquipment?.uid}
              <span>
                {isCopied && isCopied === parmEquipment?.uid ? (
                  <BiSolidCopy
                    color={'#00534C'}
                    size="1rem"
                    className="copy_icon"
                  />
                ) : (
                  <BiCopy
                    size="1rem"
                    className="copy_icon"
                    onClick={(e) => handleCopy(parmEquipment?.uid ?? '', e)}
                  />
                )}
              </span>
            </h6>
          ) : undefined
        }
        // isVersionButton={true}
        isDeleteButton={isEditMode}
        handleCreate={handleCreateEquipment}
        {...(isEditMode ? { isSaveButton: !editCanelled } : {})}
        {...(editCanelled ? { isEditButton: true } : {})}
        isDotButton={isEditMode ? true : false}
        handleCancel={handleBack}
        handleCancelEdit={handleCancel}
        handleEdit={() => {
          handleCheckIsDraft().then((isDraft: boolean) => {
            if (!isDraft || !isEditMode) {
              setEditCancelled(false);
            }
          });
        }}
        handleDelete={handleDelete}
        handleSave={() => formik.submitForm()}
        // isVersionButton={isEditMode}
      />
      <div className="ketamineContent">
        <div className="KetamineGeneral">
          <h5 className="ketmine-header-text" data-testid="equipment-header">
            {isEditMode ? 'Equipment Information' : 'Required Information'}
          </h5>
          {parmEquipment && (
            <div className="input-container roundBorder">
              <div
                style={{ display: 'flex', marginTop: '5px' }}
                className="ketamine-general-label"
              >
                <div style={{ marginRight: '10px' }}>Modified By:</div>
                <div style={{ fontWeight: '500' }}>
                  {modifiedBy
                    ? modifiedBy.firstName + ' ' + modifiedBy.lastName
                    : 'Hinckley Medical'}
                </div>
              </div>
              {parmEquipment.model?.updatedAt && (
                <div
                  style={{ display: 'flex' }}
                  className="ketamine-general-label"
                >
                  <div style={{ marginRight: '10px' }}>Last Updated:</div>
                  <div style={{ fontWeight: '500' }}>
                    {getFormattedDate(parmEquipment.model.updatedAt, true)}
                  </div>
                </div>
              )}
              <div
                style={{ display: 'flex' }}
                className="ketamine-general-label"
              >
                <div style={{ marginRight: '10px' }}>Version:</div>
                <div style={{ fontWeight: '500' }}>{parmEquipment.version}</div>
              </div>
              {parmEquipment.parents && (
                <div
                  style={{ display: 'flex' }}
                  className="ketamine-general-label"
                >
                  <div style={{ marginRight: '10px' }}>Paired Protocols:</div>
                  <div style={{ fontWeight: '500' }}>
                    {parmEquipment.parents.length}
                  </div>
                </div>
              )}
              {parmEquipment.activeID && (
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div
                    style={{ display: 'flex' }}
                    className="ketamine-general-label"
                  >
                    <div style={{ marginRight: '10px' }}>Active ID:</div>
                    <div style={{ fontWeight: '500' }}>
                      {parmEquipment.activeID}
                    </div>

                    <span>
                      {isCopied && isCopied === parmEquipment.activeID ? (
                        <BiSolidCopy
                          color={'#00534C'}
                          size="1rem"
                          className="copy_icon"
                        />
                      ) : (
                        <BiCopy
                          size="1rem"
                          className="copy_icon"
                          onClick={(e) =>
                            handleCopy(parmEquipment.activeID ?? '', e)
                          }
                        />
                      )}
                    </span>
                  </div>
                </div>
              )}
            </div>
          )}
          <label htmlFor="Name" className="ketamine-general-label">
            Name <span className="required-field">*</span>
          </label>
          <div className="input-container">
            <InputText
              type="text"
              className="form-control-general"
              id="name"
              name="name"
              data-testid="name"
              value={formik.values.name}
              onChange={(e: any) => {
                formik.setFieldValue('name', e.target.value);
              }}
              onBlur={formik.handleBlur}
              {...(isEditMode ? { disabled: editCanelled } : {})}
            />
            <div className="input-border"></div>
          </div>
          {formik.touched.name && formik.errors.name ? (
            <span className="errorText">{formik.errors.name}</span>
          ) : null}
          <label htmlFor="Title" className="ketamine-general-label">
            Title
          </label>
          <div className="input-container">
            <InputText
              type="text"
              className="form-control-general"
              id="title"
              name="title"
              data-testid="title"
              value={formik.values.title}
              onChange={(e: any) => {
                formik.setFieldValue('title', e.target.value);
              }}
              {...(isEditMode ? { disabled: editCanelled } : {})}
            />
            <div className="input-border"></div>
          </div>
          <label htmlFor="Warning" className="ketamine-general-label">
            Warning
          </label>
          <div className="input-container">
            <InputText
              type="text"
              className="form-control-general"
              id="warning"
              name="warning"
              required={true}
              data-testid="warning"
              value={formik.values.warning}
              onChange={(e: any) => {
                formik.setFieldValue('warning', e.target.value);
              }}
              {...(isEditMode ? { disabled: editCanelled } : {})}
            />
            <div className="input-border"></div>
          </div>
          <label htmlFor="Instruction" className="ketamine-general-label">
            Instruction
          </label>
          <div className="input-container">
            <InputText
              type="text"
              className="form-control-general"
              id="instruction"
              name="instruction"
              required={true}
              data-testid="instruction"
              value={formik.values.instruction}
              onChange={(e: any) => {
                formik.setFieldValue('instruction', e.target.value);
              }}
              {...(isEditMode ? { disabled: editCanelled } : {})}
            />
            <div className="input-border"></div>
          </div>
          <label htmlFor="Note" className="ketamine-general-label">
            Note
          </label>
          <div className="input-container">
            <InputText
              type="text"
              className="form-control-general"
              id="note"
              name="note"
              required={true}
              data-testid="note"
              value={formik.values.note}
              onChange={(e: any) => {
                formik.setFieldValue('note', e.target.value);
              }}
              {...(isEditMode ? { disabled: editCanelled } : {})}
            />
            <div className="input-border"></div>
          </div>
          {pairedProtocols && pairedProtocols.length > 0 && (
            <div>
              <label
                htmlFor="Paired Protocols"
                className="ketamine-general-label"
              >
                Paired Protocols
              </label>
              <div
                style={{
                  overflow: 'auto',
                  border: '1px solid #ccc',
                  borderRadius: '5px',
                  marginTop: '5px',
                }}
              >
                <ViewportList items={pairedProtocols}>
                  {(item: ProtocolItem, index: number) => {
                    return (
                      <div
                        key={index}
                        className=""
                        style={{
                          borderBottom:
                            index === pairedProtocols.length - 1
                              ? ''
                              : '1px solid #ccc',
                        }}
                        onClick={(e: any) => {
                          handleProtocolSelected(item);
                        }}
                      >
                        <div
                          key={index}
                          style={{
                            display: 'grid',
                            gridTemplateColumns: '1fr 16fr 1fr',
                            padding: '6px 10px',
                            alignItems: 'center',
                          }}
                          className="listItem hoverable"
                        >
                          <FaTimes
                            className={
                              isEditMode
                                ? 'icon-cancel'
                                : 'icon-cancel-disabled'
                            }
                            size={16}
                            onClick={(e) => {
                              if (isEditMode) handleRemoveProtoocol(item, e);
                            }}
                          />
                          <div className="contentText">{item.name}</div>
                          <FaChevronRight size={12} />
                        </div>
                      </div>
                    );
                  }}
                </ViewportList>
              </div>
            </div>
          )}
        </div>
        <div className="KetamineGeneral">
          <h5 className="ketmine-header-text">Equipment Options</h5>
          {!editCanelled && (
            <div style={{ marginTop: '5px' }}>
              <span className="ketamine-general-label">Option</span>
              <div
                onClick={onAddDoseClick}
                className={`contentBorder protocolCalculationPad contentHeading newProtocolBorder  cursorPointer newRouteButton`}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <span
                  style={{
                    textDecoration: 'underLine',
                    textUnderlinePosition: 'under',
                  }}
                >
                  <HiPlus className="text-icon " /> Add Option
                </span>
              </div>
            </div>
          )}
          <ViewportList items={formik.values.optionItems}>
            {(item: EquipmentOption, index: number) => {
              return (
                <div
                  key={index}
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    if (!editCanelled) {
                      setSelectedData(item);
                      setDoseIndex(index);
                      setEditDose(true);
                      setSidebarVisible(true);
                    }
                  }}
                >
                  <EquipmentDose dose={item} doseIndex={index} />
                </div>
              );
            }}
          </ViewportList>
        </div>
      </div>
    </div>
  );
};

export default EquipmentNewHMPage;
