/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createDepartment = /* GraphQL */ `mutation CreateDepartment(
  $input: CreateDepartmentInput!
  $condition: ModelDepartmentConditionInput
) {
  createDepartment(input: $input, condition: $condition) {
    id
    Categories {
      items {
        id
        name
        departmentID
        department {
          id
          name
          location
          protocolVersions
          shiftTypes
          cognitoID
          logoID
          userID
          neonateCutoff
          pediatricCutoff
          calculators
          softwarePlan
          subDepIDs
          parentDepID
          indexedParentDepID
          activeStatus
          agencyNumEMS
          stateIdEMS
          gnisCodeEMS
          uniqueCode
          uniquePublicURL
          hashedPin
          saltedPin
          keychainID
          isPublic
          infusionCalculation
          isPublicSignup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          departmentRequireSyncId
          __typename
        }
        index
        pairedDepIDs
        parentCategoryID
        activeID
        status
        version
        createdBy
        modifiedBy
        isPublic
        isRestrictive
        color
        tags
        overrideID
        keychainID
        Protocols {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        departmentCategoriesId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    OneWeights {
      items {
        id
        name
        serialNumber
        projectNumber
        lastCalibration
        type
        departmentID
        department {
          id
          name
          location
          protocolVersions
          shiftTypes
          cognitoID
          logoID
          userID
          neonateCutoff
          pediatricCutoff
          calculators
          softwarePlan
          subDepIDs
          parentDepID
          indexedParentDepID
          activeStatus
          agencyNumEMS
          stateIdEMS
          gnisCodeEMS
          uniqueCode
          uniquePublicURL
          hashedPin
          saltedPin
          keychainID
          isPublic
          infusionCalculation
          isPublicSignup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          departmentRequireSyncId
          __typename
        }
        Logs {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        departmentOneWeightsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    name
    location
    protocolVersions
    shiftTypes
    Medications {
      items {
        id
        name
        rxNormCode
        contraindication
        note
        warning
        route
        nemsisRoutes {
          route
          code
          __typename
        }
        concentration {
          firstAmnt
          firstUnit
          secAmnt
          secUnit
          color
          __typename
        }
        medClass
        action
        indication
        interaction
        onset
        duration
        departmentID
        protocolOptions {
          protocolID
          __typename
        }
        rangeLow
        rangeHigh
        maxDose
        minDose
        activeID
        overrideID
        status
        version
        createdBy
        modifiedBy
        metaData {
          parentID
          fullName
          nickName
          brandNames
          maxDose
          minDose
          absMaxDose
          absMinDose
          releaseNotes
          __typename
        }
        MedicationDoses {
          nextToken
          startedAt
          __typename
        }
        Concentrations {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Equipment {
      items {
        id
        name
        snomedCode
        optionItems {
          id
          amnt
          amntHigh
          rangeLow
          rangeHigh
          ageLow
          ageHigh
          index
          __typename
        }
        departmentID
        title
        note
        warning
        instruction
        activeID
        overrideID
        status
        version
        createdBy
        modifiedBy
        metaData {
          parentID
          fullName
          nickName
          brandNames
          maxDose
          minDose
          absMaxDose
          absMinDose
          releaseNotes
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Forms {
      items {
        id
        name
        departmentID
        items {
          id
          title
          isUrgent
          isDouble
          index
          __typename
        }
        activeID
        overrideID
        status
        version
        createdBy
        modifiedBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Vitals {
      items {
        id
        title
        options {
          id
          amntLow
          amntHigh
          rangeLow
          rangeHigh
          ageLow
          ageHigh
          index
          __typename
        }
        index
        departmentID
        activeID
        overrideID
        status
        version
        createdBy
        modifiedBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Electricals {
      items {
        id
        title
        options {
          title
          __typename
        }
        departmentID
        activeID
        status
        version
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    ElectricalShocks {
      items {
        id
        title
        rxNormCode
        options {
          protocolID
          __typename
        }
        warning
        instruction
        note
        rangeHigh
        rangeLow
        departmentID
        activeID
        overrideID
        status
        version
        createdBy
        modifiedBy
        metaData {
          parentID
          fullName
          nickName
          brandNames
          maxDose
          minDose
          absMaxDose
          absMinDose
          releaseNotes
          __typename
        }
        ElectricalDoses {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    cognitoID
    Users {
      items {
        id
        firstName
        lastName
        type
        cognitoID
        indexedParentDepID
        departmentID
        depAdmins
        logs {
          nextToken
          startedAt
          __typename
        }
        hashedPin
        saltPin
        pairedDepIDs
        oneDoseVersion
        lastActivity
        notificationTokens
        deviceIDs
        maxDevices
        allowAnalytics
        status
        FormLogs {
          nextToken
          startedAt
          __typename
        }
        Reviewals {
          nextToken
          startedAt
          __typename
        }
        Acknowledges {
          nextToken
          startedAt
          __typename
        }
        UserComments {
          nextToken
          startedAt
          __typename
        }
        NotifyACKs {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Logs {
      items {
        id
        actualWeight
        ageValue
        protocolID
        Users {
          nextToken
          startedAt
          __typename
        }
        departmentID
        oneweightID
        ambulanceID
        incidentNumber
        startTime
        endTime
        estimatedWeight
        medications {
          medicationID
          amntSolid
          amntLiquid
          timestamp
          verified
          name
          rxNormCode
          concentration
          isActualWeight
          adminType
          weight
          protocolID
          __typename
        }
        equipments {
          equipmentID
          amount
          name
          snomedCode
          timestamp
          isActualWeight
          verified
          weight
          successful
          protocolID
          __typename
        }
        electricals {
          electricalID
          name
          snomedCode
          amount
          timestamp
          isActualWeight
          verified
          weight
          protocolID
          __typename
        }
        cpr {
          cprID
          startTime
          endTime
          outcome
          __typename
        }
        emsCalculators {
          calculatorID
          responses
          scores
          timestamp
          __typename
        }
        patientSaved
        patientReleased
        arrivedAtHospital
        ageTimestamp
        forms {
          formID
          name
          completed
          startedTime
          protocolID
          __typename
        }
        subtractedItems {
          weightObjectID
          name
          weight
          added
          __typename
        }
        userIDs
        infusions {
          dripID
          amnt
          amnt2
          timestamp
          verified
          name
          rxNormCode
          concentration
          adminType
          weight
          protocolID
          amntUnit
          amntUnit2
          isActualWeight
          gttsDropFactor
          gttsAmount
          gttsUnit
          __typename
        }
        estimatedSaved
        tags
        shifts {
          shiftStart
          shiftEnd
          completedLogs
          taggedAmbulance
          taggedOneWeight
          taggedUsers
          ownerID
          departmentID
          metaData
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Ambulances {
      items {
        id
        name
        departmentID
        Logs {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Drips {
      items {
        id
        name
        rxNormCode
        contraindication
        note
        warning
        route
        nemsisRoutes {
          route
          code
          __typename
        }
        concentration {
          firstAmnt
          firstUnit
          secAmnt
          secUnit
          color
          __typename
        }
        medClass
        action
        indication
        interaction
        onset
        duration
        dripOptions {
          protocolID
          __typename
        }
        departmentID
        rangeLow
        rangeHigh
        maxDose
        minDose
        activeID
        overrideID
        status
        version
        createdBy
        modifiedBy
        metaData {
          parentID
          fullName
          nickName
          brandNames
          maxDose
          minDose
          absMaxDose
          absMinDose
          releaseNotes
          __typename
        }
        InfusionDoses {
          nextToken
          startedAt
          __typename
        }
        Concentrations {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    RequireSync {
      id
      sync
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    logoID
    userID
    neonateCutoff
    pediatricCutoff
    calculators
    adultRanges {
      label
      value
      __typename
    }
    softwarePlan
    Notifications {
      items {
        id
        type
        title
        message
        timestamp
        expirationTimestamp
        deadlineTimestamp
        isReadIDs
        isAckIDs
        imageURLs
        videoURLs
        fileURLs
        taggedProtocols
        questions {
          question
          options
          answer
          taggedProtocol
          __typename
        }
        formQuestions {
          index
          type
          question
          description
          answers
          correctAnswer
          __typename
        }
        isPush
        isPinRequired
        departmentID
        pairedDepIDs
        Groups {
          nextToken
          startedAt
          __typename
        }
        createdBy
        modifiedBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Contacts {
      items {
        id
        fullName
        title
        number
        note
        index
        departmentID
        pairedDepIDs
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    InputForms {
      items {
        id
        name
        items {
          index
          type
          question
          description
          answers
          correctAnswer
          __typename
        }
        FormLogs {
          nextToken
          startedAt
          __typename
        }
        departmentID
        pairedDepIDs
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    FormLogs {
      items {
        id
        responses {
          questionIndex
          type
          responses
          __typename
        }
        inputformID
        departmentID
        userID
        pairedDepID
        pairedUserIDs
        notes
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Workbooks {
      items {
        id
        name
        workbookID
        fileSize
        aiPdfParserResults
        activeID
        createdBy
        modifiedBy
        status
        version
        pairedDepIDs
        departmentID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    WeightObjects {
      items {
        id
        name
        weight
        createdBy
        modifiedBy
        version
        pairedDepIDs
        departmentID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Keychains {
      items {
        id
        name
        hashedPin
        saltPin
        hashType
        version
        createdBy
        modifiedBy
        metaData
        departmentID
        Protocols {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Reviewals {
      items {
        id
        title
        description
        reviewers
        status
        events {
          color
          title
          description
          timestamp
          icon
          __typename
        }
        createdAt
        endedAt
        departmentID
        userID
        User {
          id
          firstName
          lastName
          type
          cognitoID
          indexedParentDepID
          departmentID
          depAdmins
          hashedPin
          saltPin
          pairedDepIDs
          oneDoseVersion
          lastActivity
          notificationTokens
          deviceIDs
          maxDevices
          allowAnalytics
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        DraftGroups {
          nextToken
          startedAt
          __typename
        }
        Acknowledges {
          nextToken
          startedAt
          __typename
        }
        UserComments {
          nextToken
          startedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Groups {
      items {
        id
        name
        type
        departmentID
        pairedDepIDs
        pairedUserIDs
        isAutoGen
        Department {
          id
          name
          location
          protocolVersions
          shiftTypes
          cognitoID
          logoID
          userID
          neonateCutoff
          pediatricCutoff
          calculators
          softwarePlan
          subDepIDs
          parentDepID
          indexedParentDepID
          activeStatus
          agencyNumEMS
          stateIdEMS
          gnisCodeEMS
          uniqueCode
          uniquePublicURL
          hashedPin
          saltedPin
          keychainID
          isPublic
          infusionCalculation
          isPublicSignup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          departmentRequireSyncId
          __typename
        }
        Notifications {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    CPRAssists {
      items {
        id
        protocolIDs
        epiIDs
        defibIDs
        equipmentID
        departmentID
        activeID
        overrideID
        status
        version
        createdBy
        modifiedBy
        cprMetaData {
          defaultMode
          epiOrangeSec
          epiRedSec
          compressionRate
          ventRatePerMin
          ventilateSoundDelay
          ventilateLoops
          continousVentilateLoops
          ventilateSoundSpeed
          pulseCheckTime
          chargingTime
          __typename
        }
        DefibDoses {
          nextToken
          startedAt
          __typename
        }
        EpiDoses {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    subDepIDs
    parentDepID
    indexedParentDepID
    activeStatus
    agencyNumEMS
    stateIdEMS
    gnisCodeEMS
    uniqueCode
    uniquePublicURL
    hashedPin
    saltedPin
    keychainID
    isPublic
    infusionCalculation
    isPublicSignup
    config {
      isTopEnabled
      neonateCutoff
      pediatricCutoff
      calculators
      adultRanges {
        label
        value
        __typename
      }
      softwarePlan
      infusionCalculation
      isPublic
      realTimeUpdating
      epcrProvider
      oneweightEnabled
      ageFilterEnabled
      ageGroupFilterEnabled
      renewalDate
      infusionGTTS
      signedAgreement
      isToggleAdvancedFiltersEnabled
      premiumTrialExpiration
      estimationMethod
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    departmentRequireSyncId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateDepartmentMutationVariables,
  APITypes.CreateDepartmentMutation
>;
export const updateDepartment = /* GraphQL */ `mutation UpdateDepartment(
  $input: UpdateDepartmentInput!
  $condition: ModelDepartmentConditionInput
) {
  updateDepartment(input: $input, condition: $condition) {
    id
    Categories {
      items {
        id
        name
        departmentID
        department {
          id
          name
          location
          protocolVersions
          shiftTypes
          cognitoID
          logoID
          userID
          neonateCutoff
          pediatricCutoff
          calculators
          softwarePlan
          subDepIDs
          parentDepID
          indexedParentDepID
          activeStatus
          agencyNumEMS
          stateIdEMS
          gnisCodeEMS
          uniqueCode
          uniquePublicURL
          hashedPin
          saltedPin
          keychainID
          isPublic
          infusionCalculation
          isPublicSignup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          departmentRequireSyncId
          __typename
        }
        index
        pairedDepIDs
        parentCategoryID
        activeID
        status
        version
        createdBy
        modifiedBy
        isPublic
        isRestrictive
        color
        tags
        overrideID
        keychainID
        Protocols {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        departmentCategoriesId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    OneWeights {
      items {
        id
        name
        serialNumber
        projectNumber
        lastCalibration
        type
        departmentID
        department {
          id
          name
          location
          protocolVersions
          shiftTypes
          cognitoID
          logoID
          userID
          neonateCutoff
          pediatricCutoff
          calculators
          softwarePlan
          subDepIDs
          parentDepID
          indexedParentDepID
          activeStatus
          agencyNumEMS
          stateIdEMS
          gnisCodeEMS
          uniqueCode
          uniquePublicURL
          hashedPin
          saltedPin
          keychainID
          isPublic
          infusionCalculation
          isPublicSignup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          departmentRequireSyncId
          __typename
        }
        Logs {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        departmentOneWeightsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    name
    location
    protocolVersions
    shiftTypes
    Medications {
      items {
        id
        name
        rxNormCode
        contraindication
        note
        warning
        route
        nemsisRoutes {
          route
          code
          __typename
        }
        concentration {
          firstAmnt
          firstUnit
          secAmnt
          secUnit
          color
          __typename
        }
        medClass
        action
        indication
        interaction
        onset
        duration
        departmentID
        protocolOptions {
          protocolID
          __typename
        }
        rangeLow
        rangeHigh
        maxDose
        minDose
        activeID
        overrideID
        status
        version
        createdBy
        modifiedBy
        metaData {
          parentID
          fullName
          nickName
          brandNames
          maxDose
          minDose
          absMaxDose
          absMinDose
          releaseNotes
          __typename
        }
        MedicationDoses {
          nextToken
          startedAt
          __typename
        }
        Concentrations {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Equipment {
      items {
        id
        name
        snomedCode
        optionItems {
          id
          amnt
          amntHigh
          rangeLow
          rangeHigh
          ageLow
          ageHigh
          index
          __typename
        }
        departmentID
        title
        note
        warning
        instruction
        activeID
        overrideID
        status
        version
        createdBy
        modifiedBy
        metaData {
          parentID
          fullName
          nickName
          brandNames
          maxDose
          minDose
          absMaxDose
          absMinDose
          releaseNotes
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Forms {
      items {
        id
        name
        departmentID
        items {
          id
          title
          isUrgent
          isDouble
          index
          __typename
        }
        activeID
        overrideID
        status
        version
        createdBy
        modifiedBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Vitals {
      items {
        id
        title
        options {
          id
          amntLow
          amntHigh
          rangeLow
          rangeHigh
          ageLow
          ageHigh
          index
          __typename
        }
        index
        departmentID
        activeID
        overrideID
        status
        version
        createdBy
        modifiedBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Electricals {
      items {
        id
        title
        options {
          title
          __typename
        }
        departmentID
        activeID
        status
        version
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    ElectricalShocks {
      items {
        id
        title
        rxNormCode
        options {
          protocolID
          __typename
        }
        warning
        instruction
        note
        rangeHigh
        rangeLow
        departmentID
        activeID
        overrideID
        status
        version
        createdBy
        modifiedBy
        metaData {
          parentID
          fullName
          nickName
          brandNames
          maxDose
          minDose
          absMaxDose
          absMinDose
          releaseNotes
          __typename
        }
        ElectricalDoses {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    cognitoID
    Users {
      items {
        id
        firstName
        lastName
        type
        cognitoID
        indexedParentDepID
        departmentID
        depAdmins
        logs {
          nextToken
          startedAt
          __typename
        }
        hashedPin
        saltPin
        pairedDepIDs
        oneDoseVersion
        lastActivity
        notificationTokens
        deviceIDs
        maxDevices
        allowAnalytics
        status
        FormLogs {
          nextToken
          startedAt
          __typename
        }
        Reviewals {
          nextToken
          startedAt
          __typename
        }
        Acknowledges {
          nextToken
          startedAt
          __typename
        }
        UserComments {
          nextToken
          startedAt
          __typename
        }
        NotifyACKs {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Logs {
      items {
        id
        actualWeight
        ageValue
        protocolID
        Users {
          nextToken
          startedAt
          __typename
        }
        departmentID
        oneweightID
        ambulanceID
        incidentNumber
        startTime
        endTime
        estimatedWeight
        medications {
          medicationID
          amntSolid
          amntLiquid
          timestamp
          verified
          name
          rxNormCode
          concentration
          isActualWeight
          adminType
          weight
          protocolID
          __typename
        }
        equipments {
          equipmentID
          amount
          name
          snomedCode
          timestamp
          isActualWeight
          verified
          weight
          successful
          protocolID
          __typename
        }
        electricals {
          electricalID
          name
          snomedCode
          amount
          timestamp
          isActualWeight
          verified
          weight
          protocolID
          __typename
        }
        cpr {
          cprID
          startTime
          endTime
          outcome
          __typename
        }
        emsCalculators {
          calculatorID
          responses
          scores
          timestamp
          __typename
        }
        patientSaved
        patientReleased
        arrivedAtHospital
        ageTimestamp
        forms {
          formID
          name
          completed
          startedTime
          protocolID
          __typename
        }
        subtractedItems {
          weightObjectID
          name
          weight
          added
          __typename
        }
        userIDs
        infusions {
          dripID
          amnt
          amnt2
          timestamp
          verified
          name
          rxNormCode
          concentration
          adminType
          weight
          protocolID
          amntUnit
          amntUnit2
          isActualWeight
          gttsDropFactor
          gttsAmount
          gttsUnit
          __typename
        }
        estimatedSaved
        tags
        shifts {
          shiftStart
          shiftEnd
          completedLogs
          taggedAmbulance
          taggedOneWeight
          taggedUsers
          ownerID
          departmentID
          metaData
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Ambulances {
      items {
        id
        name
        departmentID
        Logs {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Drips {
      items {
        id
        name
        rxNormCode
        contraindication
        note
        warning
        route
        nemsisRoutes {
          route
          code
          __typename
        }
        concentration {
          firstAmnt
          firstUnit
          secAmnt
          secUnit
          color
          __typename
        }
        medClass
        action
        indication
        interaction
        onset
        duration
        dripOptions {
          protocolID
          __typename
        }
        departmentID
        rangeLow
        rangeHigh
        maxDose
        minDose
        activeID
        overrideID
        status
        version
        createdBy
        modifiedBy
        metaData {
          parentID
          fullName
          nickName
          brandNames
          maxDose
          minDose
          absMaxDose
          absMinDose
          releaseNotes
          __typename
        }
        InfusionDoses {
          nextToken
          startedAt
          __typename
        }
        Concentrations {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    RequireSync {
      id
      sync
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    logoID
    userID
    neonateCutoff
    pediatricCutoff
    calculators
    adultRanges {
      label
      value
      __typename
    }
    softwarePlan
    Notifications {
      items {
        id
        type
        title
        message
        timestamp
        expirationTimestamp
        deadlineTimestamp
        isReadIDs
        isAckIDs
        imageURLs
        videoURLs
        fileURLs
        taggedProtocols
        questions {
          question
          options
          answer
          taggedProtocol
          __typename
        }
        formQuestions {
          index
          type
          question
          description
          answers
          correctAnswer
          __typename
        }
        isPush
        isPinRequired
        departmentID
        pairedDepIDs
        Groups {
          nextToken
          startedAt
          __typename
        }
        createdBy
        modifiedBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Contacts {
      items {
        id
        fullName
        title
        number
        note
        index
        departmentID
        pairedDepIDs
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    InputForms {
      items {
        id
        name
        items {
          index
          type
          question
          description
          answers
          correctAnswer
          __typename
        }
        FormLogs {
          nextToken
          startedAt
          __typename
        }
        departmentID
        pairedDepIDs
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    FormLogs {
      items {
        id
        responses {
          questionIndex
          type
          responses
          __typename
        }
        inputformID
        departmentID
        userID
        pairedDepID
        pairedUserIDs
        notes
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Workbooks {
      items {
        id
        name
        workbookID
        fileSize
        aiPdfParserResults
        activeID
        createdBy
        modifiedBy
        status
        version
        pairedDepIDs
        departmentID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    WeightObjects {
      items {
        id
        name
        weight
        createdBy
        modifiedBy
        version
        pairedDepIDs
        departmentID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Keychains {
      items {
        id
        name
        hashedPin
        saltPin
        hashType
        version
        createdBy
        modifiedBy
        metaData
        departmentID
        Protocols {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Reviewals {
      items {
        id
        title
        description
        reviewers
        status
        events {
          color
          title
          description
          timestamp
          icon
          __typename
        }
        createdAt
        endedAt
        departmentID
        userID
        User {
          id
          firstName
          lastName
          type
          cognitoID
          indexedParentDepID
          departmentID
          depAdmins
          hashedPin
          saltPin
          pairedDepIDs
          oneDoseVersion
          lastActivity
          notificationTokens
          deviceIDs
          maxDevices
          allowAnalytics
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        DraftGroups {
          nextToken
          startedAt
          __typename
        }
        Acknowledges {
          nextToken
          startedAt
          __typename
        }
        UserComments {
          nextToken
          startedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Groups {
      items {
        id
        name
        type
        departmentID
        pairedDepIDs
        pairedUserIDs
        isAutoGen
        Department {
          id
          name
          location
          protocolVersions
          shiftTypes
          cognitoID
          logoID
          userID
          neonateCutoff
          pediatricCutoff
          calculators
          softwarePlan
          subDepIDs
          parentDepID
          indexedParentDepID
          activeStatus
          agencyNumEMS
          stateIdEMS
          gnisCodeEMS
          uniqueCode
          uniquePublicURL
          hashedPin
          saltedPin
          keychainID
          isPublic
          infusionCalculation
          isPublicSignup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          departmentRequireSyncId
          __typename
        }
        Notifications {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    CPRAssists {
      items {
        id
        protocolIDs
        epiIDs
        defibIDs
        equipmentID
        departmentID
        activeID
        overrideID
        status
        version
        createdBy
        modifiedBy
        cprMetaData {
          defaultMode
          epiOrangeSec
          epiRedSec
          compressionRate
          ventRatePerMin
          ventilateSoundDelay
          ventilateLoops
          continousVentilateLoops
          ventilateSoundSpeed
          pulseCheckTime
          chargingTime
          __typename
        }
        DefibDoses {
          nextToken
          startedAt
          __typename
        }
        EpiDoses {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    subDepIDs
    parentDepID
    indexedParentDepID
    activeStatus
    agencyNumEMS
    stateIdEMS
    gnisCodeEMS
    uniqueCode
    uniquePublicURL
    hashedPin
    saltedPin
    keychainID
    isPublic
    infusionCalculation
    isPublicSignup
    config {
      isTopEnabled
      neonateCutoff
      pediatricCutoff
      calculators
      adultRanges {
        label
        value
        __typename
      }
      softwarePlan
      infusionCalculation
      isPublic
      realTimeUpdating
      epcrProvider
      oneweightEnabled
      ageFilterEnabled
      ageGroupFilterEnabled
      renewalDate
      infusionGTTS
      signedAgreement
      isToggleAdvancedFiltersEnabled
      premiumTrialExpiration
      estimationMethod
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    departmentRequireSyncId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateDepartmentMutationVariables,
  APITypes.UpdateDepartmentMutation
>;
export const deleteDepartment = /* GraphQL */ `mutation DeleteDepartment(
  $input: DeleteDepartmentInput!
  $condition: ModelDepartmentConditionInput
) {
  deleteDepartment(input: $input, condition: $condition) {
    id
    Categories {
      items {
        id
        name
        departmentID
        department {
          id
          name
          location
          protocolVersions
          shiftTypes
          cognitoID
          logoID
          userID
          neonateCutoff
          pediatricCutoff
          calculators
          softwarePlan
          subDepIDs
          parentDepID
          indexedParentDepID
          activeStatus
          agencyNumEMS
          stateIdEMS
          gnisCodeEMS
          uniqueCode
          uniquePublicURL
          hashedPin
          saltedPin
          keychainID
          isPublic
          infusionCalculation
          isPublicSignup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          departmentRequireSyncId
          __typename
        }
        index
        pairedDepIDs
        parentCategoryID
        activeID
        status
        version
        createdBy
        modifiedBy
        isPublic
        isRestrictive
        color
        tags
        overrideID
        keychainID
        Protocols {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        departmentCategoriesId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    OneWeights {
      items {
        id
        name
        serialNumber
        projectNumber
        lastCalibration
        type
        departmentID
        department {
          id
          name
          location
          protocolVersions
          shiftTypes
          cognitoID
          logoID
          userID
          neonateCutoff
          pediatricCutoff
          calculators
          softwarePlan
          subDepIDs
          parentDepID
          indexedParentDepID
          activeStatus
          agencyNumEMS
          stateIdEMS
          gnisCodeEMS
          uniqueCode
          uniquePublicURL
          hashedPin
          saltedPin
          keychainID
          isPublic
          infusionCalculation
          isPublicSignup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          departmentRequireSyncId
          __typename
        }
        Logs {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        departmentOneWeightsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    name
    location
    protocolVersions
    shiftTypes
    Medications {
      items {
        id
        name
        rxNormCode
        contraindication
        note
        warning
        route
        nemsisRoutes {
          route
          code
          __typename
        }
        concentration {
          firstAmnt
          firstUnit
          secAmnt
          secUnit
          color
          __typename
        }
        medClass
        action
        indication
        interaction
        onset
        duration
        departmentID
        protocolOptions {
          protocolID
          __typename
        }
        rangeLow
        rangeHigh
        maxDose
        minDose
        activeID
        overrideID
        status
        version
        createdBy
        modifiedBy
        metaData {
          parentID
          fullName
          nickName
          brandNames
          maxDose
          minDose
          absMaxDose
          absMinDose
          releaseNotes
          __typename
        }
        MedicationDoses {
          nextToken
          startedAt
          __typename
        }
        Concentrations {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Equipment {
      items {
        id
        name
        snomedCode
        optionItems {
          id
          amnt
          amntHigh
          rangeLow
          rangeHigh
          ageLow
          ageHigh
          index
          __typename
        }
        departmentID
        title
        note
        warning
        instruction
        activeID
        overrideID
        status
        version
        createdBy
        modifiedBy
        metaData {
          parentID
          fullName
          nickName
          brandNames
          maxDose
          minDose
          absMaxDose
          absMinDose
          releaseNotes
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Forms {
      items {
        id
        name
        departmentID
        items {
          id
          title
          isUrgent
          isDouble
          index
          __typename
        }
        activeID
        overrideID
        status
        version
        createdBy
        modifiedBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Vitals {
      items {
        id
        title
        options {
          id
          amntLow
          amntHigh
          rangeLow
          rangeHigh
          ageLow
          ageHigh
          index
          __typename
        }
        index
        departmentID
        activeID
        overrideID
        status
        version
        createdBy
        modifiedBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Electricals {
      items {
        id
        title
        options {
          title
          __typename
        }
        departmentID
        activeID
        status
        version
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    ElectricalShocks {
      items {
        id
        title
        rxNormCode
        options {
          protocolID
          __typename
        }
        warning
        instruction
        note
        rangeHigh
        rangeLow
        departmentID
        activeID
        overrideID
        status
        version
        createdBy
        modifiedBy
        metaData {
          parentID
          fullName
          nickName
          brandNames
          maxDose
          minDose
          absMaxDose
          absMinDose
          releaseNotes
          __typename
        }
        ElectricalDoses {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    cognitoID
    Users {
      items {
        id
        firstName
        lastName
        type
        cognitoID
        indexedParentDepID
        departmentID
        depAdmins
        logs {
          nextToken
          startedAt
          __typename
        }
        hashedPin
        saltPin
        pairedDepIDs
        oneDoseVersion
        lastActivity
        notificationTokens
        deviceIDs
        maxDevices
        allowAnalytics
        status
        FormLogs {
          nextToken
          startedAt
          __typename
        }
        Reviewals {
          nextToken
          startedAt
          __typename
        }
        Acknowledges {
          nextToken
          startedAt
          __typename
        }
        UserComments {
          nextToken
          startedAt
          __typename
        }
        NotifyACKs {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Logs {
      items {
        id
        actualWeight
        ageValue
        protocolID
        Users {
          nextToken
          startedAt
          __typename
        }
        departmentID
        oneweightID
        ambulanceID
        incidentNumber
        startTime
        endTime
        estimatedWeight
        medications {
          medicationID
          amntSolid
          amntLiquid
          timestamp
          verified
          name
          rxNormCode
          concentration
          isActualWeight
          adminType
          weight
          protocolID
          __typename
        }
        equipments {
          equipmentID
          amount
          name
          snomedCode
          timestamp
          isActualWeight
          verified
          weight
          successful
          protocolID
          __typename
        }
        electricals {
          electricalID
          name
          snomedCode
          amount
          timestamp
          isActualWeight
          verified
          weight
          protocolID
          __typename
        }
        cpr {
          cprID
          startTime
          endTime
          outcome
          __typename
        }
        emsCalculators {
          calculatorID
          responses
          scores
          timestamp
          __typename
        }
        patientSaved
        patientReleased
        arrivedAtHospital
        ageTimestamp
        forms {
          formID
          name
          completed
          startedTime
          protocolID
          __typename
        }
        subtractedItems {
          weightObjectID
          name
          weight
          added
          __typename
        }
        userIDs
        infusions {
          dripID
          amnt
          amnt2
          timestamp
          verified
          name
          rxNormCode
          concentration
          adminType
          weight
          protocolID
          amntUnit
          amntUnit2
          isActualWeight
          gttsDropFactor
          gttsAmount
          gttsUnit
          __typename
        }
        estimatedSaved
        tags
        shifts {
          shiftStart
          shiftEnd
          completedLogs
          taggedAmbulance
          taggedOneWeight
          taggedUsers
          ownerID
          departmentID
          metaData
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Ambulances {
      items {
        id
        name
        departmentID
        Logs {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Drips {
      items {
        id
        name
        rxNormCode
        contraindication
        note
        warning
        route
        nemsisRoutes {
          route
          code
          __typename
        }
        concentration {
          firstAmnt
          firstUnit
          secAmnt
          secUnit
          color
          __typename
        }
        medClass
        action
        indication
        interaction
        onset
        duration
        dripOptions {
          protocolID
          __typename
        }
        departmentID
        rangeLow
        rangeHigh
        maxDose
        minDose
        activeID
        overrideID
        status
        version
        createdBy
        modifiedBy
        metaData {
          parentID
          fullName
          nickName
          brandNames
          maxDose
          minDose
          absMaxDose
          absMinDose
          releaseNotes
          __typename
        }
        InfusionDoses {
          nextToken
          startedAt
          __typename
        }
        Concentrations {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    RequireSync {
      id
      sync
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    logoID
    userID
    neonateCutoff
    pediatricCutoff
    calculators
    adultRanges {
      label
      value
      __typename
    }
    softwarePlan
    Notifications {
      items {
        id
        type
        title
        message
        timestamp
        expirationTimestamp
        deadlineTimestamp
        isReadIDs
        isAckIDs
        imageURLs
        videoURLs
        fileURLs
        taggedProtocols
        questions {
          question
          options
          answer
          taggedProtocol
          __typename
        }
        formQuestions {
          index
          type
          question
          description
          answers
          correctAnswer
          __typename
        }
        isPush
        isPinRequired
        departmentID
        pairedDepIDs
        Groups {
          nextToken
          startedAt
          __typename
        }
        createdBy
        modifiedBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Contacts {
      items {
        id
        fullName
        title
        number
        note
        index
        departmentID
        pairedDepIDs
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    InputForms {
      items {
        id
        name
        items {
          index
          type
          question
          description
          answers
          correctAnswer
          __typename
        }
        FormLogs {
          nextToken
          startedAt
          __typename
        }
        departmentID
        pairedDepIDs
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    FormLogs {
      items {
        id
        responses {
          questionIndex
          type
          responses
          __typename
        }
        inputformID
        departmentID
        userID
        pairedDepID
        pairedUserIDs
        notes
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Workbooks {
      items {
        id
        name
        workbookID
        fileSize
        aiPdfParserResults
        activeID
        createdBy
        modifiedBy
        status
        version
        pairedDepIDs
        departmentID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    WeightObjects {
      items {
        id
        name
        weight
        createdBy
        modifiedBy
        version
        pairedDepIDs
        departmentID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Keychains {
      items {
        id
        name
        hashedPin
        saltPin
        hashType
        version
        createdBy
        modifiedBy
        metaData
        departmentID
        Protocols {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Reviewals {
      items {
        id
        title
        description
        reviewers
        status
        events {
          color
          title
          description
          timestamp
          icon
          __typename
        }
        createdAt
        endedAt
        departmentID
        userID
        User {
          id
          firstName
          lastName
          type
          cognitoID
          indexedParentDepID
          departmentID
          depAdmins
          hashedPin
          saltPin
          pairedDepIDs
          oneDoseVersion
          lastActivity
          notificationTokens
          deviceIDs
          maxDevices
          allowAnalytics
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        DraftGroups {
          nextToken
          startedAt
          __typename
        }
        Acknowledges {
          nextToken
          startedAt
          __typename
        }
        UserComments {
          nextToken
          startedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Groups {
      items {
        id
        name
        type
        departmentID
        pairedDepIDs
        pairedUserIDs
        isAutoGen
        Department {
          id
          name
          location
          protocolVersions
          shiftTypes
          cognitoID
          logoID
          userID
          neonateCutoff
          pediatricCutoff
          calculators
          softwarePlan
          subDepIDs
          parentDepID
          indexedParentDepID
          activeStatus
          agencyNumEMS
          stateIdEMS
          gnisCodeEMS
          uniqueCode
          uniquePublicURL
          hashedPin
          saltedPin
          keychainID
          isPublic
          infusionCalculation
          isPublicSignup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          departmentRequireSyncId
          __typename
        }
        Notifications {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    CPRAssists {
      items {
        id
        protocolIDs
        epiIDs
        defibIDs
        equipmentID
        departmentID
        activeID
        overrideID
        status
        version
        createdBy
        modifiedBy
        cprMetaData {
          defaultMode
          epiOrangeSec
          epiRedSec
          compressionRate
          ventRatePerMin
          ventilateSoundDelay
          ventilateLoops
          continousVentilateLoops
          ventilateSoundSpeed
          pulseCheckTime
          chargingTime
          __typename
        }
        DefibDoses {
          nextToken
          startedAt
          __typename
        }
        EpiDoses {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    subDepIDs
    parentDepID
    indexedParentDepID
    activeStatus
    agencyNumEMS
    stateIdEMS
    gnisCodeEMS
    uniqueCode
    uniquePublicURL
    hashedPin
    saltedPin
    keychainID
    isPublic
    infusionCalculation
    isPublicSignup
    config {
      isTopEnabled
      neonateCutoff
      pediatricCutoff
      calculators
      adultRanges {
        label
        value
        __typename
      }
      softwarePlan
      infusionCalculation
      isPublic
      realTimeUpdating
      epcrProvider
      oneweightEnabled
      ageFilterEnabled
      ageGroupFilterEnabled
      renewalDate
      infusionGTTS
      signedAgreement
      isToggleAdvancedFiltersEnabled
      premiumTrialExpiration
      estimationMethod
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    departmentRequireSyncId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteDepartmentMutationVariables,
  APITypes.DeleteDepartmentMutation
>;
export const createMedicShift = /* GraphQL */ `mutation CreateMedicShift(
  $input: CreateMedicShiftInput!
  $condition: ModelMedicShiftConditionInput
) {
  createMedicShift(input: $input, condition: $condition) {
    id
    shiftStart
    shiftEnd
    completedLogs
    taggedAmbulance
    taggedOneWeight
    taggedUsers
    subscribedDevices
    invitedUsers {
      itemID
      expiration
      sentTime
      title
      description
      __typename
    }
    ownerID
    departmentID
    interactionID
    metaData
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateMedicShiftMutationVariables,
  APITypes.CreateMedicShiftMutation
>;
export const updateMedicShift = /* GraphQL */ `mutation UpdateMedicShift(
  $input: UpdateMedicShiftInput!
  $condition: ModelMedicShiftConditionInput
) {
  updateMedicShift(input: $input, condition: $condition) {
    id
    shiftStart
    shiftEnd
    completedLogs
    taggedAmbulance
    taggedOneWeight
    taggedUsers
    subscribedDevices
    invitedUsers {
      itemID
      expiration
      sentTime
      title
      description
      __typename
    }
    ownerID
    departmentID
    interactionID
    metaData
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateMedicShiftMutationVariables,
  APITypes.UpdateMedicShiftMutation
>;
export const deleteMedicShift = /* GraphQL */ `mutation DeleteMedicShift(
  $input: DeleteMedicShiftInput!
  $condition: ModelMedicShiftConditionInput
) {
  deleteMedicShift(input: $input, condition: $condition) {
    id
    shiftStart
    shiftEnd
    completedLogs
    taggedAmbulance
    taggedOneWeight
    taggedUsers
    subscribedDevices
    invitedUsers {
      itemID
      expiration
      sentTime
      title
      description
      __typename
    }
    ownerID
    departmentID
    interactionID
    metaData
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteMedicShiftMutationVariables,
  APITypes.DeleteMedicShiftMutation
>;
export const createPatientInteraction = /* GraphQL */ `mutation CreatePatientInteraction(
  $input: CreatePatientInteractionInput!
  $condition: ModelPatientInteractionConditionInput
) {
  createPatientInteraction(input: $input, condition: $condition) {
    id
    isLogActive
    actualWeight
    ageValue
    startTime
    endTime
    estimatedWeight
    medications {
      medicationID
      amntSolid
      amntLiquid
      timestamp
      verified
      name
      rxNormCode
      concentration
      isActualWeight
      adminType
      route {
        route
        code
        __typename
      }
      weight
      protocolID
      __typename
    }
    equipments {
      equipmentID
      amount
      name
      snomedCode
      timestamp
      isActualWeight
      verified
      weight
      successful
      protocolID
      __typename
    }
    electricals {
      electricalID
      name
      snomedCode
      amount
      timestamp
      isActualWeight
      verified
      weight
      protocolID
      __typename
    }
    infusions {
      dripID
      amnt
      amnt2
      timestamp
      verified
      name
      rxNormCode
      concentration
      adminType
      route {
        route
        code
        __typename
      }
      weight
      protocolID
      amntUnit
      amntUnit2
      isActualWeight
      gttsDropFactor
      gttsAmount
      gttsUnit
      __typename
    }
    forms {
      formID
      name
      completed
      startedTime
      protocolID
      __typename
    }
    subtractedItems {
      weightObjectID
      name
      weight
      added
      __typename
    }
    cpr {
      cprID
      startTime
      endTime
      outcome
      __typename
    }
    emsCalculators {
      calculatorID
      responses
      scores
      timestamp
      __typename
    }
    taggedUsers
    taggedAmbulance
    taggedOneWeight
    incidentNumber
    actualTime
    estimatedTime
    patientReleased
    ageTimestamp
    departmentID
    subscribedUsers
    subscribedDevices
    events {
      color
      title
      description
      timestamp
      icon
      __typename
    }
    shifts
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePatientInteractionMutationVariables,
  APITypes.CreatePatientInteractionMutation
>;
export const updatePatientInteraction = /* GraphQL */ `mutation UpdatePatientInteraction(
  $input: UpdatePatientInteractionInput!
  $condition: ModelPatientInteractionConditionInput
) {
  updatePatientInteraction(input: $input, condition: $condition) {
    id
    isLogActive
    actualWeight
    ageValue
    startTime
    endTime
    estimatedWeight
    medications {
      medicationID
      amntSolid
      amntLiquid
      timestamp
      verified
      name
      rxNormCode
      concentration
      isActualWeight
      adminType
      route {
        route
        code
        __typename
      }
      weight
      protocolID
      __typename
    }
    equipments {
      equipmentID
      amount
      name
      snomedCode
      timestamp
      isActualWeight
      verified
      weight
      successful
      protocolID
      __typename
    }
    electricals {
      electricalID
      name
      snomedCode
      amount
      timestamp
      isActualWeight
      verified
      weight
      protocolID
      __typename
    }
    infusions {
      dripID
      amnt
      amnt2
      timestamp
      verified
      name
      rxNormCode
      concentration
      adminType
      route {
        route
        code
        __typename
      }
      weight
      protocolID
      amntUnit
      amntUnit2
      isActualWeight
      gttsDropFactor
      gttsAmount
      gttsUnit
      __typename
    }
    forms {
      formID
      name
      completed
      startedTime
      protocolID
      __typename
    }
    subtractedItems {
      weightObjectID
      name
      weight
      added
      __typename
    }
    cpr {
      cprID
      startTime
      endTime
      outcome
      __typename
    }
    emsCalculators {
      calculatorID
      responses
      scores
      timestamp
      __typename
    }
    taggedUsers
    taggedAmbulance
    taggedOneWeight
    incidentNumber
    actualTime
    estimatedTime
    patientReleased
    ageTimestamp
    departmentID
    subscribedUsers
    subscribedDevices
    events {
      color
      title
      description
      timestamp
      icon
      __typename
    }
    shifts
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePatientInteractionMutationVariables,
  APITypes.UpdatePatientInteractionMutation
>;
export const deletePatientInteraction = /* GraphQL */ `mutation DeletePatientInteraction(
  $input: DeletePatientInteractionInput!
  $condition: ModelPatientInteractionConditionInput
) {
  deletePatientInteraction(input: $input, condition: $condition) {
    id
    isLogActive
    actualWeight
    ageValue
    startTime
    endTime
    estimatedWeight
    medications {
      medicationID
      amntSolid
      amntLiquid
      timestamp
      verified
      name
      rxNormCode
      concentration
      isActualWeight
      adminType
      route {
        route
        code
        __typename
      }
      weight
      protocolID
      __typename
    }
    equipments {
      equipmentID
      amount
      name
      snomedCode
      timestamp
      isActualWeight
      verified
      weight
      successful
      protocolID
      __typename
    }
    electricals {
      electricalID
      name
      snomedCode
      amount
      timestamp
      isActualWeight
      verified
      weight
      protocolID
      __typename
    }
    infusions {
      dripID
      amnt
      amnt2
      timestamp
      verified
      name
      rxNormCode
      concentration
      adminType
      route {
        route
        code
        __typename
      }
      weight
      protocolID
      amntUnit
      amntUnit2
      isActualWeight
      gttsDropFactor
      gttsAmount
      gttsUnit
      __typename
    }
    forms {
      formID
      name
      completed
      startedTime
      protocolID
      __typename
    }
    subtractedItems {
      weightObjectID
      name
      weight
      added
      __typename
    }
    cpr {
      cprID
      startTime
      endTime
      outcome
      __typename
    }
    emsCalculators {
      calculatorID
      responses
      scores
      timestamp
      __typename
    }
    taggedUsers
    taggedAmbulance
    taggedOneWeight
    incidentNumber
    actualTime
    estimatedTime
    patientReleased
    ageTimestamp
    departmentID
    subscribedUsers
    subscribedDevices
    events {
      color
      title
      description
      timestamp
      icon
      __typename
    }
    shifts
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePatientInteractionMutationVariables,
  APITypes.DeletePatientInteractionMutation
>;
export const createKeychain = /* GraphQL */ `mutation CreateKeychain(
  $input: CreateKeychainInput!
  $condition: ModelKeychainConditionInput
) {
  createKeychain(input: $input, condition: $condition) {
    id
    name
    hashedPin
    saltPin
    hashType
    version
    createdBy
    modifiedBy
    metaData
    departmentID
    Protocols {
      items {
        id
        name
        pdfID
        categoryID
        pairedProtocols
        medicationIDs
        equipmentIDs
        electricalIDs
        dripIDs
        formIDs
        index
        nickname
        pairedDepIDs
        rangeLow
        rangeHigh
        ageLow
        ageHigh
        ageGroup
        status
        version
        pdfVersion
        activeID
        overrideID
        privatePin
        keychainID
        createdBy
        modifiedBy
        isPublic
        isRestrictive
        color
        tags
        departmentID
        MedicationDoses {
          nextToken
          startedAt
          __typename
        }
        ElectricalDoses {
          nextToken
          startedAt
          __typename
        }
        DripDoses {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateKeychainMutationVariables,
  APITypes.CreateKeychainMutation
>;
export const updateKeychain = /* GraphQL */ `mutation UpdateKeychain(
  $input: UpdateKeychainInput!
  $condition: ModelKeychainConditionInput
) {
  updateKeychain(input: $input, condition: $condition) {
    id
    name
    hashedPin
    saltPin
    hashType
    version
    createdBy
    modifiedBy
    metaData
    departmentID
    Protocols {
      items {
        id
        name
        pdfID
        categoryID
        pairedProtocols
        medicationIDs
        equipmentIDs
        electricalIDs
        dripIDs
        formIDs
        index
        nickname
        pairedDepIDs
        rangeLow
        rangeHigh
        ageLow
        ageHigh
        ageGroup
        status
        version
        pdfVersion
        activeID
        overrideID
        privatePin
        keychainID
        createdBy
        modifiedBy
        isPublic
        isRestrictive
        color
        tags
        departmentID
        MedicationDoses {
          nextToken
          startedAt
          __typename
        }
        ElectricalDoses {
          nextToken
          startedAt
          __typename
        }
        DripDoses {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateKeychainMutationVariables,
  APITypes.UpdateKeychainMutation
>;
export const deleteKeychain = /* GraphQL */ `mutation DeleteKeychain(
  $input: DeleteKeychainInput!
  $condition: ModelKeychainConditionInput
) {
  deleteKeychain(input: $input, condition: $condition) {
    id
    name
    hashedPin
    saltPin
    hashType
    version
    createdBy
    modifiedBy
    metaData
    departmentID
    Protocols {
      items {
        id
        name
        pdfID
        categoryID
        pairedProtocols
        medicationIDs
        equipmentIDs
        electricalIDs
        dripIDs
        formIDs
        index
        nickname
        pairedDepIDs
        rangeLow
        rangeHigh
        ageLow
        ageHigh
        ageGroup
        status
        version
        pdfVersion
        activeID
        overrideID
        privatePin
        keychainID
        createdBy
        modifiedBy
        isPublic
        isRestrictive
        color
        tags
        departmentID
        MedicationDoses {
          nextToken
          startedAt
          __typename
        }
        ElectricalDoses {
          nextToken
          startedAt
          __typename
        }
        DripDoses {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteKeychainMutationVariables,
  APITypes.DeleteKeychainMutation
>;
export const createContact = /* GraphQL */ `mutation CreateContact(
  $input: CreateContactInput!
  $condition: ModelContactConditionInput
) {
  createContact(input: $input, condition: $condition) {
    id
    fullName
    title
    number
    note
    index
    departmentID
    pairedDepIDs
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateContactMutationVariables,
  APITypes.CreateContactMutation
>;
export const updateContact = /* GraphQL */ `mutation UpdateContact(
  $input: UpdateContactInput!
  $condition: ModelContactConditionInput
) {
  updateContact(input: $input, condition: $condition) {
    id
    fullName
    title
    number
    note
    index
    departmentID
    pairedDepIDs
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateContactMutationVariables,
  APITypes.UpdateContactMutation
>;
export const deleteContact = /* GraphQL */ `mutation DeleteContact(
  $input: DeleteContactInput!
  $condition: ModelContactConditionInput
) {
  deleteContact(input: $input, condition: $condition) {
    id
    fullName
    title
    number
    note
    index
    departmentID
    pairedDepIDs
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteContactMutationVariables,
  APITypes.DeleteContactMutation
>;
export const createFormLog = /* GraphQL */ `mutation CreateFormLog(
  $input: CreateFormLogInput!
  $condition: ModelFormLogConditionInput
) {
  createFormLog(input: $input, condition: $condition) {
    id
    responses {
      questionIndex
      type
      responses
      __typename
    }
    inputformID
    departmentID
    userID
    pairedDepID
    pairedUserIDs
    notes
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateFormLogMutationVariables,
  APITypes.CreateFormLogMutation
>;
export const updateFormLog = /* GraphQL */ `mutation UpdateFormLog(
  $input: UpdateFormLogInput!
  $condition: ModelFormLogConditionInput
) {
  updateFormLog(input: $input, condition: $condition) {
    id
    responses {
      questionIndex
      type
      responses
      __typename
    }
    inputformID
    departmentID
    userID
    pairedDepID
    pairedUserIDs
    notes
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateFormLogMutationVariables,
  APITypes.UpdateFormLogMutation
>;
export const deleteFormLog = /* GraphQL */ `mutation DeleteFormLog(
  $input: DeleteFormLogInput!
  $condition: ModelFormLogConditionInput
) {
  deleteFormLog(input: $input, condition: $condition) {
    id
    responses {
      questionIndex
      type
      responses
      __typename
    }
    inputformID
    departmentID
    userID
    pairedDepID
    pairedUserIDs
    notes
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteFormLogMutationVariables,
  APITypes.DeleteFormLogMutation
>;
export const createInputForm = /* GraphQL */ `mutation CreateInputForm(
  $input: CreateInputFormInput!
  $condition: ModelInputFormConditionInput
) {
  createInputForm(input: $input, condition: $condition) {
    id
    name
    items {
      index
      type
      question
      description
      answers
      correctAnswer
      __typename
    }
    FormLogs {
      items {
        id
        responses {
          questionIndex
          type
          responses
          __typename
        }
        inputformID
        departmentID
        userID
        pairedDepID
        pairedUserIDs
        notes
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    departmentID
    pairedDepIDs
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateInputFormMutationVariables,
  APITypes.CreateInputFormMutation
>;
export const updateInputForm = /* GraphQL */ `mutation UpdateInputForm(
  $input: UpdateInputFormInput!
  $condition: ModelInputFormConditionInput
) {
  updateInputForm(input: $input, condition: $condition) {
    id
    name
    items {
      index
      type
      question
      description
      answers
      correctAnswer
      __typename
    }
    FormLogs {
      items {
        id
        responses {
          questionIndex
          type
          responses
          __typename
        }
        inputformID
        departmentID
        userID
        pairedDepID
        pairedUserIDs
        notes
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    departmentID
    pairedDepIDs
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateInputFormMutationVariables,
  APITypes.UpdateInputFormMutation
>;
export const deleteInputForm = /* GraphQL */ `mutation DeleteInputForm(
  $input: DeleteInputFormInput!
  $condition: ModelInputFormConditionInput
) {
  deleteInputForm(input: $input, condition: $condition) {
    id
    name
    items {
      index
      type
      question
      description
      answers
      correctAnswer
      __typename
    }
    FormLogs {
      items {
        id
        responses {
          questionIndex
          type
          responses
          __typename
        }
        inputformID
        departmentID
        userID
        pairedDepID
        pairedUserIDs
        notes
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    departmentID
    pairedDepIDs
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteInputFormMutationVariables,
  APITypes.DeleteInputFormMutation
>;
export const createNotification = /* GraphQL */ `mutation CreateNotification(
  $input: CreateNotificationInput!
  $condition: ModelNotificationConditionInput
) {
  createNotification(input: $input, condition: $condition) {
    id
    type
    title
    message
    timestamp
    expirationTimestamp
    deadlineTimestamp
    isReadIDs
    isAckIDs
    imageURLs
    videoURLs
    fileURLs
    taggedProtocols
    questions {
      question
      options
      answer
      taggedProtocol
      __typename
    }
    formQuestions {
      index
      type
      question
      description
      answers
      correctAnswer
      __typename
    }
    isPush
    isPinRequired
    departmentID
    pairedDepIDs
    Groups {
      items {
        id
        notificationId
        groupId
        notification {
          id
          type
          title
          message
          timestamp
          expirationTimestamp
          deadlineTimestamp
          isReadIDs
          isAckIDs
          imageURLs
          videoURLs
          fileURLs
          taggedProtocols
          isPush
          isPinRequired
          departmentID
          pairedDepIDs
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        group {
          id
          name
          type
          departmentID
          pairedDepIDs
          pairedUserIDs
          isAutoGen
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdBy
    modifiedBy
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateNotificationMutationVariables,
  APITypes.CreateNotificationMutation
>;
export const updateNotification = /* GraphQL */ `mutation UpdateNotification(
  $input: UpdateNotificationInput!
  $condition: ModelNotificationConditionInput
) {
  updateNotification(input: $input, condition: $condition) {
    id
    type
    title
    message
    timestamp
    expirationTimestamp
    deadlineTimestamp
    isReadIDs
    isAckIDs
    imageURLs
    videoURLs
    fileURLs
    taggedProtocols
    questions {
      question
      options
      answer
      taggedProtocol
      __typename
    }
    formQuestions {
      index
      type
      question
      description
      answers
      correctAnswer
      __typename
    }
    isPush
    isPinRequired
    departmentID
    pairedDepIDs
    Groups {
      items {
        id
        notificationId
        groupId
        notification {
          id
          type
          title
          message
          timestamp
          expirationTimestamp
          deadlineTimestamp
          isReadIDs
          isAckIDs
          imageURLs
          videoURLs
          fileURLs
          taggedProtocols
          isPush
          isPinRequired
          departmentID
          pairedDepIDs
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        group {
          id
          name
          type
          departmentID
          pairedDepIDs
          pairedUserIDs
          isAutoGen
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdBy
    modifiedBy
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateNotificationMutationVariables,
  APITypes.UpdateNotificationMutation
>;
export const deleteNotification = /* GraphQL */ `mutation DeleteNotification(
  $input: DeleteNotificationInput!
  $condition: ModelNotificationConditionInput
) {
  deleteNotification(input: $input, condition: $condition) {
    id
    type
    title
    message
    timestamp
    expirationTimestamp
    deadlineTimestamp
    isReadIDs
    isAckIDs
    imageURLs
    videoURLs
    fileURLs
    taggedProtocols
    questions {
      question
      options
      answer
      taggedProtocol
      __typename
    }
    formQuestions {
      index
      type
      question
      description
      answers
      correctAnswer
      __typename
    }
    isPush
    isPinRequired
    departmentID
    pairedDepIDs
    Groups {
      items {
        id
        notificationId
        groupId
        notification {
          id
          type
          title
          message
          timestamp
          expirationTimestamp
          deadlineTimestamp
          isReadIDs
          isAckIDs
          imageURLs
          videoURLs
          fileURLs
          taggedProtocols
          isPush
          isPinRequired
          departmentID
          pairedDepIDs
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        group {
          id
          name
          type
          departmentID
          pairedDepIDs
          pairedUserIDs
          isAutoGen
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdBy
    modifiedBy
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteNotificationMutationVariables,
  APITypes.DeleteNotificationMutation
>;
export const createNotifyACK = /* GraphQL */ `mutation CreateNotifyACK(
  $input: CreateNotifyACKInput!
  $condition: ModelNotifyACKConditionInput
) {
  createNotifyACK(input: $input, condition: $condition) {
    id
    ownerID
    userID
    status
    createdAt
    User {
      id
      firstName
      lastName
      type
      cognitoID
      indexedParentDepID
      departmentID
      depAdmins
      logs {
        items {
          id
          userId
          logId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      hashedPin
      saltPin
      pairedDepIDs
      oneDoseVersion
      lastActivity
      notificationTokens
      deviceIDs
      maxDevices
      allowAnalytics
      status
      FormLogs {
        items {
          id
          inputformID
          departmentID
          userID
          pairedDepID
          pairedUserIDs
          notes
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Reviewals {
        items {
          id
          title
          description
          reviewers
          status
          createdAt
          endedAt
          departmentID
          userID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Acknowledges {
        items {
          id
          reviewalID
          ownerID
          userID
          status
          isArchived
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      UserComments {
        items {
          id
          ownerID
          userID
          acknowledgeID
          message
          relatedItems
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      NotifyACKs {
        items {
          id
          ownerID
          userID
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateNotifyACKMutationVariables,
  APITypes.CreateNotifyACKMutation
>;
export const updateNotifyACK = /* GraphQL */ `mutation UpdateNotifyACK(
  $input: UpdateNotifyACKInput!
  $condition: ModelNotifyACKConditionInput
) {
  updateNotifyACK(input: $input, condition: $condition) {
    id
    ownerID
    userID
    status
    createdAt
    User {
      id
      firstName
      lastName
      type
      cognitoID
      indexedParentDepID
      departmentID
      depAdmins
      logs {
        items {
          id
          userId
          logId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      hashedPin
      saltPin
      pairedDepIDs
      oneDoseVersion
      lastActivity
      notificationTokens
      deviceIDs
      maxDevices
      allowAnalytics
      status
      FormLogs {
        items {
          id
          inputformID
          departmentID
          userID
          pairedDepID
          pairedUserIDs
          notes
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Reviewals {
        items {
          id
          title
          description
          reviewers
          status
          createdAt
          endedAt
          departmentID
          userID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Acknowledges {
        items {
          id
          reviewalID
          ownerID
          userID
          status
          isArchived
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      UserComments {
        items {
          id
          ownerID
          userID
          acknowledgeID
          message
          relatedItems
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      NotifyACKs {
        items {
          id
          ownerID
          userID
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateNotifyACKMutationVariables,
  APITypes.UpdateNotifyACKMutation
>;
export const deleteNotifyACK = /* GraphQL */ `mutation DeleteNotifyACK(
  $input: DeleteNotifyACKInput!
  $condition: ModelNotifyACKConditionInput
) {
  deleteNotifyACK(input: $input, condition: $condition) {
    id
    ownerID
    userID
    status
    createdAt
    User {
      id
      firstName
      lastName
      type
      cognitoID
      indexedParentDepID
      departmentID
      depAdmins
      logs {
        items {
          id
          userId
          logId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      hashedPin
      saltPin
      pairedDepIDs
      oneDoseVersion
      lastActivity
      notificationTokens
      deviceIDs
      maxDevices
      allowAnalytics
      status
      FormLogs {
        items {
          id
          inputformID
          departmentID
          userID
          pairedDepID
          pairedUserIDs
          notes
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Reviewals {
        items {
          id
          title
          description
          reviewers
          status
          createdAt
          endedAt
          departmentID
          userID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Acknowledges {
        items {
          id
          reviewalID
          ownerID
          userID
          status
          isArchived
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      UserComments {
        items {
          id
          ownerID
          userID
          acknowledgeID
          message
          relatedItems
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      NotifyACKs {
        items {
          id
          ownerID
          userID
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteNotifyACKMutationVariables,
  APITypes.DeleteNotifyACKMutation
>;
export const createGroup = /* GraphQL */ `mutation CreateGroup(
  $input: CreateGroupInput!
  $condition: ModelGroupConditionInput
) {
  createGroup(input: $input, condition: $condition) {
    id
    name
    type
    departmentID
    pairedDepIDs
    pairedUserIDs
    isAutoGen
    Department {
      id
      Categories {
        items {
          id
          name
          departmentID
          index
          pairedDepIDs
          parentCategoryID
          activeID
          status
          version
          createdBy
          modifiedBy
          isPublic
          isRestrictive
          color
          tags
          overrideID
          keychainID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          departmentCategoriesId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      OneWeights {
        items {
          id
          name
          serialNumber
          projectNumber
          lastCalibration
          type
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          departmentOneWeightsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      name
      location
      protocolVersions
      shiftTypes
      Medications {
        items {
          id
          name
          rxNormCode
          contraindication
          note
          warning
          route
          medClass
          action
          indication
          interaction
          onset
          duration
          departmentID
          rangeLow
          rangeHigh
          maxDose
          minDose
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Equipment {
        items {
          id
          name
          snomedCode
          departmentID
          title
          note
          warning
          instruction
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Forms {
        items {
          id
          name
          departmentID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Vitals {
        items {
          id
          title
          index
          departmentID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Electricals {
        items {
          id
          title
          departmentID
          activeID
          status
          version
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      ElectricalShocks {
        items {
          id
          title
          rxNormCode
          warning
          instruction
          note
          rangeHigh
          rangeLow
          departmentID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      cognitoID
      Users {
        items {
          id
          firstName
          lastName
          type
          cognitoID
          indexedParentDepID
          departmentID
          depAdmins
          hashedPin
          saltPin
          pairedDepIDs
          oneDoseVersion
          lastActivity
          notificationTokens
          deviceIDs
          maxDevices
          allowAnalytics
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Logs {
        items {
          id
          actualWeight
          ageValue
          protocolID
          departmentID
          oneweightID
          ambulanceID
          incidentNumber
          startTime
          endTime
          estimatedWeight
          patientSaved
          patientReleased
          arrivedAtHospital
          ageTimestamp
          userIDs
          estimatedSaved
          tags
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Ambulances {
        items {
          id
          name
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Drips {
        items {
          id
          name
          rxNormCode
          contraindication
          note
          warning
          route
          medClass
          action
          indication
          interaction
          onset
          duration
          departmentID
          rangeLow
          rangeHigh
          maxDose
          minDose
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      RequireSync {
        id
        sync
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      logoID
      userID
      neonateCutoff
      pediatricCutoff
      calculators
      adultRanges {
        label
        value
        __typename
      }
      softwarePlan
      Notifications {
        items {
          id
          type
          title
          message
          timestamp
          expirationTimestamp
          deadlineTimestamp
          isReadIDs
          isAckIDs
          imageURLs
          videoURLs
          fileURLs
          taggedProtocols
          isPush
          isPinRequired
          departmentID
          pairedDepIDs
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Contacts {
        items {
          id
          fullName
          title
          number
          note
          index
          departmentID
          pairedDepIDs
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      InputForms {
        items {
          id
          name
          departmentID
          pairedDepIDs
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      FormLogs {
        items {
          id
          inputformID
          departmentID
          userID
          pairedDepID
          pairedUserIDs
          notes
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Workbooks {
        items {
          id
          name
          workbookID
          fileSize
          aiPdfParserResults
          activeID
          createdBy
          modifiedBy
          status
          version
          pairedDepIDs
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      WeightObjects {
        items {
          id
          name
          weight
          createdBy
          modifiedBy
          version
          pairedDepIDs
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Keychains {
        items {
          id
          name
          hashedPin
          saltPin
          hashType
          version
          createdBy
          modifiedBy
          metaData
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Reviewals {
        items {
          id
          title
          description
          reviewers
          status
          createdAt
          endedAt
          departmentID
          userID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Groups {
        items {
          id
          name
          type
          departmentID
          pairedDepIDs
          pairedUserIDs
          isAutoGen
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      CPRAssists {
        items {
          id
          protocolIDs
          epiIDs
          defibIDs
          equipmentID
          departmentID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      subDepIDs
      parentDepID
      indexedParentDepID
      activeStatus
      agencyNumEMS
      stateIdEMS
      gnisCodeEMS
      uniqueCode
      uniquePublicURL
      hashedPin
      saltedPin
      keychainID
      isPublic
      infusionCalculation
      isPublicSignup
      config {
        isTopEnabled
        neonateCutoff
        pediatricCutoff
        calculators
        adultRanges {
          label
          value
          __typename
        }
        softwarePlan
        infusionCalculation
        isPublic
        realTimeUpdating
        epcrProvider
        oneweightEnabled
        ageFilterEnabled
        ageGroupFilterEnabled
        renewalDate
        infusionGTTS
        signedAgreement
        isToggleAdvancedFiltersEnabled
        premiumTrialExpiration
        estimationMethod
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      departmentRequireSyncId
      __typename
    }
    Notifications {
      items {
        id
        notificationId
        groupId
        notification {
          id
          type
          title
          message
          timestamp
          expirationTimestamp
          deadlineTimestamp
          isReadIDs
          isAckIDs
          imageURLs
          videoURLs
          fileURLs
          taggedProtocols
          isPush
          isPinRequired
          departmentID
          pairedDepIDs
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        group {
          id
          name
          type
          departmentID
          pairedDepIDs
          pairedUserIDs
          isAutoGen
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateGroupMutationVariables,
  APITypes.CreateGroupMutation
>;
export const updateGroup = /* GraphQL */ `mutation UpdateGroup(
  $input: UpdateGroupInput!
  $condition: ModelGroupConditionInput
) {
  updateGroup(input: $input, condition: $condition) {
    id
    name
    type
    departmentID
    pairedDepIDs
    pairedUserIDs
    isAutoGen
    Department {
      id
      Categories {
        items {
          id
          name
          departmentID
          index
          pairedDepIDs
          parentCategoryID
          activeID
          status
          version
          createdBy
          modifiedBy
          isPublic
          isRestrictive
          color
          tags
          overrideID
          keychainID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          departmentCategoriesId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      OneWeights {
        items {
          id
          name
          serialNumber
          projectNumber
          lastCalibration
          type
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          departmentOneWeightsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      name
      location
      protocolVersions
      shiftTypes
      Medications {
        items {
          id
          name
          rxNormCode
          contraindication
          note
          warning
          route
          medClass
          action
          indication
          interaction
          onset
          duration
          departmentID
          rangeLow
          rangeHigh
          maxDose
          minDose
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Equipment {
        items {
          id
          name
          snomedCode
          departmentID
          title
          note
          warning
          instruction
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Forms {
        items {
          id
          name
          departmentID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Vitals {
        items {
          id
          title
          index
          departmentID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Electricals {
        items {
          id
          title
          departmentID
          activeID
          status
          version
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      ElectricalShocks {
        items {
          id
          title
          rxNormCode
          warning
          instruction
          note
          rangeHigh
          rangeLow
          departmentID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      cognitoID
      Users {
        items {
          id
          firstName
          lastName
          type
          cognitoID
          indexedParentDepID
          departmentID
          depAdmins
          hashedPin
          saltPin
          pairedDepIDs
          oneDoseVersion
          lastActivity
          notificationTokens
          deviceIDs
          maxDevices
          allowAnalytics
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Logs {
        items {
          id
          actualWeight
          ageValue
          protocolID
          departmentID
          oneweightID
          ambulanceID
          incidentNumber
          startTime
          endTime
          estimatedWeight
          patientSaved
          patientReleased
          arrivedAtHospital
          ageTimestamp
          userIDs
          estimatedSaved
          tags
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Ambulances {
        items {
          id
          name
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Drips {
        items {
          id
          name
          rxNormCode
          contraindication
          note
          warning
          route
          medClass
          action
          indication
          interaction
          onset
          duration
          departmentID
          rangeLow
          rangeHigh
          maxDose
          minDose
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      RequireSync {
        id
        sync
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      logoID
      userID
      neonateCutoff
      pediatricCutoff
      calculators
      adultRanges {
        label
        value
        __typename
      }
      softwarePlan
      Notifications {
        items {
          id
          type
          title
          message
          timestamp
          expirationTimestamp
          deadlineTimestamp
          isReadIDs
          isAckIDs
          imageURLs
          videoURLs
          fileURLs
          taggedProtocols
          isPush
          isPinRequired
          departmentID
          pairedDepIDs
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Contacts {
        items {
          id
          fullName
          title
          number
          note
          index
          departmentID
          pairedDepIDs
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      InputForms {
        items {
          id
          name
          departmentID
          pairedDepIDs
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      FormLogs {
        items {
          id
          inputformID
          departmentID
          userID
          pairedDepID
          pairedUserIDs
          notes
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Workbooks {
        items {
          id
          name
          workbookID
          fileSize
          aiPdfParserResults
          activeID
          createdBy
          modifiedBy
          status
          version
          pairedDepIDs
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      WeightObjects {
        items {
          id
          name
          weight
          createdBy
          modifiedBy
          version
          pairedDepIDs
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Keychains {
        items {
          id
          name
          hashedPin
          saltPin
          hashType
          version
          createdBy
          modifiedBy
          metaData
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Reviewals {
        items {
          id
          title
          description
          reviewers
          status
          createdAt
          endedAt
          departmentID
          userID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Groups {
        items {
          id
          name
          type
          departmentID
          pairedDepIDs
          pairedUserIDs
          isAutoGen
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      CPRAssists {
        items {
          id
          protocolIDs
          epiIDs
          defibIDs
          equipmentID
          departmentID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      subDepIDs
      parentDepID
      indexedParentDepID
      activeStatus
      agencyNumEMS
      stateIdEMS
      gnisCodeEMS
      uniqueCode
      uniquePublicURL
      hashedPin
      saltedPin
      keychainID
      isPublic
      infusionCalculation
      isPublicSignup
      config {
        isTopEnabled
        neonateCutoff
        pediatricCutoff
        calculators
        adultRanges {
          label
          value
          __typename
        }
        softwarePlan
        infusionCalculation
        isPublic
        realTimeUpdating
        epcrProvider
        oneweightEnabled
        ageFilterEnabled
        ageGroupFilterEnabled
        renewalDate
        infusionGTTS
        signedAgreement
        isToggleAdvancedFiltersEnabled
        premiumTrialExpiration
        estimationMethod
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      departmentRequireSyncId
      __typename
    }
    Notifications {
      items {
        id
        notificationId
        groupId
        notification {
          id
          type
          title
          message
          timestamp
          expirationTimestamp
          deadlineTimestamp
          isReadIDs
          isAckIDs
          imageURLs
          videoURLs
          fileURLs
          taggedProtocols
          isPush
          isPinRequired
          departmentID
          pairedDepIDs
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        group {
          id
          name
          type
          departmentID
          pairedDepIDs
          pairedUserIDs
          isAutoGen
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateGroupMutationVariables,
  APITypes.UpdateGroupMutation
>;
export const deleteGroup = /* GraphQL */ `mutation DeleteGroup(
  $input: DeleteGroupInput!
  $condition: ModelGroupConditionInput
) {
  deleteGroup(input: $input, condition: $condition) {
    id
    name
    type
    departmentID
    pairedDepIDs
    pairedUserIDs
    isAutoGen
    Department {
      id
      Categories {
        items {
          id
          name
          departmentID
          index
          pairedDepIDs
          parentCategoryID
          activeID
          status
          version
          createdBy
          modifiedBy
          isPublic
          isRestrictive
          color
          tags
          overrideID
          keychainID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          departmentCategoriesId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      OneWeights {
        items {
          id
          name
          serialNumber
          projectNumber
          lastCalibration
          type
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          departmentOneWeightsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      name
      location
      protocolVersions
      shiftTypes
      Medications {
        items {
          id
          name
          rxNormCode
          contraindication
          note
          warning
          route
          medClass
          action
          indication
          interaction
          onset
          duration
          departmentID
          rangeLow
          rangeHigh
          maxDose
          minDose
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Equipment {
        items {
          id
          name
          snomedCode
          departmentID
          title
          note
          warning
          instruction
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Forms {
        items {
          id
          name
          departmentID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Vitals {
        items {
          id
          title
          index
          departmentID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Electricals {
        items {
          id
          title
          departmentID
          activeID
          status
          version
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      ElectricalShocks {
        items {
          id
          title
          rxNormCode
          warning
          instruction
          note
          rangeHigh
          rangeLow
          departmentID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      cognitoID
      Users {
        items {
          id
          firstName
          lastName
          type
          cognitoID
          indexedParentDepID
          departmentID
          depAdmins
          hashedPin
          saltPin
          pairedDepIDs
          oneDoseVersion
          lastActivity
          notificationTokens
          deviceIDs
          maxDevices
          allowAnalytics
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Logs {
        items {
          id
          actualWeight
          ageValue
          protocolID
          departmentID
          oneweightID
          ambulanceID
          incidentNumber
          startTime
          endTime
          estimatedWeight
          patientSaved
          patientReleased
          arrivedAtHospital
          ageTimestamp
          userIDs
          estimatedSaved
          tags
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Ambulances {
        items {
          id
          name
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Drips {
        items {
          id
          name
          rxNormCode
          contraindication
          note
          warning
          route
          medClass
          action
          indication
          interaction
          onset
          duration
          departmentID
          rangeLow
          rangeHigh
          maxDose
          minDose
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      RequireSync {
        id
        sync
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      logoID
      userID
      neonateCutoff
      pediatricCutoff
      calculators
      adultRanges {
        label
        value
        __typename
      }
      softwarePlan
      Notifications {
        items {
          id
          type
          title
          message
          timestamp
          expirationTimestamp
          deadlineTimestamp
          isReadIDs
          isAckIDs
          imageURLs
          videoURLs
          fileURLs
          taggedProtocols
          isPush
          isPinRequired
          departmentID
          pairedDepIDs
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Contacts {
        items {
          id
          fullName
          title
          number
          note
          index
          departmentID
          pairedDepIDs
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      InputForms {
        items {
          id
          name
          departmentID
          pairedDepIDs
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      FormLogs {
        items {
          id
          inputformID
          departmentID
          userID
          pairedDepID
          pairedUserIDs
          notes
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Workbooks {
        items {
          id
          name
          workbookID
          fileSize
          aiPdfParserResults
          activeID
          createdBy
          modifiedBy
          status
          version
          pairedDepIDs
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      WeightObjects {
        items {
          id
          name
          weight
          createdBy
          modifiedBy
          version
          pairedDepIDs
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Keychains {
        items {
          id
          name
          hashedPin
          saltPin
          hashType
          version
          createdBy
          modifiedBy
          metaData
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Reviewals {
        items {
          id
          title
          description
          reviewers
          status
          createdAt
          endedAt
          departmentID
          userID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Groups {
        items {
          id
          name
          type
          departmentID
          pairedDepIDs
          pairedUserIDs
          isAutoGen
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      CPRAssists {
        items {
          id
          protocolIDs
          epiIDs
          defibIDs
          equipmentID
          departmentID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      subDepIDs
      parentDepID
      indexedParentDepID
      activeStatus
      agencyNumEMS
      stateIdEMS
      gnisCodeEMS
      uniqueCode
      uniquePublicURL
      hashedPin
      saltedPin
      keychainID
      isPublic
      infusionCalculation
      isPublicSignup
      config {
        isTopEnabled
        neonateCutoff
        pediatricCutoff
        calculators
        adultRanges {
          label
          value
          __typename
        }
        softwarePlan
        infusionCalculation
        isPublic
        realTimeUpdating
        epcrProvider
        oneweightEnabled
        ageFilterEnabled
        ageGroupFilterEnabled
        renewalDate
        infusionGTTS
        signedAgreement
        isToggleAdvancedFiltersEnabled
        premiumTrialExpiration
        estimationMethod
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      departmentRequireSyncId
      __typename
    }
    Notifications {
      items {
        id
        notificationId
        groupId
        notification {
          id
          type
          title
          message
          timestamp
          expirationTimestamp
          deadlineTimestamp
          isReadIDs
          isAckIDs
          imageURLs
          videoURLs
          fileURLs
          taggedProtocols
          isPush
          isPinRequired
          departmentID
          pairedDepIDs
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        group {
          id
          name
          type
          departmentID
          pairedDepIDs
          pairedUserIDs
          isAutoGen
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteGroupMutationVariables,
  APITypes.DeleteGroupMutation
>;
export const createDrip = /* GraphQL */ `mutation CreateDrip(
  $input: CreateDripInput!
  $condition: ModelDripConditionInput
) {
  createDrip(input: $input, condition: $condition) {
    id
    name
    rxNormCode
    contraindication
    note
    warning
    route
    nemsisRoutes {
      route
      code
      __typename
    }
    concentration {
      firstAmnt
      firstUnit
      secAmnt
      secUnit
      color
      __typename
    }
    medClass
    action
    indication
    interaction
    onset
    duration
    dripOptions {
      protocolID
      options {
        doseID
        basis
        rangeLow
        rangeHigh
        route
        nemsisRoutes {
          route
          code
          __typename
        }
        title
        warning
        instruction
        note
        maxDose
        minDose
        maxTotalDose
        calcMax
        calcMin
        index
        ageLow
        ageHigh
        ageGroup
        repeatTime
        __typename
      }
      __typename
    }
    departmentID
    rangeLow
    rangeHigh
    maxDose
    minDose
    activeID
    overrideID
    status
    version
    createdBy
    modifiedBy
    metaData {
      parentID
      fullName
      nickName
      brandNames
      maxDose
      minDose
      absMaxDose
      absMinDose
      releaseNotes
      __typename
    }
    InfusionDoses {
      items {
        id
        basis
        rangeLow
        rangeHigh
        route
        nemsisRoutes {
          route
          code
          __typename
        }
        title
        warning
        instruction
        note
        maxDose
        minDose
        maxTotalDose
        calcMax
        calcMin
        index
        ageLow
        ageHigh
        ageGroup
        repeatTime
        incrementAmnt
        departmentID
        protocolID
        dripID
        activeID
        overrideID
        status
        version
        createdBy
        modifiedBy
        createdAt
        Protocol {
          id
          name
          pdfID
          categoryID
          pairedProtocols
          medicationIDs
          equipmentIDs
          electricalIDs
          dripIDs
          formIDs
          index
          nickname
          pairedDepIDs
          rangeLow
          rangeHigh
          ageLow
          ageHigh
          ageGroup
          status
          version
          pdfVersion
          activeID
          overrideID
          privatePin
          keychainID
          createdBy
          modifiedBy
          isPublic
          isRestrictive
          color
          tags
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Drip {
          id
          name
          rxNormCode
          contraindication
          note
          warning
          route
          medClass
          action
          indication
          interaction
          onset
          duration
          departmentID
          rangeLow
          rangeHigh
          maxDose
          minDose
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Concentrations {
      items {
        id
        firstAmnt
        firstUnit
        secAmnt
        secUnit
        color
        departmentID
        medicationID
        dripID
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateDripMutationVariables,
  APITypes.CreateDripMutation
>;
export const updateDrip = /* GraphQL */ `mutation UpdateDrip(
  $input: UpdateDripInput!
  $condition: ModelDripConditionInput
) {
  updateDrip(input: $input, condition: $condition) {
    id
    name
    rxNormCode
    contraindication
    note
    warning
    route
    nemsisRoutes {
      route
      code
      __typename
    }
    concentration {
      firstAmnt
      firstUnit
      secAmnt
      secUnit
      color
      __typename
    }
    medClass
    action
    indication
    interaction
    onset
    duration
    dripOptions {
      protocolID
      options {
        doseID
        basis
        rangeLow
        rangeHigh
        route
        nemsisRoutes {
          route
          code
          __typename
        }
        title
        warning
        instruction
        note
        maxDose
        minDose
        maxTotalDose
        calcMax
        calcMin
        index
        ageLow
        ageHigh
        ageGroup
        repeatTime
        __typename
      }
      __typename
    }
    departmentID
    rangeLow
    rangeHigh
    maxDose
    minDose
    activeID
    overrideID
    status
    version
    createdBy
    modifiedBy
    metaData {
      parentID
      fullName
      nickName
      brandNames
      maxDose
      minDose
      absMaxDose
      absMinDose
      releaseNotes
      __typename
    }
    InfusionDoses {
      items {
        id
        basis
        rangeLow
        rangeHigh
        route
        nemsisRoutes {
          route
          code
          __typename
        }
        title
        warning
        instruction
        note
        maxDose
        minDose
        maxTotalDose
        calcMax
        calcMin
        index
        ageLow
        ageHigh
        ageGroup
        repeatTime
        incrementAmnt
        departmentID
        protocolID
        dripID
        activeID
        overrideID
        status
        version
        createdBy
        modifiedBy
        createdAt
        Protocol {
          id
          name
          pdfID
          categoryID
          pairedProtocols
          medicationIDs
          equipmentIDs
          electricalIDs
          dripIDs
          formIDs
          index
          nickname
          pairedDepIDs
          rangeLow
          rangeHigh
          ageLow
          ageHigh
          ageGroup
          status
          version
          pdfVersion
          activeID
          overrideID
          privatePin
          keychainID
          createdBy
          modifiedBy
          isPublic
          isRestrictive
          color
          tags
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Drip {
          id
          name
          rxNormCode
          contraindication
          note
          warning
          route
          medClass
          action
          indication
          interaction
          onset
          duration
          departmentID
          rangeLow
          rangeHigh
          maxDose
          minDose
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Concentrations {
      items {
        id
        firstAmnt
        firstUnit
        secAmnt
        secUnit
        color
        departmentID
        medicationID
        dripID
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateDripMutationVariables,
  APITypes.UpdateDripMutation
>;
export const deleteDrip = /* GraphQL */ `mutation DeleteDrip(
  $input: DeleteDripInput!
  $condition: ModelDripConditionInput
) {
  deleteDrip(input: $input, condition: $condition) {
    id
    name
    rxNormCode
    contraindication
    note
    warning
    route
    nemsisRoutes {
      route
      code
      __typename
    }
    concentration {
      firstAmnt
      firstUnit
      secAmnt
      secUnit
      color
      __typename
    }
    medClass
    action
    indication
    interaction
    onset
    duration
    dripOptions {
      protocolID
      options {
        doseID
        basis
        rangeLow
        rangeHigh
        route
        nemsisRoutes {
          route
          code
          __typename
        }
        title
        warning
        instruction
        note
        maxDose
        minDose
        maxTotalDose
        calcMax
        calcMin
        index
        ageLow
        ageHigh
        ageGroup
        repeatTime
        __typename
      }
      __typename
    }
    departmentID
    rangeLow
    rangeHigh
    maxDose
    minDose
    activeID
    overrideID
    status
    version
    createdBy
    modifiedBy
    metaData {
      parentID
      fullName
      nickName
      brandNames
      maxDose
      minDose
      absMaxDose
      absMinDose
      releaseNotes
      __typename
    }
    InfusionDoses {
      items {
        id
        basis
        rangeLow
        rangeHigh
        route
        nemsisRoutes {
          route
          code
          __typename
        }
        title
        warning
        instruction
        note
        maxDose
        minDose
        maxTotalDose
        calcMax
        calcMin
        index
        ageLow
        ageHigh
        ageGroup
        repeatTime
        incrementAmnt
        departmentID
        protocolID
        dripID
        activeID
        overrideID
        status
        version
        createdBy
        modifiedBy
        createdAt
        Protocol {
          id
          name
          pdfID
          categoryID
          pairedProtocols
          medicationIDs
          equipmentIDs
          electricalIDs
          dripIDs
          formIDs
          index
          nickname
          pairedDepIDs
          rangeLow
          rangeHigh
          ageLow
          ageHigh
          ageGroup
          status
          version
          pdfVersion
          activeID
          overrideID
          privatePin
          keychainID
          createdBy
          modifiedBy
          isPublic
          isRestrictive
          color
          tags
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Drip {
          id
          name
          rxNormCode
          contraindication
          note
          warning
          route
          medClass
          action
          indication
          interaction
          onset
          duration
          departmentID
          rangeLow
          rangeHigh
          maxDose
          minDose
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Concentrations {
      items {
        id
        firstAmnt
        firstUnit
        secAmnt
        secUnit
        color
        departmentID
        medicationID
        dripID
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteDripMutationVariables,
  APITypes.DeleteDripMutation
>;
export const createInfusionDose = /* GraphQL */ `mutation CreateInfusionDose(
  $input: CreateInfusionDoseInput!
  $condition: ModelInfusionDoseConditionInput
) {
  createInfusionDose(input: $input, condition: $condition) {
    id
    basis
    rangeLow
    rangeHigh
    route
    nemsisRoutes {
      route
      code
      __typename
    }
    title
    warning
    instruction
    note
    maxDose
    minDose
    maxTotalDose
    calcMax
    calcMin
    index
    ageLow
    ageHigh
    ageGroup
    repeatTime
    incrementAmnt
    departmentID
    protocolID
    dripID
    activeID
    overrideID
    status
    version
    createdBy
    modifiedBy
    createdAt
    Protocol {
      id
      name
      pdfID
      categoryID
      pairedProtocols
      medicationIDs
      equipmentIDs
      electricalIDs
      dripIDs
      formIDs
      index
      nickname
      pairedDepIDs
      rangeLow
      rangeHigh
      ageLow
      ageHigh
      ageGroup
      status
      version
      pdfVersion
      activeID
      overrideID
      privatePin
      keychainID
      createdBy
      modifiedBy
      isPublic
      isRestrictive
      color
      tags
      departmentID
      MedicationDoses {
        items {
          id
          basis
          rangeLow
          rangeHigh
          route
          title
          warning
          instruction
          note
          maxDose
          minDose
          maxTotalDose
          calcMax
          calcMin
          index
          ageLow
          ageHigh
          ageGroup
          repeatTime
          incrementAmnt
          departmentID
          protocolID
          medicationID
          cprAssistID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      ElectricalDoses {
        items {
          id
          basis
          rangeLow
          rangeHigh
          title
          warning
          instruction
          note
          maxDose
          minDose
          maxTotalDose
          calcMax
          calcMin
          index
          ageLow
          ageHigh
          ageGroup
          repeatTime
          incrementAmnt
          departmentID
          protocolID
          electricalID
          cprAssistID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      DripDoses {
        items {
          id
          basis
          rangeLow
          rangeHigh
          route
          title
          warning
          instruction
          note
          maxDose
          minDose
          maxTotalDose
          calcMax
          calcMin
          index
          ageLow
          ageHigh
          ageGroup
          repeatTime
          incrementAmnt
          departmentID
          protocolID
          dripID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    Drip {
      id
      name
      rxNormCode
      contraindication
      note
      warning
      route
      nemsisRoutes {
        route
        code
        __typename
      }
      concentration {
        firstAmnt
        firstUnit
        secAmnt
        secUnit
        color
        __typename
      }
      medClass
      action
      indication
      interaction
      onset
      duration
      dripOptions {
        protocolID
        options {
          doseID
          basis
          rangeLow
          rangeHigh
          route
          title
          warning
          instruction
          note
          maxDose
          minDose
          maxTotalDose
          calcMax
          calcMin
          index
          ageLow
          ageHigh
          ageGroup
          repeatTime
          __typename
        }
        __typename
      }
      departmentID
      rangeLow
      rangeHigh
      maxDose
      minDose
      activeID
      overrideID
      status
      version
      createdBy
      modifiedBy
      metaData {
        parentID
        fullName
        nickName
        brandNames
        maxDose
        minDose
        absMaxDose
        absMinDose
        releaseNotes
        __typename
      }
      InfusionDoses {
        items {
          id
          basis
          rangeLow
          rangeHigh
          route
          title
          warning
          instruction
          note
          maxDose
          minDose
          maxTotalDose
          calcMax
          calcMin
          index
          ageLow
          ageHigh
          ageGroup
          repeatTime
          incrementAmnt
          departmentID
          protocolID
          dripID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Concentrations {
        items {
          id
          firstAmnt
          firstUnit
          secAmnt
          secUnit
          color
          departmentID
          medicationID
          dripID
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateInfusionDoseMutationVariables,
  APITypes.CreateInfusionDoseMutation
>;
export const updateInfusionDose = /* GraphQL */ `mutation UpdateInfusionDose(
  $input: UpdateInfusionDoseInput!
  $condition: ModelInfusionDoseConditionInput
) {
  updateInfusionDose(input: $input, condition: $condition) {
    id
    basis
    rangeLow
    rangeHigh
    route
    nemsisRoutes {
      route
      code
      __typename
    }
    title
    warning
    instruction
    note
    maxDose
    minDose
    maxTotalDose
    calcMax
    calcMin
    index
    ageLow
    ageHigh
    ageGroup
    repeatTime
    incrementAmnt
    departmentID
    protocolID
    dripID
    activeID
    overrideID
    status
    version
    createdBy
    modifiedBy
    createdAt
    Protocol {
      id
      name
      pdfID
      categoryID
      pairedProtocols
      medicationIDs
      equipmentIDs
      electricalIDs
      dripIDs
      formIDs
      index
      nickname
      pairedDepIDs
      rangeLow
      rangeHigh
      ageLow
      ageHigh
      ageGroup
      status
      version
      pdfVersion
      activeID
      overrideID
      privatePin
      keychainID
      createdBy
      modifiedBy
      isPublic
      isRestrictive
      color
      tags
      departmentID
      MedicationDoses {
        items {
          id
          basis
          rangeLow
          rangeHigh
          route
          title
          warning
          instruction
          note
          maxDose
          minDose
          maxTotalDose
          calcMax
          calcMin
          index
          ageLow
          ageHigh
          ageGroup
          repeatTime
          incrementAmnt
          departmentID
          protocolID
          medicationID
          cprAssistID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      ElectricalDoses {
        items {
          id
          basis
          rangeLow
          rangeHigh
          title
          warning
          instruction
          note
          maxDose
          minDose
          maxTotalDose
          calcMax
          calcMin
          index
          ageLow
          ageHigh
          ageGroup
          repeatTime
          incrementAmnt
          departmentID
          protocolID
          electricalID
          cprAssistID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      DripDoses {
        items {
          id
          basis
          rangeLow
          rangeHigh
          route
          title
          warning
          instruction
          note
          maxDose
          minDose
          maxTotalDose
          calcMax
          calcMin
          index
          ageLow
          ageHigh
          ageGroup
          repeatTime
          incrementAmnt
          departmentID
          protocolID
          dripID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    Drip {
      id
      name
      rxNormCode
      contraindication
      note
      warning
      route
      nemsisRoutes {
        route
        code
        __typename
      }
      concentration {
        firstAmnt
        firstUnit
        secAmnt
        secUnit
        color
        __typename
      }
      medClass
      action
      indication
      interaction
      onset
      duration
      dripOptions {
        protocolID
        options {
          doseID
          basis
          rangeLow
          rangeHigh
          route
          title
          warning
          instruction
          note
          maxDose
          minDose
          maxTotalDose
          calcMax
          calcMin
          index
          ageLow
          ageHigh
          ageGroup
          repeatTime
          __typename
        }
        __typename
      }
      departmentID
      rangeLow
      rangeHigh
      maxDose
      minDose
      activeID
      overrideID
      status
      version
      createdBy
      modifiedBy
      metaData {
        parentID
        fullName
        nickName
        brandNames
        maxDose
        minDose
        absMaxDose
        absMinDose
        releaseNotes
        __typename
      }
      InfusionDoses {
        items {
          id
          basis
          rangeLow
          rangeHigh
          route
          title
          warning
          instruction
          note
          maxDose
          minDose
          maxTotalDose
          calcMax
          calcMin
          index
          ageLow
          ageHigh
          ageGroup
          repeatTime
          incrementAmnt
          departmentID
          protocolID
          dripID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Concentrations {
        items {
          id
          firstAmnt
          firstUnit
          secAmnt
          secUnit
          color
          departmentID
          medicationID
          dripID
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateInfusionDoseMutationVariables,
  APITypes.UpdateInfusionDoseMutation
>;
export const deleteInfusionDose = /* GraphQL */ `mutation DeleteInfusionDose(
  $input: DeleteInfusionDoseInput!
  $condition: ModelInfusionDoseConditionInput
) {
  deleteInfusionDose(input: $input, condition: $condition) {
    id
    basis
    rangeLow
    rangeHigh
    route
    nemsisRoutes {
      route
      code
      __typename
    }
    title
    warning
    instruction
    note
    maxDose
    minDose
    maxTotalDose
    calcMax
    calcMin
    index
    ageLow
    ageHigh
    ageGroup
    repeatTime
    incrementAmnt
    departmentID
    protocolID
    dripID
    activeID
    overrideID
    status
    version
    createdBy
    modifiedBy
    createdAt
    Protocol {
      id
      name
      pdfID
      categoryID
      pairedProtocols
      medicationIDs
      equipmentIDs
      electricalIDs
      dripIDs
      formIDs
      index
      nickname
      pairedDepIDs
      rangeLow
      rangeHigh
      ageLow
      ageHigh
      ageGroup
      status
      version
      pdfVersion
      activeID
      overrideID
      privatePin
      keychainID
      createdBy
      modifiedBy
      isPublic
      isRestrictive
      color
      tags
      departmentID
      MedicationDoses {
        items {
          id
          basis
          rangeLow
          rangeHigh
          route
          title
          warning
          instruction
          note
          maxDose
          minDose
          maxTotalDose
          calcMax
          calcMin
          index
          ageLow
          ageHigh
          ageGroup
          repeatTime
          incrementAmnt
          departmentID
          protocolID
          medicationID
          cprAssistID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      ElectricalDoses {
        items {
          id
          basis
          rangeLow
          rangeHigh
          title
          warning
          instruction
          note
          maxDose
          minDose
          maxTotalDose
          calcMax
          calcMin
          index
          ageLow
          ageHigh
          ageGroup
          repeatTime
          incrementAmnt
          departmentID
          protocolID
          electricalID
          cprAssistID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      DripDoses {
        items {
          id
          basis
          rangeLow
          rangeHigh
          route
          title
          warning
          instruction
          note
          maxDose
          minDose
          maxTotalDose
          calcMax
          calcMin
          index
          ageLow
          ageHigh
          ageGroup
          repeatTime
          incrementAmnt
          departmentID
          protocolID
          dripID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    Drip {
      id
      name
      rxNormCode
      contraindication
      note
      warning
      route
      nemsisRoutes {
        route
        code
        __typename
      }
      concentration {
        firstAmnt
        firstUnit
        secAmnt
        secUnit
        color
        __typename
      }
      medClass
      action
      indication
      interaction
      onset
      duration
      dripOptions {
        protocolID
        options {
          doseID
          basis
          rangeLow
          rangeHigh
          route
          title
          warning
          instruction
          note
          maxDose
          minDose
          maxTotalDose
          calcMax
          calcMin
          index
          ageLow
          ageHigh
          ageGroup
          repeatTime
          __typename
        }
        __typename
      }
      departmentID
      rangeLow
      rangeHigh
      maxDose
      minDose
      activeID
      overrideID
      status
      version
      createdBy
      modifiedBy
      metaData {
        parentID
        fullName
        nickName
        brandNames
        maxDose
        minDose
        absMaxDose
        absMinDose
        releaseNotes
        __typename
      }
      InfusionDoses {
        items {
          id
          basis
          rangeLow
          rangeHigh
          route
          title
          warning
          instruction
          note
          maxDose
          minDose
          maxTotalDose
          calcMax
          calcMin
          index
          ageLow
          ageHigh
          ageGroup
          repeatTime
          incrementAmnt
          departmentID
          protocolID
          dripID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Concentrations {
        items {
          id
          firstAmnt
          firstUnit
          secAmnt
          secUnit
          color
          departmentID
          medicationID
          dripID
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteInfusionDoseMutationVariables,
  APITypes.DeleteInfusionDoseMutation
>;
export const createEquipment = /* GraphQL */ `mutation CreateEquipment(
  $input: CreateEquipmentInput!
  $condition: ModelEquipmentConditionInput
) {
  createEquipment(input: $input, condition: $condition) {
    id
    name
    snomedCode
    optionItems {
      id
      amnt
      amntHigh
      rangeLow
      rangeHigh
      ageLow
      ageHigh
      index
      __typename
    }
    departmentID
    title
    note
    warning
    instruction
    activeID
    overrideID
    status
    version
    createdBy
    modifiedBy
    metaData {
      parentID
      fullName
      nickName
      brandNames
      maxDose
      minDose
      absMaxDose
      absMinDose
      releaseNotes
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateEquipmentMutationVariables,
  APITypes.CreateEquipmentMutation
>;
export const updateEquipment = /* GraphQL */ `mutation UpdateEquipment(
  $input: UpdateEquipmentInput!
  $condition: ModelEquipmentConditionInput
) {
  updateEquipment(input: $input, condition: $condition) {
    id
    name
    snomedCode
    optionItems {
      id
      amnt
      amntHigh
      rangeLow
      rangeHigh
      ageLow
      ageHigh
      index
      __typename
    }
    departmentID
    title
    note
    warning
    instruction
    activeID
    overrideID
    status
    version
    createdBy
    modifiedBy
    metaData {
      parentID
      fullName
      nickName
      brandNames
      maxDose
      minDose
      absMaxDose
      absMinDose
      releaseNotes
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateEquipmentMutationVariables,
  APITypes.UpdateEquipmentMutation
>;
export const deleteEquipment = /* GraphQL */ `mutation DeleteEquipment(
  $input: DeleteEquipmentInput!
  $condition: ModelEquipmentConditionInput
) {
  deleteEquipment(input: $input, condition: $condition) {
    id
    name
    snomedCode
    optionItems {
      id
      amnt
      amntHigh
      rangeLow
      rangeHigh
      ageLow
      ageHigh
      index
      __typename
    }
    departmentID
    title
    note
    warning
    instruction
    activeID
    overrideID
    status
    version
    createdBy
    modifiedBy
    metaData {
      parentID
      fullName
      nickName
      brandNames
      maxDose
      minDose
      absMaxDose
      absMinDose
      releaseNotes
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteEquipmentMutationVariables,
  APITypes.DeleteEquipmentMutation
>;
export const createMedication = /* GraphQL */ `mutation CreateMedication(
  $input: CreateMedicationInput!
  $condition: ModelMedicationConditionInput
) {
  createMedication(input: $input, condition: $condition) {
    id
    name
    rxNormCode
    contraindication
    note
    warning
    route
    nemsisRoutes {
      route
      code
      __typename
    }
    concentration {
      firstAmnt
      firstUnit
      secAmnt
      secUnit
      color
      __typename
    }
    medClass
    action
    indication
    interaction
    onset
    duration
    departmentID
    protocolOptions {
      protocolID
      options {
        doseID
        basis
        rangeLow
        rangeHigh
        route
        nemsisRoutes {
          route
          code
          __typename
        }
        title
        warning
        instruction
        note
        maxDose
        minDose
        maxTotalDose
        calcMax
        calcMin
        index
        ageLow
        ageHigh
        ageGroup
        repeatTime
        __typename
      }
      __typename
    }
    rangeLow
    rangeHigh
    maxDose
    minDose
    activeID
    overrideID
    status
    version
    createdBy
    modifiedBy
    metaData {
      parentID
      fullName
      nickName
      brandNames
      maxDose
      minDose
      absMaxDose
      absMinDose
      releaseNotes
      __typename
    }
    MedicationDoses {
      items {
        id
        basis
        rangeLow
        rangeHigh
        route
        nemsisRoutes {
          route
          code
          __typename
        }
        title
        warning
        instruction
        note
        maxDose
        minDose
        maxTotalDose
        calcMax
        calcMin
        index
        ageLow
        ageHigh
        ageGroup
        repeatTime
        incrementAmnt
        departmentID
        protocolID
        medicationID
        cprAssistID
        activeID
        overrideID
        status
        version
        createdBy
        modifiedBy
        createdAt
        Protocol {
          id
          name
          pdfID
          categoryID
          pairedProtocols
          medicationIDs
          equipmentIDs
          electricalIDs
          dripIDs
          formIDs
          index
          nickname
          pairedDepIDs
          rangeLow
          rangeHigh
          ageLow
          ageHigh
          ageGroup
          status
          version
          pdfVersion
          activeID
          overrideID
          privatePin
          keychainID
          createdBy
          modifiedBy
          isPublic
          isRestrictive
          color
          tags
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Medication {
          id
          name
          rxNormCode
          contraindication
          note
          warning
          route
          medClass
          action
          indication
          interaction
          onset
          duration
          departmentID
          rangeLow
          rangeHigh
          maxDose
          minDose
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        CPRAssist {
          id
          protocolIDs
          epiIDs
          defibIDs
          equipmentID
          departmentID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Concentrations {
      items {
        id
        firstAmnt
        firstUnit
        secAmnt
        secUnit
        color
        departmentID
        medicationID
        dripID
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateMedicationMutationVariables,
  APITypes.CreateMedicationMutation
>;
export const updateMedication = /* GraphQL */ `mutation UpdateMedication(
  $input: UpdateMedicationInput!
  $condition: ModelMedicationConditionInput
) {
  updateMedication(input: $input, condition: $condition) {
    id
    name
    rxNormCode
    contraindication
    note
    warning
    route
    nemsisRoutes {
      route
      code
      __typename
    }
    concentration {
      firstAmnt
      firstUnit
      secAmnt
      secUnit
      color
      __typename
    }
    medClass
    action
    indication
    interaction
    onset
    duration
    departmentID
    protocolOptions {
      protocolID
      options {
        doseID
        basis
        rangeLow
        rangeHigh
        route
        nemsisRoutes {
          route
          code
          __typename
        }
        title
        warning
        instruction
        note
        maxDose
        minDose
        maxTotalDose
        calcMax
        calcMin
        index
        ageLow
        ageHigh
        ageGroup
        repeatTime
        __typename
      }
      __typename
    }
    rangeLow
    rangeHigh
    maxDose
    minDose
    activeID
    overrideID
    status
    version
    createdBy
    modifiedBy
    metaData {
      parentID
      fullName
      nickName
      brandNames
      maxDose
      minDose
      absMaxDose
      absMinDose
      releaseNotes
      __typename
    }
    MedicationDoses {
      items {
        id
        basis
        rangeLow
        rangeHigh
        route
        nemsisRoutes {
          route
          code
          __typename
        }
        title
        warning
        instruction
        note
        maxDose
        minDose
        maxTotalDose
        calcMax
        calcMin
        index
        ageLow
        ageHigh
        ageGroup
        repeatTime
        incrementAmnt
        departmentID
        protocolID
        medicationID
        cprAssistID
        activeID
        overrideID
        status
        version
        createdBy
        modifiedBy
        createdAt
        Protocol {
          id
          name
          pdfID
          categoryID
          pairedProtocols
          medicationIDs
          equipmentIDs
          electricalIDs
          dripIDs
          formIDs
          index
          nickname
          pairedDepIDs
          rangeLow
          rangeHigh
          ageLow
          ageHigh
          ageGroup
          status
          version
          pdfVersion
          activeID
          overrideID
          privatePin
          keychainID
          createdBy
          modifiedBy
          isPublic
          isRestrictive
          color
          tags
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Medication {
          id
          name
          rxNormCode
          contraindication
          note
          warning
          route
          medClass
          action
          indication
          interaction
          onset
          duration
          departmentID
          rangeLow
          rangeHigh
          maxDose
          minDose
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        CPRAssist {
          id
          protocolIDs
          epiIDs
          defibIDs
          equipmentID
          departmentID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Concentrations {
      items {
        id
        firstAmnt
        firstUnit
        secAmnt
        secUnit
        color
        departmentID
        medicationID
        dripID
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateMedicationMutationVariables,
  APITypes.UpdateMedicationMutation
>;
export const deleteMedication = /* GraphQL */ `mutation DeleteMedication(
  $input: DeleteMedicationInput!
  $condition: ModelMedicationConditionInput
) {
  deleteMedication(input: $input, condition: $condition) {
    id
    name
    rxNormCode
    contraindication
    note
    warning
    route
    nemsisRoutes {
      route
      code
      __typename
    }
    concentration {
      firstAmnt
      firstUnit
      secAmnt
      secUnit
      color
      __typename
    }
    medClass
    action
    indication
    interaction
    onset
    duration
    departmentID
    protocolOptions {
      protocolID
      options {
        doseID
        basis
        rangeLow
        rangeHigh
        route
        nemsisRoutes {
          route
          code
          __typename
        }
        title
        warning
        instruction
        note
        maxDose
        minDose
        maxTotalDose
        calcMax
        calcMin
        index
        ageLow
        ageHigh
        ageGroup
        repeatTime
        __typename
      }
      __typename
    }
    rangeLow
    rangeHigh
    maxDose
    minDose
    activeID
    overrideID
    status
    version
    createdBy
    modifiedBy
    metaData {
      parentID
      fullName
      nickName
      brandNames
      maxDose
      minDose
      absMaxDose
      absMinDose
      releaseNotes
      __typename
    }
    MedicationDoses {
      items {
        id
        basis
        rangeLow
        rangeHigh
        route
        nemsisRoutes {
          route
          code
          __typename
        }
        title
        warning
        instruction
        note
        maxDose
        minDose
        maxTotalDose
        calcMax
        calcMin
        index
        ageLow
        ageHigh
        ageGroup
        repeatTime
        incrementAmnt
        departmentID
        protocolID
        medicationID
        cprAssistID
        activeID
        overrideID
        status
        version
        createdBy
        modifiedBy
        createdAt
        Protocol {
          id
          name
          pdfID
          categoryID
          pairedProtocols
          medicationIDs
          equipmentIDs
          electricalIDs
          dripIDs
          formIDs
          index
          nickname
          pairedDepIDs
          rangeLow
          rangeHigh
          ageLow
          ageHigh
          ageGroup
          status
          version
          pdfVersion
          activeID
          overrideID
          privatePin
          keychainID
          createdBy
          modifiedBy
          isPublic
          isRestrictive
          color
          tags
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Medication {
          id
          name
          rxNormCode
          contraindication
          note
          warning
          route
          medClass
          action
          indication
          interaction
          onset
          duration
          departmentID
          rangeLow
          rangeHigh
          maxDose
          minDose
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        CPRAssist {
          id
          protocolIDs
          epiIDs
          defibIDs
          equipmentID
          departmentID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Concentrations {
      items {
        id
        firstAmnt
        firstUnit
        secAmnt
        secUnit
        color
        departmentID
        medicationID
        dripID
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteMedicationMutationVariables,
  APITypes.DeleteMedicationMutation
>;
export const createMedicationDose = /* GraphQL */ `mutation CreateMedicationDose(
  $input: CreateMedicationDoseInput!
  $condition: ModelMedicationDoseConditionInput
) {
  createMedicationDose(input: $input, condition: $condition) {
    id
    basis
    rangeLow
    rangeHigh
    route
    nemsisRoutes {
      route
      code
      __typename
    }
    title
    warning
    instruction
    note
    maxDose
    minDose
    maxTotalDose
    calcMax
    calcMin
    index
    ageLow
    ageHigh
    ageGroup
    repeatTime
    incrementAmnt
    departmentID
    protocolID
    medicationID
    cprAssistID
    activeID
    overrideID
    status
    version
    createdBy
    modifiedBy
    createdAt
    Protocol {
      id
      name
      pdfID
      categoryID
      pairedProtocols
      medicationIDs
      equipmentIDs
      electricalIDs
      dripIDs
      formIDs
      index
      nickname
      pairedDepIDs
      rangeLow
      rangeHigh
      ageLow
      ageHigh
      ageGroup
      status
      version
      pdfVersion
      activeID
      overrideID
      privatePin
      keychainID
      createdBy
      modifiedBy
      isPublic
      isRestrictive
      color
      tags
      departmentID
      MedicationDoses {
        items {
          id
          basis
          rangeLow
          rangeHigh
          route
          title
          warning
          instruction
          note
          maxDose
          minDose
          maxTotalDose
          calcMax
          calcMin
          index
          ageLow
          ageHigh
          ageGroup
          repeatTime
          incrementAmnt
          departmentID
          protocolID
          medicationID
          cprAssistID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      ElectricalDoses {
        items {
          id
          basis
          rangeLow
          rangeHigh
          title
          warning
          instruction
          note
          maxDose
          minDose
          maxTotalDose
          calcMax
          calcMin
          index
          ageLow
          ageHigh
          ageGroup
          repeatTime
          incrementAmnt
          departmentID
          protocolID
          electricalID
          cprAssistID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      DripDoses {
        items {
          id
          basis
          rangeLow
          rangeHigh
          route
          title
          warning
          instruction
          note
          maxDose
          minDose
          maxTotalDose
          calcMax
          calcMin
          index
          ageLow
          ageHigh
          ageGroup
          repeatTime
          incrementAmnt
          departmentID
          protocolID
          dripID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    Medication {
      id
      name
      rxNormCode
      contraindication
      note
      warning
      route
      nemsisRoutes {
        route
        code
        __typename
      }
      concentration {
        firstAmnt
        firstUnit
        secAmnt
        secUnit
        color
        __typename
      }
      medClass
      action
      indication
      interaction
      onset
      duration
      departmentID
      protocolOptions {
        protocolID
        options {
          doseID
          basis
          rangeLow
          rangeHigh
          route
          title
          warning
          instruction
          note
          maxDose
          minDose
          maxTotalDose
          calcMax
          calcMin
          index
          ageLow
          ageHigh
          ageGroup
          repeatTime
          __typename
        }
        __typename
      }
      rangeLow
      rangeHigh
      maxDose
      minDose
      activeID
      overrideID
      status
      version
      createdBy
      modifiedBy
      metaData {
        parentID
        fullName
        nickName
        brandNames
        maxDose
        minDose
        absMaxDose
        absMinDose
        releaseNotes
        __typename
      }
      MedicationDoses {
        items {
          id
          basis
          rangeLow
          rangeHigh
          route
          title
          warning
          instruction
          note
          maxDose
          minDose
          maxTotalDose
          calcMax
          calcMin
          index
          ageLow
          ageHigh
          ageGroup
          repeatTime
          incrementAmnt
          departmentID
          protocolID
          medicationID
          cprAssistID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Concentrations {
        items {
          id
          firstAmnt
          firstUnit
          secAmnt
          secUnit
          color
          departmentID
          medicationID
          dripID
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    CPRAssist {
      id
      protocolIDs
      epiIDs
      defibIDs
      equipmentID
      departmentID
      activeID
      overrideID
      status
      version
      createdBy
      modifiedBy
      cprMetaData {
        defaultMode
        epiOrangeSec
        epiRedSec
        compressionRate
        ventRatePerMin
        ventilateSoundDelay
        ventilateLoops
        continousVentilateLoops
        ventilateSoundSpeed
        pulseCheckTime
        chargingTime
        __typename
      }
      DefibDoses {
        items {
          id
          basis
          rangeLow
          rangeHigh
          title
          warning
          instruction
          note
          maxDose
          minDose
          maxTotalDose
          calcMax
          calcMin
          index
          ageLow
          ageHigh
          ageGroup
          repeatTime
          incrementAmnt
          departmentID
          protocolID
          electricalID
          cprAssistID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      EpiDoses {
        items {
          id
          basis
          rangeLow
          rangeHigh
          route
          title
          warning
          instruction
          note
          maxDose
          minDose
          maxTotalDose
          calcMax
          calcMin
          index
          ageLow
          ageHigh
          ageGroup
          repeatTime
          incrementAmnt
          departmentID
          protocolID
          medicationID
          cprAssistID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateMedicationDoseMutationVariables,
  APITypes.CreateMedicationDoseMutation
>;
export const updateMedicationDose = /* GraphQL */ `mutation UpdateMedicationDose(
  $input: UpdateMedicationDoseInput!
  $condition: ModelMedicationDoseConditionInput
) {
  updateMedicationDose(input: $input, condition: $condition) {
    id
    basis
    rangeLow
    rangeHigh
    route
    nemsisRoutes {
      route
      code
      __typename
    }
    title
    warning
    instruction
    note
    maxDose
    minDose
    maxTotalDose
    calcMax
    calcMin
    index
    ageLow
    ageHigh
    ageGroup
    repeatTime
    incrementAmnt
    departmentID
    protocolID
    medicationID
    cprAssistID
    activeID
    overrideID
    status
    version
    createdBy
    modifiedBy
    createdAt
    Protocol {
      id
      name
      pdfID
      categoryID
      pairedProtocols
      medicationIDs
      equipmentIDs
      electricalIDs
      dripIDs
      formIDs
      index
      nickname
      pairedDepIDs
      rangeLow
      rangeHigh
      ageLow
      ageHigh
      ageGroup
      status
      version
      pdfVersion
      activeID
      overrideID
      privatePin
      keychainID
      createdBy
      modifiedBy
      isPublic
      isRestrictive
      color
      tags
      departmentID
      MedicationDoses {
        items {
          id
          basis
          rangeLow
          rangeHigh
          route
          title
          warning
          instruction
          note
          maxDose
          minDose
          maxTotalDose
          calcMax
          calcMin
          index
          ageLow
          ageHigh
          ageGroup
          repeatTime
          incrementAmnt
          departmentID
          protocolID
          medicationID
          cprAssistID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      ElectricalDoses {
        items {
          id
          basis
          rangeLow
          rangeHigh
          title
          warning
          instruction
          note
          maxDose
          minDose
          maxTotalDose
          calcMax
          calcMin
          index
          ageLow
          ageHigh
          ageGroup
          repeatTime
          incrementAmnt
          departmentID
          protocolID
          electricalID
          cprAssistID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      DripDoses {
        items {
          id
          basis
          rangeLow
          rangeHigh
          route
          title
          warning
          instruction
          note
          maxDose
          minDose
          maxTotalDose
          calcMax
          calcMin
          index
          ageLow
          ageHigh
          ageGroup
          repeatTime
          incrementAmnt
          departmentID
          protocolID
          dripID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    Medication {
      id
      name
      rxNormCode
      contraindication
      note
      warning
      route
      nemsisRoutes {
        route
        code
        __typename
      }
      concentration {
        firstAmnt
        firstUnit
        secAmnt
        secUnit
        color
        __typename
      }
      medClass
      action
      indication
      interaction
      onset
      duration
      departmentID
      protocolOptions {
        protocolID
        options {
          doseID
          basis
          rangeLow
          rangeHigh
          route
          title
          warning
          instruction
          note
          maxDose
          minDose
          maxTotalDose
          calcMax
          calcMin
          index
          ageLow
          ageHigh
          ageGroup
          repeatTime
          __typename
        }
        __typename
      }
      rangeLow
      rangeHigh
      maxDose
      minDose
      activeID
      overrideID
      status
      version
      createdBy
      modifiedBy
      metaData {
        parentID
        fullName
        nickName
        brandNames
        maxDose
        minDose
        absMaxDose
        absMinDose
        releaseNotes
        __typename
      }
      MedicationDoses {
        items {
          id
          basis
          rangeLow
          rangeHigh
          route
          title
          warning
          instruction
          note
          maxDose
          minDose
          maxTotalDose
          calcMax
          calcMin
          index
          ageLow
          ageHigh
          ageGroup
          repeatTime
          incrementAmnt
          departmentID
          protocolID
          medicationID
          cprAssistID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Concentrations {
        items {
          id
          firstAmnt
          firstUnit
          secAmnt
          secUnit
          color
          departmentID
          medicationID
          dripID
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    CPRAssist {
      id
      protocolIDs
      epiIDs
      defibIDs
      equipmentID
      departmentID
      activeID
      overrideID
      status
      version
      createdBy
      modifiedBy
      cprMetaData {
        defaultMode
        epiOrangeSec
        epiRedSec
        compressionRate
        ventRatePerMin
        ventilateSoundDelay
        ventilateLoops
        continousVentilateLoops
        ventilateSoundSpeed
        pulseCheckTime
        chargingTime
        __typename
      }
      DefibDoses {
        items {
          id
          basis
          rangeLow
          rangeHigh
          title
          warning
          instruction
          note
          maxDose
          minDose
          maxTotalDose
          calcMax
          calcMin
          index
          ageLow
          ageHigh
          ageGroup
          repeatTime
          incrementAmnt
          departmentID
          protocolID
          electricalID
          cprAssistID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      EpiDoses {
        items {
          id
          basis
          rangeLow
          rangeHigh
          route
          title
          warning
          instruction
          note
          maxDose
          minDose
          maxTotalDose
          calcMax
          calcMin
          index
          ageLow
          ageHigh
          ageGroup
          repeatTime
          incrementAmnt
          departmentID
          protocolID
          medicationID
          cprAssistID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateMedicationDoseMutationVariables,
  APITypes.UpdateMedicationDoseMutation
>;
export const deleteMedicationDose = /* GraphQL */ `mutation DeleteMedicationDose(
  $input: DeleteMedicationDoseInput!
  $condition: ModelMedicationDoseConditionInput
) {
  deleteMedicationDose(input: $input, condition: $condition) {
    id
    basis
    rangeLow
    rangeHigh
    route
    nemsisRoutes {
      route
      code
      __typename
    }
    title
    warning
    instruction
    note
    maxDose
    minDose
    maxTotalDose
    calcMax
    calcMin
    index
    ageLow
    ageHigh
    ageGroup
    repeatTime
    incrementAmnt
    departmentID
    protocolID
    medicationID
    cprAssistID
    activeID
    overrideID
    status
    version
    createdBy
    modifiedBy
    createdAt
    Protocol {
      id
      name
      pdfID
      categoryID
      pairedProtocols
      medicationIDs
      equipmentIDs
      electricalIDs
      dripIDs
      formIDs
      index
      nickname
      pairedDepIDs
      rangeLow
      rangeHigh
      ageLow
      ageHigh
      ageGroup
      status
      version
      pdfVersion
      activeID
      overrideID
      privatePin
      keychainID
      createdBy
      modifiedBy
      isPublic
      isRestrictive
      color
      tags
      departmentID
      MedicationDoses {
        items {
          id
          basis
          rangeLow
          rangeHigh
          route
          title
          warning
          instruction
          note
          maxDose
          minDose
          maxTotalDose
          calcMax
          calcMin
          index
          ageLow
          ageHigh
          ageGroup
          repeatTime
          incrementAmnt
          departmentID
          protocolID
          medicationID
          cprAssistID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      ElectricalDoses {
        items {
          id
          basis
          rangeLow
          rangeHigh
          title
          warning
          instruction
          note
          maxDose
          minDose
          maxTotalDose
          calcMax
          calcMin
          index
          ageLow
          ageHigh
          ageGroup
          repeatTime
          incrementAmnt
          departmentID
          protocolID
          electricalID
          cprAssistID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      DripDoses {
        items {
          id
          basis
          rangeLow
          rangeHigh
          route
          title
          warning
          instruction
          note
          maxDose
          minDose
          maxTotalDose
          calcMax
          calcMin
          index
          ageLow
          ageHigh
          ageGroup
          repeatTime
          incrementAmnt
          departmentID
          protocolID
          dripID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    Medication {
      id
      name
      rxNormCode
      contraindication
      note
      warning
      route
      nemsisRoutes {
        route
        code
        __typename
      }
      concentration {
        firstAmnt
        firstUnit
        secAmnt
        secUnit
        color
        __typename
      }
      medClass
      action
      indication
      interaction
      onset
      duration
      departmentID
      protocolOptions {
        protocolID
        options {
          doseID
          basis
          rangeLow
          rangeHigh
          route
          title
          warning
          instruction
          note
          maxDose
          minDose
          maxTotalDose
          calcMax
          calcMin
          index
          ageLow
          ageHigh
          ageGroup
          repeatTime
          __typename
        }
        __typename
      }
      rangeLow
      rangeHigh
      maxDose
      minDose
      activeID
      overrideID
      status
      version
      createdBy
      modifiedBy
      metaData {
        parentID
        fullName
        nickName
        brandNames
        maxDose
        minDose
        absMaxDose
        absMinDose
        releaseNotes
        __typename
      }
      MedicationDoses {
        items {
          id
          basis
          rangeLow
          rangeHigh
          route
          title
          warning
          instruction
          note
          maxDose
          minDose
          maxTotalDose
          calcMax
          calcMin
          index
          ageLow
          ageHigh
          ageGroup
          repeatTime
          incrementAmnt
          departmentID
          protocolID
          medicationID
          cprAssistID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Concentrations {
        items {
          id
          firstAmnt
          firstUnit
          secAmnt
          secUnit
          color
          departmentID
          medicationID
          dripID
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    CPRAssist {
      id
      protocolIDs
      epiIDs
      defibIDs
      equipmentID
      departmentID
      activeID
      overrideID
      status
      version
      createdBy
      modifiedBy
      cprMetaData {
        defaultMode
        epiOrangeSec
        epiRedSec
        compressionRate
        ventRatePerMin
        ventilateSoundDelay
        ventilateLoops
        continousVentilateLoops
        ventilateSoundSpeed
        pulseCheckTime
        chargingTime
        __typename
      }
      DefibDoses {
        items {
          id
          basis
          rangeLow
          rangeHigh
          title
          warning
          instruction
          note
          maxDose
          minDose
          maxTotalDose
          calcMax
          calcMin
          index
          ageLow
          ageHigh
          ageGroup
          repeatTime
          incrementAmnt
          departmentID
          protocolID
          electricalID
          cprAssistID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      EpiDoses {
        items {
          id
          basis
          rangeLow
          rangeHigh
          route
          title
          warning
          instruction
          note
          maxDose
          minDose
          maxTotalDose
          calcMax
          calcMin
          index
          ageLow
          ageHigh
          ageGroup
          repeatTime
          incrementAmnt
          departmentID
          protocolID
          medicationID
          cprAssistID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteMedicationDoseMutationVariables,
  APITypes.DeleteMedicationDoseMutation
>;
export const createProtocol = /* GraphQL */ `mutation CreateProtocol(
  $input: CreateProtocolInput!
  $condition: ModelProtocolConditionInput
) {
  createProtocol(input: $input, condition: $condition) {
    id
    name
    pdfID
    categoryID
    pairedProtocols
    medicationIDs
    equipmentIDs
    electricalIDs
    dripIDs
    formIDs
    index
    nickname
    pairedDepIDs
    rangeLow
    rangeHigh
    ageLow
    ageHigh
    ageGroup
    status
    version
    pdfVersion
    activeID
    overrideID
    privatePin
    keychainID
    createdBy
    modifiedBy
    isPublic
    isRestrictive
    color
    tags
    departmentID
    MedicationDoses {
      items {
        id
        basis
        rangeLow
        rangeHigh
        route
        nemsisRoutes {
          route
          code
          __typename
        }
        title
        warning
        instruction
        note
        maxDose
        minDose
        maxTotalDose
        calcMax
        calcMin
        index
        ageLow
        ageHigh
        ageGroup
        repeatTime
        incrementAmnt
        departmentID
        protocolID
        medicationID
        cprAssistID
        activeID
        overrideID
        status
        version
        createdBy
        modifiedBy
        createdAt
        Protocol {
          id
          name
          pdfID
          categoryID
          pairedProtocols
          medicationIDs
          equipmentIDs
          electricalIDs
          dripIDs
          formIDs
          index
          nickname
          pairedDepIDs
          rangeLow
          rangeHigh
          ageLow
          ageHigh
          ageGroup
          status
          version
          pdfVersion
          activeID
          overrideID
          privatePin
          keychainID
          createdBy
          modifiedBy
          isPublic
          isRestrictive
          color
          tags
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Medication {
          id
          name
          rxNormCode
          contraindication
          note
          warning
          route
          medClass
          action
          indication
          interaction
          onset
          duration
          departmentID
          rangeLow
          rangeHigh
          maxDose
          minDose
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        CPRAssist {
          id
          protocolIDs
          epiIDs
          defibIDs
          equipmentID
          departmentID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    ElectricalDoses {
      items {
        id
        basis
        rangeLow
        rangeHigh
        title
        warning
        instruction
        note
        maxDose
        minDose
        maxTotalDose
        calcMax
        calcMin
        index
        ageLow
        ageHigh
        ageGroup
        repeatTime
        incrementAmnt
        departmentID
        protocolID
        electricalID
        cprAssistID
        activeID
        overrideID
        status
        version
        createdBy
        modifiedBy
        createdAt
        Protocol {
          id
          name
          pdfID
          categoryID
          pairedProtocols
          medicationIDs
          equipmentIDs
          electricalIDs
          dripIDs
          formIDs
          index
          nickname
          pairedDepIDs
          rangeLow
          rangeHigh
          ageLow
          ageHigh
          ageGroup
          status
          version
          pdfVersion
          activeID
          overrideID
          privatePin
          keychainID
          createdBy
          modifiedBy
          isPublic
          isRestrictive
          color
          tags
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Electrical {
          id
          title
          rxNormCode
          warning
          instruction
          note
          rangeHigh
          rangeLow
          departmentID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        CPRAssist {
          id
          protocolIDs
          epiIDs
          defibIDs
          equipmentID
          departmentID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    DripDoses {
      items {
        id
        basis
        rangeLow
        rangeHigh
        route
        nemsisRoutes {
          route
          code
          __typename
        }
        title
        warning
        instruction
        note
        maxDose
        minDose
        maxTotalDose
        calcMax
        calcMin
        index
        ageLow
        ageHigh
        ageGroup
        repeatTime
        incrementAmnt
        departmentID
        protocolID
        dripID
        activeID
        overrideID
        status
        version
        createdBy
        modifiedBy
        createdAt
        Protocol {
          id
          name
          pdfID
          categoryID
          pairedProtocols
          medicationIDs
          equipmentIDs
          electricalIDs
          dripIDs
          formIDs
          index
          nickname
          pairedDepIDs
          rangeLow
          rangeHigh
          ageLow
          ageHigh
          ageGroup
          status
          version
          pdfVersion
          activeID
          overrideID
          privatePin
          keychainID
          createdBy
          modifiedBy
          isPublic
          isRestrictive
          color
          tags
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Drip {
          id
          name
          rxNormCode
          contraindication
          note
          warning
          route
          medClass
          action
          indication
          interaction
          onset
          duration
          departmentID
          rangeLow
          rangeHigh
          maxDose
          minDose
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateProtocolMutationVariables,
  APITypes.CreateProtocolMutation
>;
export const updateProtocol = /* GraphQL */ `mutation UpdateProtocol(
  $input: UpdateProtocolInput!
  $condition: ModelProtocolConditionInput
) {
  updateProtocol(input: $input, condition: $condition) {
    id
    name
    pdfID
    categoryID
    pairedProtocols
    medicationIDs
    equipmentIDs
    electricalIDs
    dripIDs
    formIDs
    index
    nickname
    pairedDepIDs
    rangeLow
    rangeHigh
    ageLow
    ageHigh
    ageGroup
    status
    version
    pdfVersion
    activeID
    overrideID
    privatePin
    keychainID
    createdBy
    modifiedBy
    isPublic
    isRestrictive
    color
    tags
    departmentID
    MedicationDoses {
      items {
        id
        basis
        rangeLow
        rangeHigh
        route
        nemsisRoutes {
          route
          code
          __typename
        }
        title
        warning
        instruction
        note
        maxDose
        minDose
        maxTotalDose
        calcMax
        calcMin
        index
        ageLow
        ageHigh
        ageGroup
        repeatTime
        incrementAmnt
        departmentID
        protocolID
        medicationID
        cprAssistID
        activeID
        overrideID
        status
        version
        createdBy
        modifiedBy
        createdAt
        Protocol {
          id
          name
          pdfID
          categoryID
          pairedProtocols
          medicationIDs
          equipmentIDs
          electricalIDs
          dripIDs
          formIDs
          index
          nickname
          pairedDepIDs
          rangeLow
          rangeHigh
          ageLow
          ageHigh
          ageGroup
          status
          version
          pdfVersion
          activeID
          overrideID
          privatePin
          keychainID
          createdBy
          modifiedBy
          isPublic
          isRestrictive
          color
          tags
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Medication {
          id
          name
          rxNormCode
          contraindication
          note
          warning
          route
          medClass
          action
          indication
          interaction
          onset
          duration
          departmentID
          rangeLow
          rangeHigh
          maxDose
          minDose
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        CPRAssist {
          id
          protocolIDs
          epiIDs
          defibIDs
          equipmentID
          departmentID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    ElectricalDoses {
      items {
        id
        basis
        rangeLow
        rangeHigh
        title
        warning
        instruction
        note
        maxDose
        minDose
        maxTotalDose
        calcMax
        calcMin
        index
        ageLow
        ageHigh
        ageGroup
        repeatTime
        incrementAmnt
        departmentID
        protocolID
        electricalID
        cprAssistID
        activeID
        overrideID
        status
        version
        createdBy
        modifiedBy
        createdAt
        Protocol {
          id
          name
          pdfID
          categoryID
          pairedProtocols
          medicationIDs
          equipmentIDs
          electricalIDs
          dripIDs
          formIDs
          index
          nickname
          pairedDepIDs
          rangeLow
          rangeHigh
          ageLow
          ageHigh
          ageGroup
          status
          version
          pdfVersion
          activeID
          overrideID
          privatePin
          keychainID
          createdBy
          modifiedBy
          isPublic
          isRestrictive
          color
          tags
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Electrical {
          id
          title
          rxNormCode
          warning
          instruction
          note
          rangeHigh
          rangeLow
          departmentID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        CPRAssist {
          id
          protocolIDs
          epiIDs
          defibIDs
          equipmentID
          departmentID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    DripDoses {
      items {
        id
        basis
        rangeLow
        rangeHigh
        route
        nemsisRoutes {
          route
          code
          __typename
        }
        title
        warning
        instruction
        note
        maxDose
        minDose
        maxTotalDose
        calcMax
        calcMin
        index
        ageLow
        ageHigh
        ageGroup
        repeatTime
        incrementAmnt
        departmentID
        protocolID
        dripID
        activeID
        overrideID
        status
        version
        createdBy
        modifiedBy
        createdAt
        Protocol {
          id
          name
          pdfID
          categoryID
          pairedProtocols
          medicationIDs
          equipmentIDs
          electricalIDs
          dripIDs
          formIDs
          index
          nickname
          pairedDepIDs
          rangeLow
          rangeHigh
          ageLow
          ageHigh
          ageGroup
          status
          version
          pdfVersion
          activeID
          overrideID
          privatePin
          keychainID
          createdBy
          modifiedBy
          isPublic
          isRestrictive
          color
          tags
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Drip {
          id
          name
          rxNormCode
          contraindication
          note
          warning
          route
          medClass
          action
          indication
          interaction
          onset
          duration
          departmentID
          rangeLow
          rangeHigh
          maxDose
          minDose
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateProtocolMutationVariables,
  APITypes.UpdateProtocolMutation
>;
export const deleteProtocol = /* GraphQL */ `mutation DeleteProtocol(
  $input: DeleteProtocolInput!
  $condition: ModelProtocolConditionInput
) {
  deleteProtocol(input: $input, condition: $condition) {
    id
    name
    pdfID
    categoryID
    pairedProtocols
    medicationIDs
    equipmentIDs
    electricalIDs
    dripIDs
    formIDs
    index
    nickname
    pairedDepIDs
    rangeLow
    rangeHigh
    ageLow
    ageHigh
    ageGroup
    status
    version
    pdfVersion
    activeID
    overrideID
    privatePin
    keychainID
    createdBy
    modifiedBy
    isPublic
    isRestrictive
    color
    tags
    departmentID
    MedicationDoses {
      items {
        id
        basis
        rangeLow
        rangeHigh
        route
        nemsisRoutes {
          route
          code
          __typename
        }
        title
        warning
        instruction
        note
        maxDose
        minDose
        maxTotalDose
        calcMax
        calcMin
        index
        ageLow
        ageHigh
        ageGroup
        repeatTime
        incrementAmnt
        departmentID
        protocolID
        medicationID
        cprAssistID
        activeID
        overrideID
        status
        version
        createdBy
        modifiedBy
        createdAt
        Protocol {
          id
          name
          pdfID
          categoryID
          pairedProtocols
          medicationIDs
          equipmentIDs
          electricalIDs
          dripIDs
          formIDs
          index
          nickname
          pairedDepIDs
          rangeLow
          rangeHigh
          ageLow
          ageHigh
          ageGroup
          status
          version
          pdfVersion
          activeID
          overrideID
          privatePin
          keychainID
          createdBy
          modifiedBy
          isPublic
          isRestrictive
          color
          tags
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Medication {
          id
          name
          rxNormCode
          contraindication
          note
          warning
          route
          medClass
          action
          indication
          interaction
          onset
          duration
          departmentID
          rangeLow
          rangeHigh
          maxDose
          minDose
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        CPRAssist {
          id
          protocolIDs
          epiIDs
          defibIDs
          equipmentID
          departmentID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    ElectricalDoses {
      items {
        id
        basis
        rangeLow
        rangeHigh
        title
        warning
        instruction
        note
        maxDose
        minDose
        maxTotalDose
        calcMax
        calcMin
        index
        ageLow
        ageHigh
        ageGroup
        repeatTime
        incrementAmnt
        departmentID
        protocolID
        electricalID
        cprAssistID
        activeID
        overrideID
        status
        version
        createdBy
        modifiedBy
        createdAt
        Protocol {
          id
          name
          pdfID
          categoryID
          pairedProtocols
          medicationIDs
          equipmentIDs
          electricalIDs
          dripIDs
          formIDs
          index
          nickname
          pairedDepIDs
          rangeLow
          rangeHigh
          ageLow
          ageHigh
          ageGroup
          status
          version
          pdfVersion
          activeID
          overrideID
          privatePin
          keychainID
          createdBy
          modifiedBy
          isPublic
          isRestrictive
          color
          tags
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Electrical {
          id
          title
          rxNormCode
          warning
          instruction
          note
          rangeHigh
          rangeLow
          departmentID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        CPRAssist {
          id
          protocolIDs
          epiIDs
          defibIDs
          equipmentID
          departmentID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    DripDoses {
      items {
        id
        basis
        rangeLow
        rangeHigh
        route
        nemsisRoutes {
          route
          code
          __typename
        }
        title
        warning
        instruction
        note
        maxDose
        minDose
        maxTotalDose
        calcMax
        calcMin
        index
        ageLow
        ageHigh
        ageGroup
        repeatTime
        incrementAmnt
        departmentID
        protocolID
        dripID
        activeID
        overrideID
        status
        version
        createdBy
        modifiedBy
        createdAt
        Protocol {
          id
          name
          pdfID
          categoryID
          pairedProtocols
          medicationIDs
          equipmentIDs
          electricalIDs
          dripIDs
          formIDs
          index
          nickname
          pairedDepIDs
          rangeLow
          rangeHigh
          ageLow
          ageHigh
          ageGroup
          status
          version
          pdfVersion
          activeID
          overrideID
          privatePin
          keychainID
          createdBy
          modifiedBy
          isPublic
          isRestrictive
          color
          tags
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Drip {
          id
          name
          rxNormCode
          contraindication
          note
          warning
          route
          medClass
          action
          indication
          interaction
          onset
          duration
          departmentID
          rangeLow
          rangeHigh
          maxDose
          minDose
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteProtocolMutationVariables,
  APITypes.DeleteProtocolMutation
>;
export const createAmbulance = /* GraphQL */ `mutation CreateAmbulance(
  $input: CreateAmbulanceInput!
  $condition: ModelAmbulanceConditionInput
) {
  createAmbulance(input: $input, condition: $condition) {
    id
    name
    departmentID
    Logs {
      items {
        id
        actualWeight
        ageValue
        protocolID
        Users {
          nextToken
          startedAt
          __typename
        }
        departmentID
        oneweightID
        ambulanceID
        incidentNumber
        startTime
        endTime
        estimatedWeight
        medications {
          medicationID
          amntSolid
          amntLiquid
          timestamp
          verified
          name
          rxNormCode
          concentration
          isActualWeight
          adminType
          weight
          protocolID
          __typename
        }
        equipments {
          equipmentID
          amount
          name
          snomedCode
          timestamp
          isActualWeight
          verified
          weight
          successful
          protocolID
          __typename
        }
        electricals {
          electricalID
          name
          snomedCode
          amount
          timestamp
          isActualWeight
          verified
          weight
          protocolID
          __typename
        }
        cpr {
          cprID
          startTime
          endTime
          outcome
          __typename
        }
        emsCalculators {
          calculatorID
          responses
          scores
          timestamp
          __typename
        }
        patientSaved
        patientReleased
        arrivedAtHospital
        ageTimestamp
        forms {
          formID
          name
          completed
          startedTime
          protocolID
          __typename
        }
        subtractedItems {
          weightObjectID
          name
          weight
          added
          __typename
        }
        userIDs
        infusions {
          dripID
          amnt
          amnt2
          timestamp
          verified
          name
          rxNormCode
          concentration
          adminType
          weight
          protocolID
          amntUnit
          amntUnit2
          isActualWeight
          gttsDropFactor
          gttsAmount
          gttsUnit
          __typename
        }
        estimatedSaved
        tags
        shifts {
          shiftStart
          shiftEnd
          completedLogs
          taggedAmbulance
          taggedOneWeight
          taggedUsers
          ownerID
          departmentID
          metaData
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAmbulanceMutationVariables,
  APITypes.CreateAmbulanceMutation
>;
export const updateAmbulance = /* GraphQL */ `mutation UpdateAmbulance(
  $input: UpdateAmbulanceInput!
  $condition: ModelAmbulanceConditionInput
) {
  updateAmbulance(input: $input, condition: $condition) {
    id
    name
    departmentID
    Logs {
      items {
        id
        actualWeight
        ageValue
        protocolID
        Users {
          nextToken
          startedAt
          __typename
        }
        departmentID
        oneweightID
        ambulanceID
        incidentNumber
        startTime
        endTime
        estimatedWeight
        medications {
          medicationID
          amntSolid
          amntLiquid
          timestamp
          verified
          name
          rxNormCode
          concentration
          isActualWeight
          adminType
          weight
          protocolID
          __typename
        }
        equipments {
          equipmentID
          amount
          name
          snomedCode
          timestamp
          isActualWeight
          verified
          weight
          successful
          protocolID
          __typename
        }
        electricals {
          electricalID
          name
          snomedCode
          amount
          timestamp
          isActualWeight
          verified
          weight
          protocolID
          __typename
        }
        cpr {
          cprID
          startTime
          endTime
          outcome
          __typename
        }
        emsCalculators {
          calculatorID
          responses
          scores
          timestamp
          __typename
        }
        patientSaved
        patientReleased
        arrivedAtHospital
        ageTimestamp
        forms {
          formID
          name
          completed
          startedTime
          protocolID
          __typename
        }
        subtractedItems {
          weightObjectID
          name
          weight
          added
          __typename
        }
        userIDs
        infusions {
          dripID
          amnt
          amnt2
          timestamp
          verified
          name
          rxNormCode
          concentration
          adminType
          weight
          protocolID
          amntUnit
          amntUnit2
          isActualWeight
          gttsDropFactor
          gttsAmount
          gttsUnit
          __typename
        }
        estimatedSaved
        tags
        shifts {
          shiftStart
          shiftEnd
          completedLogs
          taggedAmbulance
          taggedOneWeight
          taggedUsers
          ownerID
          departmentID
          metaData
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAmbulanceMutationVariables,
  APITypes.UpdateAmbulanceMutation
>;
export const deleteAmbulance = /* GraphQL */ `mutation DeleteAmbulance(
  $input: DeleteAmbulanceInput!
  $condition: ModelAmbulanceConditionInput
) {
  deleteAmbulance(input: $input, condition: $condition) {
    id
    name
    departmentID
    Logs {
      items {
        id
        actualWeight
        ageValue
        protocolID
        Users {
          nextToken
          startedAt
          __typename
        }
        departmentID
        oneweightID
        ambulanceID
        incidentNumber
        startTime
        endTime
        estimatedWeight
        medications {
          medicationID
          amntSolid
          amntLiquid
          timestamp
          verified
          name
          rxNormCode
          concentration
          isActualWeight
          adminType
          weight
          protocolID
          __typename
        }
        equipments {
          equipmentID
          amount
          name
          snomedCode
          timestamp
          isActualWeight
          verified
          weight
          successful
          protocolID
          __typename
        }
        electricals {
          electricalID
          name
          snomedCode
          amount
          timestamp
          isActualWeight
          verified
          weight
          protocolID
          __typename
        }
        cpr {
          cprID
          startTime
          endTime
          outcome
          __typename
        }
        emsCalculators {
          calculatorID
          responses
          scores
          timestamp
          __typename
        }
        patientSaved
        patientReleased
        arrivedAtHospital
        ageTimestamp
        forms {
          formID
          name
          completed
          startedTime
          protocolID
          __typename
        }
        subtractedItems {
          weightObjectID
          name
          weight
          added
          __typename
        }
        userIDs
        infusions {
          dripID
          amnt
          amnt2
          timestamp
          verified
          name
          rxNormCode
          concentration
          adminType
          weight
          protocolID
          amntUnit
          amntUnit2
          isActualWeight
          gttsDropFactor
          gttsAmount
          gttsUnit
          __typename
        }
        estimatedSaved
        tags
        shifts {
          shiftStart
          shiftEnd
          completedLogs
          taggedAmbulance
          taggedOneWeight
          taggedUsers
          ownerID
          departmentID
          metaData
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteAmbulanceMutationVariables,
  APITypes.DeleteAmbulanceMutation
>;
export const createUser = /* GraphQL */ `mutation CreateUser(
  $input: CreateUserInput!
  $condition: ModelUserConditionInput
) {
  createUser(input: $input, condition: $condition) {
    id
    firstName
    lastName
    type
    cognitoID
    indexedParentDepID
    departmentID
    depAdmins
    logs {
      items {
        id
        userId
        logId
        user {
          id
          firstName
          lastName
          type
          cognitoID
          indexedParentDepID
          departmentID
          depAdmins
          hashedPin
          saltPin
          pairedDepIDs
          oneDoseVersion
          lastActivity
          notificationTokens
          deviceIDs
          maxDevices
          allowAnalytics
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        log {
          id
          actualWeight
          ageValue
          protocolID
          departmentID
          oneweightID
          ambulanceID
          incidentNumber
          startTime
          endTime
          estimatedWeight
          patientSaved
          patientReleased
          arrivedAtHospital
          ageTimestamp
          userIDs
          estimatedSaved
          tags
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    hashedPin
    saltPin
    pairedDepIDs
    oneDoseVersion
    lastActivity
    notificationTokens
    deviceIDs
    maxDevices
    allowAnalytics
    status
    FormLogs {
      items {
        id
        responses {
          questionIndex
          type
          responses
          __typename
        }
        inputformID
        departmentID
        userID
        pairedDepID
        pairedUserIDs
        notes
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Reviewals {
      items {
        id
        title
        description
        reviewers
        status
        events {
          color
          title
          description
          timestamp
          icon
          __typename
        }
        createdAt
        endedAt
        departmentID
        userID
        User {
          id
          firstName
          lastName
          type
          cognitoID
          indexedParentDepID
          departmentID
          depAdmins
          hashedPin
          saltPin
          pairedDepIDs
          oneDoseVersion
          lastActivity
          notificationTokens
          deviceIDs
          maxDevices
          allowAnalytics
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        DraftGroups {
          nextToken
          startedAt
          __typename
        }
        Acknowledges {
          nextToken
          startedAt
          __typename
        }
        UserComments {
          nextToken
          startedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Acknowledges {
      items {
        id
        reviewalID
        ownerID
        userID
        status
        isArchived
        createdAt
        User {
          id
          firstName
          lastName
          type
          cognitoID
          indexedParentDepID
          departmentID
          depAdmins
          hashedPin
          saltPin
          pairedDepIDs
          oneDoseVersion
          lastActivity
          notificationTokens
          deviceIDs
          maxDevices
          allowAnalytics
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Comment {
          id
          ownerID
          userID
          acknowledgeID
          message
          relatedItems
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    UserComments {
      items {
        id
        ownerID
        userID
        acknowledgeID
        message
        relatedItems
        createdAt
        User {
          id
          firstName
          lastName
          type
          cognitoID
          indexedParentDepID
          departmentID
          depAdmins
          hashedPin
          saltPin
          pairedDepIDs
          oneDoseVersion
          lastActivity
          notificationTokens
          deviceIDs
          maxDevices
          allowAnalytics
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Acknowledge {
          id
          reviewalID
          ownerID
          userID
          status
          isArchived
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    NotifyACKs {
      items {
        id
        ownerID
        userID
        status
        createdAt
        User {
          id
          firstName
          lastName
          type
          cognitoID
          indexedParentDepID
          departmentID
          depAdmins
          hashedPin
          saltPin
          pairedDepIDs
          oneDoseVersion
          lastActivity
          notificationTokens
          deviceIDs
          maxDevices
          allowAnalytics
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserMutationVariables,
  APITypes.CreateUserMutation
>;
export const updateUser = /* GraphQL */ `mutation UpdateUser(
  $input: UpdateUserInput!
  $condition: ModelUserConditionInput
) {
  updateUser(input: $input, condition: $condition) {
    id
    firstName
    lastName
    type
    cognitoID
    indexedParentDepID
    departmentID
    depAdmins
    logs {
      items {
        id
        userId
        logId
        user {
          id
          firstName
          lastName
          type
          cognitoID
          indexedParentDepID
          departmentID
          depAdmins
          hashedPin
          saltPin
          pairedDepIDs
          oneDoseVersion
          lastActivity
          notificationTokens
          deviceIDs
          maxDevices
          allowAnalytics
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        log {
          id
          actualWeight
          ageValue
          protocolID
          departmentID
          oneweightID
          ambulanceID
          incidentNumber
          startTime
          endTime
          estimatedWeight
          patientSaved
          patientReleased
          arrivedAtHospital
          ageTimestamp
          userIDs
          estimatedSaved
          tags
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    hashedPin
    saltPin
    pairedDepIDs
    oneDoseVersion
    lastActivity
    notificationTokens
    deviceIDs
    maxDevices
    allowAnalytics
    status
    FormLogs {
      items {
        id
        responses {
          questionIndex
          type
          responses
          __typename
        }
        inputformID
        departmentID
        userID
        pairedDepID
        pairedUserIDs
        notes
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Reviewals {
      items {
        id
        title
        description
        reviewers
        status
        events {
          color
          title
          description
          timestamp
          icon
          __typename
        }
        createdAt
        endedAt
        departmentID
        userID
        User {
          id
          firstName
          lastName
          type
          cognitoID
          indexedParentDepID
          departmentID
          depAdmins
          hashedPin
          saltPin
          pairedDepIDs
          oneDoseVersion
          lastActivity
          notificationTokens
          deviceIDs
          maxDevices
          allowAnalytics
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        DraftGroups {
          nextToken
          startedAt
          __typename
        }
        Acknowledges {
          nextToken
          startedAt
          __typename
        }
        UserComments {
          nextToken
          startedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Acknowledges {
      items {
        id
        reviewalID
        ownerID
        userID
        status
        isArchived
        createdAt
        User {
          id
          firstName
          lastName
          type
          cognitoID
          indexedParentDepID
          departmentID
          depAdmins
          hashedPin
          saltPin
          pairedDepIDs
          oneDoseVersion
          lastActivity
          notificationTokens
          deviceIDs
          maxDevices
          allowAnalytics
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Comment {
          id
          ownerID
          userID
          acknowledgeID
          message
          relatedItems
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    UserComments {
      items {
        id
        ownerID
        userID
        acknowledgeID
        message
        relatedItems
        createdAt
        User {
          id
          firstName
          lastName
          type
          cognitoID
          indexedParentDepID
          departmentID
          depAdmins
          hashedPin
          saltPin
          pairedDepIDs
          oneDoseVersion
          lastActivity
          notificationTokens
          deviceIDs
          maxDevices
          allowAnalytics
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Acknowledge {
          id
          reviewalID
          ownerID
          userID
          status
          isArchived
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    NotifyACKs {
      items {
        id
        ownerID
        userID
        status
        createdAt
        User {
          id
          firstName
          lastName
          type
          cognitoID
          indexedParentDepID
          departmentID
          depAdmins
          hashedPin
          saltPin
          pairedDepIDs
          oneDoseVersion
          lastActivity
          notificationTokens
          deviceIDs
          maxDevices
          allowAnalytics
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserMutationVariables,
  APITypes.UpdateUserMutation
>;
export const deleteUser = /* GraphQL */ `mutation DeleteUser(
  $input: DeleteUserInput!
  $condition: ModelUserConditionInput
) {
  deleteUser(input: $input, condition: $condition) {
    id
    firstName
    lastName
    type
    cognitoID
    indexedParentDepID
    departmentID
    depAdmins
    logs {
      items {
        id
        userId
        logId
        user {
          id
          firstName
          lastName
          type
          cognitoID
          indexedParentDepID
          departmentID
          depAdmins
          hashedPin
          saltPin
          pairedDepIDs
          oneDoseVersion
          lastActivity
          notificationTokens
          deviceIDs
          maxDevices
          allowAnalytics
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        log {
          id
          actualWeight
          ageValue
          protocolID
          departmentID
          oneweightID
          ambulanceID
          incidentNumber
          startTime
          endTime
          estimatedWeight
          patientSaved
          patientReleased
          arrivedAtHospital
          ageTimestamp
          userIDs
          estimatedSaved
          tags
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    hashedPin
    saltPin
    pairedDepIDs
    oneDoseVersion
    lastActivity
    notificationTokens
    deviceIDs
    maxDevices
    allowAnalytics
    status
    FormLogs {
      items {
        id
        responses {
          questionIndex
          type
          responses
          __typename
        }
        inputformID
        departmentID
        userID
        pairedDepID
        pairedUserIDs
        notes
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Reviewals {
      items {
        id
        title
        description
        reviewers
        status
        events {
          color
          title
          description
          timestamp
          icon
          __typename
        }
        createdAt
        endedAt
        departmentID
        userID
        User {
          id
          firstName
          lastName
          type
          cognitoID
          indexedParentDepID
          departmentID
          depAdmins
          hashedPin
          saltPin
          pairedDepIDs
          oneDoseVersion
          lastActivity
          notificationTokens
          deviceIDs
          maxDevices
          allowAnalytics
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        DraftGroups {
          nextToken
          startedAt
          __typename
        }
        Acknowledges {
          nextToken
          startedAt
          __typename
        }
        UserComments {
          nextToken
          startedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Acknowledges {
      items {
        id
        reviewalID
        ownerID
        userID
        status
        isArchived
        createdAt
        User {
          id
          firstName
          lastName
          type
          cognitoID
          indexedParentDepID
          departmentID
          depAdmins
          hashedPin
          saltPin
          pairedDepIDs
          oneDoseVersion
          lastActivity
          notificationTokens
          deviceIDs
          maxDevices
          allowAnalytics
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Comment {
          id
          ownerID
          userID
          acknowledgeID
          message
          relatedItems
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    UserComments {
      items {
        id
        ownerID
        userID
        acknowledgeID
        message
        relatedItems
        createdAt
        User {
          id
          firstName
          lastName
          type
          cognitoID
          indexedParentDepID
          departmentID
          depAdmins
          hashedPin
          saltPin
          pairedDepIDs
          oneDoseVersion
          lastActivity
          notificationTokens
          deviceIDs
          maxDevices
          allowAnalytics
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Acknowledge {
          id
          reviewalID
          ownerID
          userID
          status
          isArchived
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    NotifyACKs {
      items {
        id
        ownerID
        userID
        status
        createdAt
        User {
          id
          firstName
          lastName
          type
          cognitoID
          indexedParentDepID
          departmentID
          depAdmins
          hashedPin
          saltPin
          pairedDepIDs
          oneDoseVersion
          lastActivity
          notificationTokens
          deviceIDs
          maxDevices
          allowAnalytics
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserMutationVariables,
  APITypes.DeleteUserMutation
>;
export const createCategory = /* GraphQL */ `mutation CreateCategory(
  $input: CreateCategoryInput!
  $condition: ModelCategoryConditionInput
) {
  createCategory(input: $input, condition: $condition) {
    id
    name
    departmentID
    department {
      id
      Categories {
        items {
          id
          name
          departmentID
          index
          pairedDepIDs
          parentCategoryID
          activeID
          status
          version
          createdBy
          modifiedBy
          isPublic
          isRestrictive
          color
          tags
          overrideID
          keychainID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          departmentCategoriesId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      OneWeights {
        items {
          id
          name
          serialNumber
          projectNumber
          lastCalibration
          type
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          departmentOneWeightsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      name
      location
      protocolVersions
      shiftTypes
      Medications {
        items {
          id
          name
          rxNormCode
          contraindication
          note
          warning
          route
          medClass
          action
          indication
          interaction
          onset
          duration
          departmentID
          rangeLow
          rangeHigh
          maxDose
          minDose
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Equipment {
        items {
          id
          name
          snomedCode
          departmentID
          title
          note
          warning
          instruction
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Forms {
        items {
          id
          name
          departmentID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Vitals {
        items {
          id
          title
          index
          departmentID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Electricals {
        items {
          id
          title
          departmentID
          activeID
          status
          version
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      ElectricalShocks {
        items {
          id
          title
          rxNormCode
          warning
          instruction
          note
          rangeHigh
          rangeLow
          departmentID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      cognitoID
      Users {
        items {
          id
          firstName
          lastName
          type
          cognitoID
          indexedParentDepID
          departmentID
          depAdmins
          hashedPin
          saltPin
          pairedDepIDs
          oneDoseVersion
          lastActivity
          notificationTokens
          deviceIDs
          maxDevices
          allowAnalytics
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Logs {
        items {
          id
          actualWeight
          ageValue
          protocolID
          departmentID
          oneweightID
          ambulanceID
          incidentNumber
          startTime
          endTime
          estimatedWeight
          patientSaved
          patientReleased
          arrivedAtHospital
          ageTimestamp
          userIDs
          estimatedSaved
          tags
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Ambulances {
        items {
          id
          name
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Drips {
        items {
          id
          name
          rxNormCode
          contraindication
          note
          warning
          route
          medClass
          action
          indication
          interaction
          onset
          duration
          departmentID
          rangeLow
          rangeHigh
          maxDose
          minDose
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      RequireSync {
        id
        sync
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      logoID
      userID
      neonateCutoff
      pediatricCutoff
      calculators
      adultRanges {
        label
        value
        __typename
      }
      softwarePlan
      Notifications {
        items {
          id
          type
          title
          message
          timestamp
          expirationTimestamp
          deadlineTimestamp
          isReadIDs
          isAckIDs
          imageURLs
          videoURLs
          fileURLs
          taggedProtocols
          isPush
          isPinRequired
          departmentID
          pairedDepIDs
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Contacts {
        items {
          id
          fullName
          title
          number
          note
          index
          departmentID
          pairedDepIDs
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      InputForms {
        items {
          id
          name
          departmentID
          pairedDepIDs
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      FormLogs {
        items {
          id
          inputformID
          departmentID
          userID
          pairedDepID
          pairedUserIDs
          notes
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Workbooks {
        items {
          id
          name
          workbookID
          fileSize
          aiPdfParserResults
          activeID
          createdBy
          modifiedBy
          status
          version
          pairedDepIDs
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      WeightObjects {
        items {
          id
          name
          weight
          createdBy
          modifiedBy
          version
          pairedDepIDs
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Keychains {
        items {
          id
          name
          hashedPin
          saltPin
          hashType
          version
          createdBy
          modifiedBy
          metaData
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Reviewals {
        items {
          id
          title
          description
          reviewers
          status
          createdAt
          endedAt
          departmentID
          userID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Groups {
        items {
          id
          name
          type
          departmentID
          pairedDepIDs
          pairedUserIDs
          isAutoGen
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      CPRAssists {
        items {
          id
          protocolIDs
          epiIDs
          defibIDs
          equipmentID
          departmentID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      subDepIDs
      parentDepID
      indexedParentDepID
      activeStatus
      agencyNumEMS
      stateIdEMS
      gnisCodeEMS
      uniqueCode
      uniquePublicURL
      hashedPin
      saltedPin
      keychainID
      isPublic
      infusionCalculation
      isPublicSignup
      config {
        isTopEnabled
        neonateCutoff
        pediatricCutoff
        calculators
        adultRanges {
          label
          value
          __typename
        }
        softwarePlan
        infusionCalculation
        isPublic
        realTimeUpdating
        epcrProvider
        oneweightEnabled
        ageFilterEnabled
        ageGroupFilterEnabled
        renewalDate
        infusionGTTS
        signedAgreement
        isToggleAdvancedFiltersEnabled
        premiumTrialExpiration
        estimationMethod
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      departmentRequireSyncId
      __typename
    }
    index
    pairedDepIDs
    parentCategoryID
    activeID
    status
    version
    createdBy
    modifiedBy
    isPublic
    isRestrictive
    color
    tags
    overrideID
    keychainID
    Protocols {
      items {
        id
        name
        pdfID
        categoryID
        pairedProtocols
        medicationIDs
        equipmentIDs
        electricalIDs
        dripIDs
        formIDs
        index
        nickname
        pairedDepIDs
        rangeLow
        rangeHigh
        ageLow
        ageHigh
        ageGroup
        status
        version
        pdfVersion
        activeID
        overrideID
        privatePin
        keychainID
        createdBy
        modifiedBy
        isPublic
        isRestrictive
        color
        tags
        departmentID
        MedicationDoses {
          nextToken
          startedAt
          __typename
        }
        ElectricalDoses {
          nextToken
          startedAt
          __typename
        }
        DripDoses {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    departmentCategoriesId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCategoryMutationVariables,
  APITypes.CreateCategoryMutation
>;
export const updateCategory = /* GraphQL */ `mutation UpdateCategory(
  $input: UpdateCategoryInput!
  $condition: ModelCategoryConditionInput
) {
  updateCategory(input: $input, condition: $condition) {
    id
    name
    departmentID
    department {
      id
      Categories {
        items {
          id
          name
          departmentID
          index
          pairedDepIDs
          parentCategoryID
          activeID
          status
          version
          createdBy
          modifiedBy
          isPublic
          isRestrictive
          color
          tags
          overrideID
          keychainID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          departmentCategoriesId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      OneWeights {
        items {
          id
          name
          serialNumber
          projectNumber
          lastCalibration
          type
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          departmentOneWeightsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      name
      location
      protocolVersions
      shiftTypes
      Medications {
        items {
          id
          name
          rxNormCode
          contraindication
          note
          warning
          route
          medClass
          action
          indication
          interaction
          onset
          duration
          departmentID
          rangeLow
          rangeHigh
          maxDose
          minDose
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Equipment {
        items {
          id
          name
          snomedCode
          departmentID
          title
          note
          warning
          instruction
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Forms {
        items {
          id
          name
          departmentID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Vitals {
        items {
          id
          title
          index
          departmentID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Electricals {
        items {
          id
          title
          departmentID
          activeID
          status
          version
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      ElectricalShocks {
        items {
          id
          title
          rxNormCode
          warning
          instruction
          note
          rangeHigh
          rangeLow
          departmentID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      cognitoID
      Users {
        items {
          id
          firstName
          lastName
          type
          cognitoID
          indexedParentDepID
          departmentID
          depAdmins
          hashedPin
          saltPin
          pairedDepIDs
          oneDoseVersion
          lastActivity
          notificationTokens
          deviceIDs
          maxDevices
          allowAnalytics
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Logs {
        items {
          id
          actualWeight
          ageValue
          protocolID
          departmentID
          oneweightID
          ambulanceID
          incidentNumber
          startTime
          endTime
          estimatedWeight
          patientSaved
          patientReleased
          arrivedAtHospital
          ageTimestamp
          userIDs
          estimatedSaved
          tags
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Ambulances {
        items {
          id
          name
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Drips {
        items {
          id
          name
          rxNormCode
          contraindication
          note
          warning
          route
          medClass
          action
          indication
          interaction
          onset
          duration
          departmentID
          rangeLow
          rangeHigh
          maxDose
          minDose
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      RequireSync {
        id
        sync
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      logoID
      userID
      neonateCutoff
      pediatricCutoff
      calculators
      adultRanges {
        label
        value
        __typename
      }
      softwarePlan
      Notifications {
        items {
          id
          type
          title
          message
          timestamp
          expirationTimestamp
          deadlineTimestamp
          isReadIDs
          isAckIDs
          imageURLs
          videoURLs
          fileURLs
          taggedProtocols
          isPush
          isPinRequired
          departmentID
          pairedDepIDs
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Contacts {
        items {
          id
          fullName
          title
          number
          note
          index
          departmentID
          pairedDepIDs
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      InputForms {
        items {
          id
          name
          departmentID
          pairedDepIDs
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      FormLogs {
        items {
          id
          inputformID
          departmentID
          userID
          pairedDepID
          pairedUserIDs
          notes
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Workbooks {
        items {
          id
          name
          workbookID
          fileSize
          aiPdfParserResults
          activeID
          createdBy
          modifiedBy
          status
          version
          pairedDepIDs
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      WeightObjects {
        items {
          id
          name
          weight
          createdBy
          modifiedBy
          version
          pairedDepIDs
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Keychains {
        items {
          id
          name
          hashedPin
          saltPin
          hashType
          version
          createdBy
          modifiedBy
          metaData
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Reviewals {
        items {
          id
          title
          description
          reviewers
          status
          createdAt
          endedAt
          departmentID
          userID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Groups {
        items {
          id
          name
          type
          departmentID
          pairedDepIDs
          pairedUserIDs
          isAutoGen
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      CPRAssists {
        items {
          id
          protocolIDs
          epiIDs
          defibIDs
          equipmentID
          departmentID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      subDepIDs
      parentDepID
      indexedParentDepID
      activeStatus
      agencyNumEMS
      stateIdEMS
      gnisCodeEMS
      uniqueCode
      uniquePublicURL
      hashedPin
      saltedPin
      keychainID
      isPublic
      infusionCalculation
      isPublicSignup
      config {
        isTopEnabled
        neonateCutoff
        pediatricCutoff
        calculators
        adultRanges {
          label
          value
          __typename
        }
        softwarePlan
        infusionCalculation
        isPublic
        realTimeUpdating
        epcrProvider
        oneweightEnabled
        ageFilterEnabled
        ageGroupFilterEnabled
        renewalDate
        infusionGTTS
        signedAgreement
        isToggleAdvancedFiltersEnabled
        premiumTrialExpiration
        estimationMethod
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      departmentRequireSyncId
      __typename
    }
    index
    pairedDepIDs
    parentCategoryID
    activeID
    status
    version
    createdBy
    modifiedBy
    isPublic
    isRestrictive
    color
    tags
    overrideID
    keychainID
    Protocols {
      items {
        id
        name
        pdfID
        categoryID
        pairedProtocols
        medicationIDs
        equipmentIDs
        electricalIDs
        dripIDs
        formIDs
        index
        nickname
        pairedDepIDs
        rangeLow
        rangeHigh
        ageLow
        ageHigh
        ageGroup
        status
        version
        pdfVersion
        activeID
        overrideID
        privatePin
        keychainID
        createdBy
        modifiedBy
        isPublic
        isRestrictive
        color
        tags
        departmentID
        MedicationDoses {
          nextToken
          startedAt
          __typename
        }
        ElectricalDoses {
          nextToken
          startedAt
          __typename
        }
        DripDoses {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    departmentCategoriesId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCategoryMutationVariables,
  APITypes.UpdateCategoryMutation
>;
export const deleteCategory = /* GraphQL */ `mutation DeleteCategory(
  $input: DeleteCategoryInput!
  $condition: ModelCategoryConditionInput
) {
  deleteCategory(input: $input, condition: $condition) {
    id
    name
    departmentID
    department {
      id
      Categories {
        items {
          id
          name
          departmentID
          index
          pairedDepIDs
          parentCategoryID
          activeID
          status
          version
          createdBy
          modifiedBy
          isPublic
          isRestrictive
          color
          tags
          overrideID
          keychainID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          departmentCategoriesId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      OneWeights {
        items {
          id
          name
          serialNumber
          projectNumber
          lastCalibration
          type
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          departmentOneWeightsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      name
      location
      protocolVersions
      shiftTypes
      Medications {
        items {
          id
          name
          rxNormCode
          contraindication
          note
          warning
          route
          medClass
          action
          indication
          interaction
          onset
          duration
          departmentID
          rangeLow
          rangeHigh
          maxDose
          minDose
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Equipment {
        items {
          id
          name
          snomedCode
          departmentID
          title
          note
          warning
          instruction
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Forms {
        items {
          id
          name
          departmentID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Vitals {
        items {
          id
          title
          index
          departmentID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Electricals {
        items {
          id
          title
          departmentID
          activeID
          status
          version
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      ElectricalShocks {
        items {
          id
          title
          rxNormCode
          warning
          instruction
          note
          rangeHigh
          rangeLow
          departmentID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      cognitoID
      Users {
        items {
          id
          firstName
          lastName
          type
          cognitoID
          indexedParentDepID
          departmentID
          depAdmins
          hashedPin
          saltPin
          pairedDepIDs
          oneDoseVersion
          lastActivity
          notificationTokens
          deviceIDs
          maxDevices
          allowAnalytics
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Logs {
        items {
          id
          actualWeight
          ageValue
          protocolID
          departmentID
          oneweightID
          ambulanceID
          incidentNumber
          startTime
          endTime
          estimatedWeight
          patientSaved
          patientReleased
          arrivedAtHospital
          ageTimestamp
          userIDs
          estimatedSaved
          tags
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Ambulances {
        items {
          id
          name
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Drips {
        items {
          id
          name
          rxNormCode
          contraindication
          note
          warning
          route
          medClass
          action
          indication
          interaction
          onset
          duration
          departmentID
          rangeLow
          rangeHigh
          maxDose
          minDose
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      RequireSync {
        id
        sync
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      logoID
      userID
      neonateCutoff
      pediatricCutoff
      calculators
      adultRanges {
        label
        value
        __typename
      }
      softwarePlan
      Notifications {
        items {
          id
          type
          title
          message
          timestamp
          expirationTimestamp
          deadlineTimestamp
          isReadIDs
          isAckIDs
          imageURLs
          videoURLs
          fileURLs
          taggedProtocols
          isPush
          isPinRequired
          departmentID
          pairedDepIDs
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Contacts {
        items {
          id
          fullName
          title
          number
          note
          index
          departmentID
          pairedDepIDs
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      InputForms {
        items {
          id
          name
          departmentID
          pairedDepIDs
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      FormLogs {
        items {
          id
          inputformID
          departmentID
          userID
          pairedDepID
          pairedUserIDs
          notes
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Workbooks {
        items {
          id
          name
          workbookID
          fileSize
          aiPdfParserResults
          activeID
          createdBy
          modifiedBy
          status
          version
          pairedDepIDs
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      WeightObjects {
        items {
          id
          name
          weight
          createdBy
          modifiedBy
          version
          pairedDepIDs
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Keychains {
        items {
          id
          name
          hashedPin
          saltPin
          hashType
          version
          createdBy
          modifiedBy
          metaData
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Reviewals {
        items {
          id
          title
          description
          reviewers
          status
          createdAt
          endedAt
          departmentID
          userID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Groups {
        items {
          id
          name
          type
          departmentID
          pairedDepIDs
          pairedUserIDs
          isAutoGen
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      CPRAssists {
        items {
          id
          protocolIDs
          epiIDs
          defibIDs
          equipmentID
          departmentID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      subDepIDs
      parentDepID
      indexedParentDepID
      activeStatus
      agencyNumEMS
      stateIdEMS
      gnisCodeEMS
      uniqueCode
      uniquePublicURL
      hashedPin
      saltedPin
      keychainID
      isPublic
      infusionCalculation
      isPublicSignup
      config {
        isTopEnabled
        neonateCutoff
        pediatricCutoff
        calculators
        adultRanges {
          label
          value
          __typename
        }
        softwarePlan
        infusionCalculation
        isPublic
        realTimeUpdating
        epcrProvider
        oneweightEnabled
        ageFilterEnabled
        ageGroupFilterEnabled
        renewalDate
        infusionGTTS
        signedAgreement
        isToggleAdvancedFiltersEnabled
        premiumTrialExpiration
        estimationMethod
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      departmentRequireSyncId
      __typename
    }
    index
    pairedDepIDs
    parentCategoryID
    activeID
    status
    version
    createdBy
    modifiedBy
    isPublic
    isRestrictive
    color
    tags
    overrideID
    keychainID
    Protocols {
      items {
        id
        name
        pdfID
        categoryID
        pairedProtocols
        medicationIDs
        equipmentIDs
        electricalIDs
        dripIDs
        formIDs
        index
        nickname
        pairedDepIDs
        rangeLow
        rangeHigh
        ageLow
        ageHigh
        ageGroup
        status
        version
        pdfVersion
        activeID
        overrideID
        privatePin
        keychainID
        createdBy
        modifiedBy
        isPublic
        isRestrictive
        color
        tags
        departmentID
        MedicationDoses {
          nextToken
          startedAt
          __typename
        }
        ElectricalDoses {
          nextToken
          startedAt
          __typename
        }
        DripDoses {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    departmentCategoriesId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCategoryMutationVariables,
  APITypes.DeleteCategoryMutation
>;
export const createElectrical = /* GraphQL */ `mutation CreateElectrical(
  $input: CreateElectricalInput!
  $condition: ModelElectricalConditionInput
) {
  createElectrical(input: $input, condition: $condition) {
    id
    title
    options {
      title
      ranges {
        basis
        rangeLow
        rangeHigh
        calcMax
        fixedMax
        __typename
      }
      __typename
    }
    departmentID
    activeID
    status
    version
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateElectricalMutationVariables,
  APITypes.CreateElectricalMutation
>;
export const updateElectrical = /* GraphQL */ `mutation UpdateElectrical(
  $input: UpdateElectricalInput!
  $condition: ModelElectricalConditionInput
) {
  updateElectrical(input: $input, condition: $condition) {
    id
    title
    options {
      title
      ranges {
        basis
        rangeLow
        rangeHigh
        calcMax
        fixedMax
        __typename
      }
      __typename
    }
    departmentID
    activeID
    status
    version
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateElectricalMutationVariables,
  APITypes.UpdateElectricalMutation
>;
export const deleteElectrical = /* GraphQL */ `mutation DeleteElectrical(
  $input: DeleteElectricalInput!
  $condition: ModelElectricalConditionInput
) {
  deleteElectrical(input: $input, condition: $condition) {
    id
    title
    options {
      title
      ranges {
        basis
        rangeLow
        rangeHigh
        calcMax
        fixedMax
        __typename
      }
      __typename
    }
    departmentID
    activeID
    status
    version
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteElectricalMutationVariables,
  APITypes.DeleteElectricalMutation
>;
export const createElectricalShock = /* GraphQL */ `mutation CreateElectricalShock(
  $input: CreateElectricalShockInput!
  $condition: ModelElectricalShockConditionInput
) {
  createElectricalShock(input: $input, condition: $condition) {
    id
    title
    rxNormCode
    options {
      protocolID
      ranges {
        doseID
        index
        basis
        rangeLow
        rangeHigh
        calcMax
        fixedMax
        title
        warning
        instruction
        note
        ageLow
        ageHigh
        ageGroup
        __typename
      }
      __typename
    }
    warning
    instruction
    note
    rangeHigh
    rangeLow
    departmentID
    activeID
    overrideID
    status
    version
    createdBy
    modifiedBy
    metaData {
      parentID
      fullName
      nickName
      brandNames
      maxDose
      minDose
      absMaxDose
      absMinDose
      releaseNotes
      __typename
    }
    ElectricalDoses {
      items {
        id
        basis
        rangeLow
        rangeHigh
        title
        warning
        instruction
        note
        maxDose
        minDose
        maxTotalDose
        calcMax
        calcMin
        index
        ageLow
        ageHigh
        ageGroup
        repeatTime
        incrementAmnt
        departmentID
        protocolID
        electricalID
        cprAssistID
        activeID
        overrideID
        status
        version
        createdBy
        modifiedBy
        createdAt
        Protocol {
          id
          name
          pdfID
          categoryID
          pairedProtocols
          medicationIDs
          equipmentIDs
          electricalIDs
          dripIDs
          formIDs
          index
          nickname
          pairedDepIDs
          rangeLow
          rangeHigh
          ageLow
          ageHigh
          ageGroup
          status
          version
          pdfVersion
          activeID
          overrideID
          privatePin
          keychainID
          createdBy
          modifiedBy
          isPublic
          isRestrictive
          color
          tags
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Electrical {
          id
          title
          rxNormCode
          warning
          instruction
          note
          rangeHigh
          rangeLow
          departmentID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        CPRAssist {
          id
          protocolIDs
          epiIDs
          defibIDs
          equipmentID
          departmentID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateElectricalShockMutationVariables,
  APITypes.CreateElectricalShockMutation
>;
export const updateElectricalShock = /* GraphQL */ `mutation UpdateElectricalShock(
  $input: UpdateElectricalShockInput!
  $condition: ModelElectricalShockConditionInput
) {
  updateElectricalShock(input: $input, condition: $condition) {
    id
    title
    rxNormCode
    options {
      protocolID
      ranges {
        doseID
        index
        basis
        rangeLow
        rangeHigh
        calcMax
        fixedMax
        title
        warning
        instruction
        note
        ageLow
        ageHigh
        ageGroup
        __typename
      }
      __typename
    }
    warning
    instruction
    note
    rangeHigh
    rangeLow
    departmentID
    activeID
    overrideID
    status
    version
    createdBy
    modifiedBy
    metaData {
      parentID
      fullName
      nickName
      brandNames
      maxDose
      minDose
      absMaxDose
      absMinDose
      releaseNotes
      __typename
    }
    ElectricalDoses {
      items {
        id
        basis
        rangeLow
        rangeHigh
        title
        warning
        instruction
        note
        maxDose
        minDose
        maxTotalDose
        calcMax
        calcMin
        index
        ageLow
        ageHigh
        ageGroup
        repeatTime
        incrementAmnt
        departmentID
        protocolID
        electricalID
        cprAssistID
        activeID
        overrideID
        status
        version
        createdBy
        modifiedBy
        createdAt
        Protocol {
          id
          name
          pdfID
          categoryID
          pairedProtocols
          medicationIDs
          equipmentIDs
          electricalIDs
          dripIDs
          formIDs
          index
          nickname
          pairedDepIDs
          rangeLow
          rangeHigh
          ageLow
          ageHigh
          ageGroup
          status
          version
          pdfVersion
          activeID
          overrideID
          privatePin
          keychainID
          createdBy
          modifiedBy
          isPublic
          isRestrictive
          color
          tags
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Electrical {
          id
          title
          rxNormCode
          warning
          instruction
          note
          rangeHigh
          rangeLow
          departmentID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        CPRAssist {
          id
          protocolIDs
          epiIDs
          defibIDs
          equipmentID
          departmentID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateElectricalShockMutationVariables,
  APITypes.UpdateElectricalShockMutation
>;
export const deleteElectricalShock = /* GraphQL */ `mutation DeleteElectricalShock(
  $input: DeleteElectricalShockInput!
  $condition: ModelElectricalShockConditionInput
) {
  deleteElectricalShock(input: $input, condition: $condition) {
    id
    title
    rxNormCode
    options {
      protocolID
      ranges {
        doseID
        index
        basis
        rangeLow
        rangeHigh
        calcMax
        fixedMax
        title
        warning
        instruction
        note
        ageLow
        ageHigh
        ageGroup
        __typename
      }
      __typename
    }
    warning
    instruction
    note
    rangeHigh
    rangeLow
    departmentID
    activeID
    overrideID
    status
    version
    createdBy
    modifiedBy
    metaData {
      parentID
      fullName
      nickName
      brandNames
      maxDose
      minDose
      absMaxDose
      absMinDose
      releaseNotes
      __typename
    }
    ElectricalDoses {
      items {
        id
        basis
        rangeLow
        rangeHigh
        title
        warning
        instruction
        note
        maxDose
        minDose
        maxTotalDose
        calcMax
        calcMin
        index
        ageLow
        ageHigh
        ageGroup
        repeatTime
        incrementAmnt
        departmentID
        protocolID
        electricalID
        cprAssistID
        activeID
        overrideID
        status
        version
        createdBy
        modifiedBy
        createdAt
        Protocol {
          id
          name
          pdfID
          categoryID
          pairedProtocols
          medicationIDs
          equipmentIDs
          electricalIDs
          dripIDs
          formIDs
          index
          nickname
          pairedDepIDs
          rangeLow
          rangeHigh
          ageLow
          ageHigh
          ageGroup
          status
          version
          pdfVersion
          activeID
          overrideID
          privatePin
          keychainID
          createdBy
          modifiedBy
          isPublic
          isRestrictive
          color
          tags
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Electrical {
          id
          title
          rxNormCode
          warning
          instruction
          note
          rangeHigh
          rangeLow
          departmentID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        CPRAssist {
          id
          protocolIDs
          epiIDs
          defibIDs
          equipmentID
          departmentID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteElectricalShockMutationVariables,
  APITypes.DeleteElectricalShockMutation
>;
export const createElectricalDose = /* GraphQL */ `mutation CreateElectricalDose(
  $input: CreateElectricalDoseInput!
  $condition: ModelElectricalDoseConditionInput
) {
  createElectricalDose(input: $input, condition: $condition) {
    id
    basis
    rangeLow
    rangeHigh
    title
    warning
    instruction
    note
    maxDose
    minDose
    maxTotalDose
    calcMax
    calcMin
    index
    ageLow
    ageHigh
    ageGroup
    repeatTime
    incrementAmnt
    departmentID
    protocolID
    electricalID
    cprAssistID
    activeID
    overrideID
    status
    version
    createdBy
    modifiedBy
    createdAt
    Protocol {
      id
      name
      pdfID
      categoryID
      pairedProtocols
      medicationIDs
      equipmentIDs
      electricalIDs
      dripIDs
      formIDs
      index
      nickname
      pairedDepIDs
      rangeLow
      rangeHigh
      ageLow
      ageHigh
      ageGroup
      status
      version
      pdfVersion
      activeID
      overrideID
      privatePin
      keychainID
      createdBy
      modifiedBy
      isPublic
      isRestrictive
      color
      tags
      departmentID
      MedicationDoses {
        items {
          id
          basis
          rangeLow
          rangeHigh
          route
          title
          warning
          instruction
          note
          maxDose
          minDose
          maxTotalDose
          calcMax
          calcMin
          index
          ageLow
          ageHigh
          ageGroup
          repeatTime
          incrementAmnt
          departmentID
          protocolID
          medicationID
          cprAssistID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      ElectricalDoses {
        items {
          id
          basis
          rangeLow
          rangeHigh
          title
          warning
          instruction
          note
          maxDose
          minDose
          maxTotalDose
          calcMax
          calcMin
          index
          ageLow
          ageHigh
          ageGroup
          repeatTime
          incrementAmnt
          departmentID
          protocolID
          electricalID
          cprAssistID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      DripDoses {
        items {
          id
          basis
          rangeLow
          rangeHigh
          route
          title
          warning
          instruction
          note
          maxDose
          minDose
          maxTotalDose
          calcMax
          calcMin
          index
          ageLow
          ageHigh
          ageGroup
          repeatTime
          incrementAmnt
          departmentID
          protocolID
          dripID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    Electrical {
      id
      title
      rxNormCode
      options {
        protocolID
        ranges {
          doseID
          index
          basis
          rangeLow
          rangeHigh
          calcMax
          fixedMax
          title
          warning
          instruction
          note
          ageLow
          ageHigh
          ageGroup
          __typename
        }
        __typename
      }
      warning
      instruction
      note
      rangeHigh
      rangeLow
      departmentID
      activeID
      overrideID
      status
      version
      createdBy
      modifiedBy
      metaData {
        parentID
        fullName
        nickName
        brandNames
        maxDose
        minDose
        absMaxDose
        absMinDose
        releaseNotes
        __typename
      }
      ElectricalDoses {
        items {
          id
          basis
          rangeLow
          rangeHigh
          title
          warning
          instruction
          note
          maxDose
          minDose
          maxTotalDose
          calcMax
          calcMin
          index
          ageLow
          ageHigh
          ageGroup
          repeatTime
          incrementAmnt
          departmentID
          protocolID
          electricalID
          cprAssistID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    CPRAssist {
      id
      protocolIDs
      epiIDs
      defibIDs
      equipmentID
      departmentID
      activeID
      overrideID
      status
      version
      createdBy
      modifiedBy
      cprMetaData {
        defaultMode
        epiOrangeSec
        epiRedSec
        compressionRate
        ventRatePerMin
        ventilateSoundDelay
        ventilateLoops
        continousVentilateLoops
        ventilateSoundSpeed
        pulseCheckTime
        chargingTime
        __typename
      }
      DefibDoses {
        items {
          id
          basis
          rangeLow
          rangeHigh
          title
          warning
          instruction
          note
          maxDose
          minDose
          maxTotalDose
          calcMax
          calcMin
          index
          ageLow
          ageHigh
          ageGroup
          repeatTime
          incrementAmnt
          departmentID
          protocolID
          electricalID
          cprAssistID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      EpiDoses {
        items {
          id
          basis
          rangeLow
          rangeHigh
          route
          title
          warning
          instruction
          note
          maxDose
          minDose
          maxTotalDose
          calcMax
          calcMin
          index
          ageLow
          ageHigh
          ageGroup
          repeatTime
          incrementAmnt
          departmentID
          protocolID
          medicationID
          cprAssistID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateElectricalDoseMutationVariables,
  APITypes.CreateElectricalDoseMutation
>;
export const updateElectricalDose = /* GraphQL */ `mutation UpdateElectricalDose(
  $input: UpdateElectricalDoseInput!
  $condition: ModelElectricalDoseConditionInput
) {
  updateElectricalDose(input: $input, condition: $condition) {
    id
    basis
    rangeLow
    rangeHigh
    title
    warning
    instruction
    note
    maxDose
    minDose
    maxTotalDose
    calcMax
    calcMin
    index
    ageLow
    ageHigh
    ageGroup
    repeatTime
    incrementAmnt
    departmentID
    protocolID
    electricalID
    cprAssistID
    activeID
    overrideID
    status
    version
    createdBy
    modifiedBy
    createdAt
    Protocol {
      id
      name
      pdfID
      categoryID
      pairedProtocols
      medicationIDs
      equipmentIDs
      electricalIDs
      dripIDs
      formIDs
      index
      nickname
      pairedDepIDs
      rangeLow
      rangeHigh
      ageLow
      ageHigh
      ageGroup
      status
      version
      pdfVersion
      activeID
      overrideID
      privatePin
      keychainID
      createdBy
      modifiedBy
      isPublic
      isRestrictive
      color
      tags
      departmentID
      MedicationDoses {
        items {
          id
          basis
          rangeLow
          rangeHigh
          route
          title
          warning
          instruction
          note
          maxDose
          minDose
          maxTotalDose
          calcMax
          calcMin
          index
          ageLow
          ageHigh
          ageGroup
          repeatTime
          incrementAmnt
          departmentID
          protocolID
          medicationID
          cprAssistID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      ElectricalDoses {
        items {
          id
          basis
          rangeLow
          rangeHigh
          title
          warning
          instruction
          note
          maxDose
          minDose
          maxTotalDose
          calcMax
          calcMin
          index
          ageLow
          ageHigh
          ageGroup
          repeatTime
          incrementAmnt
          departmentID
          protocolID
          electricalID
          cprAssistID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      DripDoses {
        items {
          id
          basis
          rangeLow
          rangeHigh
          route
          title
          warning
          instruction
          note
          maxDose
          minDose
          maxTotalDose
          calcMax
          calcMin
          index
          ageLow
          ageHigh
          ageGroup
          repeatTime
          incrementAmnt
          departmentID
          protocolID
          dripID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    Electrical {
      id
      title
      rxNormCode
      options {
        protocolID
        ranges {
          doseID
          index
          basis
          rangeLow
          rangeHigh
          calcMax
          fixedMax
          title
          warning
          instruction
          note
          ageLow
          ageHigh
          ageGroup
          __typename
        }
        __typename
      }
      warning
      instruction
      note
      rangeHigh
      rangeLow
      departmentID
      activeID
      overrideID
      status
      version
      createdBy
      modifiedBy
      metaData {
        parentID
        fullName
        nickName
        brandNames
        maxDose
        minDose
        absMaxDose
        absMinDose
        releaseNotes
        __typename
      }
      ElectricalDoses {
        items {
          id
          basis
          rangeLow
          rangeHigh
          title
          warning
          instruction
          note
          maxDose
          minDose
          maxTotalDose
          calcMax
          calcMin
          index
          ageLow
          ageHigh
          ageGroup
          repeatTime
          incrementAmnt
          departmentID
          protocolID
          electricalID
          cprAssistID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    CPRAssist {
      id
      protocolIDs
      epiIDs
      defibIDs
      equipmentID
      departmentID
      activeID
      overrideID
      status
      version
      createdBy
      modifiedBy
      cprMetaData {
        defaultMode
        epiOrangeSec
        epiRedSec
        compressionRate
        ventRatePerMin
        ventilateSoundDelay
        ventilateLoops
        continousVentilateLoops
        ventilateSoundSpeed
        pulseCheckTime
        chargingTime
        __typename
      }
      DefibDoses {
        items {
          id
          basis
          rangeLow
          rangeHigh
          title
          warning
          instruction
          note
          maxDose
          minDose
          maxTotalDose
          calcMax
          calcMin
          index
          ageLow
          ageHigh
          ageGroup
          repeatTime
          incrementAmnt
          departmentID
          protocolID
          electricalID
          cprAssistID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      EpiDoses {
        items {
          id
          basis
          rangeLow
          rangeHigh
          route
          title
          warning
          instruction
          note
          maxDose
          minDose
          maxTotalDose
          calcMax
          calcMin
          index
          ageLow
          ageHigh
          ageGroup
          repeatTime
          incrementAmnt
          departmentID
          protocolID
          medicationID
          cprAssistID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateElectricalDoseMutationVariables,
  APITypes.UpdateElectricalDoseMutation
>;
export const deleteElectricalDose = /* GraphQL */ `mutation DeleteElectricalDose(
  $input: DeleteElectricalDoseInput!
  $condition: ModelElectricalDoseConditionInput
) {
  deleteElectricalDose(input: $input, condition: $condition) {
    id
    basis
    rangeLow
    rangeHigh
    title
    warning
    instruction
    note
    maxDose
    minDose
    maxTotalDose
    calcMax
    calcMin
    index
    ageLow
    ageHigh
    ageGroup
    repeatTime
    incrementAmnt
    departmentID
    protocolID
    electricalID
    cprAssistID
    activeID
    overrideID
    status
    version
    createdBy
    modifiedBy
    createdAt
    Protocol {
      id
      name
      pdfID
      categoryID
      pairedProtocols
      medicationIDs
      equipmentIDs
      electricalIDs
      dripIDs
      formIDs
      index
      nickname
      pairedDepIDs
      rangeLow
      rangeHigh
      ageLow
      ageHigh
      ageGroup
      status
      version
      pdfVersion
      activeID
      overrideID
      privatePin
      keychainID
      createdBy
      modifiedBy
      isPublic
      isRestrictive
      color
      tags
      departmentID
      MedicationDoses {
        items {
          id
          basis
          rangeLow
          rangeHigh
          route
          title
          warning
          instruction
          note
          maxDose
          minDose
          maxTotalDose
          calcMax
          calcMin
          index
          ageLow
          ageHigh
          ageGroup
          repeatTime
          incrementAmnt
          departmentID
          protocolID
          medicationID
          cprAssistID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      ElectricalDoses {
        items {
          id
          basis
          rangeLow
          rangeHigh
          title
          warning
          instruction
          note
          maxDose
          minDose
          maxTotalDose
          calcMax
          calcMin
          index
          ageLow
          ageHigh
          ageGroup
          repeatTime
          incrementAmnt
          departmentID
          protocolID
          electricalID
          cprAssistID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      DripDoses {
        items {
          id
          basis
          rangeLow
          rangeHigh
          route
          title
          warning
          instruction
          note
          maxDose
          minDose
          maxTotalDose
          calcMax
          calcMin
          index
          ageLow
          ageHigh
          ageGroup
          repeatTime
          incrementAmnt
          departmentID
          protocolID
          dripID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    Electrical {
      id
      title
      rxNormCode
      options {
        protocolID
        ranges {
          doseID
          index
          basis
          rangeLow
          rangeHigh
          calcMax
          fixedMax
          title
          warning
          instruction
          note
          ageLow
          ageHigh
          ageGroup
          __typename
        }
        __typename
      }
      warning
      instruction
      note
      rangeHigh
      rangeLow
      departmentID
      activeID
      overrideID
      status
      version
      createdBy
      modifiedBy
      metaData {
        parentID
        fullName
        nickName
        brandNames
        maxDose
        minDose
        absMaxDose
        absMinDose
        releaseNotes
        __typename
      }
      ElectricalDoses {
        items {
          id
          basis
          rangeLow
          rangeHigh
          title
          warning
          instruction
          note
          maxDose
          minDose
          maxTotalDose
          calcMax
          calcMin
          index
          ageLow
          ageHigh
          ageGroup
          repeatTime
          incrementAmnt
          departmentID
          protocolID
          electricalID
          cprAssistID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    CPRAssist {
      id
      protocolIDs
      epiIDs
      defibIDs
      equipmentID
      departmentID
      activeID
      overrideID
      status
      version
      createdBy
      modifiedBy
      cprMetaData {
        defaultMode
        epiOrangeSec
        epiRedSec
        compressionRate
        ventRatePerMin
        ventilateSoundDelay
        ventilateLoops
        continousVentilateLoops
        ventilateSoundSpeed
        pulseCheckTime
        chargingTime
        __typename
      }
      DefibDoses {
        items {
          id
          basis
          rangeLow
          rangeHigh
          title
          warning
          instruction
          note
          maxDose
          minDose
          maxTotalDose
          calcMax
          calcMin
          index
          ageLow
          ageHigh
          ageGroup
          repeatTime
          incrementAmnt
          departmentID
          protocolID
          electricalID
          cprAssistID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      EpiDoses {
        items {
          id
          basis
          rangeLow
          rangeHigh
          route
          title
          warning
          instruction
          note
          maxDose
          minDose
          maxTotalDose
          calcMax
          calcMin
          index
          ageLow
          ageHigh
          ageGroup
          repeatTime
          incrementAmnt
          departmentID
          protocolID
          medicationID
          cprAssistID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteElectricalDoseMutationVariables,
  APITypes.DeleteElectricalDoseMutation
>;
export const createDeveloperNotification = /* GraphQL */ `mutation CreateDeveloperNotification(
  $input: CreateDeveloperNotificationInput!
  $condition: ModelDeveloperNotificationConditionInput
) {
  createDeveloperNotification(input: $input, condition: $condition) {
    id
    title
    message
    targetVersion
    additionalData
    type
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateDeveloperNotificationMutationVariables,
  APITypes.CreateDeveloperNotificationMutation
>;
export const updateDeveloperNotification = /* GraphQL */ `mutation UpdateDeveloperNotification(
  $input: UpdateDeveloperNotificationInput!
  $condition: ModelDeveloperNotificationConditionInput
) {
  updateDeveloperNotification(input: $input, condition: $condition) {
    id
    title
    message
    targetVersion
    additionalData
    type
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateDeveloperNotificationMutationVariables,
  APITypes.UpdateDeveloperNotificationMutation
>;
export const deleteDeveloperNotification = /* GraphQL */ `mutation DeleteDeveloperNotification(
  $input: DeleteDeveloperNotificationInput!
  $condition: ModelDeveloperNotificationConditionInput
) {
  deleteDeveloperNotification(input: $input, condition: $condition) {
    id
    title
    message
    targetVersion
    additionalData
    type
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteDeveloperNotificationMutationVariables,
  APITypes.DeleteDeveloperNotificationMutation
>;
export const createVitals = /* GraphQL */ `mutation CreateVitals(
  $input: CreateVitalsInput!
  $condition: ModelVitalsConditionInput
) {
  createVitals(input: $input, condition: $condition) {
    id
    title
    options {
      id
      amntLow
      amntHigh
      rangeLow
      rangeHigh
      ageLow
      ageHigh
      index
      __typename
    }
    index
    departmentID
    activeID
    overrideID
    status
    version
    createdBy
    modifiedBy
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateVitalsMutationVariables,
  APITypes.CreateVitalsMutation
>;
export const updateVitals = /* GraphQL */ `mutation UpdateVitals(
  $input: UpdateVitalsInput!
  $condition: ModelVitalsConditionInput
) {
  updateVitals(input: $input, condition: $condition) {
    id
    title
    options {
      id
      amntLow
      amntHigh
      rangeLow
      rangeHigh
      ageLow
      ageHigh
      index
      __typename
    }
    index
    departmentID
    activeID
    overrideID
    status
    version
    createdBy
    modifiedBy
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateVitalsMutationVariables,
  APITypes.UpdateVitalsMutation
>;
export const deleteVitals = /* GraphQL */ `mutation DeleteVitals(
  $input: DeleteVitalsInput!
  $condition: ModelVitalsConditionInput
) {
  deleteVitals(input: $input, condition: $condition) {
    id
    title
    options {
      id
      amntLow
      amntHigh
      rangeLow
      rangeHigh
      ageLow
      ageHigh
      index
      __typename
    }
    index
    departmentID
    activeID
    overrideID
    status
    version
    createdBy
    modifiedBy
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteVitalsMutationVariables,
  APITypes.DeleteVitalsMutation
>;
export const createOneWeight = /* GraphQL */ `mutation CreateOneWeight(
  $input: CreateOneWeightInput!
  $condition: ModelOneWeightConditionInput
) {
  createOneWeight(input: $input, condition: $condition) {
    id
    name
    serialNumber
    projectNumber
    lastCalibration
    type
    departmentID
    department {
      id
      Categories {
        items {
          id
          name
          departmentID
          index
          pairedDepIDs
          parentCategoryID
          activeID
          status
          version
          createdBy
          modifiedBy
          isPublic
          isRestrictive
          color
          tags
          overrideID
          keychainID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          departmentCategoriesId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      OneWeights {
        items {
          id
          name
          serialNumber
          projectNumber
          lastCalibration
          type
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          departmentOneWeightsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      name
      location
      protocolVersions
      shiftTypes
      Medications {
        items {
          id
          name
          rxNormCode
          contraindication
          note
          warning
          route
          medClass
          action
          indication
          interaction
          onset
          duration
          departmentID
          rangeLow
          rangeHigh
          maxDose
          minDose
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Equipment {
        items {
          id
          name
          snomedCode
          departmentID
          title
          note
          warning
          instruction
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Forms {
        items {
          id
          name
          departmentID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Vitals {
        items {
          id
          title
          index
          departmentID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Electricals {
        items {
          id
          title
          departmentID
          activeID
          status
          version
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      ElectricalShocks {
        items {
          id
          title
          rxNormCode
          warning
          instruction
          note
          rangeHigh
          rangeLow
          departmentID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      cognitoID
      Users {
        items {
          id
          firstName
          lastName
          type
          cognitoID
          indexedParentDepID
          departmentID
          depAdmins
          hashedPin
          saltPin
          pairedDepIDs
          oneDoseVersion
          lastActivity
          notificationTokens
          deviceIDs
          maxDevices
          allowAnalytics
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Logs {
        items {
          id
          actualWeight
          ageValue
          protocolID
          departmentID
          oneweightID
          ambulanceID
          incidentNumber
          startTime
          endTime
          estimatedWeight
          patientSaved
          patientReleased
          arrivedAtHospital
          ageTimestamp
          userIDs
          estimatedSaved
          tags
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Ambulances {
        items {
          id
          name
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Drips {
        items {
          id
          name
          rxNormCode
          contraindication
          note
          warning
          route
          medClass
          action
          indication
          interaction
          onset
          duration
          departmentID
          rangeLow
          rangeHigh
          maxDose
          minDose
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      RequireSync {
        id
        sync
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      logoID
      userID
      neonateCutoff
      pediatricCutoff
      calculators
      adultRanges {
        label
        value
        __typename
      }
      softwarePlan
      Notifications {
        items {
          id
          type
          title
          message
          timestamp
          expirationTimestamp
          deadlineTimestamp
          isReadIDs
          isAckIDs
          imageURLs
          videoURLs
          fileURLs
          taggedProtocols
          isPush
          isPinRequired
          departmentID
          pairedDepIDs
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Contacts {
        items {
          id
          fullName
          title
          number
          note
          index
          departmentID
          pairedDepIDs
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      InputForms {
        items {
          id
          name
          departmentID
          pairedDepIDs
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      FormLogs {
        items {
          id
          inputformID
          departmentID
          userID
          pairedDepID
          pairedUserIDs
          notes
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Workbooks {
        items {
          id
          name
          workbookID
          fileSize
          aiPdfParserResults
          activeID
          createdBy
          modifiedBy
          status
          version
          pairedDepIDs
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      WeightObjects {
        items {
          id
          name
          weight
          createdBy
          modifiedBy
          version
          pairedDepIDs
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Keychains {
        items {
          id
          name
          hashedPin
          saltPin
          hashType
          version
          createdBy
          modifiedBy
          metaData
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Reviewals {
        items {
          id
          title
          description
          reviewers
          status
          createdAt
          endedAt
          departmentID
          userID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Groups {
        items {
          id
          name
          type
          departmentID
          pairedDepIDs
          pairedUserIDs
          isAutoGen
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      CPRAssists {
        items {
          id
          protocolIDs
          epiIDs
          defibIDs
          equipmentID
          departmentID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      subDepIDs
      parentDepID
      indexedParentDepID
      activeStatus
      agencyNumEMS
      stateIdEMS
      gnisCodeEMS
      uniqueCode
      uniquePublicURL
      hashedPin
      saltedPin
      keychainID
      isPublic
      infusionCalculation
      isPublicSignup
      config {
        isTopEnabled
        neonateCutoff
        pediatricCutoff
        calculators
        adultRanges {
          label
          value
          __typename
        }
        softwarePlan
        infusionCalculation
        isPublic
        realTimeUpdating
        epcrProvider
        oneweightEnabled
        ageFilterEnabled
        ageGroupFilterEnabled
        renewalDate
        infusionGTTS
        signedAgreement
        isToggleAdvancedFiltersEnabled
        premiumTrialExpiration
        estimationMethod
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      departmentRequireSyncId
      __typename
    }
    Logs {
      items {
        id
        actualWeight
        ageValue
        protocolID
        Users {
          nextToken
          startedAt
          __typename
        }
        departmentID
        oneweightID
        ambulanceID
        incidentNumber
        startTime
        endTime
        estimatedWeight
        medications {
          medicationID
          amntSolid
          amntLiquid
          timestamp
          verified
          name
          rxNormCode
          concentration
          isActualWeight
          adminType
          weight
          protocolID
          __typename
        }
        equipments {
          equipmentID
          amount
          name
          snomedCode
          timestamp
          isActualWeight
          verified
          weight
          successful
          protocolID
          __typename
        }
        electricals {
          electricalID
          name
          snomedCode
          amount
          timestamp
          isActualWeight
          verified
          weight
          protocolID
          __typename
        }
        cpr {
          cprID
          startTime
          endTime
          outcome
          __typename
        }
        emsCalculators {
          calculatorID
          responses
          scores
          timestamp
          __typename
        }
        patientSaved
        patientReleased
        arrivedAtHospital
        ageTimestamp
        forms {
          formID
          name
          completed
          startedTime
          protocolID
          __typename
        }
        subtractedItems {
          weightObjectID
          name
          weight
          added
          __typename
        }
        userIDs
        infusions {
          dripID
          amnt
          amnt2
          timestamp
          verified
          name
          rxNormCode
          concentration
          adminType
          weight
          protocolID
          amntUnit
          amntUnit2
          isActualWeight
          gttsDropFactor
          gttsAmount
          gttsUnit
          __typename
        }
        estimatedSaved
        tags
        shifts {
          shiftStart
          shiftEnd
          completedLogs
          taggedAmbulance
          taggedOneWeight
          taggedUsers
          ownerID
          departmentID
          metaData
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    departmentOneWeightsId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateOneWeightMutationVariables,
  APITypes.CreateOneWeightMutation
>;
export const updateOneWeight = /* GraphQL */ `mutation UpdateOneWeight(
  $input: UpdateOneWeightInput!
  $condition: ModelOneWeightConditionInput
) {
  updateOneWeight(input: $input, condition: $condition) {
    id
    name
    serialNumber
    projectNumber
    lastCalibration
    type
    departmentID
    department {
      id
      Categories {
        items {
          id
          name
          departmentID
          index
          pairedDepIDs
          parentCategoryID
          activeID
          status
          version
          createdBy
          modifiedBy
          isPublic
          isRestrictive
          color
          tags
          overrideID
          keychainID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          departmentCategoriesId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      OneWeights {
        items {
          id
          name
          serialNumber
          projectNumber
          lastCalibration
          type
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          departmentOneWeightsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      name
      location
      protocolVersions
      shiftTypes
      Medications {
        items {
          id
          name
          rxNormCode
          contraindication
          note
          warning
          route
          medClass
          action
          indication
          interaction
          onset
          duration
          departmentID
          rangeLow
          rangeHigh
          maxDose
          minDose
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Equipment {
        items {
          id
          name
          snomedCode
          departmentID
          title
          note
          warning
          instruction
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Forms {
        items {
          id
          name
          departmentID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Vitals {
        items {
          id
          title
          index
          departmentID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Electricals {
        items {
          id
          title
          departmentID
          activeID
          status
          version
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      ElectricalShocks {
        items {
          id
          title
          rxNormCode
          warning
          instruction
          note
          rangeHigh
          rangeLow
          departmentID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      cognitoID
      Users {
        items {
          id
          firstName
          lastName
          type
          cognitoID
          indexedParentDepID
          departmentID
          depAdmins
          hashedPin
          saltPin
          pairedDepIDs
          oneDoseVersion
          lastActivity
          notificationTokens
          deviceIDs
          maxDevices
          allowAnalytics
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Logs {
        items {
          id
          actualWeight
          ageValue
          protocolID
          departmentID
          oneweightID
          ambulanceID
          incidentNumber
          startTime
          endTime
          estimatedWeight
          patientSaved
          patientReleased
          arrivedAtHospital
          ageTimestamp
          userIDs
          estimatedSaved
          tags
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Ambulances {
        items {
          id
          name
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Drips {
        items {
          id
          name
          rxNormCode
          contraindication
          note
          warning
          route
          medClass
          action
          indication
          interaction
          onset
          duration
          departmentID
          rangeLow
          rangeHigh
          maxDose
          minDose
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      RequireSync {
        id
        sync
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      logoID
      userID
      neonateCutoff
      pediatricCutoff
      calculators
      adultRanges {
        label
        value
        __typename
      }
      softwarePlan
      Notifications {
        items {
          id
          type
          title
          message
          timestamp
          expirationTimestamp
          deadlineTimestamp
          isReadIDs
          isAckIDs
          imageURLs
          videoURLs
          fileURLs
          taggedProtocols
          isPush
          isPinRequired
          departmentID
          pairedDepIDs
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Contacts {
        items {
          id
          fullName
          title
          number
          note
          index
          departmentID
          pairedDepIDs
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      InputForms {
        items {
          id
          name
          departmentID
          pairedDepIDs
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      FormLogs {
        items {
          id
          inputformID
          departmentID
          userID
          pairedDepID
          pairedUserIDs
          notes
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Workbooks {
        items {
          id
          name
          workbookID
          fileSize
          aiPdfParserResults
          activeID
          createdBy
          modifiedBy
          status
          version
          pairedDepIDs
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      WeightObjects {
        items {
          id
          name
          weight
          createdBy
          modifiedBy
          version
          pairedDepIDs
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Keychains {
        items {
          id
          name
          hashedPin
          saltPin
          hashType
          version
          createdBy
          modifiedBy
          metaData
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Reviewals {
        items {
          id
          title
          description
          reviewers
          status
          createdAt
          endedAt
          departmentID
          userID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Groups {
        items {
          id
          name
          type
          departmentID
          pairedDepIDs
          pairedUserIDs
          isAutoGen
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      CPRAssists {
        items {
          id
          protocolIDs
          epiIDs
          defibIDs
          equipmentID
          departmentID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      subDepIDs
      parentDepID
      indexedParentDepID
      activeStatus
      agencyNumEMS
      stateIdEMS
      gnisCodeEMS
      uniqueCode
      uniquePublicURL
      hashedPin
      saltedPin
      keychainID
      isPublic
      infusionCalculation
      isPublicSignup
      config {
        isTopEnabled
        neonateCutoff
        pediatricCutoff
        calculators
        adultRanges {
          label
          value
          __typename
        }
        softwarePlan
        infusionCalculation
        isPublic
        realTimeUpdating
        epcrProvider
        oneweightEnabled
        ageFilterEnabled
        ageGroupFilterEnabled
        renewalDate
        infusionGTTS
        signedAgreement
        isToggleAdvancedFiltersEnabled
        premiumTrialExpiration
        estimationMethod
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      departmentRequireSyncId
      __typename
    }
    Logs {
      items {
        id
        actualWeight
        ageValue
        protocolID
        Users {
          nextToken
          startedAt
          __typename
        }
        departmentID
        oneweightID
        ambulanceID
        incidentNumber
        startTime
        endTime
        estimatedWeight
        medications {
          medicationID
          amntSolid
          amntLiquid
          timestamp
          verified
          name
          rxNormCode
          concentration
          isActualWeight
          adminType
          weight
          protocolID
          __typename
        }
        equipments {
          equipmentID
          amount
          name
          snomedCode
          timestamp
          isActualWeight
          verified
          weight
          successful
          protocolID
          __typename
        }
        electricals {
          electricalID
          name
          snomedCode
          amount
          timestamp
          isActualWeight
          verified
          weight
          protocolID
          __typename
        }
        cpr {
          cprID
          startTime
          endTime
          outcome
          __typename
        }
        emsCalculators {
          calculatorID
          responses
          scores
          timestamp
          __typename
        }
        patientSaved
        patientReleased
        arrivedAtHospital
        ageTimestamp
        forms {
          formID
          name
          completed
          startedTime
          protocolID
          __typename
        }
        subtractedItems {
          weightObjectID
          name
          weight
          added
          __typename
        }
        userIDs
        infusions {
          dripID
          amnt
          amnt2
          timestamp
          verified
          name
          rxNormCode
          concentration
          adminType
          weight
          protocolID
          amntUnit
          amntUnit2
          isActualWeight
          gttsDropFactor
          gttsAmount
          gttsUnit
          __typename
        }
        estimatedSaved
        tags
        shifts {
          shiftStart
          shiftEnd
          completedLogs
          taggedAmbulance
          taggedOneWeight
          taggedUsers
          ownerID
          departmentID
          metaData
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    departmentOneWeightsId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateOneWeightMutationVariables,
  APITypes.UpdateOneWeightMutation
>;
export const deleteOneWeight = /* GraphQL */ `mutation DeleteOneWeight(
  $input: DeleteOneWeightInput!
  $condition: ModelOneWeightConditionInput
) {
  deleteOneWeight(input: $input, condition: $condition) {
    id
    name
    serialNumber
    projectNumber
    lastCalibration
    type
    departmentID
    department {
      id
      Categories {
        items {
          id
          name
          departmentID
          index
          pairedDepIDs
          parentCategoryID
          activeID
          status
          version
          createdBy
          modifiedBy
          isPublic
          isRestrictive
          color
          tags
          overrideID
          keychainID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          departmentCategoriesId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      OneWeights {
        items {
          id
          name
          serialNumber
          projectNumber
          lastCalibration
          type
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          departmentOneWeightsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      name
      location
      protocolVersions
      shiftTypes
      Medications {
        items {
          id
          name
          rxNormCode
          contraindication
          note
          warning
          route
          medClass
          action
          indication
          interaction
          onset
          duration
          departmentID
          rangeLow
          rangeHigh
          maxDose
          minDose
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Equipment {
        items {
          id
          name
          snomedCode
          departmentID
          title
          note
          warning
          instruction
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Forms {
        items {
          id
          name
          departmentID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Vitals {
        items {
          id
          title
          index
          departmentID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Electricals {
        items {
          id
          title
          departmentID
          activeID
          status
          version
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      ElectricalShocks {
        items {
          id
          title
          rxNormCode
          warning
          instruction
          note
          rangeHigh
          rangeLow
          departmentID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      cognitoID
      Users {
        items {
          id
          firstName
          lastName
          type
          cognitoID
          indexedParentDepID
          departmentID
          depAdmins
          hashedPin
          saltPin
          pairedDepIDs
          oneDoseVersion
          lastActivity
          notificationTokens
          deviceIDs
          maxDevices
          allowAnalytics
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Logs {
        items {
          id
          actualWeight
          ageValue
          protocolID
          departmentID
          oneweightID
          ambulanceID
          incidentNumber
          startTime
          endTime
          estimatedWeight
          patientSaved
          patientReleased
          arrivedAtHospital
          ageTimestamp
          userIDs
          estimatedSaved
          tags
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Ambulances {
        items {
          id
          name
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Drips {
        items {
          id
          name
          rxNormCode
          contraindication
          note
          warning
          route
          medClass
          action
          indication
          interaction
          onset
          duration
          departmentID
          rangeLow
          rangeHigh
          maxDose
          minDose
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      RequireSync {
        id
        sync
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      logoID
      userID
      neonateCutoff
      pediatricCutoff
      calculators
      adultRanges {
        label
        value
        __typename
      }
      softwarePlan
      Notifications {
        items {
          id
          type
          title
          message
          timestamp
          expirationTimestamp
          deadlineTimestamp
          isReadIDs
          isAckIDs
          imageURLs
          videoURLs
          fileURLs
          taggedProtocols
          isPush
          isPinRequired
          departmentID
          pairedDepIDs
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Contacts {
        items {
          id
          fullName
          title
          number
          note
          index
          departmentID
          pairedDepIDs
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      InputForms {
        items {
          id
          name
          departmentID
          pairedDepIDs
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      FormLogs {
        items {
          id
          inputformID
          departmentID
          userID
          pairedDepID
          pairedUserIDs
          notes
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Workbooks {
        items {
          id
          name
          workbookID
          fileSize
          aiPdfParserResults
          activeID
          createdBy
          modifiedBy
          status
          version
          pairedDepIDs
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      WeightObjects {
        items {
          id
          name
          weight
          createdBy
          modifiedBy
          version
          pairedDepIDs
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Keychains {
        items {
          id
          name
          hashedPin
          saltPin
          hashType
          version
          createdBy
          modifiedBy
          metaData
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Reviewals {
        items {
          id
          title
          description
          reviewers
          status
          createdAt
          endedAt
          departmentID
          userID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Groups {
        items {
          id
          name
          type
          departmentID
          pairedDepIDs
          pairedUserIDs
          isAutoGen
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      CPRAssists {
        items {
          id
          protocolIDs
          epiIDs
          defibIDs
          equipmentID
          departmentID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      subDepIDs
      parentDepID
      indexedParentDepID
      activeStatus
      agencyNumEMS
      stateIdEMS
      gnisCodeEMS
      uniqueCode
      uniquePublicURL
      hashedPin
      saltedPin
      keychainID
      isPublic
      infusionCalculation
      isPublicSignup
      config {
        isTopEnabled
        neonateCutoff
        pediatricCutoff
        calculators
        adultRanges {
          label
          value
          __typename
        }
        softwarePlan
        infusionCalculation
        isPublic
        realTimeUpdating
        epcrProvider
        oneweightEnabled
        ageFilterEnabled
        ageGroupFilterEnabled
        renewalDate
        infusionGTTS
        signedAgreement
        isToggleAdvancedFiltersEnabled
        premiumTrialExpiration
        estimationMethod
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      departmentRequireSyncId
      __typename
    }
    Logs {
      items {
        id
        actualWeight
        ageValue
        protocolID
        Users {
          nextToken
          startedAt
          __typename
        }
        departmentID
        oneweightID
        ambulanceID
        incidentNumber
        startTime
        endTime
        estimatedWeight
        medications {
          medicationID
          amntSolid
          amntLiquid
          timestamp
          verified
          name
          rxNormCode
          concentration
          isActualWeight
          adminType
          weight
          protocolID
          __typename
        }
        equipments {
          equipmentID
          amount
          name
          snomedCode
          timestamp
          isActualWeight
          verified
          weight
          successful
          protocolID
          __typename
        }
        electricals {
          electricalID
          name
          snomedCode
          amount
          timestamp
          isActualWeight
          verified
          weight
          protocolID
          __typename
        }
        cpr {
          cprID
          startTime
          endTime
          outcome
          __typename
        }
        emsCalculators {
          calculatorID
          responses
          scores
          timestamp
          __typename
        }
        patientSaved
        patientReleased
        arrivedAtHospital
        ageTimestamp
        forms {
          formID
          name
          completed
          startedTime
          protocolID
          __typename
        }
        subtractedItems {
          weightObjectID
          name
          weight
          added
          __typename
        }
        userIDs
        infusions {
          dripID
          amnt
          amnt2
          timestamp
          verified
          name
          rxNormCode
          concentration
          adminType
          weight
          protocolID
          amntUnit
          amntUnit2
          isActualWeight
          gttsDropFactor
          gttsAmount
          gttsUnit
          __typename
        }
        estimatedSaved
        tags
        shifts {
          shiftStart
          shiftEnd
          completedLogs
          taggedAmbulance
          taggedOneWeight
          taggedUsers
          ownerID
          departmentID
          metaData
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    departmentOneWeightsId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteOneWeightMutationVariables,
  APITypes.DeleteOneWeightMutation
>;
export const createForm = /* GraphQL */ `mutation CreateForm(
  $input: CreateFormInput!
  $condition: ModelFormConditionInput
) {
  createForm(input: $input, condition: $condition) {
    id
    name
    departmentID
    items {
      id
      title
      options {
        id
        firstItem
        secItem
        index
        __typename
      }
      isUrgent
      isDouble
      index
      __typename
    }
    activeID
    overrideID
    status
    version
    createdBy
    modifiedBy
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateFormMutationVariables,
  APITypes.CreateFormMutation
>;
export const updateForm = /* GraphQL */ `mutation UpdateForm(
  $input: UpdateFormInput!
  $condition: ModelFormConditionInput
) {
  updateForm(input: $input, condition: $condition) {
    id
    name
    departmentID
    items {
      id
      title
      options {
        id
        firstItem
        secItem
        index
        __typename
      }
      isUrgent
      isDouble
      index
      __typename
    }
    activeID
    overrideID
    status
    version
    createdBy
    modifiedBy
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateFormMutationVariables,
  APITypes.UpdateFormMutation
>;
export const deleteForm = /* GraphQL */ `mutation DeleteForm(
  $input: DeleteFormInput!
  $condition: ModelFormConditionInput
) {
  deleteForm(input: $input, condition: $condition) {
    id
    name
    departmentID
    items {
      id
      title
      options {
        id
        firstItem
        secItem
        index
        __typename
      }
      isUrgent
      isDouble
      index
      __typename
    }
    activeID
    overrideID
    status
    version
    createdBy
    modifiedBy
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteFormMutationVariables,
  APITypes.DeleteFormMutation
>;
export const createLog = /* GraphQL */ `mutation CreateLog(
  $input: CreateLogInput!
  $condition: ModelLogConditionInput
) {
  createLog(input: $input, condition: $condition) {
    id
    actualWeight
    ageValue
    protocolID
    Users {
      items {
        id
        userId
        logId
        user {
          id
          firstName
          lastName
          type
          cognitoID
          indexedParentDepID
          departmentID
          depAdmins
          hashedPin
          saltPin
          pairedDepIDs
          oneDoseVersion
          lastActivity
          notificationTokens
          deviceIDs
          maxDevices
          allowAnalytics
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        log {
          id
          actualWeight
          ageValue
          protocolID
          departmentID
          oneweightID
          ambulanceID
          incidentNumber
          startTime
          endTime
          estimatedWeight
          patientSaved
          patientReleased
          arrivedAtHospital
          ageTimestamp
          userIDs
          estimatedSaved
          tags
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    departmentID
    oneweightID
    ambulanceID
    incidentNumber
    startTime
    endTime
    estimatedWeight
    medications {
      medicationID
      amntSolid
      amntLiquid
      timestamp
      verified
      name
      rxNormCode
      concentration
      isActualWeight
      adminType
      route {
        route
        code
        __typename
      }
      weight
      protocolID
      __typename
    }
    equipments {
      equipmentID
      amount
      name
      snomedCode
      timestamp
      isActualWeight
      verified
      weight
      successful
      protocolID
      __typename
    }
    electricals {
      electricalID
      name
      snomedCode
      amount
      timestamp
      isActualWeight
      verified
      weight
      protocolID
      __typename
    }
    cpr {
      cprID
      startTime
      endTime
      outcome
      __typename
    }
    emsCalculators {
      calculatorID
      responses
      scores
      timestamp
      __typename
    }
    patientSaved
    patientReleased
    arrivedAtHospital
    ageTimestamp
    forms {
      formID
      name
      completed
      startedTime
      protocolID
      __typename
    }
    subtractedItems {
      weightObjectID
      name
      weight
      added
      __typename
    }
    userIDs
    infusions {
      dripID
      amnt
      amnt2
      timestamp
      verified
      name
      rxNormCode
      concentration
      adminType
      route {
        route
        code
        __typename
      }
      weight
      protocolID
      amntUnit
      amntUnit2
      isActualWeight
      gttsDropFactor
      gttsAmount
      gttsUnit
      __typename
    }
    estimatedSaved
    tags
    shifts {
      shiftStart
      shiftEnd
      completedLogs
      taggedAmbulance
      taggedOneWeight
      taggedUsers
      ownerID
      departmentID
      metaData
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateLogMutationVariables,
  APITypes.CreateLogMutation
>;
export const updateLog = /* GraphQL */ `mutation UpdateLog(
  $input: UpdateLogInput!
  $condition: ModelLogConditionInput
) {
  updateLog(input: $input, condition: $condition) {
    id
    actualWeight
    ageValue
    protocolID
    Users {
      items {
        id
        userId
        logId
        user {
          id
          firstName
          lastName
          type
          cognitoID
          indexedParentDepID
          departmentID
          depAdmins
          hashedPin
          saltPin
          pairedDepIDs
          oneDoseVersion
          lastActivity
          notificationTokens
          deviceIDs
          maxDevices
          allowAnalytics
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        log {
          id
          actualWeight
          ageValue
          protocolID
          departmentID
          oneweightID
          ambulanceID
          incidentNumber
          startTime
          endTime
          estimatedWeight
          patientSaved
          patientReleased
          arrivedAtHospital
          ageTimestamp
          userIDs
          estimatedSaved
          tags
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    departmentID
    oneweightID
    ambulanceID
    incidentNumber
    startTime
    endTime
    estimatedWeight
    medications {
      medicationID
      amntSolid
      amntLiquid
      timestamp
      verified
      name
      rxNormCode
      concentration
      isActualWeight
      adminType
      route {
        route
        code
        __typename
      }
      weight
      protocolID
      __typename
    }
    equipments {
      equipmentID
      amount
      name
      snomedCode
      timestamp
      isActualWeight
      verified
      weight
      successful
      protocolID
      __typename
    }
    electricals {
      electricalID
      name
      snomedCode
      amount
      timestamp
      isActualWeight
      verified
      weight
      protocolID
      __typename
    }
    cpr {
      cprID
      startTime
      endTime
      outcome
      __typename
    }
    emsCalculators {
      calculatorID
      responses
      scores
      timestamp
      __typename
    }
    patientSaved
    patientReleased
    arrivedAtHospital
    ageTimestamp
    forms {
      formID
      name
      completed
      startedTime
      protocolID
      __typename
    }
    subtractedItems {
      weightObjectID
      name
      weight
      added
      __typename
    }
    userIDs
    infusions {
      dripID
      amnt
      amnt2
      timestamp
      verified
      name
      rxNormCode
      concentration
      adminType
      route {
        route
        code
        __typename
      }
      weight
      protocolID
      amntUnit
      amntUnit2
      isActualWeight
      gttsDropFactor
      gttsAmount
      gttsUnit
      __typename
    }
    estimatedSaved
    tags
    shifts {
      shiftStart
      shiftEnd
      completedLogs
      taggedAmbulance
      taggedOneWeight
      taggedUsers
      ownerID
      departmentID
      metaData
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateLogMutationVariables,
  APITypes.UpdateLogMutation
>;
export const deleteLog = /* GraphQL */ `mutation DeleteLog(
  $input: DeleteLogInput!
  $condition: ModelLogConditionInput
) {
  deleteLog(input: $input, condition: $condition) {
    id
    actualWeight
    ageValue
    protocolID
    Users {
      items {
        id
        userId
        logId
        user {
          id
          firstName
          lastName
          type
          cognitoID
          indexedParentDepID
          departmentID
          depAdmins
          hashedPin
          saltPin
          pairedDepIDs
          oneDoseVersion
          lastActivity
          notificationTokens
          deviceIDs
          maxDevices
          allowAnalytics
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        log {
          id
          actualWeight
          ageValue
          protocolID
          departmentID
          oneweightID
          ambulanceID
          incidentNumber
          startTime
          endTime
          estimatedWeight
          patientSaved
          patientReleased
          arrivedAtHospital
          ageTimestamp
          userIDs
          estimatedSaved
          tags
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    departmentID
    oneweightID
    ambulanceID
    incidentNumber
    startTime
    endTime
    estimatedWeight
    medications {
      medicationID
      amntSolid
      amntLiquid
      timestamp
      verified
      name
      rxNormCode
      concentration
      isActualWeight
      adminType
      route {
        route
        code
        __typename
      }
      weight
      protocolID
      __typename
    }
    equipments {
      equipmentID
      amount
      name
      snomedCode
      timestamp
      isActualWeight
      verified
      weight
      successful
      protocolID
      __typename
    }
    electricals {
      electricalID
      name
      snomedCode
      amount
      timestamp
      isActualWeight
      verified
      weight
      protocolID
      __typename
    }
    cpr {
      cprID
      startTime
      endTime
      outcome
      __typename
    }
    emsCalculators {
      calculatorID
      responses
      scores
      timestamp
      __typename
    }
    patientSaved
    patientReleased
    arrivedAtHospital
    ageTimestamp
    forms {
      formID
      name
      completed
      startedTime
      protocolID
      __typename
    }
    subtractedItems {
      weightObjectID
      name
      weight
      added
      __typename
    }
    userIDs
    infusions {
      dripID
      amnt
      amnt2
      timestamp
      verified
      name
      rxNormCode
      concentration
      adminType
      route {
        route
        code
        __typename
      }
      weight
      protocolID
      amntUnit
      amntUnit2
      isActualWeight
      gttsDropFactor
      gttsAmount
      gttsUnit
      __typename
    }
    estimatedSaved
    tags
    shifts {
      shiftStart
      shiftEnd
      completedLogs
      taggedAmbulance
      taggedOneWeight
      taggedUsers
      ownerID
      departmentID
      metaData
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteLogMutationVariables,
  APITypes.DeleteLogMutation
>;
export const createRequireSync = /* GraphQL */ `mutation CreateRequireSync(
  $input: CreateRequireSyncInput!
  $condition: ModelRequireSyncConditionInput
) {
  createRequireSync(input: $input, condition: $condition) {
    id
    sync
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateRequireSyncMutationVariables,
  APITypes.CreateRequireSyncMutation
>;
export const updateRequireSync = /* GraphQL */ `mutation UpdateRequireSync(
  $input: UpdateRequireSyncInput!
  $condition: ModelRequireSyncConditionInput
) {
  updateRequireSync(input: $input, condition: $condition) {
    id
    sync
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateRequireSyncMutationVariables,
  APITypes.UpdateRequireSyncMutation
>;
export const deleteRequireSync = /* GraphQL */ `mutation DeleteRequireSync(
  $input: DeleteRequireSyncInput!
  $condition: ModelRequireSyncConditionInput
) {
  deleteRequireSync(input: $input, condition: $condition) {
    id
    sync
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteRequireSyncMutationVariables,
  APITypes.DeleteRequireSyncMutation
>;
export const createHardwareVersion = /* GraphQL */ `mutation CreateHardwareVersion(
  $input: CreateHardwareVersionInput!
  $condition: ModelHardwareVersionConditionInput
) {
  createHardwareVersion(input: $input, condition: $condition) {
    id
    version
    name
    description
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateHardwareVersionMutationVariables,
  APITypes.CreateHardwareVersionMutation
>;
export const updateHardwareVersion = /* GraphQL */ `mutation UpdateHardwareVersion(
  $input: UpdateHardwareVersionInput!
  $condition: ModelHardwareVersionConditionInput
) {
  updateHardwareVersion(input: $input, condition: $condition) {
    id
    version
    name
    description
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateHardwareVersionMutationVariables,
  APITypes.UpdateHardwareVersionMutation
>;
export const deleteHardwareVersion = /* GraphQL */ `mutation DeleteHardwareVersion(
  $input: DeleteHardwareVersionInput!
  $condition: ModelHardwareVersionConditionInput
) {
  deleteHardwareVersion(input: $input, condition: $condition) {
    id
    version
    name
    description
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteHardwareVersionMutationVariables,
  APITypes.DeleteHardwareVersionMutation
>;
export const createFirmwareVersion = /* GraphQL */ `mutation CreateFirmwareVersion(
  $input: CreateFirmwareVersionInput!
  $condition: ModelFirmwareVersionConditionInput
) {
  createFirmwareVersion(input: $input, condition: $condition) {
    id
    name
    version
    fileURL
    description
    cover_image
    compatibleHW
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateFirmwareVersionMutationVariables,
  APITypes.CreateFirmwareVersionMutation
>;
export const updateFirmwareVersion = /* GraphQL */ `mutation UpdateFirmwareVersion(
  $input: UpdateFirmwareVersionInput!
  $condition: ModelFirmwareVersionConditionInput
) {
  updateFirmwareVersion(input: $input, condition: $condition) {
    id
    name
    version
    fileURL
    description
    cover_image
    compatibleHW
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateFirmwareVersionMutationVariables,
  APITypes.UpdateFirmwareVersionMutation
>;
export const deleteFirmwareVersion = /* GraphQL */ `mutation DeleteFirmwareVersion(
  $input: DeleteFirmwareVersionInput!
  $condition: ModelFirmwareVersionConditionInput
) {
  deleteFirmwareVersion(input: $input, condition: $condition) {
    id
    name
    version
    fileURL
    description
    cover_image
    compatibleHW
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteFirmwareVersionMutationVariables,
  APITypes.DeleteFirmwareVersionMutation
>;
export const createWorkbook = /* GraphQL */ `mutation CreateWorkbook(
  $input: CreateWorkbookInput!
  $condition: ModelWorkbookConditionInput
) {
  createWorkbook(input: $input, condition: $condition) {
    id
    name
    workbookID
    fileSize
    aiPdfParserResults
    activeID
    createdBy
    modifiedBy
    status
    version
    pairedDepIDs
    departmentID
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateWorkbookMutationVariables,
  APITypes.CreateWorkbookMutation
>;
export const updateWorkbook = /* GraphQL */ `mutation UpdateWorkbook(
  $input: UpdateWorkbookInput!
  $condition: ModelWorkbookConditionInput
) {
  updateWorkbook(input: $input, condition: $condition) {
    id
    name
    workbookID
    fileSize
    aiPdfParserResults
    activeID
    createdBy
    modifiedBy
    status
    version
    pairedDepIDs
    departmentID
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateWorkbookMutationVariables,
  APITypes.UpdateWorkbookMutation
>;
export const deleteWorkbook = /* GraphQL */ `mutation DeleteWorkbook(
  $input: DeleteWorkbookInput!
  $condition: ModelWorkbookConditionInput
) {
  deleteWorkbook(input: $input, condition: $condition) {
    id
    name
    workbookID
    fileSize
    aiPdfParserResults
    activeID
    createdBy
    modifiedBy
    status
    version
    pairedDepIDs
    departmentID
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteWorkbookMutationVariables,
  APITypes.DeleteWorkbookMutation
>;
export const createWeightObject = /* GraphQL */ `mutation CreateWeightObject(
  $input: CreateWeightObjectInput!
  $condition: ModelWeightObjectConditionInput
) {
  createWeightObject(input: $input, condition: $condition) {
    id
    name
    weight
    createdBy
    modifiedBy
    version
    pairedDepIDs
    departmentID
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateWeightObjectMutationVariables,
  APITypes.CreateWeightObjectMutation
>;
export const updateWeightObject = /* GraphQL */ `mutation UpdateWeightObject(
  $input: UpdateWeightObjectInput!
  $condition: ModelWeightObjectConditionInput
) {
  updateWeightObject(input: $input, condition: $condition) {
    id
    name
    weight
    createdBy
    modifiedBy
    version
    pairedDepIDs
    departmentID
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateWeightObjectMutationVariables,
  APITypes.UpdateWeightObjectMutation
>;
export const deleteWeightObject = /* GraphQL */ `mutation DeleteWeightObject(
  $input: DeleteWeightObjectInput!
  $condition: ModelWeightObjectConditionInput
) {
  deleteWeightObject(input: $input, condition: $condition) {
    id
    name
    weight
    createdBy
    modifiedBy
    version
    pairedDepIDs
    departmentID
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteWeightObjectMutationVariables,
  APITypes.DeleteWeightObjectMutation
>;
export const createConcentration = /* GraphQL */ `mutation CreateConcentration(
  $input: CreateConcentrationInput!
  $condition: ModelConcentrationConditionInput
) {
  createConcentration(input: $input, condition: $condition) {
    id
    firstAmnt
    firstUnit
    secAmnt
    secUnit
    color
    departmentID
    medicationID
    dripID
    status
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateConcentrationMutationVariables,
  APITypes.CreateConcentrationMutation
>;
export const updateConcentration = /* GraphQL */ `mutation UpdateConcentration(
  $input: UpdateConcentrationInput!
  $condition: ModelConcentrationConditionInput
) {
  updateConcentration(input: $input, condition: $condition) {
    id
    firstAmnt
    firstUnit
    secAmnt
    secUnit
    color
    departmentID
    medicationID
    dripID
    status
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateConcentrationMutationVariables,
  APITypes.UpdateConcentrationMutation
>;
export const deleteConcentration = /* GraphQL */ `mutation DeleteConcentration(
  $input: DeleteConcentrationInput!
  $condition: ModelConcentrationConditionInput
) {
  deleteConcentration(input: $input, condition: $condition) {
    id
    firstAmnt
    firstUnit
    secAmnt
    secUnit
    color
    departmentID
    medicationID
    dripID
    status
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteConcentrationMutationVariables,
  APITypes.DeleteConcentrationMutation
>;
export const createCPRAssist = /* GraphQL */ `mutation CreateCPRAssist(
  $input: CreateCPRAssistInput!
  $condition: ModelCPRAssistConditionInput
) {
  createCPRAssist(input: $input, condition: $condition) {
    id
    protocolIDs
    epiIDs
    defibIDs
    equipmentID
    departmentID
    activeID
    overrideID
    status
    version
    createdBy
    modifiedBy
    cprMetaData {
      defaultMode
      epiOrangeSec
      epiRedSec
      compressionRate
      ventRatePerMin
      ventilateSoundDelay
      ventilateLoops
      continousVentilateLoops
      ventilateSoundSpeed
      pulseCheckTime
      chargingTime
      __typename
    }
    DefibDoses {
      items {
        id
        basis
        rangeLow
        rangeHigh
        title
        warning
        instruction
        note
        maxDose
        minDose
        maxTotalDose
        calcMax
        calcMin
        index
        ageLow
        ageHigh
        ageGroup
        repeatTime
        incrementAmnt
        departmentID
        protocolID
        electricalID
        cprAssistID
        activeID
        overrideID
        status
        version
        createdBy
        modifiedBy
        createdAt
        Protocol {
          id
          name
          pdfID
          categoryID
          pairedProtocols
          medicationIDs
          equipmentIDs
          electricalIDs
          dripIDs
          formIDs
          index
          nickname
          pairedDepIDs
          rangeLow
          rangeHigh
          ageLow
          ageHigh
          ageGroup
          status
          version
          pdfVersion
          activeID
          overrideID
          privatePin
          keychainID
          createdBy
          modifiedBy
          isPublic
          isRestrictive
          color
          tags
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Electrical {
          id
          title
          rxNormCode
          warning
          instruction
          note
          rangeHigh
          rangeLow
          departmentID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        CPRAssist {
          id
          protocolIDs
          epiIDs
          defibIDs
          equipmentID
          departmentID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    EpiDoses {
      items {
        id
        basis
        rangeLow
        rangeHigh
        route
        nemsisRoutes {
          route
          code
          __typename
        }
        title
        warning
        instruction
        note
        maxDose
        minDose
        maxTotalDose
        calcMax
        calcMin
        index
        ageLow
        ageHigh
        ageGroup
        repeatTime
        incrementAmnt
        departmentID
        protocolID
        medicationID
        cprAssistID
        activeID
        overrideID
        status
        version
        createdBy
        modifiedBy
        createdAt
        Protocol {
          id
          name
          pdfID
          categoryID
          pairedProtocols
          medicationIDs
          equipmentIDs
          electricalIDs
          dripIDs
          formIDs
          index
          nickname
          pairedDepIDs
          rangeLow
          rangeHigh
          ageLow
          ageHigh
          ageGroup
          status
          version
          pdfVersion
          activeID
          overrideID
          privatePin
          keychainID
          createdBy
          modifiedBy
          isPublic
          isRestrictive
          color
          tags
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Medication {
          id
          name
          rxNormCode
          contraindication
          note
          warning
          route
          medClass
          action
          indication
          interaction
          onset
          duration
          departmentID
          rangeLow
          rangeHigh
          maxDose
          minDose
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        CPRAssist {
          id
          protocolIDs
          epiIDs
          defibIDs
          equipmentID
          departmentID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCPRAssistMutationVariables,
  APITypes.CreateCPRAssistMutation
>;
export const updateCPRAssist = /* GraphQL */ `mutation UpdateCPRAssist(
  $input: UpdateCPRAssistInput!
  $condition: ModelCPRAssistConditionInput
) {
  updateCPRAssist(input: $input, condition: $condition) {
    id
    protocolIDs
    epiIDs
    defibIDs
    equipmentID
    departmentID
    activeID
    overrideID
    status
    version
    createdBy
    modifiedBy
    cprMetaData {
      defaultMode
      epiOrangeSec
      epiRedSec
      compressionRate
      ventRatePerMin
      ventilateSoundDelay
      ventilateLoops
      continousVentilateLoops
      ventilateSoundSpeed
      pulseCheckTime
      chargingTime
      __typename
    }
    DefibDoses {
      items {
        id
        basis
        rangeLow
        rangeHigh
        title
        warning
        instruction
        note
        maxDose
        minDose
        maxTotalDose
        calcMax
        calcMin
        index
        ageLow
        ageHigh
        ageGroup
        repeatTime
        incrementAmnt
        departmentID
        protocolID
        electricalID
        cprAssistID
        activeID
        overrideID
        status
        version
        createdBy
        modifiedBy
        createdAt
        Protocol {
          id
          name
          pdfID
          categoryID
          pairedProtocols
          medicationIDs
          equipmentIDs
          electricalIDs
          dripIDs
          formIDs
          index
          nickname
          pairedDepIDs
          rangeLow
          rangeHigh
          ageLow
          ageHigh
          ageGroup
          status
          version
          pdfVersion
          activeID
          overrideID
          privatePin
          keychainID
          createdBy
          modifiedBy
          isPublic
          isRestrictive
          color
          tags
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Electrical {
          id
          title
          rxNormCode
          warning
          instruction
          note
          rangeHigh
          rangeLow
          departmentID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        CPRAssist {
          id
          protocolIDs
          epiIDs
          defibIDs
          equipmentID
          departmentID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    EpiDoses {
      items {
        id
        basis
        rangeLow
        rangeHigh
        route
        nemsisRoutes {
          route
          code
          __typename
        }
        title
        warning
        instruction
        note
        maxDose
        minDose
        maxTotalDose
        calcMax
        calcMin
        index
        ageLow
        ageHigh
        ageGroup
        repeatTime
        incrementAmnt
        departmentID
        protocolID
        medicationID
        cprAssistID
        activeID
        overrideID
        status
        version
        createdBy
        modifiedBy
        createdAt
        Protocol {
          id
          name
          pdfID
          categoryID
          pairedProtocols
          medicationIDs
          equipmentIDs
          electricalIDs
          dripIDs
          formIDs
          index
          nickname
          pairedDepIDs
          rangeLow
          rangeHigh
          ageLow
          ageHigh
          ageGroup
          status
          version
          pdfVersion
          activeID
          overrideID
          privatePin
          keychainID
          createdBy
          modifiedBy
          isPublic
          isRestrictive
          color
          tags
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Medication {
          id
          name
          rxNormCode
          contraindication
          note
          warning
          route
          medClass
          action
          indication
          interaction
          onset
          duration
          departmentID
          rangeLow
          rangeHigh
          maxDose
          minDose
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        CPRAssist {
          id
          protocolIDs
          epiIDs
          defibIDs
          equipmentID
          departmentID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCPRAssistMutationVariables,
  APITypes.UpdateCPRAssistMutation
>;
export const deleteCPRAssist = /* GraphQL */ `mutation DeleteCPRAssist(
  $input: DeleteCPRAssistInput!
  $condition: ModelCPRAssistConditionInput
) {
  deleteCPRAssist(input: $input, condition: $condition) {
    id
    protocolIDs
    epiIDs
    defibIDs
    equipmentID
    departmentID
    activeID
    overrideID
    status
    version
    createdBy
    modifiedBy
    cprMetaData {
      defaultMode
      epiOrangeSec
      epiRedSec
      compressionRate
      ventRatePerMin
      ventilateSoundDelay
      ventilateLoops
      continousVentilateLoops
      ventilateSoundSpeed
      pulseCheckTime
      chargingTime
      __typename
    }
    DefibDoses {
      items {
        id
        basis
        rangeLow
        rangeHigh
        title
        warning
        instruction
        note
        maxDose
        minDose
        maxTotalDose
        calcMax
        calcMin
        index
        ageLow
        ageHigh
        ageGroup
        repeatTime
        incrementAmnt
        departmentID
        protocolID
        electricalID
        cprAssistID
        activeID
        overrideID
        status
        version
        createdBy
        modifiedBy
        createdAt
        Protocol {
          id
          name
          pdfID
          categoryID
          pairedProtocols
          medicationIDs
          equipmentIDs
          electricalIDs
          dripIDs
          formIDs
          index
          nickname
          pairedDepIDs
          rangeLow
          rangeHigh
          ageLow
          ageHigh
          ageGroup
          status
          version
          pdfVersion
          activeID
          overrideID
          privatePin
          keychainID
          createdBy
          modifiedBy
          isPublic
          isRestrictive
          color
          tags
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Electrical {
          id
          title
          rxNormCode
          warning
          instruction
          note
          rangeHigh
          rangeLow
          departmentID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        CPRAssist {
          id
          protocolIDs
          epiIDs
          defibIDs
          equipmentID
          departmentID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    EpiDoses {
      items {
        id
        basis
        rangeLow
        rangeHigh
        route
        nemsisRoutes {
          route
          code
          __typename
        }
        title
        warning
        instruction
        note
        maxDose
        minDose
        maxTotalDose
        calcMax
        calcMin
        index
        ageLow
        ageHigh
        ageGroup
        repeatTime
        incrementAmnt
        departmentID
        protocolID
        medicationID
        cprAssistID
        activeID
        overrideID
        status
        version
        createdBy
        modifiedBy
        createdAt
        Protocol {
          id
          name
          pdfID
          categoryID
          pairedProtocols
          medicationIDs
          equipmentIDs
          electricalIDs
          dripIDs
          formIDs
          index
          nickname
          pairedDepIDs
          rangeLow
          rangeHigh
          ageLow
          ageHigh
          ageGroup
          status
          version
          pdfVersion
          activeID
          overrideID
          privatePin
          keychainID
          createdBy
          modifiedBy
          isPublic
          isRestrictive
          color
          tags
          departmentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Medication {
          id
          name
          rxNormCode
          contraindication
          note
          warning
          route
          medClass
          action
          indication
          interaction
          onset
          duration
          departmentID
          rangeLow
          rangeHigh
          maxDose
          minDose
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        CPRAssist {
          id
          protocolIDs
          epiIDs
          defibIDs
          equipmentID
          departmentID
          activeID
          overrideID
          status
          version
          createdBy
          modifiedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCPRAssistMutationVariables,
  APITypes.DeleteCPRAssistMutation
>;
export const createReviewal = /* GraphQL */ `mutation CreateReviewal(
  $input: CreateReviewalInput!
  $condition: ModelReviewalConditionInput
) {
  createReviewal(input: $input, condition: $condition) {
    id
    title
    description
    reviewers
    status
    events {
      color
      title
      description
      timestamp
      icon
      __typename
    }
    createdAt
    endedAt
    departmentID
    userID
    User {
      id
      firstName
      lastName
      type
      cognitoID
      indexedParentDepID
      departmentID
      depAdmins
      logs {
        items {
          id
          userId
          logId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      hashedPin
      saltPin
      pairedDepIDs
      oneDoseVersion
      lastActivity
      notificationTokens
      deviceIDs
      maxDevices
      allowAnalytics
      status
      FormLogs {
        items {
          id
          inputformID
          departmentID
          userID
          pairedDepID
          pairedUserIDs
          notes
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Reviewals {
        items {
          id
          title
          description
          reviewers
          status
          createdAt
          endedAt
          departmentID
          userID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Acknowledges {
        items {
          id
          reviewalID
          ownerID
          userID
          status
          isArchived
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      UserComments {
        items {
          id
          ownerID
          userID
          acknowledgeID
          message
          relatedItems
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      NotifyACKs {
        items {
          id
          ownerID
          userID
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    DraftGroups {
      items {
        id
        reviewalID
        departmentID
        ownerID
        title
        description
        createdAt
        Reviewal {
          id
          title
          description
          reviewers
          status
          createdAt
          endedAt
          departmentID
          userID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        DraftChanges {
          nextToken
          startedAt
          __typename
        }
        isClosed
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Acknowledges {
      items {
        id
        reviewalID
        ownerID
        userID
        status
        isArchived
        createdAt
        User {
          id
          firstName
          lastName
          type
          cognitoID
          indexedParentDepID
          departmentID
          depAdmins
          hashedPin
          saltPin
          pairedDepIDs
          oneDoseVersion
          lastActivity
          notificationTokens
          deviceIDs
          maxDevices
          allowAnalytics
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Comment {
          id
          ownerID
          userID
          acknowledgeID
          message
          relatedItems
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    UserComments {
      items {
        id
        ownerID
        userID
        acknowledgeID
        message
        relatedItems
        createdAt
        User {
          id
          firstName
          lastName
          type
          cognitoID
          indexedParentDepID
          departmentID
          depAdmins
          hashedPin
          saltPin
          pairedDepIDs
          oneDoseVersion
          lastActivity
          notificationTokens
          deviceIDs
          maxDevices
          allowAnalytics
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Acknowledge {
          id
          reviewalID
          ownerID
          userID
          status
          isArchived
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateReviewalMutationVariables,
  APITypes.CreateReviewalMutation
>;
export const updateReviewal = /* GraphQL */ `mutation UpdateReviewal(
  $input: UpdateReviewalInput!
  $condition: ModelReviewalConditionInput
) {
  updateReviewal(input: $input, condition: $condition) {
    id
    title
    description
    reviewers
    status
    events {
      color
      title
      description
      timestamp
      icon
      __typename
    }
    createdAt
    endedAt
    departmentID
    userID
    User {
      id
      firstName
      lastName
      type
      cognitoID
      indexedParentDepID
      departmentID
      depAdmins
      logs {
        items {
          id
          userId
          logId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      hashedPin
      saltPin
      pairedDepIDs
      oneDoseVersion
      lastActivity
      notificationTokens
      deviceIDs
      maxDevices
      allowAnalytics
      status
      FormLogs {
        items {
          id
          inputformID
          departmentID
          userID
          pairedDepID
          pairedUserIDs
          notes
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Reviewals {
        items {
          id
          title
          description
          reviewers
          status
          createdAt
          endedAt
          departmentID
          userID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Acknowledges {
        items {
          id
          reviewalID
          ownerID
          userID
          status
          isArchived
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      UserComments {
        items {
          id
          ownerID
          userID
          acknowledgeID
          message
          relatedItems
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      NotifyACKs {
        items {
          id
          ownerID
          userID
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    DraftGroups {
      items {
        id
        reviewalID
        departmentID
        ownerID
        title
        description
        createdAt
        Reviewal {
          id
          title
          description
          reviewers
          status
          createdAt
          endedAt
          departmentID
          userID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        DraftChanges {
          nextToken
          startedAt
          __typename
        }
        isClosed
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Acknowledges {
      items {
        id
        reviewalID
        ownerID
        userID
        status
        isArchived
        createdAt
        User {
          id
          firstName
          lastName
          type
          cognitoID
          indexedParentDepID
          departmentID
          depAdmins
          hashedPin
          saltPin
          pairedDepIDs
          oneDoseVersion
          lastActivity
          notificationTokens
          deviceIDs
          maxDevices
          allowAnalytics
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Comment {
          id
          ownerID
          userID
          acknowledgeID
          message
          relatedItems
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    UserComments {
      items {
        id
        ownerID
        userID
        acknowledgeID
        message
        relatedItems
        createdAt
        User {
          id
          firstName
          lastName
          type
          cognitoID
          indexedParentDepID
          departmentID
          depAdmins
          hashedPin
          saltPin
          pairedDepIDs
          oneDoseVersion
          lastActivity
          notificationTokens
          deviceIDs
          maxDevices
          allowAnalytics
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Acknowledge {
          id
          reviewalID
          ownerID
          userID
          status
          isArchived
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateReviewalMutationVariables,
  APITypes.UpdateReviewalMutation
>;
export const deleteReviewal = /* GraphQL */ `mutation DeleteReviewal(
  $input: DeleteReviewalInput!
  $condition: ModelReviewalConditionInput
) {
  deleteReviewal(input: $input, condition: $condition) {
    id
    title
    description
    reviewers
    status
    events {
      color
      title
      description
      timestamp
      icon
      __typename
    }
    createdAt
    endedAt
    departmentID
    userID
    User {
      id
      firstName
      lastName
      type
      cognitoID
      indexedParentDepID
      departmentID
      depAdmins
      logs {
        items {
          id
          userId
          logId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      hashedPin
      saltPin
      pairedDepIDs
      oneDoseVersion
      lastActivity
      notificationTokens
      deviceIDs
      maxDevices
      allowAnalytics
      status
      FormLogs {
        items {
          id
          inputformID
          departmentID
          userID
          pairedDepID
          pairedUserIDs
          notes
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Reviewals {
        items {
          id
          title
          description
          reviewers
          status
          createdAt
          endedAt
          departmentID
          userID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Acknowledges {
        items {
          id
          reviewalID
          ownerID
          userID
          status
          isArchived
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      UserComments {
        items {
          id
          ownerID
          userID
          acknowledgeID
          message
          relatedItems
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      NotifyACKs {
        items {
          id
          ownerID
          userID
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    DraftGroups {
      items {
        id
        reviewalID
        departmentID
        ownerID
        title
        description
        createdAt
        Reviewal {
          id
          title
          description
          reviewers
          status
          createdAt
          endedAt
          departmentID
          userID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        DraftChanges {
          nextToken
          startedAt
          __typename
        }
        isClosed
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Acknowledges {
      items {
        id
        reviewalID
        ownerID
        userID
        status
        isArchived
        createdAt
        User {
          id
          firstName
          lastName
          type
          cognitoID
          indexedParentDepID
          departmentID
          depAdmins
          hashedPin
          saltPin
          pairedDepIDs
          oneDoseVersion
          lastActivity
          notificationTokens
          deviceIDs
          maxDevices
          allowAnalytics
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Comment {
          id
          ownerID
          userID
          acknowledgeID
          message
          relatedItems
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    UserComments {
      items {
        id
        ownerID
        userID
        acknowledgeID
        message
        relatedItems
        createdAt
        User {
          id
          firstName
          lastName
          type
          cognitoID
          indexedParentDepID
          departmentID
          depAdmins
          hashedPin
          saltPin
          pairedDepIDs
          oneDoseVersion
          lastActivity
          notificationTokens
          deviceIDs
          maxDevices
          allowAnalytics
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Acknowledge {
          id
          reviewalID
          ownerID
          userID
          status
          isArchived
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteReviewalMutationVariables,
  APITypes.DeleteReviewalMutation
>;
export const createDraftGroup = /* GraphQL */ `mutation CreateDraftGroup(
  $input: CreateDraftGroupInput!
  $condition: ModelDraftGroupConditionInput
) {
  createDraftGroup(input: $input, condition: $condition) {
    id
    reviewalID
    departmentID
    ownerID
    title
    description
    createdAt
    Reviewal {
      id
      title
      description
      reviewers
      status
      events {
        color
        title
        description
        timestamp
        icon
        __typename
      }
      createdAt
      endedAt
      departmentID
      userID
      User {
        id
        firstName
        lastName
        type
        cognitoID
        indexedParentDepID
        departmentID
        depAdmins
        logs {
          nextToken
          startedAt
          __typename
        }
        hashedPin
        saltPin
        pairedDepIDs
        oneDoseVersion
        lastActivity
        notificationTokens
        deviceIDs
        maxDevices
        allowAnalytics
        status
        FormLogs {
          nextToken
          startedAt
          __typename
        }
        Reviewals {
          nextToken
          startedAt
          __typename
        }
        Acknowledges {
          nextToken
          startedAt
          __typename
        }
        UserComments {
          nextToken
          startedAt
          __typename
        }
        NotifyACKs {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      DraftGroups {
        items {
          id
          reviewalID
          departmentID
          ownerID
          title
          description
          createdAt
          isClosed
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Acknowledges {
        items {
          id
          reviewalID
          ownerID
          userID
          status
          isArchived
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      UserComments {
        items {
          id
          ownerID
          userID
          acknowledgeID
          message
          relatedItems
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    DraftChanges {
      items {
        id
        draftGroupID
        departmentID
        changeID
        previousID
        changeType
        isClosed
        createdAt
        DraftGroup {
          id
          reviewalID
          departmentID
          ownerID
          title
          description
          createdAt
          isClosed
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Comments {
          nextToken
          startedAt
          __typename
        }
        Acknowledges {
          nextToken
          startedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    isClosed
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateDraftGroupMutationVariables,
  APITypes.CreateDraftGroupMutation
>;
export const updateDraftGroup = /* GraphQL */ `mutation UpdateDraftGroup(
  $input: UpdateDraftGroupInput!
  $condition: ModelDraftGroupConditionInput
) {
  updateDraftGroup(input: $input, condition: $condition) {
    id
    reviewalID
    departmentID
    ownerID
    title
    description
    createdAt
    Reviewal {
      id
      title
      description
      reviewers
      status
      events {
        color
        title
        description
        timestamp
        icon
        __typename
      }
      createdAt
      endedAt
      departmentID
      userID
      User {
        id
        firstName
        lastName
        type
        cognitoID
        indexedParentDepID
        departmentID
        depAdmins
        logs {
          nextToken
          startedAt
          __typename
        }
        hashedPin
        saltPin
        pairedDepIDs
        oneDoseVersion
        lastActivity
        notificationTokens
        deviceIDs
        maxDevices
        allowAnalytics
        status
        FormLogs {
          nextToken
          startedAt
          __typename
        }
        Reviewals {
          nextToken
          startedAt
          __typename
        }
        Acknowledges {
          nextToken
          startedAt
          __typename
        }
        UserComments {
          nextToken
          startedAt
          __typename
        }
        NotifyACKs {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      DraftGroups {
        items {
          id
          reviewalID
          departmentID
          ownerID
          title
          description
          createdAt
          isClosed
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Acknowledges {
        items {
          id
          reviewalID
          ownerID
          userID
          status
          isArchived
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      UserComments {
        items {
          id
          ownerID
          userID
          acknowledgeID
          message
          relatedItems
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    DraftChanges {
      items {
        id
        draftGroupID
        departmentID
        changeID
        previousID
        changeType
        isClosed
        createdAt
        DraftGroup {
          id
          reviewalID
          departmentID
          ownerID
          title
          description
          createdAt
          isClosed
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Comments {
          nextToken
          startedAt
          __typename
        }
        Acknowledges {
          nextToken
          startedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    isClosed
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateDraftGroupMutationVariables,
  APITypes.UpdateDraftGroupMutation
>;
export const deleteDraftGroup = /* GraphQL */ `mutation DeleteDraftGroup(
  $input: DeleteDraftGroupInput!
  $condition: ModelDraftGroupConditionInput
) {
  deleteDraftGroup(input: $input, condition: $condition) {
    id
    reviewalID
    departmentID
    ownerID
    title
    description
    createdAt
    Reviewal {
      id
      title
      description
      reviewers
      status
      events {
        color
        title
        description
        timestamp
        icon
        __typename
      }
      createdAt
      endedAt
      departmentID
      userID
      User {
        id
        firstName
        lastName
        type
        cognitoID
        indexedParentDepID
        departmentID
        depAdmins
        logs {
          nextToken
          startedAt
          __typename
        }
        hashedPin
        saltPin
        pairedDepIDs
        oneDoseVersion
        lastActivity
        notificationTokens
        deviceIDs
        maxDevices
        allowAnalytics
        status
        FormLogs {
          nextToken
          startedAt
          __typename
        }
        Reviewals {
          nextToken
          startedAt
          __typename
        }
        Acknowledges {
          nextToken
          startedAt
          __typename
        }
        UserComments {
          nextToken
          startedAt
          __typename
        }
        NotifyACKs {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      DraftGroups {
        items {
          id
          reviewalID
          departmentID
          ownerID
          title
          description
          createdAt
          isClosed
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Acknowledges {
        items {
          id
          reviewalID
          ownerID
          userID
          status
          isArchived
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      UserComments {
        items {
          id
          ownerID
          userID
          acknowledgeID
          message
          relatedItems
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    DraftChanges {
      items {
        id
        draftGroupID
        departmentID
        changeID
        previousID
        changeType
        isClosed
        createdAt
        DraftGroup {
          id
          reviewalID
          departmentID
          ownerID
          title
          description
          createdAt
          isClosed
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Comments {
          nextToken
          startedAt
          __typename
        }
        Acknowledges {
          nextToken
          startedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    isClosed
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteDraftGroupMutationVariables,
  APITypes.DeleteDraftGroupMutation
>;
export const createDraftChange = /* GraphQL */ `mutation CreateDraftChange(
  $input: CreateDraftChangeInput!
  $condition: ModelDraftChangeConditionInput
) {
  createDraftChange(input: $input, condition: $condition) {
    id
    draftGroupID
    departmentID
    changeID
    previousID
    changeType
    isClosed
    createdAt
    DraftGroup {
      id
      reviewalID
      departmentID
      ownerID
      title
      description
      createdAt
      Reviewal {
        id
        title
        description
        reviewers
        status
        events {
          color
          title
          description
          timestamp
          icon
          __typename
        }
        createdAt
        endedAt
        departmentID
        userID
        User {
          id
          firstName
          lastName
          type
          cognitoID
          indexedParentDepID
          departmentID
          depAdmins
          hashedPin
          saltPin
          pairedDepIDs
          oneDoseVersion
          lastActivity
          notificationTokens
          deviceIDs
          maxDevices
          allowAnalytics
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        DraftGroups {
          nextToken
          startedAt
          __typename
        }
        Acknowledges {
          nextToken
          startedAt
          __typename
        }
        UserComments {
          nextToken
          startedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      DraftChanges {
        items {
          id
          draftGroupID
          departmentID
          changeID
          previousID
          changeType
          isClosed
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      isClosed
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    Comments {
      items {
        id
        ownerID
        userID
        acknowledgeID
        message
        relatedItems
        createdAt
        User {
          id
          firstName
          lastName
          type
          cognitoID
          indexedParentDepID
          departmentID
          depAdmins
          hashedPin
          saltPin
          pairedDepIDs
          oneDoseVersion
          lastActivity
          notificationTokens
          deviceIDs
          maxDevices
          allowAnalytics
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Acknowledge {
          id
          reviewalID
          ownerID
          userID
          status
          isArchived
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Acknowledges {
      items {
        id
        reviewalID
        ownerID
        userID
        status
        isArchived
        createdAt
        User {
          id
          firstName
          lastName
          type
          cognitoID
          indexedParentDepID
          departmentID
          depAdmins
          hashedPin
          saltPin
          pairedDepIDs
          oneDoseVersion
          lastActivity
          notificationTokens
          deviceIDs
          maxDevices
          allowAnalytics
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Comment {
          id
          ownerID
          userID
          acknowledgeID
          message
          relatedItems
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateDraftChangeMutationVariables,
  APITypes.CreateDraftChangeMutation
>;
export const updateDraftChange = /* GraphQL */ `mutation UpdateDraftChange(
  $input: UpdateDraftChangeInput!
  $condition: ModelDraftChangeConditionInput
) {
  updateDraftChange(input: $input, condition: $condition) {
    id
    draftGroupID
    departmentID
    changeID
    previousID
    changeType
    isClosed
    createdAt
    DraftGroup {
      id
      reviewalID
      departmentID
      ownerID
      title
      description
      createdAt
      Reviewal {
        id
        title
        description
        reviewers
        status
        events {
          color
          title
          description
          timestamp
          icon
          __typename
        }
        createdAt
        endedAt
        departmentID
        userID
        User {
          id
          firstName
          lastName
          type
          cognitoID
          indexedParentDepID
          departmentID
          depAdmins
          hashedPin
          saltPin
          pairedDepIDs
          oneDoseVersion
          lastActivity
          notificationTokens
          deviceIDs
          maxDevices
          allowAnalytics
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        DraftGroups {
          nextToken
          startedAt
          __typename
        }
        Acknowledges {
          nextToken
          startedAt
          __typename
        }
        UserComments {
          nextToken
          startedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      DraftChanges {
        items {
          id
          draftGroupID
          departmentID
          changeID
          previousID
          changeType
          isClosed
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      isClosed
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    Comments {
      items {
        id
        ownerID
        userID
        acknowledgeID
        message
        relatedItems
        createdAt
        User {
          id
          firstName
          lastName
          type
          cognitoID
          indexedParentDepID
          departmentID
          depAdmins
          hashedPin
          saltPin
          pairedDepIDs
          oneDoseVersion
          lastActivity
          notificationTokens
          deviceIDs
          maxDevices
          allowAnalytics
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Acknowledge {
          id
          reviewalID
          ownerID
          userID
          status
          isArchived
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Acknowledges {
      items {
        id
        reviewalID
        ownerID
        userID
        status
        isArchived
        createdAt
        User {
          id
          firstName
          lastName
          type
          cognitoID
          indexedParentDepID
          departmentID
          depAdmins
          hashedPin
          saltPin
          pairedDepIDs
          oneDoseVersion
          lastActivity
          notificationTokens
          deviceIDs
          maxDevices
          allowAnalytics
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Comment {
          id
          ownerID
          userID
          acknowledgeID
          message
          relatedItems
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateDraftChangeMutationVariables,
  APITypes.UpdateDraftChangeMutation
>;
export const deleteDraftChange = /* GraphQL */ `mutation DeleteDraftChange(
  $input: DeleteDraftChangeInput!
  $condition: ModelDraftChangeConditionInput
) {
  deleteDraftChange(input: $input, condition: $condition) {
    id
    draftGroupID
    departmentID
    changeID
    previousID
    changeType
    isClosed
    createdAt
    DraftGroup {
      id
      reviewalID
      departmentID
      ownerID
      title
      description
      createdAt
      Reviewal {
        id
        title
        description
        reviewers
        status
        events {
          color
          title
          description
          timestamp
          icon
          __typename
        }
        createdAt
        endedAt
        departmentID
        userID
        User {
          id
          firstName
          lastName
          type
          cognitoID
          indexedParentDepID
          departmentID
          depAdmins
          hashedPin
          saltPin
          pairedDepIDs
          oneDoseVersion
          lastActivity
          notificationTokens
          deviceIDs
          maxDevices
          allowAnalytics
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        DraftGroups {
          nextToken
          startedAt
          __typename
        }
        Acknowledges {
          nextToken
          startedAt
          __typename
        }
        UserComments {
          nextToken
          startedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      DraftChanges {
        items {
          id
          draftGroupID
          departmentID
          changeID
          previousID
          changeType
          isClosed
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      isClosed
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    Comments {
      items {
        id
        ownerID
        userID
        acknowledgeID
        message
        relatedItems
        createdAt
        User {
          id
          firstName
          lastName
          type
          cognitoID
          indexedParentDepID
          departmentID
          depAdmins
          hashedPin
          saltPin
          pairedDepIDs
          oneDoseVersion
          lastActivity
          notificationTokens
          deviceIDs
          maxDevices
          allowAnalytics
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Acknowledge {
          id
          reviewalID
          ownerID
          userID
          status
          isArchived
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Acknowledges {
      items {
        id
        reviewalID
        ownerID
        userID
        status
        isArchived
        createdAt
        User {
          id
          firstName
          lastName
          type
          cognitoID
          indexedParentDepID
          departmentID
          depAdmins
          hashedPin
          saltPin
          pairedDepIDs
          oneDoseVersion
          lastActivity
          notificationTokens
          deviceIDs
          maxDevices
          allowAnalytics
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Comment {
          id
          ownerID
          userID
          acknowledgeID
          message
          relatedItems
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteDraftChangeMutationVariables,
  APITypes.DeleteDraftChangeMutation
>;
export const createAcknowledge = /* GraphQL */ `mutation CreateAcknowledge(
  $input: CreateAcknowledgeInput!
  $condition: ModelAcknowledgeConditionInput
) {
  createAcknowledge(input: $input, condition: $condition) {
    id
    reviewalID
    ownerID
    userID
    status
    isArchived
    createdAt
    User {
      id
      firstName
      lastName
      type
      cognitoID
      indexedParentDepID
      departmentID
      depAdmins
      logs {
        items {
          id
          userId
          logId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      hashedPin
      saltPin
      pairedDepIDs
      oneDoseVersion
      lastActivity
      notificationTokens
      deviceIDs
      maxDevices
      allowAnalytics
      status
      FormLogs {
        items {
          id
          inputformID
          departmentID
          userID
          pairedDepID
          pairedUserIDs
          notes
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Reviewals {
        items {
          id
          title
          description
          reviewers
          status
          createdAt
          endedAt
          departmentID
          userID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Acknowledges {
        items {
          id
          reviewalID
          ownerID
          userID
          status
          isArchived
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      UserComments {
        items {
          id
          ownerID
          userID
          acknowledgeID
          message
          relatedItems
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      NotifyACKs {
        items {
          id
          ownerID
          userID
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    Comment {
      id
      ownerID
      userID
      acknowledgeID
      message
      relatedItems
      createdAt
      User {
        id
        firstName
        lastName
        type
        cognitoID
        indexedParentDepID
        departmentID
        depAdmins
        logs {
          nextToken
          startedAt
          __typename
        }
        hashedPin
        saltPin
        pairedDepIDs
        oneDoseVersion
        lastActivity
        notificationTokens
        deviceIDs
        maxDevices
        allowAnalytics
        status
        FormLogs {
          nextToken
          startedAt
          __typename
        }
        Reviewals {
          nextToken
          startedAt
          __typename
        }
        Acknowledges {
          nextToken
          startedAt
          __typename
        }
        UserComments {
          nextToken
          startedAt
          __typename
        }
        NotifyACKs {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Acknowledge {
        id
        reviewalID
        ownerID
        userID
        status
        isArchived
        createdAt
        User {
          id
          firstName
          lastName
          type
          cognitoID
          indexedParentDepID
          departmentID
          depAdmins
          hashedPin
          saltPin
          pairedDepIDs
          oneDoseVersion
          lastActivity
          notificationTokens
          deviceIDs
          maxDevices
          allowAnalytics
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Comment {
          id
          ownerID
          userID
          acknowledgeID
          message
          relatedItems
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAcknowledgeMutationVariables,
  APITypes.CreateAcknowledgeMutation
>;
export const updateAcknowledge = /* GraphQL */ `mutation UpdateAcknowledge(
  $input: UpdateAcknowledgeInput!
  $condition: ModelAcknowledgeConditionInput
) {
  updateAcknowledge(input: $input, condition: $condition) {
    id
    reviewalID
    ownerID
    userID
    status
    isArchived
    createdAt
    User {
      id
      firstName
      lastName
      type
      cognitoID
      indexedParentDepID
      departmentID
      depAdmins
      logs {
        items {
          id
          userId
          logId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      hashedPin
      saltPin
      pairedDepIDs
      oneDoseVersion
      lastActivity
      notificationTokens
      deviceIDs
      maxDevices
      allowAnalytics
      status
      FormLogs {
        items {
          id
          inputformID
          departmentID
          userID
          pairedDepID
          pairedUserIDs
          notes
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Reviewals {
        items {
          id
          title
          description
          reviewers
          status
          createdAt
          endedAt
          departmentID
          userID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Acknowledges {
        items {
          id
          reviewalID
          ownerID
          userID
          status
          isArchived
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      UserComments {
        items {
          id
          ownerID
          userID
          acknowledgeID
          message
          relatedItems
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      NotifyACKs {
        items {
          id
          ownerID
          userID
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    Comment {
      id
      ownerID
      userID
      acknowledgeID
      message
      relatedItems
      createdAt
      User {
        id
        firstName
        lastName
        type
        cognitoID
        indexedParentDepID
        departmentID
        depAdmins
        logs {
          nextToken
          startedAt
          __typename
        }
        hashedPin
        saltPin
        pairedDepIDs
        oneDoseVersion
        lastActivity
        notificationTokens
        deviceIDs
        maxDevices
        allowAnalytics
        status
        FormLogs {
          nextToken
          startedAt
          __typename
        }
        Reviewals {
          nextToken
          startedAt
          __typename
        }
        Acknowledges {
          nextToken
          startedAt
          __typename
        }
        UserComments {
          nextToken
          startedAt
          __typename
        }
        NotifyACKs {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Acknowledge {
        id
        reviewalID
        ownerID
        userID
        status
        isArchived
        createdAt
        User {
          id
          firstName
          lastName
          type
          cognitoID
          indexedParentDepID
          departmentID
          depAdmins
          hashedPin
          saltPin
          pairedDepIDs
          oneDoseVersion
          lastActivity
          notificationTokens
          deviceIDs
          maxDevices
          allowAnalytics
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Comment {
          id
          ownerID
          userID
          acknowledgeID
          message
          relatedItems
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAcknowledgeMutationVariables,
  APITypes.UpdateAcknowledgeMutation
>;
export const deleteAcknowledge = /* GraphQL */ `mutation DeleteAcknowledge(
  $input: DeleteAcknowledgeInput!
  $condition: ModelAcknowledgeConditionInput
) {
  deleteAcknowledge(input: $input, condition: $condition) {
    id
    reviewalID
    ownerID
    userID
    status
    isArchived
    createdAt
    User {
      id
      firstName
      lastName
      type
      cognitoID
      indexedParentDepID
      departmentID
      depAdmins
      logs {
        items {
          id
          userId
          logId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      hashedPin
      saltPin
      pairedDepIDs
      oneDoseVersion
      lastActivity
      notificationTokens
      deviceIDs
      maxDevices
      allowAnalytics
      status
      FormLogs {
        items {
          id
          inputformID
          departmentID
          userID
          pairedDepID
          pairedUserIDs
          notes
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Reviewals {
        items {
          id
          title
          description
          reviewers
          status
          createdAt
          endedAt
          departmentID
          userID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Acknowledges {
        items {
          id
          reviewalID
          ownerID
          userID
          status
          isArchived
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      UserComments {
        items {
          id
          ownerID
          userID
          acknowledgeID
          message
          relatedItems
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      NotifyACKs {
        items {
          id
          ownerID
          userID
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    Comment {
      id
      ownerID
      userID
      acknowledgeID
      message
      relatedItems
      createdAt
      User {
        id
        firstName
        lastName
        type
        cognitoID
        indexedParentDepID
        departmentID
        depAdmins
        logs {
          nextToken
          startedAt
          __typename
        }
        hashedPin
        saltPin
        pairedDepIDs
        oneDoseVersion
        lastActivity
        notificationTokens
        deviceIDs
        maxDevices
        allowAnalytics
        status
        FormLogs {
          nextToken
          startedAt
          __typename
        }
        Reviewals {
          nextToken
          startedAt
          __typename
        }
        Acknowledges {
          nextToken
          startedAt
          __typename
        }
        UserComments {
          nextToken
          startedAt
          __typename
        }
        NotifyACKs {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Acknowledge {
        id
        reviewalID
        ownerID
        userID
        status
        isArchived
        createdAt
        User {
          id
          firstName
          lastName
          type
          cognitoID
          indexedParentDepID
          departmentID
          depAdmins
          hashedPin
          saltPin
          pairedDepIDs
          oneDoseVersion
          lastActivity
          notificationTokens
          deviceIDs
          maxDevices
          allowAnalytics
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Comment {
          id
          ownerID
          userID
          acknowledgeID
          message
          relatedItems
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteAcknowledgeMutationVariables,
  APITypes.DeleteAcknowledgeMutation
>;
export const createUserComment = /* GraphQL */ `mutation CreateUserComment(
  $input: CreateUserCommentInput!
  $condition: ModelUserCommentConditionInput
) {
  createUserComment(input: $input, condition: $condition) {
    id
    ownerID
    userID
    acknowledgeID
    message
    relatedItems
    createdAt
    User {
      id
      firstName
      lastName
      type
      cognitoID
      indexedParentDepID
      departmentID
      depAdmins
      logs {
        items {
          id
          userId
          logId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      hashedPin
      saltPin
      pairedDepIDs
      oneDoseVersion
      lastActivity
      notificationTokens
      deviceIDs
      maxDevices
      allowAnalytics
      status
      FormLogs {
        items {
          id
          inputformID
          departmentID
          userID
          pairedDepID
          pairedUserIDs
          notes
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Reviewals {
        items {
          id
          title
          description
          reviewers
          status
          createdAt
          endedAt
          departmentID
          userID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Acknowledges {
        items {
          id
          reviewalID
          ownerID
          userID
          status
          isArchived
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      UserComments {
        items {
          id
          ownerID
          userID
          acknowledgeID
          message
          relatedItems
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      NotifyACKs {
        items {
          id
          ownerID
          userID
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    Acknowledge {
      id
      reviewalID
      ownerID
      userID
      status
      isArchived
      createdAt
      User {
        id
        firstName
        lastName
        type
        cognitoID
        indexedParentDepID
        departmentID
        depAdmins
        logs {
          nextToken
          startedAt
          __typename
        }
        hashedPin
        saltPin
        pairedDepIDs
        oneDoseVersion
        lastActivity
        notificationTokens
        deviceIDs
        maxDevices
        allowAnalytics
        status
        FormLogs {
          nextToken
          startedAt
          __typename
        }
        Reviewals {
          nextToken
          startedAt
          __typename
        }
        Acknowledges {
          nextToken
          startedAt
          __typename
        }
        UserComments {
          nextToken
          startedAt
          __typename
        }
        NotifyACKs {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Comment {
        id
        ownerID
        userID
        acknowledgeID
        message
        relatedItems
        createdAt
        User {
          id
          firstName
          lastName
          type
          cognitoID
          indexedParentDepID
          departmentID
          depAdmins
          hashedPin
          saltPin
          pairedDepIDs
          oneDoseVersion
          lastActivity
          notificationTokens
          deviceIDs
          maxDevices
          allowAnalytics
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Acknowledge {
          id
          reviewalID
          ownerID
          userID
          status
          isArchived
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserCommentMutationVariables,
  APITypes.CreateUserCommentMutation
>;
export const updateUserComment = /* GraphQL */ `mutation UpdateUserComment(
  $input: UpdateUserCommentInput!
  $condition: ModelUserCommentConditionInput
) {
  updateUserComment(input: $input, condition: $condition) {
    id
    ownerID
    userID
    acknowledgeID
    message
    relatedItems
    createdAt
    User {
      id
      firstName
      lastName
      type
      cognitoID
      indexedParentDepID
      departmentID
      depAdmins
      logs {
        items {
          id
          userId
          logId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      hashedPin
      saltPin
      pairedDepIDs
      oneDoseVersion
      lastActivity
      notificationTokens
      deviceIDs
      maxDevices
      allowAnalytics
      status
      FormLogs {
        items {
          id
          inputformID
          departmentID
          userID
          pairedDepID
          pairedUserIDs
          notes
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Reviewals {
        items {
          id
          title
          description
          reviewers
          status
          createdAt
          endedAt
          departmentID
          userID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Acknowledges {
        items {
          id
          reviewalID
          ownerID
          userID
          status
          isArchived
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      UserComments {
        items {
          id
          ownerID
          userID
          acknowledgeID
          message
          relatedItems
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      NotifyACKs {
        items {
          id
          ownerID
          userID
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    Acknowledge {
      id
      reviewalID
      ownerID
      userID
      status
      isArchived
      createdAt
      User {
        id
        firstName
        lastName
        type
        cognitoID
        indexedParentDepID
        departmentID
        depAdmins
        logs {
          nextToken
          startedAt
          __typename
        }
        hashedPin
        saltPin
        pairedDepIDs
        oneDoseVersion
        lastActivity
        notificationTokens
        deviceIDs
        maxDevices
        allowAnalytics
        status
        FormLogs {
          nextToken
          startedAt
          __typename
        }
        Reviewals {
          nextToken
          startedAt
          __typename
        }
        Acknowledges {
          nextToken
          startedAt
          __typename
        }
        UserComments {
          nextToken
          startedAt
          __typename
        }
        NotifyACKs {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Comment {
        id
        ownerID
        userID
        acknowledgeID
        message
        relatedItems
        createdAt
        User {
          id
          firstName
          lastName
          type
          cognitoID
          indexedParentDepID
          departmentID
          depAdmins
          hashedPin
          saltPin
          pairedDepIDs
          oneDoseVersion
          lastActivity
          notificationTokens
          deviceIDs
          maxDevices
          allowAnalytics
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Acknowledge {
          id
          reviewalID
          ownerID
          userID
          status
          isArchived
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserCommentMutationVariables,
  APITypes.UpdateUserCommentMutation
>;
export const deleteUserComment = /* GraphQL */ `mutation DeleteUserComment(
  $input: DeleteUserCommentInput!
  $condition: ModelUserCommentConditionInput
) {
  deleteUserComment(input: $input, condition: $condition) {
    id
    ownerID
    userID
    acknowledgeID
    message
    relatedItems
    createdAt
    User {
      id
      firstName
      lastName
      type
      cognitoID
      indexedParentDepID
      departmentID
      depAdmins
      logs {
        items {
          id
          userId
          logId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      hashedPin
      saltPin
      pairedDepIDs
      oneDoseVersion
      lastActivity
      notificationTokens
      deviceIDs
      maxDevices
      allowAnalytics
      status
      FormLogs {
        items {
          id
          inputformID
          departmentID
          userID
          pairedDepID
          pairedUserIDs
          notes
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Reviewals {
        items {
          id
          title
          description
          reviewers
          status
          createdAt
          endedAt
          departmentID
          userID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Acknowledges {
        items {
          id
          reviewalID
          ownerID
          userID
          status
          isArchived
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      UserComments {
        items {
          id
          ownerID
          userID
          acknowledgeID
          message
          relatedItems
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      NotifyACKs {
        items {
          id
          ownerID
          userID
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    Acknowledge {
      id
      reviewalID
      ownerID
      userID
      status
      isArchived
      createdAt
      User {
        id
        firstName
        lastName
        type
        cognitoID
        indexedParentDepID
        departmentID
        depAdmins
        logs {
          nextToken
          startedAt
          __typename
        }
        hashedPin
        saltPin
        pairedDepIDs
        oneDoseVersion
        lastActivity
        notificationTokens
        deviceIDs
        maxDevices
        allowAnalytics
        status
        FormLogs {
          nextToken
          startedAt
          __typename
        }
        Reviewals {
          nextToken
          startedAt
          __typename
        }
        Acknowledges {
          nextToken
          startedAt
          __typename
        }
        UserComments {
          nextToken
          startedAt
          __typename
        }
        NotifyACKs {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Comment {
        id
        ownerID
        userID
        acknowledgeID
        message
        relatedItems
        createdAt
        User {
          id
          firstName
          lastName
          type
          cognitoID
          indexedParentDepID
          departmentID
          depAdmins
          hashedPin
          saltPin
          pairedDepIDs
          oneDoseVersion
          lastActivity
          notificationTokens
          deviceIDs
          maxDevices
          allowAnalytics
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Acknowledge {
          id
          reviewalID
          ownerID
          userID
          status
          isArchived
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserCommentMutationVariables,
  APITypes.DeleteUserCommentMutation
>;
export const createGroupNotification = /* GraphQL */ `mutation CreateGroupNotification(
  $input: CreateGroupNotificationInput!
  $condition: ModelGroupNotificationConditionInput
) {
  createGroupNotification(input: $input, condition: $condition) {
    id
    notificationId
    groupId
    notification {
      id
      type
      title
      message
      timestamp
      expirationTimestamp
      deadlineTimestamp
      isReadIDs
      isAckIDs
      imageURLs
      videoURLs
      fileURLs
      taggedProtocols
      questions {
        question
        options
        answer
        taggedProtocol
        __typename
      }
      formQuestions {
        index
        type
        question
        description
        answers
        correctAnswer
        __typename
      }
      isPush
      isPinRequired
      departmentID
      pairedDepIDs
      Groups {
        items {
          id
          notificationId
          groupId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdBy
      modifiedBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    group {
      id
      name
      type
      departmentID
      pairedDepIDs
      pairedUserIDs
      isAutoGen
      Department {
        id
        Categories {
          nextToken
          startedAt
          __typename
        }
        OneWeights {
          nextToken
          startedAt
          __typename
        }
        name
        location
        protocolVersions
        shiftTypes
        Medications {
          nextToken
          startedAt
          __typename
        }
        Equipment {
          nextToken
          startedAt
          __typename
        }
        Forms {
          nextToken
          startedAt
          __typename
        }
        Vitals {
          nextToken
          startedAt
          __typename
        }
        Electricals {
          nextToken
          startedAt
          __typename
        }
        ElectricalShocks {
          nextToken
          startedAt
          __typename
        }
        cognitoID
        Users {
          nextToken
          startedAt
          __typename
        }
        Logs {
          nextToken
          startedAt
          __typename
        }
        Ambulances {
          nextToken
          startedAt
          __typename
        }
        Drips {
          nextToken
          startedAt
          __typename
        }
        RequireSync {
          id
          sync
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        logoID
        userID
        neonateCutoff
        pediatricCutoff
        calculators
        adultRanges {
          label
          value
          __typename
        }
        softwarePlan
        Notifications {
          nextToken
          startedAt
          __typename
        }
        Contacts {
          nextToken
          startedAt
          __typename
        }
        InputForms {
          nextToken
          startedAt
          __typename
        }
        FormLogs {
          nextToken
          startedAt
          __typename
        }
        Workbooks {
          nextToken
          startedAt
          __typename
        }
        WeightObjects {
          nextToken
          startedAt
          __typename
        }
        Keychains {
          nextToken
          startedAt
          __typename
        }
        Reviewals {
          nextToken
          startedAt
          __typename
        }
        Groups {
          nextToken
          startedAt
          __typename
        }
        CPRAssists {
          nextToken
          startedAt
          __typename
        }
        subDepIDs
        parentDepID
        indexedParentDepID
        activeStatus
        agencyNumEMS
        stateIdEMS
        gnisCodeEMS
        uniqueCode
        uniquePublicURL
        hashedPin
        saltedPin
        keychainID
        isPublic
        infusionCalculation
        isPublicSignup
        config {
          isTopEnabled
          neonateCutoff
          pediatricCutoff
          calculators
          softwarePlan
          infusionCalculation
          isPublic
          realTimeUpdating
          epcrProvider
          oneweightEnabled
          ageFilterEnabled
          ageGroupFilterEnabled
          renewalDate
          infusionGTTS
          signedAgreement
          isToggleAdvancedFiltersEnabled
          premiumTrialExpiration
          estimationMethod
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        departmentRequireSyncId
        __typename
      }
      Notifications {
        items {
          id
          notificationId
          groupId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateGroupNotificationMutationVariables,
  APITypes.CreateGroupNotificationMutation
>;
export const updateGroupNotification = /* GraphQL */ `mutation UpdateGroupNotification(
  $input: UpdateGroupNotificationInput!
  $condition: ModelGroupNotificationConditionInput
) {
  updateGroupNotification(input: $input, condition: $condition) {
    id
    notificationId
    groupId
    notification {
      id
      type
      title
      message
      timestamp
      expirationTimestamp
      deadlineTimestamp
      isReadIDs
      isAckIDs
      imageURLs
      videoURLs
      fileURLs
      taggedProtocols
      questions {
        question
        options
        answer
        taggedProtocol
        __typename
      }
      formQuestions {
        index
        type
        question
        description
        answers
        correctAnswer
        __typename
      }
      isPush
      isPinRequired
      departmentID
      pairedDepIDs
      Groups {
        items {
          id
          notificationId
          groupId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdBy
      modifiedBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    group {
      id
      name
      type
      departmentID
      pairedDepIDs
      pairedUserIDs
      isAutoGen
      Department {
        id
        Categories {
          nextToken
          startedAt
          __typename
        }
        OneWeights {
          nextToken
          startedAt
          __typename
        }
        name
        location
        protocolVersions
        shiftTypes
        Medications {
          nextToken
          startedAt
          __typename
        }
        Equipment {
          nextToken
          startedAt
          __typename
        }
        Forms {
          nextToken
          startedAt
          __typename
        }
        Vitals {
          nextToken
          startedAt
          __typename
        }
        Electricals {
          nextToken
          startedAt
          __typename
        }
        ElectricalShocks {
          nextToken
          startedAt
          __typename
        }
        cognitoID
        Users {
          nextToken
          startedAt
          __typename
        }
        Logs {
          nextToken
          startedAt
          __typename
        }
        Ambulances {
          nextToken
          startedAt
          __typename
        }
        Drips {
          nextToken
          startedAt
          __typename
        }
        RequireSync {
          id
          sync
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        logoID
        userID
        neonateCutoff
        pediatricCutoff
        calculators
        adultRanges {
          label
          value
          __typename
        }
        softwarePlan
        Notifications {
          nextToken
          startedAt
          __typename
        }
        Contacts {
          nextToken
          startedAt
          __typename
        }
        InputForms {
          nextToken
          startedAt
          __typename
        }
        FormLogs {
          nextToken
          startedAt
          __typename
        }
        Workbooks {
          nextToken
          startedAt
          __typename
        }
        WeightObjects {
          nextToken
          startedAt
          __typename
        }
        Keychains {
          nextToken
          startedAt
          __typename
        }
        Reviewals {
          nextToken
          startedAt
          __typename
        }
        Groups {
          nextToken
          startedAt
          __typename
        }
        CPRAssists {
          nextToken
          startedAt
          __typename
        }
        subDepIDs
        parentDepID
        indexedParentDepID
        activeStatus
        agencyNumEMS
        stateIdEMS
        gnisCodeEMS
        uniqueCode
        uniquePublicURL
        hashedPin
        saltedPin
        keychainID
        isPublic
        infusionCalculation
        isPublicSignup
        config {
          isTopEnabled
          neonateCutoff
          pediatricCutoff
          calculators
          softwarePlan
          infusionCalculation
          isPublic
          realTimeUpdating
          epcrProvider
          oneweightEnabled
          ageFilterEnabled
          ageGroupFilterEnabled
          renewalDate
          infusionGTTS
          signedAgreement
          isToggleAdvancedFiltersEnabled
          premiumTrialExpiration
          estimationMethod
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        departmentRequireSyncId
        __typename
      }
      Notifications {
        items {
          id
          notificationId
          groupId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateGroupNotificationMutationVariables,
  APITypes.UpdateGroupNotificationMutation
>;
export const deleteGroupNotification = /* GraphQL */ `mutation DeleteGroupNotification(
  $input: DeleteGroupNotificationInput!
  $condition: ModelGroupNotificationConditionInput
) {
  deleteGroupNotification(input: $input, condition: $condition) {
    id
    notificationId
    groupId
    notification {
      id
      type
      title
      message
      timestamp
      expirationTimestamp
      deadlineTimestamp
      isReadIDs
      isAckIDs
      imageURLs
      videoURLs
      fileURLs
      taggedProtocols
      questions {
        question
        options
        answer
        taggedProtocol
        __typename
      }
      formQuestions {
        index
        type
        question
        description
        answers
        correctAnswer
        __typename
      }
      isPush
      isPinRequired
      departmentID
      pairedDepIDs
      Groups {
        items {
          id
          notificationId
          groupId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdBy
      modifiedBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    group {
      id
      name
      type
      departmentID
      pairedDepIDs
      pairedUserIDs
      isAutoGen
      Department {
        id
        Categories {
          nextToken
          startedAt
          __typename
        }
        OneWeights {
          nextToken
          startedAt
          __typename
        }
        name
        location
        protocolVersions
        shiftTypes
        Medications {
          nextToken
          startedAt
          __typename
        }
        Equipment {
          nextToken
          startedAt
          __typename
        }
        Forms {
          nextToken
          startedAt
          __typename
        }
        Vitals {
          nextToken
          startedAt
          __typename
        }
        Electricals {
          nextToken
          startedAt
          __typename
        }
        ElectricalShocks {
          nextToken
          startedAt
          __typename
        }
        cognitoID
        Users {
          nextToken
          startedAt
          __typename
        }
        Logs {
          nextToken
          startedAt
          __typename
        }
        Ambulances {
          nextToken
          startedAt
          __typename
        }
        Drips {
          nextToken
          startedAt
          __typename
        }
        RequireSync {
          id
          sync
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        logoID
        userID
        neonateCutoff
        pediatricCutoff
        calculators
        adultRanges {
          label
          value
          __typename
        }
        softwarePlan
        Notifications {
          nextToken
          startedAt
          __typename
        }
        Contacts {
          nextToken
          startedAt
          __typename
        }
        InputForms {
          nextToken
          startedAt
          __typename
        }
        FormLogs {
          nextToken
          startedAt
          __typename
        }
        Workbooks {
          nextToken
          startedAt
          __typename
        }
        WeightObjects {
          nextToken
          startedAt
          __typename
        }
        Keychains {
          nextToken
          startedAt
          __typename
        }
        Reviewals {
          nextToken
          startedAt
          __typename
        }
        Groups {
          nextToken
          startedAt
          __typename
        }
        CPRAssists {
          nextToken
          startedAt
          __typename
        }
        subDepIDs
        parentDepID
        indexedParentDepID
        activeStatus
        agencyNumEMS
        stateIdEMS
        gnisCodeEMS
        uniqueCode
        uniquePublicURL
        hashedPin
        saltedPin
        keychainID
        isPublic
        infusionCalculation
        isPublicSignup
        config {
          isTopEnabled
          neonateCutoff
          pediatricCutoff
          calculators
          softwarePlan
          infusionCalculation
          isPublic
          realTimeUpdating
          epcrProvider
          oneweightEnabled
          ageFilterEnabled
          ageGroupFilterEnabled
          renewalDate
          infusionGTTS
          signedAgreement
          isToggleAdvancedFiltersEnabled
          premiumTrialExpiration
          estimationMethod
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        departmentRequireSyncId
        __typename
      }
      Notifications {
        items {
          id
          notificationId
          groupId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteGroupNotificationMutationVariables,
  APITypes.DeleteGroupNotificationMutation
>;
export const createLogUser = /* GraphQL */ `mutation CreateLogUser(
  $input: CreateLogUserInput!
  $condition: ModelLogUserConditionInput
) {
  createLogUser(input: $input, condition: $condition) {
    id
    userId
    logId
    user {
      id
      firstName
      lastName
      type
      cognitoID
      indexedParentDepID
      departmentID
      depAdmins
      logs {
        items {
          id
          userId
          logId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      hashedPin
      saltPin
      pairedDepIDs
      oneDoseVersion
      lastActivity
      notificationTokens
      deviceIDs
      maxDevices
      allowAnalytics
      status
      FormLogs {
        items {
          id
          inputformID
          departmentID
          userID
          pairedDepID
          pairedUserIDs
          notes
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Reviewals {
        items {
          id
          title
          description
          reviewers
          status
          createdAt
          endedAt
          departmentID
          userID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Acknowledges {
        items {
          id
          reviewalID
          ownerID
          userID
          status
          isArchived
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      UserComments {
        items {
          id
          ownerID
          userID
          acknowledgeID
          message
          relatedItems
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      NotifyACKs {
        items {
          id
          ownerID
          userID
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    log {
      id
      actualWeight
      ageValue
      protocolID
      Users {
        items {
          id
          userId
          logId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      departmentID
      oneweightID
      ambulanceID
      incidentNumber
      startTime
      endTime
      estimatedWeight
      medications {
        medicationID
        amntSolid
        amntLiquid
        timestamp
        verified
        name
        rxNormCode
        concentration
        isActualWeight
        adminType
        route {
          route
          code
          __typename
        }
        weight
        protocolID
        __typename
      }
      equipments {
        equipmentID
        amount
        name
        snomedCode
        timestamp
        isActualWeight
        verified
        weight
        successful
        protocolID
        __typename
      }
      electricals {
        electricalID
        name
        snomedCode
        amount
        timestamp
        isActualWeight
        verified
        weight
        protocolID
        __typename
      }
      cpr {
        cprID
        startTime
        endTime
        outcome
        __typename
      }
      emsCalculators {
        calculatorID
        responses
        scores
        timestamp
        __typename
      }
      patientSaved
      patientReleased
      arrivedAtHospital
      ageTimestamp
      forms {
        formID
        name
        completed
        startedTime
        protocolID
        __typename
      }
      subtractedItems {
        weightObjectID
        name
        weight
        added
        __typename
      }
      userIDs
      infusions {
        dripID
        amnt
        amnt2
        timestamp
        verified
        name
        rxNormCode
        concentration
        adminType
        route {
          route
          code
          __typename
        }
        weight
        protocolID
        amntUnit
        amntUnit2
        isActualWeight
        gttsDropFactor
        gttsAmount
        gttsUnit
        __typename
      }
      estimatedSaved
      tags
      shifts {
        shiftStart
        shiftEnd
        completedLogs
        taggedAmbulance
        taggedOneWeight
        taggedUsers
        ownerID
        departmentID
        metaData
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateLogUserMutationVariables,
  APITypes.CreateLogUserMutation
>;
export const updateLogUser = /* GraphQL */ `mutation UpdateLogUser(
  $input: UpdateLogUserInput!
  $condition: ModelLogUserConditionInput
) {
  updateLogUser(input: $input, condition: $condition) {
    id
    userId
    logId
    user {
      id
      firstName
      lastName
      type
      cognitoID
      indexedParentDepID
      departmentID
      depAdmins
      logs {
        items {
          id
          userId
          logId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      hashedPin
      saltPin
      pairedDepIDs
      oneDoseVersion
      lastActivity
      notificationTokens
      deviceIDs
      maxDevices
      allowAnalytics
      status
      FormLogs {
        items {
          id
          inputformID
          departmentID
          userID
          pairedDepID
          pairedUserIDs
          notes
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Reviewals {
        items {
          id
          title
          description
          reviewers
          status
          createdAt
          endedAt
          departmentID
          userID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Acknowledges {
        items {
          id
          reviewalID
          ownerID
          userID
          status
          isArchived
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      UserComments {
        items {
          id
          ownerID
          userID
          acknowledgeID
          message
          relatedItems
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      NotifyACKs {
        items {
          id
          ownerID
          userID
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    log {
      id
      actualWeight
      ageValue
      protocolID
      Users {
        items {
          id
          userId
          logId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      departmentID
      oneweightID
      ambulanceID
      incidentNumber
      startTime
      endTime
      estimatedWeight
      medications {
        medicationID
        amntSolid
        amntLiquid
        timestamp
        verified
        name
        rxNormCode
        concentration
        isActualWeight
        adminType
        route {
          route
          code
          __typename
        }
        weight
        protocolID
        __typename
      }
      equipments {
        equipmentID
        amount
        name
        snomedCode
        timestamp
        isActualWeight
        verified
        weight
        successful
        protocolID
        __typename
      }
      electricals {
        electricalID
        name
        snomedCode
        amount
        timestamp
        isActualWeight
        verified
        weight
        protocolID
        __typename
      }
      cpr {
        cprID
        startTime
        endTime
        outcome
        __typename
      }
      emsCalculators {
        calculatorID
        responses
        scores
        timestamp
        __typename
      }
      patientSaved
      patientReleased
      arrivedAtHospital
      ageTimestamp
      forms {
        formID
        name
        completed
        startedTime
        protocolID
        __typename
      }
      subtractedItems {
        weightObjectID
        name
        weight
        added
        __typename
      }
      userIDs
      infusions {
        dripID
        amnt
        amnt2
        timestamp
        verified
        name
        rxNormCode
        concentration
        adminType
        route {
          route
          code
          __typename
        }
        weight
        protocolID
        amntUnit
        amntUnit2
        isActualWeight
        gttsDropFactor
        gttsAmount
        gttsUnit
        __typename
      }
      estimatedSaved
      tags
      shifts {
        shiftStart
        shiftEnd
        completedLogs
        taggedAmbulance
        taggedOneWeight
        taggedUsers
        ownerID
        departmentID
        metaData
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateLogUserMutationVariables,
  APITypes.UpdateLogUserMutation
>;
export const deleteLogUser = /* GraphQL */ `mutation DeleteLogUser(
  $input: DeleteLogUserInput!
  $condition: ModelLogUserConditionInput
) {
  deleteLogUser(input: $input, condition: $condition) {
    id
    userId
    logId
    user {
      id
      firstName
      lastName
      type
      cognitoID
      indexedParentDepID
      departmentID
      depAdmins
      logs {
        items {
          id
          userId
          logId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      hashedPin
      saltPin
      pairedDepIDs
      oneDoseVersion
      lastActivity
      notificationTokens
      deviceIDs
      maxDevices
      allowAnalytics
      status
      FormLogs {
        items {
          id
          inputformID
          departmentID
          userID
          pairedDepID
          pairedUserIDs
          notes
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Reviewals {
        items {
          id
          title
          description
          reviewers
          status
          createdAt
          endedAt
          departmentID
          userID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Acknowledges {
        items {
          id
          reviewalID
          ownerID
          userID
          status
          isArchived
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      UserComments {
        items {
          id
          ownerID
          userID
          acknowledgeID
          message
          relatedItems
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      NotifyACKs {
        items {
          id
          ownerID
          userID
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    log {
      id
      actualWeight
      ageValue
      protocolID
      Users {
        items {
          id
          userId
          logId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      departmentID
      oneweightID
      ambulanceID
      incidentNumber
      startTime
      endTime
      estimatedWeight
      medications {
        medicationID
        amntSolid
        amntLiquid
        timestamp
        verified
        name
        rxNormCode
        concentration
        isActualWeight
        adminType
        route {
          route
          code
          __typename
        }
        weight
        protocolID
        __typename
      }
      equipments {
        equipmentID
        amount
        name
        snomedCode
        timestamp
        isActualWeight
        verified
        weight
        successful
        protocolID
        __typename
      }
      electricals {
        electricalID
        name
        snomedCode
        amount
        timestamp
        isActualWeight
        verified
        weight
        protocolID
        __typename
      }
      cpr {
        cprID
        startTime
        endTime
        outcome
        __typename
      }
      emsCalculators {
        calculatorID
        responses
        scores
        timestamp
        __typename
      }
      patientSaved
      patientReleased
      arrivedAtHospital
      ageTimestamp
      forms {
        formID
        name
        completed
        startedTime
        protocolID
        __typename
      }
      subtractedItems {
        weightObjectID
        name
        weight
        added
        __typename
      }
      userIDs
      infusions {
        dripID
        amnt
        amnt2
        timestamp
        verified
        name
        rxNormCode
        concentration
        adminType
        route {
          route
          code
          __typename
        }
        weight
        protocolID
        amntUnit
        amntUnit2
        isActualWeight
        gttsDropFactor
        gttsAmount
        gttsUnit
        __typename
      }
      estimatedSaved
      tags
      shifts {
        shiftStart
        shiftEnd
        completedLogs
        taggedAmbulance
        taggedOneWeight
        taggedUsers
        ownerID
        departmentID
        metaData
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteLogUserMutationVariables,
  APITypes.DeleteLogUserMutation
>;
