import { Accordion, AccordionTab } from 'primereact/accordion';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { handleUpdateSelectedAccordion } from '../../../../store/actions';
import ProtocolAccordionSecondLayer from './ProtocolAccordionSecondLayer';
import ProtocolItem from '../../../../data/model/ProtocolItem';
import MedicationSubItem from '../../../../data/model/MedicationSubItem';
import ElectricalSubItem from '../../../../data/model/ElectricalSubItem';
import EquipmentItem from '../../../../data/model/EquipmentItem';
import FormItem from '../../../../data/model/FormItem';
import DepartmentItem from '../../../../data/model/DepartmentItem';

import {
  findDepartmentOwner,
  findPairedDepartments,
  getDepartmentsFromState,
  getFormattedDate,
  globals,
  hasAdminUserAccess,
} from '../../../_global/common/Utils';
import { InputText } from 'primereact/inputtext';
import { Col, Row } from 'react-bootstrap';
import { TbDatabaseOff } from 'react-icons/tb';
import MedicationItem from '../../../../data/model/MedicationItem';
import SearchableDropdown from '../../../components/SearchableDropdown';
import { ViewportList } from 'react-viewport-list';
import { FaCheckCircle, FaTimes } from 'react-icons/fa';
import MedicationAccordion from '../edit/Medication/MedicationAccordion';
import { User } from '../../../../models';
import { UserType } from '../../../../models';
import { BiCopy, BiSolidCopy } from 'react-icons/bi';
import { ProgressStatus } from '../../../../models';
import Status from '../../../components/ProgressStatus/ProgressStatus';
import EditAccessModal from '../../../components/Modal/EditAccessModal';
import CategoryItem from '../../../../data/model/CategoryItem';
import InfusionSubItem from '../../../../data/model/InfusionSubItem';
import { InputSwitch } from 'primereact/inputswitch';
import ModelItem from '../../../../data/model/ModelItem';
import ModelSubItem from '../../../../data/model/ModelSubItem';
import { AccordionDetails, AccordionSummary } from '@mui/material';
import { FaChevronDown, FaChevronRight } from 'react-icons/fa6';
import FolderProtocolAdvancedSettings from '../../../components/FolderProtocolAdvancedSettings';
import AdminModelItemDetails from '../../../components/AdminModelItemDetails';
import { IoDocumentText } from 'react-icons/io5';
import { Sketch } from '@uiw/react-color';

type CalculationScreenProps = {
  onItemClick: (
    item:
      | MedicationSubItem
      | InfusionSubItem
      | ElectricalSubItem
      | EquipmentItem
      | FormItem
      | ProtocolItem,
    type:
      | 'Medication'
      | 'Infusion'
      | 'Equipment'
      | 'Electrical'
      | 'Checklist'
      | 'Paired Protocol',
    state?: any
  ) => void;

  onRemoveItem: (
    item:
      | MedicationSubItem
      | InfusionSubItem
      | ElectricalSubItem
      | EquipmentItem
      | FormItem
      | ProtocolItem,
    type:
      | 'Medication'
      | 'Infusion'
      | 'Equipment'
      | 'Electrical'
      | 'Checklist'
      | 'Paired Protocol'
  ) => void;
  onReorder: (
    list: (
      | MedicationSubItem
      | InfusionSubItem
      | ElectricalSubItem
      | EquipmentItem
      | FormItem
      | ProtocolItem
    )[],
    type:
      | 'Medication'
      | 'Infusion'
      | 'Equipment'
      | 'Electrical'
      | 'Checklist'
      | 'Paired Protocol'
  ) => void;
  formik: any;
  isEditMode: any;
  protocolDetail: any;
  newEquipment?: EquipmentItem[];
  newChecklists?: FormItem[];
  newPairedProtocols?: ProtocolItem[];
  pairedDeps: DepartmentItem[];
  setPairedDeps: any;
  setSelectedItemDeactivationStatus?: (selected: any) => void;
  viewOnlyMode?: boolean;
};

/* 10-02-23 Arul: Created CalculationScreen component  for Protocol detail page*/
const CalculationScreen: React.FC<CalculationScreenProps> = (props) => {
  const {
    onItemClick,
    onReorder,
    onRemoveItem,
    formik,
    protocolDetail,
    isEditMode,
    setPairedDeps,
    setSelectedItemDeactivationStatus,
    viewOnlyMode,
  } = props;
  const database = useSelector((state: any) => state.protocol.departmentItem);
  const department: DepartmentItem = database.department;
  const user: User = useSelector((state: any) => state?.user);
  const [protocol, setProtocol] = useState<ProtocolItem>(protocolDetail);
  const [departmentList, setDepartmentList] = useState<any[]>([]);
  const [isAccessVisible, setAccessVisible] = useState<boolean>(false);
  const pairedDeps = useMemo(() => props.pairedDeps, [props.pairedDeps]);

  const reducerState = useSelector((state: any) => state.department);
  const { subDeps, allSubDeps, userDepartments } = useMemo(() => {
    return getDepartmentsFromState(department, reducerState);
  }, [department, reducerState]);

  const adminAccess = useMemo(() => {
    return hasAdminUserAccess(department, reducerState, user, protocolDetail);
  }, [department, reducerState, user, protocolDetail]);

  const isOwner = useMemo(() => {
    const departmentOwner = findDepartmentOwner(
      department,
      reducerState,
      protocolDetail
    );
    return departmentOwner?.id === user.id;
  }, [department, protocolDetail, user, reducerState]);

  const adminLevel = useMemo(() => {
    return database.department?.adminLevel ?? 4;
  }, [database.department]);

  const dispatch = useDispatch();
  const selectedAccordion = useSelector(
    (state: any) => state.protocol.selectedAccordion
  );

  const [isCopied, setIsCopied] = useState<string | null>(null);
  const [showDeactivated, setShowDeactivated] = useState(false);
  const [isSelectingDepartments, setIsSelectingDepartments] =
    useState<boolean>(false);
  const [owner, setOwner] = useState<DepartmentItem | undefined>(department);
  const [isColorPickerOpen, setIsColorPickerOpen] = useState<boolean>(false);

  const colorPickerRef = useRef<HTMLDivElement>(null);
  const colorInputRef = useRef<HTMLDivElement>(null);

  const availableDepartments = useMemo(() => {
    if (owner == null) return allSubDeps ?? [];

    const ownerAllSubDeps = getDepartmentsFromState(
      owner,
      reducerState
    ).allSubDeps;
    let list = ownerAllSubDeps ?? [];

    const parentFolder = protocol.parent;
    list = list.filter((dep) => {
      if (!parentFolder.isRestrictive) return true;
      else if (parentFolder.pairedDepIDs) {
        return parentFolder.pairedDepIDs.includes(dep.id);
      } else return false;
    });

    return list;
  }, [owner, protocol, department, allSubDeps]);

  const numDeactivated = useMemo(() => {
    let items: (ModelItem<any> | ModelSubItem<any>)[] = [
      ...protocol.medications,
      ...protocol.infusions,
      ...protocol.electrical,
      ...protocol.equipment,
      ...protocol.forms,
      ...protocol.pairedProtocols,
    ];
    return items.filter((item) => item.status === ProgressStatus.DEACTIVATED)
      .length;
  }, [protocol]);
  const [itemDeactivationStatus, setItemDeactivationStatus] = useState<any>();

  const medications = useMemo(() => {
    return protocol.medications
      .filter(
        (item) => showDeactivated || item.status !== ProgressStatus.DEACTIVATED
      )
      .sort((a, b) =>
        a.index === b.index ? a.rangeLow - b.rangeLow : a.index - b.index
      );
  }, [protocol, showDeactivated]);

  const infusions = useMemo(() => {
    return protocol.infusions
      .filter(
        (item) => showDeactivated || item.status !== ProgressStatus.DEACTIVATED
      )
      .sort((a, b) =>
        a.index === b.index ? a.rangeLow - b.rangeLow : a.index - b.index
      );
  }, [protocol, showDeactivated]);

  const electrical = useMemo(() => {
    return protocol.electrical
      .filter(
        (item) => showDeactivated || item.status !== ProgressStatus.DEACTIVATED
      )
      .sort((a, b) =>
        a.index === b.index ? a.rangeLow - b.rangeLow : a.index - b.index
      );
  }, [protocol, showDeactivated]);

  const equipment = useMemo(() => {
    return protocol.equipment.filter(
      (item) => showDeactivated || item.status !== ProgressStatus.DEACTIVATED
    );
  }, [protocol, showDeactivated]);

  const forms = useMemo(() => {
    // if (adminLevel < 3) return [];
    return (
      protocol.forms.filter(
        (item) => showDeactivated || item.status !== ProgressStatus.DEACTIVATED
      ) ?? []
    );
  }, [protocol, showDeactivated]);

  const pairedProtocols = useMemo(() => {
    return protocol.pairedProtocols.filter
      ? protocol.pairedProtocols.filter(
          (item) =>
            showDeactivated || item.status !== ProgressStatus.DEACTIVATED
        )
      : [];
  }, [protocol, showDeactivated]);

  const newPairedProtocols = useMemo(() => {
    return (
      props.newPairedProtocols &&
      props.newPairedProtocols.filter(
        (item) => showDeactivated || item.status !== ProgressStatus.DEACTIVATED
      )
    );
  }, [props.newPairedProtocols, showDeactivated]);

  const newEquipment = useMemo(() => {
    return (
      props.newEquipment &&
      props.newEquipment.filter(
        (item) => showDeactivated || item.status !== ProgressStatus.DEACTIVATED
      )
    );
  }, [props.newEquipment, showDeactivated]);

  const newChecklists = useMemo(() => {
    // if (adminLevel < 3) return [];
    return (
      props.newChecklists &&
      props.newChecklists.filter(
        (item) => showDeactivated || item.status !== ProgressStatus.DEACTIVATED
      )
    );
  }, [props.newChecklists, showDeactivated]);

  useEffect(() => {
    const initialStatus = [
      ...protocol.medications,
      ...protocol.infusions,
      ...protocol.electrical,
      ...protocol.equipment,
      ...protocol.forms,
      ...protocol.pairedProtocols,
    ].filter((item) => item.status === ProgressStatus.DEACTIVATED);

    setItemDeactivationStatus(initialStatus);
  }, [protocol]);

  const handleToggleEnabledUI = async (
    item: ModelItem<any> | ModelSubItem<any>
  ) => {
    // Check if the item is already in itemDeactivationStatus
    const isItemDeactivated = itemDeactivationStatus.some(
      (deactivatedItem: ModelItem<any> | ModelSubItem<any>) =>
        deactivatedItem.uid === item.uid
    );

    // Update itemDeactivationStatus
    if (isItemDeactivated) {
      // Remove the item if it's already deactivated
      setItemDeactivationStatus(
        itemDeactivationStatus.filter(
          (newItem: ModelItem<any> | ModelSubItem<any>) =>
            newItem.uid !== item.uid
        )
      );
    } else {
      // Add the item if it's not deactivated
      setItemDeactivationStatus([...itemDeactivationStatus, item]);
    }

    // Update selectedItemDeactivationStatus
    setSelectedItemDeactivationStatus &&
      setSelectedItemDeactivationStatus((prevSelected: any) => {
        const currentSelected = prevSelected || [];

        const isItemSelected = currentSelected.some(
          (selectedItem: any) => selectedItem.uid === item.uid
        );

        if (isItemSelected) {
          // Remove the item if it's already selected
          return currentSelected.filter(
            (selectedItem: any) => selectedItem.uid !== item.uid
          );
        } else {
          // Add the item if it's not selected
          return [...currentSelected, item];
        }
      });
  };

  useEffect(() => {
    setProtocol(protocolDetail);
  }, [protocolDetail]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (isColorPickerOpen) {
        // Check if the click is outside both the color picker and the input container
        if (
          colorPickerRef.current &&
          colorInputRef.current &&
          !colorPickerRef.current.contains(event.target as Node) &&
          !colorInputRef.current.contains(event.target as Node)
        ) {
          setIsColorPickerOpen(false);
        }
      }
    };

    // Add event listener when color picker is open
    if (isColorPickerOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    // Clean up
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isColorPickerOpen]);

  useEffect(() => {
    if (allSubDeps) {
      let l = [...allSubDeps];
      l = l.filter((dep) => {
        return !pairedDeps.some((d: DepartmentItem) => d.id === dep.id);
      });
      setDepartmentList(l);
    }
  }, [allSubDeps, pairedDeps]);

  // const protocol = useSelector((state: any) => state?.protocol?.protocol);

  const handleClick = (e: any) => {
    dispatch<any>(handleUpdateSelectedAccordion(e.index));
  };

  const handleRemoveDepartment = (item: DepartmentItem, e: any) => {
    e.stopPropagation();
    let l = pairedDeps.filter((dep: DepartmentItem) => dep.id !== item.id);
    formik.setFieldValue('pairedDeps', l);
  };

  const handleAddDepartment = (option: DepartmentItem) => {
    let l = [...pairedDeps, option];
    l.sort((a, b) => a.name.localeCompare(b.name));
    formik.setFieldValue('pairedDeps', l);
  };

  const handleClearDepartments = () => {
    formik.setFieldValue('pairedDeps', []);
  };

  const handleCopy = (text: string, event: any) => {
    event.stopPropagation(); // Prevents the click event from bubbling up
    navigator.clipboard.writeText(text);

    setIsCopied(text);
    setTimeout(() => setIsCopied(null), 2000); // show a "copied!" message for 2 seconds
  };

  return (
    <div className="ketamineContent">
      <EditAccessModal
        isVisible={isAccessVisible}
        handleClose={() => setAccessVisible(false)}
        handleSubmit={(
          newItem: DepartmentItem | CategoryItem | ProtocolItem,
          metaData: any
        ) => {
          console.log('Updated item:', newItem);
          setAccessVisible(false);
          setProtocol(newItem as ProtocolItem);
        }}
        department={department}
        keychains={database.keychains}
        item={protocol}
      />
      <div className="KetamineGeneral">
        <h5 className="ketmine-header-text">General Information</h5>
        <div className="container overflow-info-first overflow-info-container">
          <div
            className="input-container roundBorder"
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <div
              style={{
                display: 'flex',
                marginTop: '5px',
                flexDirection: 'column',
                flex: 1,
              }}
              className="ketamine-general-label"
            >
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ marginRight: '10px' }}>Access:</div>
                <div style={{ fontWeight: '500', paddingBottom: '10px' }}>
                  <Status
                    status={
                      !protocol.isPublic
                        ? 'Private'
                        : protocol.keychainID
                          ? 'Protected'
                          : 'Public'
                    }
                    onClick={
                      isEditMode && isOwner
                        ? (status: string) => {
                            setAccessVisible(true);
                          }
                        : undefined
                    }
                  />
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  flex: undefined,
                }}
              >
                <div style={{ marginRight: '10px' }}>Modified By:</div>
                <div style={{ fontWeight: '500', paddingBottom: '10px' }}>
                  {protocol.modifiedBy
                    ? protocol.modifiedBy.firstName +
                      ' ' +
                      protocol.modifiedBy.lastName
                    : 'Hinckley Medical'}
                </div>
              </div>
              {protocol.model.updatedAt && (
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div style={{ marginRight: '10px' }}>Last Updated:</div>
                  <div style={{ fontWeight: '500', paddingBottom: '10px' }}>
                    {getFormattedDate(protocol.model.updatedAt, false)}
                  </div>
                </div>
              )}
            </div>
            <div
              style={{
                display: 'flex',
                marginTop: '5px',
                flexDirection: 'column',
                flex: 0.75,
              }}
              className="ketamine-general-label"
            >
              {protocol.version && (
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div style={{ marginRight: '10px' }}>Version:</div>
                  <div style={{ fontWeight: '500', paddingBottom: '10px' }}>
                    {protocol.version}
                  </div>
                </div>
              )}
              {protocol.pdfVersion && (
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div style={{ marginRight: '10px' }}>PDF Version:</div>
                  <div style={{ fontWeight: '500', paddingBottom: '10px' }}>
                    {protocol.pdfVersion}
                  </div>
                </div>
              )}
            </div>
          </div>
          <label htmlFor="name" className="ketamine-general-label">
            Name <span className="required-field">*</span>
          </label>
          <div className="input-container">
            <InputText
              type="text"
              className="form-control-general"
              id="name"
              name="name"
              data-testid="name"
              required={true}
              value={formik.values.name}
              onChange={(e: any) => {
                formik.setFieldValue('name', e.target.value);
              }}
              disabled={!isEditMode || !adminAccess}
            />
            <div className="input-border"></div>
          </div>
          <label htmlFor="nickname" className="ketamine-general-label">
            Nickname <span className="required-field">*</span>
          </label>
          <div className="input-container">
            <InputText
              type="text"
              className="form-control-general"
              id="nickname"
              name="nickname"
              data-testid="nickname"
              required={true}
              value={formik.values.nickname}
              onChange={(e: any) => {
                formik.setFieldValue('nickname', e.target.value);
              }}
              disabled={!isEditMode || !adminAccess}
            />
            <div className="input-border"></div>
          </div>
          <label
            htmlFor=""
            className={`notification-css-title`}
            style={{ fontSize: '16px' }}
          >
            Color
          </label>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              position: 'relative',
            }}
          >
            <div
              className={`color-input-container ${
                !isEditMode || !adminAccess ? 'disabled' : ''
              }`}
              onClick={() => {
                if (!isEditMode || !adminAccess) return;
                setIsColorPickerOpen(!isColorPickerOpen);
              }}
              ref={colorInputRef}
            >
              <IoDocumentText
                className="table-icon"
                style={{ margin: '0 4px' }}
                color={formik.values.color}
              />
            </div>
            {isColorPickerOpen && (
              <div
                className="color-picker-container"
                style={{
                  position: 'absolute',
                  left: '18%',
                  top: '-30px',
                  zIndex: 1000,
                }}
                ref={colorPickerRef}
              >
                <Sketch
                  color={formik.values.color}
                  onChange={(color) => {
                    formik.setFieldValue('color', color.hex);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                      setIsColorPickerOpen(false);
                    }
                  }}
                />
              </div>
            )}
          </div>
          <Row>
            <Col sm={6}>
              <label htmlFor="rangeLow" className="ketamine-general-label">
                Range Low (kg)
              </label>
              <div className="input-container">
                <InputText
                  type="text"
                  className="form-control-general"
                  id="rangeLow"
                  name="rangeLow"
                  data-testid="rangeLow"
                  value={formik.values.rangeLow}
                  // style={{color: Number(formik.values.rangeLow) ? '#9e9e9e' : '#000000'}}
                  placeholder="Min"
                  disabled={!isEditMode || !adminAccess}
                  onChange={(e: any) => {
                    /* Check for "Min" */
                    let n = Number(e.target.value);
                    if (isNaN(n))
                      formik.setFieldError('rangeLow', 'Invalid input');
                    else formik.setFieldValue('rangeLow', e.target.value);
                  }}
                  onBlur={formik.handleBlur}
                />
                <div className="input-border"></div>
              </div>
              {formik.touched.rangeLow && formik.errors.rangeLow ? (
                <span className="errorText">{formik.errors.rangeLow}</span>
              ) : null}
            </Col>
            <Col sm={6}>
              <label htmlFor="rangeHigh" className="ketamine-general-label">
                Range High (kg)
              </label>
              <div className="input-container">
                <InputText
                  type="text"
                  className="form-control-general"
                  id="rangeHigh"
                  name="rangeHigh"
                  data-testid="rangeHigh"
                  disabled={!isEditMode || !adminAccess}
                  value={
                    formik.values.rangeHigh &&
                    Number(formik.values.rangeHigh) !== globals.MAX_VALUE
                      ? formik.values.rangeHigh + ''
                      : ''
                  }
                  placeholder="Max"
                  onChange={(e: any) => {
                    let n = Number(e.target.value);
                    if (isNaN(n))
                      formik.setFieldError('rangeHigh', 'Invalid input');
                    else formik.setFieldValue('rangeHigh', e.target.value);
                  }}
                  onBlur={formik.handleBlur}
                />
                <div className="input-border"></div>
              </div>
              {formik.touched.rangeHigh && formik.errors.rangeHigh ? (
                <span className="errorText">{formik.errors.rangeHigh}</span>
              ) : null}
            </Col>
          </Row>

          {adminAccess && department.isMultiDep && (
            <FolderProtocolAdvancedSettings
              type={'Protocol'}
              owner={owner}
              setOwner={setOwner}
              isRestrictive={formik.values.isRestrictive}
              setIsRestrictive={() => {
                formik.setFieldValue(
                  'isRestrictive',
                  !formik.values.isRestrictive
                );
              }}
              availableDepartments={availableDepartments}
              pairedDeps={pairedDeps}
              setPairedDeps={setPairedDeps}
              department={department}
              isEditMode={isEditMode}
              activeID={formik.values.activeID}
              setActiveID={(id: string) => formik.setFieldValue('activeID', id)}
            />
          )}

          {user.type === UserType.ADMIN && (
            <AdminModelItemDetails modelItem={protocol} />
          )}
          {/* {department.subDeps && department.subDeps.length > 0 && (
            <div>
              <label
                htmlFor="rangeHigh"
                className="ketamine-general-label"
                style={{
                  marginTop: '10px',
                }}
              >
                Subscribed Departments: {pairedDeps.length} /{' '}
                {department.subDeps.length}
                {isEditMode && (
                  <span
                    onClick={() =>
                      formik.setFieldValue('pairedDeps', department.subDeps)
                    }
                  >
                    <div className="clickableText">Add All</div>
                  </span>
                )}
              </label>
              <span
                className="contentText greyText"
                style={{ fontSize: '13px', marginLeft: '10px' }}
              >
                This is a list of every department that subscribes to protocol{' '}
                {protocol.name}.
              </span>
              <SearchableDropdown<DepartmentItem>
                id="searchDropdown"
                options={departmentList}
                labelField={(option) => option.name}
                valueField={(option) => option.name}
                keyField={(option) => option.id}
                multiSelect={true}
                onChange={(option: DepartmentItem) =>
                  handleAddDepartment(option)
                }
                onClear={handleClearDepartments}
                placeholder="Search department..."
                isActive={isEditMode}
              />
              {pairedDeps.length === 0 && (
                <h6 style={{ textAlign: 'center', marginTop: '10px' }}>
                  No paired departments...
                </h6>
              )}
              <div
                style={{
                  // overflowY: 'auto',
                  // maxHeight: '200px',
                  border:
                    pairedDeps.length === 0
                      ? '0px'
                      : '1px solid #ccc',
                  borderRadius: '5px',
                  marginBottom: '20px',
                  marginTop: '10px',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <ViewportList items={pairedDeps}>
                  {(item: DepartmentItem, index) => (
                    <div
                      key={index}
                      style={{
                        display: 'grid',
                        gridTemplateColumns: '16fr 1fr',
                        padding: '6px 10px',
                        alignItems: 'center',
                        borderBottom:
                          index === pairedDeps.length - 1
                            ? ''
                            : '1px solid #ccc',
                      }}
                      className="listItem"
                    >
                      <div className="contentText">{item.name}</div>
                      <FaTimes
                        className={
                          isEditMode ? 'icon-cancel' : 'icon-cancel-disabled'
                        }
                        size={16}
                        onClick={(e) => {
                          if (isEditMode) handleRemoveDepartment(item, e);
                        }}
                      />
                    </div>
                  )}
                </ViewportList>
              </div>
            </div>
          )} */}
        </div>
      </div>
      <div className="KetamineGeneral ">
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            // marginBottom: '10px',
            // marginTop: '10px',
          }}
        >
          <h5 className="ketmine-header-text">Treatment References</h5>
          {numDeactivated > 0 && (
            <InputSwitch
              tooltip={'Show ' + numDeactivated + ' Deactivated Items'}
              tooltipOptions={{
                position: 'bottom',
                style: { fontSize: '12px' },
              }}
              name="isSolidOnly"
              style={{ marginLeft: '10px' }}
              checked={showDeactivated}
              onChange={(e) => {
                setShowDeactivated(e.value);
              }}
            />
          )}
        </div>
        {protocol.sum === 0 && (
          <div className="no-data-container">
            <TbDatabaseOff size={220} className="light-grey-icon" />
            <h4 className="light-grey-icon">No references created...</h4>
          </div>
        )}
        <Accordion
          activeIndex={selectedAccordion}
          onTabChange={handleClick}
          multiple
        >
          {medications?.length >= 1 && (
            <AccordionTab
              data-testid="Medications"
              header={`Medications: ${medications.length}`}
            >
              <div>
                <MedicationAccordion
                  medicationList={medications}
                  protocol={protocol}
                  isEditMode={isEditMode}
                  onReorder={(items: MedicationSubItem[]) =>
                    onReorder(items, 'Medication')
                  }
                  onItemClick={(item: MedicationSubItem, state: any) =>
                    onItemClick(item, 'Medication', state)
                  }
                  onToggle={handleToggleEnabledUI}
                  // isToggled={
                  //   itemDeactivationStatus.find(
                  //     (newItem: ModelItem<any> | ModelSubItem<any>) =>
                  //       medications.find(
                  //         (med: MedicationSubItem) => med.uid === newItem.uid
                  //       )
                  //   )
                  //     ? false
                  //     : true
                  // }
                />
              </div>
            </AccordionTab>
          )}
          {infusions?.length >= 1 && (
            <AccordionTab
              data-testid="Infusions"
              header={`Infusions: ${infusions.length}`}
            >
              <div>
                <ProtocolAccordionSecondLayer
                  data={infusions}
                  protocol={protocol}
                  type={'Infusion'}
                  accordionType={'Infusion'}
                  isEditMode={isEditMode}
                  onReorder={onReorder}
                  onItemClick={(item: any, type: any, state: any) => {
                    onItemClick(item, type, state);
                  }}
                  department={department}
                  adminLevel={adminLevel}
                  onToggle={handleToggleEnabledUI}
                  viewOnlyMode={viewOnlyMode}
                  // isToggled={
                  //   itemDeactivationStatus.find(
                  //     (newItem: ModelItem<any> | ModelSubItem<any>) =>
                  //       infusions.find(
                  //         (inf: InfusionSubItem) => inf.uid === newItem.uid
                  //       )
                  //   )
                  //     ? false
                  //     : true
                  // }
                />
              </div>
            </AccordionTab>
          )}

          {electrical?.length >= 1 && (
            <AccordionTab
              data-testid="Electrical"
              header={`Electrical: ${electrical.length}`}
            >
              <div>
                <ProtocolAccordionSecondLayer
                  data={electrical}
                  protocol={protocol}
                  type={'Electrical'}
                  accordionType={'Electrical'}
                  isEditMode={isEditMode}
                  onReorder={onReorder}
                  onItemClick={(item: any, type: any, state: any) => {
                    onItemClick(item, type, state);
                  }}
                  department={department}
                  adminLevel={adminLevel}
                  onToggle={handleToggleEnabledUI}
                  viewOnlyMode={viewOnlyMode}
                  // isToggled={
                  //   itemDeactivationStatus.find(
                  //     (newItem: ModelItem<any> | ModelSubItem<any>) =>
                  //       electrical.find(
                  //         (inf: ElectricalSubItem) => inf.uid === newItem.uid
                  //       )
                  //   )
                  //     ? false
                  //     : true
                  // }
                />
              </div>
            </AccordionTab>
          )}
          {((newEquipment && newEquipment.length > 0) ||
            equipment?.length >= 1) && (
            <AccordionTab
              data-testid="Equipment"
              header={`Equipment: ${
                (newEquipment && newEquipment.length > 0
                  ? newEquipment
                  : equipment
                )?.length
              }`}
            >
              <div>
                <ProtocolAccordionSecondLayer
                  data={newEquipment ? newEquipment : equipment}
                  protocol={protocol}
                  type={'Equipment'}
                  accordionType={'Equipment'}
                  isEditMode={isEditMode}
                  onReorder={onReorder}
                  onRemoveItem={onRemoveItem}
                  department={department}
                  adminLevel={adminLevel}
                  viewOnlyMode={viewOnlyMode}
                />
              </div>
            </AccordionTab>
          )}
          {((newChecklists && newChecklists.length > 0) ||
            forms?.length >= 1) && (
            <AccordionTab
              data-testid="Checklist"
              header={`Checklist: ${
                (newChecklists && newChecklists.length > 0
                  ? newChecklists
                  : forms
                )?.length
              }`}
            >
              <div>
                <ProtocolAccordionSecondLayer
                  data={
                    newChecklists && newChecklists.length > 0
                      ? newChecklists
                      : forms
                  }
                  protocol={protocol}
                  type={'Checklist'}
                  accordionType={'Checklist'}
                  isEditMode={isEditMode}
                  onReorder={onReorder}
                  onRemoveItem={onRemoveItem}
                  department={department}
                  adminLevel={adminLevel}
                  onToggle={handleToggleEnabledUI}
                  viewOnlyMode={viewOnlyMode}
                />
              </div>
            </AccordionTab>
          )}
          {((newPairedProtocols && newPairedProtocols.length > 0) ||
            pairedProtocols?.length >= 1) && (
            <AccordionTab
              data-testid="Paired Protocols"
              header={`Paired Protocols: ${
                (newPairedProtocols && newPairedProtocols.length > 0
                  ? newPairedProtocols
                  : pairedProtocols
                )?.length
              }`}
            >
              <div>
                <ProtocolAccordionSecondLayer
                  data={
                    newPairedProtocols ? newPairedProtocols : pairedProtocols
                  }
                  protocol={protocol}
                  type={'Paired Protocol'}
                  accordionType={'Paired Protocol'}
                  isEditMode={isEditMode}
                  onReorder={onReorder}
                  onItemClick={onItemClick}
                  onRemoveItem={onRemoveItem}
                  department={department}
                  adminLevel={adminLevel}
                  onToggle={handleToggleEnabledUI}
                  viewOnlyMode={viewOnlyMode}
                />
              </div>
            </AccordionTab>
          )}
        </Accordion>
      </div>
    </div>
  );
};

export default CalculationScreen;
